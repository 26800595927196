
import Vue from 'vue';
import folderDialog from '@/tasks/common/views/$common/folderCommon.vue';
import recordDialog from './$dialogs/contrlistid.vue';

export default Vue.extend({
  components: {
    folderDialog,
    recordDialog,
  },
});
