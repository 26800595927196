const Agreement: StackSelection = {
  objectName: 'ЛицевыеСчета.СозданиеРассрочки',
  description: 'Выберите услуги для создания рассрочки',
  fields: [
    {
      field: 'услуга',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: 'сумма начислений',
      text: 'Сумма начислений',
      type: 'String',
    },
    {
      field: 'сумма прошлый период',
      text: 'Сумма прошлый период',
      type: 'String',
    },
    {
      field: 'процент превышения',
      text: 'Процент превышения',
      type: 'String',
    },
  ],
};

export default Agreement;
