
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      state: {
        датасостояния: '',
        вид: 0,
        сотрудник: 0,
        состояние: 0,
      },
    };
  },
});
