
import Vue from 'vue';

export default Vue.extend({
  props: {
    dialogName: { type: [String], default: 'default' },
  },
  computed: {
    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
  },
  data() {
    return {
      item: {} as StackTableRow,
      dataModel: new this.$HttpModel('ВыгрузкиПодкачки'),
      loading: false,
      isDialog: false,
      records: [] as StackTableRow[],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.records = await this.dataModel.getRecords({ операция: 0 });
    },
    getDialogName(row: StackTableRow): string | undefined {
      if (row && row.dialog) {
        return row.dialog as string;
      } else {
        return undefined;
      }
    },
    async openList(item: StackTableRow) {
      this.isDialog = false;
      const record = await this.dataModel.initRecord({ выгрузка: item.title });
      const tempRecord = {} as StackTableRow;
      for (const ind in record) {
        if (record[ind] && !ind.startsWith('$')) {
          tempRecord[ind] = record[ind];
        }
      }
      this.item = Object.assign({}, item, tempRecord);
      this.isDialog = true;
    },
    upload(item: StackTableRow) {
      const заголовок = (item.title ? item.title : '') as string;
      this.dataModel.executeMethod('Выгрузить', item, {
        async: true,
        jobName: заголовок,
      });
    },
  },
});
