
import Vue from 'vue';
import AddressTab from './$otherstabs/AddressTab.vue';
import CallCentrTab from './$otherstabs/CallCentrTab.vue';
import DopsTab from './$otherstabs/DopsTab.vue';
import HistoryLSTab from './$otherstabs/HistoryLSTab.vue';
import KvitTab from './$otherstabs/KvitTab.vue';
import LogsDocTab from './$otherstabs/LogsDocTab.vue';
import LogsRecalcTab from './$otherstabs/LogsRecalcTab.vue';
import LogsTab from './$otherstabs/LogsTab.vue';
import PostTab from './$otherstabs/PostTab.vue';
import ClaimsTab from './$otherstabs/ClaimsTab.vue';
import networkTab from '@/tasks/fl/components/adrDialogs/$components/networkTab.vue';
import DistributorTab from './$otherstabs/DistributorTab.vue';

export default Vue.extend({
  name: 'OthersTab',
  components: { AddressTab, CallCentrTab, DopsTab, HistoryLSTab, KvitTab, LogsDocTab, LogsRecalcTab, LogsTab, PostTab, ClaimsTab, networkTab, DistributorTab },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
    ownerID: { type: [Number, String], default: null },
  },
  computed: {
    contactcenter(): boolean {
      return this.$store.getters['flStore/getContactCenter']();
    },
    isNewRecord(): boolean {
      return this.ownerID === 'new';
    },
  },
  methods: {
    tabChange(tab: string) {
      if (tab === 'history') {
        this.$sendMsg('stack-table', 'ЛицевыеСчета.История', 'reload');
      }
    },
  },
});
