
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: { type: Object, required: true },
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
  },
  data() {
    return {
      items: [] as any,
    };
  },
  computed: {
    hier(): boolean {
      return this.dataObject && this.dataObject.hier ? this.dataObject.hier : false;
    },
    nameOfDataModelType(): string {
      return this.$store.getters['flStore/getLossesEl']() ? 'ТипыНоменклатурТрасформатора' : 'ТипыНоменклатур';
    },
  },
  async created() {
    const result = await new this.$HttpModel(this.nameOfDataModelType).getRecords();
    this.items = [];
    result.forEach((element: any) => {
      this.items.push({ title: element.значение, type: element.ключ });
    });
  },
  methods: {
    onBtnClick(type: number) {
      const payload = { идентификатор: type };
      this.tableAction({ action: 'add', payload });
    },
    onBtnClickFolder() {
      this.tableAction({ action: 'add_folder' });
    },
  },
});
