
import Vue from 'vue';
import MonthChip from './MonthChip.vue';
import UserMenu from './UserMenu.vue';
import AsyncActionMenu from './AsyncActionMenu.vue';

export default Vue.extend({
  components: { MonthChip, UserMenu, AsyncActionMenu },
  computed: {
    isCurrentTaskExternal(): boolean {
      return this.$store.getters.isCurrentTaskExternal();
    },
    breadcrumbsItems(): any {
      return this.$store.getters.getBreadcrumbsItems();
    },
    taskList(): Task[] {
      return this.$store.getters.getTaskList();
    },
    currentTaskName(): string {
      return this.$store.getters.getCurrentTaskName();
    },
    isAuth(): boolean {
      return this.$store.getters.isAuth();
    },
  },
  data() {
    return {
      set: false,
      breadOldLen: 0, // вместо from в вотчере
    };
  },
  methods: {
    onBreadItemClick(to: string) {
      this.$store.commit('SHRINK_BREADCRUMB_ITEMS', to);
    },
  },
  watch: {
    breadcrumbsItems(to) {
      // @ts-ignore
      if (this.$refs.slidegroup && this.$refs.slidegroup.scrollTo) {
        if (to.length >= this.breadOldLen) {
          // @ts-ignore
          this.$refs.slidegroup.scrollTo('next');
        } else {
          // @ts-ignore
          this.$refs.slidegroup.scrollTo('prev');
        }
        this.breadOldLen = to.length;
      }
    },
  },
});
