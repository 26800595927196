
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
    params: {
      type: Object,
      default: (): any => {
        return {};
      },
    },
  },
  data() {
    return {
      item: {
        ...this.value,
        ДатНач: this.$store.getters.getWorkMonth(),
        ДатКнц: this.$stackDate.lastDay(this.$store.getters.getWorkMonth()) as string,
        Дата: this.$store.getters.getCurrentDate() as string,
        Вариант: 0,
        'Вызов из веба': true,
        ВидДокСоб: 1,
      },
    };
  },
});
