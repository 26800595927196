
import Vue from 'vue';
import BlockedRecordsTable from './BlockedRecordsTable.vue';
export default Vue.extend({
  components: { BlockedRecordsTable },
  data() {
    return {
      isBlockedRecordDialogVisible: true,
    };
  },
});
