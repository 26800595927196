import { TableHandlers } from '@/stackEngine';
const Agreement: StackSelection = {
  objectName: 'СоглашенияОРассрочке.ПогашенныеСоглашения',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.принудительно === 0) {
      TableHandlers.tdIcon(row, '@готовыкзакрытию', '$vuetify.icons.check', 'green');
    }
    return row;
  },
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'лицевойсчет',
      text: 'Лицевой счет',
      type: 'Number',
    },
    {
      field: 'наниматель',
      text: 'Наниматель',
      type: 'String',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'нзсоглашение',
      text: 'нзСоглашение',
      type: 'Number',
    },
    {
      field: 'нзлицевой',
      text: 'нзЛицевой',
      type: 'Number',
    },
    {
      field: 'принудительно',
      text: 'Принудительно',
      type: 'Number',
    },
    {
      field: '@готовыкзакрытию',
      text: 'Готовность к закрытию',
      type: 'String',
      computed: true,
      align: 'center',
    },
  ],
};

export default Agreement;
