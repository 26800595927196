const uploads: StackSelection = {
  objectName: 'АПИ_Импорт_СписокФайлов',
  description: '',
  fields: [
    {
      field: 'файл',
      text: 'Файл',
      type: 'String',
    },
  ],
};
export default uploads;
