
import Vue from 'vue';

export default Vue.extend({
  props: {
    ownerID: { type: [Number, String], required: true },
  },
  data() {
    return {
      title: null as null | string,
      newParamDialogVisible: false,
      currentNewParam: {} as StackTableRow,
      params: {
        '@датнач': '',
        '@даткнц': '',
        '@продолжительность': 0,
        '@среднесут': 0,
        '@t0': 0,
        '@tcs': 0,
        '@tc': 0,
        '@альфа': -1,
        '@кир': 0,
      } as any,
      year: this.$store.getters.getWorkMonth(),
      month: this.$store.getters.getWorkMonth(),
      itemsList: [] as any,
      averageTemp: {} as any,
      headers: [
        {
          field: '@01',
          text: 'Янв',
          type: 'Boolean',
        },
        {
          field: '@02',
          text: 'Фев',
          type: 'Boolean',
        },
        {
          field: '@03',
          text: 'Март',
          type: 'Boolean',
        },
        {
          field: '@04',
          text: 'Апр',
          type: 'Boolean',
        },
        {
          field: '@05',
          text: 'Май',
          type: 'Boolean',
        },
        {
          field: '@06',
          text: 'Июнь',
          type: 'Boolean',
        },
        {
          field: '@07',
          text: 'Июль',
          type: 'Boolean',
        },
        {
          field: '@08',
          text: 'Авг',
          type: 'Boolean',
        },
        {
          field: '@09',
          text: 'Сент',
          type: 'Boolean',
        },
        {
          field: '@10',
          text: 'Окт',
          type: 'Boolean',
        },
        {
          field: '@11',
          text: 'Нояб',
          type: 'Boolean',
        },
        {
          field: '@12',
          text: 'Дек',
          type: 'Boolean',
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    disabled(): boolean {
      return this.params.$толькоЧтение;
    },
    disabledDate(): boolean {
      return this.params['@продолжительностьчтение'] > 0;
    },
  },
  methods: {
    changeDate(key: boolean) {
      if (key) {
        const date = new Date(this.params['@датнач']);
        date.setDate(date.getDate() + this.params['@продолжительность'] - 1);
        this.params['@даткнц'] = this.$stackDate.format(date, 'ISO');
      } else {
        const days = this.$stackDate.differenceInDays(new Date(this.params['@даткнц']), new Date(this.params['@датнач'])) + 1;
        this.params['@продолжительность'] = days;
      }
      this.params['@продолжительность'] = this.params['@продолжительность'] === 0 ? 1 : this.params['@продолжительность'];
    },
    async onSaveParameters() {
      const average = this.itemsList[0] ? this.itemsList[0] : '';
      await new this.$HttpModel('ПараметрыТепловогоРасчета').executeMethod('обновить', {
        месяц: this.month,
        плс: this.ownerID,
        запись: this.params,
        средняя: this.averageTemp,
        равномерно: average,
      });
      this.$emit('updateParams');
      this.$set(this.params, '$толькоЧтение', true);
    },
    async onSavePeriod() {
      await new this.$HttpModel('ПараметрыТепловогоРасчета').executeMethod('Период', { плс: this.ownerID, запись: this.params });
      this.$emit('updateParams');
      this.$set(this.params, '@продолжительностьчтение', 1);
    },
    async fetchData() {
      const res = await new this.$HttpModel('ПараметрыТепловогоРасчета').getRecords({ плс: this.ownerID });
      if (res && res[0]) {
        this.params = Object.assign(res[0], {});
      }
      const res2 = await new this.$HttpModel('ПериодРавномерногоНачисления').getRecords({ плс: this.ownerID });
      if (res2 && res2[0]) {
        this.itemsList = [];
        this.itemsList.push({
          '@01': Boolean(res2[0]['@01']),
          '@02': Boolean(res2[0]['@02']),
          '@03': Boolean(res2[0]['@03']),
          '@04': Boolean(res2[0]['@04']),
          '@05': Boolean(res2[0]['@05']),
          '@06': Boolean(res2[0]['@06']),
          '@07': Boolean(res2[0]['@07']),
          '@08': Boolean(res2[0]['@08']),
          '@09': Boolean(res2[0]['@09']),
          '@10': Boolean(res2[0]['@10']),
          '@11': Boolean(res2[0]['@11']),
          '@12': Boolean(res2[0]['@12']),
        });
      }
    },
    changeAverageTemp(payload: any) {
      if (payload && payload[0]) {
        this.averageTemp = payload[0];
      }
    },
  },
});
