
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record' },
  data() {
    return {
      additionalShablons: [] as StackTableRow[],
      month: this.$store.getters.getWorkMonth() as Date,
    };
  },
  props: {
    record: { type: Object, required: true },
  },
  methods: {
    async onSave() {
      const parameters = {
        method: 'печать',
        ДополнительнаяПечать: this.additionalShablons,
        Месяц: this.month,
        Фонд: this.record.$номерЗаписи,
        ОбщПечать: false,
        Разовая: true,
      };
      try {
        if (await new this.$HttpModel('МассоваяПечатьКвитанций').executeMethod('ПроверкаПечати', parameters)) {
          return;
        }
      } catch {
        return;
      }
      await new this.$HttpModel('МассоваяПечатьКвитанций').executeMethod('СохранитьФайл', parameters, {
        async: true,
        jobName: `Печать: Дополнительных квитанций по л/с ${this.record.номер}  за ${this.$stackDate.format(this.month, 'MM.YYYY')}`,
        type: 'report',
        onComplete: () => {
          this.refreshTable();
        },
        onError: () => {
          this.refreshTable();
        },
      });
      this.$emit('close');
    },
    refreshTable() {
      this.$sendMsg('stack-table', 'ЛицевыеСчета.Квитанции', 'reload');
    },
  },
});
