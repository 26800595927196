
import Vue from 'vue';
import CalcTabMixin from './CalcTabMixin';

export default Vue.extend({
  name: 'PaymentsInfo',
  mixins: [CalcTabMixin],
  data() {
    return {
      http: new this.$HttpModel('ЛицевыеСчета.ДопИнформацияПоЛицевому'),
      items: [] as StackTableRow[],
      emptyText: '',
    };
  },
  methods: {
    async fetchData() {
      // @ts-ignore
      this.items = await this.http.executeMethod('ПолучитьПлатежи', { владелец: this.ownerId });
      this.emptyText = !this.items.length ? 'Платежи за данный период отсутствуют' : '';
    },
  },
});
