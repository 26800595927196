
import Vue from 'vue';
import LawsuitTab from './$otherstabs/LawsuitTab.vue';
import AgreementsTab from './AgreementsTab.vue';

export default Vue.extend({
  components: { LawsuitTab, AgreementsTab },
  name: 'DebtTab',
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  methods: {
    onChangeTab(tabName: string) {
      if (tabName === 'norecalc') {
        this.$sendMsg('stack-table', 'ЛицевыеСчета.ПениПериодыНеначисления', 'reload');
      }
    },
  },
});
