
import Vue from 'vue';
import { stackAppEmitter } from '../../../utils/emitters';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  data() {
    return {
      record: { флаги: 0 },
      loading: false,
    };
  },
  methods: {
    async onReload() {
      this.loading = true;
      try {
        const res = await new this.$StackApi().reloadResources(this.record['флаги']);
        // /При обновлении экспл. параметров перекешируем всё то с бекэнда, что мы кешируем при авторизации
        if (this.record['флаги'] & 8192) {
          await stackAppEmitter.emitAsync('APP_USER_SIGN_IN', this.$store);
        }
      } finally {
        this.loading = false;
        this.$emit('close');
      }
    },
  },
});
