
import Vue from 'vue';
import NetTree from './NetTree.vue';

export default Vue.extend({
  components: { NetTree },
  props: {
    /** видимость диалога */
    value: { type: Boolean, required: true },
    /** макс. ширина диалога  */
    maxWidth: { type: [String, Number], default: '800px' },
    width: { type: [String, Number], default: '100%' },
    /** тип сети для фильтра */
    type: { type: Number, required: true },
    /** мультивыбор в дереве */
    multi: { type: Boolean, default: false },
  },
  data() {
    return {
      records: [] as StackTableRow[],
      diag: false,
    };
  },
  computed: {
    disableselect(): boolean {
      return this.multi ? !this.records.length : this.records.length !== 1;
    },
  },
  methods: {
    async onSelectElement(payload: any) {
      this.records = [];
      if (payload && payload.length) {
        for (const item of payload) {
          const res = await new this.$HttpModel('ЭлементСети').getRecords({ номерЗаписи: item.$номерЗаписи });
          if (res && res[0]) {
            const record = Object.assign({}, payload[0], res[0]);
            this.records.push(record);
          }
        }
      }
    },
    onSave() {
      if (!this.records.length) {
        this.$toast('Необходимо выбрать элемент сети');
      } else {
        this.$emit('save', this.records);
      }
    },
  },
});
