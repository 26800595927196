const employees: StackSelection = {
  objectName: 'Организации.Сотрудники',
  description: 'Сотрудники, работающие с данной организацией и её подразделениями',
  fields: [
    {
      field: 'подразделение',
      text: 'Подразделение организации',
      type: 'String',
    },
    {
      field: 'отдел',
      text: 'Отдел сотрудника',
      type: 'String',
    },
    {
      field: 'табномер',
      text: 'ТабНомер',
      type: 'String',
    },
    {
      field: 'фио',
      text: 'ФИО сотрудника',
      type: 'String',
    },
  ],
};

export default employees;
