
import Vue from 'vue';
import docvlad from '@/tasks/common/views/$common/residents/docVlad.vue';
import NewPeopleDialog from '@/tasks/common/views/references/people/$dialog/NewPeopleDialog.vue';

export default Vue.extend({
  name: 'OwnerDocsTab',
  components: { docvlad, NewPeopleDialog },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
    height: { type: String, default: '100%' },
  },
  data() {
    return {
      newPeopleVis: false,
      newIdLs: -1 as number,
      newIdDoc: -1 as number,
    };
  },
  methods: {
    async onCreateRecord(payload: any) {
      if (await this.$yesno('Вы хотите зарегистрировать человека?')) {
        this.newIdLs = payload['счет-владение'];
        this.newIdDoc = payload['человек-владение'];
        this.newPeopleVis = true;
      }
    },
    changeItems(items: StackTableRow[]) {
      const isMainOwnerExist = items.some((item: StackTableRow) => item.титульный);
      this.$emit('change-items', isMainOwnerExist, (items.length > 0));
    },
  },
});
