<template>
  <l-map
    :style="`z-index: 0;height:${height}`"
    :zoom="zoom"
    :center="center"
    :options="{ zoomControl: false }"
    @ready="$emit('ready')"
  >
    <l-tile-layer :url="url" />
    <l-marker v-for="item of markers" :key="item.guid" :lat-lng="item.coordinats">
      <l-tooltip :content="item.description" />
    </l-marker>
    <l-control position="topleft">
      <slot name="toolbar" />
    </l-control>
    <l-control-zoom position="topright" />
  </l-map>
</template>

<script>
import Vue from 'vue';
import { LMap, LTileLayer, LMarker, LTooltip, LControl, LControlZoom } from 'vue2-leaflet';
// добавлено для корректной отрисовки карты
import 'leaflet/dist/leaflet.css';

// TODO иконки для leaflet
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default Vue.extend({
  name: 'StackMap',
  components: { LMap, LTileLayer, LMarker, LTooltip, LControl, LControlZoom },
  props: {
    center: { type: [Array], default: () => { return [59.18, 123.75]; } }, // центр россии
    markers: { type: Array, default: undefined },
    zoom: { type: Number, default: 4 },
    url: { type: String, default: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' },
    height: { type: String, default: '100%' },
  },
});
</script>