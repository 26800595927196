import Vue from 'vue';
import Router from 'vue-router';
import { getTaskConfig, getGlobalConfig } from '../utils';
import { store } from '../store';

const cfg: any = getGlobalConfig();
const baseURL: string = cfg && cfg.ROOT_URL ? cfg.ROOT_URL : '/';

let router: Router;

export function getRouter() {
  if (!router) {
    const taskConfig = getTaskConfig();
    // инициализируем роутер
    router = new Router({
      mode: 'history',
      base: `${baseURL}${taskConfig.prefix || ''}`,
    });

    if (!taskConfig.externalTask) {
      router.beforeEach(async (to, _from, next) => {
        store.commit('INC_ROUTE_HISTORY');
        // TODO whitelist
        if (to.path === '/' || to.name === 'about' || to.name === 'plugin' || to.path === '/redirect' || to.name === '404') {
          next();
          return;
        }
        // Подождём загрузки приложения, прежде чем посылать нафиг,
        // если прошли по прямой ссылке
        if (!store.getters.isAppReady()) {
          await new Promise((resolve) => {
            const unwatch = store.watch(() => store.getters.isAppReady(), (value) => {
              unwatch();
              resolve(value);
              if (!store.getters.isAuth()) {
                Vue.prototype.$toast(`Учетные данные для аутентификации не предоставлены`, { color: 'error', icon: '$vuetify.icons.lock' });
                next({ name: 'redirect', query: { from: to.fullPath } });
                return;
              }
              next(to.fullPath);
            });
          });
          return;
        }
        // Проверим права доступа...
        if (store.getters.checkRoutePermission(to.path)) {
          next();
        } else {
          Vue.prototype.$toast(`Недостаточно прав доступа !`, { color: 'error', icon: '$vuetify.icons.lock' });
        }
      });
    }
  }
  return router;
}

const routerViews: __WebpackModuleApi.RequireContext[] = [];

export function getRouteViews() {
  return routerViews;
}

export function registerViews(views: __WebpackModuleApi.RequireContext[]) {
  routerViews.push(...views);
}