
import Vue from 'vue';

export default Vue.extend({
  props: {
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
  },
  computed: {
    hier(): boolean {
      return this.dataObject && this.dataObject.hier ? this.dataObject.hier : false;
    },
  },
  data() {
    return {
      items: [{ title: 'Акт по решению суда', type: 2 }, { title: 'Акт отключения', type: 3 }, { title: 'Акт подключения', type: 4 }] as any,
    };
  },
  methods: {
    onBtnClick(typels: number) {
      const payload = { видакта: typels };
      this.tableAction({ action: +typels === 0 ? 'add_folder' : 'add', payload });
    },
  },
});
