
import Vue from 'vue';
import addressFilter from '@/tasks/fl/views/$common/filters/addressFilter.vue';

export default Vue.extend({
  components: { addressFilter },
  data() {
    return {
      dial: false,
      // stateIdHash: 'account_search_last_state',
      title: 'Быстрый поиск лицевого счета',
      item: {} as StackTableRow,
      loading: false,
      fundModel: {} as StaticModel,
      results: [] as StackTableRow[],
      manyRec: false,
      tabs: null,
      cacheRecs: [] as StackTableRow[],
    };
  },
  computed: {
    headers(): StackField[] {
      const headers = [
        {
          field: 'номер',
          text: 'Номер',
          type: 'String',
        },
        {
          field: 'адреслс',
          text: 'Адрес',
          type: 'String',
        },
      ] as StackField[];
      if (this.$store.getters.getCurrentTask() === 'ul') {
        headers.push({ field: 'примечание', text: 'Примечание', type: 'String' });
        headers.push({ field: 'договор', text: 'Договор', type: 'String' });
      } else {
        headers.push({ field: 'счет-наниматель>фио', text: 'ФИО', type: 'String' });
        headers.push({ field: 'примечание', text: 'Примечание', type: 'String' });
        headers.push({ field: 'месблок', text: 'Месяц блокировки', type: 'Date' });
      }
      return headers;
    },
  },
  methods: {
    toDateTime(date: string) {
      return this.$stackDate.format(date, 'DD.MM.YYYY HH:mm');
    },
    onHisListClick(to: string) {
      if (to && this.$route.path !== to) {
        this.$router.replace(to);
      }
      this.dial = false;
    },
    onOpenDial() {
      this.item = {};
      this.tabs = null;
      this.cacheRecs = this.$store.getters.getSyncCache('stack_dialog_ЛицевыеСчета') || [];
      this.dial = true;
    },
    timeout(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async onFilter() {
      // TODO Комбобокс не успевает дослать change, если жать сразу на Сохранить
      // Нужно разобраться ! Никуда Не копипастить !!!
      await this.timeout(200);
      // this.$store.commit('SAVE_CACHE_DATA', { id: this.stateIdHash, data: this.item });
      await this.onSearch(false);
    },
    async onSearch(jump: boolean) {
      const parameters = {} as any;
      let foundOne = false;
      for (const field in this.item) {
        if (this.item[field] && (!jump || field === 'нп' || field === 'пструктура' || field === 'улица' || field === 'дом' || field === 'квартира' || field === 'комната')) {
          parameters[field] = this.item[field];
          foundOne = true;
          // Если выбрано фионанимателя - добавляем флаг собственников
          if (field === 'фионанимателя') {
            parameters.собственники = 1;
          }
        }
      }
      if (!foundOne) {
        this.$toast('Переход возможен только при указании адреса');
        return;
      }
      this.loading = true;
      const params = { фильтр: { ...parameters, развернутьПапки: 1, сКорня: 1, переход: jump } };
      this.results = await new this.$HttpModel('ЛицевыеСчета').getRecords(params);
      if (this.results.length === 1) {
        const rec: StackTableRow = this.results[0];
        this.pushRoute(rec);
      } else if (this.results.length > 1) {
        this.fundModel = new this.$StaticModel(this.results);
        this.manyRec = true;
      } else {
        this.$toast('Лицевой не найден');
      }
      this.loading = false;
    },
    onBtnChoose(payload: any) {
      this.pushRoute(payload[0]);
    },
    pushRoute(rec: StackTableRow) {
      // TODO Очищаем хлебные крошки, т.к. "путь" не соотв. переходу.
      // Нужно будет генерить крошки от текущей записи иерарх. выборок.
      this.$store.commit('CLEAR_BREADCRUMB_ITEM', 2);
      this.$store.commit('SAVE_CACHE_DATA', { id: `ЛицевыеСчета_stack_table_rowid`, data: rec.$номерЗаписи });
      this.dial = false;
      this.manyRec = false;
      if (rec.$этоПапка) {
        this.$router.push({
          name: 'accounts',
          query: {
            row_id: rec.$номерЗаписи as string,
          },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        }).catch(e => {});
      } else {
        this.$router.push({
          name: 'accounts-accountsid',
          params: {
            accountsid: rec.$номерЗаписи as string,
            noRouteHistory: 'true',
          },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        }).catch(e => {});
      }
    },

  },
});
