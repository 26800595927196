
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      rulesThis: {
        isLinked: (value: string | number): string | true => {
          if (value !== undefined && value !== null) {
            const valueArr = value.toString().split(',');
            if (valueArr.length > 0 && +valueArr[0] > 0) {
              return true;
            }
          }
          return 'Поле не может быть пустым';
        },
      },
    };
  },
  methods: {
    labelField(item: StackTableRow) {
      this.record.адрес = item.адрес;
      return item.адрес;
    },
  },
});
