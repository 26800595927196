
import Vue from 'vue';

import NetTreeDialog from '@/tasks/commun/views/nettree/$components/NetTreeDialog.vue';

export default Vue.extend({
  components: { NetTreeDialog },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      netTreeVisible: false,
      netType: 0,
      netLink: null,
    };
  },
  methods: {
    onEditLink(item: any) {
      this.netType = item.типсети;
      this.netLink = item.связь;
      this.netTreeVisible = true;
    },
    async onSelectNetElement(payload: StackTableRow[]) {
      this.netTreeVisible = false;
      if (payload && payload[0] && payload[0].типсети === this.netType) {
        await new this.$HttpModel('ЛицевыеСчета.Сети').executeMethod('Привязать', { связь: this.netLink, лицевой: this.record.$номерЗаписи, элемент: payload[0].$номерЗаписи, соединение: payload[0].соединение_ид });
        // @ts-ignore
        this.$refs.table.fetchData();
      }
    },
    async onDeleteLink(item: StackTableRow) {
      if (await this.$yesno('Вы уверены, что хотите удалить привязку к сети ?')) {
        await new this.$HttpModel('ЛицевыеСчета.Сети').executeMethod('Отвязать', { связь: item.связь });
        // @ts-ignore
        this.$refs.table.fetchData();
      }
    },
  },
});
