
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    srok2(): any {
      const arr = this.record.значение.split(';');
      // Если пришло что-то непонятное ставим значения по умолчанию
      return arr.length === 2 ? arr : [0, 10];
    },
  },
  methods: {
    changeFunc(value: number, id: number) {
      const arr = this.srok2;
      arr[id] = value;
      this.record.значение = arr.join(';');
    },
  },
});
