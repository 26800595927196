
import Vue from 'vue';

/** компонент переключалка Месяца  */
export default Vue.extend({
  name: 'StackMonthField',
  props: {
    value: { type: String, default: undefined },
    changeWorkMonth: { type: Boolean, default: false },
    small: { type: Boolean, default: false },

  },
  data() {
    return {
      menu: false,
      curValue: this.value || this.$store.getters.getWorkMonth(),
    };
  },
  computed: {
    currentMonth(): string {
      return this.curValue ? this.$stackDate.format(this.curValue, 'YYYY-MM') : undefined;
    },
    getCloseMonth(): string {
      return this.$store.getters.getCloseMonth();
    },
    getWorkMonth(): string {
      return this.$store.getters.getWorkMonth();
    },
    isClosedMounth(): boolean {
      return new Date(this.getCloseMonth) >= new Date(this.curValue);
    },
    monthColor(): string {
      return this.isClosedMounth ? 'error--text' : 'primary--text';
    },
  },
  methods: {
    onUpDown(idx: number) {
      const d = new Date(this.curValue);
      d.setMonth(d.getMonth() + idx);
      this.changeCurDate(d);
    },
    changeCurDate(d: string | Date) {
      this.curValue = d;
      if (this.changeWorkMonth) {
        this.$store.commit('CHANGE_WORK_MONTH', d);
      }
      this.$emit('input', this.$stackDate.format(d, 'ISOMonth'));
    },
  },
  watch: {
    getWorkMonth() {
      if (this.changeWorkMonth) {
        this.changeCurDate(this.getWorkMonth);
      }
    },
  },
});
