
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: { type: Object, default: null },
  },
  data() {
    return {
      item: {
        Дата: this.$store.getters.getOpenMonth(),
        ДатаРаспределения: '',
      },
    };
  },
  async created() {
    const res = await new this.$HttpModel('ВедомостьРаспределенияОплаты').executeMethod('ДатаРаспределения', {});
    if (res) {
      this.item.ДатаРаспределения = res;
      this.item.Дата = res;
    }
  },
  methods: {
    async onSave() {
      const jobID = (await new this.$HttpModel('ВедомостьРаспределенияОплаты').executeMethod(
        'УдалитьРаспределение',
        { Дата: this.item.Дата },
        { async: true, jobName: 'Удаление распределения оплаты' },
      )).asyncId;
      this.$emit('save', jobID);
    },
  },
});
