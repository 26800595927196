const deductions_payers: StackSelection = {
  objectName: 'ВедомостиУдержаний.Удержание.Плательщики',
  description: 'Плательщики лицевого',
  fields: [
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'карточка-плательщик',
      text: 'КарточкаID',
      type: 'Link',
    },
  ],
};
export default deductions_payers;
