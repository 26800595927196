
import Vue from 'vue';
import stepBtn from './stepBtn.vue';

export default Vue.extend({
  props: {
    record: { type: Object, required: true },
    steps: { type: Object, required: true },
  },
});
