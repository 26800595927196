export const appToolbar: StackTourStep[] = [
  {
    target: '.v-app-bar',
    selectedEl: '#toolbar-overlay',
    content: `Панель инструментов (тулбар) всегда на виду.
    <div class="pt-2" />
    Здесь вы получите быстрый доступ ко многим важным функциям.`,
    params: {
      placement: 'bottom',
    },
  },
  {
    target: '[data-cy="user-menu"]',
    content: `Откройте меню пользователя, чтобы:
    <div class="pt-2" />
    <ul>
      <li>перезагрузить ресурсы,</li>
      <li>перейти в настройки администратора,</li>
      <li>установить Стек Ассистент,</li>
      <li>переключить программу,</li>
      <li>получить помощь,</li>
      <li>выйти из программы.</li>
    </ul>
    `,
    params: {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-90, 15],
          },
        },
      ],
    },
  },
  {
    target: '[data-cy="stack-month-chip"]',
    content: `Сверху – текущий расчетный месяц. Его можно переключить с помощью стрелок или нажав на область с месяцами.<div/>
    Снизу отображается последний закрытый месяц в программе. Он неактивен.`,
    params: {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [5, 15],
          },
        },
      ],
    },
  },
  {
    target: '[data-test-id="fast-readings-input"]',
    content: `Оперативно введите показания по любому лицевому счету.`,
    params: {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [5, 15],
          },
        },
      ],
    },
  },
  {
    target: '[data-cy="app-toolbar-chip"]',
    content: `Удобно и быстро найдите лицевой счет по номеру, адресу, ФИО нанимателя или номеру прибора учета.`,
    params: {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [5, 15],
          },
        },
      ],
    },
  },
  {
    target: '[data-cy="msg-menu"]',
    content: `Следите за статусом операций в реестре уведомлений. Например, за построением отчета, выгрузкой.
    <div class="pt-2" />  
    Операция может быть завершена <span class="primary--text">успешно</span>, <span class="error--text">c ошибкой</span> или еще  <span class="warning--text">не завершена</span>.
    <div class="pt-2" />  
    Нажмите на реестр операций, чтобы посмотреть результат каждой операции, скачать файл или отменить операцию.
    `,
    params: {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [5, 15],
          },
        },
      ],
    },
  },
];