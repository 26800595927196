
import Vue from 'vue';

export default Vue.extend({
  props: {
    noRowAction: { type: Boolean, default: false },
    params: { type: Object, required: true },
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
  },
  data() {
    return {
      rec: {},
      calcid: null,
      Dial: false,
      typeDate: '',
      newDate: null,
      items: [] as any,
    };
  },
  async created() {
    const result = await new this.$HttpModel('ТипыНоменклатур').getRecords();
    this.items = [];
    result.forEach((element: any) => {
      this.items.push({ title: element.значение, type: element.ключ });
    });
  },

  methods: {
    onBtnClick(type: number) {
      const payload = { идентификатор: type };
      this.tableAction({ action: 'add', payload });
    },
  },
});
