
import Vue from 'vue';
import ContactsDialog from '@/tasks/common/views/$common/contactsDialog.vue';

export default Vue.extend({
  components: { ContactsDialog },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
    headers: { type: [String], default: 'нпп,датаактуализации,номер,@тип,@принадлежность,примечание,@флаги' },
    description: { type: [String], default: '' },
    noSearch: { type: [Boolean], default: false },
  },
});
