
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  created() {
    this.flags = this.record.значение.split('#');
  },
  data() {
    return {
      flags: [],
    };
  },
  methods: {
    changeFunc(items: StackTableRow[]) {
      const val = this.flags[0] + '#' + this.flags[1];
      this.$set(this.record, 'значение', val);
    },
  },
});
