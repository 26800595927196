
import Vue from 'vue';

export default Vue.extend({
  name: 'InspectionsactsTab',
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  methods: {
    routeTo(payload: any) {
      if (payload) {
        this.$router.push({
          name: 'documents-inspectionsacts-inspectionsactid',
          params: {
            inspectionsactid: payload.$номерЗаписи as string,
            readonly: 'true',
          },
        });
      }
    },
  },
});
