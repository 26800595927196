
import Vue from 'vue';
export default Vue.extend({
  props: {
    src: { type: String, required: true },
  },
  data() {
    return {
      isPlayed: undefined as boolean | undefined,
    };
  },
  methods: {
    onPlayPause() {
      if (!this.isPlayed) {
        // @ts-ignore
        this.$refs.player.play();
        this.isPlayed = true;
      } else {
        // @ts-ignore
        this.$refs.player.pause();
        this.isPlayed = false;
      }
    },
    onStop() {
      // @ts-ignore
      this.$refs.player.pause();
      // @ts-ignore
      this.$refs.player.currentTime = 0;
      this.isPlayed = undefined;
    },
    onVolPlus() {
      // @ts-ignore
      if (this.$refs.player.volume < 1) {
        // @ts-ignore
        this.$refs.player.volume += 0.1;
      }
    },
    onVolMinus() {
      // @ts-ignore
      if (this.$refs.player.volume > 0.1) {
        // @ts-ignore
        this.$refs.player.volume -= 0.1;
      }
    },
  },
});
