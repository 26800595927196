
import Vue from 'vue';

/**
 * Диалог выбора записей в таблице
 */
export default Vue.extend({
  name: 'StackSelectDialog',
  props: {
    /** видимость диалога */
    value: { type: Boolean, required: true },

    /** макс. ширина диалога  */
    maxWidth: { type: [String, Number], default: undefined },
    width: { type: [String, Number], default: '100%' },
    /** высота таблицы, по умолчанию - как было жёстко прописано **/
    height: { type: [String, Number], default: '450px' },

    /** выбранные записи */
    items: { type: [String, Number], default: undefined }, // инициализированные значения линка TODO

    /**
     * модель данных
     */
    dataModel: { type: [String, Object], required: true },

    /**
     * перечень названий полей для таблицы ('номер,примечание,фио')
     */
    // eslint-disable-next-line vue/require-prop-types
    headers: { required: true }, // парсер лох type: [String, Array]

    /**
     * Можно выбирать только одну запись
     */
    single: { type: Boolean, default: false },

    /**
     * нельзя выбирать папки
     */
    noFolder: { type: Boolean, default: false },

    /**
     * пропс что-бы не срабатывал клик на иконке при задизейбленом поле
     */
    readonly: { type: Boolean, default: false },

    /**
     * пропс для мультивыбора, когда необходимо выбрать на разных страницах
     */
    noPagination: { type: Boolean, default: false },
    /**
     * не отображать иконку поиска в тулбаре
     */
    noSearch: { type: Boolean, default: false },
    tableKey: { type: String, default: '' },
  },
  data() {
    return {
      menu: false,
      selected: [] as StackTableRow[],
      unwatchHandler: undefined as any,
      inputValue: '' as string,
    };
  },
  created() {
    // ESC
    window.addEventListener('keyup', this.onKeyPress);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyPress);
  },
  methods: {
    onKeyPress(event: KeyboardEvent) {
      switch (event.key) {
        case 'Escape':
          this.onClose();
          break;
      }
    },
    // событие на чекбокс
    onSelect(selectedRows: StackTableRow[]) {
      this.selected = selectedRows;
      this.$emit('select', this.selected);
      if (this.single && this.selected && this.selected.length > 0) {
        this.onSave();
      }
    },

    // Закрываем диалог
    onClose() {
      this.$emit('input', false);
    },

    // Выбор
    onSave() {
      this.$emit('save', this.selected);
    },
  },
});
