
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      item: {
        флаг: null,
        флагошибок: null,
        номерлсфайл: null,
      } as any,
    };
  },
  methods: {
    onChange(payload: number) {
      this.item.флагошибок = Math.pow(2, payload);
    },
  },
});
