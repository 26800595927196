
import Vue from 'vue';
import ReadingFilter from './$components/ReadingFilter.vue';

export default Vue.extend({
  name: 'MassInputReadings',
  components: { ReadingFilter },
  methods: {
    beforeFetch(payload: any) {
      return new Promise((resolve, reject) => {
        new this.$HttpModel('Счетчики.МассовыйВвод').executeMethod('Заполнить', payload, {
          async: true,
          jobName: 'Заполнение выборки',
          silent: true,
          external: true,
          onComplete: () => {
            resolve(true);
          },
          onError: err => {
            this.$toast(err.status, { color: 'error' });
            reject(err.status);
          },
        });
      });
    },
    applyFilter(payload: any) {
      // @ts-ignore
      this.$refs.table.applyFilter(payload);
    },
    applyData(payload: any) {
      // @ts-ignore
      this.$refs.table.applyData(payload);
    },
  },
  computed: {
    needRecalcLs() {
      return this.$store.getters['flStore/getOnRecalc']();
    },
  },
});
