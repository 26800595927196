
import Vue from 'vue';

export default Vue.extend({
  props: {
    kvitmessagesid: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      selected: {} as StackTableRow,
    };
  },
  computed: {
    modelTypeAdress(): string {
      return this.record.типадресата && this.record.типадресата > 3 ? 'СообщенияДляКвитанций.ТипАдресата' : 'СообщенияДляКвитанций.ТипАдресатаБезОрг';
    },
    readonlyDial(): boolean {
      return !!(this.record.типадресата === 4 || this.record.типадресата === 5);
    },
    title(): string {
      return `Сообщение для квитанции`;
    },
    closed(): boolean {
      if (this.record && (this.record.$номерЗаписи === -1 || this.record.$номерЗаписи === 'new')) {
        return false;
      }
      if (this.record && this.record.датнач && this.record.датнач < this.$store.getters.getOpenMonth()) {
        return true;
      }
      return false;
    },
    closedDateEnd(): boolean {
      if (this.record && (this.record.$номерЗаписи === -1 || this.record.$номерЗаписи === 'new')) {
        return false;
      }
      if (this.record && this.record.даткнц && this.record.даткнц < this.$store.getters.getOpenMonth()) {
        return true;
      }
      return false;
    },
  },
  methods: {
    labelFieldLSHandler(record: StackTableRow) {
      return record.тип === 5 ? record.адреслс + ' ' + '№' + record.номер + ' (' + record['счет-наниматель>фио'] + ')' : record.$название;
    },
    labelFieldUKHandler(record: StackTableRow) {
      return '№' + record.номер + ' ' + record.тема + ' ' + record['организация-укдоговор>название'] + ' от ' + this.$stackDate.format(record.датнач as string, 'DD.MM.YYYY');
    },
  },
  watch: {
    'record.типадресата': {
      handler(to) {
        if (to !== 1 && this.record['лицевой-сообщение'] && this.record['лицевой-сообщение'] > 0) {
          this.record['лицевой-сообщение'] = null;
        }
        if (to !== 2 && this.record['ук договор-сообщение'] && this.record['ук договор-сообщение'] > 0) {
          this.record['ук договор-сообщение'] = null;
        }
        if (to !== 4 && to !== 5 && this.record['организация-сообщение'] && this.record['организация-сообщение'] > 0) {
          this.record['организация-сообщение'] = null;
        }
      },
    },
  },
});
