const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.УчасткиПодключенияСети',
  description: 'Участки подключения',
  fields: [
    {
      field: 'длинап',
      text: 'Длина линии',
      type: 'Number',
    },
    {
      field: 'эксплуатационный коэффициент',
      text: 'Напряжение в линии',
      type: 'Number',
    },
    {
      field: 'процент участия',
      text: 'Удельное сопротивление',
      type: 'Number',
    },
    {
      field: 'диаметрп',
      text: 'Площадь сечения',
      type: 'Number',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'датнач',
      text: 'Дата начала',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Дата окончания',
      type: 'Date',
    },
    {
      field: 'вид прокладки',
      text: 'Тип число',
      type: 'Enum',
    },
    {
      field: '@видпрокладки',
      text: 'Тип',
      type: 'String',
      computed: true,
    },
  ],
};
export default accs;
