const acts: StackSelection = {
  objectName: 'ПочтовыйКлиент.НоваяПочта',
  description: 'Новая почта',
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
      width: '50px',
    },
    {
      field: 'номерпп',
      text: 'НомерПП',
      type: 'String',
    },
    {
      field: 'идентификатор',
      text: 'Идентификатор',
      type: 'String',
    },
    {
      field: 'получить',
      text: 'Получить',
      type: 'String',
    },
    {
      field: 'удалить',
      text: 'Удалить',
      type: 'String',
    },
    {
      field: 'размер',
      text: 'Размер',
      type: 'String',
    },
    {
      field: 'откого',
      text: 'ОтКого',
      type: 'String',
    },
    {
      field: 'кому',
      text: 'Кому',
      type: 'String',
    },
    {
      field: 'копия',
      text: 'Копия',
      type: 'String',
    },
    {
      field: 'скрытаякопия',
      text: 'СкрытаяКопия',
      type: 'String',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
      width: '200px',
    },
    {
      field: 'заголовок',
      text: 'Заголовок',
      type: 'String',
    },
    {
      field: 'написано',
      text: 'Написано',
      type: 'String',
    },
    {
      field: 'текст',
      text: 'Текст',
      type: 'String',
    },
  ],
};
export default acts;
