
import Vue from 'vue';
import { HttpModel } from '@/stackEngine';

export default Vue.extend({
  data() {
    return {
      isPassVisible: false,
      visible: false,
      item: {
        user: this.$store.getters.getLogin(),
        password: '',
        списокверсий: '',
        длявсех: '',
        группаклиентов: '',
        объектыобновления: '',
        модуль: '',
      } as any,
    };
  },
  methods: {
    async onSave() {
      this.$emit('close');
    },
    async onUpdate() {
      (await (new HttpModel('ОбновлениеВерсииНСИ')).executeMethod(
        'ВыложитьВерсию',
        [this.item],
        { async: true, jobName: 'Обновление версии на НСИ' },
      ));
      this.$emit('close');
    },
  },
});
