
import Vue from 'vue';
import datnachDatend from './$datNachdatEnd.vue';

export default Vue.extend({
  components: { datnachDatend },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    hideDate: { type: Boolean, default: false },
  },
  watch: {
    record: {
      immediate: true,
      deep: true,
      handler() {
        if (this.record.значение === 0) {
          this.record.знач2 = '';
          this.record.примечание = '';
        }
      },
    },
  },
});
