const acts: StackSelection = {
  objectName: 'ЛицевыеСчета.АктыНарушений',
  description: 'Акты нарушений',
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'DateMonth',
      align: 'right',
    },
    {
      field: 'акт-услуга>номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'объем',
      text: 'Объем',
      type: 'Number',
      align: 'right',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
      align: 'right',
    },
    {
      field: 'предписание',
      text: 'Предписание',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'окончания срок',
      text: 'Отменен',
      type: 'Date',
    },
  ],
};

export default acts;
