
import Vue from 'vue';
import recalcPeniDialog from './$dialogs/recalcPeniDialog.vue';
import HandCalcToolbar from './$toolbars/HandCalcToolbar.vue';
import peniLsFilter from '@/tasks/fl/views/$common/filters/peniLsFilter.vue';
import { ReCalcPeniModel } from './$classes/ReCalcPeniModel';
import handleHeaders from '@/tasks/fl/selections/accounts/ЛицевыеСчета.Пени_перерасчет';

export default Vue.extend({
  components: { recalcPeniDialog, HandCalcToolbar, peniLsFilter },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    isReadonly(): boolean {
      return this.$store.getters.isWorkMonthInClosedMonth();
    },
    closedMonthLs(): [Date, String] {
      return this.record['@вадмрежиме'] ? this.$stackDate.prevMonth(this.record.закрытыймесяц, 1) : this.record.закрытыймесяц;
    },
  },
  data() {
    return {
      dataModel: null as ReCalcPeniModel | null,
      headers: handleHeaders.fields as StackField[],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.dataModel = new ReCalcPeniModel([], { rowTemplate: this.headers, description: '' });
      this.dataModel.setOpenMonthLs(this.closedMonthLs.toString());
    },
  },
});
