const uploads: StackSelection = {
  objectName: 'ВыгрузкиПодкачки',
  description: '',
  fields: [
    {
      field: 'месяц',
      text: 'месяц',
      type: 'Date',
    },
    {
      field: 'лицевые',
      text: 'лицевые',
      type: 'String',
    },
    {
      field: 'типстрока',
      text: 'тип',
      type: 'String',
    },
    {
      field: 'типчисло',
      text: 'тип',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'месяц',
      type: 'Date',
    },
    {
      field: 'радиобокс',
      text: 'радиобокс',
      type: 'Number',
    },
    {
      field: 'датнач',
      text: 'датнач',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'даткнц',
      type: 'Date',
    },
    {
      field: 'льгота',
      text: 'льгота',
      type: 'Number',
    },
    {
      field: 'поставщик',
      text: 'поставщик',
      type: 'Number',
    },
    {
      field: 'меснач',
      text: 'меснач',
      type: 'Date',
    },
    {
      field: 'мескнц',
      text: 'мескнц',
      type: 'Date',
    },
    {
      field: 'формат',
      text: 'формат',
      type: 'String',
    },
    {
      field: 'иденторг',
      text: 'иденторг',
      type: 'String',
    },
    {
      field: 'естьинтеграция',
      text: 'естьИнтеграция',
      type: 'Number',
    },
    {
      field: 'типвыгрузкиосзн',
      text: 'типвыгрузкиосзн',
      type: 'Number',
    },
    {
      field: 'title',
      text: 'title',
      type: 'String',
    },
    {
      field: 'dialog',
      text: 'dialog',
      type: 'String',
    },
    {
      field: 'выгрузка',
      text: 'выгрузка',
      type: 'String',
    },
    {
      field: 'подкачка',
      text: 'подкачка',
      type: 'String',
    },
    {
      field: 'флаги',
      text: 'Флаги',
      type: 'Number',
    },
  ],
};
export default uploads;
