
import Vue from 'vue';

export default Vue.extend({
  props: {
    record: { type: Object, default: null },
  },
  data() {
    return {
      dateStart: null as Date | string | null,
      dateEnd: null as Date | string | null,
      clearID: null,
    };
  },
  created() {
    this.dateStart = this.$store.getters.getWorkMonth() as Date;
    this.dateEnd = this.$store.getters.getCurrentDate() as Date;
  },
  methods: {
    async onClear() {
      const obj = new this.$HttpModel('РеестрМногопроцессныхОпераций');
      this.clearID = (await obj.executeMethod(
        'Очистить',
        {
          ДатНач: this.dateStart,
          ДатКнц: this.dateEnd,
        },
        { async: true, jobName: 'Очистка реестра многопроцессных операций' },
      )).asyncId;

      this.$emit('clear', this.clearID);
      this.$emit('close');
    },
  },
});
