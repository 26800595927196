import { TableHandlers } from '@/stackEngine';
const acts: StackSelection = {
  objectName: 'АктыОбхода.Формирование',
  description: 'Формирование актов обхода',
  fields: [
    {
      field: 'номер',
      text: 'Номер ЛС',
      type: 'String',
      width: '100px',
    },
    {
      field: 'фио',
      text: 'Абонент',
      type: 'String',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'счет',
      text: 'Счет',
      type: 'Link',
    },
  ],
};
export default acts;
