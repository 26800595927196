
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    ownerID: { type: Number, required: true },
    dataObject: { type: Object, required: true },
    closedMonth: { type: [Date, String], required: true },
  },
  computed: {
    readonly(): boolean {
      if (typeof this.$stackRules.isClosedMonth(this.record.перерасчет) === 'string') {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      rulesThis: {
        isRequired: (value: number): string | true => {
          return (value !== undefined && value !== null && value !== 0) || 'Сумма не может быть равна 0';
        },
        isOpenMonth: (value: string): string | true => {
          return !value || Date.parse(value) <= Date.parse(this.closedMonth) || `Месяц должен быть меньше открытого`;
        },
      },
    };
  },
  methods: {
    labelFieldUKHandler(item: StackTableRow) {
      return '№' + item.номер + ' ' + item.тема + ' ' + item['организация-укдоговор>название'] + ' от ' + this.$stackDate.format(item.датнач as string, 'DD.MM.YYYY');
    },
    async saveRecordService(event: any) {
      if (event[0]) {
        this.record['номер услуги'] = event[0]['номер услуги'];
        this.record.название = event[0].название;
      }
      this.searchPost(this.record['номер услуги']);
    },
    saveRecordPost(event: any) {
      if (event[0]) {
        this.record.поставщикназвание = event[0].название;
      }
    },
    async searchPost(serviceID: number) {
      const data = await this.dataObject.executeMethod('поискПоставщика', { владелец: this.ownerID, услуга: serviceID });
      this.record.поставщик = data;
    },
    saveRecordDogUK(event: any) {
      if (event[0]) {
        this.record.договорномер = event[0].номер;
        this.record.укназвание = event[0]['организация-укдоговор>название'];
      }
    },
  },
});
