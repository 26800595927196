
import Vue from 'vue';
import datNachdatEnd from './$datNachdatEnd.vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    hideDate: { type: Boolean, default: false },
  },
  computed: {
    inp() {
      let arr = [0, 0, 0];
      if (this.record.примечание != null) {
        arr = this.record.примечание.split(';');
        return arr;
      } else { return arr; }
    },
  },
  methods: {
    onChange(znach: string, ind: number) {
      let arr = [0, 0, 0];
      arr = this.record.примечание.split(';');
      arr[ind] = Number(znach);
      this.record.примечание = [arr[0], arr[1], arr[2]].join(';');
    },
  },
  components: { datNachdatEnd },
});
