const serv: StackSelection = {
  recordRoute: 'references-services-servicesid-rates-ratesid',
  objectName: 'Услуги.Тарифы',
  description: 'Тарифы',
  fields: [
    {
      field: 'датнач',
      text: 'Действие с',
      type: 'Date',
    },
    {
      field: 'значение',
      text: 'Тариф',
      type: 'Money',
    },
    {
      field: 'сверх',
      text: 'Тариф сверх нормы',
      type: 'Money',
    },
    {
      field: 'полный тариф',
      text: 'Полный тариф',
      type: 'Money',
    },
    {
      field: 'тариф по счетчику',
      text: 'Тариф по счетчику',
      type: 'Money',
    },
    {
      field: 'тариф поставщика',
      text: 'Тариф поставщика',
      type: 'Money',
    },
    {
      field: 'тариф1',
      text: 'Тариф1',
      type: 'Money',
    },
    {
      field: 'тариф2',
      text: 'Тариф2',
      type: 'Money',
    },
    {
      field: 'тариф3',
      text: 'Тариф3',
      type: 'Money',
    },
    {
      field: 'тариф4',
      text: 'Тариф4',
      type: 'Money',
    },
    {
      field: 'тариф5',
      text: 'Тариф5',
      type: 'Money',
    },
    {
      field: 'тариф6',
      text: 'Тариф6',
      type: 'Money',
    },
  ],
};

export default serv;
