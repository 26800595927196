const constant: StackSelection = {
  recordRoute: 'consthistory',
  objectName: 'Константы.История',
  description: 'История констант',
  fields: [
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
    {
      field: 'дата',
      text: 'Действие с',
      type: 'Date',
    },
    {
      field: 'допзначение',
      text: 'Дополнительное значение',
      type: 'String',
    },
  ],
};

export default constant;
