
import Vue from 'vue';
import addressFilter from '@/tasks/fl/views/$common/filters/addressFilter.vue';
import numberTypeFilter from '@/tasks/fl/views/$common/filters/numberTypeFilter.vue';

export default Vue.extend({
  components: { addressFilter, numberTypeFilter },
  props: {
    tableAction: { type: Function, required: true }, // коллбек к таблице
  },
  data() {
    return {
      item: {
        нп: null,
        пструктура: null,
        улица: null,
        дом: null,
        кадастрномер: null,
        собственники: 1,
        квартира: null,
        комната: null,
        фионанимателя: null,
        номер: null,
        номерлс: null,
        стороннийномер: null,
        номерил: null,
        основвлад: null,
        типстроения: null,
        укдоговор: null,
        состояние: null,
      } as any,
      loading: false,
    };
  },
  methods: {
    async onJump() {
      const parameters = {} as any;
      let foundOne = false;
      for (const field in this.item) {
        if (this.item[field] && (field === 'нп' || field === 'пструктура' || field === 'улица' || field === 'дом' || field === 'квартира' || field === 'комната' || field === 'кадастрномер')) {
          parameters[field] = this.item[field];
          foundOne = true;
        }
      }
      if (!foundOne) {
        this.$toast('Переход возможен только при указании адреса');
        return;
      }
      this.loading = true;
      const params = { фильтр: Object.assign(parameters, {}, { развернутьПапки: 1, сКорня: 1, переход: true }) };
      try {
        const results = await new this.$HttpModel('ЛицевыеСчета').getRecords(params);
        if (results.length === 1) {
          const rec: StackTableRow = results[0];
          // @ts-ignore
          this.tableAction({ action: 'filter', payload: { row_id: rec.$номерЗаписи } });
          // this.pushRoute(rec);
        } else if (results.length > 0) {
          this.$toast('Найдено несколько адресов. Уточните поиск!');
        } else {
          this.$toast('Не найдено ни одного адреса.');
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
