const actsqu: StackSelection = {
  objectName: 'СозданиеКоррекцииСальдо',
  description: 'Коррекция сальдо',
  checkRow: row => {
    const result = actsqu.fields.filter(({ type, field }) => {
      if (type === 'Enum' || type === 'Link') {
        return row[field] === null || row[field] === -1;
      }
      return false;
    }).length;
    if (result) return 'Необходимо заполнить поле связи';
    return false;
  },
  fields: [
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
      width: '100px',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Enum',
      dataModel: 'ВедомостиКоррекцииСальдо.Тип',
      width: '100px',
    },
    {
      field: 'период',
      text: 'Период',
      type: 'DateMonth',
      width: '80px',
    },
    {
      field: 'услуга-коррекция',
      text: 'Услуга',
      type: 'Link',
      dataModel: 'Услуги',
      labelField: record => {
        return '№' + record['номер услуги'];
      },
      headers: 'наименование,номер услуги',
      noFolder: true,
      hier: true,
      width: '120px',
    },
    {
      field: 'поставщик-коррекция',
      text: 'Поставщик',
      type: 'Link',
      dataModel: 'Организации',
      labelField: 'название',
      headers: 'название,инн,кпп',
      noFolder: true,
      hier: true,
      width: '20%',
    },
    {
      field: 'укдоговор-коррекция',
      text: 'Договор УК',
      type: 'Link',
      dataModel: 'ДоговорыУКВыбор',
      labelField: record => {
        return '№' + record.номер + ' ' + record.тема + ' ' + record['организация-укдоговор>название'];
      },
      headers: 'номер,тема,датнач,даткнц,организация-укдоговор>название',
      hier: true,
      width: '20%',
    },
    {
      field: 'счет-коррекция',
      text: 'Счет-Коррекция',
      type: 'Link',
      dataModel: 'ЛицевыеСчета',
      labelField: record => {
        return '№' + record.номер + ' (' + record['счет-наниматель>фио'] + ')' + ' ' + record.адреслс;
      },
      headers: 'номер,счет-наниматель>фио,примечание',
      hier: true,
    },
  ],
};
export default actsqu;
