
import Vue from 'vue';

export default Vue.extend({
  computed: {
    tableSample(): any {
      const res = [] as any;
      for (let i = 0; i < 5; i++) {
        res.push({ название: `Строка таблицы ` + i, описание: `Описание ` + i });
      }
      return res;
    },
    elements(): any {
      return this.$store.getters.Element() ? this.$store.getters.Element().split(',') : null;
    },
    targetElement(): any {
      return this.elements ? document.querySelector(this.elements[0]) : null;
    },
    selectedElement(): any {
      return this.elements.length === 2 ? document.querySelector(this.elements[1]) : null;
    },
    HelpAction(): number|string {
      return this.$store.getters.HelpAction();
    },
    isTourEnable(): boolean {
      return this.$store.getters.isTourEnable();
    },
    targetTop(): number {
      // @ts-ignore
      return this.targetElement.getBoundingClientRect().bottom - this.height;
    },
    targetHeight(): number {
      // @ts-ignore
      return this.targetElement.getBoundingClientRect().top - this.height;
    },
  },
  data() {
    return {
      record: {
        input: '',
      } as StackTableRow,
      currentTarget: '' as any,
      // @ts-ignore
      height: document.querySelector('.v-app-bar').offsetHeight as number,
      buttons: ['btn-add', 'btn-search', 'btn-print-record', 'btn-filter-record', 'stack-table-btn-more', 'btn-calc-sum', 'btn-copy', 'btn-delete'] as string[],
    };
  },
  methods: {
    hoverElement() {
      this.currentTarget && this.currentTarget.classList.remove('selected-btn');
      if (this.buttons.includes(this.targetElement.dataset.cy)) {
        this.targetElement.classList.add('selected-btn');
        this.currentTarget = this.targetElement;
      }
    },
  },
  watch: {
    async HelpAction(to) {
      if (to === 'search' || to === 'add') {
        // @ts-ignore
        this.$refs.table.selectedItems = [];
      } else {
        // @ts-ignore
        this.$refs.table.selectedItems = [{}];
      }
      await this.$nextTick();
      this.hoverElement();
    },
  },
  mounted() {
    if (this.isTourEnable) {
      // @ts-ignore
      this.$parent.$children[0].firstStep();
    }
  },
});
