
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: 'нпп',
          text: 'Номер п/п',
          type: 'Number',
          readonly: true,
        },
        {
          field: 'значение',
          text: 'Наименование',
          type: 'String',
        },
      ],
      maxIndexNumber: 0,
    };
  },
  methods: {
    rowhandler(item: any) {
      // Если новая запись, то нпп не объявлен - и возьмём его из переменной, так как поле не доступно на редактирование
      item.нпп = item.нпп || +this.maxIndexNumber + 1;
      // значение - чтобы не летело как пустое
      item.значение = item.значение || '';
      return item;
    },
    changeTable(items: StackTableRow[]) {
      items.forEach((row: StackTableRow) => {
        const curIndexNumber = row.нпп ? +row.нпп : 0;
        this.maxIndexNumber = Math.max(+this.maxIndexNumber, curIndexNumber);
      });
    },
  },
});
