
import Vue from 'vue';
import { ReportsBuilder, scanable } from '@/stackEngine';
import ProblemPaysToolbar from './$paylists/ProblemPaysToolbar.vue';
import problemPayFilter from '@/tasks/fl/views/$common/filters/problemPayFilter.vue';
import FileTable from '@/tasks/common/views/$common/fileTable.vue';
import TransferDialog from './$paylists/TransferDialog.vue';

interface InfoItem {
  title: string;
  value: any;
}

export default Vue.extend({
  components: { problemPayFilter, ProblemPaysToolbar, FileTable, TransferDialog },
  mixins: [scanable],
  props: {
    paylistsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
    ownerID: { type: [Number, String], default: null },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      record: {} as StackTableRow,
      rulesThis: {
        dateMaxRules: (minDate: string, date: string): string | true => {
          const dateMax = minDate ? this.$stackDate.addYears(minDate, 1) : this.$stackDate.addYears(new Date(), 1);
          return (date && this.$stackDate.isAfter(new Date(dateMax), new Date(date))) || 'Дата пачки на год превышает открытый месяц';
        },
        dataMonthRules: (minDate: string, date: string): string | true => {
          const dateMax = minDate ? this.$stackDate.addMonths(minDate, 1) : this.$stackDate.addMonths(new Date(), 1);
          return this.$stackRules.dateIsGreaterEqualThan(this.$stackDate.format(date, 'YYYY-MM') + '-01', this.$stackDate.format(dateMax, 'YYYY-MM-DD'), 'Месяц пачки превышает расчетный месяц более чем на 1');
        },
        dateMinRules: (maxDate: string, date: string): string | true => {
          const dateMin = maxDate ? this.$stackDate.addMonths(maxDate, -2) : this.$stackDate.addMonths(new Date(), -2);
          return (date && this.$stackDate.isAfter(new Date(date), new Date(dateMin))) || 'Дата пачки на два месяца меньше открытого месяца';
        },
        paymentsReceiptsRules: (paymentsReceiptsId: number, ownerID: number): string | true => {
          return !paymentsReceiptsId || paymentsReceiptsId < 0 || ownerID > 0 || 'Редактирование платежной ведомости доступно только из выписки';
        },
        changeOffset: (value: boolean, org: number): string | true => {
          return !value || (org && +org > 0) || 'Не заполнен "Источник платежа"';
        },
      },
      disabledOffset: false,
      disabledIst: false,
      isDialogEnable: false,
    };
  },
  computed: {
    boxInfo(): InfoItem[] {
      if (this.$options.filters) {
        return [
          // TODO замапить фильтры
          { title: 'Всего платежей', value: this.$options.filters.int(this.record.кол_во) },
          { title: 'Сумма ₽', value: this.$options.filters.money(this.record.сумма) },
          { title: 'В т.ч. пени ₽', value: this.$options.filters.money(this.record.суммапени) },
          { title: 'Проблемных', value: this.$options.filters.int(this.record.проблемных) },
          { title: 'Сумма проблемных ₽', value: this.$options.filters.money(this.record.сумма3) },
          { title: 'Дата распределения', value: this.$options.filters.date(this.record['дата распределения']) },
        ];
      }
      return [];
    },
    title(): string {
      return `Платежная ведомость № ${this.record.номер} от ${this.$stackDate.format(this.record.дата, 'DD.MM.YYYYг.')}`;
    },
    openMonth(): Date {
      return this.$store.getters.getOpenMonth();
    },
    isRasprTabVisible(): boolean {
      return this.$store.getters.getCurrentTask() === 'rsp';
    },
    notInReceipt(): boolean {
      return !this.ownerID || this.ownerID < 0;
    },
    paymentOutReceipt(): boolean {
      return !!this.record['платеж-выписка'] && this.record['платеж-выписка'] > 0 && this.notInReceipt;
    },
  },
  methods: {
    comboboxHandler(item: any) {
      return item['р/счет'] + ' в ' + item['банк-р/с>название банка'] + ', ' + item['банк-р/с>адрес банка'];
    },
    paymentsReceiptsHandler(item: any) {
      return '№ ' + item.номер + ' от ' + this.$stackDate.format(item.дата, 'DD.MM.YYYY');
    },
    onClickSendInPayList(to: any) {
      // @ts-ignore
      this.$refs.promblemPayTable.fetchData();
      if (+to >= 0) {
        // @ts-ignore
        this.$refs.payTable.fetchData();
      }
    },
    onPrintPaylist(report: string) {
      switch (report) {
        case 'paylist':
          new ReportsBuilder('Платежная ведомость').executeReport2('Платежная ведомость', { номерЗаписи: this.record.$номерЗаписи }, { title: `Печать платежной ведомости` });
          break;
        case 'search':
          new ReportsBuilder('ЛицевыеСчета').executeReport2(
            'Платежная ведомость - Поиск суммы платежа',
            { Ведомость: this.record.$номерЗаписи },
            { title: `Печать платежной ведомости` },
          );
          break;
      }
    },
    async changeIst(items: StackTableRow[]) {
      if (!this.readonly) {
        const res = await new this.$HttpModel('ПлатежныеВедомости').executeMethod('проверкаБлокировокПолей', {
          номерЗаписи: this.paylistsid,
          источник: items[0] ? items[0].$номерЗаписи : -1,
        });
        if (res && Object.keys(res).length) {
          this.disabledOffset = !!res['ВидСчета'];
          if (this.disabledOffset) {
            this.record['видсчета'] = 0;
          }
          this.disabledIst = !!res['Источник-Платежи'];
        } else {
          this.disabledOffset = false;
          this.disabledIst = false;
        }
      }
    },
    async calcVed() {
      const answer = await this.$yesno('Вы действительно хотите рассчитать все лицевые ведомости?');
      if (answer) {
        new this.$HttpModel('ПлатежныеВедомости').executeMethod(
          'рассчитатьВедомость',
          { ведомость: this.paylistsid },
          { async: true, jobName: `Расчет ведомости ${this.record.номер}`, type: 'func' },
        );
      }
    },
  },
});
