
import Vue from 'vue';
import paylist from '../../../paylists/_paylistsid.vue';

export default Vue.extend({
  components: { paylist },
  props: {
    paylistsid: { type: [Number, String], default: undefined },
    paymentsreceiptsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
  },
});
