
import Vue from 'vue';
import heatcalcTab from './$components/heatcalcTab.vue';
import dayaverageTab from './$components/dayaverageTab.vue';
import paramTable from '@/tasks/common/views/$common/paramTable.vue';
import LogsTab from '@/tasks/fl/views/accounts/$accountsid/$otherstabs/LogsTab.vue';

export default Vue.extend({
  components: { paramTable, heatcalcTab, dayaverageTab, LogsTab },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    heatParams(): boolean {
      return !this.$store.getters['flStore/isComplex']('ЭНЕРГО') && !this.$store.getters['flStore/isComplex']('ТКО') && this.$store.getters.getCurrentTask() === 'ul';
    },
  },
});
