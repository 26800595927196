
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    otdel: { type: Number, default: 2 },
  },
  computed: {
    isk(): boolean {
      return this.dopfields.indexOf('дата уведомления') !== -1;
    },
    delivery(): boolean {
      return this.dopfields.indexOf('доставка') !== -1;
    },
    nomerUv(): boolean {
      return this.dopfields.indexOf('номеруведомления') !== -1;
    },
    pokSchet(): boolean {
      return this.dopfields.indexOf('показаниясчетчиков') !== -1;
    },
    executor(): boolean {
      return this.dopfields.indexOf('организации') !== -1;
    },
  },
  data() {
    return {
      dopfields: '',
      filter: {
        долгот: 0,
        долгдо: 0,
        'дата уведомления': this.record.датауведомления,
        ТекущаяФаза: this.record.текущаяфаза,
        ТекущееСостояние: this.record.текущеесостояние,
        статус: this.record.состояние,
        доставка: this.record.доставка,
        организации: this.record.организации,
        дсостояние: this.record.датнач,
      } as any,
      arrMoving: [] as any,
      items: [] as any[],
    };
  },
  async created() {
    // Сформируем статичные массивы перехода и доставки
    const resultsMoving = await new this.$HttpModel('РеестрПерехода_Переходы').getRecords({ ТекущаяФаза: this.record.ТекущаяФаза, ТекущееСостояние: this.record.ТекущееСостояние });
    for (const key in resultsMoving) {
      this.arrMoving.push(resultsMoving[key].значение);
    }
    this.Change_sost();
  },
  methods: {
    async Change_date() {
      if (this.record.датнач < this.record.датнач_до) {
        this.$toast('Невозможно установить дату перехода ранее даты предыдущей фазы!');
        this.record.датнач = this.record.датнач_до;
      }
    },
    async Change_delivery() {
      this.items = await new this.$HttpModel('РеестрПерехода').executeMethod('ПроверкаДоставки', { Параметры: this.record, отдел: this.otdel });
      if (this.items !== this.record.доставка) {
        this.$toast('Выбранный способ доставки не может быть установлен на дело!');
        this.record.доставка = this.items;
      }
    },
    async Change_sost() {
      this.filter.статус = this.record.состояние;
      const parameters = { Параметры: this.filter, 'Подготовка данных': true, отдел: this.otdel };
      this.items = await new this.$HttpModel('РеестрПерехода').executeMethod('ПроверкаПолей', parameters);
      this.dopfields = '';
      for (const key in this.items) {
        this.dopfields += this.dopfields.indexOf(this.items[key].columnName.toLowerCase()) === -1 ? ',' + this.items[key].columnName.toLowerCase() : '';
      }
      this.Change_date();
    },
  },
});
