
import Vue from 'vue';
import { TableHandlers } from '@/stackEngine';
import individualRate from './$dialogs/_individualRate.vue';
import normaUsl from './$dialogs/_norma.vue';
import tarifsUsl from './_servicesid/rates/_ratesid.vue';

export default Vue.extend({
  components: { normaUsl, individualRate, tarifsUsl },
  props: {
    servicesid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      dialog: false,
    };
  },
  computed: {
    title(): string {
      return `Услуга ` + this.record['номер услуги'];
    },
  },
});
