import { TableHandlers } from '@/stackEngine';
const grcount: StackSelection = {
  objectName: 'ГрупповыеСчетчики.Показания',
  description: '',
  recordRoute: '',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.тип === 2) {
      TableHandlers.trColor(row, 'grey');
    } else {
      if (row['@тип ввода'] === 'КП') {
        TableHandlers.trColor(row, 'info');
      }
    }
    return row;
  },
  fields: [
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: 'заводскойномер',
      text: 'Заводской номер',
      type: 'String',
    },
    {
      field: 'расчетный месяц',
      text: 'За',
      type: 'DateMonth',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'показание',
      text: 'Показание',
      type: 'NumberZero',
    },
    {
      field: 'расход',
      text: 'Расход',
      type: 'NumberZero',
    },
    {
      field: 'итоговый расход',
      text: 'Итоговый\n расход',
      type: 'NumberZero',
    },
    {
      field: 'дополнительный расход',
      text: 'Доп. расход',
      type: 'NumberZero',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'тариф',
      text: 'Зона суток',
      type: 'String',
    },
    {
      field: '@зона',
      text: 'Зона\nсуток',
      type: 'Enum',
      width: '100px',
      computed: true,
    },
    {
      field: 'типввода',
      text: 'Тип ввода',
      type: 'Number',
    },
    {
      field: '@тип ввода',
      text: 'Тип\nввода',
      type: 'Enum',
      width: '100px',
      computed: true,
    },
    {
      field: 'тип счетчика',
      text: 'Тип счетчика',
      type: 'String',
    },
    {
      field: 'объект-показания',
      text: 'Объект-Показания',
      type: 'Number',
    },
    {
      field: 'тарифность',
      text: 'Тарифность',
      type: 'Number',
    },
    {
      field: 'разрядность',
      text: 'Разрядность',
      type: 'Number',
    },
    {
      field: 'дробная разрядность',
      text: 'Дробная разрядность',
      type: 'Number',
    },
    {
      field: 'коэффициент трансформации',
      text: 'Коэффициент трансформации',
      type: 'Number',
    },
    {
      field: 'типсчетчика',
      text: 'ТипСчетчика',
      type: 'Number',
      computed: true,
    },
  ],
};
export default grcount;
