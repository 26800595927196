
import Vue from 'vue';
import hotKeysRow from './hotKeysRow.vue';

export default Vue.extend({
  components: { hotKeysRow },
  props: {
    value: { type: Boolean, default: false },
  },
  data() {
    return {
      rows: [
        { name: 'Быстрый поиск лицевого счета', imgs: ['$vuetify.icons.search'] },
        { name: 'Быстрый ввод показаний ИПУ по ЛС', imgs: ['$vuetify.icons.ipu_counter'] },
        { name: 'Печать справок, экрана', imgs: ['$vuetify.icons.printer'] },
        { name: 'Возврат на предыдущий уровень в таблице', imgs: ['$vuetify.icons.prev_month'], desc: '<Esc>', divider: 'или' },
        { name: 'Открыть запись на редактирование (просмотр)', desc: '<F3>' },
        { name: 'Открыть в новой вкладке', imgs: ['$vuetify.icons.open_in_new_tab'] },
        { name: 'Создать новую запись', imgs: ['$vuetify.icons.add'], divider: 'или', desc: '<Insert>' },
        { name: 'Сохранить введенную информацию в записи', imgs: ['$vuetify.icons.plus_circle'] },
        { name: 'Создать новую группу (папку)', imgs: ['$vuetify.icons.new_folder'] },
        { name: 'Редактировать группу (папку) или запись', imgs: ['$vuetify.icons.edit'] },
        { name: 'Копировать группу (папку) или запись', imgs: ['$vuetify.icons.copy'], desc: '<Shift>', divider: 'или', sDivivder: '+', sImgs: ['$vuetify.icons.edit'] },
        { name: 'Удалить группу (папку) или запись', imgs: ['$vuetify.icons.delete'] },
        { name: 'Следующая страница в таблице', desc: '<Ctrl + СтрелкаВправо>' },
        { name: 'Предыдущая страница в таблице ', desc: '<Ctrl + СтрелкаВлево>' },
        { name: 'Закрыть запись', imgs: ['$vuetify.icons.close'] },
        { name: 'Вызвать окно с фильтрами', imgs: ['$vuetify.icons.filter_list'], divider: 'или', desc: '<Ctrl + Q>' },
        { name: 'Поиск', imgs: ['$vuetify.icons.search'], divider: 'или', desc: '<Ctrl + F>' },
        { name: 'Выбор информации из справочников', imgs: ['$vuetify.icons.link_field_icon'] },
        { name: 'Выделить запись/записи', imgs: ['$vuetify.icons.checkboxOn'] },
        { name: 'Перемещение выделенных записей в выбранную папку', imgs: ['$vuetify.icons.table_move_record'] },
        { name: 'Подсчет количества и суммирование числовых полей выделенных записей', imgs: ['$vuetify.icons.sum'] },
        { name: 'Развернуть список до наименьших записей', imgs: ['$vuetify.icons.expand_folders'] },
        { name: 'Выбор даты', imgs: ['$vuetify.icons.event'] },
        { name: 'Дополнительные действия', imgs: ['$vuetify.icons.menu_vert'] },
        { name: 'Переключение рабочего месяца', workMonthChanger: true },
        { name: 'Выбор рабочего месяца', desc: 'Щелчок «мышкой» по рабочему месяцу' },
        { name: 'Переключение между пунктами меню', desc: '<Alt + 1>, <Alt + 2>,…. <Alt + 9>' },
        { name: 'Очистить дату в поле ввода', desc: '<Ctrl + Y>' },
        { name: 'Установка даты «бесконечности» (09.05.2045)', desc: '<Ctrl + Z>' },
        { name: 'Установка текущего рабочего месяца', desc: '<Ctrl + M>' },
        { name: 'Передвижение по полям выбранного окна', desc: '<Tab>' },
        { name: 'Сохранить введенный платеж и открыть новый', desc: '<Pagedown>', sDivivder: 'или', sImgs: ['$vuetify.icons.content_save', '$vuetify.icons.add'] },
        { name: 'Перезагрузка программных файлов и справочников', desc: '<Alt - F12>', hideBottomLine: true },
      ],
    };
  },
});
