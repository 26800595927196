
import Vue from 'vue';
import distribution from './$modal/distributionDialog.vue';

export default Vue.extend({
  components: { distribution },
  props: {
    problempaysid: { type: [Number, String], default: undefined },
    paylistsid: { type: [Number, String], default: undefined },
    paymentsreceipts: { type: [Number, String], default: undefined }, // id выписки из банка, если открываем с ее диалога
  },
  data() {
    return {
      record: {} as StackTableRow,
      enableMonthLSRules: false as boolean,
      rulesThis: {
        monthLSRules: (value: boolean, enableMonthLSRules: boolean): string | true => {
          return !enableMonthLSRules || !value || 'Расчетный месяц лицевого счета закрыт. Необходимо выбрать другой ЛС';
        },
        dataMonthRules: (minDate: string, date: string): string | true => {
          const dateMax = minDate ? this.$stackDate.addMonths(minDate, 1) : this.$stackDate.addMonths(new Date(), 1);
          return this.$stackRules.dateIsGreaterEqualThan(this.$stackDate.format(date, 'YYYY-MM') + '-01', this.$stackDate.format(dateMax, 'YYYY-MM-DD'), 'Месяц платежа превышает расчетный месяц более чем на 1');
        },
      },
      korrLSAddress: '' as string,
      korrLSComment: '' as string,
      korrLSInitValue: -1 as number,
      korrLSIsClose: false as boolean,
      korrLSIsFirstInit: true as boolean,
      disItemsFlags: [] as boolean[],
    };
  },
  computed: {
    problemPayInVed(): boolean {
      const problemPayList = this.record['проблемный платеж-список'] as number;
      return +problemPayList > 0;
    },
  },
  methods: {
    onInitKorrLS(event: number[]) {
      const objectEvent = (event && event[0] ? event[0] : {}) as StackTableRow;
      if (objectEvent && this.record) {
        if (this.record.расчмесяц) {
          const monthKorrLS = (objectEvent.закрытыймесяц ? objectEvent.закрытыймесяц : '') as string;
          this.korrLSIsClose = Date.parse(monthKorrLS) >= Date.parse(this.record.расчмесяц.toString());
        }
        const primKorrLS = (objectEvent.примечание ? objectEvent.примечание : '') as string;
        this.korrLSComment = primKorrLS;

        const addrKorrLS = (((objectEvent.адреслс ? objectEvent.адреслс : '') as string) +
          ' (' +
          (objectEvent['счет-наниматель>фио'] ? objectEvent['счет-наниматель>фио'] : '') +
          ')') as string;
        this.korrLSAddress = addrKorrLS;
        // включаем правило MonthLSRules только после того, как изменилось поле "Лицевой корректный"
        const LSKorr = (this.record['счет-коррекция'] ? this.record['счет-коррекция'] : -1) as number;
        if (this.korrLSIsFirstInit) {
          this.korrLSIsFirstInit = false;
          this.korrLSInitValue = +LSKorr;
          this.enableMonthLSRules = false;
        } else if (this.korrLSInitValue === +LSKorr || +LSKorr <= 0) {
          this.enableMonthLSRules = false;
        } else {
          this.enableMonthLSRules = true;
        }
        this.disItemsFlags[6] = this.korrLSIsClose;
      }
    },
  },
  watch: {
    record: {
      deep: true,
      handler(to) {
        const error = typeof this.rulesThis.dataMonthRules(to.расчмесяц, to.дата) === 'string';
        this.disItemsFlags[2] = error;
        const mask = 1 << 2;
        to.флагошибок = error ? to.флагошибок | mask : to.флагошибок & ~mask;
      },
    },
  },
});
