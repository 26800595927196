
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    tableData(): StackTableRow[] {
      return this.record && this.record.значение ? JSON.parse(this.record.значение) : [];
    },
  },
  data() {
    return {
      comboTypeC: 0,
      comboSyst: 0,
      comboInput: 0,
      comboTypeP: 0,
      comboService: 0,
      headers: [
        {
          field: '@Плот00',
          text: 'Вид трубы',
          type: 'String',
          readonly: true,
        },
        {
          field: '@Плот01',
          text: '38',
          type: 'Number',
        },
        {
          field: '@Плот02',
          text: '48',
          type: 'Number',
        },
        {
          field: '@Плот03',
          text: '57',
          type: 'Number',
        },
        {
          field: '@Плот04',
          text: '76',
          type: 'Number',
        },
        {
          field: '@Плот05',
          text: '89',
          type: 'Number',
        },
        {
          field: '@Плот06',
          text: '108',
          type: 'Number',
        },
        {
          field: '@Плот07',
          text: '133',
          type: 'Number',
        },
        {
          field: '@Плот08',
          text: '159',
          type: 'Number',
        },
        {
          field: '@Плот09',
          text: '219',
          type: 'Number',
        },
        {
          field: '@Плот10',
          text: '273',
          type: 'Number',
        },
        {
          field: '@Плот11',
          text: '325',
          type: 'Number',
        },
        {
          field: '@Плот12',
          text: '377',
          type: 'Number',
        },
        {
          field: '@Плот13',
          text: '426',
          type: 'Number',
        },
        {
          field: '@Плот14',
          text: '476',
          type: 'Number',
        },
        {
          field: '@Плот15',
          text: '529',
          type: 'Number',
        },
        {
          field: '@Плот16',
          text: '630',
          type: 'Number',
        },
        {
          field: '@Плот17',
          text: '720',
          type: 'Number',
        },
      ] as StackField[],
    };
  },
  methods: {
    async changeData() {
      const params = {
        дата: this.record.дата,
        услугавыбор: this.comboService,
        системавыбор: this.comboSyst,
        датавыбор: this.comboInput,
        котельнаявыбор: this.comboTypeC,
        прокладкавыбор: this.comboTypeP,
      };
      const newRecord = await new this.$HttpModel('КонстантыНастроек.ТаблицаКонстанты_НОРМЫПОТОКА').getRecords(params);
      const dataString = newRecord[0] && newRecord[0].значение ? newRecord[0].значение : '';
      const name = newRecord[0] && newRecord[0].описание ? newRecord[0].описание : '';
      this.$set(this.record, 'значение', dataString);
      this.$set(this.record, 'допимя', name);
      // @ts-ignore
      this.$refs.dialog.saveState(this.record);
    },
    changeTable(items: StackTableRow[]) {
      // облегчаем объект (убираем лишние данные вроде $data и тп)
      const arrayTemp = [];
      for (const item of items) {
        let objTemp = {};
        for (const ind in item) {
          if (ind.indexOf('$') < 0) {
            objTemp = Object.assign({}, objTemp, { [ind]: item[ind] });
          }
        }
        arrayTemp.push(objTemp);
      }
      this.$set(this.record, 'значение', JSON.stringify(arrayTemp));
    },
  },
});
