const work: StackSelection = {
  objectName: 'Аккаунты',
  description: 'Аккаунты',
  fields: [
    {
      field: 'датасоздания',
      text: 'Дата создания',
      type: 'Date',
    },
    {
      field: 'датаобновления',
      text: 'Дата обновления',
      type: 'Date',
    },
    {
      field: 'логин',
      text: 'Аккаунт',
      type: 'String',
    },
    {
      field: 'e-mail',
      text: 'E-Mail',
      type: 'String',
    },
    {
      field: 'телефон',
      text: 'Телефон',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: '@стрсостояние',
      text: 'Состояние',
      type: 'String',
      computed: true,
    },
    {
      field: 'доступ',
      text: 'пароль',
      type: 'String',
    },
    {
      field: '@синхронизирован',
      text: 'Синхронизация',
      type: 'String',
      computed: true,
    },
    {
      field: '@колволс',
      text: 'Лицевых',
      type: 'NumberZero',
      computed: true,
    },
    {
      field: '@колводог',
      text: 'Договоров',
      type: 'NumberZero',
      computed: true,
    },
  ],
};

export default work;
