
import Vue from 'vue';
import datnachDatend from './$datNachdatEnd.vue';

export default Vue.extend({
  components: { datnachDatend },
  model: {
    prop: 'record',
  },
  data() {
    return {
      rules: {
        region: (value: number): string | true => {
          return (value > 0 && value < 8) || `Параметр может принимать числовые значения от 1 до 7`;
        },
      },
    };
  },
  props: {
    record: { type: Object, required: true },
  },
});
