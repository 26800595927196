const patterns: StackSelection = {
  objectName: 'Организации.Шаблоны организаций',
  description: 'Шаблоны',
  fields: [
    {
      field: 'шаблон>код',
      text: 'Код',
      type: 'Number',
    },
    {
      field: 'услуга',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'шаблон>содержание',
      text: 'Содержание',
      type: 'String',
    },
    {
      field: 'шаблон>детализация',
      text: 'Детализация',
      type: 'String',
    },
    {
      field: 'шаблон',
      text: 'шаблон',
      type: 'Link',
    },
    {
      field: 'получатель',
      text: 'получатель',
      type: 'Link',
    },
  ],
};
export default patterns;
