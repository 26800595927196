
import Vue from 'vue';

export default Vue.extend({
  name: 'MassPlombUpd',
  props: {
    maxDatePlombSel: { type: String, default: '1990-01-01T00:00:00' },
  },
  data() {
    return {
      сотрудник: -1,
      дата: this.$store.getters.getCurrentDate(),
      первыйНомер: 0,
      последнийНомер: 0,
      rulesThis: {
        dateIsGreaterThanOrEqual: (date1: string, date2: string, mess: string): string | true => {
          return !date1 || !date2 || Date.parse(date1) >= Date.parse(date2) || mess;
        },
        checkNumber: (value1: number, value2: number): string | true => {
          if (value1 && value1 % 1 === 0) {
            if (value2 && value2 % 1 === 0 && value1 <= value2) {
              return true;
            } else {
              return 'Первый номер должен быть не меньше последнего';
            }
          } else {
            return 'Введите целое число больше 0';
          }
        },
      },
    };
  },
});
