
import Vue from 'vue';

export default Vue.extend({
  props: {
    dataModel: { type: Object, required: true },
    fund: { type: String, default: null },
    accounts: { type: String, default: null },
    month: { type: [String, Date], default: null },
    blockM: { type: [String, Date], default: null },
    monthLic: { type: [String, Date], default: null },
    operation: { type: [String], required: true },
    fundString: { type: String, default: null },
  },
  data() {
    return {
      jobID: null as null | number,
      isDone: false, // флаг что закрытие месяца закончено
    };
  },
  computed: {
    title(): string {
      return `ВНИМАНИЕ!  Будет производиться ` + this.operation + ` по фонду ` + this.fundString + ` ` + this.$stackDate.format(this.monthOperation, 'MM.YYYY');
    },
    titleOperation(): string {
      return this.operation + ` по фонду ` + this.fundString + ` ` + this.$stackDate.format(this.monthOperation, 'MM.YYYY');
    },
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
    monthOperation(): Date | String {
      return this.operation.indexOf('Открытие месяца', -1) >= 0 ? this.month : this.blockM;
    },
  },
  methods: {
    async selection() {
      const params = {
        фонд: this.fund,
        месяц: this.monthOperation,
        лицевые: this.accounts,
        событие: this.operation,
      };
      this.jobID = (await this.dataModel.executeMethod('выполнитьОперацию', params, { async: true })).asyncId;
      this.$store.commit('MSG_ADD', { title: this.titleOperation, asyncId: this.jobID });
      this.$emit('run');
    },
    cancel() {
      this.$emit('reset');
    },
    finishJob(res: string) {
      this.$store.commit('MSG_DELETE', this.jobID);
      this.jobID = null;
      this.$emit('end', res);
    },
  },
  watch: {
    isComplete(to) {
      this.finishJob('success');
      this.isDone = true;
    },
    isError(to) {
      if (to) {
        this.$toast(this.resultJob.status, { color: 'error' });
        this.finishJob('error');
      }
    },
  },
});
