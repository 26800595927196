
import Vue from 'vue';
import history from './history.vue';

export default Vue.extend({
  components: { history },
  props: {
    dataModel: { type: Object, required: true },
    disabled: { type: Boolean, required: true },
    isClose: { type: Boolean, required: true },
    selectedFund: { type: String, default: '' },
    monthSaved: { type: [Date, String], default: null },
  },
  data() {
    return {
      фонд: '',
      лицевые: '',
      фондСтрокой: '',
      message: '',
      isDialog: false,
      isPart: false,
      enableAdmMode: false,
      monthLic: null as null | string,
      month: this.monthSaved ? this.monthSaved : this.isClose ? this.$store.getters.getOpenMonth() : this.$store.getters.getCloseMonth(),
      isArchiveOpen: false,
      justCloseAdmMode: false,
    };
  },
  computed: {
    title(): string {
      return this.isClose ? 'Месяц для операции' : 'Выберите адресный фонд';
    },
    styleTable(): string {
      return this.disabled ? 'pointer-events: none;' : '';
    },
  },
  created() {
    this.checkConst();
  },
  methods: {
    async checkConst() {
      const result = await this.dataModel.executeMethod('проверкаКонстантыЗакрытияПоЧастям');
      this.isPart = !!result.почастям;
      this.enableAdmMode = !!result.АдмРежимДоступен;
      if (!this.isPart) {
        this.emitFund();
      } else {
        this.checkFund();
      }
    },
    btnSelect() {
      this.$emit('click', {
        фонд: this.фонд,
        лицевые: this.лицевые,
        месяцЛицевого: this.monthLic ? this.monthLic : this.month,
        месяцОперации: this.month,
        сообщениеОшибки: this.message,
        фондСтрокой: this.фондСтрокой,
        enableAdmMode: this.enableAdmMode,
        justCloseAdmMode: this.justCloseAdmMode,
      });
    },
    // выбранный фонд
    selectFund(arrayItems: StackTableRow[]) {
      // Если заблокирован элемент, то никак не реагируем на событие
      if (this.disabled && !this.isClose) {
        return;
      }
      if (this.selectedFund) {
        const masFund = this.selectedFund.split(',');
        masFund.forEach((item) => {
          const indArray = arrayItems.findIndex((fundItem: StackTableRow) => +item === fundItem.$номерЗаписи);
          if (indArray >= 0) {
            arrayItems[indArray].$selected = true;
          }
        });
      }
      // не проверяем на ошибки - если сама таблица заброкирована
      if (this.disabled) {
        return;
      }
      this.фонд = '';
      let errorAddress = '';
      let errorMonth = '';
      this.фондСтрокой = '';
      this.justCloseAdmMode = false;

      if (arrayItems.length === 0) {
        return;
      }
      // извлечем из первой записи весь ли выбранный фонд в административном режиме
      const fundSyncAdmMode = arrayItems[0].вадмрежиме;
      // Если все не выделены записи - нет смысла делать проверку на административный режим
      let allSelected = true;
      const allAdmMode = arrayItems.every((item: StackTableRow) => {
        if (!item.$selected) {
          allSelected = false;
        }
        return item.вадмрежиме === fundSyncAdmMode;
      });
      if (!allAdmMode && allSelected) {
        this.$toast('Месяц в разных административных режимах. Выбрать общий фонд нельзя', { color: 'error' });
      } else {
        for (const ind in arrayItems) {
          const strDate = (arrayItems[ind].закрыт ? arrayItems[ind].закрыт : '') as string;
          const arrDate = strDate.split('.');
          let currentMonth = '';
          if (arrDate.length === 2) {
            currentMonth = this.$stackDate.format(new Date(+arrDate[1].slice(0, 4), +arrDate[0] - 1, 1), 'ISO');
            this.monthLic = arrayItems[ind].вадмрежиме ? this.$stackDate.addMonths(currentMonth, -1) : currentMonth;
          }
          if (
            !this.isClose ||
            !currentMonth ||
            Date.parse(this.month) > Date.parse(currentMonth) ||
            (this.isClose && this.enableAdmMode && Date.parse(this.month) === Date.parse(currentMonth) && arrayItems[ind].вадмрежиме)
          ) {
            // если доступен административный режим и месяца равны - то возводим флаг,что можно закрыть административный режим
            if (this.isClose && this.enableAdmMode && Date.parse(this.month) === Date.parse(currentMonth) && arrayItems[ind].вадмрежиме) {
              this.justCloseAdmMode = true;
            }
            if (this.фонд.length > 0) {
              this.фонд += ',';
              this.фондСтрокой += ',';
            }
            this.фонд += arrayItems[ind].$номерЗаписи;
            this.фондСтрокой += arrayItems[ind].адрес;
          } else {
            errorMonth = arrDate[1];
            errorAddress += arrayItems[ind].наименование + '; ';
          }
        }
        if (errorAddress.length > 0) {
          if (errorMonth.indexOf('частично') > 0) {
            this.$toast('Месяц частично закрыт. Выбрать общий фонд нельзя', { color: 'error' });
          } else {
            this.$toast('По адресам ' + errorAddress + ' месяц уже закрыт', { color: 'error' });
          }
          this.фонд = '';
        } else {
          this.emitFund();
        }
      }
    },
    async emitFund() {
      const data = await this.dataModel.executeMethod('получитьФонд', { фонд: this.фонд });
      // TODO для обратной совместимости с новым и старым маззлом
      this.лицевые = data && data.ЛИЦЕВЫЕ ? data.ЛИЦЕВЫЕ : data.лицевые ? data.лицевые : ''; // для печати квитанций и расчета
      this.фонд = data && data.ПАПКИ ? data.ПАПКИ : data.папки ? data.папки : '';
    },
    async checkFund() {
      this.message = await new this.$HttpModel('ЗакрытиеМесяца.Реестр').executeMethod('ПроверкаКорректности');
    },
  },
});
