import { render, staticRenderFns } from "./_statementabonentsid.vue?vue&type=template&id=13715822&"
import script from "./_statementabonentsid.vue?vue&type=script&lang=ts&"
export * from "./_statementabonentsid.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports