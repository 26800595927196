
import Vue from 'vue';

export default Vue.extend({
  name: 'OrganizationsFilter',
  model: { prop: 'params', event: 'input' },
  props: {
    params: { type: Object, required: true },
    type: { type: String, default: 'link' },
    label: { type: String, default: undefined },
    dataModel: { type: String, default: 'Организации' },
    orgLabel: { type: String, default: 'По организации' },
    namefield: { type: String, default: 'организация' },
    disabled: { type: Boolean, default: false },
  },
});
