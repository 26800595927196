const orgs: StackSelection = {
  objectName: 'Организации.Представители',
  description: '',
  fields: [
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'телефон',
      text: 'Телефон',
      type: 'String',
    },
    {
      field: 'основание',
      text: 'Основание',
      type: 'String',
    },
    {
      field: 'email',
      text: 'email',
      type: 'String',
    },
    {
      field: 'признаки',
      text: 'Признаки',
      type: 'Number',
    },
    {
      field: 'подписьюрист',
      text: 'подписьюрист',
      type: 'Number',
    },
    {
      field: 'должность',
      text: 'Должность',
      type: 'String',
    },
    {
      field: 'допинформация',
      text: 'ДопИнформация',
      type: 'String',
    },
    {
      field: 'кодподразделения',
      text: 'КодПодразделения',
      type: 'String',
    },
    {
      field: 'доверенность номер',
      text: 'Доверенность номер',
      type: 'String',
    },
    {
      field: 'доверенность дата',
      text: 'Доверенность дата',
      type: 'Date',
    },
    {
      field: 'доверенность срок',
      text: 'Доверенность срок',
      type: 'Date',
    },
    {
      field: 'доверенность кем выдана',
      text: 'Доверенность кем выдана',
      type: 'String',
    },
    {
      field: 'кодподразделения',
      text: 'Код подразделения',
      type: 'String',
    },
    {
      field: 'типподписи',
      text: 'Тип подписи',
      type: 'String',
    },
    {
      field: 'флагиподписи',
      text: 'Подписывает',
      type: 'String',
    },
    {
      field: 'логин',
      text: 'Логин',
      type: 'String',
    },
    {
      field: 'представитель-организация',
      text: 'Предствитель-Организация',
      type: 'Number',
    },
    {
      field: 'рфио',
      text: 'РФИО',
      type: 'String',
    },
    {
      field: 'дфио',
      text: 'ДФИО',
      type: 'String',
    },

  ],
};

export default orgs;
