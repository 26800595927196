
import Vue from 'vue';

export default Vue.extend({
  props: {
    tableAction: { type: Function, required: true }, // коллбек к таблице
    dataObject: { type: [Object, Array], required: true }, // Array парсер
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      dial: false, // статус фабов
      items: [] as any,
    };
  },
  async created() {
    this.items = [];
    const data = await new this.$HttpModel('АктыОбхода.Типы').getRecords();
    for (const ind in data) {
      const type = data[ind].тип;
      const noDialog = data[ind].бездиалога;
      let icon = '';
      let children = null;
      const childrenStr = data[ind].children || ('' as String);
      if (childrenStr) {
        children = [];
        // переведем массив с бека в объект и переберем
        const childrenBuf = JSON.parse(childrenStr.toString());
        for (const ind_child in childrenBuf) {
          // JSON.parse - все приводит к верхнему регистру, при декодировании нумерация выборки вместо 0 идёт с 1
          children.push({ title: childrenBuf[ind_child].ЗАГОЛОВОК, тип: type, операция: +ind_child - 1, noDialog, типпу: childrenBuf[ind_child].ТИППУ, информация: '' });
          icon = '$vuetify.icons.report_more';
        }
      }
      this.items.push({ title: data[ind].заголовок, тип: type, children, icon, noDialog, информация: '' });
    }
  },
  methods: {
    async onBtnClick(payload: StackTableRow) {
      // Если прилетает noDialog - просто создает запись в выборке без модального диалога
      if (payload.noDialog) {
        await this.dataObject.createRecord({ ...payload }, { владелец: this.$attrs?.ownerID });
        this.tableAction({ action: 'reload' });
      } else {
        this.tableAction({ action: 'add', payload });
      }
    },
  },
});
