
import Vue from 'vue';
import { getComponents, stringTranslit } from '@/stackEngine';
import JSON from 'json5';
import ApiDialog from '@/stackEngine/components/StackWrapper/ApiDialog.vue';
import datnachDatend from '../references/parameters/$dialogs/$datNachdatEnd.vue';

const componentsDefs = require.context('@/tasks/common/views/references/parameters/$dialogs', false, /\.vue$/);
const paramDlgs = getComponents(componentsDefs);

export default Vue.extend({
  components: { ...paramDlgs, ApiDialog, datnachDatend },
  props: {
    record: { type: Object, required: true },
    params: { type: Object, required: true },
    linkField: { type: String, default: 'виды-параметры' },
    hideDate: { type: Boolean, default: false },
  },
  data() {
    return {
      apiParams: null as Object | null,
      elements: null,
      dialName: undefined as undefined | string,
      shortName: '',
      fullName: '',
    };
  },
  computed: {
    // Короткое имя параметра
    getShortName(): string {
      return (this.record[this.linkField + '>название'] as string) || (this.record.название as string) || this.shortName;
    },
    // Короткое имя параметра
    getFullName(): string {
      return (this.record[this.linkField + '>наименование'] as string) || (this.record.наименование as string) || this.fullName;
    },
    getLocalName(): string | undefined {
      return this.checkName(this.shortName);
    },
    passParams(): any {
      return this.apiParams ? { ...this.params, ...this.apiParams } : this.params;
    },
  },
  methods: {
    checkName(dialname: string | undefined) {
      if (dialname) {
        const name = stringTranslit(dialname).toUpperCase();
        if (this.$options.components && name in this.$options.components) {
          return name as string;
        }
        return 'DEFAULT';
      }
      return undefined;
    },
    async getModalName(paramName: string) {
      if (paramName) {
        try {
          const result = await new this.$StackApi().getApiDialog(paramName);
          if (result) {
            this.elements = result.elements;
            this.apiParams = typeof result.params === 'string' ? JSON.parse(result.params) : result.params;
            if (result.dialog) {
              return this.checkName(result.dialog);
            }
          }
        } catch {
          return undefined;
        }
      }
      return undefined;
    },
  },
  watch: {
    record: {
      deep: true,
      immediate: true,
      handler(to) {
        this.shortName = this.getShortName;
        this.fullName = this.getFullName;
      },
    },
    shortName: {
      immediate: true,
      async handler(to: string) {
        this.dialName = await this.getModalName(to) || this.getLocalName;
      },
    },
  },
});
