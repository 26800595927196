
import Vue from 'vue';
import credits from '../../themes/credits_stack';

export default Vue.extend({
  name: 'RegisterLicenseDialog',
  computed: {
    title(): string {
      return `Активация программного комплекса "${this.credits.shortName}"`;
    },
    hasLicKey() {
      return this.$store.getters.hasLicKey();
    },
    getLicKey() {
      return this.$store.getters.getLicKey();
    },
  },
  data() {
    return {
      visible: true,
      outRegCode: '',
      error: '',
      hint: '',
      credits,
    };
  },
  methods: {
    async onFocus() {
      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(this.getLicKey);
          this.hint = 'Код скопирован в буфер обмена';
        } catch (e: AnyException) {
          this.hint = 'Скопируйте код в буфер обмена';
        }
      }
    },
    onClose() {
      this.$store.commit('SET_LICENSE_KEY', undefined); // Успешная рега, стираем идентификационный код
    },
    async checkLicense() {
      this.error = '';
      if (!this.outRegCode) {
        return false;
      }
      const http = new this.$StackApi();
      try {
        await http.registerLicense(this.outRegCode);
        this.onClose();
      } catch (error: AnyException) {
        this.error = error.message;
      }
    },
  },
});
