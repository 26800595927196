
import Vue from 'vue';
import { TableHandlers } from '@/stackEngine';
import folder from './$folder/folderStatement.vue';
import modalDialogStatement from './_statementabonentsid.vue';
import StatementFilter from '@/tasks/fl/views/$common/filters/StatementFilter.vue';

export default Vue.extend({
  name: 'Statementabonents',
  components: { folder, StatementFilter, modalDialogStatement },
  data() {
    return {};
  },
  methods: {
    fetchHandler(item: StackTableRow) {
      if (item.цвет) {
        switch (item.цвет) {
          case 'серый':
            TableHandlers.trColor(item, 'grey');
            break;
          case 'желтый':
            TableHandlers.trColor(item, 'yellow');
            break;
          case 'красный':
            TableHandlers.trColor(item, 'red');
            break;
          case 'синий':
            TableHandlers.trColor(item, 'blue');
            break;

          default:
            break;
        }
      }
      return item;
    },
  },
});
