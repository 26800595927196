import { TableHandlers } from '@/stackEngine';
const payments: StackSelection = {
  recordRoute: 'payments-paylists-paylistsid',
  objectName: 'ПлатежныеВедомости',
  description: 'Платежные ведомости',
  hier: true,
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    // @ts-ignore
    if (row['@цвет'] !== '') {
      // @ts-ignore
      TableHandlers.stackStyle(row, row['@цвет']);
    }
    return row;
  },
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'источник-платежи>название',
      text: 'Источник',
      type: 'String',
    },
    {
      field: 'источник-платежи',
      text: 'ИсточникID',
      type: 'Link',
    },
    {
      field: 'кол_во',
      text: 'Σ',
      type: 'Number',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'расчмесяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: '₽',
      type: 'Money',
    },
    {
      field: 'сумма2',
      text: 'Перечислено',
      type: 'Money',
    },
    {
      field: 'сумма3',
      text: '₽ проблемных',
      type: 'Money',
    },
    {
      field: 'суммапени',
      text: 'в т.ч.пени, ₽',
      type: 'Money',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'дата распределения',
      text: 'Дата распределения',
      type: 'Date',
    },
    {
      field: 'проблемных',
      text: 'Σ проблемных',
      type: 'Number',
    },
    {
      field: 'автор',
      text: 'Автор',
      type: 'String',
    },
    {
      field: 'наш р/с-платежи',
      text: 'Наш р/с-Платежи',
      type: 'Link',
    },
    {
      field: 'наш р/с-платежи>р/счет',
      text: 'наш р/с-платежи>р/счет',
      type: 'String',
    },
    {
      field: 'платеж-выписка',
      text: 'Выписка',
      type: 'Link',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: '_датавыписки',
      text: '_датавыписки',
      type: 'Date',
    },
    {
      field: '@блокирована',
      text: ' ',
      type: 'Enum',
    },
    {
      field: '@цвет',
      text: 'цвет',
      type: 'String',
    },
    {
      field: 'видсчета',
      text: 'Взаимозачёт',
      type: 'Number',
    },
    {
      field: 'дата создания',
      text: 'Дата создания',
      type: 'DateTime',
    },
    {
      field: 'причина-платежи',
      text: 'причина-платежи',
      type: 'String',
    },
    {
      field: 'причина-платежи>название',
      text: 'Признак',
      type: 'String',
    },
  ],
};
export default payments;
