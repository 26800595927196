
import Vue from 'vue';
import contractFolder from './$folder/foldercontracts.vue';
import contractsFilter from '@/tasks/fl/views/$common/filters/contractsFilter.vue';
import contractsPrintBtn from './$components/contractsPrintBtn.vue';

export default Vue.extend({
  components: { contractFolder, contractsFilter, contractsPrintBtn },
  props: {
    dataModel: { type: String, required: true },
  },
});
