
import Vue from 'vue';
import { getComponents } from '@/stackEngine';

const componentsDefs = require.context('./$dialogs', false, /\.vue$/);
const constDlg = getComponents(componentsDefs);

export default Vue.extend({
  components: Object.assign(constDlg),
  data() {
    return {
      item: {} as StackTableRow,
      dataModel: new this.$HttpModel('ВыгрузкиПодкачки'),
      loading: false,
      isDialog: false,
      records: [] as StackTableRow[],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.records = await this.dataModel.getRecords({ операция: 2 });
    },
    getDialogName(row: StackTableRow): string {
      if (row && row.dialog) {
        const name = (row.dialog as string).toUpperCase();
        if (this.$options.components && name in this.$options.components) {
          return name as string;
        }
        return 'DEFAULT';
      }
      return '';
    },
    async openList(item: StackTableRow) {
      this.isDialog = false;
      const record = await this.dataModel.initRecord({ подкачка: item.title, естьинтеграция: item.естьинтеграция });

      const hasIntegration = record.естьинтеграция;
      if (hasIntegration !== 0) {
        this.upload(item);
      } else {
        const tempRecord = {} as StackTableRow;
        for (const ind in record) {
          if (record[ind] && !ind.startsWith('$')) {
            tempRecord[ind] = record[ind];
          }
        }
        this.item = Object.assign({}, item, tempRecord);
        this.isDialog = true;
      }
    },
    upload(item: StackTableRow) {
      const заголовок = (item.title ? item.title : '') as string;
      this.dataModel.executeMethod('Подкачать', item, {
        async: true,
        jobName: заголовок,
      });
    },
  },
});
