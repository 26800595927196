
import Vue from 'vue';

interface IGWStateComponent {
  name: string;
  status: string;
  descr?: string;
}

export default Vue.extend({
  data() {
    return {
      record: {},
      visible: false,
      gwState: {} as any,
      metrics: [] as any[],
      log: '',
    };
  },
  computed: {
    components(): IGWStateComponent[] {
      const comp = this.gwState.components;
      const res: IGWStateComponent[] = [];
      comp?.db && res.push({ name: 'db', status: comp.db.status });
      comp?.diskSpace &&
        res.push({
          name: 'diskSpace',
          status: comp.diskSpace.status,
          descr: `Всего: ${~~(comp.diskSpace.details?.total / 1024 / 1024)}Мб, Свободно: ${~~(comp.diskSpace.details?.free / 1024 / 1024)}Мб`,
        });
      comp?.rabbit &&
        res.push({
          name: 'rabbit',
          status: comp.rabbit.status,
          descr: `Версия: ${comp.rabbit.details?.version}`,
        });
      const tasks = comp?.stackGateway.details;
      for (const taskname in tasks) {
        const task = tasks[taskname];
        res.push({
          name: taskname,
          status: task.status,
          descr: `async:${task.details.async?.status}, sync:${task.details.sync?.status}`,
        });
      }
      return res;
    },
  },
  async created() {
    this.record = await new this.$HttpModel('СервисныеОперации').executeMethod('ДанныеСоединения');
    this.visible = true;
    const http = new this.$StackApi();
    this.gwState = await http.getGWState();
    const metrics = await http.getGWState('/metrics');
  },
  methods: {
    async onOpenLog() {
      const http = new this.$StackApi();
      this.log = await http.getGWState('/logfile');
    },
    async onOpenMetrics() {
      if (this.metrics.length) return;
      const http = new this.$StackApi();
      const metrics = await http.getGWState('/metrics');
      this.metrics = [];
      metrics.names.forEach(async (name: string) => {
        const res = await http.getGWState(`/metrics/${name}`);
        let val: number | string = '';
        if (res.measurements.length === 1) {
          val = res.measurements[0].value;
          // Переводим в байты в мегабайты
          if (res.baseUnit === 'bytes') {
            res.baseUnit = 'megabytes';
            val = ((val as number) / 1024 / 1024).toFixed(2);
          }
        } else if (res.measurements.length > 1) {
          res.measurements.forEach((meas: any) => (val += `${meas.statistic}:${meas.value}; `));
        }
        this.metrics.push({
          name: res.name,
          description: res.description || res.name,
          baseUnit: res.baseUnit,
          value: val,
        });
      });
    },
    getIcon(state: string) {
      return state.toUpperCase() === 'UP' ? '$check' : '$alert';
    },
    getIconColor(state: string) {
      return state.toUpperCase() === 'UP' ? 'primary' : 'error';
    },
  },
});
