
import Vue from 'vue';

interface FileStack extends File {
  file: { originName?: string };
}

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    maxWidth: { type: [String, Number], default: '800px' },
    dataModel: { type: [String, Object], required: true },
    record: { type: Object, required: true },
    fileNamefield: { type: String, default: 'имя файла' },
    title: { type: String, default: 'Файл' },
    multiple: { type: Boolean, default: false },
    showSaveMode: { type: Boolean, default: false },
    customValid: { type: Boolean, default: true },
  },
  computed: {
    isNewRecord(): boolean {
      return !(this.record.$номерЗаписи > 0);
    },
    // good name :)
    isAssConnected(): boolean {
      return this.$store.getters['wsStore/isConnected']();
    },
    getTwainDevices(): any {
      return [...this.$store.getters['wsStore/getTwainDevices']()];
    },
    apiHostUpload(): string {
      return this.$store.getters.getApiHostUpload();
    },
    queryParams(): any {
      return {
        fileId: this.uploadfileName,
      };
    },
    loading(): boolean {
      if (this.isBusy) {
        return true;
      }
      for (const file of this.files) {
        // @ts-ignore
        if (file?.active) {
          return true;
        }
      }
      return false;
    },
    success(): boolean {
      for (const file of this.files) {
        // @ts-ignore
        if (!file?.success) {
          return false;
        }
      }
      return true;
    },
    error(): boolean {
      for (const file of this.files) {
        // @ts-ignore
        if (file?.error) {
          // @ts-ignore
          return file.error;
        }
      }
      return false;
    },
    progress(): string | undefined {
      if (this.files.length === 0) {
        return undefined;
      }
      let res = 100;
      for (const file of this.files) {
        // @ts-ignore
        res = Math.min(res, file?.progress);
      }
      return `Загружено ${res}%`;
    },
    currentTask(): string {
      return this.$store.getters.getCurrentTask();
    },
    docTypeRequired(): boolean {
      return !(['ul', 'fl', 'pdu', 'commun', 'admin', 'avar'].some((task) => task === this.currentTask));
    },
    showDocType(): boolean {
      return ['dlg_fl', 'dlg_ul', 'ul', 'fl', 'admin'].some((task) => task === this.currentTask);
    },
    isValid(): boolean {
      if (this.docTypeRequired && +this.record['типы документов-файл'] <= 0) {
        return false;
      }
      if (this.showSaveMode && this.saveMode && !this.networkFolder) {
        return false;
      }

      return true;
    },
  },
  data() {
    return {
      fileType: 0,
      fileTypes: ['jpg', 'tiff', 'png', 'bmp', 'gif', 'pdf'],
      mode: 0,
      dataObject: {} as DataModel,
      file: {} as StackTableRow,
      uploadfileName: undefined as string | undefined,
      deviceName: null as string | null,
      isScanned: false,
      files: [] as FileStack[],
      isBusy: false,
      networkFolder: null, // каталог на сервере в который сохраним файл
      saveMode: this.showSaveMode ? this.$store.getters['flStore/getExtDocSaveMode']() : 0, // сохранение образа в базу или файла на общий ресурс
    };
  },
  async created() {
    if (typeof this.dataModel === 'string') {
      this.dataObject = new this.$HttpModel(this.dataModel);
    } else {
      this.dataObject = this.dataModel;
    }
    await this.$store.dispatch('wsStore/checkScanDevices');
    this.deviceName = this.$store.getters['wsStore/getTwainDefaultDevice']();
  },
  methods: {
    async onScan() {
      if (this.deviceName) {
        this.isScanned = true;
        try {
          const filename = `${this.record[this.fileNamefield]}.${this.fileTypes[this.fileType]}`;
          const files: string[] = await this.$store.dispatch('wsStore/executeTwain', { device: this.deviceName, fileName: `${this.apiHostUpload}/${filename}` });
          if (files.length > 0) {
            this.uploadfileName = files[0].split('/').pop();
            this.record[this.fileNamefield] = filename;
            // TODO Это проблема бекэнда
            this.record.дата = this.$store.getters.getCurrentDate();
            // @ts-ignore
            await this.$refs.controller.onSave();
          } else {
            this.$toast(`Документ не отсканирован`, { color: 'error' });
          }
        } catch (e: AnyException) {
          this.$toast(`Ошибка сканирования: ${e.message}`, { color: 'error' });
          console.log(e);
        } finally {
          this.isScanned = false;
        }
      }
    },
    async setOrigName(file: File) {
      // Получим новое имя с бека
      const newName = await this.getBackendName(file.name);
      if (newName) {
        const rfile = new File([file], newName, {
          type: file.type,
          lastModified: file.lastModified,
        });
        // @ts-ignore
        this.$refs.upload.add(Object.assign(rfile, { originName: file.name }));
      }
    },
    async onFileSelect(files: File[] | File) {
      this.isBusy = true;
      // @ts-ignore
      this.$refs.upload.clear();
      if (Array.isArray(files)) {
        for (const file of files) {
          await this.setOrigName(file);
        }
      } else {
        await this.setOrigName(files);
      }
      this.isBusy = false;
      // Запуск аплоада
      // @ts-ignore
      this.$refs.upload.active = true;
    },

    async fetchDataAsync() {
      if (!this.dataObject.executeMethod) {
        return;
      }
      if (this.record.$номерЗаписи > 0) {
        await this.dataObject.executeMethod(
          'получитьФайл',
          { номерЗаписи: this.record.$номерЗаписи },
          { async: true, jobName: `Получение файла: ${this.record[this.fileNamefield]}` },
        );
        // @ts-ignore
        this.$refs.controller.onClose();
      }
    },

    async customSave() {
      this.isBusy = true;
      for (const file of this.files) {
        // @ts-ignore
        await this.dataObject.createRecord(
          { ...this.record, [this.fileNamefield]: file.name, дата: this.$store.getters.getCurrentDate() },
          { fileId: file.name, владелец: this.$attrs?.ownerID, fileName: file.file.originName, networkFolder: this.saveMode ? this.networkFolder : undefined },
        );
      }
      // @ts-ignore
      this.$refs.controller.onClose();
      this.isBusy = false;
      this.$sendMsg('stack-table', this.dataObject.model, 'reload');
      return false;
    },

    // Получение и резервирование уникального имени на беке
    async getBackendName(fileName: string) {
      const http = new this.$StackApi();
      http.clear();
      const taskID = http.fetch(fileName, {}, 'загрузить', 'файл') as number;
      await http.run();
      const data = http.getTaskResult(taskID);
      if (data && data.fileId) {
        return data.fileId;
      }
      return undefined;
    },
  },
});
