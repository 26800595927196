const statements: StackSelection = {
  objectName: 'ЗаявкиАбонентов',
  description: 'Заявки абонентов',
  hier: true,
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'лицевой',
      text: 'Лицевой',
      type: 'Link',
    },
    {
      field: 'номерлс',
      text: 'Лицевой',
      type: 'String',
    },
    {
      field: 'адреслс',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'телефон',
      text: 'Телефон',
      type: 'String',
    },
    {
      field: 'сотрудник',
      text: 'Сотрудник',
      type: 'Link',
    },
    {
      field: 'исполнитель',
      text: 'Исполнитель',
      type: 'String',
    },
    {
      field: 'заявитель',
      text: 'Заявитель',
      type: 'String',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Link',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'описание',
      text: 'Описание',
      type: 'String',
    },
    {
      field: 'датазавершения',
      text: 'Дата завершения',
      type: 'Date',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: '@состояние',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'дописполнители',
      text: 'ДопИсполнители',
      type: 'String',
    },
    {
      field: 'обработано',
      text: 'Обработано',
      type: 'Number',
    },
    {
      field: 'срок',
      text: 'Срок',
      type: 'Date',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'время',
      text: 'Время',
      type: 'String',
    },
    {
      field: 'автор',
      text: 'Автор',
      type: 'String',
    },
    {
      field: 'цвет',
      text: 'Цвет',
      type: 'String',
    },
    {
      field: '_автор',
      text: 'Автор',
      type: 'Number',
    },
  ],
};
export default statements;
