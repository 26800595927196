
import Vue from 'vue';
import NetTreeDialog from './NetTreeDialog.vue';

export default Vue.extend({
  components: { NetTreeDialog },
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: [Number, String], default: '' },
    type: { type: Number, required: true },
    label: { type: String, default: 'Сеть' },
    multi: { type: Boolean, default: false },
  },
  data() {
    return {
      selected: [] as StackTableRow[],
      netTreeVisible: false as boolean,
    };
  },
  created() {
    this.loadSelected();
  },
  computed: {
    textValue(): string | null {
      if (!this.value) {
        return null;
      }
      let values = '';
      this.selected.forEach((row: any) => {
        if (row) {
          const value = row.название;
          values += values ? ', ' + value : value;
        }
      },
      );
      return values;
    },
  },
  methods: {
    onSelectNetElement(payload: StackTableRow[]) {
      this.netTreeVisible = false;
      let value = this.multi ? '' : -1 as string | number;
      this.selected = [];
      if (payload && payload.length) {
        for (const item of payload) {
          if (item && item.типсети === this.type && item.$номерЗаписи && !(typeof value === 'string' && value.split(',').includes(item.$номерЗаписи.toString()))) {
            if (this.multi) {
              value += value ? ',' + item.$номерЗаписи : item.$номерЗаписи as string;
            } else {
              value = item.$номерЗаписи as number;
            }
            this.selected.push(item);
          }
        }
      }
      this.$emit('change', value);
    },
    // т.к. поле ридонли, то инпут приходит только при очистке
    onInput(payload: any) {
      if (!payload) {
        this.selected = [];
        this.$emit('change', this.multi ? '' : -1);
      }
    },
    async loadSelected() {
      this.selected = [];
      if (this.value) {
        const valueArray = this.value.toString().split(',');
        const dataObject = new this.$HttpModel('ЭлементСети');
        for (const i in valueArray) {
          const rowid = +valueArray[i];
          if (rowid >= 0) {
            const rec = await dataObject.getRecords({ номерЗаписи: rowid });
            if (rec[0]) {
              this.selected.push(rec[0]);
            }
          }
        }
      }
    },
  },
  watch: {
    value(to) {
      this.loadSelected();
    },
  },
});
