
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
    params: { type: Object, default: (): any => { return {}; } },
  },
  data() {
    return {
      item: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        '@ФИОсогл': -1,
        Дата: this.$store.getters.getCurrentDate(),
      },
    };
  },
});
