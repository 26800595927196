
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    title(): string {
      return `Сообщение для квитанции`;
    },
    closed(): boolean {
      if (this.record && (this.record.$номерЗаписи === -1 || this.record.$номерЗаписи === 'new')) {
        return false;
      }
      if (this.record && this.record.датнач && this.record.датнач < this.$store.getters.getOpenMonth()) {
        return true;
      }
      return false;
    },
    closedDateEnd(): boolean {
      if (this.record && (this.record.$номерЗаписи === -1 || this.record.$номерЗаписи === 'new')) {
        return false;
      }
      if (this.record && this.record.даткнц && this.record.даткнц < this.$store.getters.getOpenMonth()) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async generateMessage() {
      const result = await new this.$HttpModel('Организации.СообщенияДляКвитанций').executeMethod('Генерировать', {
        орг: this.record['организация-сообщение'],
        тип: this.record.типадресата,
      });
      Vue.set(this.record, 'текст', result);
    },
  },
});
