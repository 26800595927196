
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      items: [
        { title: 'Ведомости показаний по ИПУ', img: 'doc_ipu', to: { name: 'metersdata-readingstatements' } },
        { title: 'Общедомовые и общеквартирные приборы учёта', img: 'doc_odpu', to: { name: 'metersdata-groupcounters' } },
        { title: 'Ведомости\nсторонних показаний', img: 'doc_ext_pok', to: { name: 'metersdata-problemstatements' } },
        { title: 'Акты замены ИПУ', img: 'doc_ipu_change', to: { name: 'metersdata-counterschange' } },
      ],
      items2: [
        { title: 'Массовый ввод\nиндивидуальных показаний', img: 'master_vvod_pok_ipu', to: { name: 'metersdata-readings-massinput' }, color: 'stack' },
        { title: 'Массовый ввод\nпоказаний по ОДПУ/ОКПУ', img: 'master_vvod_pok_odpu', to: { name: 'metersdata-readings-massinputgroup' }, color: 'stack' },
        { title: 'Прием сторонних показаний\nиз файла', img: 'import_pokaz', to: { name: 'metersdata-readingsuploads' }, color: 'stack' },
        { title: 'Формирование реестра для приема сторонних показаний', img: 'export_pokaz_csv', to: { name: 'metersdata-readingsunloads' }, color: 'stack' },
      ],
      items3: [
        { title: 'Реестр контролёров', img: 'doc_reestrs', to: { name: 'documents-contrlist' } },
        { title: 'Акты обхода', img: 'doc_inspections', to: { name: 'documents-inspectionsacts' } },
        { title: 'Формирование актов обхода', img: 'formact_obh', to: { name: 'operations-actobhform' }, color: 'stack' },
        { title: 'Реестр пломб', img: 'reestr_plomb', to: { name: 'documents-plombs' }, color: 'stack' },
      ],
    };
  },
});
