
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'rec',
  },
  props: {
    rec: { type: Object, required: true },
  },
});
