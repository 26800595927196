
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    ownerID: { type: [String, Number], required: true },
    contact: { type: String, default: 'человек-телефон' },
    type: { type: [String], required: true },
    title: { type: String, default: 'Контакт' },
    noOwner: { type: Boolean, default: false },
  },
  computed: {
    contactHint(): string | null {
      switch (+this.record.тип) {
        case 1:
        case 2:
        case 3:
          return `*В номере телефона допускается использовать пробелы, скобки,а так же символы '+'' и '-'.\n
                  Примеры: +7-999-999-99-99 или 8(1234)12-34-56 или (495) 123 45 67`;
        case 5:
          return 'Введите e-mail';
        default:
          return null;
      }
    },
    typedRules(): object | boolean {
      switch (+this.record.тип) {
        case 1:
          return [this.$stackRules.mobilePhone(this.record.номер)]; // аргумент задан явно, иначе правило может не отработать
        case 2:
        case 3:
          return [this.$stackRules.phone(this.record.номер)]; // аргумент задан явно, иначе правило может не отработать
        case 5:
          return [this.$stackRules.email(this.record.номер)]; // аргумент задан явно, иначе правило может не отработать
        default:
          return [];
      }
    },
  },
  data() {
    return {
      rulesThis: {
        checkType: (type: number, flag: number): string | true => {
          if (!flag) {
            return true;
          }
          if (type === 1 && flag % 4 >= 1) {
            if (flag % 8 >= 4) {
              return 'Тип контакта не соответствует типу рассылки';
            }
            return true;
          }
          if ((type === 2 || type === 3) && flag % 4 >= 2) {
            if (flag % 8 >= 4 || flag % 2 === 1) {
              return 'Тип контакта не соответствует типу рассылки';
            }
            return true;
          }
          if (type === 5 && flag % 8 >= 4) {
            if (flag % 4 >= 1) {
              return 'Тип контакта не соответствует типу рассылки';
            }
            return true;
          }
          if (type === 9 && ((flag % 16) === 8 || (flag % 32) === 16 || (flag % 32) === 24)) {
            return true;
          }
          return 'Тип контакта не соответствует типу рассылки';
        },
      },
    };
  },
});
