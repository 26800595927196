import { TableHandlers } from '@/stackEngine';

const serv: StackSelection = {
  recordRoute: 'references-phases-phasesid',
  folderRoute: 'fphases',
  objectName: 'ВидыФаз',
  description: 'Фазы',
  hier: true,
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    // @ts-ignore
    if (row['@цвет'] !== '') {
      // @ts-ignore
      TableHandlers.stackStyle(row, row['@цвет']);
    }
    return row;
  },
  fields: [
    {
      field: 'код',
      text: 'Код',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'цвет',
      text: 'Цвет',
      type: 'Number',
    },
    {
      field: 'отдел',
      text: 'Отдел',
      type: 'Number',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'дополнительныйтип',
      text: 'Производство',
      type: 'Number',
    },
    {
      field: 'значение',
      text: 'Контрольный срок',
      type: 'Number',
    },
    {
      field: 'признак',
      text: 'Флаги',
      type: 'Number',
    },
    {
      field: 'фаза-комплект',
      text: 'Комплект',
      type: 'Number',
    },
    {
      field: 'фаза-комплект>название',
      text: 'Название комплекта',
      type: 'String',
    },
    {
      field: 'госпошлина',
      text: 'Процент госпошлины',
      type: 'Number',
    },
    {
      field: '@цвет',
      text: 'Цвет записи',
      type: 'String',
    },
  ],
};
export default serv;
