
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    disable: { type: Boolean, default: false },
  },
  data() {
    return {
      filter: {
        ук: -1,
        укИнверт: 0,
        поставщик: -1,
        поставщикИнверт: 0,
        услуга: -1,
        услугаИнверт: 0,
        категория: -1,
        категорияИнверт: 0,
        кредиторы: -1,
      },
    };
  },
  created() {
    this.fullFilter(this.record.тема);
  },
  methods: {
    async clearChart() {
      this.record.тема = '-1;-1;-1;-1;-1;0;0;0;0';
      this.fullFilter(this.record.тема);
      this.changeField();
    },
    async fullFilter(tema: string) {
      const arrd = tema.toString().split(';');
      this.filter.ук = +arrd[0];
      this.filter.поставщик = +arrd[1];
      this.filter.услуга = +arrd[2];
      this.filter.категория = +arrd[3];
      this.filter.кредиторы = +arrd[4];
      // Если переключатель stack-switch включен, то фильтр работает по стандарту
      // Если выключен, то значение, указанное, в соответствующем поле исключается из выборки (фильтр инвертируется)
      this.filter.укИнверт = Number(arrd[5]);
      this.filter.поставщикИнверт = Number(arrd[6]);
      this.filter.услугаИнверт = Number(arrd[7]);
      this.filter.категорияИнверт = Number(arrd[8]);
    },
    async changeField() {
      this.$sendMsg('stack-table', 'Задолженность_детализация_соглашение', 'reload');
    },
    async buildChart() {
      this.record.тема =
        (this.filter.ук ? this.filter.ук.toString() : '-1') +
        ';' +
        (this.filter.поставщик ? this.filter.поставщик.toString() : '-1') +
        ';' +
        (this.filter.услуга ? this.filter.услуга.toString() : '-1') +
        ';' +
        (this.filter.категория ? this.filter.категория.toString() : '-1') +
        ';' +
        (this.filter.кредиторы ? this.filter.кредиторы.toString() : '-1') +
        ';' +
        (this.filter.укИнверт.toString()) +
        ';' +
        (this.filter.поставщикИнверт.toString()) +
        ';' +
        (this.filter.услугаИнверт.toString()) +
        ';' +
        (this.filter.категорияИнверт.toString());
      const res = await new this.$HttpModel('СоглашенияОРассрочке').executeMethod('ПолучитьСтрокуФильтра', { тема: this.record.тема });
      this.record.списокф = res || '';
      this.$emit('build');
    },
  },
});
