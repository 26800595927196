const dog = {
  objectName: 'ЧастныеЛица.Контрагенты',
  description: ' ',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'фактадрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'должность',
      text: 'Должность',
      type: 'String',
    },
    {
      field: 'флагиподписи',
      text: 'Подписывает',
      type: 'String',
    },
    {
      field: 'кодподразделения',
      text: 'Код подразделения',
      type: 'String',
    },
  ],
};

export default dog;
