
import Vue from 'vue';
import Recordable from '../mixins/recordable';
/**
 * List Dialog
 */
export default Vue.extend({
  name: 'StackListDialog',
  mixins: [Recordable],
  props: {
    /**
     * заголовок диалога
     */
    title: { type: String, default: '' },
    testId: { type: String, default: '' },
    // Внешнее управление блокировки кнопки Сохранить
    disableSaveBtn: { type: Boolean, default: false },
  },
});
