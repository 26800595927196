const actsqu: StackSelection = {
  recordRoute: 'documents-qualityacts-qualityactsid',
  objectName: 'АктыСнятияКачества',
  description: 'Акты снятия качества',
  hier: true,
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'расчмесяц',
      text: 'Расчетный месяц',
      type: 'DateMonth',
    },
    {
      field: 'окончания срок',
      text: 'Отменен',
      type: 'Date',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: '@нзкоэф',
      text: '@НЗКоэф',
      type: 'Number',
    },
    {
      field: '@коэффициент',
      text: 'Коэффициент',
      type: 'Number',
    },
    {
      field: '@ркоэффициент',
      text: '@РКоэффициент',
      type: 'Number',
    },
    {
      field: '@вид-документы',
      text: 'услуга',
      type: 'Link',
    },
    {
      field: '@документ-показатели',
      text: '@Документ-Показатели',
      type: 'Link',
    },
    {
      field: '@виновник',
      text: '@Виновник',
      type: 'Link',
    },
    {
      field: '@нтемпература',
      text: 'НТемпература',
      type: 'Number',
    },
    {
      field: '@фтемпература',
      text: '@ФТемпература',
      type: 'Number',
    },
    {
      field: '@параметры снятия',
      text: '@Параметры снятия',
      type: 'Link',
    },
    {
      field: '@часовотсутствия',
      text: '@ЧасовОтсутствия',
      type: 'Number',
    },
    {
      field: '@месяцотсутствия',
      text: '@МесяцОтсутствия',
      type: 'Number',
    },
    {
      field: '@оп',
      text: 'Общая площадь',
      type: 'Number',
    },
    {
      field: '@плкомну',
      text: '@ПлКомнУ',
      type: 'Number',
    },
    {
      field: '@плкомн1',
      text: '@ПлКомн1',
      type: 'Number',
    },
    {
      field: '@плкомн2',
      text: '@ПлКомн2',
      type: 'Number',
    },
    {
      field: '@плкомн3',
      text: '@ПлКомн3',
      type: 'Number',
    },
    {
      field: '@темпу',
      text: '@ТемпУ',
      type: 'Number',
    },
    {
      field: '@темп1',
      text: '@Темп1',
      type: 'Number',
    },
    {
      field: '@темп2',
      text: '@Темп2',
      type: 'Number',
    },
    {
      field: '@темп3',
      text: '@Темп3',
      type: 'Number',
    },
    {
      field: '@часов снижения давления',
      text: '@Часов снижения давления',
      type: 'Number',
    },
  ],
};
export default actsqu;
