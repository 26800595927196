
import Vue from 'vue';
import datnachDatend from './$datNachdatEnd.vue';

export default Vue.extend({
  components: { datnachDatend },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    hideDate: { type: Boolean, default: false },
  },
  methods: {
    onChange(value: number) {
      if (value === 0) {
        this.record.знач2 = 0;
      }
    },
  },
});
