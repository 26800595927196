const uploads: StackSelection = {
  objectName: 'ОперацииИндивидуальные',
  description: '',
  fields: [
    {
      field: 'title',
      text: 'title',
      type: 'String',
    },
    {
      field: 'dialog',
      text: 'dialog',
      type: 'String',
    },
    {
      field: 'месяц',
      text: 'месяц',
      type: 'Date',
    },
    {
      field: 'лицевые',
      text: 'лицевые',
      type: 'String',
    },
    {
      field: 'дата',
      text: 'месяц',
      type: 'Date',
    },
    {
      field: 'датнач',
      text: 'датнач',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'даткнц',
      type: 'Date',
    },
    {
      field: 'комбо',
      text: 'комбо',
      type: 'String',
    },
    {
      field: 'моделькомбо',
      text: 'моделькомбо',
      type: 'String',
    },
    {
      field: 'названиекомбо',
      text: 'названиекомбо',
      type: 'String',
    },
    {
      field: 'флаги',
      text: 'флаги',
      type: 'String',
    },
    {
      field: 'модельфлаги',
      text: 'модельфлаги',
      type: 'String',
    },
  ],
};
export default uploads;
