import { TableHandlers } from '@/stackEngine';
const ci: StackSelection = {
  objectName: 'ЛицевыеСчета.ЦентрИнформирования',
  description: '',
  prepareRow: item => {
    if (!item.$data) {
      item.$data = {};
    }
    // @ts-ignore
    item.$data['@статус'] = { $иконка: '$vuetify.icons.ci_stop', $иконка_цвет: 'primary' };
    switch (item.статус) {
      case 0:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.ci_stop', 'primary');
        break;
      case 1:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.ci_play', 'primary');
        break;
      case 2:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.ci_send', 'primary');
        break;
      case 3:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.done', 'primary');
        break;
      case 4:
      case 5:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.error', 'error');
        break;
    }

    // @ts-ignore
    item.$data['@транспорт'] = { $иконка: '$vuetify.icons.phone', $иконка_цвет: 'blue' };
    switch (item.транспорт) {
      case 0:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.phone', 'blue');
        break;
      case 1:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.sms', 'blue');
        break;
      case 2:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.cellphone', 'green');
        break;
      case 3:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.email', 'green');
        break;
      case 4:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.person_box', 'green');
        break;
      case 5:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.person_card', 'green');
        break;
    }
    return item;
  },
  fields: [
    {
      field: 'кампания',
      text: 'Кампания',
      type: 'Number',
    },
    {
      field: 'статус',
      text: '',
      type: 'Number',
    },
    {
      field: '@статус',
      text: ' ',
      type: 'Number',
    },
    {
      field: 'статустекст',
      text: 'СтатусТекст',
      type: 'String',
    },
    {
      field: 'адресдоставки',
      text: '  ',
      type: 'String',
    },
    {
      field: 'датавремя',
      text: 'Время',
      type: 'String',
    },
    {
      field: 'транспорттекст',
      text: '   ',
      type: 'String',
    },
    {
      field: 'транспорт',
      text: '    ',
      type: 'Number',
    },
    {
      field: '@транспорт',
      text: '     ',
      type: 'Number',
    },
    {
      field: 'текст',
      text: 'Текст сообщения',
      type: 'String',
    },
  ],
};
export default ci;
