
import Vue from 'vue';
import { ReportsBuilder } from '@/stackEngine';
import SettingsDial from './SettingsDial.vue';

export default Vue.extend({
  components: { SettingsDial },
  props: {
    params: { type: Object, required: true },
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    selectedItems: { type: [Object, Array], required: true }, // array
    selectedValues: { type: [String, Number], default: undefined },
  },
  computed: {
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
  },
  data() {
    return {
      jobID: null,
      settingsDialogVis: false,
      record: {} as StackTableRow,
    };
  },
  methods: {
    async onRunCheck() {
      const obj = new this.$HttpModel('РеестрПроверокГис');
      this.jobID = (
        await obj.executeMethod('Проверка', { items: this.selectedValues }, { async: true, jobName: 'Запущены проверки лицевых' })
      ).asyncId;
    },
    async onRunReport() {
      new ReportsBuilder('Отчет реестра проверок данных в ГИС').executeReport2('Отчет реестра проверок данных в ГИС');
    },
    async onSetting() {
      const rec = await new this.$HttpModel('НастройкаРолиОрганизации').getRecords();
      if (rec && rec[0]) {
        this.record = Object.assign(this.record, {}, rec[0]);
      }
      this.settingsDialogVis = true;
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.jobID = null;
        this.$emit('updateTable');
      }
    },
  },
});
