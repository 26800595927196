const lg: StackSelection = {
  objectName: 'КатегорииЛьгот',
  description: 'Категории льгот',
  openLink: 'references-lgotkat',
  hier: true,
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'n п/п',
      text: 'N п/п',
      type: 'Number',
      width: '10%',
    },
    {
      field: 'льгота-категории',
      text: 'Льгота',
      type: 'Link',
    },
  ],
};

export default lg;
