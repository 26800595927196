const acts: StackSelection = {
  objectName: 'ЛицевыеСчета.ДокументыНаВладение',
  description: '',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.титульный === 1) {
      // @ts-ignore
      row.$data.титульныйиконка = { $иконка: '$vuetify.icons.check' };
    }
    if (row.довлицо === 1) {
      // @ts-ignore
      row.$data.довлицоиконка = { $иконка: '$vuetify.icons.check' };
    }

    return row;
  },
  fields: [
    {
      field: 'довлицоиконка',
      text: 'Доверенное лицо при голосовании',
      type: 'Number',
      computed: true,
    },
    {
      field: 'титульныйиконка',
      text: 'Титульный',
      type: 'Number',
      computed: true,
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'датнач',
      text: 'Действителен с',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Действителен по',
      type: 'Date',
    },
    {
      field: 'основание',
      text: 'Основание',
      type: 'String',
    },
    {
      field: 'основание>название',
      text: 'Основание',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'датадок',
      text: 'Дата записи в ЕГРН',
      type: 'Date',
    },
    {
      field: 'доля_числитель',
      text: 'Доля_Числитель',
      type: 'Number',
    },
    {
      field: 'доля_знаменатель',
      text: 'Доля_Знаменатель',
      type: 'Number',
    },
    {
      field: '@долявладения',
      text: 'Доля владения',
      type: 'String',
      computed: true,
    },
    {
      field: 'совместная',
      text: 'Совместная',
      type: 'Number',
    },
    {
      field: 'владениедок',
      text: 'ВладениеДок',
      type: 'String',
    },
    {
      field: '@владениедок',
      text: 'Документ владения',
      type: 'String',
      computed: true,
    },
    {
      field: 'датарег',
      text: 'ДатаРег',
      type: 'Date',
    },
    {
      field: 'датадокумента',
      text: 'ДатаДокумента',
      type: 'Date',
    },
    {
      field: 'кемрегистр',
      text: 'КемРегистр',
      type: 'String',
    },
    {
      field: 'сериябланк',
      text: 'СерияБланк',
      type: 'String',
    },
    {
      field: 'бланкномер',
      text: 'БланкНомер',
      type: 'String',
    },
    {
      field: 'регномер',
      text: 'РегНомер',
      type: 'String',
    },
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: '@типжильца',
      text: 'Тип ',
      type: 'String',
      computed: true,
    },
  ],
};
export default acts;
