
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: 'услуга',
          text: 'Услуга',
          type: 'Link',
          dataModel: 'Услуги',
          keyField: 'номер услуги',
          headers: 'наименование,номер услуги',
          labelField: (record: any) => {
            return '№' + record['номер услуги'] + ' ' + record.название;
          },
          hier: true,
        },
        {
          field: 'тип',
          text: 'Тип докрутки',
          type: 'Enum',
          dataModel: 'Докрутка_ТипДокрутки',
          align: 'left',
        },
        {
          field: 'значение',
          text: 'Значение',
          type: 'Number',
        },
        {
          field: 'номеруслуги',
          text: 'Номер услуги для показаний',
          type: 'Link',
          dataModel: 'Услуги',
          headers: 'наименование,номер услуги',
          keyField: 'номер услуги',
          labelField: (record: any) => {
            return '№' + record['номер услуги'] + ' ' + record.название;
          },
          hier: true,
        },
      ],
    };
  },
});
