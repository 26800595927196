const serv: StackSelection = {
  objectName: 'ЧастныеЛица',
  description: 'Частные лица',
  recordRoute: 'references-people-peopleid',
  openLink: 'references-people',
  fields: [
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'инн',
      text: 'ИНН',
      type: 'String',
    },
    {
      field: 'пенсионное свидетельство',
      text: 'СНИЛС',
      type: 'Number',
    },
    {
      field: 'дата рождения',
      text: 'Дата рождения',
      type: 'Date',
    },
    {
      field: '@рождение',
      text: 'Место рождения',
      type: 'String',
      computed: true,
    },
    {
      field: 'адрес',
      text: 'Адрес регистрации',
      type: 'String',
    },
    {
      field: 'фактадрес',
      text: 'Фактический адрес',
      type: 'String',
    },
    {
      field: '@регстрана',
      text: 'РегСтрана',
      type: 'String',
      computed: true,
    },
    {
      field: '@реггород',
      text: 'РегГород',
      type: 'String',
      computed: true,
    },
    {
      field: '@регграйон',
      text: 'РегГРайон',
      type: 'String',
      computed: true,
    },
    {
      field: '@реграйон',
      text: 'РегРайон',
      type: 'String',
      computed: true,
    },
    {
      field: '@регобласть',
      text: 'ФОбласть',
      type: 'String',
      computed: true,
    },
    {
      field: '@регулица',
      text: 'ФОбласть',
      type: 'String',
      computed: true,
    },
    {
      field: '@регдом',
      text: 'ФОбласть',
      type: 'String',
      computed: true,
    },
    {
      field: '@регкорп',
      text: 'ФОбласть',
      type: 'String',
      computed: true,
    },
    {
      field: '@регкв',
      text: 'ФОбласть',
      type: 'String',
      computed: true,
    },
    {
      field: '@регкм',
      text: 'ФОбласть',
      type: 'String',
      computed: true,
    },
    {
      field: '@регпланструктура',
      text: 'фПланировочнаяСтруктура',
      type: 'String',
      computed: true,
    },
    {
      field: 'индекс',
      text: 'РегИндекс',
      type: 'String',
    },
    {
      field: '@фстрана',
      text: 'ФСтрана',
      type: 'String',
      computed: true,
    },
    {
      field: '@фгород',
      text: 'ФГород',
      type: 'String',
      computed: true,
    },
    {
      field: '@фграйон',
      text: 'ФГРайон',
      type: 'String',
      computed: true,
    },
    {
      field: '@фрайон',
      text: 'ФРайон',
      type: 'String',
      computed: true,
    },
    {
      field: '@фобласть',
      text: 'ФОбласть',
      type: 'String',
      computed: true,
    },
    {
      field: '@фулица',
      text: 'ФОбласть',
      type: 'String',
      computed: true,
    },
    {
      field: '@фдом',
      text: 'ФОбласть',
      type: 'String',
      computed: true,
    },
    {
      field: '@фкорп',
      text: 'ФОбласть',
      type: 'String',
      computed: true,
    },
    {
      field: '@фкв',
      text: 'ФОбласть',
      type: 'String',
      computed: true,
    },
    {
      field: '@фпланструктура',
      text: 'ФПланировочнаяСтруктура',
      type: 'String',
      computed: true,
    },
    {
      field: 'фактиндекс',
      text: 'фИндекс',
      type: 'String',
    },
    {
      field: 'место работы',
      text: 'Место работы',
      type: 'Number',
    },
    {
      field: 'табном',
      text: 'ТабНом',
      type: 'Number',
    },
    {
      field: 'цех',
      text: 'Цех',
      type: 'String',
    },
    {
      field: '@телефон',
      text: 'Телефоны',
      type: 'String',
      computed: true,
    },
    {
      field: '@email',
      text: 'Emails',
      type: 'String',
      computed: true,
    },
    {
      field: 'серия',
      text: 'Серия',
      type: 'String',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'виддокумента',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'видтекст',
      text: 'Вид',
      type: 'String',
      computed: true,
    },
    {
      field: 'даткнцдог',
      text: 'Дата закрытия',
      type: 'Date',
      computed: true,
    },
    {
      field: 'даткнцрег',
      text: 'Дата снятия',
      type: 'Date',
      computed: true,
    },
    {
      field: 'закрытьдокумент',
      text: 'Прекратить действие документа на владение',
      type: 'Number',
      computed: true,
    },
    {
      field: 'снятсрегистрации',
      text: 'Снять человека с регистрации',
      type: 'Number',
      computed: true,
    },
    {
      field: 'типжильца',
      text: 'ТипЖильца',
      type: 'Number',
    },
    {
      field: '@типжильца',
      text: 'Тип ',
      type: 'String',
      computed: true,
    },
    {
      field: 'отношение',
      text: 'Отношение к в/службе',
      type: 'Enum',
    },
    {
      field: 'военная обязанность',
      text: 'Воинская обязанность',
      type: 'Enum',
    },
    {
      field: 'состав',
      text: 'Воинский состав',
      type: 'Enum',
    },
    {
      field: 'дата постановки',
      text: 'Дата постановки',
      type: 'Date',
    },
    {
      field: 'дата снятия',
      text: 'Дата снятия',
      type: 'Date',
    },
  ],
};

export default serv;
