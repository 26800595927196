
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'Карточка соответствия услуг' },
  },
  data() {
    return {
      service: {} as any,
    };
  },
  computed: {
    serviceName(): string {
      return this.service?.значение ?? this.record.номерсторонний;
    },
  },
  methods: {
    onBeforeSave() {
      this.record.наименованиесторонний = this.serviceName;
      return true;
    },
  },
});
