
import Vue from 'vue';

import historyModal from './$folder/historymodal.vue';

export default Vue.extend({
  components: { historyModal },
  props: {
    constantsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      constModel: new this.$HttpModel('Константы'),
    };
  },
  methods: {
    async fetchData() {
      // TODO Зацикливание
      if (!this.record.$копирование) {
        // @ts-ignore
        await this.$refs.dialog.fetchData();
      }
    },
  },
});
