
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        ПогрешностьСравнения: 0.1,
        НМесяц: this.$store.getters.getWorkMonth(),
        КМесяц: this.$store.getters.getWorkMonth(),
        ВыборНач: 0,
        ВыборВидНач: 0,
      },
      objectService: [] as StackTableRow[],
    };
  },
});
