
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: () => ({}) },
  },
  computed: {
    delo(): number {
      return this.value['номерЗаписи'] ? this.value['номерЗаписи'] : -1;
    },
    queryParams(): any {
      return { ...this.record, ...this.value };
    },
  },

  data() {
    return {
      record: {
        руководитель: '',
        должность: '',
        основание: '',
      },
    };
  },
  methods: {
    onSignChange(record: StackTableRow) {
      if (record) {
        this.record.руководитель = record.руководитель as string || '';
        this.record.должность = record.должность as string || '';
        this.record.основание = record.основание as string || '';
      }
    },
  },
});
