
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      item: {
        флаги: 0,
        вид: 0,
        ипу: 0,
      },
    };
  },
  computed: {
    isFlagConters(): boolean {
      const flag = (this.item.флаги ? this.item.флаги : 0) as number;
      return flag % 2 >= 1;
    },
    isFlagAddress(): boolean {
      const flag = (this.item.флаги ? this.item.флаги : 0) as number;
      return flag % 4 >= 2;
    },
  },
});
