
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Параметры.ДатаВОтчет': this.$store.getters.getWorkMonth(),
        'Параметры.фВыводExcel': 0,
        'Параметры.@Услуга': -1,
        'Параметры.@Категория': -1,
      },
    };
  },
  methods: {
    onChangeUsl() {
      this.record['Параметры.@Категория'] = -1;
    },
    onChangeKat() {
      this.record['Параметры.@Услуга'] = -1;
    },
  },
});
