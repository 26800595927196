const accs: StackSelection = {
  objectName: 'ОбновлениеВерсии.ИзменяемыеТаблицы',
  description: '',
  fields: [
    {
      field: 'имя',
      text: 'Таблица',
      type: 'String',
    },
    {
      field: 'база',
      text: 'База',
      type: 'String',
    },
    {
      field: 'записей',
      text: 'Записей',
      type: 'Number',
    },
    {
      field: 'добавляются',
      text: 'Добавляются',
      type: 'Number',
    },
    {
      field: 'удаляются',
      text: 'Удаляются',
      type: 'Number',
    },
    {
      field: 'изменяется тип',
      text: 'Изменяется тип',
      type: 'Number',
    },
    {
      field: 'изменяется размер',
      text: 'Изменяется размер',
      type: 'Number',
    },
    {
      field: 'переименовываются',
      text: 'Переименовываются',
      type: 'Number',
    },
    {
      field: 'изменение умолчаний',
      text: 'Изменение умолчаний',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'проверено',
      text: 'Проверено',
      type: 'Number',
      width: '30',
    },
  ],
};
export default accs;
