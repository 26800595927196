const serv: StackSelection = {
  objectName: 'Улицы',
  description: 'Адресная система',
  hier: true,
  openLink: 'references-streets',
  prepareRow: row => {
    if (row.$этоПапка) {
      row.$название = `${row.сокращение} ${row.$название}`;
    }
    return row;
  },
  fields: [
    {
      field: 'сокращение',
      text: 'Сокращение',
      type: 'String',
      width: '100px',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
      computed: true,
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'до_после',
      text: 'До_после',
      type: 'Number',
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
    {
      field: 'кладр',
      text: 'Кладр',
      type: 'String',
    },
    {
      field: 'почтовый индекс',
      text: 'Почтовый индекс',
      type: 'Number',
    },
    {
      field: 'статус',
      text: 'Статус',
      type: 'Number',
    },
    {
      field: 'гуид',
      text: 'ГУИД',
      type: 'String',
    },
    {
      field: 'уид',
      text: 'УИД',
      type: 'String',
    },
    {
      field: 'окато',
      text: 'ОКАТО',
      type: 'String',
    },
    {
      field: 'октмо',
      text: 'ОКТМО',
      type: 'String',
    },
    {
      field: 'кодрегиона',
      text: 'Код региона',
      type: 'String',
    },
    {
      field: 'кодавтономии',
      text: 'Код автономии',
      type: 'String',
    },
    {
      field: 'кодрайона',
      text: 'Код района',
      type: 'String',
    },
    {
      field: 'кодгорода',
      text: 'Код города',
      type: 'String',
    },
    {
      field: 'кодграйона',
      text: 'Код гор. района',
      type: 'String',
    },
    {
      field: 'коднп',
      text: 'Код нас. пункта',
      type: 'String',
    },
    {
      field: 'кодулицы',
      text: 'Код улицы',
      type: 'String',
    },
    {
      field: 'допкод',
      text: 'Доп. код',
      type: 'String',
    },
    {
      field: 'доппкод',
      text: 'Доп. код2',
      type: 'String',
    },
    {
      field: 'кладр',
      text: 'Кладр',
      type: 'String',
    },
    {
      field: 'датаизменения',
      text: 'Дата изменения',
      type: 'Date',
    },
    {
      field: 'код улицы',
      text: 'Код УСЗН',
      type: 'String',
    },
    {
      field: 'код бти',
      text: 'Код БТИ',
      type: 'String',
    },
  ],
};

export default serv;
