import { render, staticRenderFns } from "./ОРГ_ОГР_КУ.vue?vue&type=template&id=67c6b522&"
import script from "./ОРГ_ОГР_КУ.vue?vue&type=script&lang=ts&"
export * from "./ОРГ_ОГР_КУ.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports