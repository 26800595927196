
import Vue from 'vue';
export default Vue.extend({
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Number, default: 0 },
    hours: { type: Number, default: 0 },
    noAdd: { type: Boolean, default: false },
  },
});
