import { TableHandlers } from '@/stackEngine';

export default {
  objectName: 'ЛицевыеСчета.Поставщики',
  description: 'Поставщики',
  prepareRow: row => {
    TableHandlers.isClosed(row);
    return row;
  },

  fields: [
    {
      field: 'номер услуги',
      text: '№',
      type: 'Number',
    },
    {
      field: 'счет-список поставщиков',
      text: 'счет-список поставщиков',
      type: 'Link',
    },
    {
      field: 'наименование',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: 'поставщики-список>название',
      text: 'Поставщики услуг',
      type: 'String',
    },
    {
      field: 'датнач',
      text: 'С',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'По',
      type: 'Date',
    },
    {
      field: 'поставщики-договоры ук>номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'поставщики-договоры ук>датнач',
      text: 'ДатНач',
      type: 'String',
    },
    {
      field: 'поставщики-список',
      text: 'Поставщики-Список',
      type: 'Link',
    },
    {
      field: 'услуги-список поставщиков',
      text: 'Услуги-список поставщиков',
      type: 'Link',
    },
    {
      field: 'принимаетвседолги',
      text: 'ПринимаетВсеДолги',
      type: 'Number',
    },
    {
      field: '@долгдатнач',
      text: 'ДолгДатНач',
      type: 'Date',
    },
    {
      field: '@долгдаткнц',
      text: 'ДолгДатКнц',
      type: 'Date',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: '@2номер',
      text: '@номер2',
      type: 'Number',
    },
  ],
} as StackSelection;
