
import Vue from 'vue';
import commonCounter from '@/tasks/common/views/$common/counters/_counterid.vue';
import CountersToolbar from './CountersToolbar.vue';

export default Vue.extend({
  components: { CountersToolbar, commonCounter },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
});
