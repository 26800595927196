
import Vue from 'vue';
import detailsModalDialog from './$details/detailsid.vue';
import AdrPrintBtn from '@/tasks/fl/views/accounts/$accountsid/AdrPrintBtn.vue';

export default Vue.extend({
  components: {
    detailsModalDialog, AdrPrintBtn,
  },
  props: {
    filterlsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
    title: { type: String, default: 'Фильтр' },
  },
  data() {
    return {
      record: {} as StackTableRow,
      dialog: false,
      loading: false,
      dataObject: new this.$HttpModel('ФильтрыЛС'),
    };
  },
  computed: {
    isNewDialog(): boolean {
      if (this.record && this.record.$номерЗаписи && +this.record.$номерЗаписи > 0 && !this.record.$копирование) {
        return false;
      }
      return true;
    },
    subtitle(): string {
      if (this.record['времяактуализации'] !== '') {
        return 'Актуализирован ' + this.record.автор + ' ' + this.$stackDate.format(this.record.времяактуализации, 'DD.MM.YYYY HH:mm:ss');
      } else {
        return '';
      }
    },
  },
  methods: {
    async runActualization() {
      this.loading = true;
      await new this.$HttpModel('ФильтрыЛС').executeMethod(
        'Актуализировать',
        { номерЗаписи: this.record.$номерЗаписи },
        {
          async: true,
          jobName: 'Актуализация набора ЛС по фильтру: ' + this.record.название,
          onComplete: () => {
            this.loading = false;
            this.$sendMsg('stack-table', 'ФильтрыЛС.Результат', 'reload');
            this.$sendMsg('stack-dialog', 'ФильтрыЛС', 'reload');
          },
          onError: () => {
            this.loading = false;
            this.$sendMsg('stack-table', 'ФильтрыЛС.Результат', 'reload');
            this.$sendMsg('stack-dialog', 'ФильтрыЛС', 'reload');
          },
        },
      );
    },
    async onClick(item: StackTableRow) {
      const id = item['результат-лицевой'];
      const routeData = this.$router.resolve({ path: `/accounts/${id}` });
      if (id && +id > 0) {
        window.open(routeData.href, '_blank');
      }
    },
  },
});
