import { VueConstructor } from 'vue';
import VueUploadComponent from 'vue-upload-component';
import StackWrapper from './components/StackWrapper/StackWrapper.vue';
import { wrapComponent } from './components/StackWrapper/utils';
import StackMsgDialog from './components/StackWrapper/StackMsgDialog.vue';
import StackYesNo from './components/StackWrapper/StackYesNo.vue';
import StackToast from './components/StackWrapper/StackToast.vue';
import StackInputField from './components/StackWrapper/StackInputField.vue';
import StackDialogBuilder from './components/StackWrapper/StackDialogBuilder.vue';
import { rules, stackDate } from './utils';
import { HttpModel, ReportsBuilder, StackApi, StaticModel, GraphqlModel } from './middleware/StackApi';
import {
  StackApp,
  TaskSwitchDialog,
  StackVersionLabel,
  FilterNavigationDrawer,
  FilterController,
  StackUndisable,
  StackInput,
  StackHistoryInput,
  StackInputLight,
  StackTextarea,
  StackDateField,
  StackDateTimeField,
  StackTimeField,
  StackMonthField,
  StackLinkField,
  StackCombobox,
  StackFlags,
  StackRadioBox,
  StackCheckBox,
  StackTree,
  StackTimeline,
  StackTimelineItem,
  StackTable,
  StackTableRow,
  StackTableToolbar,
  StackTableActionMenu,
  SearchToolbarAction,
  StackReadingsTable,
  StackTabs,
  StackTab,
  StackTabsItems,
  StackTabItem,
  StackDialog,
  StackModalDialog,
  StackActionDialog,
  StackListDialog,
  StackSelectDialog,
  StackReportDialog,
  StackCardList,
  StackCardButton,
  StackList,
  StackMenuList,
  StackReportList,
  StackMedia,
  StackAudio,
  StackBackground,
  StackFileInput,
  StackHelpIcon,
  StackInfoBox,
  StackEditor,
  StackContentMenu,
  StackActionBtn,
  StackPrintBtn,
  StackToolbarBtn,
  StackRestoreBtn,
  StackChipBtn,
  StackExpansionPanel,
  AsyncActionMenuMultiDialog,
  BlockedRecordsTable,
  SvgIcon,
  StackWaitOverlay,
  StackSwitch,
  StackSimpleDialog,
  StackMap,
} from './components';

// целое число
function toInt(value: number) {
  value = Number(value);
  if (isNaN(value)) {
    return '-';
  }
  return value.toFixed().toString();
}

// деньги
function toMoney(value: number) {
  value = Number(value);
  if (isNaN(value)) {
    return '-';
  }
  return value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ');
}

function toQuantity(value: string) {
  // @ts-ignore
  return value.toLocaleString('ru-RU', { maximumFractionDigits: 20 }).replace(',', '.');
}

function toDate(value: string | Date, formatStr = 'DD.MM.YYYYг.'): string {
  return value ? stackDate.format(value, formatStr) : '';
}

const StackEngine = {
  install(Vue: VueConstructor) {
    Vue.filter('int', toInt);
    Vue.filter('money', toMoney);
    Vue.filter('date', toDate);
    // @ts-ignore
    Vue.filter('datemonth', (value: string | Date, formatStr = 'MM.YYYYг.'): string => toDate(value, formatStr));
    Vue.filter(
      'format',
      (value: string, type: StackTypes): string => {
        if (!value && type !== 'NumberZero') {
          return '';
        }
        switch (type) {
          case 'Date': // дд.мм.гггг
            return toDate(value, 'DD.MM.YYYY');
          case 'DateMonth': // мм.гггг
            return toDate(value, 'MM.YYYY');
          case 'DateTime': // мм.гггг
            return toDate(value, 'DD.MM.YYYY HH:mm:ss');
          case 'Money': // деньги
            return toMoney(+value);
          case 'Quantity':
            return toQuantity(value);
          default:
            return value;
        }
      },
    );

    Vue.component('StackApp', StackApp);
    Vue.component('TaskSwitchDialog', TaskSwitchDialog);
    Vue.component('StackVersionLabel', StackVersionLabel);
    Vue.component('FilterNavigationDrawer', FilterNavigationDrawer);
    Vue.component('FilterController', FilterController);
    Vue.component('StackUndisable', StackUndisable);

    Vue.component('StackInput', StackInput);
    Vue.component('StackHistoryInput', StackHistoryInput);
    Vue.component('StackInputLight', StackInputLight);
    Vue.component('StackTextarea', StackTextarea);
    Vue.component('StackSwitch', StackSwitch);

    Vue.component('StackTable', StackTable);
    Vue.component('StackTableRow', StackTableRow);
    Vue.component('StackTableToolbar', StackTableToolbar);
    Vue.component('StackTableActionMenu', StackTableActionMenu);
    Vue.component('StackReadingsTable', StackReadingsTable);

    Vue.component('StackDateField', StackDateField);
    Vue.component('StackDateTimeField', StackDateTimeField);
    Vue.component('StackTimeField', StackTimeField);
    Vue.component('StackMonthField', StackMonthField);
    Vue.component('StackLinkField', StackLinkField);
    Vue.component('StackCombobox', StackCombobox);
    Vue.component('StackFlags', StackFlags);
    Vue.component('StackRadioBox', StackRadioBox);
    Vue.component('StackCheckBox', StackCheckBox);

    Vue.component('StackTree', StackTree);

    Vue.component('StackTabs', StackTabs);
    Vue.component('StackTab', StackTab);
    Vue.component('StackTabsItems', StackTabsItems);
    Vue.component('StackTabItem', StackTabItem);

    Vue.component('StackTimeline', StackTimeline);
    Vue.component('StackTimelineItem', StackTimelineItem);

    Vue.component('StackDialog', StackDialog);
    Vue.component('StackModalDialog', StackModalDialog);
    Vue.component('StackActionDialog', StackActionDialog);
    Vue.component('StackListDialog', StackListDialog);
    Vue.component('StackSelectDialog', StackSelectDialog);
    Vue.component('StackReportDialog', StackReportDialog);
    Vue.component('StackSimpleDialog', StackSimpleDialog);

    Vue.component('StackCardButton', StackCardButton);
    Vue.component('StackCardList', StackCardList);
    Vue.component('StackHelpIcon', StackHelpIcon);
    Vue.component('StackMedia', StackMedia);
    Vue.component('StackAudio', StackAudio);

    Vue.component('StackList', StackList);
    Vue.component('StackMenuList', StackMenuList);
    Vue.component('StackReportList', StackReportList);
    Vue.component('StackInfoBox', StackInfoBox);
    Vue.component('StackBackground', StackBackground);

    Vue.component('StackFileInput', StackFileInput);

    Vue.component('StackEditor', StackEditor);

    Vue.component('StackContentMenu', StackContentMenu);

    Vue.component('StackActionBtn', StackActionBtn);
    Vue.component('StackPrintBtn', StackPrintBtn);
    Vue.component('StackChipBtn', StackChipBtn);
    Vue.component('StackToolbarBtn', StackToolbarBtn);
    Vue.component('StackRestoreBtn', StackRestoreBtn);

    Vue.component('StackExpansionPanel', StackExpansionPanel);
    Vue.component('StackWrapper', StackWrapper);
    Vue.component('FileUpload', VueUploadComponent);
    Vue.component('AsyncActionMenuMultiDialog', AsyncActionMenuMultiDialog);
    Vue.component('SearchToolbarAction', SearchToolbarAction);
    Vue.component('BlockedRecordsTable', BlockedRecordsTable);
    Vue.component('StackWaitOverlay', StackWaitOverlay);

    Vue.component('StackMap', StackMap);

    Vue.component('SvgIcon', SvgIcon);

    Vue.prototype.$stackDialog = wrapComponent(StackDialogBuilder, 'scheme', 'record', 'params');
    Vue.prototype.$stackMsg = wrapComponent(StackMsgDialog, 'questions', 'title');
    Vue.prototype.$yesno = wrapComponent(StackYesNo, 'message', 'title', 'options');
    Vue.prototype.$toast = wrapComponent(StackToast, 'message', 'options');
    Vue.prototype.$inputField = wrapComponent(StackInputField, 'message', 'title', 'inputValue', 'options');
    Vue.prototype.$sendMsg = function (who: string, name: string, action: string) {
      this.$root.$emit('send-global-event', { who, name, action });
    };
    Vue.prototype.$stackDate = stackDate;
    Vue.prototype.$stackRules = rules;

    Vue.prototype.$StackApi = StackApi;
    Vue.prototype.$HttpModel = HttpModel;
    Vue.prototype.$GraphqlModel = GraphqlModel;
    Vue.prototype.$StaticModel = StaticModel;
    Vue.prototype.$ReportsBuilder = ReportsBuilder;
  },
};

export default StackEngine;
