
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        РасчНач: this.$store.getters.getWorkMonth(),
        РасчКнц: this.$store.getters.getWorkMonth(),
        ПереНач: '',
        ПереКнц: '',
        '@Услуга': -1,
        '@КатегорияУслуг': -1,
        '@Поставщик': -1,
        '@КатегорияПоставщиков': -1,
        '@УК': -1,
        фТолькоРуч: 0,
        Детализация: 0,
        фТолькоЗакр: 0,
      },
    };
  },
});
