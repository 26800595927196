const serv: StackSelection = {
  recordRoute: 'references-services-servicesid',
  folderRoute: 'fservice',
  objectName: 'Услуги',
  linkField: 'номер услуги',
  description: 'Услуги',
  hier: true,
  openLink: 'references-services',
  prepareRow: row => {
    if (row.$этоПапка) {
      row.$название = row['номер услуги'] + ' - ' + row.$название;
    }
    return row;
  },
  fields: [
    {
      field: 'название',
      text: 'Краткое название',
      type: 'String',
    },
    {
      field: 'наименование',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'внешний код 1',
      text: 'Внешний код 1',
      type: 'String',
    },
    {
      field: 'внешний код 2',
      text: 'Внешний код 2',
      type: 'String',
    },
    {
      field: 'счет1',
      text: 'Счет1',
      type: 'String',
    },
    {
      field: 'счет2',
      text: 'Счет2',
      type: 'String',
    },
    {
      field: 'единица измерения',
      text: 'Единица измерения',
      type: 'String',
    },
    {
      field: 'платежная группа',
      text: 'Платежная группа',
      type: 'Number',
    },
    {
      field: 'группа',
      text: 'Группа оптимизации льгот',
      type: 'String',
    },
    {
      field: 'параметры услуги',
      text: 'Параметры услуги',
      type: 'String',
    },
    {
      field: 'признаки',
      text: 'Признаки',
      type: 'String',
    },
    {
      field: 'поставщик по умолчанию',
      text: 'Поставщик по умолчанию',
      type: 'Number',
    },
    {
      field: '@тариф',
      text: 'Тариф',
      type: 'Quantity',
      computed: true,
    },
    {
      field: '@сверхтариф',
      text: 'Тариф сверх нормы',
      type: 'Number',
      computed: true,
    },
    {
      field: '@норма',
      text: 'Норма',
      type: 'Number',
      computed: true,
    },
    {
      field: 'тип отображения',
      text: 'Тип отображения',
      type: 'Number',
    },
    {
      field: '@уктариф',
      text: 'УКТариф',
      type: 'Number',
      computed: true,
    },
    {
      field: '@индтариф',
      text: 'ИндТариф',
      type: 'Number',
      computed: true,
    },
    {
      field: '@калькуляция',
      text: 'Калькуляция',
      type: 'Number',
      computed: true,
    },
    {
      field: 'группа счетчика',
      text: 'группа счетчика',
      type: 'Link',
    },
    {
      field: '@энерго',
      text: '@Энерго',
      type: 'Number',
      computed: true,
    },
    {
      field: 'управление',
      text: 'Управление',
      type: 'Number',
    },
    {
      field: 'налог1',
      text: 'Налог 1',
      type: 'Number',
    },
    {
      field: 'налог2',
      text: 'Налог 2',
      type: 'Number',
    },
    {
      field: 'склад-услуги',
      text: 'Номенклатура',
      type: 'Link',
    },
    {
      field: 'склад-услуги>наименование',
      text: 'Номенклатура.Наименование',
      type: 'String',
    },
    {
      field: 'склад-услуги>номномер',
      text: 'Номенклатура.НомНомер',
      type: 'String',
    },
  ],
};

export default serv;
