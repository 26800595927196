
import Vue from 'vue';

interface StackTableRows {
  [index: number]: StackTableRow;
}

export default Vue.extend({
  props: {
    dataModel: { type: [String, Object], required: true },
    dataModelPok: { type: [String, Object], default: 'Счетчики.ТипыВвода' }, // модель для видов показаний
    /**
     * row_id счетчика или лицевого
     */
    parentID: { type: [String, Number], default: null },
    /**
     * row_id ведомости показаний
     */
    vedID: { type: [String, Number], default: null },
    title: { type: String, default: 'Ввод показаний по лицевому счёту' },
    /**
     * показать link для выбора лицевого и убираем условие даты, которое актуально для внесения с карточки лицевого
     */
    selectLS: { type: Boolean, default: false },
    showDateVed: { type: Boolean, default: true }, // отображать ли дату и месяц у ведомости, актуально для ведомости показания ипу
    month: { type: [Date, String], default: null },
    date: { type: [Date, String], default: null },
    // добавил свойства для использования в длж фл
    typeinput: { type: [Number, String], default: null }, // Тип ввода
    delo: { type: [Number, String], default: null }, // row_id дела
    save: { type: [Boolean], default: false }, // флаг сохранения , сохранять ли сразу при вводе
    selecttypepok: { type: [Boolean], default: false }, // можно ли править тип показания (disabled)
    showDate: { type: [Boolean], default: false }, // отображать ли дату у каждого показания
    isGroup: { type: [Boolean], default: false }, // ввод со счетчика ОДПУ
    notMass: { type: [Boolean], default: false }, // создание ведомости без примечания массового ввода
    noController: { type: [Boolean], default: false }, // Не выводить поле связи на контролёра при вводе контрольных показаний
    needRecalcLs: { type: [Boolean], default: false }, // при отправке показаний нужно ли делать перерасчет по лс
  },
  computed: {
    inputFilter(): object {
      return { владелец: this.лицевой ? this.лицевой : this.parentID, ведомость: this.vedID, типввода: this.typeinput, дело: this.delo };
    },
    dataFilter(): object {
      return { дата: this.дата, месяц: this.месяц, типввода: this.типввода, дело: this.delo, сохранитьпоказания: this.save, состояниедела: this.parentID };
    },
    noShowDate(): boolean {
      if (this.showDate) {
        return false;
      } else {
        return !this.selectLS;
      }
    },
  },
  data() {
    return {
      error: true,
      дата: this.date ? this.date : this.$store.getters.getCurrentDate(),
      месяц: this.month ? this.month : this.$stackDate.firstDay(this.$store.getters.getCurrentDate()),
      типввода: this.typeinput ? Number(this.typeinput) : 1,
      лицевой: null,
      контролер: -1,
      rules: Object.assign(this.$stackRules, {
        // При вводе показаний группового дата показания должна быть в расчетном месяце
        inCorrectMonth: (date1: string, date2: string): string | true => {
          return this.$stackDate.firstDay(date1) === this.$stackDate.firstDay(date2) || 'Дата показаний должна быть в расчетном месяце';
        },
      }),
      isBusy: false, // признак что что-то выполняется
    };
  },
  methods: {
    async sendReadings() {
      this.error = true;
      let result;
      if (this.контролер > 0) {
        // @ts-ignore
        result = await this.$refs.table.sendReadings(undefined, { контролер: this.контролер });
      } else {
        // @ts-ignore
        result = await this.$refs.table.sendReadings();
      }
      this.error = false;
      if (result) {
        if (this.selectLS && this.showDateVed) {
          this.лицевой = null;
          // @ts-ignore
          this.$refs.linkField.focus();
          this.error = true;
        } else {
          this.$emit('close');
          this.$emit('save');
        }
      } else {
        this.error = true;
      }
    },
  },
});
