const ls: StackSelection = {
  objectName: 'ДелениеЛицевого.Жильцы до',
  description: 'Старый лицевой',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.титульный === 1) {
      // @ts-ignore
      row.$data.титульныйиконка = { $иконка: '$vuetify.icons.check' };
    }
    if (row.доквлад === 1) {
      // @ts-ignore
      row.$data.доквладиконка = { $иконка: '$vuetify.icons.check' };
    }
    return row;
  },
  fields: [
    {
      field: 'видрег',
      text: 'Вид регистрации',
      type: 'String',
      width: '50px',
      readonly: true,
    },
    {
      field: 'row_id',
      text: 'ROW_ID',
      type: 'Number',
      readonly: true,
    },
    {
      field: 'dv_id',
      text: 'DV_ID',
      type: 'Number',
      readonly: true,
    },
    {
      field: 'kr_id',
      text: 'KR_ID',
      type: 'Number',
      readonly: true,
    },
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
      readonly: true,
    },
    {
      field: 'доквлад',
      text: 'Собственник',
      type: 'Number',
      readonly: true,
    },
    {
      field: 'титульный',
      text: 'Титульный',
      type: 'Number',
      readonly: true,
    },
    {
      field: 'доквладиконка',
      text: 'Собственник',
      type: 'Number',
      align: 'center',
      computed: true,
    },
    {
      field: 'титульныйиконка',
      text: 'Титульный',
      type: 'Number',
      align: 'center',
      computed: true,
    },
  ],
};

export default ls;
