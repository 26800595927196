
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    selectedItems: { type: Array as PropType<StackTableRow[]>, default: undefined },
    selectedValues: { type: [String, Number], default: undefined },
    dataObject: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    noPrintRec: { type: Boolean, default: false },
  },
  computed: {
    disablePrint(): boolean {
      let cont = false;
      this.selectedItems.forEach((item: StackTableRow) => {
        if (item.тип !== 5) {
          cont = true;
        }
      });
      return !this.selectedValues ? true : cont;
    },
  },
  methods: {
    async onBtnPrintClick(reportName: string, favorite = false) {
      // let selectedValues = this.selectedValues;
      // const models = ['РеестрыЛицевых', 'РеестрыУК'];
      // if (models.includes(this.dataObject.model)) {
      //   selectedValues = await this.dataObject.executeMethod('ПолучитьЛицевые', { лицевые: selectedValues });
      // }
      await new this.$ReportsBuilder(reportName).executeReport2(
        reportName,
        {
          'Список лицевых': this.selectedValues,
          номерЗаписи: this.selectedValues,
          имяВыборки: this.dataObject.model,
        },
        { params: { favorite, selectionName: this.dataObject.model } },
      );
    },
  },
});
