
import Vue from 'vue';

export default Vue.extend({
  props: {
    dataObject: { type: Object, required: true },
    selectedItems: { type: Array, default: undefined },
    selectedValues: { type: [String, Number], default: undefined },
  },
  methods: {
    async onBtnPrintClick(reportName: string) {
      await new this.$ReportsBuilder(reportName).executeReport2(
        reportName,
        {},
        {
          params: {
            договор: this.selectedValues,
            номерЗаписи: this.selectedValues,
            имяВыборки: this.dataObject.model,
          },
        },
      );
    },
  },
});
