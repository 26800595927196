const par: StackSelection = {
  objectName: 'ФильтрыЛС.Детализация',
  fields: [
    {
      field: 'фильтр-заголовок',
      text: 'Заголовок фильтра',
      type: 'Link',
    },
    {
      field: '@тип',
      text: 'Тип фильтра',
      type: 'String',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'опция',
      text: 'Опция',
      type: 'Number',
    },
    {
      field: 'наличие',
      text: 'Наличие',
      type: 'Number',
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
    {
      field: 'открытиес',
      text: 'Начало интервала открытия',
      type: 'Date',
    },
    {
      field: 'открытиепо',
      text: 'Конец интервала открытия',
      type: 'Date',
    },
    {
      field: 'закрытиес',
      text: 'Начало интервала закрытия',
      type: 'Date',
    },
    {
      field: 'закрытиепо',
      text: 'Конец интервала закрытия',
      type: 'Date',
    },
    {
      field: 'датаповерки',
      text: 'Дата поверки',
      type: 'Date',
    },
    {
      field: 'фильтр-параметр',
      text: 'Параметр',
      type: 'Link',
    },
    {
      field: 'фильтр-параметр>название',
      text: 'Название параметра',
      type: 'String',
    },
    {
      field: 'фильтр-услуга',
      text: 'Услуга',
      type: 'Link',
    },
    {
      field: 'фильтр-организация',
      text: 'Организация',
      type: 'Link',
    },
    {
      field: 'описание',
      text: 'Описание',
      type: 'String',
    },
  ],
};

export default par;
