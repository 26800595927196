
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'Услуга' },
    hideTarif: { type: Boolean, default: false },
    hideDate: { type: Boolean, default: false },
    hideState: { type: Boolean, default: false },
  },
});
