
import Vue from 'vue';
import CommonAddressFilterElement from './CommonAddressFilterElement.vue';
import ControlDeviceAddressFilterElement from './ControlDeviceAddressFilterElement.vue';
import AddressFilterElement from './AddressElementFilter.vue';

export default Vue.extend({
  components: {
    'common-filter-element': CommonAddressFilterElement,
    'control-device-filter-element': ControlDeviceAddressFilterElement,
    'address-filter-element': AddressFilterElement,
  },
  props: {},
  data() {
    return {
      params: {} as any,
      defaultFilter: {
        НомерТочки: '' as string,
        СтороннийНомер: '' as string,

        ЮлФл: '' as string,
        Действует: '' as string,
        Договор: 0 as number,
        ФильтрСетьЗнак: false as boolean,
        РежимПоискаСети: 0 as number,
        ТипСети: 0 as number,
        Сеть: 0 as number,
        ФильтрПараметрЗнак: false as boolean,
        Параметр: '' as string,
        ПараметрЗначение: '' as string,

        ЗаводскойНомер: '' as string,
        Марка: 0 as number,
        ФильтрПоказанияЗнак: false as boolean,
        ПоказанияЗнак: '' as string,
        ПоказанияЗнач: '' as string,

        ФильтрОбходыЗнак: false as boolean,
        ОбходыЗнак: '' as string,
        ОбходыЗнач: '' as string,

        ф_нп: -1,
        ф_улица: -1,
        ф_дом: -1,
        ф_квартира: -1,
      } as any,
    };
  },
  created() {
    this.params = Object.assign({}, this.defaultFilter);
  },
});
