const cmonth: StackSelection = {
  objectName: 'ЗакрытиеМесяца.ИсторияОпераций',
  description: 'История операций',
  fields: [
    {
      field: 'реестр',
      text: 'Реестр',
      type: 'Number',
    },
    {
      field: 'время',
      text: 'Время',
      type: 'String',
    },
    {
      field: 'имяпользователя',
      text: 'Пользователь',
      type: 'String',
    },
    {
      field: 'компьютер',
      text: 'Компьютер',
      type: 'String',
    },
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'String',
    },
    {
      field: 'режим',
      text: 'Операция',
      type: 'String',
    },
    {
      field: 'действие',
      text: 'Действие',
      type: 'String',
    },
  ],
};

export default cmonth;
