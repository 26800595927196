
import Vue from 'vue';
import StackRssFeedItem from './StackRssFeedItem.vue';
import Parser from 'rss-parser';

export default Vue.extend({
  components: {
    StackRssFeedItem,
  },
  props: {
    url: { type: String, required: true },
    value: { type: Boolean, required: true },
  },
  data() {
    return {
      feed: {} as any,
      rssParser: new Parser(),
      isLoading: true,
      isLoaded: false,
    };
  },
  methods: {
    async readRSS() {
      this.isLoading = true;
      try {
        this.feed = await this.rssParser.parseURL(this.url);
        this.isLoaded = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(to: boolean) {
        if (to && !this.isLoaded) {
          this.readRSS();
        }
      },
    },
  },
});
