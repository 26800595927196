
import Vue from 'vue';

export default Vue.extend({
  props: {
    disabled: { type: Boolean, required: true },
    dataModel: { type: Object, required: true },
    fund: { type: String, default: null },
    accounts: { type: String, default: null },
    month: { type: [String, Date], default: null },
  },
  data() {
    return {
      jobID: null as null | number,
      noError: false,
    };
  },
  computed: {
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
  },
  methods: {
    end() {
      if (this.noError) {
        this.$emit('end', 'success');
      }
    },
    async RunAvansPrepare() {
      const params = { фонд: this.fund, месяц: this.month, лицевые: this.accounts };
      this.jobID = (await this.dataModel.executeMethod('авансыРассчет', params, { async: true })).asyncId;
      this.$store.commit('MSG_ADD', { title: 'Рассчёт авансов', asyncId: this.jobID, external: true });
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.$store.commit('MSG_DELETE', this.jobID);
        this.jobID = null;
        // this.$emit('end', 'success');
        this.noError = true;
      }
    },
    isError(to) {
      if (to) {
        this.$toast(this.resultJob.status, { color: 'error' });
        this.$store.commit('MSG_DELETE', this.jobID);
        this.jobID = null;
        this.$emit('end', { status: 'error' });
        this.noError = false;
      }
    },
  },
});
