const lg: StackSelection = {
  recordRoute: 'references-lgoty-lgotyid-lgotylist-lgotylistid',
  objectName: 'Льготы.Список',
  description: 'Льготы список',
  fields: [
    {
      field: 'наименование',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'ключевая фраза',
      text: 'Правило операции',
      type: 'String',
    },
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'скидка',
      text: 'Скидка',
      type: 'Number',
    },
    {
      field: 'на кого',
      text: 'На кого',
      type: 'Number',
    },
    {
      field: '@на кого',
      text: 'На кого',
      type: 'Enum',
    },
    {
      field: 'услуга-состав льготы"',
      text: 'Услуга-Состав льготы"',
      type: 'Link',
    },
    {
      field: 'правило-льготы',
      text: 'Правило-Льготы',
      type: 'Link',
    },
    {
      field: 'порядок расчета',
      text: 'Порядок расчета',
      type: 'Number',
    },
    {
      field: 'датнач',
      text: 'С',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'По',
      type: 'Date',
    },
    {
      field: 'условие',
      text: 'Условие',
      type: 'Number',
    },
  ],
};
export default lg;
