const payments: StackSelection = {
  recordRoute: 'paypeni',
  objectName: 'ПлатежныеВедомости.ПлатежиВедомости.Пени',
  description: '',
  fields: [
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Number',
    },
    {
      field: 'распределение-ук>название',
      text: 'Управляющая компания',
      type: 'String',
    },
    {
      field: 'распределение-пост>название',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'распределение-ук',
      text: 'Управляющая компания',
      type: 'Link',
    },
    {
      field: 'распределение-пост',
      text: 'Поставщик',
      type: 'Link',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'распределение-услуга',
      text: 'Услуга',
      type: 'Link',
    },
  ],
};
export default payments;
