
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
    params: {
      type: Object,
      default: (): any => {
        return {};
      },
    },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        ДатКнц: this.params['ДатКнц'] ? this.params['ДатКнц'] : this.$store.getters.getWorkMonth(),
      },
    };
  },
});
