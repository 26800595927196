
import Vue from 'vue';

export default Vue.extend({
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    dataTable(): StackTableRow[] {
      if (this.record) {
        const znachenie = this.record.значение.split(',');
        const znacheniemass = [];
        let index = 0;
        let nameindex = 0;
        for (const item of znachenie) {
          if (znachenie[index].trim() === '') {
            break;
          }
          znacheniemass.push({ название: nameindex, сумма: znachenie[index + 1] });
          index += 2;
          nameindex++;
        }
        return znacheniemass;
      }

      return [];
    },
  },
  data() {
    return {
      isChange: false,
      header: [
        {
          field: 'название',
          text: 'Тип задолженности',
          dataModel: 'ТипДолга',
          type: 'Enum',
          itemValue: 'ключ',
        },
        {
          field: 'сумма',
          text: 'Сумма долга',
          type: 'String',
        },
      ],
    };
  },
  methods: {
    changeFunc(items: StackTableRow[]) {
      if (this.isChange) {
        let newVal = '';
        let index = 0;
        for (const item of items) {
          newVal += index + ',' + items[index++].сумма + ',';
        }
        this.$set(this.record, 'значение', newVal);
      }
      this.isChange = true;
    },
  },
});
