
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
    params: {
      type: Object,
      default: (): any => {
        return {};
      },
    },
  },
  computed: {
    dateStartLabel(): string {
      return this.params.flagtransfer ? 'Месяц сальдо для переноса' : 'Период с';
    },
    dateEndLabel(): string {
      return this.params.flagtransfer ? 'Месяц, в который переносим' : 'по';
    },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        МесНач: this.$store.getters.getWorkMonth(),
        МесКнц: this.$store.getters.getWorkMonth(),
      },
    };
  },
});
