const orgs: StackSelection = {
  recordRoute: 'references-organizations-resources-resourcesid',
  objectName: 'Организации.Поставщики',
  description: 'Поставщики',
  hier: true,
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
    {
      field: 'инн',
      text: 'ИНН',
      type: 'String',
    },
    {
      field: 'кпп',
      text: 'КПП',
      type: 'String',
    },
    {
      field: 'оконх',
      text: 'Категория',
      type: 'String',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'фактадрес',
      text: 'ФактАдрес',
      type: 'String',
    },
    {
      field: 'огрн',
      text: 'ОГРН',
      type: 'String',
    },
    {
      field: 'телефон',
      text: 'Телефон',
      type: 'String',
    },
    {
      field: 'email',
      text: 'Почта',
      type: 'String',
    },
    {
      field: 'www',
      text: 'Сайт',
      type: 'String',
    },
    {
      field: 'режим работы',
      text: 'Режим работы',
      type: 'String',
    },
    {
      field: 'оквэд',
      text: 'ОКВЭД',
      type: 'String',
    },
    {
      field: 'окпо',
      text: 'ОКПО',
      type: 'String',
    },
    {
      field: 'окдп',
      text: 'ОКДП',
      type: 'String',
    },
    {
      field: 'окато',
      text: 'ОКАТО',
      type: 'String',
    },
    {
      field: 'часы работы бухгалтерии',
      text: 'Часы работы бухгалтерии',
      type: 'String',
    },
    {
      field: 'часы работы паспортного',
      text: 'Часы работы паспортного',
      type: 'String',
    },
    {
      field: 'часы работы кассы',
      text: 'Часы работы кассы',
      type: 'String',
    },
    {
      field: 'факс',
      text: 'Факс',
      type: 'String',
    },
    {
      field: 'комсбор',
      text: 'КомСбор',
      type: 'Number',
    },
    {
      field: 'вариант ндс',
      text: 'Вариант НДС',
      type: 'Number',
    },
    {
      field: 'расчетныепризнаки',
      text: 'РасчетныеПризнаки',
      type: 'Number',
    },
    {
      field: 'бюджет',
      text: 'Вид',
      type: 'Number',
    },
    {
      field: 'отрасль',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'категория',
      text: 'Категория должника',
      type: 'Number',
    },
    {
      field: 'датарегистрации',
      text: 'Дата регистрации',
      type: 'Date',
    },
    {
      field: 'даталиквидации',
      text: 'Дата ликвидации',
      type: 'Date',
    },
    {
      field: 'днаименование',
      text: 'Дательный падеж',
      type: 'String',
    },
    {
      field: 'рнаименование',
      text: 'Родительный падеж',
      type: 'String',
    },
    {
      field: 'тнаименование',
      text: 'Творительный падеж',
      type: 'String',
    },
    {
      field: 'инт_исполнитель',
      text: 'инт_исполнитель',
      type: 'String',
    },
    {
      field: 'количестволицензий',
      text: 'количестволицензий',
      type: 'Number',
    },
  ],
};

export default orgs;
