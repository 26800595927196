
import Vue from 'vue';
import datnachDatend from './$datNachdatEnd.vue';

export default Vue.extend({
  components: { datnachDatend },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    hideDate: { type: Boolean, default: false },
  },
  computed: {
    workMonth(): string {
      return this.$store.getters.getWorkMonth();
    },
    isDisabled(): boolean {
      return this.record['значение'] === 0;
    },
  },
  watch: {
    'record.значение': {
      handler(to) {
        if (to === 0) {
          this.record['знач2'] = null;
        }
      },
    },
  },
});
