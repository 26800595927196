
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    /**
     * описание полей таблицы
     */
    header: { type: [Array, Object], required: true },
    /**
     * Надо ли показывать дату константы
     */
    noDate: { type: Boolean, default: false },
    /**
     * переопределение заголовка диалога
     */
    titleFunc: {
      type: Function,
      default: (item: StackTableRow) => {
        return (item && item['полное имя'] ? item['полное имя'] : '') as string;
      },
    },
    /**
     * Убрать возможность добавления и удаления строк
     */
    noRowActions: { type: Boolean, default: false },
    /**
     * Убрать только возможность удаления строк
     */
    noDelete: { type: Boolean, default: false },
    /**
     * вывод в простую таблицу из поля значения по разделителю , ; и тд.
     */
    otherSeparator: { type: String, default: '' },
    description: { type: String, default: '' },
    /**
     * Кастомный обработчик
     */
    rowhandler: {
      type: Function,
      default: (item: StackTableRow): StackTableRow => {
        return item;
      },
    },
    // коллбек на изменение поля в инлайн
    onInlineFieldChange: {
      type: Function,
      default: (item: StackTableRow, columnName: string): StackTableRow => {
        return item;
      },
    },
    disableSaveBtn: { type: Boolean, default: false },
  },
  computed: {
    title(): string {
      // @ts-ignore
      return this.titleFunc(this.record);
    },
    dataTable(): StackTableRow[] {
      if (Array.isArray(this.record.значение)) {
        return this.record.значение;
      }

      if (this.otherSeparator.length > 0) {
        if (this.record && this.record.значение) {
          return this.record.значение.split(this.otherSeparator).map((value: string | number) => {
            value = this.header[0].type === 'Enum' || this.header[0].type === 'number' ? +value : value;
            return { значение: value };
          });
        } else {
          return [];
        }
      } else {
        return this.record && this.record.значение ? JSON.parse(this.record.значение) : [];
      }
    },
  },
  data() {
    return {
      isChange: false,
    };
  },
  methods: {
    // TODO подумать как переделать без this.$set
    changeFunc(items: StackTableRow[]) {
      const sItems = items.sort((a, b) => Number(a.$index) - Number(b.$index));
      // в первый раз просто загрузка записи
      if (this.isChange) {
        // облегчаем объект (убираем лишние данные вроде $data и тп)
        const arrayTemp = [];
        for (const item of sItems) {
          let objTemp = {};
          for (const ind in item) {
            if (ind.indexOf('$') < 0 || ind === '$толькоЧтение') {
              objTemp = Object.assign({}, objTemp, { [ind]: item[ind] });
            }
          }
          arrayTemp.push(objTemp);
        }
        const str =
          this.otherSeparator.length === 0
            ? JSON.stringify(arrayTemp)
            : arrayTemp
              .map((value: StackTableRow) => {
                return value.значение;
              })
              .join(this.otherSeparator);

        this.$set(this.record, 'значение', str);
      }
      this.isChange = true;
      this.$emit('update:tableItems', sItems);
    },
  },
});
