
import Vue from 'vue';

import errorDialog from './error.vue';

interface DataMsgs {
  [index: number]: string;
}

export default Vue.extend({
  components: { errorDialog },
  props: {
    month: { type: [String, Date], default: null },
  },
  data() {
    return {
      jobID: null as null | number,
      items: [] as StackTableRow[],
      noError: false,
      dataObject: new this.$HttpModel('ПравилаПроверкиБазы'),
      errorDialog: false,
    };
  },
  computed: {
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
    dataResult(): DataMsgs {
      if (this.resultJob.data) {
        if (this.resultJob.data.result && this.resultJob.data.result.ПРАВИЛА) {
          return this.resultJob.data.result.ПРАВИЛА;
        } else if (this.resultJob.data.state && this.resultJob.data.state.ПРАВИЛА) {
          return this.resultJob.data.state.ПРАВИЛА;
        }
      }
      return this.resultJob.status ? { 0: this.resultJob.status } : {};
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const data = await this.dataObject.getRecords();
      for (const key in data) {
        this.items.push(data[key]);
      }
    },
    async runControl() {
      this.jobID = (await this.dataObject.executeMethod('запускПравил', { месяц: this.month }, { async: true })).asyncId;
      this.$store.commit('MSG_ADD', { title: 'Проверка базы', asyncId: this.jobID, external: true });
      for (const ind in this.items) {
        this.iconStatus(this.items[ind], '');
      }
      this.$emit('run');
    },
    iconStatus(item: StackTableRow, status: string) {
      switch (status) {
        case 'выполнено':
          item.icon = '$vuetify.icons.check';
          item.color = 'success';
          break;
        case 'ошибка':
          item.icon = '$vuetify.icons.error';
          item.color = 'error';
          break;
        case 'предупреждение':
          item.icon = '$vuetify.icons.error';
          item.color = 'warning';
          break;
        default:
          item.icon = '';
          item.color = '';
          break;
      }
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.noError = true;
        for (const ind in this.dataResult) {
          for (const indIt in this.items) {
            if (this.items[indIt].id === +ind) {
              this.iconStatus(this.items[indIt], this.dataResult[ind]);
              if (this.dataResult[ind] !== 'выполнено') {
                this.noError = false;
              }
              break;
            }
          }
        }
        this.$store.commit('MSG_DELETE', this.jobID);
        this.jobID = null;
        if (this.noError) {
          this.$emit('end', 'success');
        } else {
          this.$emit('end', 'error');
        }
      }
    },
    isError(to) {
      if (to) {
        this.noError = false;
        for (const ind in this.items) {
          if (this.items[ind].icon === '') {
            this.iconStatus(this.items[ind], 'ошибка');
          }
        }
        this.$store.commit('MSG_DELETE', this.jobID);
        this.jobID = null;
        this.$emit('end', 'error');
      }
    },
    dataResult(to) {
      if (to[0] && !this.isComplete && !this.isError) {
        for (const ind in to) {
          for (const indIt in this.items) {
            if (this.items[indIt].id === +ind) {
              this.iconStatus(this.items[indIt], to[ind]);
              break;
            }
          }
        }
      }
    },
  },
});
