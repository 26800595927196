const reports: StackSelection = {
  objectName: 'МакетыКвитанций.Псевдонимы',
  description: '',
  fields: [
    {
      field: 'номусл',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'услуга-макет',
      text: 'Услуга',
      type: 'Link',
      dataModel: 'Услуги',
      labelField: record => {
        return '№' + record['номер услуги'] + ' ' + record.название;
      },
      headers: 'наименование,номер услуги',
      hier: true,
    },
    {
      field: 'псевдоним',
      text: 'Псевдоним',
      type: 'String',
    },
    {
      field: 'макет-состав',
      text: 'макет',
      type: 'Number',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
  ],
};
export default reports;
