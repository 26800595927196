
import Vue from 'vue';
import { TableHandlers } from '@/stackEngine';
import ReadingDialog from '@/tasks/fl/views/$common/$readingdialog/ReadingDialog.vue';
import ReadingInputDialog from '@/tasks/fl/views/$common/$readingdialog/ReadingInputDialog.vue';
import readingHistoryGSDialog from './$poktabs/$dialogs/readingHistoryGSDialog.vue';
import GroupReadingDialog from '@/tasks/fl/views/metersdata/groupcounters/$dialogs/$groupReadingDialog/GroupReadingDialog.vue';

export default Vue.extend({
  components: { ReadingDialog, readingHistoryGSDialog, ReadingInputDialog, GroupReadingDialog },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    pokMonth(): Date {
      const work = this.$store.getters.getWorkMonth();
      const open = this.$store.getters.getOpenMonth();
      return Date.parse(work) > Date.parse(open) ? work : open;
    },
    inputFilter(): object {
      return { владелец: this.record.$номерЗаписи, месяц: this.месяц };
    },
  },
  created() {
    this.месяц = this.pokMonth;
  },
  data() {
    return {
      месяц: null as Date | null,
    };
  },
  methods: {
    saveReadings() {
      // @ts-ignore
      this.$refs.pokIPUtable.modalEventBus('close');
      this.$sendMsg('stack-table', 'ЛицевыеСчета.ПоказанияСчетчиковИндивидуальные', 'reload');
      this.$sendMsg('stack-table', 'ЛицевыеСчета.ПоказанияСчетчиковОбщеквартирные', 'reload');
    },
    selectDialog(item: StackTableRow) {
      const idItem = item && item.$номерЗаписи ? item.$номерЗаписи.toString() : '';
      return idItem.indexOf('new') < 0;
    },
  },
});
