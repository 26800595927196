const reports: StackSelection = {
  objectName: 'МакетыКвитанций.Фильтр',
  description: '',
  fields: [
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'макет-состав',
      text: 'Макет',
      type: 'Link',
    },
    {
      field: 'макетсвойство',
      text: 'Включен',
      type: 'Enum',
      dataModel: 'МакетыКвитанций.Фильтр.МакетСвойство',
      width: '50px',
    },
    {
      field: 'услуга-макет',
      text: 'Услуга',
      type: 'Link',
      dataModel: 'Услуги',
      labelField: record => {
        return '№' + record['номер услуги'] + ' ' + record.название;
      },
      headers: 'наименование,номер услуги',
      hier: true,
    },
    {
      field: 'поставщик-макет',
      text: 'Получатель',
      type: 'Link',
      dataModel: 'Организации',
      labelField: 'название',
      headers: 'название,инн,кпп',
      hier: true,
    },
    {
      field: 'ук-макет',
      text: 'УК',
      type: 'Link',
      dataModel: 'Организации',
      labelField: 'название',
      headers: 'название,инн,кпп',
      hier: true,
    },
  ],
};
export default reports;
