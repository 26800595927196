
import Vue from 'vue';
import CountersTab from './$dialogs/CountersTab.vue';
import HistoryTab from './$dialogs/HistoryTab.vue';
import LSTab from './$dialogs/LSTab.vue';
import ParamTab from './$dialogs/ParamTab.vue';
import PokTab from './$dialogs/PokTab.vue';
import LogTab from './$dialogs/LogTab.vue';
import MonthSelectDialog from './$dialogs/$monthSelectDialog/MonthSelectDialog.vue';

export default Vue.extend({
  components: {
    'counters-tab': CountersTab,
    'history-tab': HistoryTab,
    'ls-tab': LSTab,
    'param-tab': ParamTab,
    'pok-tab': PokTab,
    'month-select-dialog': MonthSelectDialog,
    'log-tab': LogTab,
  },
  props: {
    groupcountersid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      monthSelectDialogVis: false,
      dataObject: new this.$HttpModel('ГрупповыеСчетчики'),
      jobID: null as null | string,
      isChanged: false,
    };
  },
  computed: {
    title(): string {
      return `Групповой счётчик № ${this.record.номер} от ${this.$stackDate.format(this.record.дата as string, 'DD.MM.YYYYг.')}`;
    },
    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
    closeMonth(): Date {
      return this.$store.getters.getCloseMonth();
    },
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
    isNewRecord(): boolean {
      return !(this.record && this.record.$номерЗаписи && +this.record.$номерЗаписи > 0);
    },
  },
  methods: {
    onBtnCalcClick() {
      if (this.record.$естьИзменения) {
        this.$toast('Есть измененные данные! Необходимо сохранить документ!', { color: 'error' });
        return;
      }
      this.monthSelectDialogVis = true;
    },
    onUpdateLs(items: StackTableRow[]) {
      if (this.record.$естьИзменения) {
        // @ts-ignore
        this.$refs.dialog.fetchData();
      }
    },
    updatePok(payload: string) {
      if (payload === 'pok') {
        this.$sendMsg('stack-table', 'ГрупповыеСчетчики.Показания', 'reload');
      }
    },
    async calcODPU(month: any) {
      this.monthSelectDialogVis = false;

      if (month && this.record.$номерЗаписи && this.record.$номерЗаписи > 0) {
        const obj = this.dataObject as DataModel;
        if (obj.executeMethod) {
          try {
            this.jobID = (
              await obj.executeMethod(
                'Рассчитать',
                {
                  номерЗаписиГС: this.record.$номерЗаписи,
                  месяцРасчета: month,
                },
                {
                  async: true,
                  jobName: 'Деление группового счетчика №' + this.record.номер + ' ' + this.record.примечание + ' за ' + this.$stackDate.format(month as string, 'MM-YYYY'),
                  type: 'func',
                },
              )
            ).asyncId;
          } catch (error: AnyException) {
            this.$toast('Ошибка деления', { color: 'error' });
          }
        }
      }
    },
    async reportDistribCounter() {
      await new this.$ReportsBuilder('Групповой счетчик: Распределение общего ПУ по истории деления').executeReport2(
        'Групповой счетчик: Распределение общего ПУ по истории деления',
        { ведомость: this.record.$номерЗаписи },
        {
          params: {
            ведомость: this.record.$номерЗаписи,
            номерЗаписи: this.record.$номерЗаписи,
            имяВыборки: 'ГрупповыеСчетчики',
          },
        },
      );
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.jobID = null;
        this.$sendMsg('stack-table', 'ГрупповыеСчетчики.История', 'reload');
        this.$sendMsg('stack-table', 'ГрупповыеСчетчики.Параметры', 'reload');
      }
    },
    isError(to) {
      if (to) {
        this.jobID = null;
      }
    },
    workMonth() {
      this.$sendMsg('stack-table', 'ГрупповыеСчетчики.Счетчики', 'reload');
    },
  },
});
