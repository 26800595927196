
import Vue from 'vue';

export default Vue.extend({
  name: 'MonthPeriodFilter',
  model: { prop: 'params', event: 'input' },
  props: {
    params: { type: Object, required: true },
  },
  data() {
    return {
      rulesThis: {
        monthLessThan: (month1: string, month2: string, message = 'Месяц конца меньше месяца начала'): string | true => {
          return Date.parse(month1) <= Date.parse(month2) || !month1 || !month2 || message;
        },
      },
    };
  },
});
