const tars: StackSelection = {
  objectName: 'Услуги.Тарифы.ТарифыПоставщиковУК',
  description: '',
  fields: [
    {
      field: 'ук-детализация',
      text: 'УК',
      type: 'Link',
      dataModel: 'Организации',
      headers: 'инн,название',
      labelField: 'название',
      hier: true,
      width: '500px',
      noFolder: true,
    },
    {
      field: 'поставщик-детализация',
      text: 'Поставщик',
      type: 'Link',
      dataModel: 'Организации',
      headers: 'инн,название',
      labelField: 'название',
      hier: true,
      width: '500px',
      noFolder: true,
    },
    {
      field: 'значение',
      text: 'Тариф',
      type: 'Money',
      align: 'left',
      width: '100px',
    },
    {
      field: 'сверх',
      text: 'Сверх',
      type: 'Money',
      align: 'left',
      width: '100px',
    },
    {
      field: 'тариф1',
      text: 'Тариф1',
      type: 'Money',
      align: 'left',
      width: '100px',
    },
    {
      field: 'тариф2',
      text: 'Тариф2',
      type: 'Money',
      align: 'left',
      width: '100px',
    },
    {
      field: 'тариф3',
      text: 'Тариф3',
      type: 'Money',
      align: 'left',
      width: '100px',
    },
    {
      field: 'тариф4',
      text: 'Тариф4',
      type: 'Money',
      align: 'left',
      width: '100px',
    },
    {
      field: 'тариф по счетчику',
      text: 'Тариф СЧ',
      type: 'Money',
      align: 'left',
      width: '100px',
    },
  ],
};

export default tars;
