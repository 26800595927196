
import Vue from 'vue';
import monthPeriodFilter from './monthPeriodFilter.vue';

export default Vue.extend({
  components: { monthPeriodFilter },
  data() {
    return {
      item: {
        месяцс: null,
        месяцпо: null,
        услуга: null,
        организация: null,
        номердог: null,
      } as any,
    };
  },
});
