
import Vue from 'vue';
import StateDialog from './$dialogs/StateDialog.vue';

export default Vue.extend({
  components: { StateDialog },
  props: {
    ownerID: { type: [Number, String], default: undefined },
  },
});
