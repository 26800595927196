// перечисляемые в параметрах (свойствах)
// по алфавиту
export default {
  enums: [
    'АрхивГИСЗначение',
    'БаняЗначение',
    'БассЗакрЗначение',
    'БассОткрЗначение',
    'Без614Значение',
    'БезВоднГВЗначение',
    'БезВоднОТЗначение',
    'БезВоднХВЗначение',
    'БезВоднЭЛЗначение',
    'БезКвитанцЗначение',
    'БезРоднГВЗначение',
    'БезРоднОТЗначение',
    'БезРоднХВЗначение',
    'БезРоднЭЛЗначение',
    'ВидРасчетаЗначение',
    'ВидРасчетаОДНЗначение',
    'ВидРасчетаОДНХВСЗначение',
    'ВидРасчетаОДНГВСЗначение',
    'ВидРасчетаОДНЭЭЗначение',
    'ВидРасчетаОДНВОЗначение',
    'ВидРасчетаОДНГЗЗначение',
    'ГисТипЛСЗначение',
    'ДвухКомпГВСЗначение',
    'ДвухКомпГВСЗнач2',
    'КатегЖилЗначение',
    'КатегЭлЗначение',
    'КатМкдЗначение',
    'КатМкдЭЭЗначение',
    'ЛИВНСТОКИВидПоверхности',
    'НОТРОДНЗначение',
    'НомерГрафикаВнешнегоОсвещения',
    'НомерГрафикаВнутреннегоОсвещения',
    'ОтклЭлЗначение',
    'ПениНеВзЗначение',
    'ПерКупанияЗначение',
    'ПериодОтопленияЗначение',
    'ПерПоливЗначение',
    'ПолучитьДанныеДляДоговорныхФорм1',
    'ПолучитьДанныеДляДоговорныхФорм2',
    'ПолучитьДанныеДляДоговорныхФорм3',
    'ПотЛПрЗначение',
    'ПРаспОтЗначение',
    'ПрямойДогЗначение',
    'ПрямойРасчетЗначение',
    'РасчОКПУ',
    'СостояниеДопЗначение',
    'СостояниеЗначение',
    'СхемаХВЗначение',
    'ТеплицаЗначение',
    'ТипВводаЗначение',
    'ТипГВСЗначение',
    'ТипОтопЗначение',
    'ТипЛампЗначение',
    'ТипПлитыЗначение',
    'ТипПотребленияЗнач2',
    'ТипПотребленияЗнач3',
    'ТипПотребленияЗначение',
    'ТипТеплоЗначение',
    'ЮрЛицоЗначение',
    'РасчетПениЗначение',
    'ФактНапряжениеЗначение',
    'ВыставлятьПлатТребЗначение',
    'КатегорияКвартиры',
    'НепосредственноеУправлениеЗначение',
    'ФОРМАТФИОЗначение',
    'НаселениеЗначение1',
    'НаселениеЗначение2',
    'КвитСобсЗначение',
    'ТипСтроенияЗначение',
    'ТипСтроенияЗначенияОбщежития',
    'МатериалСтенЗначение',
    'ВидПомещенияЗначение',
    'ДогУКРСОЗначение',
    'ВидРасчСоиЗначение',
    'КатПомещЗначение',
    'НормПотЗначение',
    'ВидОтпускаЗначение',
    'ЧекОФДЗначение',
    'ТИПТУЗначение',
    'РАСКОНТПУЗначение',
    'ВидыЭнергии_Сокр',
    'ТипыКаналов',
    'ТипыПрофилей',
    'ВидыЭнергии',
    'ВидРасчетаПотерь',
    'БезСЧППЗначение',
  ],
};
