
import Vue from 'vue';
import credits from '../../themes/credits_stack';

export default Vue.extend({
  props: {
    color: { type: String, default: undefined },
  },
  computed: {
    getBaseName(): string {
      return this.$store.getters.getBaseName();
    },
    getFrontendVersion(): string {
      return this.$store.getters.getFrontendVersion();
    },
    getBackEndVersion(): string {
      return this.$store.getters.getBackEndVersion();
    },
  },
  data() {
    return {
      credits,
    };
  },
});
