const doguk: StackSelection = {
  objectName: 'ДоговорыУК.МакетыКвитанций',
  description: '',
  fields: [
    {
      field: 'код',
      text: 'Код',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'период',
      text: 'Период',
      type: 'Number',
    },
    {
      field: 'минсумма',
      text: 'МинСумма',
      type: 'Number',
    },
    {
      field: 'укмакет',
      text: 'УКМакет',
      type: 'Link',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'датнач',
      text: 'Дата начала',
      type: 'DateMonth',
    },
    {
      field: 'даткнц',
      text: 'Дата конца',
      type: 'DateMonth',
    },
  ],
};
export default doguk;
