const ccc: StackSelection = {
  objectName: 'ЛицевыеСчета.АктыОбхода',
  hier: true,
  description: 'Акты проверки',
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
      width: '100px',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'счет',
      text: 'Счет',
      type: 'Link',
    },
    {
      field: 'Автор',
      text: 'Автор',
      type: 'Link',
    },
    {
      field: 'видакта',
      text: 'Вид акта',
      type: 'Enum',
    },
    {
      field: '@видакта',
      text: 'Вид акта ',
      type: 'String',
    },
    {
      field: 'акт-услуга>наименование',
      text: 'Услуга',
      type: 'String',
    },
  ],
};
export default ccc;
