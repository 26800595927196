
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    subTab: { type: [Boolean, String], default: false },
    value: { type: [String, Number], default: undefined },
  },
  data() {
    return {
      isMounted: false,
    };
  },
  computed: {
    subTabId(): string {
      return typeof this.subTab === 'string' ? this.subTab : 'tab';
    },
    path(): string | undefined {
      return this.$route.query?.[this.subTabId] as string;
    },
  },
  mounted() {
    this.isMounted = true;
    if (this.subTab && this.path) {
      this.$emit('change', this.path);
    }
  },
  methods: {
    onChange(tab: string) {
      if (this.subTab && this.isMounted) {
        if (this.path !== tab) {
          this.$router.replace({ query: { [this.subTabId]: tab }, hash: this.$route.hash });
        }
      }
      this.$emit('change', tab);
    },
  },
});
