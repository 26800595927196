
import Vue from 'vue';
import CalcTabMixin from './CalcTabMixin';

export default Vue.extend({
  name: 'CountersInfo',
  mixins: [CalcTabMixin],
  props: {
    typeCounters: { type: String, default: 'ИПУ,ОКПУ,ОДПУ' },
  },
  data() {
    return {
      http: new this.$HttpModel('ЛицевыеСчета.ДопИнформацияПоЛицевому'),
      items: [] as StackTableRow[],
      emptyText: '',
    };
  },
  methods: {
    countersIcon(item: StackTableRow): string | undefined {
      if (item.видсчетчика === 'ОДПУ') {
        return '$vuetify.icons.odpu_counter';
      } else {
        return '$vuetify.icons.ipu_counter';
      }
    },
    statusIcon(состояние: number): string {
      return состояние === 0 ? '$vuetify.icons.close_circle' : состояние === 1 ? '$vuetify.icons.check_circle' : '';
    },
    iconColor(состояние: number): string {
      return состояние === 1 ? 'green' : 'grey';
    },
    async fetchData() {
      // @ts-ignore
      this.items = await this.http.executeMethod('ПолучитьПриборыУчета', { владелец: this.ownerId, типСчетчиков: this.typeCounters });
      this.emptyText = !this.items.length ? 'Приборы учета за данный период отсутствуют' : '';
    },
  },
});
