
import Vue from 'vue';

export default Vue.extend({
  props: {
    tableAction: { type: Function, required: true }, // коллбек к таблице
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      dial: false, // статус фабов
      items: [] as any,
    };
  },
  async created() {
    this.items = [];
    const data = await new this.$HttpModel('ТипАктаЗамены').getRecords();
    for (const ind in data) {
      this.items.push({ title: data[ind].заголовок, type: +ind, typeCounter: data[ind].типпу });
    }
  },
  methods: {
    onBtnClick(payload: StackTableRow) {
      this.$emit('add', payload);
    },
  },
});
