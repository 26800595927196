const actsqu: StackSelection = {
  recordRoute: 'metersdata-readingstatements-readingstatementsid',
  objectName: 'ВедомостиПоказанийИПУ',
  description: 'Ведомости показаний ИПУ',
  hier: true,
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчмесяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'кол_во',
      text: 'Количество показаний',
      type: 'Number',
    },
    {
      field: 'видсчета',
      text: 'видсчета',
      type: 'Number',
    },
    {
      field: 'контролер',
      text: 'Контролёр',
      type: 'String',
    },
    {
      field: 'документы-контролер',
      text: 'Документы-Контролер',
      type: 'Link',
    },
  ],
};
export default actsqu;
