
import Vue from 'vue';
import FileDialog from './_fileDialog.vue';

/**
 * Услуги лицевого
 */
export default Vue.extend({
  components: { FileDialog },
  props: {
    dataModel: { type: String, required: true },
    ownerID: { type: [Number, String], required: true },
    headers: { type: String, default: 'имя файла,дата,комментарий,расширение' },
    params: { type: Object, default: undefined },
    description: { type: String, default: 'Внешние документы' },
    showSaveMode: { type: Boolean, default: false },
  },
  methods: {
    async onSaveFileArchive(items: StackTableRow[], itemIds: string) {
      if (this.dataModel && typeof this.dataModel === 'string') {
        await new this.$HttpModel(this.dataModel).executeMethod(
          'получитьФайл',
          { номерЗаписи: itemIds, архив: true },
          { async: true, jobName: `Сохраняем архив. Кол-во документов: ${items.length}`, type: 'file' },
        );
        this.$sendMsg('stack-table', this.dataModel, 'unselect-all');
      }
    },
  },
});
