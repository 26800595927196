
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: 'название',
          text: 'Название',
          type: 'String',
          readonly: true,
        },
        {
          field: 'значение',
          text: 'Значение',
          type: 'Number',
        },
      ],
    };
  },
});
