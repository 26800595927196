const reports: StackSelection = {
  objectName: 'МассоваяПечатьКвитанций.КудаПечатать',
  description: '',
  fields: [
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
    {
      field: 'ключ',
      text: 'Ключ',
      type: 'Number',
    },
  ],
};
export default reports;
