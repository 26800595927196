
import Vue from 'vue';

export default Vue.extend({
  props: {
    asyncID: { type: String, required: true },
  },
  computed: {
    msgAction(): StackStateMsg {
      const msg = this.$store.getters.getMsgActionByID(this.asyncID);
      return msg || { complete: true, error: false, percent: 100 };
    },
    dataMsg(): any {
      if (this.msgAction && this.msgAction.data) {
        if (this.msgAction.data.state) {
          return this.msgAction.data.state;
        } else if (this.msgAction.data.result) {
          return this.msgAction.data.result;
        }
      }
      return null;
    },
    IDProc(): null | number {
      return this.dataMsg && this.dataMsg.ИДПроцесса ? this.dataMsg.ИДПроцесса : null;
    },
    countLS(): number {
      return this.dataMsg && this.dataMsg.ИтогоКолво ? this.dataMsg.ИтогоКолво : 0;
    },
    title(): string {
      if (this.msgAction.complete) {
        return 'Операция завершена успешно';
      } else if (this.msgAction.error) {
        return 'Операция завершена с ошибками';
      } else if (!this.IDProc) {
        return 'Запуск процессов';
      } else {
        return 'Выполнение операции';
      }
    },
    dataResult(): any {
      if (this.dataMsg && this.dataMsg.Сообщения) {
        return this.dataMsg.Сообщения;
      }
      return this.dataMsg && this.dataMsg.status ? { 0: this.dataMsg.status } : { 0: '' };
    },
    countPercent(): number {
      return this.dataMsg && this.dataMsg.Прогресс ? this.dataMsg.Прогресс : this.dataMsg && this.dataMsg.percent ? this.dataMsg.percent : 0;
    },
    waitLinear(): boolean {
      return this.msgAction.percent ? false : !(this.msgAction.complete || this.msgAction.error);
    },
  },
  data() {
    return {
      autoUpdate: false,
      selectedItems: [] as StackTableRow[],
      dataModel: new this.$HttpModel('ДиспетчерПроцессов'),
      isCanceling: false,
    };
  },
  methods: {
    getName(_name: string): string {
      return _name.length > 50 ? _name.substring(0, 50) + '...' : _name;
    },
    runUpdate() {
      this.selectedItems = [];
      // @ts-ignore
      this.$refs.table.unSelectAll();
      // @ts-ignore
      this.$refs.table.fetchData();
    },
    async runRestart() {
      if (!this.selectedItems.length) {
        this.$toast('Необходимо выделить процессы для перезапуска', { color: 'error' });
        return;
      }
      await this.dataModel.executeMethod('Перезапустить', { записи: this.selectedItems, asyncID: this.asyncID, гуид: this.asyncID });
      // this.$store.commit('MSG_SET_STATUS', { asyncId: this.asyncID, percent: 0, error: false, complete: false, multi: true });
      this.runUpdate();
      this.$emit('restart');
    },
    async runComplete() {
      if (!this.selectedItems.length) {
        this.$toast('Необходимо выделить процессы для завершения', { color: 'error' });
        return;
      }
      await this.dataModel.executeMethod('Завершить', { записи: this.selectedItems, гуид: this.asyncID });
      this.runUpdate();
      this.$emit('stop');
    },
    async runDownload() {
      const jobID = (await this.dataModel.executeMethod('ПоказатьЛог', {}, { async: true })).asyncId;
      this.$store.commit('MSG_ADD', { title: 'Файл лога', asyncId: jobID, silent: true, type: 'file' });
    },
    async runCancel() {
      this.isCanceling = true;
      await new this.$StackApi().cancelAsyncJob(this.asyncID);
    },
  },
  watch: {
    dataMsg(to) {
      if (to && this.autoUpdate) {
        this.runUpdate();
      }
    },
    msgAction(to) {
      if (to.error) {
        this.isCanceling = false;
      }
    },
  },
});
