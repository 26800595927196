const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ДетализацияЛьгот',
  description: '',
  hier: true,
  fields: [
    {
      field: 'наименованиеуслуги',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'номер льготы',
      text: 'Номер льготы',
      type: 'Number',
    },
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: 'Сумма,₽',
      type: 'Money',
    },
    {
      field: 'перерасчет',
      text: 'За месяц',
      type: 'DateMonth',
    },
    {
      field: 'объем',
      text: 'Объем,V',
      type: 'Number',
    },
    {
      field: 'льготник',
      text: 'Льготник',
      type: 'String',
    },
    {
      field: 'fl',
      text: '',
      type: 'String',
    },
  ],
};
export default accs;
