import Vue from 'vue';

export interface Fetchable extends Vue {
  dataObject: DataModel;
}

export default Vue.extend({
  props: {
    dataModel: { type: [String, Object, Array], required: true },
  },
  data() {
    return {
      dataObject: {} as DataModel,
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fetchData() {
    },
  },
  watch: {
    dataModel: {
      immediate: true,
      handler() {
        if (typeof this.dataModel === 'string') {
          this.dataObject = new this.$HttpModel(this.dataModel);
        } else if (Array.isArray(this.dataModel)) {
          this.dataObject = new this.$StaticModel(this.dataModel);
        } else {
          this.dataObject = this.dataModel;
        }
        if (this.dataModel) {
          this.fetchData();
        }
      },
    },
    queryParams: {
      immediate: false,
      handler(to, old) {
        // TODO спорная проверка
        if (this.dataModel && JSON.stringify(to) !== JSON.stringify(old)) {
          this.fetchData();
        }
      },
    },
  },
});
