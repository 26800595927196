const dolgsogldet: StackSelection = {
  objectName: 'Задолженность_детализация_соглашение',
  description: 'Детализация долга',
  hier: true,
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.$этоПапка) {
      // @ts-ignore
      row.$data['месяц долга'] = { $иконка: '$vuetify.icons.folder' };
    }
    return row;
  },
  fields: [
    {
      field: 'месяц долга',
      text: 'Месяц\nдолга',
      type: 'DateMonth',
      width: '150px',
    },
    {
      field: 'номер услуги',
      text: 'Номер\n услуги',
      type: 'Number',
    },
    {
      field: 'долг',
      text: 'Долг',
      type: 'Money',
    },
    {
      field: 'поставщик',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'ук',
      text: 'УК',
      type: 'String',
    },
    {
      field: 'укномер',
      text: 'УК\nДоговор',
      type: 'Number',
    },
    {
      field: 'нзп',
      text: '',
      type: 'Number',
    },
    {
      field: 'нзук',
      text: '',
      type: 'Number',
    },
    {
      field: 'нзпост',
      text: '',
      type: 'Number',
    },
    {
      field: 'ксумированию',
      text: '',
      type: 'Number',
    },
  ],
};

export default dolgsogldet;
