const acts: StackSelection = {
  objectName: 'ЧастныеЛица.ДокументыНаВладение',
  description: '',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'датнач',
      text: 'ДатНач',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'ДатКнц',
      type: 'Date',
    },
    {
      field: 'основание',
      text: 'Основание',
      type: 'String',
    },
    {
      field: 'основание>название',
      text: 'Основание',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'датарег',
      text: 'ДатаРег',
      type: 'Date',
    },
    {
      field: 'доля_числитель',
      text: 'Доля_Числитель',
      type: 'Number',
    },
    {
      field: 'доля_знаменатель',
      text: 'Доля_Знаменатель',
      type: 'Number',
    },
    {
      field: '@долявладения',
      text: 'Доля владения',
      type: 'String',
    },
    {
      field: 'совместная',
      text: 'Совместная',
      type: 'Number',
    },
    {
      field: 'владениедок',
      text: 'ВладениеДок',
      type: 'String',
    },
    {
      field: 'датадок',
      text: 'ДатаДок',
      type: 'Date',
    },
    {
      field: 'датадокумента',
      text: 'ДатаДокумента',
      type: 'Date',
    },
    {
      field: 'датарег',
      text: 'ДатаРег',
      type: 'Date',
    },
    {
      field: 'кемрегистр',
      text: 'КемРегистр',
      type: 'String',
    },
    {
      field: 'сериябланк',
      text: 'СерияБланк',
      type: 'String',
    },
    {
      field: 'бланкномер',
      text: 'БланкНомер',
      type: 'String',
    },
    {
      field: 'регномер',
      text: 'РегНомер',
      type: 'String',
    },
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'счет-владение>номер',
      text: 'Номер лицевого',
      type: 'String',
    },
    {
      field: '@адрес',
      text: 'Адрес',
      type: 'String',
    },
  ],
};
export default acts;
