const accs: StackSelection = {
  objectName: 'КонфигурацияСистемы',
  description: '',
  fields: [
    {
      field: 'нзлицо0',
      text: 'Значение',
      type: 'Link',
    },
    {
      field: 'нзлицо0рсчет',
      text: 'Наш расчетный счет',
      type: 'Link',
    },
    {
      field: 'догукпоумолчанию',
      text: 'Договор ук по умолчанию',
      type: 'Link',
    },
  ],
};
export default accs;
