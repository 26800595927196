
import Vue from 'vue';
import LsInfo from './LSInfo.vue';
import BalanceInfo from './BalanceInfo.vue';
import CountersInfo from './CountersInfo.vue';
import DebtInfo from './DebtInfo.vue';

export default Vue.extend({
  name: 'CommonTab',
  components: { LsInfo, CountersInfo, BalanceInfo, DebtInfo },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
    ownerID: { type: [String, Number], required: true },
  },
  data() {
    return {
      datKvit: this.$store.getters.getWorkMonth(),
      panel: [false, false],
      категория: 0,
      типстрой: 0,
    };
  },
  computed: {
    lsNumberIsDisabled(): boolean {
      return !this.record.датаоткрытия || (Date.parse(this.record.датаоткрытия) < Date.parse(this.$store.getters.getOpenMonth()) && this.ownerID !== 'new');
    },
  },
});
