const ls: StackSelection = {
  objectName: 'ДелениеЛицевого.Разделение счетчиков',
  description: '',
  fields: [
    {
      field: '@Услуга',
      text: '@Услуга',
      type: 'String',
      readonly: true,
    },
    {
      field: 'спобъект',
      text: 'спобъект',
      type: 'Number',
      readonly: true,
    },
    {
      field: 'нзправилоопераций',
      text: 'нзПравилоОпераций',
      type: 'Number',
      readonly: true,
    },
    {
      field: '@состояние',
      text: 'Состояние',
      type: 'String',
      readonly: true,
    },
    {
      field: '@показание',
      text: 'Показание',
      type: 'Number',
      readonly: true,
    },
    {
      field: '@показание2',
      text: 'Тариф 2',
      type: 'Number',
      readonly: true,
    },
    {
      field: '@показание3',
      text: 'Тариф 3',
      type: 'Number',
      readonly: true,
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
      readonly: true,
    },
    {
      field: 'дата2',
      text: 'Дата',
      type: 'Date',
      readonly: true,
    },
    {
      field: 'дата3',
      text: 'Дата',
      type: 'Date',
      readonly: true,
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
      readonly: true,
    },
    {
      field: 'заводскойномер',
      text: 'Заводской номер',
      type: 'String',
      readonly: true,
    },
    {
      field: 'датнач',
      text: 'С',
      type: 'Date',
      readonly: true,
    },
    {
      field: 'даткнц',
      text: 'ПО',
      type: 'Date',
      readonly: true,
    },
    {
      field: 'объект-услуга>наименование',
      text: 'Наименование',
      type: 'String',
      readonly: true,
    },
    {
      field: 'номер услуги',
      text: 'Услуга',
      type: 'Number',
      readonly: true,
    },
    {
      field: 'тарифность',
      text: 'Тарифность',
      type: 'Number',
      readonly: true,
    },
    {
      field: 'разрядность',
      text: 'Разрядность',
      type: 'Number',
      readonly: true,
    },
  ],
};

export default ls;
