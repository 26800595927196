const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.СписокРаспределителей.Показания',
  description: '',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчетный месяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'показание',
      text: 'Показание',
      type: 'Number',
    },
    {
      field: 'расход',
      text: 'Расход',
      type: 'Number',
    },
    {
      field: 'итоговый расход',
      text: 'Итоговый расход',
      type: 'Number',
    },
    {
      field: 'радиаторный коэффициент',
      text: 'Радиаторный коэффициент',
      type: 'Number',
    },
    {
      field: 'коэффициент помещения',
      text: 'Коэффициент помещения',
      type: 'Number',
    },
    {
      field: 'рпоказание-распределитель>заводскойномер',
      text: 'Счетчик',
      type: 'String',
    },
  ],
};
export default accs;
