const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ЖурналПерерасчетов',
  recordRoute: '',
  description: '',
  fields: [
    {
      field: 'действие',
      text: 'Действие',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'датнач',
      text: 'ДатНач',
      type: 'Date',
      computed: true,
    },
    {
      field: 'даткнц',
      text: 'ДатКнц',
      type: 'Date',
      computed: true,
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'Number',
    },
    {
      field: 'время',
      text: 'Время',
      type: 'String',
    },
    {
      field: 'имятаблицы',
      text: 'ИмяТаблицы',
      type: 'String',
    },
    {
      field: 'комментарий',
      text: 'Комментарий',
      type: 'String',
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
    {
      field: 'пользователь',
      text: 'Пользователь',
      type: 'String',
    },
    {
      field: 'таблица',
      text: 'Таблица',
      type: 'Number',
    },
    {
      field: 'позиция',
      text: 'Действие',
      type: 'String',
    },
  ],
};
export default accs;
