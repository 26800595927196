
import Vue from 'vue';
// @ts-ignore
import monthChipBtn from './customMonthChip.vue';
export default Vue.extend({
  props: {
    row: { type: Object, required: true },
  },
  components: {
    monthChipBtn,
  },
});
