export default {
  objectName: 'ИсторияВерсий',
  recordRoute: 'versionhistory-versionhistoryid',
  description: 'История версий',
  customApi: 'admin',
  fields: [
    {
      field: 'версия',
      text: 'Версия',
      type: 'String',
    },

    {
      field: 'сборка',
      text: 'Сборка',
      type: 'Number',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'задача',
      text: 'Задача',
      type: 'String',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'изменения',
      text: 'Изменения',
      type: 'String',
    },
    {
      field: 'блокировать',
      text: 'Блокировать',
      type: 'Number',
    },
  ],
} as StackSelection;
