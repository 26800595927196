const cmonth: StackSelection = {
  objectName: 'ЗакрытиеМесяца.Ошибки_ПослеРасчета',
  description: 'Ошибки после расчета',
  hier: true,
  fields: [
    {
      field: 'номерлс',
      text: 'НомерЛС',
      type: 'String',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'обработано',
      text: 'Обработано',
      type: 'Number',
    },
    {
      field: 'исправил',
      text: 'Исправил',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'когда исправлено',
      text: 'Время исправления',
      type: 'String',
    },
  ],
};

export default cmonth;
