
import Vue from 'vue';
export default Vue.extend({
  computed: {
    isTourEnable(): boolean {
      return this.$store.getters.isTourEnable();
    },
  },
  mounted() {
    if (this.isTourEnable) {
      this.$store.commit('MSG_ADD_ACTION', { title: 'Пример успешной операции', type: 'report', complete: true, asyncId: 'fake_success' });
      this.$store.commit('MSG_ADD_ACTION', { title: 'Пример операции с ошибкой', type: 'report', error: true, asyncId: 'fake_error' });
      // this.$store.commit('MSG_ADD_ACTION', { title: 'Пример длительной операции', type: 'report', asyncId: 'fake_long' });
      // @ts-ignore
      this.$parent.$children[0].firstStep();
    }
  },
});
