
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    id: { type: [Number, String], required: true },
    title: { type: String, default: 'Группа' },
  },
});
