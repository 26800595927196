
import Vue from 'vue';
import stStep from './step.vue';

export default Vue.extend({
  components: { stStep },
  props: {
    isComplete: { type: Boolean, default: false },
    routeParams: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async createDocument() {
      try {
        this.loading = true;
        await new this.$HttpModel('ЛицевыеСчета.БлокировкаПерерасчетаПени').executeMethod(
          'СоздатьВедомость',
          {
            НомерВедомости: this.routeParams.docNumber,
            месяц: this.routeParams.month,
            ВариантСписания: this.routeParams.optionWriteOff,
          },
          {
            async: true,
            jobName: 'Создание ведомости № ' + this.routeParams.docNumber,
            onComplete: () => {
              this.loading = false;
              const query = { ...this.$route.query };
              query.acceptToCreate = 'true';
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              this.$router.push({ query }).catch((e) => {});
              this.$emit('next');
            },
            onError: () => {
              this.loading = false;
            },
          },
        );
      } catch (error: AnyException) {
        this.loading = false;
        this.$toast('Ошибка создания ведомости № ' + this.routeParams.docNumber, { color: 'error' });
      }
    },
    onCancelCreate() {
      const query = { ...this.$route.query };
      query.acceptToCreate = 'false';
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push({ query }).catch((e) => {});
      this.$emit('next');
    },
  },
});
