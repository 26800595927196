const issuePlaces: StackSelection = {
  objectName: 'Классификаторы.МестаВыдачи',
  description: 'Места выдачи',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'код',
      text: 'Код',
      type: 'String',
    },
  ],
};
export default issuePlaces;
