
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      typeObject: ['Общедомовые', 'Общеквартирные', 'Все'],
      filter: {
        название: '',
        флаг: 0,
        услуга: -1,
        куслуг: -1,
        вид: 0,
        записи: 0,
        дата: this.$stackDate.lastDay(this.$store.getters.getOpenMonth()),
      } as any,
      disabledBtnFilter: false,
      valid: true,
    };
  },
  computed: {
    disableInputReadings(): boolean {
      if (!this.valid) {
        return true;
      } else {
        return this.disabledBtnFilter;
      }
    },
  },
  methods: {
    applyFilter() {
      this.disabledBtnFilter = true;
      this.$emit('apply', this.filter);
    },
    onChange(value: string, dataModel: string) {
      if (value) {
        if (dataModel === 'КатегорииУслуг') this.filter.услуга = -1;
        if (dataModel === 'Счетчики.Услуги') this.filter.куслуг = -1;
      }
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.disabledBtnFilter = false;
      },
    },
  },
});
