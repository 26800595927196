
import Vue from 'vue';

export default Vue.extend({
  name: 'DatePeriodFilter',
  model: { prop: 'params', event: 'input' },
  props: {
    params: { type: Object, required: true },
  },
});
