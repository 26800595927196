
import Vue from 'vue';

import account from './$components/account.vue';
import parameters from './$components/parameters.vue';
import saldo from './$components/saldo.vue';
import payments from './$components/payments.vue';
import people from './$components/people.vue';
import readings from './$components/readings.vue';
import finish from './$components/finish.vue';
import saldoCombo from './$components/saldoCombo.vue';

type HookReleaseFunction = () => void;

interface AccountSplitRow {
  идДоля: number;
  идСост: number;
  лицевой: number;
  дата: string;
  номер: number;
  коэфДоля: number;
  коэфПлощадь: number;
  делСальдо: number;
  выбХарактеристик: StackTableRow[];
  выбДопПараметров: StackTableRow[];
  выбПлатежей: StackTableRow[];
  выбНовыхЖильцов: StackTableRow[];
  выбСтарыхЖильцов: StackTableRow[];
  выбСчетчиков: StackTableRow[];
}

export default Vue.extend({
  components: { account, parameters, payments, people, readings, finish, saldo, saldoCombo },
  data() {
    return {
      statusAccount: '',
      statusParameters: '',
      statusSaldoCombo: '',
      statusPayments: '',
      statusPeople: '',
      statusReadings: '',
      statusFinish: '',
      disabledAccount: false,
      disabledParameters: true,
      disabledSaldoCombo: true,
      disabledPayments: true,
      disabledPeople: true,
      disabledReadings: true,
      disabledFinish: true,
      routeHook: null as null | HookReleaseFunction, // хук на смену роута
      runCreation: false,
      finishedOperation: false,
      dataModel: new this.$HttpModel('ДелениеЛицевого'),
      record: {
        делСальдо: 0,
      } as AccountSplitRow,
    };
  },
  created() {
    this.routeHook = this.$router.beforeEach(this.checkChangedOnLeave) as HookReleaseFunction;
    this.init();
  },
  beforeDestroy() {
    if (this.routeHook) {
      this.routeHook();
    }
  },
  methods: {
    async init() {
      const response = await this.dataModel.executeMethod('инициализацияМастера');
      if (response && (response.ИДДОЛЯ || response.идДоля)) {
        // TODO для обратной совместимости с новым и старым маззлом
        const идДоля = response.ИДДОЛЯ ? response.ИДДОЛЯ : response.идДоля;
        const идСост = response.ИДСОСТ ? response.ИДСОСТ : response.идСост;
        this.record = Object.assign({}, this.record, { идДоля, идСост });
      } else {
        this.$toast(response, { color: 'error' });
        this.disabledAccount = true;
      }
    },
    async checkChangedOnLeave(to: any, from: any, next: any) {
      if (this.runCreation && from.path !== to.path) {
        this.$toast('Выполняется операция, выход невозможен!');
        next(false);
        return;
      }
      if (!this.finishedOperation && this.record.лицевой && from.path !== to.path) {
        const answer = await this.$yesno('Отменить деление лицевого?');
        if (!answer) {
          next(false);
          return;
        }
        // @ts-ignore
        this.$refs.account.removeLock();
      }
      next();
    },
    saveAccount(item: AccountSplitRow) {
      if (this.record.лицевой && this.record.лицевой !== item.лицевой) {
        this.record = Object.assign({}, item);
        this.statusParameters = !this.disabledParameters ? 'error' : '';
        this.statusSaldoCombo = !this.disabledSaldoCombo ? 'error' : '';
        this.statusPayments = !this.disabledPayments ? 'error' : '';
        this.statusPeople = !this.disabledPeople ? 'error' : '';
        this.statusReadings = !this.disabledReadings ? 'error' : '';
      } else if (this.record.дата && this.record.дата !== item.дата) {
        this.record = Object.assign({}, this.record, item);
        this.statusParameters = !this.disabledParameters ? 'error' : '';
        this.disabledSaldoCombo = false;
      } else {
        this.record = Object.assign({}, this.record, item);
        this.disabledSaldoCombo = false;
      }
      this.statusAccount = 'success';
    },
    saveParameters(item: AccountSplitRow) {
      this.record = { ...this.record, ...item };
      this.statusParameters = 'success';
      this.disabledPayments = false;
    },
    saveSaldoCombo(item: AccountSplitRow) {
      if (item.делСальдо !== this.record.делСальдо && (this.record.делСальдо === 1 || this.record.делСальдо === 2)) {
        this.statusParameters = !this.disabledParameters ? 'error' : '';
      }
      this.record = { ...this.record, ...item };
      this.statusSaldoCombo = 'success';
      this.disabledParameters = false;
    },
    savePayments(item: AccountSplitRow) {
      this.record = Object.assign(this.record, item);
      this.statusPayments = 'success';
      this.disabledPeople = false;
    },
    savePeople(item: AccountSplitRow) {
      this.record = Object.assign(this.record, item);
      this.statusPeople = 'success';
      this.disabledReadings = false;
    },
    saveReadings(item: AccountSplitRow) {
      this.record = Object.assign(this.record, item);
      this.statusReadings = 'success';
      this.disabledFinish = false;
    },
    runOperation() {
      this.runCreation = true;
      this.finishedOperation = true;
      this.statusFinish = '';
    },
    finishOperation(status: string) {
      this.statusFinish = status;
      this.runCreation = false;
      if (status !== 'success') {
        this.finishedOperation = false;
      }
    },
  },
});
