export default {
  objectName: 'ЛицевыеСчета.Баланс',
  description: 'Управляющие компании',
  fields: [
    {
      field: 'входсальдо',
      text: 'Входящее сальдо',
      type: 'Money',
    },
    {
      field: 'начислено',
      text: 'Начислено',
      type: 'Money',
    },
    {
      field: 'перерасчет',
      text: 'Перерасчёт',
      type: 'Money',
    },
    {
      field: 'оплачено',
      text: 'оплачено',
      type: 'Money',
    },
    {
      field: 'пениначислено',
      text: 'пениначислено',
      type: 'Money',
    },
    {
      field: 'пениоплачено',
      text: 'пениоплачено',
      type: 'Money',
    },
    {
      field: 'коррекция',
      text: 'Коррекция',
      type: 'Money',
    },
    {
      field: 'входпени',
      text: 'ВходПени',
      type: 'Money',
    },
    {
      field: 'исхсальдо',
      text: 'исхсальдо',
      type: 'Money',
    },
    {
      field: 'задолженность',
      text: 'Задолженность',
      type: 'Money',
    },
    {
      field: 'исхпенисальдо',
      text: 'Исхпенисальдо',
      type: 'Money',
    },
    {
      field: 'коплате',
      text: 'К Оплате',
      type: 'Money',
    },
    {
      field: 'оплатапотекдат',
      text: 'ОплатаПоТекДат',
      type: 'Money',
    },
    {
      field: 'пениоплатапотекдат',
      text: 'ПениОплатаПоТекДат',
      type: 'Money',
    },
    {
      field: 'коплатепени',
      text: 'КОплатеПени',
      type: 'Money',
    },
    {
      field: 'коплатевсего',
      text: 'КОплатеВсего',
      type: 'Money',
    },
    {
      field: 'оплатапотекдатвсего',
      text: 'ОплатаПоТекДатВсего',
      type: 'Money',
    },
  ],
} as StackSelection;
