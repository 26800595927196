const actsqu: StackSelection = {
  objectName: 'ВедомостиРазовыхНачислений.Список',
  description: '',
  fields: [
    {
      field: 'счет',
      text: 'Лицевой',
      type: 'Link',
    },
    {
      field: 'счет>номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'поставщик',
      text: 'Поставщик',
      type: 'Link',
    },
    {
      field: 'договорук',
      text: 'ДоговорУК',
      type: 'Link',
    },
    {
      field: 'организация-укдоговор>название',
      text: 'УК',
      type: 'String',
    },
    {
      field: 'номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'объем',
      text: 'Объем',
      type: 'Quantity',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'месяц расчета',
      text: 'Расчетный месяц',
      type: 'DateMonth',
    },
    {
      field: 'за',
      text: 'За месяц',
      type: 'DateMonth',
    },
    {
      field: 'тариф',
      text: 'Тариф',
      type: 'Quantity',
    },
    {
      field: 'площадь',
      text: 'Площадь',
      type: 'Number',
    },
    {
      field: 'проживает',
      text: 'Проживает',
      type: 'Number',
    },
    {
      field: 'начисления',
      text: 'Начисления',
      type: 'Number',
    },
    {
      field: 'постоянные начисления',
      text: 'Постоянные начисления',
      type: 'Number',
    },
    {
      field: 'доля',
      text: 'Доля,%',
      type: 'Number',
    },
    {
      field: 'договорук>номер',
      text: 'Договор',
      type: 'String',
    },
    {
      field: 'поставщик>название',
      text: 'Поставщик',
      type: 'String',
    },
  ],
};
export default actsqu;
