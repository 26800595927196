
import Vue from 'vue';
import sealsTable from '../../views/$common/seals/sealsTable.vue';
import { ChangeCountersTransport } from './ITransportTypes';
import LsTab from '@/tasks/fl/views/metersdata/groupcounters/$dialogs/LSTab.vue';

export default Vue.extend({
  components: { sealsTable, LsTab },
  props: {
    calcMonth: { type: [Date, String], required: true },
    date: { type: [Date, String], required: true },
    params: { type: Object, required: true },
    noSealsTable: { type: Boolean, default: false },
    showOkpy: { type: Boolean, default: false },
    filter: {
      type: Object,
      default: () => {
        return { услуга: -1 };
      },
    },
    typeCounter: { type: String, default: 'ИПУ' },
    oldCounterSaved: {
      type: Object,
      default: () => {
        return {};
      },
    },
    newCounterSaved: {
      type: Object,
      default: () => {
        return {};
      },
    },
    newCounterDocSaved: {
      type: Object,
      default: () => {
        return {};
      },
    },
    newReadingsSaved: {
      type: Object,
      default: () => {
        return {};
      },
    },
    oldReadingsSaved: {
      type: Object,
      default: () => {
        return {};
      },
    },
    addDataDoc: {
      type: Object,
      default: () => {
        return {};
      },
    },
    newCounterlsGroupSaved: { type: String, default: '' },
    showMeasureText: { type: Boolean, default: false },
    inspector: { type: Number, default: null },
  },
  data() {
    return {
      dataObject: new this.$HttpModel('ЛицевыеСчета.Объекты'),
      dostup: [' ', 'Доступен', 'Не доступен'],

      tarifs: [''] as string[],
      tarifsNew: [''] as string[],
      counter: {} as StackTableRow,
      measuretype: {} as any,
      serviceItems: [] as StackTableRow[],
      oldCounterState: {} as { значение: number, текст: string },
      oldCounter: {
        дата: this.oldCounterSaved.дата || this.date,
        среднее: this.oldCounterSaved.среднее || false,
        датначср: this.oldCounterSaved.датначср || '',
        даткнцср: this.oldCounterSaved.даткнцср || '',
        'n п/п': this.oldCounterSaved['n п/п'] || '',
        'объект-услуга': this.oldCounterSaved['объект-услуга'] || -1,
        объект: this.oldCounterSaved.объект || null,
        причина: this.oldCounterSaved.причина || 0,
        огрмесяцевсреднего: this.oldCounterSaved.огрмесяцевсреднего || true,
        тарифность: this.oldCounterSaved.тарифность || 0,
        заводскойномер: this.oldCounterSaved.заводскойномер || '',
        '@безпоказаний': 0,
        микрогенерация: this.oldCounterSaved.микрогенерация || 0,
      },

      newCounter: {
        датнач: this.newCounterSaved.датнач || this.date,
        датаповерки: this.newCounterSaved.датаповерки || this.date,
        'коэффициент трансформации': this.newCounterSaved['коэффициент трансформации'] || 1,
        'объект-услуга': this.newCounterSaved['объект-услуга'] || null,
        'номенклатура-объекты': this.newCounterSaved['номенклатура-объекты'] || null,
        'n п/п': this.newCounterSaved['n п/п'] || 0,
        годвыпуска: this.newCounterSaved.годвыпуска || '',
        датаследующейповерки: this.newCounterSaved.датаследующейповерки || '',
        доступ: this.newCounterSaved.доступ || 0,
        дробнаяразрядность: this.newCounterSaved.дробнаяразрядность || 3,
        заводскойномер: this.newCounterSaved.заводскойномер || '',
        'место установки': this.newCounterSaved['место установки'] || 0,
        разрядность: this.newCounterSaved.разрядность || 5,
        тарифность: this.newCounterSaved.тарифность || 1,
        типПУТекст: this.newCounterSaved.типПУТекст || '',
        микрогенерация: this.newCounterSaved.микрогенерация || 0,
      } as StackTableRow,
      newCounterSeals: {} as any,
      // актуально для Окпу, содержит данные о документе
      newCounterDoc: {
        номер: this.newCounterDocSaved.номер || this.addDataDoc.НомерДокумента || '',
        тема: this.newCounterDocSaved.тема || '',
        примечание: this.newCounterDocSaved.примечание || '',
        'правила-документы': this.newCounterDocSaved['правила-документы'] || this.addDataDoc.идПравилоГр || null,
      } as StackTableRow,

      readings: {
        0: { показание: 0, послпок: 0, расход: 0, допрасход: 0, итоговыйрасход: 0 },
        1: { показание: 0, послпок: 0, расход: 0, допрасход: 0, итоговыйрасход: 0 },
        2: { показание: 0, послпок: 0, расход: 0, допрасход: 0, итоговыйрасход: 0 },
        3: { показание: 0, послпок: 0, расход: 0, допрасход: 0, итоговыйрасход: 0 },
        4: { показание: 0, послпок: 0, расход: 0, допрасход: 0, итоговыйрасход: 0 },
        5: { показание: 0, послпок: 0, расход: 0, допрасход: 0, итоговыйрасход: 0 },
      } as any, // показания старого счетчика
      newReadings: {
        0: { показание: null },
        1: { показание: null },
        2: { показание: null },
        3: { показание: null },
        4: { показание: null },
        5: { показание: null },
      } as any, // показания нового счетчика

      lastReadings: [] as any,
      lastReadingsDate: null as null | string,
      lastReadingsMonth: null as null | string,
      isClosedCounter: false as boolean, // признак закрытого пу
      lastReadingsIsChange: {} as any,
      askdata: {
        visible: false,
        answer: null,
        variants: ['Перекрутка счетчика', 'Отрицательный расход', 'Ошибка оператора'],
      } as any, // варианты для диалога ошибки показаний

      rulesThis: Object.assign(this.$stackRules, {
        dateIsLessOrEqualThan: (date1: string, date2: string, mess: string): string | true => {
          return !date1 || !date2 || Date.parse(date1) >= Date.parse(date2) || mess;
        },
        dateLessThanMonthReadings: (date1: string, date2: string): string | true => {
          const firstDay = this.$stackDate.addDays(new Date(date1), -1);
          const firstDayM = this.$stackDate.firstDay(firstDay);
          return Date.parse(firstDayM) >= Date.parse(date2) || date2 === null || 'Есть показания за более поздний расчетный месяц!';
        },
      }),
      menux: 0,
      menuy: 0,
      loading: false,
      newCounterlsGroup: '',
      needInitOldCounterDone: this.oldCounterSaved.объект || false, // надо отрабатывать событие init - только если прилетел сохранненый объект
      typeCounterCurrent: '', // Тип выбранного пу
    };
  },
  // инициализируем новый счетчик пустой записью, чтобы не терялись поля
  async created() {
    if (this.typeCounter === 'ОКПУ') {
      await new this.$HttpModel('АктыЗамены.ГС.ЛицевыеСчета').executeMethod(
        'ЗаполнитьТаблицу',
        { ls: this.newCounterlsGroupSaved },
        {
          async: true,
          jobName: 'Заполнение таблиц',
          silent: true,
          external: true,
          onComplete: () => this.$sendMsg('stack-table', 'АктыЗамены.ГС.ЛицевыеСчета', 'reload'),
          onError: () => this.$sendMsg('stack-table', 'АктыЗамены.ГС.ЛицевыеСчета', 'reload'),
        },
      );
    }

    // Считываем newCounter из params и опеределяем показание установочные
    if (this.newCounterSaved && Object.entries(this.newCounterSaved).length !== 0) {
      if (this.newReadingsSaved && Object.entries(this.newReadingsSaved).length !== 0) {
        const tariffRate = this.newCounterSaved.тарифность ? +this.newCounterSaved.тарифность : 0;
        const microgen = this.newCounterSaved.микрогенерация ? +this.newCounterSaved.микрогенерация : 0;
        let tariffmg = tariffRate;
        if (microgen) {
          tariffmg = tariffRate * 2;
        }
        for (const tar in this.newReadingsSaved) {
          if (+tar < tariffmg) {
            this.newReadings[tar].показание = this.newReadingsSaved[tar].показание;
          }
        }
      }
    } else {
      // инициализируем новый счетчик пустой записью, чтобы не терялись поля
      this.newCounter = await this.dataObject.initRecord();
    }
  },
  computed: {
    lsChange(): number | null {
      return this.params.счет;
    },
    dateStart(): string {
      return this.newCounter.датнач as string;
    },
    dateEnd(): string {
      return this.oldCounter.дата as string;
    },
    type(): number {
      return this.params.тип;
    },
    isSetupCounter(): boolean {
      return +this.params.тип !== 2;
    },
    isCloseCounter(): boolean {
      return +this.params.тип === 0 || +this.params.тип === 2;
    },
    newCounterVisible(): boolean {
      return !!this.newCounter['номенклатура-объекты'];
    },
    noShowSealsTable(): boolean {
      return !!this.newCounter['номенклатура-объекты'] && !this.noSealsTable;
    },
    newCounterSealsSaved(): Object {
      return this.newCounterSaved && this.newCounterSaved.пломбы ? this.newCounterSaved.пломбы : {};
    },
    // объект для передачи родителю
    watchObj(): ChangeCountersTransport {
      return {
        oldCounter: this.oldCounter,
        newCounter: this.newCounter,
        oldReadings: this.readings,
        newReadings: this.newReadings,
        newCounterlsGroup: this.newCounterlsGroup,
        typeCounter: this.typeCounterCurrent || this.typeCounter,
        установка: this.isSetupCounter,
        снятие: this.isCloseCounter,
        newCounterDoc: this.newCounterDoc,
        askdata: this.askdata.visible,
        newCounterSeals: this.newCounterSeals,
      };
    },
    dateFilter(): Date {
      return this.$stackDate.addMonths(this.calcMonth, 1);
    },
    checkServNewCounter(): boolean | string {
      return (
        !this.isCloseCounter ||
        !this.newCounter['номенклатура-объекты'] ||
        (this.newCounter['объект-услуга'] && +this.oldCounter['объект-услуга'] === +this.newCounter['объект-услуга']) ||
        'Услуги не могут быть разными'
      );
    },
  },
  methods: {
    // названия полей для ввода показаний в зависимости от тарифности
    async tariffToZones(tarif: number, usl: number, microgen: number) {
      const masLabelsTar = await new this.$HttpModel('СписокМетокТарифов').executeMethod('ВернутьМассивМеток', {
        нзусл: usl,
        тарифность: tarif,
        мг: microgen,
      });
      return Object.values(masLabelsTar) as string[];
    },
    async onInputTariff(payload: number) {
      const curService = this.newCounter['объект-услуга'] ? +this.newCounter['объект-услуга'] : 0;
      this.tarifsNew = await this.tariffToZones(payload, curService, (this.newCounter.микрогенерация && +this.newCounter.микрогенерация) || 0);
    },
    // реакция на выбор старого счетчика
    async onSelectOldCounter(payload: any) {
      this.oldCounter.дата = this.date;
      this.lastReadingsIsChange = {};
      if (payload && payload[0]) {
        this.oldCounter['объект-услуга'] = payload[0].услуга;
        this.oldCounter['n п/п'] = payload[0]['n п/п'];
        this.typeCounterCurrent = payload[0].тип;
        if (this.isSetupCounter) {
          // Для установки окпу всегда 1
          this.newCounter['n п/п'] = this.params.тип === 4 ? 1 : this.oldCounter['n п/п'];
        }
        this.counter = payload[0];
      } else {
        return;
      }
      this.oldCounter.тарифность = this.counter.тарифность ? +this.counter.тарифность : 0;
      this.oldCounter.микрогенерация = this.counter.микрогенерация ? +this.counter.микрогенерация : 0;
      this.oldCounter.заводскойномер = this.counter.заводскойномер ? this.counter.заводскойномер.toString() : '';
      this.tarifs = await this.tariffToZones(this.oldCounter.тарифность, this.oldCounter['объект-услуга'], this.oldCounter.микрогенерация);
      this.lastReadings = [];
      this.lastReadingsDate = null;
      this.lastReadingsMonth = null;
      this.isClosedCounter = false;
      this.oldCounter.огрмесяцевсреднего = await new this.$HttpModel('ЛицевыеСчета.Объекты').executeMethod('ПроверитьОграничениеСреднего', {
        услуга: this.oldCounter['объект-услуга'],
      });
      let tariffmg = Math.max(this.oldCounter.тарифность, 1);
      if (this.oldCounter.микрогенерация) {
        tariffmg = tariffmg * 2;
      }
      // Пересчитаем даты, если изменился флаг огрмесяцевсреднего
      this.setDateDiff();

      const result = await new this.$HttpModel('Счетчики.Показания').getRecords({ фильтр: { объект: this.counter.$номерЗаписи } });
      // как на бэкенде перебираем показания, сверху лежат самые поздние.
      if (result) {
        for (const reading of result as any) {
          if (this.counter.тарифность && (reading.тариф < this.counter.тарифность || this.oldCounter.микрогенерация)) {
            if (!this.lastReadings[reading.тариф]) {
              this.lastReadings[reading.тариф] = reading.показание;
            }
            if (!this.lastReadingsDate) {
              this.lastReadingsDate = reading.дата;
            }
            if (!this.lastReadingsMonth) {
              this.lastReadingsMonth = reading['расчетный месяц'];
            }
            // определяем по типу ввода что счетчик уже закрыт
            if (reading.типввода === 5) {
              this.isClosedCounter = true;
            }
          }
          if ((this.lastReadings.length === this.counter.тарифность && this.oldCounter.микрогенерация === 0) || (this.lastReadings.length === tariffmg && this.oldCounter.микрогенерация)) {
            break;
          }
        }
        // дата снятия не может быть меньше даты последнего показания
        if (this.lastReadingsDate) {
          if (Date.parse(this.oldCounter.дата) <= Date.parse(this.lastReadingsDate)) {
            this.oldCounter.дата = this.$stackDate.addDays(this.lastReadingsDate, 1);
          }
        }
      }
      let newtar;
      // устанавливаем конечные показания
      for (const tar in this.readings) {
        newtar = tar;
        if (this.oldCounter.микрогенерация) {
          newtar = this.tariffToMg(Number(tar), this.oldCounter.тарифность);
        }
        if (this.lastReadings[newtar]) {
          this.readings[tar].показание = this.lastReadings[newtar];
          this.readings[tar].послпок = this.lastReadings[newtar];
        }
      }
    },
    async onInitOldCounter(payload: any) {
      if (!this.needInitOldCounterDone) {
        return;
      }
      if (payload && payload[0]) {
        this.counter = payload[0];
        this.counter['объект-услуга'] = payload[0].услуга;
        this.typeCounterCurrent = payload[0].тип;
      } else {
        return;
      }
      this.lastReadingsIsChange = {};
      this.tarifs = await this.tariffToZones(this.oldCounter.тарифность, this.oldCounter['объект-услуга'], this.oldCounter.микрогенерация);
      let tariffmg = Math.max(this.oldCounter.тарифность, 1);
      if (this.oldCounter.микрогенерация) {
        tariffmg = tariffmg * 2;
      }
      this.lastReadings = [];
      this.lastReadingsDate = null;
      this.isClosedCounter = false;
      const result = await new this.$HttpModel('Счетчики.Показания').getRecords({ фильтр: { объект: this.counter.$номерЗаписи } });
      // как на бэкенде перебираем показания, сверху лежат самые поздние.
      if (result) {
        for (const reading of result as any) {
          if (this.counter.тарифность && (reading.тариф < this.counter.тарифность || this.oldCounter.микрогенерация)) {
            if (!this.lastReadings[reading.тариф]) {
              this.lastReadings[reading.тариф] = reading.показание;
            }
            if (!this.lastReadingsDate) {
              this.lastReadingsDate = reading.дата;
            }
            // определяем по типу ввода что счетчик уже закрыт
            if (reading.типввода === 5) {
              this.isClosedCounter = true;
            }
          }
          if ((this.lastReadings.length === this.counter.тарифность && this.oldCounter.микрогенерация === 0) || (this.lastReadings.length === tariffmg && this.oldCounter.микрогенерация)) {
            break;
          }
        }
      }
      let newtar;
      // устанавливаем конечные показания
      for (const tar in this.readings) {
        newtar = tar;
        if (this.oldCounter.микрогенерация) {
          newtar = this.tariffToMg(Number(tar), this.oldCounter.тарифность);
        }
        if (this.lastReadings[newtar]) {
          this.readings[tar].послпок = this.lastReadings[newtar];
          this.readings[tar].показание = this.oldReadingsSaved[tar].показание || this.lastReadings[newtar] || 0;
          this.onChangeReading(+tar);
        }
      }
      this.needInitOldCounterDone = false;
    },

    // при изменении показаний пересчитываем расходы
    onChangeReading(tar: number) {
      let newtar;
      newtar = tar;
      if (this.oldCounter.микрогенерация) {
        newtar = this.tariffToMg(Number(tar), this.oldCounter.тарифность);
      }
      if (this.readings[tar]) {
        const reading = this.readings[tar].показание;
        const lastreading = this.lastReadings[Number(newtar)] ? this.lastReadings[Number(newtar)] : 0;
        this.lastReadingsIsChange[tar] = true;

        let volume = +(reading - lastreading).toFixed(3);
        let koef = 1;
        if (this.counter['коэффициент трансформации']) {
          koef = Number(this.counter['коэффициент трансформации']);
        }

        if (volume < 0) {
          // спросим пользователя что это он ввел
          this.askdata.visible = true;
          // @ts-ignore
          const pos = this.$refs.ref_pok[tar].$el.getBoundingClientRect();
          this.menux = pos.left + pos.width / 2;
          this.menuy = pos.top;
          const timer = setInterval(() => {
            if (!this.askdata.visible) {
              clearInterval(timer);
              switch (this.askdata.answer) {
                case 0: {
                  const max = this.counter.разрядность ? Math.pow(10, Number(this.counter.разрядность)) : Math.pow(10, 9);
                  volume = max - lastreading + reading;
                  break;
                }
                case 1:
                  break;
                default: {
                  this.readings[tar].показание = lastreading;
                  volume = 0;
                  break;
                }
              }
              this.readings[tar].расход = volume;
              this.readings[tar].итоговыйрасход = this.readings[tar].расход * koef + this.readings[tar].допрасход;
            }
          }, 500);
        } else {
          this.readings[tar].расход = volume;
          this.readings[tar].итоговыйрасход = this.readings[tar].расход * koef + this.readings[tar].допрасход;
        }
      }
    },

    // пересчет итогового расхода при изменении объема
    onChangeVolume(tar: number) {
      if (this.readings[tar]) {
        let koef = 1;
        if (this.counter['коэффициент трансформации']) {
          koef = Number(this.counter['коэффициент трансформации']);
        }
        this.readings[tar].итоговыйрасход = this.readings[tar].расход * koef + this.readings[tar].допрасход;
      }
    },

    // считаем дату следующей поверки
    changeNextPoverkaDate(event: string) {
      let changeDate = false;
      const poverkaInterval = (this.measuretype['@интервал'] || 0) as number;

      switch (event) {
        case 'onChangePoverkaDate':
          changeDate = true;
          break;
        case 'onChangeInstallDate':
          if (this.newCounter.датаповерки || this.newCounter.датаповерки === '') {
            changeDate = true;
          }
          this.newCounter.датаповерки = this.newCounter.датнач;
          break;
      }

      const poverkaDate = (this.newCounter.датаповерки || this.newCounter.датнач || '') as string;
      if (changeDate && poverkaDate) {
        this.newCounter.датаследующейповерки = this.$stackDate.addMonths(poverkaDate as string, poverkaInterval * 12);
      }
    },
    async onChangePoverkaDate() {
      this.changeNextPoverkaDate('onChangePoverkaDate');
      await this.setNPP('update');
    },

    onChangeInstallDate(idx: number) {
      this.changeNextPoverkaDate('onChangeInstallDate');
    },

    // реакция на выбор нового счетчика
    async onSelectMeasure(event: string, payload: any) {
      if (payload && payload[0]) {
        this.measuretype = payload[0];
        this.newCounter.тарифность = Math.max(this.measuretype.тарифность, 1);
        this.newCounter.микрогенерация = this.measuretype['микрогенерация'] ? +this.measuretype['микрогенерация'] : 0;
        const curService = this.measuretype['@услуга'] ? +this.measuretype['@услуга'] : -1;
        this.tarifsNew = await this.tariffToZones(this.newCounter.тарифность, curService, this.newCounter.микрогенерация);
        this.$nextTick(() => {
          // @ts-ignore
          if (this.$refs.input_reading && this.$refs.input_reading[0]) {
            // @ts-ignore
            this.$refs.input_reading[0].focus();
          }
        });
        this.newCounter.разрядность = this.measuretype['счетчика разрядность'];
        this.newCounter.дробнаяразрядность = this.measuretype['счетчика дробная разрядность'];
        if (this.serviceItems.map(item => item.ключ).includes(curService)) {
          this.newCounter['объект-услуга'] = curService;
        }

        const poverkaInterval = (this.measuretype['@интервал'] || 0) as number;
        this.newCounter.датаследующейповерки = this.$stackDate.addMonths(this.newCounter.датаповерки as string, poverkaInterval * 12);

        if (this.oldCounter['объект-услуга'] !== this.newCounter['объект-услуга']) {
          await this.setNPP('newService');
          return;
        }
        await this.setNPP(event);
      }
    },
    // при смене услуги нового счетчика попробуем определить его новый нпп
    async onChangeServ(item: number) {
      this.tarifsNew = await this.tariffToZones((this.newCounter.тарифность && +this.newCounter.тарифность) || 1, item, (this.newCounter.микрогенерация && +this.newCounter.микрогенерация) || 0);
      if (this.oldCounter['объект-услуга'] !== this.newCounter['объект-услуга']) {
        await this.setNPP('newService');
        return;
      }
      await this.setNPP('update');
    },

    // определим даты начисления среднего
    setDateDiff() {
      switch (this.params.тип) {
        case 0:
          if (this.dateStart > this.$stackDate.addDays(this.dateEnd, 1)) {
            this.oldCounter.датначср = this.$stackDate.addDays(this.oldCounter.дата, 1);
            this.oldCounter.даткнцср = this.$stackDate.addDays(this.newCounter.датнач as string, -1);
          } else {
            this.oldCounter.датначср = '';
            this.oldCounter.даткнцср = '';
          }
          break;
        case 1:
          this.oldCounter.датначср = '';
          this.oldCounter.даткнцср = '';
          break;
        case 2:
          this.oldCounter.датначср = this.$stackDate.addDays(this.oldCounter.дата, 1);
          if (this.oldCounter.огрмесяцевсреднего) {
            // Сместим на 3 месяца вперед и уберем 1 день, так как он не должен учитываться в интервале
            this.oldCounter.даткнцср = this.$stackDate.addDays(this.$stackDate.addMonths(this.oldCounter.датначср, 3), -1);
          } else {
            this.oldCounter.даткнцср = this.$stackDate.format(new Date(2045, 4, 9), 'ISO');
          }
          break;
        default:
          break;
      }
    },
    // определим нпп нового счетчика
    async setNPP(event: string) {
      //  для установки окпу - не отправляем доп. запрос
      if (this.params.тип === 4) {
        this.newCounter['n п/п'] = 1;
        return;
      }
      // сформируем номер по порядку при установке
      if (((this.params.тип === 1 && event === 'update') || event === 'newService') && this.dataObject && this.dataObject.executeMethod) {
        const tmpResult = await this.dataObject.executeMethod('ПолучитьНПП', {
          запись: { ...this.newCounter, ...{ 'объекты-счет': this.params.счет } },
        });
        if (tmpResult !== 0) {
          this.newCounter['n п/п'] = tmpResult;
        }
      }
      // при замене, берем со старого
      if (this.params.тип === 0 && event === 'update') {
        this.newCounter['n п/п'] = this.oldCounter['n п/п'];
      }
    },
    onUpdateTableGsLs(payload: any) {
      if (payload && Object.entries(payload).length !== 0) {
        return (this.newCounterlsGroup = payload.map((item: StackTableRow) => item['показания-счет']).join(','));
      } else return '';
    },
    async getState(счетчик: string, дата: string) {
      const res = await new this.$HttpModel('Объекты.Состояния').executeMethod('ПолучитьСостояние', { счетчик, дата });
      this.$set(this, 'oldCounterState', res || {});
    },
    tariffToMg(tar: number, tarifnost: number) {
      let tarmg = tar;

      switch (tarifnost) {
        case 1:
          if (tar === 1) {
            tarmg = 10;
          }
          return tarmg;
        case 2:
        {
          switch (tar) {
            case 2:
            {
              tarmg = 10;
              return tarmg;
            }
            case 3:
            {
              tarmg = 11;
              return tarmg;
            }
            default:
              return tarmg;
          }
        }
        case 3:
        {
          switch (tar) {
            case 3:
            {
              tarmg = 10;
              return tarmg;
            }
            case 4:
            {
              tarmg = 11;
              return tarmg;
            }
            case 5:
            {
              tarmg = 12;
              return tarmg;
            }
            default:
              return tarmg;
          }
        }
        default:
          return tarmg;
      }
    },
  },
  watch: {
    oldCounter: {
      deep: true,
      immediate: true,
      handler(to) {
        if (to.дата && to.объект) {
          this.getState(to.объект, to.дата);
        }
      },
    },
    dateStart() {
      this.setDateDiff();
    },
    dateEnd(to) {
      this.setDateDiff();
      this.newCounter.датнач = to;
      this.newCounter.датаповерки = to;
      this.onChangePoverkaDate();
    },
    type() {
      this.setDateDiff();
    },
    lsChange() {
      this.oldCounter = {
        дата: this.date,
        среднее: false,
        датначср: '',
        даткнцср: '',
        'n п/п': '',
        'объект-услуга': -1,
        объект: null,
        причина: 0,
        огрмесяцевсреднего: true,
        тарифность: 0,
        заводскойномер: '',
        '@безпоказаний': 0,
        микрогенерация: 0,
      };
      this.newCounter = {
        датнач: this.date,
        датаповерки: this.date,
        'коэффициент трансформации': 1,
        'объект-услуга': null,
        'номенклатура-объекты': null,
        'n п/п': 0,
        годвыпуска: '',
        датаследующейповерки: '',
        доступ: 0,
        дробнаяразрядность: 3,
        заводскойномер: '',
        'место установки': 0,
        разрядность: 5,
        тарифность: 1,
        типПУТекст: '',
        пломбы: {} as any,
      };
      this.newCounterDoc = {
        номер: '',
        тема: '',
        примечание: '',
        'правила-документы': null,
      };
    },
    // посылаем объект с даннными из компонента в родителя при изменении
    watchObj: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('onChange', this.watchObj);
      },
    },
  },
});
