const lg: StackSelection = {
  recordRoute: '',
  objectName: 'НастройкиЭкспорта',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
  ],
};

export default lg;
