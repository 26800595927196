const acts: StackSelection = {
  objectName: 'АктыСменыПУ',
  recordRoute: 'metersdata-counterschange-counterschangeid',
  description: 'Акты замены ИПУ',
  hier: true,
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчмесяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'кол_во',
      text: 'Показаний',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
  ],
};

export default acts;
