
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isValid(): boolean {
      return this.record.files?.length > 0 && !this.isLoading;
    },
  },
});
