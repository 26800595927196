
import Vue from 'vue';
import tarif from '@/tasks/ul/views/operations/uploads/$dialogs/tarif.vue';

export default Vue.extend({
  components: { tarif },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    year(): number {
      return this.record && this.record.значение ? new Date(this.record.значение).getFullYear() : 0;
    },
  },
  data() {
    return {
      recordUpload: {
        месяц: this.record.дата,
        типчисло: -1,
        files: [],
        подкачка: 'Подкачка часов мощности (формат АТС)',
      },
    };
  },
  methods: {
    async updateData() {
      await new this.$HttpModel('СписокВыходныхДней').getRecords({ год: this.record.значение });
      // @ts-ignore
      this.$refs.dialog.saveState(this.record);
    },
    upload(item: StackTableRow) {
      new this.$HttpModel('ВыгрузкиПодкачки').executeMethod('Подкачать', item, {
        async: true,
        jobName: 'Подкачка часов пиковой нагрузки (формат АТС)',
      });
    },
  },
});
