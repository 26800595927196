
import Vue from 'vue';
import multiProcFilter from '@/tasks/fl/views/$common/filters/multiProcFilter.vue';
import MultiProcToolbar from './$dialogs/MultiProcToolbar.vue';

export default Vue.extend({
  name: 'HistoryLsTab',
  components: { multiProcFilter, MultiProcToolbar },
  data() {
    return {
      timer: null as any | null,
      bDropReload: false,
    };
  },
  async mounted() {
    this.timerStart();
  },
  beforeDestroy() {
    this.timerStop();
  },
  methods: {
    refreshConsole() {
      this.bDropReload = false;
      this.$sendMsg('stack-table', 'КонсольМногопроцессныхОпераций', 'reload');
    },
    async clearConsole() {
      const obj = new this.$HttpModel('КонсольМногопроцессныхОпераций');
      await obj.executeMethod('Очистить', {}, { async: false, jobName: 'Очистка консоли многопроцессных операций' });
      this.bDropReload = true;
      this.$sendMsg('stack-table', 'КонсольМногопроцессныхОпераций', 'reload');
    },
    async runDebug(item: StackTableRow) {
      const заголовок = item.название;
      const obj = new this.$HttpModel('РеестрМногопроцессныхОпераций');
      await obj.executeMethod('Отладка', item, { async: true, jobName: 'Отладка многопроцессной операции( ' + заголовок + ')' });
    },
    updateTable() {
      // @ts-ignore
      this.$refs.table.fetchData();
    },
    timerStart() {
      this.timer = setInterval(() => {
        this.$sendMsg('stack-table', 'КонсольМногопроцессныхОпераций', 'reload');
      }, 10000);
    },
    // остановка опроса бэка
    timerStop() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
});
