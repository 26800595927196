import { TableHandlers } from '@/stackEngine';

const disp: StackSelection = {
  objectName: 'ДиспетчерПроцессов',
  description: '',
  prepareRow: row => {
    switch (row.статус) {
      case 2:
        TableHandlers.trColor(row, 'success');
        break;
      case 3:
        TableHandlers.trColor(row, 'error');
        break;
      default:
        break;
    }
    return row;
  },
  fields: [
    {
      field: 'пид',
      text: 'Pid',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'врстарт',
      text: 'Запущен',
      type: 'String',
    },
    {
      field: 'врфиниш',
      text: 'Окончен',
      type: 'String',
    },
    {
      field: 'продолжительность',
      text: '',
      type: 'String',
    },
    {
      field: 'всеголс',
      text: 'ЛС',
      type: 'Number',
    },
    {
      field: 'процент',
      text: '%',
      type: 'Number',
    },
    {
      field: 'статус',
      text: 'Статус',
      type: 'Number',
    },
    {
      field: '@статустекст',
      text: 'Статус',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Детально',
      type: 'String',
    },
  ],
};
export default disp;
