
import Vue from 'vue';
import peopleDialog from '../$dialogs/peopleDialog.vue';
import tempMoving from '../$dialogs/tempMovingDialog.vue';
import stepBtn from './stepBtn.vue';

export default Vue.extend({
  components: { peopleDialog, tempMoving, stepBtn },
  props: {
    record: { type: Object, required: true },
    steps: { type: Object, required: true },
  },
  methods: {
    vremHandler(record: any) {
      switch (record.тип) {
        case 0:
          record.type = 'Временное отсутствие';
          record.$data.фио.$иконка = '$vuetify.icons.persons_minus';
          break;
        case 1:
          record.type = 'Временная регистрация';
          record.$data.фио.$иконка = '$vuetify.icons.persons_plus';
          break;
      }
      return record;
    },
  },
});
