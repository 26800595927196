
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'Макет квитанции' },
  },
  data() {
    return {
      isOpenMonth: (value: string): string | boolean => {
        return Date.parse(value) >= Date.parse(this.$store.getters.getCloseMonth()) || `Месяц закрыт`;
      },
    };
  },
  computed: {
    disabledDN(): boolean {
      return !(Date.parse(this.record.датнач) >= Date.parse(this.$store.getters.getOpenMonth()) || this.record.$номерЗаписи === -1);
    },
    disabledDK(): boolean {
      return !(Date.parse(this.record.даткнц) >= Date.parse(this.$store.getters.getOpenMonth()) || this.record.$номерЗаписи === -1);
    },
  },
});
