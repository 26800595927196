import Vue from 'vue';

export default Vue.extend({
  props: {
    ownerId: { type: [Number, String], required: true },
  },
  computed: {
    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
    scrollable(): string | number {
      if (this.isVisible) {
        const sel = this.$el?.querySelector('.wrapper');
        if (sel) {
          const rect = sel.getBoundingClientRect();
          return `max-height: calc(100vh - ${rect.top + 12}px); overflow-x: hidden; overflow-y:auto;`;
        }
      }
      return '';
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  updated() {
    this.isVisible = true;
  },
  watch: {
    workMonth() {
      // @ts-ignore
      this.fetchData();
    },
    ownerId: {
      immediate: true,
      handler(to) {
        if (to && to > 0) {
          // @ts-ignore
          this.fetchData();
        }
      },
    },
  },
});