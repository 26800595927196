import Vue from 'vue';

import analit from './reports/analit.vue';
import analitpayments from './reports/analitpayments.vue';
import debt from './reports/debt.vue';
import debtsummon from './reports/debtsummon.vue';
import dopcountpov from './reports/dopcountpov.vue';
import dopcountstatus from './reports/dopcountstatus.vue';
import dopetcdebt from './reports/dopetcdebt.vue';
import dopetcdebtls from './reports/dopetcdebtls.vue';
import dopetcdog from './reports/dopetcdog.vue';
import dopetcflat from './reports/dopetcflat.vue';
import doppayment from './reports/doppayment.vue';
import dopreestrzhilparam from './reports/dopreestrzhilparam.vue';
import dopreestrzhilparamnesk from './reports/dopreestrzhilparamnesk.vue';
import doprepcalcdebt from './reports/doprepcalcdebt.vue';
import doprepcalcrecalc from './reports/doprepcalcrecalc.vue';
import dopreport from './reports/dopreport.vue';
import dopreportsaldo from './reports/dopreportsaldo.vue';
import dopreprecalcserv from './reports/dopreprecalcserv.vue';
import doprepvremdv from './reports/doprepvremdv.vue';
import dopvedcounters from './reports/dopvedcounters.vue';
import doplswithcharacters from './reports/doplswithcharacters.vue';
import lgotniki from './reports/lgotniki.vue';
import lostMoney from './reports/lostMoney.vue';
import onDate from './reports/onDate.vue';
import overpayment from './reports/overpayment.vue';
import payments from './reports/payments.vue';
import paymentsFindSum from './reports/paymentsFindSum.vue';
import payroll from './reports/payroll.vue';
import peni from './reports/peni.vue';
import periodDate from './reports/periodDate.vue';
import periodMonth from './reports/periodMonth.vue';
import perMonth from './reports/perMonth.vue';
import qualityacts from './reports/qualityacts.vue';
import saldonach from './reports/saldonach.vue';
import service from './reports/service.vue';
import slbigvolume from './reports/slbigvolume.vue';
import slcheckcalc from './reports/slcheckcalc.vue';
import slcountoff from './reports/slcountoff.vue';
import slondateflag from './reports/slondateflag.vue';
import slperiodflags from './reports/slperiodflags.vue';
import calcmonth from './reports/calcmonth.vue';
import monthservice from './reports/monthservice.vue';
import lschangelog from './reports/lschangelog.vue';
import monthperiod from './reports/monthperiod.vue';
import frommonth from './reports/frommonth.vue';
import todatemonth from './reports/todatemonth.vue';
import lsexcerpt from './reports/lsexcerpt.vue';
import todateend from './reports/todateend.vue';
import printpodpis from './reports/print_podpis.vue';
import detraschsaldo from './reports/detraschsaldo.vue';
import emptyDialog from './reports/emptyDialog.vue';
import withfile from './reports/withfile.vue';
import indop_default from './reports/indop_default.vue';
import slugbeznach from './reports/slugbeznach.vue';
import slugcheckserv from './reports/slugcheckserv.vue';
import grpotr from './reports/grpotr.vue';
import checkListControler from './reports/checkListControler.vue';
import sogl from './reports/sogl.vue';
import homeowners from './reports/homeowners.vue';
import otheranalysfl from './reports/OtherAnalysFL.vue';
import monthPeriodCorrectOtop from './reports/monthPeriodCorrectOtop.vue';

// Выгрузки
import unloadLgot from './components/global/unloadLgot.vue';
import unloadLgotPost from './components/global/unloadLgotPost.vue';
import unloadOnDate from './components/global/unloadOnDate.vue';
import unloadOnMonth from './components/global/unloadOnMonth.vue';
import unloadReestr from './components/global/unloadReestr.vue';
import unloadToOszn from './components/global/unloadToOszn.vue';
import uploadFile from './components/global/uploadFile.vue';
import unloadOnMonthXML from './components/global/unloadOnMonthXML.vue';
import unloadOnMonthTXT from './components/global/unloadOnMonthTXT.vue';
import unloadStorLs from './components/global/unloadStorLs.vue';
import unloadExchange from './components/global/unloadExchange.vue';
import unloadOnMR from './components/global/unloadOnMR.vue';

Vue.component('Analit', analit);
Vue.component('Analitpayments', analitpayments);
Vue.component('Debt', debt);
Vue.component('Debtsummon', debtsummon);
Vue.component('Dopcountpov', dopcountpov);
Vue.component('Dopcountstatus', dopcountstatus);
Vue.component('Dopetcdebt', dopetcdebt);
Vue.component('Dopetcdebtls', dopetcdebtls);
Vue.component('Dopetcdog', dopetcdog);
Vue.component('Dopetcflat', dopetcflat);
Vue.component('Doppayment', doppayment);
Vue.component('Dopreestrzhilparam', dopreestrzhilparam);
Vue.component('Dopreestrzhilparamnesk', dopreestrzhilparamnesk);
Vue.component('Doprepcalcdebt', doprepcalcdebt);
Vue.component('Doprepcalcrecalc', doprepcalcrecalc);
Vue.component('Dopreport', dopreport);
Vue.component('Dopreportsaldo', dopreportsaldo);
Vue.component('Dopreprecalcserv', dopreprecalcserv);
Vue.component('Doprepvremdv', doprepvremdv);
Vue.component('Dopvedcounters', dopvedcounters);
Vue.component('Doplswithcharacters', doplswithcharacters);
Vue.component('Lgotniki', lgotniki);
Vue.component('LostMoney', lostMoney);
Vue.component('OnDate', onDate);
Vue.component('Overpayment', overpayment);
Vue.component('Payments', payments);
Vue.component('PaymentsFindSum', paymentsFindSum);
Vue.component('Payroll', payroll);
Vue.component('Peni', peni);
Vue.component('PeriodDate', periodDate);
Vue.component('PeriodMonth', periodMonth);
Vue.component('PerMonth', perMonth);
Vue.component('Qualityacts', qualityacts);
Vue.component('Saldonach', saldonach);
Vue.component('Service', service);
Vue.component('Slbigvolume', slbigvolume);
Vue.component('Slcheckcalc', slcheckcalc);
Vue.component('Slcountoff', slcountoff);
Vue.component('Slondateflag', slondateflag);
Vue.component('Slperiodflags', slperiodflags);
Vue.component('Calcmonth', calcmonth);
Vue.component('Monthservice', monthservice);
Vue.component('Lschangelog', lschangelog);
Vue.component('Monthperiod', monthperiod);
Vue.component('Frommonth', frommonth);
Vue.component('Todatemonth', todatemonth);
Vue.component('Lsexcerpt', lsexcerpt);
Vue.component('Todateend', todateend);
Vue.component('Printpodpis', printpodpis);
Vue.component('Detraschsaldo', detraschsaldo);
Vue.component('EmptyDialog', emptyDialog);
Vue.component('Withfile', withfile);
Vue.component('IndopDefault', indop_default);
Vue.component('Slugbeznach', slugbeznach);
Vue.component('Slugcheckserv', slugcheckserv);
Vue.component('Grpotr', grpotr);
Vue.component('CheckListControler', checkListControler);
Vue.component('Sogl', sogl);
Vue.component('Homeowners', homeowners);
Vue.component('Otheranalysfl', otheranalysfl);
Vue.component('MonthPeriodCorrectOtop', monthPeriodCorrectOtop);

Vue.component('UnloadLgot', unloadLgot);
Vue.component('UnloadLgotPost', unloadLgotPost);
Vue.component('UnloadOnDate', unloadOnDate);
Vue.component('UnloadOnMonth', unloadOnMonth);
Vue.component('UnloadReestr', unloadReestr);
Vue.component('UnloadToOszn', unloadToOszn);
Vue.component('UploadFile', uploadFile);
Vue.component('UnloadOnMonthXML', unloadOnMonthXML);
Vue.component('UnloadOnMonthTXT', unloadOnMonthTXT);
Vue.component('UnloadStorLs', unloadStorLs);
Vue.component('UnloadExchange', unloadExchange);
Vue.component('UnloadOnMR', unloadOnMR);