const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.СоответствиеЛицевых',
  description: 'Соответствие лицевых',
  fields: [
    {
      field: 'тип',
      text: 'Тип',
      type: 'Enum',
      dataModel: 'ЛицевыеСчета.СоответствиеЛицевых.Тип',
      width: '200px',
    },
    {
      field: 'номер',
      text: 'Свой номер',
      type: 'Number',
      readonly: true,
    },
    {
      field: 'сторонний',
      text: 'Сторонний номер',
      type: 'String',
    },
  ],
};
export default accs;
