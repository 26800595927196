
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      item: {
        датнач: null,
        даткнц: null,
        договор: null,
        поставщик: null,
        услуга: null,
        типсуммы: null,
        типоплаты: null,
      } as any,
    };
  },
});
