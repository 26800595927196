
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      items: [
        { title: 'Закрытие или\nблокировка месяца', img: 'action_close_month', to: { name: 'operations-closemonth' }, color: 'stack' },
        { title: 'Открытие или\nразблокировка месяца', img: 'action_open_month', to: { name: 'operations-openmonth' }, color: 'stack' },
        { title: 'Массовая печать квитанций', img: 'action_kvit_print', to: { name: 'operations-bulkprint' }, color: 'stack' },
        { title: 'Настройка реестра закрытия месяца', img: 'action_reestr_for_close_settings', to: { name: 'settings-closemonthreestr' }, color: 'stack' },
      ],
    };
  },
});
