
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    dataTable(): StackTableRow[] {
      if (this.record && this.record.значение) {
        const znachenie = this.record.значение.split(';');
        const znacheniemass = [];
        for (const item of znachenie) {
          const data = item.split(':');
          if (data[1]) {
            znacheniemass.push({ нпп: data[0], название: data[1] });
          }
        }
        return znacheniemass;
      }

      return [];
    },
  },
  data() {
    return {
      isChange: false,
      header: [
        {
          field: 'нпп',
          text: 'НПП',
          type: 'String',
          width: '30px',
          readonly: true,
        },
        {
          field: 'название',
          text: 'Название',
          type: 'String',
        },
      ],
    };
  },
  methods: {
    changeFunc(items: StackTableRow[]) {
      if (this.isChange) {
        let newVal = '';
        let нпп = 1;
        for (const item of items) {
          if (item.название) {
            newVal += нпп++ + ':' + item.название + ';';
          }
        }
        this.$set(this.record, 'значение', newVal.substr(0, newVal.length - 1));
      }
      this.isChange = true;
    },
  },
});
