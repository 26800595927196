
import Vue from 'vue';
import AddressFilter from '@/tasks/fl/views/$common/filters/addressFilter.vue';

export default Vue.extend({
  components: { 'address-filter-element': AddressFilter },
  model: {
    prop: 'params',
  },
  props: {
    params: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async onJump() {
      const parameters = {} as any;
      let foundOne = false;
      for (const field in this.params) {
        if (this.params[field] && (field === 'нп' || field === 'улица' || field === 'дом' || field === 'квартира')) {
          parameters[field] = this.params[field];
          foundOne = true;
        }
      }
      if (!foundOne) {
        this.$toast('Переход возможен только при указании адреса');
        return;
      }
      this.loading = true;
      const params = { фильтр: Object.assign(parameters, {}, { развернутьПапки: 1, сКорня: 1, переход: true }) };
      const results = await new this.$HttpModel('ЛицевыеСчета').getRecords(params);
      if (results.length === 1) {
        const rec: StackTableRow = results[0];
        this.tableAction({ action: 'filter', payload: { row_id: rec.$номерЗаписи } });
      } else {
        this.$toast('Найдено несколько адресов. Уточните поиск!');
      }
      this.loading = false;
    },
  },
});
