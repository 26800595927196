
import Vue from 'vue';
import serviceFilter from './serviceFilter.vue';

export default Vue.extend({
  components: { serviceFilter },
  data() {
    return {
      item: {
        модель: null,
        тарифность: null,
        услуга: null,
        интервал: null,
      } as any,
    };
  },
});
