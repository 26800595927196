
import Vue from 'vue';

export default Vue.extend({
  props: {
    dataModel: { type: Object, required: true },
    fund: { type: String, default: null },
    accounts: { type: String, default: null },
    month: { type: [String, Date], default: null },
    blockM: { type: [String, Date], default: null },
    disabled: { type: Boolean, required: true },
    enableAdmMode: { type: Boolean, default: false },
    justCloseAdmMode: { type: Boolean, default: false },
  },
  data() {
    return {
      jobID: null as null | number,
      operations: 0,
      operationOpen: false,
    };
  },
  computed: {
    dataOperations(): string[] {
      let array = [];
      if (this.justCloseAdmMode) {
        return ['Закрытие месяца для административной группы'];
      }
      if (this.enableAdmMode) {
        array = ['Закрытие месяца', 'Блокировка месяца', 'Полный расчет'];
      } else {
        array = ['Закрытие месяца', 'Блокировка месяца', 'Полный расчет'];
      }
      return array;
    },
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
  },
  methods: {
    async open(event: string) {
      this.operationOpen = true;
      let title = '';
      if (event === undefined) event = 'Закрытие месяца';
      switch (event) {
        case 'Закрытие месяца':
          title = 'Открытие месяца';
          break;
        case 'Блокировка месяца':
          title = 'Разблокировка месяца';
          break;
        case 'Полный расчет':
          title = 'Снятие блокировки с фонда';
          break;
      }
      const params = { фонд: this.fund, месяц: this.month, лицевые: this.accounts, событие: event };
      this.jobID = (await this.dataModel.executeMethod('открытие', params, { async: true })).asyncId;
      this.$store.commit('MSG_ADD', { title, asyncId: this.jobID, external: true });
      this.$emit('run');
    },
    async close() {
      const params = {
        фонд: this.fund,
        месяц: this.month,
        лицевые: this.accounts,
        событие: !this.justCloseAdmMode ? 'Закрытие месяца' : 'Закрытие месяца для административной группы',
      };
      this.jobID = (await this.dataModel.executeMethod('закрытие', params, { async: true })).asyncId;
      this.$store.commit('MSG_ADD', { title: 'Закрытие', asyncId: this.jobID, external: true, silent: true });
      this.$emit('run', { show: true, operation: params.событие });
    },
    async block() {
      const params = { фонд: this.fund, месяц: this.month, лицевые: this.accounts, событие: 'Блокировка месяца' };
      this.jobID = (await this.dataModel.executeMethod('блокировать', params, { async: true })).asyncId;
      this.$store.commit('MSG_ADD', { title: 'Блокировка', asyncId: this.jobID, external: true, silent: true });
      this.$emit('run', { show: true, operation: 'Блокировка месяца' });
    },
    async calc() {
      const params = { фонд: this.fund, месяц: this.month, лицевые: this.accounts, событие: 'Полный расчет' };
      this.jobID = (await this.dataModel.executeMethod('проверкаДоступностиОперации', params, { async: true })).asyncId;
      this.$store.commit('MSG_ADD', { title: 'Полный расчет', asyncId: this.jobID, external: true, silent: true });
      this.$emit('run', { show: false, operation: 'Полный расчет' });
    },
    selection() {
      switch (this.operations) {
        case 0:
          this.close();
          break;
        case 1:
          if (this.dataOperations.length === 2) {
            this.calc();
          } else {
            this.block();
          }
          break;
        case 2:
          this.calc();
          break;
      }
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.$store.commit('MSG_DELETE', this.jobID);
        this.jobID = null;
        this.$emit('end', { status: 'success', open: this.operationOpen });
        this.$store.dispatch('checkSystemState');
        this.operationOpen = false;
      }
    },
    isError(to) {
      if (to) {
        this.$toast(this.resultJob.status, { color: 'error' });
        this.$emit('end', { status: 'error', msg: this.resultJob.status });
        this.$store.commit('MSG_DELETE', this.jobID);
        this.jobID = null;
      }
    },
  },
});
