
import Vue from 'vue';
import datnachDatend from './$datNachdatEnd.vue';

export default Vue.extend({
  components: { datnachDatend },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    hideDate: { type: Boolean, default: false },
  },
  data() {
    return {
      itemCombo: [] as StackTableRow[],
    };
  },
  methods: {
    async changeCombo(to: any) {
      if (to) {
        const itemTemp = await new this.$HttpModel('ПараметрДопЗначения').getRecords({ ИмяПараметра: 'ТИПСТРОЙ', ЗначениеПараметра: to.$номерЗаписи });
        this.itemCombo = itemTemp.length === 1 ? [] : itemTemp;
      }
    },
  },
});
