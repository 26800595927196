
import Vue from 'vue';

type param = { название: string, инн: string, логин: string };

export default Vue.extend({
  data() {
    return {
      item: {} as param,
    };
  },
});
