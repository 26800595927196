const actsqu: StackSelection = {
  recordRoute: '',
  objectName: 'АктыСнятияКачества.Детализация',
  description: '',
  fields: [
    {
      field: 'датнач',
      text: 'с',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'по',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'коэффициент',
      text: 'Коэффициент',
      type: 'Number',
    },
    {
      field: '@тип акта',
      text: 'Тип',
      type: 'String',
    },
  ],
};
export default actsqu;
