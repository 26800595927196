import { TableHandlers } from '@/stackEngine';
const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ПоказанияСчетчиковИндивидуальные',
  description: 'Показания',
  recordRoute: 'reading',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.тип === 2) {
      TableHandlers.trColor(row, 'grey');
    } else {
      if (row['@тип ввода'] === 'КП') {
        TableHandlers.trColor(row, 'info');
      } else {
        if (row['@тип ввода'] === 'Коррекция') {
          TableHandlers.trColor(row, 'green');
        }
      }
    }
    return row;
  },
  fields: [
    {
      field: 'номер услуги',
      text: 'Номер\nуслуги',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название\nуслуги',
      type: 'String',
    },
    {
      field: 'Наименование',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: 'заводскойномер',
      text: 'Заводской\nномер',
      type: 'String',
      width: '20%',
    },
    {
      field: 'расчетный месяц',
      text: 'За',
      type: 'DateMonth',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'показание',
      text: 'Показание',
      type: 'NumberZero',
    },
    {
      field: 'расход',
      text: 'Расход',
      type: 'NumberZero',
    },
    {
      field: 'итоговый расход',
      text: 'Итоговый\nрасход',
      type: 'NumberZero',
    },
    {
      field: 'дополнительный расход',
      text: 'Доп.\nрасход',
      type: 'NumberZero',
    },
    {
      field: 'счмесяцев',
      text: 'СчМесяцев',
      type: 'Number',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'тариф',
      text: 'Зона\nсуток',
      type: 'String',
    },
    {
      field: '@зона',
      text: 'Зона\nсуток',
      type: 'Enum',
      width: '50px',
      computed: true,
    },
    {
      field: 'типввода',
      text: 'Тип\nввода',
      type: 'Number',
    },
    {
      field: '@тип ввода',
      text: 'Тип\nввода',
      type: 'Enum',
      computed: true,
    },
    {
      field: 'тип счетчика',
      text: 'Тип\nсчетчика',
      type: 'String',
    },
    {
      field: 'объект-показания',
      text: 'Объект-Показания',
      type: 'Number',
    },
    {
      field: 'тарифность',
      text: 'Тарифность',
      type: 'Number',
    },
    {
      field: 'разрядность',
      text: 'Разрядность',
      type: 'Number',
    },
    {
      field: 'дробнаяразрядность',
      text: 'Дробная\nразрядность',
      type: 'Number',
    },
    {
      field: 'коэффициент трансформации',
      text: 'КТранс',
      type: 'Number',
    },
    {
      field: 'показания-документ',
      text: 'Показания-Документ',
      type: 'Number',
    },
    {
      field: 'ограничениерасхода',
      text: 'ОграничениеРасхода',
      type: 'Number',
    },
  ],
};
export default accs;
