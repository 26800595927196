
import Vue from 'vue';
import debitorsActsFilter from '@/tasks/fl/views/$common/filters/debitorsActsFilter.vue';
import folder from '../violationsacts/$folder/violationactsfolder.vue';
import ActToolbar from './$debtorsactsid/ActToolbar.vue';
import debtorsactsid from './debtorsactsid.vue';

export default Vue.extend({
  name: 'Debtorsacts',
  components: { folder, debitorsActsFilter, ActToolbar, debtorsactsid },
});
