
import Vue from 'vue';

export default Vue.extend({
  props: {
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    ownerID: { type: Number, required: true },
    selectedValues: { type: [String, Number], default: undefined },
    selectedItems: { type: Array, required: true },
  },
  data() {
    return {
      newDialogVisible: false,
    };
  },
  computed: {
    selectionHeaders(): string {
      return this.dataObject.model === 'Объекты.Пломбы'
        ? 'номер,@вид пломбы,место установки,заводскойномер,фио'
        : 'номер,@вид пломбы,заводскойномер,фио,@состояние';
    },
    selectionDataModel(): string {
      return this.dataObject.model === 'Объекты.Пломбы' ? 'РеестрПломб' : 'РеестрБланков';
    },
  },
  methods: {
    async onBtnChoose(plomb: StackTableRow[]) {
      this.newDialogVisible = false;
      const typeobj = this.dataObject.model === 'Объекты.Бланки' ? 1 : 0;
      if (plomb && plomb[0]) {
        const res = await new this.$HttpModel('ЛицевыеСчета.Объекты').executeMethod('ПривязатьПломбу', { тип: typeobj, пломба: plomb[0].$номерЗаписи, объект: this.ownerID });
      }
      this.tableAction({ action: 'reload' });
    },
    async onDeleteClick() {
      if (this.selectedItems.length !== 0) {
        const typeobj = this.dataObject.model === 'Объекты.Бланки' ? 1 : 0;
        const res = await new this.$HttpModel('ЛицевыеСчета.Объекты').executeMethod('ОтвязатьПломбы', { тип: typeobj, пломба: this.selectedValues });
        this.tableAction({ action: 'reload' });
      } else {
        this.$toast('Нет отмеченных записей', { color: 'error' });
      }
    },
    async onPrintBlank() {
      new this.$HttpModel('РеестрБланков').executeMethod(
        'НапечататьБланки',
        { стрИдБланков: this.selectedValues },
        { async: true, jobName: `Печать бланков`, type: 'report' },
      );
    },
  },
});
