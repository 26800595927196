const ls: StackSelection = {
  objectName: 'ДелениеЛицевого',
  description: '',
  fields: [
    {
      field: 'операция',
      text: 'Операция',
      type: 'String',
    },
  ],
};

export default ls;
