
import Vue from 'vue';
import fund from './$components/fund.vue';

export default Vue.extend({
  name: 'Quickaddfund',
  components: { fund },
  data() {
    return {
      statusGenerateHouse: '',
      statusGenerateFund: '',
      valid: true,
      selHouse: {} as any,
      idHouse: null as null | number,
      House: {} as any,
      loading: false,
      newHouse: {
        нп: null,
        улица: null,
        номер: null as null | string,
        полныйномер: null,
        фамилия: null as null | string,
        индекс: '',
        гуид: '',
        типздания: 2,
      },
      CreateHouse: false as boolean,
      findHouse: false as boolean,
      findGuid: false as boolean,
      rulesThis: {
        isCorrectHouse: (value: string): string | true => {
          const pattern = /^([0-9])+([0-9A-zА-я., \-/])*$/;
          return !value || pattern.test(value) || 'Некорректно введен номер';
        },
        isCorrectHouseLetter: (value: string): string | true => {
          const pattern = /^[0-9A-zА-я., \-/]*$/;
          return !value || pattern.test(value) || 'Нельзя использовать специальные символы кроме .,/-';
        },
      },
    };
  },
  computed: {
    isSearchFieldsDisabled(): boolean {
      return this.CreateHouse || this.findHouse;
    },
  },
  methods: {
    async onFindHouse() {
      this.idHouse = null;
      this.loading = true;
      this.idHouse = await new this.$HttpModel('БыстрыйВводФонда').executeMethod('получитьВыбранныйДом', { metod: 'вставкадома', newhouse: this.newHouse });
      if (this.idHouse === -1) {
        if (this.newHouse.номер != null) {
          this.CreateHouse = !this.CreateHouse;
          this.findGuid = true;
        }
      } else {
        this.findHouse = !this.findHouse;
        this.statusGenerateHouse = 'success';
      }
      this.loading = false;
    },
    async onAddHouse() {
      if (this.findHouse !== true) {
        const parameters = { metod: 'вставкадома', newhouse: this.newHouse };
        this.idHouse = await new this.$HttpModel('БыстрыйВводФонда').executeMethod('Отправить', parameters);
        this.findHouse = !this.findHouse;
        this.findGuid = true;
        this.statusGenerateHouse = 'success';
      }
    },
    async onNumChange(payload: any) {
      if (this.selHouse != null) {
        // заполняем только если есть чем заполнять
        if (this.selHouse.номер) {
          this.newHouse.фамилия = this.selHouse.литера || '';
          this.newHouse.номер = this.selHouse.номер || null;
          this.newHouse.индекс = this.selHouse.индекс || '';
          this.newHouse.гуид = this.selHouse.гуид || '';
          this.findGuid = true;
        } else {
          const dom = this.newHouse.полныйномер || '';
          [, this.newHouse.номер, this.newHouse.фамилия] = dom.split(/^(\d+)/);
          this.newHouse.фамилия = this.newHouse.фамилия.trimLeft();
          this.newHouse.индекс = '';
          this.newHouse.гуид = '';
          this.findGuid = false;
        }
      } else {
        this.newHouse.индекс = '';
        this.newHouse.гуид = '';
        this.findGuid = false;
      }
    },
    onResetId() {
      this.idHouse = null;
      this.CreateHouse = this.findHouse = false;
      this.newHouse.номер = this.newHouse.полныйномер = this.newHouse.улица = this.newHouse.нп = null;
      this.newHouse.индекс = this.newHouse.гуид = this.newHouse.фамилия = '';
      this.statusGenerateHouse = '';
      this.$emit('end', { str: '', idHouse: this.idHouse });
    },
    onUpdateAddressField(field: string) {
      switch (field) {
        case 'нп':
          this.newHouse.улица = this.newHouse.полныйномер = null;
          break;
        case 'улица':
          this.newHouse.полныйномер = null;
          break;
        case 'типздания':
          this.newHouse.полныйномер = null;
          break;
        default:
          break;
      }
    },
  },
});
