const residentsdocs: StackSelection = {
  objectName: 'ЛицевыеСчета.КарточкиРегистрации.Контакты',
  recordRoute: '',
  description: 'Контакты',
  fields: [
    {
      field: 'номер',
      text: 'Контакт',
      type: 'String',
    },
    {
      field: 'тип',
      text: 'Тип 1',
      type: 'Number',
    },
    {
      field: '@тип',
      text: 'Тип',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: '@флаги',
      text: 'Участвует в рассылке',
      type: 'String',
    },
  ],
};
export default residentsdocs;
