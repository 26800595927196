
import Vue from 'vue';

import { TableHandlers } from '@/stackEngine';
import ProblemReadingsFilter from '@/tasks/fl/views/$common/filters/ProblemReadingsFilter.vue';
import ReadingDialog from './$problemstatements/ReadingDialog.vue';
import AddFund from './$problemstatements/AddFund.vue';

export default Vue.extend({
  components: { ProblemReadingsFilter, ReadingDialog, AddFund },
  props: {
    problemstatementsid: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      dialog: false,
      loading: false,
      selected: [] as StackTableRow[],
    };
  },
  computed: {
    title(): string {
      return `Ведомость сторонних показаний`;
    },
    HeadersVed(): string {
      return this.record['типведомости'] === 1
        ? 'номер одпу файл,одпу файл,услуга файл,заводскойномер файл,апи_зона,@датпредсч,@покпредсч,дата,показание,контроль-показание>итоговый расход,строшибка'
        : 'номерлс файл,адреслс файл,услуга файл,заводскойномер файл,апи_зона,@датпредсч,@покпредсч,дата,показание,контроль-показание>итоговый расход,строшибка';
    },
    // readonlyDialog(): boolean {
    //   return this.record && new Date(this.record.расчмесяц as Date) <= this.$store.getters.getCloseMonth();
    // },
  },
  methods: {
    onKeyPress(event: KeyboardEvent) {
      const input = event.target || (event.srcElement as any);
      if (input) {
        let tr = input.closest('tr');
        switch (event.keyCode) {
          case 38:
            tr = tr.previousElementSibling;
            break;
          case 40:
          case 13:
            tr = tr.nextElementSibling;
            break;
        }
        if (tr) {
          const nextInput = tr.querySelector('#pok input');
          if (nextInput) {
            nextInput.focus();
          }
        }
      }
    },
    async sendToCalc() {
      this.loading = true;
      await new this.$HttpModel('ВедомостиСтороннихПоказаний').executeMethod(
        'Отправить',
        { номерЗаписи: this.record.$номерЗаписи, выбранныеЗаписи: this.selected.map(row => row.$номерЗаписи).join(',') },
        {
          async: true,
          jobName: 'Отправка данных в расчет',
          type: 'func',
          onComplete: () => {
            this.loading = false;
            this.$sendMsg('stack-table', 'ВедомостиСтороннихПоказаний.Ведомость', 'unselect-all');
            this.selected = [];
            this.updateTable();
          },
          onError: () => {
            this.loading = false;
            this.updateTable();
          },
        },
      );
    },
    onDialogSave() {
      this.dialog = false;
      this.updateTable();
    },
    updateTable() {
      this.$sendMsg('stack-table', 'ВедомостиСтороннихПоказаний.Ведомость', 'reload');
    },
  },
});
