
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    date: { type: String, default: undefined },
  },
  data() {
    return {
      item: {
        НаДату: !this.date ? this.$store.getters.getCurrentDate() : this.date,
        'Вызов из веба': true,
      },
    };
  },
});
