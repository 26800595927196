
import Vue from 'vue';
import AgreementsAddBtn from './AgreementsAddBtn.vue';
import AgreementAddDialog from '../$resources/AgreementAddDialog.vue';

export default Vue.extend({
  components: { AgreementAddDialog },
  name: 'AgreementsTab',
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      dateBeginDialogVis: false,
    };
  },
  methods: {
    fetchData() {
      this.dateBeginDialogVis = false;
      // @ts-ignore
      this.$refs.table.fetchData();
    },
  },
});
