
import Vue from 'vue';
import { ReCalcModel } from './$classes/ReCalcModel';
import handleHeaders from '@/tasks/fl/selections/accounts/ЛицевыеСчета.Перерасчеты';
import recalcDialog from './$dialogs/recalcDialog.vue';

export default Vue.extend({
  components: { recalcDialog },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
    btnDisable(): boolean {
      return this.selectedItems.length === 0 || this.isReadonly;
    },
    isReadonly(): boolean {
      return this.$store.getters.isWorkMonthInClosedMonth();
    },
  },
  created() {
    // @ts-ignore TODO
    this.dataModel = new ReCalcModel([], { rowTemplate: this.headers, description: '' });
  },
  data() {
    return {
      dataModel: null as ReCalcModel | null,
      headers: handleHeaders.fields as StackField[],
      loading: false,
      selectedItems: [] as StackTableRow[],
    };
  },
  methods: {
    fetchData() {
      // @ts-ignore
      this.$refs.table.fetchData();
    },
    async deleteRecalc() {
      try {
        this.loading = true;
        if (this.dataModel && this.dataModel.executeMethod) {
          await this.dataModel.executeMethod('удалитьПерерасчет', { владелец: this.record.$номерЗаписи, записи: this.selectedItems });
        }
      } catch {
        this.$toast('Не удалось удалить перерасчёт !');
      } finally {
        this.selectedItems = [];
        this.loading = false;
        this.fetchData();
      }
    },
    select(to: StackTableRow[]) {
      this.selectedItems = [...to];
    },
  },
  watch: {
    workMonth(to) {
      this.selectedItems = [];
      this.fetchData();
    },
  },
});
