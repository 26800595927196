
import Vue from 'vue';
import InspectionsActSostDialog from './$resources/_inspectionsActSost.vue';
import FileTable from '@/tasks/common/views/$common/fileTable.vue';
import inspectionsActPok from './$resources/inspectionsact_pok.vue';
import inspectionsActToolbar from './$resources/InspectionsActToolbar.vue';

export default Vue.extend({
  components: { InspectionsActSostDialog, FileTable, inspectionsActPok, inspectionsActToolbar },
  props: {
    inspectionsactid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      record: {} as any,
      loading: false,
      tabs: null,
      showPokForDel: false,
      dataModel: new this.$HttpModel('АктыОбхода'),
      correctSaveField: false, // проверяем корректны поля лс и услуга
      double: false,
    };
  },
  computed: {
    isNewRecord(): boolean {
      return !(this.record.$номерЗаписи > 0) || this.record.$блок;
    },
    readononlyAct(): boolean {
      return this.record.счет < 0 || !this.record.счет || this.record.дата === '' || this.readonly || this.record.статус === 1 || this.record.$толькоЧтение;
    },
    datePok(): Date {
      return this.double ? this.$stackDate.firstDay(this.record.датапроверки) : this.record.датапроверки;
    },
    filter(): any {
      return {
        lastdate: this.record['@датапоследнейпроверки'],
        dateact: this.record['датапроверки'] || this.record['дата'],
        ls: this.record['счет'],
        usl: this.record['акт-услуга'],
        number: this.record['номер'],
        status: this.record['статус'],
      };
    },
    disabledFinishAct(): boolean {
      // @ts-ignore
      return this.readononlyAct || this.isNewRecord || !this.record.датапроверки || this.$refs.dialog.isChanged;
    },
    readonlyDialog(): boolean {
      return this.readonly || this.record.статус === 1;
    },
    disableInputFields(): boolean {
      return !this.isNewRecord && this.correctSaveField;
    },
    titlePok(): string {
      const strUsl = this.record['акт-услуга>название'];
      const strDate = this.$stackDate.format(this.datePok, 'dd.MM.yyyy');
      let str = `Удалить показания по ` + strUsl + ` позднее ` + strDate;
      if (this.double) {
        str += ` и установить состояние двойной недопуск?`;
      } else {
        str += ` и установить состояние по среднему на 3 месяца?`;
      }
      return str;
    },
  },
  created() {
    this.correctSaveField = +this.record.счет > 0 && +this.record['акт-услуга'] > 0;
  },
  methods: {
    async onBeforeSave() {
      this.correctSaveField = true;
      return true;
    },
    onWorkOutAkt() {
      this.dataModel.executeMethod('ОтработатьАктНедопуска', {
        счет: this.record['счет'],
        услуга: this.record['акт-услуга'],
        дата: this.record.датапроверки,
        двойной: this.double,
      });
    },
    onSavePok() {
      this.onWorkOutAkt();
      this.onClosePok();
    },
    onClosePok() {
      this.showPokForDel = false;
      this.loading = false;
      this.reloadTables();
    },
    async onFinishAct() {
      if (this.record['статусконтролера'] !== 2 && this.record['@естьмпконтр'] === 1) {
        this.$toast('Статус акта не соответствует выполняемой операции!', { color: 'error' });
        return;
      }
      const answer = await this.$yesno('Будут внесены необратимые изменения на ЛС. Вы уверены?');
      if (answer) {
        this.loading = true;
        await new this.$HttpModel('АктыОбхода').executeMethod('ОтработатьАкт', { номерЗаписи: this.record.$номерЗаписи });
        if (this.record['видакта'] === 1) {
          const resCheckDouble = await this.dataModel.executeMethod('ПроверитьДвойнойНедопуск', {
            счет: this.record['счет'],
            услуга: this.record['акт-услуга'],
            дата: this.record.датапроверки,
            месяц: this.datePok,
            текид: this.record.$номерЗаписи,
          });
          this.double = resCheckDouble.флаг === 1;
          // Задаем вопрос, только есть действующие счетчики
          if (+resCheckDouble.есть_счетчики === 1) {
            if (+resCheckDouble.есть_показания === 0) {
              let strAsk = ``;
              if (this.double) {
                strAsk = `Произошел двукратный недопуск к прибору учета. Устанавливаем признак двукратного недопуска?`;
              } else {
                strAsk = `Устанавливаем состояние по среднему на 3 месяца?`;
              }
              if (await this.$yesno(strAsk)) {
                this.onWorkOutAkt();
              }
            } else {
              this.showPokForDel = true;
            }
          } else {
            if (this.double) {
              this.onWorkOutAkt();
            }
          }
        }
        if (!this.showPokForDel) {
          this.loading = false;
          this.reloadTables();
        }
      }
    },
    async onChangeParams() {
      this.loading = true;
      if (this.isNewRecord && +this.record.счет > 0 && +this.record['акт-услуга'] > 0) {
        const res = await new this.$HttpModel('АктыОбхода').executeMethod('ЗаполнитьОперацииПоУмолчанию', {
          номерЗаписи: this.record.$номерЗаписи,
          счет: this.record.счет,
          видакта: this.record.видакта,
          услуга: this.record['акт-услуга'],
        });
        if (res) {
          this.record['@датапоследнейпроверки'] = res.ДатаПоследнейПроверки;
        }
        this.$sendMsg('stack-table', 'АктыОбходаСостав', 'reload');
      }
      this.loading = false;
    },
    isLinked(value: number): string | true {
      return (value !== undefined && value !== null && value >= 0) || 'Выберите услугу';
    },
    reloadTables() {
      this.$sendMsg('stack-dialog', 'АктыОбхода', 'reload');
      this.$sendMsg('stack-table', 'АктыОбходаСостав', 'reload');
    },
  },
});
