
import Vue from 'vue';

export default Vue.extend({
  props: {
    workersid: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
    };
  },
  computed: {
    title(): string {
      return `Сотрудник`;
    },
  },
});
