
import Vue from 'vue';
import StackRssFeed from '../StackRssFeed/StackRssFeed.vue';

export default Vue.extend({
  components: {
    StackRssFeed,
  },
  computed: {
    isRSSVisible(): boolean {
      return this.rssOpen && this.$vuetify.breakpoint.lgAndUp;
    },
    rssOpen(): boolean {
      return this.$store.state.configStore.rssOpen;
    },
    getRssFeedUrl(): string {
      return this.$store.getters.getRssFeedUrl();
    },
  },
  methods: {
    onRssStateClick(state: boolean) {
      this.$store.commit('SET_RSS_STATE', state);
    },
  },
});
