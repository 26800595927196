const actsqu: StackSelection = {
  recordRoute: 'documents-correctionsaldostatements-correctionsaldostatementsid',
  objectName: 'ВедомостиКоррекцииСальдо',
  description: 'Ведомости коррекции сальдо',
  hier: true,
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'расчмесяц',
      text: 'Расчетный месяц',
      type: 'DateMonth',
    },
    {
      field: 'название',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'кол_во',
      text: 'Количество',
      type: 'Quantity',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'причина-коррекция',
      text: 'причина-коррекция',
      type: 'String',
    },
    {
      field: 'причина-коррекция>название',
      text: 'Причина',
      type: 'String',
    },
  ],
};
export default actsqu;
