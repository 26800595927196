
import Vue from 'vue';
import datNachdatEnd from './$datNachdatEnd.vue';
export default Vue.extend({
  components: { datNachdatEnd },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    label: { type: String, default: '' },
    mask: { type: String, default: undefined },
    hideDate: { type: Boolean, default: false },
  },
  data() {
    return {
      list: [] as number[],
    };
  },
  created() {
    const s = this.record.примечание || '';
    const data = s.split(';', 12);

    let val = 0;
    for (let i = 0; i < 12; i++) {
      if (data[i]) {
        val = parseFloat(data[i]);
      } else {
        val = 0;
      }
      this.list.push(val);
    }
  },
  methods: {
    onChange() {
      this.record.примечание = '';

      let val = 0;
      let resValue = '';
      for (let i = 0; i < 12; i++) {
        if (this.list[i]) {
          val = this.list[i];
        } else {
          val = 0;
        }
        resValue += val + ';';
      }
      this.$set(this.record, 'примечание', resValue);
    },
  },
});
