const accs: StackSelection = {
  objectName: 'ИзменяемыеТаблицы.МеняетсяТип',
  description: '',
  fields: [
    {
      field: 'имя',
      text: 'Имя',
      type: 'String',
    },
    {
      field: 'тип',
      text: 'Прежний тип',
      type: 'String',
    },
    {
      field: 'размер',
      text: 'Прежний размер',
      type: 'String',
    },
    {
      field: 'точность',
      text: 'Прежняя точность',
      type: 'Number',
      width: '30',
    },
    {
      field: 'масштаб',
      text: 'Прежний масштаб',
      type: 'Number',
      width: '30',
    },
    {
      field: 'новый тип',
      text: 'Новый Тип',
      type: 'String',
    },
    {
      field: 'новый размер',
      text: 'Новый  размер',
      type: 'String',
    },
    {
      field: 'новая точность',
      text: 'Новая точность',
      type: 'Number',
      width: '30',
    },
    {
      field: 'новый масштаб',
      text: 'Новый масштаб',
      type: 'Number',
      width: '30',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'проверено',
      text: 'Проверено',
      type: 'Number',
      width: '30',
    },
  ],
};
export default accs;
