
import Vue from 'vue';

export default Vue.extend({
  props: {
    stepName: { type: String, required: true },
    steps: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      valid: true,
    };
  },
});
