import {
  mdiCached,
  mdiAt,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiChevronDown,
  mdiArrowDown,
  mdiCancel,
  mdiAlert,
  mdiAlertOutline,
  mdiClose,
  mdiCheck,
  mdiPlus,
  mdiFolderPlus,
  mdiCash,
  mdiCashRefund,
  mdiWhiteBalanceSunny,
  mdiBrightness2,
  mdiCallMissed,
  mdiCallMade,
  mdiCallSplit,
  mdiPencil,
  mdiContentCopy,
  mdiDelete,
  mdiHistory,
  mdiFolderOutline,
  mdiGauge,
  mdiPhoneLog,
  mdiArrowLeft,
  mdiRotateLeft,
  mdiPlayCircle,
  mdiAccountClock,
  mdiCashMultiple,
  mdiContentSave,
  mdiCalculator,
  mdiCellphone,
  mdiPanoramaFisheye,
  mdiCloseCircle,
  mdiCreditCard,
  mdiCreditCardMultiple,
  mdiArrowCollapse,
  mdiDownload,
  mdiDownloadMultiple,
  mdiAlertCircleOutline,
  mdiCalendar,
  mdiArrowExpand,
  mdiFileDocument,
  mdiFileChart,
  mdiXml,
  mdiFilePdfBox,
  mdiFlag,
  mdiFlash,
  mdiLightningBoltCircle,
  mdiFilterVariant,
  mdiHelp,
  mdiArrowTopLeft,
  mdiHome,
  mdiHomeModern,
  mdiHomeAlert,
  mdiLink,
  mdiLinkBoxOutline,
  mdiDotsVertical,
  mdiMenu,
  mdiMinusCircleOutline,
  mdiCheckboxMarkedCircle,
  mdiPackageUp,
  mdiHomeCircle,
  mdiPalette,
  mdiPaperclip,
  mdiCubeSend,
  mdiPlusCircleOutline,
  mdiPrinter,
  mdiPrinterSettings,
  mdiCardAccountDetails,
  mdiAccountMultipleMinusOutline,
  mdiAccountMultiplePlusOutline,
  mdiAccountBox,
  mdiAccountOutline,
  mdiAccountCircle,
  mdiAccountCheck,
  mdiShieldAccount,
  mdiAccountStar,
  mdiPhone,
  mdiPhoneSettings,
  mdiPhoneOff,
  mdiPlay,
  mdiRefresh,
  mdiReload,
  mdiRestore,
  mdiMagnify,
  mdiArrowRight,
  mdiMessageBulleted,
  mdiTimerSand,
  mdiUpdate,
  mdiUpload,
  mdiLock,
  mdiInformationOutline,
  mdiBriefcase,
  mdiAccountHardHat,
  mdiLockOpen,
  mdiCashRegister,
  mdiContentCut,
  mdiReceipt,
  mdiAlarm,
  mdiPlusCircleMultipleOutline,
  mdiArrowRightBoldBox,
  mdiLightbulb,
  mdiArrowExpandVertical,
  mdiOpenInNew,
  mdiTextBoxMultipleOutline,
  mdiWeatherSunny,
  mdiTransferLeft,
  mdiTransferRight,
  mdiDisc,
  mdiSigma,
  mdiAlphaCCircleOutline,
  mdiCloudDownload,
  mdiCloudUpload,
  mdiEmailOpenOutline,
  mdiEmailOff,
  mdiEmailOutline,
  mdiReply,
  mdiReplyOutline,
  mdiStop,
  mdiPause,
  mdiAccountMultipleOutline,
  mdiCogOutline,
  mdiMerge,
  mdiCounter,
  mdiCalendarClock,
  mdiWeb,
  mdiNoteText,
  mdiPhoneMissed,
  mdiEmail,
  mdiFileAlertOutline,
  mdiFileOutline,
  mdiFileCancelOutline,
  mdiBank,
  mdiSwapVertical,
  mdiFinance,
  mdiArchive,
  mdiNas,
  mdiLan,
  mdiClockStart,
  mdiTable,
  mdiRadiator,
  mdiWater,
  mdiFire,
  mdiLogin,
  mdiCircle,
  mdiFolderOpen,
  mdiFolderText,
  mdiAccount,
  mdiHelpCircleOutline,
  mdiLockOutline,
  mdiCalendarAlert,
  mdiAlertDecagramOutline,
  mdiCalendarQuestion,
  mdiDeleteSweepOutline,
  mdiFunction,
  mdiFileQuestion,
  mdiRestart,
  mdiCheckAll,
  mdiArrowExpandAll,
  mdiPowerPlug,
  mdiClockOutline,
  mdiFileMove,
  mdiTableRowPlusBefore,
  mdiArrowUp,
  mdiMenuRight,
  mdiMenuDown,
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatStrikethrough,
  mdiFormatUnderline,
  mdiCodeNotEqualVariant,
  mdiFormatParagraph,
  mdiFormatListNumbered,
  mdiFormatQuoteClose,
  mdiCodeTags,
  mdiMinus,
  mdiUndo,
  mdiRedo,
  mdiFormatListBulleted,
  mdiVolumePlus,
  mdiVolumeMinus,
  mdiHomeOutline,
  mdiCreditCardOutline,
  mdiFileDocumentOutline,
  mdiChartDonut,
  mdiForumOutline,
  mdiBookOpenOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAlarmLight,
  mdiFileRemove,
  mdiBroom,
  mdiToggleSwitch,
  mdiToggleSwitchOff,
  mdiLockOpenVariantOutline,
  mdiDipSwitch,
  mdiImage,
  mdiSineWave,
  mdiPhoneMissedOutline,
  mdiFormatListCheckbox,
  mdiPhoneLogOutline,
  mdiPhoneOutline,
  mdiPhoneMessageOutline,
  mdiScriptTextOutline,
  mdiDomain,
  mdiFlashOutline,
  mdiSourceMerge,
  mdiSourcePull,
  mdiNotebookOutline,
  mdiFileEditOutline,
  mdiCircleMedium,
  mdiViewParallel,
  mdiClock,
  mdiMinusCircle,
  mdiDesktopClassic,
  mdiKeyboardSettingsOutline,
  mdiTextBoxCheckOutline,
  mdiNewspaperPlus,
  mdiCommentQuoteOutline,
  mdiLockReset,
  mdiLogout,
  mdiCardAccountPhoneOutline,
  mdiCardAccountDetailsOutline,
  mdiBriefcaseOutline,
  mdiCardAccountDetailsStarOutline,
  mdiFilterVariantRemove,
  mdiPrinterCheck,
  mdiHandPointingUp,
  mdiApplication,
  mdiProgressWrench,
  mdiArchiveArrowDown,
  mdiFaceRecognition,
  mdiDatabase,
  mdiMicrosoftAccess,
  mdiSend,
  mdiNewspaperVariant,
  mdiRepeat,
  mdiTextSearch,
  mdiSpeedometer,
  mdiSmartCardOutline,
  mdiCardBulletedOutline,
  mdiBriefcaseCheckOutline,
  mdiBriefcaseAccountOutline,
  mdiBriefcaseSearchOutline,
  mdiBriefcaseClockOutline,
  mdiBackburger,
  mdiFileChartOutline,
  mdiMessageTextOutline,
  mdiDeleteForeverOutline,
  mdiDeleteOutline,
  mdiBell,
  mdiLockOpenOutline,
  mdiLockAlertOutline,
  mdiStar,
  mdiFolderStar,
  mdiStarOutline,
  mdiCheckCircleOutline,
  mdiMapMarkerOutline,
  mdiApplicationCog,
  mdiHandBackLeftOutline,
  mdiCloseBox,
  mdiAccountCheckOutline,
  mdiCar,
  mdiWalk,
  mdiAccountArrowRight,
} from '@mdi/js';

export const icons = {
  account: mdiAccount,
  accounts: mdiAccountMultipleOutline, // главное меню Адреса проживающих
  accounts_ul: mdiDomain, // главное меню Адреса объектов ЮЛ
  references: mdiBookOpenOutline, // главное меню справочники
  reports: mdiChartDonut, // главное меню Отчеты
  settings: mdiCogOutline, // главное меню Настройки
  netstructure: mdiFlashOutline, // главное меню Структура сети
  nettree: mdiSourceMerge, // главное меню Структура сети - Дерево
  netnomenclature: mdiSourcePull, // главное меню Структура сети - Номенклатура
  payments: mdiCreditCardOutline, // главное меню Платежи
  metersdata: mdiCounter, // главное меню Приборы учёта
  operations: mdiCalendarClock, // главное меню Операции
  gis: mdiWeb, // главное меню ГИС-ЖКХ
  infocenter: mdiForumOutline, // главное меню Центр информирования
  closemonth: mdiLockOutline, // главное меню Закрытие месяца
  database: mdiDatabase, // Главное меню арм администратора База данных
  access: mdiMicrosoftAccess, // Главное меню арм администратора Права доступа
  speedometer: mdiSpeedometer, // Помощь > Качество связи
  srv_operations: mdiApplicationCog, // Сервисные операции
  appointments: mdiAccountCheckOutline, // Запись на приём

  month_close: mdiLockOutline, // Месяц закрыт в туллбаре
  month_adm: mdiAlertDecagramOutline, // Месяц в административном режиме
  month_alert: mdiCalendarAlert, // Месяц с восклицательным знаком
  month_half_open: mdiLockAlertOutline, // Месяц частично
  month_open: mdiLockOpenOutline, // Месяц открыт
  bell: mdiBell,
  help_outline: mdiHelpCircleOutline, // помощь
  help_start: mdiClockStart, // Начало работы
  help_tours: mdiDesktopClassic, // Интерактивные туры
  help_hotkeys: mdiKeyboardSettingsOutline, // Горячие клавиши
  help_online: mdiTextBoxCheckOutline, // Документация
  help_history: mdiNewspaperPlus, // История версий
  history_new_version: mdiAlertDecagramOutline,
  help_askme: mdiCommentQuoteOutline, // Обратная связь

  nomenclature: mdiNoteText, // главное меню Номенклатура
  missedcalls: mdiPhoneMissed, // главное меню Пропущенные звонки
  missedcalls_сс: mdiPhoneMissedOutline, // главное меню Пропущенные звонки КЦ
  requestlog: mdiFormatListCheckbox, // главное меню Журнал обращений
  phonelog: mdiPhoneLog, // главное меню Журнал звонков
  phonelog_сс: mdiPhoneLogOutline, // главное меню Журнал звонков КЦ
  phone_сс: mdiPhoneOutline, // главное меню Звонки КЦ
  telephonemessage: mdiPhoneMessageOutline, // главное меню Телефонограммы КЦ
  mail: mdiEmail, // главное меню Почта
  documents: mdiFileDocumentOutline, // главное меню Документы
  pretension: mdiFileAlertOutline, // главное меню Претензии
  lawsuit: mdiFileOutline, // главное меню Иски
  restrictions: mdiFileCancelOutline, // главное меню Ограничения
  contracts: mdiScriptTextOutline, // главное меню Договоры
  acts: mdiFileEditOutline, // главное меню Договоры
  onlinecashbox: mdiCashMultiple, // Онлайн касса
  books: mdiNotebookOutline, // книги продаж покупок
  external_res: mdiLinkBoxOutline, // главное меню Внешние ресурсы
  surveys: mdiFormatListCheckbox, // главное меню Опросы

  exchange: mdiSwapVertical, // Обмен
  monitoring: mdiFinance, // Мониторинг
  archive: mdiArchive, // Архив
  master: mdiNas, // Мастер каких нибудь операций
  lan: mdiLan, // Сеть
  events: mdiClockStart, // Журнал событий

  table: mdiTable, // Таблица
  table_move_record: mdiFileMove, // перенести записи
  table_fields: mdiTableRowPlusBefore, // Поля таблицы
  table_sort_headers: mdiArrowUp, // Сортировка заголовков
  table_unexpand: mdiMenuDown, // свернуть строчку
  table_expand: mdiMenuRight, // развернуть строчку

  login: mdiLogin, // Логин
  eye: mdiEyeOutline, // Пароль виден
  eye_off: mdiEyeOffOutline, // Пароль скрыт
  circle: mdiCircle, // Круг

  bank: mdiBank, // Банк

  claimsmenu: mdiCardAccountPhoneOutline, // Заявки разводящее меню
  claims: mdiCardAccountDetailsOutline, // Заявки
  orgclaims: mdiCardAccountDetailsStarOutline, // Заявки по организации
  tasks: mdiBriefcaseOutline, // Работы
  phonemessages: mdiPhoneMessageOutline, // Телефонограммы
  telephony: mdiPhoneOutline, // Телефония

  loading: mdiCached, // ожидание при чтении узла дерева stacktree
  email: mdiAt, // иконка значка email
  prev_month: mdiChevronLeft, // пред рабмес в тулбаре
  next_month: mdiChevronRight, // след рабмес в тулбаре
  arrow_up: mdiArrowUp, // стрелка вверх в арм админ CalcSaldo
  arrow_down: mdiArrowDown, // стрелка вниз для конечной записи в реестре ук b в арм админ CalcSaldo
  block: mdiCancel, // иконка блокировки для статуса ОДПУ
  alert: mdiAlert, // восклицательный знак в треугольнике для статуса "Внимание"
  alert_outline: mdiAlertOutline, // восклицательный знак в треугольнике outline
  close: mdiClose, // крестик на диалогах
  check: mdiCheck, // галочка для статуса "Ок"
  check_all: mdiCheckAll, // галочка для статуса "Ок"
  check_circle: mdiCheckCircleOutline,
  done: mdiCheck, // галочка для заданий, завершено
  calc: mdiCash, // иконка расчета
  recalc: mdiCashRefund, // иконка перерарсчета
  upfolder: mdiChevronLeft, // Перейти на уровень выше по иерархии
  minimizeNavbar: mdiBackburger, // минимизировать главное меню (navbar)
  zone_day: mdiWhiteBalanceSunny, // показание день
  zone_night: mdiBrightness2, // показание ночь
  zone_ppik: mdiCallMissed, // показание ппик
  zone_pik: mdiCallMade, // показание пик
  print_ctrl_f8: mdiPrinterCheck, // печать отчета аналог CTRL+F8 в десктопе
  network_scheme: mdiCallSplit, // иконка для файла схемы сети

  add: mdiPlus, // Добавить в тулбаре
  new_folder: mdiFolderPlus, // иконка добавления папки в тулбарах
  edit: mdiPencil, // иконка с ручкой при редактировании
  copy: mdiContentCopy, // копирование
  delete: mdiDelete, // иконка удаления
  merge: mdiMerge,
  favorite: mdiStarOutline, // Иконка избранных отчетов
  favorite_selected: mdiStar, // Иконка избранных отчетов

  history: mdiHistory, // значок истории в таблицах
  folder: mdiFolderOutline, // иконка папки
  folder_open: mdiFolderOpen, // иконка папки
  record: mdiFolderText, // запись в папке
  folder_favorite: mdiFolderStar,

  ipu_counter: mdiGauge, // иконка счетчика ИПУ
  phone_logs: mdiPhoneLog, // иконка Журнала звонков
  phone_out: mdiArrowLeft, // иконка исходящий вызов
  phone_repeat: mdiRotateLeft, // иконка перезвона
  phone_play: mdiPlayCircle, // иконка прослушивания

  logout: mdiLogout, // разлогин
  reset_password: mdiLockReset, // Смена пароля в меню пользователя

  clock: mdiAccountClock, // часики в ассинхронных задачах
  cash_multiple: mdiSineWave, // иконка оборотов в тулбаре расчета и кнопки в ордере на инкассацию
  demand: mdiCashMultiple, // иконка требований
  content_save: mdiContentSave, // иконка сохранения в платежах
  calculator: mdiCalculator, // значок калькуляции в услугах
  cellphone: mdiCellphone, // иконка мобилки
  circle1: mdiPanoramaFisheye, // пустой кружок для статуса в ОДПУ
  close_circle: mdiCloseCircle, // крестик в кружочке, для ошибок
  credit_card: mdiCreditCard, // иконка кредитки
  credit_cards: mdiCreditCardMultiple, // иконка кредиток
  compare: mdiArrowCollapse, // сравнение чего-либо
  download: mdiDownload, // загрузить откуда то
  error: mdiAlertCircleOutline, // восклицательный знак в кружке для "Ошибок"
  event: mdiCalendar, // иконка календаря в таблице показаний
  expend: mdiArrowExpand, // значок дополнительного чего нибудь
  expand_all: mdiArrowExpandAll, // значок дополнительного чего нибудь
  file: mdiFileDocument, // иконка файла для юриков
  fileArchive: mdiArchiveArrowDown, // иконка архива файлов во внешних документах
  file_box: mdiFileDocument, // иконка файла для юриков в квадратике
  file_chart: mdiFileChart, // иконка файла со статистикой для юриков
  file_xml: mdiXml, // иконка файла xml
  file_pdf: mdiFilePdfBox, // иконка файла pdf
  flag: mdiFlag, // флаг для статуса в ОДПУ
  flash: mdiFlash, // значек молнии, как на лбу у гарри поттера
  flash_circle: mdiLightningBoltCircle, // значек молнии в кружочке
  filter_list: mdiFilterVariant, // иконка фильтров в тулбаре
  filter_remove: mdiFilterVariantRemove, // иконка сброса фильтров в тулбаре
  help: mdiHelp, // помощь для интеграции
  hier: mdiArrowTopLeft, // иконка иерархии
  home: mdiHome, // дом в хлебных крошках
  home_outline: mdiHomeOutline, // дом главном меню
  home_modern: mdiHomeModern, // современный дом в услугах для тарифов ук
  home_alert: mdiHomeAlert, // дом с восклицательным знаком в услугах
  link: mdiLink, // иконка кнопки ссылки
  menu_vert: mdiDotsVertical, // меню вертикальное в иконках записи
  menu: mdiMenu, // иконка меню
  minus_circle: mdiMinusCircleOutline, // минус в круге для статуса в ОДПУ
  inline_save: mdiCheckboxMarkedCircle, // сохранение в инлайн таблице
  jump: mdiPackageUp, // переход куда нить в должниках
  newspaper: mdiNewspaperVariant, // иконка новостей
  odpu_counter: mdiHomeCircle, // иконка счетчика ОДПУ
  page: mdiFileDocument, // иконка листка с текстом
  palette: mdiPalette, // иконка палитры
  paperclip: mdiPaperclip, // иконка скрепки
  pay_move: mdiCubeSend, // переносим проблемные платежи
  plus_circle: mdiPlusCircleOutline, // плюс в круге для статуса в ОДПУ
  printer: mdiPrinter, // иконка принтера
  printer_settings: mdiPrinterSettings, // иконка принтера
  person_card: mdiCardAccountDetails, // иконка человека с карточкой детализации
  persons_minus: mdiAccountMultipleMinusOutline, // времеенное отсутствие
  persons_plus: mdiAccountMultiplePlusOutline, // времеенное присутствие
  person_box: mdiAccountBox, // иконка человека в квадратике для коллцентра
  person_outline: mdiAccountOutline, // иконка человека в карточке регистрации
  person_circle: mdiAccountCircle, // иконка человека в ручных перерасчетах
  person_сheck: mdiAccountCheck, // иконка человека с галкой
  person_shield: mdiShieldAccount, // иконка человека на щите
  person_star: mdiAccountStar, // иконка человека со звездой
  phone: mdiPhone, // иконка телефона
  phone_settings: mdiPhoneSettings, // иконка Настройки телефона
  phone_off: mdiPhoneOff, // иконка Телефон. Нет связи
  clean: mdiBroom, // иконка веника для очистки поля
  pointer: mdiHandPointingUp, // иконка руки указателя
  aplication: mdiApplication, // иконка диолога
  debug: mdiProgressWrench, // иконка отладки

  play: mdiPlay, // иконка проиграть
  pause: mdiPause, // иконка пауза
  start: mdiClockStart, // иконка старт
  stop: mdiStop, // иконка стоп
  volume_plus: mdiVolumePlus, // иконка громкость +
  volume_minus: mdiVolumeMinus, // иконка громкость -

  refresh: mdiRefresh, // иконка перезагрузки бекенда
  reload: mdiReload, // обновление таблицы
  restore: mdiRestore, // отмена изменений
  save: mdiContentSave, // иконка сохранения в меню интеграции
  search: mdiMagnify, // иконка поиска в тулбаре
  text_search: mdiTextSearch,
  skip: mdiArrowRight, // стрелка вправо - пропустить
  sms: mdiMessageBulleted, // смска
  timer_sand: mdiTimerSand, // песочные часы, когда чтото в процессе
  update: mdiUpdate, // иконка обновления в тулбарах
  upload: mdiUpload, // выгрузка куда-либо
  lock: mdiLock, // иконка замка на записях закрытых для редактирования
  info_outline: mdiInformationOutline, // иконка информации в карточке регистрации
  work: mdiBriefcase, // чемоданчик для отчетов
  worker: mdiAccountHardHat, // иконка рабочего
  unlock: mdiLockOpen, // иконка открытого замка
  unlock_outline: mdiLockOpenVariantOutline, // иконка совсем открытого замка
  cash_register: mdiCashRegister, // иконка фр
  cut_check: mdiContentCut, // иконка отрезать чек
  receipt: mdiReceipt, // иконка контрольной ленты
  collapse: mdiArrowCollapse, // объединение организаций в итеграции
  hourly_rates: mdiAlarm, // иконка часов для почасовых тарифов
  update_summ: mdiPlusCircleMultipleOutline, // плюс в нескольких кругах для обновления суммы задолженности
  transition: mdiArrowRightBoldBox, // иконка для масоового перехода
  lightbulb: mdiLightbulb, // иконка лампочки для адресного в ЮЛ
  expand_folders: mdiArrowExpandVertical, // Развернуть папки в таблице
  open_in_new_tab: mdiOpenInNew, // открыть в новой вкладке
  print_kit: mdiTextBoxMultipleOutline, // Печать  комплектов
  sun: mdiWeatherSunny, // иконка РСО для ЛС ЮЛ
  transfer_left: mdiTransferLeft, // перенести в левую часть
  transfer_right: mdiTransferRight, // перенести в правую часть
  disc: mdiDisc, // иконка для схемы подключения
  sum: mdiSigma, // значек суммы, подведения итогов
  sub: mdiAlphaCCircleOutline, // значек субабонента
  closeDoc: mdiFileRemove, // значек закрытия соглашений
  edoSend: mdiSend, // иконка отправки документов в эдо ( самолетик )
  closeBox: mdiCloseBox, // значек ошибки при массовой простановки гуид

  email_download: mdiCloudDownload,
  email_upload: mdiCloudUpload,
  email_lock: mdiLock, // иконка замка на записях писем
  email_unlock: mdiLockOpen, // иконка замка на записях писем
  email_flag: mdiFlag, // флаг для статуса в ОДПУ
  email_open: mdiEmailOpenOutline, // открытое письмо
  email_off: mdiEmailOff, // ошибка при отправке письма
  email_сlose: mdiEmailOutline, //  письмо
  email_reply: mdiReply, // ответ на емейл
  email_resend: mdiReplyOutline, // ответ на емейл

  ci_stop: mdiStop, // статус компании в ЦИ
  ci_play: mdiPlay, // статус компании в ЦИ
  ci_pause: mdiPause, // статус компании в ЦИ
  ci_send: mdiCubeSend, // статус компании в ЦИ

  pu_heat: mdiRadiator, // Значок отопления
  pu_water: mdiWater, // Значок Водоснабжение
  pu_gas: mdiFire, // Значок Газоснабжение
  pu_el: mdiFlash, // Значок Электроснабжение

  async_wait: mdiTimerSand, // Асинхр задания - ждём результата
  async_error: mdiAlertCircleOutline, // Асинхр задания - ждём результата
  async_done: mdiCheck, // Асинхр задания - успешно завершено
  async_delete_all: mdiDeleteForeverOutline, // Асинхр задания - удалить всё
  async_delete: mdiDeleteOutline, // Асинхр задания - удалить всё
  async_attach: mdiDownload, // Асинхр задания - есть вложения
  async_attach_multi: mdiDownloadMultiple, // Асинхр задания - есть вложения
  async_type_report: mdiFileChartOutline, // Асинхр задания - отчет
  async_type_func: mdiFunction, // Асинхр задания - функция (операция)
  async_type_file: mdiPaperclip, // Асинхр задания - файл
  async_type_other: mdiFileQuestion, // Асинхр задания - разное
  async_type_message: mdiMessageTextOutline, // Асинхр задания - сообщения
  report_more: mdiMenuRight, // Подменю списка отчетов
  report_list: mdiFormatListBulleted, // Список отчетов
  report: mdiFileChartOutline, // иконка отчета
  restart: mdiRestart, // перезагрузить бек
  plugin: mdiPowerPlug, // Меню туллбара - Установка плагина
  param_repeat: mdiRepeat, // Диалог отчетов. Заполнить поля последними выбранными данными
  handRecalc: mdiHandBackLeftOutline, // иконка руки для ручных перерасчетов

  date_field_icon: mdiCalendar, // иконка календаря stackDateTime
  time_field_icon: mdiClockOutline, // иконка StackTimeField
  link_field_icon: mdiLink, // иконка поля связи

  toggle_switch: mdiToggleSwitch, // переключатель вкл
  toggle_switch_off: mdiToggleSwitchOff, // переключатель выкл
  // иконки редактора
  editor_bold: mdiFormatBold,
  editor_italic: mdiFormatItalic,
  editor_strike: mdiFormatStrikethrough,
  editor_underline: mdiFormatUnderline,
  editor_code: mdiCodeNotEqualVariant,
  editor_paragraph: mdiFormatParagraph,
  editor_bullet_list: mdiFormatListBulleted,
  editor_ordered_list: mdiFormatListNumbered,
  editor_blockquote: mdiFormatQuoteClose,
  editor_code_block: mdiCodeTags,
  editor_horizontal_rule: mdiMinus,
  editor_undo: mdiUndo,
  editor_redo: mdiRedo,
  editor_img: mdiImage,
  // Иконки АДС
  part_claims: mdiSmartCardOutline, // Заявки по ТП
  others_claims: mdiCardBulletedOutline, // Прочие заявки
  all_tasks: mdiBriefcaseCheckOutline, // Все задачи
  my_tasks: mdiBriefcaseAccountOutline, // Текущие задачи
  control_tasks: mdiBriefcaseSearchOutline, // Задачи на контроле
  pending_tasks: mdiBriefcaseClockOutline, // Задачи в ожидании
  avar_light: mdiAlarmLight,
  task_switch: mdiDipSwitch, // Переключение программ
  repeat_point: mdiCircleMedium,
  expired_task: mdiFlag,
  priority: mdiViewParallel,
  // Иконки КЦ
  absent: mdiClock,
  donotdisturb: mdiMinusCircle,
  scan: mdiFaceRecognition, // кнопка сканирования документов
  account_arrow_right: mdiAccountArrowRight,
  // Карты
  map_marker: mdiMapMarkerOutline,

  search_prev: mdiChevronUp, // Подменю списка отчетов
  search_next: mdiChevronDown, // Список отчетов
  drop_down: mdiChevronDown, // dropDown в Меню
  walk: mdiWalk,
  car: mdiCar,

  // Стандартные MDI
  complete: 'M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z',
  cancel: 'M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z',
  // close: 'M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z',
  // delete: 'M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z',
  clear: 'M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z',
  success: 'M12,2C17.52,2 22,6.48 22,12C22,17.52 17.52,22 12,22C6.48,22 2,17.52 2,12C2,6.48 6.48,2 12,2M11,16.5L18,9.5L16.59,8.09L11,13.67L7.91,10.59L6.5,12L11,16.5Z',
  // info: 'M13,9H11V7H13M13,17H11V11H13M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2Z',
  info: mdiInformationOutline,
  warning: 'M11,4.5H13V15.5H11V4.5M13,17.5V19.5H11V17.5H13Z',
  // error: 'M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z',
  prev: 'M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z',
  next: 'M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z',
  checkboxOn: 'M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3Z',
  checkboxOff: 'M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z',
  checkboxIndeterminate: 'M17,13H7V11H17M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3Z',
  delimiter: 'M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2Z',
  sort: 'M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z',
  expand: 'M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z',
  // menu: 'M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z',
  subgroup: 'M7,10L12,15L17,10H7Z',
  dropdown: 'M7,10L12,15L17,10H7Z',
  radioOn: 'M12,20C7.58,20 4,16.42 4,12C4,7.58 7.58,4 12,4C16.42,4 20,7.58 20,12C20,16.42 16.42,20 12,20M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2M12,7C9.24,7 7,9.24 7,12C7,14.76 9.24,17 12,17C14.76,17 17,14.76 17,12C17,9.24 14.76,7 12,7Z',
  radioOff: 'M12,20C7.58,20 4,16.42 4,12C4,7.58 7.58,4 12,4C16.42,4 20,7.58 20,12C20,16.42 16.42,20 12,20M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2Z',
  // edit: 'M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z',
  ratingEmpty: 'M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z',
  ratingFull: 'M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z',
  ratingHalf: 'M12,15.4V6.1L13.71,10.13L18.09,10.5L14.77,13.39L15.76,17.67M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z',
  // loading: 'M19,8L15,12H18C18,15.31 15.31,18 12,18C11,18 10.03,17.75 9.2,17.3L7.74,18.76C8.97,19.54 10.43,20 12,20C16.42,20 20,16.42 20,12H23M6,12C6,8.69 8.69,6 12,6C13,6 13.97,6.25 14.8,6.7L16.26,5.24C15.03,4.46 13.57,4 12,4C7.58,4 4,7.58 4,12H1L5,16L9,12',
  first: 'M18.41,16.59L13.82,12L18.41,7.41L17,6L11,12L17,18L18.41,16.59M6,6H8V18H6V6Z',
  last: 'M5.59,7.41L10.18,12L5.59,16.59L7,18L13,12L7,6L5.59,7.41M16,6H18V18H16V6Z',
  unfold: 'M12,18.17L8.83,15L7.42,16.41L12,21L16.59,16.41L15.17,15M12,5.83L15.17,9L16.58,7.59L12,3L7.41,7.59L8.83,9L12,5.83Z',
  // file: 'M16.5,6V17.5C16.5,19.71 14.71,21.5 12.5,21.5C10.29,21.5 8.5,19.71 8.5,17.5V5C8.5,3.62 9.62,2.5 11,2.5C12.38,2.5 13.5,3.62 13.5,5V15.5C13.5,16.05 13.05,16.5 12.5,16.5C11.95,16.5 11.5,16.05 11.5,15.5V6H10V15.5C10,16.88 11.12,18 12.5,18C13.88,18 15,16.88 15,15.5V5C15,2.79 13.21,1 11,1C8.79,1 7,2.79 7,5V17.5C7,20.54 9.46,23 12.5,23C15.54,23 18,20.54 18,17.5V6H16.5Z',
  plus: 'M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z',
  minus: 'M19,13H5V11H19V13Z',
  // Иконки для микрогенерации
  mg_zone: 'M3.28125 7.04688H5.23633L7.45801 13.9717L9.67969 7.04688H11.6348L8.25098 17H6.66504L3.28125 7.04688ZM2.0918 7.04688H4.11523L4.49121 14.6758V17H2.0918V7.04688ZM10.8008 7.04688H12.8311V17H10.4248V14.6758L10.8008 7.04688Z M22.3252 7.04688V8.89941H17.998V17H15.6055V7.04688H22.3252Z',
  mg_zone_night: 'M9.66667 1C8.332 1 7.078 1.35 6 1.945C8.2 3.156 9.66667 5.41 9.66667 8C9.66667 10.59 8.2 12.844 6 14.055C7.078 14.65 8.332 15 9.66667 15C11.6116 15 13.4768 14.2625 14.8521 12.9497C16.2274 11.637 17 9.85652 17 8C17 7.08075 16.8103 6.17049 16.4418 5.32122C16.0732 4.47194 15.5331 3.70026 14.8521 3.05025C14.1712 2.40024 13.3627 1.88463 12.473 1.53284C11.5833 1.18106 10.6297 1 9.66667 1V1Z M7.25 17.3125H8.36719L9.63672 21.2695L10.9062 17.3125H12.0234L10.0898 23H9.18359L7.25 17.3125ZM6.57031 17.3125H7.72656L7.94141 21.6719V23H6.57031V17.3125ZM11.5469 17.3125H12.707V23H11.332V21.6719L11.5469 17.3125Z M17.668 17.3125V18.3711H15.1953V23H13.8281V17.3125H17.668Z',
  mg_zone_day: 'M6.62273 12.5005L7.52 13.4018L8.66545 12.2575L7.76182 11.3498L6.62273 12.5005ZM11.3636 15C11.5673 15 12.6364 15 12.6364 15V13.1142H11.3636V15ZM12 4.16438C10.9874 4.16438 10.0162 4.56849 9.30014 5.28781C8.58409 6.00713 8.18182 6.98273 8.18182 8C8.18182 9.01727 8.58409 9.99287 9.30014 10.7122C10.0162 11.4315 10.9874 11.8356 12 11.8356C13.0126 11.8356 13.9838 11.4315 14.6999 10.7122C15.4159 9.99287 15.8182 9.01727 15.8182 8C15.8182 5.87763 14.1064 4.16438 12 4.16438ZM17.0909 8.63927H19V7.36073H17.0909V8.63927ZM15.3345 12.2575L16.48 13.4018L17.3773 12.5005L16.2382 11.3498L15.3345 12.2575ZM17.3773 3.49954L16.48 2.59817L15.3345 3.74247L16.2382 4.65023L17.3773 3.49954ZM12.6364 1H11.3636V2.88584H12.6364V1ZM6.90909 7.36073H5V8.63927H6.90909V7.36073ZM8.66545 3.74247L7.52 2.59817L6.62273 3.49954L7.76182 4.65023L8.66545 3.74247Z M7.25 17.3125H8.36719L9.63672 21.2695L10.9062 17.3125H12.0234L10.0898 23H9.18359L7.25 17.3125ZM6.57031 17.3125H7.72656L7.94141 21.6719V23H6.57031V17.3125ZM11.5469 17.3125H12.707V23H11.332V21.6719L11.5469 17.3125Z M17.668 17.3125V18.3711H15.1953V23H13.8281V17.3125H17.668Z',
  mg_zone_pik: 'M9.66667 1V2.86667H15.8173L5 13.684L6.316 15L17.1333 4.18267V10.3333H19V1 M7.25 17.3125H8.36719L9.63672 21.2695L10.9062 17.3125H12.0234L10.0898 23H9.18359L7.25 17.3125ZM6.57031 17.3125H7.72656L7.94141 21.6719V23H6.57031V17.3125ZM11.5469 17.3125H12.707V23H11.332V21.6719L11.5469 17.3125Z M17.668 17.3125V18.3711H15.1953V23H13.8281V17.3125H17.668Z',
  mg_zone_ppik: 'M19.59 3L12 10.2911L6.41 4.92123H11V3H3V10.6849H5V6.2757L12 13L21 4.35447 M7.25 17.3125H8.36719L9.63672 21.2695L10.9062 17.3125H12.0234L10.0898 23H9.18359L7.25 17.3125ZM6.57031 17.3125H7.72656L7.94141 21.6719V23H6.57031V17.3125ZM11.5469 17.3125H12.707V23H11.332V21.6719L11.5469 17.3125Z M17.668 17.3125V18.3711H15.1953V23H13.8281V17.3125H17.668Z',
} as IconPack;
