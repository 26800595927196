const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.Платежи',
  recordRoute: 'accounts-accountsid-payments-paymentsid',
  description: 'Платежи',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчмесяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'платеж-список>номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'платеж-список>источник-платежи>название',
      text: 'Источник',
      type: 'String',
    },
    {
      field: 'сумма',
      text: 'Сумма,₽',
      type: 'Money',
    },
    {
      field: 'платеж-список>примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'суммапени',
      text: 'В т.ч. пени,₽',
      type: 'Money',
    },
    {
      field: 'назначение платежа',
      text: 'Назначение',
      type: 'String',
    },
  ],
};
export default accs;
