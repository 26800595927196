
import Vue from 'vue';
import addressFilter from './addressFilter.vue';
import datePeriodFilter from './datePeriodFilter.vue';
import monthPeriodFilter from './monthPeriodFilter.vue';
import serviceFilter from './serviceFilter.vue';

export default Vue.extend({
  components: { addressFilter, datePeriodFilter, monthPeriodFilter, serviceFilter },
  data() {
    return {
      item: {
        нп: null,
        улица: null,
        дом: null,
        квартира: null,
        фионанимателя: null,
        номер: null,
        номерлс: null,
        тип: null,
        датнач: this.$stackDate.firstDay(this.$store.getters.getCloseMonth()),
        даткнц: null,
        месяцс: null,
        месяцпо: null,
        услуга: null,
      } as any,
    };
  },
});
