import { TableHandlers } from '@/stackEngine';

const grcount: StackSelection = {
  recordRoute: 'metersdata-groupcounters-groupcountersid',
  objectName: 'ГрупповыеСчетчики',
  description: 'Групповые приборы учёта',
  hier: true,
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.цвет && row.цвет === 10) {
      TableHandlers.trColor(row, 'grey');
    }
    return row;
  },
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
      width: '10%',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'окончания срок',
      text: 'Дата последнего показания',
      type: 'Date',
    },
    {
      field: 'правила-документы',
      text: 'Правила-Документы',
      type: 'Link',
    },
    {
      field: 'полекартинки',
      text: '',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'группа нумерации',
      text: 'Группа нумерации',
      type: 'Number',
    },
    {
      field: 'видсчета',
      text: 'Вид счетчика',
      type: 'Number',
    },
    {
      field: '@видсчета',
      text: 'Вид счетчика',
      type: 'String',
      computed: true,
    },
    {
      field: 'цвет',
      text: 'Цвет',
      type: 'Number',
    },
    {
      field: '@типпоказания',
      text: 'Тип',
      type: 'String',
      computed: true,
    },
  ],
};

export default grcount;
