// по алфавиту
export default {
  enums: [
    'ВидыДетализацияДолга',
    'Должности.Признак',
    'Контакты.Тип',
    'Контакты.Флаги',
    'Объекты.Бланки.Вид',
    'Объекты.Бланки.Состояние',
    'Объекты.Пломбы.Вид',
    'Объекты.Пломбы.Состояния',
    'Объекты.Пломбы.МестоУстановки',
    'Организации.НДС',
    'Организации.Представители.Тип',
    'Организации.РасчетныеПризнаки',
    'Параметры объектов.Типы',
    'Показания.ТипыВвода',
    'Показания.ТипыВводаПолные',
    'СторонниеУслуги.Тип',
    'Счетчики.МестаУстановки',
    'Счетчики.МестаУстановки.ВидРесурса',
    'Счетчики.ПричиныСнятия',
    'Счетчики.ТипыВвода',
    'ТипыДокументов.Вид',
    'ТипыДокументов.Раздел',
    'Услуги.Каталог.Признаки',
    'КарточкиРегистрации.ВидыДокументов.ПричиныЗамены',
    'СпособСписанияЗначение',
    'Улицы.ДоПосле',
    'Улицы.Статус',
    'Улицы.Тип',
    'КонстантыНастроек.ФункцииТихогоРежима',
    'КонстантыНастроек.ПолучитьТипДоговораЮЛ',
    'КонстантыНастроек.ТипКотельной',
    'КонстантыНастроек.СистемаТрубопровода',
    'КонстантыНастроек.ВводВЭксплуатацию',
    'КонстантыНастроек.ТипПрокладки',
    'КонстантыНастроек.УслугаВыбор',
    'КонстантыНастроек.ТаблицаКонстанты_НОРМЫПОТОКА',
    'Организации.Аккаунты.Статус',
    'Организации.Аккаунты.Привязка.Статус',
    'ТипыНоменклатур',
    'Организации.СтавкаНДС',
    'Организации.ТипПользователяВеб',
    'Счетчики.Вариант',
    'API_СетевыеПутиКВнешнимФайлам',
  ],
};
