/**
 * Стандартные обработчики каллбэки для stack-table
 */
export class TableHandlers {
  // ячейку цветом
  public static tdColor(item: StackTableRow, name: string, color: string, addColor: string | null = null): any {
    if (!item.$data) {
      item.$data = {};
    }
    if (!item.$data[name]) {
      item.$data[name] = {} as any;
    }
    if (!item.$data[name].$класс) {
      item.$data[name].$класс = '';
    }
    item.$data[name].$класс += `${color}--text ` + (addColor ? `text--${addColor} ` : '');
    return this;
  }

  // строку цветом
  public static trColor(item: StackTableRow, color: string, addColor: string | null = null): any {
    if (!item.$класс) {
      item.$класс = '';
    }
    item.$класс += `${color}--text ` + (addColor ? `text--${addColor} ` : '');
    return this;
  }

  // запись не действует (выделяет всю строку серым цветом) TODO
  public static isClosed(item: StackTableRow, date = `2045-05-09T00:00:00`): any {
    if (!item.$класс) {
      item.$класс = '';
    }
    if (item.датнач && Date.parse(item.даткнц as string) < Date.parse(date)) {
      item.$класс += 'grey--text ';
    }
    return this;
  }

  // добавляем иконку в ячейку
  public static tdIcon(item: StackTableRow, name: string, icon: string, color = 'primary'): any {
    if (!item.$data) {
      item.$data = {};
    }
    if (!item.$data[name]) {
      // @ts-ignore
      item.$data[name] = {};
    }
    item.$data[name].$иконка = icon;
    item.$data[name].$иконка_цвет = color;
    return this;
  }

  // применяем цвет и шрифт по формату стека
  public static stackStyle(item: StackTableRow, strStyle: string): any {
    const style = {} as any;
    const strColor = strStyle.indexOf('Шрифт') > 0 ? strStyle.substring(0, strStyle.indexOf('Шрифт') - 1) : strStyle;
    const strFont = strStyle.indexOf('Шрифт') > 0 ? strStyle.substring(strStyle.indexOf('Шрифт') + 5) : '';

    // парсим цвет
    const colors = strColor.split(';');
    if (colors) {
      while (colors.length !== 0) {
        // @ts-ignore
        const color = colors.pop().split(',');
        if (color[0] !== '') {
          if (color.length === 1) {
            // общий цвет заданый для всехй строки
            style['*'] = {
              color: `rgb(${color[0]
                .split('*')
                .join('')
                .split('.')
                .join(',')})`,
            };
          } else {
            // цвет конкретного поля
            style[color[0].toLowerCase()] = {
              color: `rgb(${color[1]
                .split('*')
                .join('')
                .split('.')
                .join(',')})`,
            };
          }
        }
      }
    }

    // парсим шрифт
    const fieldsFont = strFont.split(';');
    if (fieldsFont) {
      while (fieldsFont.length !== 0) {
        // @ts-ignore
        const field = fieldsFont.pop();
        let key = '*';
        if (field && field.indexOf('*') < 0) {
          // указан шрифт для конкретного поля
          key = field.split(',')[0].toLowerCase();
        }

        if (!style[key]) {
          style[key] = {};
        }

        if (field && field.indexOf(',К') > 0) {
          style[key]['font-style'] = 'italic';
        }
        if (field && field.indexOf(',Ж') > 0) {
          style[key]['font-weight'] = 'bold';
        }
      }
    }
    // применяем стиль к полям записи
    if (item.$data) {
      for (const field in item.$data) {
        if (style[field]) {
          item.$data[field].$стиль = style[field];
        }
      }
    }
    // применяем стиль к ко всей строке записи
    if (style['*']) {
      item.$стиль = style['*'];
    }
    return this;
  }
}
