import { RouteConfig, VueRouter, Route } from 'vue-router/types/router';

function cleanChildrenRoutes(routes: RouteConfig[], isChild = false) {
  let start = -1;
  const routesIndex = [] as any;
  routes.forEach((route: RouteConfig) => {
    if (route.name && (/-index$/.test(route.name) || route.name === 'index')) {
      // Save indexOf 'index' key in name
      const res = route.name.split('-');
      const s = res.indexOf('index');
      start = start === -1 || s < start ? s : start;
      routesIndex.push(res);
    }
  });
  routes.forEach((route: RouteConfig) => {
    route.path = isChild ? route.path.replace('/', '') : route.path;
    if (route.name && route.path.indexOf('?') > -1) {
      const names = route.name.split('-');
      const paths = route.path.split('/');
      if (!isChild) {
        paths.shift();
      } // clean first / for parents
      routesIndex.forEach((r: any) => {
        const i = r.indexOf('index') - start; //  children names
        if (i < paths.length) {
          for (let a = 0; a <= i; a++) {
            if (a === i) {
              paths[a] = paths[a].replace('?', '');
            }
            if (a < i && names[a] !== r[a]) {
              break;
            }
          }
        }
      });
      route.path = (isChild ? '' : '/') + paths.join('/');
    }
    if (route.name) {
      route.name = route.name.replace(/-index$/, '');
    }
    if (route.children) {
      if (route.children.find((child: any) => child.path === '')) {
        delete route.name;
      }
      route.children = cleanChildrenRoutes(route.children, true);
    }
  });
  return routes;
}
// Передача просов через url
// TODO привести по типам, сейчас всё летит строками
function getRouteProps(route: Route) {
  const query = route.query;
  const urlParams: any = {};
  // Если в query встречается параметр, который начинается на prop_, то прокидываем его, как props компонента
  for (const queryItem in query) {
    if (queryItem.startsWith('prop_')) {
      const fieldName = queryItem.substr(5);
      urlParams[fieldName] = query[queryItem] as string;
    }
  }
  return { ...route.params, ...urlParams };
}

function createRoutes(files: __WebpackModuleApi.RequireContext): RouteConfig[] {
  const filenames = files.keys();
  const routes = [] as any;
  filenames.forEach((file: any) => {
    const keys = file
      .replace(/\.(vue|js)$/, '')
      .replace(/\/{2,}/g, '/')
      .split('/')
      .slice(1);
    const route = { name: '', path: '', component: files(file).default, props: getRouteProps } as any;
    let parent = routes;
    let ignoreFlag = false;
    keys.forEach((key: string, i: any) => {
      // ингорируем папки, начинающиеся с $
      if (key.startsWith('$')) {
        ignoreFlag = true;
        return;
      }

      // remove underscore only, if its the prefix
      const sanatizedKey = key.indexOf('_') === 0 ? key.replace('_', '') : key;
      route.name = route.name ? route.name + '-' + sanatizedKey : sanatizedKey;
      route.name += key === '_' ? 'all' : '';
      //      const child = _.find(parent, { name: route.name }) as any;
      const child = parent.find((name: string) => {
        return name === route.name;
      }) as any;
      if (child) {
        child.children = child.children || [];
        parent = child.children;
        route.path = '';
      } else {
        if (key === 'index' && i + 1 === keys.length) {
          route.path += i > 0 ? '' : '/';
        } else {
          route.path += '/' + (key === '_' ? '*' : key.indexOf('_') === 0 ? key.replace('_', ':') : key);
          if (key !== '_' && key.indexOf('_') === 0) {
            route.path += `(\\d+|new)`;
          }
        }
      }
    });
    // Order Routes path
    if (!ignoreFlag) {
      parent.push(route);
      parent.sort((a: any, b: any) => {
        if (!a.path.length) {
          return -1;
        }
        if (!b.path.length) {
          return 1;
        }
        // Order: /static, /index, /:dynamic
        // Match exact route before index: /login before /index/_slug
        if (a.path === '/') {
          return /^\/(:|\*)/.test(b.path) ? -1 : 1;
        }
        if (b.path === '/') {
          return /^\/(:|\*)/.test(a.path) ? 1 : -1;
        }
        let i = 0;
        let res = 0;
        let y = 0;
        let z = 0;
        const a1 = a.path.split('/');
        const b1 = b.path.split('/');
        for (i = 0; i < a1.length; i++) {
          if (res !== 0) {
            break;
          }
          y = a1[i] === '*' ? 2 : a1[i].indexOf(':') > -1 ? 1 : 0;
          z = b1[i] === '*' ? 2 : b1[i].indexOf(':') > -1 ? 1 : 0;
          res = y - z;
          // If a.length >= b.length
          if (i === b1.length - 1 && res === 0) {
            // change order if * found
            res = a1[i] === '*' ? -1 : 1;
          }
        }
        return res === 0 ? (a1[i - 1] === '*' && b1[i] ? 1 : -1) : res;
      });
    }
  });
  return cleanChildrenRoutes(routes);
}

// функция генерит роутинг по файлам задачи
export function buildTaskRoutes(router: VueRouter, views: __WebpackModuleApi.RequireContext[]) {
  const routesNames = new Map<string, RouteConfig>();
  // let total = 0;
  // let skipped = 0;
  views.forEach(files => {
    const autoRoutes = createRoutes(files);
    autoRoutes.forEach((route: RouteConfig) => {
      const name = route.name;
      if (name && !routesNames.has(name)) {
        routesNames.set(name, route);
        // total++;
      } else {
        // skipped++;
      }
      // store.commit('ADD_AUTO_ROUTE', { name: route.name, path: route.path });
    });
  });
  // общие роуты
  routesNames.set('about', { path: '/about', name: 'about', component: () => import('../components/StackApp/About/About.vue') });
  routesNames.set('plugin', { path: '/plugin', name: 'plugin', component: () => import('../components/StackApp/Plugin.vue') });
  routesNames.set('whatsnew', { path: '/whatsnew', name: 'whatsnew', component: () => import('../components/StackApp/WhatsNew/WhatsNew.vue') });
  routesNames.set('taskswitch', { path: '/taskswitch', name: 'taskswitch', component: () => import('../components/StackApp/TaskSwitchDialog.vue') });
  routesNames.set('redirect', { path: '/redirect', name: 'redirect', component: () => import('../components/StackApp/Redirect.vue') });
  routesNames.set('404', { path: '*', name: '404', component: () => import('../components/StackApp/404.vue') });
  // deprecate https://github.com/vuejs/vue-router/blob/dev/CHANGELOG.md#350-2021-01-25
  // router.addRoute(Array.from(routesNames.values()));
  routesNames.forEach((route) => router.addRoute(route));
  // console.log(`Загружено роутов: ${total}, переопределено: ${skipped}`);
  routesNames.clear();
}
