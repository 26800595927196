import { StaticModel, HttpModel } from '@/stackEngine';

export class FundModel extends StaticModel {
  public async initRecord(params?: object): Promise<StackTableRow> {
    const newRec = {} as StackTableRow;
    const data = await new HttpModel('БыстрыйВводФонда').executeMethod('ПолучитьЛС', params);
    newRec.$номерЗаписи = 'new';
    for (const field in data) {
      newRec[field] = data[field];
    }
    return newRec;
  }
}
