
import Vue from 'vue';
import IncomingDialog from './Incoming.vue';
import OutgoingDialog from './Outgoing.vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  components: { IncomingDialog, OutgoingDialog },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      dataModel: new this.$HttpModel('ПочтовыйКлиент.Сообщения'),
      initParams: {},
      reply: false,
    };
  },
  computed: {
    isNewRecord(): boolean {
      return !(this.record && this.record.$номерЗаписи && +this.record.$номерЗаписи > 0);
    },
    outgoing(): boolean {
      return this.record.видпапки === 'Исходящее';
    },
  },
  methods: {
    async onSend() {
      const result = await this.dataModel.saveRecord(this.record, { папка: this.record.$папка });
      if (result) {
        await this.dataModel.executeMethod('Отправить', { папка: this.record.$папка, емейл: this.record.$номерЗаписи }, { async: true, jobName: 'Отправляем письмо' });
        this.onClose();
      }
    },
    async onNewEmail(payload: any) {
      this.reply = true;
      this.initParams = Object.assign({}, this.initParams, { action: payload, email: this.record.$номерЗаписи });
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs.email.initRecord();
      });
    },
    async onBeforeClose() {
      await this.dataModel.executeMethod('Прочитано', { емейл: this.record.$номерЗаписи });
      this.$emit('readed');
      return true;
    },
    async onClose() {
      // снимаем блокировку перед закрытием диалога, иначе удаляется запись заголовка сообщения
      this.record.$блок = false;
      await this.dataModel.releaseLock(this.record, { сУдалением: false });
      // @ts-ignore
      await this.$refs.email.onClose();
    },
  },
});
