
import Vue from 'vue';
export default Vue.extend({
  model: { prop: 'item', event: 'change' },
  props: {
    base64: { type: Boolean, default: true },
  },
  mounted() {
    if (this.imageReader) this.imageReader.onload = this.onBase64;
  },
  computed: {
    apiHostUpload(): string {
      return this.$store.getters.getApiHostUpload();
    },
    apiHostShare(): string {
      return this.$store.getters.getApiHostShare();
    },
    isLoaded(): boolean {
      return !!this.item.src && this.loading;
    },
    checkFileUpload(): true | string {
      return (this.uploadSuccess && this.lastError === '') || this.lastError;
    },
  },
  data() {
    return {
      loading: false,
      previewImage: new Image(),
      imageReader: this.base64 ? new FileReader() : null,
      uploadSuccess: true,
      imgLoadError: false,
      imgLoadOk: false,
      imgFull: false,
      lastError: '',
      item: { src: '', alt: '', title: '' },
    };
  },
  methods: {
    onUpload(files: []) {
      if (files.length > 0) {
        const ob_file = files.pop() as any;
        if (ob_file) {
          if (!this.base64) this.item.src = `${this.apiHostUpload}/${ob_file.name}`;
          else {
            if (ob_file.file && this.imageReader) {
              this.imageReader.readAsDataURL(ob_file.file);
            }
          }
        }
      }
    },
    onBase64() {
      if (this.imageReader) this.item.src = this.imageReader.result as string;
    },
    onLoadImage(payload: string) {
      this.imgLoadOk = true;
      this.previewImage.src = payload;
    },
  },
});
