
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'Состояние' },
    usl: { type: Number, default: null },
    counter: { type: Number, default: null },
    typeCounter: { type: Number, default: 2 },
  },
  data() {
    return {
      isLoad: false,
    };
  },
  methods: {
    async onBeforeCreate() {
      if (this.record.состояние === 4 && this.typeCounter !== 0) {
        this.isLoad = true;
        const res = await new this.$HttpModel('Объекты.Состояния').executeMethod('ПроверитьНаличиеПоказаний', {
          счетчик: this.counter,
          услуга: this.usl,
          датнач: this.record.датнач,
        });
        this.isLoad = false;
        if (res === 1) {
          this.$stackMsg([
            {
              текст: `На счетчике есть показания с большей датой. Удалите их и повторите ввод состояния`,
              варианты: ['ОК'],
            },
          ]);
          return false;
        }
      }
      return true;
    },
  },
});
