
import Vue from 'vue';
import prepare from './$components/prepare.vue';
import prepareSBP from './$components/prepareSBP.vue';
import print from './$components/print.vue';
import kvitEmail from './$components/kvitEmail.vue';

type HookReleaseFunction = () => void;

export default Vue.extend({
  components: { prepare, print, kvitEmail, prepareSBP },
  name: 'Bulkprint',
  data() {
    return {
      disabledPrepare: false,
      disabledPrint: false,
      disabledEmail: false,
      disabledPrepareSBP: false,
      statusPrepareSBP: '',
      statusPrepare: '',
      statusPrint: '',
      statusEmail: '',
      runOperation: false,
      routeHook: null as null | HookReleaseFunction, // хук на смену роута
      accounts: null as null | number | string,
      rights: 0xFFFF,
    };
  },
  computed: {
    hasPrepareRights(): boolean {
      return (this.rights & 1) !== 0;
    },
    hasMailingRights(): boolean {
      return (this.rights & 2) !== 0;
    },
    hasPrintRights(): boolean {
      return (this.rights & 4) !== 0;
    },
    hasSBPRights(): boolean {
      return (this.rights & 8) !== 0;
    },
  },
  async created() {
    const data = await new this.$HttpModel('МассоваяПечатьКвитанций').getRecords();
    this.rights = (data && data[0] && data[0].права ? data[0].права : 0xFFFF) as number;
    this.routeHook = this.$router.beforeEach(this.checkChangedOnLeave) as HookReleaseFunction;
  },
  beforeDestroy() {
    if (this.routeHook) {
      this.routeHook();
    }
  },
  methods: {
    async checkChangedOnLeave(to: any, from: any, next: any) {
      if (this.runOperation && from.path !== to.path) {
        this.$toast('Выполняется операция, выход невозможен!');
        next(false);
        return;
      }
      next();
    },
    runPrepareSBP() {
      this.statusPrepareSBP = '';
      this.disabledPrint = true;
      this.disabledEmail = true;
      this.disabledPrepare = true;
      this.disabledPrepareSBP = true;
    },
    runPrepare() {
      this.statusPrepare = '';
      this.disabledPrint = true;
      this.disabledEmail = true;
    },
    runPrint() {
      this.statusPrint = '';
      this.disabledPrepare = true;
    },
    runEmail() {
      this.statusEmail = '';
      this.disabledPrepare = true;
      this.disabledPrint = true;
      this.runOperation = true;
    },
    endPrepareSBP(to: any) {
      this.statusPrepareSBP = to.status;
      this.disabledPrint = false;
      this.disabledEmail = false;
      this.disabledPrepare = false;
      this.disabledPrepareSBP = false;
      if (!this.accounts) {
        this.accounts = to.accounts;
      }
    },
    endPrepare(to: any) {
      this.statusPrepare = to.status;
      if (to.status === 'success') {
        this.disabledPrint = false;
        this.disabledEmail = false;
        this.accounts = to.accounts;
      }
    },
    endPrint(to: string) {
      this.statusPrint = to;
      this.disabledPrepare = false;
    },
    endEmail(to: string) {
      this.statusEmail = to;
      this.disabledPrepare = false;
      this.disabledPrint = false;
      this.runOperation = false;
    },
  },
});
