const regpa: StackSelection = {
  recordRoute: 'agentsregistry',
  objectName: 'РеестрПлатежногоАгента',
  description: 'Реестр Платежного Агента',
  hier: true,
  fields: [
    {
      field: 'номер',
      text: 'Идентификатор',
      type: 'Number',
    },
    {
      field: 'дата_формат',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'исполнитель',
      text: 'Исполнитель',
      type: 'String',
    },
    {
      field: 'ordernum',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'amount',
      text: 'Сумма',
      type: 'String',
    },
    {
      field: 'accountnumber',
      text: 'Номер ЛС',
      type: 'String',
    },
  ],
};

export default regpa;
