
import Vue from 'vue';

export default Vue.extend({
  name: 'InstDateForm',
  model: {
    prop: 'record',
    event: 'input',
  },
  props: {
    record: { type: Object, required: true },
    /**
     * межповерочный интервал
     */
    interval: { type: Number, default: 0 },
  },
  computed: {
    isNewRecord(): boolean {
      return !(this.record.$номерЗаписи > 0) || this.record.$блок;
    },
  },
  methods: {
    onChangePoverkaDate() {
      this.changeNextPoverkaDate('onChangePoverkaDate');
    },

    onChangeInstallDate() {
      this.changeNextPoverkaDate('onChangeInstallDate');
    },

    changeNextPoverkaDate(event: string) {
      let changeDate = false;
      const poverkaDate = (this.record.датаповерки || this.record.датнач || '') as string;
      const nextpoverkaDate = (this.record.датаследующейповерки || '') as string;
      const poverkaInterval = (this.interval || 0) as number;

      switch (event) {
        case 'onChangePoverkaDate':
          if (this.record.датаповерки === null) {
            this.record.датаповерки = poverkaDate;
          }
          changeDate = true;
          break;
        case 'onChangeInstallDate':
          if (this.isNewRecord) {
            this.record.датаповерки = this.record.датнач;
            this.onChangePoverkaDate();
          }
          break;
        case 'onSelectMeasureInit':
          if (this.isNewRecord && nextpoverkaDate === '') {
            changeDate = true;
          }
          break;
        case 'onSelectMeasureUpdate':
          // если выбрали прибор учета с нулевым интервалом, дата следующей поверки совпадает с текущей
          if (poverkaInterval === 0) {
            this.record.датаследующейповерки = poverkaDate;
          } else {
            changeDate = true;
          }
          break;
      }

      if ((changeDate || nextpoverkaDate === '') && poverkaDate !== '' && poverkaInterval) {
        this.record.датаследующейповерки = this.$stackDate.format(this.$stackDate.addYears(new Date(poverkaDate), poverkaInterval), 'ISO');
      }
    },
  },
  watch: {
    interval() {
      this.changeNextPoverkaDate('onSelectMeasureUpdate');
    },
  },
  mounted() {
    if (this.isNewRecord) {
      this.changeNextPoverkaDate('onSelectMeasureUpdate');
    }
  },
});
