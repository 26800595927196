
import Vue from 'vue';

export default Vue.extend({
  name: 'InlineTableConst',
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    type: { type: String, default: 'String' },
    headers: { type: String, default: '' },
    labelField: {
      type: [Function, String],
      default: (): any => {
        return '';
      },
    },
    keyField: { type: String, default: '' },
    dataModel: { type: String, default: '' },
    addBtnToTableDialog: { type: Boolean, default: false }, // заменить кнопку добавления на кнопку добавления диалога с таблицей выборки
    noOpenLink: { type: Boolean, default: false }, // не отображать иконку открытия в новом окне
    searchWithFolder: { type: Boolean, default: false }, // искать в link fiel с учетом папок
  },
  computed: {
    data(): string[] {
      return this.record.значение ? this.record.значение.split(this.separator) : [];
    },
    separator(): string {
      return this.record.разделитель ? this.record.разделитель : ';';
    },
  },
  data() {
    return {
      dataObject: {},
      header: [
        {
          field: 'значение',
          text: '',
          type: this.type,
          headers: this.headers,
          dataModel: this.dataModel,
          labelField: this.labelField,
          keyField: this.keyField,
        },
      ],
      showModalDialog: false,
    };
  },
  methods: {
    computeAndSetNewValue(items: StackTableRow[]) {
      const computedValue = items
        .map((item: StackTableRow) => {
          return item.значение;
        })
        .join(this.separator);
      this.$set(this.record, 'значение', computedValue);
    },
    onSelectAdd(items: StackTableRow[]) {
      const currentItems = this.record.значение ? this.record.значение.split(this.separator) : [];
      const selectedItems = items.map((item: StackTableRow) => {
        return item[this.keyField]?.toString();
      });
      const uniqueValue = new Set([...currentItems, ...selectedItems]);
      const computedValue = Array.from(uniqueValue).join(this.separator);
      this.$set(this.record, 'значение', computedValue);
      this.showModalDialog = false;
    },
  },
});
