
import Vue from 'vue';
import agreementCloseDialogFilter from './agreementCloseDialogFilter.vue';

export default Vue.extend({
  components: { agreementCloseDialogFilter },
  props: {
    selectedValues: { type: [String, Number], default: undefined },
  },
  data() {
    return {
      records: [] as StackTableRow[],
      loading: false,
    };
  },
  methods: {
    selectTable(items: StackTableRow[]) {
      this.records = [];
      for (const item of items) {
        let objTemp = {};
        for (const ind in item) {
          if (ind.indexOf('$') < 0) {
            objTemp = Object.assign({}, objTemp, { [ind]: item[ind] });
          }
        }
        this.records.push(objTemp);
      }
    },
  },
});
