
import Vue from 'vue';
import Fetch from '../mixins/fetchable';
import inputable from '../mixins/inputable';
import { stringTranslit } from '@/stackEngine';
/**
 * Комбобокс
 */
export default Vue.extend({
  name: 'StackCombobox',
  mixins: [Fetch, inputable],
  model: { prop: 'value', event: 'change' },
  props: {
    /**
     * Значение (ключ кобмобокса)
     * @model
     */
    value: { type: [Number, String, Array], default: undefined },
    /**
     * какое поле является ключем в записи. По-умолчанию это $номерЗаписи
     */
    itemValue: { type: String, default: '$номерЗаписи' },
    /**
     * функция коллбэк для кастомного вызуального вывода элементов комбобокса
     * по-умолчанию это поле значение
     */
    itemText: {
      type: [Function, String],
      default: (item: StackTableRow) => {
        return item && item.значение ? item.значение : '';
      },
    },
    /**
     * вторая строчка в комбобоксе (например для расшифровки)
     */
    descrField: { type: String, default: undefined },
    floatPlaceholder: { type: Boolean, default: false },
    /**
     * row_id владельца выборки
     */
    ownerID: { type: [Number, String], default: null },
    /**
     * объект с кастомными параметрами в запрос
     */
    params: { type: [Object], default: null },
    /**
     * в объект возвращаем итем который выбран в комбобоксе
     */
    itemData: { type: Object, default: null },
    /**
     * поле для поиска. если не указано, то не ищем
     */
    searchField: { type: String, default: undefined },
    whatever: { type: Boolean, default: false },
    /**
     * Отложенный фетч, только при первом фокусе на поле
     */
    lazy: { type: Boolean, default: false },
    maxItemsCount: { type: Number, default: undefined },
    multiple: { type: Boolean, default: false },
    /**
     * фильтр по частичному вводу строки
     */
    customFilter: {
      type: Function,
      default: (item: any, queryText: string, itemText: string) => {
        return queryText.split(' ').every((partStr) => partStr.length === 0 || itemText.toLowerCase().indexOf(partStr.toLowerCase()) >= 0);
      },
    },
    historyId: { type: String, default: undefined },
    capacity: { type: Number, default: 3 },
    dataTestId: { type: String, default: '' },
    // прокидываем пропсы в меню
    menuProps: { type: Object, default: undefined },
  },
  data() {
    return {
      items: [] as StackTableRow[] | null,
      loading: false,
      loaded: false,
      select: null as StackTableRow | null,
      search: null,
      timeoutID: undefined as number | undefined | any,
      delay: 500, // задержка debounceEmitunce
      // filter: '' as string,
      attach: false,
    };
  },
  computed: {
    cacheId(): string {
      return `stack_combobox_${this.historyId}`;
    },
    prevRecords(): StackTableRow[] {
      const prev = this.$store.getters.getSyncCache(this.cacheId) || [];
      return this.historyId ? prev : [];
    },
    menuTargetClass(): string {
      // @ts-ignore
      return stringTranslit(this.label || 'noname');
    },
    comboboxQueryParams(): StackHttpRequestTaskParam {
      let q: StackHttpRequestTaskParam = {};
      if (this.maxItemsCount) {
        q.размерСтраницы = this.maxItemsCount;
      }
      if (this.ownerID > 0) {
        q.владелец = Number(this.ownerID);
      }
      if (this.params) {
        q = Object.assign(q, this.params);
      }
      return q;
    },
    cValue(): number | string | null | any[] {
      if (!this.loading && this.value !== undefined) {
        return isNaN(+this.value) || typeof this.value === 'object' ? this.value : +this.value;
      }
      return null;
    },
    cItems(): StackTableRow[] | null | undefined {
      if (this.items && this.items.length && this.prevRecords && this.prevRecords.length) {
        const prevItems = [{ header: 'Последние выбранные записи:' }, ...this.prevRecords];
        if (this.items.length) {
          prevItems.push({ divider: true }, { header: 'Найдено:' });
        }
        return [...prevItems, ...this.items];
      }
      return this.items;
    },
  },
  // mounted() {
  //   // При скорлле менюха убегает вместе со скроллом
  //   this.attach = !this.$el.closest('.v-dialog');
  // },
  methods: {
    focus() {
      // @ts-ignore
      if (this.$refs.textField && this.$refs.textField.focus) {
        // @ts-ignore
        this.$refs.textField.focus();
      }
    },
    async onFocus() {
      if (!this.loaded) {
        await this.fetchData(true);
      }
    },
    async fetchItems(filter?: any) {
      const q = { ...this.comboboxQueryParams };
      if ((!this.value || this.value === -1) && filter) {
        if (!q.фильтр) {
          q.фильтр = {};
        }
        q.фильтр = { ...q.фильтр, ...filter };
      }

      // @ts-ignore
      const data = await this.dataObject.getRecords(q);
      for (const key in data) {
        this.$set(data[key], '$text', typeof this.itemText === 'function' ? this.itemText(data[key]) : data[key][this.itemText]);
      }
      this.items = data && data.length ? data : undefined;
      this.$emit('update:combobox-items', this.items);
    },
    // получение данных с бэкэнда
    async fetchData(lazy?: boolean) {
      if (!lazy && this.lazy) {
        return;
      }
      try {
        this.loading = true;
        await this.fetchItems();
        this.loaded = true;
        this.select = this.findItem();
        this.$emit('update:itemData', this.select);
      } catch (error: AnyException) {
        console.log('Stack-combobox fetchData error', error);
      } finally {
        this.loading = false;
      }
    },
    findItem() {
      if (this.items) {
        const item = this.items.filter((item) => item && item[this.itemValue] !== undefined && item[this.itemValue] === this.value);
        if (item && item[0]) {
          return item[0];
        }
      }
      return null;
    },

    onChange(payload: any | any[]) {
      if (this.historyId && payload) {
        let data: StackTableRow[] = [];
        if (Array.isArray(payload)) {
          data = [...payload, ...this.prevRecords];
        } else {
          data = [payload, ...this.prevRecords];
        }
        if (data.length > this.capacity) {
          data.length = this.capacity;
        }
        this.$store.commit('SAVE_CACHE_DATA', { id: this.cacheId, data });
      }
      if (this.multiple) {
        const returnValue = [] as any[];
        payload.forEach((element: StackTableRow | string | null) => {
          returnValue.push(typeof element === 'object' ? (element && element[this.itemValue] !== undefined ? element[this.itemValue] : null) : element);
        });
        this.$emit('change', returnValue);
      } else {
        this.$emit('change', typeof payload === 'object' ? (payload && payload[this.itemValue] !== undefined ? payload[this.itemValue] : null) : payload || null);
      }
      this.$emit('update:itemData', typeof payload === 'object' ? payload : payload ? { $text: payload } : undefined);
    },
  },
  watch: {
    search(to: string | null, from: string | null) {
      if ((this.value && this.value !== -1) || (this.itemData && this.itemData.$text && to === this.itemData.$text)) {
        return;
      }
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => {
        let filter;
        if (this.searchField) {
          if (to) {
            filter = { [this.searchField]: to };
          }
        }
        this.fetchItems(filter);
      }, this.delay);
    },
    comboboxQueryParams: {
      immediate: false,
      handler(to, from) {
        if (JSON.stringify(to) !== JSON.stringify(from)) {
          this.fetchData(true);
        }
      },
    },
  },
});
