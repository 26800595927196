
import Vue from 'vue';

import folder from './$modal/folder.vue';
import reestrDialog from './$modal/reestrDialog.vue';

export default Vue.extend({
  components: { folder, reestrDialog },
  data() {
    return {
      message: '',
      jobID: null,
      dataModel: new this.$HttpModel('ЗакрытиеМесяца.Реестр'),
    };
  },
  computed: {
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
  },
  mounted() {
    this.checkFund();
  },
  methods: {
    async checkFund() {
      this.jobID = (
        await this.dataModel.executeMethod('ПроверкаКорректности', {},
          { async: true, jobName: 'Проверка реестра...' },
        )
      ).asyncId;
    },
  },

  watch: {
    isComplete(to) {
      if (to) {
        this.message = this.resultJob && this.resultJob.data ? this.resultJob.data.result : '';
        this.$store.commit('MSG_DELETE', this.jobID);
      }
    },
  },
});
