
import Vue from 'vue';
import { getComponents, stringTranslit, ReportsBuilder } from '@/stackEngine';

const componentsDefs = require.context('.', false, /\.vue$/);
const constDlg = getComponents(componentsDefs);

export default Vue.extend({
  components: Object.assign(constDlg),
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      itemsList: [{} as StackTableRow],
      isDialog: false,
      itemsListData: [{} as StackTableRow],
      comboValue: 0,
    };
  },
  methods: {
    getDialogName(row: StackTableRow): string {
      if (row && row.имя) {
        const name = stringTranslit(row.имя as string).toUpperCase();
        if (this.$options.components && name in this.$options.components) {
          return name as string;
        }
        return 'DEFAULT';
      }
      return '';
    },
    getList(item: StackTableRow) {
      this.itemsList = [{} as StackTableRow];
      this.isDialog = false;
      const objItems = (this.record.значение ?? '') === '' ? [] : JSON.parse(this.record.значение);
      for (const objItem of objItems) {
        if (objItem.гп === item.ключ) {
          this.itemsList = [].concat(objItem.настр ?? []).map((item: StackTableRow) => {
            item.$номерЗаписи = this.record.$номерЗаписи;
            item.model = 'КонстантыНастроек';
            return item;
          });
        }
      }
      this.isDialog = true;
    },
    print() {
      new ReportsBuilder('').executeReport('ПР-14 Расшифровка тарифов');
    },
  },
});
