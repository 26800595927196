
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        'Параметры.ДатНач': this.$stackDate.addYears(this.$store.getters.getWorkMonth(), -1),
        'Параметры.ДатКнц': this.$store.getters.getWorkMonth(),
        'Параметры.@Услуга': -1,
        'Параметры.@Категория': -1,
        'Параметры.@Поставщик': -1,
        'Параметры.@ПостКатегория': -1,
        'Параметры.@УК': -1,
      },
    };
  },
});
