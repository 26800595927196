
import Vue from 'vue';
import ReadingDialog from '../$dialogs/ReadingDialog.vue';
import tableHeaders from '@/tasks/fl/selections/accountsplit/ДелениеЛицевого.Разделение счетчиков';

export default Vue.extend({
  components: { ReadingDialog },
  props: {
    record: { type: Object, required: true },
    disabled: { type: Boolean, required: true },
    runCreation: { type: Boolean, required: true },
  },
  computed: {
    paramGeneral(): object {
      return { лицевой: this.record.лицевой, дата: this.record.дата };
    },
  },
  data() {
    return {
      isSave: false,
      isDialog: false,
      group: false,
      recordDialog: [] as StackTableRow[],
      readings: [] as StackTableRow[],
      readingsModel: null as DataModel | null,
      headers: tableHeaders.fields as StackField[],
    };
  },
  methods: {
    async fetchData() {
      this.readings = await new this.$HttpModel('ДелениеЛицевого.Разделение счетчиков').getRecords(this.paramGeneral);
      this.createModel();
    },
    createModel() {
      this.readingsModel = new this.$StaticModel(this.readings, { rowTemplate: this.headers });
    },
    apply() {
      this.isSave = true;
      this.$emit('save', { выбСчетчиков: this.readings });
    },
    runOperation(type: number, selectedItems: StackTableRow[]) {
      if (selectedItems.length === 0) {
        this.$toast('Необходимо отметить запись');
        return;
      }
      this.recordDialog = [];
      for (const ind in selectedItems) {
        if (type === 1) {
          this.readingsModel = null;
          for (const indD in this.readings) {
            if (this.readings[indD].$номерЗаписи === selectedItems[ind].$номерЗаписи) {
              this.readings[indD]['@состояние'] = 'Старый л/с';
              this.readings[indD]['@показание'] = 0;
              this.readings[indD]['@показание2'] = 0;
              this.readings[indD]['@показание3'] = 0;
              this.readings[indD].нзправилоопераций = -1;
            }
          }
        } else if (type === 2) {
          this.recordDialog.push(selectedItems[ind]);
          this.group = false;
        } else if (type === 3) {
          this.recordDialog.push(selectedItems[ind]);
          this.group = true;
        }
      }
      if (type === 1) {
        this.$nextTick(() => {
          this.createModel();
        });
      } else {
        this.isDialog = true;
      }
    },
    saveDialog(itemObject: any) {
      if (itemObject.записи) {
        this.readingsModel = null;
        for (const index in this.recordDialog) {
          const reading = this.readings.find(item => this.recordDialog[index].$номерЗаписи === item.$номерЗаписи);
          if (!reading) {
            break;
          }
          const items = itemObject.записи[index] as StackTableRow[];
          if (itemObject.правило && itemObject.правило[index] && +itemObject.правило[index] > 0) {
            reading['@состояние'] = 'Общеквартирный';
            reading.нзправилоопераций = itemObject.правило[index];
          } else {
            reading['@состояние'] = 'Новый л/с';
            reading.нзправилоопераций = -1;
          }
          for (const ind in items) {
            switch (+ind) {
              case 0:
                reading['@показание'] = items[ind].показание;
                reading.дата = items[ind].дата;
                reading.месяц = items[ind]['расчетный месяц'];
                break;
              case 1:
                reading['@показание2'] = items[ind].показание;
                reading.дата2 = items[ind].дата;
                reading.месяц2 = items[ind]['расчетный месяц'];
                break;
              case 2:
                reading['@показание3'] = items[ind].показание;
                reading.дата3 = items[ind].дата;
                reading.месяц3 = items[ind]['расчетный месяц'];
                break;
            }
          }
        }
        this.$nextTick(() => {
          this.createModel();
        });
      }
      this.isDialog = false;
    },
  },
  watch: {
    paramGeneral() {
      this.fetchData();
    },
  },
});
