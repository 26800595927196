
import Vue from 'vue';

export default Vue.extend({
  props: {
    record: { type: [Number, String], required: true },
  },
  data() {
    return {
      phazeID: null as number | null,
      item: {
        адрес: null,
        текст: null,
        состояние: null,
      } as any,
    };
  },
});
