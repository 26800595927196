import JSON from 'json5';

async function checkLocalEnv(path: string) {
  const response = await fetch(path);
  if (response.ok) {
    try {
      return JSON.parse(await response.text());
    } catch (error: AnyException) {
      console.log(`Неверный формат ${path}`);
    }
  }
  return undefined;
}

// читаем конфигурацию из env.json
export async function getCustomConfig(configUrl: string) {
  const urls = window.location.hostname.split('.');
  const url = urls[0];
  let config;
  let localEnv;
  if (url) {
    localEnv = `${configUrl || './'}env.${url}.json`;
    config = await checkLocalEnv(localEnv);
  }
  if (!config) {
    localEnv = `${configUrl || './'}env.json`;
    config = await checkLocalEnv(localEnv);
  }
  return config;
}

export function getGlobalConfig(): any {
  return process?.env?.cfg;
}

let storeTaskConfig: Task | undefined;

export function setTaskConfig(taskConfig: Task) {
  storeTaskConfig = taskConfig;
}

export function getTaskConfig(): Task {
  return storeTaskConfig as Task;
}