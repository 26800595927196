const debt: StackSelection = {
  recordRoute: '',
  objectName: 'ЛицевыеСчета.ИнформацияОЗадолженности',
  description: 'Информация о задолженности',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'признак',
      text: 'признак',
      type: 'Number',
    },
  ],
};

export default debt;
