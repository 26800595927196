
import Vue from 'vue';
import ContactsDialog from '@/tasks/common/views/$common/contactsDialog.vue';
import stepBtn from './stepBtn.vue';

export default Vue.extend({
  components: { ContactsDialog, stepBtn },
  props: {
    record: { type: Object, required: true },
    steps: { type: Object, required: true },
  },
});
