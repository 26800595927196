import { TableHandlers } from '@/stackEngine';
const sel: StackSelection = {
  recordRoute: 'claims-claimsid',
  objectName: 'ЗаявкиАДС',
  description: 'Заявки',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (!row.$этоПапка) {
      switch (row.цветзаписи) {
        case 1:
          TableHandlers.trColor(row, 'red');
          break;
        case 2:
          TableHandlers.trColor(row, 'orange');
          break;
        case 3:
          TableHandlers.trColor(row, 'green');
          break;
        case 4:
          TableHandlers.trColor(row, 'blue');
          break;
        case 5:
          TableHandlers.trColor(row, 'grey');
          break;
        case 6:
          TableHandlers.trColor(row, 'pink');
          break;
        case 7:
          TableHandlers.trColor(row, 'purple');
          break;
        case 8:
          TableHandlers.trColor(row, 'cyan');
          break;
        case 9:
          TableHandlers.trColor(row, 'teal');
          break;
        default:
          switch (row.заявкасост) {
            case 0:
              break;
            case 1:
            case 2:
            case 4:
            case 5:
              TableHandlers.trColor(row, 'blue');
              break;
            case 3:
            case 6:
              TableHandlers.trColor(row, 'grey');
              break;
          }
          break;
      }
      if (row['@непрочитано']) {
        TableHandlers.stackStyle(row, ';Шрифт*,Ж');
      }
      if (row['@флагпросрочки'] === 1) {
        // @ts-ignore
        row.$data.иконка = { $иконка: '$vuetify.icons.flag', $иконка_цвет: 'error' };
      }
      if (row['@флагпросрочки'] === 2) {
        // @ts-ignore
        row.$data.иконка = { $иконка: '$vuetify.icons.flag', $иконка_цвет: 'warning' };
      }
    }
    return row;
  },
  fields: [
    {
      field: 'автор',
      text: 'Автор',
      type: 'String',
    },
    {
      field: 'адрес',
      text: 'Адрес объекта',
      type: 'String',
    },
    {
      field: 'адресмассив',
      text: 'АдресМассив',
      type: 'String',
    },
    {
      field: 'адресдома',
      text: 'АдресДома',
      type: 'String',
    },
    {
      field: 'время',
      text: 'Время',
      type: 'DateTime',
    },
    {
      field: 'вспомогательный номер',
      text: 'Вспомогательный номер',
      type: 'String',
    },
    {
      field: 'группа нумерации',
      text: 'Группа нумерации',
      type: 'String',
    },
    {
      field: 'дата',
      text: 'Дата приема',
      type: 'Date',
    },
    {
      field: 'дом-наряд',
      text: 'Дом-Наряд',
      type: 'Number',
    },
    {
      field: 'домофон',
      text: 'Домофон',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Содержимое заявки',
      type: 'String',
    },
    {
      field: 'номер',
      text: '№ заявки',
      type: 'String',
    },
    {
      field: 'папки',
      text: 'Папки',
      type: 'String',
    },
    {
      field: 'подъезд',
      text: 'Подъезд',
      type: 'String',
    },
    {
      field: 'получено',
      text: 'Получено',
      type: 'DateTime',
    },
    {
      field: 'приоритет',
      text: ' ',
      type: 'String',
    },
    {
      field: '@приоритет',
      text: '  ',
      type: 'String',
      computed: true,
    },
    {
      field: 'расчмесяц',
      text: 'РасчМесяц',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'счет-наряд',
      text: 'Счет-Наряд',
      type: 'String',
    },
    {
      field: 'телефон',
      text: 'Телефон',
      type: 'String',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'карточка-заявитель',
      text: 'Заявитель',
      type: 'Number',
    },
    {
      field: 'карточка-заявитель>фио',
      text: 'ФИО контрагента',
      type: 'String',
    },
    {
      field: '@контрагент',
      text: 'Контрагент',
      type: 'String',
      computed: true,
    },
    {
      field: 'этаж',
      text: 'Этаж',
      type: 'Number',
    },
    {
      field: 'признакповторная',
      text: 'ПризнакПовторная',
      type: 'Number',
    },
    {
      field: 'примечдиспетчер',
      text: 'ПримечДиспетчер',
      type: 'String',
    },
    {
      field: 'заявкасост',
      text: 'ЗаявкаСост',
      type: 'String',
    },
    {
      field: 'карточка-источник',
      text: 'Карточка-Источник',
      type: 'Number',
    },
    {
      field: 'карточка-видзаявки',
      text: 'Карточка-ВидЗаявки',
      type: 'Number',
    },
    {
      field: 'завершить до',
      text: 'Завершить до',
      type: 'Date',
    },
    {
      field: 'получена',
      text: 'Получена',
      type: 'DateTime',
    },
    {
      field: 'фаза',
      text: 'Фаза',
      type: 'String',
    },
    {
      field: '@приоритетз',
      text: '@ПриоритетЗ',
      type: 'String',
      computed: true,
    },
    {
      field: '@наряд-классификаторы',
      text: '@Наряд-Классификаторы',
      type: 'String',
      computed: true,
    },
    {
      field: '@наряд-подрядчик',
      text: '@Наряд-Подрядчик',
      type: 'String',
      computed: true,
    },
    {
      field: '@наряд-причина',
      text: '@Наряд-Причина',
      type: 'String',
      computed: true,
    },
    {
      field: '@причинаработ',
      text: '@ПричинаРабот',
      type: 'String',
      computed: true,
    },
    {
      field: '@срок',
      text: '@Срок',
      type: 'DateTime',
      computed: true,
    },
    {
      field: '@окончаниясрок',
      text: '@ОкончанияСрок',
      type: 'DateTime',
      computed: true,
    },
    {
      field: '@время',
      text: '@Время',
      type: 'Time',
      computed: true,
    },
    {
      field: '@окончаниявремя',
      text: '@ОкончанияВремя',
      type: 'Time',
      computed: true,
    },
    {
      field: '@выходс',
      text: 'Выполнить',
      type: 'Date',
      computed: true,
    },
    {
      field: '@выходпо',
      text: '@ВыходПо',
      type: 'DateTime',
      computed: true,
    },
    {
      field: '@подразделение',
      text: 'Подразделение ',
      type: 'String',
      computed: true,
    },
    {
      field: '@подрисполнитель',
      text: 'Подразделение',
      type: 'String',
      computed: true,
    },
    {
      field: 'карточки-маршрут>название',
      text: 'Вид работы',
      type: 'String',
    },
    {
      field: '@исполнитель',
      text: 'Исполнитель',
      type: 'String',
      computed: true,
    },
    {
      field: '@подсказка',
      text: 'Подсказка',
      type: 'String',
      computed: true,
    },
    {
      field: '@данныезаявкитп',
      text: '@ДанныеЗаявкиТП',
      type: 'String',
      computed: true,
    },
    {
      field: 'клиенттип',
      text: 'КлиентТип',
      type: 'Number',
    },
    {
      field: '@фаза',
      text: 'Фаза',
      type: 'String',
      computed: true,
    },
    {
      field: 'иконка',
      text: '',
      type: 'Number',
      computed: true,
    },
    {
      field: '@закрытие',
      text: '@Закрытие',
      type: 'Number',
    },
  ],
};
export default sel;
