
import Vue from 'vue';

export default Vue.extend({
  name: 'Corrsaldomaster',
  props: {
    preSettings: { type: Object, required: true },
  },
  data() {
    return {
      correction: [] as StackTableRow[],
    };
  },
  computed: {
    headers(): string {
      if (this.preSettings.типкоррекции === 0 || this.preSettings.типкоррекции === 3) {
        return 'счет-коррекция,сумма';
      }
      return 'счет-коррекция,тип,период,услуга-коррекция,поставщик-коррекция,укдоговор-коррекция,сумма';
    },
  },
  methods: {
    changeFund(payload: any) {
      this.correction = payload;
    },
  },
});
