
import Vue from 'vue';
import datePeriodFilter from '../../$common/filters/datePeriodFilter.vue';
import monthPeriodFilter from '../../$common/filters/monthPeriodFilter.vue';
import numberTypeFilter from '../../$common/filters/numberTypeFilter.vue';
import serviceFilter from '../../$common/filters/serviceFilter.vue';
import organizationsFilter from '../../$common/filters/organizationsFilter.vue';

export default Vue.extend({
  components: { datePeriodFilter, monthPeriodFilter, numberTypeFilter, serviceFilter, organizationsFilter },
  data() {
    return {
      item: {
        номер: null,
        услуга: null,
        датнач: this.$stackDate.firstDay(this.$store.getters.getCloseMonth()),
        даткнц: null,
        месяцс: null,
        месяцпо: null,
        причина: null,
      } as any,
    };
  },
});
