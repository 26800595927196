
import Vue from 'vue';
import SumToolbar from '@/tasks/fl/components/SumToolbar.vue';

export default Vue.extend({
  components: { SumToolbar },
  props: {
    ownerID: { type: [Number, String], default: null },
  },
  data() {
    return {
      perMonth: this.$store.getters.getWorkMonth(),
      divisionMonth: 0,
    };
  },
  methods: {
    onChangePerMonth() {
      this.divisionMonth = 0;
    },
    async fetchData() {
      // @ts-ignore
      this.$refs.table.fetchData();
    },
  },
});
