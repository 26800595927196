import { Module, GetterTree, MutationTree } from 'vuex';

interface SOHistoryRecord {
  textFunc: string; // текст команды
  date: string; // дата последнего выполнения
  time: string; // время начала последнего выполнения
  executionTime: string; // время выполнения
  status: string; // статус
}

interface SOHistoryState {
  SOHistoryRecords: SOHistoryRecord[], // массив записей
}

const state: SOHistoryState = {
  SOHistoryRecords: [],
};

const limit = 30;

// Геттеры
const getters: GetterTree<SOHistoryState, any> = {
  getSOHistoryRecords: () => state.SOHistoryRecords,
};

const mutations: MutationTree<SOHistoryState> = {
  SET_SO_HISTORY(state: SOHistoryState, storage) {
    Object.assign(state, storage);
  },
  SET_NEW_SO_HISTORY_RECORD(state: SOHistoryState, newRecord: SOHistoryRecord) {
    const recordIndex = state.SOHistoryRecords.findIndex(record => record.textFunc === newRecord.textFunc);
    if (recordIndex !== -1) state.SOHistoryRecords.splice(recordIndex, 1);
    if (state.SOHistoryRecords.length === limit) state.SOHistoryRecords.pop();
    state.SOHistoryRecords.unshift(newRecord);
  },
  CLEAR_SO_HISTORY(state: SOHistoryState) {
    const initialState: SOHistoryState = {
      SOHistoryRecords: [],
    };
    Object.assign(state, initialState);
  },
};

const ServiseOperationsStore: Module<SOHistoryState, any> = {
  state,
  getters,
  mutations,
};

export default ServiseOperationsStore;
