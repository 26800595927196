const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.Почта.Вложения',
  description: '',
  fields: [
    {
      field: 'имяфайла',
      text: 'Имя файла',
      type: 'String',
    },
    {
      field: 'размер',
      text: 'Кб',
      type: 'Number',
    },
    {
      field: 'образ',
      text: 'Образ',
      type: 'String',
    },
  ],
};
export default accs;
