
import Vue from 'vue';
import datePeriodFilter from './datePeriodFilter.vue';
import addressFilter from './addressFilter.vue';

export default Vue.extend({
  components: { datePeriodFilter, addressFilter },
  data() {
    return {
      item: {
        датнач: null,
        даткнц: null,
        тип: null,
        сотрудник: null,
        состояние: null,
        нп: null,
        улица: null,
        дом: null,
        квартира: null,
        фионанимателя: null,
        номерлс: null,
        стороннийномер: null,
      } as any,
    };
  },
});
