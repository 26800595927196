const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.Расчет',
  description: '',
  hier: true,
  cached: true,
  fields: [
    {
      field: 'номеру',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'наименование',
      text: 'Услуга',
      type: 'String',
      minwidth: '250px',
    },
    {
      field: 'название',
      text: 'Аналитика',
      type: 'String',
    },
    {
      field: 'использовантариф',
      text: 'Тариф',
      type: 'Number',
    },
    {
      field: 'использовананорма',
      text: 'Норма',
      type: 'Number',
    },
    {
      field: 'тариф',
      text: 'Начислено,₽',
      type: 'Money',
    },
    {
      field: 'льготировано',
      text: 'Льготы,₽',
      type: 'Money',
    },
    {
      field: 'прошлое',
      text: 'Перерасчет,₽',
      type: 'Money',
    },
    {
      field: 'качество',
      text: 'Качество,₽',
      type: 'Money',
    },
    {
      field: 'начислено',
      text: 'Итого,₽',
      type: 'Money',
    },
    {
      field: 'оплата',
      text: 'Оплата,₽',
      type: 'Money',
    },
    {
      field: 'входсальдо',
      text: 'ВходСальдо,₽',
      type: 'Money',
    },
    {
      field: 'исхсальдо',
      text: 'ИсхСальдо,₽',
      type: 'Money',
    },
    {
      field: 'тобъем',
      text: 'Начислено,V',
      type: 'Number',
    },
    {
      field: 'побъем',
      text: 'Перерасчет,V',
      type: 'Number',
    },
    {
      field: 'кобъем',
      text: 'Качество,V',
      type: 'Number',
    },
    {
      field: 'объем',
      text: 'Итого,V',
      type: 'Number',
    },
    {
      field: 'коррекция',
      text: 'Коррекция,₽',
      type: 'Money',
    },
  ],
};
export default accs;
