
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      results: [] as StackTableRow[],
      workdayModel: null as DataModel | null,
      holidayModel: null as DataModel | null,
      headers: [
        {
          field: '_дата',
          text: 'Дата',
          type: 'Date',
          width: '120px',
        },
        {
          field: 'день недели',
          text: 'День недели',
          type: 'String',
          readonly: true,
        },
      ] as StackField[],
    };
  },
  computed: {
    year(): number {
      return this.record && this.record.значение ? new Date(this.record.значение).getFullYear() : 0;
    },
    isContactcenter(): boolean {
      return this.$store.getters.getCurrentTask() === 'contactcenter';
    },
  },
  async created() {
    this.updateData();
  },
  methods: {
    dayOfWeek(row: StackTableRow) {
      const dateRow = row._дата ? row._дата.toString() : '';
      if (dateRow !== '') {
        row['день недели'] = new Date(dateRow).toLocaleString('ru', { weekday: 'long' });
      } else {
        row['день недели'] = '';
      }
      return row;
    },
    checkRowHolidayData(item: StackTableRow): false | string {
      // если вернёшь строку, то выйдет предупреждение и запись не сохранится
      const d = item._дата as string;
      const dayrecord = new Date(d).getDay();
      const yearrecord = new Date(d).getFullYear();
      if (this.isContactcenter && this.year === yearrecord) {
        return false;
      } else if (dayrecord !== 0 && dayrecord !== 6 && this.year === yearrecord) {
        return false;
      }
      return 'Некорректая дата! Уже является выходным днем или некорректый год';
    },
    checkRowWorkdayData(item: StackTableRow): false | string {
      // если вернёшь строку, то выйдет предупреждение и запись не сохранится
      const d = item._дата as string;
      const dayrecord = new Date(d).getDay();
      const yearrecord = new Date(d).getFullYear();
      if (this.isContactcenter && this.year === yearrecord) {
        return false;
      } else if ((dayrecord === 0 || dayrecord === 6) && this.year === yearrecord) {
        return false;
      }
      return 'Некорректая дата! Не является выходным днем или некорректый год';
    },
    changeHolidayData(items: StackTableRow[]) {
      this.$set(this.record, 'допзначение', this.arrayToString(items));
    },
    changeWorkdayData(items: StackTableRow[]) {
      this.$set(this.record, 'допзначение2', this.arrayToString(items));
    },
    arrayToString(items: StackTableRow[]) {
      let str = '';
      for (const objItem of items) {
        if (objItem._дата) {
          str += this.$stackDate.format(objItem._дата.toString(), 'DDMM') + ';';
        }
      }
      return str;
    },
    async updateData() {
      const holidaysArray = await new this.$HttpModel('СписокВыходныхДней').getRecords({ год: this.record.значение });
      this.holidayModel = new this.$StaticModel(holidaysArray, {
        rowTemplate: this.headers,
        description: 'Укажите праздники',
        prepareRow: this.dayOfWeek,
        checkRow: this.checkRowHolidayData,
      });
      this.$set(this.record, 'допзначение', this.arrayToString(holidaysArray));
      const workArray = await new this.$HttpModel('СписокРабочихДней').getRecords({ год: this.record.значение });
      this.workdayModel = new this.$StaticModel(workArray, {
        rowTemplate: this.headers,
        description: 'Укажите будни',
        prepareRow: this.dayOfWeek,
        checkRow: this.checkRowWorkdayData,
      });
      this.$set(this.record, 'допзначение2', this.arrayToString(workArray));
      // @ts-ignore
      this.$refs.dialog.saveState(this.record);
    },
  },
});
