
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      record: {
        'Список лицевых': null,
        Месяц: this.$store.getters.getWorkMonth(),
        НомерЗаписиУслуги: null,
        НомерЗаписиКатегории: null,
        НомерЗаписиПоставщика: null,
        НомерЗаписиКатегорииПост: null,
        НомерЗаписиУК: null,
        НомерЗаписиУКДоговор: null,
        Период: null,
        Процент: null,
        ПроцентПрев: null,
      } as StackTableRow,
      loading: false,
    };
  },
  computed: {
    title(): string {
      let str = `Внимание! чтобы корректно сформировать соглашения необходимо, чтобы выбранная часть фонда была рассчитана за `;
      str += this.$stackDate.format(this.record.Месяц, 'MM.YYYY');
      str += `\n Процент, при превышении которого предоставляется рассрочка - ` + this.record.ПроцентПрев + `%`;
      return str;
    },
    requiredService(): boolean {
      return !this.record['НомерЗаписиКатегории'];
    },
    requiredCategoryService(): boolean {
      return !this.record['НомерЗаписиУслуги']; // +this.record['НомерЗаписиКатегории'] > 0 || +this.record['НомерЗаписиУслуги'] <= 0;
    },
    disableButton(): boolean {
      return !this.record || !this.record['Список лицевых'] || (!this.record['НомерЗаписиУслуги'] && !this.record['НомерЗаписиКатегории']);
    },
  },
  async created() {
    const res = await new this.$HttpModel('СоглашенияОРассрочке').executeMethod('ИнициализацияПараметровМассово');
    this.record.Период = res.период || 12;
    this.record.Процент = res.процент || 0;
    this.record.ПроцентПрев = res.процентпрев || 0;
  },

  methods: {
    onChangeServ(id: number) {
      this.record['НомерЗаписиКатегории'] = null;
    },
    onChangeCategServ(id: number) {
      this.record['НомерЗаписиУслуги'] = null;
    },
    onChangePost(id: number) {
      this.record['НомерЗаписиКатегорииПост'] = null;
    },
    onChangeCategPost(id: number) {
      this.record['НомерЗаписиПоставщика'] = null;
    },
    async onClick() {
      try {
        this.loading = true;
        await new this.$HttpModel('СоглашенияОРассрочке').executeMethod(
          'СформироватьМассово',
          { параметры: this.record },
          {
            async: true,
            jobName: 'Формирование рассрочек',
            onComplete: () => {
              this.loading = false;
            },
            onError: () => {
              this.loading = false;
            },
          },
        );
      } catch (error: AnyException) {
        this.$toast(error);
      }
    },
  },
});
