
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    items: { type: Array as PropType<StackStateMsg[]>, required: true },
  },
  methods: {
    timeStartEnd(msg: StackStateMsg): string {
      // @ts-ignore
      const datConv = this.$options.filters.date;
      return `${datConv(msg.created, 'HH:mm')} - ${msg.closed ? datConv(msg.closed, 'HH:mm') : '........'}`;
    },
    msgPercent(msg: StackStateMsg): number {
      return !msg.complete ? msg.percent : 100;
    },
    getIcon(msg: StackStateMsg) {
      switch (msg.type) {
        case 'report':
          return '$async_type_report';
        case 'func':
          return '$async_type_func';
        case 'file':
          return '$async_type_file';
        case 'message':
          return '$async_type_message';
        default:
          return '$async_type_other';
      }
    },
  },
});
