
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      items2: [
        { title: 'Выгрузки в файлы', img: 'action_exports', to: { name: 'operations-unloads' }, color: 'stack' },
        { title: 'Подкачки из файлов', img: 'action_imports', to: { name: 'operations-uploads' }, color: 'stack' },
        { title: 'Принять показания по распределителям', img: 'import_pokaz_otop', to: { name: 'metersdata-readingsotopuploads' }, color: 'stack' },
        { title: 'Ежемесячный расчет компенсаций', img: 'ezhem_raschet_kompensacii', to: { name: 'operations-compensations' }, color: 'stack' },
        { title: 'Индивидуальные операции', img: 'action_custom', to: { name: 'operations-individual' }, color: 'stack' },
        { title: 'Служебные операции', img: 'action_service', to: { name: 'operations-serviceoperations' }, color: 'stack' },
        { title: 'Загрузка показаний из АСД', img: 'import_pokaz', to: { name: 'operations-asd-uploadreadings' }, color: 'stack' },
      ],
      items3: [
        { title: 'Мастер коррекции сальдо', img: 'master_saldo_corr', to: { name: 'operations-corrsaldomaster' }, color: 'stack' },
        { title: 'Массовая расстановка ГУИДов на домах', img: 'guids_on_homes', to: { name: 'operations-placementguids' }, color: 'stack' },
        { title: 'Почтовый клиент', img: 'mail_client', to: { name: 'operations-emailagent' }, color: 'stack' },
      ],
    };
  },
});
