
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      item: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        ДатНач: this.$store.getters.getWorkMonth(),
        ДатКнц: this.$stackDate.lastDay(this.$store.getters.getWorkMonth()) as string,
        '@Таблицы': 'Все',
        '@Пользователи': '',
      },
    };
  },
});
