const cmonth: StackSelection = {
  objectName: 'ПравилаПроверкиБазы',
  description: '',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'id',
      text: 'ID',
      type: 'Number',
    },
    {
      field: 'icon',
      text: 'icon',
      type: 'String',
    },
    {
      field: 'color',
      text: 'color',
      type: 'String',
    },
  ],
};

export default cmonth;
