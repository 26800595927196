const dog = {
  objectName: 'РеестрыЛицевых',
  description: 'Реестры лицевых',
  openLink: 'documents-registries',
  hier: true,
  prepareRow: (row: any) => {
    if (!row.$data) {
      row.$data = {};
    }
    if (!row.$этоПапка) {
      // @ts-ignore
      row.$data.адрес = { $иконка: '$vuetify.icons.arrow_up' };
    }
    return row;
  },
  fields: [
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
  ],
};

export default dog;
