const requestlog: StackSelection = {
  objectName: 'РеестрОбращений',
  description: 'Реестр обращений',
  fields: [
    {
      field: 'датавремя',
      text: 'Дата обращения',
      type: 'DateTime',
    },
    {
      field: 'содержание',
      text: 'Содержание',
      type: 'String',
    },
    {
      field: 'иддом',
      text: 'идДом',
      type: 'Number',
    },

    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'адресдом',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'адресподъезд',
      text: 'АдресПодъезд',
      type: 'String',
    },
    {
      field: 'адресэтаж',
      text: 'АдресЭтаж',
      type: 'DateTime',
    },
    {
      field: 'адресквартира',
      text: 'АдресКвартира',
      type: 'String',
    },
    {
      field: 'адрескомната',
      text: 'АдресКомната',
      type: 'String',
    },
    {
      field: 'адреспризнаки',
      text: 'АдресПризнаки',
      type: 'String',
    },
    {
      field: 'номерлс',
      text: 'НомерЛС',
      type: 'DateTime',
    },
    {
      field: 'абонент',
      text: 'Абонент',
      type: 'String',
    },
    {
      field: 'заявитель',
      text: 'Заявитель',
      type: 'String',
    },
    {
      field: 'контакт',
      text: 'Контакт',
      type: 'String',
    },
    {
      field: 'выполнитьс',
      text: 'ВыполнитьС',
      type: 'DateTime',
    },
    {
      field: 'выполнитьпо',
      text: 'ВыполнитьПо',
      type: 'DateTime',
    },
    {
      field: 'заявкаисполнитель',
      text: 'ЗаявкаИсполнитель',
      type: 'String',
    },
    {
      field: 'заявкаид',
      text: 'ЗаявкаИД',
      type: 'String',
    },
    {
      field: 'заявканомер',
      text: '№ заявки',
      type: 'String',
    },
    {
      field: 'заявкадата',
      text: 'ЗаявкаДата',
      type: 'DateTime',
    },
    {
      field: 'заявкастатус',
      text: 'Статус',
      type: 'String',
    },
    {
      field: 'обращение-вид>название',
      text: 'Вид обращения',
      type: 'String',
    },
    {
      field: 'обращение-результат>название',
      text: 'Результат',
      type: 'String',
    },
    {
      field: 'заявка-вид',
      text: 'Заявка - Вид',
      type: 'Number',
    },
    {
      field: 'заявка-работа',
      text: 'Заявка - Работа',
      type: 'Number',
    },
    {
      field: 'обращение-вид',
      text: 'Вид обращения',
      type: 'Number',
    },
    {
      field: 'обращение-результат',
      text: 'Результат',
      type: 'Number',
    },
    {
      field: 'автор>фио',
      text: 'Автор',
      type: 'String',
    },
    {
      field: 'редактировал',
      text: 'Редактировал',
      type: 'Number',
    },
    {
      field: 'оператор',
      text: 'Оператор обращения',
      type: 'Number',
    },
    {
      field: 'оператор>фио',
      text: 'Оператор',
      type: 'String',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'ответ',
      text: 'Ответ',
      type: 'String',
    },
    {
      field: 'обращение-вид>признаки',
      text: 'Признак',
      type: 'Number',
    },
    {
      field: 'emailответа',
      text: 'EmailОтвета',
      type: 'String',
    },
    {
      field: 'датавремяответа',
      text: 'ДатаВремяОтвета',
      type: 'DateTime',
    },
    {
      field: 'идентификаторлс',
      text: 'лс',
      type: 'Number',
    }, // Идентификатор лицевого счета в контакт-центре
    {
      field: '@идабонент',
      text: 'ИдАбонент',
      type: 'Number',
    }, // Идентификатор лицевого счета в сторонней системе
  ],
};
export default requestlog;
