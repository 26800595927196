const serv: StackSelection = {
  objectName: 'КатегорииУслуг',
  description: 'Категории услуг',
  openLink: 'references-serviceskat',
  hier: true,
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'номер категории',
      text: 'Номер категории',
      type: 'Number',
    },
    {
      field: 'услуга-категории',
      text: 'Услуга',
      type: 'Link',
    },
  ],
};

export default serv;
