const problemPays: StackSelection = {
  recordRoute: 'problempaydistribution',
  objectName: 'ПлатежныеВедомости.ПроблемныеПлатежи.Распределение',
  description: 'Распределение',
  fields: [
    {
      field: '@тип',
      text: 'Тип',
      type: 'String',
      width: '100px',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Number',
    },
    {
      field: 'прраспределение-услуга',
      text: 'Услуга',
      type: 'Link',
    },
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'прраспределение-ук',
      text: 'Управляющая компания',
      type: 'Link',
    },
    {
      field: 'ук',
      text: 'Управляющая компания',
      type: 'String',
    },
    {
      field: 'прраспределение-пост',
      text: 'Поставщик',
      type: 'Link',
    },
    {
      field: 'поставщик',
      text: 'Поставщик',
      type: 'String',
    },
  ],
};
export default problemPays;
