
import Vue from 'vue';

export default Vue.extend({
  name: 'StackFileInput',
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: [String], default: undefined },
    /**
     * флаг необходимости переименовать файл, чтобы кто-нибудь не перетер его в папке uploads
     */
    nonUnique: { type: Boolean, default: false },
    /**
     * выбирать только папку
     */
    folder: { type: Boolean, default: false },
    /**
     * загрузка нескольких файлов
     */
    multiple: { type: Boolean, default: false },
    /**
     * фильтр по расширению файла (txt,dbf,jpg и тд.)
     */
    extensions: { type: String, default: '' },
    /**
     * возвращать дополнительно первоначальное имя
     */
    sourceName: { type: Boolean, default: false },
  },
  data() {
    return {
      showFiles: '',
      files: [] as any,
      uploadedFiles: [] as any,
      directory: false,
      item: {} as StackTableRow,
      loading: false,
      progress: 0,
      fileId: '',
      label: !this.folder ? 'Имя файла' : 'Выберите каталог',
    };
  },
  computed: {
    apiHostShare(): string {
      return this.$store.getters.getApiHostShare();
    },
    apiHostUpload(): string {
      return this.$store.getters.getApiHostUpload();
    },
    computedValue(): string {
      return this.value ? this.value : this.showFiles;
    },
  },
  methods: {
    onSelect() {
      this.uploadedFiles = [];
      this.files = [];
      this.showFiles = '';

      if (this.folder) {
        this.onAddFolder();
      } else {
        this.onFileSelect();
      }
    },
    onFileSelect() {
      // @ts-ignore
      const input = this.$refs.upload.$el.querySelector('input');
      input.click();
    },
    onAddFolder() {
      // @ts-ignore
      if (!this.$refs.upload.features.directory) {
        this.$toast('Бразуер не поддерживает загрузку каталога!', { color: 'error' });
        return;
      }
      // @ts-ignore
      const input = this.$refs.upload.$el.querySelector('input');
      input.directory = true;
      input.webkitdirectory = true;
      this.directory = true;
      input.onclick = null;
      input.click();
      input.onclick = () => {
        this.directory = false;
        input.directory = false;
        input.webkitdirectory = false;
      };
    },
    onClear() {
      this.uploadedFiles = [];
      this.files = [];
      this.showFiles = '';
    },
    async onUpload(newFile: any, oldFile: any) {
      try {
        // начало загрузки
        if (newFile && !oldFile) {
          this.loading = true;
          this.progress = 0;

          if (this.folder) {
            const indexSlash = newFile.name.indexOf('/');
            if (indexSlash > 0) {
              newFile.name = newFile.name.slice(indexSlash + 1);
              // @ts-ignore
              this.$refs.upload.update(newFile, { name: newFile.name });
            }
          }
          this.files.push({ name: newFile.name, nameOld: newFile.nameOld });
          this.showFiles += newFile.name + '; ';
          // Переименование файла для уникальности
          if (!this.nonUnique) {
            const http = new this.$StackApi();
            http.clear();
            const taskID = http.fetch(newFile.name, {}, 'загрузить', 'файл') as number;
            await http.run();
            const data = http.getTaskResult(taskID);
            if (data && data.fileId) {
              // eslint-disable-next-line require-atomic-updates
              newFile.nameOld = newFile.name;
              newFile.name = data.fileId;
              // @ts-ignore
              this.$refs.upload.update(newFile, { name: newFile.name, nameOld: newFile.nameOld });
            }
          }
          // @ts-ignore
          this.$refs.upload.active = true;
        }
        // Идёт загрузка
        if (newFile && oldFile) {
          // Прогресс
          if (newFile.progress !== oldFile.progress && newFile.active) {
            this.progress = Math.floor((this.uploadedFiles.length * 100 + Number(newFile.progress)) / this.files.length);
          }
          // загрузка завершена
          if (newFile.success !== oldFile.success) {
            if (!this.sourceName) {
              this.uploadedFiles.push({ name: newFile.name, file: newFile.file });
            } else {
              this.uploadedFiles.push({ fileId: newFile.name, fileName: newFile.nameOld, file: newFile.file });
            }
            this.loading = false;
          }
          // Произошла ошибка
          if (newFile.error !== oldFile.error) {
            const beforeError = this.folder ? 'В одном из файлов каталога ошибка! Файлы не загрузились!\n' : '';
            if (newFile.error === 'extension') {
              const textError = beforeError + 'Неверный тип файла, необходимо выбрать тип "' + this.extensions + '"';
              throw textError;
            } else {
              throw new Error(beforeError + newFile.error);
            }
          }
        }
      } catch (error: AnyException) {
        // console.log('FileDialog: Произошла ошибка при upload', error);
        this.$toast(error.message ? error.message : error, { color: 'error' });
        this.loading = false;
      }
    },
  },
  watch: {
    uploadedFiles() {
      if (!this.uploadedFiles.length) {
        this.$emit('uploadFiles', this.uploadedFiles);
        this.$emit('loading', true);
      }
      if (this.uploadedFiles.length === this.files.length && this.uploadedFiles.length > 0) {
        this.$emit('uploadFiles', this.uploadedFiles);
        this.$emit('loading', false);
      }
    },
    folder() {
      this.uploadedFiles = [];
      this.files = [];
      this.showFiles = '';
    },
  },
});
