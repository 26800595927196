import Vue from 'vue';
import { StackApi } from './StackApi';
import { store } from '../../store';
import { StackSelectionCache } from '../../utils';

export interface Report {
  executeReport(reportName: string, params?: object, options?: any): Promise<boolean>; // начинаем формирование отчета
  getReportList(forTable?: boolean): Promise<StackReportSelections[]>; // получаем список отчетов
  getReportMenu(): Promise<StackReportSelections[]>; // получаем список меню отчетов
  getDialogScheme(): Promise<any>; // получаем схему диалога для конструктора
}

export class ReportsBuilder implements Report {
  public name: string;
  private http: StackApi;

  constructor(reportPrefix: string) {
    this.name = reportPrefix;
    this.http = new StackApi();
  }

  // TODO Временно. Потом будет убит основной метод
  public async executeReport2(reportName: string, record?: any, options?: any): Promise<boolean> {
    try {
      // Проверяем наличие диалога на бекэнде
      // TODO "отчет" для совместимости c 20.06-20.09. он не нужен
      const reportDialogScheme = await this.getDialogScheme(record);
      let localParams: StackHttpRequestTaskParam | boolean = {};
      // Если описание найдено, то вызываем диалог
      if (reportDialogScheme) {
        localParams = await Vue.prototype.$stackDialog(reportDialogScheme, record, options ? options.params : undefined);
        if (localParams === false) {
          return false;
        }
      } else {
        localParams = record;
      }
      localParams = {
        ...localParams as StackHttpRequestTaskParam,
        ...{
          ТихийРежим: 1,
          'Вызов из веба': 1,
        },
      };
      localParams.номерЗаписи = record?.номерЗаписи;
      localParams.имяВыборки = record?.имяВыборки;
      localParams['Список лицевых'] = record?.['Список лицевых'] || localParams['Список лицевых'];
      if (options && options.params) {
        localParams = { ...localParams, ...options.params as StackHttpRequestTaskParam };
      }
      this.http.clear();
      this.http.setAsyncJob();
      const taskID = this.http.fetch(reportName, localParams, 'получить', 'отчет');
      await this.http.run();
      const res = taskID !== null ? this.http.getTaskResult(taskID) : this.http.getTaskResult(0);
      const guid = res && res.asyncId ? res.asyncId : null;
      if (guid) {
        let title = options && options.title ? options.title : reportName;
        title = title.replace('@', '');
        store.commit('MSG_ADD', { title, asyncId: guid, type: 'report' });
        return true;
      }
    } catch (error: AnyException) {
      Vue.prototype.$toast(`Не удалось выполнить отчет ${reportName}\nОшибка: ${error.message}`, { color: 'error' });
      return false;
    }
    return false;
  }

  public async executeReport(reportName: string, params?: StackHttpRequestTaskParam, options?: any): Promise<boolean> {
    try {
      // Проверяем наличие диалога на бекэнде
      let localParams: StackHttpRequestTaskParam | boolean = {};
      localParams = { ...localParams as StackHttpRequestTaskParam, ...params };
      this.http.clear();
      this.http.setAsyncJob();
      const taskID = this.http.fetch(reportName, localParams, 'получить', 'отчет');
      await this.http.run();
      const res = taskID !== null ? this.http.getTaskResult(taskID) : this.http.getTaskResult(0);
      const guid = res && res.asyncId ? res.asyncId : null;
      if (guid) {
        let title = options && options.title ? options.title : reportName;
        title = title.replace('@', '');
        store.commit('MSG_ADD', { title, asyncId: guid, type: 'report', silent: options && options.silent });
        return true;
      }
    } catch (error: AnyException) {
      Vue.prototype.$toast(`Не удалось выполнить отчет ${reportName}\nОшибка: ${error.message}`, { color: 'error' });
      return false;
    }
    return false;
  }

  public async getReportList(forTable?: boolean): Promise<StackReportSelections[]> {
    try {
      let data: any;
      data = StackSelectionCache.getCacheByModel(`${this.name}_получитьСписокОтчетов`);
      if (!data) {
        this.http.clear();
        this.http.fetch(this.name, { дляЗаписи: !forTable }, 'получитьСписокОтчетов', 'выборка');
        await this.http.run();
        data = this.http.getTaskResult(0);
        StackSelectionCache.setCacheByModel(`${this.name}_получитьСписокОтчетов`, data);
      }

      const itemsReport = this.fillReportsMenu(data.список);
      return itemsReport;
    } catch (error: AnyException) {
      return [];
    }
  }

  public async getReportMenu(): Promise<StackReportSelections[]> {
    try {
      let data: any;
      data = StackSelectionCache.getCacheByModel(`${this.name}_получитьМенюОтчетов`);
      if (!data) {
        this.http.clear();
        this.http.fetch(this.name, {}, 'получитьМенюОтчетов', 'отчет');
        await this.http.run();
        data = this.http.getTaskResult(0);
        StackSelectionCache.setCacheByModel(`${this.name}_получитьМенюОтчетов`, data);
      }

      const itemsReport = this.fillReportsMenu(data.список);
      return itemsReport;
    } catch (error: AnyException) {
      return [];
    }
  }

  private fillReportsMenu(reportsList: any[]): StackReportSelections[] {
    const result = [] as StackReportSelections[];
    let customGr = undefined as any;
    for (const elem of reportsList) {
      const res = {
        title: elem.заголовок,
        name: elem.название,
        isGroup: elem.тип === 'группа',
      } as StackReportSelections;
      if (elem.группировка) {
        if (!customGr) {
          customGr = {};
        }
        if (!customGr[elem.группировка]) {
          customGr[elem.группировка] = {
            title: elem.группировка,
            name: elem.группировка,
            isGroup: true,
            children: [],
          };
        }
        if (elem.список) {
          res.children = this.fillReportsMenu(elem.список);
        }
        customGr[elem.группировка].children.push(res);
      } else {
        if (elem.список) {
          res.children = this.fillReportsMenu(elem.список);
        }
        result.push(res);
      }
    }
    for (const grID in customGr) {
      result.unshift(customGr[grID]);
    }
    return result;
  }

  public async getDialogScheme(params?: any): Promise<any> {
    return this.http.getApiDialog(this.name, params, 'отчет');
  }
}
