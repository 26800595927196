const grcount: StackSelection = {
  objectName: 'ГрупповыеСчетчики.Журнал',
  description: '',
  recordRoute: '',
  fields: [
    {
      field: 'за месяц',
      text: 'За месяц',
      type: 'DateMonth',
    },
    {
      field: 'стрдата',
      text: 'Дата',
      type: 'String',
    },
    {
      field: 'пользователь',
      text: 'Пользователь',
      type: 'String',
    },
    {
      field: 'комментарий',
      text: 'Действие',
      type: 'String',
    },
  ],
};
export default grcount;
