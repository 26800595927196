
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    monthKvit: { type: String, default: null },
    selectedRows: { type: Array, required: true },
  },
  data() {
    return {
      email: '' as string,
      emailCombo: '' as string,
      emailText: '' as string,
      emailType: 0 as number,
      saveemail: 0 as number,
      listEmail: [] as any,
      месяц: this.$store.getters.getWorkMonth(),
      items: [
        { emailType: 1, inputLabel: 'из параметра: ', inputName: 'email' },
        { emailType: 2, inputLabel: 'из Контактов: ', inputName: 'listEmail' },
        { emailType: 3, inputLabel: 'ввод вручную: ', inputName: 'emailText' },
      ] as any,
      isDiffMonths: false as boolean,
      isLoad: true as boolean,
    };
  },
  async created() {
    if (this.selectedRows.length) {
      // @ts-ignore
      this.месяц = this.selectedRows[0].month;
      if (this.selectedRows.length > 1) {
        this.isDiffMonths = this.selectedRows.some((checkedRow: any) => {
          if (checkedRow.month !== this.месяц) {
            return true;
          }
          return false;
        });
      }
    }
    const res = await new this.$HttpModel('ЛицевыеСчета.Квитанции').executeMethod('получитьEmail', { лицевой: this.record.$номерЗаписи });
    if (res && res.тип) {
      this.email = res.email ? res.email : '';
      this.listEmail = res.contactEmail ? res.contactEmail.split(';') : [];
      this.emailCombo = this.listEmail[0];
      this.emailType = res.тип;
    } else {
      this.emailType = 3;
    }
    this.isLoad = false;
  },
  methods: {
    async onSave() {
      this.isLoad = true;
      if ((!!this.email && this.emailType === 1) || (!!this.emailCombo && this.emailType === 2) || (!!this.emailText && this.emailType === 3)) {
        if (this.selectedRows.length < 1) {
          this.selectedRows.push({
            row_id: -1,
            month: this.месяц,
            name: '',
          });
        }
        const parameters = {
          лицевой: this.record.$номерЗаписи,
          квитанции: this.selectedRows,
          емайл: this.emailType === 1 ? this.email : this.emailType === 2 ? this.emailCombo : '',
          тип: this.emailType,
          емайлввод: this.emailText,
          сохранить: this.saveemail,
          месяц: this.месяц,
        };
        const emailMsg = this.emailType === 1 ? this.email : this.emailType === 2 ? this.emailCombo : this.emailText;
        const isOneKvit = this.selectedRows.length === 1 ? `и` : `й`;
        await new this.$HttpModel('ЛицевыеСчета.Квитанции').executeMethod('ОтправитьКвитанцию', parameters, {
          async: true,
          jobName: `Отправка квитанци` + isOneKvit + ` по л/с ` + this.record.номер + (!this.isDiffMonths ? ` за ` + this.$stackDate.format(this.месяц, 'MM.YYYY') : ``) + ` на email ` + emailMsg,
          type: 'func',
          onComplete: () => {
            this.refreshTable();
          },
          onError: () => {
            this.refreshTable();
          },
        });
        this.$emit('close');
      } else {
        this.$toast('Не задан адрес email для отправки', { color: 'error' });
        this.isLoad = false;
      }
    },
    onChange(emailType: number) {
      if (emailType !== 3) {
        this.saveemail = 0;
      }
    },
    refreshTable() {
      this.$sendMsg('stack-table', 'ЛицевыеСчета.Квитанции', 'reload');
    },
  },
});
