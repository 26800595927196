const acts: StackSelection = {
  objectName: 'РеестрыУК',
  description: 'Реестры УК',
  hier: true,
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (!row.$этоПапка) {
      // @ts-ignore
      row.$data.сортировка = { $иконка: '$vuetify.icons.arrow_down' };
    }
    return row;
  },
  fields: [
    {
      field: 'реестры_состав-лицевой',
      text: 'Реестры_состав-Лицевой',
      type: 'Link',
    },
    {
      field: 'сортировка',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'месблок',
      text: 'МесБлок',
      type: 'Date',
    },
    {
      field: 'adress',
      text: 'Название',
      type: 'String',
    },
  ],
};
export default acts;
