
import Vue from 'vue';
import { TableHandlers } from '@/stackEngine';

import TransitionTable from '@/tasks/dlg_fl/components/$components/TransitionTable.vue';
import PrintKomplekt from '@/tasks/dlg_fl/components/$dialogs/PrintKomplekt.vue';
import TransitionFilterMain from '@/tasks/dlg_fl/views/$common/filters/TransitionFilter.vue';
import ReadingInputDialog from '@/tasks/fl/views/$common/$readingdialog/ReadingInputDialog.vue';

export default Vue.extend({
  name: 'Trans',
  components: { 'Print-Komplekt': PrintKomplekt, TransitionFilterMain, 'ReadingInput-Dialog': ReadingInputDialog },
  model: { prop: 'parametersdop', event: 'input' },
  props: {
    parametersdop: { type: [Object, Array], required: true },
  },

  data() {
    return {
      headers: 'номерлс,допфио,номердела,дата документа,дата контроля,кредитор,текущаясумма,состояние,датнач' as string,
      dataObject: new this.$HttpModel('РеестрПереходаОгрЮЛ'),
      modelControl: null as any,
      filter: {
        долгот: 0,
        долгдо: 0,
        дсостояние: this.parametersdop.НаДату.НаДату,
        'дата уведомления': '',
        текфаза: -1,
        текподфаза: -1,
        ТекущаяФаза: -1,
        ТекущееСостояние: -1,
        сетевая: -1,
        статус: 0,
        доставка: -1,
        ответственный: this.$store.getters.getUserId(),
        ответственныйфлаг: 0,
      } as any,
      record: {
        показаниясчетчиков: 0,
        номерЗаписи: 0,
      } as any,
      modaldialog: false,
      loading: false,
      items: [] as any[],
      SelectIndex: [] as any,
      fileds: '',
      readinginput: false,
      dopfields: '',
    };
  },
  computed: {
    isk(): boolean {
      return this.dopfields.indexOf('дата уведомления') !== -1;
    },
    delivery(): boolean {
      return this.dopfields.indexOf('доставка') !== -1;
    },
    nomerUv(): boolean {
      return this.dopfields.indexOf('номеруведомления') !== -1;
    },
    pokSchet(): boolean {
      return this.dopfields.indexOf('показаниясчетчиков') !== -1;
    },
    setOrg(): boolean {
      return this.fileds.indexOf('сетевая') !== -1;
    },
    deliveryOsn(): boolean {
      return this.fileds.indexOf('доставка') !== -1;
    },
  },
  methods: {
    async onFilter(payload: object) {
      Object.assign(this.filter, payload);
      this.initializationTableControl();
    },
    onEnterPok(payload: any) {
      this.record.показаниясчетчиков = payload.показаниясчетчиков;
      this.record.номерЗаписи = payload.текущеесостояние;
      this.readinginput = true;
    },
    async onPrepare() {
      this.fileds = '';
      const d = await this.dataObject.executeMethod('Подготовка', { Параметры: this.filter, 'Подготовка данных': true });
      this.fileds += d;
    },
    getLawsuits() {
      this.initializationTableControl();
    },
    async initializationTableControl() {
      const results = await this.dataObject.getRecords({ Параметры: this.filter });
      this.modelControl = new this.$StaticModel(results, { rowTemplate: this.dataObject.fields as StackField[], description: this.dataObject.description });
      if (this.modelControl.data.length !== 0) {
        this.filter.ТекущаяФаза = this.modelControl.data[0]['фаза-состояние'];
        this.filter.ТекущееСостояние = this.modelControl.data[0]['подфаза-состояние'];
      } else {
        this.$stackMsg([
          {
            текст: `Не найдено дел для дальнейшей обработки`,
            варианты: ['Ок'],
          },
        ]);
      }
    },
    async ApplyReadings() {
      for (const row of this.SelectIndex) {
        this.SelectIndex[row] = 0;
      }
      for (const key in this.modelControl.data) {
        this.modelControl.data[key].датнач = this.filter.дсостояние;
        this.modelControl.data[key].состояние = this.filter.статус;
        this.modelControl.data[key].номеруведомления = this.nomerUv ? +this.filter.номеруведомления + +key : '';
        this.modelControl.data[key].датнач =
            this.modelControl.data[key].датнач_до > this.modelControl.data[key].датнач
              ? this.modelControl.data[key].датнач_до
              : this.modelControl.data[key].датнач;
      }
      // @ts-ignore
      this.$refs.table.fetchData();
    },
    async sendControls() {
      this.loading = true;
      for (const key in this.modelControl.data) {
        // Проверим заполнение датнач
        this.modelControl.data[key].датнач = this.modelControl.data[key].датнач ? this.modelControl.data[key].датнач : this.$store.getters.getCurrentDate();
        if (!this.SelectIndex[this.modelControl.data[key].состояние] || this.SelectIndex[this.modelControl.data[key].состояние] < 0) {
          this.SelectIndex[this.modelControl.data[key].состояние] = 0;
        }
        this.SelectIndex[this.modelControl.data[key].состояние] += 1;
      }
      const parameters = { Параметры: this.filter, Выборка: this.modelControl.data, 'Подготовка данных': false, Индексы: this.SelectIndex };
      const reportID = await this.dataObject.executeMethod('МассовыйПереход', parameters, { jobName: 'Массовый переход', type: 'func' });
      this.loading = false;
      // Смотрим, есть ли комплекты для массового перехода
      const res = await new this.$HttpModel('РеестрПерехода_Печать').getRecords({
        текущаяФаза: this.filter.ТекущаяФаза,
        текущееСостояние: this.filter.ТекущееСостояние,
        тип: 1,
        индексы: this.SelectIndex,
      });
      if (res && res.length !== 0) {
        this.modaldialog = true;
      } else {
        this.$emit('closeTransition');
      }
    },
    async Change_sost() {
      const parameters = { Параметры: this.filter, 'Подготовка данных': true };
      this.headers = 'номерлс,допфио,номердела,дата документа,дата контроля,текущаясумма,состояние,датнач';
      this.items = await this.dataObject.executeMethod('ПроверкаПолей', parameters);
      for (const key in this.items) {
        this.headers +=
          this.headers.indexOf(this.items[key].columnName.toLowerCase()) === -1 && this.items[key].columnName.toLowerCase() !== 'показаниясчетчиков'
            ? ',' + this.items[key].columnName
            : '';
        this.dopfields += this.dopfields.indexOf(this.items[key].columnName.toLowerCase()) === -1 ? ',' + this.items[key].columnName.toLowerCase() : '';
        this.filter[this.items[key].columnName] = this.items[key].record;
      }
    },
  },
});
