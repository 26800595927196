
import Vue from 'vue';

export default Vue.extend({
  name: 'LsTenants',
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      http: new this.$HttpModel('ЛицевыеСчета.ОбщаяИнформация'),
      item: {} as StackTableRow,
      index: '' as String,
    };
  },
  computed: {
    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
  },
  methods: {
    async fetchData() {
      if (this.record && this.record.$номерЗаписи > 0) {
        const items = await this.http.getRecords({ владелец: this.record.$номерЗаписи });
        if (items.length > 0) {
          this.item = items[0];
          if (items[0].индекс) {
            this.index = items[0].индекс.toString();
          }
          if (items[0].типстрой) {
            this.$emit('getType', items[0].типстрой);
          }
          if (items[0].категория) {
            this.$emit('getCategory', items[0].категория);
          }
        }
        this.$emit('getIndex', this.index);
      }
    },
  },
  watch: {
    record: {
      immediate: true,
      handler(to) {
        this.fetchData();
      },
    },
    workMonth() {
      this.fetchData();
    },
  },
});
