const deductionslist: StackSelection = {
  objectName: 'ВедомостиУдержаний.СписокУдержаний',
  description: '',
  fields: [
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'удержание-плательщик',
      text: 'ПлательщикИд',
      type: 'Link',
    },
    {
      field: 'плательщикфио',
      text: 'Плательщик',
      type: 'String',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'номер',
      text: 'Лицевой счет',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'удержание-счет',
      text: 'удержание-счет',
      type: 'Link',
    },
  ],
};
export default deductionslist;
