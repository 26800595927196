const acts: StackSelection = {
  objectName: 'Организации.Журнал',
  description: 'Журнал',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'время',
      text: 'Время',
      type: 'Time',
    },
    {
      field: 'пользователь',
      text: 'Пользователь',
      type: 'String',
    },
    {
      field: 'позиция',
      text: 'Действие',
      type: 'String',
    },
    {
      field: 'комментарий',
      text: 'Комментарий',
      type: 'String',
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
  ],
};
export default acts;
