
import Vue from 'vue';

import StackReadingCalcFlow from '@/tasks/fl/components/StackReadingCalcFlow.vue';

export default Vue.extend({
  components: { StackReadingCalcFlow },
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    ownerID: { type: [String, Number], required: true },
    dataModel: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
    title: { type: String, default: 'Показание' },
  },
  data() {
    return {
      типввода: this.record.типввода,
      ограничениерасхода: this.record.ограничениерасхода ? this.record.ограничениерасхода : 0,
      мнач: {
        ограничениерасхода: 0,
        // в ведомости показаний из бэкенда может прийти значение  @покпредсч, равное "нет"
        покпредсч: this.record['@покпредсч'] !== undefined && !isNaN(+this.record['@покпредсч']) ? +this.record['@покпредсч'] : 0,
        датпредсч: this.record['@датпредсч'] !== undefined ? this.record['@датпредсч'] : '',
        меспредсч: this.record['@меспредсч'] !== undefined ? this.record['@меспредсч'] : '',
        типпредсч: this.record['@типпредсч'] !== undefined ? this.record['@типпредсч'] : 255,
        документ: '',
        последнееизменение: '',
        текстсообщения: '',
        разрешеноредактирование: 0,
      },
      errorInPok: false,
      readonlyDialog: true,
      readonlyButton: true,
      дрразрядность: (this.record.дробнаяразрядность ? +this.record.дробнаяразрядность : 0) || 9,
      rulesThis: {
        dateIsGreaterThanOpenMonth: (date1: string): string | true => {
          return Date.parse(date1) <= Date.parse(this.$store.getters.getOpenMonth()) || !date1 || 'Нельзя указать расчетный месяц больше первого открытого!';
        },
      },
    };
  },
  created() {
    this.initDialog();
  },
  computed: {
    openMonth(): string {
      return this.$store.getters.getOpenMonth();
    },
    changesIsDisabled(): boolean {
      return this.типввода === 7 || this.readonly || this.record.тип === 2;
    },
    readonlyEnterType(): boolean {
      return this.типввода === 4 || this.типввода === 5 || this.типввода === 7;
    },
  },
  methods: {
    async initDialog() {
      if (this.dataModel && this.dataModel.executeMethod) {
        const tempObject = await this.dataModel.executeMethod('ПолучитьНачальныеДанные', {
          нзсч: this.record['объект-показания'],
          месяц: this.record['расчетный месяц'],
          дата: this.record.дата,
          тариф: this.record.тариф,
          номеруслуги: this.record['номер услуги'],
          ндок: this.record['показания-документ'],
          нпок: this.record.$номерЗаписи,
        });
        if (tempObject) {
          this.мнач = Object.assign(this.мнач, tempObject);
          this.readonlyButton = false;
          this.ограничениерасхода = this.мнач.ограничениерасхода ? this.мнач.ограничениерасхода : this.ограничениерасхода;
        }
      }
    },
    calcError(to: boolean) {
      this.errorInPok = to;
    },
    ruleErrorReading(value: boolean) {
      return value ? '' : true;
    },
    calcRashod(to: any) {
      if (to.показание !== undefined && to.показание !== null && to.показание.toString() !== '') {
        this.record.расход = to.расход && this.record.типввода !== 4 ? this.round(to.расход, this.дрразрядность) : 0;
        this.record.показание = to.показание ? to.показание : 0;
        this.calcItogRashod(this.record.расход, this.record['дополнительный расход']);
      }
    },
    checkRashod(value: number) {
      const msgRules = this.$stackRules.rulesRash({ расход: value, ограничениерасхода: this.ограничениерасхода });
      if (typeof msgRules === 'string') {
        this.$toast(msgRules, { color: 'error' });
      }
    },
    onChangeRashod(value: number) {
      this.checkRashod(value);
      this.calcItogRashod(value, this.record['дополнительный расход'], 1);
    },
    onChangeDopRashod(value: number) {
      this.calcItogRashod(this.record.расход, value, 1);
    },
    calcItogRashod(rash: number, doprash: number, emit?: number) {
      const koef = (this.record['коэффициент трансформации'] ? this.record['коэффициент трансформации'] : 1) as number;
      this.record['итоговый расход'] = this.round(+rash * koef + +doprash, this.дрразрядность);
    },
    toAllowChanges() {
      if (!this.changesIsDisabled) {
        if (this.мнач.разрешеноредактирование === 0) {
          if (this.мнач.текстсообщения) {
            this.$toast(this.мнач.текстсообщения, { color: 'error' });
          }
        } else {
          this.readonlyDialog = false;
        }
        this.readonlyButton = true;
      }
    },
    round(value: number, decimals: number) {
      const numConvert = Math.pow(10, decimals);
      return Number(Math.round(value * numConvert) / numConvert);
    },
  },
});
