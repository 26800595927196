
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: { type: Object, required: true },
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    selectedItems: { type: [Object, Array], required: true }, // array
  },
  data() {
    return {
      jobID: null,
      clearDialogVis: false,
    };
  },
  methods: {
    async onClick() {
      const guid = await new this.$HttpModel('АктыСнятияКачества').executeMethod('Рассчитать', { записи: this.selectedItems }, { async: true, jobName: 'Расчет актов качества' });
      this.$emit('updateTable');
    },
  },
});
