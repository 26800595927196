
import Vue from 'vue';

export default Vue.extend({
  name: 'LawsuitTab',
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async onAddNewRequest() {
      try {
        const service = 'contactcenter';
        const path = 'requestlog';
        let params = '';

        const resLS = await new this.$HttpModel('ЛицевыеСчета.ОбращенияФЛ').executeMethod('ПолучитьДанныеЛС', { идентификаторлс: this.record.$номерЗаписи });
        params = 'rec_номерлс=' + this.record.номер;
        params += '&rec_идентификаторлс=' + this.record.$номерЗаписи;
        params += '&rec_абонент=' + this.record['счет-наниматель>фио'];
        params += '&rec_заявитель=' + this.record['счет-наниматель>фио'];
        params += '&rec_адресдом=' + resLS.АдресДом;
        params += '&rec_адресквартира=' + resLS.Квартира;
        params += '&rec_адрескомната=' + resLS.Комната;
        params += '&rec_фиасдом=' + resLS.ФИАСДом;
        params += '&rec_участокназвание=' + resLS.УчастокНазвание;
        params += '&исполнитель=' + resLS.Исполнитель;
        params += '&участок=' + resLS.Участок;
        params += '&ид_базы=' + resLS.ид_базы;

        const resExternal = await new this.$StackApi().getExternalToken(service);
        const route = `${resExternal.addr}/${service}/${path}/new?${params}&token=${resExternal.guid}`;
        window.open(route, '_blank');
      } catch (error: AnyException) {
        this.$toast('Не удалось совершить переход !', { color: 'error' });
      }
      return true;
    },
    async openNewTab(row: StackTableRow) {
      try {
        this.loading = true;
        const service = 'contactcenter';
        const res = await new this.$StackApi().getExternalToken(service);
        const route = `${res.addr}/${service}/requestlog/${row.$номерЗаписи}?token=${res.guid}`;
        window.open(route, '_blank');
      } catch (error: AnyException) {
        this.$toast('Не удалось совершить переход !', { color: 'error' });
      } finally {
        this.loading = false;
      }
    },
  },
});
