import { TableHandlers } from '@/stackEngine';
const cishabl: StackSelection = {
  recordRoute: 'infocenter-shablonci-shablonciid',
  objectName: 'ЦентрИнформированияШаблоны',
  description: 'Шаблоны центра информирования',
  openLink: 'infocenter-shablonci',
  hier: true,
  prepareRow: item => {
    if (!item.$data) {
      item.$data = {};
    }
    // @ts-ignore
    item.$data['@транспорт'] = { $иконка: '$vuetify.icons.phone', $иконка_цвет: 'blue' };
    switch (item.транспорт) {
      case 0:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.phone', 'blue');
        break;
      case 1:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.sms', 'blue');
        break;
      case 2:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.cellphone', 'green');
        break;
      case 3:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.email', 'green');
        break;
      case 4:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.person_box', 'green');
        break;
      case 5:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.person_card', 'green');
        break;
      case 8:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.sms', 'blue');
        break;
    }
    return item;
  },
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'текст',
      text: 'Текст',
      type: 'String',
    },
    {
      field: 'комментарий',
      text: 'Комментарий',
      type: 'String',
    },
    {
      field: '@транспорт',
      text: 'Транспорт',
      type: 'String',
    },
  ],
};
export default cishabl;
