const cmonth: StackSelection = {
  objectName: 'Архив.Результатов.Проверок',
  hier: true,
  fields: [
    {
      field: 'комментарий',
      text: 'Комментарий',
      type: 'String',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'обработано',
      text: 'Обработано',
      type: 'Number',
    },
    {
      field: 'исправил',
      text: 'Исправил',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'когда исправлено',
      text: 'Время исправления',
      type: 'Date',
    },
  ],
};

export default cmonth;
