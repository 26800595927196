const Agreement: StackSelection = {
  objectName: 'ЛицевыеСчета.СоглашенияОРассрочке',
  description: 'Соглашения о рассрочке',
  recordRoute: 'documents-agreement-agreementid',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Number',
    },
    {
      field: 'статус',
      text: 'Статус',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: '@фильтр',
      text: 'Срок',
      type: 'Number',
    },
  ],
};

export default Agreement;
