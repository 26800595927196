import { StackSelectionCache } from '../../utils';
import { StackApi } from './StackApi';

export class GraphqlModel implements DataModel {
  public model: string;
  private sDef: StackSelection | undefined;
  private http: StackApi;
  private data: StackTableRow[] = [];
  public prepareRow: any;

  get description(): string {
    return this.sDef && this.sDef.description ? this.sDef.description : '';
  }

  get hier(): boolean {
    return this.sDef && this.sDef.hier ? this.sDef.hier : false;
  }

  get fields(): StackField[] {
    return this.sDef && this.sDef.fields ? this.sDef.fields : [];
  }

  get length(): number {
    return 0;
  }

  constructor(model: string) {
    this.model = model;
    this.sDef = StackSelectionCache.getSelectionDefinition(model);
    this.http = new StackApi();
    if (this.sDef && this.sDef.prepareRow) {
      this.prepareRow = this.sDef.prepareRow;
    }
  }

  // public async executeMethod(methodName: string, params?: object, options?: MetodOptions): Promise<any> {
  // }

  public async getRecords(params?: StackHttpRequestTaskParam): Promise<StackTableRow[]> {
    const query = this.sDef?.methods?.getRecords;
    if (!query) return this.data;
    this.data = [];
    const rec = await this.http.graphql(query, params);
    if (rec) this.data = rec;
    if (this.prepareRow) {
      this.data.forEach((row: StackTableRow) => {
        this.prepareRow(row);
      });
    }
    return this.data;
  }

  public async initRecord() {
    return {};
  }

  public async executeQuery(query: string, variables?: any) {
    return await this.http.graphql(query, variables);
  }

  public async saveRecord(record: StackTableRow): Promise<boolean> {
    return true;
  }

  public async createRecord(record: StackTableRow): Promise<number | null> {
    try {
      const query = this.sDef?.methods?.createRecord;
      if (!query) return null;
      return await this.http.graphql(query, record);
    } catch (error: AnyException) {
      Promise.reject(error);
      return null;
    }
  }

  public async deleteRecords(records: StackTableRow[]) {
    try {
      const query = this.sDef?.methods?.deleteRecord;
      if (!query) return false;
      for (const record of records) {
        await this.http.graphql(query, record);
      }
      return true;
    } catch (error: AnyException) {
      return false;
    }
  }
}