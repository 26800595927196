
import Vue from 'vue';
import historyList from './$dialogs/historyList.vue';
import lsSearch from './$dialogs/lsSearch.vue';

export default Vue.extend({
  components: { historyList, lsSearch },
  data() {
    return {
      cacheRecs: [] as StackTableRow[],
    };
  },
  created() {
    this.cacheRecs = this.$store.getters.getSyncCache('stack_dialog_ОкноОператораЛС') || [];
  },
});
