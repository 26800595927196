
import Vue from 'vue';
import vialationdialog from './$dialogs/violationdialog.vue';

export default Vue.extend({
  components: { vialationdialog },
  props: {
    violationsactsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
    readonly: { type: [Boolean, String], default: false },
  },
  data() {
    return {
      readonlystate: !!this.readonly, // TODO при переключении вкладок readonly теряется
      record: {} as StackTableRow,
      dialog: false,
      flagTarif: ['Распределить по тарифам'],
      flagMonth: ['Распределить по месяцам'],
      headers: [
        {
          field: 'исполнители-сотрудник',
          text: '',
          type: 'Link',
          labelField: 'фио',
          noFolder: true,
          dataModel: 'Сотрудники',
          headers: 'фио,табномер,телефон,имя,флаги,вход,хост,процесс',
        },
      ],
      calcTableIsNotEmpy: false,
      disableAddCalcTable: false,
      loading: false,
    };
  },
  computed: {
    title(): string {
      return `Акт нарушения абонента`;
    },
    titleBtn(): string {
      if (!this.record['окончания срок']) {
        return `Отменить акт`;
      } else {
        return `Применить акт`;
      }
    },
    textCancel(): string {
      if (!this.record['окончания срок']) {
        return ``;
      } else {
        const окончанияСрок = this.record['окончания срок'] as string;
        return `Акт отменен ${this.$stackDate.format(окончанияСрок, 'DD.MM.YY')}`;
      }
    },
  },
  methods: {
    isLinked(value: number): string | true {
      return (value !== undefined && value !== null && value >= 0) || 'Выберите услугу';
    },
    onSave(items: any) {
      let sum = 0;
      let value = 0;
      this.calcTableIsNotEmpy = items.length > 0;

      // @ts-ignore
      items.forEach(({ суммаакта, объемакта }) => {
        sum += суммаакта;
        value += объемакта;
      });
      this.record['сумма'] = sum;
      this.record['объем'] = parseFloat(value.toFixed(3));
    },
    async calcAct() {
      this.loading = true;
      if (this.record.$номерЗаписи === -1) {
        // @ts-ignore перед расчетом (создание записей по услугам) надо сохранить документ
        await this.$refs.dialog.createRecord();
      } else {
        // @ts-ignore перед расчетом (создание записей по услугам) надо сохранить документ
        await this.$refs.dialog.saveRecord();
      }
      try {
        await new this.$HttpModel('АктыНарушенийАбонентов').executeMethod('Рассчитать', { номерЗаписи: this.record.$номерЗаписи });
        // @ts-ignore
        this.$refs.calcTable.fetchData();
      } catch (error: AnyException) {
        this.$toast(error.message, { color: 'error' });
      }
      this.loading = false;
    },
    async cancelAct() {
      this.loading = true;
      try {
        await new this.$HttpModel('АктыНарушенийАбонентов').executeMethod('Отменить', { номерЗаписи: this.record.$номерЗаписи });
        // @ts-ignore
        this.$refs.dialog.fetchData();
      } catch (error: AnyException) {
        this.$toast(error.message, { color: 'error' });
      }
      this.loading = false;
    },
    changeFunc(item: StackTableRow) {
      const isItem = item['исполнители-сотрудник'] === undefined || item['исполнители-сотрудник'] === null ? -1 : item['исполнители-сотрудник'];
      if (isItem && isItem > 0) {
        if (item.фио !== undefined) {
          item.$номерзаписи = item.$номерЗаписи;
          new this.$HttpModel('АктыНарушенийАбонентов.Исполнители').saveRecord(item);
        } else {
          new this.$HttpModel('АктыНарушенийАбонентов.Исполнители').createRecord(item, { владелец: this.violationsactsid });
        }
      } else {
        alert('Необходимо выбрать исполнителя!');
        // @ts-ignore
        this.$refs.inline.fetchData();
      }
    },
  },
});
