
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'Поставщик' },
    onlyServiceProvider: { type: Boolean, default: false },
    hideDate: { type: Boolean, default: false },

  },
  computed: {
    disabledDebts(): boolean {
      return !(
        this.record.принимаетвседолги !== 0 &&
        (this.record.примечание === '' || this.record['@долгдатнач'] === '' || this.record['@долгдаткнц'] === '' || this.record.$номерЗаписи === -1)
      );
    },
  },
});
