
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: '@Услуга',
          text: 'Название',
          type: 'String',
          readonly: true,
        },
        {
          field: '@ВН',
          text: 'Тариф',
          type: 'Number',
          width: '100px',
        },
        {
          field: '@СН1',
          text: 'Тариф сверх СОН',
          type: 'Number',
          width: '100px',
        },
      ],
    };
  },
});
