
import Vue from 'vue';
import addressFilter from '@/tasks/fl/views/$common/filters/addressFilter.vue';

export default Vue.extend({
  components: { addressFilter },
  data() {
    return {
      item: {
        нп: null,
        улица: null,
        дом: null,
        квартира: null,
        фионанимателя: null,
        гис_елс: null,
        номерлс: null,
        стороннийномер: null,
      } as any,
      loading: false,
    };
  },
});
