
import Vue from 'vue';
import paramDialog from '@/tasks/common/views/$common/paramDialog.vue';

export default Vue.extend({
  components: { paramDialog },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    max3tar(): string | true {
      return this.record.тарифность && this.record.тарифность >= 1 && this.record.тарифность <= 3 ? true : 'Некорректная тарифность';
    },
    korRaz(): string | true {
      return this.record['счетчика разрядность'] && this.record['счетчика разрядность'] >= 3 && this.record['счетчика разрядность'] <= 8 ? true : 'Введите корректное значение разрядности от 3 до 8';
    },
    title(): string {
      let title = 'Номенклатура';
      switch (this.record.идентификатор) {
        case 0:
          title = 'Счетчик';
          break;
        case 1:
          title = 'Трансформатор тока';
          break;
        case 2:
          title = 'Трансформатор силовой';
          break;
        case 3:
          title = 'Трансформатор напряжения';
          break;
        default:
          break;
      }
      return title;
    },
    name_field(): string {
      return (this.marka_field || '') + '  ' + (this.model_field || '');
    },
    marka_field: {
      get() {
        const naim = this.record.наименование as string;
        const marka: string = this.razbMarkaModel(naim).marka || '';
        return marka;
      },
      set(newValue: string) {
        if (newValue !== undefined) {
          this.record.наименование = newValue.trim() + '  ' + this.model_field;
        }
      },
    },
    model_field: {
      get() {
        const naim = this.record.наименование as string;
        const model: string = this.razbMarkaModel(naim).model || '';
        return model;
      },
      set(newValue: string) {
        if (newValue !== undefined) {
          this.record.наименование = this.marka_field + '  ' + newValue.trim();
        }
      },
    },
  },
  methods: {
    razbMarkaModel(naim: string) {
      interface SomeObject {
        marka: string;
        model: string;
      }
      const objMarkaModel: SomeObject = { marka: '', model: '' };
      if (typeof naim !== 'undefined') {
        const arrIPUName = naim.split('  ');
        objMarkaModel.marka = arrIPUName[0].trim();
        delete arrIPUName[0];
        objMarkaModel.model = arrIPUName.join('  ').trim();
      }
      return objMarkaModel;
    },
  },
});
