
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    params: { type: Object, required: true },
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    selectedValues: { type: [String, Number], default: undefined },
    selectedItems: { type: Array as PropType<StackTableRow[]>, default: undefined },
  },
  data() {
    return {
      rec: {},
      calcid: null,
      Dial: false,
      loading: false,
      typeDate: '',
      newDate: null as null | string,
      items: [] as any,
    };
  },
  async created() {
    const result = await new this.$HttpModel('ТипыНоменклатур').getRecords();
    this.items = [];
    result.forEach((element: any) => {
      this.items.push({ title: element.значение, type: element.ключ });
    });
  },

  methods: {
    onBtnClick(type: number) {
      const payload = { идентификатор: type };
      this.tableAction({ action: 'add', payload });
    },

    changeDate(type: string) {
      if (String(this.selectedValues).indexOf(',') > 0) {
        this.$toast('Изменять дату установки или снятия можно изменять только по одному прибору учета!', { color: 'error' });
        return;
      }
      this.Dial = true;
      this.typeDate = type;
      if (this.selectedItems && this.selectedItems[0]) {
        this.newDate = type === 'inst' ? this.selectedItems[0].датнач as string : this.selectedItems[0].даткнц as string;
      }
    },
    async runChange() {
      this.Dial = false;
      const obj = this.dataObject as DataModel;
      if (obj.executeMethod) {
        try {
          this.loading = true;
          await obj.executeMethod('Сменить', { номерЗаписи: this.selectedValues, тип: this.typeDate, дата: this.newDate });
          this.tableAction({ action: 'unselect-all' });
          this.tableAction({ action: 'reload' });
        } catch (error: AnyException) {
          if (!error.message) {
            this.$toast('Ошибка смены даты !', { color: 'error' });
          }
        } finally {
          this.loading = false;
        }
      }
    },
  },
});
