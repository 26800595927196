
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'Показание' },
  },
  data() {
    return {
      items: [],
      rules: Object.assign(this.$stackRules, {
        checkReading: (val: number, int: number, fact: number): string | true => {
          // проверка разрядности, выделим целую и дробную часть
          let integer, factorial;
          if (val && val < 0) {
            return 'Нельзя вводить отрицательное показание!';
          }
          if (!val && val !== 0) {
            return 'Вы не ввели показание!';
          }
          // TODO
          // eslint-disable-next-line prefer-const
          [integer, factorial] = val.toString().split('.');
          if (integer && int && integer.length > int) {
            return 'Вы превысили разрядность счетчика!';
          }
          if (factorial && fact && factorial.length > fact) {
            return 'Вы превысили дробную разрядность счетчика!';
          }
          return true;
        },
      }),
      menux: 0,
      menuy: 0,
      askdata: {
        visible: false,
        answer: null,
        variants: ['Перекрутка счетчика', 'Отрицательный расход', 'Ошибка оператора'],
      } as any,
      isLoading: false,
      isMounted: false, // Флаг показывает отрисовался ли компонент, иначе watcher может инициализировать ещё проверки
    };
  },
  created() {
    if (this.record['@зона'] !== '') this.items = JSON.parse(this.record['@зона']);
  },
  async mounted() {
    if (this.record && this.record['контроль-показание'] <= 0) {
      await this.checkReding(true);
    }
    this.isMounted = true;
  },
  computed: {
    isNewRecord(): boolean {
      return this.record.$номерЗаписи === 'new' || this.record.$номерЗаписи === -1;
    },
    disButton(): boolean {
      return (
        (this.record['флагошибок'] !== 0 &&
          this.record['флагошибок'] !== 2 &&
          this.record['флагошибок'] !== 4 &&
          this.record['флагошибок'] !== 8192 &&
          (this.record['флагошибок'] !== 4096 || (this.record['типввода'] !== 0 && this.record['типввода'] !== 6))) ||
        this.record['контроль-показание'] > 0 ||
        this.isNewRecord
      );
    },
    LableNumb(): string {
      return this.record['типпоказаний'] === 1 ? 'Документ' : 'Лицевой счет';
    },
    DataLinkCounter(): string {
      return this.record['типпоказаний'] === 1 ? 'ГрупповыеСчетчики.Счетчики' : 'ЛицевыеСчета.ОбъектыЛицевого';
    },
    OwnerLinkCounter(): number {
      return this.record['типпоказаний'] === 1 ? this.record['документгрупповой-контроль'] : undefined;
    },
    DataModelFlagsErrors(): string {
      return this.record['типпоказаний'] === 1 ? 'ВедомостиСтороннихПоказаний.ФлагиФильтраОДПУ' : 'ВедомостиСтороннихПоказаний.ФлагиФильтра';
    },
    readonly(): boolean {
      return this.record['контроль-показание'] > 0;
    },
    filterCounters(): any {
      return this.record['типпоказаний'] === 1 ? {} : { лицевой: this.record['контроль-счет'], фвыводитьипу: true, фвыводитьокпу: true, дата: this.record.дата };
    },
    headersCounters(): string {
      return this.record['типпоказаний'] === 1
        ? 'заводскойномер,номенклатура-объекты>наименование,объект-услуга>номер услуги,объект-услуга>наименование,датнач,датаследующейповерки'
        : 'заводскойномер,номенклатура>наименование,услуга>номер услуги,услуга>наименование,датнач,датаследующейповерки,тип';
    },
    // поля, при которых надо делать проверку сторонних показаний, чтобы избежать лишних запросов на бэк
    fieldsForCheckReadings(): Object {
      // если показание уже имеет ссылку с обычным - то ничего не делаем
      if (this.record['контроль-показание'] > 0) { return {}; } else {
        return {
          mainLink: this.record['типпоказаний'] !== 1 ? this.record['контроль-счет'] : this.record['документгрупповой-контроль'],
          counter: this.record['объект-контроль'],
          reading: this.record.показание,
          date: this.record.дата,
          month: this.record['расчетный месяц'],
          type: this.record.типввода,
          tar: this.record.тариф,
        };
      }
    },
  },
  methods: {
    async checkReding(updateLinks: boolean) {
      // Если ещё предыдущая проверка не закончилась,из-за $set, то не стоит запускать проверку по новой. так как watcher её инициализирует
      if (!this.isLoading) {
        this.isLoading = true;
        this.$emit('save', this.record);
        const master = new this.$HttpModel('ВедомостиСтороннихПоказаний.Ведомость');
        const result = await master.executeMethod('Проверить', { номерЗаписи: this.record.$номерЗаписи, запись: this.record, игнорироватьТекстовыеПоля: !updateLinks });
        this.$set(this.record, 'флагошибок', result.флагошибок);
        if (updateLinks) {
          if (result['контроль-счет'] && this.record['контроль-счет'] !== result['контроль-счет']) {
            this.$set(this.record, 'контроль-счет', result['контроль-счет']);
          }
          if (result['документгрупповой-контроль'] && this.record['документгрупповой-контроль'] !== result['документгрупповой-контроль']) {
            this.$set(this.record, 'документгрупповой-контроль', result['документгрупповой-контроль']);
          }
          if (result['объект-контроль'] && this.record['объект-контроль'] !== result['объект-контроль']) {
            this.$set(this.record, 'объект-контроль', result['объект-контроль']);
          }
          const lastReadings = { ...result.предпок };
          if (Object.keys(lastReadings).length > 0) {
            if (lastReadings['@покпредсч'] && lastReadings['@покпредсч'] !== this.record['@покпредсч']) {
              this.$set(this.record, '@покпредсч', lastReadings['@покпредсч']);
            }
            if (lastReadings['@датпредсч'] && lastReadings['@датпредсч'] !== this.record['@датпредсч']) {
              this.$set(this.record, '@датпредсч', lastReadings['@датпредсч']);
            }
            if (lastReadings['@меспредсч'] && lastReadings['@меспредсч'] !== this.record['@меспредсч']) {
              this.$set(this.record, '@меспредсч', lastReadings['@меспредсч']);
            }
            if (lastReadings['@типпредсч'] && lastReadings['@типпредсч'] !== this.record['@типпредсч']) {
              this.$set(this.record, '@типпредсч', lastReadings['@типпредсч']);
            }
          }
          if (result['расход'] && this.record['расход'] !== result['расход']) {
            this.$set(this.record, 'расход', result['расход']);
          }
        }
        this.isLoading = false;
      }
    },
    async sendToCalc() {
      if (this.record['расход'] === 0) {
        this.calcRashod();
      }
      if (!this.askdata.visible) {
        try {
          this.isLoading = true;
          const master = new this.$HttpModel('ВедомостиСтороннихПоказаний.Ведомость');
          // сначала проверим на флаг ошибок, так как данные могли имениться
          const result = await master.executeMethod('Проверить', { номерЗаписи: this.record.$номерЗаписи, запись: this.record });
          this.$set(this.record, 'флагошибок', result.флагошибок);
          const res_send = await master.executeMethod('Отправить', { номерЗаписи: this.record.$номерЗаписи, запись: this.record });
          if (res_send === 1) {
            // @ts-ignore
            this.$refs.reading.onClose();
            this.$sendMsg('stack-table', 'ВедомостиСтороннихПоказаний.Ведомость', 'reload');
          }
        } finally {
          this.isLoading = false;
        }
      }
    },
    openLS() {
      const lsID = this.record['контроль-счет'];
      const routeData = this.$router.resolve({ path: `/accounts/${lsID}` });
      open(routeData.href, '_blank');
    },
    openDoc() {
      const idDoc = this.record['документгрупповой-контроль'];
      const routeData = this.$router.resolve({ path: `/metersdata/groupcounters/${idDoc}` });
      open(routeData.href, '_blank');
    },
    calcRashod() {
      const reading = this.record.показание;
      const lastreading = this.record['@покпредсч'];
      let volume = +(reading - lastreading).toFixed(3);
      let koef = 1;
      if (this.record['объект-контроль>коэффициент трансформации']) {
        koef = Number(this.record['объект-контроль>коэффициент трансформации']);
      }
      if (volume < 0 && +reading >= 0) {
        // @ts-ignore
        const pos = this.$refs.reading_pok.$el.getBoundingClientRect();
        this.menux = pos.left + pos.width / 2;
        this.menuy = pos.top;
        // спросим пользователя что это он ввел
        this.askdata.visible = true;
        const timer = setInterval(() => {
          if (!this.askdata.visible) {
            clearInterval(timer);
            switch (this.askdata.answer) {
              case 0: {
                const max = this.record['объект-контроль>разрядность'] ? Math.pow(10, Number(this.record['объект-контроль>разрядность'])) : Math.pow(10, 9);
                volume = max - lastreading + reading;
                break;
              }
              case 2: {
                this.record.показание = lastreading;
                volume = 0;
                break;
              }
              default: {
                // Пока ждём, показание может измениться
                const reading = this.record.показание;
                volume = +(reading - lastreading).toFixed(3);
                break;
              }
            }
            this.record.расход = volume;
            this.record.итоговыйрасход = this.record.расход * koef + this.record.допрасход;
          }
        }, 500);
      } else {
        this.askdata.visible = false;
      }
      this.record.расход = volume;
      this.record.итоговыйрасход = this.record.расход * koef + this.record.допрасход;
    },
    async onChangeCounters() {
      const master = new this.$HttpModel('ВедомостиСтороннихПоказаний.Ведомость');
      await master.saveRecord(this.record, { новоеПоказание: this.record.показание });
      this.$sendMsg('stack-dialog', 'ВедомостиСтороннихПоказаний.Ведомость', 'reload');
    },
    checkValue() {
      return !(this.record.расход === 0 && this.record.показание === 0 && this.record['объект-контроль'] > 0) || 'показание и расход = 0';
    },
  },
  watch: {
    fieldsForCheckReadings: {
      deep: true,
      handler() {
        if (this.isMounted && this.record['контроль-показание'] <= 0) {
          this.checkReding(false);
        }
      },
    },
  },
});
