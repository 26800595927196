const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.РасчетЗаПериод',
  description: '',
  fields: [
    {
      field: 'начислено',
      text: 'Итого,₽',
      type: 'Money',
    },
    {
      field: 'объем',
      text: 'Итого,V',
      type: 'Number',
    },
    {
      field: 'коррекция',
      text: 'Коррекция\n  сальдо',
      type: 'Money',
    },
    {
      field: 'вхсальдо',
      text: 'Входящее сальдо,₽',
      type: 'Money',
    },
    {
      field: 'оплачено',
      text: 'Оплата,₽',
      type: 'Money',
    },
    {
      field: 'исхсальдо',
      text: 'ИсхСальдо,₽',
      type: 'Money',
    },
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    // {
    //   field: 'показание',
    //   text: 'Показание',
    //   type: 'Number',
    // },
    // {
    //   field: 'объемнорматив',
    //   text: 'Объем норматив,V',
    //   type: 'Money',
    // },
    // {
    //   field: 'объемсверх',
    //   text: 'Объем сверхнормы,V',
    //   type: 'Money',
    // },
    // {
    //   field: 'объемодн',
    //   text: 'в т.ч. ОДН,V',
    //   type: 'Number',
    // },
    {
      field: 'тариф',
      text: 'Тариф,₽',
      type: 'Money',
    },
    // {
    //   field: 'тарифсверх',
    //   text: 'Начислено сверхнормы,₽',
    //   type: 'Money',
    //   width: '75px',
    // },
    {
      field: 'услуга',
      text: 'Услуга',
      type: 'String',
      width: '300px',
    },
  ],
};
export default accs;
