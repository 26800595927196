
import Vue from 'vue';

export default Vue.extend({
  props: {
    files: { type: Array, required: true, default: undefined },
  },
  computed: {
    filesWithIcon(): StackResultAsFile[] {
      const filesI: StackResultAsFile[] = [];
      for (const i in this.files) {
        const file: any = this.files[i] as StackResultAsFile;
        file.icon = '$vuetify.icons.async_attach';
        filesI.push(file);
      }
      return filesI;
    },
  },
  methods: {
    onClick(file: StackResultAsFile) {
      window.open(this.$store.getters.getApiHostShare() + file.fileUrl);
    },
  },
});
