
import Vue from 'vue';
import { ReportsBuilder } from '@/stackEngine';
import StackTableHeadersSelectDialog from './StackTableHeadersSelectDialog.vue';

function headerWidthForPrint(header: StackField) {
  switch (header.type) {
    case 'String':
      return 200;
  }
  return 80;
}

export default Vue.extend({
  components: {
    StackTableHeadersSelectDialog,
  },
  inject: {
    isDialogReadonly: { default: () => false },
  },
  props: {
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    selectedItems: { type: Array, required: true },
    selectedItemsOnPage: { type: Array, required: true },
    headers: { type: Array, required: true },
    allHeadersDef: { type: [Object], required: true }, // описание всех полей выборки
    hier: { type: Boolean, default: false },
    noRowAction: { type: Boolean, default: false },
    // noCopy: { type: Boolean, default: false },
    params: { type: Object, default: undefined },
    description: { type: String, default: 'Таблица без названия' },
    items: { type: Array, default: [] as StackTableRow[] },
    expandA: { type: Boolean, default: false },
    /**
     * Печать экрана данными с фронта, а не выборки с бека
     * Нужно, когда вычисляемые поля на фронте, а на беке их нет
     */
    printContext: { type: Boolean, default: false },
  },
  computed: {
    countSelected(): number {
      // @ts-ignore
      return this.selectedItemsOnPage.length;
    },
    headersForPrint(): [] {
      const headsArr = [] as any;
      const headers: StackField[] = this.headers as StackField[];
      headers.forEach((header: any) => {
        // Для печати экрана с бека скрываем вычисляемые на фронте поля
        if (!header.computed || this.printContext) {
          headsArr.push({
            name: header.field,
            title: header.text,
            width: headerWidthForPrint(header),
            type: header.type,
          });
        }
      });
      return headsArr;
    },
    menuItems() {
      const items = [{ title: 'Обновить таблицу', icon: '$vuetify.icons.reload', id: 0 }];
      if (this.hier) {
        items.push({ title: `${this.expandA ? 'Свернуть' : 'Развернуть'} папки`, icon: '$vuetify.icons.expand_folders', id: 2 });
      }
      items.push({ title: `Печать экрана`, icon: '$vuetify.icons.printer', id: 4 });
      items.push({ title: `Поля`, icon: '$vuetify.icons.table_fields', id: 6 });
      return items;
    },
  },
  data() {
    return {
      fieldDialVisible: false,
      headersCheck: {} as any,
    };
  },
  methods: {
    onClick(index: number) {
      const dataObject: DataModel = this.dataObject as DataModel;
      switch (index) {
        case 0:
          this.tableAction({ action: 'reload' });
          break;
        case 2:
          this.$emit('update:expandA', !this.expandA);
          break;
        case 4:
          if (dataObject) {
            const format = 'xlsx';
            const params = { поляДляПечати: this.headersForPrint, формат: format, ...this.params };
            if (dataObject.model !== 'static' && !this.printContext && dataObject.executeMethod) {
              dataObject.executeMethod('печать', params, { async: true, jobName: `Печать экрана в ${format}: ${this.description}`, type: 'report' });
            } else {
              new ReportsBuilder('ЛицевыеСчета').executeReport2('ПечатьЭкрана', { записи: this.items }, { title: `Печать экрана в ${format}: ${this.description}` });
            }
          }
          break;
        case 6:
          this.fieldDialVisible = true;
          break;
        default:
          this.$toast('В разработке');
      }
    },
  },
});
