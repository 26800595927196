
import Vue from 'vue';

import GroupFilter from './$components/GroupFilter.vue';

export default Vue.extend({
  components: { GroupFilter },
  data() {
    return {
      dataModel: 'ГрупповыеСчетчики.ВводПоказаний',
    };
  },
  methods: {
    beforeFetch(payload: any) {
      return new Promise((resolve, reject) => {
        new this.$HttpModel(this.dataModel).executeMethod('Заполнить', payload, {
          async: true,
          jobName: 'Заполнение выборки',
          silent: true,
          external: true,
          onComplete: () => {
            resolve(true);
          },
          onError: err => {
            this.$toast(err.status, { color: 'error' });
            reject(err.status);
          },
        });
      });
    },
    applyFilter(payload: any) {
      // @ts-ignore
      this.$refs.table.applyFilter(payload);
    },
  },
});
