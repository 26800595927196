
import Vue from 'vue';

export default Vue.extend({
  props: {
    message: { type: String, required: true },
    title: { type: String, default: '' },
    options: {
      type: Object,
      default: (): any => {
        return undefined;
      },
    },
  },
  computed: {
    maxWidth(): string | number {
      return this.options && this.options.width ? this.options.width : '800px';
    },
  },
  methods: {
    onBtnClick(isOk: boolean) {
      this.$emit('stack-wrapper:close', isOk);
    },
  },
});
