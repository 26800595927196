
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    dateType: { type: String, default: 'date' },
  },
  methods: {
    onChangeDateEnd() {
      if (this.dateType === 'month') {
        this.record['даткнц'] = this.$stackDate.lastDay(this.record['даткнц']);
      }
    },
  },
});
