
import Vue from 'vue';

export default Vue.extend({
  props: {
    message: { type: String, required: true },
    title: { type: String, default: '' },
    inputValue: { type: [String, Number], default: null },
    options: {
      type: Object,
      default: (): any => {
        return undefined;
      },
    },
  },
  computed: {
    maxWidth(): string | number {
      return this.options && this.options.width ? this.options.width : '800px';
    },
    type(): string {
      return this.options && this.options.type ? this.options.type : 'string';
    },
    dateType(): string {
      return this.options && this.options.dateType ? this.options.dateType : 'date';
    },
  },
  data() {
    return {
      inputResult: this.inputValue,
    };
  },
  methods: {
    onBtnClick() {
      this.$emit('stack-wrapper:close', this.inputResult);
    },
    onClose() {
      this.$emit('stack-wrapper:error', 'close');
    },
  },
});
