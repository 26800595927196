import { TableHandlers, store } from '@/stackEngine';
export default {
  objectName: 'ЛицевыеСчета.Параметры',
  description: 'Параметры лицевых счетов',
  prepareRow: row => {
    TableHandlers.isClosed(row, store.getters.getWorkMonth());

    return row;
  },
  fields: [
    {
      field: 'виды-параметры',
      text: 'виды-параметры',
      type: 'Link',
    },
    {
      field: 'виды-параметры>название',
      text: 'Краткое название',
      type: 'String',
    },
    {
      field: 'виды-параметры>наименование',
      text: 'Параметр',
      type: 'String',
    },
    {
      field: 'строка',
      text: 'Значение',
      type: 'String',
      align: 'right',
      maxwidth: '300px',
      computed: true,
    },
    {
      field: 'датнач',
      text: 'Дата начала',
      type: 'Date',
      align: 'right',
    },
    {
      field: 'даткнц',
      text: 'Дата окончания',
      type: 'Date',
      align: 'right',
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
      align: 'right',
    },
    {
      field: 'знач2',
      text: 'Знач2',
      type: 'String',
    },
    {
      field: 'знач3',
      text: 'Знач3',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
  ],
} as StackSelection;
