
import Vue from 'vue';
import CycleDialog from './$calctabs/$dialogs/CycleDialog.vue';

export default Vue.extend({
  name: 'BalanceInfo',
  components: { CycleDialog },
  props: {
    record: { type: Object, required: true },
    calcLs: { type: Boolean, default: false },
  },
  data() {
    return {
      cycleDialogVis: false,
      http: new this.$HttpModel('ЛицевыеСчета.Баланс'),
      loading: false,
      item: {} as StackTableRow,
      datef: this.$options.filters
        ? this.$options.filters.date
        : (val: any) => {
            return val;
          },
    };
  },
  computed: {
    total(): number {
      return this.item.коплатевсего ? Math.abs(+this.item['коплатевсего']) : 0;
    },
    // totalPeni(): number {
    //   return this.item.коплатепени ? Math.abs(+this.item['коплатепени']) : 0;
    // },
    titleBalance(): string {
      if (this.item && this.item.коплатевсего) {
        return this.item.коплатевсего > 0 ? 'К оплате' : 'Переплата';
      } else {
        return '';
      }
    },
    items(): any {
      return [
        { title: `Долг на начало ${this.datef(this.workMonth, 'MMMM')}`, value: this.item.входсальдо },
        { title: 'в том числе пени', value: this.item.входпени },
        { title: 'Начислено', value: this.item.начислено },
        { title: 'Перерасчет', value: this.item.перерасчет },
        { title: 'Коррекция', value: this.item.коррекция },
        { title: 'Начислено пени', value: this.item.пениначислено },
        { title: 'Оплачено', value: this.item.оплатапотекдатвсего },
      ];
    },
    workMonth(): Date {
      return (this.record.закрытыймесяц > this.record['месяц блокировки'] ? this.record.закрытыймесяц : this.record['месяц блокировки']) || this.$store.getters.getCloseMonth();
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      if (this.record && this.record.$номерЗаписи > 0) {
        const items = await this.http.getRecords({ владелец: this.record.$номерЗаписи, рассчитатьлс: this.calcLs, фильтр: { месНач: this.workMonth, месКнц: this.workMonth } });
        if (items.length > 0) {
          this.item = items[0];
        }
      }
      this.loading = false;
    },
  },
  watch: {
    record: {
      immediate: true,
      handler(to, old) {
        if (to?.$номерЗаписи !== old?.$номерЗаписи) {
          this.fetchData();
        }
      },
    },
    // workMonth() {
    //   this.fetchData();
    // },
  },
});
