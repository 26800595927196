
import Vue from 'vue';

export default Vue.extend({
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      времянач: undefined as undefined | string,
      времякнц: undefined as undefined | string,
    };
  },
  methods: {
    onChange() {
      this.record.значение = JSON.stringify({ ВРЕМЯНАЧ: this.времянач, ВРЕМЯКНЦ: this.времякнц });
    },
  },
  watch: {
    record: {
      immediate: true,
      handler(to) {
        const objectValue = JSON.parse(this.record.значение as string);
        this.времянач = objectValue['ВРЕМЯНАЧ'];
        this.времякнц = objectValue['ВРЕМЯКНЦ'];
      },
    },
  },
});
