
import Vue from 'vue';
import StackAudio from './StackAudio.vue';

/**
 * компонент-медиа (изображение или аудио), который забирает данные с бэкэнда и отрисовывает
 */
export default Vue.extend({
  name: 'StackMedia',
  components: { StackAudio },
  props: {
    /**
     * номер записи владельца выборки
     */
    ownerID: { type: [Number, String], default: null },
    /**
     * row_id записи, которую нужно получить с бэкэнда
     */
    id: { type: [Number, String], default: null },
    /**
     * модель данных
     */
    dataModel: { type: [String, Object], required: true },
    /**
     * название поля
     */
    nameField: { type: [String], default: 'имя_файла' },
    /**
     * размер изображения
     */
    size: { type: [String, Number], required: true },
    /**
     * отображать или нет кнопки для загрузки\удаления изображения
     */
    showButtons: { type: Boolean, default: false },
    /**
     * объект с кастомными параметрами в запрос
     */
    params: { type: [Object], default: null },
  },
  data() {
    return {
      dataObject: {} as DataModel,
      file: {} as StackTableRow,
      fileUrl: null as string | null,
      unwatchHandler: undefined as any,
      loading: false,
      progress: 0,
      imgLoadError: false,
      imgLoadOk: false,
      imgFull: false,
    };
  },
  computed: {
    isLoaded(): boolean {
      return !!this.fileUrl && !this.loading;
    },
    apiHostShare(): string {
      return this.$store.getters.getApiHostShare();
    },
    imgUrl(): string | null {
      return this.fileUrl ? `${this.apiHostShare}${this.fileUrl}` : null;
    },
    extension(): string | undefined {
      if (this.fileUrl) {
        const arrName = this.fileUrl.split('.');
        return arrName.length > 1 ? arrName.pop() : '';
      }
      return '';
    },
    isImage(): boolean {
      const res = this.extension ? this.extension.toLowerCase() : undefined;
      return res === 'png' || res === 'jpg' || res === 'bmp' || res === 'gif' || res === 'tiff';
    },
    isAudio(): boolean {
      const res = this.extension;
      return res === 'mp3' || res === 'wav';
    },
    apiHostUpload(): string {
      return this.$store.getters.getApiHostUpload();
    },
    queryParams(): any {
      return {
        номерЗаписи: this.id ? this.id : undefined,
        владелец: this.ownerID ? this.ownerID : undefined,
        ...this.params,
      };
    },
  },
  created() {
    if (typeof this.dataModel === 'string') {
      this.dataObject = new this.$HttpModel(this.dataModel);
    } else {
      this.dataObject = this.dataModel;
    }
    this.unwatchHandler = this.$watch('queryParams', this.fetchData, { immediate: true });
  },
  beforeDestroy() {
    this.unwatchHandler();
  },
  methods: {
    onFileSelect() {
      if (this.showButtons) {
        // @ts-ignore
        const input = this.$refs.upload.$el.querySelector('input');
        input.click();
      }
    },
    async fetchData() {
      if (!this.dataObject.executeMethod) {
        return;
      }
      if (+this.ownerID > 0 || +this.id > 0) {
        try {
          const data = await this.dataObject.executeMethod('получитьФайл', this.queryParams);
          if (data && data.fileUrl) {
            this.fileUrl = data.fileUrl;
          }
        } catch (error: AnyException) {
          console.log('StackMedia: нет файла');
        }
      }
    },
    // async onInput(files: any) {
    //   const newFile = files[0];
    //   if (newFile === undefined) {
    //     return;
    //   }
    //   const http = new StackApi();
    //   http.clear();
    //   const taskID = http.fetch(newFile.name, {}, 'загрузить', 'файл') as number;
    //   await http.run();
    //   const data = http.getTaskResult(taskID);
    //   if (data && data.fileId) {
    //     newFile.name = data.fileId;
    //   }
    // },
    async onUpload(newFile: any, oldFile: any) {
      if (!this.dataObject.executeMethod) {
        return;
      }
      try {
        // начало загрузки
        if (newFile && !oldFile) {
          this.loading = true;
          this.progress = 0;
          this.fileUrl = null;
          // @ts-ignore
          this.$refs.upload.active = true;
        }
        // Идёт загрузка
        if (newFile && oldFile) {
          // Прогресс
          if (newFile.progress !== oldFile.progress && newFile.active) {
            this.progress = newFile.progress;
          }

          // загрузка завершена
          if (newFile.success !== oldFile.success) {
            await this.dataObject.executeMethod('сохранитьФайл', { fileId: newFile.name, ...this.queryParams });
            await this.fetchData();
            this.loading = false;
          }
        }
      } catch (error: AnyException) {
        console.log('StackMedia: Произошла ошибка при upload', error);
        this.loading = false;
      }
    },
    onDelete() {
      this.fileUrl = null;
      // отправление на бэкэнд команды удаления файла
      if (this.dataObject.executeMethod) {
        this.dataObject.executeMethod('удалитьФайл', this.queryParams);
        this.file = {};
        this.fileUrl = null;
      }
    },
  },
});
