
import Vue from 'vue';
export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      rulesTime: (value: number) => {
        return (value >= 0 && value <= 24) || 'Время должно быть указано с 0 до 24 часов';
      },
    };
  },
});
