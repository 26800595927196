
import Vue from 'vue';

import CheckDialog from './$master/CheckDialog.vue';

export default Vue.extend({
  name: 'Integrexport',
  components: { CheckDialog },
  data() {
    return {
      statusGenerate: '',
      loading: false,
      showResult: false,
      showResultData: false,
      readyToGo: false,
      correction: [] as StackTableRow[],
      jobID: null,
      finish: false,
      allcheck: false,
      showDialog: false,
      recordDialog: null,
      settings: {
        вариант: 0,
        проверка: 0,
        ффлагивыгрузки: 0,
        датнач: this.$store.getters.getCurrentDate(),
        даткнц: this.$store.getters.getCurrentDate(),
        месяц: this.$store.getters.getWorkMonth(),
        лицевые: '',
        маска: null,
      },
      checkedArr: [],
      checked: false,
      errorAdr: false,
      dataModel: new this.$HttpModel('ЭкспортИнтеграции'),
      isValid: true,
      enablePartSelectAdr: false,
    };
  },
  computed: {
    monthFrom(): any {
      return this.$store.getters.getCloseMonth();
    },
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
    criticalError(): boolean {
      let critical = false;
      this.checkedArr.forEach((row: any) => {
        if (row.критичная) {
          critical = true;
        }
      });
      return critical;
    },
    disableExport(): boolean {
      return !((this.checked && !this.criticalError) || this.settings.проверка === 2) || !this.isValid;
    },
    rulesSelectedAdr(): boolean | string {
      return !this.errorAdr || 'Минимальный уровень выгрузки – Дом';
    },
    settingCheckOnValid(): object {
      return {
        ДатНач: this.settings.датнач,
        ДатКнц: this.settings.даткнц,
        лицевые: this.settings.лицевые,
      };
    },
    disableSelectAddr(): boolean {
      return !this.enablePartSelectAdr || this.showResult;
    },
  },
  async created() {
    this.enablePartSelectAdr = !!(await this.dataModel.executeMethod('ПроверитьВозможностьВыбораФондаПоЧастям'));
  },
  methods: {
    fillCheckedArr(payload: any) {
      this.checked = true;
      this.checkedArr = payload;
    },
    openRowTable(payload: any) {
      this.showDialog = true;
      this.recordDialog = payload;
    },
    async startExport() {
      const parameters = Object.assign({}, this.settings, {});
      this.finish = true;
      this.jobID = (
        await this.dataModel.executeMethod('Выгрузить', parameters, {
          async: true,
          jobName: 'Экспорт данных в Интеграцию',
        })
      ).asyncId;
    },
    async toLog() {
      await this.dataModel.executeMethod(
        'ЗаписатьЛог',
        { проверки: this.checkedArr },
        {
          async: true,
          jobName: 'Сохранение результата проверок',
        },
      );
    },
    onSelectFund(items: StackTableRow[]) {
      this.errorAdr = items.some((row: StackTableRow) => row.тип === 4 || row.тип === 5);
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.jobID = null;
      }
    },
    isError(to) {
      if (to) {
        this.jobID = null;
      }
    },
    allcheck(to) {
      if (to) {
        Vue.set(this.settings, 'маска', 16383);
      } else {
        Vue.set(this.settings, 'маска', 0);
      }
    },
    'settings.вариант': {
      immediate: true,
      async handler(to, from) {
        const flags = await this.dataModel.executeMethod('Флаги', { вариант: this.settings.вариант });
        Vue.set(this.settings, 'маска', flags);
      },
    },
    settingCheckOnValid: {
      deep: true,
      handler() {
        // форма не всегда успевает отрисоваться
        this.$nextTick(() => {
          // @ts-ignore
          this.isValid = this.$refs.form && this.$refs.form.validate();
        });
      },
    },
  },
});
