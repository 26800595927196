
import Vue from 'vue';

export default Vue.extend({
  name: 'ServiceFilter',
  model: { prop: 'params', event: 'input' },
  props: {
    params: { type: Object, required: true },
    type: { type: String, required: true },
  },
  data() {
    return {
      objectService: [] as StackTableRow[],
      httpV: new this.$HttpModel('УслугиПоСчетчику_Значения'),
      httpK: new this.$HttpModel('УслугиПоСчетчику_Ключи'),
    };
  },
  created() {
    if (this.type === 'combo') {
      this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      try {
        const dataV = await this.httpV.getRecords();
        const dataK = await this.httpK.getRecords();
        for (const key in dataV) {
          this.objectService.push({ значение: dataV[key].значение, $номерЗаписи: Number(dataK[key].значение) } as StackTableRow);
        }
      } catch (error: AnyException) {
        console.log('service-filter fetchData error');
      }
    },
  },
});
