const grcount: StackSelection = {
  recordRoute: '',
  objectName: 'ГрупповыеСчетчики.ЛицевыеСчета',
  description: '',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.типввода === 1) {
      // @ts-ignore
      row.$data.типвводаиконка = { $иконка: '$vuetify.icons.check' };
    }
    return row;
  },
  fields: [
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
      computed: true,
    },
    {
      field: 'показания-счет>тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'апи_типлс',
      text: 'Тип объекта',
      type: 'String',
      width: '200px',
      computed: true,
    },
    {
      field: 'показания-счет>примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'показания-счет',
      text: 'показания-счет',
      type: 'Link',
    },
    {
      field: 'типввода',
      text: 'ТипВвода',
      type: 'Number',
    },
    {
      field: '@фильтр',
      text: '@фильтр',
      type: 'Number',
      computed: true,
    },
    {
      field: '@подъезд_с',
      text: '@подъезд_с',
      type: 'Number',
      computed: true,
    },
    {
      field: '@подъезд_по',
      text: '@подъезд_по',
      type: 'Number',
      computed: true,
    },
    {
      field: '@этажи_с',
      text: '@этажи_с',
      type: 'Number',
      computed: true,
    },
    {
      field: '@этажи_по',
      text: '@этажи_по',
      type: 'Number',
      computed: true,
    },
    {
      field: 'типвводаиконка',
      text: 'Главный ЛС',
      type: 'Number',
      computed: true,
    },
  ],
};

export default grcount;
