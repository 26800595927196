<template>
  <v-fade-transition>
    <div :id="'v-step-' + hash" :ref="'v-step-' + hash" class="v-step v-card v-sheet" style="z-index: 1000">
      <v-toolbar color="secondary" height="48" class="elevation-1">
        <v-btn v-if="!isLast" icon small color="primary" @click.prevent="stop">
          <!-- {{ labels.buttonSkip }} -->
          <v-icon>$vuetify.icons.close</v-icon>
        </v-btn>

        <v-card-title v-if="title" class="primary--text text-subtitle-1">
          {{ title }}
        </v-card-title>
      </v-toolbar>
      <v-card-text class="stack--text pt-7">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-if="step.content" v-html="step.content" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="isFirst" left small outlined color="primary" class="mx-1" width="80px" @click.prevent="previousStep">
          <v-icon>$vuetify.icons.prev_month</v-icon>
          {{ labels.buttonPrevious }}
        </v-btn>
        <v-btn :disabled="isLast" right small outlined color="primary" class="mx-1" width="80px" @click.prevent="nextStep">
          {{ labels.buttonNext }}
          <v-icon>$vuetify.icons.next_month</v-icon>
        </v-btn>
        <v-btn v-if="isLast" small outlined color="primary" class="mx-1" @click.prevent="stop">{{ labels.buttonStop }}</v-btn>
      </v-card-actions>
      <div class="v-step__arrow" />
    </div>
  </v-fade-transition>
</template>

<script>
import { createPopper } from '@popperjs/core';
import { DEFAULT_STEP_OPTIONS } from './constants';

export default {
  name: 'VStep',
  props: {
    title: { type: String, default: undefined },
    // eslint-disable-next-line vue/require-default-prop
    step: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    previousStep: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    nextStep: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    stop: {
      type: Function,
    },
    isFirst: {
      type: Boolean,
    },
    isLast: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    labels: {
      type: Object,
    },
  },
  data() {
    return {
      // @ts-ignore
      hash: this._uid, // sum(this.step.target),
      // @ts-ignore
      selectedElement: this.step.selectedEl ? document.querySelector(this.step.selectedEl) : null,
      targetElement: {},
    };
  },
  computed: {
    params() {
      return {
        ...DEFAULT_STEP_OPTIONS,
        ...this.step.params,
      };
    },
  },
  async mounted() {
    if (this.step.commit) {
      this.$store.commit('SET_HELP_TOUR_ACTION', this.step.commit);
      this.$store.commit('SET_HELP_TOUR_ELEMENT', this.step.selectedEl ? this.step.target + ',' + this.step.selectedEl : this.step.target);
    }
    await this.createStep();
    this.selectedElement ? this.selectedElement.classList.add('overlay-selected') : this.targetElement.classList.add('overlay-selected');
  },
  destroyed() {
    this.selectedElement ? this.selectedElement.classList.remove('overlay-selected') : this.targetElement.classList.remove('overlay-selected');
  },
  methods: {
    async createStep() {
      await this.$nextTick();
      this.targetElement = document.querySelector(this.step.target);
      if (this.targetElement) {
        if (this.params.enableScrolling) {
          if (this.step.duration || this.step.offset) {
            const jumpOptions = {
              duration: this.step.duration || 1000,
              offset: this.step.offset || 0,
              callback: undefined,
              easing: 'easeInOutCubic',
              a11y: false,
            };
            this.$vuetify.goTo(this.targetElement, jumpOptions);
          } else {
            this.targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        }

        createPopper(this.targetElement, this.$refs['v-step-' + this.hash], this.params);
      } else {
        console.error('[Vue Tour] The target element ' + this.step.target + ' of .v-step[id="' + this.hash + '"] does not exist!');
        this.$emit('targetNotFound', this.step);
      }
    },
  },
};
</script>
