
import Vue from 'vue';
import typesmeteringdevicesFilter from '@/tasks/common/views/$common/filters/typesmeteringdevicesFilter.vue';
import devicefolder from '@/tasks/common/views/references/typesmeteringdevices/$folder/devicefolder.vue';
import DeviceToolbar from './$typesmeteringdevices/DeviceToolbar.vue';
import typesmeteringdevices from './typesmeteringdevices.vue';

export default Vue.extend({
  components: { devicefolder, typesmeteringdevicesFilter, DeviceToolbar, typesmeteringdevices },
  name: 'Typesmeteringdevices',
});
