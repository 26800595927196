
import Vue from 'vue';
import Fetchable from '../mixins/fetchable';

export default Vue.extend({
  name: 'StackTree',
  mixins: [Fetchable],
  props: {
    /**
     * объект с кастомными параметрами в запрос
     */
    params: { type: [Object], default: null },
    /**
     * запись, на которую переходит фокус
     */
    id: { type: [Number, Array], default: undefined },
    /**
     * Возможно выбирать элементы или нет
     */
    noSelect: { type: Boolean, default: false },
    /**
     * поле которое выводится в дерево
     */
    itemText: { type: String, default: '$название' },
    /**
     * иконка для узлов
     */
    iconFolder: { type: String, default: '' },
    /**
     * иконка для узлов в развёрнутом виде
     */
    iconFolderOpen: { type: String, default: '' },
    /**
     * иконка для записей
     */
    iconRecord: { type: String, default: '' },
    /**
     * объект с данными или название выборки
     */
    dataModel: { type: [String, Object, Array], required: true },
    selectable: { type: Boolean, default: false },
    /**
     * не выводить записи (выводить только узлы)
     */
    onlyFolders: { type: Boolean, default: false },
    /**
     * Показать корень
     */
    showRoot: { type: Boolean, default: false },
    /**
     * раскрыть все записи
     */
    openAll: { type: Boolean, default: false },
    /**
     * внешняя функция загрузки потомков
     */
    loadChildrenExt: { type: Function, default: null },
    /**
     * разрешен ли мультивыбор чекбоксами
     */
    single: { type: Boolean, default: false },
    /**
     * не отображать иконку поиска в тулбаре
     */
    noSearch: { type: Boolean, default: false },
    /**
     * Сделать тублар тёмным
     */
    toolbarDark: { type: Boolean, default: false },
    /**
     * Скрывать тулбар
     */
    noToolbar: { type: Boolean, default: false },
    /**
     * высота таблицы
     */
    height: { type: [String, Number], default: undefined },
    /**
     * префикс для ключа кеширования данных дерева
     */
    cacheKeyPrefix: { type: String, default: undefined },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      items: [] as StackTableRow[], // данные таблицы
      selectedValues: [] as number[],
      openItems: [] as number[],
      activeValues: [] as number[],
      dataObject: {} as DataModel,
      cachedItems: {} as Map<number, StackTableRow>, // плоский массив с загруженными записями
      isVisible: false,
      loadingStructure: false, // признак полной переотрисовки структуры дерева
      changeSelectedLeaf: false,
    };
  },
  computed: {
    queryParams(): StackHttpRequestTaskParam {
      return this.params;
    },
    selectedItems(): StackTableRow[] {
      return this.getItemsByIds(this.selectedValues);
    },
    activeItems(): StackTableRow[] {
      return this.getItemsByIds(this.activeValues);
    },
    calcHeight(): string | number {
      const height = this.height ? this.height.toString() : undefined;
      if (!height || height.indexOf('%') <= 0) {
        return this.height;
      }
      if (this.isVisible) {
        const sel = this.$el.querySelector('.stack-tree');
        if (sel) {
          const rect = sel.getBoundingClientRect();
          const heightProcent = +height.replace('%', '');
          const top = Math.round((rect.top * heightProcent) / 100); // + (!this.noToolbar ? 48 : 0);
          return `calc(${heightProcent}vh - ${top}px)`;
        }
      }
      return this.height;
    },
    cacheId(): string {
      return `${this.cacheKeyPrefix}_tree_open_leafs`;
    },
    cacheIdAc(): string {
      return `${this.cacheKeyPrefix}_tree_active_leafs`;
    },
    showProgressLinear(): boolean {
      return this.loadingStructure || this.loading || this.changeSelectedLeaf;
    },
  },
  methods: {
    async fetchData() {
      let cacheOpenIds: number[] = [];
      let cacheActiveIds: number[] = [];
      if (this.cacheKeyPrefix) {
        cacheOpenIds = this.$store.getters.getSyncCache(this.cacheId);
        cacheActiveIds = this.$store.getters.getSyncCache(this.cacheIdAc);
      }
      // TODO try catch
      this.loadingStructure = true;
      this.cachedItems = new Map();
      const rootItems = await this.fetchCurrent();
      let items: StackTableRow[];
      if (this.showRoot) {
        items = [{ $название: 'Корень', $номерЗаписи: -10, children: [] as any }];
        this.cachedItems.set(-10, items[0]);
        // @ts-ignore
        items.children = rootItems;
        this.openItems.push(-10);
      } else {
        items = rootItems;
      }
      // TODO Новый фетч считает, что узел не изменился и не больше не просит loadChildren
      for (const item of items) {
        this.prepareRec(item);
      }
      this.items = [];
      await this.$nextTick();
      this.items = items;
      if (this.id) {
        await this.focusOnLeaf(this.id as number | number[]);
      }
      // TODO добавить загрузку для других моделей...
      if (this.openAll) {
        this.items.forEach((item: any) => {
          this.openLeaf(item);
        });
      }
      if (cacheOpenIds && cacheOpenIds.length > 0) {
        await this.readPath(cacheOpenIds);
      }
      if (cacheActiveIds) {
        this.activeValues = cacheActiveIds;
      }
      this.loadingStructure = false;
    },
    async readPath(hierIds: number[]) {
      for (const hieridStr of hierIds) {
        const hierid = +hieridStr;
        if (hierid > 0) {
          const currentItem = this.cachedItems.get(hierid);
          if (currentItem && currentItem.$этоПапка) {
            // @ts-ignore
            const ch = currentItem.children as StackTableRow[];
            if (ch.length === 0) {
              const subItems = await this.fetchCurrent({ $номерЗаписи: hierid });
              if (subItems.length > 0) {
                Vue.set(currentItem, 'children', subItems);
              }
            }
            if (!this.openItems.includes(hierid)) {
              this.openItems.push(hierid);
            }
          }
        }
      }
    },
    async focusOnLeaf(ids: number | number[]) {
      this.changeSelectedLeaf = true;
      const openArr: number[] = [];
      let hierIds = [] as number[];
      let curId = undefined as undefined | number;
      if (Array.isArray(ids)) {
        hierIds = ids;
        curId = +hierIds[hierIds.length - 1];
      } else if (this.dataObject.getFolderID) {
        hierIds = (await this.dataObject.getFolderID({ номерЗаписи: this.id, доКорня: true })) as number[];
        curId = +this.id as number;
      }
      await this.readPath(hierIds);
      if (curId) {
        this.activeValues.push(curId);
        this.$nextTick(() => {
          const tag = `.v-treeview-node--active`;
          const el = this.$el.querySelector(tag) as HTMLElement;
          if (el) {
            el.scrollIntoView({ block: 'center', behavior: 'smooth', inline: 'center' });
          }
        });
      }
      this.changeSelectedLeaf = false;
    },
    async fetchCurrent(item?: any) {
      const params = {} as any;
      if (item && item.$номерЗаписи) {
        params.папка = item.$номерЗаписи;
      }
      const q = Object.assign({}, params, this.queryParams);
      let data = await this.dataObject.getRecords(q);
      data.forEach((item) => {
        if (item.$этоПапка && !item.children) {
          Vue.set(item, 'children', []);
        }
      });
      if (this.onlyFolders) {
        data = data.filter((item: StackTableRow) => {
          return item.$этоПапка;
        });
      }
      this.addToChache(data);
      return data;
    },

    // грузим потомков по раскрытию
    async loadChildren(item: any) {
      if (item.children && item.children.length === 0) {
        // TODO https://github.com/vuetifyjs/vuetify/issues/12103
        let currentSelectedValues;
        try {
          await this.fetchCurrent(item).then((newItems) => {
            for (const newItem of newItems) {
              if (newItem && newItem.$номерЗаписи) {
                const cachedItem = this.cachedItems.get(+newItem.$номерЗаписи);
                item.children.push(cachedItem);
              }
            }
            // сохраняем выбранные элементы
            // TODO https://github.com/vuetifyjs/vuetify/issues/12103
            currentSelectedValues = this.selectedValues;
          });
        } catch (e: AnyException) {
          console.log(e);
        }
        // TODO https://github.com/vuetifyjs/vuetify/issues/12103
        if (!this.single && currentSelectedValues) {
          this.selectedValues = currentSelectedValues;
        }
      }
    },

    // перезагрузить потомков у записи с переданным id
    async reloadChildren(id: number) {
      const item = this.cachedItems.get(+id);
      if (item) {
        this.$set(item, 'children', []);
        await this.loadChildren(item);
      }
    },

    addToChache(items: StackTableRow[]) {
      for (const item of items) {
        if (item && item.$номерЗаписи) {
          if (!this.cachedItems.has(+item.$номерЗаписи)) {
            this.cachedItems.set(+item.$номерЗаписи, item);
          } else {
            let cached_item = this.cachedItems.get(+item.$номерЗаписи);
            cached_item = { ...cached_item, ...item };
          }
        }
      }
    },
    // пробежимся по всем узлам и добавим их в раскрытые
    openLeaf(item: any) {
      this.openItems.push(item.$номерЗаписи);
      if (item.children) {
        item.children.forEach((el: StackTableRow) => {
          this.openLeaf(el);
        });
      }
    },
    prepareRec(item: StackTableRow) {
      this.$set(item, '$selected', false); // флаг выделения записи
    },
    // Возвращает массив объектов из массива idшников
    getItemsByIds(ids: number[]): StackTableRow[] {
      const items = [] as StackTableRow[];
      ids.forEach((id) => {
        const item = this.cachedItems.get(id);
        if (item) {
          items.push(item);
        }
      });
      return items;
    },
    onOpen(itemIds: number[]) {
      if (!this.loadingStructure) {
        this.openItems = [];
        for (const chItem of this.cachedItems.values()) {
          const idx = chItem.$номерЗаписи as number;
          if (itemIds.indexOf(idx) !== -1) {
            this.openItems.push(idx);
          }
        }
        if (this.cacheKeyPrefix) {
          this.$store.commit('SAVE_CACHE_DATA', { id: this.cacheId, data: this.openItems });
        }
      }
    },
    onActivate(itemIds: number[]) {
      this.activeValues = itemIds;
      if (this.cacheKeyPrefix) {
        this.$store.commit('SAVE_CACHE_DATA', { id: this.cacheIdAc, data: this.activeValues });
      }
      this.$emit('update:active', this.activeItems);
    },
    onSelect(itemIds: number[]) {
      // Если можно выбирать только один узел
      if (this.single && itemIds.length > 1) {
        itemIds = [itemIds[itemIds.length - 1]];
        this.selectedValues = itemIds;
        return;
      }
      this.$emit('select', this.selectedItems);
    },
    onToolBarAction({ action, payload }: StackTableActionWithPayload) {
      if (action === 'reload') {
        this.fetchData();
      }
    },
    // onIntersect(entries: any) {
    //   this.isVisible = entries[0].isIntersecting || this.isVisible;
    // },
  },
  watch: {
    id(to, from) {
      if (to) {
        this.focusOnLeaf(to);
      }
    },
  },
});
