const accs: StackSelection = {
  objectName: 'ИзменяемыеТаблицы.Удаление',
  description: '',
  fields: [
    {
      field: 'имя',
      text: 'Имя',
      type: 'String',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'String',
    },
    {
      field: 'размер',
      text: 'Размер',
      type: 'String',
    },
    {
      field: 'точность',
      text: 'Точность',
      type: 'Number',
      width: '30',
    },
    {
      field: 'масштаб',
      text: 'Масштаб',
      type: 'Number',
      width: '30',
    },
    {
      field: 'файловый поток',
      text: 'Файловый\nпоток',
      type: 'Number',
      width: '30',
    },
    {
      field: 'проверено',
      text: 'Проверено',
      type: 'Number',
      width: '30',
    },
  ],
};
export default accs;
