
import Vue from 'vue';
import BillChangeToolbar from './BillChangeToolbar.vue';
import ReadingDialog from './$dialogs/ReadingDialog.vue';
import ChangeMaster from './$dialogs/ChangeMaster.vue';
export default Vue.extend({
  components: { ReadingDialog, ChangeMaster, BillChangeToolbar },
  props: {
    counterschangeid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      masterDialogVis: false,
      paramsMasterDialog: {} as StackTableRow,
    };
  },
  computed: {
    title(): string {
      return `Ведомость актов замены ИПУ`;
    },
    isNewRecord(): boolean {
      return this.counterschangeid === 'new';
    },
  },
  methods: {
    onDialogSave() {
      this.masterDialogVis = false;
      this.$sendMsg('stack-table', 'АктыСменыПУ.Ведомость', 'reload');
    },
    onAdd(payload: StackTableRow) {
      this.paramsMasterDialog = payload;
      this.masterDialogVis = true;
    },
  },
});
