
import Vue from 'vue';
import GroupDialog from './$kvitmodelsid/GroupDialog.vue';

export default Vue.extend({
  components: { GroupDialog },
  props: {
    kvitmodelsid: { type: [Number, String], required: true },
  },
  computed: {
    title(): string {
      return 'Макет квитанции';
    },
  },
  data() {
    return {
      tabs: null,
      filterItems: [] as StackTableRow[],
      aliasItems: [] as StackTableRow[],
      record: {} as StackTableRow,
    };
  },
  async beforeRouteLeave(to, from, next) {
    let nullServiceAlias = false;
    let exceptionService = false;
    const arrServiceException = [] as any;

    this.aliasItems.forEach((element: any) => {
      if (element && element.номусл && +element.номусл === 0) {
        nullServiceAlias = true;
      }
    });

    this.filterItems.forEach((element: any) => {
      arrServiceException.forEach((serv: any) => {
        if (serv.номусл === element.номусл && serv.макетсвойство !== element.макетсвойство) {
          exceptionService = true;
        }
      });
      arrServiceException.push({ номусл: element.номусл, макетсвойство: element.макетсвойство });
    });

    if (nullServiceAlias) {
      this.$toast('Внимание! \nСоздание псевдонимов по нулевой услуге запрещено!', { color: 'error' });
      next(false);
      return;
    }

    if (exceptionService) {
      this.$toast('Внимание! \nСоздание одновременно включающих и исключающих записей фильтра запрещено!', { color: 'error' });
      next(false);
    } else {
      next();
    }
  },
  methods: {
    onChangeOrg(val: number | null) {
      if (val === null) {
        this.record['детализацияполучателей'] = 0;
      }
    },
  },
});
