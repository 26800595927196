
import Vue from 'vue';

import { TableHandlers } from '@/stackEngine';

export default Vue.extend({
  model: { prop: 'record', event: 'input' },
  props: {
    ownerID: { type: Number, required: true },
  },
  data() {
    return {
      датнач: this.$stackDate.prevMonth(this.$store.getters.getOpenMonth(), 12) as string,
      даткнц: this.$store.getters.getOpenMonth(),
      развернуть: false,
    };
  },
  methods: {
    colorhandler(item: StackTableRow) {
      if (item && item.цвет && item.цвет === 1) {
        item.$класс = 'secondary';
      }
      return item;
    },
  },
});
