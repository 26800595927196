
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    // this.itemCombo.length <= 1 означает, что параметр имеет числовые значения, иначе на больше меньше сравнивать нельзя
    nameFlag(): String[] {
      return this.itemCombo.length <= 1 ? ['равно', 'не равно', 'меньше', 'больше'] : ['равно', 'не равно'];
    },
  },
  data() {
    return {
      itemCombo: [] as StackTableRow[],
    };
  },
  created() {
    this.record['фильтр-параметр>название'] && this.fetchComboboxData(this.record['фильтр-параметр>название']);
  },
  methods: {
    async fetchComboboxData(paramName: string) {
      this.itemCombo = await new this.$HttpModel('Реестр_ПараметрыОтчетаПоПараметрам').getRecords({ Параметр: paramName });
    },
    async fetchCombo(to: StackTableRow[]) {
      const paramName = to?.[0]?.название as string;
      paramName && await this.fetchComboboxData(paramName);
      this.record.наличие = 0;
      this.record.опция = 0;
      this.record.значение = 0;
    },
  },
});
