
import Vue from 'vue';
import serviceTable from '@/tasks/common/views/$common/serviceTable.vue';
import providerTable from '@/tasks/common/views/$common/providerTable.vue';
import indrateTable from '@/tasks/common/views/$common/indrateTable.vue';
import { TableHandlers } from '@/stackEngine';

export default Vue.extend({
  name: 'ServicesTab',
  components: { serviceTable, providerTable, indrateTable },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  methods: {
    indtarHandler(item: any) {
      if (+this.record.$номерЗаписи !== +item['счет-тарифы']) {
        item.$data['вид-тарифы>номер услуги'].$иконка = '$vuetify.icons.hier';
        item.$data['вид-тарифы>номер услуги'].$иконка_цвет = 'grey';
        TableHandlers.trColor(item, 'grey');
      }
      return item;
    },
  },
});
