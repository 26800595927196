
import Vue from 'vue';
export default Vue.extend({
  model: { prop: 'value', event: 'change' },
  props: {
    type: { type: String, required: true },
    readonly: { type: Boolean, default: false },
    params: { type: Object, default: undefined },
    value: { type: [String, Number], default: null },
    focusEl: { type: Boolean, default: false },
  },
  computed: {
    typeField(): string {
      switch (this.type) {
        case 'Date':
          return 'date';
        case 'DateMonth':
          return 'month';
        default:
          return 'date';
      }
    },
  },
  mounted() {
    if (this.focusEl) {
      this.$nextTick(() => {
        // @ts-ignore
        this.$el.querySelector('input').focus();
      });
    }
  },
});
