
import Vue from 'vue';
import stStep from './step.vue';

export default Vue.extend({
  components: {
    stStep,
  },
  computed: {
    titleResult(): string {
      console.log(this.routeParams.acceptToCreate, Boolean(this.routeParams.acceptToCreate));
      return this.routeParams.acceptToCreate
        ? 'Ведомость № ' + this.routeParams.docNumber + ' успешно создана!'
        : 'Создание ведомости № ' + this.routeParams.docNumber + ' отменено!';
    },
  },
  props: {
    routeParams: { type: Object, required: true },
  },
});
