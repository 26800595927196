
import Vue from 'vue';
import NetTreeLinkField from '@/tasks/commun/views/nettree/$components/NetTreeLinkField.vue';

export default Vue.extend({
  components: { NetTreeLinkField },
  model: {
    prop: 'params',
  },
  props: {
    params: { type: Object, required: true },
  },
  data() {
    return {
      dataParameters: new this.$HttpModel('Договоры.Фильтр.ЗначенияПараметра'),
      netLink: this.params['Сеть'] ? +this.params['Сеть'] : 0,
    };
  },
  computed: {
    netTypes(): any {
      return this.$store.getters['communStore/getNetTypes']();
    },
    items(): any {
      const items = [] as any;
      if (this.netTypes) {
        for (const item of this.netTypes) {
          items.push({ $text: item.name, значение: item.name, $номерЗаписи: item.value });
        }
      }
      return items;
    },
  },
  watch: {
    netLink(to) {
      if (to) {
        this.params['Сеть'] = to;
      }
    },
  },
  methods: {
    handleDog(item: any) {
      return '№ ' + item.номер + ' ' + item['плательщик>название'];
    },
    onChangeNetworkType() {
      this.netLink = 0;
    },
  },
  created() {
    if (this.params['ТипСети'] === 0 && this.netTypes && this.netTypes.length === 1) {
      this.params['ТипСети'] = Number(this.netTypes[0].value);
    }
  },
});
