
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: {
      type: Object,
      default: (): any => {
        return {};
      },
    },
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        ДатНач: this.$store.getters.getWorkMonth(),
        ДатКнц: this.$store.getters.getWorkMonth(),
        Дата:
          this.params.flagDateKncCurMonth === 1
            ? this.$stackDate.lastDay(this.$store.getters.getWorkMonth())
            : this.params.flagDateCurDate === 1
              ? this.$store.getters.getCurrentDate()
              : this.$store.getters.getWorkMonth(),
        Месяц: this.$store.getters.getWorkMonth(),
        '@Услуга': -1,
        '@КатегорияУслуг': -1,
        '@Поставщик': -1,
        '@КатегорияПоставщиков': -1,
        '@УК': -1,
        Аналитики: '',
        ФТариф: this.params.flagTarif !== undefined ? this.params.flagTarif : 0,
        ФТипНач: 0,
        ФГруппыУсл: this.params.flagGroupUsl !== undefined ? this.params.flagGroupUsl : 0,
        фВыводExcel: 0,
        Детализация: 0,
        сТипНач: -1,
        МинДатаКП: null,
      },
      paramDetail: {
        естьЛС: this.params.outLS !== undefined ? this.params.outLS : 1,
        поСостоянию: this.params.status !== undefined ? this.params.status : 0,
        общий: this.params.general !== undefined ? this.params.general : 0,
        допОтчетДетЛсНоМР: this.params.flagDetLSnoMR !== undefined ? this.params.flagDetLSnoMR : 0,
        поСальдо: this.params.onSaldo !== undefined ? this.params.onSaldo : 0,
        допОтчетДетЛсВсе: this.params.flagDetLSALL !== undefined ? this.params.flagDetLSALL : 0,
      },
      showDataContr: this.params.showDataContr !== undefined ? this.params.showDataContr : false,
    };
  },
});
