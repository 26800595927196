
import Vue from 'vue';
import summDebtDiadlog from '../$dialogs/SummDebt.vue';
import selectContract from '../$dialogs/SelectContract.vue';
import basemethod from '@/tasks/dlg_fl/components/$dialogs/basemethod.vue';

export default Vue.extend({
  extends: basemethod,
  model: {
    prop: 'record',
  },
  components: { 'SummDebt-Diadlog': summDebtDiadlog, 'SelectContract-Diadlog': selectContract },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      summDebtvisible: false,
      specifyGpvisible: false,
      selectContractvisible: false,
      specifygp: [] as any,
    };
  },
  methods: {
    async updateSummDebt(data: any) {
      this.summDebtvisible = false;
      const res = await new this.$HttpModel('СостоянияДела').executeMethod('ВыполнитьОперациюНаФазе', { выборка: data, record: this.record, операция: 'УточнитьСуммуДолга' });
      if (res) {
        this.$emit('update');
      }
    },
    async createNotePrepare() {
      const res = await new this.$HttpModel('СостоянияДела').executeMethod('ВыполнитьОперациюНаФазе', {
        тип: 1,
        дело: this.record['документ-состояние'],
        операция: 'СформироватьЗаписку',
      });
      if (res > 0) {
        this.$toast('По данному делу уже сформирована служебная записка', { color: 'info' });
      } else {
        if (this.record['документ-состояние>договор-взыскания'] === -1) {
          this.selectContractvisible = true;
        } else {
          const res = await new this.$HttpModel('СостоянияДела').executeMethod('ВыполнитьОперациюНаФазе', {
            дело: this.record['документ-состояние'],
            договор: this.record['документ-состояние>договор-взыскания'],
            record: this.record,
            операция: 'СформироватьЗаписку',
          });
        }
      }
    },
    async createNote(dogovor: any) {
      this.selectContractvisible = false;
      const res = await new this.$HttpModel('СостоянияДела').executeMethod('ВыполнитьОперациюНаФазе', {
        дело: this.record['документ-состояние'],
        договор: dogovor,
        операция: 'СформироватьЗаписку',
      });
    },
  },
});
