
import Vue from 'vue';

export default Vue.extend({
  props: {
    paramsForClose: { type: Object, required: true },
  },
  computed: {},
  data() {
    return {
      currentParams: {
        месяцРасторжения: this.paramsForClose.ОткрытыйМесяцЛС || this.$store.getters.getOpenMonth(),
        ВариантРасторжения: 0,
        лицевойДляПереноса: null,
        потребительДляПереноса: null,
        датаНовогоСоглашения: this.$store.getters.getCurrentDate(),
      },
      loading: true,
    };
  },
  mounted() {
    this.loading = false;
    this.currentParams.ВариантРасторжения = 0;
  },
  methods: {
    async onSave() {
      this.loading = true;
      await new this.$HttpModel('СоглашенияОРассрочке').executeMethod(
        'ЗакрытьСоглашение',
        {
          ...this.paramsForClose,
          ...this.currentParams,
        },
        {
          async: true,
          jobName: 'Закрываем соглашение',
          onComplete: () => {
            this.loading = false;
            this.$emit('save');
            return true;
          },
          onError: (result: StackStateMsg) => {
            this.loading = false;
            this.$toast(result.data.error.message || 'Во время закрытия возникли ошибки!', { color: 'error' });
            return false;
          },
        },
      );
      return false;
    },
    isCloseMonth() {
      return (
        (Date.parse(this.paramsForClose.ДатНач) <= Date.parse(this.currentParams.месяцРасторжения) &&
          Date.parse(this.paramsForClose.ОткрытыйМесяцЛС) >= Date.parse(this.currentParams.месяцРасторжения)) ||
        !this.paramsForClose.ОткрытыйМесяцЛС ||
        !this.currentParams.месяцРасторжения ||
        'Месяц расторжения должен быть между датой начала рассрочки и открытым месяцем лс'
      );
    },
  },
});
