export default {
  objectName: 'ИсторияВерсий.ОграничениеГруппы',
  description: 'Группы, которым видна версия',
  customApi: 'admin',
  fields: [
    {
      field: 'история версий',
      text: 'история версий',
      type: 'Number',
    },

    {
      field: 'сотрудник',
      text: 'сотрудник',
      type: 'Number',
    },
    {
      field: 'группы>название',
      text: 'Группа',
      type: 'String',
    },
    {
      field: 'группы',
      text: 'группы',
      type: 'Number',
    },
  ],
} as StackSelection;
