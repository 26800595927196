const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ДелаВзыскания',
  recordRoute: 'accounts-accountsid-lawsuit-lawsuitid',
  description: '',
  fields: [
    {
      field: 'дата документа',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'дата статуса',
      text: 'Дата\nстатуса',
      type: 'Date',
    },
    {
      field: 'дата контроля',
      text: 'Дата\nконтроля',
      type: 'Date',
    },
    {
      field: 'срок',
      text: 'Срок',
      type: 'Date',
    },
    {
      field: 'название',
      text: 'Фаза',
      type: 'String',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'окончаниясрок',
      text: 'Срок\nокончания',
      type: 'Date',
    },
    {
      field: 'примечание',
      text: '',
      type: 'String',
    },
    {
      field: 'документ-фаза>фаза-состояние>тип',
      text: 'тип',
      type: 'Number',
    },
    {
      field: 'документ-фаза>подфаза-состояние>имя',
      text: 'Состояние',
      type: 'String',
    },
  ],
};
export default accs;
