
import Vue from 'vue';
import basemethod from './basemethod.vue';
export default Vue.extend({
  extends: basemethod,
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
});
