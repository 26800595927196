const acts: StackSelection = {
  objectName: 'АктыДолжников',
  description: 'Акты по должникам',
  hier: true,
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'DateMonth',
      align: 'right',
    },
    {
      field: '@Госпошлина',
      text: 'Госпошлина',
      type: 'Number',
    },
    {
      field: '@Издержки',
      text: 'Издержки',
      type: 'Number',
    },
    {
      field: '@Пени',
      text: 'Пени',
      type: 'Number',
    },
    {
      field: '@поставщикгоспошлина',
      text: 'Госпошлина поставщик',
      type: 'Link',
    },
    {
      field: '@поставщикздержки',
      text: 'Издержки поставщик',
      type: 'Link',
    },
    {
      field: '@поставщикпени',
      text: 'Пени поставщик',
      type: 'Link',
    },
    {
      field: '@Оплата',
      text: 'Оплата',
      type: 'Number',
    },
    {
      field: 'акт-услуга',
      text: 'Услуга',
      type: 'Link',
    },
    {
      field: 'счет-акты',
      text: 'Счет',
      type: 'Link',
    },
    {
      field: 'акт-услуга>номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'акт-карточка',
      text: 'Заявка',
      type: 'Link',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'счет-акты>номер',
      text: 'НомерЛС',
      type: 'String',
      align: 'center',
    },
    {
      field: '@aдрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: '@фамилия',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'объем',
      text: 'Объем',
      type: 'Number',
      align: 'right',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
      align: 'right',
    },
    {
      field: 'видакта',
      text: 'ВидАкта',
      type: 'Number',
    },
  ],
};

export default acts;
