const doguk: StackSelection = {
  objectName: 'ДоговорыУК.ОбъединениеПерерасчетов',
  description: '',
  cached: true,
  fields: [
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
  ],
};
export default doguk;
