const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ПениПериодыНеначисления',
  description: 'Периоды не начисления пени',
  fields: [
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'дело-пенипериоды',
      text: 'Дело',
      type: 'Number',
    },
    {
      field: 'состояние-пенипериоды',
      text: 'состояние',
      type: 'Number',
    },
    {
      field: 'счет',
      text: 'Счет',
      type: 'Number',
    },
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'договорук',
      text: 'ДоговорУК',
      type: 'Number',
    },
    {
      field: 'поставщикн',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'номердоговорук',
      text: 'Договор УК',
      type: 'String',
    },
    {
      field: 'ук',
      text: 'УК',
      type: 'String',
    },
  ],
};
export default accs;
