
import Vue from 'vue';
import inputConst from '@/tasks/common/views/references/constants/$dialogs/$inputConst.vue';

export default Vue.extend({
  components: { inputConst },
  props: {
    record: { type: Object, required: true },
  },
  methods: {
    typeData() {
      if (this.record.тип === 'число') {
        return 'number';
      } else {
        return 'string';
      }
    },
  },
});
