
import Vue from 'vue';
import debtorsacts from '@/tasks/fl/views/documents/debtorsacts/debtorsactsid.vue';

export default Vue.extend({
  name: 'JudicialactsTab',
  model: { prop: 'record', event: 'input' },
  components: { debtorsacts },
  props: {
    record: { type: Object, required: true },
  },
});
