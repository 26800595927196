
import Vue from 'vue';

export default Vue.extend({
  props: {
    ownerID: { type: Number, default: -1 },
  },
  data() {
    return {
      договор: -1,
    };
  },
});
