
import Vue from 'vue';

export default Vue.extend({
  props: {
    selectedItems: { type: [String, Object, Array], default: undefined },
    fund: { type: [Number, String], required: true },
    dataModel: { type: String, required: true },
  },
  data() {
    return {
      обработано: false,
    };
  },
  methods: {
    async onSave(item: any) {
      await new this.$HttpModel(this.dataModel).executeMethod(item.обработано === 4 ? 'учитывать' : 'игнорировать', { номерЗаписи: item.$номерЗаписи });
      this.$sendMsg('stack-table', this.dataModel, 'reload');
    },
    onClick(item: StackTableRow) {
      let str;
      if (item['проверка-лицевой'] && item['проверка-лицевой'] > 1) str = `/accounts?row_id=${item['проверка-лицевой']}&openModal=${item['проверка-лицевой']}`;
      if (item['проверка-документ'] && item['проверка-документ'] > 1) str = `/metersdata/groupcounters/${item['проверка-документ']}`;
      if (item['проверка-платеж'] && item['проверка-платеж'] > 1) str = `/payments/paylists/${item['проверка-платеж']}`;
      if (item['проверка-договор'] && item['проверка-договор'] > 1) str = `/documents/qualityacts/${item['проверка-договор']}`;
      if (item['проверка-акт'] && item['проверка-акт'] > 1) str = `/documents/inspectionsacts/${item['проверка-акт']}`;
      if (str) window.open(this.$router.resolve(str).href, '_blank');
    },
  },
});
