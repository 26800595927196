
import Vue from 'vue';
import basemethod from '@/tasks/dlg_fl/components/$dialogs/basemethod.vue';
export default Vue.extend({
  extends: basemethod,
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },

  data() {
    return {
      actvisible: true,
      dataObject: new this.$HttpModel('СостоянияДела'),
    };
  },
  methods: {
    async changeDeliveryField(delivery: any) {
      if (delivery > 0) {
        let rec = this.record;
        const result = await this.dataObject.executeMethod('ПроверкаПолей', { поле: 'Доставка', сдела: rec });
        rec = Object.assign(rec, result);
        this.$emit('change', rec);
        // @ts-ignore
        this.$refs.ogrultutable.fetchData();
      }
    },
  },
});
