
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      rulesThis: {
        email: (val: string): boolean | string => {
          if (val === '') {
            return true;
          } else {
            return /.+@.+\..+/i.test(val) || 'Указан некорректный адрес электронной почты';
          }
        },
      },
    };
  },
});
