const accs: StackSelection = {
  objectName: 'Аккаунты.Привязки',
  description: '',
  fields: [
    {
      field: 'датасоздания',
      text: 'Дата создания',
      type: 'Date',
    },
    {
      field: 'датаобновления',
      text: 'Дата обновления',
      type: 'Date',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: '@состояние',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
  ],
};
export default accs;
