
import Vue from 'vue';
import Recordable from '../mixins/recordable';
import Focusable from '../mixins/focusable';
// import Heightable from '../mixins/heightable';
import Listenable from '../mixins/listenable';
/**
 * Диалог
 */

type HookReleaseFunction = () => void;
const capacity = 20;

export default Vue.extend({
  name: 'StackDialog',
  mixins: [Recordable, Focusable, Listenable],
  model: { prop: 'record', event: 'change' },
  provide(): any {
    return {
      // @ts-ignore
      isDialogReadonly: () => this.isReadonly,
      isDialogChild: true,
    };
  },
  props: {
    /**
     * заголовок диалога
     */
    title: { type: String, default: '' },
    /**
     * заголовок закладки браузера. если не указан, берем title
     */
    tabTitle: { type: String, default: '' },
    /**
     * подзаголовок диалога
     */
    subtitle: { type: String, default: null },
    /**
     * Добавляет расширеную облась, в которую можно вставлять свои элементы (например заголовки табов)
     * slot dialog_toolbar
     */
    extended: { type: Boolean, default: false },
    /**
     * Список доступных действий
     */
    actionitems: { type: Array, default: () => undefined },
    noButtons: { type: Boolean, default: false },

    noRouteHistory: { type: [Boolean, String], default: false },
    /**
     *  всегда разрешать сохранять
     */
    forceSave: { type: Boolean, default: false },
    /**
     *  без кнопки сохранить
     */
    noClose: { type: Boolean, default: false },
    /**
     *  Статус диалог занят
     */
    loading: { type: Boolean, default: false },
    /**
     *  Сохранение истории открытия диалогов
     */
    historyId: { type: String, default: undefined },
    testId: { type: String, default: undefined },
  },
  data() {
    return {
      routeHook: null as null | HookReleaseFunction, // хук на смену роута
      creating: false,
      noRouteHistoryRes: !!this.noRouteHistory, // при переключении вкладки c якорем пропс теряется
      isVisible: false,
      fallbackTimeout: undefined as undefined | number,
    };
  },
  computed: {
    routeHistoryCount(): number {
      return this.$store.state.routesStore.routeHistoryCount;
    },
    cacheId(): string {
      return this.historyId ? `stack_dialog_${this.historyId}` : '';
    },
    prevRecords(): StackTableRow[] {
      if (this.cacheId) {
        return this.$store.getters.getSyncCache(this.cacheId) || [];
      }
      return [];
    },
    calcHeight(): string | number {
      if (this.isVisible) {
        const sel = this.$el.querySelector('.main-content');
        if (sel) {
          const rect = sel.getBoundingClientRect();
          const top = rect.top;
          return `calc(100vh - ${top}px)`;
        }
      }
      return '100%';
    },
  },
  created() {
    this.routeHook = this.$router.beforeEach(this.checkChangedOnLeave) as HookReleaseFunction;
  },
  beforeDestroy() {
    if (this.routeHook) {
      this.routeHook();
    }
    if (this.fallbackTimeout) {
      clearTimeout(this.fallbackTimeout);
    }
  },
  methods: {
    async onClose(_rec: StackTableRow, err = false) {
      if (err) {
        this.$router.push('/404');
        return;
      }
      if (this.routeHook) {
        this.routeHook();
      }
      // @ts-ignore
      if (await this.close()) {
        this.$emit('close');
        if (!this.noRouteHistoryRes) {
          // Если рефер (открыли диалог из нашей программы,но в новой владке) и еще не ходили по программе, то закрываем вкладку
          if (document.referrer.includes(window.location.hostname) && this.routeHistoryCount < 5) {
            window.close();
          }
          this.$router.go(-1);
          // подождём go(-1) и если переход не удался (например открыли программу по прямой ссылке), то переходим вверх относительно текущего роута
          this.fallbackTimeout = window.setTimeout(() => {
            this.goToFallback();
          }, 200);
        } else {
          this.goToFallback();
        }
      }
    },
    goToFallback() {
      const currentRoute: string = this.$route.path;
      const toRoute = currentRoute.substr(0, currentRoute.lastIndexOf('/'));
      // @ts-ignore
      this.$router.push({ path: toRoute, query: { row_id: this.record.$номерЗаписи } });
    },
    async onCreateRecord() {
      this.creating = true;
      try {
        // TODO Комбобокс не успевает дослать change, если жать сразу на Внести
        // Нужно разобраться !
        await this.timeout(200);
        // @ts-ignore
        if (await this.createRecord()) {
          // @ts-ignore
          if (this.record.$номерЗаписи) {
            // @ts-ignore
            this.customEmit('create', this.record);
            const currentUrl = this.$route.path;
            // @ts-ignore
            const newUrl = currentUrl.substr(0, currentUrl.lastIndexOf('/new')) + '/' + this.record.$номерЗаписи;
            this.$router.replace(newUrl);
          }
        }
      } finally {
        this.creating = false;
      }
    },
    timeout(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async onSaveRecord() {
      try {
        // TODO Комбобокс не успевает дослать change, если жать сразу на Сохранить
        // Нужно разобраться !
        await this.timeout(200);
        // @ts-ignore
        if (await this.saveRecord()) {
          // @ts-ignore
          this.customEmit('save', this.record);
        }
      } catch {
        //
      }
    },

    async checkChangedOnLeave(to: any, from: any, next: any) {
      // @ts-ignore
      if (!this.noCheckChanged && this.isChanged && !this.creating && from.path !== to.path) {
        const answer = await this.$yesno('Данные не сохранены. Вы уверены ?');
        if (!answer) {
          next(false);
          return;
        }
      }
      next();
    },
    save2Cache() {
      // @ts-ignore mixin
      if (this.historyId && this.title && !this.isNewRecord) {
        const data: StackTableRow[] = [
          { title: this.title, subtitle: this.subtitle, date: new Date(), to: this.$route.path },
          ...this.prevRecords.filter((item) => item.title !== this.title),
        ];
        if (data.length > capacity) {
          data.length = capacity;
        }
        this.$store.commit('SAVE_CACHE_DATA', { id: this.cacheId, data });
      }
    },
  },
  watch: {
    title: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (this.title) {
            this.$store.commit('SET_BROWSER_TITLE', this.tabTitle || this.title);
            this.save2Cache();
          }
        });
      },
    },
  },
});
