import { store } from '../../store';
import { stackDate } from '..';
import { HttpModel } from '@/stackEngine';

export class StateStorage {
  private static storage = window.localStorage;

  // читает состояние из localstorage
  public static getState(taskPrefix?: string) {
    const task: string = taskPrefix || store.getters.getCurrentTask();
    const state = StateStorage.storage.getItem(task);
    return state ? JSON.parse(state) : {};
  }

  // Устанавливает key:value в localstorage
  public static setItem(key: string, value: any, section?: string) {
    const state: any = StateStorage.getState(section) || {};
    state[key] = value;
    const task: string = section || store.getters.getCurrentTask();
    StateStorage.storage.setItem(task, JSON.stringify(state));
  }

  // синхронизация в localstorage
  public static subscriber(mutation: any, state: any) {
    if (mutation.type === 'SET_FAVORITE_REPORT') {
      new HttpModel('ХранилищеПользователя').executeMethod('СохранитьЗапись', { Значение: JSON.stringify(state.configStore.favoriteReports.children), ключ: 'Избранные отчеты' });
    }
    if (mutation.type === 'SET_LOCAL_HISTORY_VERSION') {
      StateStorage.setItem('history_version', mutation.payload);
    }
    if (mutation.type === 'SET_USER_ID') {
      StateStorage.setItem('user_id', mutation.payload);
    }
    if (mutation.type === 'SET_TOKEN') {
      const tokenStore = store.getters.getTokenStorageName();
      StateStorage.setItem('token', mutation.payload, tokenStore);
    }
    if (mutation.type === 'SET_REFRESH_TOKEN') {
      const tokenStore = store.getters.getTokenStorageName();
      StateStorage.setItem('rtoken', mutation.payload, tokenStore);
    }
    if (mutation.type === 'SENTRY_LOG_LVL') {
      StateStorage.setItem('log_lvl', mutation.payload, 'common');
    }
    if (mutation.type === 'CHANGE_WORK_MONTH') {
      StateStorage.setItem('workMonth', stackDate.format(mutation.payload, 'ISOMonth'));
    }
    if (mutation.type === 'SET_OPEN_MONTH') {
      StateStorage.setItem('openMonth', stackDate.format(mutation.payload, 'ISOMonth'));
    }
    if (mutation.type === 'SET_OPEN_MONTH_PARTLY') {
      StateStorage.setItem('openMonthPartly', mutation.payload);
    }
    if (mutation.type === 'SET_OPEN_MONTH_ADM') {
      StateStorage.setItem('openMonthAdm', mutation.payload);
    }
    if (mutation.type === 'SET_LOGIN') {
      StateStorage.setItem('login', mutation.payload);
    }
    if (mutation.type === 'SET_DRAWER_STATE') {
      StateStorage.setItem('mainMenuState', mutation.payload);
    }
    if (mutation.type === 'SET_ORG') {
      StateStorage.setItem('orgname', mutation.payload);
    }
    if (mutation.type === 'SET_BASE_NAME') {
      StateStorage.setItem('basename', mutation.payload);
    }
    if (mutation.type === 'MSG_ADD_ACTION' || mutation.type === 'MSG_SET_ERROR' || mutation.type === 'MSG_SET_STATUS' || mutation.type === 'MSG_DELETE' || mutation.type === 'MSG_CLEAR') {
      StateStorage.setItem('asyncActionList', state.systemStore.msgActions);
    }
    if (mutation.type === 'flStore/SET_SHOWLSSALDOMODE') {
      StateStorage.setItem('showLSSaldoMode', mutation.payload);
    }

    if (mutation.type === 'flStore/SET_SHOWCALCBENEFIT') {
      StateStorage.setItem('showCalcBenefit', mutation.payload);
    }
    if (mutation.type === 'SAVE_CACHE_DATA') {
      StateStorage.setItem('syncCache', state.configStore.syncCache);
    }

    if (mutation.type === 'SET_CUSTOM_BACKEND_URL') {
      StateStorage.setItem('customAPIUrl', state.configStore.customAPIUrl);
    }
    if (mutation.type === 'SET_RSS_STATE') {
      StateStorage.setItem('rssOpen', state.configStore.rssOpen);
    }
    if (mutation.type === 'SET_NEW_SO_HISTORY_RECORD') {
      StateStorage.setItem('SOHistory', state.serviceOperationsStore);
    }
    if (mutation.type === 'CLEAR_SO_HISTORY') {
      StateStorage.setItem('SOHistory', []);
    }
    if (mutation.type === 'SET_DEBUG_MODE') {
      StateStorage.setItem('debugMode', mutation.payload);
    }
  }

  // загрузка данных из localstorage
  public static async loadState(task: string) {
    // console.log('Загружаем данные из localStorage');
    const state = StateStorage.getState(task);
    const tokenStore = store.getters.getTokenStorageName();
    const stateCommon = StateStorage.getState(tokenStore);
    if (stateCommon.log_lvl) {
      store.commit('SENTRY_LOG_LVL', stateCommon.log_lvl);
    }
    if (stateCommon.token) {
      store.commit('SET_TOKEN', stateCommon.token);
    }
    if (stateCommon.token) {
      store.commit('SET_REFRESH_TOKEN', stateCommon.rtoken);
    }
    try {
      if (state.history_version) {
        store.commit('SET_LOCAL_HISTORY_VERSION', state.history_version);
      }
      if (state.user_id) {
        store.commit('SET_USER_ID', state.user_id);
      }
      if (state.login) {
        store.commit('SET_LOGIN', state.login);
      }
      if (state.mainMenuState !== undefined) {
        store.commit('SET_DRAWER_STATE', !!state.mainMenuState);
      }
      if (state.workMonth) {
        store.commit('CHANGE_WORK_MONTH', state.workMonth);
      }
      if (state.orgname) {
        store.commit('SET_ORG', state.orgname);
      }
      if (state.basename) {
        store.commit('SET_BASE_NAME', state.basename);
      }
      if (state.openMonth) {
        store.commit('SET_OPEN_MONTH', state.openMonth);
      }
      if (state.openMonthPartly) {
        store.commit('SET_OPEN_MONTH_PARTLY', state.openMonthPartly);
      }
      if (state.openMonthAdm) {
        store.commit('SET_OPEN_MONTH_ADM', state.openMonthAdm);
      }
      if (state.asyncActionList) {
        for (const action in state.asyncActionList) {
          store.commit('MSG_ADD_ACTION', state.asyncActionList[action]);
        }
      }
      // fl
      if (state.showLSSaldoMode !== undefined) {
        store.commit('flStore/SET_SHOWLSSALDOMODE', !!state.showLSSaldoMode);
      }
      if (state.showCalcBenefit !== undefined) {
        store.commit('flStore/SET_SHOWCALCBENEFIT', !!state.showCalcBenefit);
      }
      if (state.syncCache !== undefined) {
        for (const id in state.syncCache) {
          store.commit('SAVE_CACHE_DATA', { id, data: state.syncCache[id] });
        }
      }
      if (state.customAPIUrl) {
        store.commit('SET_CUSTOM_BACKEND_URL', state.customAPIUrl);
      }
      if (state.rssOpen !== undefined) {
        store.commit('SET_RSS_STATE', state.rssOpen);
      }
      if (state.SOHistory !== undefined) {
        store.commit('SET_SO_HISTORY', state.SOHistory);
      }
      if (state.debugMode !== undefined) {
        store.commit('SET_DEBUG_MODE', state.debugMode);
      }
    } catch (e: AnyException) {
      console.log('loadState', e);
    }
    // Добавим слушателя события localstorage извне, если сменился токен, меняем и во vuex
    window.addEventListener('storage', (event) => {
      if (event.key === tokenStore) {
        try {
          const newTokenObj = event.newValue ? JSON.parse(event.newValue) : null;
          store.commit('SET_TOKEN', newTokenObj?.token);
        } catch (e: AnyException) {
          console.log(e);
          store.commit('SET_TOKEN', '');
        }
      }
    });
  }

  public static async loadStateAfterAuth() {
    try {
      const favorite_reports = await new HttpModel('ХранилищеПользователя').executeMethod('ПолучитьЗапись', { ключ: 'Избранные отчеты' });
      favorite_reports && store.commit('SET_FAVORITE_REPORT_NODE', JSON.parse(favorite_reports));
    } catch (e: AnyException) {
      console.log(e);
    }
  }
}
