const par: StackSelection = {
  objectName: 'ГруппыДоговоров',
  description: 'Типы договоров',
  openLink: 'references-contractscategory',
  hier: true,
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
  ],
};

export default par;
