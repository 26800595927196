const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.РазовыеНачисления',
  description: 'Разовые начисления',
  fields: [
    {
      field: 'рн-список>номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'рн-список>дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'месяц расчета',
      text: 'Месяц',
      type: 'Date',
    },
    {
      field: 'номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'поставщик>название',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'договорук>номер',
      text: 'Договор УК',
      type: 'Number',
    },
    {
      field: 'организация-укдоговор>название',
      text: 'УК',
      type: 'String',
    },
    {
      field: 'тариф',
      text: 'Тариф',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Number',
    },
    {
      field: 'объем',
      text: 'Объем',
      type: 'Number',
    },
  ],
};
export default accs;
