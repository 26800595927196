const ls: StackSelection = {
  objectName: 'ДелениеЛицевого.Разделение характеристик',
  description: '',
  checkRow: row => {
    /**
     * что-то изменили в таблице параметров, надо проверить старые и новые значения
     */
    let valueOld = (row && row.старзнач ? row.старзнач : 0) as number;
    let valueNew = (row && row.новзнач ? row.новзнач : 0) as number;
    valueOld = +valueOld < 0 ? +valueOld * -1 : +valueOld;
    valueNew = +valueNew < 0 ? +valueNew * -1 : +valueNew;
    if (row.параметр === 'ПРОП' && !Number.isInteger(valueOld)) {
      row.старзнач = Math.floor(valueOld);
      valueOld = row.старзнач;
    }
    if (row.параметр === 'ПРОП' && !Number.isInteger(valueNew)) {
      row.новзнач = Math.floor(valueNew);
      valueNew = row.новзнач;
    }
    const round = (value: number, decimals: number) => {
      const numConvert = Math.pow(10, decimals);
      return Number(Math.round(value * numConvert) / numConvert);
    };
    const value = (row && row.значение ? row.значение : 0) as number;
    if (row.параметр === 'ПРОП') return false;
    if (round(valueOld + valueNew, 2) > +value) {
      if (valueOld > +value || valueNew > +value) {
        row.старзнач = +value;
        row.новзнач = 0;
      } else if (row.проверить) {
        row.старзнач = round(+value - valueNew, 2);
      }
    } else if (row.проверить) {
      row.новзнач = round(+value - valueOld, 2);
    }
    return false;
  },
  fields: [
    {
      field: 'параметр',
      text: 'Параметр',
      type: 'String',
      readonly: true,
    },
    {
      field: 'проверить',
      text: 'Проверить',
      type: 'Number',
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
      readonly: true,
    },
    {
      field: 'старзнач',
      text: 'Старый л/с',
      type: 'Number',
    },
    {
      field: 'новзнач',
      text: 'Новый л/с',
      type: 'Number',
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'Number',
      readonly: true,
    },
    {
      field: 'идхар',
      text: 'ИдХар',
      type: 'Number',
      readonly: true,
    },
  ],
};

export default ls;
