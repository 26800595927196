
import Vue from 'vue';
import datePeriodFilter from './datePeriodFilter.vue';
import monthPeriodFilter from './monthPeriodFilter.vue';
import numberTypeFilter from './numberTypeFilter.vue';

export default Vue.extend({
  components: { datePeriodFilter, monthPeriodFilter, numberTypeFilter },
  data() {
    return {
      item: {
        номер: null,
        тип: null,
        контролер: null,
        примечание: null,
        датнач: this.$stackDate.firstDay(this.$store.getters.getCloseMonth()),
        даткнц: null,
        месяцс: null,
        месяцпо: null,
      } as any,
    };
  },
});
