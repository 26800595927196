const regpa: StackSelection = {
  objectName: 'Организации.СообщенияДляКвитанций',
  description: 'Сообщения для квитанции',
  prepareRow: row => {
    switch (row.типадресата) {
      case 4:
        row['_тип'] = 'Для шапки';
        break;
      case 5:
        row['_тип'] = 'Для исполнителя';
        break;
    }
    return row;
  },
  fields: [
    {
      field: 'датнач',
      text: 'Дата начала',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Дата окончания',
      type: 'Date',
    },
    {
      field: '_признаки',
      text: 'Признаки',
      type: 'Number',
    },
    {
      field: '_тип',
      text: 'Тип',
      type: 'String',
      computed: true,
    },
    {
      field: 'лицевой-сообщение',
      text: 'ЛС',
      type: 'Link',
    },
    {
      field: 'ук договор-сообщение',
      text: 'УК',
      type: 'Link',
    },
    {
      field: 'договор',
      text: 'Договор',
      type: 'String',
    },
    {
      field: '_адресдоговор',
      text: 'Адрес договора',
      type: 'String',
      computed: true,
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'текст',
      text: 'Текст',
      type: 'String',
    },
    {
      field: 'типадресата',
      text: 'Тип адресата',
      type: 'Number',
    },
  ],
};

export default regpa;
