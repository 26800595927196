
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      items: [] as StackTableRow[],
      menu: [] as any,
      tabs: null,
    };
  },
  async created() {
    const items = await new this.$HttpModel('МакросыШаблонаПочты').getRecords();
    const menuItems = {} as any;
    items.forEach((row: StackTableRow) => {
      if (row && row.значение) {
        const value = row.значение as string;
        const menuitem = value.substring(0, value.indexOf('@'));
        const title = value.substring(value.indexOf('@') + 2);
        const action = value.substring(value.indexOf('%'), value.indexOf(' ('));

        menuItems[menuitem] = Object.assign({ type: 'menu', title: menuitem, children: [] }, {}, menuItems[menuitem]);
        menuItems[menuitem].children.push({ title, action });
      }
    });
    for (const key in menuItems) {
      this.menu.push(menuItems[key]);
    }
  },
  methods: {
    onBtnClick(parameter: string) {
      if (this.tabs === 0) {
        this.record['@текст'] += parameter;
      }
      if (this.tabs === 1) {
        this.record['@html'] += parameter;
      }
    },

  },
});
