const acts: StackSelection = {
  objectName: 'ПочтовыйКлиент.Сообщения.Файлы',
  description: 'Почта',
  fields: [
    {
      field: 'имяфайла',
      text: 'Имя',
      type: 'String',
    },
    {
      field: 'размер',
      text: 'Размер',
      type: 'String',
    },
  ],
};
export default acts;
