
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    sbpDialogVis: { type: Boolean, default: false },
  },
  data() {
    return {
      loaded: false,
      recordSbp: {} as any,
      dataObject: new this.$HttpModel('Организации'),
    };
  },
  computed: {
    disabled(): boolean {
      return this.recordSbp.merchantId !== null || this.recordSbp.brandName === null || this.recordSbp.contactPhoneNumber === null || this.recordSbp.countrySubDivisionCode === null || this.recordSbp.city === null || this.recordSbp.zip === null || this.recordSbp.mcc === null;
    },
  },
  async created() {
    this.recordSbp = await this.dataObject.executeMethod('ЗагрузитьДанныеСбп', { record: this.record });
    this.loaded = true;
  },
  methods: {
    async saveParam() {
      await this.dataObject.executeMethod('СохранитьДанныеСбп', { recordSbp: this.recordSbp, record: this.record });
      this.$emit('close');
    },
    async runReg() {
      await this.dataObject.executeMethod('СохранитьДанныеСбп', { recordSbp: this.recordSbp, record: this.record });
      try {
        await this.dataObject.executeMethod('ВыполнитьРегСбп', { recordSbp: this.recordSbp, record: this.record });
      } finally {
        this.recordSbp = await this.dataObject.executeMethod('ЗагрузитьДанныеСбп', { record: this.record });
      }
    },
    async checkReg() {
      await this.dataObject.executeMethod('СохранитьДанныеСбп', { recordSbp: this.recordSbp, record: this.record });
      await this.dataObject.executeMethod('ПроверитьРегСбп', { recordSbp: this.recordSbp, record: this.record });
    },
  },
});
