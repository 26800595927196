
import Vue from 'vue';

import errorStep from './errorStep.vue';

interface DataMsgs {
  [index: number]: string;
}

export default Vue.extend({
  components: { errorStep },
  props: {
    type: { type: Number, required: true },
    disabled: { type: Boolean, required: true },
    fund: { type: String, default: null },
    month: { type: [String, Date], default: null },
    accounts: { type: String, default: null },
  },
  data() {
    return {
      jobID: null as null | number,
      items: [] as StackTableRow[],
      noError: false,
      isCritical: false,
      runError: false, // была запущена проверка, нужен, чтобы корректно записать действия пользователя
      dataObject: new this.$HttpModel('ЗакрытиеМесяца_ПравилаПроверкиБазы'),
      errorBeforeDialog: false,
      errorAfterDialog: false,
      errorStat: null as any,
    };
  },
  computed: {
    title(): string {
      return this.type === 1 ? 'Предрасчетная проверка' : 'Послерасчетная проверка';
    },
    titleBtnRun(): string {
      return this.jobID === null ? 'Запустить' : 'Повтор';
    },
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
    dataResult(): DataMsgs {
      if (this.resultJob.data) {
        if (this.resultJob.data.result && this.resultJob.data.result.Правила) {
          return this.resultJob.data.result.Правила;
        } else if (this.resultJob.data.state && this.resultJob.data.state.Правила) {
          return this.resultJob.data.state.Правила;
        }
      }
      return this.resultJob.status ? { 0: this.resultJob.status } : {};
    },
    dataCritical(): boolean {
      if (this.resultJob.data) {
        if (this.resultJob.data.result && this.resultJob.data.result.КритичныеПравила) {
          return +this.resultJob.data.result.КритичныеПравила > 0;
        } else if (this.resultJob.data.state && this.resultJob.data.state.КритичныеПравила) {
          return +this.resultJob.data.state.КритичныеПравила > 0;
        }
      }
      return false;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const data = await this.dataObject.getRecords({ тип: this.type });
      for (const key in data) {
        this.items.push(data[key]);
      }
    },
    async runControl() {
      this.jobID = (await this.dataObject.executeMethod('запускПравил', { тип: this.type, фонд: this.fund, месяц: this.month, лицевые: this.accounts }, { async: true })).asyncId;
      this.$store.commit('MSG_ADD', { title: 'Проверка базы', asyncId: this.jobID, external: true });
      this.runError = true;
      for (const ind in this.items) {
        this.iconStatus(this.items[ind], '');
      }
      this.$emit('run');
    },
    async btnSelect() {
      const text = this.runError ? 'Проверка выполнена с ошибками' : 'Проверка не выполнена';
      const answer = await this.$yesno(`${text}, вы уверены что хотите продолжить ?`);
      if (!answer) {
        return;
      }
      const error = this.runError ? !this.noError : false;
      this.dataObject.executeMethod('пропуститьШаг', { тип: this.type, ошибки: error, фонд: this.fund, месяц: this.month });
      this.$emit('end', 'success');
    },
    openListError() {
      if (this.fund) {
        if (this.type === 1) {
          this.errorBeforeDialog = true;
        } else {
          this.errorAfterDialog = true;
        }
      } else {
        this.$toast('Необходимо выбрать фонд');
      }
    },
    iconStatus(item: StackTableRow, status: string) {
      switch (status) {
        case 'выполнено':
          item.icon = '$vuetify.icons.check';
          item.color = 'success';
          break;
        case 'ошибка':
          item.icon = '$vuetify.icons.close_circle';
          item.color = 'error';
          break;
        case 'предупреждение':
          item.icon = '$vuetify.icons.error';
          item.color = 'warning';
          break;
        default:
          item.icon = '';
          item.color = '';
          break;
      }
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.noError = true;
        this.isCritical = this.dataCritical;
        for (const ind in this.dataResult) {
          for (const indIt in this.items) {
            if (this.items[indIt].id === +ind) {
              this.iconStatus(this.items[indIt], this.dataResult[ind]);
              if (this.dataResult[ind] !== 'выполнено') {
                this.noError = false;
                this.errorStat = this.resultJob.data.result.Ошибки;
              }
              break;
            }
          }
        }
        if (this.noError || this.errorStat.Осталось === 0) {
          this.$emit('end', 'success');
          this.isCritical = false;
        } else {
          const errorMsg = this.resultJob.status || (this.isCritical && 'Обнаружены критические ошибки! Продолжение операции невозможно!') || '';
          errorMsg && this.$toast(errorMsg, { color: 'error' });
          this.$emit('end', errorMsg ? 'error' : 'warning', errorMsg);
        }
        this.$store.commit('MSG_DELETE', this.jobID);
        this.jobID = null;
      }
    },
    isError(to) {
      if (to) {
        this.noError = false;
        this.isCritical = this.dataCritical;
        for (const ind in this.items) {
          if (this.items[ind].icon === '') {
            this.iconStatus(this.items[ind], 'ошибка');
          }
        }
        const errorMsg = this.resultJob.status || (this.isCritical && 'Обнаружены критические ошибки! Продолжение операции невозможно!') || '';
        errorMsg && this.$toast(errorMsg, { color: 'error' });
        this.$emit('end', errorMsg ? 'error' : 'warning', errorMsg);
        this.$store.commit('MSG_DELETE', this.jobID);
        this.jobID = null;
      }
    },
    dataResult(to) {
      if (to[0] && !this.isComplete && !this.isError) {
        for (const ind in to) {
          for (const indIt in this.items) {
            if (this.items[indIt].id === +ind) {
              this.iconStatus(this.items[indIt], to[ind]);
              break;
            }
          }
        }
      }
    },
  },
});
