
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: { type: Object, default: undefined },
  },
  created() {
    this.record.дата = this.params.дата;
    this.record.долгс = this.$stackDate.format(new Date(1980, 0, 1), 'ISO');
    this.record.долгпо = this.$store.getters.getCloseMonth();
  },
  data() {
    return {
      record: {
        дата: null,
        долгс: null,
        долгпо: null,
        'счет-коррекция': -1,
      } as any,
      dialog: false,
    };
  },
  methods: {
    async onSave() {
      const parameters = Object.assign({ метод: 'генерация', владелец: this.params.владелец }, this.record);
      const master = new this.$HttpModel('ВедомостиКоррекцииСальдо.Заголовок');
      const reportID = await master.executeMethod('Отправить', parameters, { async: true, jobName: 'Генерируем списки коррекции сальдо', type: 'func' });
      this.$emit('save', reportID);
    },
  },
  computed: {
    title(): string {
      return `Параметры коррекции сальдо`;
    },
  },
});
