/* eslint-disable no-case-declarations */
export enum ScriptValueType {
  empty = 'empty',
  nil = 'nil',
  boolean = 'boolean',
  int32 = 'int32',
  int64 = 'int64',
  real = 'real',
  money = 'money',
  date = 'date',
  time = 'time',
  datetime = 'datetime',
  string = 'string',
  object = 'object',
}

export interface ScriptValue {
  type: ScriptValueType;
  value?: null | boolean | number | string | { [key: string]: ScriptValue };
}

export interface ScriptCallFunctionResult {
  result: any;
}

export interface ScriptMakeEngineResult {
  result: any;
}

interface Client {
  send: (method: string, params?: object) => Promise<any>;
}

export class Script {
  // tslint:disable: variable-name
  private _client: Client;

  constructor(client: Client) {
    this._client = client;
  }

  public getValue(scriptValue: ScriptValue) {
    return Script.scriptValueToJsValue(scriptValue);
  }

  private static scriptValueToJsValue(scriptValue: ScriptValue) {
    switch (scriptValue.type) {
      case ScriptValueType.empty:
        return undefined;

      case ScriptValueType.nil:
        return null;

      case ScriptValueType.boolean:
        return Boolean(scriptValue.value);

      case ScriptValueType.int32:
        return Number(scriptValue.value);

      case ScriptValueType.int64:
        return Number(scriptValue.value); // TODO: can be out of range

      case ScriptValueType.real:
        return Number(scriptValue.value);

      case ScriptValueType.money:
        return Number(scriptValue.value); // TODO: need to round

      case ScriptValueType.date: {
        const [date, month, year] = String(scriptValue.value).split('.');
        return new Date(Number(year), Number(month), Number(date));
      }

      case ScriptValueType.time:
        return String(scriptValue.value); // TODO: convert to date

      case ScriptValueType.datetime: {
        const [dateComponent, timeComponent] = String(scriptValue.value).split(' ');
        const [date, month, year] = dateComponent.split('.');
        const [time, ms] = timeComponent.split('.');
        const [hours, minutes, seconds] = time.split(':');
        return new Date(Number(year), Number(month), Number(date), Number(hours), Number(minutes), Number(seconds), Number(ms));
      }

      case ScriptValueType.string:
        return String(scriptValue.value);

      case ScriptValueType.object:
        const obj = Object(scriptValue.value);
        const result: { [key: string]: any } = {};
        for (const propName in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(propName)) {
            result[propName] = Script.scriptValueToJsValue(obj[propName]);
          }
        }
        return result;
    }
    throw new Error('Unknown script value type.');
  }

  private callFunction(functionName: string, ...args: any[]): Promise<ScriptCallFunctionResult> {
    return this._client.send('Script.callFunction', { functionName, arguments: args.map(e => ({ value: e })) });
  }

  public dispatch(method: string, params?: any): Promise<any> {
    return this.callFunction('dispatch', { method, params });
  }

  public makeEngine(params: any): Promise<ScriptMakeEngineResult> {
    return this._client.send('Script.MakeEngine', params);
  }
}
