
import Vue from 'vue';
import FileTable from '@/tasks/common/views/$common/fileTable.vue';

export default Vue.extend({
  components: { FileTable },
  props: {
    residentsmoveid: { type: [Number, String], default: undefined },
    accountsid: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
    };
  },
  computed: {
    title(): string {
      return 'Временное движение жильца' + this.titleAcc;
    },
    titleAcc(): string {
      return this.record.номерлс ? ' ( Лицевой счет № ' + this.record.номерлс + ' )' : '';
    },
    getCalcByPassport(): boolean {
      return this.$store.getters['flStore/getCalcByPassport']();
    },
  },
});
