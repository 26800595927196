<template>
  <div>
    <v-row>
      <v-col>
        <stack-link-field
          v-model="preSettings.услуга"
          label="Услуга"
          data-model="Услуги"
          :label-field="record => '№' + record['номер услуги'] + ' ' + record.название"
          headers="номер услуги,наименование"
          link-field="номер услуги"
          :no-folder="preSettings.типкоррекции !== 2"
          :required="!isRequired"
        />
      </v-col>
      <template v-if="preSettings.типкоррекции === 2 && preSettings.типсписания === 1">
        <v-col cols="2">
          <stack-date-field v-model="preSettings.за" label="С" :required="isRequired" type="month" />
        </v-col>
        <v-col cols="2">
          <stack-date-field v-model="preSettings.на_за" label="По" :required="isRequired" type="month" />
        </v-col>
      </template>
      <template v-else>
        <v-col cols="2">
          <stack-date-field v-model="preSettings.за" label="Долг за" :required="isRequired" type="month" />
        </v-col>
      </template>
      <v-col cols="2">
        <stack-combobox v-model="preSettings.тип" data-model="ВедомостиКоррекцииСальдо.Тип" label="Тип" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <stack-link-field
          v-model="preSettings.укдоговор"
          label="Договор"
          data-model="ДоговорыУКВыбор"
          :label-field="labelFieldUKHandler"
          headers="номер,тема,датнач,даткнц,организация-укдоговор>название"
          :required="!isRequired"
          no-folder
        />
      </v-col>
      <v-col>
        <stack-link-field
          v-model="preSettings.поставщик"
          label="Поставщик"
          data-model="Организации"
          :label-field="'название'"
          headers="наименование"
          :required="!isRequired"
          no-folder
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    preSettings: { type: Object, required: true },
    isRequired: { type: Boolean, default: false },
    labelFieldUKHandler: { type: Function, required: true },
  },
};
</script>