const groupdlj: StackSelection = {
  objectName: 'ГруппыКредиторовДЛЖ',
  description: 'ГруппыКредиторовДЛЖ',
  fields: [
    {
      field: 'ключ',
      text: 'ключ',
      type: 'Link',
    },
    {
      field: 'значение',
      text: 'значение',
      type: 'String',
    },
  ],
};

export default groupdlj;
