
import Vue from 'vue';
import CalcToolbar from './$calctabs/$toolbars/CalcToolbar.vue';
import HandCalcTab from './$calctabs/HandCalcTab.vue';
import ReCalcTab from './$calctabs/ReCalcTab.vue';
import PeniTab from './$calctabs/PeniTab.vue';
import SaldoLsFilter from '@/tasks/fl/views/$common/filters/saldoLsFilter.vue';
import { StackSelectionCache, TableHandlers } from '@/stackEngine';
import SumToolbar from '@/tasks/fl/components/SumToolbar.vue';
import CountersInfo from './CounterInfoLight.vue';
import lsInfoLight from './LSinfoLight.vue';
import ReadingsInfo from './ReadingsInfo.vue';
import PaymentsInfo from './PaymentsInfo.vue';

export default Vue.extend({
  name: 'CalcTab',
  components: { ReCalcTab, HandCalcTab, CalcToolbar, PeniTab, SaldoLsFilter, SumToolbar, CountersInfo, lsInfoLight, ReadingsInfo, PaymentsInfo },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    getLSSaldoMode(): boolean {
      return this.$store.getters['flStore/getLSSaldoMode']();
    },
    headersCalc(): string {
      return this.$store.getters['flStore/getCalcBenefit']()
        ? 'номеру,наименование,название,использовантариф,использовананорма,входсальдо,коррекция,тариф,льготировано,прошлое,качество,начислено,оплата,исхсальдо,тобъем,объем'
        : 'номеру,наименование,название,использовантариф,использовананорма,входсальдо,коррекция,тариф,прошлое,качество,начислено,оплата,исхсальдо,тобъем,объем';
    },
    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
  },
  data() {
    return {
      loadedTables: {} as any,
      loading: false,
      tabs: null,
      calcModel: new this.$HttpModel('ЛицевыеСчета'),
    };
  },
  mounted() {
    this.calcLS();
  },
  methods: {
    calcHandler(item: StackTableRow) {
      if (item && item.$data && item.$data.наименование) {
        item.$data.наименование.$иконка = item.$этоПапка ? '$vuetify.icons.folder' : '';
      }
      if (item && item.наименование && item.наименование === 'Переплата') {
        TableHandlers.trColor(item, 'red');
      }
      return item;
    },
    lgHandler(item: StackTableRow) {
      if (item && item.$data) {
        item.$data.льготник.$иконка = item.$этоПапка ? '$vuetify.icons.folder' : '';
      }
      return item;
    },
    async calcLS() {
      this.loading = true;
      StackSelectionCache.clearCacheByModel('ЛицевыеСчета.Расчет');
      await this.calcModel.executeMethod('Рассчитать', { номерЗаписи: this.record.$номерЗаписи });
      this.loading = false;
    },
  },
  watch: {
    workMonth() {
      this.calcLS();
    },
  },
});
