
import Vue from 'vue';
import tabWithErrorsFilter from './filter/tabWithErrorsFilter.vue';
import FiasResult from '@/tasks/common/views/$common/FiasResult.vue';

export default Vue.extend({
  components: { tabWithErrorsFilter, FiasResult },
  props: {
    typeTable: { type: Number, default: 0 }, // 0 - улицы, 1 - дома
  },
  computed: {
    headers(): String {
      return this.typeTable === 0 ? 'бд_адрес,nsi_адрес,nsi_гуид' : 'бд_адрес,nsi_адрес,бд_гуид,nsi_гуид,бд_индекс,nsi_индекс';
    },
    title(): String {
      return this.typeTable === 0 && this.allItemsNotFounded
        ? 'ВНИМАНИЕ! Не найдено соответствие с ФИАС. Чтобы устранить проблему, необходимо установить корректные ссылки по указанным ниже адресам в реестре '
        : '';
    },
  },
  data() {
    return {
      waitFias: false,
      houses: [] as StackTableRow[],
      findFias: false,
      idForCheckGuid: 0,
      allItemsNotFounded: false,
    };
  },
  methods: {
    async onSyncFias(selectedItems: StackTableRow[]) {
      if (selectedItems[0] && selectedItems[0].$номерЗаписи) {
        this.idForCheckGuid = +selectedItems[0].$номерЗаписи;
        this.waitFias = true;
        this.houses = await new this.$HttpModel('ЛицевыеСчета.ПоискДомаФиас').getRecords({
          номер: selectedItems[0].номер,
          литера: selectedItems[0].литера,
          улица: selectedItems[0].улица,
        });
        if (this.houses.length > 0) {
          this.findFias = true;
        } else {
          this.$toast('Не найдены записи в ФИАС');
        }
        this.waitFias = false;
      }
    },
    async onFiasSave(selectedHouse: StackTableRow) {
      if (selectedHouse != null) {
        await new this.$HttpModel('ПроверкаЛсПоГуиду').executeMethod(
          'ЗаполнитьДанныеДома',
          {
            запись: this.idForCheckGuid,
            записьДома: selectedHouse,
          },
          {
            async: true,
            jobName: 'Заполнение данных из ФИАС',
            onComplete: () => {
              this.$sendMsg('stack-table', 'ПроверкаЛсПоГуиду', 'reload');
            },
            onError: () => {
              this.$sendMsg('stack-table', 'ПроверкаЛсПоГуиду', 'reload');
            },
          },
        );
      }
      this.findFias = false;
    },
    async fillFromFias(selectedItems: StackTableRow[]) {
      await new this.$HttpModel('ПроверкаЛсПоГуиду').executeMethod(
        'перенестиДанныеИзФиас',
        {
          записи: selectedItems.map((row: StackTableRow) => row.$номерЗаписи).join(','),
        },
        {
          async: true,
          jobName: 'Заполнение данных из ФИАС',
          onComplete: () => {
            this.$sendMsg('stack-table', 'ПроверкаЛсПоГуиду', 'reload');
          },
          onError: () => {
            this.$sendMsg('stack-table', 'ПроверкаЛсПоГуиду', 'reload');
          },
        },
      );
    },
    checkSelectedItems(selectedItems: StackTableRow[]) {
      return !selectedItems.some((row: StackTableRow) => !row.флагошибок || +row.флагошибок & 1);
    },
    onUpdateItems(items: StackTableRow[]) {
      if (this.typeTable === 0) {
        const itemsWithError1 = items.filter((item: StackTableRow) => item.флагошибок && +item.флагошибок & 1);
        this.allItemsNotFounded = items.length === itemsWithError1.length;
      } else {
        this.allItemsNotFounded = false;
      }
    },
    onClickHyper() {
      const routeData = this.$router.resolve({ path: `/accounts` });
      window.open(routeData.href, '_blank');
    },
  },
});
