
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      item: {
        датас: null,
        датапо: null,
        документ: null,
        сумма: 0,
        лицевой: null,
        договор: null,
        контрагент: null,
      } as any,
    };
  },
});
