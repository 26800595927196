
import Vue from 'vue';
import datePeriodFilter from '@/tasks/fl/views/$common/filters/datePeriodFilter.vue';

export default Vue.extend({
  data() {
    return {
      item: {
        видпломбы: null,
        номер: null,
        завномер: null,
        состояние: null,
        контролер: null,
        датасостояниянач: null,
        датасостояниякнц: null,
        датавыдачинач: null,
        датавыдачикнц: null,
      } as any,
    };
  },
});
