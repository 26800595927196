
import Vue from 'vue';
export default Vue.extend({
  props: {
    ownerID: { type: [Number, String], required: true },
    inputType: { type: Number, default: 0 },
  },
  data() {
    return {
      датнач: null,
      времянач: '00:00',
      даткнц: null,
      времякнц: '23:59',
      тип: 0 as number,
    };
  },
  methods: {
    async onSave() {
      await new this.$HttpModel('Счетчики.НештатнаяРабота').executeMethod('задатьПериод', {
        владелец: this.ownerID,
        датнач: this.датнач,
        времянач: this.времянач,
        даткнц: this.даткнц,
        времякнц: this.времякнц,
        тип: this.тип,
        типввода: this.inputType,
      });
      this.$sendMsg('stack-table', 'Счетчики.НештатнаяРабота', 'reload');
      this.$emit('close');
    },
  },
  created() {
    this.датнач = this.$store.getters.getWorkMonth();
    this.даткнц = this.$stackDate.lastDay(this.датнач);
  },
});
