
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
    lastpokaz: { type: [Number, String], required: true },
    razr: { type: [Number, String], default: null },
  },
  data() {
    return {
      показание: this.record.показание,
      расход: this.record.расход,
      разрядность: this.razr != null ? this.razr : this.record.разрядность,
      перекрутка: false,
      askDialog: false,
      menux: 0,
      menuy: 0,
      isChanged: false,
    };
  },
  methods: {
    // изменение показания
    onChangePok(payload: number) {
      this.показание = payload;
      this.isChanged = true;
      this.calcRashod();
    },
    // считаем расход
    calcRashod() {
      if (!this.askDialog) {
        this.$emit('error', true);
      }
      // если возведен флаг перекрутки
      const maxPok = this.перекрутка ? +'9'.repeat(this.разрядность) + 1 : 0;
      // если что то введено
      this.расход = 0;
      if (this.показание !== null && this.показание.toString() !== '') {
        this.расход = this.перекрутка ? maxPok + +this.показание - +this.lastpokaz : +this.показание - +this.lastpokaz;
      }
      this.$emit('calc', { расход: this.расход, показание: this.показание });
      this.перекрутка = false;
      if (this.askDialog) {
        this.$emit('blur', false);
      }
    },
    // справшиваем пользователя о корректности показания
    onBlur() {
      if (this.показание !== null && this.показание.toString() !== '' && this.isChanged) {
        if (+this.показание < +this.lastpokaz) {
          // @ts-ignore
          const pos = this.$refs.pokaz.$el.getBoundingClientRect();
          // @ts-ignore
          this.menux = pos.left + pos.width / 2;
          // @ts-ignore
          this.menuy = pos.top;
          this.askDialog = true;
          this.$emit('error', this.askDialog);
        } else {
          this.askDialog = false;
          this.$emit('error', this.askDialog);
          this.$emit('calc', { расход: this.расход, показание: this.показание });
        }
      }
      if (!this.askDialog) {
        this.$emit('blur', false);
      }
      this.isChanged = false;
    },
  },
});
