
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  data() {
    return {
      rulesThis: {
        length: (value: string): string | true => {
          return (value && value.toString().length === 20) || 'Длина расчетного счета должна быть 20 символов';
        },
      },
    };
  },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'Расчетный счет' },
  },
});
