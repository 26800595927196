const payments: StackSelection = {
  recordRoute: 'paydetail',
  objectName: 'ПлатежныеВедомости.ПлатежиВедомости.Детализация',
  description: '',
  fields: [
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'платежза',
      text: 'ПлатежЗа',
      type: 'Date',
    },
    {
      field: 'платежпо',
      text: 'ПлатежПо',
      type: 'Date',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Number',
    },
    {
      field: 'распределение-ук>название',
      text: 'Управляющая компания',
      type: 'String',
    },
    {
      field: 'распределение-пост>название',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'распределение-ук',
      text: 'Управляющая компания',
      type: 'Link',
    },
    {
      field: 'распределение-пост',
      text: 'Поставщик',
      type: 'Link',
    },
    {
      field: 'распределение-услуга',
      text: 'Услуга',
      type: 'Link',
    },
    {
      field: 'суммапени',
      text: 'СуммаПени',
      type: 'Number',
    },
    {
      field: 'суммапосчету',
      text: 'СуммаПоСчету',
      type: 'Number',
    },
  ],
};
export default payments;
