
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      items: [
        { title: 'Платежные ведомости', img: 'plat_vedom', to: { name: 'payments-paylists' } },
        { title: 'Распределение оплаты', img: 'bank_raspredeleniye_oplaty', to: { name: 'payments-payrolllists' } },
        { title: 'Выписки из банка', img: 'bank_vipiski', to: { name: 'payments-paymentsreceipts' } },
      ],
      items2: [
        { title: 'Прием платежей из файла', img: 'bank_file_import', to: { name: 'payments-paymentsuploads' }, color: 'stack' },
        { title: 'Журнал реестров платежей', img: 'bank_file_import_log', to: { name: 'payments-paymentslog' }, color: 'stack' },
        { title: 'Ежедневное\nраспределение оплаты', img: 'bank_pay_fee', to: { name: 'operations-payroll' }, color: 'stack' },
      ],
    };
  },
});
