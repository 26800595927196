const acts: StackSelection = {
  objectName: 'КонсольМногопроцессныхОпераций',
  description: 'Отладочная информация',
  hier: true,
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.$этоПапка) {
      if (row.ошибка === 1) {
        // @ts-ignore
        row.$data.дата = { $иконка: '$vuetify.icons.folder', $иконка_цвет: 'error' };
      } else {
        // @ts-ignore
        row.$data.дата = { $иконка: '$vuetify.icons.folder' };
      }
    } else if (row.ошибка === 1) {
      // @ts-ignore
      row.$data.дата = { $иконка: '$vuetify.icons.error', $иконка_цвет: 'error' };
    }
    return row;
  },
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'String',
      width: '340px',
    },
    {
      field: 'текст',
      text: 'Текст',
      type: 'String',
    },
    {
      field: 'ошибка',
      text: 'Ошибка',
      type: 'Number',
    },
  ],
};
export default acts;
