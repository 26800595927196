
import Vue from 'vue';

import FileTable from '@/tasks/common/views/$common/fileTable.vue';
import addressDialog from './$modal/addressDialog.vue';
import agreementDialog from './$modal/agreementDialog.vue';
import onechargestatementsList from './$modal/onechargestatementsList.vue';

export default Vue.extend({
  components: { FileTable, addressDialog, onechargestatementsList, agreementDialog },
  props: {
    onechargestatementsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
    readonly: { type: [Boolean], default: false },
  },
  data() {
    return {
      record: {} as StackTableRow,
      adrItems: {} as StackTableRow[],
      rulesThis: {
        isOpenMonth: (recalc: string): string | true => {
          return !recalc || Date.parse(recalc) <= Date.parse(this.$store.getters.getCloseMonth()) || `Месяц перерасчета должен быть закрытым`;
        },
        monthMoreRecalc: (month: string, recalc: string): string | true => {
          return !recalc || Date.parse(recalc) < Date.parse(month) || `Месяц перерасчета должен быть меньше месяца расчета`;
        },
      },
      inputprogress: false as boolean,
      modalAdressDialog: false,
      modalAgreementDialog: false,
      haveLS: false, // есть записи в таблице адресов
      isAgreement: false, // есть рассрочка
      isCloseLS: false, // есть закрытые лицевые
      isPrim: false, // есть определенные слова в примечании
      isCorrect: false, // есть корректировка отопления
      listItems: [] as StackTableRow[], // список адресов
    };
  },
  computed: {
    title(): string {
      return `Разовое начисление`;
    },
    titleBtn(): string {
      return !this.record['окончания срок'] ? 'Сторнировать' : 'Применить';
    },
    closeDialog(): boolean {
      return this.isAgreement || this.isCloseLS || this.readonly;
    },
    disabledDistribute(): boolean {
      return this.isAgreement || this.isCloseLS || this.isPrim || this.isCorrect || !this.haveLS || this.inputprogress;
    },
    disabledCreateAgreement(): boolean {
      return this.onechargestatementsid.toString() === 'new' || this.isAgreement || this.isCloseLS || !this.haveLS || this.inputprogress || !this.havePaymentsDistribution;
    },
    disabledReverseApply(): boolean {
      return this.onechargestatementsid.toString() === 'new' || !this.isCloseLS || this.isAgreement || this.inputprogress;
    },
    havePaymentsDistribution(): boolean {
      return this.listItems.some((item: StackTableRow) => item['сумма']);
    },
  },
  created() {
    this.initialization();
  },
  methods: {
    async initialization() {
      if (this.onechargestatementsid.toString() !== 'new') {
        this.inputprogress = true;
        const res = await new this.$HttpModel('ВедомостиРазовыхНачислений').executeMethod('ПредварительнаяИнициализация', {
          номерЗаписи: this.onechargestatementsid,
        });
        if (res) {
          this.isAgreement = !!res.естьРассрочка;
          this.isCloseLS = !!res.естьЗакрытыеЛицевые;
          this.isPrim = res.естьПримечание;
          this.isCorrect = res.естьКорректировка;
        }
        this.inputprogress = false;
      }
    },
    /**
     * кнопка "распределить", распределяет сумму по выбранным лицевым
     */
    async distribute() {
      this.inputprogress = true;
      // @ts-ignore нужны актуальные данные, иначе не ставится доля
      await this.$refs.dialog.saveRecord();
      await new this.$HttpModel('ВедомостиРазовыхНачислений').executeMethod(
        'Распределить',
        {
          номерЗаписи: this.onechargestatementsid,
        },
        {
          async: true,
          onComplete: () => {
            this.$sendMsg('stack-table', 'ВедомостиРазовыхНачислений.Список', 'reload');
            this.inputprogress = false;
          },
          onError: () => {
            this.inputprogress = false;
          },
          jobName: 'Перерасчет лицевых Ведомости разовых начислений №' + this.record['номер'],
        },
      );
    },
    /**
     * добавление лицевых дома, срабатывает после выбора дома в соответствующем поле
     */
    async addHouse(item: StackTableRow) {
      this.modalAdressDialog = false;
      this.inputprogress = true;
      if (this.onechargestatementsid === 'new') {
        // @ts-ignore перед созданием записей лицевых надо сохранить документ
        await this.$refs.dialog.onCreateRecord();
      } else {
        // @ts-ignore перед созданием записей лицевых надо сохранить документ
        await this.$refs.dialog.saveRecord();
      }
      await new this.$HttpModel('ВедомостиРазовыхНачислений').executeMethod('ДобавитьДом', {
        номерЗаписи: this.record.$номерЗаписи,
        флаги: item.флаги,
        вид: item.вид,
        ипу: item.ипу,
      });
      // @ts-ignore
      this.$refs.list.fetchData();
      this.inputprogress = false;
    },
    /**
     *  кнопка "сторнировать" или "применить"(отменить сторнировку)
     */
    async reverseApply() {
      if (this.record.$естьИзменения) {
        this.$toast('Есть измененные данные! Необходимо сохранить документ!', { color: 'error' });
      } else {
        this.inputprogress = true;
        await new this.$HttpModel('ВедомостиРазовыхНачислений').executeMethod(this.titleBtn, {
          номерЗаписи: this.record.$номерЗаписи,
        }, { async: true, jobName: 'Перерасчет лицевых Ведомости разовых начислений №' + this.record['номер'] });
        // @ts-ignore
        this.$refs.dialog.fetchData();
        this.inputprogress = false;
      }
    },
    /**
     *  изменили тариф, объем или сумму
     */
    changeSum(field: string) {
      switch (field) {
        case 'Тариф':
          if (this.record.тариф && this.record.объем) {
            this.record.сумма = this.round(+this.record.объем * +this.record.тариф, 2);
          } else if (this.record.тариф && this.record.сумма) {
            this.record.объем = this.round(+this.record.сумма / +this.record.тариф, 3);
          }
          break;
        case 'Объем':
          if (this.record.тариф && this.record.объем) {
            this.record.сумма = this.round(+this.record.объем * +this.record.тариф, 2);
          } else if (this.record.объем && this.record.сумма) {
            this.record.тариф = this.round(+this.record.сумма / +this.record.объем, 2);
          }
          break;
        case 'Сумма':
          if (this.record.тариф && this.record.сумма) {
            this.record.объем = this.round(+this.record.сумма / +this.record.тариф, 3);
          } else if (this.record.объем && this.record.сумма) {
            this.record.тариф = this.round(+this.record.сумма / +this.record.объем, 2);
          }
          break;

        default:
          break;
      }
    },
    round(value: number, decimals: number) {
      const numConvert = Math.pow(10, decimals);
      return Number(Math.round(value * numConvert) / numConvert);
    },
    /**
     * добавили запись в адреса, можно распределять
     */
    changeTable(items: StackTableRow[]) {
      this.haveLS = items.length > 0;
      this.listItems = items;
    },
    /**
     * создано соглашения о рассрочке
     */
    async createAgreement(result: number) {
      if (result) {
        this.isAgreement = true;
        this.modalAgreementDialog = false;
      }
    },
    /**
     * При выборе адреса, проверяем, что выбран дом и выводим диалог
     */
    onAddressChange(keyValues: string | null) {
      if (keyValues && this.adrItems && this.adrItems[0]) {
        if (this.adrItems[0].тип === 3 || this.adrItems[0].тип === 4 || this.adrItems[0].тип === 5) {
          this.modalAdressDialog = true;
        } else {
          this.record['рн документы-адрес'] = null;
          this.$toast('Выбирать можно только один дом');
        }
      }
    },
    async CheckField(zn: number) {
      // @ts-ignore
      if (this.$refs.list.items.length > 0) {
        const answer = await this.$yesno('Внести изменения во все записи ведомости?');
        if (answer) {
          // @ts-ignore
          await this.$refs.dialog.onSaveRecord();
          // @ts-ignore
          this.$refs.list.fetchData();
        } else {
          // @ts-ignore
          this.$refs.dialog.fetchData();
        }
      }
    },
  },
});
