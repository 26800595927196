
import Vue from 'vue';
import docreg from '@/tasks/common/views/$common/residents/docReg.vue';
import docvlad from '@/tasks/common/views/$common/residents/docVlad.vue';
import ContactsDialog from '@/tasks/common/views/$common/contactsDialog.vue';
import AdrDialog from './$dialog/AdrModalDialog.vue';
import NewPeopleDialog from './$dialog/NewPeopleDialog.vue';

export default Vue.extend({
  components: { docreg, docvlad, ContactsDialog, AdrDialog, NewPeopleDialog },
  props: {
    peopleid: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      tabs: null,
      addressDialogVisible: false as boolean,
      typeOfAdress: true as boolean,
      нзСтрана: -1 as number,
      нзОбласть: -1 as number,
      нзРайон: -1 as number,
      нзГород: -1 as number,
      нзУлица: -1 as number,
      newPeopleVis: false,
      newIdLs: -1 as number,
      newIdDoc: -1 as number,
      death: false,
    };
  },
  methods: {
    checkFields(field: string, value: string) {
      new this.$HttpModel('ЧастныеЛица').executeMethod('ПроверитьПоля', { [field]: value });
    },
    editAddress(type: boolean) {
      this.addressDialogVisible = !this.addressDialogVisible;
      this.typeOfAdress = type;
    },
    async onChangeFIO(description: string, field: string, value: string) {
      if (description && field && value) {
        // с заглавной буквы
        this.$set(this.record, field, value.charAt(0).toUpperCase() + value.slice(1));
        if (typeof this.$stackRules.onlyRussianLetters(value) === 'string') {
          const answer = await this.$toast('В поле "' + description + '" есть недопустимые символы');
        }
      }
    },
    onUpdateAddressField(field: string, item: StackTableRow) {
      const id = item && item.ключ ? (item.ключ as number) : -1;
      switch (field) {
        case 'страна':
          if (this.нзСтрана !== id) {
            this.нзСтрана = id;
            this.нзОбласть = this.нзРайон = this.нзГород = -1;
            this.record['@робласть'] = this.record['@ррайон'] = this.record['@ргород'] = this.record['@рграйон'] = '';
          }
          break;
        case 'область':
          if (this.нзОбласть !== id) {
            this.нзОбласть = id;
            this.нзРайон = this.нзГород = -1;
            this.record['@ррайон'] = this.record['@ргород'] = this.record['@рграйон'] = '';
          }
          break;
        case 'район':
          if (this.нзРайон !== id) {
            this.нзРайон = id;
            this.нзГород = -1;
            this.record['@ргород'] = this.record['@рграйон'] = '';
          }
          break;
        default:
          break;
      }
    },
    async onCreateRecord(payload: any) {
      if (await this.$yesno('Вы хотите зарегистрировать человека?')) {
        this.newIdLs = payload['счет-владение'];
        this.newIdDoc = payload['человек-владение'];
        this.newPeopleVis = true;
      }
    },
    async onChangeDD() {
      this.record['даткнцдог'] = this.record['дата смерти'];
      this.record['даткнцрег'] = this.record['дата смерти'];
      this.$set(this, 'даткнцрег', this.record['дата смерти']);
      if (this.record['дата смерти'] !== null) {
        this.record['снятсрегистрации'] = 1;
        this.record['закрытьдокумент'] = 1;
        this.death = true;
        this.$toast('Проверьте дату снятия с регистрации и дату закрытия документа на владение', { color: 'info' });
      } else {
        this.record['снятсрегистрации'] = 0;
        this.record['закрытьдокумент'] = 0;
        this.death = false;
        this.$toast('Не забудьте удалить дату снятия с регистрации и продлить дату действия документа на владение, если это необходимо', { color: 'info' });
      }
    },
  },
});
