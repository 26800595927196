import Vue from 'vue';
import { Module, MutationTree, GetterTree, ActionTree } from 'vuex';
import { store } from '.';
import { StackApi } from '../middleware/StackApi';
import { StackPushClient, SerialPortBaudrate, SerialPortDataBits, SerialPortParity, SerialPortStopBits, SerialPortHandshake } from '../middleware/stackPushClient';

interface STwainDevice {
  id: string;
  name: string;
}

interface SWSEnumerateDevices {
  defaultDeviceId?: string;
  devices?: STwainDevice[];
}

export interface WSState {
  isConnected: boolean;
  clientVersion: string; // Версия ассистента, установленная на клиенте
  serverVersion: string; // Версия на бекэнде
  url: string | undefined;
  twainDeviceList: STwainDevice[];
  twainDefaultDeviceId: number | null;
  barcode: string; // строка штрих кода
  ports: object | null; // список портов
}

const state: WSState = {
  isConnected: false,
  clientVersion: '',
  serverVersion: '',
  url: undefined,
  twainDeviceList: [],
  twainDefaultDeviceId: null,
  barcode: '',
  ports: null,
};
let wsClient: StackPushClient | null = null;

// Геттеры
const getters: GetterTree<WSState, any> = {
  getWsObj: (state: WSState) => () => {
    return wsClient;
  },
  isConnected: (state: WSState) => () => {
    return state.isConnected;
  },
  getTwainDevices: (state: WSState) => () => {
    return state.twainDeviceList;
  },
  getTwainDefaultDevice: (state: WSState) => () => {
    const item = state.twainDeviceList.find((item: STwainDevice) => +item.id === state.twainDefaultDeviceId);
    return item?.name;
  },
  getCurrentVersion: (state: WSState) => () => {
    return state.clientVersion;
  },
  getStoreVersion: (state: WSState) => () => {
    return state.serverVersion;
  },
  isExpired: (state: WSState) => () => {
    if (state.clientVersion && state.serverVersion) {
      const maxDigits = 5; // Максимальное кол-во цифр в подверсии
      const serverVer = state.serverVersion.split('.');
      const clientVer = state.clientVersion.split('.');
      let fullClientVer = '';
      let fullServerVer = '';
      for (const idx in clientVer) {
        fullClientVer += clientVer[idx].padStart(maxDigits, '0');
        fullServerVer += serverVer[idx].padStart(maxDigits, '0');
      }
      return +fullClientVer < +fullServerVer;
    }
    return false;
  },
  pluginUrl: (state: WSState) => () => {
    return state.url ? `${store.getters.getApiHostShare()}${state.url}` : undefined;
  },
  // штрих код
  getBarcode: (state: WSState) => () => {
    return state.barcode;
  },
  // массив портов сканера
  getPorts: (state: WSState) => () => {
    return state.ports;
  },
};

const mutations: MutationTree<WSState> = {
  SET_STATUS(state: WSState, status: boolean) {
    state.isConnected = status;
  },
  SET_VERSION(state: WSState, version: string) {
    state.clientVersion = version;
  },
  SET_STORE_VERSION(state: WSState, version: string) {
    state.serverVersion = version;
  },
  SET_PLUGIN_URL(state: WSState, url: string) {
    state.url = url;
  },
  CLEAR_TWAIN_DEVICES(state: WSState) {
    state.twainDeviceList = [];
  },
  ADD_TWAIN_DEVICE(state: WSState, dev: STwainDevice) {
    state.twainDeviceList.push(dev);
  },
  SET_DEFAULT_DEVICE_ID(state: WSState, id: number) {
    state.twainDefaultDeviceId = id;
  },
  // устанавливаем штрих код
  SET_BARCODE(state: WSState, value: string) {
    state.barcode = value;
  },
  // находим массив портов сканера
  SET_PORTS(state: WSState, value: object) {
    state.ports = value;
  },
};

const actions: ActionTree<WSState, any> = {
  async checkPluginInfo() {
    const http = new StackApi();
    const pluginInfo = await http.getPluginInfo('');
    if (pluginInfo?.version) {
      store.commit('wsStore/SET_STORE_VERSION', pluginInfo.version);
    }
    if (pluginInfo?.fileUrl) {
      store.commit('wsStore/SET_PLUGIN_URL', pluginInfo.fileUrl);
    }
  },
  async connect() {
    try {
      wsClient = new StackPushClient(`ws://localhost:8077`);
      await wsClient.connect();
      const version = await wsClient.send('GetPluginVersion');
      store.commit('wsStore/SET_VERSION', version.result);
      await store.dispatch('wsStore/connectBarcode');
    } catch {
      console.log('Подключиться не удалось!');
    }
  },

  // Получаем список доступных сканеров (twain)
  async checkScanDevices() {
    if (!state.isConnected || !wsClient) {
      return;
    }
    store.commit('wsStore/CLEAR_TWAIN_DEVICES');
    const res: SWSEnumerateDevices = await wsClient.send('Twain.enumerateDevices');
    if (res.defaultDeviceId) {
      store.commit('wsStore/SET_DEFAULT_DEVICE_ID', +res.defaultDeviceId);
    }
    for (const deviceId in res.devices) {
      store.commit('wsStore/ADD_TWAIN_DEVICE', res.devices[+deviceId]);
    }
  },
  async executeTwain({ commit }, payload: { device: string, fileName: string }) {
    if (!state.isConnected || !wsClient) {
      return;
    }
    const command = { deviceId: 0, fileName: payload.fileName, device: payload.device };
    const res: any = await wsClient.send('Twain.scanToFile', command);
    if (res.code && +res.code < 0) {
      Vue.prototype.$toast(`${res.code}  ${res.message}`, { color: 'error' });
    }
    return res?.files;
  },
  async close() {
    try {
      store.dispatch('wsStore/closeBarcode');
      await wsClient?.close();
    } catch {
      console.log('Отключиться не удалось!');
    }
  },
  async connectBarcode() {
    if (store.getters.getCurrentTaskName() !== 'Касса' && store.getters.getCurrentTaskName() !== 'ФЛ' && store.getters.getCurrentTaskName() !== 'Администрирование') {
      return;
    }
    if (!wsClient) {
      return;
    }
    try {
      const { ports } = await wsClient.SerialPort.enumeratePorts();
      store.commit('wsStore/SET_PORTS', ports);
      const settings = await store.dispatch('wsStore/getSettingsBarcode');
      if (settings.scanner1) {
        await wsClient.SerialPort.open({
          portName: settings.scanner1.portName,
          baudrate: settings.scanner1.baudrate,
          dataBits: settings.scanner1.dataBits,
          parity: settings.scanner1.parity,
          stopBits: settings.scanner1.stopBits,
          handshake: SerialPortHandshake.Disable,
          terminator: settings.scanner1.terminator,
        });
      } else if (ports[0] && ports[0] !== '') {
        await wsClient.SerialPort.open({
          portName: '\\\\.\\COM11',
          baudrate: SerialPortBaudrate.Baudrate_9600,
          dataBits: SerialPortDataBits.DataBits_8,
          parity: SerialPortParity.NoParity,
          stopBits: SerialPortStopBits.OneStopBit,
          handshake: SerialPortHandshake.Disable,
          terminator: '',
        });
      }
    } catch (error: AnyException) {
      console.log('Подключиться к сканеру штрих кода не удалось', error);
    }
  },
  async closeBarcode() {
    if (store.getters.getCurrentTaskName() !== 'Касса' && store.getters.getCurrentTaskName() !== 'ФЛ' && store.getters.getCurrentTaskName() !== 'Администрирование') {
      return;
    }
    if (!wsClient) {
      return;
    }
    try {
      await wsClient.SerialPort.close();
    } catch (error: AnyException) {
      console.log('Отключиться от сканера штрих кода не удалось', error);
    }
  },
  async enumeratePorts() {
    if (state.isConnected && wsClient) {
      try {
        return await wsClient.SerialPort.enumeratePorts();
      } catch (error: AnyException) {
        console.log('enumeratePorts error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async getSettings() {
    if (state.isConnected && wsClient) {
      try {
        return await wsClient.send('Settings.GetKassa');
      } catch (error: AnyException) {
        console.log('GetKassa error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async saveSettings({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        await wsClient.send('Settings.SaveKassa', params);
        store.commit('kassaStore/SET_INIT_PARAMETERS');
      } catch (error: AnyException) {
        console.log('SaveKassa error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
  },
  async getSettingsBarcode() {
    if (state.isConnected && wsClient) {
      try {
        return await wsClient.send('Settings.GetBarcodeScanner');
      } catch (error: AnyException) {
        console.log('GetBarcodeScanner error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async saveSettingsBarcode({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        await wsClient.send('Settings.SaveBarcodeScanner', params);
      } catch (error: AnyException) {
        console.log('SaveBarcodeScanner error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
  },
  async sendResource({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return await wsClient.Script.makeEngine(params);
      } catch (error: AnyException) {
        console.log('MakeEngine error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  // -------------
  // кассовые ф-ии. начало
  // -------------
  async checkErrorFR({ commit }, _err: any) {
    // обработка ошибки,
    // при отсутствии бумаги нужно обязательно сделать действия вручную
    if (_err.code === 99) {
      Vue.prototype.$stackMsg([
        {
          текст: `Отсутствует лента в ККТ.  
  - Необходимо внести платеж без печати чека. 
  - Допечатать чек через тест драйвера (операция "продолжить печать"). 
  - Произвести возврат чека 
  - Повторить процедуру печати`,
          варианты: ['Ок'],
        }]);
    }
  },
  async getDescription({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.GetDescription', params)).result;
      } catch (error: AnyException) {
        console.log('GetDescription error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async printCheck({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.PrintCheck', params)).result;
      } catch (error: AnyException) {
        console.log('PrintCheck error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
        store.dispatch('wsStore/checkErrorFR', error);
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async getDataCheck({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.GetDataCheck', params)).result;
      } catch (error: AnyException) {
        console.log('GetDataCheck error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async printCheckDouble({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        await wsClient.Script.dispatch('Kassa.PrintCheckDouble', params);
      } catch (error: AnyException) {
        console.log('PrintCheckDouble error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async printReport({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.Report', params)).result;
      } catch (error: AnyException) {
        console.log('PrintReport error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async getSumPerChange({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.SumPerChange', params)).result;
      } catch (error: AnyException) {
        console.log('SumPerChange error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async getNumberLastChange({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.NumberLastChange', params)).result;
      } catch (error: AnyException) {
        console.log('NumberLastChange error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async getCashInCashier({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.CashInCashier', params)).result;
      } catch (error: AnyException) {
        console.log('CashInCashier error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async cancelOpenCheck({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.CancelOpenCheck', params)).result;
      } catch (error: AnyException) {
        console.log('CancelOpenCheck error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async printPD({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.PrintPD', params)).result;
      } catch (error: AnyException) {
        console.log('PrintPD error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async cutCheck({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.CutCheck', params)).result;
      } catch (error: AnyException) {
        console.log('CutCheck error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async getCountDoc({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.NumberUnconfirmedDocuments', params)).result;
      } catch (error: AnyException) {
        console.log('NumberUnconfirmedDocuments error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async encashmentSum({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.WithdrawSum', params)).result;
      } catch (error: AnyException) {
        console.log('WithdrawSum error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async printPreCheck({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.PrintPreCheck', params)).result;
      } catch (error: AnyException) {
        console.log('PrintPreCheck error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async addDepositSum({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.DepositSum', params)).result;
      } catch (error: AnyException) {
        console.log('DepositSum error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async getNumberKKM({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.NumberKKM', params)).result;
      } catch (error: AnyException) {
        console.log('NumberKKM error', error);
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async execVerificationClosure({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.VerifyChangeClosureType', params)).result;
      } catch (error: AnyException) {
        console.log('VerifyChangeClosureType error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async cancelPayFromCard({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Terminal.Acq_CancelPayFromCard', params)).result;
      } catch (error: AnyException) {
        console.log('Acq_CancelPayFromCard error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async cancelCheck({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.CancelCheck', params)).result;
      } catch (error: AnyException) {
        console.log('CancelCheck error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async payFromCard({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Terminal.Acq_PayFromCard', params)).result;
      } catch (error: AnyException) {
        console.log('Acq_PayFromCard error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async getUncorruptedSumSales({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.UncorruptedSumSales', params)).result;
      } catch (error: AnyException) {
        console.log('UncorruptedSumSales error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async getSumByDepartment({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Kassa.SumByDepartment', params)).result;
      } catch (error: AnyException) {
        console.log('SumByDepartment error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  async posReport({ commit }, params) {
    if (state.isConnected && wsClient) {
      try {
        return (await wsClient.Script.dispatch('Terminal.Acq_posReport', params)).result;
      } catch (error: AnyException) {
        console.log('Acq_posReport error', error);
        Vue.prototype.$toast(error.message, { color: 'error' });
      }
    } else {
      Vue.prototype.$toast('Подключиться не удалось!', { color: 'error' });
    }
    return false;
  },
  // -------------
  // кассовые ф-ии. конец
  // -------------
};

const wsStore: Module<WSState, any> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default wsStore;
