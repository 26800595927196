
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      месяцНач: this.$store.getters.getCloseMonth(),
      месяцКнц: this.$store.getters.getCloseMonth(),
      лицевой: null,
      visible: false,
      dataModel: new this.$HttpModel('СервисныеОперацииФл'),
      openMonth: this.$store.getters.getOpenMonth(),
    };
  },
  methods: {
    async onSave(команда: string) {
      await this.dataModel.executeMethod(
        'РасчетСальдо',
        { команда, лицевой: this.лицевой, месяцНач: this.месяцНач, месяцКнц: this.месяцКнц },
        { async: true, jobName: 'Расчет сальдо', type: 'multi' },
      );
      this.$emit('close');
    },
    allowedMonths(value: string) {
      return Date.parse(value) < Date.parse(this.openMonth);
    },
    monthLessThan: (month1: string, month2: string, message = 'Месяц конца меньше месяца начала'): string | true => {
      return Date.parse(month1) <= Date.parse(month2) || !month1 || !month2 || message;
    },
    monthClosed(month: string, message = 'Месяц не закрыт'): string | true {
      return Date.parse(month) < Date.parse(this.openMonth) || message;
    },
  },
});
