
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
    noFormat: { type: Boolean, default: false },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        МесяцН: this.$store.getters.getWorkMonth(),
        МесяцК: this.$store.getters.getWorkMonth(),
        МесяцДолга: this.$store.getters.getWorkMonth(),
        НомерЗаписиУслуги: -1,
        НомерЗаписиКатегории: -1,
        НомерЗаписиПоставщика: -1,
        НомерЗаписиКатегорииПост: -1,
        НомерЗаписиУК: -1,
        НомерЗаписиУКДоговор: -1,
        Флаги: 3,
        Переключатель: 1,
        ПериодДолг: 0,
        УслугаВОтчет: '',
        КатегорияВОтчет: '',
        ПоставщикВОтчет: '',
        КатегорияПостВОтчет: '',
        УКВОтчет: '',
        ДоговорУКВОтчет: '',
      } as any,
    };
  },
  computed: {
    period(): boolean {
      return this.record.ПериодДолг !== 0;
    },
  },

  methods: {
    onChangeServ(payload: any, field: string, katfield: string) {
      if (payload && payload[0]) {
        switch (field) {
          case 'ПоставщикВОтчет':
          case 'КатегорияВОтчет':
          case 'КатегорияПостВОтчет':
          case 'УКВОтчет':
            this.record[field] = payload[0].$название ? payload[0].$название : payload[0].название ? payload[0].название : payload[0].наименование;
            break;
          case 'УслугаВОтчет':
            this.record[field] = '№ ' + payload[0]['номер услуги'] + ' ' + payload[0].наименование;
            break;
          case 'ДоговорУКВОтчет':
            this.record[field] = '№ ' + payload[0].номер + ' ' + payload[0].тема + ' ' + payload[0]['организация-укдоговор>название'];
            break;
          default:
            break;
        }
      }
      this.record[katfield] = -1;
    },
  },
});
