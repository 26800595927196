
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: {
      type: Object,
      default: (): any => {
        return {};
      },
    },
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        'Параметры.ДатНач': this.$store.getters.getWorkMonth(),
        'Параметры.ДатКнц': this.$store.getters.getWorkMonth(),
        'Параметры.НомерЗаписиУслуги': -1,
        'Параметры.НомерЗаписиКатегории': -1,
        'Параметры.НомерЗаписиПоставщика': -1,
        'Параметры.НомерЗаписиКатегорииПост': -1,
        'Параметры.НомерЗаписиКатегорииЛьгот': -1,
      } as any,
      valid: true,
    };
  },
  methods: {
    onChangeServ(id: number) {
      this.record['Параметры.НомерЗаписиКатегории'] = -1;
    },
    onChangeCategServ(id: number) {
      this.record['Параметры.НомерЗаписиУслуги'] = -1;
      if (+id === 0) {
        this.record['Параметры.флКат'] = 0;
        this.record['Параметры.флУслКат'] = 0;
      }
    },
    onChangePost(id: number) {
      this.record['Параметры.НомерЗаписиКатегорииПост'] = -1;
    },
    onChangeCategPost(id: number) {
      this.record['Параметры.НомерЗаписиПоставщика'] = -1;
    },
    buildReport() {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.$emit('build', this.record);
      }
    },
  },
});
