
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    dataModel: { type: [String, Object], default: 'РеестрПломб' },
  },
  data() {
    return {
      dateRule: (date1: string, date2: string): string | boolean => {
        return Date.parse(date1) >= Date.parse(date2) || !date1 || !date2 || 'Дата проверки пломбы не может быть меньше даты установки';
      },
    };
  },
  computed: {
    dataModelName(): String {
      return typeof this.dataModel === 'string' ? this.dataModel : this.dataModel.model;
    },
  },
});
