const lg: StackSelection = {
  recordRoute: '',
  objectName: 'ДетализацияПроверки',
  description: 'Список проблемных объектов',
  fields: [
    {
      field: 'текст',
      text: 'Текст',
      type: 'String',
    },
  ],
};

export default lg;
