
import Vue from 'vue';
import SearchToolbarAction from './SearchToolbarAction.vue';
import { StackSelectionCache } from '../../utils/selections';
import { Location } from 'vue-router';
import undisable from '../mixins/undisable';

export default Vue.extend({
  components: {
    SearchToolbarAction,
  },
  mixins: [undisable],
  inject: {
    isDialogReadonly: { default: () => () => false },
    historyState: { default: () => () => false },
  },
  props: {
    dark: { type: Boolean, default: false },
    params: { type: Object, default: undefined },
    dataModel: { type: [String, Object, Array], required: true, default: undefined },
    dataObject: { type: Object, default: undefined },
    noSearch: { type: Boolean, default: false }, // не показывать поиск в тулбаре
    history: { type: Boolean, default: false }, // история
    noRowAction: { type: Boolean, default: false }, // не показывать кнопки действий
    noDelete: { type: Boolean, default: false }, // нельзя удалять
    noAdd: { type: Boolean, default: false }, // не показывать кнопку добавить
    noCalcSum: { type: Boolean, default: false }, // не показывать кнопку суммирования записей
    isChanged: { type: Boolean, default: false }, // отображение кнопки сохранения
    closeBtn: { type: Boolean, default: false }, // кнопка закрытия
    tableAction: { type: Function, required: true }, // коллбек к таблице
    isFilterPresent: { type: Boolean, default: false }, // показывать кнопку фильтра или нет
    isFilterEnable: { type: Boolean, default: false }, // Активен ли фильтр или нет
    fastsearchFields: { type: String, default: undefined }, // перечень полей для быстрого поиска
    ownerID: { type: [String, Number], default: undefined },
    searchOnType: { type: Boolean, default: false }, // сразу начинать быстрый поиск при нажатии на клавиатуру
    currentRow: { type: Object, default: undefined }, // Текущая запись таблицы
    selectedItems: { type: Array, default: undefined }, // Выбранные записи
    selectedItemsOnPage: { type: Array, required: true }, // Выбранные записи на текущей странице
    noActionOnSelect: { type: Boolean, default: false }, // не менять данные туллбара на выделение
    noCopy: { type: Boolean, default: false }, // Запрет копирования
    noMove: { type: Boolean, default: false }, // Запрет переноса
    headers: { type: Array, default: () => [] },
    searchEnable: { type: Boolean, default: false }, // (Не)быстрый поиск вкл/выкл
    canMerge: { type: Boolean, default: false },
    merging: { type: Boolean, default: false },
    moving: { type: Boolean, default: false },
    actionLoading: { type: Boolean, default: false },
    fastSearchValue: { type: [String, Number], default: '' },
    ignoreReadonly: { type: Boolean, default: false },
  },
  data() {
    return {
      dial: false, // статус фабов
      // btnhistory: !this.history,
      // search: '', // значение строки быстрого поиска
      dialMoveVisible: false,
      treeItems: undefined as StackTableRow[] | undefined,
      sumDialVisible: false,
    };
  },
  computed: {
    btnhistory(): boolean {
      // @ts-ignore
      return this.historyState();
    },
    isReadonly(): boolean {
      // @ts-ignore
      return this.isDialogReadonly();
    },
    folderId(): number | undefined {
      return this.treeItems && this.treeItems[0] && this.treeItems[0].$номерЗаписи ? +this.treeItems[0].$номерЗаписи : undefined;
    },
    countSelected(): number {
      // @ts-ignore
      return this.selectedItemsOnPage.length;
    },
    toolbarOnSelected(): boolean {
      return this.hasSelectedItems && !this.noActionOnSelect;
    },
    hasSelectedItems(): boolean {
      return this.selectedItems && this.selectedItems.length > 0;
    },
    isInputDisabled(): boolean {
      // @ts-ignore
      return this.isReadonly && !this.ignoreReadonly;
    },
    isUpFolder(): boolean {
      return (this.params && this.params.parentID && this.params.parentID >= 0) || (this.currentRow && this.currentRow.$папка && this.currentRow.$папка >= 0);
    },
    hier(): boolean {
      return this.dataObject && this.dataObject.hier ? this.dataObject.hier : false;
    },
    fastLink(): Location | undefined {
      if (this.dataObject && this.dataObject.model) {
        const sel = StackSelectionCache.getSelectionDefinition(this.dataObject.model);
        if (sel && sel.openLink) {
          const loc = typeof sel.openLink === 'string' ? { name: sel.openLink } : sel.openLink;
          return typeof sel.openLink !== 'string' || loc.name !== this.$route.name ? loc : undefined;
        }
      }
      return undefined;
    },
    itemsTotals(): any {
      const f = (x: number) => (~(x + '').indexOf('.') ? (x + '').split('.')[1].length : 0);
      const totals: any = Vue.observable({});
      const selItems: StackTableRow[] = this.selectedItems as StackTableRow[];
      const headers: StackField[] = this.headers as StackField[];
      selItems.forEach((item: StackTableRow) => {
        for (const fieldName in item) {
          if (typeof item[fieldName] === 'number') {
            const headerInfo = headers.findIndex((item) => item.field === fieldName);
            if (headerInfo !== -1) {
              const name = headers[headerInfo].text;
              if (totals[name] === undefined) {
                Vue.set(totals, name, 0);
              }
              if (item[fieldName]) {
                // т.к. float даёт 000000001 округляем до нужного знака после запятой
                // @ts-ignore
                const digits = Math.max(f(+item[fieldName]), f(totals[name]));
                totals[name] = +(totals[name] + item[fieldName]).toFixed(digits);
              }
            }
          }
        }
      });
      return totals;
    },
  },
  methods: {
    onSearchFilter(search: string) {
      // убераем .00 из поиска если это чилсо
      search = search && !isNaN(+search) ? search.replace(/\.00$/, '') : search;
      // eslint-disable-next-line eqeqeq
      if (search != this.fastSearchValue) {
        this.sendParamToTable('filter', { поискСтрока: search, поискПоля: this.fastsearchFields, история: this.history ? this.btnhistory : undefined });
      }
    },
    onSearch(flag: boolean) {
      this.sendParamToTable('search', flag);
      this.$emit('search', flag);
    },
    onMoveRecords() {
      if (this.selectedItems.length !== this.countSelected) {
        this.$toast('Переносить записи можно только с одной страницы');
        return;
      }
      this.dialMoveVisible = true;
    },
    onCloseBtn() {
      this.$emit('close');
    },
    // кнопка истории
    onBtnHistory() {
      // this.btnhistory = !this.btnhistory;
      this.sendParamToTable('filter', { история: !this.btnhistory });
    },

    // шлём stack-table событие add,edit, и тд...
    sendParamToTable(action: string, payload?: any) {
      this.tableAction({ action, payload });
    },

    saveRecords() {
      this.sendParamToTable('save');
    },
  },
});
