
import Vue from 'vue';

import TwoTableExchange from './TwoTableExchange.vue';
import tableHeadersLeft from '@/tasks/fl/selections/accountsplit/ДелениеЛицевого.Жильцы до';
import tableHeadersRight from '@/tasks/fl/selections/accountsplit/ДелениеЛицевого.Жильцы после';
import PeopleSubDialog from '@/tasks/common/components/StackPeopleLinkFieldDialog.vue';

export default Vue.extend({
  components: { TwoTableExchange, PeopleSubDialog },
  props: {
    record: { type: Object, required: true },
    disabled: { type: Boolean, required: true },
    runCreation: { type: Boolean, required: true },
    filter: { type: [Object], default: null },
  },
  computed: {
    paramGeneral(): object {
      return { лицевой: this.record.лицевой, дата: this.record.дата };
    },
  },
  data() {
    return {
      isSave: false,
      headersLeft: tableHeadersLeft.fields as StackField[],
      headersRight: tableHeadersRight.fields as StackField[],
      value: true,
      visibleLeftOwnerDialog: false,
      visibleRightOwnerDialog: false,
      arrayTypesReg: [] as StackTableRow[],
    };
  },
  mounted() {
    this.initializationTable();
  },

  methods: {
    async initializationTable() {
      this.arrayTypesReg = await new this.$HttpModel('КарточкиРегистрации.ВидыРегистрации').getRecords();
    },
    apply() {
      // @ts-ignore
      const recordsNew = this.$refs.twoTables.getRightTable();
      let countEmployerNew = recordsNew.length ? 0 : 1;
      for (const ind in recordsNew) {
        if (recordsNew[ind].титульный === 1) {
          countEmployerNew++;
        }
      }
      // @ts-ignore
      const recordsOld = this.$refs.twoTables.getLeftTable();
      let countEmployerOld = 0;
      for (const ind in recordsOld) {
        if (recordsOld[ind].титульный === 1) {
          countEmployerOld++;
        }
      }

      if (recordsNew.length > 0 && recordsOld.length > 0) {
        if (countEmployerNew >= 1 && countEmployerOld >= 1) {
          this.isSave = true;
          this.$emit('save', { выбНовыхЖильцов: recordsNew, выбСтарыхЖильцов: recordsOld });
        } else {
          this.$toast(!countEmployerOld ? 'На старом лицевом должен быть наниматель' : 'На новом лицевом должен быть наниматель', { color: 'error' });
        }
      } else {
        this.$toast(!recordsOld.length ? 'На старом лицевом должен быть наниматель' : 'На новом лицевом должен быть наниматель', { color: 'error' });
      }
    },
    // кнопка Сделать нанимателем
    makeEmployer(selectedItems: StackTableRow[], dataObject: any) {
      if (selectedItems.length === 0) {
        this.$toast('Необходимо отметить запись');
        return;
      }
      let fioNoVlad = '';
      for (const indS in selectedItems) {
        for (const ind in dataObject.data) {
          if (dataObject.data[ind].row_id === selectedItems[indS].row_id) {
            if (selectedItems[indS].титульный === 1) {
              this.$set(dataObject.data[ind], 'титульный', 0);
              this.$set(dataObject.data[ind].$data, 'титульныйиконка', { $иконка: null });
            } else {
              if (selectedItems[indS].доквлад === 0) {
                fioNoVlad += '\n' + selectedItems[indS].фио;
              }
              this.$set(dataObject.data[ind], 'титульный', 1);
              this.$set(dataObject.data[ind].$data, 'титульныйиконка', { $иконка: '$vuetify.icons.check' });
            }
            dataObject.data[ind].$selected = false;
          }
        }
      }
      if (fioNoVlad.length > 0) {
        this.$toast('В связи с отсутствием документа на владение будет создан фиктивный документ для:' + fioNoVlad);
      }
      // @ts-ignore
      this.$refs.twoTables.fetchData();
    },
    async onSelectLeft(selectedRows: StackTableRow[]) {
      if (selectedRows && selectedRows.length > 0) {
        this.visibleLeftOwnerDialog = false;
        if (!('выбСтарыхЖильцов' in this.record)) {
          this.record['выбСтарыхЖильцов'] = [];
        }
        const row = this.prepareRow(selectedRows[0]);
        this.record['выбСтарыхЖильцов'].push(row);
        // @ts-ignore
        this.$refs.twoTables.onSelectLeft(row);
      }
    },
    async onSelectRight(selectedRows: StackTableRow[]) {
      if (selectedRows && selectedRows.length > 0) {
        this.visibleRightOwnerDialog = false;
        if (!('выбНовыхЖильцов' in this.record)) {
          this.record['выбНовыхЖильцов'] = [];
        }
        const row = this.prepareRow(selectedRows[0]);
        this.record['выбНовыхЖильцов'].push(row);
        // @ts-ignore
        this.$refs.twoTables.onSelectRight(row);
      }
    },
    onClose() {
      this.visibleLeftOwnerDialog = false;
      this.visibleRightOwnerDialog = false;
    },
    prepareRow(row: StackTableRow) {
      const preparedRow = {} as any;
      preparedRow.row_id = row.$номерЗаписи;
      preparedRow.dv_id = -1;
      preparedRow.kr_id = -1;
      preparedRow.видрег = '';
      preparedRow.доквлад = 0;
      preparedRow.титульный = 0;
      preparedRow.фио = row.фио;
      preparedRow.доквладиконка = '';
      preparedRow.титульныйиконка = '';
      preparedRow.$selected = false;
      preparedRow.$номерЗаписи = row.$номерЗаписи;
      return preparedRow;
    },
  },
});
