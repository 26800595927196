
import Vue from 'vue';
import Mfolder from '@/tasks/common/views/$common/folderCommon.vue';
import AdrPrintBtn from '@/tasks/fl/views/accounts/$accountsid/AdrPrintBtn.vue';
import AdrFilter from '@/tasks/fl/views/$common/filters/AdrFilter.vue';

export default Vue.extend({
  components: { Mfolder, AdrPrintBtn, AdrFilter },
  data() {
    return {
      newRecordVisible: false,
      params: { parentID: -10 },
      loading: false,
      dataModel: new this.$HttpModel('РеестрыЛицевых'),
      loadLsDialog: false,
      date: this.$store.getters.getCurrentDate(),
      files: [],
      jobID: null as null | number,
    };
  },
  computed: {
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
  },
  methods: {
    async onClick(item: StackTableRow) {
      const id = item['реестры_состав-лицевой'];
      if (id && +id > 0) {
        // this.$router.replace('/accounts?parentID=' + id);
        window.open(this.$router.resolve('/accounts?row_id=' + id).href, '_blank');
      }
    },
    async loadLs() {
      this.loadLsDialog = false;
      this.jobID = (
        await this.dataModel.executeMethod('ПодкачатьЛС', { files: this.files, дата: this.date }, {
          async: true,
          jobName: 'Подкачка ЛС',
        })).asyncId;
    },
    addRecords(tableInfo: any) {
      this.params.parentID = tableInfo.parentID;
      this.newRecordVisible = true;
    },
    async toInputReadings(selectedValues: string) {
      this.loading = true;
      const result = await this.dataModel.executeMethod('ПолучитьЛицевые', { лицевые: selectedValues });
      this.loading = false;
      if (result) {
        this.$router.push({
          name: 'metersdata-readings-massinput',
          query: { владелец: result },
        });
      }
    },
    async onBtnChooseAccounts(items: StackTableRow[]) {
      for (const ind in items) {
        await this.dataModel.createRecord(
          {
            'реестры_состав-лицевой': items[ind].$номерЗаписи,
            'реестры_состав-дом': -1,
            название: items[ind].$название,
            $папка: this.params.parentID,
          },
          {
            папка: this.params.parentID,
          },
        );
      }

      this.$sendMsg('stack-table', 'РеестрыЛицевых', 'reload');
      this.newRecordVisible = false;
    },
  },
  watch: {
    isComplete(to: boolean) {
      if (to) {
        this.$sendMsg('stack-table', 'РеестрыЛицевых', 'reload');
      }
    },
  },
});
