import { TableHandlers } from '@/stackEngine';
const ci: StackSelection = {
  recordRoute: 'infocenter-campaigns-infocenterid',
  objectName: 'ЦентрИнформирования',
  description: 'Реестр кампаний ЦИ',
  hier: true,
  prepareRow: item => {
    if (!item.$data) {
      item.$data = {};
    }
    // @ts-ignore
    item.$data['@статус'] = { $иконка: '$vuetify.icons.ci_stop', $иконка_цвет: 'primary' };
    switch (item.статус) {
      case 0:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.ci_stop', 'primary');
        break;
      case 1:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.ci_send', 'primary');
        break;
      case 2:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.ci_play', 'primary');
        break;
      case 3:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.ci_pause', 'primary');
        break;
      case 4:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.done', 'primary');
        break;
    }

    // @ts-ignore
    item.$data['@транспорт'] = { $иконка: '$vuetify.icons.phone', $иконка_цвет: 'blue' };
    switch (item.транспорт) {
      case 0:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.phone', 'blue');
        break;
      case 1:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.sms', 'blue');
        break;
      case 2:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.cellphone', 'green');
        break;
      case 3:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.email', 'green');
        break;
      case 4:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.person_box', 'green');
        break;
      case 5:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.person_card', 'green');
        break;
      case 8:
        TableHandlers.tdIcon(item, '@транспорт', '$vuetify.icons.sms', 'blue');
        break;
    }
    return item;
  },
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'Статус',
      text: 'статус',
      type: 'Number',
    },
    {
      field: 'попыток',
      text: 'Попыток',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'комментарий',
      text: 'Комментарий',
      type: 'String',
    },
    {
      field: 'датнач',
      text: 'ДатНач',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'ДатКнц',
      type: 'Date',
    },
    {
      field: '@времянач',
      text: 'ВремяНач',
      type: 'String',
    },
    {
      field: '@времякнц',
      text: 'ВремяКнц',
      type: 'String',
    },
    {
      field: '@транспорт',
      text: ' ',
      type: 'String',
      computed: true,
    },
    {
      field: '@статус',
      text: '  ',
      type: 'String',
      computed: true,
    },
    {
      field: '@получателей',
      text: 'Получателей',
      type: 'String',
      computed: true,
    },
    {
      field: '@успех',
      text: 'Успешных',
      type: 'String',
      computed: true,
    },
    {
      field: '@неуспех',
      text: 'Неуспешных',
      type: 'String',
      computed: true,
    },
    {
      field: '@впроцессе',
      text: 'В процессе',
      type: 'String',
      computed: true,
    },
    {
      field: 'авторс',
      text: 'Автор',
      type: 'String',
    },
    {
      field: 'автор',
      text: 'Автор',
      type: 'String',
    },
    {
      field: 'отправитель',
      text: 'Отправитель',
      type: 'String',
    },
  ],
};
export default ci;
