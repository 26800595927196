
import Vue from 'vue';
import FileTable from '@/tasks/common/views/$common/fileTable.vue';

export default Vue.extend({
  components: { FileTable },
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    ownerID: { type: [String, Number], required: true },
  },
  computed: {
    openMonth(): Date {
      return this.$store.getters.getOpenMonth();
    },
    isReadonly(): boolean {
      if (+this.record.$номерЗаписи > 0 && this.record.месяц !== undefined && Date.parse(this.record.месяц.toString()) < Date.parse(this.openMonth.toString())) {
        return true;
      }
      return false;
    },
  },
});
