import { TableHandlers } from '@/stackEngine';

const ved: StackSelection = {
  objectName: 'ВедомостиСтороннихПоказаний.Ведомость',
  description: 'Показания',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.флагошибок && row.флагошибок > 0) {
      TableHandlers.trColor(row, 'red');
    }
    if (row['контроль-показание'] && row['контроль-показание'] > 0) {
      TableHandlers.trColor(row, 'indigo');
    }
    TableHandlers.isClosed(row);
    return row;
  },
  fields: [
    {
      field: 'номерлс файл',
      text: 'Л/с\nпереданный',
      type: 'Number',
    },
    {
      field: 'адреслс файл',
      text: 'Адрес',
      type: 'String',
      minwidth: '200px',
    },
    {
      field: 'услуга файл',
      text: 'Услуга',
      type: 'Enum',
    },
    {
      field: 'заводскойномер файл',
      text: 'Зав.номер\n счетчика',
      type: 'String',
      width: '150px',
    },
    {
      field: 'типсчетчика файл',
      text: 'Марка',
      type: 'String',
    },
    {
      field: 'апи_зона',
      text: 'Зона\n суток',
      type: 'String',
      width: '75px',
      computed: true,
    },
    {
      field: '@зона',
      text: 'Зона суток массив',
      type: 'String',
      computed: true,
    },
    {
      field: '@критичность',
      text: 'Критичность',
      type: 'Number',
      computed: true,
    },
    {
      field: '@датпредсч',
      text: 'Дата посл.пок.',
      type: 'Date',
      computed: true,
    },
    {
      field: '@меспредсч',
      text: 'Месяц пред. пок.',
      type: 'Date',
      computed: true,
    },
    {
      field: 'расчетный месяц',
      text: 'Месяц пок.',
      type: 'Date',
    },
    {
      field: '@покпредсч',
      text: 'Посл. расч.\n показ.',
      type: 'NumberZero',
      computed: true,
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'показание',
      text: 'Показание',
      type: 'NumberZero',
      align: 'center',
      maxwidth: '100px',
    },
    {
      field: 'расход',
      text: 'расход',
      type: 'NumberZero',
    },
    {
      field: 'контроль-показание>итоговый расход',
      text: 'Расчетный\n расход',
      type: 'NumberZero',
    },
    {
      field: 'строшибка',
      text: 'Ошибка',
      type: 'String',
    },
    {
      field: 'флагошибок',
      text: 'Флаг ошибок',
      type: 'Number',
    },
    {
      field: 'адреслс',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'номер',
      text: 'НомерЛС',
      type: 'Number',
    },
    {
      field: 'типввода',
      text: 'типввода',
      type: 'Number',
    },
    {
      field: 'тариф',
      text: 'тариф',
      type: 'Number',
    },
    {
      field: 'объект-контроль',
      text: 'ПУ1',
      type: 'Link',
    },
    {
      field: 'контроль-счет',
      text: 'ЛС1',
      type: 'Link',
    },
    {
      field: 'контроль-показание',
      text: 'ПОК1',
      type: 'Link',
    },
    {
      field: 'номер одпу файл',
      text: '№ ОДПУ',
      type: 'String',
      computed: true,
    },
    {
      field: 'одпу файл',
      text: 'ОДПУ',
      type: 'String',
      computed: true,
    },
    {
      field: '@типпредсч',
      text: 'Тип пред. пок',
      type: 'String',
      computed: true,
    },
    {
      field: 'объект-контроль>коэффициент трансформации',
      text: 'Коэффициент трансформации',
      type: 'Number',
    },
    {
      field: 'объект-контроль>разрядность',
      text: 'Разрядность',
      type: 'Number',
    },
    {
      field: 'договор',
      text: '№ Договора',
      type: 'String',
    },
    {
      field: 'плательщикназвание',
      text: 'Плательщик',
      type: 'String',
    },
    {
      field: 'контроль-счет>номер',
      text: '№ ТУ',
      type: 'String',
    },
    {
      field: 'контроль-счет>примечание',
      text: 'Наименование ТУ',
      type: 'String',
    },
  ],
};
export default ved;
