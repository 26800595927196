
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    showDate: { type: Boolean, default: false },
    hint: { type: String, default: '' },
    type: { type: String, default: 'string' },
    suffix: { type: String, default: '' },
    typeDate: { type: String, default: 'date' },
    testId: { type: String, default: '' },
  },
});
