
import Vue from 'vue';
import basemethod from './basemethod.vue';
import ServicesactsAddDialog from '../$lawsuits/servicesactsAddDialog.vue';
export default Vue.extend({
  extends: basemethod,
  components: {
    'ServicesactsAdd-dialog': ServicesactsAddDialog,
  },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },

  data() {
    return {
      actvisible: true,
      dataObject: new this.$HttpModel('СостоянияДела'),
      loading: false,
    };
  },
  computed: {
    aktotkl(): boolean {
      return !!(this && this.record && this.record.$блокинит && this.record['@операции'].indexOf('АКТОТКЛЮЧЕНИЯ') !== -1);
    },
  },

  methods: {
    async changeDeliveryField(delivery: any) {
      if (delivery > 0) {
        let rec = this.record;
        const result = await this.dataObject.executeMethod('ПроверкаПолей', { поле: 'Доставка', сдела: rec });
        rec = Object.assign(rec, result);
        this.$emit('change', rec);
      }
    },
    async setact(data: any) {
      this.loading = true;
      await new this.$HttpModel('ОперацииЕдиничногоПерехода').executeMethod('ВыполнитьОперацию', {
        состояние: this.record.$номерЗаписи,
        длг: data,
        тип: 'АктОтключения',
      });
      this.loading = false;
      this.actvisible = false;
      this.$emit('update');
    },
  },
});
