
import Vue from 'vue';
import helpHotkeys from './helpHotkeys.vue';
import SpeedometerDialog from './SpeedometerDialog.vue';
import { helloToolbar, helloDial, helloTable, appToolbar, stackTable, stackDialog } from './StackAppTour/tours';

export default Vue.extend({
  components: {
    helpHotkeys,
    SpeedometerDialog,
  },
  data() {
    return {
      dial: false,
      tour: false,
      speedDial: false,
    };
  },
  props: {
    value: { type: Boolean, default: false },
  },
  computed: {
    getHelpUrl(): string | undefined {
      return this.$store.getters.getHelpUrl();
    },
    items(): any {
      return [
        { id: 1, title: 'Начало работы', description: 'Ознакомьтесь с интерфейсом программы', icon: '$help_start', disabled: false },
        // { id: 2, title: 'Интерактивные туры', description: 'Изучите основные функции и рабочие процессы', icon: '$help_tours', disabled: true },
        { id: 3, title: 'Горячие клавиши', description: 'Узнайте как упростить и ускорить работу', icon: '$help_hotkeys', disabled: false },
        { id: 4, title: 'Руководство пользователя', description: 'Ознакомьтесь с возможностями программы', icon: '$help_online', href: this.getHelpUrl, disabled: !this.getHelpUrl },
        { id: 5, title: 'История версий', description: 'Будьте в курсе последних изменений в программе', icon: '$help_history', to: '/whatsnew' },
        // { id: 6, title: 'Обратная связь', description: 'Задайте вопрос, поделитесь своим мнением, сообщие об ошибке', icon: '$help_askme', disabled: true },
        { id: 10, title: 'Тест скорости соединения', description: 'Проверьте скорость вашего интернет-канала', icon: '$speedometer' },
      ];
    },
    tours(): any {
      return [
        { id: 7, title: 'Панель инструментов (тулбар)', icon: '$pointer', disabled: false },
        { id: 8, title: 'Окно диалога', icon: '$aplication', disabled: false },
        { id: 9, title: 'Работа с таблицей', icon: '$table', disabled: false },
      ];
    },
  },
  methods: {
    back() {
      this.tour = false;
    },
    setDrawerState(state: boolean) {
      this.$store.commit('SET_HELP_STATE', state);
    },
    setTourState(steps: StackTourStep[]) {
      this.$store.commit('SET_HELP_STATE', false);
      this.$store.commit('SET_HELP_STEPS', steps);
      this.$store.commit('SET_HELP_TOUR_STATE', true);
    },
    onOpen(item: any) {
      switch (item.id) {
        case 1:
          this.tour = true;
          break;
        case 3:
          this.dial = true;
          break;
        case 4:
          window.open(item.href, '_blank');
          break;
        case 5:
          this.$store.commit('SET_HELP_STATE', false);
          break;
        case 7:
          this.setTourState([...helloToolbar, ...appToolbar]);
          break;
        case 8:
          this.setTourState([...helloDial, ...stackDialog]);
          break;
        case 9:
          this.setTourState([...helloTable, ...stackTable]);
          break;
        case 10:
          this.speedDial = true;
          break;
      }
    },
  },
});
