
import Vue from 'vue';
import UchNetDialog from './$dialogs/UchNetDialog.vue';

export default Vue.extend({
  model: { prop: 'record' },
  components: { UchNetDialog },
  name: 'UchNetTab',
  props: {
    record: { type: Object, required: true },
  },
});
