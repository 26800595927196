
import Vue from 'vue';
import PersonFilter from '@/tasks/common/views/$common/filters/PersonFilter.vue';

export default Vue.extend({
  model: { prop: 'record', event: 'input' },
  components: { PersonFilter },
  data() {
    return {
      selectedResidents: [] as StackTableRow[], // выделенные записи в таблице
    };
  },
  methods: {
    addResidentUr() {
      // @ts-ignore
      this.$refs.people.onToolBarAction({ action: 'add', payload: { типжильца: 1 } });
    },
    async changeTypeFizUr() {
      if (this.selectedResidents && this.selectedResidents.length > 0 && this.selectedResidents[0]) {
        const residentId = this.selectedResidents[0].$номерЗаписи as number;
        const residentType = this.selectedResidents[0].типжильца as number;
        if (residentId !== undefined && residentType !== undefined) {
          if (residentType === 1 && !(await this.$yesno('Данная карточка является организацией.Сделать карточку жильцом ?'))) {
            return;
          }
          const dataObject = new this.$HttpModel('ЧастныеЛица');
          const result = await dataObject.executeMethod('ИзменитьТипФизЮр', { нзЖильца: residentId, типЖильца: residentType });
          if (result === 1) {
            // @ts-ignore
            this.$refs.people.fetchData();
          } else {
            this.$toast('Операция не выполнена!');
          }
        }
      }
    },
  },
});
