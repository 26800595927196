const serv: StackSelection = {
  objectName: 'ЧастныеЛица.Регистрация',
  description: 'Регистрация жильца',
  fields: [
    {
      field: 'дата прописки',
      text: 'Дата прописки',
      type: 'Date',
    },
    {
      field: 'дата выписки',
      text: 'Дата выписки',
      type: 'Date',
    },
    {
      field: 'человек-лицевой>номер',
      text: 'Номер лицевого',
      type: 'Number',
    },
    {
      field: '@адрес',
      text: 'Адрес',
      type: 'String',
    },
  ],
};

export default serv;
