const report: StackSelection = {
  objectName: 'СлужебныеОтчеты_Реестр_Начисления',
  description: '',
  fields: [
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
  ],
};
export default report;
