
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      files: [] as StackTableRow[],
      filesTable: false,
      loadingBtn: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async upload() {
      try {
        await new this.$HttpModel('ВыгрузкиПодкачки').executeMethod(
          'Подкачать',
          { files: this.files, подкачка: 'Подкачка платежей от Сбербанка' },
          {
            async: true,
            jobName: 'Подкачка платежей от Сбербанка',
          },
        );
        this.files = [];
      } catch (error: AnyException) {
        this.$toast(error);
      }
    },
    async fetchData() {
      const record = await new this.$HttpModel('ВыгрузкиПодкачки').initRecord({ подкачка: 'Подкачка платежей от Сбербанка' });
      this.filesTable = (record && record.типстрока && record.типстрока !== '') || false;
    },
    selectedFiles(files: StackTableRow[]) {
      const tempFiles = [] as any;
      for (const ind in files) {
        tempFiles.push({ name: files[ind].файл });
      }
      this.files = [].concat(tempFiles);
    },
  },
});
