
import Vue from 'vue';
import problempays from '@/tasks/fl/views/payments/paylists/_paylistsid/problempays/_problempaysid.vue';

export default Vue.extend({
  components: { problempays },
  props: {
    problempaysid: { type: [Number, String], default: undefined },
    paylistsid: { type: [Number, String], default: undefined },
    paymentsreceiptsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
  },
});
