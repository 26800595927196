const acts: StackSelection = {
  objectName: 'НастройкаРолиОрганизации',
  description: 'Настройка роли организации',
  fields: [
    {
      field: 'роль',
      text: 'Роль',
      type: 'Number',
    },
    {
      field: 'участок',
      text: 'Участок',
      type: 'Number',
    },
  ],
};
export default acts;
