
import Vue from 'vue';

export default Vue.extend({
  props: {
    disabled: { type: Boolean, required: true },
    accounts: { type: String, default: null },
    month: { type: String, default: null },
  },
  computed: {
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
  },
  data() {
    return {
      фонд: '',
      jobID: null as null | string,
    };
  },
  created() {
    this.фонд = this.accounts ? this.accounts : '';
  },
  methods: {
    async prepareSBP() {
      this.jobID = (await new this.$HttpModel('СБП_ПолучитьТокены').executeMethod('получить', { Фонд: this.фонд }, { async: true })).asyncId;
      this.$store.commit('MSG_ADD', { title: 'Получение токенов с сервиса СБП для новых лицевых счетов', asyncId: this.jobID });
      this.$emit('run');
    },
  },
  watch: {
    accounts(to) {
      this.фонд = to;
    },
    isComplete(to) {
      if (to) {
        this.jobID = null;
        this.$emit('end', { status: 'success', accounts: this.фонд });
      }
    },
    isError(to) {
      if (to) {
        this.jobID = null;
        this.$emit('end', { status: 'error', accounts: this.фонд });
      }
    },
    resultJob(to) {
      if (!Object.keys(to).length) {
        this.jobID = null;
      }
    },
  },
});
