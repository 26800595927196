const ccc: StackSelection = {
  objectName: 'Города.АдресныйСписок',
  description: 'Адреса',
  fields: [
    {
      field: 'ключ',
      text: 'ключ',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
  ],
};
export default ccc;
