
import Vue from 'vue';
export default Vue.extend({
  props: {
    drawer: { type: Boolean, required: true },
  },
  computed: {
    getNavBarWidth(): number {
      return this.$store.getters.getNavBarWidth();
    },
  },
  methods: {
    drawerChange() {
      this.$store.commit('SET_DRAWER_STATE', !this.drawer);
    },
  },
});
