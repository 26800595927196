
import Vue from 'vue';
import CommonTab from './$accountsid/CommonTab.vue';
import PokTab from './$accountsid/PokTab.vue';
import PaymentsTab from './$accountsid/PaymentsTab.vue';
import CalcTab from './$accountsid/CalcTab.vue';
import ServicesTab from './$accountsid/ServicesTab.vue';
import CountersTab from './$accountsid/CountersTab.vue';
import DocumentsTab from './$accountsid/DocumentsTab.vue';
import DebtTab from './$accountsid/DebtTab.vue';
import OthersTab from './$accountsid/OthersTab.vue';
import recalcDialog from './$resources/RecalcDialog.vue';
import UchNetTab from './$accountsid/UchNetTab.vue';

import { ReportsBuilder } from '@/stackEngine';
import AdrPrintBtn from './$accountsid/AdrPrintBtn.vue';

export default Vue.extend({
  components: {
    CommonTab,
    PokTab,
    PaymentsTab,
    CalcTab,
    ServicesTab,
    CountersTab,
    DocumentsTab,
    OthersTab,
    recalcDialog,
    AdrPrintBtn,
    DebtTab,
    UchNetTab,
  },
  props: {
    accountsid: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      tabs: null,
      record: {} as StackTableRow,
      recalcDialogVis: false,
      dataObject: new this.$HttpModel('ЛицевыеСчета'),
      isRecordDeleted: false as boolean,
    };
  },
  computed: {
    isNewRecord(): boolean {
      return this.accountsid === 'new';
    },
    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
    lockIcon(): string {
      if (this.record && this.record['месяц блокировки'] && this.record.закрытыймесяц && this.record.месблок) {
        if (this.record.месблок === 'Месяц закрыт') {
          return '$vuetify.icons.lock';
        }
        if (this.record.месблок === 'Месяц открыт в адм. режиме') {
          return '$vuetify.icons.month_adm';
        }
        return '$vuetify.icons.unlock';
      }
      return '';
    },
    lockIconColor(): string {
      if (this.record && this.record['месяц блокировки'] && this.record.закрытыймесяц && this.record.месблок) {
        return this.record.месблок === 'Месяц закрыт' ? 'error' : 'warning';
      }
      return '';
    },
    title(): string {
      return this.record.номер ? 'Лицевой счет № ' + this.record.номер : '';
    },
    tabTitle(): string {
      return this.record.номер ? 'ЛС №' + this.record.номер : '';
    },
    showUchNet(): boolean {
      return this.$store.getters['flStore/getLossesEl']();
    },
  },
  async beforeRouteLeave(to, from, next) {
    const masCheck = await new this.$HttpModel('ЛицевыеСчета').executeMethod('ПроверитьИзменения', { владелец: this.record.$номерЗаписи });
    const firstMonthRecalc = masCheck.месяц;
    const warningMsg = masCheck.предупреждение;
    if (this.isNewRecord && !this.isRecordDeleted) {
      if (Number(this.record.$номерЗаписи) >= 0) await this.dataObject.deleteRecords([this.record]);
    }
    if (warningMsg) {
      this.$toast(warningMsg, { color: 'info', icon: '$vuetify.icons.alert_outline' });
    }
    if (firstMonthRecalc) {
      const answer = await this.$yesno('Данные были изменены. Хотите запустить перерасчет?');
      if (answer) {
        this.recalcDialogVis = true;
        this.record.перерасчет = firstMonthRecalc;
      } else {
        next();
      }
    } else {
      next();
    }
  },
  methods: {
    onCalcTab() {
      if (this.$refs.calctab) {
        // @ts-ignore
        this.$refs.calctab.calcLS();
      }
    },
    onPrintKvit() {
      new ReportsBuilder('ЛицевыеСчета').executeReport2(
        'Квитанция',
        { 'Список лицевых': this.record.$номерЗаписи, 'Месяц формирования': this.workMonth, 'Вызов из веба': true, Подготовка: true },
        { title: `Квитанция по лицевому ${this.record.номер}  за ${this.$stackDate.format(this.workMonth, 'DD.MM.YYYY')}` },
      );
    },
    async updateBalance(payload: any) {
      if (payload === 'pok') {
        const tableModels = [
          'ЛицевыеСчета.ПоказанияСчетчиковОбщеквартирные',
          'ЛицевыеСчета.ПоказанияСчетчиковИндивидуальные',
          'ЛицевыеСчета.РаспределенныйРасходОДПУ',
          'ЛицевыеСчета.СторонниеПоказания',
        ];
        tableModels.forEach((model) => this.$sendMsg('stack-table', model, 'reload'));
      }
      if (payload === 'others') {
        this.$sendMsg('stack-table', 'ЛицевыеСчета.История', 'reload');
      }
    },
    async onBeforeClose(item: StackTableRow) {
      if (this.isNewRecord) {
        if (Number(item.$номерЗаписи) >= 0) await this.dataObject.deleteRecords([item]);
        this.isRecordDeleted = true;
      }
      return true;
    },
  },
  watch: {
    workMonth() {
      // @ts-ignore
      if (!this.isNewRecord) this.$refs.dialog.fetchData();
    },
  },
});
