
import Vue from 'vue';

export default Vue.extend({
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      item: {
        '@ТипСуммы': 0,
        '@ТипПроцента': 0,
        '@ВидПроцента': 0,
        Срок: 0,
        Процент: 0,
        Дата: '',
      },
      loading: false,
    };
  },
  created() {
    this.initialization();
  },
  methods: {
    async initialization() {
      const res = await new this.$HttpModel('ВедомостиРазовыхНачислений').executeMethod('ИнициализацияДиалогаРассрочки', {
        номерЗаписи: this.record.$номерЗаписи,
      });
      if (res) {
        this.item = Object.assign({}, this.item, res);
      }
    },
    async save() {
      this.loading = true;
      let res = 0;
      try {
        res = await new this.$HttpModel('ВедомостиРазовыхНачислений').executeMethod('СоздатьРассрочку', {
          номерЗаписи: this.record.$номерЗаписи,
          допДанные: this.item,
        });
      } catch { // error
      }
      this.$emit('save', res);
      this.loading = false;
    },
  },
});
