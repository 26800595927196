
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    dataModel: { type: Object, required: true },
  },
  computed: {
    subsOperation(): boolean {
      return this.isSubs || this.record.типвыгрузкиосзн === 3;
    },
    chooseFile(): boolean {
      return (
        this.record.типвыгрузкиосзн === 1 ||
        this.record.типвыгрузкиосзн === 3 ||
        this.record.типвыгрузкиосзн === 5 ||
        this.record.типвыгрузкиосзн === 6 ||
        this.record.типвыгрузкиосзн === 7
      );
    },
  },
  data() {
    return {
      isSubs: false,
      isError: this.record.типвыгрузкиосзн === 3,
    };
  },
  methods: {
    async getParam() {
      if (this.record.типвыгрузкиосзн !== 3) {
        const response = await new this.$HttpModel('ОСЗН_ОпределитьРегион').getRecords(this.record);
        if (response && response[0] && response[0].значение !== undefined && response[0].значение !== null) {
          this.$set(this.record, 'типвыгрузкиосзн', +response[0].значение);
        }
      }
    },
    async uploadFiles(files: any) {
      this.record.files = files;
      if (files.length && this.subsOperation) {
        try {
          const response = await this.dataModel.executeMethod('ПроверкаДанныхОСЗН_Субсидии', this.record);
          if (response) {
            this.isSubs = true;
            this.isError = true;
          } else {
            this.isError = false;
            this.isSubs = false;
          }
        } catch (error: AnyException) {
          this.isError = true;
        }
      }
    },
  },
});
