const actsqu: StackSelection = {
  recordRoute: 'references-paymentreduction-paymentreductionid',
  objectName: 'ВидыСниженияОплаты',
  description: 'Виды снижения оплаты',
  hier: true,
  openLink: 'references-paymentreduction',
  fields: [
    {
      field: 'код',
      text: 'Код',
      type: 'Number',
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
    {
      field: 'комментарий',
      text: 'Комментарий',
      type: 'String',
    },
    {
      field: 'допустимочасов',
      text: 'Допустимо часов',
      type: 'Number',
    },
    {
      field: '@номеруслуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'допустимочасоввсего',
      text: 'Всего допустимо часов',
      type: 'Number',
    },
    {
      field: 'коэфштрафа',
      text: 'Коэффициент штрафа',
      type: 'Number',
    },
    {
      field: '@типснятия',
      text: 'Тип снятия',
      type: 'String',
    },
    {
      field: 'типснятия',
      text: 'ТипСнятия',
      type: 'Number',
    },
    {
      field: 'показатели-услуга',
      text: 'Показатели-Услуга',
      type: 'Link',
    },
    {
      field: 'показатели-допуслуга',
      text: 'Показатели-ДопУслуга',
      type: 'Link',
    },
    {
      field: 'параметры снятия',
      text: 'Параметры снятия',
      type: 'Number',
    },
    {
      field: 'чдень',
      text: 'ЧДень',
      type: 'Number',
    },
    {
      field: 'чночь',
      text: 'ЧНочь',
      type: 'Number',
    },
  ],
};
export default actsqu;
