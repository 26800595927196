
import Vue from 'vue';

export default Vue.extend({
  name: 'StackList',
  model: {
    event: 'change',
  },
  props: {
    items: { type: [Object, Array], required: true },
    dense: { type: Boolean, default: false },
    isGroup: { type: Boolean, default: false },
    /**
     * отражать ли чекбокс
     */
    selectable: { type: Boolean, default: false },
  },
  data() {
    return {
      activeClick: -1,
      activeClickSub: -1,
    };
  },
  methods: {
    openList(item: any, index: number, indexSub?: number) {
      this.activeClick = index;
      if (indexSub !== undefined) {
        this.activeClickSub = indexSub;
      }
      this.$emit('open', item);
    },
    getBackgroundColor(index: number, indexSub?: number) {
      if (indexSub !== undefined) {
        return this.activeClickSub === indexSub && this.activeClick === index ? 'secondary' : '';
      } else {
        return this.activeClick === index ? 'secondary' : '';
      }
    },
    onChange(event: boolean, row: StackTableRow) {
      event ? (row.$selected = true) : (row.$selected = false);
      this.$emit('change', row);
    },
  },
});
