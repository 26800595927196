import { TableHandlers } from '@/stackEngine';

export default {
  objectName: 'СписокЛоговПрограммы',
  description: 'Список лог файлов программы',
  fields: [
    {
      field: 'path',
      text: 'Файл',
      type: 'String',
    },
  ],
} as StackSelection;
