const files: StackSelection = {
  objectName: 'АктыСнятияКачества.Журнал',
  description: 'Внешние файлы',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'время',
      text: 'Время',
      type: 'String',
    },
    {
      field: 'пользователь',
      text: 'Пользователь',
      type: 'String',
    },
    {
      field: 'комментарий',
      text: 'Действие',
      type: 'String',
    },
  ],
};
export default files;
