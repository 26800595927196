import { render, staticRenderFns } from "./МИКРОГЕН.vue?vue&type=template&id=152e8411&"
import script from "./МИКРОГЕН.vue?vue&type=script&lang=ts&"
export * from "./МИКРОГЕН.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports