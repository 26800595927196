
import Vue from 'vue';

export default Vue.extend({
  props: {
    dataModelLeft: { type: String, required: true },
    dataModelRight: { type: String, required: true },
    headersLeft: { type: String, required: true },
    headersRight: { type: String, required: true },
    descriptionLeft: { type: String, default: '' },
    descriptionRight: { type: String, default: '' },
    tableHeadersLeft: { type: [Object, Array], required: true },
    tableHeadersRight: { type: [Object, Array], required: true },
    height: { type: String, default: '400px' },
    params: { type: Object, default: null },
    disabled: { type: Boolean, required: true },
    keyField: { type: String, default: '$номерЗаписи' },
    inlineEdit: { type: Boolean, default: true },
    noCalcSum: { type: Boolean, default: false },
    noCopy: { type: Boolean, default: false },
  },
  data() {
    return {
      leftModel: null as any,
      rightModel: null as any,
      selectedLeft: [] as StackTableRow[],
      selectedRight: [] as StackTableRow[],
    };
  },
  mounted() {
    this.initializationTableLeft();
    this.initializationTableRight();
  },
  methods: {
    async initializationTableLeft() {
      const results = await new this.$HttpModel(this.dataModelLeft).getRecords(this.params);
      this.leftModel = new this.$StaticModel(results, {
        rowTemplate: this.tableHeadersLeft,
        description: this.descriptionLeft,
      });
    },
    async initializationTableRight() {
      const results = await new this.$HttpModel(this.dataModelRight).getRecords(this.params);
      this.rightModel = new this.$StaticModel(results, {
        rowTemplate: this.tableHeadersRight,
        description: this.descriptionRight,
      });
    },
    moveTo(selected: StackTableRow[], addModel: any, arrayDeleteModel: StackTableRow[]) {
      if (selected.length > 0) {
        for (const ind in selected) {
          selected[ind].$selected = false;
          addModel.data.push(selected[ind]);
          for (const indM in arrayDeleteModel) {
            if (arrayDeleteModel[indM][this.keyField] === selected[ind][this.keyField]) {
              arrayDeleteModel.splice(+indM, 1);
              break;
            }
          }
        }
        selected.splice(0);
        this.fetchData();
      }
    },
    getLeftTable() {
      return this.leftModel.data;
    },
    getRightTable() {
      return this.rightModel.data;
    },
    fetchData() {
      // @ts-ignore
      this.$refs.tableLeft.fetchData();
      // @ts-ignore
      this.$refs.tableRight.fetchData();
    },
    onSelectLeft(row: StackTableRow) {
      this.leftModel.data.push(row);
      this.fetchData();
    },
    onSelectRight(row: StackTableRow) {
      this.rightModel.data.push(row);
      this.fetchData();
    },
  },
  watch: {
    params: {
      handler(to) {
        if (to) {
          this.leftModel = null as any;
          this.rightModel = null as any;
          this.initializationTableLeft();
          this.initializationTableRight();
        }
      },
    },
  },
});
