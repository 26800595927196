
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      hourFrom: Date,
      hourTo: Date,
    };
  },
  created() {
    const data = this.record.значение.split('-');
    this.hourFrom = data[0];
    this.hourTo = data[1];
  },
  methods: {
    changeFunc() {
      const newVal = this.hourFrom + '-' + this.hourTo;
      this.$set(this.record, 'значение', newVal);
    },
  },
});
