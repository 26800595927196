import { TableHandlers } from '@/stackEngine';

const acts: StackSelection = {
  objectName: 'АктыОбхода.Пломбы',
  description: 'Пломбы',
  checkRow: row => {
    if (!row.номер) {
      return 'Номер пломбы обязателен для заполнения!';
    }
    const dateust = row['дата установки'] ? row['дата установки'] : null;
    const datecheck = row['дата проверки'] ? row['дата проверки'] : null;
    if (!dateust) {
      return 'Дата установки пломбы не может быть пустой!';
    }
    if (dateust && datecheck && dateust > datecheck) {
      return 'Дата проверки не может быть меньше даты установки!';
    }
    return false;
  },
  prepareRow: row => {
    if (row.статуспереноса === 2) {
      TableHandlers.trColor(row, 'error');
    }
    return row;
  },

  fields: [
    {
      field: 'дата выдачи',
      text: 'Дата выдачи',
      type: 'Date',
      width: '160px',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'вид пломбы',
      text: 'Вид пломбы',
      type: 'Enum',
      dataModel: 'Объекты.Пломбы.Вид',
    },
    {
      field: '@вид пломбы текст',
      text: 'Вид пломбы',
      type: 'String',
    },
    {
      field: 'место установки',
      text: 'Место установки',
      type: 'Enum',
      dataModel: 'Объекты.Пломбы.МестоУстановки',
      itemValue: 'значение',
    },
    {
      field: '@место установки',
      text: 'Место установки',
      type: 'String',
    },
    {
      field: 'дата установки',
      text: 'Дата установки',
      type: 'Date',
      width: '160px',
    },
    {
      field: 'пломба-организация',
      text: 'Кто установил',
      type: 'Link',
      dataModel: 'Организации',
      labelField: 'название',
      headers: 'название,инн,кпп',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Enum',
      dataModel: 'Объекты.Пломбы.Состояния',
    },
    {
      field: '@состояние текст',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'дата проверки',
      text: 'Дата проверки',
      type: 'Date',
      width: '140px',
    },
    {
      field: 'дата снятия',
      text: 'Дата снятия',
      type: 'Date',
      width: '140px',
    },
    {
      field: 'пломба-объект',
      text: 'Объект',
      type: 'Link',
    },
    {
      field: 'пломба-сотрудник',
      text: 'Контролер',
      type: 'Link',
    },
    {
      field: 'пломба-сотрудник>фио',
      text: 'Контролер',
      type: 'String',
    },
    {
      field: 'статуспереноса',
      text: 'Статус переноса',
      type: 'Enum',
    },
  ],
};
export default acts;
