
import Vue from 'vue';
import Recordable from '../mixins/recordable';
import Listenable from '../mixins/listenable';
import ActionDialog from './StackActionDialog.vue';
/**
 * Модальный диалог
 */
export default Vue.extend({
  name: 'StackModalDialog',
  mixins: [Recordable, Listenable],
  components: { ActionDialog },
  props: {
    /**
     * заголовок диалога
     */
    title: { type: String, default: '' },
    /**
     * подзаголовок диалога
     */
    subtitle: { type: String, default: null },

    maxWidth: { type: [String, Number], default: '1000px' },
    width: { type: [String, Number], default: undefined },
    /**
     *  при инициализации вычитывть данные по записи с бэка
     */
    forceInit: { type: Boolean, default: false },
    /**
     *  всегда разрешать сохранять
     */
    forceSave: { type: Boolean, default: false },
    /**
     *  Отображение кнопок
     */
    noButtons: { type: Boolean, default: false },
    /**
     * Название кнопки сохранить
     */
    saveBtnTitle: { type: String, default: 'Сохранить' },
    /**
     * не показывать кнопку сохранить
     */
    noSave: { type: Boolean, default: false },
    noCancel: { type: Boolean, default: false },
    /**
     * кнопка сохранить неактивна
     */
    error: { type: Boolean, default: false },
  },
  computed: {
    getTestId(): string | undefined {
      // @ts-ignore
      switch (typeof this.dataModel) {
        case 'string':
          // @ts-ignore
          return this.dataModel;
        case 'object':
          // @ts-ignore
          return this.dataModel.model ? this.dataModel.model : undefined;
      }

      return undefined;
    },
  },
  methods: {
    async onClose(rec?: StackTableRow) {
      // @ts-ignore
      if (await this.close(true)) {
        // @ts-ignore
        this.customEmit('close', rec);
        this.$emit('close');
      }
    },
    async onSave() {
      // @ts-ignore
      if (this.isNewRecord) {
        // @ts-ignore
        if (await this.createRecord()) {
          // @ts-ignore
          this.customEmit('create', this.record);
          // @ts-ignore
          this.onClose(this.record);
        }
      } else {
        // @ts-ignore
        if (await this.saveRecord()) {
          // @ts-ignore
          this.customEmit('save', this.record);
          // @ts-ignore
          this.onClose(this.record);
        }
      }
    },
  },
});
