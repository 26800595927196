
import Vue from 'vue';
import folder from './$dialogs/folder.vue';
import checkDialog from './$dialogs/CheckDialogGis.vue';
import CheckListsToolbar from './$dialogs/CheckListsToolbar.vue';

export default Vue.extend({
  name: 'HistoryLsTab',
  components: { checkDialog },
  props: {
    checklistsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      httpModel: {} as DataModel,
      modalVisibility: false,
      propDialog: {} as any,
    };
  },
  methods: {
    async onRowClick(row: StackTableRow) {
      this.propDialog = {
        item: row,
        id: row.$номерЗаписи,
        linkRecord: {},
      };
      this.dataModel(row);
      if (this.httpModel.model) {
        const recs = await this.httpModel.getRecords({ номерЗаписи: row.ид_объект });
        if (recs && recs[0]) {
          this.propDialog.linkRecord = recs[0];
          this.propDialog.id = row.ид_объект;
        }
      }
      this.modalVisibility = true;
    },
    dataModel(row: StackTableRow): string {
      let linkModel = '';
      if (row && row.связь) {
        switch (row.связь) {
          case 'Реестр проверок':
            linkModel = 'РеестрПроверокГис.Проверки';
            break;
          case 'ИерархияЛС':
            linkModel = 'ЛицевыеСчета';
            break;
          case 'Счетчик':
          case 'ОДПУСчетчик':
          case 'ОКПУСчетчик': {
            linkModel = 'ЛицевыеСчета.Объекты';
            break;
          }
          case 'ДокументПУ':
            linkModel = 'ГрупповыеСчетчики';
            break;
          case 'ВидПараметр':
            linkModel = 'ВидыПараметров';
            break;
          case 'Организации':
            linkModel = 'Организации';
            break;
        }
      }
      if (linkModel && !this.httpModel.model) {
        this.httpModel = new this.$HttpModel(linkModel);
      }
      return linkModel;
    },
    onClose() {
      this.modalVisibility = false;
    },
  },
});
