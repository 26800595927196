
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },
  methods: {
    labelFieldUKHandler(item: StackTableRow) {
      return '№' + item.номер + ' ' + item.тема + ' ' + item['организация-укдоговор>название'] + ' от ' + this.$stackDate.format(item.датнач as string, 'DD.MM.YYYY');
    },
  },
});
