
import Vue from 'vue';
import NetTreePrintBtn from './NetTreePrintBtn.vue';
import PrepareCalcDialog from '../$dialogs/PrepareCalcDialog.vue';
import PrepareReadingsDialog from '../$dialogs/PrepareReadingsDialog.vue';
import NetSchemeBtn from './NetSchemeBtn.vue';
import NetTreeSearch from './NetTreeSearch.vue';

export default Vue.extend({
  name: 'NetTreeToolbar',
  components: { NetTreePrintBtn, PrepareCalcDialog, PrepareReadingsDialog, NetSchemeBtn, NetTreeSearch },
  props: {
    params: { type: Object, default: undefined },
    dataObject: { type: Object, default: undefined },
    selectedItems: { type: Array, required: true },
    readonly: { type: Boolean, default: false }, // скрываем действия для диалога выбора
    enableSearchStep: { type: Boolean, default: false }, // возможность навигации по результатам поиска
    netType: { type: Number, required: true }, // тип сети для расчета и других кнопок
  },
  data() {
    return {
      setting: false,
      calcVisible: false,
      redingsVisible: false,
      searchEnable: false,
      search: '',
    };
  },
  methods: {
    tableAction({ action, payload }: any) {
      this.$emit(action, payload);
    },
    searchBtnPress(payload: any) {
      this.searchEnable = payload;
    },
    searchTextChanged(payload: any) {
      this.$emit('filter', { search: payload.search, searchType: payload.searchType });
    },
    onBtnSettingsMode() {
      this.setting = !this.setting;
      this.$emit('settingmode');
    },
  },
});
