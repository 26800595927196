
import Vue from 'vue';
import datnachDatend from './$datNachdatEnd.vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    arr: { type: Array, default() { return [1, 0.6, 0.4, 0.2, 0.1, 0.4, 0.4, 0.3]; } },
    hideDate: { type: Boolean, default: false },
  },
  components: { datnachDatend },
  computed: {
    VidPoverh() {
      return Number(this.record.примечание);
    },
    Koef() {
      return Number(this.arr[Number(this.record.примечание)]);
    },
    Form() {
      const колвомес = this.$stackDate.differenceInMonths(this.record.даткнц, this.record.датнач) + 1;
      const numConvert = Math.pow(10, 3);
      const Ko = Number(this.arr[Number(this.record.примечание)]);
      const знач01 = Math.round(this.record.знач3 * this.record.знач2 * Ko / колвомес * numConvert) / numConvert;
      const знач02 = Math.round(this.record.знач3 * this.record.знач2 * Ko * numConvert) / numConvert;
      const temp1 = this.record.знач3 + '*' + this.record.знач2 + '/' + колвомес + '*' + Ko + ' = ' + знач01;
      const temp2 = this.record.знач3 + '*' + this.record.знач2 + '*' + Ko + ' = ' + знач02;
      return колвомес > 1 ? temp1 : temp2;
    },
  },
  methods: {
    onVidPoverhChange(val: number) {
      this.record.примечание = Number(val);
    },
    onFormChange() {
      const колвомес = this.$stackDate.differenceInMonths(this.record.даткнц, this.record.датнач) + 1;
      const numConvert = Math.pow(10, 3);
      this.record.значение = Math.round(this.record.знач3 * this.record.знач2 * this.Koef / колвомес * numConvert) / numConvert;
    },
  },
});
