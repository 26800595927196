
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        'Параметры.ДатНач': this.$store.getters.getWorkMonth(),
        'Параметры.ДатКнц': this.$store.getters.getWorkMonth(),
        'Параметры.НомерЗаписиУслуги': -1,
        'Параметры.НомерЗаписиКатегории': -1,
        'Параметры.НомерЗаписиПоставщика': -1,
        'Параметры.НомерЗаписиКатегорииПост': -1,
        'Параметры.НомерЗаписиУК': -1,
        'Параметры.НомерЗаписиУКДоговор': -1,
        'Параметры.фДогУК': 0,
        'Параметры.showMonth': 0,
        'Параметры.Флаги': 0,
        'Параметры.Переключатель': 0,
        'Параметры.УслугаВОтчет': '',
        'Параметры.КатегорияВОтчет': '',
        'Параметры.ПоставщикВОтчет': '',
        'Параметры.КатегорияПостВОтчет': '',
        'Параметры.УКВОтчет': '',
        'Параметры.ДоговорВОтчет': '',
      },
    };
  },
  methods: {
    onChange(payload: any, type: string) {
      if (payload && payload[0]) {
        switch (type) {
          case 'поставщик':
            this.$set(this.record, 'Параметры.ПоставщикВОтчет', payload[0].название);
            break;
          case 'услуга':
            this.$set(this.record, 'Параметры.УслугаВОтчет', payload[0].наименование);
            break;
          case 'катуслуги':
            this.$set(this.record, 'Параметры.КатегорияВОтчет', payload[0].название);
            break;
          case 'катпоставщика':
            this.$set(this.record, 'Параметры.КатегорияПостВОтчет', payload[0].наименование);
            break;
          case 'ук':
            this.$set(this.record, 'Параметры.УКВОтчет', payload[0].название);
            break;
          case 'договорук':
            this.$set(this.record, 'Параметры.ДоговорВОтчет', '№' + payload[0].номер + ' ' + payload[0].тема + ' ' + payload[0]['организация-укдоговор>название']);
            break;
          default:
            break;
        }
      }
    },
  },
});
