const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ДетализацияСальдо_Входящее',
  description: 'Входящее сальдо',
  fields: [
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'услуга',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'сумма',
      text: 'Сумма,₽',
      type: 'Money',
    },
    {
      field: 'номер договора',
      text: 'Договор',
      type: 'String',
    },
    {
      field: 'объем',
      text: 'Объем,V',
      type: 'Number',
    },
    {
      field: 'название ук',
      text: 'Исполнитель',
      type: 'String',
    },
    {
      field: 'поставщик',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'месяц долга',
      text: 'Месяц',
      type: 'DateMonth',
    },
  ],
};
export default accs;
