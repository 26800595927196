
import Vue from 'vue';

export default Vue.extend({
  name: 'NetTreeSearch',
  props: {
    dark: { type: Boolean, default: false },
    searchOnType: { type: Boolean, default: false }, // сразу начинать быстрый поиск при нажатии на клавиатуру
    searchEnable: { type: Boolean, default: false }, // (Не)быстрый поиск вкл/выкл
    enableSearchStep: { type: Boolean, default: false }, // Есть ли предыдущий результат поиска
  },
  data() {
    return {
      search: '', // значение строки быстрого поиска
      showChoiсeDialog: false,
      searchType: 1,
      delay: 1800, // задержка debounce
      wait: false,
      timeoutID: undefined as number | undefined | any,
      menuItems: [
        { name: 'По названию', searchType: 1 },
        { name: 'По счетчику', searchType: 2 },
      ] as any,
    };
  },
  mounted() {
    if (this.searchOnType) {
      window.addEventListener('keypress', this.handleKeyup);
    }
  },
  beforeDestroy() {
    if (this.searchOnType) {
      window.removeEventListener('keypress', this.handleKeyup);
    }
  },
  methods: {
    handleKeyup(e: KeyboardEvent) {
      if (e.metaKey || e.ctrlKey || e.altKey || e.shiftKey || this.searchEnable) {
        return;
      }
      const allowedCharRegex = /[а-яА-Яa-zA-Z0-9]/;
      if (allowedCharRegex.test(e.key)) {
        this.search += e.key;
        e.stopPropagation();
        e.preventDefault();
        this.onBtnSearch();
      }
    },
    // кнопка поиска
    onBtnSearch() {
      this.$emit('search', true);
      this.$nextTick(() => {
        if (this.$refs.search) {
          // @ts-ignore
          this.$refs.search.focus();
        }
      });
    },
    emitSearch() {
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
      }
      this.$emit('input', { search: this.search, searchType: this.searchType });
    },
    debounceEmit(to: string) {
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => {
        this.$emit('input', { search: to, searchType: this.searchType });
      }, this.delay);
    },
    onCloseBtnClick(payload: any) {
      this.search = '';
      this.$emit('search', false);
    },
  }, // methods
  watch: {
    searchEnable(to) {
      if (to) {
        this.$nextTick(() => {
          if (this.$refs.search) {
            // @ts-ignore
            this.$refs.search.focus();
          }
        });
      }
    },
  },
});
