const doguk: StackSelection = {
  objectName: 'ДоговорыУК.Параметры',
  description: 'Общие параметры',
  fields: [
    {
      field: 'датнач',
      text: 'С',
      type: 'Date',
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
    {
      field: 'строка',
      text: 'Значение',
      type: 'String',
      align: 'right',
    },
    {
      field: 'даткнц',
      text: 'По',
      type: 'Date',
    },
    {
      field: 'виды-параметры',
      text: 'виды-параметры',
      type: 'Link',
    },
    {
      field: 'виды-параметры>название',
      text: 'Краткое название',
      type: 'String',
    },
    {
      field: 'виды-параметры>наименование',
      text: 'Параметр',
      type: 'String',
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
  ],
};
export default doguk;
