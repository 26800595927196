const paymentsSearch: StackSelection = {
  objectName: 'ПлатежныеВедомости.ПоискПлатежей',
  recordRoute: 'payments-paylists-search-payments-paymentsid',
  description: 'Платежи',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчмесяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'платеж-список',
      text: 'ВедомостьID',
      type: 'Link',
    },
    {
      field: 'платеж-список>дата',
      text: 'ДатаВедомости',
      type: 'Date',
    },
    {
      field: 'платеж-список>расчмесяц',
      text: 'МесяцВедомости',
      type: 'DateMonth',
    },
    {
      field: 'платеж-список>номер',
      text: '№ Ведомости',
      type: 'Number',
    },
    {
      field: 'счет-оплата',
      text: 'СчетID',
      type: 'Link',
    },
    {
      field: 'счет-оплата>номер',
      text: 'Лицевой счет',
      type: 'Number',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'сумма',
      text: '₽',
      type: 'Money',
    },
    {
      field: 'пени',
      text: 'в т.ч.пени, ₽',
      type: 'Money',
    },
    {
      field: '@ведомость',
      text: 'Ведомость',
      type: 'String',
      computed: true,
    },
  ],
};
export default paymentsSearch;
