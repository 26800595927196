
import Vue from 'vue';
import OwnerDocsTab from '@/tasks/fl/views/accounts/$accountsid/OwnerDocsTab.vue';
import stepBtn from './stepBtn.vue';

export default Vue.extend({
  components: { OwnerDocsTab, stepBtn },
  props: {
    record: { type: Object, required: true },
    steps: { type: Object, required: true },
  },
  data() {
    return {
      isMainOwnerExist: false,
    };
  },
  methods: {
    checkMainOwner(isMainOwnerExist: boolean, isNotEmpty: boolean) {
      if (!isMainOwnerExist && isNotEmpty) {
        this.$toast('Установите одному из владельцев статус "Титульный"', { color: 'error' });
      }
      this.isMainOwnerExist = isMainOwnerExist;
      this.$emit('change-items', this.isMainOwnerExist);
    },
  },
});
