
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    comboModel(): any {
      return this.record.поля.split('\n');
    },
  },
  data() {
    return {
      tableDog: [] as StackTableRow[],
      tableAnalytic: [] as StackTableRow[],
      comboValue: '',
      comboID: 0,
      phase: -1,
      dateGS: null as string | null,
      headersDog: [
        {
          field: 'значение',
          text: 'Тип договора',
          type: 'Enum',
          dataModel: 'КонстантыНастроек.ПолучитьТипДоговораЮЛ',
          itemValue: 'ключ',
        },
      ] as StackField[],
      headersAnalytic: [
        {
          field: 'значение',
          text: 'Аналитика',
          type: 'Link',
          dataModel: 'АналитикиДокументов',
          headers: 'название,код',
          labelField: 'название',
        },
      ] as StackField[],
    };
  },
  created() {
    this.comboValue = this.comboModel[0] ? this.comboModel[0] : '';
  },
  methods: {
    changeRecord(str: string, name: string) {
      const dataObject = this.record && this.record.значение ? JSON.parse(this.record.значение) : [];
      if (dataObject[this.comboID]) {
        dataObject[this.comboID][name] = str;
      } else {
        dataObject[this.comboID] = { ГруппыУслуг: '', Аналитика: '', Дата: '', Фаза: '' };
        dataObject[this.comboID][name] = str;
      }
      this.$set(this.record, 'значение', JSON.stringify(dataObject));
    },
    changeTable(items: StackTableRow[], name: string) {
      const str = items
        .map((value: StackTableRow) => {
          return value.значение;
        })
        .join(',');
      this.changeRecord(str, name);
    },
    getData(item: StackTableRow) {
      this.tableDog = [];
      this.tableAnalytic = [];
      this.dateGS = '';
      this.phase = -1;
      this.comboID = item.$номерЗаписи !== undefined ? item.$номерЗаписи : this.comboModel.length;
      // ввели новое значение combobox
      if (item.значение === undefined && item.$text && item.$text.toString().trim() !== '') {
        this.$set(this.record, 'поля', this.record.поля + '\n' + item.$text);
      }
      // наберем данные, в зависимости от значения combobox
      const dataObject = this.record && this.record.значение ? JSON.parse(this.record.значение) : [];
      for (const ind in dataObject) {
        if (+ind === item.$номерЗаписи) {
          this.dateGS = dataObject[ind].Дата;
          this.phase = +dataObject[ind].Фаза;
          this.tableDog = dataObject[ind].ГруппыУслуг
            ? [].concat(dataObject[ind].ГруппыУслуг.split(',')).map((value: StackTableRow) => {
                return { значение: Number(value) };
              })
            : [];
          this.tableAnalytic = dataObject[ind].Аналитика
            ? [].concat(dataObject[ind].Аналитика.split(',')).map((value: StackTableRow) => {
                return { значение: Number(value) };
              })
            : [];
          break;
        }
      }
    },
  },
});
