
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: '@Выбор',
          text: '',
          type: 'Boolean',
        },
        {
          field: '@Виддеятельности',
          text: 'Вид деятельности',
          type: 'String',
          readonly: true,
        },
        {
          field: '@Тема',
          text: 'Тема',
          type: 'String',
          readonly: true,
        },
      ],
    };
  },
});
