
import Vue from 'vue';
import Inputmask from 'inputmask';
import inputable from '../mixins/inputable';

/**
 * Поле ввода
 */
export default Vue.extend({
  name: 'StackInput',
  mixins: [inputable],
  model: { prop: 'value', event: 'change' },
  props: {
    /**
     * Значение инпута
     * @model
     */
    value: { type: [String, Number], default: undefined },
    type: { type: String, default: undefined },
    maxlength: { type: [String, Number], default: undefined },
    mask: { type: String, default: undefined },
    returnMaskedValue: { type: Boolean, default: false },
    left: { type: Boolean, default: false },
    dataTestId: { type: String, default: '' },
    floatPlaceholder: { type: Boolean, default: false },
  },
  computed: {
    inputListeners(): any {
      // TODO
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      return Object.assign({}, this.$listeners, {
        input(event: string): any {
          vm.$emit('input', vm.convertVal(event));
        },
        change(event: string): any {
          vm.$emit('change', vm.convertVal(event));
        },
        // blur(event: string): any {
        //   vm.$emit('blur', vm.convertVal(event));
        // },
      });
    },
    inputType(): string | object | undefined {
      if (this.type) {
        switch (this.type.toLowerCase()) {
          case 'number':
          case 'quantity':
            return 'numeric';
          case 'time':
            return 'datetime';
          case 'money':
            return 'decimal';
          case 'guid':
            return { regex: '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}', casing: 'lower', autoUnmask: false };
          case 'string':
          case 'enum':
          case 'password':
            return '';
        }
      }
      return this.type;
    },
    computedValue(): string | number {
      return this.type && this.type.toLowerCase() === 'money' && (this.value || this.value === 0) ? Number(this.value).toFixed(2) : this.value;
    },
    inputOptions(): object {
      let opt;
      if (this.type) {
        switch (this.type.toLowerCase()) {
          case 'money':
            opt = { prefix: '', groupSeparator: ' ', digitsOptional: true, digits: 2 };
            break;
          case 'number':
            opt = { placeholder: '' };
            break;
          case 'quantity':
            opt = { placeholder: '', groupSeparator: ' ' };
            break;
          case 'time':
            opt = { placeholder: ' ', inputFormat: 'HH:MM' };
            break;
        }
      }
      return {
        autoUnmask: !this.returnMaskedValue,
        rightAlign: false,
        // positionCaretOnClick: 'select',
        onBeforePaste: (pasteValue: string) => {
          const types = ['decimal', 'numeric'];
          if (typeof this.inputType === 'string' && types.includes(this.inputType || '')) {
            pasteValue = pasteValue.replace(',', '.').replace(/[^0-9.-]|((?!^)-)|((?<=\..*)\.)/g, '');
          } else if (this.type === 'guid') {
            pasteValue = this.stringToUUID(pasteValue);
          }
          typeof this.$listeners.change === 'function' && this.$listeners.change(pasteValue);
        },
        definitions: {
          '↑': {
            casing: 'upper',
          },
        },
        ...opt,
      };
    },
  },
  data() {
    return {
      im: undefined as any,
    };
  },
  mounted() {
    this.addMask();
  },
  methods: {
    addMask() {
      const selector = this.$el.querySelector('input');
      // @ts-ignore
      if (selector && selector.inputmask && selector.inputmask.remove) {
        // @ts-ignore
        selector.inputmask.remove();
      }
      if (this.inputType || this.mask) {
        if (!this.mask && typeof this.inputType === 'object') {
          this.im = new Inputmask({ ...this.inputOptions, ...this.inputType });
        } else {
          this.im = new Inputmask(this.mask || this.inputType, this.inputOptions);
        }
        this.im.mask(selector);
      }
    },
    stringToUUID(string: string) {
      const str = string.replaceAll('-', '');
      return str.substr(0, 8) + '-' + str.substr(8, 4) + '-' + str.substr(12, 4) + '-' + str.substr(16, 4) + '-' + str.substr(20);
    },
    convertVal(val: string) {
      const inputType = this.type ? this.type.toLowerCase() : undefined;
      switch (inputType) {
        case 'money':
          if (val === '-0') {
            this.$el.querySelector('input')?.select();
          }
          return val ? Number(val).toFixed(2) : null;
        case 'number':
          return val ? +val : null;
        default:
          return val || null;
      }
    },
    focus() {
      // @ts-ignore
      if (this.$refs.textField && this.$refs.textField.focus) {
        // @ts-ignore
        this.$refs.textField.focus();
      }
    },
  },
  watch: {
    mask(to) {
      this.addMask();
    },
    type(to) {
      this.addMask();
    },
  },
});
