
import Vue from 'vue';
import { TableHandlers } from '@/stackEngine';
import EmailDialog from './$dialogs/EmailDialog.vue';
import GetEmails from './$dialogs/GetEmails.vue';
import ShablonEmail from './$dialogs/ShablonEmail.vue';
import EmailBox from './$dialogs/EmailBox.vue';
import DateFilter from '@/tasks/fl/views/$common/filters/EmailsLogFilter.vue';
import emailFilter from './emailFilter.vue';

export default Vue.extend({
  name: 'Emailagent',
  components: { EmailDialog, GetEmails, ShablonEmail, EmailBox, DateFilter, emailFilter },
  data() {
    return {
      selected: {} as any,
      jobSendMails: null,
      jobClearTrash: null,
      getEmailsVisible: false,
      messagesButtonsDisabled: true,
      dataModel: new this.$HttpModel('ПочтовыйКлиент'),
      messages: [] as any,
      loading: false,
      chunkSize: 100,
      selectedMessages: [] as any,
      dataModelMails: new this.$HttpModel('ПочтовыйКлиент.Сообщения'),
    };
  },
  computed: {
    noRowAction(): boolean {
      if (this.selected && this.selected.видпапки && this.selected.видпапки === 'Исходящее') {
        return false;
      }
      return true;
    },
    resultJobSend(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobSendMails);
      return result || {};
    },
    isCompleteSend(): boolean {
      return this.resultJobSend.complete ? this.resultJobSend.complete : false;
    },
    isErrorSend(): boolean {
      return this.resultJobSend.error ? this.resultJobSend.error : false;
    },
    resultJobClear(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobClearTrash);
      return result || {};
    },
    isCompleteClear(): boolean {
      return this.resultJobClear.complete ? this.resultJobClear.complete : false;
    },
    isContactcenter(): boolean {
      return this.$store.getters.getCurrentTask() === 'contactcenter';
    },
    isIncoming(): boolean {
      return this.selected.видпапки === 'Входящее';
    },
    isOutcoming(): boolean {
      return this.selected.видпапки === 'Исходящее';
    },
    headersMessages(): string {
      const headersCommon = 'состояние,вложение,откого,кому';
      switch (this.selected.видпапки) {
        case 'Исходящее':
          return headersCommon + ',@адреслс,тема,отб_создано';
        case 'Входящее':
          return headersCommon + ',тема,отб_получено' + (this.isContactcenter ? ',@обращениякц' : '');
        case 'Отправленное':
          return headersCommon + ',@адреслс,тема,отб_создано,отб_отправлено';
        default:
          return headersCommon + ',тема,отб_получено,отб_создано';
      }
    },
    isUserAdmin(): boolean {
      return this.$store.getters.isUserAdmin();
    },
    isCleanBtnVisible(): boolean {
      return this.selected.видпапки === 'Корзина';
    },
    isSendAllBtnVisible(): boolean {
      return this.selected.видпапки === 'Исходящее';
    },
  },
  methods: {
    async onSelectItem(payload: any) {
      // @ts-ignore
      this.$refs.messages.unSelectAll();
      if (payload && payload[0]) {
        this.messagesButtonsDisabled = this.selected !== payload[0];
        this.selected = payload[0];
      }
    },
    async sendEmails(selRows: StackTableRow[]) {
      const records = [] as number[];
      if (selRows) {
        selRows.forEach((record) => {
          if (record.$номерЗаписи && !record.$этоПапка) records.push(+record.$номерЗаписи);
        });
      }
      this.jobSendMails = (
        await this.dataModel.executeMethod('ОтправитьПисьма', { ид_папка: this.selected.$номерЗаписи, записи: records }, { async: true, jobName: 'Отправка писем' })
      ).asyncId;
      // this.$emit('close');
    },
    async clickBtn(row: any, action: string) {
      await this.dataModelMails.executeMethod('Действие', { ид: row.$номерЗаписи, действие: action });
      this.readMessage();
    },
    async onCreateRequest() {
      const messagesIDs = [];
      for (const ind of this.selectedMessages) {
        messagesIDs.push(ind.$номерЗаписи);
      }

      await this.dataModel.executeMethod('СоздатьОбращенияПоПисьмам', { письма: messagesIDs }, { async: true, jobName: 'Создаем обращения' });
    },
    readMessage() {
      // @ts-ignore
      this.$refs.messages.fetchData();
    },
    async onDelete(selRows: StackTableRow[]) {
      const records = [] as number[];
      selRows.forEach((record) => {
        if (record.$номерЗаписи && !record.$этоПапка) records.push(+record.$номерЗаписи);
      });
      if (records.length > 0) {
        const msg = records.length > 0 ? ` выделенных записей: ${records.length}` : 'выделенную запись';
        if (!this.isTrash()) {
          if (!(await this.$yesno(`Вы уверены, что хотите переместить в корзину\n${msg} ?`, 'Внимание', { width: '600px' }))) {
            return;
          }
        } else {
          if (!(await this.$yesno(`Вы уверены, что хотите удалить\n${msg} ?`, 'Внимание', { width: '600px' }))) {
            return;
          }
        }
        try {
          this.loading = true;
          for (let i = 0; i < Math.ceil(records.length / this.chunkSize); i++) {
            const rec = records.slice(i * this.chunkSize, (i + 1) * this.chunkSize);
            await this.dataModelMails.executeMethod('delete', { номераЗаписей: rec, папка: this.selected.$номерЗаписи });
          }
          // @ts-ignore
          this.$refs.messages.unSelectAll();
        } catch (error: AnyException) {
          return;
        } finally {
          this.readMessage();
          this.loading = false;
        }
      }
    },
    isTrash() {
      return this.selected.видпапки === 'Корзина';
    },
    async clearTrash() {
      if (!(await this.$yesno(`Вы уверены, что хотите удалить все сообщения из Корзины?`, 'Внимание', { width: '600px' }))) {
        return;
      }
      let fClear = 1;
      if (await this.dataModel.executeMethod('ПроверкаКорзины', { ид_папка: this.selected.$номерЗаписи })) {
        fClear = await this.$yesno(`Обнаружены письма, которые невозможно удалить!\n Желаете удалить остальные?`, 'Внимание', { width: '600px' });
      }
      if (fClear) {
        this.jobClearTrash = (
          await this.dataModel.executeMethod('ОчиститьКорзину', { ид_папка: this.selected.$номерЗаписи }, { async: true, jobName: 'Удаление сообщений из корзины' })
        ).asyncId;
      }
    },
    emailsColorHandler(item: StackTableRow) {
      if (this.isContactcenter && this.isIncoming) {
        if (item['@обращениякц'] && item['@обращениякц'] !== '') {
          TableHandlers.trColor(item, 'green');
        }
      }
      return item;
    },
    updateMessages(payload: any) {
      this.messages = payload;
      this.messagesButtonsDisabled = false;
    },
  },
  watch: {
    selected: {
      deep: true,
      handler(to, old) {
        if (to.видпапки !== old.видпапки) {
          this.$sendMsg('stack-table', 'ПочтовыйКлиент.Сообщения', 'clear_filter');
        }
      },
    },
    isCompleteSend(to) {
      if (to) {
        this.jobSendMails = null;
        this.readMessage();
      }
    },
    isErrorSend(to) {
      if (to) {
        this.jobSendMails = null;
      }
    },
    isCompleteClear(to) {
      if (to) {
        this.jobClearTrash = null;
        this.readMessage();
      }
    },
  },
});
