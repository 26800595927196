
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      record: {
        status: false,
        port: 0,
      },
      localPort: 0,
      visible: false,
      loading: false,
      dataModel: new this.$HttpModel('СервисныеОперации'),
    };
  },
  async created() {
    this.record = await this.dataModel.executeMethod('Отладчик');
    this.visible = true;
  },
  computed: {
    command(): string {
      // return this.record.status ? 'остановить' : 'запустить';
      return this.isDebugMode ? 'остановить' : 'запустить';
    },
    isDebugMode(): string {
      return this.$store.getters.isDebugMode();
    },
  },
  methods: {
    async onSave(команда: string) {
      // this.loading = true;
      // try {
      //   this.record = await this.dataModel.executeMethod('Отладчик', { команда, port: this.localPort });
      // } catch (e: AnyException) {
      //   console.log('debug error', e);
      // }
      // this.loading = false;
      this.$store.commit('SET_DEBUG_MODE', !this.isDebugMode);
    },
  },
  watch: {
    record(to) {
      if (to) {
        this.localPort = to.port;
      }
    },
  },
});
