
import Vue from 'vue';
import paramTable from '@/tasks/common/views/$common/paramTable.vue';

export default Vue.extend({
  components: { paramTable },
  props: {
    ownerID: { type: [Number, String], default: null },
  },
});
