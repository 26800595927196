import { TableHandlers } from '@/stackEngine';
const cicall: StackSelection = {
  objectName: 'ЦентрИнформирования.Вызовы',
  description: 'Вызовы центра информирования',

  prepareRow: item => {
    if (!item.$data) {
      item.$data = {};
    }
    // @ts-ignore
    item.$data['@статус'] = { $иконка: '$vuetify.icons.ci_stop', $иконка_цвет: 'primary' };
    switch (item.последнийстатус) {
      case 0:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.ci_stop', 'primary');
        break;
      case 1:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.ci_play', 'primary');
        break;
      case 2:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.ci_send', 'primary');
        break;
      case 3:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.done', 'primary');
        break;
      case 4:
      case 5:
        TableHandlers.tdIcon(item, '@статус', '$vuetify.icons.error', 'error');
        break;
    }
    return item;
  },
  fields: [
    {
      field: 'адресдоставки',
      text: 'Адрес доставки',
      type: 'Number',
    },
    {
      field: 'текст',
      text: 'Текст',
      type: 'String',
      align: 'center',
    },
    {
      field: 'авторс',
      text: 'Автор',
      type: 'String',
    },
    {
      field: 'попытка',
      text: 'Попытка',
      type: 'Number',
    },
    {
      field: '@номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: '@адреслс',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: '@статус',
      text: '',
      type: 'Number',
      computed: true,
    },
    {
      field: 'статусдоптекст',
      text: 'Статус',
      type: 'String',
    },

    {
      field: 'последнийстатус',
      text: '',
      type: 'Number',
    },
  ],
};
export default cicall;
