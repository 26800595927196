
import Vue from 'vue';
import { getComponents } from '@/stackEngine';

const componentsDefs = require.context('./$dialogs', false, /\.vue$/);
const constDlg = getComponents(componentsDefs);

export default Vue.extend({
  components: Object.assign(constDlg),
  props: {
    dialogName: { type: [String], default: 'default' },
  },
  computed: {
    isAlone(): boolean {
      return this.records.length === 1;
    },
  },
  data() {
    return {
      item: {} as StackTableRow,
      dataModel: new this.$HttpModel('Компенсации'),
      loading: false,
      isDialog: false,
      records: [] as StackTableRow[],
    };
  },
  async created() {
    await this.fetchData();
    if (this.isAlone) {
      this.openList(this.records[0]);
    }
  },
  methods: {
    async fetchData() {
      this.records = await this.dataModel.getRecords();
    },
    getDialogName(row: StackTableRow): string {
      if (row) {
        if (row.dialog) {
          const name = (row.dialog as string).toUpperCase();
          if (this.$options.components && name in this.$options.components) {
            return name as string;
          }
        }
        return 'DEFAULT';
      }
      return '';
    },
    async openList(item: StackTableRow) {
      this.isDialog = false;
      const record = await this.dataModel.initRecord({ расчет: item.title });
      const tempRecord = {} as StackTableRow;
      for (const ind in record) {
        if (record[ind] && !ind.startsWith('$')) {
          tempRecord[ind] = record[ind];
        }
      }
      this.item = Object.assign({}, item, tempRecord);
      this.isDialog = true;
    },
    calc(item: StackTableRow) {
      const заголовок = (item.title ? item.title : '') as string;
      this.dataModel.executeMethod('Рассчитать', item, {
        async: true,
        jobName: заголовок,
      });
    },
  },
});
