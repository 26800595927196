
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'Параметр' },
  },
  data() {
    return {
      comboboxModel: '',
      selectedServices: [],
      rulesThis: {
        checkFolder: (obj: any): string | true => {
          if (obj && obj[0] && !obj[0].$этоПапка) {
            return 'Необходимо выбрать группу услуг';
          }
          return true;
        },
      },
    };
  },
  methods: {
    onChangeParameter(payload: any) {
      if (payload && payload[0]) {
        switch (payload[0].имя) {
          case 'ПРЯМРАСЧЕТ':
            this.comboboxModel = 'ПрямойРасчетЗначение';
            break;
          case 'РАСЧЕТПЕНИ':
            this.comboboxModel = 'РасчетПениЗначение';
            break;
        }
      }
    },
  },
});
