
import Vue from 'vue';
import DialogDate from '../$dialogs/НаДату.vue';
import basemethod from './basemethod.vue';
import SelectGpDialog from '../$lawsuits/selectGpDialog.vue';

export default Vue.extend({
  extends: basemethod,
  model: {
    prop: 'record',
  },
  components: { DialogDate, 'SelectGp-dialog': SelectGpDialog },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      gpvisible: true,
      dataObject: new this.$HttpModel('СостоянияДела'),
      isdialog: false,
      isload: false,
    };
  },
  computed: {
    selectgpvisible(): boolean {
      return !!(this && this.record && this.record.$блокинит && this.record['@операции'].indexOf('ЗАПРОСПОГОСПОШЛИНЕ') !== -1);
    },
    selectDolya(): boolean {
      return !!(this && this.record && this.record.$блокинит && this.record['@операции'].indexOf('ДОЛЖНИКИПОДОЛЯМ') !== -1);
    },
    gpnodialog(): boolean {
      return !!(this && this.record && this.record.$блокинит && this.record['@операции'].indexOf('ГОСПОШЛИНАБЕЗДИАЛОГА') !== -1);
    },
  },
  methods: {
    async changeСourtField(gpload: any) {
      if (gpload >= 0) {
        const rec = this.record;
        await this.dataObject.executeMethod('ПроверкаПолей', { поле: 'Суд', сдела: rec });
      }
    },
    async ChangeDate(item: any) {
      this.isdialog = false;
      let rec = this.record;
      this.isload = true;
      const result = await this.dataObject.executeMethod('ВыполнитьОперациюНаФазе', { сдела: rec, dopparams: item, операция: 'ИзменитьДату' });
      rec = Object.assign(rec, result);
      this.isload = false;
    },
    async ChangeDate_dialog() {
      const answer = await this.$yesno('Внимание! При изменении даты планируемой подачи иска будет обновлена сумма по основному долгу и пени.');
      if (answer) {
        this.isdialog = true;
      }
    },
    async setdolya() {
      const answer = await this.$yesno('Создать раздельные дела по долям ответчиков?');
      await new this.$HttpModel('ОперацииЕдиничногоПерехода').executeMethod('ВыполнитьОперацию', {
        состояние: this.record.$номерЗаписи,
        ответ: answer,
        тип: 'ДолжникиПОДолям',
      });
      this.$emit('update');
    },
    async ChangePeni() {
      let rec = this.record;
      const result = await this.dataObject.executeMethod('ВыполнитьОперациюНаФазе', { сдела: rec, операция: 'ИсключитьПениИзИска' });
      rec = Object.assign(rec, result);
      this.$emit('change', rec);
    },
    async setgp(data: any) {
      await new this.$HttpModel('ОперацииЕдиничногоПерехода').executeMethod('ВыполнитьОперацию', {
        состояние: this.record.$номерЗаписи,
        длг: data,
        тип: 'ЗапросПоГоспошлине',
      });
      this.gpvisible = false;
      this.$emit('update');
    },
  },
  watch: {
    gpnodialog: {
      immediate: true,
      handler(to) {
        if (to && this.record.$блокинит) {
          // Если не выводим диалог, но новая запись, все равно нужно создать госпошлину, запускаем операцию
          this.setgp({});
        }
      },
    },
    selectDolya: {
      immediate: true,
      handler(to) {
        if (to && this.record.$блокинит) {
          this.setdolya();
        }
      },
    },
  },
});
