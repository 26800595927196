
import Vue from 'vue';
import lgotylistId from './$folder/_lgotylistid.vue';

export default Vue.extend({
  components: { lgotylistId },
  props: {
    lgotyid: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      dialog: false,
    };
  },
  computed: {
    title(): string {
      return `Льгота`;
    },
  },
});
