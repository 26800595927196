
import { TableHandlers } from '@/stackEngine';
import infocenterFilter from '@/tasks/fl/views/$common/filters/infocenterFilter.vue';

import Vue from 'vue';
export default Vue.extend({
  name: 'Infocenter',
  components: { infocenterFilter },
  data() {
    return {
      dataObject: new this.$HttpModel('ЦентрИнформирования'),
      campaigns: [] as any,
      answer: true,
      selectedItems: [] as any,
    };
  },
  methods: {
    async onciLaunch() {
      this.campaigns = [];
      this.selectedItems.forEach((row: any) => {
        this.campaigns.push({ row_id: row.$номерЗаписи });
      });
      if (!this.campaigns.length) {
        this.answer = await this.$yesno('Внимание! Вы уверены, что хотите запустить ВСЕ незапущенные кампании?');
      } else {
        this.answer = true;
      }
      if (this.answer) {
        const parameters = { МассКампании: this.campaigns };
        this.dataObject.executeMethod('ЗапускКампаний', parameters);
        // @ts-ignore
        this.$refs.table.fetchData();
      }
    },
  },
});
