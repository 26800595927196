import { render, staticRenderFns } from "./frommonth.vue?vue&type=template&id=c8fe987c&"
import script from "./frommonth.vue?vue&type=script&lang=ts&"
export * from "./frommonth.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports