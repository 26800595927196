const serv: StackSelection = {
  objectName: 'Услуги.Правила',
  description: 'Правила',
  hier: true,
  fields: [
    {
      field: 'ключевая фраза',
      text: 'Ключевая фраза',
      type: 'String',
    },
    {
      field: 'имя',
      text: 'Имя',
      type: 'String',
    },
  ],
};

export default serv;
