const sogldet: StackSelection = {
  objectName: 'Соглашение_детализация',
  description: 'График соглашения',
  hier: true,
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.$этоПапка) {
      // @ts-ignore
      row.$data.месяц = { $иконка: '$vuetify.icons.folder' };
    }
    return row;
  },
  fields: [
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'срокопл',
      text: 'Срок\nоплаты',
      type: 'Date',
    },
    {
      field: 'замесяц',
      text: '   За\nмесяц',
      type: 'DateMonth',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'суммапроцентов',
      text: 'Процент',
      type: 'Money',
    },
    {
      field: 'общаясумма',
      text: 'Общая сумма\n к оплате',
      type: 'Money',
    },
    {
      field: 'оплатадолг',
      text: 'Сумма\n оплачено',
      type: 'Money',
    },
    {
      field: 'оплатапроцент',
      text: 'Процент\n оплачено',
      type: 'Money',
    },
    {
      field: 'оплатаобщая',
      text: 'Общая сумма\n оплачено',
      type: 'Money',
    },
  ],
};

export default sogldet;
