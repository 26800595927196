
import Vue from 'vue';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Blockquote,
  // CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  // Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
} from 'tiptap-extensions';
import ImageDialog from './ImageDialog.vue';

export default Vue.extend({
  name: 'StackEditor',
  components: {
    EditorContent,
    EditorMenuBar,
    ImageDialog,
  },
  props: {
    value: { type: String, default: '' },
    noToolbar: { type: Boolean, default: false },
    height: { type: String, required: true, default: undefined },
    /**
     * работа с изображениями в редакторе:
     *  no - кнопка выбора картинки не доступна
     *  base64 - картинка будет сохранена в html закодированной в base64
     *  link - картинка будет сохранена в html как ссылка на внешний ресурс или выбранное изображение
     */
    image: {
      type: String,
      validator: (value: string) => {
        return ['no', 'base64', 'link'].indexOf(value) !== -1;
      },
      default: 'base64',
    },
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  computed: {
    computedValue(): string {
      return this.value.replace(/(<\/?.*?>)/g, '$1\n');
    },
    imageBase64(): boolean {
      return this.image === 'base64';
    },
  },
  data() {
    return {
      imageFile: {},
      showFileUpload: false,
      editor: null as any,
      emitAfterOnUpdate: false,
      toggleBtn: null,
      codeSourceActive: false,
      toolbarButtons: [
        { name: 'bold', icon: '$vuetify.icons.editor_bold', tooltip: 'Жирный' },
        { name: 'italic', icon: '$vuetify.icons.editor_italic', tooltip: 'Курсив' },
        { name: 'strike', icon: '$vuetify.icons.editor_strike', tooltip: 'Зачеркнутый' },
        { name: 'underline', icon: '$vuetify.icons.editor_underline', divider: true, tooltip: 'Подчеркнутый' },
        // { name: 'code', icon: '$vuetify.icons.editor_code', tooltip: 'Код' },
        // { name: 'paragraph', icon: '$vuetify.icons.editor_paragraph', tooltip: 'Параграф' },
        { name: 'bullet_list', icon: '$vuetify.icons.editor_bullet_list', tooltip: 'Список' },
        { name: 'ordered_list', icon: '$vuetify.icons.editor_ordered_list', tooltip: 'Нумерованный список ' },
        { name: 'blockquote', icon: '$vuetify.icons.editor_blockquote', divider: true, tooltip: 'Цитата' },
        // { name: 'code_block', icon: '$vuetify.icons.editor_code_block', tooltip: 'Блок кода' },
        { name: 'horizontal_rule', icon: '$vuetify.icons.editor_horizontal_rule', divider: true, tooltip: 'Горизонтальная линия' },
        { name: this.image !== 'no' ? 'image' : '', icon: '$vuetify.icons.editor_img', divider: true, tooltip: 'Изображение' },
        { name: 'undo', icon: '$vuetify.icons.editor_undo', tooltip: 'Отменить' },
        { name: 'redo', icon: '$vuetify.icons.editor_redo', tooltip: 'Вернуть' },
        { name: 'code-source', icon: '$vuetify.icons.editor_code', tooltip: 'Источник' },
      ],
      imageHandler: null as any,
      isLoaded: false,
    };
  },
  created() {
    this.editor = new Editor({
      content: this.getContent(this.value),
      disablePasteRules: true,
      extensions: [
        new Blockquote(),
        new BulletList(),
        // new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        // new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
        new Image(),
      ],
      onUpdate: this.onUpdate,
    });
    this.isLoaded = true;
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    onUpdate(info: any) {
      this.emitAfterOnUpdate = true;
      this.$emit('input', info.getHTML().replace(/<p[^>]*>/g, '').replace(/<\/p>$/, '').replace(/<\/p>/g, '<br/>'), info);
    },
    onChange(str: string) {
      const value = str.replace(/(\r\n|\n|\r)/gm, '');
      this.editor.setContent(value);
      this.$emit('input', value);
    },
    isButtonActive(isActive: any, button: any): boolean {
      return (!!isActive[button.name] && isActive[button.name](button.params)) || (this.codeSourceActive && button.name === 'code-source');
    },
    onButtonClick(commands: any, button: any) {
      if (button.name === 'image') {
        if (!this.imageHandler) this.imageHandler = commands[button.name];
        this.showFileUpload = true;
        return undefined;
      }
      if (commands[button.name]) {
        return commands[button.name](button.params);
      } else {
        if (button.name === 'code-source') this.codeSourceActive = !this.codeSourceActive;
        return undefined;
      }
    },
    onAreaClick() {
      this.editor.focus();
    },
    onSaveFileDialog(payload: any) {
      this.showFileUpload = false;
      this.imageHandler(payload);
    },
    getContent(content: string): string {
      if (!this.imageBase64) return content.replaceAll('%upload_host%', this.$store.getters.getApiHostUpload());
      return content;
    },
  },
  watch: {
    value: {
      handler(to: string) {
        if (this.emitAfterOnUpdate) {
          this.emitAfterOnUpdate = false;
          return;
        }
        this.editor.setContent(this.getContent(to));
      },
    },
  },
});
