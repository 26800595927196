import Vue from 'vue';
/**
 * функционал для сканирования штрихкода в фл в ведомости и в платеже
 */
export default Vue.extend({
  data() {
    return {
      record: {} as StackTableRow,
      sumpeni: 0,
      barcode: '',
      delay: 500,
      timeoutID: undefined as number | undefined | any,
    };
  },
  computed: {
    getBarcode(): string {
      return this.barcode || this.$store.getters['wsStore/getBarcode']();
    },
  },
  methods: {
    onKeyPressTabs(e: KeyboardEvent) {
      if (e.shiftKey) {
        if (e.code === 'KeyE' || e.code === 'KeyY' || e.code === 'KeyW' || (e.code === 'KeyS' && !this.barcode.includes('ST0'))) {
          this.barcode = '';
        }
      }
      this.barcode += e.key !== 'Shift' ? e.key : '';
    },
    async debounceEmit(to: string) {
      if ((to.length >= 8 && to.substring(0, 1) === 'W') || (to.length >= 29 && (to.substring(0, 1) === 'E' || to.substring(0, 1) === 'Y' || to.includes('ST0')))) {
        const res = await new this.$HttpModel('ПлатежныеВедомости.ПлатежиВедомости').executeMethod('ПроверитьШтрихкод', { штрихкод: to });
        if (res) {
          switch (res['Результат']) {
            case 1:
              this.$store.commit('wsStore/SET_BARCODE', to);
              this.$sendMsg('stack-table', 'ПлатежныеВедомости.ПлатежиВедомости', 'add');
              break;
            case 2:
              this.sumpeni += res['Пени'] !== undefined ? +res['Пени'] : 0;
              this.record.сумма = this.record.сумма !== null && this.record.сумма !== undefined ? +this.record.сумма + +this.sumpeni : 0;
              break;
            default:
              this.$toast('Неизвестный штрихкод', { color: 'error' });
              break;
          }
        } else {
          this.$toast('Неизвестный штрихкод (русская раскладка?)', { color: 'error' });
        }
        this.barcode = '';
        // очищаем по таймауту так как нужно время чтобы штрихкод прочитался при инициализации платежа
        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(() => {
          this.$store.commit('wsStore/SET_BARCODE', '');
        }, this.delay);
      }
    },
  },
  watch: {
    getBarcode(to) {
      if (to) {
        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(() => {
          this.debounceEmit(to);
        }, this.delay);
      }
    },
  },
});
