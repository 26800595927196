import { stackAppEmitter } from '../emitters';
import { StackSelectionCache } from './selections';

export function registerSelections(views: __WebpackModuleApi.RequireContext[]) {
  stackAppEmitter.on('APP_MOUNTED', async () => {
    // читаем описание выборок
    StackSelectionCache.clearSelectionsCache();
    views.forEach(view => {
      StackSelectionCache.addSelections(view);
    });
  }, 'Читаем описания выборок...');
}