
import Vue from 'vue';
import SemiAutoRecalc from './SemiAutoRecalc.vue';

export default Vue.extend({
  components: { SemiAutoRecalc },
  props: {
    rowid: { type: [Number, String], required: true },
    record: { type: Object, default: null },
    async: { type: Boolean, default: false },
    closedMonthLs: { type: [Date, String], default: null }, // передается с записи лицевого счета
    calcItems: { type: [Object, Array], default: undefined },
  },
  computed: {
    shortCalcNames(): string | undefined {
      return this.calcItems
        ? this.calcItems.reduce(
          (acc: string, item: StackTableRow, i: number) => (acc ? (i === this.limit ? acc + '...' : i > this.limit ? acc + '' : acc + ', ' + (item.$название?.trim() || 'Лицевой счет № ' + item.номер)) : acc + (item.$название?.trim() || 'Лицевой счет № ' + item.номер)),
          '',
        )
        : undefined;
    },
    fullCalcNames(): string | undefined {
      return this.calcItems ? this.calcItems.map((item: StackTableRow) => item.$название || 'Лицевой счет № ' + item.номер).join(', ') : undefined;
    },
  },
  data() {
    return {
      recalculatedNow: false,
      reasonEnable: false,
      reason: null,
      reasonComment: null,
      recalcMonthStart: null as Date | string | null,
      recalcMonthEnd: null as Date | string | null,
      semiRecalcDialogVis: false,
      semiRecalcParams: {},
      recalcID: null,
      rulesThis: {
        minDataMonth: (smonth: string, record: StackTableRow): string | true => {
          const firstMonthRecalc =
            record && record.тип && +record.тип === 5 ? this.$store.getters['flStore/getstartDataMonth']() : this.$store.getters['flStore/getfirstWorkMonth']();
          return Date.parse(smonth) >= Date.parse(firstMonthRecalc) || `Нельзя делать перерасчет ранее ${this.$stackDate.format(firstMonthRecalc, 'MM.YYYY')}`;
        },
        dateIsLessThan: (date1: string, date2: string): string | true => {
          return Date.parse(date1) >= Date.parse(date2) || !date1 || !date2 || 'Дата конца меньше даты начала';
        },
        dateIsGreaterThan: (date1: string, date2: string): string | true => {
          return Date.parse(date1) <= Date.parse(date2) || !date1 || !date2 || 'Дата начала больше даты конца';
        },
      },
      limit: 5,
    };
  },
  created() {
    const workMonth = this.closedMonthLs || (this.$store.getters.getCloseMonth() as Date);
    this.recalcMonthStart = this.record.перерасчет || workMonth;
    this.recalcMonthEnd = workMonth;
  },
  methods: {
    async onRecalc() {
      const obj = new this.$HttpModel('ЛицевыеСчета');
      if (obj.executeMethod) {
        try {
          this.recalculatedNow = true;
          const firstWorkMonth = this.$store.getters['flStore/getfirstWorkMonth']();
          let firstMonthRecalc = firstWorkMonth;
          if (this.record && this.record.тип && this.record.тип === 5) {
            firstMonthRecalc = this.record['начисления с'] > firstMonthRecalc ? this.record['начисления с'] : firstMonthRecalc;
          }
          const startMonthRecalc = this.recalcMonthStart && this.recalcMonthStart >= firstMonthRecalc ? this.recalcMonthStart : firstMonthRecalc;
          if (this.recalcMonthEnd && this.recalcMonthEnd >= startMonthRecalc) {
            this.recalcID = (
              await obj.executeMethod(
                'Рассчитать',
                {
                  номерЗаписи: this.rowid,
                  датНач: startMonthRecalc,
                  датКнц: this.recalcMonthEnd,
                  примечание: this.reasonComment,
                  причина: this.reason,
                  перерасчет: true,
                  фЭтоСЛС: !!this.record.перерасчет,
                },
                { async: this.async, jobName: 'Перерасчет лицевых', type: this.record && this.record.тип && this.record.тип === 5 ? undefined : 'multi' },
              )
            ).asyncId;
          }
          this.recalculatedNow = false;
          let isOldRecalc = false;
          if (this.record && this.record.тип && this.record.тип === 5 && this.recalcMonthStart && firstMonthRecalc > this.recalcMonthStart) {
            this.semiRecalcParams = {
              from: this.recalcMonthStart,
              to: this.recalcMonthEnd && firstMonthRecalc > this.recalcMonthEnd ? this.recalcMonthEnd : this.$stackDate.addMonths(firstMonthRecalc, -1),
            };
            this.semiRecalcDialogVis = true;
            isOldRecalc = true;
          }
          if (!isOldRecalc) {
            this.$emit('recalc', this.recalcID);
            this.$emit('close');
          }
        } catch (error: AnyException) {
          this.recalculatedNow = false;
          this.$toast(error && error.message ? error.message : 'Ошибка перерасчета', { color: 'error' });
        }
      }
    },
    onSave() {
      this.$emit('recalc', this.recalcID);
      this.$emit('close');
    },
  },
});
