
import Vue from 'vue';
import CalcTabMixin from './CalcTabMixin';

export default Vue.extend({
  name: 'ReadingsInfo',
  mixins: [CalcTabMixin],
  props: {
    typeCounters: { type: String, default: 'ИПУ,ОКПУ,ОДПУ' },
    noCorrections: { type: Boolean, default: false },
  },
  data() {
    return {
      http: new this.$HttpModel('ЛицевыеСчета.ДопИнформацияПоЛицевому'),
      items: [] as StackTableRow[],
      emptyText: '',
    };
  },
  methods: {
    async fetchData() {
      // @ts-ignore
      this.items = await this.http.executeMethod('ПолучитьПоказания', { владелец: this.ownerId, типСчетчиков: this.typeCounters, безКоррекции: this.noCorrections });
      this.emptyText = !this.items.length ? 'Показания за данный период отсутствуют' : '';
    },
    getIcon(service: number) {
      return this.$store.getters['flStore/getServiceTypeIcon'](service);
    },
    getIconColor(service: number) {
      return this.$store.getters['flStore/getServiceTypeIconColor'](service) + ' darken-4';
    },
    colorZone(zone: string): string {
      switch (zone.toLowerCase()) {
        case 'хвс':
          return 'info';
        case 'гвс':
          return 'error';
        default:
          return '';
      }
    },
    zone(item: string): string | undefined {
      if (!item) {
        return '';
      }
      switch (item.toLowerCase()) {
        case 'мг день':
          return '$vuetify.icons.mg_zone_day';
        case 'мг ночь':
          return '$vuetify.icons.mg_zone_night';
        case 'мг':
          return '$vuetify.icons.mg_zone';
        case 'мг пик':
          return '$vuetify.icons.mg_zone_pik';
        case 'мг ппик':
          return '$vuetify.icons.mg_zone_ppik';
        case 'день':
          return '$vuetify.icons.zone_day';
        case 'ночь':
          return '$vuetify.icons.zone_night';
        case 'ппик':
          return '$vuetify.icons.zone_ppik';
        case 'пик':
          return '$vuetify.icons.zone_pik';
        case 'хвс':
          return '$vuetify.icons.pu_water';
        case 'гвс':
          return '$vuetify.icons.pu_water';
        default:
          return undefined;
      }
    },
  },
});
