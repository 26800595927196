
import Vue from 'vue';
import { TableHandlers } from '@/stackEngine';
import serviceTable from '@/tasks/common/views/$common/serviceTable.vue';
import providerTable from '@/tasks/common/views/$common/providerTable.vue';
import indrateTable from '@/tasks/common/views/$common/indrateTable.vue';
import paramTable from '@/tasks/common/views/$common/paramTable.vue';

import KvitmessDialog from '@/tasks/fl/views/accounts/$resources/KvitmessDialog.vue';
import UkDialog from '@/tasks/fl/views/accounts/$resources/UkDialog.vue';

import LogsTab from '@/tasks/fl/views/accounts/$accountsid/$otherstabs/LogsTab.vue';
import LogsDocTab from '@/tasks/fl/views/accounts/$accountsid/$otherstabs/LogsDocTab.vue';
import FileTable from '@/tasks/common/views/$common/fileTable.vue';

import networkTab from './$components/networkTab.vue';

import FiasResult from '@/tasks/common/views/$common/FiasResult.vue';

export default Vue.extend({
  components: { paramTable, serviceTable, providerTable, UkDialog, KvitmessDialog, LogsTab, LogsDocTab, FileTable, indrateTable, networkTab, FiasResult },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      waitFias: false,
      findFias: false as boolean,
      houses: {} as any,
      hIndex: '',
      rulesThis: {
        isCorrectHouseLetter: (value: string): string | true => {
          const pattern = /^[0-9A-zА-я., \-/]*$/;
          return !value || pattern.test(value) || 'Нельзя использовать специальные символы кроме .,/-';
        },
        isCorrectYear: (value: number): string | true => {
          return value === 0 || (value > 0 && value <= +new Date().getFullYear()) || 'Некорректный год';
        },
        isCorrectSquare: (value: number): string | true => {
          return value === 0 || value > 0 || 'Некорректная площадь';
        },
        isCorrectFloors: (floorsCount: number, floorsMinCount: number): string | true => {
          return +floorsCount === 0 || +floorsMinCount === 0 || +floorsCount >= +floorsMinCount || 'Значение ниже наименьшего';
        },
      },
      измененыХарЗд: false as boolean,
    };
  },
  methods: {
    async onSyncFias() {
      this.waitFias = true;
      this.houses = await new this.$HttpModel('ЛицевыеСчета.ПоискДомаФиас').getRecords({
        номер: this.record['номер'],
        литера: this.record['фамилия'],
        улица: this.record['$папка'],
      });
      this.hIndex = await new this.$HttpModel('ЛицевыеСчета.Параметры').executeMethod('ПолучитьИндекс', { идДома: this.record.$номерЗаписи });
      if (this.houses.length > 0) {
        this.findFias = true;
      }
      this.waitFias = false;
    },
    async onFiasSave(_selectedHouse: any) {
      if (_selectedHouse != null) {
        const номер = _selectedHouse.номер || '';
        const литера = _selectedHouse.литера || '';
        const гуид = (_selectedHouse.гуид || '').toLowerCase();
        const индекс = _selectedHouse.индекс || '';
        const тип = _selectedHouse.тип || '';
        // проверка, есть ли изменения
        if (
          номер !== this.record.номер ||
          литера !== this.record.фамилия ||
          гуид !== this.record.гуид.toLowerCase() ||
          индекс !== this.hIndex ||
          тип !== this.record.типздания
        ) {
          if (await this.$yesno('Данные не совпадают, обновить?')) {
            this.record.номер = номер;
            this.record.фамилия = литера;
            this.record.типздания = тип;
            // не перезаписываем гуид, если не совпадает только регистр
            if (гуид !== this.record['гуид'].toLowerCase()) {
              this.record['гуид'] = гуид;
            }
            this.record['@индексдома'] = индекс;
            await new this.$HttpModel('ЛицевыеСчета.Параметры').executeMethod('ОбновитьИндекс', this.record);
            this.$sendMsg('stack-table', 'ЛицевыеСчета.Параметры', 'reload');
          } else {
            this.houses = {};
          }
        }
      }
      this.findFias = false;
    },
    onChangeSpecs(field: string, item: any) {
      this.измененыХарЗд = true;
      if (field === '@количество этажи' && item && +item > 0) {
        const minFloorsCount = this.record['@этажей наименьшее'] as number;
        if (minFloorsCount === 0 || minFloorsCount > +item) {
          this.record['@этажей наименьшее'] = +item;
        }
      }
    },
    ukColorHandler(item: any) {
      TableHandlers.isClosed(item, this.$store.getters.getWorkMonth());
      return item;
    },
  },
});
