
import Vue from 'vue';
import ReadingDialog from '@/tasks/fl/views/$common/$readingdialog/ReadingDialog.vue';
import ReadingInputDialog from '@/tasks/fl/views/$common/$readingdialog/ReadingInputDialog.vue';

export default Vue.extend({
  components: { ReadingDialog, ReadingInputDialog },
  props: {
    readingstatementsid: { type: [Number, String], required: true },
    parentID: { type: [Number, String], default: undefined },
  },
  computed: {
    title(): string {
      return 'Ведомость показаний';
    },
    typeinput(): string {
      return this.record['видсчета'] === 1 ? '0' : '1';
    },
    needRecalcLs(): boolean {
      return this.$store.getters['flStore/getOnRecalc']();
    },
  },
  data() {
    return {
      record: {} as StackTableRow,
    };
  },
  methods: {
    selectDialog(item: StackTableRow) {
      const idItem = item && item.$номерЗаписи ? item.$номерЗаписи.toString() : '';
      return idItem.indexOf('new') < 0;
    },
    saveReadings() {
      // @ts-ignore
      this.$refs.pokIPUtable.modalEventBus('close');
      this.$sendMsg('stack-table', 'ВедомостиПоказанийИПУ.Показания', 'reload');
    },
  },
});
