
import Vue from 'vue';
import commonStep from './$components/commonStep.vue';
import addressStep from './$components/addressStep.vue';
import paramStep from './$components/paramStep.vue';
import documentStep from './$components/documentStep.vue';
import peopleStep from './$components/peopleStep.vue';
import lgotsStep from './$components/lgotsStep.vue';
import counterStep from './$components/counterStep.vue';
import contactStep from './$components/contactStep.vue';
import filesStep from './$components/filesStep.vue';

type HookReleaseFunction = () => void;

export default Vue.extend({
  name: 'CreateLSMaster',
  components: { commonStep, addressStep, paramStep, documentStep, peopleStep, lgotsStep, counterStep, contactStep, filesStep },
  props: {
    accountsid: { type: [Number, String], default: undefined },
    parentID: { type: String, default: '' },
  },
  data() {
    return {
      steps: {} as any,
      record: {
        $номерЗаписи: 'new',
      } as StackTableRow,
      dataObject: new this.$HttpModel('ЛицевыеСчета'),
      params: {
        ДатаОткрытия: this.$store.getters.getWorkMonth(),
        ДатПарНач: this.$store.getters.getWorkMonth(),
        безПроверкиПараметров: true,
        безПроверкиВладельцев: true,
      },
      routeHook: null as null | HookReleaseFunction,
      isMainOwnerExist: false,
      isSave: false,
      isRecordDeleted: false,
    };
  },
  created() {
    this.routeHook = this.$router.beforeEach(this.deleteRecordOnLeave) as HookReleaseFunction;
  },
  beforeDestroy() {
    if (this.routeHook) {
      this.routeHook();
    }
  },
  computed: {
    cStep(): number {
      return Object.keys(this.steps).length;
    },
  },
  methods: {
    async nextStep(step: string) {
      this.params.ДатаОткрытия = this.record['датаоткрытия'];
      this.params.ДатПарНач = this.record['начисления с'];
      let res = true;
      this.params.безПроверкиПараметров = !(step === 'params' && !this.steps[step]);
      this.params.безПроверкиВладельцев = !(step === 'document' && !this.steps[step]);
      if (step === 'common' && this.cStep === 0) {
        // @ts-ignore
        res = await this.$refs.dialog.createRecord();
      } else if (!this.steps[step]) {
        // @ts-ignore
        res = await this.$refs.dialog.saveRecord();
      }
      if (!res) return;
      if (step === 'common' && !this.steps[step] && this.params.ДатПарНач !== this.record['начисления с']) {
        await this.dataObject.executeMethod('ОбновитьПараметры', { ДатПарНач: this.record['начисления с'], номерЗаписи: this.record.$номерЗаписи });
        this.params.ДатПарНач = this.record['начисления с'];
        this.$sendMsg('stack-table', 'ЛицевыеСчета.Параметры', 'reload');
      }
      this.$set(this.steps, step, !this.steps[step]);
    },
    save() {
      this.isSave = true;
      this.$router.push({
        name: 'accounts-accountsid',
        params: {
          accountsid: this.record.$номерЗаписи as string,
          noRouteHistory: 'true',
        },
      });
    },
    back() {
      this.$router.go(-1);
    },
    scroll() {
      const el = [...this.$el.querySelectorAll('.v-timeline-item')].pop();
      if (el) {
        this.$nextTick(() => {
          el.scrollIntoView({ block: 'end', behavior: 'smooth' });
        });
      }
    },
    checkMainOwner(isMainOwnerExist: boolean) {
      this.isMainOwnerExist = isMainOwnerExist;
    },
    async deleteRecordOnLeave(to: any, from: any, next: any) {
      if (!this.isSave && !this.isRecordDeleted) {
        if (Number(this.record.$номерЗаписи) >= 0) await this.dataObject.deleteRecords([this.record]);
        this.isRecordDeleted = true;
      }
      if (to.name === 'operations') {
        this.$router.push({
          name: 'accounts',
          query: {
            ...(from.params.parentID > -10 && {
              parentID: from.params.parentID,
            }),
          },
        });
      }
      next();
    },
  },
});
