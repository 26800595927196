
import Vue from 'vue';

export default Vue.extend({
  props: {
    ownerid: { type: Number, required: true },
  },
  data() {
    return {
      record: [] as any,
    };
  },
  async created() {
    const item = await new this.$HttpModel('УточнитьГоспошлинуЮЛ').getRecords({ дело: this.ownerid });
    if (item && item[0]) {
      this.record = item[0];
    }
  },
  computed: {
    начислено(): number {
      return this.record.тексумма - this.record.списано - this.record.избюджета;
    },
  },
});
