
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: { type: Object, required: true },
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    id: { type: Number, default: null },
    readonly: { type: Boolean, default: true },
    selectedItems: { type: [Object, Array], default: undefined }, // Array парсер
  },
  data() {
    return {
      items: [] as any,
    };
  },
  methods: {
    async sendInPayList() {
      if (this.selectedItems && this.selectedItems.length > 0) {
        const problemPays = [] as any;
        for (const row in this.selectedItems) {
          const problemPayRow = this.selectedItems[row];
          if (problemPayRow.$номерЗаписи) {
            problemPays[row] = +problemPayRow.$номерЗаписи;
          }
        }
        if (problemPays.length > 0) {
          const dataObject = new this.$HttpModel('ПлатежныеВедомости.ПроблемныеПлатежи');
          const countSendPays = await dataObject.executeMethod('ОтправитьВВедомость', { владелец: this.id, отмеченныеЗаписи: problemPays });
          if (+countSendPays >= 0) {
            this.$toast('Перенесено платежей - ' + countSendPays);
            this.$emit('clickSendInPayList', +countSendPays);
          }
        }
      }
    },
  },
});
