
import Vue from 'vue';
import datePeriodFilter from './datePeriodFilter.vue';
import numberTypeFilter from './numberTypeFilter.vue';
import organizationsFilter from './organizationsFilter.vue';

export default Vue.extend({
  components: { datePeriodFilter, organizationsFilter, numberTypeFilter },
  props: {
    isDisabled: { type: [Boolean], default: false },
  },
  data() {
    return {
      item: {
        номер: null,
        вид: null,
        датнач: null,
        даткнц: null,
        организация: null,
      } as any,
    };
  },
});
