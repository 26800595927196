
import Vue from 'vue';
import serviceFilter from './serviceFilter.vue';

export default Vue.extend({
  components: { serviceFilter },
  data() {
    return {
      item: {
        флаг: null,
        номер: null,
        тип: null,
        услуга: null,
        состояние: 1,
        фильтр: true,
        примечание: null,
        заводской_номер: null,
      } as any,
    };
  },
});
