
import Vue from 'vue';
import datePeriodFilter from './datePeriodFilter.vue';

export default Vue.extend({
  components: { datePeriodFilter },
  data() {
    return {
      item: {
        датнач: this.$stackDate.addDays(this.$store.getters.getCurrentDate(), -1),
        даткнц: this.$store.getters.getCurrentDate(),
      } as any,
    };
  },
});
