// TODO Удалить ?
const lg: StackSelection = {
  recordRoute: '',
  objectName: 'Льготы.Правила',
  description: 'Правила',
  fields: [
    {
      field: 'ключевая фраза',
      text: 'Ключевая фраза',
      type: 'String',
    },
    {
      field: 'имя',
      text: 'Имя',
      type: 'String',
    },
  ],
};

export default lg;
