import { TableHandlers } from '@/stackEngine';
export default {
  objectName: 'ЛицевыеСчета.Услуги',
  description: 'Услуги',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.состояние) {
      row.состояниекартинка = 'Не действует';
      // @ts-ignore
      row.$data.состояниекартинка = { $иконка: '$vuetify.icons.close', $иконка_цвет: 'grey' };
    } else {
      row.состояниекартинка = 'Действует';
      // @ts-ignore
      row.$data.состояниекартинка = { $иконка: '$vuetify.icons.check' };
    }

    TableHandlers.isClosed(row);
    return row;
  },
  fields: [
    {
      field: 'номер услуги',
      text: '№',
      type: 'Number',
    },
    {
      field: 'наименование',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'датнач',
      text: 'Начало',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Окончание',
      type: 'Date',
    },
    {
      field: 'вид-услуги',
      text: 'Ссылка на справочник услуг',
      type: 'Link',
    },
    {
      field: 'счет-услуги',
      text: 'Ссылка на лицевой',
      type: 'Link',
    },
    {
      field: 'состояниекартинка',
      text: 'Состояние',
      type: 'String',
      width: '170px',
      computed: true,
    },
    {
      field: '@тариф',
      text: '@Тариф',
      type: 'Number',
    },
  ],
} as StackSelection;
