
import Vue from 'vue';
import inputable from '../mixins/inputable';

export default Vue.extend({
  name: 'StackTimeField',
  mixins: [inputable],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    dataTestId: { type: String, default: undefined },
    placeholder: { type: [String], default: '00:00' },
    floatPlaceholder: { type: Boolean, default: false },
    value: { type: [String], default: null },
    clearable: { type: Boolean, default: false },
    noIcon: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      menu: false,
      manualValue: this.value,
    };
  },
});
