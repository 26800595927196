import { TableHandlers } from '@/stackEngine';
const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.Квитанции',
  recordRoute: '',
  description: '',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.флаги === 1) {
      // обнулим - чтобы отображать только иконку
      row.флаги = 0;
      TableHandlers.tdIcon(row, 'флаги', '$vuetify.icons.check', 'green');
    }
    return row;
  },
  fields: [
    {
      field: 'месяц',
      text: 'За месяц',
      type: 'DateMonth',
    },
    {
      field: 'начислено',
      text: 'Начислено',
      type: 'Money',
    },
    {
      field: 'оплачено',
      text: 'Оплачено',
      type: 'Money',
    },
    {
      field: 'входсальдо',
      text: 'Вх.сальдо',
      type: 'Money',
    },
    {
      field: 'исхсальдо',
      text: 'Исх.сальдо',
      type: 'Money',
    },
    {
      field: 'долгпени',
      text: 'Пени',
      type: 'Money',
    },
    {
      field: 'подготовкаавтор',
      text: 'Автор',
      type: 'String',
    },
    {
      field: 'способформирования',
      text: 'Сформирована',
      type: 'String',
    },
    {
      field: 'код',
      text: 'Код макета',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Имя макета',
      type: 'String',
    },
    {
      field: 'признакмакета',
      text: 'Признак макета',
      type: 'String',
    },
    {
      field: 'расчвремяпечати',
      text: 'Дата печати',
      type: 'String',
    },
    {
      field: 'расчвремяподготовки',
      text: 'Дата подготовки',
      type: 'String',
    },
    {
      field: 'естьфайл',
      text: 'ЕстьФайл',
      type: 'Number',
    },
    {
      field: 'флаги',
      text: 'ГИС',
      type: 'Number',
    },
  ],
};
export default accs;
