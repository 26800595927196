const typedebt: StackSelection = {
  objectName: 'СписокРабочихДней',
  description: 'Укажите будни',
  fields: [
    {
      field: '_дата',
      text: 'Дата',
      type: 'Date',
      width: '120px',
    },
    {
      field: 'день недели',
      text: 'День недели',
      type: 'String',
      readonly: true,
    },
  ],
};
export default typedebt;
