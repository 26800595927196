
import Vue from 'vue';
import { ReportsBuilder } from '../../middleware';

export default Vue.extend({
  props: {
    dataModel: { type: String, required: true },
    dark: { type: Boolean, default: false },
    type: { type: String, default: 'record' },
    disabled: { type: Boolean, default: false },
    title: { type: String, default: '' },
    favorite: { type: Boolean, default: false },
  },
  data() {
    return {
      reportList: [] as StackReportSelections[], // список отчетов для записи
      reportMenu: null, // видимость меню отчетов записи
      loading: false,
    };
  },
  computed: {
    favoriteReportNode(): any {
      const node = JSON.parse(JSON.stringify(this.$store.getters.getFavoriteReportNode()));
      node.icon = '$vuetify.icons.report_more';
      node.isGroup = true;
      node.children = this.setFrontEntries(node.children);
      return node;
    },
    cRecordsReport(): any {
      if (this.favoriteReportNode && this.favorite) return [this.favoriteReportNode, ...this.reportList];
      return this.reportList;
    },
  },
  methods: {
    // получаем список отчетов для записи F4
    async onReportMenu(menuVisible: boolean) {
      if (menuVisible && this.reportList.length === 0) {
        this.loading = true;
        switch (this.type) {
          case 'main':
            this.reportList = await new ReportsBuilder(this.dataModel).getReportMenu();
            break;
          default:
            this.reportList = await new ReportsBuilder(this.dataModel).getReportList(this.type !== 'record');
        }

        this.reportList = this.setFrontEntries(this.reportList);

        this.loading = false;
      }
    },

    setFrontEntries(reports: any) {
      for (const ind in reports) {
        reports[ind].title = reports[ind].title || reports[ind].name.replace('@', '');
        reports[ind].icon = reports[ind].children ? '$vuetify.icons.report_more' : '$vuetify.icons.async_type_report';
        if (reports[ind].children) {
          reports[ind].children = this.setFrontEntries(reports[ind].children);
        }
      }
      return reports;
    },

    // посылаем команду печати
    onMenuReportClick(reportName: string) {
      // Некоторы отчеты имеют после своего имени кучу пробелов, эти проверки не пройдут, если их не обрезать.
      this.$emit('click', reportName.trim());
    },
  },
  filters: {
    dogOut(value: string) {
      return value ? value.replace('@', '') : value;
    },
  },
});
