
import Vue from 'vue';

export default Vue.extend({
  name: 'DatePeriodFilter',
  model: { prop: 'params', event: 'input' },
  props: {
    params: { type: Object, required: true },
    models: { type: Object, default: () => ({ датнач: 'датнач', даткнц: 'даткнц' }) }, // позволяем вместо датнач и даткнц использовать альтернативные поля
    label: { type: String, default: 'По дате:' }, // заголовок
    type: { type: String, default: 'date' },
    requiredFrom: { type: Boolean, default: false },
  },
});
