const blanks: StackSelection = {
  objectName: 'Объекты.Бланки',
  description: 'Бланки',
  checkRow: row => {
    if (!row.номер) {
      return 'Номер бланка обязателен для заполнения!';
    }
    return false;
  },
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'вид пломбы',
      text: 'Вид бланка',
      type: 'Enum',
      dataModel: 'Объекты.Бланки.Вид',
    },
    {
      field: '@вид пломбы текст',
      text: 'Вид бланка',
      type: 'String',
    },
    {
      field: 'дата состояния',
      text: 'Дата состояния',
      type: 'Date',
      width: '160px',
    },
    {
      field: 'дата установки',
      text: 'Дата установки',
      type: 'Date',
      width: '160px',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Enum',
      dataModel: 'Объекты.Бланки.Состояния',
    },
    {
      field: '@состояние текст',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'пломба-объект',
      text: 'Объект',
      type: 'Link',
    },
    {
      field: 'пломба-сотрудник',
      text: 'Контролер',
      type: 'Link',
    },
    {
      field: 'пломба-сотрудник>фио',
      text: 'Контролер',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
  ],
};
export default blanks;
