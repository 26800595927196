import Vue from 'vue';
import { Module, GetterTree, MutationTree } from 'vuex';
import { store } from '.';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

interface SyncCache {
  [index: string]: object;
}

interface TaskList {
  title: string;
  img: string;
  href: string;
  disabled?: boolean;
  external?: boolean;
}

interface FavoriteReport {
  name: string;
  title: string;
  folderId: string;
  createFolder: boolean;
  remove: boolean;
}

interface ConfigState {
  appConfig: any; // Конфигурация приложения из env
  BUNDLES: string; // список бандлов через запятую (jkh,energo,rso,...)
  VERSION: string; // версия фронта
  MIN_BACKEND_VERSION: string; // минимальная разрешенная версия бэкэнда
  BACKEND_STATE_INTERVAL: number; // интервал опроса состояния бэкэнда
  ASYNC_JOBS_INTERVAL: number; // интервал опроса асинхронных задач
  NAVBAR_WIDTH: number; // Ширина меню в развернутом виде
  NAVBAR_WIDTH_MINI: number; // Ширина меню в свёрнутом виде
  isHelpTourActive: boolean; // Интерактивный помощник
  isHelpVisible: boolean; // Видимость помощи
  HelpSteps: StackTourStep[]; // Шаги ознакомительного тура
  HelpAction: string;
  Element: string;
  syncCache: SyncCache; // Кеш произвольных данных, которые синхр. с localStorage
  customAPIUrl: string | undefined; // кастомный URL бэкэнда. Если заполнен, то используется он
  urlDialogState: boolean; // Диалог выбора URLов бекэнда.
  rssOpen: boolean; // Открыт/закрыт навбар с RSS новостями
  traceLvl: number; // Уровень журналирования. 0: выкл, 1: включены ошибки, 2: 1 + трейс браузера, 3:  2 + трейс рендера Vue компонентов
  isSentryEnabled: boolean;
  taskList: TaskList[]; // Список задач для переключения программ
  favoriteReports: any;
  isHiddenMenuEnabled: boolean; // Видимость скрытого меню
  NO_SSL_AUTH: boolean; // Если true, то рефреш-токен будет сохраняться в локалстроаже, а не использовать http-only куки. Использовать в режиме production запрещено
}

const state: ConfigState = {
  appConfig: {},
  BUNDLES: '',
  VERSION: '',
  MIN_BACKEND_VERSION: '',
  NAVBAR_WIDTH: 260,
  NAVBAR_WIDTH_MINI: 56,
  BACKEND_STATE_INTERVAL: 20000,
  ASYNC_JOBS_INTERVAL: 2000,
  isHelpTourActive: false,
  isHelpVisible: false,
  HelpSteps: [],
  HelpAction: '',
  Element: '',
  syncCache: {},
  customAPIUrl: undefined,
  urlDialogState: false,
  rssOpen: true,
  traceLvl: -1,
  isSentryEnabled: false,
  taskList: [],
  favoriteReports: { name: 'Избранные', title: 'Избранные', children: [] },
  isHiddenMenuEnabled: false,
  NO_SSL_AUTH: false,
};

const getters: GetterTree<ConfigState, any> = {
  getAppConfig: (state: ConfigState) => () => {
    return state.appConfig;
  },
  // видимость навбара помощи на экране
  isHelpVisible: (state: ConfigState) => () => {
    return state.isHelpVisible;
  },
  // видимость помощника на экране
  isTourEnable: (state: ConfigState) => () => {
    return state.isHelpTourActive;
  },
  // возвращает шаги помощника
  HelpSteps: (state: ConfigState) => () => {
    return state.HelpSteps;
  },
  HelpAction: (state: ConfigState) => () => {
    return state.HelpAction;
  },
  Element: (state: ConfigState) => () => {
    return state.Element;
  },
  // Возвращает дату и время сборки фронта
  getBuildDateTime: (state: ConfigState) => () => {
    return state.appConfig.buildDateTime;
  },
  // Возвращает hash git ревизии сборки
  getbuildRevision: (state: ConfigState) => () => {
    return state.appConfig.buildRevision;
  },
  // Возвращает URL канала новостей
  getRssFeedUrl: (state: ConfigState) => () => {
    return state.appConfig.RSS_FEED;
  },
  // Возвращаем ключ хранения токена
  getTokenStorageName: (state: ConfigState) => () => {
    return state.appConfig.SESSION_PER_TASK ? `${store.getters.getCurrentTask()}` : 'common';
  },
  // Возвращает список URLов для смены бэкэнда "на лету"
  getAPIUrls: (state: ConfigState) => () => {
    const currentTask = store.getters.getCurrentTask().toUpperCase();
    const currentAPIName = `API_LIST_${currentTask}`;
    return state.appConfig[currentAPIName];
  },
  getApiHostBaseName: (state: ConfigState) => (customAPI: string | undefined) => {
    const currentTask = store.getters.getCurrentTask().toUpperCase();
    let currentTaskName = `API_HOST_${currentTask}`;
    if (customAPI) {
      const customUrlWithTaskName = `API_HOST_${currentTask}_${customAPI.toUpperCase()}`;
      currentTaskName = state.appConfig[customUrlWithTaskName] ? customUrlWithTaskName : `API_HOST_${customAPI.toUpperCase()}`;
    }
    return currentTaskName;
  },
  // адрес бэкэнда в конфиге API_HOST_*ПрефиксЗадачи* или если не указан, то основной API_HOST
  getApiHost: (state: ConfigState) => (customAPI: string | undefined) => {
    const currentTaskHostName = store.getters.getApiHostBaseName(customAPI);
    const currentTask = store.getters.getCurrentTask();
    const host = state.customAPIUrl || state.appConfig[currentTaskHostName] || `${state.appConfig.API_HOST}/stackgateway/${currentTask}`;
    return host.replace(/{host}/gi, window.location.host).replace(/{http}/gi, window.location.protocol);
  },
  // url адрес состояния бека
  getApiHostHealth: (state: ConfigState) => () => {
    const currentTaskHostName = store.getters.getApiHostBaseName();
    return state.appConfig[`${currentTaskHostName}_HEALTH`] || state.appConfig.API_HOST_HEALTH || `${state.appConfig.API_HOST}/actuator`;
  },
  // url адрес авторизации
  getApiHostAuth: (state: ConfigState) => () => {
    const currentTaskHostName = store.getters.getApiHostBaseName();
    const url = state.appConfig[`${currentTaskHostName}_AUTH`] || state.appConfig.API_HOST_AUTH || `${state.appConfig.API_HOST}/stackgateway/auth`;
    return `${url}/login`;
  },
  getApiHostLogout: (state: ConfigState) => () => {
    const currentTaskHostName = store.getters.getApiHostBaseName();
    const url = state.appConfig[`${currentTaskHostName}_AUTH`] || state.appConfig.API_HOST_AUTH || `${state.appConfig.API_HOST}/stackgateway/auth`;
    return `${url}/logout`;
  },
  // url адрес рефреша
  getApiHostAuthRefresh: (state: ConfigState) => () => {
    const currentTaskHostName = store.getters.getApiHostBaseName();
    const url = state.appConfig[`${currentTaskHostName}_AUTH`] || state.appConfig.API_HOST_AUTH || `${state.appConfig.API_HOST}/stackgateway/auth`;
    return `${url}/refresh`;
  },

  // url адрес, по которому забирать данные, например сформированные бэкэндом пдфки отчетов
  getApiHostShare: (state: ConfigState) => () => {
    const currentTaskHostName = store.getters.getApiHostBaseName();
    return state.appConfig[`${currentTaskHostName}_SHARE`] || state.appConfig.API_HOST_SHARE || `${state.appConfig.API_HOST}/stackgateway/share`;
  },

  // url адрес для загрузки файлов на сервер
  getApiHostUpload: (state: ConfigState) => () => {
    const currentTaskHostName = store.getters.getApiHostBaseName();
    return state.appConfig[`${currentTaskHostName}_UPLOAD`] || state.appConfig.API_HOST_UPLOAD || `${state.appConfig.API_HOST}/stackgateway/upload`;
  },

  // url адрес graphql
  getApiGraphql: (state: ConfigState) => () => {
    const currentTaskHostName = store.getters.getApiHostBaseName();
    return state.appConfig[`${currentTaskHostName}_GRAPHQL`] || state.appConfig.API_HOST_GRAPHQL || `${state.appConfig.API_HOST}/graphql`;
  },

  // таймаут обращений к бэкэнду
  getTimeout: (state: ConfigState) => () => {
    return state.appConfig.API_HOST_TIMEOUT || 60000;
  },

  getNavBarWidth: (state: ConfigState) => () => {
    return state.NAVBAR_WIDTH;
  },

  getNavBarWidthMini: (state: ConfigState) => () => {
    return state.NAVBAR_WIDTH_MINI;
  },

  // Перечень задач
  getBundles: (state: ConfigState) => () => {
    return state.BUNDLES.split(',');
  },

  // Перечень задач
  getTaskList: (state: ConfigState) => () => {
    return state.taskList;
  },

  // версия фронтэнда (из package.json)
  getFrontendVersion: (state: ConfigState) => () => {
    return state.VERSION;
  },

  // минимальная разрешенная версия бэкэнда (из package.json)
  getMinBackendVersion: (state: ConfigState) => () => {
    return state.MIN_BACKEND_VERSION;
  },

  // интервал опроса состояния опроса бэкэнда
  getBackendStateInterval: (state: ConfigState) => () => {
    return state.BACKEND_STATE_INTERVAL;
  },

  // интервал опроса состояния асинхронных задач
  getAsyncJobsInterval: (state: ConfigState) => () => {
    return state.ASYNC_JOBS_INTERVAL;
  },

  getSyncCache: (state: ConfigState) => (id: string) => {
    return state.syncCache[id];
  },
  sentryMaxLatancy: (state: ConfigState) => () => {
    return state.appConfig.SENTRY_REQUEST_LATENCY || 0;
  },
  // Уровень протоколирования Sentry
  traceLvl: (state: ConfigState) => () => {
    return state.traceLvl === -1 ? state.appConfig.SENTRY_TRACE_LVL : state.traceLvl;
  },
  // Урезанная версия задачи администрирования
  getLimitedAdmin: (state: ConfigState) => () => {
    return state.appConfig.LIMITED_ADMIN;
  },
  getFavoriteReportNode: (state: ConfigState) => () => {
    return state.favoriteReports;
  },
};

const mutations: MutationTree<ConfigState> = {
  SAVE_CACHE_DATA(state: ConfigState, payload: { id: string; data: object }) {
    state.syncCache[payload.id] = payload.data;
  },
  // Активация помощника
  SET_HELP_TOUR_STATE(state: ConfigState, val: boolean) {
    state.isHelpTourActive = val;
  },
  // Вызов навбара помощи
  SET_HELP_STATE(state: ConfigState, val: boolean) {
    state.isHelpVisible = val;
  },
  SET_HELP_TOUR_ACTION(state: ConfigState, action: string) {
    state.HelpAction = action;
  },
  SET_HELP_TOUR_ELEMENT(state: ConfigState, el: string) {
    state.Element = el;
  },
  SET_HELP_STEPS(state: ConfigState, steps: StackTourStep[]) {
    state.HelpSteps = steps;
  },
  SET_FAVORITE_REPORT(state: ConfigState, payloadArray: FavoriteReport[]) {
    for (const payload of payloadArray) {
      const report = {
        name: payload.name,
        children: payload.createFolder && [],
        folderId: payload.createFolder && new Date().valueOf().toString(),
      };
      const sort = (array: any) => {
        array.sort((a: any, b: any) => !a.children && b.children ? 1 : a.children && !b.children ? -1 : 0);
      };
      const loop = (children: any, folderId: string) => {
        children.forEach((child: any) => {
          if (child.children) {
            if (payload.remove && child.folderId === folderId) {
              child.children = child.children.filter((child: any) => child.name !== payload.name);
              if (!child.children.length) state.favoriteReports.children = state.favoriteReports.children.filter((child: any) => !child.folderId || (child.folderId !== folderId));
            } else if (child.folderId === folderId) {
              child.children.push(report);
              sort(child.children);
              return;
            }
            loop(child.children, folderId);
          }
        });
      };
      if (payload.folderId) {
        loop(state.favoriteReports.children, payload.folderId);
      } else if (payload.remove) {
        state.favoriteReports.children = state.favoriteReports.children.filter((child: any) => child.name !== payload.name);
      } else {
        state.favoriteReports.children.push(report);
        sort(state.favoriteReports.children);
      }
    }
  },
  SET_FAVORITE_REPORT_NODE(state: ConfigState, node) {
    state.favoriteReports.children = node;
  },
  // Устанавливает url обращения к бэкэнду
  SET_CUSTOM_BACKEND_URL(state: ConfigState, url: string | undefined) {
    state.customAPIUrl = url;
  },
  SET_RSS_STATE(state: ConfigState, payload: boolean) {
    state.rssOpen = payload;
  },
  // Показ диалога выбора урлов бекэнда
  SET_URL_DIALOG_STATE(state: ConfigState, payload: boolean) {
    state.urlDialogState = payload;
  },
  ENABLE_HIDDEN_MENU(state: ConfigState) {
    state.isHiddenMenuEnabled = !state.isHiddenMenuEnabled;
  },
  SET_APP_CONFIG(state: ConfigState, cfg: any) {
    state.appConfig = { ...state.appConfig, ...cfg };
    if (cfg) {
      if (cfg.BACKEND_STATE_INTERVAL) {
        state.BACKEND_STATE_INTERVAL = cfg.BACKEND_STATE_INTERVAL;
      }
      if (cfg.ASYNC_JOBS_INTERVAL) {
        state.ASYNC_JOBS_INTERVAL = cfg.ASYNC_JOBS_INTERVAL;
      }
      if (cfg.version) {
        state.VERSION = cfg.version;
      }
      if (cfg.minBackendVersion) {
        state.MIN_BACKEND_VERSION = cfg.minBackendVersion;
      }
      if (cfg.BUNDLES) {
        state.BUNDLES = cfg.BUNDLES;
      }
      if (cfg.TASK_LIST) {
        state.taskList = cfg.TASK_LIST;
      }
      if (cfg.NO_SSL_AUTH) {
        state.NO_SSL_AUTH = true;
      }
    }
  },
  // инициализация SENTRY
  // 1 - только ошибки, 2 - телеметрия, 3 - телеметрия с Vue компонентами
  SENTRY_INIT(state: ConfigState) {
    const traceLvl = store.getters.traceLvl();
    if (state.isSentryEnabled || traceLvl > 0) {
      const integrations = [];
      if (traceLvl >= 2) {
        integrations.push(new Integrations.BrowserTracing());
      }
      Sentry.init({
        Vue,
        integrations,
        release: `stack-front@${state.appConfig.version}`,
        dsn: traceLvl > 0 ? state.appConfig?.SENTRY_URL : undefined,
        tracesSampleRate: state.appConfig?.SENTRY_TRACES_SAMPLE_RATE || 1.0,
        tracingOptions: {
          trackComponents: traceLvl >= 3,
        },
      });
      state.isSentryEnabled = true;
    }
  },
  // Уровень логгирования Sentry
  SENTRY_LOG_LVL(state: ConfigState, val: number) {
    state.traceLvl = val;
    store.commit('SENTRY_INIT');
  },

  SENTRY_SEND_EXCEPTION(state: ConfigState, options: { message: string, payload: any }) {
    if (store.getters.traceLvl() > 0) {
      const tags = options.payload && options.payload.latency ? { api_latency: options.payload.latency } : undefined;
      Sentry.captureMessage(options.message, { contexts: { payload: options.payload }, tags });
    }
  },
};

const cacheStore: Module<ConfigState, any> = {
  state,
  getters,
  mutations,
};

export default cacheStore;
