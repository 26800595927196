const serv: StackSelection = {
  recordRoute: '',
  objectName: 'Услуги.Журнал',
  description: 'Нормы',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'время',
      text: 'Время',
      type: 'DateTime',
      width: '15%',
    },
    {
      field: 'пользователь',
      text: 'Пользователь',
      type: 'String',
    },
    {
      field: 'позиция',
      text: 'Действие',
      type: 'String',
    },
  ],
};

export default serv;
