
import Vue from 'vue';
import CalcTabMixin from './CalcTabMixin';

export default Vue.extend({
  name: 'LsInfoLight',
  mixins: [CalcTabMixin],
  data() {
    return {
      http: new this.$HttpModel('ЛицевыеСчета.ДопИнформацияПоЛицевому'),
      items: [] as StackTableRow[],
    };
  },
  methods: {
    async fetchData() {
      // @ts-ignore
      this.items = await this.http.executeMethod('ПолучитьОбщиеДанные', { владелец: this.ownerId });
    },
  },
});
