export default {
  objectName: 'ИсторияВерсий.Задачи',
  customApi: 'admin',
  fields: [
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
  ],
} as StackSelection;
