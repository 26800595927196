
import Vue from 'vue';

export default Vue.extend({
  name: 'CountersTab',
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  methods: {
    routeTo(payload: any) {
      if (payload) {
        this.$router.push({
          name: 'documents-qualityacts-qualityactsid',
          params: {
            qualityactsid: payload['документ-качество'] as string,
            readonly: 'true',
          },
        });
      }
    },
  },
});
