const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ЖурналИзменения_Таблицы',
  description: '',
  fields: [
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
  ],
};
export default accs;
