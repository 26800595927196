
import Vue from 'vue';
export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    hash: { type: String, required: true },
    descr: { type: String, default: undefined },
    noCheck: { type: Boolean, default: false },
    forceRestoreFields: { type: String, default: '' },
  },
  methods: {
    onRestoreParams() {
      // Поищем сохраненные параметры
      const lastState = this.$store.getters.getSyncCache(this.hash);
      for (const field in lastState) {
        if (lastState?.[field] !== undefined && (this.noCheck || this.record?.[field] !== undefined)) {
          // Если поля уже заполнены, то игнорируем их
          if (
            ((typeof this.record[field] === 'number' && this.record[field] > 0) ||
            (typeof this.record[field] === 'string' && this.record[field] !== '')) &&
            !this.forceRestoreFields.split(',').includes(field)
          ) {
            continue;
          }
          this.$set(this.record, field, lastState[field]);
        }
      }
    },

  },
});
