const accs: StackSelection = {
  objectName: 'Счетчики.МассовыйВвод',
  description: '',
  fields: [
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'наименование',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: 'заводскойномер',
      text: 'Заводской номер',
      type: 'String',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчетный месяц',
      text: 'Месяц',
      type: 'Date',
    },
    {
      field: '@датпредсч',
      text: 'Дата предыдущая',
      type: 'Date',
    },
    {
      field: '@меспредсч',
      text: 'Месяц предыдущий',
      type: 'Date',
    },
    {
      field: '@типпредсч',
      text: 'типпредсч',
      type: 'Number',
    },
    {
      field: 'показание',
      text: 'Показание',
      type: 'Number',
    },
    {
      field: '@покпредсч',
      text: 'Показание предыдущее',
      type: 'Number',
    },
    {
      field: 'расход',
      text: 'Расход',
      type: 'Number',
    },
    {
      field: 'итоговый расход',
      text: 'Итоговый расход',
      type: 'Number',
    },
    {
      field: 'дополнительный расход',
      text: 'Доп. расход',
      type: 'Number',
    },
    {
      field: 'типсчетчика',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'тариф',
      text: 'Зона суток',
      type: 'String',
    },
    {
      field: 'типввода',
      text: 'Тип ввода',
      type: 'Number',
    },
    {
      field: '@тип ввода',
      text: 'Тип ввода',
      type: 'String',
    },
    {
      field: 'объект-показания',
      text: 'Объект',
      type: 'Number',
    },
    {
      field: 'разрядность',
      text: 'Разрядность',
      type: 'Number',
    },
    {
      field: 'дробнаяразрядность',
      text: 'ДробнаяРазрядность',
      type: 'Number',
    },
    {
      field: 'коэффициент трансформации',
      text: 'КТранс',
      type: 'Number',
    },
    {
      field: 'Адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'номер',
      text: 'номер',
      type: 'Number',
    },
    {
      field: 'марка',
      text: 'наименование счетчика',
      type: 'String',
    },
    {
      field: '@зона',
      text: 'Зона суток',
      type: 'String',
    },
    {
      field: 'объект-показания',
      text: 'объект-показания',
      type: 'Number',
    },
    {
      field: 'услуга_id',
      text: 'услуга_id',
      type: 'Number',
    },
    {
      field: 'счмесяцев',
      text: 'СчМесяцев',
      type: 'Number',
    },
    {
      field: 'ограничениерасхода',
      text: 'ОграничениеРасхода',
      type: 'Number',
    },
    {
      field: '$ошибкаПоказания',
      text: '$ошибкаПоказания',
      type: 'Boolean',
    },
    {
      field: '$ошибкаРасхода',
      text: '$ошибкаРасхода',
      type: 'Boolean',
    },
    {
      field: '$ошибкаДаты',
      text: '$ошибкаДаты',
      type: 'Boolean',
    },
    {
      field: '$ошибкаТарифности',
      text: '$ошибкаТарифности',
      type: 'Boolean',
    },
    {
      field: 'тарифность',
      text: 'тарифность',
      type: 'Number',
    },
    {
      field: 'показания-документ',
      text: 'Показания-Документ',
      type: 'Number',
    },
    {
      field: 'место установки',
      text: 'место установки',
      type: 'Number',
    },
    {
      field: 'микрогенерация',
      text: 'микрогенерация',
      type: 'String',
    },
  ],
};
export default accs;
