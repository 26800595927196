
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: {
      type: Object,
      default: (): any => {
        return {};
      },
    },
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        Дата: this.params.isRemove ? this.$store.getters.getCurrentDate() : this.$store.getters.getWorkMonth(),
        МесНач: this.$store.getters.getWorkMonth(),
        МесКнц: this.$store.getters.getWorkMonth(),
        ПричинаСнятия: 2,
        НомерЗаписиУслуги: -1,
        НомерЗаписиКатегории: -1,
        Флаги: 0,
        ТипСнятия: 0,
        ТипСтроения: 0,
      },
    };
  },
});
