const minp: StackSelection = {
  objectName: 'МассовыйВвод.Адреса',
  description: 'Адресный фонд',
  fields: [
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'номерлс',
      text: 'НомерЛС',
      type: 'String',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
  ],
};

export default minp;
