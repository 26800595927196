
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        МесНач: this.$store.getters.getWorkMonth(),
        МесКнц: this.$store.getters.getWorkMonth(),
        ДатНач: this.$store.getters.getWorkMonth(),
        ДатКнц: this.$stackDate.lastDay(this.$store.getters.getWorkMonth()),
        Флаги: 0,
        Флаги1: false,
        Переключатель: 0,
        'Поставщик-Услуги': -1,
        file: '',
      },
    };
  },
});
