
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      list: [] as number[],
    };
  },
  created() {
    // TODO в кампутед
    const s: string = this.record.примечание;
    let data = [] as any;
    if (s) {
      data = s.split('//');
    }

    let val = 0;
    for (const i in data) {
      if (data[i]) {
        val = parseFloat(data[i]);
      } else {
        val = 0;
      }
      this.list.push(val);
    }
  },
  methods: {
    onChange() {
      this.record.примечание = '';

      let val = 0;
      for (let i = 0; i < 5; i++) {
        if (this.list[i]) {
          val = this.list[i];
        } else {
          val = 0;
        }
        this.record.примечание += val + '//';
      }
    },
  },
});
