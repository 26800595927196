
import Vue from 'vue';
import changeSelections from '../../../../components/countersmaster/changeSelections.vue';
import { ChangeCountersTransport } from '../../../../components/countersmaster/ITransportTypes';

export default Vue.extend({
  components: { changeSelections },
  model: {
    prop: 'record',
  },
  props: {
    title: { type: String, default: 'Показание' },
    calcMonth: { type: [Date, String], required: true },
    ownerID: { type: Number, required: true },
    parentRecord: { type: Object, required: true },
    params: { type: Object, required: true },
  },
  data() {
    return {
      record: { счет: null, тип: this.params.type || 0 },
      loading: false,
      component: {} as any,
      typeCounter: this.params.typeCounter || 'ИПУ',
    };
  },
  methods: {
    onChange(data: ChangeCountersTransport) {
      this.component = Object.assign({}, this.component, data);
    },
    async onSave() {
      if (!this.component.oldCounter['@безпоказаний'] && this.component.oldReadings[0].показание === this.component.oldReadings[0].послпок) {
        if (!(await this.$yesno(`Не указаны (оставлены по умолчанию) конечные показания. Это верно?`, 'Внимание', { width: '600px' }))) {
          return;
        }
      }

      this.loading = true;

      const parameters = {
        ведомость: this.ownerID,
        расчмес: this.calcMonth,
        счет: this.record.счет,
        данные: this.component,
      } as any;

      try {
        await new this.$HttpModel('АктыСменыПУ.Ведомость').executeMethod('Внести', parameters);
        this.$emit('save');
      } catch (error: AnyException) {
        // error
      } finally {
        this.loading = false;
      }
    },
  },
});
