
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';
import { StackSelectionCache } from '@/stackEngine';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: 'значение',
          text: 'Наименование',
          type: 'String',
        },
      ],
      maxIndexNumber: 0,
    };
  },
  created() {
    StackSelectionCache.clearCacheByModel('Счетчики.ПричиныСнятия');
  },
  methods: {
    rowhandler(item: any) {
      // Если новая запись, то ключ не объявлен - и возьмём его из переменной, так как поле не доступно на редактирование
      item.ключ = item.ключ === undefined ? +this.maxIndexNumber + 1 : item.ключ;
      // значение - чтобы не летело как пустое
      item.значение = item.значение || '';
      return item;
    },
    changeTable(items: StackTableRow[]) {
      items.forEach((row: StackTableRow) => {
        const curIndexNumber = row.ключ ? +row.ключ : 0;
        this.maxIndexNumber = Math.max(+this.maxIndexNumber, curIndexNumber);
      });
    },
  },
});
