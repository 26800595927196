
import Vue from 'vue';
import JSON from 'json5';
import { stackDate } from '@/stackEngine';

export default Vue.extend({
  data() {
    return {
      textFunc: '',
      isAsync: 0,
      response: '' as any,
      error: '',
      loading: false,
      jobID: null as null | number,
      dataModel: new this.$HttpModel('СервисныеОперации'),
      jsonBeautify: false,
      beginTime: '' as any,
      endTime: '' as any,
    };
  },
  computed: {
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || undefined;
    },
    bResult(): any {
      if (this.jsonBeautify) {
        try {
          return JSON.stringify(this.response || this.error, null, 2);
        } catch (e: AnyException) {}
      }
      return this.response || this.error;
    },
    historyRecords(): any {
      return this.$store.getters.getSOHistoryRecords;
    },
  },
  methods: {
    async onSave() {
      this.response = '';
      this.error = '';
      this.loading = true;
      this.beginTime = new Date();
      if (this.isAsync) {
        this.jobID = (await this.dataModel.executeMethod(
          'ВызватьФункцию',
          { text: this.textFunc },
          {
            async: true,
            jobName: `Выполнение функции`,
            external: true,
            silent: true,
            onComplete: () => {
              this.loading = false;
              this.response = this.resultJob.data.result;
              this.endTime = new Date();
              this.setNewHistoryRecord();
            },
            onError: () => {
              this.loading = false;
              this.error = this.resultJob.status;
              this.endTime = new Date();
              this.setNewHistoryRecord();
            },
          },
        )).asyncId;
      } else {
        try {
          this.response = await this.dataModel.executeMethod('ВызватьФункцию', { text: this.textFunc });
        } catch (e: AnyException) {
          this.error = e;
        } finally {
          this.endTime = new Date();
          this.setNewHistoryRecord();
        }
        this.loading = false;
      }
    },
    setNewHistoryRecord() {
      const textFunc = this.textFunc;
      const date = stackDate.format(new Date(), 'DD.MM.YYYY');
      const time = stackDate.format(new Date(), 'Time');
      const executionTime = `${(this.endTime.getMilliseconds() - this.beginTime.getMilliseconds()) / 100} сек`;
      const status = this.error ? 'с ошибкой' : 'успешно';
      this.$store.commit('SET_NEW_SO_HISTORY_RECORD', { textFunc, date, time, executionTime, status });
    },
    clearHistory() {
      this.$store.commit('CLEAR_SO_HISTORY');
    },
  },
});
