
import Vue from 'vue';

export default Vue.extend({
  name: 'LsInfo',
  props: {
    record: { type: Object, required: true },
    category: { type: [Number, String], default: 0 },
    typestroy: { type: [Number, String], default: 0 },
  },
  methods: {
    openNewTab() {
      if (this.record?.['$номерЗаписи']) {
        const routeData = this.$router.resolve({ path: `/accounts/${this.record?.['$номерЗаписи']}` });
        window.open(routeData.href, '_blank');
      }
    },
  },
});
