const orgs: StackSelection = {
  recordRoute: '',
  objectName: 'ЛицевыеСчета.ДействующиеСчетчики',
  description: 'Счетчики по услугам',
  fields: [
    {
      field: 'видсчетчика',
      text: 'Вид',
      type: 'String',
    },
    {
      field: 'типпу',
      text: 'Тип',
      type: 'String',
    },
    {
      field: 'заводскойномер',
      text: 'Заводской номер',
      type: 'String',
    },
    {
      field: 'марка',
      text: 'Марка',
      type: 'String',
    },
    {
      field: 'датаследующейповерки',
      text: 'Дата поверки',
      type: 'Date',
    },
    {
      field: 'разрядность',
      text: 'Разрядность',
      type: 'Number',
    },
    {
      field: 'тарифность',
      text: 'Тарифность',
      type: 'Number',
    },
    {
      field: 'коэфтранс',
      text: 'КоэфТранс',
      type: 'Number',
    },
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'состояниестрока',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'состояние',
      type: 'Number',
    },
    {
      field: 'документ',
      text: 'документ',
      type: 'Link',
    },
  ],
};

export default orgs;
