
import Vue from 'vue';
import datePeriodFilter from './datePeriodFilter.vue';

export default Vue.extend({
  components: { datePeriodFilter },
  props: {
    accountID: { type: Number, default: -1 },
    notOnlyDate: { type: Boolean, default: false },
    withFlag: { type: Boolean, default: false },
  },
  data() {
    return {
      params: { номерЗаписи_Параметры: this.accountID },
      item: {
        датнач: '',
        даткнц: '',
        таблица: 'Все',
        флаг: 0,
      } as any,
    };
  },
});
