
import Vue from 'vue';
import DateBeginDialog from '../$resources/DateBeginDialog.vue';

export default Vue.extend({
  components: { DateBeginDialog },
  props: {
    tableAction: { type: Function, required: true }, // коллбек к таблице
    selectedValues: { type: [String, Number], default: undefined },
    selectedItems: { type: [Object, Array], default: undefined }, // Array парсер
    tableInfo: { type: [Object, Array], default: undefined }, // Array парсер
  },
  data() {
    return {
      items: [] as any,
      dateBeginDialogVis: false,
      typels: 5,
    };
  },
  methods: {
    async onBtnClick(typels: number, parentID: number) {
      if (+typels === this.typels) {
        const result = await new this.$HttpModel('ЛицевыеСчета').executeMethod('ПрочитатьКонстанту', { константа: 'СОЗДАНИЕЛС' });
        if (+result) {
          this.$router.push({
            name: 'operations-createlsmaster',
            params: {
              parentID: parentID ? parentID.toString() : '',
            },
          });
        } else {
          this.dateBeginDialogVis = true;
        }
      } else {
        const payload = { тип: typels };
        this.tableAction({ action: 'add_folder', payload });
      }
    },
    async onBtnMenuClick(parentID: number) {
      const data = this.$store.getters['flStore/getlsHierTypes']();
      const obj = new this.$HttpModel('ЛицевыеСчета');
      this.items = [];
      if (parentID > 0) {
        const rec = await obj.getRecords({ номерЗаписи: parentID });
        data.forEach((item: any) => {
          if (rec[0] && item.type === rec[0].тип) {
            this.items = item.items; // собираем массив для уровня
          }
        });
      }
      if (this.items.length === 0) {
        data.forEach((item: any) => {
          if (item.type === -10) {
            this.items = item.items; // когда только попали в адресный список, у нас нет родительсткой записи - предлагаем для уровня -10
          }
        });
      }
    },
    onDateBeginDialog(event: string, params: any) {
      this.dateBeginDialogVis = false;
      let payload = {};
      if (event === 'ok' && params && params.ДатаОткрытия && params.ДатПарНач) {
        payload = { тип: this.typels, ДатаОткрытия: params.ДатаОткрытия, ДатПарНач: params.ДатПарНач };
      } else if (event === 'cancel') {
        payload = false;
      }
      if (payload) {
        this.tableAction({ action: 'add', payload });
      }
    },
  },
});
