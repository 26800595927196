
import Vue from 'vue';
import datePeriodFilter from './datePeriodFilter.vue';
import monthPeriodFilter from './monthPeriodFilter.vue';
import serviceFilter from './serviceFilter.vue';
import numberTypeFilter from './numberTypeFilter.vue';
import sumFilter from './sumFilter.vue';

export default Vue.extend({
  components: { datePeriodFilter, monthPeriodFilter, serviceFilter, sumFilter, numberTypeFilter },
  data() {
    return {
      item: {
        номер: null,
        сумма: null,
        услуга: null,
        датнач: this.$stackDate.firstDay(this.$store.getters.getCloseMonth()),
        даткнц: null,
        месяцс: null,
        месяцпо: null,
      } as any,
    };
  },
});
