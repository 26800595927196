
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    hidefield(): boolean {
      return this.$store.getters.getCurrentTask() === 'fl';
    },
  },
});
