
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: { type: Object, required: true },
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    id: { type: Number, default: null },
    readonly: { type: Boolean, default: true },
    isNewRecord: { type: Boolean, default: true },
    dialogChanged: { type: Boolean, default: false },
    selectedItems: { type: [Object, Array], default: undefined }, // Array парсер
  },
  data() {
    return {
      items: [] as any,
      payListsDialogVisible: false,
    };
  },
  methods: {
    includePayLists(to: any) {
      if (this.dialogChanged) {
        this.$toast('Есть измененные данные! Необходимо сохранить документ!', { color: 'error' });
        return;
      }

      this.payListsDialogVisible = true;
    },
    async onBtnChoosePayLists(selectedPayLists: StackTableRow[]) {
      this.payListsDialogVisible = false;

      if (selectedPayLists && selectedPayLists.length > 0) {
        const payLists = [] as any;
        for (const row in selectedPayLists) {
          const payListRow = selectedPayLists[row];
          if (payListRow.$номерЗаписи) {
            payLists[row] = +payListRow.$номерЗаписи;
          }
        }
        if (payLists.length > 0) {
          try {
            if (this.dataObject && this.dataObject.executeMethod) {
              const result = await this.dataObject.executeMethod('ДобавитьПлатВедомости', { нзВыписки: this.id, отмеченныеЗаписи: payLists });
            }
          } catch {
            this.$toast('Не удалось выполнить операцию !');
          } finally {
            this.$emit('clickIncludePayLists');
          }
        }
      }
    },
    async excludePayLists() {
      if (this.selectedItems && this.selectedItems.length > 0) {
        const payLists = [] as any;
        for (const row in this.selectedItems) {
          const payListRow = this.selectedItems[row];
          if (payListRow.$номерЗаписи) {
            payLists[row] = +payListRow.$номерЗаписи;
          }
        }
        if (payLists.length > 0) {
          try {
            if (this.dataObject && this.dataObject.executeMethod) {
              await this.dataObject.executeMethod('ИсключитьПлатВедомости', { нзВыписки: this.id, отмеченныеЗаписи: payLists });
            }
          } catch {
            this.$toast('Не удалось выполнить операцию !');
          } finally {
            this.$emit('clickExcludePayLists');
          }
        }
      }
    },
    async divVed(rows: StackTableRow[]) { await this.dataObject.executeMethod('РаспределитьПачку', { нзВедомости: rows[0]['$номерЗаписи'] }); },
  },
});
