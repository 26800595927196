
import Vue from 'vue';
import { StaticModel } from '@/stackEngine';
import PlombDialog from '@/tasks/common/views/$common/counters/counterid/$dialogs/PlombDialog.vue';

export class SealsModel extends StaticModel {
  public async initRecord(params?: any): Promise<StackTableRow> {
    const newRec = {} as StackTableRow;
    newRec.$номерЗаписи = 'new';
    newRec.номер = 1;
    newRec['дата установки'] = params.датнач;
    newRec['дата проверки'] = params.датнач;
    newRec['дата состояния'] = params.датнач;
    newRec.состояние = 2;
    newRec['вид пломбы'] = 0;
    newRec['пломба-сотрудник'] = params.inspector;
    return newRec;
  }
}

export default Vue.extend({
  props: {
    params: { type: Object, default: undefined },
    sealsInit: {
      type: Object,
      default: () => {
        return {};
      },
    },
    inspector: { type: Number, default: null },
  },
  components: { PlombDialog },
  data() {
    return {
      newPlombDialogVisible: false,
      types: [] as StackTableRow[],
      states: [] as StackTableRow[],
      sealsModel: [] as any,
      header: [
        {
          field: 'номер',
          text: 'Номер',
          type: 'Number',
        },
        {
          field: 'вид пломбы',
          text: 'Вид',
          type: 'Enum',
          dataModel: 'Объекты.Пломбы.Вид',
        },
        {
          field: '@вид',
          text: 'Вид',
          type: 'String',
        },
        {
          field: 'место установки',
          text: 'Место установки',
          type: 'String',
        },
        {
          field: 'дата установки',
          text: 'Дата установки',
          type: 'Date',
        },
        {
          field: 'дата выдачи',
          text: 'Дата выдачи',
          type: 'Date',
        },
        {
          field: 'дата снятия',
          text: 'Дата снятия',
          type: 'Date',
          width: '140px',
        },
        {
          field: 'состояние',
          text: 'Состояние',
          type: 'Enum',
          dataModel: 'Объекты.Пломбы.Состояния',
        },
        {
          field: '@состояние',
          text: 'Состояние',
          type: 'String',
        },
        {
          field: 'дата проверки',
          text: 'Дата проверки',
          type: 'Date',
        },
      ] as StackField[],
    };
  },
  async created() {
    this.sealsModel = new SealsModel([], { rowTemplate: this.header, description: 'Пломбы', checkRow: this.checkRowSealsData });
    this.states = await new this.$HttpModel('Объекты.Пломбы.Состояния').getRecords();
    this.types = await new this.$HttpModel('Объекты.Пломбы.Вид').getRecords();
    let currentRowid = 1;
    for (const id in this.sealsInit) {
      // $номерЗаписи - нужен для корректной работы с редактированием записей. sealsInit - для наборной выборки
      this.sealsModel.data.push({ ...this.sealsInit[id], $номерЗаписи: currentRowid++ });
    }
    this.sealsModel.currentRowid = currentRowid;
    // @ts-ignore
    this.$refs.table.fetchData();
  },
  computed: {
    datecouneter(): Date {
      return this.params && this.params.датнач ? this.params.датнач : this.$store.getters.getCurrentDate();
    },
  },
  methods: {
    checkRowSealsData(item: StackTableRow): false | string {
      if (this.$stackDate.isAfter(this.datecouneter, item.датапломбы)) {
        return 'Дата установки пломбы меньше даты установки счетчика';
      }
      if (this.$stackDate.isAfter(item.датапломбы, item.датапроверкипломбы)) {
        return 'Дата проверки пломбы меньше даты установки пломбы';
      }
      return false;
    },
    rowhandler(item: any) {
      item['@состояние'] = item.состояние !== undefined ? this.states[item.состояние].значение : '';
      item['@вид'] = item['вид пломбы'] !== undefined ? this.types[item['вид пломбы']].значение : '';
      return item;
    },
    async onBtnChoosePlomb(newpar: StackTableRow[]) {
      this.newPlombDialogVisible = false;
      if (newpar[0]['пломба-объект'] && newpar[0]['пломба-объект'] > 0) {
        return this.$toast(`Пломба ${newpar[0].номер} уже привязана к прибору!`, { color: 'error' });
      }
      const date = newpar[0]['дата установки'] ? newpar[0]['дата установки'] : this.$store.getters.getCurrentDate();
      this.sealsModel.data.push({
        ...newpar[0],
        состояние: 2,
        $selected: false,
        'дата установки': date,
        'дата проверки': date,
        'дата снятия': '',
        'дата состояния': date,
        'дата выдачи': newpar[0]['дата выдачи'] ? newpar[0]['дата выдачи'] : date,
        'пломба-сотрудник':
          this.inspector || (newpar[0]['пломба-сотрудник'] && newpar[0]['пломба-сотрудник'] > 0 ? newpar[0]['пломба-сотрудник'] : this.$store.getters.getUserId()),
        нзпломбыизреестра: newpar[0].$номерЗаписи,
        сохранить: 1,
      });
      // @ts-ignore
      this.$refs.table.fetchData();
    },
  },
});
