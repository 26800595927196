
import Vue from 'vue';
import Fetch from '../mixins/fetchable';

/**
 * Побитовые флаги
 */
export default Vue.extend({
  name: 'StackFlags',
  mixins: [Fetch],
  model: { prop: 'value', event: 'change' },
  inject: {
    isDialogReadonly: { default: () => false },
  },
  props: {
    /**
     * текущее значение
     * @model
     */
    value: { type: [Number, String], default: null },
    /**
     * какое поле является ключем в записи. По-умолчанию это $номерЗаписи
     */
    itemValue: { type: String, default: '$номерЗаписи' },
    /**
     * модель данных
     */
    dataModel: { type: [String, Object, Array], required: true },
    /**
     * отображение строкой или в столбец
     */
    row: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    /**
     * объект с кастомными параметрами в запрос
     */
    params: { type: [Object], default: null },
    /**
     * Массив задизабленных флагов
     */
    disabledIndex: { type: Array, default: undefined },
    /**
     * Запрет на поднятие любых флагов
     */
    noInput: { type: Boolean, default: false },
    toggleAllBtn: { type: Boolean, default: false },
    toggleAllLabel: { type: String, default: 'Выбрать все значения' },
    dense: { type: Boolean, default: false },
  },
  computed: {
    isInputDisabled(): boolean {
      // @ts-ignore
      return this.disabled || (this.isDialogReadonly && this.isDialogReadonly());
    },
    queryParams(): StackHttpRequestTaskParam {
      return this.params;
    },
    isSelected(): Boolean {
      // @ts-ignore
      return this.items.filter((item: StackTableRow) => this.value & (1 << item[this.itemValue])).length === this.items.length;
    },
  },
  data() {
    return {
      items: [] as any,
    };
  },
  methods: {
    onChange(event: boolean, idx: number) {
      this.$emit('change', this.changedValue(event, idx, +this.value));
    },
    changedValue(event: boolean, idx: number, value: number) {
      const mask = 1 << idx;
      return event ? value | mask : value & ~mask;
    },
    toggleAll() {
      let value = this.value as number;
      this.items.forEach((item: StackTableRow) => {
        const id = +(item[this.itemValue] || 0);
        if (!!(value & (1 << id)) === this.isSelected) {
          value = this.changedValue(!this.isSelected, id, value);
        }
      });
      this.$emit('change', value);
    },

    // получение данных с бэкэнда
    async fetchData() {
      // @ts-ignore
      if (this.dataObject.getRecords) {
        // @ts-ignore
        this.items = await this.dataObject.getRecords(this.queryParams);
      }
      this.$emit('init', this.items);
    },
  },
});
