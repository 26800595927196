
import Vue from 'vue';
export default Vue.extend({
  name: 'StackTimelineItem',
  props: {
    success: { type: Boolean, default: false }, // флаг успешного выполнения текущего шага
    error: { type: Boolean, default: false }, // флаг ошибки текущего шага
    warning: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    iconError: { type: String, default: '$vuetify.icons.close_circle' }, // иконка ошибки текущего шага
    iconSuccess: { type: String, default: '$vuetify.icons.check' }, // иконка успешного выполнения текущего шага
    iconWarning: { type: String, default: '$vuetify.icons.error' },
  },
  computed: {
    dotIcon(): string | undefined {
      if (this.error) {
        return this.iconError;
      }
      if (this.success) {
        return this.iconSuccess;
      }
      if (this.warning) {
        return this.iconWarning;
      }
      return undefined;
    },
    dotColor(): string | undefined {
      if (this.error) return 'error';
      if (this.success) return 'success';
      if (this.warning) return 'warning';
      return undefined;
    },
  },
});
