
import Vue from 'vue';
export default Vue.extend({
  props: {
    dataObject: { type: Object, required: true },
    ownerID: { type: Number, required: true },
    selectedValues: { type: [String, Number], default: undefined },
    selectedItems: { type: Array, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    noAdd: { type: Boolean, default: false },
    noDelPairing: { type: Boolean, default: false }, // надо ли вызывать метод ОтвязатьПломбы
    dataModel: { type: String, default: 'Объекты.Пломбы' }, // dataModel для таблицы и метода ОтвязатьПломбы
    dataModelForPair: { type: String, default: 'ЛицевыеСчета.Объекты' }, // dataModel для метода ПривязатьПломбу
    inspector: { type: Number, default: null },
  },
  data() {
    return {
      newPlombDialogVisible: false,
    };
  },
  methods: {
    async onBtnChoosePlomb(newpar: StackTableRow[]) {
      this.newPlombDialogVisible = false;
      if (newpar && newpar[0]) {
        await new this.$HttpModel(this.dataModelForPair).executeMethod('ПривязатьПломбу', {
          тип: 0,
          пломба: newpar[0].$номерЗаписи,
          объект: this.ownerID,
          контролер: this.inspector,
        });
        this.$sendMsg('stack-table', this.dataModel, 'reload');
      }
    },
    async onDeleteClick() {
      const msg = this.selectedItems.length > 1 ? ` выделенных записей: ${this.selectedItems.length}` : 'текущую запись';
      if (!this.noDelPairing && await this.$yesno(`Вы уверены, что хотите удалить\n${msg} ?`, 'Внимание', { width: '600px' })) {
        await new this.$HttpModel(this.dataModel).executeMethod('ОтвязатьПломбы', { выбрано: this.selectedValues });
        this.$sendMsg('stack-table', this.dataModel, 'unselect-all');
        this.$sendMsg('stack-table', this.dataModel, 'reload');
      }
    },
    onBtnAdd() {
      const payload = { контролер: this.inspector };
      this.tableAction({ action: 'add', payload });
    },
  },
});
