
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: {
      type: Object,
      default: (): any => {
        return {};
      },
    },
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        Месяц: this.$store.getters.getWorkMonth(),
        '@Услуга': -1,
        '@КатегорияУслуг': -1,
        '@Поставщик': -1,
        '@КатегорияПоставщиков': -1,
        '@УК': -1,
        ВыводитьПени: 0,
        фСальдо: 0,
        Детализация: !this.params.onSaldo ? 1 : 0,
      },
      paramDetail: {
        поСальдо: this.params.onSaldo !== undefined ? this.params.onSaldo : 0,
      },
      disItems: [true] as boolean[],
    };
  },
  methods: {
    checkDisabled(value: number) {
      // Получаем степень двойки через log2
      const i = Math.log2(this.record['Детализация']);
      // Если не целое число, то выброно несколько галок
      if (Number.isInteger(i)) {
        this.disItems[i] = true;
      } else {
        this.disItems = [];
      }
    },
  },
});
