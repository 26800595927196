
import Vue from 'vue';

export default Vue.extend({
  props: {
    hoverinfo: { type: String, required: true },
    icon: { type: String, required: true },
    title: { type: String, default: undefined },
    left: { type: Boolean, default: false },
    bottom: { type: Boolean, default: false },
  },
});
