
import Vue from 'vue';
import qualityActsFilter from '@/tasks/fl/views/$common/filters/qualityActsFilter.vue';
import docFolder from '@/tasks/common/views/$common/documentFolder.vue';
import QualityToolbar from './$dialogs/QualityToolbar.vue';

export default Vue.extend({
  name: 'Qualityacts',
  components: { qualityActsFilter, docFolder, QualityToolbar },
  methods: {
    updateTable() {
      // @ts-ignore
      this.$refs.table.fetchData();
    },
  },
});
