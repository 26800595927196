
import Vue from 'vue';
import undisable from '../mixins/undisable';
export default Vue.extend({
  name: 'FormUndisabled',
  mixins: [undisable],
  provide(): any {
    return {
      // @ts-ignore
      isDialogReadonly: () => false,
    };
  },
});
