
import Vue from 'vue';
import { StackSelectionCache } from '@/stackEngine';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        Услуга: -1,
        Месяц: this.$store.getters.getWorkMonth(),
        Объем: 0,
        ТипПУ: 0,
        url: window.location.origin + this.$router.resolve(StackSelectionCache.getSelectionUrl('ЛицевыеСчета', false)).href,
      },
      objectService: [] as StackTableRow[],
    };
  },
  async created() {
    try {
      const dataV = await new this.$HttpModel('УслугиПоСчетчику_Значения').getRecords();
      const dataK = await new this.$HttpModel('УслугиПоСчетчику_Ключи').getRecords();
      for (const key in dataV) {
        this.objectService.push({ значение: dataV[key].значение, $номерЗаписи: dataK[key].значение } as StackTableRow);
      }
    } catch (error: AnyException) {
      // eslint-disable-next-line
      console.log('slbigvolume fetchData error');
    }
  },
});
