
import Vue from 'vue';

export default Vue.extend({
  props: {
    param: { type: Object, required: true },
  },

  data() {
    return {
      oneclick: false,
      dataObject: new this.$HttpModel('ВыборГоспошлины'),
      record: {
        заседаниесуд: -1,
        гпкарточка: null as null | string,
        колкарт: 1,
      },
      info: {
        колгп: 1,
        суммагп: 0,
        суммасущгп: 0,
        суд: -1,
      },
    };
  },
  async created() {
    let dop = [];
    const res = await new this.$HttpModel('ОперацииЕдиничногоПерехода').executeMethod('НачальныеПараметры', { состояние: this.param.$номерЗаписи, тип: 'ЗапросПоГоспошлине' });

    if (res) {
      dop = JSON.parse(res);
      if (dop.length !== 0) {
        this.record.заседаниесуд = dop[0].суд;
        this.record.гпкарточка = dop[0].гпкарточка;
        this.record.колкарт = dop[0].колкарт;
        this.info.суд = dop[0].суд;
        this.info.колгп = dop[0].колгп;
        this.info.суммагп = dop[0].суммагп;
        this.info.суммасущгп = dop[0].суммасущгп;
      }
    }
  },
  computed: {
    title(): string {
      // @ts-ignore
      return 'Сумма госпошлины будет составлять ' + this.info.суммагп + ' руб. ';
    },
    title2(): string {
      // @ts-ignore
      if (this.info.суммасущгп !== 0) {
        return 'в т.ч. будет учтено ' + this.info.суммасущгп + ' руб. ';
      } else {
        return '';
      }
    },
  },

  methods: {
    async changeCourtField(gpload: any) {
      if (gpload && gpload[0]) {
        const result = await this.dataObject.executeMethod('ПроверкаПолей', { поле: 'заседаниесуд', заседаниесуд: gpload[0].$номерЗаписи });
        // @ts-ignore
        this.record.колкарт = result;
        this.$emit('change', this.record);
      }
    },
    async changeGPField(gpload: any) {
      if (gpload && gpload[0]) {
        let выборкарточек = '';
        gpload.forEach((element: any) => {
          if (element && element.$номерЗаписи) {
            выборкарточек += выборкарточек.length === 0 ? element.$номерЗаписи : ',' + element.$номерЗаписи;
          }
        });
        const result = await this.dataObject.executeMethod('ПроверкаПолей', {
          поле: 'гпкарточка',
          номерЗаписи: this.param['документ-состояние'],
          суммагп: this.info.суммагп,
          суммасущгп: this.info.суммасущгп,
          колгп: this.info.колгп,
          надату: this.param.датнач,
          заседаниесуд: this.record.заседаниесуд,
          гпкарточка: выборкарточек,
        });
        if (result) {
          this.record.гпкарточка = '';
          this.$emit('change', this.record);
        }
      }
    },
  },
});
