
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      source: null,
      source_id: null,
      type: null,
      type_id: null,
      person: null,
      person_id: null,
    };
  },
  created() {
    const arr = this.record.значение.split(';');
    switch (arr.length) {
      case 3: {
        const arr_source = arr[0].split(',');
        const arr_type = arr[1].split(',');
        const arr_person = arr[2].split(',');

        this.source_id = arr_source[0];
        this.type_id = arr_type[0];
        this.person_id = arr_person[0];

        this.source = arr_source[1];
        this.type = arr_type[1];
        this.person = arr_person[1];
        break;
      }
      case 2: {
        const arr_source = arr[0].split(',');
        const arr_type = arr[1].split(',');

        this.source_id = arr_source[0];
        this.type_id = arr_type[0];

        this.source = arr_source[1];
        this.type = arr_type[1];
        break;
      }
      default:
        break;
    }
  },

  computed: {
    value(): string {
      return this.source_id + ',' + this.source + ';' + this.type_id + ',' + this.type + (this.person_id ? ';' + this.person_id + ',' + this.person : '');
    },
  },

  methods: {
    changeFunc(payload: any, type: number) {
      if (payload && payload[0]) {
        switch (type) {
          case 0:
            this.source = payload[0].наименование;
            break;
          case 1:
            this.type = payload[0].наименование;
            break;
          default:
            this.person = payload[0].фио;
            break;
        }
      }
    },
  },
  watch: {
    value(to) {
      this.$set(this.record, 'значение', this.value);
    },
  },
});
