
import Vue from 'vue';

export default Vue.extend({
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      closedMonthLs: '',
    };
  },
  computed: {
    title(): string {
      switch (this.record.видакта) {
        case 2:
          return `Акт по решению суда`;
        case 3:
          return `Акт отключения`;
        default:
          return `Акт подключения`;
      }
    },
    isDisabled(): boolean {
      return this.record.$толькоЧтение || this.$attrs.readonly;
    },
  },
  methods: {
    async calcAct() {
      try {
        this.isLoading = true;
        await new this.$HttpModel('АктыДолжников').executeMethod('Рассчитать', { владелец: this.record.$номерЗаписи, запись: this.record });
      } catch {
        return false;
      } finally {
        this.isLoading = false;
      }
      return true;
    },
    async onChangeService() {
      if (this.record.видакта === 3 || this.record.видакта === 4) {
        this.isLoading = true;
        const result = await new this.$HttpModel('АктыДолжников').executeMethod('ПолучитьТариф', { услуга: this.record['акт-услуга'] });
        this.$set(this.record, 'сумма', result);
        this.isLoading = false;
      }
    },
    // Высчитываем поля @оплата и сумма из полей госпошлина, пени и издержки
    calcSum() {
      this.record['@оплата'] = +this.record['@госпошлина'] + +this.record['@пени'] + +this.record['@издержки'];
      this.record['сумма'] = this.record['@оплата'];
    },
    // Считываем с выбранного лицевого закрытый месяц
    closeLS(lsRows: StackTableRow[]) {
      if (lsRows && lsRows[0]) {
        this.closedMonthLs = lsRows[0]['@вадмрежиме'] ? this.$stackDate.prevMonth(lsRows[0].закрытыймесяц, 1) : (lsRows[0].закрытыймесяц as string);
        if (lsRows[0].$номерЗаписи) {
          this.checkMonth();
        }
      }
    },
    // Если месяц закрыт на лс,то выводим сообщение для пользователя, чтобы не забыли запустить перерасчет
    checkMonth() {
      if (Date.parse(this.closedMonthLs as string) >= Date.parse(this.record['месяц'] as string) && !this.isDisabled) {
        this.$stackMsg([
          {
            текст: `Вы указали закрытый месяц! Не забудьте запустить перерасчет на ЛС!`,
            варианты: ['ОК'],
          },
        ]);
      }
    },
    onClose() {
      if (this.$route.query.back) {
        this.$router.go(-1);
      }
    },
  },
});
