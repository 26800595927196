
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      password: null,
      passwordRetry: null,
      passwordOld: null,
      isPassVisible: false,
      isPassVisible1: false,
      isPassVisible2: false,
      loading: false,
    };
  },
  computed: {
    checkPass(): string | true {
      return this.password !== this.passwordRetry ? 'Пароли не совпадают' : !!this.password || 'Пароль не может быть пустым';
    },
  },
  methods: {
    async onSave() {
      const http = new this.$HttpModel('СотрудникиРасширенная');
      this.loading = true;
      try {
        await http.executeMethod('СменитьПароль', { старыйПароль: this.passwordOld, пароль1: this.password, пароль2: this.passwordRetry });
        this.$toast('После смены пароля в программу необходимо перезайти');
        this.$store.dispatch('SIGN_OUT');
        this.$emit('close');
      } finally {
        this.loading = false;
      }
    },
  },
});
