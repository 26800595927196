
import Vue from 'vue';
import changeSelections from '../../../../components/countersmaster/changeSelections.vue';
import { ChangeCountersTransport } from '../../../../components/countersmaster/ITransportTypes';
import PlombDialog from './PlombDialog.vue';
import PlombToolbar from '@/tasks/common/views/$common/counters/counterid/PlombToolbar.vue';
import paramTable from '@/tasks/common/views/$common/paramTable.vue';

export default Vue.extend({
  components: { changeSelections, PlombDialog, PlombToolbar, paramTable },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    readononly: { type: [Boolean], default: false },
    filter: { type: Object, default: undefined },
    inspector: { type: Number, default: null },
    enableMobApp: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      component: {} as any,
      oldCounter: {}, // снятый ПУ
      newCounter: {}, // установленный ПУ
      newCounterDoc: {}, // установленный ПУ
      oldReadings: {}, // Показания при снятии
      newReadings: {}, // Показания при снятии
      changeCounterIsSetup: false,
      changeCounterIsClose: false,
      newCounterlsGroup: '',
      lengthParamTable: 0,
      lengthSealsTable: 0,
    };
  },
  computed: {
    isReadOnly(): boolean {
      // если это не отсутствие нарушений или несоответствие оборудования, то запись можно редактировать
      return this.record.$номерЗаписи === -1 ? false : this.record.тип !== 1 ? this.readononly : true;
    },
    calcMonth(): Date {
      return this.$stackDate.firstDay(this.filter.dateact);
    },
    params(): any {
      return {
        счет: this.filter.ls,
        тип: this.record.операция,
      };
    },
    type(): string {
      return this.record.операция === 0 ? 'замена' : this.record.операция === 1 ? 'установка' : 'снятие';
    },
    isSetSeals(): boolean {
      return this.record.тип === 6;
    },
    isAddParams(): boolean {
      return this.record.тип === 7;
    },
    disabledTableSeals(): boolean {
      return this.filter.status === 1;
    },
    title(): String {
      return this.record['@тип'] + (this.record['@операция'] ? ' : ' + this.record['@операция'] : '');
    },
    oldCounterSaved(): Object {
      return this.record.json_сменаПУ && this.record.json_сменаПУ.oldCounter ? this.record.json_сменаПУ.oldCounter : {};
    },
    newCounterSaved(): Object {
      return this.record.json_сменаПУ && this.record.json_сменаПУ.newCounter ? this.record.json_сменаПУ.newCounter : {};
    },
    newCounterDocSaved(): Object {
      return this.record.json_сменаПУ && this.record.json_сменаПУ.newCounterDoc ? this.record.json_сменаПУ.newCounterDoc : {};
    },
    newReadingsSaved(): Object {
      return this.record.json_сменаПУ && this.record.json_сменаПУ.newReadings ? this.record.json_сменаПУ.newReadings : {};
    },
    oldReadingsSaved(): Object {
      return this.record.json_сменаПУ && this.record.json_сменаПУ.oldReadings ? this.record.json_сменаПУ.oldReadings : {};
    },
    newCounterlsGroupSaved(): string {
      return this.record.json_сменаПУ && this.record.json_сменаПУ.newCounterlsGroup ? this.record.json_сменаПУ.newCounterlsGroup : '';
    },
    // доп. данные при инициализации диалога
    addDataDoc(): Object {
      const addDataBuffer = this.record['@допданные'] || ('' as String);
      if (addDataBuffer) {
        return JSON.parse(addDataBuffer.toString());
      }
      return {};
    },
    valueIsEmpty(): boolean {
      return (
        (this.record.тип === 2 &&
          (!this.record.json_показания ||
            !this.record.json_показания[0] ||
            this.record.json_показания[0].показание === null ||
            this.record.json_показания[0].показание === undefined)) ||
        (this.isSetSeals && this.lengthSealsTable === 0) ||
        (this.isAddParams && this.lengthParamTable === 0)
      );
    },
  },
  async created() {
    if (this.isSetSeals) {
      await new this.$HttpModel('АктыОбхода.Пломбы').executeMethod('ЗаполнитьТаблицуПломб', { номерЗаписи: this.record.$номерЗаписи, информация: this.record.информация });
    }
    if (this.isAddParams) {
      await new this.$HttpModel('АктыОбхода.Пломбы').executeMethod('ЗаполнитьТаблицуПараметров', { номерЗаписи: this.record.$номерЗаписи, информация: this.record.информация });
    }
  },
  methods: {
    onChangePok(payload: StackTableRow[]) {
      const buf = [] as StackTableRow[];
      payload.forEach((item: StackTableRow) => {
        buf.push({
          марка: item.марка,
          'объект-показания': item['объект-показания'],
          тариф: item['тариф'],
          дата: item.дата,
          'расчетный месяц': item['расчетный месяц'],
          показание: item.показание,
          'дополнительный расход': item['дополнительный расход'],
        });
      });
      this.$set(this.record, 'json_показания', buf);
    },
    onChange(data: ChangeCountersTransport) {
      this.oldCounter = {};
      this.newCounter = {};
      this.newCounterDoc = {};
      this.newReadings = {};
      this.oldReadings = {};
      this.changeCounterIsSetup = data.установка || false;
      this.changeCounterIsClose = data.снятие || false;
      if (data.снятие && data.oldCounter && data.oldReadings) {
        this.oldCounter = {
          дата: data.oldCounter.дата,
          среднее: data.oldCounter.среднее,
          'n п/п': data.oldCounter['n п/п'],
          'объект-услуга': data.oldCounter['объект-услуга'],
          объект: data.oldCounter.объект,
          причина: data.oldCounter.причина,
          огрмесяцевсреднего: data.oldCounter.огрмесяцевсреднего,
          датначср: data.oldCounter.датначср,
          заводскойномер: data.oldCounter.заводскойномер,
          даткнцср: data.oldCounter.даткнцср,
          тарифность: data.oldCounter.тарифность,
          микрогенерация: data.oldCounter.микрогенерация,
        };
        const tarCounter = data.oldCounter.тарифность ? +data.oldCounter.тарифность : 0;
        const mg = data.oldCounter.микрогенерация ? +data.oldCounter.микрогенерация : 0;
        let tariffmg = tarCounter;
        if (mg) {
          tariffmg = tariffmg * 2;
        }
        for (const tar in data.oldReadings) {
          if (+tar < tariffmg) {
            // @ts-ignore
            this.oldReadings[tar] = { показание: data.oldReadings[tar].показание, допрасход: data.oldReadings[tar].допрасход, расход: data.oldReadings[tar].расход };
          }
        }
        this.$set(this.record, '@типпу', data.typeCounter);
      }
      if (data.установка && data.newCounter && data.newReadings) {
        this.newCounter = {
          датнач: data.newCounter.датнач,
          датаповерки: data.newCounter.датаповерки,
          датаследующейповерки: data.newCounter.датаследующейповерки,
          'коэффициент трансформации': data.newCounter['коэффициент трансформации'],
          'объект-услуга': data.newCounter['объект-услуга'],
          'номенклатура-объекты': data.newCounter['номенклатура-объекты'],
          заводскойномер: data.newCounter.заводскойномер,
          доступ: data.newCounter.доступ,
          'место установки': data.newCounter['место установки'],
          годвыпуска: data.newCounter.годвыпуска,
          тарифность: data.newCounter.тарифность,
          разрядность: data.newCounter.разрядность,
          дробнаяразрядность: data.newCounter.дробнаяразрядность,
          'n п/п': data.newCounter['n п/п'],
          микрогенерация: data.newCounter.микрогенерация,
        };
        if (data.newCounterSeals && Object.keys(data.newCounterSeals).length > 0) {
          const arraySeals = [];
          for (const id in data.newCounterSeals) {
            arraySeals.push({
              'вид пломбы': data.newCounterSeals[id]['вид пломбы'],
              'дата проверки': data.newCounterSeals[id]['дата проверки'],
              'дата снятия': data.newCounterSeals[id]['дата снятия'],
              'дата состояния': data.newCounterSeals[id]['дата состояния'],
              'дата установки': data.newCounterSeals[id]['дата установки'],
              'пломба-сотрудник': data.newCounterSeals[id]['пломба-сотрудник'],
              'место установки': data.newCounterSeals[id]['место установки'],
              номер: data.newCounterSeals[id].номер,
              состояние: data.newCounterSeals[id].состояние,
              нзпломбыизреестра: data.newCounterSeals[id].нзпломбыизреестра || null,
            });
          }
          this.newCounter = { ...this.newCounter, пломбы: arraySeals };
        }
        if (data.newCounterDoc) {
          this.newCounterDoc = {
            номер: data.newCounterDoc.номер,
            тема: data.newCounterDoc.тема,
            примечание: data.newCounterDoc.примечание,
            'правила-документы': data.newCounterDoc['правила-документы'],
          };
        }
        this.newCounterlsGroup = data.newCounterlsGroup || '';
        const tarCounter = data.newCounter.тарифность ? +data.newCounter.тарифность : 0;
        const mg = data.newCounter.микрогенерация ? +data.newCounter.микрогенерация : 0;
        let tariffmg = tarCounter;
        if (mg) {
          tariffmg = tariffmg * 2;
        }
        for (const tar in data.newReadings) {
          if (+tar < tariffmg) {
            // @ts-ignore
            this.newReadings[tar] = { показание: data.newReadings[tar].показание, допрасход: data.newReadings[tar].допрасход };
          }
        }
      }
    },
    // при типе "несоответствие оборудования" нужно формировать акт замены
    async onSave() {
      if (this.record.тип === 2) {
        // @ts-ignore
        const resCheck = this.$refs.tableReadings.checkReadingsOnValidTarriff();
        return resCheck;
      }
      if (this.record.тип === 3) {
        let bufForSave = {};
        if (this.changeCounterIsClose) {
          bufForSave = { ...bufForSave, oldCounter: this.oldCounter, oldReadings: this.oldReadings };
        }
        if (this.changeCounterIsSetup) {
          bufForSave = { ...bufForSave, newCounter: this.newCounter, newReadings: this.newReadings };
          if (this.record['@типпу'] === 'ОКПУ' && !this.changeCounterIsClose) {
            bufForSave = { ...bufForSave, newCounterDoc: this.newCounterDoc, newCounterlsGroup: this.newCounterlsGroup };
          }
        }
        // добавим служебную информацию, для упрощения дальнейшего анализа
        bufForSave = { ...bufForSave, typeCounter: this.record['@типпу'], снятие: this.changeCounterIsClose, установка: this.changeCounterIsSetup };
        this.$set(this.record, 'json_сменаПУ', bufForSave);
      }
      return true;
    },
    openCounter(item: StackTableRow) {
      const routeData = this.$router.resolve({ path: `/accounts/${this.params.счет}?openModal=${item['объект-показания']}#counters` });
      open(routeData.href, '_blank');
    },
  },
  watch: {
    'record.$номерЗаписи': {
      immediate: true,
      handler(to) {
        if (to > 0) {
          if (this.record.информация) {
            const temp = JSON.parse(this.record.информация);
            for (const i in temp) {
              if (i.startsWith('json')) {
                this.record[i] = temp[i];
              }
            }
          }
        }
      },
    },
    async isSetSeals(to) {
      if (to) {
        await new this.$HttpModel('АктыОбхода.Пломбы').executeMethod('ЗаполнитьТаблицуПломб', { номерЗаписи: this.record.$номерЗаписи, информация: this.record.информация });
      }
    },
    async isAddParams(to) {
      if (to) {
        await new this.$HttpModel('АктыОбхода.Пломбы').executeMethod('ЗаполнитьТаблицуПараметров', { номерЗаписи: this.record.$номерЗаписи, информация: this.record.информация });
      }
    },
  },
});
