
import Vue from 'vue';

export default Vue.extend({
  name: 'DebtInfo',
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    getImagePack(): ImagePack {
      return this.$store.getters.getImagePack();
    },
  },
  data() {
    return {
      http: new this.$HttpModel('ЛицевыеСчета.ИнформацияОЗадолженности'),
      items: [] as StackTableRow[],
    };
  },
  methods: {
    getImg(item: StackTableRow) {
      if (item && item.тип) {
        return this.getImagePack['info_' + this.getNameIcon(+item.тип) + (item.признак ? '_open' : '_closed')]?.default.id;
      }
      return '';
    },
    getNameIcon(type: number) {
      switch (type) {
        case 1:
          return 'pretension';
        case 2:
          return 'lawsuit';
        case 3:
          return 'restrictions';
        case 4:
          return 'agreement';
      }
      return '';
    },
    onObjClick(item: StackTableRow) {
      this.$router.replace(item.тип === 4 ? `/accounts/${this.record.$номерЗаписи}#debt` : `/accounts/${this.record.$номерЗаписи}?tab=lawsuit#debt`);
    },
    async fetchData() {
      if (this.record && this.record.$номерЗаписи > 0) {
        this.items = await this.http.getRecords({ владелец: this.record.$номерЗаписи });
      }
    },
  },
  watch: {
    record: {
      immediate: true,
      async handler(to, old) {
        if (to?.$номерЗаписи > 0 && to.$номерЗаписи !== old?.$номерЗаписи) {
          this.fetchData();
        }
      },
    },
  },
});
