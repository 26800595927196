const accs: StackSelection = {
  objectName: 'ЗаявкиЛицевого',
  description: 'Заявки по ЛС',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'получениявремя',
      text: 'ПолученияВремя',
      type: 'DateTime',
    },
    {
      field: 'завершить до',
      text: 'Завершить до',
      type: 'DateTime',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'заявкасосттекст',
      text: 'Состояние заявки',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Содержание заявки',
      type: 'String',
    },
    {
      field: 'примечдиспетчер',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'исполнитель',
      text: 'Исполнитель',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'заявка-источник',
      text: 'Заявка-Источник',
      type: 'String',
    },
    {
      field: 'заявка-видзаявки',
      text: 'Заявка-ВидЗаявки',
      type: 'String',
    },
    {
      field: 'заявка-карточка',
      text: 'Заявка-Карточка',
      type: 'String',
    },
    {
      field: 'карточки-маршрут>название',
      text: 'Вид работ',
      type: 'String',
    },
  ],
};
export default accs;
