const residentsdocs: StackSelection = {
  objectName: 'ЧастныеЛица.ВидыДокументов',
  recordRoute: '',
  description: 'Документы',
  fields: [
    {
      field: '@вид',
      text: 'Вид',
      type: 'String',
    },
    {
      field: 'вид',
      text: 'Вид',
      type: 'Number',
    },
    {
      field: 'дата выдачи',
      text: 'Дата выдачи',
      type: 'Date',
    },
    {
      field: 'серия',
      text: 'Серия',
      type: 'String',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'основание',
      text: 'Основание',
      type: 'String',
    },
    {
      field: 'кем выдан',
      text: 'Выдан',
      type: 'String',
    },
    {
      field: 'код подразделения',
      text: 'Код подразделения',
      type: 'String',
    },
    {
      field: 'кому выдан',
      text: 'На ФИО',
      type: 'String',
    },
    {
      field: 'причиназамены',
      text: 'Причина',
      type: 'Number',
    },
    {
      field: '@причиназамены',
      text: 'Причина',
      type: 'String',
    },
  ],
};
export default residentsdocs;
