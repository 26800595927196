
import Vue from 'vue';

export default Vue.extend({
  props: {
    allHeaders: { type: Object, required: true },
    cacheId: { type: String, default: undefined },
  },
  computed: {
    getSelectedIds(): string[] {
      const res = [];
      for (const head in this.allHeaders) {
        if (this.allHeaders[head].visible) {
          res.push(head);
        }
      }
      return res;
    },
  },
  methods: {
    onClearState() {
      this.$store.commit('SAVE_CACHE_DATA', { id: `${this.cacheId}_headers_order`, data: undefined });
      this.$emit('reset-headers');
    },
    onSaveState() {
      if (this.cacheId) {
        this.$store.commit('SAVE_CACHE_DATA', { id: `${this.cacheId}_headers_order`, data: this.getSelectedIds });
        this.$emit('close');
      }
    },
  },
});
