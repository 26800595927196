
import Vue from 'vue';
import netTreeToolbar from './NetTreeToolbar.vue';

export default Vue.extend({
  name: 'NetTree',
  components: { netTreeToolbar },
  props: {
    /**
     * тип сети для фильтра
     */
    type: { type: Number, required: true },
    /**
     * разрешен ли мультивыбор чекбоксами
     */
    selectable: { type: Boolean, default: false },
    /**
     * разрешен ли мультивыбор чекбоксами
     */
    single: { type: Boolean, default: false },
    /**
     * никаких действий с элементами
     */
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      dataModel: new this.$HttpModel('Сеть_РеестрЭлементов'),
      selected: {} as StackTableRow, // активная запись
      selectedItems: [] as StackTableRow[], // отмеченные записи
      settingMode: false, // режим настройки
      searchResultMap: {} as Map<number, string>, // map, содержащая результаты поиска. Ключ - идентификатор узла. Значение - путь о ткорня(включая корень) до указанного узла(на момент написания включая значение узла в конце)
      selectedNodeFromSearch: 0 as number, // значение ключа выделенного узла поиска
      findId: undefined as string[] | undefined, // ид записи при поиске, на который переводится фокус в stack-tree
      searchInProgress: false, // признак того, что идет загрузка данных из поиска
    };
  },
  computed: {
    cacheKeyPrefix(): any {
      const net_type_value = this.$store.getters['communStore/getNetType'](this.type);
      return net_type_value?.path;
    },
    icon(): string {
      return '$vuetify.icons.disc';
    },
    getImagePack(): ImagePack {
      return this.$store.getters.getImagePack();
    },
    netType(): any {
      return this.$store.getters['communStore/getNetType'](this.type);
    },
    enableSearchStep(): boolean {
      return this.searchResultMap?.size > 1;
    },
  },
  created() {
    // переход по результату поиска по клавише CTRL+(up/down)
    window.addEventListener('keydown', this.onKeyPress);
  },
  methods: {
    treeReload() {
      // @ts-ignore
      this.selectedNodeFromSearch = 0;
      this.searchResultMap = new Map();
      // @ts-ignore
      this.$refs.stacktree.fetchData();
    },
    treeReloadChildren(ident: number) {
      // @ts-ignore
      this.$refs.stacktree.reloadChildren(ident);
    },
    getImg(item: StackTableRow) {
      if (item && item.$data && item.$data.название && item.$data.название.$иконка) {
        return this.getImagePack[item.$data.название.$иконка]?.default.id;
      }

      return undefined;
    },
    onActiveItem(payload: StackTableRow[]) {
      if (payload && payload[0]) {
        this.selected = payload[0];
        this.$emit('update:active', this.selected);
      }
    },
    onSelectedItems(payload: StackTableRow[]) {
      this.selectedItems = [];
      if (payload && payload[0]) {
        this.selectedItems = payload;
      }
      this.$emit('select', this.selectedItems);
    },
    // методы для поиска
    async onFilter(payload: any) {
      if (payload.search && payload.search !== '') {
        this.searchInProgress = true;
        const res_api = await this.dataModel.executeMethod('ПоискВДереве', { строкаПоиска: payload.search, тип: this.type, режимПоиска: payload.searchType });
        if (res_api) {
          const res_api_arr: any[] = Array.from(res_api) as any[];
          this.searchResultMap = new Map();
          for (const res_val of res_api_arr) {
            this.searchResultMap.set(+res_val.узел as number, res_val.узелПутьИд as string);
          }

          if (res_api_arr && res_api_arr.length > 0) {
            const node_id_arr = res_api_arr[0].узелПутьИд.split(',');
            this.findId = node_id_arr;
            this.selectedNodeFromSearch = res_api_arr[0].узел as number;
          } else {
            this.$toast('Поиск не дал результатов!', { color: 'info' });
          }
        }
        this.searchInProgress = false;
      }
    },
    movePrevSearchresult() {
      if (!this.searchResultMap || this.searchResultMap.size < 2) {
        return;
      }
      const search_key_arr = Array.from(this.searchResultMap.keys());
      const selected_index = search_key_arr.indexOf(this.selectedNodeFromSearch);
      if (selected_index !== 0) {
        this.selectedNodeFromSearch = search_key_arr[selected_index - 1];
      } else {
        this.$toast('Достигнуто начало поиска.', { color: 'info' });
        return;
      }
      const node_chain_string = this.searchResultMap.get(this.selectedNodeFromSearch);
      if (node_chain_string) {
        const node_id_arr = node_chain_string.split(',');
        this.findId = node_id_arr;
      }
    },
    moveNextSearchresult() {
      if (!this.searchResultMap || this.searchResultMap.size < 2) {
        return;
      }
      const search_key_arr = Array.from(this.searchResultMap.keys());
      const selected_index = search_key_arr.indexOf(this.selectedNodeFromSearch);
      if (selected_index !== search_key_arr.length - 1) {
        this.selectedNodeFromSearch = search_key_arr[selected_index + 1];
      } else {
        this.$toast('Достигнут конец поиска.', { color: 'info' });
        return;
      }
      const node_chain_string = this.searchResultMap.get(this.selectedNodeFromSearch);
      if (node_chain_string) {
        const node_id_arr = node_chain_string.split(',');
        this.findId = node_id_arr;
      }
    },
    onKeyPress(event: KeyboardEvent) {
      if (event.ctrlKey && event.shiftKey && (event.code === 'ArrowRight' || event.code === 'ArrowLeft')) {
        if (!this.searchResultMap || this.searchResultMap.size < 2) {
          return;
        }
        if (event.code === 'ArrowLeft') {
          // переход к следующему узлу в результатах поиска
          this.movePrevSearchresult();
        } else if (event.code === 'ArrowRight') {
          // переход к предыдущему узлу в результатах поиска
          this.moveNextSearchresult();
        }
      }
    },
  },
});
