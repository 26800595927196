
import Vue from 'vue';

export default Vue.extend({
  props: {
    dataModel: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      password: null,
      rulesThis: {
        minPasswordLength: (value: string|null): string | true => {
          const len = 6;
          return !value || value.toString().length >= len || `Поле не может быть меньше ${len} символов`;
        },
      },
    };
  },
  methods: {
    async generatePassword() {
      this.loading = true;
      try {
        const res = await this.dataModel.executeMethod('СгенерироватьПароль');
        this.password = res;
      } catch (error: AnyException) {
        this.$toast(error.message);
      }
      this.loading = false;
    },
  },
});
