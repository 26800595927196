
import Vue from 'vue';
import { TableHandlers } from '@/stackEngine';

export default Vue.extend({
  name: 'PeopleTab',
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    openMonth(): Date {
      return this.$store.getters.getOpenMonth();
    },
    showHistoryByDefault(): boolean {
      return this.$store.getters['flStore/getShowHistory']();
    },
  },
  methods: {
    vremHandler(record: any) {
      switch (record.тип) {
        case 0:
          record.type = 'Временное отсутствие';
          record.$data.фио.$иконка = '$vuetify.icons.persons_minus';
          break;
        case 1:
          record.type = 'Временная регистрация';
          record.$data.фио.$иконка = '$vuetify.icons.persons_plus';
          break;
      }
      if (record.даткнц < this.openMonth) {
        TableHandlers.trColor(record, 'grey');
      }
      return record;
    },
    lgotColorHandler(item: any) {
      if (item.даткнц < this.openMonth) {
        TableHandlers.trColor(item, 'grey');
      }
      return item;
    },
  },
});
