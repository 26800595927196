
import Vue from 'vue';
import ChooseNet from '@/tasks/ul/views/accounts/$accountsid/$components/ChooseNet.vue';

export default Vue.extend({
  components: { ChooseNet },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      netChooseVisible: false,
    };
  },
  methods: {
    onUpdateNet() {
      this.netChooseVisible = false;
      this.$emit('updateDialog');
    },
  },
});
