
import Vue from 'vue';
export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  methods: {
    getIcon(state: string) {
      return state.toUpperCase() === 'UP' ? '$check' : '$alert';
    },
    getIconColor(state: string) {
      return state.toUpperCase() === 'UP' ? 'primary' : 'error';
    },
  },
});
