
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: { type: Object, default: null },
  },
  data() {
    return {
      item: {
        лицевые: -10,
        типввода: 0,
      },
      loading: false,
    };
  },
  methods: {
    async addFund() {
      this.loading = true;
      const master = new this.$HttpModel('ВедомостиСтороннихПоказаний');
      const reportID = await master.executeMethod('ДобавитьФонд', {
        номерЗаписи: this.params.номерЗаписи,
        лицевые: this.item.лицевые,
        типввода: this.item.типввода,
        расчмесяц: this.params.расчмесяц,
      });
      this.loading = false;
      this.$emit('save');
    },
  },
});
