
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      selectedItems: [] as StackTableRow[], // выделенные записи в таблице
      queryParams: {},
      dataModel: new this.$HttpModel('Улицы.ЗагрузкаГеографии'),
      loading: false,
    };
  },
  computed: {
    error(): boolean {
      return this.selectedItems.length === 0;
    },
  },
  methods: {
    async downloadFias() {
      await this.dataModel.executeMethod('Загрузить', { записи: this.selectedItems }, { async: true, jobName: 'Загрузка данных ФИАС' });
      this.$emit('close');
    },
  },
  watch: {
    queryParams: {
      deep: true,
      handler(to) {
        this.loading = true;
        this.dataModel.executeMethod('ЗаполнитьПотомков', to, {
          jobName: 'Заполнение потомков',
          silent: true,
          external: true,
          async: true,
          onComplete: () => {
            this.loading = false;
            this.$sendMsg('stack-table', 'Улицы.ЗагрузкаГеографии', 'reload');
          },
          onError: () => {
            this.loading = false;
          },
        });
      },
    },
  },
});
