
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    ownerID: { type: Number, default: -1 },
  },
  computed: {
    getCalcByPassport(): boolean {
      return this.$store.getters['flStore/getCalcByPassport']();
    },
  },
});
