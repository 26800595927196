
import Vue from 'vue';

export default Vue.extend({
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    titleImg(): string {
      const el = document.createElement('html');
      el.innerHTML = this.item['content:encoded'];
      const imgs = el.getElementsByTagName('img');
      return imgs[0]?.src;
    },
  },
});
