
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        ДатаДо: this.$stackDate.lastDay(this.$store.getters.getWorkMonth()) as string,
        Лицо0: '',
        КудаПредоставить: ' бухгалтерию по расчету квартплаты ',
        Документы: ' 1. документ на льготы на ' + this.$stackDate.getYear(this.$store.getters.getWorkMonth()) + ' г. ',
      },
    };
  },
});
