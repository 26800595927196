const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.КоррекцияСальдо',
  description: 'Коррекция сальдо',
  fields: [
    {
      field: 'коррекция>номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчмесяц',
      text: 'Месяц',
      type: 'Date',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'коррекция>название',
      text: 'Примечание',
      type: 'String',
    },
  ],
};
export default accs;
