import { render, staticRenderFns } from "./ПОДКАЧПРОФ.vue?vue&type=template&id=f66ca3e6&"
import script from "./ПОДКАЧПРОФ.vue?vue&type=script&lang=ts&"
export * from "./ПОДКАЧПРОФ.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports