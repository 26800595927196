
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      outAuth: true,
    };
  },
  computed: {
    isContactcenter(): boolean {
      return this.$store.getters.getCurrentTask() === 'contactcenter';
    },
  },
  methods: {
    positiveNum(num: number): string | true {
      return num < 0 ? 'Значение не божет быть меньше 0' : true;
    },
  },
});
