
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    typeContract: { type: String, default: 'managements' },
    title: { type: String, default: 'Объект' },
    noService: { type: Boolean, default: false },
  },
  computed: {
    idAccount() {
      if (this.typeContract === 'managements') {
        return 'счет-ук';
      } else {
        return 'счет-список поставщиков';
      }
    },
    idService() {
      if (this.typeContract === 'managements') {
        return 'услуга-укдоговор';
      } else {
        return 'услуги-список поставщиков';
      }
    },
  },
  data() {
    return {
      error: false,
      rulesThis: {
        typeIsCorrect: (error: boolean): string | true => {
          if (error) {
            return 'Выберите дом!';
          }
          return true;
        },
      },
    };
  },
  methods: {
    onSelectFund(payload: any) {
      if (payload && payload[0] && payload[0].тип !== 3) {
        this.error = true;
      } else {
        this.error = false;
      }
    },
  },
});
