import { StaticModel, HttpModel } from '@/stackEngine';
export class ReCalcModel extends StaticModel {
  private httpModel: DataModel = new HttpModel('ЛицевыеСчета.Перерасчеты');

  public async initRecord(): Promise<StackTableRow> {
    let newRec = {} as StackTableRow;
    if (this.httpModel.initRecord) {
      const data = await this.httpModel.initRecord();
      if (data) {
        newRec = Object.assign({}, data);
      }
    }
    return newRec;
  }

  public async getRecords(params?: object) {
    this.data = await this.httpModel.getRecords(params);
    this.sort(params);
    return this.data;
  }

  public async executeMethod(methodName: string, params?: object, options?: MetodOptions): Promise<any> {
    if (this.httpModel.executeMethod) {
      const data = await this.httpModel.executeMethod(methodName, params, options);
      return data;
    }
    return '';
  }

  get length(): number {
    return this.httpModel.length;
  }
}
