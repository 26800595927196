
import Vue from 'vue';

export default Vue.extend({
  props: {
    disabled: { type: Boolean, required: true },
    accounts: { type: String, default: null },
    month: { type: String, default: null },
  },
  computed: {
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
    isDisabled(): boolean {
      return !this.Фонд || this.jobID !== null || this.disabled;
    },
  },
  data() {
    return {
      Фонд: '',
      jobID: null as null | string,
      месяц: this.month || this.$store.getters.getWorkMonth(),
      dataModel: new this.$HttpModel('МассоваяПечатьКвитанций'),
    };
  },
  async created() {
    this.Фонд = this.accounts ? this.accounts : '';
  },
  methods: {
    async sendKvit() {
      this.jobID = (
        await this.dataModel.executeMethod(
          'Отправить', { method: 'отправка', Месяц: this.месяц, Фонд: this.Фонд }, {
            async: true,
            jobName: 'Отправка квитанций получателям',
            type: 'func',
          },
        )
      ).asyncId;
    },
    async printKvit(params?: StackHttpRequestTaskParam) {
      const parameters = Object.assign({}, { method: 'email', Месяц: this.месяц, Фонд: this.Фонд }, params);
      this.jobID = (
        await this.dataModel.executeMethod('Отправить', parameters, {
          async: true,
          jobName: 'Формирование квитанций по email',
          type: 'multi',
        })
      ).asyncId;
      if (!params) {
        this.$emit('run');
      }
    },
    async getQuestion(question: StackHttpTaskQuestions) {
      const breakPrint = +question.ПОТМЕНА.вариантПрервать;
      const answer = await Vue.prototype.$stackMsg(question);
      if (answer) {
        this.$store.commit('MSG_DELETE', this.jobID);
        if (answer.ПОТМЕНА === breakPrint) {
          this.jobID = null;
          this.$emit('end', 'error');
        } else {
          this.$emit('end');
          this.printKvit(answer);
        }
      }
    },
  },
  watch: {
    accounts(to) {
      this.Фонд = to;
    },
    isComplete(to) {
      if (to) {
        if (this.resultJob.data && this.resultJob.data.questions) {
          this.getQuestion(this.resultJob.data.questions);
        } else {
          this.jobID = null;
          this.$emit('end', 'success');
        }
      }
    },
    isError(to) {
      if (to) {
        this.jobID = null;
        this.$emit('end', 'error');
      }
    },
    month(to) {
      this.месяц = to;
    },
  },
});
