
import Vue from 'vue';

// TODO Не копипастить !
export default Vue.extend({
  props: {
    record: { type: Object, required: true },
    filter: { type: Object, default: undefined },
    value: { type: Boolean, required: true },
    dataModel: { type: [String, Object], required: true },
    noFolder: { type: Boolean, required: true },
    noPagination: { type: Boolean, required: true },
    noSearch: { type: Boolean, default: false },
    items: { type: [String, Number], default: undefined },
    ownerID: { type: [String, Number], default: undefined },
    headers: { type: String, required: true },
  },
  data() {
    return {
      selected: [] as StackTableRow[],
    };
  },

  methods: {
    // событие на чекбокс
    onSelect(selectedRows: StackTableRow[]) {
      this.selected = selectedRows;
      if (this.selected && this.selected.length > 0) {
        this.$emit('select', this.selected);
        // @ts-ignore
        this.$refs.dialog.onSave();
      }
    },
    // Закрываем диалог
    onClose() {
      this.$emit('input', false);
    },
    // Выбор
    onSave() {
      this.$emit('save', this.selected);
    },
  },
});
