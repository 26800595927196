const vzfile: StackSelection = {
  objectName: 'АктыОбходаФайлы',
  description: 'Внешние документы',
  fields: [
    {
      field: 'имя файла',
      text: 'Имя файла',
      type: 'String',
      maxwidth: '200px',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
      width: '100px',
    },
    {
      field: 'комментарий',
      text: 'Комментарий',
      type: 'String',
    },
    {
      field: 'расширение',
      text: 'Расширение',
      type: 'String',
      width: '100px',
    },
    {
      field: 'типы документов-файл',
      text: 'типы документов-файл',
      type: 'Link',
    },
    {
      field: 'датнач',
      text: 'Период действия с',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Период действия по',
      type: 'Date',
    },
  ],
};
export default vzfile;
