const constant: StackSelection = {
  recordRoute: 'references-constants-constantsid',
  objectName: 'Константы',
  description: 'Константы',
  hier: true,
  fields: [
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
      maxwidth: '250px',
    },
    {
      field: 'имя',
      text: 'Имя',
      type: 'String',
    },
    {
      field: 'полное имя',
      text: 'Полное имя',
      type: 'String',
      minwidth: '70%',
    },
  ],
};

export default constant;
