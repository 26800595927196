
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    comboModel(): StackTableRow[] {
      return this.record.поля.split('\n');
    },
  },
  data() {
    return {
      comboValue: 0,
      koefValue: 0,
    };
  },
  methods: {
    changeRecord(newValue: number | string) {
      const dataObject = this.record && this.record.значение ? this.record.значение.split(';') : [];
      if (dataObject[this.comboValue] !== undefined) {
        dataObject[this.comboValue] = newValue;
        this.$set(this.record, 'значение', dataObject.join(';'));
      }
    },
    getData(item: StackTableRow) {
      // наберем данные, в зависимости от значения combobox
      const dataObject = this.record && this.record.значение ? this.record.значение.split(';') : [];
      this.koefValue = item.$номерЗаписи !== undefined && dataObject[item.$номерЗаписи] ? dataObject[item.$номерЗаписи] : 0;
    },
  },
});
