// по алфавиту
export default {
  enums: [
    'ЛицевыеСчета.ВременноеДвижение.Причины',
    'ЛицевыеСчета.ВременноеДвижение.Тип',
    'ЛицевыеСчета.ВременноеДвижение.Флаги',
    'ЛицевыеСчета.История.Состояние',
    'ЛицевыеСчета.Льготники.Флаги',
    'ЛицевыеСчета.РучныеПерерасчеты.Тип',
    'ЛицевыеСчета.СоответствиеЛицевых.Тип',
    'ЛицевыеСчета.ТипЗдания',
    'ЛицевыеСчета.Услуги.Состояние',
    'ЛицевыеСчета.ПолучитьАдресДоставки',
    'ЛицевыеСчета.Аккаунты.Состояние',
  ],
};
