const actsqu: StackSelection = {
  recordRoute: '',
  folderRoute: 'fonechargestatements',
  objectName: 'ВедомостиРазовыхНачислений.ПричиныПерерасчета',
  description: 'Причины перерасчета',
  fields: [
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
  ],
};
export default actsqu;
