
import Vue from 'vue';
// @ts-ignore
import MainMenuItem from './MainMenuItem.vue';
import ovscrollable from '../../mixins/ovscrollable';

export default Vue.extend({
  mixins: [ovscrollable],
  components: { MainMenuItem },
  props: {
    drawer: { type: Boolean, required: true },
  },
  data() {
    return {
      evListener: undefined as any,
      selected: undefined as string | undefined,
    };
  },
  computed: {
    getCurrentTaskHTMLTitle(): string {
      return this.$store.getters.getCurrentTaskHTMLTitle();
    },
    getNavBarWidth(): number {
      return this.$store.getters.getNavBarWidth();
    },
    isHiddenMenuEnabled(): number {
      return this.$store.state.configStore.isHiddenMenuEnabled;
    },

    items(): TaskItem[] {
      const menuItems = [{ icon: '$home_outline', menu: 'Главная', path: '/' }, ...this.$store.getters.getMainMenuItems()];
      this.isHiddenMenuEnabled && menuItems.push({ icon: '$srv_operations', menu: 'Сервисные операции', path: '/admsrv' });
      return menuItems;
    },
  },
  mounted() {
    // Комбинация alt+[1-9] переключает пункты меню
    this.evListener = (e: KeyboardEvent) => {
      const key = this.mainMenuListener(e);
      if (key !== undefined && this.items[key] && this.items[key].path) {
        this.$router.push(this.items[key].path as string);
        this.onClick(this.items[key]);
      }
    };
    document.addEventListener('keydown', this.evListener);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.evListener);
  },

  methods: {
    mainMenuListener(event: KeyboardEvent): number | undefined {
      // ALT + 1-9
      if (event.altKey && event.keyCode >= 49 && event.keyCode <= 57) {
        event.stopPropagation();
        return +event.key - 1;
      }
    },

    onClick(item: TaskItem) {
      this.selected = item.menu;
      if (item.path) {
        if (item.path !== '/') {
          this.$store.commit('ADD_BREADCRUMB_ITEM', { to: `${item.path}`, title: item.menu, maxlvl: 1 });
          this.$store.commit('SET_BROWSER_TITLE', item.menu);
        } else {
          this.$store.commit('SHRINK_BREADCRUMB_ITEMS', '/');
        }
      }
    },
  },
});
