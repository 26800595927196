
import Vue from 'vue';

/**
 * Управляющий компонент, отвечающий за передачу данных из фильтров таблице
 */
export default Vue.extend({
  name: 'FilterController',
  props: {
    /**
     * Объект с полями фильтра
     */
    value: { type: Object, required: true },
    /**
     * Текущий объект с полями фильтра из контекста таблицы
     */
    current: { type: Object, required: true },
    /**
     * Обработчик фильтра нестандартный
     */
    externalAction: { type: Boolean, default: false },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    /**
     * фильтр по умолчанию
     */
    defaultFilter: {
      type: Object,
      default: (): object => {
        return {};
      },
    },
    // Открыт или закрыт
    isOpen: { type: Boolean, required: true },
    // Активен или нет
    isEnable: { type: Boolean, required: false },
    /**
     * Статус занятости таблицы
     */
    loading: { type: Boolean, default: false },
    hier: { type: Boolean, default: false },
    // Закрытие фильтра по умолчанию
    noClose: { type: Boolean, default: false },
    // Закрытие фильтра по умолчанию
    notExpand: { type: Boolean, default: false },
    // не разворачивать иерархию, актуально для иерархических выборок
    notExpandHier: { type: Boolean, default: false },
  },
  // Игнорируем только чтение с диалогов.
  provide(): any {
    return {
      // @ts-ignore
      isDialogReadonly: () => false,
    };
  },
  data() {
    return {
      lookAll: !this.notExpand,
      valid: false,
    };
  },
  computed: {
    isDisable() {
      return Object.values(this.value).filter((el) => el || el === 0).length === 0;
    },
    expandHier() {
      return this.hier && !this.notExpandHier;
    },
  },
  methods: {
    // TODO заполняем параметры в контекст из таблицы
    fillParams() {
      for (const param in this.current) {
        this.$set(this.value, param, this.current[param]);
      }
    },
    timeout(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async onFilter() {
      await this.timeout(200);
      // @ts-ignore
      if (!this.$refs.fcform.validate()) {
        return;
      }
      // TODO Очищаем хлебные крошки, т.к. "путь" не соотв. переходу.
      // Нужно будет генерить крошки от текущей записи иерарх. выборок.
      this.$store.commit('CLEAR_BREADCRUMB_ITEM', 2);
      if (this.externalAction) {
        this.$emit('filter', { ...this.value, сКорня: this.lookAll, развернутьПапки: this.expandHier });
      } else {
        this.tableAction({ action: 'filter', payload: { ...this.value, сКорня: this.lookAll, развернутьПапки: this.expandHier } });
      }
      if (!this.noClose) {
        this.tableAction({ action: 'close_filter' });
      }
    },

    onClear() {
      // TODO убрать присваивание пропсов.
      // TODO - тут externalAction не особо в тему....
      if (this.externalAction) {
        this.$emit('filter', {});
      } else {
        this.tableAction({ action: 'clear_filter' });
      }
      if (!this.noClose) {
        this.tableAction({ action: 'close_filter' });
      }
    },

    // при активации переводим фокус на первый найденный инпут
    async moveToFirstInput() {
      const el = this.$el.querySelectorAll('input,textarea,select') as any;
      if (el && el[0]) {
        el[0].focus();
      }
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      async handler(to: boolean) {
        if (to) {
          this.fillParams();
          await this.timeout(200);
          this.moveToFirstInput();
        }
      },
    },
  },
});
