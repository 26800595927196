
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: 'значение',
          text: 'Название',
          type: 'Link',
          dataModel: 'ВидыЗадолженности',
          headers: 'название',
          labelField: (record: any) => {
            return '(' + record.код + ') ' + record.название;
          },
          hier: true,
        },
      ],
    };
  },
});
