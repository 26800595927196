
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  created() {
    const data = this.record.значение.split(';');
    this.сумма = data[0];
    this.дни = data[1];
    this.процент = data[2];
    this.минсумма = data[3];
  },
  data() {
    return {
      сумма: 0,
      дни: 0,
      процент: 0,
      минсумма: 0,
    };
  },
  methods: {
    changeValue(value: number, index: number) {
      const data = this.record.значение.split(';');
      data[index] = value;
      this.$set(this.record, 'значение', data.join(';'));
    },
  },
});
