//  import 'babel-polyfill'; // для IE11
import Router from 'vue-router';
import { mountApp } from './ui';
import { stackAppEmitter, getGlobalConfig, getCustomConfig } from './utils';
import { store } from './store'; // Инициализация хранилища
import { getRouter, getRouteViews } from './router'; // Инициализация роутера
import { getTaskConfig } from './utils/helpers/fetchConfig';
import { StateStorage } from './utils/storage'; // localStorage храним извлекаем данные
import { buildTaskRoutes } from './router/buildTaskRoutes';
import { getFingerprint } from './utils/fingerprint';
import credits from './themes/credits_stack';

// При загрузке приложения нужно выполнить определенные подготовительные действия
stackAppEmitter.on('APP_MOUNTED', async (store: any, router: Router) => {
  // Получим "уникальный отпечаток" пользователя
  try {
    const fp = await getFingerprint();
    if (fp) {
      store.commit('SET_USER_FINGERPRINT', fp);
    }
  } catch (e: AnyException) {
    console.log('fp fail', e);
  }
  // Синхронизация с local storage
  store.subscribe(StateStorage.subscriber);
  // Строим роуты
  const views = getRouteViews();
  if (views) {
    buildTaskRoutes(getRouter(), views);
  }

  // Конфиг из .env
  let appConfig: any = getGlobalConfig();
  store.commit('SET_APP_CONFIG', appConfig); // настройки приложения

  // ищем локальный env и поттягиваем настройки
  const baseURL: string = appConfig && appConfig.ROOT_URL ? appConfig.ROOT_URL : '/';
  const customConfig = await getCustomConfig(baseURL);
  if (customConfig) {
    store.commit('SET_APP_CONFIG', customConfig);
    appConfig = { ...appConfig, ...customConfig };
  }
  egg();
  // Интеграция метрики Sentry
  store.commit('SENTRY_INIT');

  // Читаем конфигурацию задачи
  const taskConfig: Task = getTaskConfig();

  // Загружаем информацию из local storage
  await StateStorage.loadState(taskConfig.prefix);
  store.commit('SET_TASK_CONFIG', taskConfig); // настроки задачи

  // На аутентификацию пользователя загружаем права доступа
  if (!taskConfig.externalTask) {
    stackAppEmitter.on('APP_USER_SIGN_IN', async (store) => {
      await store.dispatch('getUserRights');
      await StateStorage.loadStateAfterAuth();
    }, 'Определяем права доступа...');
  }

  // Если задача не внешняя (имеется ввиду без стековой инфраструтуры типа настройки диспетчера)
  if (!taskConfig.externalTask) {
    // с нашего бекэнда получим данные об версии инсталлятора, чтобы вывести плашку о необходимости обновления
    store.dispatch('wsStore/checkPluginInfo');
    // Зарегистируем опросник асинхронных заданий
    store.dispatch('runAsyncJobs');
    // TODO Для Кассы нужно дождаться инициализации стек ассистента.
    if (taskConfig.prefix === 'kassa') {
      await store.dispatch('wsStore/connect');
    } else {
      store.dispatch('wsStore/connect');
    }
  }

  // аутентификация из URL или из localStorage
  const query = router.resolve(window.location.search)?.route?.query;
  const token = query?.token || store.getters.getToken();
  const login = query?.login;
  const password = query?.password;
  if (token || login) {
    await store.dispatch('SIGN_IN', { token, login, password });
  }
}, 'Загрузка приложения...');

function egg() {
  const style = [
    'line-height: 30px',
    'font-size: 1rem',
    'width:100%',
    'font-weight: 200',
    'color: white',
    'display: block',
    'text-align: center',
    'border: 1px solid #113A51',
    'font-family: "Roboto", sans-serif !important;',
    'background: linear-gradient(0.25turn, #214A81, #113A51, #214A81)',
  ];
  const style1 = [
    'font-size: 0.85rem',
    'font-weight: 200',
    'font-family: "Roboto", sans-serif !important;',
  ];
  console.log(` `);
  console.log(`%c               Добро пожаловать в ${credits.fullName}               `, style.join(';'));
  console.log(`%c site: ${credits.siteUrl}`, style1.join(';'));
  console.log(`%c email: ${credits.email}`, style1.join(';'));
  console.log(` `);
}

// инициализируем начальные данные приложения
export async function runStackApp(App: any) {
  // Начало загрузки приложения
  await stackAppEmitter.emitAsync('APP_STARTED');

  const router = getRouter();
  // монтируем приложение
  mountApp(router, store, App);
  await stackAppEmitter.emitAsync('APP_MOUNTED', store, router);

  // выставляем флаг, что начальная инициализация закончена
  await stackAppEmitter.emitAsync('APP_READY');
  store.commit('SET_APP_READY', true);
}