
import Vue from 'vue';
import { rules } from '@/stackEngine';
import dialogAccountScheme from './dialogAccountScheme.vue';

export default Vue.extend({
  components: { dialogAccountScheme },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    params: { type: Object, required: true },
    tableModel: { type: Object, default: null },
  },
  computed: {
    isService(): boolean {
      return this.tableModel.model === 'ДоговорыУК.УслугиДоговора.СхемыРаспределения';
    },
  },
  data() {
    return {
      rules,
    };
  },
});
