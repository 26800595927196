
import Vue from 'vue';

import { getComponents, stringTranslit } from '@/stackEngine';

const componentsDefs = require.context('./$dialogs', false, /\.vue$/);
const constDlg = getComponents(componentsDefs);

export default Vue.extend({
  components: Object.assign(constDlg),
  data() {
    return {
      isDialog: false,
      itemsMenu: [] as StackTableRow[],
      itemsList: [] as StackTableRow[],
      itemsListData: [] as StackTableRow[],
      isLoading: false,
      isReadonly: false,
    };
  },
  props: {
    testId: { type: String, default: '' },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    getDialogName(row: StackTableRow): string {
      if (row && row.имя) {
        const name = stringTranslit(row.имя as string).toUpperCase();
        if (this.$options.components && name in this.$options.components) {
          return name as string;
        }
        return 'DEFAULT';
      }
      return '';
    },
    openList(item: StackTableRow) {
      this.itemsList = [];
      this.isDialog = true;
      if (this.itemsListData.length > 0) {
        for (const objItem of this.itemsListData) {
          if (objItem.константы === item.$номерЗаписи) {
            this.itemsList.push(objItem);
          }
        }
      }
    },
    async fetchData() {
      this.isLoading = true;
      const dataModel = new this.$HttpModel('КонстантыНастроек');
      const data = await dataModel.getRecords();
      this.isReadonly = !dataModel.rights?.редактирование;
      this.itemsMenu = [];
      if (data.length > 0) {
        for (const i in data) {
          const tempDataObject = data[i] !== undefined && data[i] !== null ? data[i] : {};
          const tempDataNumber = tempDataObject.константы !== undefined && tempDataObject.константы !== null ? tempDataObject.константы : 0;
          if (tempDataNumber > 0) {
            data[i].model = 'КонстантыНастроек';
            // для комбобоксов значение "1" (строка) и 1 (число) - это разные вещи, поэтому нужно преобразовать
            if (data[i].тип === 'число' && Number(data[i].значение) >= 0) {
              data[i].значение = Number(data[i].значение);
            }
            this.itemsListData.push(data[i]);
          } else {
            data[i].title = data[i]['полное имя'];
            this.itemsMenu.push(data[i]);
          }
        }
      }
      this.isLoading = false;
    },
  },
});
