
import Vue from 'vue';

export default Vue.extend({
  props: {
    record: { type: Array, required: true },
    showTu: { type: Boolean, default: false },
  },
  data() {
    return {
      dataObject: null as DataModel | null,
      headers: [
        {
          field: 'адрес',
          text: this.showTu ? 'ТУ' : 'Адрес',
          type: 'String',
        },
        {
          field: 'счетчик',
          text: 'Счетчик',
          type: 'String',
        },
        {
          field: 'ошибка',
          text: 'Ошибка',
          type: 'String',
        },
      ] as StackField[],
    };
  },

  async created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (this.record) {
        this.dataObject = new this.$StaticModel(this.record as StackTableRow[], { rowTemplate: this.headers, description: '' });
      }
    },
  },
});
