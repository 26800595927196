
import Vue from 'vue';
import { ReportsBuilder } from '@/stackEngine';

export default Vue.extend({
  methods: {
    onPrint(selectedItems: any) {
      selectedItems.forEach((item: any) => {
        new ReportsBuilder('Законы о льготах').executeReport('Законы о льготах', { номерЛьготы: item['номер льготы'] });
      });
    },
  },
});
