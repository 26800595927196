
import Vue from 'vue';
import GroupReadingDialog from './$groupReadingDialog/GroupReadingDialog.vue';
import ReadingInputDialog from '@/tasks/fl/views/$common/$readingdialog/ReadingInputDialog.vue';
export default Vue.extend({
  components: { GroupReadingDialog, ReadingInputDialog },
  props: {
    ownerID: { type: [Number, String], default: null },
  },
  methods: {
    selectDialog(item: StackTableRow) {
      const idItem = item && item.$номерЗаписи ? item.$номерЗаписи.toString() : '';
      return idItem.indexOf('new') < 0;
    },
  },
});
