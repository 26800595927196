const actsqu: StackSelection = {
  objectName: 'АктыСнятияКачества.Лицевые',
  description: '',
  fields: [
    {
      field: 'датнач',
      text: 'Период С',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Период По',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер лицевого',
      type: 'Number',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'допчасы',
      text: 'ДопЧасы',
      type: 'Number',
    },
    {
      field: 'дни',
      text: 'Дни',
      type: 'Number',
    },
    {
      field: 'счет-качество',
      text: 'Счет-Качество',
      type: 'Link',
    },
    {
      field: 'часнач',
      text: 'часнач',
      type: 'Date',
    },
    {
      field: 'часкнц',
      text: 'часкнц',
      type: 'Date',
    },
    {
      field: 'днчасы',
      text: 'днчасы',
      type: 'Number',
    },
    {
      field: 'ночнчасы',
      text: 'ночнчасы',
      type: 'Number',
    },
    {
      field: '@подъездс',
      text: 'ПодъездС',
      type: 'Number',
    },
    {
      field: '@подъездпо',
      text: 'ПодъездПо',
      type: 'Number',
    },
    {
      field: '@этажс',
      text: 'ЭтажС',
      type: 'Number',
    },
    {
      field: '@этажпо',
      text: 'ЭтажПо',
      type: 'Number',
    },
    {
      field: '@ук',
      text: 'УК',
      type: 'Number',
    },
    {
      field: '@поставщик',
      text: 'Поставщик',
      type: 'Number',
    },
  ],
};
export default actsqu;
