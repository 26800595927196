
import Vue from 'vue';
import { StackSelectionCache } from '@/stackEngine/utils';
import JSON5 from 'json5';

export default Vue.extend({
  data() {
    return {
      selection: '',
      selections: StackSelectionCache.getObjectNames().sort(),
      response: null as any,
      headers: '' as string | StackField[],
      allHeaders: '' as string | StackField[],
      headersSource: 0,
      dataModel: null as HttpModel | null,
      params: '',
      cParams: {} as any,
      isTableReady: false,
      loading: false,
      methodName: 'получить',
      stPar: {
        размерСтраницы: 100,
        номерСтраницы: 1,
        развернутьПапки: false,
      },
    };
  },
  methods: {
    async send() {
      if (this.selection && this.methodName) {
        this.response = null;
        this.dataModel = new this.$HttpModel(this.selection);
        try {
          this.cParams = this.params ? JSON5.parse(this.params) : {};
        } catch {
          this.$toast('Синтаксическая ошибка в параметрах');
          return;
        }
        this.loading = true;
        this.isTableReady = false;
        try {
          if (this.methodName === 'получить') {
            this.headers = Object.keys(this.dataModel.fields).length ? this.dataModel.fields : '';
            const headers = await this.dataModel.executeMethod('получитьДоступныеПоля');
            this.allHeaders = (headers && headers.поля.map((field: string) => { return { field, text: field }; })) || '';
            this.response = await this.dataModel.getRecords({ ...this.stPar, ...this.cParams });
            this.isTableReady = true;
          } else {
            this.response = await this.dataModel.executeMethod(this.methodName, this.cParams);
          }
        } catch (e: AnyException) {
          this.response = e;
        }
        this.loading = false;
      }
    },
  },
  computed: {
    responseJSON(): string {
      return this.response ? typeof this.response === 'object' ? JSON5.stringify(this.response, null, '\t') : this.response : '';
    },
    cHeaders(): string | StackField[] {
      return this.headersSource ? this.allHeaders : this.headers;
    },
  },
});
