
import Vue from 'vue';
import numberPerMonthParam from './$numberPerMonthParam.vue';
export default Vue.extend({
  components: { numberPerMonthParam },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
});
