
import Vue from 'vue';
import comboboxParam from './$comboboxParam.vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  components: { comboboxParam },
});
