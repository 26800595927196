const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.БлокировкаПерерасчетаПени',
  description: 'Периоды блокировки перерасчета пени',
  fields: [
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'группауслуг',
      text: 'Группа услуг',
      type: 'Number',
    },
    {
      field: 'коррекциязаголовок',
      text: 'Ведомость коррекции',
      type: 'String',
    },
    {
      field: 'поставщик',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'договор',
      text: 'Договор',
      type: 'String',
    },
    {
      field: 'названиеуслуг',
      text: 'Название услуг',
      type: 'String',
    },
  ],
};
export default accs;
