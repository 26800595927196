
import Vue from 'vue';
export default Vue.extend({
  props: {
    noShowDate: { type: Boolean, default: false },
    /**
     * отображать колонку с услугой в таблице
     */
    showService: { type: Boolean, default: false },
    /**
     * отображать итоговый расход за месяц в таблице
     */
    showTotal: { type: Boolean, default: false },
    /**
     * отображать колонку с признаком субабонента
     */
    showSub: { type: Boolean, default: false },
    /**
     * отображать колонку с номером ТУ
     */
    showTu: { type: Boolean, default: false },
    /**
     * отображать колонку с состоянием
     */
    showState: { type: Boolean, default: false },
    /**
     * отображать колонку с датой следующей поверки
     */
    showDateCheck: { type: Boolean, default: false },
    /**
     * не отображать колонки с доп.итогами
     */
    noShowDopRas: { type: Boolean, default: false },
    /**
     * не отображать колонку с зоной
     */
    noShowZone: { type: Boolean, default: false },
    showError: { type: Boolean, default: false },

  },
});
