
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    month: { type: String, default: null },
    title: { type: String, default: '' },
  },
  data() {
    return {
      totalRows: 0,
      modelPok: new this.$HttpModel('ЛицевыеСчета.ПоказанияСчетчиковИндивидуальные'),
      isDeleted: false,
    };
  },
  computed: {
    filter(): any {
      return { датНач: this.month, услуга: this.record['акт-услуга'], безкоррекции: true };
    },
  },
  methods: {
    async onSave() {
      try {
        this.isDeleted = true;
        const tableItems = await this.modelPok.getRecords({ владелец: this.record['счет'], фильтр: this.filter });
        await this.modelPok.deleteRecords(tableItems);
      } catch (err: AnyException) {
        this.$stackMsg([
          {
            текст: `Произошла ошибка при удалении ошибок, Попробуйте снова`,
            варианты: ['ОК'],
          },
        ]);
        return;
      } finally {
        this.isDeleted = false;
        this.$emit('save');
      }
    },
  },
});
