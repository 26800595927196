
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    params: { type: Object, required: true },
    dataModel: { type: Object, required: true },
  },
  data() {
    return {
      houses: '',
    };
  },
  methods: {
    async save(payload: any) {
      await this.dataModel.executeMethod('привязатьДома', { дома: this.houses, схема: this.params.$номерЗаписи });
      this.$emit('close');
      this.$sendMsg('stack-table', this.dataModel.model, 'reload');
    },
  },
});
