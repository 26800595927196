
import Vue from 'vue';
import onechargestatementsList from '@/tasks/fl/views/documents/onechargestatements/$modal/onechargestatementsList.vue';

export default Vue.extend({
  name: 'OnechargeTab',
  components: { onechargestatementsList },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
});
