
import Vue from 'vue';
export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: { ...this.value },
    };
  },
});
