
import Vue from 'vue';
import ParamTableDialog from './paramTableDialog.vue';
import { TableHandlers } from '@/stackEngine';

/**
 * Параметры (Таблица Свойства) с подменяемыми диалогами
 */
export default Vue.extend({
  components: { ParamTableDialog },
  props: {
    dataModel: { type: String, required: true },
    ownerID: { type: [Number, String], default: null },
    parentsParam: { type: Boolean, default: false },
    headers: { type: String, default: 'виды-параметры>наименование,строка,датнач,даткнц' },
    linkField: { type: String, default: 'виды-параметры' },
    noOwner: { type: Boolean, default: false },
    history: { type: Boolean, default: true },
    hideDate: { type: Boolean, default: false }, // скрывать даты у компонента
    withoutHier: { type: Boolean, default: false },
    silent: { type: Boolean, default: false }, // При выборе параметра при добавлении
    filter: { type: Object, default: undefined },
  },
  data() {
    return {
      newParamDialogVisible: false,
      currentNewParam: undefined as StackTableRow | undefined,
      hier: !this.withoutHier,
    };
  },
  computed: {
    filterParams(): null | object {
      return !this.hier ? { фильтр: { толькосвои: true } } : null;
    },
  },
  methods: {
    onBtnHierClick() {
      this.hier = !this.hier;
    },
    async onBtnChooseParam(newpar: StackTableRow[]) {
      if (newpar && newpar[0]) {
        this.currentNewParam = newpar[0];
        if (this.silent) {
          const dataObject = new this.$HttpModel(this.dataModel);
          await dataObject.createRecord({ ...this.currentNewParam }, { [this.linkField]: this.currentNewParam.$номерЗаписи });
          this.$sendMsg('stack-table', this.dataModel, 'reload');
        } else {
        // @ts-ignore
          await this.$refs.table.onToolBarAction({ action: 'add', payload: { ...this.currentNewParam, [this.linkField]: this.currentNewParam.$номерЗаписи } });
        }
      }
      this.newParamDialogVisible = false;
    },

    onAddNewParam() {
      // @ts-ignore
      document.activeElement.blur();
      this.currentNewParam = undefined;
      this.newParamDialogVisible = true;
    },

    paramColorHandler(item: any) {
      // это параметр выше по иерархии
      if (item['счет-параметры'] > 0 && item['счет-параметры'] !== +this.ownerID) {
        item.$data[this.linkField + '>наименование'].$иконка = '$vuetify.icons.hier';
        item.$data[this.linkField + '>наименование'].$иконка_цвет = 'blue-grey';
        TableHandlers.trColor(item, 'blue-grey');
      }
      return item;
    },
    fetchData() {
      // @ts-ignore
      this.$refs.table.fetchData();
    },
  },
});
