
import Vue from 'vue';
import onechargeFolder from './$folder/onechargefolder.vue';
import oneChargeStatementsFilter from '@/tasks/fl/views/$common/filters/oneChargeStatementsFilter.vue';
import { TableHandlers } from '@/stackEngine';

export default Vue.extend({
  components: { onechargeFolder, oneChargeStatementsFilter },
  name: 'Onechargestatements',
  methods: {
    rowhandler(item: StackTableRow) {
      if (item['окончания срок']) {
        TableHandlers.trColor(item, 'grey');
      }
      return item;
    },
  },
});
