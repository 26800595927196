
import Vue from 'vue';
import PayRollToolbar from './$dialogs/PayRollToolbar.vue';
import DeleteRollDial from './$dialogs/DeleteRollDial.vue';
import PayrollFilter from '@/tasks/fl/views/$common/filters/PayrollFilter.vue';

export default Vue.extend({
  name: 'Payrolllists',
  components: { PayRollToolbar, DeleteRollDial, PayrollFilter },
  data() {
    return {
      payRollDialog: false,
      jobID: null,
    };
  },
  computed: {
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
  },
  methods: {
    onSave(payload: any) {
      this.payRollDialog = false;
      this.jobID = payload;
    },
    onClick() {
      this.payRollDialog = true;
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.$sendMsg('stack-table', 'ВедомостьРаспределенияОплаты', 'reload');
      }
    },
  },
});
