
import Vue from 'vue';

export default Vue.extend({
  props: {
    selectedItems: { type: Array, required: true },
    headers: { type: String, required: true },
    dataObject: { type: Object, required: true },
  },
  computed: {
    items(): any {
      const selItems: StackTableRow[] = this.selectedItems as StackTableRow[];
      const headers: StackField[] = this.dataObject.fields as StackField[];
      if (selItems.length) {
        return this.headers.split(',').map(name => {
          const index = headers.findIndex(item => item.field === name);
          return {
            name: index !== -1 ? headers[index].text : name,
            type: index !== -1 ? headers[index].type : 'Number',
            sum: selItems.map(el => el[name] ? Number(el[name]) : 0).reduce((acc, v) => acc + v),
          };
        });
      }
      return [];
    },
  },
});
