
import Vue from 'vue';
import { ReportsBuilder } from '@/stackEngine';

export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      item: {} as StackTableRow,
      ИтогоОбъем: 0,
      РасходГр: 0,
      Схема: '',
      reportParams: {},
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const dataObject = new this.$HttpModel('ГрупповыеСчетчики');
      const recs = await dataObject.getRecords({ номерЗаписи: this.record['показания-документ'] });
      if (recs[0]) {
        this.item = Object.assign({}, recs[0]);
        const result = await dataObject.executeMethod('ПолучитьНачальныеДанные', { данные: this.record });
        if (result) {
          this.ИтогоОбъем = result.ИтогоОбъем ? result.ИтогоОбъем : 0;
          this.РасходГр = result.РасходГр ? result.РасходГр : 0;
          this.Схема = result.Схема ? result.Схема : '';
        }
      }
    },
    runReport() {
      this.reportParams = Object.assign({}, this.reportParams, {
        ведомость: +this.record['показания-документ'],
        номерЗаписи: +this.record['показания-документ'],
        ЗаМесяц: this.record['расчетный месяц'],
        имяВыборки: 'ГрупповыеСчетчики',
        'Вызов из веба': true,
      });
      const reportName = 'Групповой счетчик: Распределение общего ПУ по истории деления';
      new ReportsBuilder(reportName).executeReport2(reportName, this.reportParams);
    },
    openCounter() {
      const groupcountersId = this.record['показания-документ'];
      const routeData = this.$router.resolve({ path: `/metersdata/groupcounters/${groupcountersId}` });
      window.open(routeData.href, '_blank');
    },
  },
});
