const cmonth: StackSelection = {
  objectName: 'ОткрытиеМесяца',
  description: '',
  fields: [
    {
      field: 'операция',
      text: 'операция',
      type: 'String',
    },
    {
      field: 'фонд',
      text: 'фонд',
      type: 'String',
    },
    {
      field: 'месяц',
      text: 'месяц',
      type: 'Date',
    },
  ],
};

export default cmonth;
