
import Vue from 'vue';

export default Vue.extend({
  name: 'MonthChip',
  data() {
    return {
      menu: false,
      evListener: undefined as any,
      timeoutID: undefined as number | undefined | any,
      delay: 300,
      workMonth: '',
    };
  },
  computed: {
    calendarIcon(): string {
      return this.isWorkMonthPartly ? '$month_half_open' : this.isClosedMonth ? '$month_close' : '$month_open';
    },
    calendarMonthColor(): string {
      return this.isWorkMonthAdm ? 'error' : this.isWorkMonthPartly ? 'warning' : this.isClosedMonth ? 'error ligh' : 'accent';
    },
    isClosedMonth(): boolean {
      return this.workMonth <= this.getCloseMonth;
    },
    getCloseMonth(): string {
      return this.$store.getters.getCloseMonth();
    },
    getOpenMonth(): string {
      return this.$store.getters.getOpenMonth();
    },
    isWorkMonthPartly(): boolean {
      return this.$store.getters.isWorkMonthPartly();
    },
    isWorkMonthAdm(): string {
      return this.$store.getters.isWorkMonthAdm();
    },
    getWorkMonth(): string {
      return this.$store.getters.getWorkMonth();
    },
  },
  mounted() {
    this.evListener = (e: KeyboardEvent) => this.keyboardListener(e);
    document.addEventListener('keydown', this.evListener);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.evListener);
  },
  methods: {
    keyboardListener(event: KeyboardEvent) {
      // ctrl Стрелки вверх-вниз
      if (event.ctrlKey) {
        switch (event.key) {
          case 'ArrowUp':
            this.onUpDown(-1);
            event.stopPropagation();
            break;
          case 'ArrowDown':
            this.onUpDown(1);
            event.stopPropagation();
            break;
        }
      }
    },

    onUpDown(idx: number) {
      const d = new Date(this.workMonth);
      d.setMonth(d.getMonth() + idx);
      this.workMonth = this.$stackDate.format(d, 'ISOMonth');
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => {
        this.$store.commit('CHANGE_WORK_MONTH', d);
      }, this.delay);
    },
    onChangeWorkMonth(payload: Date) {
      this.$store.commit('CHANGE_WORK_MONTH', new Date(`${payload}-01T00:00:00`));
      this.menu = false;
    },
  },
  watch: {
    getWorkMonth: {
      immediate: true,
      handler(to) {
        this.workMonth = to;
      },
    },
  },
});
