const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.АктыСнятия',
  description: 'Акты снятия качества',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'датнач',
      text: 'C',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'По',
      type: 'Date',
    },
    {
      field: 'документ-качество',
      text: 'Документ-качество',
      type: 'Link',
    },
    {
      field: 'окончания срок',
      text: 'Отменен',
      type: 'Date',
    },
    {
      field: '@услуга',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: 'коэффициент',
      text: 'Коэффициент',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Снятие за качество-акт',
      type: 'String',
    },
  ],
};
export default accs;
