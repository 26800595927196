
import Vue from 'vue';
import ContactsDialog from '@/tasks/common/views/$common/contactsDialog.vue';

export default Vue.extend({
  components: { ContactsDialog },
  props: {
    ownerID: { type: [Number, String], default: null },
  },
  methods: {
    fetchHandler(item: StackTableRow) {
      let rowItem = -1;
      if (item['частные лица-телефон'] && item['частные лица-телефон'] !== undefined) {
        rowItem = item['частные лица-телефон'] as number;
      }
      item.fioHandler = rowItem > 0 ? item['частные лица-телефон>фио'] : 'Общий';
      return item;
    },
  },
});
