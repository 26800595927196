
import Vue from 'vue';
import stStep from './step.vue';

export default Vue.extend({
  components: {
    stStep,
  },
  props: {
    isComplete: { type: Boolean, default: false },
    routeParams: { type: Object, required: true },
  },
  computed: {
    titleStep(): string {
      const strFund = this.isComplete ? '(' + this.фондСтрокой + ')' : '';
      const strMonth = this.isComplete ? ' за месяц ' + this.$stackDate.format(this.month, 'MM.YYYY') : '';
      return 'Списание дебетового сальдо пени по лс ' + strFund + ' без долгов' + strMonth;
    },
    fund: {
      get(): string {
        return this.$route.query.fund ? this.$route.query.fund.toString() : '';
      },
      set(newValue: string) {
        const query = { ...this.$route.query };
        query.fund = newValue;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.$router.push({ query }).catch((e) => {});
      },
    },
    фондСтрокой(): string {
      return this.$route.query.фондСтрокой ? this.$route.query.фондСтрокой.toString() : '';
    },
  },
  data() {
    return {
      фонд: this.routeParams.fund,
      month: this.routeParams.month,
    };
  },
  methods: {
    onSelectAdr(items: StackTableRow[]) {
      const query = { ...this.$route.query };
      query.фондСтрокой = items.map((item) => item.адрес).join(',');
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push({ query }).catch((e) => {});
    },
  },
});
