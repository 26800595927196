const acts: StackSelection = {
  objectName: 'ВидыРодства',
  description: 'Виды родства',
  openLink: 'references-relationship',
  fields: [
    {
      field: 'название',
      text: 'Родство',
      type: 'String',
    },
  ],
};
export default acts;
