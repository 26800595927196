
import Vue from 'vue';
import datnachDatend from './$datNachdatEnd.vue';

export default Vue.extend({
  components: { datnachDatend },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    label: { type: String, default: 'Значение' },
    comboboxModel: { type: [String, Array], required: true },
    itemValue: { type: String, default: '$номерЗаписи' },
    dateType: { type: String, default: 'date' },
    hideDate: { type: Boolean, default: false },
  },
});
