
import Vue from 'vue';

import { TableHandlers } from '@/stackEngine';

export default Vue.extend({
  name: 'PostTab',
  components: {},
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      idPost: -1,
      idSH: -1,
      Текст: '',
      ТекстHTML: '',
    };
  },
  methods: {
    clickTable(event: number[]) {
      const objectEvent = (event && event[0] ? event[0] : {}) as StackTableRow;
      this.idPost = (objectEvent.$номерЗаписи ? objectEvent.$номерЗаписи : -1) as number;
      this.idSH = (objectEvent['шаблон-сообщение'] ? objectEvent['шаблон-сообщение'] : -1) as number;
      this.fetchText();
    },
    async fetchText() {
      const data = await new this.$HttpModel('ЛицевыеСчета.Почта.ТелоПисьма').getRecords({ идсообщения: this.idPost, шаблон: this.idSH });
      this.Текст = (data.length > 0 ? data[0].значение : '') as string;
      this.ТекстHTML = (data.length > 0 && data[1] ? data[1].значение : '') as string;
    },
    openFile(to: StackTableRow[]) {
      if (!to[0]) {
        this.$toast('Выберите файл');
        return;
      }
      new this.$HttpModel('ЛицевыеСчета.Почта.Вложения').executeMethod(
        'ОткрытьФайл',
        { номерЗаписи: to[0].$номерЗаписи, имя: to[0].имяфайла },
        {
          async: true,
          jobName: 'Открытие файла ' + to[0].имяфайла + ' по л/с ' + this.record.номер,
          type: 'report',
        },
      );
    },
  },
});
