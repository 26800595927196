
import Vue from 'vue';
import InputPasswordDialog from './InputPasswordDialog.vue';

interface InfoItem {
  title: string;
  value: any;
}

export default Vue.extend({
  components: { InputPasswordDialog },
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    lsID: { type: Number, default: -1 },
    dataModel: { type: Object, required: true },
    accountsID: { type: Number, default: -1 },
    readonly: { type: Boolean, default: false },
    tableHeaders: { type: String, default: 'номер,стрсостояние,датасоздания,датаобновления' },
    tableDataModel: { type: String, default: 'ЛицевыеСчета.Аккаунты.СписокЛицевых' },
  },
  computed: {
    titleBtn(): string {
      return this.record.$номерЗаписи === -1 ? 'Ввод пароля' : 'Смена пароля';
    },
    boxInfo(): InfoItem[] {
      if (this.$options.filters) {
        return [
          // TODO замапить фильтры
          { title: 'Дата создания', value: this.$options.filters.date(this.record.датасоздания) },
          { title: 'Дата обновления', value: this.$options.filters.date(this.record.датаобновления) },
        ];
      }
      return [];
    },
  },
  data() {
    return {
      loading: false,
      passwordDialog: false,
    };
  },
  methods: {
    async searchLogin() {
      if (this.record && this.record.логин !== '') {
        this.loading = true;
        try {
          const res = await this.dataModel.executeMethod('ПоискПоЛогину', { логин: this.record.логин, владелец: this.lsID });
          // TODO если нашли этот логин в удаленных, то надо его восстановить
          // пока придумал поднянуть номер записи, но  вроде как не совсем верно,
          // а на сохранении обновлять удаленную запись и прерывать реальное сохранение,
          // как это сейчас делается в десктопе, совсем не верно
          this.record.$номерЗаписи = res.номерЗаписи ? +res.номерЗаписи : -1;
          this.record['e-mail'] = res.Email;
          this.record.телефон = res.Телефон;
          this.record.состояние = res.Состояние;
          this.record['привязка-пароль'] = res.ПривязкаПароль;
        } catch (error: AnyException) {
          this.$toast(error.message);
        }
        this.loading = false;
      } else {
        this.$toast('Необходимо заполнить поле "Логин"');
      }
    },
    async inputPassword(password: string) {
      if (await this.$yesno(`Распечатать пароль?`, 'Внимание', { width: '600px' })) {
        this.dataModel.executeMethod(
          'ПечатьПароля',
          { пароль: password, владелец: this.lsID, логин: this.record.логин },
          {
            async: true,
            jobName: 'Печать пароля',
            type: 'report',
          },
        );
      }
      this.record.пароль = password;
      this.passwordDialog = false;
    },
  },
});
