
import Vue from 'vue';
import StackInput from './StackInput.vue';

export default Vue.extend({
  extends: StackInput,
  props: {
    /**
     * идентификатор, под которым сохраняется история в local storage,
     * если не указан, то в качестве идентификатора используется метка инпута
     */
    historyId: { type: String, default: undefined },
    /** Максимальное количество сохраненных значений */
    capacity: { type: Number, default: 10 },
    floatPlaceholder: { type: Boolean, default: false },
  },
  data() {
    return {
      items: [] as string[],
      storage: window.localStorage,
    };
  },
  computed: {
    hisId() {
      // @ts-ignore
      return this.historyId || this.label;
    },
  },
  created() {
    const data = this.storage.getItem(this.hisId);
    if (data) {
      try {
        this.items = JSON.parse(data);
      } catch (e: AnyException) {
        console.log(e);
      }
    }
  },
  methods: {
    onChange(val: string) {
      if (val && this.items.indexOf(val) === -1) {
        // Новое значение добавляем в начало
        this.items.unshift(val);
        // Ограничиваем длину массива
        if (this.items.length > this.capacity) {
          this.items.length = this.capacity;
        }
        this.storage.setItem(this.hisId, JSON.stringify(this.items));
      }
    },
  },
});
