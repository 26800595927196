export const stackDialog: StackTourStep[] = [
  {
    target: '[data-cy="stack-test-dialog"]',
    content: `Вводите, исправляйте, копируйте данные в поле ввода текста.`,
    commit: 'textInput',
    params: {
      placement: 'bottom',
    },
  },
  {
    target: '[data-cy="stack-test-dialog"]',
    commit: 'dataInput',
    content: `Введите дату вручнею в поле ввода даты в формате дд.мм.гггг. или воспользуйтесь календарным выбором, нажав на иконку календаря.`,
    params: {
      placement: 'bottom',
    },
  },
  {
    target: '[data-cy="stack-test-dialog"]',
    commit: 'linkFieldInput',
    content: `Нажмите на иконку в поле связи. В появившемся окне выберите нужное значение параметра из справочника.
    <div class="pt-2" />
    Для быстрого выбора услуги возможен ручной ввод. 
    Введите номер услуги и нажмите кнопку tab на клавиатуре или кликните мышкой по любой области диалога − услуга автоматически подгрузится.
    `,
    params: {
      placement: 'bottom',
    },
  },
  {
    target: '[data-cy="stack-test-dialog"]',
    commit: 'comboInput',
    content: `Начните вводить текст вручную или выберите значение из списка в поле со списком.`,
    params: {
      placement: 'bottom',
    },
  },
  {
    target: '[data-cy="btn-restore"]',
    commit: 'restore',
    content: `Вы можете отменить последнее действие, нажав на кнопку Отмена.`,
    params: {
      placement: 'bottom',
    },
  },
  {
    target: '[data-cy="btn-restore"]',
    commit: 'save',
    content: `Кнопка Сохранить становится видна в ходе любых изменений в диалоге. Чтобы сохранить результаты ввода и форматирования данных - нажмите на нее.`,
    params: {
      placement: 'bottom',
    },
  },
  {
    target: '[data-cy="btn-close"]',
    commit: 'close',
    content: `Завершив работу с диалогом, нажмите на крестик в левом верхнем углу, чтобы закрыть его.`,
    params: {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [190, 5],
          },
        },
      ],
    },
  },
];