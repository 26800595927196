
import Vue from 'vue';
import documentFolder from '@/tasks/common/views/$common/documentFolder.vue';
import ReadingDialog from '@/tasks/fl/views/accounts/$accountsid/$readingdialog/ReadingDialog.vue';
import readingStatementsFilter from '@/tasks/fl/views/$common/filters/readingStatementsFilter.vue';

export default Vue.extend({
  name: 'Readingstatements',
  components: { readingStatementsFilter, documentFolder },
});
