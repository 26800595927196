const par: StackSelection = {
  recordRoute: '',
  objectName: 'ВидыПараметров',
  description: 'Виды параметров',
  openLink: 'references-parameters',
  fields: [
    {
      field: 'номер',
      text: 'Сортировка',
      type: 'String',
    },
    {
      field: 'название',
      text: 'Краткое название',
      type: 'String',
    },
    {
      field: 'наименование',
      text: 'Полное название',
      type: 'String',
    },
    {
      field: 'управление',
      text: 'управление',
      type: 'Number',
    },
  ],
};

export default par;
