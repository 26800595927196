const ved: StackSelection = {
  objectName: 'ВедомостьРаспределенияОплаты',
  description: 'Распределение оплаты',
  hier: true,
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.$этоПапка) {
      row.апи_типсуммы = '';
      row.апи_типоплаты = '';
      // @ts-ignore
      row.$data.месяц = { $иконка: '$vuetify.icons.folder' };
    } else {
      row.услуга = row.услуга?.toString();
    }
    return row;
  },
  fields: [
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'DateMonth',
      width: '150px',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'услуга',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: 'оплата-поставщик>название',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'организация-укдоговор>название',
      text: 'Договор',
      type: 'String',
    },
    {
      field: 'сумма',
      text: 'Распределенная\n  сумма',
      type: 'Money',
    },
    {
      field: 'комсборсумма',
      text: 'в т.ч.\nКом.сбор',
      type: 'Money',
    },
    {
      field: 'типсуммы',
      text: 'типсуммы',
      type: 'Number',
    },
    {
      field: 'апи_типсуммы',
      text: 'Распределение по',
      type: 'Enum',
      width: '150px',
      computed: true,
    },
    {
      field: 'апи_типоплаты',
      text: 'Тип',
      type: 'Enum',
      width: '150px',
      computed: true,
    },
  ],
};
export default ved;
