
import Vue from 'vue';
import detail from './$modal/detailDialog.vue';
import peniDialog from './$modal/peniDialog.vue';
import { scanable } from '@/stackEngine';
import claimsFilter from '@/tasks/avar/views/$common/filters/claimsFilter.vue';
import contractsFilter from '@/tasks/avar/views/$common/filters/contractsFilter.vue';
import actsFilter from '@/tasks//avar/views/$common/filters/actsFilter.vue';
import paymentsFilter from '@/tasks//avar/views/$common/filters/paymentsFilter.vue';

export default Vue.extend({
  components: { detail, peniDialog, claimsFilter, contractsFilter, actsFilter, paymentsFilter },
  mixins: [scanable],
  props: {
    paymentsid: { type: [Number, String], default: undefined },
    paylistsid: { type: [Number, String], default: undefined },
    readonly: { type: Boolean, default: false }, // для открытия с лицевого дизеблим диалог
    paymentsreceipts: { type: [Number, String], default: undefined }, // id выписки из банка, если открываем с ее диалога
  },
  computed: {
    params(): object {
      return {
        лицевой: this.record['счет-оплата'],
        поставщик: this.record['пост-оплата'],
        ук: this.record['ук-оплата'],
        месяц: this.record.расчмесяц,
        полныйпериод: this.flagperiod,
      };
    },
    isNewRecord(): boolean {
      return !(this.record.$номерЗаписи && this.record.$номерЗаписи > 0);
    },
    sumItog(): number {
      return this.record && this.record.сумма !== undefined && this.record.сумма !== null ? +(+this.record.сумма - this.sumpeni - this.sumServ).toFixed(2) : 0;
    },
    readingsFilter(): object {
      if (this.isNewRecord || this.errorInSaveReading) {
        return { владелец: this.record['счет-оплата'], дата: this.record.дата };
      } else {
        return { владелец: this.record.$номерЗаписи };
      }
    },
    readingsInputData(): object {
      if (this.isNewRecord || this.errorInSaveReading) {
        return { месяц: this.record.расчмесяц, дата: this.record.дата };
      } else {
        return {};
      }
    },
    dataModel(): string {
      return this.isNewRecord || this.errorInSaveReading ? 'Счетчики.МассовыйВвод' : 'ПлатежныеВедомости.ПлатежиВедомости.Показания';
    },
    isRasprTabVisible(): boolean {
      return this.$store.getters.getCurrentTask() === 'rsp';
    },
    isClaimTabVisible(): boolean {
      return this.$store.getters.getCurrentTask() === 'avar';
    },
  },
  data() {
    return {
      record: {} as StackTableRow,
      isCloseLS: false,
      isPeni: false, // если есть пени в таблице, то поле сумма пени нельзя редактировать
      sumServ: 0,
      sumpeni: 0,
      flagorg: 0,
      flagperiod: 0,
      rulesThis: {
        monthLSRules: (value: boolean): string | true => {
          return !value || 'Расчетный месяц для этого лицевого счета закрыт. Изменение платежей запрещено';
        },
        summaNotNull: (value: number): string | true => {
          return +value !== 0 || 'Сумма платежа не должна быть равна 0';
        },
        pokRules: (value: number): string | true => {
          return value === 1 || 'Ошибки в показаниях';
        },
        dateIsLessThan: (date1: string, date2: string, message = 'Дата конца меньше даты начала'): string | true => {
          return Date.parse(date1) >= Date.parse(date2) || !date1 || !date2 || message;
        },
        dateIsGreaterThan: (date1: string, date2: string, message = 'Дата начала больше даты конца'): string | true => {
          return Date.parse(date1) <= Date.parse(date2) || !date1 || !date2 || message;
        },
        checkCurrentSum: (value: number, cursum: number, certificate: number, claim: number, payment: number): string | true => {
          if (cursum !== null) {
            if (certificate > 0 && value > cursum) {
              return 'Сумма платежа не должна быть больше суммы акта';
            }
            if (payment > 0 && cursum < 0 && value < cursum) {
              return 'Сумма платежа не должна быть меньше суммы платежа для сторнирования';
            }
          }
          return true;
        },
        dataMonthRules: (minDate: string, date: string): string | true => {
          const dateMax = minDate ? this.$stackDate.addMonths(minDate, 1) : this.$stackDate.addMonths(new Date(), 1);
          return this.$stackRules.dateIsGreaterEqualThan(this.$stackDate.format(date, 'YYYY-MM') + '-01', this.$stackDate.format(dateMax, 'YYYY-MM-DD'), 'Месяц платежа превышает расчетный месяц более чем на 1');
        },
      },
      validpok: 1,
      errorInSaveReading: false,
      evListener: undefined as any,
      message: '',
      dataComboIL: [] as StackTableRow[],
      valueIL: '',
      loading: false,
      disableSum: false,
      sumClaim: 0,
      sumPay: 0,
      isRequired: false,
      currentSum: null,
      item: {
        $номерЗаписи: -1,
        лицевой: -1,
        договор: -1,
        заявка: -1,
        услуга: -1,
        сторплатеж: -1,
        подразделение: -1,
        акт: -1,
        сумма: 0,
        аналитика: -1,
        поставщик: -1,
        тема: '',
        номеруслуги: '',
        дата: '',
        месяц: '',
      } as any,
    };
  },
  mounted() {
    this.evListener = (e: KeyboardEvent) => this.keyboardListener(e);
    document.addEventListener('keydown', this.evListener);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.evListener);
  },
  methods: {
    keyboardListener(event: KeyboardEvent) {
      if (event.key === 'PageDown') {
        event.stopPropagation();
        this.onSaveAdd();
      }
    },

    // Кнопка сохранить и добавить новый платеж
    async onSaveAdd() {
      if (this.readonly || (this.record && this.record.$толькоЧтение)) {
        return;
      }
      this.loading = true;
      if (this.isNewRecord) {
        // @ts-ignore
        if (await this.$refs.paydialog.createRecord()) {
          // TODO чтобы показания не перенабрались когда не следует
          this.errorInSaveReading = false;
          if (await this.saveNewReadings(this.record)) {
            // @ts-ignore
            await this.$refs.paydialog.initRecord();
            this.sumpeni = 0;
            this.sumServ = 0;
            // @ts-ignore
            this.$refs.lsfield.focus();
          } else {
            this.record.$блок = true;
          }
        }
      } else {
        // @ts-ignore
        if (await this.$refs.paydialog.saveRecord()) {
          // TODO чтобы показания не перенабрались когда не следует
          this.errorInSaveReading = false;
          if (await this.saveOldReadings()) {
            const params = { ...this.$route.params };
            params.paymentsid = 'new';
            this.sumServ = 0;
            this.sumpeni = 0;

            this.$router.replace({ params });
          } else {
            this.record.$блок = true;
          }
        }
      }
      this.loading = false;
    },
    // Выбрали лицевой - считываем с выбранного лицевого закрытый месяц
    //                 - проверяем есть ли дела по ИЛ
    async selectLS(lsRows: StackTableRow[]) {
      this.loading = true;
      if (lsRows && lsRows[0] && this.record && this.record.расчмесяц) {
        const montCloseLs = lsRows[0]['@вадмрежиме'] ? this.$stackDate.prevMonth(lsRows[0].закрытыймесяц, 1) : lsRows[0].закрытыймесяц;
        this.isCloseLS = Date.parse(montCloseLs as string) >= Date.parse(this.record.расчмесяц.toString());
      }
      this.message = '';
      if (this.record['счет-оплата'] && this.record['счет-оплата'] > 0) {
        this.disableSum = true;
        try {
          this.dataComboIL = await new this.$HttpModel('ПлатежныеВедомости.ПлатежиВедомости.ИЛ').getRecords({
            лицевой: this.record['счет-оплата'],
            платеж: this.record.$номерЗаписи,
          });
        } catch {
          // error
        }
        if (this.isNewRecord && this.record['сумма'] === 0) {
          try {
            const dataLS = await new this.$HttpModel('ПлатежныеВедомости.ПлатежиВедомости').executeMethod('ИнициализацияДанныхПоЛицевому', {
              лицевой: this.record['счет-оплата'],
              месяц: this.record.расчмесяц,
            });
            if (dataLS) {
              this.record.сумма = dataLS.Сумма ? +dataLS.Сумма : 0;
            }
          } catch {
            // error
          }
        } else {
          // @ts-ignore
          this.sumpeni = this.record['суммапени'] !== undefined ? +this.record['суммапени'] : 0;
        }
        this.disableSum = false;
      }
      if (this.dataComboIL && this.dataComboIL.length) {
        this.message = 'Внимание! По данному ЛС ведется исполнительное производство!';
        if (this.record && this.record.$номерЗаписи && this.record.$номерЗаписи > 0) {
          this.valueIL = (this.dataComboIL[0].значение ? this.dataComboIL[0].значение : '') as string;
          this.disableSum = true;
        } else {
          this.valueIL = '';
        }
      }
      this.loading = false;
    },
    // Добавили пени в таблицу, пересчитываем поле сумма пени
    changePeni(peniRows: StackTableRow[]) {
      this.isPeni = peniRows.length > 0;
      this.sumpeni = 0;
      for (const ind in peniRows) {
        this.sumpeni += (peniRows[ind] && peniRows[ind].сумма ? peniRows[ind].сумма : 0) as number;
      }
    },
    // Добавили оплату по услугам в таблицу, пересчитываем сумму по услугам
    changeDetail(servRows: StackTableRow[]) {
      this.sumServ = 0;
      for (const ind in servRows) {
        this.sumServ += (servRows[ind] && servRows[ind].сумма ? servRows[ind].сумма : 0) as number;
      }
    },
    // изменили сумму пени или показание,то изменим в record, чтобы среагировал valid
    isChange() {
      // TODO ?
      this.$set(this.record, 'изменилипениилипоказание', 1);
      // чтобы когда платеж сохранился, dataModel выборки показаний не изменился
      if (this.isNewRecord) {
        this.errorInSaveReading = true;
      }
    },
    // Проверяем выбранную организацию, если нет в combo-box, то выводим в link-field и взводим флаг
    isFlagOrg(orgRow: StackTableRow, value: number) {
      if (!orgRow && value > 0) {
        this.flagorg = 1;
      }
    },
    // Убрали флаг на свободный выбор ук и поставщика, обнуляем данные по ним и возвращаем combo-box
    changeFlag(value: number) {
      if (!value) {
        this.record['ук-оплата'] = -1;
        this.record['пост-оплата'] = -1;
      }
    },
    async saveNewReadings(to: StackTableRow) {
      if (this.item.договор > 0) {
        this.$set(this.item, 'дата', this.record.дата);
        this.$set(this.item, 'месяц', this.record.расчмесяц);
        if (this.record.$копирование) {
          await new this.$HttpModel('ПлатежныеВедомости.ПлатежиВедомости.СвязиПлатежа').saveRecord(this.item, { владелец: this.record.$номерЗаписи });
        } else {
          this.onFoundAccount(this.item);
        }
      }
      const newPayID = (to.$номерЗаписи ? to.$номерЗаписи : -1) as number | string;
      if (newPayID > 0) {
        // @ts-ignore
        this.errorInSaveReading = !(await this.$refs.reading.sendReadings(newPayID));
      }
      return !this.errorInSaveReading;
    },
    async saveOldReadings() {
      if (this.isRequired) {
        this.$set(this.item, 'дата', this.record.дата);
        this.$set(this.item, 'месяц', this.record.расчмесяц);
        if (this.item.$номерЗаписи > 0) {
          await new this.$HttpModel('ПлатежныеВедомости.ПлатежиВедомости.СвязиПлатежа').saveRecord(this.item, { владелец: this.paymentsid });
        } else {
          if (this.record && this.record.$номерЗаписи) {
            const res = await new this.$HttpModel('ПлатежныеВедомости.ПлатежиВедомости').executeMethod('ЗаполнитьДетализациюСвязи', {
              запись: this.record,
              расширение: this.item,
            });
          }
        }
      }
      // если при внесении нового показания произошла какая-то ошибка
      // TODO WTF ???
      if (!this.errorInSaveReading) {
        // @ts-ignore
        return !!(await this.$refs.reading.sendReadings());
      } else {
        return this.saveNewReadings(this.record);
      }
    },
    async onPrintBill() {
      await new this.$HttpModel('ВыгрузитьПлатежиВИнтеграцию').executeMethod(
        'ПечатьЧека',
        { платеж: this.paymentsid },
        {
          async: true,
          jobName: 'Печать чека',
        },
      );
    },
    async changeIL(value: number) {
      if (value !== null && value !== undefined) {
        this.record.значениеил = value;
        const res = await new this.$HttpModel('ПлатежныеВедомости.ПлатежиВедомости').executeMethod('ИзменениеИЛ', this.record);
        this.record = Object.assign({}, this.record, res);
      }
    },
    async onFoundAccount(payload: any) {
      this.record['счет-оплата'] = payload.лицевой;
      this.record['сумма'] = payload.сумма;
      if (this.record && this.record.$номерЗаписи) {
        const res = await new this.$HttpModel('ПлатежныеВедомости.ПлатежиВедомости').executeMethod('ЗаполнитьДетализациюСвязи', {
          запись: this.record,
          расширение: payload,
        });
        if (res && res.номер) {
          this.$set(this.record, 'номер', res.номер);
        }
      }
      this.$sendMsg('stack-table', 'ПлатежныеВедомости.ПлатежиВедомости.Детализация', 'reload');
      this.getDataPayClaim();
    },
    changeSumClaim(sum: Number) {
      this.record['сумма'] = +sum;
    },
    async getDataPayClaim() {
      const res = await new this.$HttpModel('ПлатежныеВедомости.ПлатежиВедомости.СвязиПлатежа').getRecords({
        владелец: this.record.$номерЗаписи,
      });
      if (res) {
        res.forEach((el: any) => {
          this.item.$номерЗаписи = el.row_id;
          this.item.заявка = el['карточка-платежи'];
          this.item.акт = el['флдокумент-платежи'];
          this.item.договор = el['флдоговор-платежи'];
          this.item.сумма = el.сумма;
          this.item.дата = el.дата;
          this.item.месяц = el.месяц;
          this.item.номеруслуги = el['номер услуги'];
          this.item.подразделение = el['подразделение-платежи'];
          this.item.лицевой = el['связь-лицевой'];
          if (this.item.заявка > 0 || this.item.акт > 0) {
            this.currentSum = el.суммагро;
          }
        });
        this.item.контрагент = this.record['@фио'];
      }
    },
    async onChange(type: string, payload: any) {
      if (payload && payload[0]) {
        switch (type) {
          case 'claim': {
            this.item.лицевой = payload[0]['счет-наряд'];
            this.item.договор = payload[0]['карточка-флдоговор'];
            const res = await new this.$HttpModel('ЗаявкиАДС').executeMethod('НайтиАктВыполненныхРабот', { карточка: payload[0].$номерЗаписи });
            if (res['Акт']) {
              this.$set(this.item, 'акт', res['Акт']);
              this.$set(this.item, 'сумма', res['Сумма']);
            } else {
              this.sumClaim = this.sumPay = 0;
              const payments = await new this.$HttpModel('ЗаявкиАДС.ОплатаЗаявки').getRecords({ владелец: payload[0].$номерЗаписи });
              payments.forEach((pay: any) => {
                this.sumPay += pay['связь-платеж>сумма'];
              });
              const works = await new this.$HttpModel('ЛицевыеСчета.Работы.Детализация').getRecords({ фильтр: { карточка: payload[0].$номерЗаписи } });
              works.forEach((pay: any) => {
                this.sumClaim += (pay['итого выполнено'] ? pay['итого выполнено'] : 0) as number;
              });
              this.item.сумма = this.sumClaim > this.sumPay ? this.sumClaim - this.sumPay : 0;
            }
            this.item.подразделение = res['Подразделение'];
            this.record['счет-оплата'] = this.item.лицевой;
            this.record['сумма'] = this.item.сумма;
            this.currentSum = this.item.сумма;
            break;
          }
          case 'certificate':
            this.item.лицевой = payload[0]['флдокумент-лицевой'];
            this.item.заявка = payload[0]['флдокумент-карточка'];
            this.item.договор = payload[0]['флдокумент-флдоговор'];
            this.item.сумма = payload[0]['@суммакоплате'];
            this.item.услуга = payload[0]['флдокумент-услуга'];
            this.item.подразделение = payload[0]['флдокумент-исполнитель'];
            this.record['счет-оплата'] = this.item.лицевой;
            this.record['сумма'] = this.item.сумма;
            this.currentSum = this.item.сумма;
            break;
          case 'contract':
            this.item.лицевой = payload[0]['флдоговор-лицевой'];
            this.item.услуга = payload[0]['флдоговор-услуги'];
            this.item.аналитика = payload[0]['флдоговор-группа'];
            this.item.тема = payload[0]['флдоговор-группа>тема'];
            this.record['счет-оплата'] = this.item.лицевой;
            break;
          case 'payment':
            this.item.платеж = payload[0]['связь-платежи'];
            this.item.договор = payload[0]['флдоговор-платежи'];
            this.item.акт = payload[0]['флдокумент-платежи'];
            this.item.подразделение = payload[0]['подразделение-платежи'];
            this.item.лицевой = payload[0]['связь-лицевой'];
            this.item.сумма = -1 * payload[0]['сумма'];
            this.record['счет-оплата'] = this.item.лицевой;
            this.record['сумма'] = this.item.сумма;
            this.currentSum = this.item.сумма;
            break;
          default:
            this.item.лицевой = -1;
            break;
        }
        this.$set(this.record, 'изменилипениилипоказание', 1);
      }
    },
    initContract(payload: any) {
      if (payload && payload[0]) {
        this.item.услуга = payload[0]['флдоговор-услуги'];
        this.item.поставщик = payload[0]['флдоговор-исполнитель'];
        this.item.тема = payload[0]['флдоговор-группа>тема'];
        this.item.аналитика = payload[0]['флдоговор-группа'];
        this.item.контрагент = payload[0]['контрагент'];
      }
    },
    // для копирования
    async initCertificate(payload: any) {
      if (payload && payload[0] && (this.paymentsid === 'new' || this.paymentsid === -1)) {
        const res = await new this.$HttpModel('ПлатежныеВедомости.ПлатежиВедомости').executeMethod('ПолучитьСуммуКОплате', {
          акт: payload[0].$номерЗаписи,
        });
        this.item.сумма = res > 0 ? res : payload[0]['@суммакоплате'];
        this.record['сумма'] = this.item.сумма;
        this.currentSum = this.item.сумма;
      }
    },
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.isRequired = true;
      },
    },

    'record.$номерЗаписи': {
      handler() {
        if (!this.isNewRecord) {
          this.getDataPayClaim();
        }
      },
    },
  },
});
