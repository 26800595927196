
import Vue from 'vue';
import NewCampaign from './$infocenterid/NewCampaign.vue';
import EditCampaign from './$infocenterid/EditCampaign.vue';

export default Vue.extend({
  components: { NewCampaign, EditCampaign },
  props: {
    infocenterid: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
    };
  },
  methods: {
    fetchData() {
      // @ts-ignore
      this.$refs.campaign.fetchData();
    },
  },
});
