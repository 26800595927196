import { StaticModel, HttpModel } from '@/stackEngine';

export class HandCalcModel extends StaticModel {
  private httpModel: HttpModel = new HttpModel('ЛицевыеСчета.РучныеПерерасчеты');
  private isLoad = false; // статус того, что данные загружены
  private state: StackTableRow[] = [];

  // данные протухли, нужно перезагрузить с бэка
  public reset() {
    this.isLoad = false;
  }

  public async initRecord(): Promise<StackTableRow> {
    let newRec = {} as StackTableRow;
    if (this.httpModel.initRecord) {
      const data = await this.httpModel.initRecord();
      if (data) {
        newRec = Object.assign({}, data);
      }
    }
    return newRec;
  }

  get isChanged() {
    if (this.state.length !== this.data.length) {
      return true;
    }
    for (const ind in this.state) {
      if (this.data[ind]) {
        for (const field in this.state[ind]) {
          if (this.data[ind][field] !== undefined && field.indexOf('$') < 0 && this.state[ind][field] !== this.data[ind][field]) {
            return true;
          }
        }
      } else {
        return true;
      }
    }
    return false;
  }

  public async getRecords(params?: any) {
    if (!this.isLoad) {
      this.data = await this.httpModel.getRecords(params);
      this.state = JSON.parse(JSON.stringify(this.data));
      this.isLoad = true;
      this.data.forEach((row: StackTableRow) => {
        this.currentRowid = Math.max(this.currentRowid, Number(row.$номерЗаписи));
      });
    }
    this.sort(params);
    return this.data;
  }

  public async getAllRecords() {
    // Возвращаем все без фильтра
    return this.data;
  }

  public async executeMethod(methodName: string, params?: object): Promise<any> {
    if (this.httpModel.executeMethod) {
      const data = await this.httpModel.executeMethod(methodName, params);
      return data;
    }
    return '';
  }

  public async deleteRecords(records: StackTableRow[]) {
    this.currentRowid = 0;
    for (const key in this.data) {
      records.forEach(record => {
        if (record.$номерЗаписи === this.data[key].$номерЗаписи && record?.фнеудалять === 0) {
          this.data.splice(+key, 1);
        } else {
          this.currentRowid = Math.max(this.currentRowid, Number(this.data[key].$номерЗаписи));
        }
      });
    }
    return true;
  }
}
