
import Vue from 'vue';
import { TableHandlers } from '@/stackEngine';
import printAddKvitDialog from './$dialogs/printAddKvitDialog.vue';
import sendKvitDialog from './$dialogs/sendKvitDialog.vue';

export default Vue.extend({
  name: 'KvitTab',
  components: { printAddKvitDialog, sendKvitDialog },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      mesItem: '',
      mesKvit: null as string | null,
      prItem: '',
      printAddKvit: false,
      sendAddKvit: false,
      additionalShablons: [] as StackTableRow[],
      selectedRows: [] as any[],
    };
  },
  async created() {
    this.additionalShablons = await new this.$HttpModel('МассоваяПечатьКвитанций.ДополнительнаяПечать').getRecords();
  },
  methods: {
    fetchHandler(item: StackTableRow) {
      if (item.естьфайл) {
        TableHandlers.trColor(item, 'primary');
      }
      return item;
    },
    onPrintKvit() {
      if (!this.selectedRows[0].row_id) {
        this.$toast('Выберите квитанцию');
        return;
      }
      new this.$HttpModel('ЛицевыеСчета.Квитанции').executeMethod(
        'ПросмотрКвитанции',
        { номерЗаписи: this.selectedRows[0].row_id },
        {
          async: true,
          jobName: 'Печать: Квитанция ' + this.prItem + ' по л/с ' + this.record.номер + ' за ' + this.mesItem,
          type: 'report',
        },
      );
    },
    itemFetch(to: StackTableRow[]) {
      if (to && to[0]) {
        const mes = to[0].месяц as string || null;
        if (mes) {
          this.mesItem = this.$stackDate.format(mes, 'MM.YYYY');
          this.mesKvit = mes;
          this.prItem = to[0].признакмакета as string;
        }

        this.selectedRows = to.map((item) => (
          {
            row_id: item.$номерЗаписи as number,
            month: item.месяц as string,
            name: item.название as string,
          }
        ));
      } else {
        this.selectedRows = [];
        this.mesKvit = '';
        this.prItem = '';
      }
    },
  },
});
