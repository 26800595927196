import { TableHandlers } from '@/stackEngine';

const acts: StackSelection = {
  objectName: 'ПочтовыйКлиент.Журнал',
  description: 'Журнал',
  prepareRow: row => {
    switch (row.статус) {
      case 0:
        TableHandlers.trColor(row, 'green');
        break;
      case 2:
        TableHandlers.trColor(row, 'red');
        break;
    }
    return row;
  },
  fields: [
    {
      field: 'статус',
      text: 'Статус',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'время',
      text: 'Время',
      type: 'String',
    },
    {
      field: 'пользователь',
      text: 'Пользователь',
      type: 'String',
    },
    {
      field: 'действие',
      text: 'Действие',
      type: 'String',
    },
    {
      field: 'ответсервера',
      text: 'Ответ',
      type: 'String',
    },
  ],
};
export default acts;
