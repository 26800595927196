
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    newRecord(): boolean {
      return this.record.$номерЗаписи === -1;
    },
  },
  data() {
    return {
      flags: ['Внешний документ'],
      rules: {
        disabledTypes: (value: number): string | true => {
          return value >= 100 || `Типы меньше 100 зарезервированы`;
        },
      },
    };
  },
});
