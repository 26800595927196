
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: {
      type: Object,
      default: (): any => {
        return {};
      },
    },
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        Дата: this.$store.getters.getWorkMonth(),
        Параметр: -1,
        ПЗначение: '',
        КЗначение: -1,
        Детализация: 0,
        ФБезПараметра: 1,
        ФТолькоБезПараметра: 0,
        СимСравн: 0,
        table: [] as StackTableRow[],
        ПЗначение_доп: '',
        КЗначение_доп: -1,
        Печатать: 1,
      },
      itemCombo: [] as StackTableRow[],
      itemComboDop: [] as StackTableRow[],
      headers: [
        {
          text: 'Параметр',
          align: 'center',
          sortable: false,
          value: 'paramName',
          width: '30%',
        },
        {
          text: 'Условие',
          align: 'center',
          sortable: false,
          value: 'flagName',
        },
        {
          text: 'Значение параметра',
          align: 'center',
          sortable: false,
          value: 'valueName',
        },
      ],
      fieldList: ['paramName', 'flagName', 'valueName'],
      nameParam: '',
      nameFlag: ['равно', 'не равно'],
      nameCombo: '',
      nameComboDop: '',
      paramDetail: {
        диалогРеестр: 1,
        естьЛС: this.params.outLS !== undefined ? this.params.outLS : 1,
      },
    };
  },
  methods: {
    async onChangeCombo(to: StackTableRow[]) {
      await this.fetchCombo(to);
      this.record.СимСравн = 0;
      this.record.КЗначение = -1;
      this.record.ПЗначение = '';
    },
    async fetchCombo(to: StackTableRow[]) {
      if (to.length === 1 && to[0]?.название) {
        const nameOfModel = await new this.$HttpModel('Реестр_ПараметрыОтчетаПоПараметрам').executeMethod('ПолучитьИмяВыборки', { Параметр: to[0].название }) || 'Реестр_ПараметрыОтчетаПоПараметрам';
        const itemTemp = await new this.$HttpModel(nameOfModel).getRecords({ Параметр: to[0].название });
        this.itemCombo = itemTemp.length === 1 ? [] : itemTemp;
        this.nameFlag = this.itemCombo.length === 0 ? ['равно', 'не равно', 'меньше', 'больше', 'между'] : ['равно', 'не равно'];
        this.nameParam = to[0].название as string;
      }
    },
    onChange(to: Number) {
      if (!to) this.record['ФТолькоБезПараметра'] = 0;
    },
    async nameValue(to: any) {
      if (to) {
        this.nameCombo = to.значение;
        const itemDopTemp = await new this.$HttpModel('ПараметрДопЗначения').getRecords({ ИмяПараметра: this.nameParam, ЗначениеПараметра: this.record.КЗначение });
        this.itemComboDop = itemDopTemp.length === 1 ? [] : itemDopTemp;
      }
    },
    async nameValueDop(to: any) {
      if (to) {
        this.nameComboDop = to.значение;
      }
    },
    addFilter() {
      if (this.record.Параметр > 0 && (this.record.ПЗначение !== '' || this.record.КЗначение > -1)) {
        const valueOsn = this.record.ПЗначение !== '' ? this.record.ПЗначение : this.record.КЗначение;
        const nameValue = this.record.ПЗначение !== '' ? this.record.ПЗначение : this.nameCombo;
        const valueDopZnach = this.record.ПЗначение !== '' && this.record.ПЗначение_доп !== '' && this.record.СимСравн === 4
          ? this.record.ПЗначение_доп
          : ((this.record.КЗначение !== -1 && this.record.КЗначение_доп !== -1 && this.itemComboDop.length) ? this.record.КЗначение_доп : -1);
        this.record.table.push({
          param: this.record.Параметр,
          paramName: this.nameParam,
          flag: this.record.СимСравн,
          flagName: this.nameFlag[this.record.СимСравн],
          value: valueOsn,
          valueName: nameValue,
          valueDop: valueDopZnach,
        });
      }
    },
  },
});
