
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: { type: Object, default: undefined },
  },
  data() {
    return {
      filter: {
        операция: 0,
      } as any,
      data: {
        операция: 0,
        типввода: 1,
        месяц: this.$store.getters.getWorkMonth(),
        дата: this.$stackDate.lastDay(this.$store.getters.getWorkMonth()),
      },
    };
  },
  computed: {
    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
  },
  watch: {
    workMonth() {
      this.clearFilter();
      this.$set(this.data, 'месяц', this.workMonth);
      this.$set(this.data, 'дата', this.$stackDate.lastDay(this.workMonth));
      this.applyData();
    },
  },
  methods: {
    applyFilter() {
      this.data.операция = this.filter.операция;
      this.$emit('applyData', this.data);
      this.$emit('applyFilter', this.filter);
    },
    applyData() {
      this.$emit('applyData', this.data);
    },
    clearFilter() {
      this.$set(this.filter, 'операция', 0);
      this.$set(this.filter, 'счетчик', 0);
      this.$emit('applyFilter', this.filter);
    },
  },
  mounted() {
    this.applyData();
    this.clearFilter();
  },
});
