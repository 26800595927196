import { TableHandlers } from '@/stackEngine';

const doguk: StackSelection = {
  recordRoute: 'contracts-resources-resourcesid',
  objectName: 'ДоговорыСПоставщиками',
  description: 'Договоры с поставщиками',
  openLink: 'contracts-resources',
  hier: true,
  prepareRow: row => {
    if (!row.$этоПапка && row.флагестьдома === 0) {
      TableHandlers.trColor(row, 'grey');
    }
    return row;
  },
  fields: [
    {
      field: 'датнач',
      text: 'С',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'даткнц',
      text: 'По',
      type: 'Date',
    },
    {
      field: 'организация-укдоговор>название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'организация-укдоговор',
      text: 'организация-укдоговор',
      type: 'Link',
    },
    {
      field: 'процентвц',
      text: 'ПроцентВЦ',
      type: 'Number',
    },
    {
      field: 'процентцко',
      text: 'ПроцентЦКО',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'вид договора',
      text: 'Вид договора',
      type: 'Number',
    },
    {
      field: 'методвц',
      text: 'методвц',
      type: 'Number',
    },
    {
      field: 'методцко',
      text: 'методцко',
      type: 'Number',
    },
    {
      field: 'распределениепереплаты',
      text: 'РаспределениеПереплаты',
      type: 'Number',
    },
    {
      field: 'объединениеперерасчетов',
      text: 'ОбъединениеПерерасчетов',
      type: 'Number',
    },
    {
      field: 'запретперерасчета',
      text: 'ЗапретПерерасчета',
      type: 'String',
    },
    {
      field: 'флагестьдома',
      text: 'флагЕстьДома',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'Дата заключения',
      type: 'Date',
    },
    {
      field: 'полный номер',
      text: 'Полный номер',
      type: 'String',
    },
  ],
};
export default doguk;
