
import Vue from 'vue';
import AdrAddBtn from './$accountsid/AdrAddBtn.vue';
import AdrFilter from '@/tasks/fl/views/$common/filters/AdrFilter.vue';
import AdrFolderDialog from '@/tasks/fl/components/AdrFolderDialog.vue';
import recalcDialog from './$resources/RecalcDialog.vue';
import AdrPrintBtn from './$accountsid/AdrPrintBtn.vue';

export default Vue.extend({
  name: 'Accounts',
  components: { AdrAddBtn, AdrFilter, AdrFolderDialog, recalcDialog, AdrPrintBtn },
  computed: {
    busyCalc(): boolean {
      if (!this.calcid) {
        return false;
      }
      const calcAction = this.$store.getters.getMsgActionByID(this.calcid);
      if (!calcAction) {
        return false;
      }
      if (calcAction.error) {
        return false;
      }
      return !calcAction.complete;
    },
  },
  data() {
    return {
      recalcDialogVis: false,
      calcid: null,
      calcIds: null as null | string,
      calcItems: [] as StackTableRow[],
      record: null || ({} as StackTableRow),
    };
  },
  methods: {
    onRecalc(selVal: string, items: StackTableRow[]) {
      if (items && items.length === 1) {
        this.record = items[0] ? items[0] : {};
      } else {
        this.record = {};
      }
      this.calcIds = selVal;
      this.calcItems = items;
      this.recalcDialogVis = true;
    },
    async onCalc(selectedValues: string) {
      try {
        const obj = new this.$HttpModel('ЛицевыеСчета');
        this.calcid = (await obj.executeMethod('Рассчитать', { номерЗаписи: selectedValues }, { async: true, jobName: 'Расчет лицевых', type: 'multi' })).asyncId;
      } catch (error: AnyException) {
        this.$toast('Ошибка расчета', { color: 'error' });
      }
    },
  },
});
