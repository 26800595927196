'use strict';
// @ts-ignore
import { wrappers } from './StackWrapper.vue';

export function noop() {
  /* do nothing */
}

export const CLOSE_EVENT = 'stack-wrapper:close';
export const ERROR_EVENT = 'stack-wrapper:error';

export function collectProps(props: any, args: any) {
  if (props.length === 0 && args[0] && typeof args[0] === 'object') {
    return args[0];
  }

  return props.reduce((propsData: any, prop: any, i: any) => {
    propsData[prop] = args[i];
    return propsData;
  }, {});
}

function filterUndefinedProps(props: any, component: any) {
  const propKeys = {};
  const queue = [component];

  function pushIfNotNull(value: any) {
    if (value != null) {
      queue.push(value);
    }
  }

  // tslint:disable-next-line:no-conditional-assignment
  while ((component = queue.shift())) {
    let propKeysArray: any;
    if (Array.isArray(component.props)) {
      propKeysArray = component.props;
    } else if (typeof component.props === 'object') {
      propKeysArray = Object.keys(component.props);
    }

    if (propKeysArray) {
      propKeysArray.forEach((key: any) => {
        // @ts-ignore
        propKeys[key] = true;
      });
    }

    // If component is from Vue.extend, the real options are in component.options
    pushIfNotNull(component.options);

    // Iterate over component's parent compoent and mixins
    pushIfNotNull(component.extends);
    if (Array.isArray(component.mixins)) {
      component.mixins.forEach(pushIfNotNull);
    }
  }
  // @ts-ignore
  return props.filter((key: any) => !propKeys[key]);
}

function generateDialogData(props: any, component: any) {
  let dialogData: any;

  // eslint-disable-next-line no-return-assign
  return (dialogData = {
    props,
    createdCallback: noop,
    component: Promise.resolve(component).then(component => ({
      extends: component.default || component,
      props: filterUndefinedProps(['arguments', ...props], component),
      created() {
        // Resolves componentPromise that is created in DialogsWrapper.add()
        dialogData.createdCallback(this);
      },
    })),
  });
}

export function wrapComponent(options: any, ...props: any[]) {
  const wrapper = 'default';
  const component = options;
  const dialogData = generateDialogData(props, component);
  return function dialogFunction(...args: any[]) {
    if (wrappers[wrapper]) {
      return wrappers[wrapper].add(dialogData, args);
    }
  };
}
