const regpa: StackSelection = {
  recordRoute: 'references-kvitmessages-kvitmessagesid',
  objectName: 'СообщенияДляКвитанций',
  description: 'Сообщения для квитанции',
  hier: true,
  fields: [
    {
      field: 'датнач',
      text: 'Дата начала',
      type: 'DateMonth',
    },
    {
      field: 'даткнц',
      text: 'Дата окончания',
      type: 'DateMonth',
    },
    {
      field: '_признаки',
      text: 'Признаки',
      type: 'Number',
    },
    {
      field: '_тип',
      text: 'Тип',
      type: 'String',
      computed: true,
    },
    {
      field: 'лицевой-сообщение',
      text: 'ЛС',
      type: 'Link',
    },
    {
      field: 'ук договор-сообщение',
      text: 'УК',
      type: 'Link',
    },
    {
      field: 'организация-сообщение',
      text: 'Организация',
      type: 'Link',
    },
    {
      field: 'договор',
      text: 'Договор',
      type: 'String',
    },
    {
      field: '_адресдоговор',
      text: 'Адрес договора',
      type: 'String',
      computed: true,
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'текст',
      text: 'Текст',
      type: 'String',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'типадресата',
      text: 'Тип адресата',
      type: 'Number',
    },
  ],
};

export default regpa;
