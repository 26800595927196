
import Vue from 'vue';
import Inputmask from 'inputmask';
import inputable from '../mixins/inputable';

/**
 * Инпут с датой
 */
export default Vue.extend({
  name: 'StackDateTimeField',
  mixins: [inputable],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    floatPlaceholder: { type: Boolean, default: false },
    dataTestId: { type: String, default: undefined },
    /**
     * текущее значение в ISO\Date формате
     */
    value: { type: [String], default: null },
    /**
     * не выводить иконку
     */
    noIcon: { type: Boolean, default: false },
    /**
     * может быть пустая
     */
    clearable: { type: Boolean, default: false },
    /**
     * только чтение
     */
    readonly: { type: Boolean, default: false },
    // Очищать, если введено ошибочное значение. (использ. в stackTable inline-edit)
    clearOnError: { type: Boolean, default: false },
    /**
     * не сокращать метку
     */
    noWrapLabel: { type: Boolean, default: false },
  },
  data() {
    return {
      menu: false,
      manualDateValue: null as string | null,
      isManual: false,
      selectedTab: 'calendar',

      dateModel: '',
      timeModel: '',
      mask: '99.99.9999 99:99',
      h: '00',
      m: '00',
    };
  },
  mounted() {
    const selector = this.$el.querySelector('input');
    const im = new Inputmask(this.mask, {
      onBeforePaste: (pasteValue: string) => {
        this.$emit('change', this.manual2Iso(pasteValue));
        return pasteValue;
      },
    });
    im.mask(selector);
  },
  created() {
    if (this.value) {
      this.dateModel = this.value.split('T')[0];
      this.timeModel = this.value.split('T')[1].substring(0, 5); // HH:mm
      if (this.timeModel) {
        [this.h, this.m] = this.timeModel.split(':');
      }
    }
  },
  computed: {
    actualDatetime(): string {
      const dateTime = this.yymmdd2Iso(this.dateModel, `${this.h}:${this.m}`);
      return dateTime;
    },
    rulesCalc(): any {
      // @ts-ignore mixin warn
      return this.customRules ? [this.rulesManual, ...this.customRules] : [this.rulesManual];
    },

    // Перевод из ISO или Date в dd.mm.yyyy (mm.yyyy)
    dateRu(): string | null {
      if (!this.value) {
        return null;
      }
      return this.$stackDate.format(this.value, 'DateTime');
    },
    rulesManual(): string | true {
      if (!this.manualDateValue) {
        return true;
      }

      const dateTime = this.manualDateValue.split(' ');
      const datArr = dateTime && dateTime[0] ? dateTime[0].split('.') : [];
      const timeArr = dateTime && dateTime[1] ? dateTime[1].split(':') : [];
      if (datArr.length !== 3) {
        return 'Некорректная дата';
      }
      if (timeArr.length !== 2) {
        return 'Некорректное время';
      }

      if (
        +datArr[0] >= 1 &&
        +datArr[0] <= 31 &&
        +datArr[1] >= 1 &&
        +datArr[1] <= 12 &&
        +datArr[0] <= this.$stackDate.dateInMonth(+datArr[1]) &&
        ((+datArr[2] >= 1900 && +datArr[2] <= 3000 && datArr[2].length === 4) || (+datArr[2] >= 1 && +datArr[2] <= 99 && datArr[2].length === 2)) &&
        +timeArr[0] >= 0 &&
        +timeArr[0] <= 23 &&
        +timeArr[1] >= 0 &&
        +timeArr[1] <= 59
      ) {
        return true;
      }
      return 'Некорректная дата';
    },
  },
  methods: {
    save() {
      this.selectedTab = 'calendar';
      this.menu = false;
      this.$emit('change', this.actualDatetime);
      // @ts-ignore
      this.$refs.textField.focus();
    },
    onManualInput(value: string) {
      this.manualDateValue = value;
    },

    onKeyPress(event: KeyboardEvent) {
      if (event.ctrlKey) {
        switch (event.keyCode) {
          case 90: // ctrl-Z
            this.$emit('change', this.manual2Iso('09.05.2045 00:00'));
            break;
          case 89: // ctrl-Y
            this.$emit('change', null);
            break;
        }
      }
    },
    // смена через календарь
    pickerDateChanged(date: string) {
      this.dateModel = date;
      this.selectedTab = 'timer';
    },
    pickerTimeChanged() {
      this.selectedTab = 'calendar';
      if (this.dateModel) {
        this.menu = false;
        this.$emit('change', this.actualDatetime);
        // @ts-ignore
        this.$refs.textField.focus();
      }
    },

    // после ручного ввода в текстовое поле на смену фокуса
    onTextFieldBlur() {
      // ничего не ввели - ничего не делаем
      if (!this.isManual) {
        return;
      }
      if (this.rulesManual === true) {
        const dat = this.manual2Iso(this.manualDateValue);
        this.$emit('change', dat);
      } else if (this.clearOnError) {
        this.$emit('change', null);
      }
      this.isManual = false;
    },

    manual2Iso(dat: string | null) {
      if (dat) {
        const dateTime = dat.split(' ');
        const darr = dateTime && dateTime[0] ? dateTime[0].split('.') : [];
        const tarr = dateTime[1];
        if (darr.length === 3) {
          if (darr[2].length === 2) {
            darr[2] = +darr[2] < 50 ? '20' + darr[2] : '19' + darr[2];
          }
          const dat = `${darr[2]}-${darr[1]}-${darr[0]}`;
          return this.yymmdd2Iso(dat, tarr);
        }
      }
      return null;
    },

    yymmdd2Iso(newDat: string | Date, newTime: string) {
      const tarr = newTime.split(':');
      return newDat + 'T' + `${tarr[0]}:${tarr[1]}` + ':00';
    },
  },
  watch: {
    manualDateValue(to: string) {
      if (to !== null) {
        this.isManual = true;
      }
    },
  },
});
