const acts: StackSelection = {
  recordRoute: 'documents-violationsacts-violationsactsid',
  objectName: 'АктыНарушенийАбонентов',
  description: 'Акты нарушений',
  hier: true,
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'DateMonth',
      align: 'right',
    },
    {
      field: 'датнач',
      text: 'ДатНач',
      type: 'DateMonth',
      align: 'right',
    },
    {
      field: 'даткнц',
      text: 'ДатКнц',
      type: 'DateMonth',
      align: 'right',
    },
    {
      field: 'акт-услуга>номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'акт-услуга>название',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'объем',
      text: 'Объем',
      type: 'Number',
      align: 'right',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
      align: 'right',
    },
    {
      field: 'предписание',
      text: 'Предписание',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: '@aдрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: '@фамилия',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'счет-акты>номер',
      text: 'НомерЛС',
      type: 'String',
      align: 'center',
    },
    {
      field: 'тип',
      text: 'Тип акта',
      type: 'Number',
    },
    {
      field: '@распрпотарифам',
      text: 'Распределять по тарифам',
      type: 'Number',
    },
    {
      field: '@разбиениепомесяцам',
      text: 'Разбиение по месяцам',
      type: 'Number',
    },
    {
      field: 'информация',
      text: 'Информация',
      type: 'String',
    },
    {
      field: 'окончания срок',
      text: 'Дата отмены',
      type: 'Date',
    },
  ],
};

export default acts;
