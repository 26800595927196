
import Vue from 'vue';
import addressForm from '@/tasks/common/views/$common/addressForm.vue';

export default Vue.extend({
  model: {
    prop: 'record',
    event: 'change',
  },
  props: {
    record: { type: Object, required: true },
    typeOfAdress: { type: Boolean, required: true },
  },
  components: { addressForm },
  data() {
    return {
      address: {} as StackTableRow,
      keys: {
        страна: this.typeOfAdress ? '@регстрана' : '@фстрана',
        область: this.typeOfAdress ? '@регобласть' : '@фобласть',
        район: this.typeOfAdress ? '@реграйон' : '@фрайон',
        город: this.typeOfAdress ? '@реггород' : '@фгород',
        грайон: this.typeOfAdress ? '@регграйон' : '@фграйон',
        планструктура: this.typeOfAdress ? '@регпланструктура' : '@фпланструктура',
        улица: this.typeOfAdress ? '@регулица' : '@фулица',
        дом: this.typeOfAdress ? '@регдом' : '@фдом',
        корпус: this.typeOfAdress ? '@регкорп' : '@фкорп',
        квартира: this.typeOfAdress ? '@регкв' : '@фкв',
        комната: this.typeOfAdress ? '@регкм' : '@фкм',
        индекс: this.typeOfAdress ? 'индекс' : 'фактиндекс',
      },
      waitFias: false,
      findFias: false,
      houses: [] as StackTableRow[],
    };
  },
  async created() {
    if (this.typeOfAdress) {
      const res = await new this.$HttpModel('ЧастныеЛица').executeMethod('ПроверитьРегистрацию', { $номерЗаписи: this.record.$номерЗаписи, адрес: this.record.адрес });
      if (res) {
        this.$stackMsg(
          [
            {
              текст: res,
              варианты: ['Продолжить'],
            },
          ],
          'Внимание!',
        );
      }
    }
  },
  methods: {
    async onSave() {
      Object.keys(this.address).forEach((key: string) => {
        // @ts-ignore
        this.record[this.keys[key]] = this.address[key];
      });
      const addr = await new this.$HttpModel('ЧастныеЛица').executeMethod('СтрокаПоАдресу', {
        record: this.record,
        type: this.typeOfAdress,
      });

      if (addr) {
        if (this.typeOfAdress) {
          this.record['@адрес'] = addr.адрес;
          this.record['адресмассив'] = addr.адресмассив;
        } else {
          this.record['@фактадрес'] = addr.адрес;
          this.record['фактадресмассив'] = addr.адресмассив;
        }
      } else {
        if (this.typeOfAdress) {
          this.record['@адрес'] = '';
          this.record['адресмассив'] = '';
        } else {
          this.record['@фактадрес'] = '';
          this.record['фактадресмассив'] = '';
        }
      }
      if (this.typeOfAdress) {
        this.question();
      }
      this.$emit('change', this.record);
    },
    async question() {
      if (await this.$yesno('Заполнить фактический адрес адресом регистрации?')) {
        this.record['@фстрана'] = this.record[this.keys.страна];
        this.record['@фобласть'] = this.record[this.keys.область];
        this.record['@фрайон'] = this.record[this.keys.район];
        this.record['@фгород'] = this.record[this.keys.город];
        this.record['@фграйон'] = this.record[this.keys.грайон];
        this.record['@фпланструктура'] = this.record[this.keys.планструктура];
        this.record['@фулица'] = this.record[this.keys.улица];
        this.record['@фдом'] = this.record[this.keys.дом];
        this.record['@фкорп'] = this.record[this.keys.корпус];
        this.record['@фкв'] = this.record[this.keys.квартира];
        this.record['@фкм'] = this.record[this.keys.комната];
        this.record.фактиндекс = this.record.индекс;
        this.record['@фактадрес'] = this.record['@адрес'];
        this.record.фактадресмассив = this.record.адресмассив;
      }
    },
  },
  watch: {
    typeOfAdress: {
      immediate: true,
      handler() {
        this.address = {
          страна: this.record[this.keys.страна],
          область: this.record[this.keys.область],
          район: this.record[this.keys.район],
          планструктура: this.record[this.keys.планструктура],
          наспункт: '',
          город: this.record[this.keys.город],
          индекс: this.record[this.keys.индекс],
          грайон: this.record[this.keys.грайон],
          улица: this.record[this.keys.улица],
          дом: this.record[this.keys.дом],
          корпус: this.record[this.keys.корпус],
          квартира: this.record[this.keys.квартира],
          комната: this.record[this.keys.комната],
          initPostcode: false,
        };
      },
    },
  },
});
