
import Vue from 'vue';

export default Vue.extend({
  props: {
    records: { type: [Array, Object], required: true },
    dataModel: { type: String, required: true },
    dataSplit: { type: String, required: true },
    group: { type: Boolean, default: false },
  },
  data() {
    return {
      rulesID: [] as number[],
      items: [] as any,
      rulesThis: {
        rulesDateSplit: (date: string, month?: boolean): string | true => {
          const message = 'Дата не может быть меньше даты разделения';
          const dateSplit = month ? this.$stackDate.firstDay(this.dataSplit) : this.dataSplit;
          return Date.parse(date) >= Date.parse(dateSplit) || message;
        },
        rulesMonth: (tekMonth: string, predMonth: string): string | true => {
          if (predMonth && Date.parse(predMonth) > Date.parse(tekMonth)) {
            return 'Месяц текущего показания не может быть меньше месяца предыдущего показания';
          }
          return true;
        },
      },
    };
  },
  created() {
    this.initDialog();
  },
  methods: {
    async initDialog() {
      for (const index in this.records) {
        const tempArray = await new this.$HttpModel(this.dataModel).executeMethod('инициализацияДиалогаПоказаний', {
          объект: this.records[index].спобъект,
          тарифность: this.records[index].тарифность,
          дата: this.dataSplit,
          групповой: this.group,
          услуга: this.records[index]['номер услуги'],
        });
        const arrayPok = tempArray.показания ? tempArray.показания : [];
        const items = [];
        if (arrayPok) {
          for (const ind in arrayPok) {
            const tempObj = {} as StackTableRow;
            for (const indO in arrayPok[ind]) {
              tempObj[indO.toLowerCase()] = arrayPok[ind][indO];
            }
            items.push(tempObj);
          }
        }
        this.items.push(items);
        if (this.group) {
          this.rulesID.push(tempArray.правило ? +tempArray.правило : -1);
        }
      }
    },
    zone(item: string): string | undefined {
      if (!item) {
        return '';
      }
      switch (item) {
        case 'день':
          return '$vuetify.icons.zone_day';
        case 'ночь':
          return '$vuetify.icons.zone_night';
        case 'ппик':
          return '$vuetify.icons.zone_ppik';
        case 'пик':
          return '$vuetify.icons.zone_pik';
        default:
          return undefined;
      }
    },
  },
});
