
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: { type: String, default: null },
  },
  data() {
    return {
      item: {
        срокоплаты: 10,
      },
      rulesThis: {
        maxNumber: (value: number): string | true => {
          return (+value > 0 && +value < 32) || 'Некорректная дата';
        },
      },
    };
  },
});
