const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.АдресДоставки',
  description: '',
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'адреслс',
      text: 'Адрес',
      type: 'String',
    },
  ],
};
export default accs;
