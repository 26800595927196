const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.РаспределенныйРасходОДПУ',
  description: 'Распределенный расход ОПУ',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название\nуслуги',
      type: 'String',
    },
    {
      field: '@зона',
      text: 'Зона\nсуток',
      type: 'String',
      computed: true,
    },
    {
      field: '@расход',
      text: 'Расход',
      type: 'NumberZero',
      computed: true,
    },
    {
      field: 'показания-документ',
      text: 'Показания-Документ',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'расчетный месяц',
      text: 'Расчетный\nмесяц',
      type: 'DateMonth',
    },
    {
      field: 'расход',
      text: 'Расход ',
      type: 'NumberZero',
    },
    {
      field: 'дополнительный расход',
      text: 'Коэффициент',
      type: 'NumberZero',
    },
  ],
};
export default accs;
