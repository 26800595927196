
import Vue from 'vue';
import inputable from '../mixins/inputable';
/**
 * Поле ввода
 */
export default Vue.extend({
  name: 'StackTextarea',
  mixins: [inputable],
  model: { prop: 'value', event: 'change' },
  props: {
    /**
     * Значение инпута
     * @model
     */
    value: { type: [String, Number], default: undefined },
    floatPlaceholder: { type: Boolean, default: false },
    rows: { type: [String, Number], default: 2 },
  },
  methods: {
    focus() {
      // @ts-ignore
      if (this.$refs.textarea && this.$refs.textarea.focus) {
        // @ts-ignore
        this.$refs.textarea.focus();
      }
    },
  },
});
