
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      loading: false,
      record: {
        дата: this.$store.getters.getWorkMonth(),
        лицевые: -1,
        флаги: 0,
        операция: 'Загрузка показаний из АСД',
      } as any,
    };
  },
  methods: {
    async run() {
      this.loading = true;
      await new this.$HttpModel('АСД.Операции').executeMethod(
        'ЗагрузитьПоказания',
        this.record,
        {
          async: true,
          jobName: this.record.операция,
          onComplete: this.onComplete,
          onError: this.onComplete,
        },
      );
    },
    onComplete() {
      this.loading = false;
    },
  },
  async created() {
    const res = await new this.$HttpModel('АСД.Операции').executeMethod('НачальныеПараметры', { операция: this.record.операция });
    if (res) {
      if (res.флаги) {
        this.record.флаги = res.флаги;
      }
      if (res.дата) {
        this.record.дата = res.дата;
      }
    }
  },
});
