
import Vue from 'vue';
import PaylistsOfReceiptToolbar from './$paymentsreceipts/PaylistsOfReceiptToolbar.vue';

interface InfoItem {
  title: string;
  value: any;
}

export default Vue.extend({
  components: { PaylistsOfReceiptToolbar },
  props: {
    paymentsreceiptsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
    };
  },
  computed: {
    title(): string {
      return `Выписка из банка № ${this.record.номер || ''} от ${this.record.дата === undefined ? '' : this.$stackDate.format(this.record.дата as string, 'DD.MM.YYYYг.')}`;
    },
    isNewRecord(): boolean {
      return this.paymentsreceiptsid === 'new';
    },
    boxInfo(): InfoItem[] {
      if (this.$options.filters) {
        return [
          // TODO замапить фильтры
          { title: 'Всего платежных ведомостей', value: this.$options.filters.int(this.record.кол_во) },
          { title: 'Сумма ₽', value: this.$options.filters.money(this.record.сумма) },
        ];
      }
      return [];
    },
  },
  methods: {
    comboboxHandler(item: any) {
      return item['р/счет'] + ' в ' + item['банк-р/с>название банка'];
    },
    onClickExcludePayLists(to: any) {
      // @ts-ignore
      this.$refs.table.fetchData();
      if (!this.record.$естьИзменения) {
        // @ts-ignore
        this.$refs.dialog.fetchData();
      }
    },
    onClickIncludePayLists(to: any) {
      // @ts-ignore
      this.$refs.table.fetchData();
      if (!this.record.$естьИзменения) {
        // @ts-ignore
        this.$refs.dialog.fetchData();
      }
    },
  },
});
