const orgsakpr: StackSelection = {
  objectName: 'Организации.АккаунтыПривязка',
  description: 'Привязка аккаунтов',
  fields: [
    {
      field: 'привязка-договор',
      text: 'привязка-договор',
      type: 'Link',
    },
    {
      field: 'привязка-договор>номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: '@плательщик',
      text: 'Плательщик',
      type: 'String',
    },
    {
      field: 'датасоздания',
      text: 'Создан',
      type: 'Date',
    },
    {
      field: 'датаобновления',
      text: 'Обновлен',
      type: 'Date',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: '@статус',
      text: 'Статус',
      type: 'String',
    },
  ],
};

export default orgsakpr;
