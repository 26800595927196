
import Vue from 'vue';

export default Vue.extend({
  name: 'Exchange',
  data() {
    return {
      record: {} as StackTableRow,
      isDialog: false,
      itemsMenu: [{} as StackTableRow],
      screenY: 0,
      itemsList: [{} as StackTableRow],
      itemsListData: [{} as StackTableRow],
      paramsDialog: {} as any,
    };
  },
  async created() {
    const recs = await new this.$HttpModel('НастройкиАрмЮриста').getRecords();
    this.record = Object.assign({}, recs[0]);
  },
});
