const accopu: StackSelection = {
  objectName: 'ЛицевыеСчета.ОДПУ',
  description: '',
  fields: [
    {
      field: 'показания-документ>номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'показания-документ>тема',
      text: ' ',
      type: 'String',
    },
    {
      field: 'показания-документ>примечание',
      text: 'Название',
      type: 'String',
    },
    {
      field: '@видсчета',
      text: 'Вид счетчика',
      type: 'String',
      computed: true,
    },
  ],
};
export default accopu;
