
import Vue from 'vue';
// @ts-ignore
import MainMenuLogo from './MainMenuLogo.vue';
import MainMenuItems from './MainMenuItems.vue';

export default Vue.extend({
  components: { MainMenuLogo, MainMenuItems },
  props: {
    noToolbar: { type: Boolean, default: false },
  },
  computed: {
    navBarWidth(): string {
      return this.$store.getters.getNavBarWidth();
    },
    drawer(): boolean {
      return this.$store.getters.getNavDrawerState() && this.$vuetify.breakpoint.mdAndUp;
    },
  },
});
