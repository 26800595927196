
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      header: [
        {
          field: 'значение',
          text: 'Документ',
          type: 'Enum',
          dataModel: 'ДЛЖСписокДокументов',
          itemValue: 'ключ',
        },
      ],
    };
  },
});
