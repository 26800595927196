import Пломбы from '@/tasks/common/selections/Объекты.Пломбы';
const acts: StackSelection = {
  objectName: 'ЛицевыеСчета.СписокРаспределителей.Пломбы',
  description: 'Пломбы',
  checkRow: row => {
    if (!row.номер) {
      return 'Номер пломбы обязателен для заполнения!';
    }
    const dateust = row['дата установки'] ? row['дата установки'] : null;
    const datecheck = row['дата проверки'] ? row['дата проверки'] : null;
    if (!dateust) {
      return 'Дата установки пломбы не может быть пустой!';
    }
    if (dateust && datecheck && dateust > datecheck) {
      return 'Дата проверки не может быть меньше даты установки!';
    }
    return false;
  },
  fields: Пломбы.fields,
};
export default acts;
