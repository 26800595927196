import Vue from 'vue';
import Router from 'vue-router';
import { VueRouter } from 'vue-router/types/router';
// import 'roboto-fontface/css/roboto/roboto-fontface.css';
import './assets/fonts.css'; // шрифт Material Design
import './assets/custom.scss'; // Кастомные стили
import './assets/preloader.css';

import StackEngine from './StackEngine';

import Vuetify, {
  VBtn,
  VList,
  VListItem,
  VListItemIcon,
  VListItemContent,
  VListItemTitle,
  VIcon,
  VMenu,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VCheckbox,
  VCard,
  VCardText,
  VCardTitle,
  VCardActions,
  VContainer,
  VCol,
  VRow,
  VDialog,
  VImg,
  VProgressCircular,
  VSubheader,
  VTimeline,
  VSlideYTransition,
  VSimpleCheckbox,
  VTab,
  VTabItem,
  VTooltip,
  VOverlay,
  VScrollXTransition,
  VSwitch,
  // @ts-ignore
} from 'vuetify/lib';

// @ts-ignore
import { Resize, Ripple, Intersect } from 'vuetify/lib/directives';
import ru from 'vuetify/src/locale/ru';
import { icons } from './themes/icons';
import colorTheme from './themes/jkh';

export function mountApp(router: VueRouter, store: any, App: any) {
  const opts: any = {
    lang: { locales: { ru }, current: 'ru' },
    icons: {
      iconfont: '',
      values: icons,
    },
    theme: {
      themes: {
        dark: colorTheme.colors,
        light: colorTheme.colors,
      },
      light: true,
      options: {
        customProperties: true,
      },
    },
  };

  const objs = { vuetify: new Vuetify(opts) };
  Vue.use(Vuetify, {
    directives: {
      Resize,
      Ripple,
      Intersect,
    },
    components: {
      VBtn,
      VList,
      VListItem,
      VListItemIcon,
      VListItemContent,
      VListItemTitle,
      VIcon,
      VMenu,
      VExpansionPanel,
      VExpansionPanelHeader,
      VExpansionPanelContent,
      VCheckbox,
      VCard,
      VCardText,
      VCardTitle,
      VCardActions,
      VContainer,
      VCol,
      VRow,
      VDialog,
      VImg,
      VProgressCircular,
      VSubheader,
      VTimeline,
      VSlideYTransition,
      VSimpleCheckbox,
      VTab,
      VTabItem,
      VTooltip,
      VOverlay,
      VScrollXTransition,
      VSwitch,
    },
  });

  Vue.use(StackEngine);
  Vue.use(Router);

  new Vue({
    router,
    store,
    ...objs,
    render: h => h(App),
  }).$mount('#app');
}
