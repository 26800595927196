
import Vue from 'vue';

export default Vue.extend({
  props: {
    asyncId: { type: [String, Number], default: undefined },
  },
  computed: {
    status(): any {
      if (this.asyncId) {
        const StatusInfo = this.$store.getters.getMsgActionByID(this.asyncId);
        if (StatusInfo) {
          return StatusInfo;
        } else {
          return { title: 'Подготовка данных', status: 'Пожалуйста, ждите ...' };
        }
      }
      return { title: 'Подготовка данных', status: 'Пожалуйста, ждите ...' };
    },
  },
  watch: {
    status(to) {
      let Status = '';
      const StatusInfo = to;
      if (StatusInfo) {
        if (StatusInfo.complete) {
          if (StatusInfo.files && StatusInfo.files.length !== 0) {
            this.$stackMsg([
              {
                текст: `Сформирован отчет по обновлению сумм задолженности`,
                варианты: ['Ок'],
              },
            ]);
            Status = 'Сформирован отчет по обновлению сумм задолженности';
          } else {
            Status = 'Нет обновленных сумм';
          }
          this.$store.commit('MSG_SET_STATUS', {
            asyncId: this.asyncId,
            to: StatusInfo.to,
            complete: StatusInfo.complete,
            status: Status,
            percent: 100,
          });
          this.$emit('save', StatusInfo);
        }
      }
    },
  },
});
