
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
    params: { type: Object, default: null },
  },
  data() {
    return {
      item: {
        ЗаМесяц: this.$store.getters.getWorkMonth(),
        РасчетныйМесяцКлюч: 0,
        РасчетныйМесяц: '',
        Ведомость: this.value.ведомость,
        'Вызов из веба': true,
      },
    };
  },
  methods: {
    onChangePerMonth() {
      this.item.РасчетныйМесяцКлюч = 0;
    },
    onUpdateDivMonth(item: StackTableRow) {
      if (item && item.значение) {
        this.item.РасчетныйМесяц = item.значение as string;
      }
    },
  },
});
