const accuk: StackSelection = {
  objectName: 'ЛицевыеСчета.УК',
  description: 'Управляющие компании',
  fields: [
    {
      field: 'организация-укдоговор>название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'услуга-укдоговор>номер услуги',
      text: 'Номер услуги',
      type: 'String',
    },
    {
      field: 'услуга-укдоговор',
      text: 'Услуга',
      type: 'Link',
    },
    {
      field: 'дом-укдоговор>номер',
      text: 'Номер договора',
      type: 'String',
    },
    {
      field: 'дом-укдоговор>датнач',
      text: 'Дата договора',
      type: 'Date',
    },
    {
      field: 'дом-укдоговор',
      text: 'УК',
      type: 'Link',
    },
    {
      field: 'датнач',
      text: 'Дата начала',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Дата окончания',
      type: 'Date',
    },
    {
      field: 'принимаетвседолги',
      text: 'Принимает все долги от УК',
      type: 'Number',
    },
    {
      field: '@долгдатнач',
      text: 'Дата начала долга',
      type: 'Date',
    },
    {
      field: '@долгдаткнц',
      text: 'Дата окончания долга',
      type: 'Date',
    },
    {
      field: '@2номер',
      text: 'Номер договора должника',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'вид договора',
      text: 'Вид договора',
      type: 'Number',
    },
  ],
};
export default accuk;
