const acts: StackSelection = {
  recordRoute: 'operations-checklists-checklistsid',
  objectName: 'РеестрПроверокГис',
  description: 'Реестры проверок данных',
  hier: true,
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.$этоПапка) {
      // @ts-ignore
      row.$data.название = { $иконка: '$vuetify.icons.folder' };
    }
    if (row.проверка) {
      row.$этоПапка = false;
    }
    return row;
  },
  fields: [
    {
      field: 'ид_проверка',
      text: 'ид_проверка',
      type: 'Number',
    },
    {
      field: 'количество',
      text: 'Количество',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'Number',
    },
    {
      field: 'дата проверки',
      text: 'Дата проверки',
      type: 'String',
      align: 'center',
    },
    {
      field: 'связь',
      text: 'Связь',
      type: 'String',
    },
    {
      field: 'счетчик',
      text: 'Количество',
      type: 'Number',
    },
    {
      field: 'индикатор',
      text: 'Индикатор',
      type: 'Number',
    },
    {
      field: 'связь',
      text: 'связь',
      type: 'String',
    },
  ],
};
export default acts;
