
import Vue from 'vue';

export default Vue.extend({
  props: {
    disabled: { type: Boolean, required: true },
    accounts: { type: String, default: null },
    month: { type: String, default: null },
  },
  computed: {
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
    printready(): boolean {
      if (!this.lastPrintSettings || this.isRunning || this.disabled) {
        return true;
      }
      return this.pathKvit === undefined;
    },
    disabledFormatFile(): boolean {
      return !this.item.КудаПечатать;
    },
    disabledPrint(): boolean {
      return !this.item.Фонд || this.isRunning || this.disabled;
    },
  },
  data() {
    return {
      additionalShablons: [] as StackTableRow[],
      headers: [
        {
          field: 'название',
          text: 'Название',
          type: 'String',
          width: '500px',
          readonly: true,
        },
        {
          field: 'шаблон',
          text: 'Шаблон',
          type: 'String',
          readonly: true,
        },
      ],
      item: {
        Месяц: this.month || this.$store.getters.getWorkMonth(),
        Принтер: '',
        Фонд: '',
        ОбщПечать: 1,
        Индекс: null,
        Доставщик: null,
        ДополнительнаяПечать: {} as any,
        КудаПечатать: 0,
        КаталогКвитанций: '',
        флФорматФайла: 0,
        ДетализацияРеестра: false,
        ВидимостьРеестра: 0,
        ТипПечати: 0,
      },
      lastPrintSettings: false,
      jobID: null as null | string,
      pathKvit: undefined as StackResultAsFile | undefined,
      needReturnArchive: false,
      isRunning: false,
    };
  },
  async created() {
    const data = await new this.$HttpModel('МассоваяПечатьКвитанций').getRecords();
    this.item.КаталогКвитанций = data && data[0] ? (data[0].каталогквитанций as string) : '';
    this.item.ВидимостьРеестра = data && data[0] ? (data[0].видимостьреестра as number) : 0;
    const shablons = await new this.$HttpModel('МассоваяПечатьКвитанций.ДополнительнаяПечать').getRecords();
    this.additionalShablons = shablons;
    this.item.ДополнительнаяПечать = shablons;
    this.item.Фонд = this.accounts ? this.accounts : '';
    this.item.Месяц = this.month ? this.month : this.$store.getters.getWorkMonth();
  },
  methods: {
    async printKvit() {
      this.isRunning = true;
      let parameters = { method: 'печать' };
      parameters = Object.assign(parameters, {}, this.item);
      this.lastPrintSettings = true;
      try {
        if (await new this.$HttpModel('МассоваяПечатьКвитанций').executeMethod('ПроверкаПечати', parameters)) {
          this.isRunning = false;
          return;
        }
      } catch {
        this.isRunning = false;
        return;
      }
      this.jobID = (
        await new this.$HttpModel('МассоваяПечатьКвитанций').executeMethod('СохранитьФайл', parameters, {
          async: true,
          jobName: 'Печать квитанций',
          type: this.item.КудаПечатать ? 'multi' : 'report',
        })
      ).asyncId;
      this.pathKvit = undefined;
      this.isRunning = true;
      this.needReturnArchive = false;
      // Если запустили печать с параметрами печатать в файл и вернуть архив
      if (this.item.КудаПечатать && this.item.ТипПечати) {
        this.needReturnArchive = true;
      }
      this.$emit('run');
    },
    openLastKvit() {
      if (this.pathKvit) {
        window.open(this.$store.getters.getApiHostShare() + this.pathKvit.fileUrl);
      }
    },
    finishAsyncJob(result: string) {
      this.$emit('end', result);
      this.isRunning = false;
      this.jobID = null;
    },
  },
  watch: {
    item: {
      deep: true,
      handler(to) {
        this.lastPrintSettings = false;
      },
    },
    'item.КудаПечатать'(to) {
      if (!to) {
        this.item.ДетализацияРеестра = false;
      }
    },
    accounts(to) {
      this.item.Фонд = to;
    },
    month(to) {
      this.item.Месяц = to;
    },
    async isComplete(to) {
      if (to) {
        if (this.needReturnArchive) {
          const asyncId = (
            await new this.$HttpModel('МассоваяПечатьКвитанций').executeMethod('ВернутьАрхив', { гуид: this.jobID }, {
              async: true,
              jobName: 'Подготовка архива для скачивания',
              onComplete: () => {
                const files = this.$store.getters.getMsgActionByID(asyncId);
                this.$store.commit('MSG_SET_STATUS', { asyncId: this.jobID, type: 'report', files });
                this.finishAsyncJob('success');
              },
              onError: () => {
                this.finishAsyncJob('error');
              },
            })
          ).asyncId;
        } else {
          // TODO Пакет файлов
          this.pathKvit = this.resultJob.files ? this.resultJob.files[0] : undefined;
          this.finishAsyncJob('success');
        }
      }
    },
    isError(to) {
      if (to) {
        this.isRunning = false;
        this.$emit('end', 'error');
      }
    },
    async jobID(to, from) {
      // если была запущена печать в архив и сменился jobID - то мы должны удалить архив на сервере,
      // чтобы не оставлять за собой мусора и снять флаг multi, чтобы не могли перезапускать процессы
      if (from && this.needReturnArchive) {
        this.$store.commit('MSG_SET_STATUS', { asyncId: from, data: {} });
        await new this.$HttpModel('МассоваяПечатьКвитанций').executeMethod('ВернутьАрхив', { гуид: this.jobID, фТолькоУдалитьПапку: true });
      }
    },
  },
});
