
import Vue, { PropType } from 'vue';
import closeDialog from './closeDialog.vue';
import { ReportsBuilder } from '@/stackEngine';

export default Vue.extend({
  components: { closeDialog },
  props: {
    params: { type: Object, default: undefined },
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    selectedValues: { type: [String, Number], default: undefined },
    selectedItems: { type: Array as PropType<StackTableRow[]>, default: undefined },
  },
  data() {
    return {
      modalDialog: false,
      jobID: null as null | number,
    };
  },
  computed: {
    disablePrint(): boolean {
      let cont = false;
      this.selectedItems.forEach((item: StackTableRow) => {
        if (item.$этоПапка) {
          cont = true;
        }
      });
      return !this.selectedValues || cont;
    },
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
  },
  methods: {
    // запуск построителя отчета
    async buildReport(reportName: string) {
      const reportParams = {
        'Список лицевых': +this.selectedValues,
        номерЗаписи: +this.selectedValues,
        имяВыборки: this.dataObject.model,
        'Вызов из веба': true,
      };

      await new ReportsBuilder(reportName).executeReport2(reportName, {}, { params: reportParams });
    },
    async checkCash() {
      await new this.$HttpModel('СоглашенияОРассрочке').executeMethod('ПроверитьОплаты', {}, { async: true, jobName: 'Проверка Оплаты', type: 'func' });
    },
    async closeAgreement(records: any) {
      this.modalDialog = false;
      this.jobID = (
        await new this.$HttpModel('СоглашенияОРассрочке.ПогашенныеСоглашения').executeMethod(
          'ЗакрытьСоглашения',
          { записи: records },
          { async: true, jobName: 'Закрытие соглашений о рассрочке', type: 'func' },
        )
      ).asyncId;
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.tableAction({ action: 'reload' });
      }
    },
    isError(to) {
      if (to) {
        this.$store.commit('MSG_DELETE', this.jobID);
        this.jobID = null;
      }
    },
  },
});
