const orgs: StackSelection = {
  recordRoute: 'references-dialogOrganizationRS',
  objectName: 'Организации.РасчетныеСчета',
  description: '',
  fields: [
    {
      field: 'n п/п',
      text: 'N п/п',
      type: 'Number',
    },
    {
      field: 'р/счет',
      text: 'Расчетный счет',
      type: 'Number',
    },
    {
      field: 'банк-р/с>название банка',
      text: 'Название банка',
      type: 'String',
    },
    {
      field: 'банк-р/с>адрес банка',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'лица-р/с',
      text: 'Лица-Р/с',
      type: 'Link',
    },
    {
      field: 'банк-р/с',
      text: 'Банк-Р/с',
      type: 'Link',
    },
    {
      field: 'фпоумолчанию',
      text: 'Использовать по умолчанию для перечисления ДС',
      type: 'Number',
    },
  ],
};

export default orgs;
