import { Module, GetterTree, MutationTree } from 'vuex';
import { store } from '.';
import { Route } from 'vue-router';

export interface BreadcrumbsItem {
  title?: string;
  icon?: string;
  to: string | Route;
  maxlvl?: number;
  params?: object;
}

interface TaskState {
  task: string; // задача (fl,ul...)
  mainMenuItems: TaskItem[]; // айтемы главного меню
  breadcrumbsItems: BreadcrumbsItem[]; // айтемы хлебных крошек
  name: string;
  description: string;
  htmlTitle: string;
  externalTask: boolean;
  imagePack: ImagePack;
  helpUrl: string | undefined;
}

const state: TaskState = {
  task: '',
  mainMenuItems: [] as TaskItem[], // пункты главного меню
  breadcrumbsItems: [{ to: '/', title: '', icon: '$vuetify.icons.home', maxlvl: 0 }],
  name: 'не указана',
  description: '',
  htmlTitle: '',
  externalTask: false,
  imagePack: [],
  helpUrl: undefined,
};

const getters: GetterTree<TaskState, any> = {
  // префикс текущей задачи комплекса
  getCurrentTask: (state: TaskState) => () => {
    return state.task;
  },

  isCurrentTaskExternal: (state: TaskState) => () => {
    return state.externalTask;
  },
  // имя текущей задачи комплекса
  getCurrentTaskName: (state: TaskState) => () => {
    return state.name;
  },

  // полное название текущей задачи комплекса
  getCurrentTaskFullName: (state: TaskState) => () => {
    return state.description;
  },

  // HTML строка с названием текущей задачи
  getCurrentTaskHTMLTitle: (state: TaskState) => () => {
    return state.htmlTitle;
  },

  // хлебные крошки
  getBreadcrumbsItems: (state: TaskState) => () => {
    return store.getters.isAuth() ? state.breadcrumbsItems : [];
  },

  // Список главного меню
  getMainMenuItems: (state: TaskState) => () => {
    return store.getters.isAuth() ? state.mainMenuItems : [];
  },

  // Картинки исп в программе
  getImagePack: (state: TaskState) => () => {
    return state.imagePack;
  },

  // Online help
  getHelpUrl: (state: TaskState) => () => {
    return state.helpUrl;
  },
};

const mutations: MutationTree<TaskState> = {
  SET_TASK_CONFIG(state: TaskState, taskConfig: Task) {
    state.name = taskConfig.name;
    state.description = taskConfig.description;
    state.task = taskConfig.prefix;
    state.externalTask = !!taskConfig.externalTask;
    if (taskConfig.htmltitle) {
      state.htmlTitle = taskConfig.htmltitle;
    }
    if (taskConfig.imagePack) {
      state.imagePack = taskConfig.imagePack;
    }
    // установка главного меню
    if (taskConfig.menu) {
      store.commit('SET_MENU_ITEMS', taskConfig.menu);
    }
    // установка ссылки на онлайн документацию
    if (taskConfig.helpUrl) {
      state.helpUrl = taskConfig.helpUrl;
    }
  },
  ADD_IMAGE_TO_PACK(state: TaskState, options: { name: string; image: any }) {
    state.imagePack[options.name] = options.image;
  },

  // устанавливаем главное меню
  SET_MENU_ITEMS(state: TaskState, menuItems: TaskItem[]) {
    state.mainMenuItems.length = 0;
    state.mainMenuItems.push(...menuItems.filter(item => item.menu));
  },

  // добавляем хлебную крошку
  ADD_BREADCRUMB_ITEM(state: TaskState, item: BreadcrumbsItem) {
    if (item.maxlvl !== undefined) {
      store.commit('CLEAR_BREADCRUMB_ITEM', item.maxlvl);
    }
    let foundOne = false;
    state.breadcrumbsItems.forEach((bread: BreadcrumbsItem) => {
      if (bread.to === item.to) {
        bread.title = item.title;
        bread.to = item.to;
        foundOne = true;
      }
    });
    if (!foundOne) {
      state.breadcrumbsItems.push(item);
    }
  },

  // Зачищаем всё, что идёт после текущего url
  SHRINK_BREADCRUMB_ITEMS(state: TaskState, to: string | Route) {
    state.breadcrumbsItems.forEach((bread: BreadcrumbsItem, index: number) => {
      if (bread.to === to) {
        state.breadcrumbsItems.splice(index + 1);
      }
    });
  },

  // чистим последний элемент
  POP_BREADCRUMB_ITEM(state: TaskState) {
    if (state.breadcrumbsItems.length > 0) {
      state.breadcrumbsItems.pop();
    }
  },

  // очищаем хлебные крошки до определенной позиции endInd
  CLEAR_BREADCRUMB_ITEM(state: TaskState, endInd = 1) {
    if (state.breadcrumbsItems.length > endInd) {
      state.breadcrumbsItems.length = endInd;
    }
  },
};

const taskState: Module<TaskState, any> = {
  state,
  getters,
  mutations,
};

export default taskState;
