import { Module, MutationTree, GetterTree, ActionTree } from 'vuex';
import { Location } from 'vue-router/types/router';
import { StackApi } from '../middleware/StackApi'; // для общения с бэкэндом

interface StackUserUrlPermission {
  url: string;
  permission: boolean;
  isRegExp: boolean;
}

interface StackUserUrlPermissions {
  all: boolean; // Глобальный флаг разрешен или запрещен доступ
  urls: StackUserUrlPermission[];
}

interface RoutesState {
  permissions: StackUserUrlPermissions; // Объект с правами на роуты
  routeHistoryCount: number; // Кол-во переходов
}

interface StackTablePermissionRow extends StackTableRow {
  разрешен: number;
  ресурс: string;
}

const state: RoutesState = {
  permissions: {
    all: false,
    urls: [],
  },
  routeHistoryCount: 0,
};

// Геттеры
const getters: GetterTree<RoutesState, any> = {
  // возвращает имя компонента для перехода
  // TODO Выпилить ?
  getRouteName: () => (to: string | Location) => {
    const name = typeof to === 'object' ? to.name : to;
    return typeof to === 'object' ? Object.assign(to, { name }) : name;
  },
  // Проверяет право доступа роута
  checkRoutePermission: (state: RoutesState) => (route: string) => {
    const all = state.permissions.all;
    for (const perm of state.permissions.urls) {
      if (perm.permission !== all) {
        if (perm.isRegExp) {
          if (RegExp(perm.url, 'g').test(route)) {
            return perm.permission;
          }
        } else if (perm.url === route) {
          return perm.permission;
        }
      }
    }
    return all;
  },
};

const mutations: MutationTree<RoutesState> = {
  INC_ROUTE_HISTORY(state: RoutesState) {
    state.routeHistoryCount++;
  },
  SET_USER_PERMISSIONS(state: RoutesState, permissions: StackUserUrlPermissions) {
    state.permissions = permissions;
  },
};

const actions: ActionTree<RoutesState, any> = {
  async getUserRights({ commit }) {
    // console.log('Получаем список прав доступа...');
    const rights = (await new StackApi().getAccessRights()) as StackTablePermissionRow[];
    const permissions: StackUserUrlPermissions = { all: false, urls: [] };
    rights && rights.forEach(perm => {
      if (perm?.ресурс === '*') {
        permissions.all = !!perm.разрешен;
      } else {
        if (perm.ресурс) {
          let url = perm.ресурс.toString().substring(4).trim();
          if (url === '*') {
            permissions.all = !!perm.разрешен;
          } else {
            const isRegExp = url.indexOf('*') >= 0;
            if (isRegExp) {
              url = url.replaceAll('*', '.*');
            }
            permissions.urls.push({
              url,
              permission: !!perm.разрешен,
              isRegExp,
            });
          }
        }
      }
    });
    commit('SET_USER_PERMISSIONS', permissions);
  },
};

const RoutesStore: Module<RoutesState, any> = {
  state,
  getters,
  mutations,
  actions,
};

export default RoutesStore;
