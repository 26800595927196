const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.СудебныеАкты',
  description: 'Судебные акты',
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'DateMonth',
      align: 'right',
    },
    {
      field: '@госпошлина',
      text: 'Госпошлина',
      type: 'Money',
    },
    {
      field: '@пени',
      text: 'Пени',
      type: 'Money',
    },
    {
      field: '@издержки',
      text: 'Издержки',
      type: 'Money',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
  ],
};
export default accs;
