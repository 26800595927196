
import Vue from 'vue';

export default Vue.extend({
  props: {
    idLs: { type: Number, default: null },
    idDoc: { type: Number, default: null },
  },
  computed: {
    withoutRegistration(): boolean {
      const typeOfRegistration = this.record['вид регистрации'] as number;
      return typeOfRegistration === 3;
    },
  },
  data() {
    return {
      record: {
        'человек-лицевой': this.idLs as number,
        'человек-карточка': this.idDoc as number,
        'вид регистрации': 0,
        'дата прописки': null,
        расчетныепризнаки: 0,
      } as StackTableRow,
    };
  },

  methods: {
    async onSave() {
      const http = new this.$HttpModel('ЧастныеЛица.ДокументыНаВладение');
      await http.executeMethod('ДобавитьКарточкуРегистрации', { запись: this.record });
      this.$emit('close');
      this.$sendMsg('stack-dialog', 'ЧастныеЛица', 'reload');
      this.$sendMsg('stack-table', 'ЛицевыеСчета.КарточкиРегистрации', 'reload');
    },
    onChangeRegistration() {
      const typeOfRegistration = this.record['вид регистрации'] as number;
      if (typeOfRegistration !== 3) {
        this.record.расчетныепризнаки = 0;
      }
    },
  },
});
