const acts: StackSelection = {
  objectName: 'РеестрПроверокГис.Проверки',
  description: 'Результаты проверок',
  fields: [
    {
      field: 'количество',
      text: 'Количество',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'дата проверки',
      text: 'Дата проверки',
      type: 'String',
    },
    {
      field: 'ид_объект',
      text: 'ид_объект',
      type: 'Number',
    },
    {
      field: 'связь',
      text: 'связь',
      type: 'String',
    },
    {
      field: 'ид_родитель',
      text: 'ид_родитель',
      type: 'Number',
    },
    {
      field: 'рсвязь',
      text: 'рсвязь',
      type: 'String',
    },
  ],
};
export default acts;
