
import Vue from 'vue';
import ParamFilter from './$detailTypes/ParamFilter.vue';
import LsFilter from './$detailTypes/LsFilter.vue';
import CountFilter from './$detailTypes/CountFilter.vue';
import OrgFilter from './$detailTypes/OrgFilter.vue';

export default Vue.extend({
  components: { ParamFilter, LsFilter, CountFilter, OrgFilter },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'Детализация фильтра' },
  },
  methods: {
    onTypeChange() {
      // this.record.значение = 0;
    },
  },
});
