
import Vue from 'vue';
import addressFilter from '@/tasks/fl/views/$common/filters/addressFilter.vue';

export default Vue.extend({
  name: 'FilterPoint',
  components: { addressFilter },
  props: {
    params: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    noShowAdr: { type: Boolean, default: false }, // не отображаем поиск по адресу
    noShowNumber: { type: Boolean, default: false }, // не отображать поиск по номеру ту
  },
  data() {
    return {
      dataParameters: {} as HttpModel,
      значенияпараметра: [] as any,
      loading: false,
    };
  },
  computed: {
    typeOfConsItems(): StackTableRow[] {
      return this.$store.getters['flStore/getComplexTypes']();
    },
    dataModel46(): string {
      if (this.$store.getters['flStore/typeConsumption']('ЖКХ', this.params.ВидПотребленияТУ)) {
        return 'ЛицевыхАналитики';
      }
      if (this.$store.getters['flStore/typeConsumption']('ЭНЕРГО', this.params.ВидПотребленияТУ)) {
        return 'ЛицевыхАналитики';
      }
      if (this.$store.getters['flStore/typeConsumption']('РСО', this.params.ВидПотребленияТУ)) {
        return 'ЛицевыхАналитикиТЭ';
      }
      if (this.$store.getters['flStore/typeConsumption']('ТКО', this.params.ВидПотребленияТУ)) {
        return 'ЛицевыхАналитикиФККО';
      }
      return 'ЛицевыхАналитики';
    },
  },
  methods: {
    async fetchData(dataObject: HttpModel, params: any) {
      const items = [];
      const data = (await dataObject.getRecords(params)) as StackTableEnumRow[];
      for (const key in data) {
        data[key].$номерЗаписи = data[key].ключ;
        items.push(data[key]);
      }
      return items;
    },
    parSelect(params: any) {
      let parName = '';
      for (const par in params) {
        parName = params[par].название;
      }
      this.params.ЗначениеПарТУ = null;
      if (parName) {
        this.fetchData(this.dataParameters, { name: parName }).then((res: any) => {
          this.значенияпараметра = res;
        });
      }
    },
    onChangeComplex() {
      this.params.нзФ46 = -1;
    },
    async onJump() {
      const parameters = {} as any;
      let foundOne = false;
      for (const field in this.params) {
        if (this.params[field] && (field === 'нп' || field === 'улица' || field === 'дом' || field === 'квартира')) {
          parameters[field] = this.params[field];
          foundOne = true;
        }
      }
      if (!foundOne) {
        this.$toast('Переход возможен только при указании адреса');
        return;
      }
      this.loading = true;
      const params = { фильтр: Object.assign(parameters, {}, { развернутьПапки: 1, сКорня: 1, переход: true }) };
      const results = await new this.$HttpModel('ЛицевыеСчета').getRecords(params);
      if (results.length === 1) {
        const rec: StackTableRow = results[0];
        this.tableAction({ action: 'filter', payload: { row_id: rec.$номерЗаписи } });
      } else {
        this.$toast('Найдено несколько адресов. Уточните поиск!');
      }
      this.loading = false;
    },
  },
  created() {
    this.dataParameters = new this.$HttpModel('Договоры.Фильтр.ЗначенияПараметра');
  },
});
