
import Vue from 'vue';
import ReadingInputDialog from '@/tasks/fl/views/$common/$readingdialog/ReadingInputDialog.vue';

export default Vue.extend({
  components: { 'ReadingInput-Dialog': ReadingInputDialog },
  props: {
    param: { type: Object, required: true },
  },
  data() {
    return {
      readinginput: false,
      oneclick: false,
      record: {
        НомерАкта: '',
        Сумма: 0,
        Примечание: '',
        Месяц: this.$stackDate.firstDay(this.param.датнач),
        Дата: this.param.датнач,
        ТипПоказания: 1,
      },
    };
  },
  async created() {
    let dop = [];
    const res = await new this.$HttpModel('ОперацииЕдиничногоПерехода').executeMethod('НачальныеПараметры', { состояние: this.param.$номерЗаписи, тип: 'АктОтключения' });
    if (res) {
      dop = JSON.parse(res);
      this.record.НомерАкта = dop[0].номеракта;
      this.record.Сумма = dop[0].сумма;
      this.record.ТипПоказания = dop[0].типввода;
    }
  },
});
