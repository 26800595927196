import { Module, MutationTree, GetterTree } from 'vuex';
import { HttpModel, store, stackAppEmitter } from '@/stackEngine';

export interface FLState {
  lsHierTypes: any; // Доступные узлы иерархии в лицевых
  serviceTypes: any; // Типы услуг и номера групп из НУПОК
  startDataMonth: Date | null; // первый месяц в котором есть начисления
  firstWorkMonth: Date | null; // первый рабочий месяц в программе
  debtMonth: Date | null; // первый рабочий месяц в программе
  complexTypes: StackTableRow[]; // доступные виды потребления
  complexType: number; // Тип комлпекса
  showlssaldomode: boolean;// конфиг showlssaldomode
  showcalcbenefit: boolean;// конфиг showcalcbenefit
  showcontactcenter: boolean; // есть ли контакт-центр
  onrecalc: boolean;// конфиг onrecalc
  considerplombs: boolean;// учет пломб
  considerblanks: boolean;// учет бланков
  calcbypassport: boolean;// конфиг calcbypassport
  multythreads: number; // константа КОЛВОПОТОКОВ
  showhistory: boolean;// конфиг экспл. параметра ShowHistory
  extdocsavemode: number;// признак куда по умолчанию сохраняем файлы
  lossesel: boolean;
}

const state: FLState = {
  lsHierTypes: {},
  serviceTypes: {},
  startDataMonth: null,
  firstWorkMonth: null,
  debtMonth: null,
  complexTypes: [],
  complexType: 0,
  showlssaldomode: false,
  showcalcbenefit: false,
  showcontactcenter: false,
  onrecalc: false,
  considerplombs: false,
  considerblanks: false,
  calcbypassport: false,
  multythreads: 0,
  showhistory: false,
  extdocsavemode: 0,
  lossesel: false,
};

// Геттеры
const getters: GetterTree<FLState, any> = {
  // Доступные узлы иерархии в лицевых
  getlsHierTypes: (state: FLState) => () => {
    return state.lsHierTypes;
  },
  getstartDataMonth: (state: FLState) => () => {
    return state.startDataMonth;
  },
  getfirstWorkMonth: (state: FLState) => () => {
    return state.firstWorkMonth;
  },
  // getdebtMonth: (state: FLState) => () => {
  //   return state.debtMonth;
  // },
  getServiceTypeIcon: (state: FLState) => (service: number) => {
    // @ts-ignore
    switch (state.serviceTypes[service]) {
      case 'ОТ':
        return '$vuetify.icons.pu_heat';
      case 'ХВ':
      case 'ГВ':
        return '$vuetify.icons.pu_water';
      case 'ГАЗ':
        return '$vuetify.icons.pu_gas';
      case 'ЭЭ':
        return '$vuetify.icons.pu_el';
      default:
        break;
    }
    return '';
  },
  getServiceTypeIconColor: (state: FLState) => (service: number) => {
    // @ts-ignore
    switch (state.serviceTypes[service]) {
      case 'ОТ':
      case 'ЭЭ':
        return 'orange';
      case 'ХВ':
        return 'blue';
      case 'ГВ':
      case 'ГАЗ':
        return 'red';
      default:
        break;
    }
    return '';
  },
  getComplexTypes: (state: FLState) => () => {
    return state.complexTypes;
  },
  getComplexKey: (state: FLState) => (value: number) => {
    const pos = state.complexTypes.find((el: StackTableRow) => {
      if (el && el.описание) {
        return el.ключ === value;
      } else {
        return false;
      }
    });
    return pos === undefined ? undefined : pos.описание;
  },
  typeConsumption: (state: FLState) => (key: string, value: number) => {
    const pos = state.complexTypes.find((el: StackTableRow) => {
      if (el && el.описание) {
        return el.описание.toString().toLowerCase() === key.toLowerCase() && el.ключ === value;
      } else {
        return false;
      }
    });
    return pos !== undefined;
  },
  isComplex: (state: FLState) => (key: string) => {
    const pos = state.complexTypes.find((el: StackTableRow) => {
      if (el && el.описание) {
        return el.описание.toString().toLowerCase() === key.toLowerCase();
      } else {
        return false;
      }
    });
    return pos && (pos.ключ === state.complexType || pos.описание === 'ЖКХ');
  },
  getLSSaldoMode: (state: FLState) => () => {
    return state.showlssaldomode;
  },
  getCalcBenefit: (state: FLState) => () => {
    return state.showcalcbenefit;
  },
  getContactCenter: (state: FLState) => () => {
    return state.showcontactcenter;
  },
  getOnRecalc: (state: FLState) => () => {
    return state.onrecalc;
  },
  getConsiderPlombs: (state: FLState) => () => {
    return state.considerplombs;
  },
  getConsiderBlanks: (state: FLState) => () => {
    return state.considerblanks;
  },
  getCalcByPassport: (state: FLState) => () => {
    return state.calcbypassport;
  },
  getMultyThreads: (state: FLState) => () => {
    return state.multythreads;
  },
  getShowHistory: (state: FLState) => () => {
    return state.showhistory;
  },
  getExtDocSaveMode: (state: FLState) => () => {
    return state.extdocsavemode;
  },
  getLossesEl: (state: FLState) => () => {
    return state.lossesel;
  },
};

const mutations: MutationTree<FLState> = {
  // устанавливаем наборы доступных узлов иерархии в лицевых
  SET_LS_HIER_TYPES(state: FLState, types: object) {
    state.lsHierTypes = types;
  },
  SET_SERVICE_TYPES(state: FLState, types: object) {
    state.serviceTypes = types;
  },
  SET_START_DATA_MONTH(state: FLState, startDataMonth: Date) {
    state.startDataMonth = startDataMonth;
  },
  SET_FIRST_WORK_MONTH(state: FLState, firstWorkMonth: Date) {
    state.firstWorkMonth = firstWorkMonth;
  },
  SET_DEBT_MONTH(state: FLState, debtMonth: Date) {
    state.debtMonth = debtMonth;
  },
  SET_COMPLEX_TYPES(state: FLState, types: StackTableRow[]) {
    state.complexTypes = types;
  },
  SET_COMPLEX_TYPE(state: FLState, type: number) {
    state.complexType = type;
  },
  SET_SHOWLSSALDOMODE(state: FLState, type: boolean) {
    state.showlssaldomode = type;
  },
  SET_SHOWCALCBENEFIT(state: FLState, type: boolean) {
    state.showcalcbenefit = type;
  },
  SET_SHOWCONTACTCENTER(state: FLState, type: boolean) {
    state.showcontactcenter = type;
  },
  SET_ONRECALC(state: FLState, type: boolean) {
    state.onrecalc = type;
  },
  SET_CONSIDERPLOMBS(state: FLState, type: boolean) {
    state.considerplombs = type;
  },
  SET_CONSIDERBLANKS(state: FLState, type: boolean) {
    state.considerblanks = type;
  },
  SET_CALCBYPASSPORT(state: FLState, type: boolean) {
    state.calcbypassport = type;
  },
  SET_MULTYTHREADS(state: FLState, type: number) {
    state.multythreads = type;
  },
  SET_SHOWHISTORY(state: FLState, type: boolean) {
    state.showhistory = type;
  },
  SET_EXTDOCSAVEMODE(state: FLState, mode: number) {
    state.extdocsavemode = mode;
  },
  SET_LOSSESEL(state: FLState, mode: boolean) {
    state.lossesel = mode;
  },
};

const flStore: Module<FLState, any> = {
  namespaced: true,
  state,
  getters,
  mutations,
};

store.registerModule('flStore', flStore);

async function loadHier() {
  const data = await new HttpModel('ДоступныеУровниИерархии').getRecords();
  const lsHierTypes = [] as any;

  data.forEach((type: any) => {
    const values = type.значение.split(',');
    const defs = type.описание.split(',');
    const items = [] as any;

    for (let index = 0; index < values.length; index++) {
      items.push({ title: defs[index], type: values[index] });
    }

    lsHierTypes.push({ type: type.ключ, items });
  });
  store.commit('flStore/SET_LS_HIER_TYPES', lsHierTypes);
}
async function loadStartDataMonth() {
  const data = await new HttpModel('ПрочитатьЭксплуатационныеПараметры').getRecords();
  const operationParameters = data && data[0] ? data[0] : undefined;
  if (operationParameters) {
    store.commit('flStore/SET_SERVICE_TYPES', JSON.parse(operationParameters.servicetype as string));
    store.commit('flStore/SET_START_DATA_MONTH', operationParameters.startdatamonth);
    store.commit('flStore/SET_FIRST_WORK_MONTH', operationParameters.firstworkmonth);
    store.commit('flStore/SET_DEBT_MONTH', operationParameters.debtmonth);
    store.commit('flStore/SET_COMPLEX_TYPE', operationParameters.complextype as number);
    store.commit('flStore/SET_SHOWLSSALDOMODE', operationParameters.showlssaldomode ? Boolean(operationParameters.showlssaldomode) : false);
    store.commit('flStore/SET_SHOWCALCBENEFIT', operationParameters.showcalcbenefit ? Boolean(operationParameters.showcalcbenefit) : false);
    store.commit('flStore/SET_SHOWCONTACTCENTER', operationParameters.showcontactcenter ? Boolean(operationParameters.showcontactcenter) : false);
    store.commit('flStore/SET_ONRECALC', operationParameters.onrecalc ? Boolean(operationParameters.onrecalc) : false);
    store.commit('flStore/SET_CONSIDERPLOMBS', operationParameters.considerplombs ? Boolean(operationParameters.considerplombs) : false);
    store.commit('flStore/SET_CONSIDERBLANKS', operationParameters.considerblanks ? Boolean(operationParameters.considerblanks) : false);
    store.commit('flStore/SET_CALCBYPASSPORT', operationParameters.calcbypassport ? Boolean(operationParameters.calcbypassport) : false);
    store.commit('flStore/SET_MULTYTHREADS', operationParameters.multythreads as number);
    if (operationParameters.changelogversion) {
      store.commit('SET_CURRENT_HISTORY_VERSION', operationParameters.changelogversion);
    }
    store.commit('flStore/SET_SHOWHISTORY', operationParameters.showhistory ? Boolean(operationParameters.showhistory) : false);
    store.commit('flStore/SET_EXTDOCSAVEMODE', operationParameters.extdocsavemode);
    store.commit('flStore/SET_LOSSESEL', operationParameters.lossesel);
  }
}
async function loadComplex() {
  const res = await new HttpModel('ВидыПотребленияКомплекса').getRecords();
  store.commit('flStore/SET_COMPLEX_TYPES', res);
}

async function fillStore() {
  await Promise.all([loadComplex(), loadHier(), loadStartDataMonth()]);
}

stackAppEmitter.on('APP_USER_SIGN_IN', async () => {
  await fillStore();
}, 'Загрузка данных пользователя...');
