
import Vue from 'vue';
import { ReportsBuilder } from '@/stackEngine';

export default Vue.extend({
  props: {
    dataObject: { type: Object, required: true },
    selectedValues: { type: [String, Number], default: undefined },
    selectedItems: { type: Array, required: true },
  },
  computed: {
    getOpenMonth(): string {
      return this.$store.getters.getOpenMonth();
    },
  },
  data() {
    return {
      items: [] as any,
      reportParams: {},
      dialogName: '', // имя диалога для печати по f4
      reportName: '', // имя отчета для печати по f4
    };
  },
  methods: {
    async onBtnPrintClick(reportName: string) {
      await new this.$ReportsBuilder(reportName).executeReport2(
        reportName,
        { ведомость: this.selectedValues },
        {
          params: {
            ведомость: this.selectedValues,
            номерЗаписи: this.selectedValues,
            имяВыборки: 'ГрупповыеСчетчики',
          },
        },
      );
    },
    async onClick(type: string) {
      const methodName = type === 'add' ? 'ДобавитьДокрутку' : 'УдалитьДокрутку';
      const jobName = type === 'add' ? 'Докрутка ОДПУ' : 'Удаление докрутки ОДПУ';
      const answerStr = type === 'add' ? 'проведена' : 'удалена ';
      if (await this.$yesno(`Будет ${answerStr} докрутка показаний за ${this.$stackDate.format(this.getOpenMonth, 'LLLL YYYYг.')}\nВы уверены ?`)) {
        const params = {
          позиция: this.selectedValues,
        };
        this.dataObject.executeMethod(
          methodName,
          params,
          {
            async: true,
            jobName,
            onComplete: () => {
              this.refreshTable();
            },
            onError: () => {
              this.refreshTable();
            },
          },
        );
      }
    },
    async potrODPU() {
      await new this.$ReportsBuilder('Групповые счетчики - Потребление ресурсов с ОДПУ').executeReport2(
        'Групповые счетчики - Потребление ресурсов с ОДПУ',
        { ведомость: this.selectedValues },
        {
          params: {
            ведомость: this.selectedValues,
            номерЗаписи: this.selectedValues,
            имяВыборки: 'ГрупповыеСчетчики',
          },
        },
      );
    },
    refreshTable() {
      this.$sendMsg('stack-table', 'ГрупповыеСчетчики', 'reload');
    },
  },
});
