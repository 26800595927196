const acts: StackSelection = {
  objectName: 'АктыНарушенийАбонентов.Расчет',
  description: 'Расчет акта нарушения',
  fields: [
    {
      field: 'типрасчета',
      text: 'Тип',
      type: 'String',
      align: 'center',
    },
    {
      field: '@типрасчета',
      text: 'Тип расчета',
      type: 'Enum',
      align: 'center',
    },
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'Date',
      align: 'center',
    },
    {
      field: 'услуга-расчет акта>наименование',
      text: 'Услуга',
      type: 'String',
      align: 'center',
    },
    {
      field: 'объемакта',
      text: 'Объем',
      type: 'Number',
      align: 'center',
    },
    {
      field: 'суммаакта',
      text: 'Сумма',
      type: 'Number',
      align: 'center',
    },
    {
      field: 'норма',
      text: 'Норматив',
      type: 'Number',
      align: 'center',
    },
    {
      field: 'тариф',
      text: 'Тариф',
      type: 'Number',
      align: 'center',
    },
    {
      field: 'поставщик',
      text: 'ИдПоставщика',
      type: 'Link',
    },
    {
      field: 'поставщик>название',
      text: 'Поставщик',
      type: 'String',
    },
  ],
};

export default acts;
