const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ПениCальдо_исходящее',
  description: 'Исходящее сальдо пени',
  fields: [
    {
      field: 'номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'укназвание',
      text: '',
      type: 'String',
    },
    {
      field: 'сумма',
      text: 'Сумма,₽',
      type: 'Money',
    },
    {
      field: 'договорук',
      text: 'Договор',
      type: 'Number',
    },
    {
      field: 'поставщик',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'месдолга',
      text: 'Месяц долга',
      type: 'DateMonth',
    },
    {
      field: 'поставщикназвание',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'договорномер',
      text: 'Договор',
      type: 'String',
    },
  ],
};
export default accs;
