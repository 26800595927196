
import Vue from 'vue';
import { ReportsBuilder, HttpModel } from '@/stackEngine';

export default Vue.extend({
  props: {
    selectedItems: { type: [Object, Array], default: undefined }, // Array парсер
  },
  computed: {
    disableScheme(): boolean {
      return !this.selectedItems.length;
    },
  },
  data() {
    return {
      items: [] as any,
      schemeMenu: null, // видимость меню отчетов записи
      loading: false,
    };
  },
  methods: {
    // получаем список отчетов для записи
    async onSchemeMenu(menuVisible: boolean) {
      if (!menuVisible) {
        return;
      }

      if (this.selectedItems.length !== 1) {
        this.$toast('Схему можно получить только по одному каталогу!', { color: 'error' });
        return;
      }
      this.loading = true;
      const search_params = {
        нзЭлемент: +this.selectedItems[0].$номерЗаписи,
      };
      const result = await new this.$HttpModel('Сеть_ПолучитьСписокФайловСхемы').getRecords(search_params);
      const items = [] as any;
      for (const file_rec of result) {
        items.push({ title: file_rec.значение as string, name: file_rec.значение as string, scheme_file_id: file_rec.ключ as number });
      }

      this.items = items;
      if (items.length === 0) {
        this.$toast('Не найдено схемы сети для данного узла', { icon: '$vuetify.icons.error' });
      }

      this.loading = false;
    },
    downloadSchemeFile(payload: any) {
      const result = new this.$HttpModel('Сеть_ПолучитьСписокФайловСхемы').executeMethod(
        'получитьФайл',
        { fileId: +payload.scheme_file_id },
        { async: true, jobName: `Получение файла: ${payload.title} ` },
      );
    },
  },
});
