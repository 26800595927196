
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      дата: this.$store.getters.getCurrentDate(),
      датараспр: this.$store.getters.getOpenMonth(),
      месяц: this.$store.getters.getOpenMonth(),
      dataModel: new this.$HttpModel('ЕжедневноеРаспределениеОплаты'),
      runOperation: false,
      jobID: null as null | number,
      success: false,
      error: false,
    };
  },
  async created() {
    const lastDay = this.$stackDate.lastDay(this.$store.getters.getOpenMonth());
    if (this.дата > lastDay) {
      this.дата = lastDay;
    }
    const res = await new this.$HttpModel('ВедомостьРаспределенияОплаты').executeMethod('ДатаРаспределения', {});
    if (res) {
      this.датараспр = res;
    }
  },
  computed: {
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
  },
  methods: {
    async payroll() {
      if (this.$stackDate.format(this.дата, 'ISO') > this.$stackDate.lastDay(this.$store.getters.getOpenMonth()) || this.$stackDate.format(this.дата, 'ISO') < this.$stackDate.format(this.датараспр, 'ISO')) {
        return this.$toast('Введена некорректная дата распределения', { color: 'error' });
      }
      this.success = false;
      this.error = false;
      this.jobID = (
        await this.dataModel.executeMethod(
          'Распределить',
          { Дата: this.дата, ДатаПредРаспредения: this.датараспр },
          {
            async: true,
            jobName: 'Ежедневное распределение оплаты',
            type: 'multi',
          },
        )
      ).asyncId;
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.jobID = null;
        this.success = true;
        this.error = false;
      }
    },
    isError(to) {
      if (to) {
        this.jobID = null;
        this.success = false;
        this.error = true;
      }
    },
  },
});
