
import Vue from 'vue';
import Inputmask from 'inputmask';
import inputable from '../mixins/inputable';

/**
 * Инпут с датой
 */
export default Vue.extend({
  name: 'StackDateField',
  mixins: [inputable],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    floatPlaceholder: { type: Boolean, default: false },
    dataTestId: { type: String, default: undefined },
    /**
     * текущее значение в ISO\Date формате
     */
    value: { type: [String], default: null },
    /**
     * тип даты. month - если это месяц, year, если год
     */
    type: { type: String, default: 'date' },
    /**
     * не выводить иконку
     */
    noIcon: { type: Boolean, default: false },
    /**
     * может быть пустая
     */
    clearable: { type: Boolean, default: false },
    /**
     * только чтение
     */
    readonly: { type: Boolean, default: false },
    // Очищать, если введено ошибочное значение. (использ. в stackTable inline-edit)
    clearOnError: { type: Boolean, default: false },
    /**
     * не сокращать метку
     */
    noWrapLabel: { type: Boolean, default: false },
    /**
     * даты, доступные для выбора
     */
    allowedDates: { type: Function, default: () => true },
    /**
    * при валидации поле ввода не прыгает
     */
    noBounceValidation: { type: Boolean, default: false },
  },
  data() {
    return {
      menu: false,
      manualDateValue: null as string | null,
      isManual: false,
      dateNow: this.$stackDate.format(this.$store.getters.getCurrentDate(), 'YYYY-MM-DD'),
      dateNowStr: `Сегодня ${this.$stackDate.format(this.$store.getters.getCurrentDate(), 'dd.MM.yyyy')}`,
    };
  },
  computed: {
    rulesCalc(): any {
      // @ts-ignore mixin warn
      return this.customRules ? [this.rulesManual, ...this.customRules] : [this.rulesManual];
    },

    dateMask(): string {
      switch (this.type) {
        case 'month':
          return '99.9{2,4}';
        case 'year':
          return '9999';
        default:
          return '99.99.9{2,4}';
      }
    },

    // перевод из ISO или Date в YYYY-MM-DD (YYYY-MM)
    dateISO(): string | null {
      if (!this.value) {
        return null;
      }
      switch (this.type) {
        case 'month':
          return this.$stackDate.format(this.value, 'YYYY-MM');
        case 'year':
          return this.$stackDate.format(this.value, 'YYYY');
        default:
          return this.$stackDate.format(this.value, 'YYYY-MM-DD');
      }
    },

    // Перевод из ISO или Date в dd.mm.yyyy (mm.yyyy)
    dateRu(): string | null {
      if (!this.value) {
        return null;
      }
      switch (this.type) {
        case 'month':
          return this.$stackDate.format(this.value, 'MM.YYYY');
        case 'year':
          return this.$stackDate.format(this.value, 'YYYY');
        default:
          return this.$stackDate.format(this.value, 'DD.MM.YYYY');
      }
    },

    rulesManual(): string | true {
      if (!this.manualDateValue) {
        return true;
      }
      const datArr = this.manualDateValue.split('.');
      if ((datArr.length !== 3 && this.type === 'date') || (datArr.length !== 2 && this.type === 'month') || (datArr.length !== 1 && this.type === 'year')) {
        return 'Некорректная дата';
      }

      if (this.type === 'date') {
        if (
          +datArr[0] >= 1 &&
          +datArr[0] <= 31 &&
          +datArr[1] >= 1 &&
          +datArr[1] <= 12 &&
          +datArr[0] <= this.$stackDate.dateInMonth(+datArr[1], +this.convertToFullYear(datArr[2])) &&
          ((+datArr[2] >= 1900 && +datArr[2] <= 3000 && datArr[2].length === 4) || (+datArr[2] >= 1 && +datArr[2] <= 99 && datArr[2].length === 2))
        ) {
          return true;
        }
      }
      if (
        this.type === 'month' &&
        +datArr[0] >= 1 &&
        +datArr[0] <= 12 &&
        ((+datArr[1] >= 1 && +datArr[1] <= 99 && datArr[1].length === 2) || (+datArr[1] >= 1900 && +datArr[1] <= 3000 && datArr[1].length === 4))
      ) {
        return true;
      }
      if (this.type === 'year' && +datArr[0] >= 1900 && +datArr[0] <= 3000) {
        return true;
      }
      return 'Некорректная дата';
    },
  },
  mounted() {
    // /\d{2}\/\d{2}\/\d{2,4}/
    const selector = this.$el.querySelector('input');
    const im = new Inputmask(this.dateMask, {
      onBeforePaste: (pasteValue: string) => {
        this.$emit('change', this.manual2Iso(pasteValue));
        return pasteValue;
      },
    });
    im.mask(selector);
  },

  methods: {
    onManualInput(value: string) {
      this.manualDateValue = value;
    },

    onKeyPress(event: KeyboardEvent) {
      if (event.ctrlKey) {
        switch (event.keyCode) {
          case 90: // ctrl-Z
            if (this.type === 'date') {
              this.$emit('change', this.manual2Iso('09.05.2045'));
            }
            break;
          case 77: // ctrl-M
            this.$emit('change', this.$store.getters.getWorkMonth());
            break;
          case 89: // ctrl-Y
            this.$emit('change', null);
            break;
        }
      }
    },

    focus() {
      // @ts-ignore
      if (this.$refs.textField && this.$refs.textField.focus) {
        // @ts-ignore
        this.$refs.textField.focus();
      }
    },

    // смена через календарь
    pickerChanged(date: string) {
      this.$emit('change', this.yymmdd2Iso(date));
      this.menu = false;
      this.manualDateValue = null;
      this.isManual = false;
      // @ts-ignore
      this.$refs.textField.focus();
    },
    save(date: string) {
      if (this.type === 'year') this.pickerChanged(date);
    },
    // после ручного ввода в текстовое поле на смену фокуса
    onTextFieldBlur() {
      // ничего не ввели - ничего не делаем
      if (!this.isManual) {
        return;
      }
      if (this.rulesManual === true) {
        const dat = this.manual2Iso(this.manualDateValue);
        this.$emit('change', dat);
      } else if (this.clearOnError) {
        this.$emit('change', null);
      }
      this.isManual = false;
    },

    manual2Iso(dat: string | null) {
      if (dat) {
        const darr = dat.split('.');
        if ((darr.length === 2 && this.type === 'month') || (darr.length === 3 && this.type === 'date') || (darr.length === 1 && this.type === 'year')) {
          let dat;
          switch (this.type) {
            case 'month':
              darr[1] = this.convertToFullYear(darr[1]);
              dat = `${darr[1]}-${darr[0]}`;
              break;
            case 'year':
              dat = `${darr[0]}`;
              break;
            default:
              darr[2] = this.convertToFullYear(darr[2]);
              dat = `${darr[2]}-${darr[1]}-${darr[0]}`;
              break;
          }
          return this.yymmdd2Iso(dat);
        }
      }
      return null;
    },

    yymmdd2Iso(newDat: string | Date) {
      let dat;
      switch (this.type) {
        case 'month':
          dat = newDat + `-01T00:00:00`;
          break;
        case 'year':
          dat = newDat + `-01-01T00:00:00`;
          break;
        default:
          dat = newDat + `T00:00:00`;
          break;
      }
      return dat;
    },
    convertToFullYear(year: string) {
      if (year.length === 2) {
        return +year < 50 ? '20' + year : '19' + year;
      }
      return year;
    },
  },
  watch: {
    manualDateValue(to: string) {
      if (to !== null) {
        this.isManual = true;
      }
    },
    menu(val) {
      if (this.type === 'year' && val) {
        // ждём отрисовки, чтобы высчитать скролл
        setTimeout(() => {
          if (this.$refs.picker) {
            // @ts-ignore
            const pEl = this.$refs.picker.$el;
            let activeItem: HTMLElement | undefined;
            // Если есть значание, то фокусируемся на нём
            if (this.value) {
              // @ts-ignore
              activeItem = pEl.getElementsByClassName('active')[0];
              // если значения нет, то фокусируемся на тек. год
            } else {
              [...pEl.querySelectorAll('li')].filter((li) => li.textContent.includes(new Date().getFullYear())).forEach((li) => (activeItem = li));
            }
            if (activeItem) {
              pEl.scrollTop = activeItem.offsetTop - pEl.offsetHeight / 2 + activeItem.offsetHeight / 2;
            }
          }
        }, 100);
      }
    },
  },
});
