const reportsrights: StackSelection = {
  objectName: 'Организации.ПраваНаОтчеты',
  fields: [
    {
      field: 'название отчета',
      text: 'Название отчета',
      type: 'String',
    },
  ],
};

export default reportsrights;
