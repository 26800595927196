
import Vue from 'vue';

import UnitErrorDialog from './$dialogs/UnitErrorDialog.vue';

export default Vue.extend({
  components: { UnitErrorDialog },
  methods: {
    async onRowClick(item: StackTableRow) {
      const obj = new this.$HttpModel('Ошибки юнит-теста');
      await obj.executeMethod('ПолучитьОшибку', { запись: item, async: false });

      this.$sendMsg('stack-table', 'Ошибки юнит-теста', 'reload');
    },
    async runUnitTest(items: StackTableRow[]) {
      this.$sendMsg('stack-table', 'Юнит-тесты', 'reload');
    },
  },
});
