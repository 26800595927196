const paymentsreceipts: StackSelection = {
  objectName: 'ВыпискиИзБанка.ПлатежныеВедомости.Добавление',
  description: '',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'подоговору',
      text: 'Договор',
      type: 'Number',
    },
    {
      field: 'плательщик',
      text: 'Плательщик',
      type: 'String',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'расчмесяц',
      text: 'Месяц',
      type: 'Date',
    },
    {
      field: 'кол_во',
      text: 'Σ',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: '₽',
      type: 'Money',
    },
    {
      field: 'сумма3',
      text: '₽ проблемных',
      type: 'Money',
    },
  ],
};
export default paymentsreceipts;
