
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: 'значение',
          text: 'Тип документа',
          type: 'String',
        },
      ],
    };
  },
});
