const accs: StackSelection = {
  objectName: 'КарточкиРегистрации.ПолучитьАдресЗапросом',
  description: '',
  fields: [
    {
      field: 'название',
      text: 'название',
      type: 'String',
    },
    {
      field: 'row_id',
      text: 'row_id',
      type: 'Number',
    },
    {
      field: 'описание',
      text: 'описание',
      type: 'String',
    },
  ],
};
export default accs;
