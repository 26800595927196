
import Vue from 'vue';
import autoAdd from './$correctionsaldostatements/autoAdd.vue';

export default Vue.extend({
  components: { autoAdd },
  props: {
    correctionsaldostatementsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
    readonly: { type: [Boolean], default: false },
  },
  data() {
    return {
      record: {} as StackTableRow,
      auto: false,
      dialogsParams: {} as any,
      masterEndId: '',
      items: [
        { title: 'Создать вручную', type: 1 },
        { title: 'Создать автоматически', type: 2 },
      ] as any,
    };
  },
  computed: {
    title(): string {
      return `Ведомость коррекции сальдо`;
    },
    masterEnd(): boolean {
      const result = this.$store.getters.getMsgActionByID(this.masterEndId);
      return result && !result.complete;
    },
    showMenu(): boolean {
      const cur_id = this.record.$номерЗаписи ? +this.record.$номерЗаписи : 0;
      return cur_id > 0 && !this.record.$толькоЧтение && !this.readonly;
    },
    recordType(): number | null {
      return (this.record.тип && this.record.тип && +this.record.тип) || null;
    },
  },
  methods: {
    clickAdd() {
      this.dialogsParams = { дата: this.record.дата, месяц: this.record.расчмесяц, запись: this.record.$номерЗаписи, владелец: this.correctionsaldostatementsid };
      this.auto = true;
    },

    onSave(payload: any) {
      this.masterEndId = payload.asyncId;
      this.auto = false;
    },

    onBtnClick(tableAction: StackTableAction, type: number) {
      if (type === 2) {
        this.clickAdd();
        return;
      }
      tableAction({ action: 'add', payload: type });
    },
    updateTabItems() {
      this.$sendMsg('stack-dialog', 'ВедомостиКоррекцииСальдо', 'reload');
    },
  },
  watch: {
    // сменился id записи - фетчим новые данные....
    masterEnd: {
      immediate: false,
      handler(to) {
        this.$sendMsg('stack-table', 'ВедомостиКоррекцииСальдо.Заголовок', 'reload');
        this.updateTabItems();
      },
    },
    recordType(to, from) {
      if (to === 3 && from !== null) {
        this.$toast('Нельзя выбирать вариант Акция пени!', { color: 'error' });
        this.record.тип = from;
        // @ts-ignore
        this.$refs.typeCombobox.fetchData();
      }
    },
  },
});
