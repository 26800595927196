const accs: StackSelection = {
  objectName: 'ПроверкаЛсПоГуиду',
  description: '',
  fields: [
    {
      field: 'бд_гуид',
      text: 'ГУИД в БД',
      type: 'String',
    },
    {
      field: 'бд_адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'бд_индекс',
      text: 'Индекс в БД',
      type: 'String',
    },
    {
      field: 'nsi_гуид',
      text: 'ГУИД в ФИАС',
      type: 'String',
    },
    {
      field: 'nsi_адрес',
      text: 'Адрес по ГУИД',
      type: 'String',
    },
    {
      field: 'nsi_индекс',
      text: 'Индекс в ФИАС',
      type: 'String',
    },
    {
      field: 'флагошибок',
      text: 'Флаг ошибок',
      type: 'Number',
    },
    {
      field: 'номер',
      text: 'Номер дома',
      type: 'Number',
    },
    {
      field: 'литера',
      text: 'Литера дома',
      type: 'String',
    },
    {
      field: 'улица',
      text: 'Ид улицы',
      type: 'Link',
    },
    {
      field: 'ПозицияЛицевого',
      text: 'Ид лс',
      type: 'Link',
    },
  ],
};
export default accs;
