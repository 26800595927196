
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    fastLink(): string | undefined {
      if (this.record['объекты-счет'] > 0 && this.record['пломба-объект'] > 0) {
        return `/accounts/${this.record['объекты-счет']}?openModal=${this.record['пломба-объект']}#counters`;
      }
      return undefined;
    },
    isNewRecord(): boolean {
      return !(this.record.$номерЗаписи > 0);
    },
    isPlombActs(): boolean {
      return this.record.состояние === 0 && this.record.примечание.length !== 0 && this.record['пломба-объект'] === -1;
    },
  },
});
