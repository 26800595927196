
import Vue from 'vue';
import TwoTableExchange from './TwoTableExchange.vue';
import tableHeadersLeft from '@/tasks/fl/selections/accountsplit/ДелениеЛицевого.Параметры до';
import tableHeadersRight from '@/tasks/fl/selections/accountsplit/ДелениеЛицевого.Параметры после';

export default Vue.extend({
  components: { TwoTableExchange },
  props: {
    record: { type: Object, required: true },
    disabled: { type: Boolean, required: true },
    runCreation: { type: Boolean, required: true },
  },
  computed: {
    paramGeneral(): object {
      return { лицевой: this.record.лицевой, идДоля: this.record.идДоля, дата: this.record.дата, фЗакрытиеЛС: this.record.делСальдо === 3 };
    },
  },
  data() {
    return {
      коэфДоля: 1,
      коэфПлощадь: 1,
      isSave: false,
      month: this.$store.getters.getOpenMonth(),
      recordsParam: [] as StackTableRow[], // основные
      headersLeft: tableHeadersLeft.fields as StackField[],
      headersRight: tableHeadersRight.fields as StackField[],
    };
  },
  methods: {
    apply() {
      if (this.record.делСальдо === 1 || this.record.делСальдо === 2) {
        const param = this.recordsParam.find(param => this.record.делСальдо === 1 ? param.параметр === 'ОБЩПЛОЩАДЬ' : param.параметр === 'ДОЛЯОК');
        if (param && !param.новзнач) {
          this.$toast(`Необходимо заполнить параметр "${param.наименование}"`, { color: 'error' });
          return;
        }
      }
      this.isSave = true;
      // @ts-ignore
      const recordsDopParam = this.$refs.twoTables.getRightTable();
      this.$emit('save', {
        коэфДоля: this.коэфДоля,
        коэфПлощадь: this.коэфПлощадь,
        выбХарактеристик: this.recordsParam,
        выбДопПараметров: recordsDopParam,
      });
    },
    /**
     * изменили значение параметра, вычисляем коэффициенты
     */
    inputValue(rows: StackTableRow[]) {
      if (rows) {
        const tempArray = [] as any;
        this.recordsParam = tempArray.concat(rows);
        for (const ind in rows) {
          const param = (rows[ind] && rows[ind].параметр ? rows[ind].параметр : '') as string;
          const value = (rows[ind] && rows[ind].значение ? rows[ind].значение : 0) as number;
          const valueOld = (rows[ind] && rows[ind].старзнач ? rows[ind].старзнач : 0) as number;
          const valueNew = (rows[ind] && rows[ind].новзнач ? rows[ind].новзнач : 0) as number;
          if (param === 'ОБЩПЛОЩАДЬ' && +value !== 0) {
            this.коэфПлощадь = this.round(+valueOld / +value, 4);
          }
          if (param === 'ДОЛЯОК' && +value !== 0) {
            this.коэфДоля = this.round(+valueOld / +value, 4);
          }
        }
      }
    },
    round(value: number, decimals: number) {
      const numConvert = Math.pow(10, decimals);
      return Number(Math.round(value * numConvert) / numConvert);
    },
  },
});
