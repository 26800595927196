const acts: StackSelection = {
  objectName: 'ЛицевыеСчета.Объекты.Характеристики',
  description: 'Характеристики',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
  ],
};
export default acts;
