
import Vue from 'vue';
import datnachDatend from './$datNachdatEnd.vue';

export default Vue.extend({
  components: { datnachDatend },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    ownerID: { type: Number, required: true },
    hideDate: { type: Boolean, default: false },
  },
  methods: {
    onUpdate(payload: StackTableRow[]) {
      if (payload && payload.length) {
        this.record.примечание = payload
          .map((item: StackTableRow) => {
            return `${item.описание}:${item.ключ}`;
          })
          .join('#');
      } else {
        this.record.примечание = '';
      }
    },
  },
});
