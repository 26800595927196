
import Vue from 'vue';
/**
 * Меню. Список из карточек
 */
export default Vue.extend({
  props: {
    /** Массив элементов, которые выводим */
    items: { type: Array, required: true },

    /** Мелкий размер карточек */
    small: { type: Boolean, default: false },
    justify: { type: String, default: undefined },
    breadcrumbLvl: { type: Number, default: null },
    color: { type: String, default: undefined },
  },
  methods: {
    async onClick(item: any, idx: number) {
      if (this.breadcrumbLvl && item.to) {
        this.$store.commit('ADD_BREADCRUMB_ITEM', { to: this.$store.getters.getRouteName(item.to), title: item.title, maxlvl: this.breadcrumbLvl });
        this.$store.commit('SET_BROWSER_TITLE', item.title);
      }
      // используется в интеграции
      this.$emit('click', idx);
    },
  },
});
