import WebSocketWrapper from 'websocket-as-promised';
import { store } from '../../store';

import { Script } from './script';
import { SerialPort } from './serialPort';
import { Twain } from './twain';

import Channel from 'chnl';

export class StackPushClient {
  private _url: string;
  private _ws: WebSocketWrapper;
  private _lastRequestId = 0;
  private _events: { [key: string]: Channel } = {};

  private _script: Script;
  private _serialPort: SerialPort;
  private _twain: Twain;

  constructor(url: string) {
    this._url = url;
    this._ws = new WebSocketWrapper(url, {
      packMessage: data => JSON.stringify(data),
      unpackMessage: message => JSON.parse(message as string),
      attachRequestId: (data, id) => Object.assign(data, { id }),
      extractRequestId: data => data.id || 0,
    });
    this._ws.onUnpackedMessage.addListener((response: any) => {
      if (!response.id) {
        this.emitEvent(response.method, response.params);
      }
    });
    this._script = new Script(this);
    this._serialPort = new SerialPort(this);
    this._twain = new Twain(this);

    // коллбеки
    this._ws.onOpen.addListener(async () => {
      store.commit('wsStore/SET_STATUS', true);
      if (store.getters.getCurrentTaskName() === 'Касса') {
        store.commit('kassaStore/SET_INIT_PARAMETERS');
      }
    });

    this._ws.onClose.addListener(() => {
      store.commit('wsStore/SET_STATUS', false);
    });

    // ловим скан штрих кода
    this.SerialPort.onDataRecieved.addListener((data: string) => {
      if (store.getters.getCurrentTaskName() === 'Касса' || store.getters.getCurrentTaskName() === 'ФЛ') {
        store.commit('wsStore/SET_BARCODE', data);
      }
    });
  }

  get Script() {
    return this._script;
  }

  get SerialPort() {
    return this._serialPort;
  }

  get Twain() {
    return this._twain;
  }

  public connect() {
    return this._ws.open().catch(() => {
      throw new Error('Failed to connect to ' + this._url);
    });
  }

  public close() {
    return this._ws.close();
  }

  get connected() {
    return this._ws.isOpened;
  }

  public send(method: string, params?: any) {
    return this._ws.sendRequest({ method, params }, { requestId: ++this._lastRequestId }).then(response => {
      if (response.error !== undefined) {
        throw response.error;
      }
      if (response.result.error !== undefined) {
        throw response.result.error;
      }
      return response.result;
    });
  }

  // Events
  private emitEvent(eventName: string, params: object) {
    const chnl = this._events[eventName];
    if (chnl && chnl.hasListeners()) {
      chnl.dispatch(params);
    } else {
      console.warn('Unknown stack-push event: ' + eventName, params);
    }
  }

  public onEvent(eventName: string) {
    if (this._events[eventName] === undefined) {
      this._events[eventName] = new Channel();
    }
    return this._events[eventName];
  }

  // WebSocket life cycle
  get onOpen() {
    return this._ws.onOpen;
  }

  get onClose() {
    return this._ws.onClose;
  }

  get onSend() {
    return this._ws.onSend;
  }

  get onMessage() {
    return this._ws.onMessage;
  }

  get onError() {
    return this._ws.onError;
  }
}
