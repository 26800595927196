
import Vue from 'vue';
export default Vue.extend({
  props: {
    items: { type: Array, default: () => [] },
  },
  methods: {
    onClick(item: StackTableRow) {
      item.$selected = !item.$selected;
    },
  },
});
