import Vue from 'vue';
import Vuex from 'vuex';
import systemStore from './systemStore'; // состояние бэкэнда
import configStore from './configStore'; // глобальные настройки из .env
import taskStore from './taskStore'; // данные задач, главное меню и тд. (фл,юл, etc)
import chipsStore from './chipsStore'; // данные чипсов
import routesStore from './routesStore'; // данные роутов для переходов
import wsStore from './wsStore'; // работа по вебсокетам
import serviceOperationsStore from './serviceOperationsStore'; // история сервисных операций

Vue.use(Vuex);

const state = {};

const store = new Vuex.Store({
  modules: {
    systemStore,
    configStore,
    taskStore,
    chipsStore,
    routesStore,
    wsStore,
    serviceOperationsStore,
  },
  state,
  strict: !(process.env.NODE_ENV === 'production'),
});

export { store };
