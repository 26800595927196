
import Vue from 'vue';
import InspectionsActFolder from './$resources/folderInspectionsAct.vue';
import InspectionsActsFilter from '../../$common/filters/inspectionsActsFilter.vue';
import ActsToolbar from './$resources/actsToolbar.vue';
import { ReportsBuilder } from '@/stackEngine';

export default Vue.extend({
  name: 'Inspectionsasts',
  components: { InspectionsActFolder, InspectionsActsFilter, ActsToolbar },
  data() {
    return {
      actions: [{ title: 'Редактировать', icon: '$vuetify.icons.edit', action: 'edit' }],
      history: false as Boolean,
    };
  },
  methods: {
    updateActs() {
      this.$sendMsg('stack-table', 'АктыОбхода', 'reload');
    },
  },
});
