
import Vue from 'vue';
import PersonFilter from '../views/$common/filters/PersonFilter.vue';

// TODO Не копипастить !
export default Vue.extend({
  components: {
    PersonFilter,
  },
  props: {
    record: { type: Object, required: true },
    filter: { type: Object, default: undefined },
    value: { type: Boolean, required: true },
    dataModel: { type: [String, Object], required: true },
    noFolder: { type: Boolean, required: true },
    noPagination: { type: Boolean, required: true },
    noSearch: { type: Boolean, default: true },
    noUl: { type: Boolean, default: false }, // не выводить переключатель ФЛ/ЮЛ
    items: { type: [String, Number], default: undefined },
    headers: { type: String, required: true },
  },
  data() {
    return {
      нзОбласть: -1,
      нзРайон: -1,
      rulesThis: {
        seriyaIsCorrect: (type: number, seriya: string): string | true => {
          if (type === 6) {
            const pattern = /^\d{2}[ ]?\d{2}$/;
            return pattern.test(seriya) || (seriya === '' ? 'Поле не может быть пустым' : 'Некорректная серия');
          }
          return true;
        },
        nomerIsCorrect: (type: number, nomer: string): string | true => {
          if (type === 6) {
            const pattern = /^\d{6}$/;
            return pattern.test(nomer) || (nomer === '' ? 'Поле не может быть пустым' : 'Некорректный номер');
          }
          return true;
        },
      },
    };
  },
  computed: {
    disabled() {
      return +this.record.$номерЗаписи > 0;
    },
  },
  methods: {
    async onSelect(item: StackTableRow[]) {
      let record;
      if (item && item[0]) {
        const номерЗаписи = item[0].$номерЗаписи;
        record = await new this.$HttpModel(this.dataModel).getRecords({ номерЗаписи });
      }
      this.$emit('select', record);
    },
    checkFields(field: string, value: string) {
      new this.$HttpModel('ЧастныеЛица').executeMethod('ПроверитьПоля', { [field]: value });
    },
    async onChangeFIO(description: string, field: string, value: string) {
      if (description && field && value) {
        // с заглавной буквы
        this.$set(this.record, field, value.charAt(0).toUpperCase() + value.slice(1));
        if (typeof this.$stackRules.onlyRussianLetters(value) === 'string') {
          const answer = await this.$toast('В поле "' + description + '" есть недопустимые символы');
        }
      }
    },
    async onSave() {
      if (!this.record || !this.record.$номерЗаписи || this.record.$номерЗаписи <= 0) {
        // Если нужно создать новую запись
        const result = await new this.$HttpModel(this.dataModel).createRecord(this.record);
        if (result && result > 0) {
          const tmpRec = await new this.$HttpModel(this.dataModel).getRecords({ номерЗаписи: result });
          this.record.$номерЗаписи = result;
          this.record.фио = tmpRec?.[0].фио;
          if (this.record['номер'] || this.record['серия'] || this.record['кемвыдан'] || this.record['кодподразделения'] || this.record['датавыдачи']) {
            await new this.$HttpModel('ЧастныеЛица.ВидыДокументов').createRecord(
              {
                номер: this.record['номер'],
                серия: this.record['серия'],
                вид: this.record.виддокумента,
                'кем выдан': this.record['кемвыдан'],
                'код подразделения': this.record['кодподразделения'],
                'дата выдачи': this.record['датавыдачи'],
              },
              { владелец: this.record.$номерЗаписи },
            );
          }
        }
      }
      this.$emit('save', this.record);
    },
  },
  watch: {
    // TODO Не копипастить !
    value(to, from) {
      if (to && +this.items < 0) {
        for (const field in this.record) {
          this.record[field] = field === 'типжильца' ? 0 : undefined;
        }
      }
    },
  },
});
