
import Vue from 'vue';
import basemethod from '@/tasks/dlg_fl/components/$dialogs/basemethod.vue';
import ReadingInputDialog from '@/tasks/fl/views/$common/$readingdialog/ReadingInputDialog.vue';

export default Vue.extend({
  extends: basemethod,
  components: { 'ReadingInput-Dialog': ReadingInputDialog },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },

  data() {
    return {
      actvisible: true,
      dataObject: new this.$HttpModel('СостоянияДела'),
      isload: false,
      readinginput: false,
    };
  },
  methods: {
    async onBill() {
      const result = await this.dataObject.executeMethod('ВыполнитьОперациюНаФазе', { record: this.record, операция: 'ВыставитьДокументызаОтклПодк' });
      if (result && result['@наличиесф']) {
        this.record['@наличиесф'] = 1;
      }
    },
    async changeBack(delivery: any) {
      const answer = await this.$yesno('Суммы, внесенные по ТУ, будут удалены. Вы уверены?');
      if (answer) {
        let rec = this.record;
        const result = await this.dataObject.executeMethod('ПроверкаПолей', { поле: 'ВозвратПричина', сдела: rec });
        rec = Object.assign(rec, result);
        this.$emit('change', rec);
      } else {
        this.record.возвратпричина = !delivery;
      }
    },
  },
});
