// разбирает ФИО на фамилию, имя и отчество, аналог стековской функции РазобратьФИО()
interface SomeObject {
  surname: string;
  name: string;
  patronymic: string;
  [tekVar: string]: string;
}

export function splitFullName(incomeFio: string) {
  let pozProb;
  const objFIO: SomeObject = { surname: '', name: '', patronymic: '' };
  if (typeof incomeFio !== 'undefined') {
    let tekVar = 'surname';
    let fio = incomeFio.trim();
    while (fio !== '') {
      pozProb = fio.indexOf(' ');
      if (pozProb === -1 || fio.length === pozProb + 1) {
        objFIO[tekVar] += fio.trim();
        fio = '';
      } else {
        if (fio.substring(pozProb + 1, pozProb + 2) === ' ') {
          objFIO[tekVar] += fio.substring(0, pozProb);
          fio = fio.substring(pozProb + 2).trim();
        } else {
          objFIO[tekVar] += fio.substring(0, pozProb);
          fio = fio.substring(pozProb + 1);
          switch (tekVar) {
            case 'surname':
              tekVar = 'name';
              break;
            case 'name':
              tekVar = 'patronymic';
              break;
            case 'patronymic':
              objFIO[tekVar] += fio.trim();
              fio = '';
              break;
          }
        }
      }
    }
  }
  return objFIO;
}

// собирает ФИО, аналог стековской функции УпаковатьФИО()
export function pickFullName(surname: string, name: string, patronymic: string) {
  let fio = '';
  const objFIO: SomeObject = { surname, name, patronymic };
  let tekVar = 'surname';
  let pozProb;
  while (tekVar !== '') {
    let str = objFIO[tekVar].trim();
    while (str !== '') {
      pozProb = str.indexOf(' ');
      if (pozProb === -1) {
        fio += str;
        str = '';
      } else {
        fio += str.substring(0, pozProb) + ' ';
        str = str.substring(pozProb + 1).trim();
      }
    }
    switch (tekVar) {
      case 'surname':
        tekVar = 'name';
        fio += ' ';
        break;
      case 'name':
        tekVar = 'patronymic';
        fio += ' ';
        break;
      case 'patronymic':
        tekVar = '';
        break;
    }
  }
  return fio;
}
