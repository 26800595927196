
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        'Параметры.Месяц': this.$store.getters.getWorkMonth(),
        'Параметры.@Услуга': -1,
        'Параметры.@Категория': -1,
        'Параметры.@Поставщик': -1,
        'Параметры.@КатегорияПост': -1,
        'Параметры.УслугаВОтчет': '',
        'Параметры.КатегорияВОтчет': '',
        'Параметры.ПоставщикВОтчет': '',
        'Параметры.КатегорияПостВОтчет': '',
      },
    };
  },
  methods: {
    onChange(payload: any, type: string) {
      if (payload && payload[0]) {
        switch (type) {
          case 'поставщик':
            this.$set(this.record, 'Параметры.ПоставщикВОтчет', payload[0].название);
            break;
          case 'услуга':
            this.$set(this.record, 'Параметры.УслугаВОтчет', payload[0].наименование);
            break;
          case 'катуслуги':
            this.$set(this.record, 'Параметры.КатегорияВОтчет', payload[0].название);
            break;
          case 'катпоставщика':
            this.$set(this.record, 'Параметры.КатегорияПостВОтчет', payload[0].наименование);
            break;
          default:
            break;
        }
      }
    },
  },
});
