
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
  },
  methods: {
    onChangeRegistration() {
      if (Number(this.record['вид регистрации']) !== 3) {
        this.record.расчетныепризнаки = 0;
      }
    },
  },
});
