
import Vue from 'vue';
export default Vue.extend({
  name: 'StackTableRow',
  props: {
    isExpanded: { type: Boolean, default: undefined },
    isLoading: { type: Boolean, default: false },
    item: { type: Object, required: true },
    allHeadersDef: { type: Object, required: true },
    tableInfo: { type: Object, required: true },
    folderLikeField: { type: Boolean, default: false },
    noSelect: { type: Boolean, default: false },
    noFolder: { type: Boolean, default: false },
    noDelete: { type: Boolean, default: false },
    noEdit: { type: Boolean, default: false },
    subRow: { type: Boolean, default: false },
    noRowAction: { type: Boolean, default: false },
    hier: { type: Boolean, default: false },
    headersStringList: { type: Array, required: true },
    tdClass: { type: Function, required: true },
    tdStyle: { type: Function, required: true },
    thJustify: { type: Function, required: true },
    tableAction: { type: Function, required: true },
    expandField: { type: String, default: undefined },
    expandFlag: { type: String, default: undefined },
    noMenu: { type: Boolean, default: false },
    dataObject: { type: Object, default: undefined },
    openLink: { type: Boolean, default: false },
  },
  computed: {
    // кол-во занимаемых ячеек для папки
    colspanCount(): number {
      // @ts-ignore
      return this.headersStringList.length;
    },
    isDisabled(): boolean {
      return (this.noFolder && this.item.$этоПапка) || this.isLoading;
    },
    trClass(): string[] | undefined {
      const cl = [this.item.$класс, this.item.$selected ? 'selected' : undefined, this.subRow ? 'grey--text' : undefined].filter(Boolean);
      return cl.length > 0 ? cl : undefined;
    },
  },
  methods: {
    async saveItem(item: StackTableRow, params?: StackHttpRequestTaskParam) {
      if (this.dataObject && this.dataObject.saveRecord) {
        await this.dataObject.saveRecord(item, params);
        const recs = await this.dataObject.getRecords({ номерЗаписи: item.$номерЗаписи, ...params });
        if (recs && recs[0]) {
          for (const fieldName in recs[0]) {
            Vue.set(item, fieldName, recs[0][fieldName]);
          }
        }
      }
    },
    onCheckboxClick(e: MouseEvent) {
      if (!this.isDisabled) {
        this.$emit('select', e.shiftKey);
      }
    },
  },
});
