
import Vue from 'vue';
import acc from './accounts/_accountsid.vue';
import FileTable from '@/tasks/common/views/$common/fileTable.vue';

export default Vue.extend({
  components: { acc, FileTable },
  props: {
    qualityactsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
    readonly: { type: [Boolean, String], default: false },
  },
  data() {
    return {
      readonlystate: !!this.readonly, // TODO при переключении вкладок readonly теряется
      record: {} as StackTableRow,
      dialog: false,
      dateCancel: this.$store.getters.getCurrentDate(),
      loading: false,
      commonTempDisable: false,
      roomTempDisable: false,
      lowTemp: false,
      period: true,
      validPeriod: true,
      itemPeriod: {
        датнач: '',
        даткнц: '',
        часнач: '',
        часкнц: '',
      },
      rules: Object.assign(this.$stackRules, {
        inCorrectMonth: (date1: string, date2: string): string | true => {
          return this.$stackDate.firstDay(date1) === this.$stackDate.firstDay(date2) || 'Даты должны быть в одном месяце!';
        },
      }),
    };
  },
  computed: {
    title(): string {
      return `Акт снятия качества`;
    },
    isNewDialog(): boolean {
      if (this.record && this.record.$номерЗаписи && +this.record.$номерЗаписи > 0 && !this.record.$копирование) {
        return false;
      }
      return true;
    },
    titleBtn(): string {
      if (!this.record['окончания срок']) {
        return `Отменить акт`;
      } else {
        return `Применить акт`;
      }
    },
    textCancel(): string {
      if (!this.record['окончания срок']) {
        return ``;
      } else {
        const окончанияСрок = this.record['окончания срок'] as string;
        return `Акт отменен ${this.$stackDate.format(окончанияСрок, 'DD.MM.YY')}`;
      }
    },
    checkGuilty(): boolean {
      return !!(this.lowTemp && this.record && this.record['@фтемпература'] && Number(this.record['@фтемпература']) < 40);
    },
    closeDate(): boolean {
      return Date.parse(this.record['расчмесяц'] as string) < Date.parse(this.$store.getters.getOpenMonth());
    },
    textDialogCancel(): string {
      if (!this.record['окончания срок']) {
        return `Внимание! Вы хотите отменить расчет актов качества по списку адресов`;
      } else {
        return `Внимание! Вы хотите применить акты качества по списку адресов`;
      }
    },
  },
  methods: {
    updateTableLS(payload: any) {
      this.roomTempDisable = true;
      let countLS = 0;
      payload.forEach((element: any) => {
        countLS++;
        if (element && element.тип) {
          switch (element.тип) {
            case 5:
              this.roomTempDisable = false;
              break;
            case 3:
              this.roomTempDisable = true;
              break;
          }
        }
        if (countLS > 1) {
          this.roomTempDisable = true;
        }
        if (!this.itemPeriod.датнач) {
          this.itemPeriod.датнач = element.датнач;
          this.itemPeriod.даткнц = element.даткнц;
          this.itemPeriod.часнач = element.часнач;
          this.itemPeriod.часкнц = element.часкнц;
        }
      });
    },
    onSelectType(payload: any) {
      if (this.record.$толькоЧтение) return;
      if (payload && payload[0]) {
        this.commonTempDisable = true;
        this.lowTemp = false;
        if (payload[0]['@типснятия'] === 'Отклонение температуры') {
          this.commonTempDisable = false;
        }
        if (payload[0]['@типснятия'] === 'Отклонение температуры' && payload[0]['@номеруслуги'] === '600') {
          this.lowTemp = true;
        }

        this.$set(this.record, '@вид-документы', payload[0]['показатели-услуга']);
        this.$set(this.record, '@коэффициент', payload[0]['@коэффициент']);
        this.$set(this.record, '@ркоэффициент', payload[0]['@коэффициент']);
      }
    },
    runDialogCancel() {
      // if (!this.record['окончания срок']) {
      this.dialog = true;
      // } else {
      //   this.cancelAct();
      // }
    },
    async cancelAct() {
      this.dialog = false;
      this.loading = true;
      try {
        await new this.$HttpModel('АктыСнятияКачества').executeMethod('ОтменитьАкт', {
          окончаниясрок: this.dateCancel,
          $номерЗаписи: this.qualityactsid,
          отменаакта: !this.record['окончания срок'],
        });
        this.calcAct(true);
        // @ts-ignore
        this.$refs.dialogAct.fetchData();
      } catch (error: AnyException) {
        this.$toast(error.message, { color: 'error' });
      }
      this.loading = false;
    },
    async calcAct(noMessage?: boolean) {
      if (this.closeDate && !noMessage) {
        if (!(await this.$yesno('Внимание! Вы хотите запустить расчет актов качества, внесенных в закрытом периоде'))) {
          return;
        }
      }
      new this.$HttpModel('АктыСнятияКачества').executeMethod(
        'Рассчитать',
        { номерЗаписи: this.qualityactsid },
        { async: true, jobName: 'Расчет акта качества №' + this.record.номер + ' ' + this.record.тема, type: 'func' },
      );
    },
    async changePeriod() {
      await new this.$HttpModel('АктыСнятияКачества').executeMethod('ИзменитьПериод', {
        период: this.itemPeriod,
        акт: this.record.$номерЗаписи,
      });
      // @ts-ignore
      this.$refs.accountTable.fetchData();
    },
    cancelCreate() {
      // @ts-ignore
      this.$refs.accountTable.modalEventBus('close');
      // @ts-ignore
      this.$refs.accountTable.fetchData();
    },
  },
});
