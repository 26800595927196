const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.АктыОтделаОграничений',
  description: 'Акты отдела ограничений',
  prepareRow: (item: any) => {
    switch (item.видакта) {
      case 2:
        item['@видакта'] = `Акт по решению суда`;
        break;
      case 3:
        item['@видакта'] = `Акт отключения`;
        break;
      default:
        item['@видакта'] = `Акт подключения`;
        break;
    }
    return item;
  },
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'Date',
    },
    {
      field: 'номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'видакта',
      text: 'ВидАкта',
      type: 'Number',
    },
    {
      field: '@видакта',
      text: 'Вид акта',
      type: 'String',
      computed: true,
    },
  ],
};
export default accs;
