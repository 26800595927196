
import Vue from 'vue';
import { getComponents, getDynamicModalName } from '@/stackEngine';
import UnknownAdrDialog from '@/tasks/common/components/UnknownAdrDialog.vue';
const componentsDefs = require.context('./adrDialogs', false, /\.vue$/);
const adrDlg = getComponents(componentsDefs);

export default Vue.extend({
  components: Object.assign(adrDlg, { UnknownAdrDialog }),
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      title: null as null | string,
    };
  },
  methods: {
    getModalName(row: StackTableRow) {
      return getDynamicModalName(row).file;
    },
  },
});
