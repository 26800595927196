import { TableHandlers } from '@/stackEngine';
const paymentsreceipts: StackSelection = {
  recordRoute: 'payments-paymentsreceipts-paymentsreceiptsid',
  objectName: 'ВыпискиИзБанка',
  description: 'Выписки из банка',
  hier: true,
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    // @ts-ignore
    if (row['@цвет'] !== '') {
      // @ts-ignore
      TableHandlers.stackStyle(row, row['@цвет']);
    }
    return row;
  },
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчмесяц',
      text: 'Месяц',
      type: 'Date',
    },
    {
      field: 'источник-платежи>название',
      text: 'Источник',
      type: 'String',
    },
    {
      field: 'источник-платежи',
      text: 'ИсточникID',
      type: 'Link',
    },
    {
      field: 'наш р/с-платежи',
      text: 'Наш р/с-Платежи',
      type: 'Link',
    },
    {
      field: 'р/счет',
      text: 'Номер счета',
      type: 'String',
    },
    {
      field: 'название банка',
      text: 'Банк',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: 'автор',
      text: 'Автор',
      type: 'String',
    },
    {
      field: '_автор',
      text: 'Автор',
      type: 'Number',
    },
    {
      field: 'кол_во',
      text: 'Σ',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: '₽',
      type: 'Money',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: '@блокирована',
      text: 'блокирована',
      type: 'Number',
    },
    {
      field: '@цвет',
      text: 'цвет',
      type: 'String',
    },
  ],
};
export default paymentsreceipts;
