
import Vue from 'vue';

export default Vue.extend({
  props: {
    selectedItems: { type: Array, default: undefined },
    paymentSource: { type: String, default: '' },
  },
  data() {
    return {
      record: {
        дата: this.$store.getters.getCurrentDate(),
        месяц: this.$stackDate.firstDay(this.$store.getters.getCurrentDate()),
        счет: '',
      },
    };
  },
  methods: {
    async onTransfer(item: any) {
      const result = await new this.$HttpModel('ПлатежныеВедомости.ПлатежиВедомости').executeMethod('Сторнирование', {
        ...this.record,
        сумма: item[0].сумма,
        idСписокОпл: item[0].$номерЗаписи,
        счетОплата: item[0]['счет-оплата'],
        номерЛс: item[0]['счет-оплата>номер'],
        номерДокумента: item[0]['платеж-список>номер'],
        источник: this.paymentSource,
      });
      if (await this.$yesno('Перейти в коррекционную ведомость?')) {
        this.$router.replace('/payments/paylists/' + result);
      }
      this.$emit('close');
    },
  },
});
