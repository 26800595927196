
import Vue from 'vue';

export default Vue.extend({
  props: {
    ownerID: { type: Number, required: true },
  },
  data() {
    return {
      selectedItems: [] as StackTableRow[], // выделенные записи в таблице
      period: {
        документы: 7,
        документыс: this.$stackDate.format(new Date(2000, 0, 1).toString(), 'ISO'),
        документыпо: this.$stackDate.format(new Date(2045, 4, 1).toString(), 'ISO'),
      },
    };
  },
});
