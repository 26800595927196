
import Vue from 'vue';
import FavoriteReportsMenu from './FavoriteReportsMenu.vue';
import ActionDialog from './StackActionDialog.vue';
/**
 * Модальный диалог
 */
export default Vue.extend({
  name: 'StackReportDialog',
  model: { prop: 'record', event: 'change' },
  components: { ActionDialog, FavoriteReportsMenu },
  props: {
    record: { type: Object, required: true },
    /** @deprecated the props should not be used */
    noFormat: {
      type: Boolean,
      default(): boolean {
        // @ts-ignore
        return this.noForm;
      },
    },
    format: { type: Number, default: undefined },
    formats: { type: Array, default: (): string[] => ['pdf', 'xls', 'xlsx', 'html', 'docx'] },
    title: { type: String, default: '' },
    forceRestoreFields: { type: String, default: '' },
  },
  inject: {
    reportName: { default: '' },
    noForm: { from: 'noFormat', default: false },
    favorite: { default: false },
  },
  computed: {
    // идентификатор для сохранения полей в localStorage
    stateIdHash(): string {
      return `report_last_state_${this.$route.name || ''}_${this.title}`;
    },
  },
  methods: {
    onSave() {
      // Закешируем в localStorage выбранные поля диалога
      this.$store.commit('SAVE_CACHE_DATA', { id: this.stateIdHash, data: this.record });
      this.$emit('save');
    },
  },
});
