
import Vue from 'vue';
import ContactButtons from './About/ContactButtons.vue';
import BottomCard from './BottomCard.vue';

export default Vue.extend({
  components: {
    ContactButtons,
    BottomCard,
  },
  computed: {
    hasNewVer(): boolean {
      return !!this.currentHistoryVersion && this.localHistoryVersion !== this.currentHistoryVersion;
    },
    localHistoryVersion(): string {
      return this.$store.getters.getLocalHistoryVersion();
    },
    currentHistoryVersion(): string {
      return this.$store.getters.getCurrentHistoryVersion();
    },
  },
});
