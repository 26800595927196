export const stackTable: StackTourStep[] = [
  {
    target: '[data-cy="btn-add"]',
    selectedEl: '[data-cy="stack-table-toolbar"]',
    commit: 'add',
    content: `Для добавление записей нажмите на плюс в тулбаре таблицы.    `,
    params: {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [200, 5],
          },
        },
      ],
    },
  },
  {
    target: '[data-cy="btn-search"]',
    selectedEl: '[data-cy="stack-table-toolbar"]',
    content: `Таблицы бывают разными: с большим количеством столбцов, с множеством строк, с итоговыми значениями.
    <div class="pt-2" />  
    Для быстрого поиска нужной информации − воспользуйтесь поиском по содержимому в тулбаре таблицы. `,
    commit: 'search',
    params: {
      placement: 'bottom',
    },
  },
  {
    target: '[data-cy="btn-filter-record"]',
    selectedEl: '[data-cy="stack-table-toolbar"]',
    content: `Воспользуйтесь фильтром для сортировки данных в таблице по нужным вам параметрам: по дате, состоянию, номеру, сумме.`,
    commit: 'filter',
    params: {
      placement: 'bottom',
    },
  },
  {
    target: '[data-cy="btn-print-record"]',
    selectedEl: '[data-cy="stack-table-toolbar"]',
    content: `Отправьте на печать отчет, нажав на кнопку с иконкой принтера в тулбаре таблицы.`,
    commit: 'print',
    params: {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [500, 5],
          },
        },
      ],
    },
  },
  {
    target: '[data-cy="btn-calc-sum"]',
    selectedEl: '[data-cy="stack-table-toolbar"]',
    content: `Сложите значения строк таблицы, используя кнопку просуммировать.`,
    commit: 'calc-sum',
    params: {
      placement: 'bottom',
    },
  },
  // {
  //   target: '[data-cy="btn-copy"]',
  //   selectedEl: '[data-cy="stack-table-toolbar"]',
  //   content: `С`,
  //   commit: 'copy',
  //   params: {
  //     placement: 'bottom',
  //   },
  // },
  {
    target: '[data-cy="btn-delete"]',
    selectedEl: '[data-cy="stack-table-toolbar"]',
    content: `Удалите запись, нажав на кнопку с иконкой корзины в тулбаре таблицы`,
    commit: 'delete',
    params: {
      placement: 'bottom',
    },
  },
  {
    target: '.selected',
    commit: 'divideOverlay',
    content: `Выберите записи, над которыми хотите произвести действия.`,
    params: {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-300, 5],
          },
        },
      ],
    },
  },
  {
    selectedEl: '[data-cy="stack-table-head"]',
    target: '[data-cy="stack-table-btn-more"]',
    commit: 'divideOverlay',
    content: `Выпадающее меню таблицы открывает доступ к стандартным действиям над таблицей: обновить данные, развернуть папки, выбрать видимые столбцы в таблице.`,
    params: {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [600, 5],
          },
        },
      ],
    },
  },
  {
    target: '.selected',
    commit: 'divideOverlay',
    content: `При наведении курсора на строку таблицы появляются дополнительные действия:
    <div class="pt-2" />
    <ul>
    <li>Редактировать запись</li>
    <li>Другие возможные действия над записью</li>
    </ul>`,
    params: {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [300, 5],
          },
        },
      ],
    },
  },
  {
    target: '[data-cy="stack-table-toolbar"]',
    commit: 'other-button',
    content: `В тулбаре таблицы в зависимости от контекста и возможностей работы с содержимым будут появлятся кнопки: расчет, изменить дату, внести изменения, печать квитанции.  `,
    params: {
      placement: 'bottom',
    },
  },
  {
    target: '.d-flex',
    commit: 'divideOverlay',
    content: `Переходите по страницам и настраивайте количество отображаемых записей.`,
    params: {
      placement: 'bottom',
    },
  },
];