const reports: StackSelection = {
  objectName: 'МакетыКвитанций.ГруппыДетализации',
  description: 'Группы',
  fields: [
    {
      field: 'номергруппы',
      text: 'Номер группы',
      type: 'Number',
    },
    {
      field: 'номерпопорядку',
      text: 'N п/п',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'макет-состав',
      text: 'макет',
      type: 'Number',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
  ],
};
export default reports;
