
import Vue from 'vue';
import GwInfo from './GWInfo.vue';

export default Vue.extend({
  components: {
    GwInfo,
  },
  data() {
    return {
      intervalID: null as any | null,
      interval: 3000, // интервал опроса бэкэнда, ms
      maxTry: 10, // кол-во попыток
      tryCount: 0, // сделано попыток
      status: undefined,
      gwStatus: undefined as any,
    };
  },

  async mounted() {
    if (await this.checkConnect()) {
      this.$store.commit('SET_CONNECTED', true);
      return;
    }
    this.timerStart();
  },

  beforeDestroy() {
    this.timerStop();
  },

  methods: {
    // запуск опроса бэка
    timerStart() {
      this.tryCount = 0;
      this.intervalID = setInterval(this.checkConnectHandler, this.interval);
    },
    // остановка опроса бэка
    timerStop() {
      if (this.intervalID) {
        clearInterval(this.intervalID);
        this.intervalID = null;
      }
    },

    // функция под setInterval
    async checkConnectHandler() {
      this.tryCount++;
      if (this.tryCount >= this.maxTry) {
        this.timerStop();
        return;
      }
      if (await this.checkConnect()) {
        if (this.$store.getters.isBackendConnected()) {
          this.$store.commit('SET_CONNECTED', true);
          this.$toast('Связь установлена', { icon: '$check_all' });
        }
      }
    },

    // TODO в api
    // проверка соединения
    async checkConnect() {
      try {
        const http = new this.$StackApi();
        this.gwStatus = await http.getGWState();
        const beState = !!(await this.$store.dispatch('checkSystemState'));
        if (beState) {
          this.status = undefined;
          return true;
        }
      } catch (e: AnyException) {
        // Если в ответе вообще ничего нет, тогда выводим http ошибку
        if (!this.gwStatus) {
          this.status = e.message;
        }
      }
      return false;
    },

    onExit() {
      this.$store.dispatch('SIGN_OUT');
    },
  },
});
