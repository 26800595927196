import Vue from 'vue';

export class StaticModel implements DataModel {
  protected data: StackTableRow[] = [];
  private template: StackTableRow = {};
  private headers: StackField[] = [];
  private modelDescription = '';
  protected currentRowid = 0;
  public prepareRow: any;
  public checkRow: any;
  private hasTotal = false;

  get model(): string {
    return 'static';
  }

  get description(): string {
    return this.modelDescription;
  }

  get hier(): boolean {
    return false;
  }

  get fields(): StackField[] {
    return this.headers;
  }

  constructor(rows: StackTableRow[], options?: { rowTemplate: string | StackField[]; description?: string; prepareRow?: any; checkRow?: CheckRowFunc; hasTotal?: boolean }) {
    let row: StackTableRow;
    if (typeof rows === 'object' && Array.isArray(rows)) {
      for (const key in rows) {
        let rowid: number;
        row = rows[key];
        if (typeof row === 'string') {
          row = { $номерЗаписи: +key, значение: row };
        }
        if (row.$номерЗаписи) {
          this.data.push(row);
          rowid = !isNaN(+row.$номерЗаписи) ? +row.$номерЗаписи : 0;
        } else {
          this.data.push(Object.assign(row, { $номерЗаписи: +key }));
          rowid = +key;
        }
        this.currentRowid = Math.max(this.currentRowid, rowid);
      }
    } else {
      throw new Error('Поддерживаются только массивы');
    }
    // шаблон записи (перечень полей)
    if (options) {
      this.hasTotal = !!options.hasTotal;
      if (options.prepareRow) {
        this.prepareRow = options.prepareRow;
      }
      if (options.checkRow) {
        this.checkRow = options.checkRow;
      }
      // если есть в параметрах - берем оттуда
      if (options.rowTemplate) {
        if (typeof options.rowTemplate === 'object') {
          this.headers = [...options.rowTemplate];
        }
        this.template.$номерЗаписи = undefined;
        let template: string[] | StackField[];
        if (typeof options.rowTemplate === 'string') {
          template = options.rowTemplate.split(',');
        } else {
          template = options.rowTemplate;
        }
        for (const field of template) {
          const idx = typeof field === 'string' ? field : field.field;
          this.template[idx] = undefined;
        }
      } else {
        // иначе генерим слепок из того, что передано
        if (this.data[0]) {
          for (const field in this.data[0]) {
            this.template[field] = undefined;
          }
        } else {
          throw new Error('SaticModel: нет шаблона ');
        }
      }
      if (options.description) {
        this.modelDescription = options.description;
      }
    }
  }

  get length(): number {
    return this.data.length;
  }

  public async sort(params?: any) {
    if (params && params.сортировка) {
      const fieldString = params.сортировка as string;
      const fieldArray = fieldString.split(':');
      const field = fieldArray[0];
      const asc = fieldArray.length === 1;
      this.data.sort((a, b) => {
        let aS = a[field] as string | number;
        let bS = b[field] as string | number;
        if (typeof a[field] === 'string') {
          aS = aS.toString().toLowerCase();
          bS = bS.toString().toLowerCase();
        }
        if (asc) {
          return aS > bS ? 1 : -1;
        } else {
          return aS < bS ? 1 : -1;
        }
      });
    }
  }

  public async getRecords(params?: StackHttpRequestTaskParam) {
    this.sort(params);
    let data: StackTableRow[];
    if (params) {
      // Если указан номер записи, фильтруем только по нему
      if (params.номерЗаписи) {
        data = this.data.filter((row: StackTableRow) => row.$номерЗаписи === params.номерЗаписи);
        // Если введён "быстрый поиск"
      } else if (params.поискСтрока && params.поискПоля) {
        const regStr: string = params.поискСтрока?.toLowerCase();
        const fields = params.поискПоля.split(',');
        data = this.data.filter((row: StackTableRow) => {
          for (const field of fields) {
            if (row[field]?.toString().toLowerCase().search(regStr) !== -1) {
              return true;
            }
          }
          return false;
        });
      } else {
        data = this.data;
      }
    } else {
      data = this.data;
    }

    if (this.prepareRow) {
      data.forEach((row: StackTableRow) => {
        // @ts-ignore
        this.prepareRow(row);
      });
    }
    return data;
  }

  public getTotals(): StackTableRow | null {
    let result: StackTableRow | null = null;
    if (this.hasTotal) {
      result = this.data.reduce((acc, n) => {
        for (const prop in n) {
          if (acc[prop] && typeof acc[prop] === 'number' && typeof n[prop] === 'number') {
            // @ts-ignore
            acc[prop] += n[prop];
          } else {
            acc[prop] = n[prop];
          }
        }
        return acc;
      });
    }
    return result;
  }

  public async initRecord(): Promise<StackTableRow> {
    const newRec = Object.assign({}, this.template);
    newRec.$номерЗаписи = 'new';
    return newRec;
  }

  public async createRecord(record: StackTableRow): Promise<number | null> {
    const newRec = Object.assign({}, record);
    if (!(Number(newRec.$номерЗаписи) >= 0)) {
      newRec.$номерЗаписи = ++this.currentRowid;
    }
    this.data.push(newRec);
    return this.currentRowid;
  }

  public async saveRecord(record: StackTableRow): Promise<boolean> {
    const modifingRowid = Number(record.$номерЗаписи);
    // подразумевается что $номерЗаписи должен быть
    if (!(modifingRowid >= 0)) {
      throw new Error('StaticModel: Ошибка сохранения записи');
    }
    let isSaved = false;
    // находим в наших данных элемент и переписываем его поля.
    this.data.forEach((row: StackTableRow) => {
      if (record.$номерЗаписи === row.$номерЗаписи) {
        for (const field in record) {
          Vue.set(row, field, record[field]);
        }
        isSaved = true;
      }
    });
    return isSaved;
  }

  public async deleteRecords(records: StackTableRow[]) {
    this.currentRowid = 0;
    for (const key in this.data) {
      records.forEach(record => {
        if (record.$номерЗаписи === this.data[key].$номерЗаписи) {
          this.data.splice(+key, 1);
        } else {
          this.currentRowid = Math.max(this.currentRowid, Number(this.data[key].$номерЗаписи));
        }
      });
    }
    return true;
  }
}
