const acts: StackSelection = {
  objectName: 'Ошибки юнит-теста',
  description: 'Ошибки юнит-теста',
  hier: false,
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'сообщение',
      text: 'Сообщение',
      type: 'String',
    },
    {
      field: 'файл',
      text: 'Файл',
      type: 'String',
    },
    {
      field: 'расположение',
      text: 'Расположение',
      type: 'String',
    },
  ],
};
export default acts;
