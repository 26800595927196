
import Vue from 'vue';
import addressFilter from '@/tasks/fl/views/$common/filters/addressFilter.vue';

export default Vue.extend({
  components: { addressFilter },
  props: {
    folderType: { type: String, default: '' },
  },
  data() {
    return {
      item: {
        нп: null,
        улица: null,
        дом: null,
        квартира: null,
        адресэл: null,
        номерлс: null,
        фионанимателя: null,
        пструктура: null,
        тема: null,
        датсозданияс: null,
        датсозданияпо: null,
        датотправкис: null,
        датотправкипо: null,
        датполученияс: null,
        датполученияпо: null,
      } as any,
      loading: false,
    };
  },
  computed: {
    withAddress(): boolean {
      return this.$store.getters.getCurrentTask() !== 'contactcenter';
    },
  },
});
