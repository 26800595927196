
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    ownerID: { type: Number, required: true },
    dataObject: { type: Object, required: true },
    closedMonth: { type: [Date, String], required: true },
  },
  created() {
    if (typeof this.record.$номерЗаписи === 'number' && this.record.льгота && this.record.льгота > 0) {
      this.fetchDataDoc(+this.record.льгота, true);
    }
  },
  data() {
    return {
      rulesThis: {
        summaRules: (sum: number, ob: number): string | true => {
          return sum !== 0 || ob > 0 || 'Необходимо заполнить поле Сумма или Объем';
        },
        isRequired: (value: string, lg: number): string | true => {
          if (lg) {
            return (value !== undefined && value !== null && value !== '') || 'Поле не может быть пустым';
          } else {
            return true;
          }
        },
        isOpenMonth: (value: string): string | true => {
          return !value || Date.parse(value) <= Date.parse(this.closedMonth) || `Месяц должен быть меньше открытого`;
        },
        lgotRules: (flag: boolean, numLgot: number | string): string | true => {
          return !flag || `На лицевом счете нет документа на льготу с номером ${numLgot}`;
        },
        servRules: (flag: boolean): string | true => {
          return !flag || `Нельзя вносить изменения на услуги, которые имеют калькуляцию`;
        },
      },
      dataDoc: [] as StackTableRow[],
      errorLgot: false,
      errorServ: false,
    };
  },
  methods: {
    async saveRecordService(event: any) {
      if (event[0] && +event[0]['номер услуги'] !== +this.record['номер услуги']) {
        this.errorServ = await new this.$HttpModel('ЛицевыеСчета.РучныеПерерасчеты').executeMethod('ПроверкаУслуги', { лицевой: this.ownerID, услуга: event[0] });
        if (!this.errorServ) {
          this.record['номер услуги'] = event[0]['номер услуги'];
          this.record['название услуги'] = event[0].название;
          this.searchPost(this.record['номер услуги']);
          await this.searchDogUK(this.record['номер услуги'], -1);
          this.searchUK(this.record.договорук);
        }
      }
    },
    onBeforeChange() {
      const res = this.dataObject.data.filter((item: StackTableRow) => {
        return (
          item.услуга?.toString() === this.record.услуга?.toString() &&
          item.поставщик?.toString() === this.record.поставщик?.toString() &&
          item.перерасчет === this.record.перерасчет &&
          item.договорук?.toString() === this.record.договорук?.toString() &&
          item.$номерЗаписи !== this.record.$номерЗаписи
        );
      }).length;
      if (res > 0) {
        this.$toast('Подобная запись уже существует в ручных перерасчетах, скорректируйте в ней сумму и причину перерасчета', { color: 'error' });
        return false;
      }
      return true;
    },
    saveRecordLgot(event: StackTableRowData[]) {
      this.errorLgot = false;
      this.dataDoc = [];
      if (event[0] && +event[0].$номерЗаписи !== +this.record.льгота) {
        this.record['номер льготы'] = (event[0]['номер льготы'] ? event[0]['номер льготы'] : 0) as number;
        this.record['название льготы'] = event[0]['название льготы'];
        this.fetchDataDoc(+event[0].$номерЗаписи);
      }
    },
    saveRecordPost(event: any) {
      if (event[0] && +event[0].название !== +this.record['название поставщика']) {
        this.record['название поставщика'] = event[0].название;
      }
    },
    async fetchDataDoc(lgID: number, flag?: boolean) {
      const data = await new this.$HttpModel('ЛицевыеСчета.РучныеПерерасчеты.ПроверкаЛьготы').getRecords({ лицевой: this.ownerID, льгота: lgID });
      if (data.length === 0) {
        if (!flag) {
          this.record.документ = null;
        }
        this.errorLgot = true;
      } else {
        this.errorLgot = false;
        if (!flag) {
          this.record.документ = data[0].ключ;
        }
        for (const ind in data) {
          this.dataDoc.push(data[ind]);
        }
      }
    },
    saveRecordUK(event: any) {
      this.searchDogUK(-1, +event[0].$номерЗаписи);
    },
    saveRecordDogUK(event: any) {
      this.searchUK(+event[0].$номерЗаписи);
    },
    async searchUK(dogID: number) {
      const data = await this.dataObject.executeMethod('поискУК', { договорУК: dogID });
      this.record.ук = data.нз;
      this.record['название ук'] = data.название;
    },
    async searchDogUK(serviceID: number, ukID: number) {
      const data = await this.dataObject.executeMethod('поискДоговораУК', { владелец: this.ownerID, услуга: serviceID, ук: ukID });
      this.record.договорук = data;
    },
    async searchPost(serviceID: number) {
      const data = await this.dataObject.executeMethod('поискПоставщика', { владелец: this.ownerID, услуга: serviceID });
      this.record.поставщик = data;
    },
    handleDog(item: any) {
      return '№' + item.номер + ' ' + item.тема + ' ' + item['организация-укдоговор>название'] + ' от ' + this.$stackDate.format(item.датнач, 'DD.MM.YYYY');
    },
  },
});
