
import Vue from 'vue';
import commonCounter from '@/tasks/common/views/$common/counters/_counterid.vue';

export default Vue.extend({
  components: { commonCounter },
  name: 'CountersInfo',
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
    cItems(): any {
      return this.items.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage);
    },
    showArrows(): Boolean {
      return this.items.length > this.itemsPerPage;
    },
  },
  data() {
    return {
      http: new this.$HttpModel('ЛицевыеСчета.ДействующиеСчетчики'),
      items: [] as StackTableRow[],
      dataModel: null as null | string,
      objid: null as null | number,
      objrecord: {} as StackTableRow,
      page: 1,
      itemsPerPage: 6,
      btnhistory: false,
    };
  },
  methods: {
    onBtnHistory() {
      this.btnhistory = !this.btnhistory;
      this.page = 1;
      this.fetchData();
    },
    countersIcon(item: StackTableRow): string | undefined {
      if (item.видсчетчика === 'ОДПУ') {
        return '$vuetify.icons.odpu_counter';
      }
      if (item.идентификатор === 0) {
        return '$vuetify.icons.ipu_counter';
      }
      return undefined;
    },
    statusIcon(item: StackTableRow): string {
      return item.состояние === 0 ? '$vuetify.icons.close_circle' : item.состояние === 1 ? '$vuetify.icons.check_circle' : '';
    },
    iconColor(item: StackTableRow): string {
      return item.состояние !== 0 ? 'green' : 'grey';
    },
    onObjClick(item: StackTableRow) {
      // item.видсчетчика === 'ИПУ' ? `/accounts/${record.$номерЗаписи}/counters/${item.row_id}` : `/metersdata/groupcounters/${item.документ}`
      switch (item.видсчетчика) {
        case 'ИПУ':
          this.dataModel = 'ЛицевыеСчета.Объекты';
          this.objid = item.row_id as number;
          // this.objrecord = {...item};
          break;
        case 'ОДПУ':
        case 'ОКПУ':
          this.dataModel = 'ГрупповыеСчетчики.Счетчики';
          this.objid = item.row_id as number;
          // this.objrecord = {...item};
          break;
      }
    },
    getIcon(service: number) {
      return this.$store.getters['flStore/getServiceTypeIcon'](service);
    },
    getIconColor(service: number) {
      return this.$store.getters['flStore/getServiceTypeIconColor'](service) + ' darken-4';
    },
    async fetchData() {
      this.items = await this.http.getRecords({ владелец: this.record.$номерЗаписи, история: this.btnhistory });
    },
    isClosed(item: StackTableRow, date = `2045-05-09T00:00:00`): any {
      if (item.датнач && Date.parse(item.даткнц as string) < Date.parse(date)) {
        return 'grey lighten-4';
      }
      return '';
    },
  },
  watch: {
    record: {
      immediate: true,
      async handler(to, old) {
        if (to?.$номерЗаписи > 0 && to.$номерЗаписи !== old?.$номерЗаписи) {
          this.fetchData();
        }
      },
    },
    workMonth() {
      this.fetchData();
    },
  },
});
