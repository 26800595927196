
import Vue from 'vue';
import listDialog from './$modal/listDialog.vue';

export default Vue.extend({
  components: { listDialog },
  props: {
    correctionsaldostatementsheaderid: { type: [Number, String], default: undefined },
    correctionsaldostatementsid: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      dialog: false,
      changeLS: false,
      isCloseLS: false,
    };
  },
  computed: {
    title(): string {
      return `Коррекция сальдо`;
    },
  },
  methods: {
    rowHandler(record: any) {
      record.handlerServ = '№' + record['услуга-коррекция>номер услуги'] + ' ' + record['услуга-коррекция>название'];
      record.handlerPost = record['поставщик-коррекция>название'];
      if (record['укдоговор-коррекция'] > 0) {
        record.handlerDog =
          '№' +
          record['укдоговор-коррекция>номер'] +
          ' с ' +
          record['укдоговор-коррекция>организация-укдоговор>название'] +
          ' от ' +
          (record['укдоговор-коррекция>датнач'] !== '' ? this.$stackDate.format(record['укдоговор-коррекция>датнач'], 'DD.MM.YYYY') : '');
      } else {
        record.handlerDog = '';
      }

      return record;
    },
    async selectLS(lsRows: StackTableRow[]) {
      if (lsRows && lsRows[0] && this.record && this.record.расчмесяц) {
        const montCloseLs = lsRows[0]['@вадмрежиме'] ? this.$stackDate.prevMonth(lsRows[0].закрытыймесяц, 1) : lsRows[0].закрытыймесяц;
        this.isCloseLS = Date.parse(montCloseLs as string) >= Date.parse(this.record.расчмесяц.toString());
      }
    },
    updateTabItems() {
      this.changeLS = false;
      // this.$sendMsg('stack-dialog', 'ВедомостиКоррекцииСальдо.Заголовок', 'reload');
    },
    monthLSRules(): string | true {
      return !this.isCloseLS || this.record.$толькоЧтение || 'Расчетный месяц для этого лицевого счета закрыт. Сохранение ведомости запрещено';
    },
  },
});
