
import Vue from 'vue';

import ClearDialog from './ClearDialog.vue';

export default Vue.extend({
  components: { ClearDialog },
  props: {
    params: { type: Object, required: true },
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    selectedItems: { type: [Object, Array], required: true }, // array
  },
  computed: {
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
  },
  data() {
    return {
      jobID: null,
      clearDialogVis: false,
    };
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.jobID = null;
        this.$emit('updateTable');
      }
    },
  },
});
