const acts: StackSelection = {
  objectName: 'ОснованияВладения',
  description: 'Основания владения',
  openLink: 'references-groundsofownership',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: '@стрчс',
      text: 'Частная собственность',
      type: 'Enum',
      computed: true,
    },
  ],
};
export default acts;
