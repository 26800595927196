
import Vue from 'vue';

export default Vue.extend({
  props: {
    param: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': -10,
      },
    };
  },
});
