
import Vue from 'vue';
import JSON from 'json5';

export default Vue.extend({
  props: {
    fields: { type: [String, Object, Array], required: true },
    rec: { type: Object, required: true },
  },
  methods: {
    fetchDataModel(field: any) {
      if (field.elements) {
        return typeof field.elements !== 'object' && field.elements.indexOf('\n') >= 0 ? field.elements.split('\n') : field.elements;
      } else {
        return typeof field['data-model'] === 'string' && field['data-model'].indexOf('[') >= 0 ? JSON.parse(field['data-model']) : field['data-model'];
      }
    },
    parserParams(params: any) {
      const readyParams: any = {};
      for (const prop in params) {
        if (typeof params[prop] === 'string' && this.rec[params[prop]]) {
          readyParams[prop] = this.rec[params[prop]];
        } else {
          readyParams[prop] = params[prop];
        }
      }
      return readyParams;
    },
  },
});
