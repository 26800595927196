
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Boolean, default: false },
  },
  data() {
    return {
      isEnable: false,
    };
  },
  methods: {
    setDrawerState(state: boolean) {
      this.$emit('input', state);
    },
  },
  watch: {
    // нужно для начальной одноразовой инициализации (аналог lazyload)
    value: {
      immediate: true,
      handler(to: boolean) {
        const enableURL = this.$route.query.filter;
        this.isEnable = this.isEnable || to === true || !!enableURL;
      },
    },
  },
});
