
import Vue from 'vue';

import PhoneNumber from './$dialogs/PhoneNumber.vue';

export default Vue.extend({
  components: { PhoneNumber },
  props: {
    shablonciid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      dialog: false,
      items: [] as StackTableRow[],
      mediaItems: [] as StackTableRow[],
      hiddenDialog: false,
      phone: '',
    };
  },
  async created() {
    this.items = await new this.$HttpModel('ПараметрыШаблонаЦентраИнформирования').getRecords();
    this.mediaItems = await new this.$HttpModel('МедиафайлыШаблонаЦентраИнформирования').getRecords();
  },
  computed: {
    title(): string {
      return `Шаблон центра информирования`;
    },
  },
  methods: {
    onBtnClick(parameter: string) {
      this.record.текст += parameter.substring(parameter.indexOf('['), parameter.indexOf(']') + 1);
    },
    onMediaBtnClick(parameter: string) {
      this.record.текст += '#' + parameter + '#';
    },
    enterPhone(phone: string) {
      this.hiddenDialog = false;
      this.phone = phone;
      new this.$HttpModel('ЦентрИнформированияШаблоны').executeMethod('ТестЗвонка', { телефон: this.phone, текст: this.record.текст });
    },
  },
});
