
import Vue from 'vue';

export interface UserAskError {
  title: string;
  counterOverflow?: boolean;
  neative?: boolean;
  userError?: boolean;
  overflow?: boolean;
}

export default Vue.extend({
  props: {
    value: { type: Boolean, default: false },
    type: { type: Number, default: 0 },
    positionX: { type: [String, Number], default: undefined },
    positionY: { type: [String, Number], default: undefined },
  },
  computed: {
    items(): UserAskError[] {
      return this.type === 0
        ? [
            { title: 'Перекрутка счетчика', counterOverflow: true },
            { title: 'Отрицательный расход', neative: true },
            { title: 'Ошибка оператора', userError: true },
          ]
        : [
            { title: 'Превышение расхода', overflow: true },
            { title: 'Ошибка оператора', userError: true },
          ];
    },
  },
});
