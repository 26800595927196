
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      items: [
        { title: 'Входящее соединение', result: '', loading: false },
        { title: 'Исходящее соединение', result: '', loading: false },
      ],
      startTime: 0,
      endTime: 0,
      downloadSize: 1,
      loading: false,
    };
  },
  methods: {
    async runTest() {
      const mbSize = this.downloadSize;
      this.items[0].loading = true;
      this.loading = true;
      this.items[0].result = '';
      this.items[1].result = '';
      this.startTime = new Date().getTime();
      const http = new this.$StackApi();
      http.fetch('АПИСервисы', { размер: mbSize }, 'случайныйнаборданных');
      try {
        await http.run();
        this.endTime = new Date().getTime();
        this.items[0].result = this.getSpeed(mbSize);
        this.items[0].loading = false;
        this.runTest2();
      } catch (e: AnyException) {
        this.items[0].result = `Ошибка связи !`;
        this.items[0].loading = false;
        this.loading = false;
      }
    },
    async runTest2() {
      const mbSize = this.downloadSize;
      this.items[1].loading = true;
      const xhr = new XMLHttpRequest();
      xhr.onerror = () => {
        this.items[1].result = `Ошибка связи !`;
        this.items[1].loading = false;
        this.loading = false;
      };
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          this.endTime = new Date().getTime();
          this.items[1].result = this.getSpeed(mbSize);
          this.items[1].loading = false;
          this.loading = false;
        }
      };
      const url = this.$store.getters.getApiHostUpload();
      const data = this.getRandomString(mbSize);
      try {
        this.startTime = new Date().getTime();
        xhr.open('POST', url, true);
        xhr.send(data);
      } catch (e: AnyException) {
        this.items[1].result = `Ошибка связи !`;
        this.loading = false;
      }
    },
    getSpeed(mb: number) {
      const duration = (this.endTime - this.startTime) / 1000;
      const bitsLoaded = mb * 1024 * 1024 * 8;
      let mesure = 'бит/сек';
      let speedBps = (bitsLoaded / duration).toFixed(2);
      if (+speedBps > 1000) {
        mesure = 'кбит/сек';
        speedBps = (+speedBps / 1024).toFixed(2);
      }
      if (+speedBps > 1000) {
        mesure = 'мбит/сек';
        speedBps = (+speedBps / 1024).toFixed(2);
      }
      return `${speedBps} ${mesure}`;
    },
    getRandomString(sizeInMb: number) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+`-=[]{}|;\':,./<>?';
      const iterations = sizeInMb * 1024 * 1024;
      let result = '';
      for (let index = 0; index < iterations; index++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    },
  },
});
