
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      items: [
        { title: 'Акты снятия качества', img: 'doc_quality_acts', to: { name: 'documents-qualityacts' } },
        { title: 'Ведомости разовых начислений', img: 'doc_nach_once', to: { name: 'documents-onechargestatements' } },
        { title: 'Ведомости коррекции сальдо', img: 'doc_saldo_corr', to: { name: 'documents-correctionsaldostatements' } },
        { title: 'Ведомости удержаний', img: 'ved_uderzh', to: { name: 'documents-deductions' } },
        { title: 'Соглашения о рассрочке', img: 'doc_installment_agreements', to: { name: 'documents-agreement' } },
        { title: 'Массовое формирование соглашений согласно пп 72-75 ПП РФ №354', img: 'doc_installment_agreements', to: { name: 'operations-forminstallments' }, color: 'stack' },
      ],
      items3: [
        { title: 'Договоры с поставщиками', img: 'doc_sup_agreements', to: { name: 'contracts-resources' } },
        { title: 'Договоры с управляющими компаниями', img: 'doc_uk_agreements', to: { name: 'contracts-managements' } },
      ],
      loading: false,
    };
  },
  computed: {
    contactcenter(): boolean {
      return this.$store.getters['flStore/getContactCenter']();
    },
    items2(): any {
      const items: any = [
        { title: 'Акты нарушений абонентов', img: 'doc_offense', to: { name: 'documents-violationsacts' } },
        { title: 'Акты по должникам', img: 'doc_debtors', to: { name: 'documents-debtorsacts' } },
      ];
      if (this.contactcenter) {
        items.push({ title: 'Заявки абонентов', img: 'doc_abon_requests', loading: this.loading });
      }
      return items;
    },
  },
  methods: {
    async onClick(idx: number) {
      // Если это заявки абонентов
      if (idx === 2) {
        try {
          this.loading = true;
          const service = 'contactcenter';
          const res = await new this.$StackApi().getExternalToken(service);
          const route = `${res.addr}/${service}/requestlog?token=${res.guid}`;
          window.open(route, '_blank');
        } catch (error: AnyException) {
          this.$toast('Не удалось совершить переход !', { color: 'error' });
        } finally {
          this.loading = false;
        }
      }
    },
  },
});
