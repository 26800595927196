
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'Карточка соответствия услуг' },
  },
  computed: {
    isSpecRegion(): boolean {
      return +this.record['@спецрегион'] === 1;
    },
  },
});
