
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    dataModel: { type: String, default: 'Объекты.Пломбы' },
  },
});
