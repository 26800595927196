
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'Услуга' },
  },
  data() {
    return {
      init: true,
    };
  },
  methods: {
    onChange(items: StackTableRow[]) {
      if (this.init) {
        this.init = false;
        return;
      }
      if (items[items.length - 1]?.$номерЗаписи !== -1) {
        this.record.значение = items.reduce((acc, item) => acc + Number(item.значение), 0);
      } else {
        this.record.значение = 0;
      }
    },
  },
});
