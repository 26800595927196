
import Vue from 'vue';
import basemethod from './basemethod.vue';
import { getComponents, stringTranslit } from '@/stackEngine';
import SummfazDialog from '../$lawsuits/summfazDialog.vue';
const componentsDefs = (require as any).context('../$dialogs', false, /\.vue$/);
const paramDlgs = getComponents(componentsDefs);
const componentsDefsUL = (require as any).context('@/tasks/dlg_ul/components/$dialogs', false, /\.vue$/);
const paramDlgsUL = getComponents(componentsDefsUL);
export default Vue.extend({
  components: Object.assign(paramDlgs, paramDlgsUL, { 'Summfaz-Dialog': SummfazDialog }),
  extends: basemethod,
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      summfaz: false,
    };
  },
  methods: {
    updateDialog() {
      // @ts-ignore
      this.$refs.dialog.fetchData();
      this.$emit('update');
    },
    getModalName(row: StackTableRow): string {
      const TypeDialog: string = row['@типдиалога'] as string;
      if (TypeDialog) {
        const name = stringTranslit(TypeDialog).toUpperCase();
        if (this.$options.components && name in this.$options.components) {
          return name as string;
        }
      }
      if (row.$номерЗаписи === -1) {
        return '';
      }
      return 'DEFAULT';
    },
  },
});
