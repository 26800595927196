
import Vue from 'vue';

export default Vue.extend({
  computed: {
    paramsTable(): object {
      return { фильтрфайлов: 'xls,xlsx,csv', путьапи: this.isWayAPI, файлыизкаталога: this.loadedFiles };
    },
  },
  data() {
    return {
      files: [] as StackTableRow[],
      loadedFiles: [] as StackTableRow[],
      isWayAPI: false,
    };
  },
  methods: {
    async upload() {
      try {
        await new this.$HttpModel('ВыгрузкиПодкачки').executeMethod(
          'Подкачать',
          { files: this.files, путьапи: this.isWayAPI, подкачка: 'Прием показаний распределителей' },
          {
            async: true,
            jobName: 'Прием показаний распределителей',
          },
        );
        this.files = [];
      } catch (error: AnyException) {
        this.$toast(error);
      }
    },
    selectedFiles(files: StackTableRow[]) {
      const tempFiles = [] as any;
      for (const ind in files) {
        tempFiles.push({ name: files[ind].файл });
      }
      this.files = [].concat(tempFiles);
    },
    loadFiles(files: StackTableRow[]) {
      this.loadedFiles = files;
      this.isWayAPI = files.length > 0;
    },
  },
});
