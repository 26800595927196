
import Vue from 'vue';
import ReesterUKToolbar from './$dialogs/ReesterUKToolbar.vue';

export default Vue.extend({
  name: 'KvitModels',
  components: { ReesterUKToolbar },
  methods: {
    async onClick(item: StackTableRow) {
      window.open(this.$router.resolve('/accounts?parentID=' + item['реестры_состав-лицевой']).href, '_blank');
    },
  },
});
