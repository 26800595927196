
import Vue from 'vue';
import datePeriodFilter from './datePeriodFilter.vue';

export default Vue.extend({
  components: { datePeriodFilter },
  data() {
    return {
      item: {
        датнач: this.$store.getters.getCurrentDate(),
        даткнц: this.$store.getters.getCurrentDate(),
        фильтры: 0,
        варианты: 0,
        пользователь: null,
        отладка: false,
      } as any,
    };
  },
});
