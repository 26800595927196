
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    dataModel: { type: String, required: true },
    labelField: {
      type: [Function, String],
      default: 'название',
    },
    headers: { type: String, default: 'название' },
    keyField: { type: String, default: '$номерЗаписи' },
    folder: { type: Boolean, default: false },
    noSingle: { type: Boolean, default: false },
  },
});
