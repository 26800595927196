import {
  format as formatFNS,
  addYears as addYearsFNS,
  isAfter as isAfterFNS,
  differenceInDays as differenceInDaysFNS,
  differenceInMonths as differenceInMonthsFNS,
  getYear as getYearFNS,
  addMonths as addMonthsFNS,
  addDays as addDaysFNS,
  subMonths as subMonthsFNS,
} from 'date-fns';
import ru from 'date-fns/locale/ru';

type StackDateTypes = 'ISO' | 'ISOMonth';

function format(value: string | Date | StackDateTypes, format: string) {
  if (value) {
    try {
      const val: Date = typeof value === 'string' ? new Date(value) : value;
      const toStackFormat = format.replace(/DD/, 'dd')
        .replace(/YYYY/, 'yyyy')
        .replace(/YY/, 'yy');
      switch (format) {
        case 'ISO':
          return formatFNS(val, `yyyy-MM-dd'T'00:00:00.000`, { locale: ru });
        case 'ISOMonth':
          return formatFNS(val, `yyyy-MM-01'T'00:00:00.000`, { locale: ru });
        case 'ISODateTime':
          return formatFNS(val, `yyyy-MM-dd'T'HH:mm:ss.000`, { locale: ru });
        case 'Time':
          return formatFNS(val, 'HH:mm', { locale: ru });
        case 'DateTime':
          return formatFNS(val, 'dd.MM.yyyy HH:mm', { locale: ru });
        case 'DateTimeFull':
          return formatFNS(val, 'yyyy-MM-dd HH:mm:ss', { locale: ru });
        default:
          return formatFNS(val, toStackFormat, { locale: ru });
      }
    } catch (e: AnyException) {
      return value.toString();
    }
  }
  return value;
}

function firstDay(value: string | Date | number): string | undefined {
  if (value) {
    const d = new Date(value);
    d.setDate(1);
    return format(d, 'ISO');
  }
  return undefined;
}

function lastDay(value: string | Date | number): string | undefined {
  if (value) {
    const date = new Date(value);
    return format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'ISO');
  }
  return undefined;
}
function nextMonth(value: string | Date | number, inc: number): string | undefined {
  if (value) {
    const d = new Date(value);
    d.setMonth(d.getMonth() + inc, 1);
    return format(d, 'ISO');
  }
  return undefined;
}
function prevMonth(value: string | Date | number, inc: number): string | undefined {
  if (value) {
    const d = new Date(value);
    d.setMonth(d.getMonth() - inc, 1);
    return format(d, 'ISO');
  }
  return undefined;
}
function addDays(value: string | Date | number, inc: number): string {
  const d = new Date(value);
  return format(addDaysFNS(d, inc), 'ISO');
}

function addMonths(value: string | Date | number, inc: number): string {
  const d = new Date(value);
  return format(addMonthsFNS(d, inc), 'ISO');
}

function dateInMonth(month: number, year?: number): number {
  const date = new Date();
  date.setMonth(month - 1);
  return 33 - new Date(year || date.getFullYear(), date.getMonth(), 33).getDate();
}

function isAfter(value: string | Date, value1: string | Date) {
  const val: Date = typeof value === 'string' ? new Date(value) : value;
  const val1: Date = typeof value1 === 'string' ? new Date(value1) : value1;
  return isAfterFNS(val, val1);
}

function subMonths(value: string | Date, amount: number) {
  const val: Date = typeof value === 'string' ? new Date(value) : value;
  return subMonthsFNS(val, amount);
}

function differenceInDays(value: string | Date, value1: string | Date) {
  const val: Date = typeof value === 'string' ? new Date(value) : value;
  const val1: Date = typeof value1 === 'string' ? new Date(value1) : value1;
  return differenceInDaysFNS(val, val1);
}

function differenceInMonths(value: string | Date, value1: string | Date) {
  const val: Date = typeof value === 'string' ? new Date(value) : value;
  const val1: Date = typeof value1 === 'string' ? new Date(value1) : value1;
  return differenceInMonthsFNS(val, val1);
}

function getYear(value: string | Date) {
  const val: Date = typeof value === 'string' ? new Date(value) : value;
  return getYearFNS(val);
}

function addYears(value: string | Date, amount: number) {
  const val: Date = typeof value === 'string' ? new Date(value) : value;
  return addYearsFNS(val, amount);
}

export const stackDate = {
  format,
  subMonths,
  isAfter,
  differenceInDays,
  differenceInMonths,
  getYear,
  firstDay,
  lastDay,
  nextMonth,
  prevMonth,
  addYears,
  addDays,
  addMonths,
  dateInMonth,
};
