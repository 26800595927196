const orgs: StackSelection = {
  recordRoute: 'references-dialogOrganizationParam',
  objectName: 'Организации.Параметры',
  description: '',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
    {
      field: 'параметр-значения',
      text: 'Параметр-Значения',
      type: 'Link',
    },
  ],
};

export default orgs;
