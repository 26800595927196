const acts: StackSelection = {
  objectName: 'ТипыПриборовУчета',
  description: 'Типы приборов учета',
  openLink: 'references-typesmeteringdevices',
  hier: true,
  fields: [
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
    {
      field: 'номномер',
      text: 'Номенклатурный номер',
      type: 'String',
    },
    {
      field: 'тарифность',
      text: 'Число тарифов',
      type: 'Number',
    },
    {
      field: 'счетчика разрядность',
      text: 'Разрядность',
      type: 'Number',
    },
    {
      field: 'услуга счетчика',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: '@услуга',
      text: 'Услуга',
      type: 'Number',
      computed: true,
    },
    {
      field: 'счетчика дробная разрядность',
      text: 'Дробная разрядность',
      type: 'Number',
    },
    {
      field: '@интервал',
      text: 'Интервал',
      type: 'Number',
      computed: true,
    },
    {
      field: 'идентификатор',
      text: 'Идентификатор ',
      type: 'Number',
    },
    {
      field: 'идентификаторстр',
      text: 'Идентификатор',
      type: 'String',
      computed: true,
    },
    {
      field: 'номинмощ',
      text: 'номинмощ',
      type: 'Number',
    },
    {
      field: 'потерикз',
      text: 'потерикз',
      type: 'Number',
    },
    {
      field: 'потерихх',
      text: 'потерихх',
      type: 'Number',
    },
    {
      field: 'тарифностьстр',
      text: 'Тарифность',
      type: 'String',
    },
    {
      field: 'разрядностьстр',
      text: 'Разрядность ',
      type: 'String',
    },
    {
      field: 'коэффициентстр',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'классточности',
      text: 'Класс точности',
      type: 'String',
    },
    {
      field: 'межповерочныйинтервал',
      text: 'МПИ',
      type: 'String',
    },
    {
      field: 'микрогенерация',
      text: 'Микрогенерация',
      type: 'String',
    },
  ],
};
export default acts;
