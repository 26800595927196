const work: StackSelection = {
  recordRoute: 'references-workers-workersid',
  objectName: 'Сотрудники',
  description: 'Сотрудники',
  openLink: 'references-workers',
  hier: true,
  fields: [
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'табномер',
      text: 'ТабНомер',
      type: 'Number',
    },
    {
      field: 'должность',
      text: 'Должность',
      type: 'String',
    },
    {
      field: 'имя',
      text: 'Логин',
      type: 'String',
    },
    {
      field: 'разряд',
      text: 'Разряд',
      type: 'Number',
    },
    {
      field: 'телефон',
      text: 'Телефон',
      type: 'String',
    },
    {
      field: 'хост',
      text: 'Хост',
      type: 'String',
    },
    {
      field: 'пользователь',
      text: 'Пользователь',
      type: 'Number',
    },
    {
      field: 'флаги',
      text: 'Флаги',
      type: 'Number',
    },
    {
      field: 'процесс',
      text: 'Процесс',
      type: 'String',
    },
    {
      field: 'вход',
      text: 'Вход',
      type: 'String',
    },
    {
      field: 'активность',
      text: 'Активность',
      type: 'String',
    },
    {
      field: 'сотрудник-организация',
      text: 'сотрудник-организация',
      type: 'Link',
    },
    {
      field: 'признаки',
      text: 'Признаки',
      type: 'Number',
    },
    {
      field: 'сотрудник-должность',
      text: 'Сотрудник-Должность',
      type: 'Link',
    },
    {
      field: 'инн',
      text: 'ИНН',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'адрессервиса',
      text: 'URL',
      type: 'String',
    },
  ],
};

export default work;
