
import Vue from 'vue';

export default Vue.extend({
  name: 'DateBeginDialog',
  data() {
    return {
      ДатаОткрытия: this.$store.getters.getWorkMonth(),
      ДатПарНач: this.$store.getters.getWorkMonth(),
    };
  },
  methods: {
    async onBtnOkClick() {
      this.$emit('ok', { ДатаОткрытия: this.ДатаОткрытия, ДатПарНач: this.ДатПарНач });
    },
  },
});
