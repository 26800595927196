
import Vue from 'vue';

export default Vue.extend({
  props: {
    selectedValues: { type: [String, Number], default: undefined },
  },
  methods: {
    async onBtnPrintClick(reportName: string) {
      await new this.$ReportsBuilder(reportName).executeReport2(reportName, {
        ведомость: this.selectedValues,
        номерЗаписи: this.selectedValues,
        имяВыборки: 'ПлатежныеВедомости',
      });
    },
    onClicFindPays() {
      this.$router.push({
        name: 'payments-paylists-search',
      });
    },
  },
});
