
import Vue from 'vue';
// import { tasks } from '../../constants';

export default Vue.extend({
  props: {
    noClose: { type: Boolean, default: false },
  },
  computed: {
    getBundles(): string[] {
      // @ts-ignore
      return this.$store.getters.getBundles();
    },
    taskItems(): any[] {
      const tasks = this.$store.getters.getTaskList();
      tasks.forEach((i: any) => {
        i.disabled = !i.external && (this.getBundles.indexOf(i.href.substr(1)) === -1 || !i.href);
      });
      return tasks;
    },
  },
});
