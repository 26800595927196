const lg: StackSelection = {
  recordRoute: 'references-lgoty-lgotyid',
  objectName: 'Льготы',
  description: 'Льготы',
  openLink: 'references-lgoty',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'краткое название',
      text: 'Краткое\nназвание',
      type: 'String',
    },
    {
      field: 'номер льготы',
      text: 'Номер\nльготы',
      type: 'Number',
    },
    {
      field: 'код',
      text: 'Код ОСЗН',
      type: 'Number',
    },
    {
      field: 'связанная льгота',
      text: 'Связанная\nльгота',
      type: 'Number',
    },
    {
      field: 'группа ограничения',
      text: 'Группа\nограничения',
      type: 'Number',
    },
    {
      field: 'приоритет ограничения',
      text: 'Приоритет\nограничения',
      type: 'Number',
    },
    {
      field: 'способ возмещения',
      text: 'Способ\nвозмещения',
      type: 'Number',
    },
    {
      field: 'монетизация',
      text: 'Монетизация',
      type: 'Date',
    },
    {
      field: 'бюджет',
      text: 'Бюджет',
      type: 'Number',
    },
  ],
};
export default lg;
