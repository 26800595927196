
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      месяц: this.$store.getters.getCloseMonth(),
      лицевой: null,
      visible: false,
      dataModel: new this.$HttpModel('СервисныеОперацииФл'),
    };
  },
  methods: {
    async onSave(команда: string) {
      await this.dataModel.executeMethod('СменаБлокировкиРасчета', { команда, лицевой: this.лицевой, месяц: this.месяц }, { async: true, jobName: 'Смена блокировки расчета' });
      this.$emit('close');
    },
  },
});
