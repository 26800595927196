
import Vue from 'vue';
import basemethod from './basemethod.vue';

export default Vue.extend({
  extends: basemethod,
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      dataObject: new this.$HttpModel('СостоянияДела'),
    };
  },
  methods: {
    async changeСourtField(gpload: any) {
      if (gpload >= 0) {
        const rec = this.record;
        await this.dataObject.executeMethod('ПроверкаПолей', { поле: 'Суд', сдела: rec });
      }
    },
  },
});
