import { StackApi } from './StackApi';
import { StackSelectionCache } from '../../utils';
import { store } from '../../store';

export class HttpModel implements DataModel {
  public model: string;
  private http: StackApi;
  private sDef: StackSelection | undefined;
  private taskID: number | null = null;
  private data: StackTableRow[] = [];
  public prepareRow: any;
  public checkRow: any;

  get description(): string {
    return this.sDef && this.sDef.description ? this.sDef.description : '';
  }

  get hier(): boolean {
    return this.sDef && this.sDef.hier ? this.sDef.hier : false;
  }

  get fields(): StackField[] {
    return this.sDef && this.sDef.fields ? this.sDef.fields : [];
  }

  get rights(): apiRights | undefined {
    return this.http.getRights();
  }

  constructor(model: string) {
    this.model = model;
    this.http = new StackApi();
    this.sDef = StackSelectionCache.getSelectionDefinition(model);
    if (this.sDef && this.sDef.prepareRow) {
      this.prepareRow = this.sDef.prepareRow;
    }
    if (this.sDef && this.sDef.checkRow) {
      this.checkRow = this.sDef.checkRow;
    }
  }

  get length(): number {
    return this.taskID !== null ? this.http.getTaskCount(this.taskID) : 0;
  }

  public async executeReport?(reportName: string | undefined, params?: object): Promise<string> {
    this.http.setAsyncJob();
    try {
      const res = await this.executeMethod('печать', { имяОтчета: reportName, ...params }, { async: true, jobName: reportName, type: 'report' });
      if (!res.asyncId) {
        throw new Error(`Запуск отчета ${reportName} не удался`);
      }
      return res.asyncId;
    } catch (error: AnyException) {
      Promise.reject(error);
      return '';
    }
  }

  public async executeMethod(methodName: string, params?: object, options?: MetodOptions): Promise<any> {
    try {
      this.http.clear();
      // задача является асинхронной
      if (options && options.async) {
        this.http.setAsyncJob();
      }

      // Если нужна внешняя обработка от сообщений бэкэнда
      if (options && options.externalMsgHandler) {
        this.http.setNoInternalMsg();
      }

      this.taskID = this.http.fetch(this.model, params, methodName);
      await this.http.run();
      const result = this.http.getTaskResult(this.taskID);
      if (options && options.async && options.jobName) {
        const payload = {
          title: options.jobName,
          asyncId: result.asyncId,
          type: options.type,
          external: options.external,
          to: options.to,
          silent: options.silent,
          onChangeState: options.onChangeState,
          onComplete: options.onComplete,
          onError: options.onError,
        };
        store.commit('MSG_ADD', payload);
      }
      return result;
    } catch (error: AnyException) {
      return Promise.reject(error);
    }
  }

  public async getFolderID(params?: object): Promise<number | number[] | null> {
    const useCache = this.sDef && this.sDef.cached && this.sDef.hier;
    let res: any;
    if (useCache) {
      const cachedData = StackSelectionCache.getCacheByModel(this.model, params);
      if (cachedData) {
        res = cachedData;
        if (res && res[0] && res[0].$папка > 0) {
          return res[0].$папка;
        }
      }
    } else {
      this.http.clear();
      this.taskID = this.http.fetch(this.model, params, 'получитьПапкуЗаписи');
      await this.http.run();
      res = this.http.getTaskResult(this.taskID);
    }
    if (res && res.номерЗаписи > 0) {
      return res.номерЗаписи;
    }
    // таким образом прилетает массив, когда указано, что нужно получить всю иерархию
    if (res && Array.isArray(res.номерЗаписи)) {
      return res.номерЗаписи;
    }
    return null;
  }

  // @ts-ignore
  public async findRecord(findValue?: string, linkField?: string, развернутьПапки = true, показыватьУзлы = true): Promise<StackTableRow[]> {
    let fieldName;
    if (linkField) {
      fieldName = linkField;
    } else if (this.sDef) {
      fieldName = this.sDef.linkField;
    }

    if (fieldName) {
      return this.getRecords({ развернутьПапки: Number(развернутьПапки), показыватьУзлы: Number(показыватьУзлы), поискПоПолям: { [fieldName]: findValue } });
    }
    return [];
  }

  public async getRecords(params?: StackHttpRequestTaskParam): Promise<StackTableRow[]> {
    // const useCache = this.sDef && this.sDef.cached && (!params || Object.keys(params).length === 0);
    const useCache = this.sDef && this.sDef.cached && (!params || !params.развернутьПапки);
    const cacheParams: StackHttpRequestTaskParam = { ...params };
    let httpParams: StackHttpRequestTaskParam = { ...params };
    // если разрешено кэширование, то попытаемся найти в кэше
    if (useCache) {
      if (this.sDef && this.sDef.hier && !cacheParams.папка) {
        cacheParams.папка = -10;
      }
      let needInvalidate = false;
      if (params) {
        const newParams = StackSelectionCache.getTriggeredParams(params);
        const oldParams = StackSelectionCache.getCacheParamsByModel(this.model);
        needInvalidate = JSON.stringify(oldParams) !== JSON.stringify(newParams);
        // console.log('newParams !', newParams);
        // console.log('oldParams !', oldParams);
      }
      if (!needInvalidate) {
        const cachedData = StackSelectionCache.getCacheByModel(this.model, cacheParams);
        if (cachedData) {
          return cachedData;
        }
      } else {
        // console.log('needInvalidate !');
      }
      if (this.sDef && this.sDef.hier) {
        httpParams = { ...cacheParams, показыватьУзлы: 1, развернутьПапки: 1 };
      }
    }
    this.http.clear();
    this.taskID = this.http.fetch(this.model, httpParams);
    await this.http.run();
    this.data = this.http.getTaskRows(this.taskID);
    if (this.prepareRow) {
      this.data.forEach((row: StackTableRow) => {
        this.prepareRow(row);
      });
    }
    // кэшируем данные
    if (useCache) {
      StackSelectionCache.setCacheByModel(this.model, this.data, params);
      return StackSelectionCache.getCacheByModel(this.model, cacheParams) || [];
    }
    return this.data;
  }

  public getTotals(): StackTableRow | null {
    const totals = this.taskID !== null ? this.http.getTaskRows(this.taskID, true) : null;
    return totals && totals[0] ? totals[0] : null;
  }

  public async getQuestions(): Promise<StackHttpTaskQuestions | null> {
    return this.taskID !== null ? this.http.getTaskQuestions(this.taskID) : null;
  }

  public async createRecord(record: StackTableRow, params?: any): Promise<number | null> {
    try {
      this.http.clear();
      const taskID = this.http.updateRecord(this.model, record, 'вставить', params);
      await this.http.run();
      const res = this.http.getTaskResult(taskID);
      if (!res || res.номерЗаписи === undefined) {
        throw new Error('Запись не создана');
      }
      return typeof res.номерЗаписи === 'object' ? res.номерЗаписи[res.номерЗаписи.length - 1] : res.номерЗаписи;
    } catch (error: AnyException) {
      return null;
    }
  }

  public async saveRecord(record: StackTableRow, params?: any): Promise<boolean> {
    try {
      this.http.clear();
      this.taskID = this.http.updateRecord(this.model, record, 'обновить', params);
      await this.http.run();
      return true;
    } catch (error: AnyException) {
      return false;
    }
  }

  public async releaseLock(record: StackTableRow, params?: any): Promise<boolean> {
    try {
      this.http.clear();
      const pars = Object.assign({ номерЗаписи: record.$номерЗаписи }, params);
      this.taskID = this.http.updateRecord(this.model, {}, 'снятьБлокировку', pars);
      await this.http.run();
      return true;
    } catch (error: AnyException) {
      return false;
    }
  }

  public async saveRecords(records: StackTableRow[]) {
    try {
      this.http.clear();
      this.taskID = this.http.updateRecords(this.model, records);
      await this.http.run();
      return true;
    } catch (error: AnyException) {
      return false;
    }
  }

  public async deleteRecords(records: StackTableRow[], params?: any) {
    try {
      this.http.clear();
      records.forEach(record => {
        this.taskID = this.http.deleteRecord(this.model, record, params);
      });
      await this.http.run();

      return true;
    } catch (error: AnyException) {
      return false;
    }
  }

  public async moveRecords(records: StackTableRow[], params?: any) {
    try {
      this.http.clear();
      records.forEach(record => {
        this.taskID = this.http.moveRecord(this.model, record, params);
      });
      await this.http.run();

      return true;
    } catch (error: AnyException) {
      return false;
    }
  }

  public async moveRecord(record: StackTableRow, params?: any) {
    try {
      this.http.clear();
      this.taskID = this.http.moveRecord(this.model, record, params);
      await this.http.run();
      return true;
    } catch (error: AnyException) {
      return false;
    }
  }

  public async initRecord(params?: any): Promise<StackTableRow> {
    this.http.clear();
    this.taskID = this.http.fetch(this.model, params, params && params.$копировать ? 'копировать' : 'инициализировать');
    await this.http.run();
    this.data = this.http.getTaskRows(this.taskID);
    if (!this.data || !this.data[0]) {
      throw new Error('Инициализация записи не удалась');
    }
    return this.data[0];
  }
}
