
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
    noFormat: { type: Boolean, default: false },
  },
  data() {
    return {
      record: {
        услуга: null,
        ДатНач: this.$store.getters.getWorkMonth(),
        ДатКнц: this.$stackDate.lastDay(this.$store.getters.getWorkMonth()),
        контролер: this.value.контролер || undefined, // обязательно undefined, чтобы отчет случайно не построился без контролера
        ...this.value,
      },
    };
  },
});
