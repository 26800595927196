
import Vue from 'vue';
import PaymentSearchFilter from './$components/PaymentSearchFilter.vue';
import TransferDialog from '../$paylists/TransferDialog.vue';

export default Vue.extend({
  name: 'AktObhodForm',
  components: { PaymentSearchFilter, TransferDialog },
  data() {
    return {
      isDialogEnable: false,
      filter: { фильтр: {} },
    };
  },
  methods: {
    onFilter(filterOuter: any) {
      this.filter.фильтр = { filterEnable: true, ...filterOuter };
      // @ts-ignore
      this.$refs.table.onToolBarAction({ action: 'filter', payload: { filterEnable: true, ...filterOuter } });
    },
    onClearFilter() {
      this.$sendMsg('stack-table', 'ПлатежныеВедомости.ПоискПлатежей', 'clear_filter');
    },
    async openDoc(idDoc: number) {
      const routeData = this.$router.resolve({ path: `payments/paylists/${idDoc}`, name: this.$route.path });
      open(routeData.href, '_blank');
    },
  },
});
