/* eslint-disable camelcase */
import Channel from 'chnl';

function enumToOptions(e: { [key: string]: any }) {
  return Object.keys(e)
    .filter(k => typeof e[k as any] === 'number')
    .map(k => ({ key: k, value: e[k] }));
}

export enum SerialPortBaudrate {
  Baudrate_110 = 110,
  Baudrate_300 = 300,
  Baudrate_600 = 600,
  Baudrate_1200 = 1200,
  Baudrate_2400 = 2400,
  Baudrate_4800 = 4800,
  Baudrate_9600 = 9600,
  Baudrate_14400 = 14400,
  Baudrate_19200 = 19200,
  Baudrate_38400 = 38400,
  Baudrate_56000 = 56000,
  Baudrate_57600 = 57600,
  Baudrate_115200 = 115200,
  Baudrate_128000 = 128000,
  Baudrate_256000 = 256000,
}

export enum SerialPortDataBits {
  DataBits_5 = 5,
  DataBits_6 = 6,
  DataBits_7 = 7,
  DataBits_8 = 8,
}

export enum SerialPortParity {
  NoParity = 0,
  OddParity = 1,
  EvenParity = 2,
  MarkParity = 3,
  SpaceParity = 4,
}

export enum SerialPortStopBits {
  OneStopBit = 0,
  One5StopBits = 1,
  TwoStopBits = 2,
}

export enum SerialPortHandshake {
  Disable = 0,
  Hardware = 1,
  Software = 2,
}

export interface SerialPortOptions {
  portName: string;
  baudrate: SerialPortBaudrate;
  dataBits: SerialPortDataBits;
  parity: SerialPortParity;
  stopBits: SerialPortStopBits;
  handshake: SerialPortHandshake;
  terminator?: string;
}

export interface SerialPortEnumeratePortsResult {
  ports: [string];
}

interface Client {
  send: (method: string, params?: object) => Promise<any>;
  onEvent: (eventName: string) => Channel;
}

export class SerialPort {
  // tslint:disable: variable-name
  private _client: Client;
  private _onDataRecieved = new Channel();

  constructor(client: Client) {
    this._client = client;
    (this._client.onEvent('SerialPort.dataRecieved') as any).addListener((params: any) => this._onDataRecieved.dispatch(params.data));
  }

  public enumeratePorts(): Promise<SerialPortEnumeratePortsResult> {
    return this._client.send('SerialPort.enumeratePorts');
  }

  public open(options: SerialPortOptions) {
    return this._client.send('SerialPort.open', options);
  }

  public close() {
    return this._client.send('SerialPort.close');
  }

  get onDataRecieved() {
    return this._onDataRecieved;
  }

  static get baudrateOptions() {
    return enumToOptions(SerialPortBaudrate);
  }

  static get dataBitsOptions() {
    return enumToOptions(SerialPortDataBits);
  }

  static get parityOptions() {
    return enumToOptions(SerialPortParity);
  }

  static get stopBitsOptions() {
    return enumToOptions(SerialPortStopBits);
  }

  static get handshakeOptions() {
    return enumToOptions(SerialPortHandshake);
  }
}
