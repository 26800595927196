const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.Почта',
  description: '',
  fields: [
    {
      field: 'отправлено',
      text: 'Отправлено',
      type: 'String',
    },
    {
      field: 'кому',
      text: 'Кому',
      type: 'String',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'шаблон-сообщение',
      text: 'Шаблон-Сообщение',
      type: 'String',
    },
  ],
};
export default accs;
