
import Vue from 'vue';
import FileTable from '@/tasks/common/views/$common/fileTable.vue';

export default Vue.extend({
  components: { FileTable },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    dataModel: { type: Object, required: true },
    fileDataModel: { type: String, default: 'ЛицевыеСчета.КарточкиРегистрации.ВидыДокументов.Файлы' },
    withoutFile: { type: Boolean, default: false },
  },
  data() {
    return {
      rulesThis: {
        seriyaIsCorrect: (type: number, seriya: string): string | true => {
          if (type === 6) {
            const pattern = /^\d{2}[ ]?\d{2}$/;
            return pattern.test(seriya) || (seriya === '' ? 'Поле не может быть пустым' : 'Некорректная серия');
          }
          return true;
        },
        nomerIsCorrect: (type: number, nomer: string): string | true => {
          if (type === 6) {
            const pattern = /^\d{6}$/;
            return pattern.test(nomer) || (nomer === '' ? 'Поле не может быть пустым' : 'Некорректный номер');
          }
          return true;
        },
      },
    };
  },
  computed: {
    title(): string {
      return 'Документ';
    },
  },
  methods: {
    onChangeSeriya(seriya: string) {
      if (this.record.вид === 6) {
        //  добавляем в серию пробел, если отсутствует
        const pattern = /^\d{4}$/;
        if (pattern.test(seriya)) {
          this.record.серия = seriya[0] + seriya[1] + ' ' + seriya[2] + seriya[3];
        }
      }
    },
    onBtnChooseIssuePlace(issuePlace: StackTableRow) {
      if (issuePlace && issuePlace.название !== undefined && issuePlace.код) {
        this.record['код подразделения'] = issuePlace.код;
      }
    },
  },
});
