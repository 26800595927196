
import Vue from 'vue';

export default Vue.extend({
  props: {
    dark: { type: Boolean, default: false },
    searchOnType: { type: Boolean, default: false }, // сразу начинать быстрый поиск при нажатии на клавиатуру
    searchEnable: { type: Boolean, default: false }, // (Не)быстрый поиск вкл/выкл
    value: { type: [String, Number], default: undefined },
  },
  data() {
    return {
      search: '', // значение строки быстрого поиска
      delay: 1800, // задержка debounce
      wait: false,
      timeoutID: undefined as number | undefined | any,
    };
  },
  mounted() {
    if (this.searchOnType) {
      window.addEventListener('keypress', this.handleKeyup);
    }
  },
  beforeDestroy() {
    if (this.searchOnType) {
      window.removeEventListener('keypress', this.handleKeyup);
    }
  },
  methods: {
    handleKeyup(e: KeyboardEvent) {
      if (e.metaKey || e.ctrlKey || e.altKey || e.shiftKey || this.searchEnable) {
        return;
      }
      const allowedCharRegex = /[а-яА-Яa-zA-Z0-9]/;
      if (allowedCharRegex.test(e.key)) {
        this.search += e.key;
        e.stopPropagation();
        e.preventDefault();
        this.onBtnSearch();
      }
    },
    // кнопка поиска
    onBtnSearch() {
      this.$emit('search', true);
      this.$nextTick(() => {
        if (this.$refs.search) {
          // @ts-ignore
          this.$refs.search.focus();
        }
      });
    },
    onBlur() {
      if (!this.search) {
        this.$emit('search', false);
      }
    },
    emitSearch(to: string) {
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
      }
      this.search = to;
      this.$emit('input', this.search);
    },
    debounceEmit(to: string) {
      this.search = to;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => {
        this.$emit('input', to);
      }, this.delay);
    },
  },
  watch: {
    searchEnable(to) {
      if (to) {
        this.$nextTick(() => {
          if (this.$refs.search) {
            // @ts-ignore
            this.$refs.search.focus();
          }
        });
      }
    },
  },
});
