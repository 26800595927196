
import Vue from 'vue';
export default Vue.extend({
  computed: {
    HelpAction(): any {
      return this.$store.getters.HelpAction();
    },
    isTourEnable(): boolean {
      return this.$store.getters.isTourEnable();
    },
  },
  watch: {
    HelpAction() {
      if (this.refs.includes(this.HelpAction)) {
        setTimeout(() => {
          // @ts-ignore
          this.$refs[this.HelpAction].focus();
        }, 0);
      }
    },
  },
  data() {
    return {
      record: {
        input: '',
      } as StackTableRow,
      refs: ['textInput', 'dataInput', 'linkFieldInput', 'comboInput'] as string[],
    };
  },
  methods: {
    onClose() {
      this.$store.commit('SET_HELP_TOUR_STATE', false);
    },
  },
  mounted() {
    // @ts-ignore
    this.$refs.form.$parent.$el.classList.add('minimize-dialog-form');
    if (this.isTourEnable) {
      // TODO поправить
      // @ts-ignore
      this.$parent.$children[0].firstStep();
    }
  },
});
