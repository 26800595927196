
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      item: {
        ...this.value,
        Месяц: this.$store.getters.getWorkMonth(),
        'Вызов из веба': true,
      },
    };
  },
});
