const tars: StackSelection = {
  objectName: 'Услуги.Тарифы.ИндТарифы.Детализация',
  description: 'Калькуляция тарифа',
  fields: [
    {
      field: 'услуга-детализация',
      text: 'Номер услуги',
      type: 'Link',
      dataModel: 'Услуги',
      headers: 'наименование,номер услуги',
      labelField: record => {
        return '№' + record['номер услуги'] + ' ' + record.название;
      },
      hier: true,
      width: '300px',
    },
    {
      field: 'значение',
      text: 'Тариф',
      type: 'Money',
      align: 'left',
    },
    {
      field: 'услуга-детализация>наименование',
      text: 'Название',
      type: 'String',
      align: 'center',
    },
  ],
};

export default tars;
