
import Vue from 'vue';
import datePeriodFilter from './datePeriodFilter.vue';

export default Vue.extend({
  components: { datePeriodFilter },
  props: {
    accountID: { type: Number, required: true },
  },
  data() {
    return {
      params: { лицевой: this.accountID },
      item: {
        датнач: null,
        даткнц: null,
        аналитика: -1,
      } as any,
    };
  },
});
