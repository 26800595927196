
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      rules: {
        isRequired: (value: number): string | true => {
          return value !== 0 || 'Введите сумму';
        },
      },
    };
  },
});
