
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      filter: {
        нп: null,
        пструктура: null,
        улица: null,
        дом: null,
        квартира: null,
        комната: null,
        фионанимателя: null,
        номерлс: null,
        источник: null,
        суммаплат_с: null,
        суммаплат_по: null,
        дата_с: this.$stackDate.firstDay(this.$store.getters.getCloseMonth()),
        дата_по: null,
        идентификатортранзакции: null,
      },
    };
  },
  computed: {
    showPs(): boolean {
      const data = this.$store.getters['flStore/getlsHierTypes'] && this.$store.getters['flStore/getlsHierTypes']();
      return data?.some((row: StackTableRow) => row.type === 10);
    },
  },
  methods: {
    clearFilter() {
      this.filter.нп = null;
      this.filter.пструктура = null;
      this.filter.улица = null;
      this.filter.дом = null;
      this.filter.квартира = null;
      this.filter.комната = null;
      this.filter.фионанимателя = null;
      this.filter.номерлс = null;
      this.filter.источник = null;
      this.filter.суммаплат_с = null;
      this.filter.суммаплат_по = null;
      this.filter.дата_с = this.$stackDate.firstDay(this.$store.getters.getCloseMonth());
      this.filter.дата_по = null;
      this.filter.идентификатортранзакции = null;
      this.$emit('clearFilter');
    },
    cleanupAdr(id: number, type: number) {
      if (!id) {
        switch (type) {
          case 12:
            this.filter.пструктура = null;
            this.filter.улица = null;
            this.filter.дом = null;
            this.filter.квартира = null;
            this.filter.комната = null;
            break;
          case 2:
            this.filter.дом = null;
            this.filter.квартира = null;
            this.filter.комната = null;
            break;
          case 3:
            this.filter.квартира = null;
            this.filter.комната = null;
            break;
          case 10:
            this.filter.улица = null;
            this.filter.дом = null;
            this.filter.квартира = null;
            this.filter.комната = null;
            break;
        }
      }
    },
  },
});
