
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: 'полноеимя',
          text: 'Название',
          type: 'String',
        },
        {
          field: 'значение0',
          text: 'до 5 этажей',
          type: 'Number',
        },
        {
          field: 'значение1',
          text: '2от 6 до 9',
          type: 'Number',
        },
        {
          field: 'значение2',
          text: 'от 10 до 16',
          type: 'Number',
        },
        {
          field: 'значение3',
          text: 'более 16 этажей',
          type: 'Number',
        },
      ],
    };
  },
});
