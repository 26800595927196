// Иконки ФЛ
export default {
  // Главное меню
  // accounts: require(`@/assets/svg/contract_ctrl.svg`), // Адреса проживающих
  // reports: require(`@/assets/svg/reports.svg`), // Отчеты
  // ci_doc_center_info: require(`@/assets/svg/centr_informirovaniya.svg`), // Центр информирования
  // master_vvod_pok_ipu: require(`@/assets/svg/vvod_pokazaniy_IPU.svg`), // Массовый ввод показаний ИПУ
  // master_vvod_pok_odpu: require(`@/assets/svg/vvod_pokazaniy_ODPU.svg`), // Массовый ввод показаний ОДПУ
  // plat_vedom: require(`@/assets/svg/payvedom.svg`), // Платежные ведомости

  master_add_ls: require(`@/assets/svg/bystroye_dobavleniye_fonda.svg`), // Быстрый ввод фонда
  master_add_ipu: require(`@/assets/svg/bystryy_vvod_ipu.svg`), // Быстрый ввод ИПУ
  master_split_ls: require(`@/assets/svg/master_deleniya_ls.svg`), // Мастер деления ЛС
  master_mass_input: require(`@/assets/svg/master_mass_vvoda.svg`), // Массовый ввод параметров/услуг и тд
  window_operator: require(`@/assets/svg/window_operator.svg`), // Окно оператора

  // Меню Платежи
  plat_vedom: require(`@/assets/svg/payvedom.svg`), // Платежные ведомости
  bank_raspredeleniye_oplaty: require(`@/assets/svg/raspredeleniye_oplaty.svg`), // Распред. оплаты (реестр)
  bank_vipiski: require(`@/assets/svg/vypiski_iz_banka.svg`), // Выписки из банка
  bank_file_import: require(`@/assets/svg/priyem_platezhey.svg`), // Прием платежей из файлов
  bank_file_import_log: require(`@/assets/svg/zhurnal_reyestrov_platezhey.svg`), // Журнал приема платежей
  bank_pay_fee: require(`@/assets/svg/yezhednevnoye_raspredeleniye_oplaty.svg`), // Ежедн. распределение оплаты

  // Приборы учёта
  master_vvod_pok_ipu: require(`@/assets/svg/vvod_pokazaniy_IPU.svg`), // Массовый ввод показаний ИПУ
  master_vvod_pok_odpu: require(`@/assets/svg/vvod_pokazaniy_ODPU.svg`), // Массовый ввод показаний ОДПУ
  import_pokaz: require(`@/assets/svg/priyem_storonnikh_pokazaniy.svg`), // Прием показаний из файла
  export_pokaz_csv: require(`@/assets/svg/vygruzka_pokazaniy_csv.svg`), // выгрузка для показаний (CSV)
  reestr_plomb: require(`@/assets/svg/reyestr_plobm.svg`), // Реестр пломб
  doc_ipu: require(`@/assets/svg/pokazvedom.svg`), // Ведомости ИПУ показаний
  doc_odpu: require(`@/assets/svg/doc_odpu.svg`), // Документы ОДПУ
  doc_ext_pok: require(`@/assets/svg/pokproblem.svg`), // Сторонние показания
  doc_ipu_change: require(`@/assets/svg/akty_zameny_IPU.svg`), // Акты замены ИПУ
  import_pokaz_otop: require(`@/assets/svg/prinyat_pokaz_po_raspred.svg`), // Прием показаний распределителей

  // Документы
  doc_quality_acts: require(`@/assets/svg/doc_acty_kachestva.svg`), // Акты качества
  doc_nach_once: require(`@/assets/svg/doc_nach_razovie.svg`), // Разовые начисления
  doc_saldo_corr: require(`@/assets/svg/doc_saldo_korr.svg`), // Коррекция сальдо
  doc_installment_agreements: require(`@/assets/svg/directorys.svg`), // Документы рассрочки
  doc_offense: require(`@/assets/svg/doc_acty_narushenyi.svg`), // Нарушения абонентов
  doc_debtors: require(`@/assets/svg/doc_acty_dolzhnikov.svg`), // Акты по должникам
  doc_inspections: require(`@/assets/svg/doc_acty_proverki.svg`), // Акты по должникам
  doc_abon_requests: require(`@/assets/svg/zayavki_abonentov.svg`), // Заявки абонентов
  doc_sup_agreements: require(`@/assets/svg/contract_res.svg`), // Договоры с поставщиками
  doc_uk_agreements: require(`@/assets/svg/contract_uk.svg`), // Договоры с УК
  ved_uderzh: require(`@/assets/svg/ved_uderzh.svg`), // Ведомости удержаний
  doc_reestrs: require(`@/assets/svg/registry.svg`), // Реестры лицевых

  // Операции
  action_exports: require(`@/assets/svg/data_export.svg`), // Выгрузки в файлы
  action_imports: require(`@/assets/svg/data_import.svg`), // Подкачки из файлов
  action_custom: require(`@/assets/svg/individualnyye_operatsii.svg`), // Инд. операции
  action_service: require(`@/assets/svg/sluzhebnyye_operatsii.svg`), // Служебные операции
  action_service2: require(`@/assets/svg/obshchiye_nastroyki_po.svg`), // Специальные операции
  master_saldo_corr: require(`@/assets/svg/master_korrektsii_saldo.svg`), // Мастер коррекции сальдо
  mail_client: require(`@/assets/svg/pochtovyy_kliyent.svg`), // Почтовый клиент
  ezhem_raschet_kompensacii: require(`@/assets/svg/ezhem_raschet_kompensacii.svg`), // Ежемесячный расчет компенсации
  formact_obh: require('@/assets/svg/form_akty_proverki.svg'), // Формирование актов проверки

  // ГИС
  gis_export: require(`@/assets/svg/master_eksporta_v_stack-integr.svg`), // Экспорт в интеграцию
  gis_checks: require(`@/assets/svg/pravila_vedeniya_bazy_dlya_vygruzki_gis.svg`), // Реестр проверки правил ведения ГИС
  gis_import: require(`@/assets/svg/data_import.svg`), // Импорт из интеграции
  gis_export_online_kassa: require(`@/assets/svg/export_dlya_online_kass.svg`), // Экспорт онлайн касс

  // Центр информирования
  ci_doc_center_info: require(`@/assets/svg/reestr_kampanii.svg`), // Реестр кампаний
  ci_shablons: require(`@/assets/svg/spr_info_shablonci.svg`), // Шаблоны центра информирования
  ci_media_files: require(`@/assets/svg/spr_mediafayly_dlya_centra_informirovaniya.svg`), // Медиафайлы центра информирования

  // Справочники
  spr_services: require(`@/assets/svg/spr_uslugi.svg`), // Услуги
  spr_services_cat: require(`@/assets/svg/spr_kategorii_uslug.svg`), // Категории услуг
  spr_param_kinds: require(`@/assets/svg/spr_vidpar.svg`), // Виды параметров
  spr_consts: require(`@/assets/svg/spr_const.svg`), // Константы
  spr_meters_types: require(`@/assets/svg/spr_typesmeteringdevices.svg`), // Типы ИПУ (номенклатура)
  spr_аddresses: require(`@/assets/svg/spr_streets.svg`), // Справочник адресов
  spr_lgots: require(`@/assets/svg/spr_lgoty.svg`), // Виды льгот
  spr_lgots_cat: require(`@/assets/svg/spr_kategorii_lgot.svg`), // Категории льгот
  spr_paramobj_kinds: require(`@/assets/svg/spr_vidy_parametrov_obyektov.svg`), // Виды параметров объектов
  spr_org: require(`@/assets/svg/spr_organizations.svg`), // Организации
  spr_org_cat: require(`@/assets/svg/spr_kategorii_organizations.svg`), // Категории организаций
  spr_ist_plat: require(`@/assets/svg/spr_istplat.svg`), // Источники платежей
  spr_post: require(`@/assets/svg/spr_post.svg`), // Поставщики
  spr_uk: require(`@/assets/svg/spr_uk.svg`), // УК
  spr_banks: require(`@/assets/svg/spr_banks.svg`), // Банки
  spr_analit: require(`@/assets/svg/spr_analitiki.svg`), // Виды аналитики
  spr_rules: require(`@/assets/svg/spr_rules.svg`), // Правила операций
  spr_calc_params: require(`@/assets/svg/spr_parameterscalc.svg`), // Параметры расчета
  spr_recalc_kinds: require(`@/assets/svg/spr_prichpererasch.svg`), // Причины перерасчетов
  spr_relationships: require(`@/assets/svg/spr_relationship.svg`), // Виды родства
  spr_kvit_messages: require(`@/assets/svg/spr_kvit_messages.svg`), // Сообщения для квитанций
  spr_usl_sootv: require(`@/assets/svg/spr_sootvetstviye_uslug.svg`), // Соответствие услуг
  spr_opl_discont_kinds: require(`@/assets/svg/spr_vidy_snizheniya_oplaty.svg`), // Виды снижения оплаты
  spr_services_other: require(`@/assets/svg/spr_storonniye_uslugi.svg`), // Сторонние услуги
  spr_document_place: require(`@/assets/svg/spr_mesta_vydachi_dokumentov.svg`), // Места выдачи документов
  spr_people: require(`@/assets/svg/spr_chastn_liсa.svg`), // Справочник частные лица
  spr_citizenship: require(`@/assets/svg/spr_grazhdanstvo.svg`), // Гражданство
  spr_osnvladen: require(`@/assets/svg/spr_osnov_vladeniya.svg`), // Основания владения
  spr_filter_ls: require(`@/assets/svg/spr_filter_ls.svg`), // Фильтры ЛС
  spr_doc_types: require(`@/assets/svg/spr_typesofdocs.svg`), // Типы документов

  // Закрытие месяца
  action_close_month: require(`@/assets/svg/closemonth.svg`), // Закрытие месяца
  action_open_month: require(`@/assets/svg/otkrytiye_razblokirovka_mesyatsa.svg`), // Открытие месяца
  action_kvit_print: require(`@/assets/svg/kvit_massovaya.svg`), // Печать квитанций
  action_reestr_for_close_settings: require(`@/assets/svg/nastroyka_reyestra_zakrytiya_mesyatsa.svg`), // Реестр закрытия месяца

  // Настройки
  settings_main: require(`@/assets/svg/obshchiye_nastroyki_po.svg`), // Общие настройки
  kvit_layouts: require(`@/assets/svg/makety_kvitantsiy.svg`), // Макеты квитанций
  settings_dlg: require(`@/assets/svg/nastroyka_arm_yurista.svg`), //  Настройки арм юриста
  other_reestr_uk: require(`@/assets/svg/reyestry_uk.svg`), // реестры по УК
  other_reestr_multi_operations: require(`@/assets/svg/reyestr_mnogoprotsessnykh_operatsiy.svg`), // Реестр многопроц. операций

  // иконки информации о задолженности
  info_agreement_closed: require(`@/assets/svg/debtInfo/info_agreement_closed.svg`),
  info_agreement_open: require(`@/assets/svg/debtInfo/info_agreement_open.svg`),
  info_lawsuit_closed: require(`@/assets/svg/debtInfo/info_lawsuit_closed.svg`),
  info_lawsuit_open: require(`@/assets/svg/debtInfo/info_lawsuit_open.svg`),
  info_pretension_closed: require(`@/assets/svg/debtInfo/info_pretension_closed.svg`),
  info_pretension_open: require(`@/assets/svg/debtInfo/info_pretension_open.svg`),
  info_restrictions_closed: require(`@/assets/svg/debtInfo/info_restrictions_closed.svg`),
  info_restrictions_open: require(`@/assets/svg/debtInfo/info_restrictions_open.svg`),

  guids_on_homes: require(`@/assets/svg/guids_on_homes.svg`), // Мастер простановки гуида
} as ImagePack;
