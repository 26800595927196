
import Vue from 'vue';

export default Vue.extend({
  props: {
    reportName: { type: String, default: '' },
  },
  data() {
    return {
      menuIsVisible: false,
      folderName: 'Новая папка',
      folderDialogVis: false,
      folderGroup: {} as { [key: string]: Boolean },
    };
  },
  computed: {
    isFavoriteReport(): boolean {
      return !!Object.values(this.folderGroup).filter(item => item).length;
    },
    reportNodeFolders(): any {
      return this.reportNode.children.filter((item: StackReportSelections) => item.children);
    },
    reportNode(): any {
      return this.$store.getters.getFavoriteReportNode();
    },
    folderGroupSync(): { [key: string]: Boolean } {
      const folderGroup = {} as { [key: string]: Boolean };
      folderGroup[''] = !!this.reportNode.children.filter((item: any) => item.name === this.reportName).length;
      this.reportNodeFolders.forEach((child: any) => {
        folderGroup[child.folderId] = !!child.children.filter((item: any) => item.name === this.reportName).length;
      });
      return folderGroup;
    },
  },
  created() {
    this.folderGroup = { ...this.folderGroupSync };
  },
  beforeDestroy() {
    this.changeStore();
  },
  methods: {
    changeStore() {
      const reportChanges = [] as { name: string, folderId: string, remove: boolean }[];
      Object.keys(this.folderGroup).forEach(folderId => {
        if (!!this.folderGroup[folderId] !== !!this.folderGroupSync[folderId]) {
          reportChanges.push({ name: this.reportName, folderId, remove: !this.folderGroup[folderId] });
        }
      });
      this.$store.commit('SET_FAVORITE_REPORT', reportChanges);
    },
    createFolder() {
      this.$store.commit('SET_FAVORITE_REPORT', [{ name: this.folderName, createFolder: true }]);
      this.$store.commit('SET_FAVORITE_REPORT', [{ name: this.reportName, folderId: this.reportNodeFolders.pop().folderId }]);
      this.folderName = 'Новая папка';
      this.folderDialogVis = false;
      this.menuIsVisible = true;
    },
  },
  watch: {
    menuIsVisible(to) {
      if (!to) {
        this.changeStore();
      } else {
        this.folderGroup = { ...this.folderGroupSync };
      }
    },
  },
});
