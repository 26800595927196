
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
    params: {
      type: Object,
      default: (): any => {
        return {};
      },
    },
  },
  data() {
    return {
      item: {
        ...this.value,
        'Вызов из веба': true,
        МесНач: this.$stackDate.format(this.$stackDate.addYears(this.$store.getters.getWorkMonth(), -1).toISOString(), 'ISO'),
        МесКнц: this.$store.getters.getWorkMonth(),
        РасчМесяц: this.$store.getters.getWorkMonth(),
        ВидДокСоб: 1,
        ПараметрыОтчета: 1,
      },
      isLoading: false,
      valid: true,
    };
  },
  mounted() {
    this.checkMonth();
  },
  methods: {
    async checkMonth() {
      this.isLoading = true;
      const period = await new this.$HttpModel('ЛицевыеСчета').executeMethod('ПолучитьПериодКорректировкиОтопления', { лицевой: this.value.номерЗаписи, месяц: this.item.РасчМесяц });
      if (period) {
        this.item['МесНач'] = period['МесНач'];
        this.item['МесКнц'] = period['МесКнц'];
        this.valid = true;
      } else {
        this.valid = false;
      }
      this.isLoading = false;
    },
  },
});
