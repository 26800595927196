
import Vue from 'vue';
import paramTable from '@/tasks/common/views/$common/paramTable.vue';
import dialogAccount from './$dialogs/dialogAccount.vue';
import dialogKvit from './$dialogs/dialogKvit.vue';
import dialogParamCalc from './$dialogs/dialogParamCalc.vue';
import FileTable from '@/tasks/common/views/$common/fileTable.vue';
import schemeDialog from './$dialogs/schemeDialog.vue';
import schemeDialogShort from './$dialogs/schemeDialogShort.vue';
import { TableHandlers } from '@/stackEngine';

export default Vue.extend({
  components: { dialogAccount, dialogKvit, dialogParamCalc, FileTable, paramTable, schemeDialog, schemeDialogShort },
  props: {
    currentid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
    /**
     * 0 - договоры УК в РСП
     * 1 - договоры УК в ФЛ
     * 2 - договоры с постащиками в РСП
     * 3 - договоры поставщиками в ФЛ
     * 4 - договоры на прием платежей в РСП
     */
    type: { type: Number, default: 0 },
    dataModel: { type: String, default: 'ДоговорыУК' },
  },
  data() {
    return {
      record: {} as StackTableRow,
    };
  },
  computed: {
    title(): string {
      return `Договор`;
    },
    typeDogDataModel(): string {
      return `${this.dataModel}.ВидДоговора`;
    },
    routeService(): string {
      switch (this.type) {
        case 2:
          return `contracts-resources-resourcesid-service-serviceid`;
        case 4:
          return `contracts-payments-paymentsid-service-serviceid`;
        default:
          return '';
      }
    },
    routeGoingpay(): string {
      switch (this.type) {
        case 2:
          return `contracts-resources-resourcesid-goingpay-goingpayid`;
        case 4:
          return `contracts-payments-paymentsid-goingpay-goingpayid`;
        default:
          return '';
      }
    },
    routeAdministrativeLetters(): string {
      switch (this.type) {
        case 2:
          return `contracts-resources-resourcesid-administrativeletters-administrativelettersid`;
        case 4:
          return `contracts-payments-paymentsid-administrativeletters-administrativelettersid`;
        default:
          return '';
      }
    },
    accountsDataModel(): string {
      return `${this.dataModel}.Объекты`;
    },
    typeContract(): string {
      return this.type > 1 ? 'resources' : 'managements';
    },
    noService(): boolean {
      const typeDog = this.record['вид договора'] ? this.record['вид договора'] : 0;
      return this.type < 2 && +typeDog === 0;
    },
  },
  methods: {
    calcHandler(item: StackTableRow) {
      if (item && item.$data) {
        if (item.$этоПапка) {
          item.$data.номеруслуги.$иконка = '$vuetify.icons.folder';
          item.вычисляемоеназвание = item.названиеуслуги + '; Поставщик - ' + item['поставщик>название'];
        } else {
          if (item['рпоставщик>название']) {
            item.вычисляемоеназвание = ' Получатель - ' + item['рпоставщик>название'] + '; Р/с - ' + item['р/счет'];
          }
        }
      }
      return item;
    },
    accountsHandler(item: StackTableRow) {
      if (!item.даткнц || (item.даткнц && item.даткнц < this.$store.getters.getOpenMonth())) {
        TableHandlers.trColor(item, 'grey');
      }
      return item;
    },
  },
});
