
import Vue from 'vue';

export default Vue.extend({
  name: 'SelectionAbonents',
  props: {
    campaign: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      record: {
        ls: null,
        summaFrom: 0,
        summaBefore: 0,
        debtors: false,
      },
    };
  },
  computed: {
    checkDisabled(): boolean {
      return !this.record.debtors;
    },
  },
  methods: {
    async onClick() {
      const jobID = (
        await new this.$HttpModel('ЦентрИнформирования.Вызовы').executeMethod(
          'Рассчитать',
          { владелец: this.campaign.$номерЗаписи, запись: this.record },
          { async: true, jobName: 'Отбор лицевых в кампанию ' + this.campaign.номер, to: '/infocenter/campaigns/' + this.campaign.$номерЗаписи },
        )
      ).asyncId;
      this.$emit('save', jobID);
    },
  },
});
