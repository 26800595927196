const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.Перерасчеты',
  description: '',
  fields: [
    {
      field: 'номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'название услуги',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'аналитика1',
      text: 'Аналитика1',
      type: 'String',
    },
    {
      field: 'использовантариф',
      text: 'Тариф',
      type: 'Number',
    },
    {
      field: 'использовананорма',
      text: 'Норма',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: 'Сумма,₽',
      type: 'Money',
    },
    {
      field: 'номер льготы',
      text: 'Льгота',
      type: 'Number',
    },
    {
      field: 'перерасчет',
      text: 'За',
      type: 'DateMonth',
    },
    {
      field: 'акт качества',
      text: 'Акт качества',
      type: 'Number',
    },
    {
      field: 'объем',
      text: 'Объем,V',
      type: 'Number',
    },
    {
      field: 'тип',
      text: 'Тип перерасчета',
      type: 'String',
    },
    {
      field: 'пользователи',
      text: 'Пользователи',
      type: 'Number',
    },
    {
      field: 'поставщик',
      text: 'Поставщик',
      type: 'Number',
    },
    {
      field: 'документ',
      text: 'Документ',
      type: 'Number',
    },
    {
      field: 'месяц расчета',
      text: 'Месяц расчета',
      type: 'DateMonth',
    },
    {
      field: 'аналитика2',
      text: 'Аналитика',
      type: 'String',
    },
    {
      field: 'аналитикастр',
      text: 'Аналитика',
      type: 'String',
    },
    {
      field: 'поставщикстр',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'номердог',
      text: 'Договор',
      type: 'String',
    },
    {
      field: 'ук',
      text: 'Исполнитель',
      type: 'String',
    },
  ],
};
export default accs;
