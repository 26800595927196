const rules: StackSelection = {
  recordRoute: 'references-rules-rulesid',
  folderRoute: 'frules',
  objectName: 'Правила операций',
  description: 'Правила',
  hier: true,
  fields: [
    {
      field: 'ключевая фраза',
      text: 'Ключевая фраза',
      type: 'String',
    },
    {
      field: 'имя',
      text: 'Имя',
      type: 'String',
    },
    {
      field: 'расширение правил>исходный текст',
      text: 'Расширение правил>Исходный текст',
      type: 'String',
    },
    {
      field: 'признак',
      text: 'Признак',
      type: 'Number',
    },
  ],
};

export default rules;
