// (c) https://gist.github.com/zmts/b26ba9a61aa0b93126fc6979e7338ca3
// https://gist.github.com/zmts/802dc9c3510d79fd40f9dc38a12bccfc

// @ts-ignore
import * as fp from '@fingerprintjs/fingerprintjs/dist/fingerprint2.min.js';
// @ts-ignore
import * as UAParser from 'ua-parser-js';

export function getFingerprint() {
  return new Promise((resolve, reject) => {
    async function getHash() {
      const options = {
        excludes: {
          plugins: true,
          localStorage: true,
          adBlock: true,
          screenResolution: true,
          availableScreenResolution: true,
          enumerateDevices: true,
          pixelRatio: true,
          doNotTrack: true,
          preprocessor: (key: any, value: any) => {
            if (key === 'userAgent') {
              const parser = new UAParser(value);
              // return customized user agent (without browser version)
              return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${parser.getEngine().name}`;
            }
            return value;
          },
        },
      };
      try {
        const components = await fp.getPromise(options);
        const values = components.map((component: any) => component.value);
        // console.log('fingerprint hash components', components);
        return String(fp.x64hash128(values.join(''), 31));
      } catch (e: AnyException) {
        reject(e);
      }
    }
    // @ts-ignore
    if (window.requestIdleCallback) {
      // console.log('get fp hash @ requestIdleCallback');
      // @ts-ignore
      requestIdleCallback(async () => resolve(await getHash()));
    } else {
      // @ts-ignore
      // console.log('get fp hash @ setTimeout');
      setTimeout(async () => resolve(await getHash()), 500);
    }
  });
}