import { TableHandlers } from '@/stackEngine';
const acts: StackSelection = {
  objectName: 'ПочтовыйКлиент.Сообщения',
  description: '',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    switch (row.состояние) {
      case '6':
        // @ts-ignore
        row.$data.состояние = { $иконка: '$vuetify.icons.email_open' };
        break;
      case '5':
        // @ts-ignore
        row.$data.состояние = { $иконка: '$vuetify.icons.email_off', $иконка_цвет: 'error' };
        break;
      case '8':
        // @ts-ignore
        row.$data.состояние = { $иконка: '$vuetify.icons.email_resend' };
        break;
      case '3':
        // @ts-ignore
        row.$data.состояние = { $иконка: '$vuetify.icons.email_reply', $иконка_цвет: 'warning' };
        break;
      case '0':
        // @ts-ignore
        row.$data.состояние = { $иконка: '$vuetify.icons.email_сlose' };
        if (!row.$этоПапка) {
          TableHandlers.stackStyle(row, ';Шрифт*,Ж');
        }
        break;
      default:
        break;
    }

    if (row.вложение !== '0') {
      // @ts-ignore
      row.$data.вложение = { $иконка: '$vuetify.icons.paperclip' };
    }
    row.вложение = '';
    row.состояние = '';
    row['@адреслс'] = row['лицевой-сообщения>адреслс'] !== '' ? row['лицевой-сообщения>адреслс'] + '(ЛС' + row['лицевой-сообщения>номер'] + ')' : '';
    return row;
  },
  fields: [
    {
      field: 'откого',
      text: 'От кого',
      type: 'String',
    },
    {
      field: 'кому',
      text: 'Кому',
      type: 'String',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'состояние',
      text: '',
      type: 'String',
      width: '20px',
    },
    {
      field: 'блокировать',
      text: ' ',
      type: 'String',
      width: '20px',
    },
    {
      field: 'вложение',
      text: '  ',
      width: '20px',
      type: 'String',
    },
    {
      field: 'обработано',
      text: '   ',
      type: 'String',
      width: '20px',
    },
    {
      field: 'отб_создано',
      text: 'Создано',
      type: 'String',
    },
    {
      field: 'отб_получено',
      text: 'Получено',
      type: 'String',
    },
    {
      field: 'тело',
      text: 'тело',
      type: 'String',
    },
    {
      field: '@тело',
      text: '@тело',
      type: 'String',
    },
    {
      field: 'лицевой-сообщения>адреслс',
      text: 'Адрес ЛС',
      type: 'String',
    },
    {
      field: 'лицевой-сообщения>номер',
      text: 'Номер ЛС',
      type: 'String',
    },
    {
      field: '@адреслс',
      text: 'Адрес ЛС',
      type: 'String',
      computed: true,
    },
    {
      field: 'отб_отправлено',
      text: 'Отправлено',
      type: 'String',
    },
    {
      field: '@обращениякц',
      text: 'Обращения',
      type: 'String',
    },
    {
      field: 'организация-сообщения',
      text: 'Организация',
      type: 'Number',
    },
  ],
};
export default acts;
