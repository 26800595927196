
import Vue from 'vue';
import stStep from './step.vue';

export default Vue.extend({
  components: {
    stStep,
  },
  props: {
    isComplete: { type: Boolean, default: false },
    routeParams: { type: Object, required: true },
  },
  computed: {
    multyThreads(): boolean {
      return this.$store.getters['flStore/getMultyThreads']() > 1;
    },
    asyncIdCalc: {
      get(): string {
        return this.$route.query.asyncIdCalc ? this.$route.query.asyncIdCalc.toString() : '';
      },
      set(newValue: string) {
        const query = { ...this.$route.query };
        query.asyncIdCalc = newValue;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.$router.push({ query }).catch((e) => {});
      },
    },
  },
  data() {
    return { loading: false, showMultiDialog: false };
  },
  methods: {
    async onCalc() {
      try {
        if (!this.routeParams.fund || !this.routeParams.month) {
          this.$toast('Не найдены указанные', { color: 'error' });
        } else {
          this.loading = true;
          const asyncIdCalc = (
            await new this.$HttpModel('ЛицевыеСчета').executeMethod(
              'Рассчитать',
              { номерЗаписи: this.routeParams.fund, месяц: this.routeParams.month },
              {
                async: true,
                jobName: 'Расчет лицевых',
                type: 'multi',
                onComplete: () => {
                  this.loading = false;
                },
                onError: () => {
                  this.loading = false;
                },
              },
            )
          ).asyncId;
          const query = { ...this.$route.query };
          query.asyncIdCalc = asyncIdCalc;
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          this.$router.push({ query }).catch((e) => {});
        }
      } catch (error: AnyException) {
        this.$toast('Ошибка расчета', { color: 'error' });
        this.loading = false;
      }
    },
  },
});
