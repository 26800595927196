
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        'Параметры.ДатаВызова': this.$stackDate.addDays(this.$store.getters.getCurrentDate(), 2),
        'Параметры.ВремяВызова': '08:00',
        'Параметры.Месяц': this.$store.getters.getWorkMonth(),
        'Параметры.МесДо': 0,
        'Параметры.Услуга': -1,
        'Параметры.КатегорияУслуг': -1,
        'Параметры.Поставщик': -1,
        'Параметры.КатегорияПоставщиков': -1,
        'Параметры.УК': -1,
        'Параметры.ДержателиДолга': -1,
        'Параметры.Сумма': 0,
        'Параметры.От': 0,
        'Параметры.До': 0,
        'Параметры.Флаги': 1,
        'Параметры.фУчетОплат': 0,
      },
    };
  },
});
