
import Vue from 'vue';
import FileTable from '@/tasks/common/views/$common/fileTable.vue';

export default Vue.extend({
  components: { FileTable },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    idLS: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      rulesThis: {
        isRequired: (value: string, sost: number): string | true => {
          if (sost === 0 || sost === 1) {
            return true;
          } else {
            return (value !== undefined && value !== null && value !== '') || 'Для состояния Выполнено и Отказ необходимо указать дату завершения';
          }
        },
      },
    };
  },
  methods: {
    fetchLS(payload: any) {
      if (payload && payload[0]) {
        this.record.заявитель = payload[0]['счет-наниматель>фио'];
      }
    },
  },
});
