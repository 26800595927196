
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record' },
  props: {
    ownerID: { type: Number, required: true },
    type: { type: Number, required: true },
    share: { type: Number, required: true },
  },
  computed: {
    headers(): string {
      if (this.type === 3) {
        return 'датаизменения,остдолг,уточндолг,автор фазы>фио';
      } else if (this.type === 2 && this.share !== 0) {
        return 'датаизменения,остдолг,остдолгдоля,остпроц,остпроцдоля,остгоспошл,остпрочие,уточндолг,уточндолгдоля,уточнпроц,уточнпроцдоля,уточнгоспошл,уточнпрочие,списанопосуду,автор фазы>фио';
      }
      return 'датаизменения,остдолг,остпроц,остгоспошл,остпрочие,уточндолг,уточнпроц,уточнгоспошл,уточнпрочие,списанопосуду,автор фазы>фио';
    },
  },
});
