
import Vue from 'vue';

export default Vue.extend({
  name: 'ReadingsTable',
  props: {
    record: { type: Object, required: true },
    ownerID: { type: [Number, String], required: true },
    month: { type: [Date, String], default: null },
    date: { type: [Date, String], default: null },
  },
  computed: {
    inputFilter(): any {
      return { владелец: this.ownerID, толькорабочие: 0, окпу: true };
    },
    inputData(): any {
      return { дата: this.дата, месяц: this.месяц, типввода: 1 };
    },
  },
  data() {
    return {
      дата: this.date ? this.date : this.$store.getters.getCurrentDate(),
      месяц: this.month ? this.month : this.$stackDate.firstDay(this.$store.getters.getCurrentDate()),
    };
  },
});
