
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: { type: String, default: 'Судебный номер' },
  },
  data() {
    return {
      record: {
        номер: '',
        тип: 0,
      } as any,
    };
  },
});
