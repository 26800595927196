const acts: StackSelection = {
  objectName: 'ПочтовыйКлиент.Шаблоны',
  description: 'Шаблоны сообщений',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'транспорт-шаблон>название',
      text: 'Ящик',
      type: 'String',
    },
    {
      field: 'транспорт-шаблон',
      text: 'Ящик id',
      type: 'Link',
    },
    {
      field: '@текст',
      text: 'текст',
      type: 'String',
    },
    {
      field: '@html',
      text: 'хтмл',
      type: 'String',
    },
  ],
};
export default acts;
