const bills: StackSelection = {
  objectName: 'РеестрРеализации',
  description: 'Реестр реализации',
  fields: [
    {
      field: 'подоговору',
      text: 'Договор',
      type: 'String',
    },
    {
      field: 'тип документа',
      text: 'Тип документа число',
      type: 'Number',
    },
    {
      field: 'апи_тип',
      text: 'Тип документа',
      type: 'String',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчмесяц',
      text: 'Расчетный месяц',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'полный номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'тема',
      text: '',
      type: 'String',
    },
    {
      field: 'сумма',
      text: 'Всего ₽',
      type: 'Money',
    },
    {
      field: 'сумма2',
      text: 'Сумма ₽',
      type: 'Money',
    },
    {
      field: 'аналитика',
      text: 'Аналитика',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: 'исправлениеномер',
      text: 'Номер исправления',
      type: 'Number',
    },
    {
      field: 'исправлениедата',
      text: 'Дата исправления',
      type: 'Date',
    },
    {
      field: 'видсчета',
      text: 'Вид счета',
      type: 'Number',
    },
    {
      field: 'автор',
      text: 'Автор',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Покупатель',
      type: 'String',
    },
  ],
};
export default bills;
