
import Vue from 'vue';

export default Vue.extend({
  props: {
    ownerID: { type: [Number, String], required: true },
  },
  data() {
    return {
      month: this.$store.getters.getWorkMonth(),
      itemsList: {} as any,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async onSaveParameters() {
      await new this.$HttpModel('СреднесуточнаяТемпература').executeMethod('Внести', { плс: this.ownerID, запись: this.itemsList });
      this.fetchData();
    },
    async fetchData() {
      const res = await new this.$HttpModel('СреднесуточнаяТемпература').getRecords({ плс: this.ownerID, месяц: this.month });
      if (res && res[0]) {
        this.itemsList = Object.assign({}, this.itemsList, res[0]);
      }
    },
  },
});
