
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      valid: true,
      record: {
        выгрузка: 'Формирование реестра для приема сторонних показаний',
        дата: this.$store.getters.getCurrentDate(),
        лицевые: -1,
        услуга: -1,
        категорияуслуги: -1,
        ук: -1,
        категорияук: -1,
        поставщик: -1,
        категорияпост: -1,
      },
    };
  },
  methods: {
    async unload() {
      try {
        await new this.$HttpModel('ВыгрузкиПодкачки').executeMethod('Выгрузить', this.record, { async: true, jobName: 'Выгрузка показаний' });
      } catch (error: AnyException) {
        this.$toast(error);
      }
    },
  },
});
