
import Vue from 'vue';
import organization from '../_organizationsid.vue';

export default Vue.extend({
  components: { organization },
  props: {
    sourcespaymentsid: { type: [Number, String], default: undefined },
  },
});
