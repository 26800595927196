const acts: StackSelection = {
  objectName: 'МестоВыдачиДокументов',
  description: 'Место выдачи документов',
  fields: [
    {
      field: 'название',
      text: 'Место выдачи документов',
      type: 'String',
    },
    {
      field: 'код',
      text: 'Код подразделения',
      type: 'String',
    },
  ],
};
export default acts;
