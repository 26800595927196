
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: {
      type: Object,
      default: (): any => {
        return {};
      },
    },
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        Дата: this.$store.getters.getCurrentDate(),
        СобствМун: '',
      },
      rulesThis: {
        isCorrectType: (value: number): string | true => {
          return value === 3 || 'Необходимо выбрать дом!';
        },
      },
      typels: 0 as Number,
    };
  },
  methods: {
    isChanged(value: any) {
      if (value && value[0]) this.typels = value[0].тип;
    },
  },
});
