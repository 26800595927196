
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: 'выбор',
          text: '',
          type: 'Boolean',
        },
        {
          field: 'название',
          text: 'Наименование',
          type: 'String',
          readonly: true,
        },
      ],
    };
  },
});
