
import Vue from 'vue';
import QualityActsTab from './$documentstabs/QualityActsTab.vue';
import ViolationActsTab from './$documentstabs/ViolationActsTab.vue';
import InspectionsActsTab from './$documentstabs/InspectionsActsTab.vue';
import JudicialActsTab from './$documentstabs/JudicialActsTab.vue';
import DepRestrictActsTab from './$documentstabs/DepRestrictActsTab.vue';
import OneChargeTab from './$documentstabs/OneChargeTab.vue';
import KorrSaldo from './$documentstabs/KorrSaldo.vue';

export default Vue.extend({
  components: { QualityActsTab, ViolationActsTab, InspectionsActsTab, JudicialActsTab, DepRestrictActsTab, OneChargeTab, KorrSaldo },
  name: 'DocumentsTab',
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      tabs: null as null | string,
    };
  },
});
