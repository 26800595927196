
import Vue from 'vue';

export default Vue.extend({
  props: {
    disabled: { type: Boolean, required: true },
    accounts: { type: String, default: null },
    month: { type: String, default: null },
  },
  computed: {
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
  },
  data() {
    return {
      месяц: this.month || this.$store.getters.getWorkMonth(),
      фонд: '',
      jobID: null as null | string,
    };
  },
  created() {
    this.фонд = this.accounts ? this.accounts : '';
    this.месяц = this.month ? this.month : this.$store.getters.getWorkMonth();
  },
  methods: {
    async prepareKvit() {
      const parameters = { method: 'подготовка', Месяц: this.месяц, Фонд: this.фонд };
      this.jobID = (await new this.$HttpModel('МассоваяПечатьКвитанций').executeMethod('Отправить', parameters, { async: true })).asyncId;
      this.$store.commit('MSG_ADD', { title: 'Подготовка квитанций', asyncId: this.jobID });
      this.$emit('run');
    },
  },
  watch: {
    accounts(to) {
      this.фонд = to;
    },
    month(to) {
      this.месяц = to;
    },
    isComplete(to) {
      if (to) {
        this.jobID = null;
        this.$emit('end', { status: 'success', accounts: this.фонд });
      }
    },
    isError(to) {
      if (to) {
        this.jobID = null;
        this.$emit('end', { status: 'error' });
      }
    },
    resultJob(to) {
      if (!Object.keys(to).length) {
        this.jobID = null;
      }
    },
  },
});
