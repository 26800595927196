
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      items: [
        { title: 'Адреса проживающих', img: 'accounts', to: '/accounts' },
        { title: 'Реестры лицевых', img: 'doc_reestrs', to: { name: 'documents-registries' } },
        { title: 'Реестры по УК', img: 'other_reestr_uk', to: { name: 'accounts-ukreesters' } },
        { title: 'Фильтры лицевых', img: 'spr_filter_ls', to: { name: 'accounts-filterls' } },
        { title: 'Окно оператора', img: 'window_operator', to: { name: 'operations-windowoperator' } },
      ],
      items2: [
        { title: 'Быстрый ввод фонда', img: 'master_add_ls', to: { name: 'operations-quickaddfund' }, color: 'stack' },
        { title: 'Быстрый ввод индивидуальных приборов учёта', img: 'master_add_ipu', to: { name: 'operations-quickaddcounters' }, color: 'stack' },
        { title: 'Мастер деления лицевого', img: 'master_split_ls', to: { name: 'operations-accountsplit' }, color: 'stack' },
        { title: 'Массовый ввод характеристик', img: 'master_mass_input', to: { name: 'operations-massinput' }, color: 'stack' },
      ],
    };
  },
});
