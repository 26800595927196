
import Vue from 'vue';

export default Vue.extend({
  props: {
    param: { type: Object, required: true },
  },
  data() {
    return {
      oneclick: false,
      record: {
        НомерАкта: '',
        Сумма: this.param.суммадоля && this.param.суммадоля > 0 ? this.param.суммадоля : this.param.сумма,
        Пени: this.param.сумма2доля && this.param.сумма2доля > 0 ? this.param.сумма2доля : this.param.сумма2,
        Госпошлина: this.param.госпошлина,
        СуммаПрочее: this.param.суммапрочее,
        Примечание: '',
        Месяц: this.$stackDate.nextMonth(this.param.датнач as string, 0),
        Дата: this.param.датнач,
      },
    };
  },
  computed: {
    всегосумма(): number {
      return this.record ? Number(this.record.Сумма) + Number(this.record.Госпошлина) + Number(this.record.Пени) + Number(this.record.СуммаПрочее) : 0;
    },
    месяц(): object {
      return this.param ? this.$stackDate.nextMonth(this.param.дата as string, 0) : this.$store.getters.getOpenMonth();
    },
    isReadOnly(): boolean {
      return this.param && this.param['фаза-состояние>название'] ? this.param['фаза-состояние>название'].indexOf('Исковое') === -1 : true;
    },
  },
});
