const accs: StackSelection = {
  objectName: 'ОбновлениеВерсии.ТриггерыИфункцииСовпадения',
  description: '',
  fields: [
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'имяобъекта',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'базаданных',
      text: 'Номер версии',
      type: 'String',
    },
    {
      field: 'действие',
      text: 'Действие',
      type: 'String',
    },
    {
      field: 'результат',
      text: 'Результат',
      type: 'String',
    },
  ],
};
export default accs;
