
// TODO Не копипастить !

import Vue from 'vue';
import PeopleSubDialog from './StackPeopleLinkFieldDialog.vue';
import { splitFullName } from '@/stackEngine';

export default Vue.extend({
  name: 'StackPeopleLinkField',
  components: { PeopleSubDialog },
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: [String, Number], default: undefined },
    labelField: {
      type: [Function, String],
      default: 'фио',
    },
    /**
     * объект с фильтром по-умолчанию
     */
    filter: { type: [Object], default: null },
    headers: { type: String, default: 'фио,дата рождения,инн,пенсионное свидетельство,серия,номер,@типжильца' },
    dataModel: { type: String, default: 'ЧастныеЛица' },
    label: { type: String, default: 'ФИО' },
    linkField: { type: String, default: 'фио' },
    noUl: { type: Boolean, default: false }, // не выводить переключатель ФЛ/ЮЛ
  },
  data() {
    return {
      record: { типжильца: 0 } as StackTableRow,
    };
  },
  methods: {
    // событие на чекбокс
    onSelect(selectedRows: StackTableRow[]) {
      // Если типжильца не определен - зададим значение по умолчанию
      this.record = { типжильца: 0 };
      if (selectedRows && selectedRows.length > 0) {
        this.record = selectedRows[0];
        const objFIO = splitFullName(this.record.фио as string);
        this.record['@фамилия'] = objFIO.surname;
        this.record['@имя'] = objFIO.name;
        this.record['@отчество'] = objFIO.patronymic;
        // props.onSave(selectedRows);
      }
    },
    onClose(props: any) {
      props.onClose();
    },
    onSave(props: any) {
      if (this.record && this.record.$номерЗаписи && this.record.$номерЗаписи > 0) {
        props.onSave([this.record]);
      }
    },
  },
  watch: {
    // TODO если очистили извне, то очищаем окно
    value: {
      handler(to) {
        if (to === null || to === -1) {
          this.record = { типжильца: 0 };
        }
      },
    },
  },
});
