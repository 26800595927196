import Vue from 'vue';
import { Module, MutationTree, GetterTree, ActionTree } from 'vuex';
import { store } from '.';
import { stackDate } from '../utils/helpers';
import { stackAppEmitter } from '../utils/emitters';
import { StackApi } from '../middleware/StackApi'; // для общения с бэкэндом
import * as Sentry from '@sentry/vue';
import credits from '../themes/credits_stack';

// import jwt_decode, { JwtPayload } from 'jwt-decode';
export interface SystemState {
  developMode: boolean; // режим разработки или нет
  sessionToken: string | undefined; // текущий токен
  refreshToken: string | undefined; // рефреш токен (храним только в dev режиме или при соотв. включенной опции)
  // sessionTokenExp: number | undefined; // Время жизни токена
  globalHttpParams: any | undefined; // глобальные параметры во все http запросы
  backendConnected: boolean; // связь с бэкэндом установлена
  licenseKey: string | undefined; // Идент. код, если слетела регистрация
  userName: string; // ФИО пользователя
  userId: number | undefined; // НомерЗаписи Пользователя
  isAdmin: boolean; // Является пользователь админом
  orgFullName: string; // Лицо0.Наименование
  orgId: number | undefined; // НомерЗаписи(Лицо0)
  user: string; // Логин
  base: string;
  newsCount: number; // Количество новостей
  msgActions: StackStateMsgs; // асинхронные задачи бэкэнда
  navDrawerState: boolean; // состояние левого навбара (скрыт\показан)
  backendVersion: string; // версия бэкэнда
  openMonth: string | null; // первый открытый месяц
  openMonthPartly: boolean; // частично открыт
  openMonthAdm: boolean; // открыт только для текущей сессии (адм режим)
  workMonth: string;
  currentDate: string; // текущая дата
  currentTime: string; // текущее время
  busyStatusMsg: string;
  isAppReady: boolean; // начальная загрузка данных программы
  isSigning: boolean; // Флаг ожидания загрузки данных авторизации
  blockedRecords: number;
  fingerprint: string | undefined; // уникальный отпечаток пользователя fingerprint
  localHistoryVersion: string;
  currentHistoryVersion: string;
  isAsyncRun: boolean; // опрос асинхронных задач
  isInspectEnable: boolean; // статус. включён ли режим inspect на беке
  debugMode: boolean; // Включен ли режим отладки на фронте. При включенном режиме шлём хедер S-Debug
}

const currentMonth = new Date();
currentMonth.setDate(1);

const state: SystemState = {
  developMode: process.env.NODE_ENV === 'development',
  sessionToken: '',
  refreshToken: undefined,
  // sessionTokenExp: undefined,
  globalHttpParams: undefined,
  userName: '<Нет ФИО>',
  userId: undefined,
  isAdmin: false,
  orgFullName: '',
  orgId: undefined,
  user: '',
  base: '',
  newsCount: 0,
  navDrawerState: true,
  // navDrawerMini: false,
  msgActions: {},
  backendVersion: '',
  openMonth: null,
  openMonthPartly: false,
  openMonthAdm: false,
  workMonth: stackDate.format(currentMonth, 'ISO'),
  currentDate: stackDate.format(new Date(), 'ISO'),
  currentTime: stackDate.format(new Date(), 'Time'),
  backendConnected: false,
  licenseKey: undefined,
  busyStatusMsg: '',
  isAppReady: false,
  isSigning: false,
  blockedRecords: 0,
  fingerprint: undefined,
  localHistoryVersion: '',
  currentHistoryVersion: '',
  isAsyncRun: false,
  isInspectEnable: false,
  debugMode: false,
};

// Геттеры
const getters: GetterTree<SystemState, any> = {
  // начальная загрузка программы
  isAppReady: (state: SystemState) => () => {
    return state.isAppReady;
  },

  // Статус занятости программы
  getBusyStatusMsg: (state: SystemState) => () => {
    return state.busyStatusMsg || '';
  },
  // имя sql базы
  getBaseName: (state: SystemState) => () => {
    return state.base ? state.base.split('.')[0] : '';
  },

  // текущий токен
  getToken: (state: SystemState) => () => {
    return state.sessionToken;
  },
  // // Срок жизни текущего токена
  // getTokenExp: (state: SystemState) => () => {
  //   return state.sessionTokenExp;
  // },

  getFingerprint: (state: SystemState) => () => {
    return state.fingerprint;
  },

  // Глобальные параметры HTTP запроса
  getGlobalHttpParams: (state: SystemState) => () => {
    return state.globalHttpParams;
  },

  // аутентифицирован ли пользователь
  isAuth: (state: SystemState) => () => {
    return !!state.sessionToken;
  },
  isSigning: (state: SystemState) => () => {
    return state.isSigning;
  },

  // Связь установлена
  isBackendConnected: (state: SystemState) => () => {
    return store.getters.isAuth() && state.backendConnected;
  },

  // Есть ли лицензия ?
  hasLicense: (state: SystemState) => () => {
    return state.licenseKey === undefined;
  },
  // Есть ли ключ для регистрации
  hasLicKey: (state: SystemState) => () => {
    return state.licenseKey;
  },
  // Идент. код регистрации
  getLicKey: (state: SystemState) => () => {
    return state.licenseKey;
  },

  // в режиме разработчика или нет
  isDevelop: (state: SystemState) => () => {
    return state.developMode;
  },

  // развернут или скрыт навбар
  getNavDrawerState: (state: SystemState) => () => {
    return store.getters.isAuth() ? state.navDrawerState : false;
  },

  // первый открытый месяц
  getOpenMonth: (state: SystemState) => () => {
    return store.getters.isAuth() ? state.openMonth : null;
  },

  //  открытый месяц открыт частично ?
  isOpenMonthPartly: (state: SystemState) => () => {
    return store.getters.isAuth() ? state.openMonthPartly : false;
  },
  //  открытый месяц открыт только для текущей сессии ?
  isOpenMonthAdm: (state: SystemState) => () => {
    return store.getters.isAuth() ? state.openMonthAdm : false;
  },
  //  рабочий месяц открыт частично ?
  isWorkMonthPartly: (state: SystemState) => () => {
    return store.getters.isAuth() && state.workMonth === state.openMonth ? state.openMonthPartly : false;
  },
  //  рабочий месяц открыт только для текущей сессии ?
  isWorkMonthAdm: (state: SystemState) => () => {
    return store.getters.isAuth() && state.workMonth === state.openMonth ? state.openMonthAdm : false;
  },
  //  рабочий месяц в закрытом месяце
  isWorkMonthInClosedMonth: (state: SystemState) => () => {
    return store.getters.isAuth() && state.workMonth && state.openMonth && Date.parse(state.workMonth) < Date.parse(state.openMonth);
  },
  // записи, которые нужно опросить с бека
  getNeedCheckMessages: (state: SystemState) => () => {
    return store.getters.isAuth() && state.msgActions ? Object.values(state.msgActions).filter(msg => !msg.complete && !msg.error && msg.asyncId) : [];
  },
  // экшны
  // @ts-ignore TODO
  getMsgActions: (state: SystemState) => (withExt = false) => {
    return store.getters.isAuth() ? (withExt ? Object.values(state.msgActions) : Object.values(state.msgActions).filter(msg => !msg.external)) : [];
  },
  getMsgActionByID: (state: SystemState) => (asyncId: string) => {
    return store.getters.isAuth() && state.msgActions && asyncId ? state.msgActions[asyncId] : null;
  },

  getLogin: (state: SystemState) => () => {
    return state.user;
  },

  isUserAdmin: (state: SystemState) => () => {
    return state.isAdmin;
  },

  getOrgFullName: (state: SystemState) => () => {
    return state.orgFullName;
  },

  getOrgId: (state: SystemState) => () => {
    return state.orgId;
  },
  // ФИО пользователя
  getUserFullName: (state: SystemState) => () => {
    return store.getters.isAuth() ? state.userName : '';
  },
  // ФИО пользователя
  getUserName: (state: SystemState) => () => {
    if (!store.getters.isAuth()) {
      return '';
    }
    let [f, i, o] = state.userName.split(' ');
    f = f || ' ';
    i = i ? i[0] + '.' : ' ';
    o = o ? o[0] + '.' : ' ';
    return `${f} ${i}${o}`;
  },
  // ID пользователя
  getUserId: (state: SystemState) => () => {
    return state.userId;
  },

  // Рабочий месяц для бэкэнда
  getWorkMonth: (state: SystemState) => () => {
    return state.workMonth;
  },

  // Текущая дата
  getCurrentDate: (state: SystemState) => () => {
    return state.currentDate;
  },

  // закрытый месяц бэкэнда
  getCloseMonth: (state: SystemState) => () => {
    if (state.openMonth) {
      const d = new Date(state.openMonth);
      if (d < new Date('1990-01-01')) {
        return null;
      }
      d.setMonth(d.getMonth() - 1);
      return store.getters.isAuth() ? stackDate.format(d, 'ISOMonth') : null;
    }
    return null;
  },

  // Версия бэкэнда
  getBackEndVersion: (state: SystemState) => () => {
    return state.backendVersion || '-';
  },

  // Текущее время
  getCurrentTime: (state: SystemState) => () => {
    return state.currentTime;
  },
  getBlockedRecords: (state: SystemState) => () => {
    return state.blockedRecords;
  },

  getLocalHistoryVersion: (state: SystemState) => () => {
    return state.localHistoryVersion;
  },
  getCurrentHistoryVersion: (state: SystemState) => () => {
    return state.currentHistoryVersion;
  },
  isInspectEnable: (state: SystemState) => () => {
    return state.isInspectEnable;
  },
  isDebugMode: (state: SystemState) => () => {
    return state.debugMode;
  },

};

const mutations: MutationTree<SystemState> = {
  // глобально говорит, готово приложение к работе или нет
  SET_APP_READY(state: SystemState, val: boolean) {
    state.isAppReady = val;
  },
  SET_ASYNC_REFRESH_STATE(state: SystemState, val: boolean) {
    state.isAsyncRun = val;
  },
  SET_BUSY_STATUS(state: SystemState, msg: string) {
    state.busyStatusMsg = msg;
  },
  // Установка открытого месяца
  SET_OPEN_MONTH(state: SystemState, month: string) {
    state.openMonth = stackDate.format(month, 'ISOMonth');
  },
  // Установка открытого месяца частично
  SET_OPEN_MONTH_PARTLY(state: SystemState, monthState: boolean) {
    state.openMonthPartly = monthState;
  },
  // Установка открытого месяца только для тек сессии
  SET_OPEN_MONTH_ADM(state: SystemState, monthState: boolean) {
    state.openMonthAdm = monthState;
  },

  // Установка режима разработчика
  SET_DEVELOP_MODE(state: SystemState, flag: boolean) {
    state.developMode = flag;
  },

  // устанавливаем версию бэкэнда
  SET_BACKEND_VERSION(state: SystemState, ver: string) {
    state.backendVersion = ver;
  },

  SET_LOCAL_HISTORY_VERSION(state: SystemState, ver: string) {
    state.localHistoryVersion = ver;
  },
  SET_CURRENT_HISTORY_VERSION(state: SystemState, ver: string) {
    state.currentHistoryVersion = ver;
  },
  // устанавливаем токен
  SET_TOKEN(state, sessionToken: string) {
    state.sessionToken = sessionToken;
  },

  // устанавливаем рефреш токен
  SET_REFRESH_TOKEN(state, refreshToken: string) {
    state.refreshToken = refreshToken;
  },

  // Устанавливаем fp юзера
  SET_USER_FINGERPRINT(state: SystemState, fp: string) {
    state.fingerprint = fp;
  },
  // устанавливаем глобальные параметры HTTP запросов
  SET_GLOBAL_HTTP_PARAMS(state, glParams: any) {
    state.globalHttpParams = glParams;
  },

  // ФИО текущего пользователя
  SET_USER(state: SystemState, name: string) {
    state.userName = name;
  },
  // ID текущего пользователя
  SET_USER_ID(state: SystemState, rowid: number) {
    state.userId = rowid;
  },
  // Является ли пользователь админом
  SET_USER_ADMIN(state: SystemState, isAdmin: boolean) {
    state.isAdmin = isAdmin;
  },

  // устанавливает имя БД
  SET_BASE_NAME(state: SystemState, base: string) {
    state.base = base;
  },

  // Лицо0.Наименование
  SET_ORG(state: SystemState, name: string) {
    state.orgFullName = name;
  },

  // номер записи Лицо0
  SET_ORG_ID(state: SystemState, rowid: number) {
    state.orgId = rowid;
  },

  // Login текущего пользователя
  SET_LOGIN(state: SystemState, login: string) {
    state.user = login;
    Sentry.setUser({ username: state.user });
  },
  // Установка Флага ожидания загрузки данных авторизации
  SET_SIGNING(state: SystemState, to: boolean) {
    state.isSigning = to;
  },
  // Статус связи с бэком
  SET_CONNECTED(state: SystemState, isConnected: boolean) {
    state.backendConnected = isConnected;
  },

  // Наличие лицензии
  SET_LICENSE_KEY(state: SystemState, licKey: string) {
    state.licenseKey = licKey;
  },

  // кол-во новостей
  SET_NEWS_COUNT(state: SystemState, count: number) {
    state.newsCount = count;
  },

  // состояние навбара (показан\скрыт)
  SET_DRAWER_STATE(state, newState: boolean) {
    state.navDrawerState = newState;
  },

  SET_BLOCKED_RECORDS(state: SystemState, count: number) {
    state.blockedRecords = count;
  },
  // Установка нового рабочего месяца
  CHANGE_WORK_MONTH(state: SystemState, newMonth: Date | string) {
    state.workMonth = stackDate.format(newMonth, 'ISOMonth');
  },

  // добавить асинхронную задачу
  MSG_ADD(state: SystemState, payload: StackStateMsg) {
    store.commit('MSG_ADD_ACTION', payload);
    if (!payload.silent) {
      Vue.prototype.$toast(payload.title, { description: payload.status, color: payload?.error ? 'error' : 'info', icon: payload?.error ? '$error' : '$clock' });
    }
  },
  MSG_ADD_ACTION(state: SystemState, payload: StackStateMsg) {
    const asyncId = payload?.asyncId || Number(new Date());
    Vue.set(state.msgActions, asyncId, {
      origin: payload.origin,
      title: payload.title,
      files: payload.files,
      to: payload.to,
      subtitle: payload.subtitle,
      status: payload.status,
      asyncId,
      error: payload.error || false,
      complete: payload.complete || false,
      percent: 0,
      type: payload.type || 'other',
      silent: payload.silent || false,
      external: payload.external || false,
      created: payload.created || stackDate.format(new Date(), 'DateTimeFull'),
      closed: payload.closed || null,
      data: payload?.data,
      stacktrace: payload?.stacktrace,
      onChangeState: payload.onChangeState,
      onComplete: payload.onComplete,
      onError: payload.onError,
    } as StackStateMsg);
  },

  // Ошибка
  MSG_SET_ERROR(state: SystemState, asyncId: string) {
    state.msgActions[asyncId].error = true;
  },

  // Установить новый статус
  MSG_SET_STATUS(state: SystemState, payload: StackStateMsg) {
    const newStatus = {} as StackStateMsg;
    const curAction = state.msgActions[payload.asyncId];
    const timeChange = stackDate.format(new Date(), 'DateTimeFull');
    if (curAction) {
      if (payload.error !== undefined) {
        newStatus.error = payload.error;
        newStatus.closed = timeChange;
      }
      if (payload.complete !== undefined) {
        newStatus.complete = payload.complete;
        if (!curAction.silent) {
          Vue.prototype.$toast(`Выполнено: ${curAction.title}`, { color: 'info', icon: '$vuetify.icons.check' });
        }
        newStatus.closed = timeChange;
      }
      if (payload.type) {
        newStatus.type = payload.type;
      }
      if (payload.percent !== undefined) {
        newStatus.percent = payload.percent;
      }
      if (payload.origin) {
        newStatus.origin = payload.origin;
      }
      if (payload.subtitle) {
        newStatus.subtitle = payload.subtitle;
      }
      if (payload.files) {
        newStatus.files = payload.files;
      }
      if (payload.status !== undefined) {
        newStatus.status = payload.status;
      }
      if (payload.external !== undefined) {
        newStatus.external = payload.external;
      }
      if (payload.data !== undefined) {
        Vue.set(newStatus, 'data', payload.data);
      }
      if (payload.stacktrace !== undefined) {
        newStatus.stacktrace = payload.stacktrace;
      }
      if (newStatus) {
        state.msgActions[payload.asyncId] = Object.assign({}, state.msgActions[payload.asyncId], newStatus);
      }
    }
  },

  // удалить сообщения
  MSG_DELETE(state: SystemState, asyncId: string) {
    Vue.delete(state.msgActions, asyncId);
  },

  MSG_CLEAR(state: SystemState) {
    state.msgActions = {};
  },

  // Устанавливает титл вкладки браузера
  SET_BROWSER_TITLE(state: SystemState, title?: string) {
    const softName = credits.shortName.toUpperCase();
    document.title = title ? `${softName} - ${title}` : softName;
  },
  // Проверяем статус асинхронной задачи
  CHECK_MSG_RESPONSE(state: SystemState, payload: { response: StackHttpResponseAsyncTask; job: StackStateMsg }) {
    const response = payload.response;
    const job = payload.job;
    let percent;
    let complete;
    let error;
    const statuses = [];

    // Ошибки
    if (response.error) {
      error = true;
      store.commit('MSG_SET_STATUS', { asyncId: job.asyncId, error, status: response.error.message, stacktrace: response } as StackStateMsg);
      console.log(response.error);
      return;
    }

    // прилетает из окон состояний
    if (response.state) {
      percent = response.state.Прогресс && response.state.Прогресс_max ? (response.state.Прогресс / response.state.Прогресс_max) * 100 : 0;
      statuses.push(response.state.Действие);
    }

    // сообщения через Сообщить
    if (response.messages) {
      for (const key in response.messages) {
        statuses.push(response.messages[key]);
      }
    }
    let files: StackResultAsFile[] | undefined;
    // успешный результат работы
    if (response.result !== undefined) {
      complete = true;
      const res = response.result as StackResultAsFile | StackResultAsFile[];
      if (res) {
        files = Array.isArray(res) ? res[0] && res[0].fileUrl ? res : undefined : res.fileUrl ? [res] : undefined;
      }
      if (!files && job.type === 'report' && statuses.length === 0) {
        statuses.push('Нет данных');
      }
    }
    store.commit('MSG_SET_STATUS', { asyncId: job.asyncId, files, complete, status: statuses.join(' '), percent, data: response } as StackStateMsg);
  },
  SET_INSPECT_MODE(state, newState: boolean) {
    state.isInspectEnable = newState;
  },
  SET_DEBUG_MODE(state, newState: boolean) {
    state.debugMode = newState;
  },

};

interface StackAuthData {
  token?: string;
  login?: string;
  password?: string;
  newPassword?: string;
  force?: boolean;
}
const apiCheck = new StackApi();
const apiJobs = new StackApi();
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const actions: ActionTree<SystemState, any> = {
  // Метод авторизации пользователя
  async SIGN_IN({ rootState, commit, dispatch }, payload: StackAuthData) {
    commit('SET_SIGNING', true);
    let token = payload?.token;
    // Авторизация по логину паролю
    if (!token && payload.login) {
      commit('SET_LOGIN', payload.login);
      const http = new StackApi();
      const data = await http.login(payload.login, payload?.password || '', !!payload.force, payload.newPassword);
      if (data) {
        token = data.sessionToken || data.accessToken;
        // Храним рефрештокен, если разрешено конфигурацией. И пишем warning в косоль
        if (rootState.configStore.NO_SSL_AUTH && data.refreshToken) {
          commit('SET_REFRESH_TOKEN', data.refreshToken);
          console.warn('Вы используете NO_SSL_AUTH. Это крайне не рекомендуется в production !');
        } else {
          commit('SET_REFRESH_TOKEN', undefined);
        }
        if (data.tasks?.[0].result?.заблокированоЗаписей) {
          commit('SET_BLOCKED_RECORDS', data.tasks[0].result.заблокированоЗаписей);
        }
      }
    }
    // У нас есть токен
    if (token) {
      commit('SET_TOKEN', token);
      // Проверим, а валидный ли до сих пор токен. Вдруг он уже протух
      await dispatch('checkSystemState');
      // При аутентификации проверим один раз версию
      if (!payload?.token) {
        await store.dispatch('checkBackendVersion');
      }
      // Шлём рассылку, что авторизация прошла успешно
      await stackAppEmitter.emitAsync('APP_USER_SIGN_IN', store);
      commit('SET_SIGNING', false);
      return true;
    }
    commit('SET_SIGNING', false);
    return false;
  },
  // Разлогин
  async SIGN_OUT({ rootState, commit }) {
    await stackAppEmitter.emitAsync('APP_USER_SIGN_OUT', store);
    try {
      await new StackApi().logout();
    } finally {
      commit('SET_TOKEN', '');
      if (rootState.configStore.NO_SSL_AUTH) {
        commit('SET_REFRESH_TOKEN', undefined);
      }
    }
    return true;
  },
  checkBackendVersion({ state, getters }) {
    if (getters.isCurrentTaskExternal()) {
      return;
    }
    const curVerRec = state.backendVersion;
    const minVer: string = store.getters.getMinBackendVersion();
    const [minMajor, minMinor, minPatch] = minVer.split('.');
    const [curMajor, curMinor, curPatch] = curVerRec.split('.');
    if (+curMajor > 0 && +minMajor > 0) {
      if (
        (+minMajor > +curMajor) ||
        (+minMajor === +curMajor && +minMinor > +curMinor) ||
        (+minMajor === +curMajor && +minMinor === +curMinor && +minPatch > +curPatch)
      ) {
        store.commit('MSG_ADD_ACTION',
          {
            title: 'Версия бэкэнда меньше рекомендуемой',
            status: `Необходима версия: ${store.getters.getMinBackendVersion()}\nИспользуется: ${curVerRec}`,
            type: 'message',
            error: true,
            asyncId: 'checkBackend',
          });
      }
    }
  },
  async runAsyncJobs({ dispatch, getters, commit, state }) {
    // Если опрос уже запущен, то повторно его уже не стартуем
    if (state.isAsyncRun) {
      return;
    }
    // поднимаем флаг, что опрос асинхронных задач активен
    commit('SET_ASYNC_REFRESH_STATE', true);
    // опрос асинхронных задач
    dispatch('checkAsyncJobs');

    // опрос состояния бека
    const timeoutState = +getters.getBackendStateInterval();
    while (true) {
      if (getters.isAuth() && getters.isBackendConnected()) {
        try {
          await dispatch('checkSystemState');
        } catch (e: AnyException) {
          console.log(e);
        }
      }
      await delay(timeoutState);
    }
  },
  // Проверка асинхронных задач
  async checkAsyncJobs({ commit, getters }) {
    const timeoutJobs = +getters.getAsyncJobsInterval();
    while (true) {
      if (getters.isAuth() && getters.isBackendConnected()) {
        // Получим массив заданий, которые нужно опросить с бекэнда, если нечего опрашивать, то выходим
        const items = getters.getNeedCheckMessages() as StackStateMsg[];
        if (items.length > 0) {
          try {
            const response = await apiJobs.getAsyncJobResults(items.map(item => item.asyncId)); // Запросим состояние асинхронных задач
            if (response && response.result) {
              // Переберем состояния задач с бека
              for (const jobId in response.result) {
                const msgResponse = response.result[jobId];
                let job = getters.getMsgActionByID(jobId);
                if (job) {
                  commit('CHECK_MSG_RESPONSE', { response: msgResponse, job }); // распарсим результат
                  job = getters.getMsgActionByID(jobId);
                  // вызовем коллбек при ошибке
                  if (job.error && job.onError) {
                    job.onError(job);
                  } else if (job.complete && job.onComplete) {
                    // Вызовем коллбек на успешное выполнение
                    if (job.onChangeState) {
                      job.onChangeState(job);
                    }
                    job.onComplete(job);
                  } else if (job.onChangeState) {
                    job.onChangeState(job); // Вызовем коллбек. Смена состояния асинх. задачи
                  }
                }
              }
            }
          } catch (error: AnyException) {
            console.log(error);
          }
        }
      }
      await delay(timeoutJobs);
    }
  },

  // чтение общей информации с бэкэнда
  async checkSystemState({ commit, state, getters }): Promise<boolean> {
    if (getters.isCurrentTaskExternal()) {
      return true;
    }
    try {
      const rec = (await apiCheck.getSystemState()) as StackHttpResponseState;
      if (rec) {
        if (!state.backendConnected) {
          commit('SET_CONNECTED', true);
        }
        if (rec.пользовательФИО !== state.userName) {
          commit('SET_USER', rec.пользовательФИО);
        }
        if (rec.пользовательНомерЗаписи !== state.userId) {
          // если пользователь сменился, стираем историю асинхр задач
          if (state.userId && state.userId > 0) {
            commit('MSG_CLEAR');
          }
          commit('SET_USER_ID', rec.пользовательНомерЗаписи);
        }
        if (!!rec.супервизор !== getters.isUserAdmin()) {
          commit('SET_USER_ADMIN', !!rec.супервизор);
        }
        if (stackDate.format(rec.открытыйМесяц, 'ISOMonth') !== getters.getOpenMonth()) {
          commit('SET_OPEN_MONTH', rec.открытыйМесяц);
        }
        // Появилось на версиях +191001
        if (rec.открытыйМесяцЧастично !== undefined) {
          if (!!rec.открытыйМесяцЧастично !== getters.isOpenMonthPartly()) {
            commit('SET_OPEN_MONTH_PARTLY', !!rec.открытыйМесяцЧастично);
          }
          if (!!rec.открытыйМесяцАдмРежим !== getters.isOpenMonthAdm()) {
            commit('SET_OPEN_MONTH_ADM', !!rec.открытыйМесяцАдмРежим);
          }
        }
        if (rec.версияКомплекса !== getters.getBackEndVersion()) {
          commit('SET_BACKEND_VERSION', rec.версияКомплекса);
        }
        if (rec.организация && rec.организация !== getters.getOrgFullName()) {
          commit('SET_ORG', rec.организация);
        }
        if (rec.организацияНомерЗаписи && rec.организацияНомерЗаписи !== getters.getOrgId()) {
          commit('SET_ORG_ID', rec.организацияНомерЗаписи);
        }
        if (rec.строкаБазы !== state.base) {
          commit('SET_BASE_NAME', rec.строкаБазы);
        }
        if (rec.isInspectEnable !== state.isInspectEnable) {
          commit('SET_INSPECT_MODE', rec.isInspectEnable);
        }
      }
    } catch (error: AnyException) {
      console.log(error);
      throw error;
    }
    return true;
  },
};

const systemState: Module<SystemState, any> = {
  state,
  getters,
  mutations,
  actions,
};

export default systemState;
