import { icons } from './icons';
export default {
  colors: {
    primary: '#1A965A',
    secondary: '#E1EEE1',
    accent: '#89C745',
    error: '#E47272',
    warning: '#D9AB36',
    info: '#006699',
    success: '#339900',
    stack: '#113A51',
  },
  images: {
    bg: require('./jkh/bg_pen.png'),
    // logo: require('./jkh/logo.png'),
    // logo_sidebar: require('./jkh/logo_sidebar.png'),
    // title: require('./jkh/title.png'),
    // title_sidebar: require('./jkh/title_sidebar.png'),
  },
  icons,
};
