
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: { type: Object, default: undefined },
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        РасчМес: this.$store.getters.getWorkMonth(),
        АнализМесНач: this.$stackDate.prevMonth(this.$store.getters.getWorkMonth(), 1) as string,
        АнализМесКнц: this.$stackDate.prevMonth(this.$store.getters.getWorkMonth(), 1) as string,

        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,

        ЧтоСравнивать: 1,
        Детализация: 0,

        НомерЗаписиУслуги: null,
        НомерЗаписиКатегорииУсл: null,
        НомерЗаписиПоставщика: null,
        НомерЗаписиКатегорииПост: null,
        НомерЗаписиУК: null,
        НомерЗаписиУКДоговор: null,

        ВыбратьПроцентРасхождения: 1,
        ПроцентРасхождения: 20,
        Операнд: 4,
        пОперанд: '>=',
      },
      paramDetail: {
        допОтчетДетЛсВсе: 1,
      },
    };
  },
  methods: {},
});
