
import Vue from 'vue';

export default Vue.extend({
  props: {
    gpid: { type: [Number, String], default: undefined },
    record: { type: Object, required: true },
  },
  data() {
    return {
      dataObject: new this.$HttpModel('РеестрГоспошлины'),
    };
  },
  computed: {
    abonent(): string {
      return this && this.record && this.record.допфио ? this.record.допфио + (this.record.допадрес === '' ? '' : ' (' + this.record.допадрес + ')') : '';
    },
    summReturn(): number {
      return this && this.record && this.record.статус && (this.record.статус === 6 || this.record.статус === 7)
        ? this.record.сумма - this.record.суммасписания - this.record.суммавозвратафб
        : 0;
    },
  },
  methods: {
    async changeDateField(gpload: any) {
      if (gpload && gpload[0]) {
        let rec = this.record;
        const result = await this.dataObject.executeMethod('ПроверкаПолей', { поле: 'Дата', госпошлина: rec, номерЗаписи: this.gpid });
        rec = Object.assign(rec, result);
        this.$emit('change', rec);
      }
    },
    async changeСourtField(courtload: any) {
      if (courtload && courtload[0] && courtload[0].подразделение) {
        this.record.подразделение = courtload[0].подразделение;
      }
    },
    async changeLawsuitField(gpload: any) {
      if (gpload && gpload[0]) {
        let rec = this.record;
        const result = await this.dataObject.executeMethod('ПроверкаПолей', { поле: 'Дело', госпошлина: rec, номерЗаписи: this.gpid, новоеДелоИд: gpload[0].$номерЗаписи });
        rec = Object.assign(rec, result);
        this.$emit('change', rec);
      }
    },
  },
});
