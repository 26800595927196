import Vue from 'vue';
import Axios, { AxiosResponse } from 'axios';
import { store } from '../../store';
import { StackSelectionCache } from '../../utils';
import { print } from 'graphql/language/printer';
import gql from 'graphql-tag';

type TParams = any;

export const axios = Axios.create({
  method: 'post',
});

const MAIN_URL = '/kvpl'; // Основной "под"URL для задач
const STATE_URL = '/getsystemstate'; // опрос состояния
const LIC_URL = '/lic'; // регистрация лицензии
const RESULTS_URL = '/async-state'; // опрос результатов асинхронных задач
const CANCEL_ASYNC_JOB_URL = '/async-cancel'; // опрос результатов асинхронных задач
const URL_RESTART = '/__service/restart'; // служебный. только для диспетчера. Посылает команду на перезагрузку бэкэнда, если это разрешено
const URL_PLUGIN_DOWNLOAD = '/GetAssistant'; // URL для скачивания Стек-Ассистент
const URL_RELOAD = '/reload'; // URL для перезагрузки ресурсов

const ASYNC_JOB_HEADER = 'S-Async-Job';
const S_DEBUG_HEADER = 'S-Debug';
export class StackApi {
  private request: StackHttpRequest = {};
  private response: StackHttpResponse | null = null;
  private noInternalMsg = false;
  private headers = {} as any;

  // опрос состояния бека
  public async getGWState(path = '/health') {
    const token = store.getters.getToken();
    const url = `${store.getters.getApiHostHealth()}${path}`;
    return axios.get(url, { headers: { 'S-Access-Token': token } }).then(response => response.data);
  }

  public async graphql(query: string, params?: any) {
    const token = store.getters.getToken();
    const url = store.getters.getApiGraphql();
    try {
      const response = await axios.post(
        url,
        { query: print(gql`${query}`), variables: params },
        { headers: { 'content-type': 'application/json', 'S-Access-Token': token } },
      );
      const data = this.extractData(response.data);
      if (data && data.errors) {
        Promise.reject(data.errors);
        return null;
      }
      return data;
    } catch (error: AnyException) {
      Promise.reject(error);
      return null;
    }
  }

  // достаем рерзультат запроса graphql из бесконечных вложенностей
  private extractData(obj: any): any {
    if (obj && typeof obj === 'object') {
      const keys = Object.keys(obj).filter((key: string) => !key.startsWith('__'));
      if (keys.length === 1 && typeof obj[keys[0]] === 'object' && !Array.isArray(obj)) {
        return this.extractData(obj[Object.keys(obj)[0]]);
      }
    }
    return obj;
  }

  // регистрация комплекса
  public async registerLicense(key: string): Promise<StackHttpResponse | null> {
    const token = store.getters.getToken();
    if (token) {
      this.request.sessionToken = token;
    }
    this.request.regCode = key;
    const url = store.getters.getApiHost() + LIC_URL;
    this.response = await this.post(url);
    return this.response;
  }

  // отменим результат асинхронной задачи
  public async cancelAsyncJob(asyncId: string, taskID = 0): Promise<StackHttpResponseAsyncTask> {
    const token = store.getters.getToken();
    if (!token) {
      Promise.reject(new Error('Не указан токен'));
    }
    this.request.sessionToken = token;
    this.request.asyncId = asyncId;
    try {
      const url = store.getters.getApiHost() + CANCEL_ASYNC_JOB_URL;
      this.response = await this.post(url);
      if (this.response && this.response.tasks && this.response.tasks[taskID]) {
        return this.response.tasks[taskID] as StackHttpResponseAsyncTask;
      } else {
        return Promise.reject(new Error(`Нет данных по taskID ${taskID}`));
      }
    } catch (error: AnyException) {
      return Promise.reject(error);
    }
  }

  // Получаем результаты всех асинхронных задач
  public async getAsyncJobResults(asyncIds: string[]): Promise<any> {
    const token = store.getters.getToken();
    if (!token) {
      Promise.reject(new Error('Не указан токен'));
    }
    this.request.sessionToken = token;
    this.request.гуидыРабот = asyncIds;
    try {
      const url = store.getters.getApiHost() + RESULTS_URL;
      this.response = await this.post(url);
      if (this.response && this.response.tasks && this.response.tasks[0]) {
        // @ts-ignore
        // TODO переделать типизацию
        return this.response.tasks[0] as StackHttpResponseAsyncTasks;
      } else {
        return Promise.reject(new Error(`getAsyncJobResults failed`));
      }
    } catch (error: AnyException) {
      return Promise.reject(error);
    }
  }

  // Команда на перезагрузку ресурсов
  public async reloadResources(flags: number): Promise<boolean> {
    const taskID = this.addTask({ objectName: 'ПерезагрузкаРесурсов', methodName: 'обновить' });
    if (taskID != null) {
      this.addParam(taskID, { запись: { флаги: flags, $номерЗаписи: 1 } });
    }

    const url = store.getters.getApiHost() + URL_RELOAD;
    const response = await this.post(url);
    if (response && response.tasks[0] && response.tasks[0].id === 0) {
      return true;
    }
    return false;
  }

  // Получить путь к Стек ассистенту
  public async getPluginInfo(version?: string): Promise<any> {
    const url = store.getters.getApiHost() + URL_PLUGIN_DOWNLOAD;
    return axios.post(url, { версия: version }).then((response: any) => {
      return response.data && response.data.tasks && response.data.tasks[0] && response.data.tasks[0].result ? response.data.tasks[0].result : undefined;
    });
  }

  //
  public async logout(): Promise<any> {
    const url = store.getters.getApiHostLogout();
    const token = store.getters.getToken();
    return axios.post(url, {}, { withCredentials: true, headers: { 'S-Access-Token': token } });
  }

  // перезагружает бэкэнд
  public async restart(): Promise<any> {
    const url = store.getters.getApiHost() + URL_RESTART;
    const timeout = store.getters.getTimeout();
    axios.get(url, { timeout }).then(response => {
      return response.data;
    });
  }

  // определяет ошибку в ответе бэка во всевозможных форматах
  // TODO после стандартизации выкинуть
  public static getResponseError(data: StackHttpResponse | undefined): any {
    let err: StackHttpResponseError;
    if (data === undefined) {
      err = { code: 0, message: 'В ответе нет данных', data };
      return err;
    }
    // if (typeof data === 'string') {
    //   err = { code: 0, message: 'Ответ от сервера не является валидным JSON объектом', data };
    //   return err;
    // }
    if (data.error) {
      err = {
        message: data.error.message,
        code: data.error.code,
        data: data.error.data,
      };
      return err;
    }
    // ошибки при выполнении запросов graphql
    if (data.errors) {
      err = {
        message: data.errors[0].message,
        code: 200,
        data: data.errors[0].extensions,
      };
      return err;
    }
    if (data.tasks) {
      for (const task of data.tasks) {
        if (task.error) {
          err = {
            message: task.error.message,
            code: task.error.code,
            data: task.error.data,
          };
          return err;
        }
      }
    }
    if (data.Error) {
      err = {
        code: 0,
        message: data.Error,
        data: undefined,
      };
      return err;
    }
    return undefined;
  }

  // опрос статуса бэкэнда
  public async getSystemState(): Promise<StackHttpResponseState | undefined> {
    const token = store.getters.getToken();
    if (!token) {
      return undefined;
    }
    // this.request.sessionToken = token;
    const url = store.getters.getApiHost() + STATE_URL;
    this.response = await this.post(url);
    return this.response && this.response.tasks && this.response.tasks[0] && this.response.tasks[0].result ? (this.response.tasks[0].result as StackHttpResponseState) : undefined;
  }

  // метод аутентификации
  public async login(login: string, password: string, forceLogin = false, newPassword: string | undefined = undefined): Promise<any | undefined> {
    const url = store.getters.getApiHostAuth();
    const task = store.getters.getCurrentTask();
    const fingerprint: string | undefined = store.getters.getFingerprint() || undefined;
    const res: any = await axios.post(url, { userID: login, secret: password, forceLogin: forceLogin ? true : undefined, newSecret: newPassword, fingerprint, task }, { withCredentials: true });
    const response = res.data;
    if (response) {
      if (response.tasks && response.tasks[0] && response.tasks[0].messages && response.tasks[0].messages.length) {
        const err = response.tasks[0].messages.join('\n');
        store.commit('MSG_ADD_ACTION', { title: 'Внимание', status: err, type: 'message', error: true, asyncId: 'loginMsg' });
      }
      if (response.sessionToken || response.accessToken) {
        return response;
      }
    }
    return undefined;
  }

  // Запрос прав пользователя с бекенда
  public async getAccessRights(): Promise<any> {
    const taskID = this.addTask({ objectName: 'Пользователь_ПраваURL', methodName: 'получить' });
    if (taskID != null) {
      this.addParam(taskID, {});
    }
    const url = store.getters.getApiHost() + MAIN_URL;
    const response = await this.post(url);
    if (response && response.tasks && response.tasks[0].result) {
      const res: StackTableRow[] = []; // результат - массив с записями
      const headers = response.tasks[0].result.поля;
      const records = response.tasks[0].result.записи;
      // переберем пришедшие записи с бэкэнда
      return this.matchFieldHeader(records, headers, {});
    }
    return undefined;
  }

  // Запрашивает авторизацию у стороннего сервиса
  public async getExternalToken(service: string): Promise<any> {
    const taskID = this.addTask({ objectName: 'АПИСервисы', methodName: 'логин' });
    if (taskID != null) {
      this.addParam(taskID, { имяСервиса: service });
    }
    const url = store.getters.getApiHost() + MAIN_URL;
    const response = await this.post(url);
    if (response && response.tasks && response.tasks[0].result) {
      const result = response.tasks[0].result;
      if (!result.guid) {
        result.guid = store.getters.getToken();
      }
      return result;
    }
    return undefined;
  }

  // посылаем запрос
  public async run(): Promise<void> {
    if (!store.getters.isAuth()) {
      return;
    }
    // Добавляем рабочий месяц
    this.addWorkMonthInfo();

    // Если пришли вопросы с бэка - обрабатываем их
    let questions = null;
    let answer = {};
    do {
      const objName = this.getObjectName(0);
      if (objName) {
        const customAPI = StackSelectionCache.getCustomApi(objName);
        const url = store.getters.getApiHost(customAPI) + MAIN_URL;
        this.response = await this.post(url);

        // Работа вопросами поддерживается только в "однотасковом" режиме
        if (this.response && this.response.tasks && this.response.tasks.length === 1 && !this.noInternalMsg) {
          questions = this.getTaskQuestions(0);
          if (questions) {
            answer = await Vue.prototype.$stackMsg(questions);
            if (answer) {
              this.addParam(0, answer);
            }
          }
        }
        // ищем в ответе messages
        if (this.response && this.response.tasks && this.response.tasks.length > 0) {
          const tasks = this.response.tasks;
          const statuses = [];
          for (const task of tasks) {
            if (task.messages && task.messages.length > 0) {
              for (const key in task.messages) {
                statuses.push(task.messages[key]);
              }
            }
          }
          if (statuses.length > 0) {
            Vue.prototype.$toast(statuses.join('\n'), { color: 'info' });
          }
        }
      }
    } while (questions);
  }

  // если не нужно обрабатывать questions, которые прилетают с бэка
  public setNoInternalMsg(): void {
    this.noInternalMsg = true;
  }

  // добавляем инфу про текущий рабочий месяц
  private addWorkMonthInfo() {
    this.request.info = {};
    this.request.info.workMonth = store.getters.getWorkMonth();
  }

  // возвращает кол-во записей в таске
  public getTaskCount(taskID: number): number {
    if (!this.response || !this.response.tasks) {
      return 0;
    }
    const res = this.response.tasks[taskID].result as StackHttpResponseTaskResult;
    return res.всегоЗаписей;
  }

  // возвращаем результат запроса
  public getTaskResult(taskID: number): any {
    return this.response && this.response.tasks ? (this.response.tasks[taskID].result as any) : null;
  }

  // получаем сообщения бэка
  public getTaskQuestions(taskID: number): StackHttpTaskQuestions | null {
    if (this.response && this.response.tasks[taskID]) {
      const q = (this.response.tasks[taskID] as StackHttpResponseTask).questions;
      return q || null;
    }
    return null;
  }

  private getObjectName(taskID: number): string | undefined {
    return this.request && this.request.tasks && this.request.tasks[taskID] ? this.request.tasks[taskID].objectName : undefined;
  }

  public getRights(): apiRights | undefined {
    const res = this.response?.tasks[0]?.result as StackHttpResponseTaskResult;
    return res?.права;
  }

  // парсит и сопостовляет данные таска из массивов поля и записи
  public getTaskRows(taskID: number, total = false): StackTableRow[] {
    if (!store.getters.isAuth()) {
      return [];
    }

    const result = this.getTaskResult(taskID);
    const objName = this.getObjectName(taskID);

    if (!result || !objName) {
      throw new Error('getTaskRows: Недостаточно данных');
    }
    const rowTemplate = this.getRowTemplate(objName);
    if (!rowTemplate) {
      throw new Error(`getTaskRows: Нет шаблона ${objName}`);
    }

    const headers = result.поля; // массив заголовков
    const records = !total ? result.записи : [result.итог]; // массив записей
    return this.matchFieldHeader(records, headers, rowTemplate);
  }

  // обновление записи
  public updateRecord(objectName: string, record: StackTableRow, methodName = 'обновить', params?: TParams): number {
    if (!record) {
      throw new Error('нечего сохранять');
    }
    if (!objectName) {
      throw new Error('для запроса не указан objectName');
    }

    const task: StackHttpRequestTask = { objectName, methodName };
    const taskID = this.addTask(task);
    if (taskID != null) {
      this.addParam(taskID, { запись: record, ...params });
    }
    return taskID;
  }

  public updateRecords(objectName: string, record: any[], methodName = 'обновить', params?: TParams): number {
    if (!record) {
      throw new Error('нечего сохранять');
    }
    if (!objectName) {
      throw new Error('для запроса не указан objectName');
    }

    const task: StackHttpRequestTask = { objectName, methodName };
    const taskID = this.addTask(task);
    if (taskID != null) {
      this.addParam(taskID, { записи: record, ...params });
    }
    return taskID;
  }

  // Удалить запись
  public deleteRecord(objectName: string, record: StackTableRow, params?: TParams): number {
    if (!record || !record.$номерЗаписи) {
      // eslint-disable-next-line
      console.log('Ошибка удаления', record);
      throw new Error('нечего удалять');
    }
    if (!objectName) {
      throw new Error('для запроса не указан objectName');
    }
    const task: StackHttpRequestTask = { objectName, methodName: 'удалить' };
    const taskID = this.addTask(task);
    if (taskID != null) {
      this.addParam(taskID, { номерЗаписи: record.$номерЗаписи, ...params });
    }
    return taskID;
  }

  // Переместить запись
  public moveRecord(objectName: string, record: StackTableRow, params?: TParams): number {
    if (!record || !record.$номерЗаписи) {
      // eslint-disable-next-line
      console.log('Ошибка переноса', record);
      throw new Error('нечего переносить');
    }
    if (!objectName) {
      throw new Error('для запроса не указан objectName');
    }
    const task: StackHttpRequestTask = { objectName, methodName: 'переместить' };
    const taskID = this.addTask(task);
    if (taskID != null) {
      this.addParam(taskID, { номерЗаписи: record.$номерЗаписи, ...params });
    }
    return taskID;
  }

  // получить выборку
  public fetch(objectName: string, params?: StackHttpRequestTaskParam, methodName = 'получить', objectType = 'выборка'): number {
    if (!params) {
      params = {};
    }
    if (!objectName) {
      throw new Error('для запроса не указан objectName');
    }

    const task: StackHttpRequestTask = objectType !== 'выборка' ? { objectName, methodName, objectType } : { objectName, methodName };
    const taskID = this.addTask(task);
    if (taskID != null) {
      this.addParam(taskID, params);
    }
    const glParams = store.getters.getGlobalHttpParams();
    if (glParams !== undefined) {
      if (this.request.tasks && this.request.tasks[taskID]) {
        this.request.tasks[taskID] = { ...this.request.tasks[taskID], ...glParams };
      }
    }
    return taskID;
  }

  // делаем запрос асинхронным
  public setAsyncJob(): void {
    this.request.asyncJob = true;
    this.headers[ASYNC_JOB_HEADER] = true;
  }

  // добавление параметров к таску
  public addParam(taskID: number, params: StackHttpRequestTaskParam): void {
    if (this.request.tasks && this.request.tasks[taskID]) {
      if (!this.request.tasks[taskID].params) {
        this.request.tasks[taskID].params = {};
      }
      this.request.tasks[taskID].params = Object.assign(this.request.tasks[taskID].params, params);
      // this.request.tasks[taskID].id = taskID;
    } else {
      throw new Error('addParam - некуда добавлять параметры');
    }
  }

  // добавляем таск для отправки
  public addTask(task: StackHttpRequestTask): number {
    if (!this.request.tasks) {
      this.clear();
    }
    // @ts-ignore
    return this.request.tasks.push(task) - 1;
  }

  // очищаем пулл запросов
  public clear(): void {
    this.request = {};
    this.request.tasks = [];
  }

  // сопоставляет поля и заголовки по шаблону
  private matchFieldHeader(records: any, headers: any, rowTemplate: StackTableRow): StackTableRow[] {
    const res: StackTableRow[] = []; // результат - массив с записями
    // переберем пришедшие записи с бэкэнда
    for (const record of records) {
      if (record) {
        const currentRow = Object.assign({}, rowTemplate);
        for (const i in record) {
          const fieldName: string = headers[i]; // определим название поля из заголовков BE
          currentRow[fieldName] = record[i];
        }
        currentRow.$естьИзменения = false;
        res.push(currentRow);
      }
    }
    return res;
  }

  // подготавливает шаблон строки выборки согласно формата
  private getRowTemplate(objectName: string): StackTableRow {
    const fieldList = StackSelectionCache.getFields(objectName);
    const rowTemplate: StackTableRow = {};
    for (const col of fieldList) {
      rowTemplate[col] = '';
    }
    return rowTemplate;
  }

  // отправляем post запрос
  private async post(URL: string, options: any | undefined = undefined) {
    const token = store.getters.getToken();
    this.headers['S-Access-Token'] = token;
    if (store.getters.isDebugMode()) {
      this.headers[S_DEBUG_HEADER] = true;
    }
    const timeout = options?.timeout || store.getters.getTimeout();
    let latency = new Date().getTime();
    const res: any = await axios.post(URL, this.request, { headers: this.headers, timeout });
    latency = new Date().getTime() - latency;
    const maxLatency = store.getters.sentryMaxLatancy();
    if (maxLatency > 0 && latency > maxLatency) {
      store.commit('SENTRY_SEND_EXCEPTION', { message: 'Request latency warning', payload: { request: JSON.stringify(this.request), latency, maxLatency } });
    }
    return res.data;
  }

  public async getApiDialog(objectName: string, params?: object, objectType = 'диалог'): Promise<any> {
    try {
      this.clear();
      this.fetch(objectName, params, 'получитьДиалог', objectType);
      await this.run();
      const data = this.getTaskResult(0);
      return data?.dialog;
    } catch (error: AnyException) {
      return undefined;
    }
  }
}

let isRefreshing = false;
let failedQueue: any[] = [];
// https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
const processQueue = (error: Error | null, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Response interceptor
axios.interceptors.response.use(
  (response: any) => {
    const err = StackApi.getResponseError(response.data);
    if (err) {
      switch (+err.code) {
        // ошибка аутентификации
        case 401:
          store.commit('SET_TOKEN', ''); // окно аутентификации
          break;
        // Идентификационный код
        case 402:
          if (err.data && err.data.dbCode !== undefined) {
            store.commit('SET_LICENSE_KEY', err.data.dbCode);
            return response;
          }
          break;
        case 1000: // ComStack is not ready
        case 1001: // Unknown function
        case 1002: // Uncatched exception in ComStack
        case 1003: // ComStack was terminated
        case 1004: // Service error
        case 1005: // ComStack is reloading
        case 1007: // Error while waiting completions of user requests
          console.log('response error:', response.data, err);
          store.commit('SET_CONNECTED', false);
          break;
        case 1006: // Timeout while waiting completions of user requests
          if (err.message) {
            // Vue.prototype.$toast(err.message, { color: 'error' });
            store.commit('MSG_ADD', {
              title: 'При выполнении операции произошла ошибка !',
              status: err.message,
              type: 'message',
              error: true,
              asyncId: 'loginMsg',
            });
          }
          console.log('response error:', response.data, err);
          store.commit('SET_CONNECTED', false);
          break;
        default:
          console.log('response error:', response.data, err);
          // Vue.prototype.$toast(err.message, { color: 'error' });
          store.commit('MSG_ADD', {
            title: 'При выполнении операции произошла ошибка !',
            status: err.message,
            type: 'message',
            error: true,
          });
      }
      return Promise.reject(err);
    }
    return response;
  },
  // real http errors
  error => {
    if (error.response && error.response.status === 503) {
      store.commit('SET_CONNECTED', false);
      return Promise.reject(error);
    }
    if (error.response && error.response.status === 403) {
      store.commit('SET_TOKEN', ''); // окно аутентификации
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    const urlRefresh = store.getters.getApiHostAuthRefresh();
    if (error.response && error.response.status === 401 && originalRequest.url !== urlRefresh) {
      // Если уже идёт обновление токена то ставим запрос в очередь и ждём
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        }).then((token) => {
          originalRequest.headers['S-Access-Token'] = token;
          return axios(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }
      if (store.getters.isAuth()) {
        isRefreshing = true;
        // @ts-ignore
        const fingerprint = store.state.systemStore.fingerprint;
        // @ts-ignore
        const accessToken = store.state.systemStore.sessionToken;
        // При NO_SSL_AUTH токен храним в памяти а не в http-only куках и шлём его в заголовке
        // @ts-ignore
        const headers = store.state.configStore.NO_SSL_AUTH ? { 'S-Refresh-Token': store.state.systemStore.refreshToken } : undefined;
        return new Promise(function (resolve, reject) {
          axios.post(urlRefresh, { fingerprint, accessToken }, { withCredentials: true, headers })
            .then(({ data }: any) => {
              store.commit('SET_TOKEN', data?.accessToken || '');
              originalRequest.headers['S-Access-Token'] = data?.accessToken;
              // @ts-ignore
              if (store.state.configStore.NO_SSL_AUTH && data.refreshToken && store.state.systemStore.refreshToken !== data?.refreshToken) {
                store.commit('SET_REFRESH_TOKEN', data.refreshToken || '');
                originalRequest.headers['S-Refresh-Token'] = data.refreshToken;
              }
              processQueue(null, data?.accessToken);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              store.commit('SET_TOKEN', '');
              reject(err);
            })
            .finally(() => { isRefreshing = false; });
        });
      }
    }
    let err: StackHttpResponseError;
    if (!error.response) {
      store.commit('SET_CONNECTED', false);
      err = { message: 'Нет связи с сервером', code: 404, data: null };
      return Promise.reject(err);
    }
    return Promise.reject(error);
  },
);