
import Vue from 'vue';

import PrintKomplekt from '../$dialogs/PrintKomplekt.vue';
import DialogPhaze from '../$dialogs/dialogphaze.vue';
import TransitionFaza from '../$dialogs/transitionfaza.vue';
import SudNum from '../$dialogs/SudNum.vue';

export default Vue.extend({
  name: 'LawsuitshistoryTab',
  components: {
    'Print-Komplekt': PrintKomplekt,
    'TransitionFaza-dialog': TransitionFaza,
    'Phaze-dialog': DialogPhaze,
    'SudNum-dialog': SudNum,
  },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
    readonly: { type: [Boolean], default: false },
    otdel: { type: [Number], default: 2 },
  },
  computed: {
    headertable(): boolean {
      return this.otdel === 3;
    },
  },
  data() {
    return {
      PerehodDialogVisible: false,
      ViolationsactsAddVisible: false,
      ServicesactsAddVisible: false,
      SelectGpVisible: false,
      printdialog: false,
      lawsuits: [] as any,
      парам: [] as any,
      selectgp: [] as any,
      servicesacts: [] as any,
      currentfaza: -1,
      currentsost: -1,
      contacts: new this.$HttpModel('ЛицевыеСчета.Телефоны'),
      items: [] as StackTableRow[],
      more_Information: ``,
      sost_ls: ``,
      actions: [
        { title: 'Печать комплекта', icon: '$vuetify.icons.printer', action: 'print_doc' },
        { title: 'Удалить', icon: '$vuetify.icons.delete', action: 'delete', color: 'error' },
      ],
      readytoupd: false,
    };
  },

  methods: {
    async onBtnChoosePerehod(dop: object) {
      this.PerehodDialogVisible = false;
      this.ServicesactsAddVisible = false;
      this.ViolationsactsAddVisible = false;
      this.SelectGpVisible = false;
      // @ts-ignore
      this.$refs.lawsuitshistory.onToolBarAction({
        action: 'add',
        payload: { параметры: dop },
      });
    },
    printkomlect(item: any) {
      this.lawsuits = [] as any;
      this.lawsuits.push({ row_id: this.record.$номерЗаписи, фаза: item.$номерЗаписи });
      this.currentsost = item.$номерЗаписи;
      if (this.lawsuits.length === 0) {
        this.$toast('Документы не выбраны!', { color: 'error' });
      } else {
        this.printdialog = true;
      }
    },
    async onAddNewPhase() {
      const res = !(await new this.$HttpModel('СостоянияДела').executeMethod('ЕстьВсеДанныеНаФазе', { номерЗаписи: this.record.$номерЗаписи, фаза: this.record['документ-фаза'] }));
      if (res) {
        this.readytoupd = !!(await new this.$HttpModel('СостоянияДела').executeMethod('СуммаДолгаОбновление', { номерЗаписи: this.record.$номерЗаписи }));
        this.PerehodDialogVisible = true;
      }
    },
    async getContacts() {
      this.items = [];
      if (this.record && this.record['счет-взыскания'] > 0) {
        const contacts = await this.contacts.getRecords({ владелец: this.record['счет-взыскания'] });
        if (contacts.length > 0) {
          for (const key in contacts) {
            this.items.push({ title: `${contacts[key].номер} (${contacts[key]['@принадлежность']})`, value: contacts[key].примечание });
          }
        }
      }
    },
    async openLS() {
      const lsID = this.record['счет-взыскания'];
      const routeData = this.$router.resolve({ path: `/accounts/${lsID}` });
      window.open(routeData.href, '_blank');
    },
    updateCourtNumbers() {
      this.$sendMsg('stack-table', 'СудебныеНомера', 'reload');
    },
  },
  async created() {
    const moreInformation = await new this.$HttpModel('ВзысканиеЗадолженностей').executeMethod('ПолучитьДополнительнуюИнформацию', { счет: this.record['счет-взыскания'] });
    if (moreInformation.СтатусЛС) {
      this.sost_ls = moreInformation.СтатусЛС;
    }
    if (moreInformation.ДопИНфо) {
      this.more_Information = moreInformation.ДопИНфо;
    }
  },
  watch: {
    record: {
      immediate: true,
      handler(to) {
        this.getContacts();
      },
    },
  },
});
