var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('stack-input',_vm._b({ref:"pokaz",attrs:{"type":"number","clearable":""},on:{"input":function($event){return _vm.onChangePok($event)},"change":function($event){return _vm.$emit('change')},"blur":function($event){return _vm.onBlur()}},model:{value:(_vm.показание),callback:function ($$v) {_vm.показание=$$v},expression:"показание"}},'stack-input',_vm.$attrs,false)),_c('v-menu',{attrs:{"absolute":"","position-x":_vm.menux,"position-y":_vm.menuy,"close-on-click":false},model:{value:(_vm.askDialog),callback:function ($$v) {_vm.askDialog=$$v},expression:"askDialog"}},[_c('v-list',[_c('v-list-item',{on:{"click":() => {
            _vm.$emit('error', false);
            _vm.перекрутка = true;
            _vm.calcRashod();
          }}},[_c('v-list-item-title',[_vm._v("Перекрутка счетчика")])],1),_c('v-list-item',{on:{"click":() => {
            _vm.$emit('error', true);
            _vm.$refs.pokaz.focus();
          }}},[_c('v-list-item-title',[_vm._v("Ошибка оператора")])],1),_c('v-list-item',{on:{"click":() => {
            _vm.$emit('error', false);
            _vm.перекрутка = false;
            _vm.calcRashod();
          }}},[_c('v-list-item-title',[_vm._v("Отрицательный расход")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }