
import Vue from 'vue';
import AskMenuDialog, { UserAskError } from './AskMenuDialog.vue';

interface FilterRecords {
  название?: string;
  флаг?: number;
  услуга?: number;
  вид?: number;
  дата?: string;
  владелец?: number;
}

export default Vue.extend({
  components: {
    AskMenuDialog,
  },
  props: {
    /**
     * выборка
     */
    dataModel: { type: [String, Object], default: null },
    /**
     *  текущая запись
     */
    record: { type: Object, required: true },
    /**
     *  отсюда читаем возможные предустановленные дату,месяц и тип ввода
     */
    inputData: { type: Object, default: null },
    /**
     * отображать дату в таблице
     */
    noShowDate: { type: Boolean, default: false },
    /**
     * отображать колонку с услугой в таблице
     */
    showService: { type: Boolean, default: false },
    /**
     * отображать итоговый расход за месяц в таблице
     */
    showTotal: { type: Boolean, default: false },
    /**
     * отображать колонку с признаком субабонента
     */
    showSub: { type: Boolean, default: false },
    /**
     * отображать колонку с номером ТУ
     */
    showTu: { type: Boolean, default: false },
    /**
     * отображать колонку с состоянием
     */
    showState: { type: Boolean, default: false },
    /**
     * отображать колонку с датой следующей поверки
     */
    showDateCheck: { type: Boolean, default: false },
    /**
     * не отображать колонки с доп.итогами
     */
    noShowDopRas: { type: Boolean, default: false },
    /**
     * не отображать адрес в названии счетчика
     */
    noShowAddress: { type: Boolean, default: false },
    /**
     * не отображать номер ЛС
     */
    noShowNumberLs: { type: Boolean, default: false },
    /**
     * отображать дату установки счетчика
     */
    showDateInst: { type: Boolean, default: false },
    /**
     * не отображать колонку с зоной
     */
    noShowZone: { type: Boolean, default: false },
    /**
     * массив мест установки счетчика
     */
    placeInst: { type: Array, default: null },
    width: { type: Number, default: 0 },
  },
  computed: {
    nameCounter(): string {
      let name = '';
      if (this.record.групповой !== undefined) {
        name = 'По услуге ' + this.record['номер услуги'] + ' ' + this.record.модель + ' №' + this.record.заводскойномер;
        if (this.record.примечание !== '') {
          name += ' ' + this.record.примечание;
        }
      } else {
        const наименование = this.record.наименование ? this.record.наименование : '';
        const заводскойномер = this.record.заводскойномер ? ' №' + this.record.заводскойномер : '';
        if (this.record.типсчетчика !== 5) {
          name = 'ИПУ ';
        } else {
          name = 'ОКПУ ';
        }
        const place = this.placeInst && this.placeInst[+this.record['место установки']] ? this.placeInst[+this.record['место установки']] : '';
        const вариант = this.record.вариантстрока ? this.record.вариантстрока : '';
        name += наименование + (вариант ? ' ' + вариант : '') + ' ' + заводскойномер + (place ? ` ${place}` : '');
      }
      return name;
    },
    numLS(): string {
      return this.record.номерлс ? `Л/С ${this.record.номерлс}` : '';
    },
    dateInst(): string {
      return this.record?.['датаустановки'] ? ` Установлен ${this.$stackDate.format(this.record['датаустановки'], 'DD.MM.YYYY')}` : '';
    },
    addressCounter(): string {
      const len = this.record.адрес ? this.record.адрес.length : 0;
      return len ? this.record.адрес.substring(len - 110) : '';
    },
    numberTu(): string {
      return this.record.номерту;
    },
    итог(): number {
      const koef = (this.record['коэффициент трансформации'] ? this.record['коэффициент трансформации'] : 1) as number;
      let dec = this.record.дробнаяразрядность ? +this.record.дробнаяразрядность : 0;
      dec = dec || 9;
      const result = this.round(+this.record.расход * +koef + +this.record['дополнительный расход'], dec);
      return result;
    },
    openMonth(): Date {
      return this.$store.getters.getOpenMonth();
    },
    style(): string {
      if (this.record.$ошибкаПоказания || this.record['ошибки'] || this.record.isBusy || this.record.$ошибкаТарифности) {
        return 'background-color: var(--v-error-lighten4);';
      }
      if (this.$store.getters.getCurrentTask() === 'ul') {
        if (this.$store.getters.getWorkMonth() <= this.$stackDate.firstDay(this.record['@датпредсч'])) {
          return 'background-color: var(--v-secondary-base);';
        } else {
          return '';
        }
      }
      if (
        this.record['@меспредсч'] &&
        this.record['@меспредсч'] >= this.record['расчетный месяц']
        //  || (getMonth(this.record['@датпредсч']) >= getMonth(this.record.дата) && getYear(this.record['@датпредсч']) >= getYear(this.record.дата))
      ) {
        return 'background-color: var(--v-secondary-base);';
      }
      return '';
    },
    colorZone(): string {
      switch (this.record['@зона'].toLowerCase()) {
        case 'хвс':
          return 'info';
        case 'гвс':
          return 'error';
        default:
          return '';
      }
    },
    isDisabled(): Boolean {
      return this.record.$толькоЧтение;
    },
    colorText(): string {
      return !this.isDisabled ? '' : 'grey--text';
    },
  },
  data() {
    return {
      menux: 0,
      menuy: 0,
      askDialog: false,
      askDialogType: 0,
    };
  },
  methods: {
    onAskMenuClick(item: UserAskError) {
      this.askDialog = false;
      if (item.counterOverflow) {
        this.startAgain();
        this.record.$ошибкаПоказания = false;
        this.changeRas();
      }
      if (item.userError) {
        // @ts-ignore
        this.$refs.reading.focus();
        this.record.$ошибкаПоказания = false;
        this.record.показание = '';
        this.record.расход = null;
        this.$emit('change', this.record);
      }
      if (item.neative) {
        this.record.$ошибкаПоказания = false;
        this.changeRas();
      }
      if (item.overflow) {
        this.record.$ошибкаПоказания = false;
        this.$emit('change', this.record);
      }
    },
    zone(item: string): string | undefined {
      if (!item) {
        return '';
      }
      switch (item.toLowerCase()) {
        case 'мг день':
          return '$vuetify.icons.mg_zone_day';
        case 'мг ночь':
          return '$vuetify.icons.mg_zone_night';
        case 'мг':
          return '$vuetify.icons.mg_zone';
        case 'мг пик':
          return '$vuetify.icons.mg_zone_pik';
        case 'мг ппик':
          return '$vuetify.icons.mg_zone_ppik';
        case 'день':
          return '$vuetify.icons.zone_day';
        case 'ночь':
          return '$vuetify.icons.zone_night';
        case 'ппик':
          return '$vuetify.icons.zone_ppik';
        case 'пик':
          return '$vuetify.icons.zone_pik';
        case 'хвс':
          return '$vuetify.icons.pu_water';
        case 'гвс':
          return '$vuetify.icons.pu_water';
        default:
          return undefined;
      }
    },
    async changeReading() {
      this.$set(this.record, '$ошибкаПоказания', false); // предварительно сбрасываем флаг
      const msgRules = this.$stackRules.rulesPok(this.record);
      if (!this.isError(msgRules)) {
        let dec = this.record.дробнаяразрядность ? +this.record.дробнаяразрядность : 0;
        dec = dec || 9;
        this.record.расход = this.record.показание !== null && this.record.показание.toString() !== '' ? this.round(+this.record.показание - +this.record['@покпредсч'], dec) : 0;
        if (this.record.показание !== null && this.record.показание.toString() !== '' && +this.record.показание < +this.record['@покпредсч']) {
          this.record.$ошибкаПоказания = true;
          this.showAskDialog(0);
        } else {
          this.record.$ошибкаПоказания = false;
          await this.changeRas();
        }
      } else {
        this.record.$ошибкаПоказания = true;
        this.askDialog = false;
      }
      this.$emit('change', this.record);
    },
    showAskDialog(type = 0) {
      // @ts-ignore
      const pos = this.$refs.reading.getBoundingClientRect();
      this.menux = pos.left + pos.width / 2;
      this.menuy = pos.top;
      this.askDialogType = type;
      this.askDialog = true;
    },
    async changeRas() {
      // если висит флаг isBusy - то ничего не проверяем
      if (!this.record.isBusy) {
        this.$set(this.record, 'isBusy', true);
        // Если есть показание, отличающееся от предыдущего и нет расхода
        if (this.record.показание && !this.record.расход && +this.record.показание !== +this.record['@покпредсч']) {
          this.record.показание = null;
        }
        const msgRules = this.$stackRules.rulesRash(this.record);
        if (!this.isError(msgRules)) {
          if (msgRules === 'Расход превышает предельное значение') {
            this.record.$ошибкаПоказания = true;
            this.showAskDialog(1);
          } else {
            this.askDialog = false;
          }
          // msgRules = await this.checkMultiRash();
        }
        // для фл при превышении расхода надо просто выводить сообщение
        if (this.isError(msgRules)) {
          this.record.показание = 0;
          this.record.расход = 0;
        }
        this.$set(this.record, 'isBusy', false);
        this.$emit('change', this.record);
      }
    },
    changeDopRas() {
      this.$emit('change', this.record);
    },
    async changeDate() {
      this.record.$ошибкаДаты = false;
      const msgRules = this.dateRule(this.record) || this.$stackRules.rulesDate(this.record);
      if (this.isError(msgRules)) {
        this.record.дата = null;
        this.$nextTick(() => {
          this.applyInputData();
        });
      }
      this.$emit('change', this.record);
    },
    dateRule(record: StackTableRow) {
      if (this.$stackDate.firstDay(record.дата) !== this.$stackDate.firstDay(record['расчетный месяц'])) {
        return 'Месяц должен соответствовать дате показания';
      }
      return false;
    },
    async changeMonth() {
      this.record.$ошибкаДаты = false;
      const typecounter = this.record.типсчетчика;
      this.record.типсчетчика = typecounter === 1 ? 'индивидуальный' : 'групповой';
      this.record.месяц = this.record['расчетный месяц'];
      this.record.режим = 'редактирование';
      this.record.месяцПред = this.record['@меспредсч'];
      const msgRules = this.$stackRules.rulesMonth(this.record);
      if (this.isError(msgRules)) {
        this.record['расчетный месяц'] = null;
        this.$nextTick(() => {
          this.applyInputData();
        });
      }
      this.record.типсчетчика = typecounter;
      this.$emit('change', this.record);
    },

    // перекрутка
    startAgain() {
      const maxPok = +'9'.repeat(this.record.разрядность) + 1;
      let dec = this.record.дробнаяразрядность ? +this.record.дробнаяразрядность : 0;
      dec = dec || 9;
      this.record.расход = this.round(+maxPok + +this.record.показание - +this.record['@покпредсч'], dec);
      this.$emit('change', this.record);
    },
    applyInputData() {
      if (this.record && (!this.record.дата || !this.record['расчетный месяц'])) {
        let дата = this.$store.getters.getCurrentDate();
        let месяц = this.$stackDate.firstDay(this.$store.getters.getCurrentDate());
        if (this.inputData) {
          this.record.типввода = 1;
          месяц = this.inputData.месяц ? this.inputData.месяц : (this.inputData.дата ? this.$stackDate.firstDay(this.inputData.дата) : this.openMonth);
          дата = this.inputData.дата ? this.inputData.дата : дата;
        }
        if (Date.parse(дата) <= Date.parse(this.record['@датпредсч'])) {
          this.record.дата = this.$stackDate.addDays(this.record['@датпредсч'], 1);
          if (this.record.типсчетчика === 5) {
            // для одпу расчетный месяц должен соответствовать дате показаний
            this.record['расчетный месяц'] = this.$stackDate.firstDay(this.record.дата);
            месяц = this.record['расчетный месяц'];
          }
        } else {
          this.record.дата = дата;
        }

        if (Date.parse(месяц) < Date.parse(this.record['@меспредсч'])) {
          this.record['расчетный месяц'] = this.record['@меспредсч'];
        } else {
          this.record['расчетный месяц'] = месяц;
        }
      }
    },
    isError(msg: string | boolean) {
      if (typeof msg === 'string') {
        const critError = this.isCriticalError(msg);
        if (msg !== 'Расход превышает предельное значение') {
          this.$toast(msg, { color: critError ? 'error' : 'info' });
        }
        return critError;
      } else {
        return false;
      }
    },
    round(value: number, decimals: number) {
      const numConvert = Math.pow(10, decimals);
      return Number(Math.round(value * numConvert) / numConvert);
    },
    // аналог функции ЭтоКритичнаяОшибкаПоказанияПоФлагу( ФлагОшибок ) , только в данном случае определяется по тексту
    isCriticalError(msg_err: string) {
      switch (msg_err) {
        case 'Расход превышает предельное значение':
          return false;
        case 'Отрицательный расход':
          return false;
        default:
          return true;
      }
    },
    // проверим трехкратное превышение расхода
    // async checkMultiRash() {
    //   if (this.record && this.record.среднийрасход) {
    //     if (this.record.расход > +this.record.среднийрасход * 3) {
    //       if (!(await this.$yesno('Расход имеет 3-х кратное превышение. Продолжить?'))) {
    //         return 'Отклонено';
    //       }
    //     }
    //   }
    //   return true;
    // },
    getDataObject() {
      let dataObject = {} as DataModel;
      if (typeof this.dataModel === 'string') {
        dataObject = new this.$HttpModel(this.dataModel);
      } else {
        dataObject = new this.$StaticModel(this.dataModel);
      }
      return dataObject;
    },
  },
  watch: {
    inputData: {
      immediate: true,
      deep: true,
      handler(to: FilterRecords) {
        if (to) {
          this.applyInputData();
          // TODO таким образом зачем то (???) убираются нули в показаниях при инициализации, чтоб можно было вводить ноль
          if (this.record && this.record.показание === 0) {
            this.record.показание = null;
          }
        }
      },
    },
    итог(to: number) {
      this.$set(this.record, 'итоговый расход', to);
    },
    // record: {
    //   deep: true,
    //   handler(to: StackTableRow) {
    //     if (to) {
    //       this.$emit('change', to);
    //     }
    //   },
    // },
  },
});
