
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      items: [
        { title: 'Мастер экспорта\nв Стек-Интеграцию', img: 'gis_export', to: { name: 'operations-integrexport' }, color: 'stack' },
        { title: 'Проверки ведения базы данных\nдля выгрузки в ГИС ЖКХ', img: 'gis_checks', to: { name: 'operations-checklists' }, color: 'stack' },
        { title: 'Импорт файлов из ГИС', img: 'gis_import', to: { name: 'operations-uploadsGIS' }, color: 'stack' },
      ],
      items2: [{ title: 'Экспорт для online-касс', img: 'gis_export_online_kassa', to: { name: 'operations-integrexportok' }, color: 'stack' }],
    };
  },
});
