const acts: StackSelection = {
  objectName: 'Юнит-тесты',
  description: 'Юнит тесты',
  hier: false,
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.завершенсошибкой) {
      // @ts-ignore
      row.$data.имя = { $иконка: '$vuetify.icons.error', $иконка_цвет: 'error' };
    } else {
      // @ts-ignore
      row.$data.имя = { $иконка: '$vuetify.icons.done' };
    }
    return row;
  },
  fields: [
    {
      field: 'завершенсошибкой',
      text: 'завершенсошибкой',
      type: 'String',
    },
    {
      field: 'имя',
      text: 'имя',
      type: 'String',
    },
    {
      field: 'файл',
      text: 'Файл',
      type: 'String',
    },
    {
      field: 'расположение',
      text: 'Расположение',
      type: 'String',
    },
    {
      field: 'времявыполнения',
      text: 'Время, мс',
      type: 'String',
    },
  ],
};
export default acts;
