const ls: StackSelection = {
  objectName: 'ДелениеЛицевого.Параметры до',
  description: 'Старый лицевой',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
      readonly: true,
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
      readonly: true,
    },
    {
      field: '@значение',
      text: 'Значение',
      type: 'String',
      readonly: true,
    },
    {
      field: 'row_id',
      text: 'ROW_ID',
      type: 'Number',
    },
    {
      field: 'датнач',
      text: 'ДатНач',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'ДатКнц',
      type: 'Date',
    },
    {
      field: 'row_id_par',
      text: 'ROW_ID_par',
      type: 'Number',
    },
  ],
};

export default ls;
