const grcount: StackSelection = {
  recordRoute: '',
  objectName: 'ГрупповыеСчетчики.Параметры',
  description: '',
  fields: [
    {
      field: 'виды-параметры>название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'виды-параметры>наименование',
      text: 'Наименование',
      type: 'String',
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
    {
      field: 'виды-параметры',
      text: 'Виды-Параметры',
      type: 'Link',
    },
    {
      field: 'датнач',
      text: 'Дата начала',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Дата окончания',
      type: 'Date',
    },
    {
      field: 'строка',
      text: 'Значение',
      type: 'String',
      align: 'right',
      computed: true,
    },
  ],
};

export default grcount;
