const serv: StackSelection = {
  objectName: 'УслугиВыбор',
  description: 'Услуги',
  hier: true,
  openLink: 'references-services',
  fields: [
    {
      field: 'название',
      text: 'Краткое название',
      type: 'String',
    },
    {
      field: 'наименование',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'внешний код 1',
      text: 'Внешний код 1',
      type: 'String',
    },
    {
      field: 'внешний код 2',
      text: 'Внешний код 2',
      type: 'String',
    },
    {
      field: 'счет1',
      text: 'Счет1',
      type: 'String',
    },
    {
      field: 'счет2',
      text: 'Счет2',
      type: 'String',
    },
    {
      field: 'единица измерения',
      text: 'Единица измерения',
      type: 'String',
    },
    {
      field: 'платежная группа',
      text: 'Платежная группа',
      type: 'Number',
    },
    {
      field: 'группа',
      text: 'Группа оптимизации льгот',
      type: 'String',
    },
    {
      field: 'параметры услуги',
      text: 'Параметры услуги',
      type: 'String',
    },
    {
      field: 'признаки',
      text: 'Признаки',
      type: 'String',
    },
    {
      field: 'поставщик по умолчанию',
      text: 'Поставщик по умолчанию',
      type: 'Number',
    },
    {
      field: '@тариф',
      text: 'Тариф',
      type: 'Number',
    },
    {
      field: '@сверхтариф',
      text: 'Тариф сверх нормы',
      type: 'Number',
    },
    {
      field: '@норма',
      text: 'Норма',
      type: 'Number',
    },
    {
      field: 'тип отображения',
      text: 'Тип отображения',
      type: 'Number',
    },
    {
      field: '@уктариф',
      text: 'УКТариф',
      type: 'Number',
    },
    {
      field: '@индтариф',
      text: 'ИндТариф',
      type: 'Number',
    },
    {
      field: '@калькуляция',
      text: 'Калькуляция',
      type: 'Number',
    },
    {
      field: 'склад-услуги',
      text: 'склад-услуги',
      type: 'Link',
    },
    {
      field: 'группа счетчика',
      text: 'группа счетчика',
      type: 'Link',
    },
  ],
};

export default serv;
