
import Vue from 'vue';

export default Vue.extend({
  props: {
    ownerID: { type: [String, Number], default: null },
    readytoupd: { type: [Boolean], default: false },
  },
  data() {
    return {
      item: {
        надату: this.$store.getters.getCurrentDate(),
        позиция: -1,
        необнсумму: 0,
      },
    };
  },
});
