
import Vue from 'vue';
import PrimaryTab from './PrimaryTab.vue';
import ParamTab from './ParamTab.vue';
import PeopleTab from './PeopleTab.vue';
import OwnerDocsTab from './OwnerDocsTab.vue';
import ContactsTab from './ContactsTab.vue';
import WebTab from './WebTab.vue';

export default Vue.extend({
  components: { PrimaryTab, ParamTab, PeopleTab, OwnerDocsTab, ContactsTab, WebTab },
  name: 'DocumentsTab',
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
    ownerID: { type: [Number, String], default: null },
  },
  computed: {
    isNewRecord(): boolean {
      return this.ownerID === 'new';
    },
  },
  methods: {
    onCommonTab() {
      // @ts-ignore
      if (this.$refs.primarytab) {
        this.$sendMsg('stack-dialog', 'ЛицевыеСчета', 'reload');
        // @ts-ignore
        if (this.$refs.primarytab.$refs.lsInfo) {
          // @ts-ignore
          this.$refs.primarytab.$refs.lsInfo.fetchData();
        }
      }
    },
    onParTab() {
      this.$sendMsg('stack-table', 'ЛицевыеСчета.Параметры', 'reload');
    },
  },
});
