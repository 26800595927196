
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
    params: {
      type: Object,
      default: (): any => {
        return {};
      },
    },
  },
  data() {
    return {
      item: {
        ...this.value,
        МесНач: this.$stackDate.format(this.$stackDate.addYears(this.$store.getters.getWorkMonth(), -1).toISOString(), 'ISO'),
        МесКнц: this.$store.getters.getWorkMonth(),
        'Вызов из веба': true,
        ВидДокСоб: 1,
        ПараметрыОтчета: 1,
      },
    };
  },
});
