const parhouse: StackSelection = {
  recordRoute: '',
  objectName: 'ЛицевыеСчета.ПараметрыДома',
  description: 'Параметры дома',
  fields: [
    {
      field: 'дом-виды параметров>название',
      text: 'Краткое название',
      type: 'String',
    },
    {
      field: 'дом-виды параметров>наименование',
      text: 'Параметр',
      type: 'String',
    },
    {
      field: 'строка',
      text: 'Значение',
      type: 'String',
      align: 'right',
    },
    {
      field: 'датнач',
      text: 'Дата начала',
      type: 'Date',
      align: 'right',
    },
    {
      field: 'даткнц',
      text: 'Дата окончания',
      type: 'Date',
      align: 'right',
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
      align: 'right',
    },
  ],
};

export default parhouse;
