
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    hideDate: { type: Boolean, default: false },
  },
  methods: {
    onSelect(selected: StackTableRow[]) {
      this.record.примечание = selected && selected.length > 0 ? selected[0].название : '';
    },
  },
});
