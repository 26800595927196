
import Vue from 'vue';
import { ReportsBuilder, HttpModel } from '@/stackEngine';

export default Vue.extend({
  props: {
    dataObject: { type: Object, required: true },
    params: { type: Object, default: undefined },
    selectedItems: { type: [Object, Array], required: true }, // Array парсер
  },
  computed: {
    disablePrint(): boolean {
      return !this.selectedItems.length;
    },
  },
  data() {
    return {
      reportList: [] as any, // список отчетов для записи
      reportMenu: null, // видимость меню отчетов записи
      loading: false,
    };
  },
  methods: {
    // получаем список отчетов для записи
    async onReportMenu(menuVisible: boolean) {
      if (menuVisible) {
        this.loading = true;
        this.reportList = [];

        const result = await new this.$HttpModel('ОтчетыДеревоКоммуникаций').executeMethod('Получить', { тип: this.params.тип });
        const pars = JSON.parse(result as string);

        for (const report in pars) {
          this.reportList.push({ title: pars[report], name: pars[report], icon: '$vuetify.icons.file_chart' });
        }
        this.loading = false;
      }
    },
    // запуск построителя отчета
    async buildReport(reportName: string) {
      let names = '';
      let vals = '';
      this.selectedItems.forEach((el: any) => {
        names += names === '' ? el.название : ';' + el.название;
        vals += vals === '' ? (el.$номерЗаписи as string) : ',' + el.$номерЗаписи;
      });

      const reportParams = {
        _Элементы_НомераЗаписей: vals,
        _Элементы_Названия: names,
        _ТипСети: this.params.тип,
        showNetType: false,
      };
      await new ReportsBuilder(reportName).executeReport2(reportName, {}, { params: reportParams });
    },
  },
});
