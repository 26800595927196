
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      items: [
        { title: 'Услуги', img: 'spr_services', to: { name: 'references-services' } },
        { title: 'Категории услуг', img: 'spr_services_cat', to: { name: 'references-serviceskat' } },
        { title: 'Виды параметров', img: 'spr_param_kinds', to: { name: 'references-parameters' } },
        { title: 'Константы', img: 'spr_consts', to: { name: 'references-constants' } },
        { title: 'Типы приборов учета', img: 'spr_meters_types', to: { name: 'references-typesmeteringdevices' } },
        { title: 'Адресная система', img: 'spr_аddresses', to: { name: 'references-streets' } },
        { title: 'Льготы', img: 'spr_lgots', to: { name: 'references-lgoty' } },
        { title: 'Категории льгот', img: 'spr_lgots_cat', to: { name: 'references-lgotkat' } },
        { title: 'Виды параметров объектов', img: 'spr_paramobj_kinds', to: { name: 'references-parametersobj' } },
        { title: 'Типы документов', img: 'spr_doc_types', to: { name: 'references-typesofdocs' } },
      ],
      itemsorg: [
        { title: 'Все организации', img: 'spr_org', to: { name: 'references-organizations' } },
        { title: 'Категории организаций', img: 'spr_org_cat', to: { name: 'references-organizationskat' } },
        { title: 'Источники платежей', img: 'spr_ist_plat', to: { name: 'references-organizations-sourcespayments' } },
        { title: 'Поставщики', img: 'spr_post', to: { name: 'references-organizations-resources' } },
        { title: 'Управляющие компании', img: 'spr_uk', to: { name: 'references-organizations-managements' } },
        { title: 'Банки', img: 'spr_banks', to: { name: 'references-organizations-banks' } },
      ],
      itemnetwork: [
        { title: 'Нормальная структура сети', img: 'commun_struct', to: { name: 'nettree' } },
        { title: 'Номенклатура сети', img: 'commun_nomen', to: { name: 'nomenclature' } },
      ],
      items2: [
        { title: 'Виды аналитики', img: 'spr_analit', to: { name: 'references-typesanalitics2' } },
        { title: 'Правила операций', img: 'spr_rules', to: { name: 'references-rules' } },
        { title: 'Параметры расчета', img: 'spr_calc_params', to: { name: 'references-parameterscalc' } },
        { title: 'Причины перерасчетов', img: 'spr_recalc_kinds', to: { name: 'references-recalcreasons' } },
        { title: 'Виды родства', img: 'spr_relationships', to: { name: 'references-relationship' } },
        { title: 'Сообщения для квитанций', img: 'spr_kvit_messages', to: { name: 'references-kvitmessages' } },
        { title: 'Соответствие услуг', img: 'spr_usl_sootv', to: { name: 'references-serviceaccord' } },
        { title: 'Виды снижения оплаты', img: 'spr_opl_discont_kinds', to: { name: 'references-paymentreduction' } },
        { title: 'Места выдачи документов', img: 'spr_document_place', to: { name: 'references-placeissue' } },
        { title: 'Частные лица', img: 'spr_people', to: { name: 'references-people' } },
        { title: 'Гражданство', img: 'spr_citizenship', to: { name: 'references-citizenships' } },
        { title: 'Основания владения', img: 'spr_osnvladen', to: { name: 'references-groundsofownership' } },
      ],
    };
  },
});
