
import Vue from 'vue';

export default Vue.extend({
  name: 'SumFilter',
  model: { prop: 'params', event: 'input' },
  props: {
    params: { type: Object, required: true },
    labelSum: { type: String, default: 'Сумма' },
  },
});
