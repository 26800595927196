
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  computed: {
    rulesSelectedAdr(): boolean | string {
      return !this.errorAdr || 'Необходимый уровень отчета – Дом';
    },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        Месяц: this.$store.getters.getWorkMonth(),
        Услуга: null,
        фТолькоИзмененные: 0,
        ФильтрПоПУ: 0,
      },
      errorAdr: false,
    };
  },
  methods: {
    onSelectFund(payload: any) {
      this.errorAdr = payload && payload[0] && payload[0].тип !== 3;
    },
  },
});
