const accopu: StackSelection = {
  objectName: 'ЛицевыеСчета.ОПУКвартиры',
  description: '',
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'датнач',
      text: 'ДатНач',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'ДатКнц',
      type: 'Date',
    },
    {
      field: 'примечание',
      text: 'Название',
      type: 'String',
    },
  ],
};
export default accopu;
