import { TableHandlers } from '@/stackEngine';
const problempays: StackSelection = {
  recordRoute: 'payments-paylists-paylistsid-problempays-problempaysid',
  objectName: 'ПлатежныеВедомости.ПроблемныеПлатежи',
  description: '',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.ошибкаплатежа && row.ошибкаплатежа !== '') {
      TableHandlers.trColor(row, 'warning', 'darken-4');
    }
    return row;
  },
  fields: [
    {
      field: 'счет-проблемная оплата',
      text: 'Счет-Проблемная оплата',
      type: 'Link',
    },
    {
      field: 'счет-коррекция',
      text: 'Счет-Коррекция',
      type: 'Link',
    },
    {
      field: 'проблемный платеж-список',
      text: 'Проблемный платеж-Список',
      type: 'Link',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'n п/п',
      text: 'N п/п',
      type: 'Number',
    },
    {
      field: 'расчмесяц',
      text: 'РасчМесяц',
      type: 'Date',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'платежза',
      text: 'ПлатежЗа',
      type: 'Date',
    },
    {
      field: 'платежпо',
      text: 'ПлатежПо',
      type: 'Date',
    },
    {
      field: 'платежная группа',
      text: 'Платежная группа',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'пени',
      text: 'Пени',
      type: 'Money',
    },
    {
      field: 'лсизфайла',
      text: 'ЛС из файла',
      type: 'String',
    },
    {
      field: 'фиоизфайла',
      text: 'ФИО из файла',
      type: 'String',
    },
    {
      field: 'адресизфайла',
      text: 'Адрес из файла',
      type: 'String',
    },
    {
      field: 'ошибкаплатежа',
      text: 'Ошибка платежа',
      type: 'String',
    },
    {
      field: 'флагошибок',
      text: 'Флаг ошибок',
      type: 'Number',
    },
    // {
    //   field: 'счет-коррекция>номер',
    //   text: 'НомерКорр',
    //   type: 'Number',
    // },
    // {
    //   field: 'счет-проблемная оплата>номер',
    //   text: 'НомерОриг',
    //   type: 'Number',
    // },
  ],
};
export default problempays;
