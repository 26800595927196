
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: '@Мес',
          text: 'Месяц',
          type: 'String',
          readonly: true,
        },
        {
          field: '@00',
          text: '00',
          type: 'Boolean',
        },
        {
          field: '@01',
          text: '01',
          type: 'Boolean',
        },
        {
          field: '@02',
          text: '02',
          type: 'Boolean',
        },
        {
          field: '@03',
          text: '03',
          type: 'Boolean',
        },
        {
          field: '@04',
          text: '04',
          type: 'Boolean',
        },
        {
          field: '@05',
          text: '05',
          type: 'Boolean',
        },
        {
          field: '@06',
          text: '06',
          type: 'Boolean',
        },
        {
          field: '@07',
          text: '07',
          type: 'Boolean',
        },
        {
          field: '@08',
          text: '08',
          type: 'Boolean',
        },
        {
          field: '@09',
          text: '09',
          type: 'Boolean',
        },
        {
          field: '@10',
          text: '10',
          type: 'Boolean',
        },
        {
          field: '@11',
          text: '11',
          type: 'Boolean',
        },
        {
          field: '@12',
          text: '12',
          type: 'Boolean',
        },
        {
          field: '@13',
          text: '13',
          type: 'Boolean',
        },
        {
          field: '@14',
          text: '14',
          type: 'Boolean',
        },
        {
          field: '@15',
          text: '15',
          type: 'Boolean',
        },
        {
          field: '@16',
          text: '16',
          type: 'Boolean',
        },
        {
          field: '@17',
          text: '17',
          type: 'Boolean',
        },
        {
          field: '@18',
          text: '18',
          type: 'Boolean',
        },
        {
          field: '@19',
          text: '19',
          type: 'Boolean',
        },
        {
          field: '@20',
          text: '20',
          type: 'Boolean',
        },
        {
          field: '@21',
          text: '21',
          type: 'Boolean',
        },
        {
          field: '@22',
          text: '22',
          type: 'Boolean',
        },
        {
          field: '@23',
          text: '23',
          type: 'Boolean',
        },
      ],
    };
  },
});
