
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    ownerID: { type: [String, Number], required: true },
    dataModel: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
    title: { type: String, default: 'Удержание' },
    params: { type: Object, default: null },
  },
  computed: {
    openMonth(): string {
      return this.$store.getters.getOpenMonth();
    },
    changesIsDisabled(): boolean {
      return this.readonly || this.params.месяц < this.openMonth || +this.params.блокирована === 1;
    },
    paramsData(): any {
      return {
        нзист: this.params.нзист,
        счет: this.record['удержание-счет'],
        месяц: this.params.месяц,
        нзкр: this.record['карточка-плательщик'],
        блокирована: this.params.блокирована,
      };
    },
    lsWasSet(): boolean {
      return this.record && this.record['удержание-счет'] > 0;
    },
  },
  methods: {
    changeLS() {
      // сменили лс - сбросили плательщика и сумму
      this.record['удержание-плательщик'] = null;
      this.record['сумма'] = 0;
    },
  },
});
