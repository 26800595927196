
import Vue from 'vue';
import { CLOSE_EVENT, ERROR_EVENT, collectProps } from './utils';

export const wrappers: any = {};

export default Vue.extend({
  name: 'StackWrapper',
  props: {
    name: {
      type: String,
      default: 'default',
      validator: (value: any) => value,
    },
  },
  data: () => ({
    id: 0,
    dialogs: {} as any,
  }),
  computed: {
    dialogIds(): string[] {
      return Object.keys(this.dialogs);
    },
  },
  created() {
    if (process.env.NODE_ENV === 'development') {
      if (wrappers[this.name]) {
        console.error(`The wrapper '${this.name}' is already exist.`);
      }
    }
    wrappers[this.name] = this;
  },
  beforeDestroy() {
    wrappers[this.name] = undefined;
  },

  render(createElement) {
    const on: any = { ...this.$listeners };
    const props = {
      ...this.$options.propsData,
    };
    const dial = Object.keys(this.dialogs);
    const children: any = dial.map((dialogId: any) => {
      const data: any = this.dialogs[dialogId];

      const on = {};
      // @ts-ignore
      on[CLOSE_EVENT] = data.close;
      // @ts-ignore
      on[ERROR_EVENT] = data.error;

      return createElement(data.component, {
        on,
        key: data.id,
        props: data.propsData,
      });
    });
    return createElement('div', { on, props }, children);
  },
  methods: {
    /**
     * Add a new dialog component into this wrapper
     *
     * @private
     * @param {object} dialogData Data passed from the `makeDialog` function
     * @param {any[]} args Arguments from the dialog function
     */
    add(dialogData: any, args: any[]) {
      const id = this.id++;
      let close: any, error: any;

      // It will be resolved when 'close' function is called
      const dataPromise = new Promise((resolve, reject) => {
        close = resolve;
        error = reject;
      })
        .then(data => {
          // @ts-ignore
          this.remove(id);
          return data;
        })
        .catch(reason => {
          // @ts-ignore
          this.remove(id);
          throw reason;
        });

      // It will be resolved after the component instance is created
      const instancePromise = new Promise(resolve => {
        // @ts-ignore
        dialogData.createdCallback = resolve;
      });

      const finalPromise = dialogData.component.then((component: any) => {
        const propsData = {
          arguments: args,
          ...collectProps(dialogData.props, args),
        };

        // Use Object.freeze to prevent Vue from observing renderOptions
        const renderOptions = Object.freeze({ id, propsData, component, close, error });

        // Finally render the dialog component
        this.$set(this.dialogs, id, renderOptions);

        return dataPromise;
      });

      return Object.assign(finalPromise, {
        close,
        error,
        getInstance: () => instancePromise,
      });
    },

    /** Remove a dialog component from the wrapper */
    remove(id: number) {
      this.$delete(this.dialogs, id);
    },
  },
});
