
import Vue from 'vue';
import fund from '../closemonth/$components/fund.vue';
import open from './$components/open.vue';
import openChooseOperation from './$components/openChooseOperation.vue';

type HookReleaseFunction = () => void;

export default Vue.extend({
  components: { fund, open, openChooseOperation },
  computed: {
    disableFund(): boolean {
      return this.fundStatus === 'success';
    },
    disableCheck(): boolean {
      return this.checkStatus === 'success';
    },
  },
  data() {
    return {
      fundStatus: '',
      checkStatus: '',
      startStatus: '',
      фонд: '',
      фондСтрокой: '' as string,
      лицевые: '',
      блокирован: null as null | string,
      месяцЛицевого: null as null | string,
      dataModel: new this.$HttpModel('ОткрытиеМесяца'),
      routeHook: null as null | HookReleaseFunction, // хук на смену роута
      runOperation: false,
      месяц: null as null | string,
      operation: '' as string,
      enableAdmMode: false,
    };
  },
  created() {
    this.routeHook = this.$router.beforeEach(this.checkChangedOnLeave) as HookReleaseFunction;
    this.fetchBlock();
  },
  beforeDestroy() {
    if (this.routeHook) {
      this.routeHook();
    }
  },
  methods: {
    async checkChangedOnLeave(to: any, from: any, next: any) {
      if (this.runOperation && from.path !== to.path) {
        this.$toast('Выполняется операция, выход невозможен!');
        next(false);
        return;
      }
      next();
    },
    async fetchBlock() {
      const data = await this.dataModel.executeMethod('заблокированныйМесяц', { лицевые: this.лицевые });
      if (data) {
        this.блокирован = data;
      }
    },
    async clickFund(to: StackTableRow) {
      this.фонд = to.фонд as string;
      this.лицевые = to.лицевые as string;
      this.фондСтрокой = to.фондСтрокой as string;
      this.enableAdmMode = to.enableAdmMode as boolean;
      const сообщениеОшибки = (to.сообщениеОшибки ? to.сообщениеОшибки : '') as string;
      if (!сообщениеОшибки.length && this.фонд.length && this.лицевые.length) {
        this.месяцЛицевого = to.месяцЛицевого as string;
        this.месяц = to.месяцЛицевого as string;
        await this.fetchBlock();
        this.fundStatus = 'success';
      } else {
        this.fundStatus = 'error';
      }
    },
    runOpen() {
      this.runOperation = true;
      this.startStatus = '';
    },
    async endOpen(to: string) {
      this.startStatus = to;
      this.runOperation = false;
      this.$sendMsg('stack-table', 'ЗакрытиеМесяца.Реестр', 'reload');
      this.$store.dispatch('checkSystemState');
    },
    runCheck() {
      this.checkStatus = '';
    },
    async endCheck(to: StackTableRow) {
      await this.fetchBlock();
      this.checkStatus = to.res as string;
      this.operation = to.operation as string;
    },
    reset() {
      this.fundStatus = this.checkStatus = this.startStatus = '';
      this.$sendMsg('stack-table', 'ЗакрытиеМесяца.Реестр', 'reload');
    },
  },
});
