const reports: StackSelection = {
  objectName: 'МакетыКвитанций.ЭлементыДетализации',
  description: 'Детализация группы',
  fields: [
    {
      field: 'n п/п',
      text: 'N п/п',
      type: 'Number',
      width: '50px',
    },
    {
      field: 'группаитогов',
      text: 'Группа итогов',
      type: 'Number',
    },
    {
      field: 'номусл',
      text: 'Номер услуги',
      type: 'Number',
      readonly: true,
    },
    {
      field: 'макет-состав',
      text: 'макет',
      type: 'Number',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'услуга-макет',
      text: 'Услуга',
      type: 'Link',
      dataModel: 'Услуги',
      labelField: record => {
        return '№' + record['номер услуги'] + ' ' + record.название;
      },
      headers: 'наименование,номер услуги',
      hier: true,
    },
    {
      field: 'поставщик-макет',
      text: 'Получатель',
      type: 'Link',
      dataModel: 'Организации',
      labelField: 'название',
      headers: 'название,инн,кпп',
      width: '400px',
      hier: true,
    },
    {
      field: 'выводить',
      text: 'Выводить',
      type: 'Enum',
      dataModel: 'МакетыКвитанций.ЭлементыДетализации.Выводить',
      width: '150px',
    },
    {
      field: 'детализировать',
      text: 'Детализировать',
      type: 'Enum',
      dataModel: 'МакетыКвитанций.ЭлементыДетализации.Детализировать',
      width: '250px',
    },
  ],
};
export default reports;
