
import Vue from 'vue';
import historyLSDialog from './$dialogs/historyLSDialog.vue';
import historyLSFilter from '@/tasks/fl/views/$common/filters/historyLSFilter.vue';

export default Vue.extend({
  name: 'HistoryLsTab',
  components: { historyLSDialog, historyLSFilter },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
    height: { type: String, default: '100%' },
    headers: { type: String, default: 'месяц,датнач,даткнц,история-тип>название,примечание' },
    description: { type: String, default: '' },
  },
});
