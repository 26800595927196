
import Vue from 'vue';
import SumToolbar from '@/tasks/fl/components/SumToolbar.vue';

type HookReleaseFunction = () => void;

export default Vue.extend({
  props: {
    dataObject: { type: Object, required: true },
    params: { type: Object, required: true },
    ownerID: { type: Number, required: true },
    tableAction: { type: Function, required: true },
    isReadonly: { type: Boolean, default: false },
    noActiveServ: { type: Boolean, default: false },
    isFilterPresent: { type: Boolean, default: false },
    closedMonth: { type: [Date, String], required: true },
  },
  components: { SumToolbar },
  computed: {
    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
    disabled(): boolean {
      return !this.dataObject.isChanged;
    },
    filterEnable(): boolean {
      return this.params.filter.filterEnable;
    },
  },
  data() {
    return {
      loading: false,
      routeHook: null as null | HookReleaseFunction, // хук на смену роута
    };
  },
  created() {
    this.routeHook = this.$router.beforeEach(this.checkChangedOnLeave) as HookReleaseFunction;
    this.clearFlagActivServ();
  },
  beforeDestroy() {
    if (this.routeHook) {
      this.routeHook();
      this.clearFlagActivServ();
    }
  },
  methods: {
    async onBtnSaveRecords() {
      try {
        this.loading = true;
        const items = await this.dataObject.getAllRecords();
        this.dataObject.reset();
        await this.dataObject.executeMethod('перезаписатьПерерасчет', { владелец: this.ownerID, записи: items });
      } catch (error: AnyException) {
        if (!error.message) {
          this.$toast('Не удалось сохранить перерасчёт !');
        }
      } finally {
        this.tableAction({ action: 'reload' });
        this.loading = false;
      }
    },
    async checkChangedOnLeave(to: any, from: any, next: any) {
      if (!this.disabled && from.path !== to.path) {
        const answer = await this.$yesno('Измененные ручные перерасчеты не попадут в расчет. Вы уверены ?');
        if (!answer) {
          next(false);
          return;
        }
      }
      next();
    },
    async onBtnGetWorkServ() {
      try {
        const month = await this.$inputField('Укажите месяц перерасчета: ', 'Внимание', this.closedMonth, {
          width: '400px',
          type: 'date',
          dateType: 'month',
        });
        const items = await this.dataObject.getAllRecords();
        await this.dataObject.executeMethod('получитьДействующиеУслуги', { нзлс: this.ownerID, перерасчет: month, записи: items });
        this.loading = true;
        this.dataObject.reset();
      } catch (error: AnyException) {
        if (!error.message) {
          this.$toast('Не удалось получить перерасчёты !');
        }
      } finally {
        this.tableAction({ action: 'reload' });
        this.loading = false;
      }
    },
    clearFlagActivServ() {
      if (this.noActiveServ) {
        this.dataObject.executeMethod('убратьФлагДействУслуг', { нзлс: this.ownerID });
      }
    },
  },
  watch: {
    workMonth(to) {
      this.dataObject.reset();
      this.tableAction({ action: 'reload' });
    },
    ownerID(to) {
      this.dataObject.reset();
      this.tableAction({ action: 'reload' });
    },
  },
});
