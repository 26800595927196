const actsqu: StackSelection = {
  recordRoute: 'documents-onechargestatements-onechargestatementsid',
  objectName: 'ВедомостиРазовыхНачислений',
  description: 'Ведомости разовых начислений',
  hier: true,
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'объем',
      text: 'Объем',
      type: 'Quantity',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'адрес',
      text: 'Выбранный дом',
      type: 'String',
    },
    {
      field: 'расчмесяц',
      text: 'РасчМесяц',
      type: 'Date',
    },
    {
      field: 'рн документы-адрес',
      text: 'РН документы-Адрес',
      type: 'Link',
    },
    {
      field: 'перерасчет',
      text: 'Перерасчет',
      type: 'Date',
    },
    {
      field: 'рн документы-услуга',
      text: 'РН документы-Услуга',
      type: 'Link',
    },
    {
      field: 'рн документы-причина',
      text: 'РН документы-Причина',
      type: 'Link',
    },
    {
      field: 'рн документы-ук договор',
      text: 'РН документы-УК Договор',
      type: 'Link',
    },
    {
      field: 'рн документы-поставщик',
      text: 'РН документы-Поставщик',
      type: 'Link',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'флаги',
      text: 'Флаги',
      type: 'Number',
    },
    {
      field: 'видрасчета',
      text: 'ВидРасчета',
      type: 'Number',
    },
    {
      field: 'тариф',
      text: 'Тариф',
      type: 'Quantity',
    },
    {
      field: 'имяпапки',
      text: 'Имя папки',
      type: 'String',
    },
    {
      field: 'окончания срок',
      text: 'Окончания срок',
      type: 'Date',
    },
  ],
};
export default actsqu;
