
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
    ownerID: { type: Number, required: true },
  },
  data() {
    return {
      sumN: 0,
      obN: 0,
      sumP: 0,
      obP: 0,
      sumI: 0,
      obI: 0,
      title: '',
    };
  },
  methods: {
    getTotals(data: StackTableRow[]) {
      if (data) {
        for (const ind in data) {
          const тип = (data[ind].тип ? data[ind].тип : '') as string;
          const сумма = (data[ind].сумма ? data[ind].сумма : 0) as number;
          const объем = (data[ind].объем ? data[ind].объем : 0) as number;
          if (тип.indexOf('Начисление') >= 0 || тип.indexOf('Льгота') >= 0) {
            this.sumN += сумма;
            this.obN += объем;
          } else {
            this.sumP += сумма;
            this.obP += объем;
          }
          this.sumI += сумма;
          this.obI += объем;
          if (!this.title) {
            this.title = data[ind].номеруслугиродитель + ' ' + data[ind].названиеуслугиродитель;
          }
        }
      }
    },
  },
});
