
import Vue from 'vue';
import startOperations from './$components/startOperations.vue';
import fundComponent from './$components/fund.vue';
import checkBase from './$components/checkBase.vue';
import calc from './$components/calc.vue';
import endOperations from './$components/endOperations.vue';
import prepare from '../bulkprint/$components/prepare.vue';
import prepareSBP from '../bulkprint/$components/prepareSBP.vue';
import print from '../bulkprint/$components/print.vue';
import kvitEmail from '../bulkprint/$components/kvitEmail.vue';
import avansPrepare from './$components/avansPrepare.vue';

type HookReleaseFunction = () => void;

export default Vue.extend({
  components: { fundComponent, checkBase, calc, endOperations, prepare, prepareSBP, print, startOperations, kvitEmail, avansPrepare },
  data() {
    return {
      data: {
        fund: { status: '', disabled: false },
        start: { status: '', disabled: true },
        checkBefore: { status: '', disabled: true },
        calc: { status: '', disabled: true },
        checkAfter: { status: '', disabled: true },
        end: { status: '', disabled: true },
        prepare: { status: '', disabled: true },
        prepareSBP: { status: '', disabled: true },
        print: { status: '', disabled: true },
        email: { status: '', disabled: true },
        avansPrepare: { status: '', disabled: true },
      },
      фонд: '',
      лицевые: '', // для печати квитанций
      месяц: this.$store.getters.getOpenMonth(),
      блокирован: null as null | string,
      dataModel: new this.$HttpModel('ЗакрытиеМесяца'),
      routeHook: null as null | HookReleaseFunction, // хук на смену роута
      runOperation: false,
      show: false,
      operation: '',
      blockQuit: false,
      disabledMultiCalc: false,
      calcErrors: '',
      checkBeforeError: '',
      checkAfterError: '',
      fundErrorMsg: '',
      startOperationsError: '',
      enableAdmMode: false,
      justCloseAdmMode: false,
      monthSaved: null, // берется из query, если было начато закрытие
      сalcAvans: false,
      rights: 0,
    };
  },
  async created() {
    this.routeHook = this.$router.beforeEach(this.checkChangedOnLeave) as HookReleaseFunction;
    this.readQuery();
    this.fetchBlock();
    // this.checkCalcAvans();
    const data = await new this.$HttpModel('МассоваяПечатьКвитанций').getRecords();
    this.rights = (data && data[0] && data[0].права ? data[0].права : 0xFFFF) as number;
  },
  beforeDestroy() {
    if (this.routeHook) {
      this.routeHook();
    }
  },
  computed: {
    hasSBPRights(): boolean {
      return (this.rights & 8) !== 0;
    },
  },
  methods: {
    async checkChangedOnLeave(to: any, from: any, next: any) {
      if (this.runOperation && from.path !== to.path) {
        this.$toast('Выполняется операция, выход невозможен!');
        next(false);
        return;
      }
      if (from.path !== to.path) {
        if (this.blockQuit) {
          let text = '';
          switch (this.operation) {
            case 'Закрытие месяца':
              text = 'Отменить закрытие месяца?';
              break;
            case 'Закрытие месяца для административной группы':
              text = 'Отменить закрытие месяца для административной группы?';
              break;
            case 'Блокировка месяца':
              text = 'Отменить блокировку месяца?';
              break;
            case 'Полный расчет':
              text = 'Отменить полный расчет?';
              break;
          }
          if (!text) {
            next(false);
            return;
          }
          const answer = await this.$yesno(text);
          if (!answer) {
            next(false);
            return;
          } else {
            this.runOperation = true;
            // @ts-ignore
            this.$refs.start.open(this.operation);
            next(false);
            return;
          }
        }
      }
      next();
    },
    async fetchBlock() {
      const data = await this.dataModel.executeMethod('заблокированныйМесяц', { лицевые: this.лицевые });
      if (data) {
        this.блокирован = data;
      }
    },
    run(to: string) {
      // @ts-ignore
      this.data[to].status = '';
      // @ts-ignore
      this.data[to].disabled = true;
      this.runOperation = true;
      if (to !== 'calc') {
        this.disabledMultiCalc = true;
      }
    },
    clickFund(to: StackTableRow) {
      this.фонд = to.фонд as string;
      this.лицевые = to.лицевые as string;
      this.месяц = to.месяцОперации;
      this.enableAdmMode = to.enableAdmMode as boolean;
      this.justCloseAdmMode = to.justCloseAdmMode as boolean;
      const сообщениеОшибки = (to.сообщениеОшибки ? to.сообщениеОшибки : '') as string;
      if (!сообщениеОшибки.length && this.фонд.length && this.лицевые.length) {
        this.data.fund.status = 'success';
        this.data.start.disabled = false;
        this.fetchBlock();
      } else {
        this.fundErrorMsg = to.фонд && !to.лицевые ? 'Невозможно выбрать данную часть фонда, так как в ней нет записей' : '';
        this.data.fund.status = 'error';
        this.data.start.disabled = true;
      }
    },
    runStartOperations(to: any) {
      if (to) {
        this.show = to.show ? to.show : false;
        this.operation = to.operation ? to.operation : '';
        this.blockQuit = this.лицевые !== '-10';
      }
      if (this.show) {
        this.runOperation = true;
      }
      this.data.fund.disabled = true;
      this.data.start.status = '';
      this.data.checkBefore.disabled = true;
      this.data.calc.disabled = true;
      this.data.checkAfter.disabled = true;
      this.data.end.disabled = true;
      this.data.prepare.disabled = true;
      this.data.prepareSBP.disabled = true;
      this.data.print.disabled = true;
      this.data.email.disabled = true;
      this.data.avansPrepare.disabled = true;
      this.data.checkBefore.status = '';
      this.data.calc.status = '';
      this.data.checkAfter.status = '';
      this.data.end.status = '';
      this.data.prepare.status = '';
      this.data.prepareSBP.status = '';
      this.data.print.status = '';
      this.data.email.status = '';
      this.data.avansPrepare.status = '';
    },
    endStartOperations(to: StackTableRow) {
      this.data.start.status = to.status as string;
      this.runOperation = false;
      this.startOperationsError = (to.msg as string) || '';
      if (to.status === 'success') {
        if (!to.open) {
          this.data.start.disabled = true;
          this.data.checkBefore.disabled = false;
          if (this.justCloseAdmMode) {
            this.data.end.disabled = false;
            this.runOperation = false;
          }
        } else {
          this.data.start.disabled = false;
          this.blockQuit = false;
          this.data.fund.disabled = false;
        }
      } else {
        this.blockQuit = false;
        this.data.start.disabled = false;
        this.data.fund.disabled = false;
      }
      this.$sendMsg('stack-table', 'ЗакрытиеМесяца.Реестр', 'reload');
      this.$store.dispatch('checkSystemState');
    },
    endCheckBefore(to: string, error?: string) {
      this.data.checkBefore.status = to;
      this.runOperation = false;
      this.disabledMultiCalc = false;
      this.checkBeforeError = error || '';
      if (to === 'success') {
        this.data.checkBefore.disabled = true;
        this.data.calc.disabled = false;
      } else {
        this.data.checkBefore.disabled = false;
      }
    },
    runCalc() {
      this.data.checkAfter.status = '';
      this.data.checkAfter.disabled = true;
      this.data.end.status = '';
      this.data.end.disabled = true;
      this.runOperation = true;
      this.$store.dispatch('checkSystemState');
    },
    endCalc(to: string, error?: string) {
      this.data.calc.status = to;
      this.data.calc.disabled = false;
      this.runOperation = false;
      this.calcErrors = error || '';
      if (to === 'success') {
        this.data.checkAfter.disabled = false;
      }
      this.$store.dispatch('checkSystemState');
    },
    endCheckAfter(to: string, error?: string) {
      this.data.checkAfter.status = to;
      this.runOperation = false;
      this.disabledMultiCalc = false;
      const nextStep = this.сalcAvans ? 'avansPrepare' : 'end';

      this.checkAfterError = error || '';
      if (to === 'success') {
        this.data.checkAfter.disabled = true;
        this.data[nextStep].disabled = false;
      } else {
        this.data.checkAfter.disabled = false;
      }
    },
    endAvansPrepare(to: string) {
      this.data.avansPrepare.status = to;

      if (to === 'success') {
        this.data.avansPrepare.disabled = true;
        this.data.end.disabled = false;
      } else {
        this.data.avansPrepare.disabled = false;
      }
    },
    endEndOperations(to: string) {
      this.data.end.status = to;
      this.runOperation = false;
      if (to === 'success') {
        this.data.calc.disabled = true;
        this.data.end.disabled = true;
        this.data.prepare.disabled = false;
        this.data.prepareSBP.disabled = false;
        this.blockQuit = false;
        this.disabledMultiCalc = true;
      } else {
        this.data.end.disabled = false;
      }
      this.fetchBlock();
    },
    endPrepare(to: any) {
      this.data.prepare.status = to;
      this.runOperation = false;
      if (to.status === 'success') {
        this.data.prepare.disabled = true;
        this.data.print.disabled = false;
        this.data.email.disabled = false;
      } else {
        this.data.prepare.disabled = false;
      }
    },
    endPrepareSBP(to: any) {
      this.data.prepareSBP.status = to;
      this.runOperation = false;
      this.data.prepareSBP.disabled = false;
      this.data.print.disabled = false;
      this.data.email.disabled = false;
      this.data.prepare.disabled = false;
    },
    endEmail(to: string) {
      this.data.email.status = to;
      this.runOperation = false;
      if (to === 'success') {
        this.data.prepare.disabled = false;
        this.data.print.disabled = false;
        this.data.email.disabled = false;
      } else {
        this.data.email.disabled = false;
      }
    },
    endPrint(to: string) {
      this.data.print.status = to;
      this.runOperation = false;
      this.data.print.disabled = false;
      this.data.email.disabled = false;
    },
    setQuery(val: boolean) {
      if (val) {
        const q = Object.create(this.$route.query);
        q.месяц = this.месяц;
        q.фонд = this.фонд;
        q.operation = this.operation;
        q.лицевые = this.лицевые;
        q.justCloseAdmMode = this.justCloseAdmMode;
        this.$router.push({ query: q }).catch(() => {
          // do nothing.
        });
      } else {
        this.$router.push({});
      }
    },
    readQuery() {
      const q = this.$route.query;
      // если  уже было начато закрытие, то извлечем параметры из query
      if (q.operation && q.фонд && q.месяц && q.лицевые) {
        this.месяц = q.месяц;
        this.фонд = q.фонд as string;
        this.operation = q.operation as string;
        this.лицевые = q.лицевые as string;
        this.justCloseAdmMode = Boolean(q.justCloseAdmMode);
        // если мы вытаскиваем фонд из query, то на шаге выбора месяца и фонда - мы ставим статусы и блокируем их
        this.blockQuit = true;
        this.data.fund.status = 'success';
        this.data.fund.disabled = true;
        this.data.start.status = 'success';
        this.data.start.disabled = true;
        this.data.checkBefore.disabled = false;
      }
    },
    // проверяем необходимо ли рассчитывать данные для зачета авансов
    async checkCalcAvans() {
      this.сalcAvans = await this.dataModel.executeMethod('авансыПроверитьРежим', { месяц: this.месяц }) !== 0;
      // this.data.calca
      // const res =
      // return (res !== 0);
    },
  },
  watch: {
    blockQuit(to) {
      this.setQuery(to);
    },
  },
});
