
import Vue from 'vue';

import SealsTab from './distributorid/SealsTab.vue';
import ReadingsTab from './distributorid/ReadingsTab.vue';
import PropsTab from './distributorid/PropsTab.vue';
import StateTab from './distributorid/StateTab.vue';

export default Vue.extend({
  components: { SealsTab, ReadingsTab, PropsTab, StateTab },
  props: {
    distributorsid: { type: [Number, String], default: undefined },
    accountsid: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      measuretype: {} as any,
      dostup: [' ', 'Доступен', 'Не доступен'],
      closemode: false as boolean, // нажали кнопку снять
      restoremode: false as boolean, // нажали кнопку восстановить
      dataObject: new this.$HttpModel('ЛицевыеСчета.СписокРаспределителей'),
      isOtop: true,
      rulesThis: {
        onlyOtop: (val: boolean): boolean | string => {
          return val || 'Выбранная номенклатура не относится к отоплению';
        },
      },
    };
  },
  computed: {
    title(): string {
      return 'Информация об объекте ' + this.titleAcc;
    },
    titleAcc(): string {
      return this.record.номерлс ? '( Лицевой счет № ' + this.record.номерлс + ' )' : '';
    },
    isNewRecord(): boolean {
      return this.distributorsid === 'new';
    },
    typeOperation(): string {
      if (this.isNewRecord) {
        return 'Установка';
      }
      if (this.closemode) {
        return 'Снятие';
      }
      return '';
    },
    isClosedCounter(): boolean {
      return this.record.даткнц !== this.$stackDate.format(new Date(2045, 4, 9), 'ISO');
    },
  },
  methods: {
    // изменяем номенклатуру
    async onSelectMeasure(event: string, payload: any) {
      if (payload && payload[0]) {
        this.measuretype = payload[0];
        // проверяем только на update
        if (event === 'Update') {
          this.isOtop = false;
          if (this.measuretype['услуга счетчика'] && this.measuretype['услуга счетчика'] > 0) {
            const tmpResult = await this.dataObject.executeMethod('ПроверкаНоменклатуры', {
              запись: this.measuretype,
            });
            if (tmpResult) {
              this.isOtop = true;
            } else {
              return;
            }
          }
        }
        if (this.isNewRecord) {
          this.$set(this.record, 'разрядность', this.measuretype['счетчика разрядность']);
          this.$set(this.record, 'дробнаяразрядность', this.measuretype['счетчика дробная разрядность']);

          // сформируем номер по порядку
          if (event === 'Update' && this.dataObject && this.dataObject.executeMethod) {
            const tmpResult = await this.dataObject.executeMethod('ПолучитьНПП', {
              лицевой: this.accountsid,
            });
            if (tmpResult !== 0) {
              this.record['n п/п'] = tmpResult;
            }
          }
        }
        this.changeNextPoverkaDate('onSelectMeasure' + event);
      }
    },
    async onCloseCounter() {
      this.closemode = true;
      this.record.даткнц = this.$store.getters.getCurrentDate() as string;
    },

    async onRestoreCounter() {
      this.restoremode = true;
      await this.dataObject.executeMethod('Восстановление', { номерЗаписи: this.record.$номерЗаписи });
      this.restoremode = false;
      // @ts-ignore
      this.$refs.dialog.fetchData();
      this.$sendMsg('stack-table', 'ЛицевыеСчета.СписокРаспределителей.Состояния', 'reload');
    },
    onChangePoverkaDate() {
      this.changeNextPoverkaDate('onChangePoverkaDate');
    },

    onChangeInstallDate() {
      this.changeNextPoverkaDate('onChangeInstallDate');
    },

    changeNextPoverkaDate(event: string) {
      let changeDate = false;
      const poverkaDate = (this.record.датаповерки || this.record.датнач || '') as string;
      const poverkaInterval = (this.measuretype['@интервал'] || 0) as number;

      switch (event) {
        case 'onChangePoverkaDate':
          changeDate = true;
          break;
        case 'onChangeInstallDate':
          if (this.record.датаповерки === '') {
            changeDate = true;
          }
          break;
        case 'onSelectMeasureInit':
          if (!this.isNewRecord && this.record.датаследующейповерки === '') {
            changeDate = true;
          }
          break;
        case 'onSelectMeasureUpdate':
          // если выбрали прибор учета с нулевым интервалом, обнулим дату следующей поверки
          if (poverkaInterval === 0) {
            this.record.датаследующейповерки = '';
          } else {
            changeDate = true;
          }
          break;
      }

      if (changeDate && poverkaDate && poverkaInterval) {
        this.record.датаследующейповерки = this.$stackDate.format(this.$stackDate.addYears(new Date(poverkaDate), poverkaInterval), 'ISO');
      }
    },

    async onSaveDialog(payload: any) {
      this.$sendMsg('stack-table', 'ЛицевыеСчета.СписокРаспределителей.Состояния', 'reload');
      this.closemode = false;
    },
  },
});
