
import Vue from 'vue';
import FileDialog from '@/tasks/common/views/$common/_fileDialog.vue';

export default Vue.extend({
  components: { FileDialog },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      shablon: null,
      chooseRecipient: false,
      typeRecipient: 0,
      htmlBody: this.record['@телоhtml'],
    };
  },
  methods: {
    selectShablon(payload: any) {
      if (payload && payload[0]) {
        this.$set(this.record, '@тело', payload[0]['@текст']);
      }
    },
    addRecipient(type: number) {
      this.chooseRecipient = true;
      this.typeRecipient = type;
    },
    onSave(selectedRows: StackTableRow[]) {
      this.chooseRecipient = false;
      let text = '';
      selectedRows.forEach((element) => {
        text += text === '' ? '' : ';';
        text += element.название + ' <' + element.email + '>';
      });
      text = text === '' ? text : ';' + text;
      switch (this.typeRecipient) {
        case 1:
          this.$set(this.record, 'кому', this.record.кому + text);
          break;
        case 2:
          this.$set(this.record, 'копия', this.record.копия + text);
          break;
        case 3:
          this.$set(this.record, 'скрытаякопия', this.record.скрытаякопия + text);
          break;
        default:
          break;
      }
    },
    changeHtmlBody() {
      this.$set(this.record, '@телоhtml', this.htmlBody);
    },
  },
});
