
import Vue from 'vue';
import datnachDatend from './$datNachdatEnd.vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    disabled: { type: Boolean, default: true },
    hideDate: { type: Boolean, default: false },
  },
  components: { datnachDatend },
  computed: {
    calcS(): number {
      const numConvert = Math.pow(10, 11);
      return Number(Math.round(this.record.значение * this.record.значение / 4000000 * 3.14159265359 * numConvert) / numConvert);
    },
  },
});
