import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      d: {} as any,
      mouseDownListener: null as any,
      mouseMoveListener: null as any,
      mouseUpListener: null as any,
      intervalId: null as any,
    };
  },
  mounted() {
    this.mouseDownListener = window.addEventListener('mousedown', (e: MouseEvent) => {
      // @ts-ignore
      const closestDialog = e.target.closest('.v-dialog.v-dialog--active');
      // @ts-ignore
      if (e.button === 0 && closestDialog != null && e.target.classList.contains('v-toolbar__content')) {
        // element which can be used to move element
        this.d.el = closestDialog; // element which should be moved
        this.d.mouseStartX = e.clientX;
        this.d.mouseStartY = e.clientY;
        this.d.elStartX = this.d.el.getBoundingClientRect().left;
        this.d.elStartY = this.d.el.getBoundingClientRect().top;
        this.d.el.style.position = 'fixed';
        this.d.el.style.margin = 0;
        this.d.oldTransition = this.d.el.style.transition;
        this.d.el.style.transition = 'none';
      }
    });
    this.mouseMoveListener = window.addEventListener('mousemove', (e: MouseEvent) => {
      if (this.d.el === undefined) {
        return;
      }
      this.d.el.style.left = Math.min(Math.max(this.d.elStartX + e.clientX - this.d.mouseStartX, 0), window.innerWidth - this.d.el.getBoundingClientRect().width) + 'px';
      this.d.el.style.top = Math.min(Math.max(this.d.elStartY + e.clientY - this.d.mouseStartY, 0), window.innerHeight - this.d.el.getBoundingClientRect().height) + 'px';
    });
    this.mouseUpListener = document.addEventListener('mouseup', () => {
      if (this.d.el === undefined) {
        return;
      }
      this.d.el.style.transition = this.d.oldTransition;
      this.d.el = undefined;
    });
    // this.intervalId = setInterval(() => {
    //   // prevent out of bounds
    //   const dialog: any = document.querySelector('.v-dialog.v-dialog--active');
    //   if (dialog === null) {
    //     return;
    //   }
    //   dialog.style.left = Math.min(parseInt(dialog.style.left, 0), window.innerWidth - dialog.getBoundingClientRect().width) + 'px';
    //   dialog.style.top = Math.min(parseInt(dialog.style.top, 0), window.innerHeight - dialog.getBoundingClientRect().height) + 'px';
    // }, 100);
  },
  beforeDestroy() {
    window.removeEventListener('mousedown', this.mouseDownListener);
    window.removeEventListener('mousemove', this.mouseMoveListener);
    window.removeEventListener('mouseup', this.mouseUpListener);
    // clearInterval(this.intervalId);
  },
});
