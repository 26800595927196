
import Vue from 'vue';
import contractsDialog from '@/tasks/common/views/$common/contracts/contractsDialog.vue';

export default Vue.extend({
  components: { contractsDialog },
  props: {
    managementsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
  },
});
