
import Vue from 'vue';

interface DataMsgs {
  [index: number]: string;
}

export default Vue.extend({
  props: {
    dataModel: { type: Object, required: true },
    fund: { type: String, default: null },
    accounts: { type: String, default: null },
    month: { type: [String, Date], default: null },
    operation: { type: String, default: '' },
    disabled: { type: Boolean, required: true },
    disabledMultiCalc: { type: Boolean, required: true },
  },
  data() {
    return {
      месяц: this.$store.getters.getOpenMonth(),
      jobID: null as null | string,
      noError: null as null | boolean,
      value: 0,
      wait: false,
      interval: 0,
      multiDialogEnable: false,
    };
  },
  computed: {
    titleBtnRun(): string {
      return this.jobID === null ? 'Запустить' : 'Повтор';
    },
    multyThreads(): boolean {
      return this.$store.getters['flStore/getMultyThreads']() > 1;
    },
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
    dataResult(): DataMsgs {
      if (this.resultJob.data) {
        if (this.resultJob.data.result && this.resultJob.data.result.Сообщения) {
          return this.resultJob.data.result.Сообщения;
        } else if (this.resultJob.data.state && this.resultJob.data.state.Сообщения) {
          return this.resultJob.data.state.Сообщения;
        }
      }
      return this.resultJob.status ? { 0: this.resultJob.status } : {};
    },
  },
  methods: {
    async runCalc() {
      if (this.jobID) {
        this.$store.commit('MSG_DELETE', this.jobID);
        this.jobID = null;
      }
      this.wait = true;
      this.value = 0;
      const params = { фонд: this.fund, месяц: this.month, событие: this.operation, лицевые: this.accounts };
      this.jobID = (await this.dataModel.executeMethod('полныйРасчет', params, { async: true })).asyncId;
      this.$store.commit('MSG_ADD', { title: 'Полный расчет', asyncId: this.jobID, type: 'multi', external: true });
      this.$emit('run');
    },
    async btnSelect() {
      const answer = await this.$yesno('Расчет не выполнен, вы уверены что хотите продолжить ?');
      if (!answer) {
        return;
      }
      this.dataModel.executeMethod('пропуститьШаг', { ошибки: this.noError === null ? false : !this.noError, фонд: this.fund, месяц: this.month, событие: this.operation });
      this.noError = true;
      this.$emit('end', 'success');
    },
    restart() {
      this.wait = true;
      this.value = 0;
      this.$emit('run');
    },
    stop() {
      this.wait = false;
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.noError = true;
        this.wait = false;
        this.value = 100;
        this.$emit('end', 'success');
      }
    },
    isError(to) {
      if (to) {
        this.noError = false;
        this.wait = false;
        const errorMsg = this.resultJob.status;
        errorMsg && this.$toast(errorMsg, { color: 'error' });
        this.$emit('end', 'error', errorMsg);
      }
    },
    dataResult(to) {
      if (to[0] && !this.isComplete && !this.isError) {
        this.wait = false;
        this.value = this.resultJob.percent >= 0 && this.resultJob.percent > this.value ? this.resultJob.percent : this.value;
        const length = Object.keys(to).length;
        if (this.resultJob.percent <= 0) {
          this.value = this.value > 0 ? this.value + 1 : length * 10;
        }
      }
    },
  },
});
