import { TableHandlers } from '@/stackEngine';
const acts: StackSelection = {
  recordRoute: 'documents-inspectionsacts-inspectionsactid',
  objectName: 'АктыОбхода',
  hier: true,
  description: 'Акты обхода',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row['@уведомление'] !== '') {
      TableHandlers.tdIcon(row, '@уведомление', '$vuetify.icons.error', 'error');
    }
    if (row['статус'] === 1) {
      TableHandlers.trColor(row, 'grey');
    }
    return row;
  },

  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
      width: '100px',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'счет',
      text: 'Счет',
      type: 'Link',
    },
    {
      field: 'Автор',
      text: 'Автор',
      type: 'Link',
    },
    {
      field: 'видакта',
      text: 'Вид акта',
      type: 'Enum',
    },
    {
      field: '@видакта',
      text: 'Вид акта ',
      type: 'String',
    },
    {
      field: 'номерлс',
      text: 'Номер ЛС',
      type: 'String',
    },
    {
      field: 'адреслс',
      text: 'Адрес ЛС',
      type: 'String',
      width: '400px',
    },
    {
      field: 'датапроверки',
      text: 'Дата обхода',
      type: 'Date',
    },
    {
      field: '@актотработан',
      text: 'Отработан',
      type: 'Number',
      computed: true,
    },
    {
      field: '@уведомление',
      text: 'Уведомление',
      type: 'String',
      width: '150px',
      computed: true,
    },
    {
      field: '@датапоследнейпроверки',
      text: '@ДатаПоследнейПроверки',
      type: 'Date',
    },
    {
      field: 'дело',
      text: 'Дело',
      type: 'Number',
    },
    {
      field: 'статус',
      text: 'статус',
      type: 'Number',
    },
    {
      field: 'основание',
      text: 'основание',
      type: 'Number',
    },
    {
      field: 'актнарушения',
      text: 'актнарушения',
      type: 'Number',
    },
    {
      field: 'акт-услуга',
      text: 'Услуга',
      type: 'Link',
    },
    {
      field: 'акт-услуга>наименование',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: 'акт-услуга>название',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: '@двойнойнедопуск',
      text: 'ДвойнойНедопуск',
      type: 'Number',
    },
    {
      field: 'тип проверки',
      text: 'Тип проверки',
      type: 'Number',
    },
    {
      field: 'контролер',
      text: 'Контролер_ид',
      type: 'Link',
    },
    {
      field: 'контролер>фио',
      text: 'Контролер',
      type: 'String',
    },
    {
      field: 'статусконтролера',
      text: 'Статус',
      type: 'Number',
    },
    {
      field: '@естьмпконтр',
      text: 'Есть мп контролера',
      type: 'Number',
    },
  ],
};
export default acts;
