
import Vue from 'vue';

export default Vue.extend({
  props: {
    record: { type: Object, default: null },
  },
  data() {
    return {
      district: false,
      dataModel: new this.$HttpModel('НастройкаРолиОрганизации'),
    };
  },
  methods: {
    async onSave() {
      await this.dataModel.executeMethod('Сохранить', { участок: this.record.участок, роль: this.record.роль });
      this.$emit('close');
    },
  },
});
