
import Vue from 'vue';
import { ReportsBuilder } from '@/stackEngine';

export default Vue.extend({
  model: { prop: 'record', event: 'input' },
  props: {
    params: { type: Object, default: undefined },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    selectedValues: { type: [String, Number], required: true },
    selectedItems: { type: [Object, Array], required: true }, // Array парсер
    dataObject: { type: [Object, Array], required: true }, // Array парсер
  },
  data() {
    return { record: {} as StackTableRow, btnhistory: false as Boolean };
  },
  methods: {
    async onBtnPrintClick(reportName: string) {
      await new this.$ReportsBuilder(reportName).executeReport2(reportName, {
        списокАктов: this.selectedValues,
        номерЗаписи: this.selectedValues,
        контролер: this.selectedItems.length === 1 ? this.selectedItems[0].контролер : undefined,
        имяВыборки: 'АктыОбхода',
      });
    },
    onClickHistory() {
      this.btnhistory = !this.btnhistory;
      this.$emit('pressBtnHistory', this.btnhistory);
    },
  },
});
