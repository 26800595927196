
import Vue from 'vue';

import SelectionAbonents from './SelectionAbonents.vue';
import CallsFilter from '@/tasks/fl/views/$common/filters/CallsFilter.vue';

export default Vue.extend({
  components: { SelectionAbonents, CallsFilter },
  name: 'EditCampaign',
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      dial: false, // статус фабов
      hiddenDialog: false,
      selectedRows: [] as StackTableRow[],
      callsRows: [] as StackTableRow[],
      jobID: null as null | number,
      loading: false,
    };
  },
  computed: {
    disable(): boolean {
      return this.record.статус !== 0;
    },
    allowed(): boolean {
      let result = true;
      if (this.disable) {
        result = false;
      }
      if (this.record['@добавлениезаписей'] === 0) {
        result = false;
      }
      return result;
    },
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
  },
  methods: {
    checkCountCalls() {
      if (this.callsRows.length === 0) {
        this.$toast('Отсутствуют контактные данные');
      }
    },
    updateCalls() {
      // @ts-ignore
      this.$refs.calls.fetchData();
    },
    async onCancelSending(selectedValues: string) {
      await new this.$HttpModel('ЦентрИнформирования.Вызовы').executeMethod('Отменить', { записи: selectedValues });
      this.updateCalls();
    },
    async onClick(method: string) {
      this.loading = true;
      await new this.$HttpModel('ЦентрИнформирования').executeMethod('Рассчитать', { запись: this.record.$номерЗаписи, метод: method });
      this.loading = false;
      this.updateCalls();
      this.$emit('fetch');
    },
    onSelectSave(payload: any) {
      this.hiddenDialog = false;
      this.jobID = payload;
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.$store.commit('MSG_DELETE', this.jobID);
        this.jobID = null;
        this.updateCalls();
        this.checkCountCalls();
      }
    },
  },
});
