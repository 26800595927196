export default {
  objectName: 'ПерезагрузкаРесурсов',
  fields: [
    {
      field: 'флаги',
      text: 'Флаги',
      type: 'Number',
    },
  ],
} as StackSelection;
