const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.Улицы',
  description: 'Улицы',
  fields: [
    {
      field: 'счета',
      text: 'НП',
      type: 'Link',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'полныйадрес',
      text: 'Полный адрес',
      type: 'String',
    },
  ],
};
export default accs;
