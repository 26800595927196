
import Vue from 'vue';

export default Vue.extend({
  name: 'MonthSelectDialog',
  props: {
    month: { type: [String, Date], default: null },
  },
  data() {
    return {
      месяц: this.month,
    };
  },
  methods: {
    async onBtnOkClick() {
      if (this.месяц) {
        const closeMonth = this.$store.getters.getCloseMonth();
        let toContinue = 1;
        // TODO: перенести проверку в бэкенд
        if (Date.parse(closeMonth) >= Date.parse(this.месяц)) {
          toContinue = await this.$yesno('Вы точно хотите переделить групповой счетчик в закрытом месяце?');
        }
        if (toContinue) {
          this.$emit('ok', this.месяц);
        }
      }
    },
  },
});
