
import Vue from 'vue';
import ReCalcPeniTab from './ReCalcPeniTab.vue';
import SaldoLsFilter from '@/tasks/fl/views/$common/filters/saldoLsFilter.vue';
import peniLsFilter from '@/tasks/fl/views/$common/filters/peniLsFilter.vue';
import SumToolbar from '@/tasks/fl/components/SumToolbar.vue';

export default Vue.extend({
  components: { ReCalcPeniTab, SaldoLsFilter, peniLsFilter, SumToolbar },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
});
