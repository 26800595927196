const payments: StackSelection = {
  objectName: 'ПлатежныеВедомости.ПлатежиВедомости.СвязиПлатежа',
  description: '',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'платежза',
      text: 'ПлатежЗа',
      type: 'Date',
    },
    {
      field: 'платежпо',
      text: 'ПлатежПо',
      type: 'Date',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'заявка',
      text: 'Заявка',
      type: 'String',
    },
    {
      field: 'акт',
      text: 'Акт',
      type: 'String',
    },
    {
      field: 'договор',
      text: 'Договор',
      type: 'String',
    },
    {
      field: 'распределение-услуга',
      text: 'Распределение-Услуга',
      type: 'Number',
    },
    {
      field: 'флдоговор-платежи',
      text: 'ФЛДоговор-Платежи',
      type: 'Number',
    },
    {
      field: 'карточка-платежи',
      text: 'Карточка-Платежи',
      type: 'Number',
    },
    {
      field: 'флдокумент-платежи',
      text: 'ФЛДокумент-Платежи',
      type: 'Number',
    },

  ],
};
export default payments;
