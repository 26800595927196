
import Vue from 'vue';
import serviceTable from '@/tasks/common/views/$common/serviceTable.vue';
import BalanceInfo from '@/tasks/fl/views/accounts/$accountsid/BalanceInfo.vue';
import lsInfo from './$dialogs/lsInfo.vue';
import lsTenants from './$dialogs/lsTenants.vue';
import lsParams from './$dialogs/lsParams.vue';
import readingsTable from './$dialogs/readingsTable.vue';
import ContactsTab from '@/tasks/fl/views/accounts/$accountsid/ContactsTab.vue';

export default Vue.extend({
  name: 'Accountsid',
  components: { BalanceInfo, ContactsTab, lsInfo, lsTenants, lsParams, readingsTable, serviceTable },
  props: {
    accountsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      категория: 0,
      типстрой: 0,
      индекс: undefined,
      item: {} as StackTableRow,
      loading: false,
      cacheRecs: [] as StackTableRow[],
    };
  },
  computed: {
    title(): String | undefined {
      return this.индекс ? this.record.адрес?.toString() : undefined;
    },
    subtitle(): String {
      return this.record.адреслс + (this.индекс ? ' Индекс: ' + this.индекс : '');
    },
  },
});
