
import Vue from 'vue';
import FileTable from '@/tasks/common/views/$common/fileTable.vue';

export default Vue.extend({
  components: { FileTable },
  name: 'DopsTab',
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
});
