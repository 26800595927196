const orgs: StackSelection = {
  objectName: 'Банки',
  description: 'Банки',
  hier: true,
  openLink: 'references-organizations-banks',
  fields: [
    {
      field: 'название банка',
      text: 'Название банка',
      type: 'String',
    },
    {
      field: 'расположение банка',
      text: 'Расположение банка',
      type: 'String',
    },
    {
      field: 'бик',
      text: 'БИК',
      type: 'String',
    },
    {
      field: 'головной банк',
      text: 'Головной банк',
      type: 'String',
    },
    {
      field: 'код ркц',
      text: 'Код РКЦ',
      type: 'String',
    },
    {
      field: 'коррсчет',
      text: 'КоррСчет',
      type: 'String',
    },
    {
      field: 'адрес банка',
      text: 'Адрес банка',
      type: 'String',
    },
    {
      field: 'ркц',
      text: 'РКЦ',
      type: 'String',
    },
    {
      field: 'смр',
      text: 'СМР',
      type: 'String',
    },
  ],
};

export default orgs;
