
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      item: {
        флагошибок: 0,
      } as any,
    };
  },
});
