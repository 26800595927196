
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'Акт нарушения абонента' },
  },
  methods: {
    onChange() {
      this.record['типрасчета'] = 1;
    },
  },
});
