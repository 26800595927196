const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ЗаявкиПоДому',
  description: '',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'время',
      text: 'Время',
      type: 'String',
    },
    {
      field: 'завершитьдо',
      text: 'ЗавершитьДо',
      type: 'DateTime',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'заявкасост',
      text: 'Состояние заяки',
      type: 'String',
    },
    {
      field: 'комментарий',
      text: 'Комментарий',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'фиозаявителя',
      text: 'Заявитель',
      type: 'String',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'примечдиспетчер',
      text: 'Примечание',
      type: 'String',
    },
  ],
};
export default accs;
