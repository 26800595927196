
import Vue from 'vue';
import addressFilter from '@/tasks/fl/views/$common/filters/addressFilter.vue';
import datePeriodFilter from '@/tasks/fl/views/$common/filters/datePeriodFilter.vue';

export default Vue.extend({
  components: { addressFilter, datePeriodFilter },
  data() {
    return {
      item: {
        нп: null,
        улица: null,
        дом: null,
        квартира: null,
        фионанимателя: null,
        номер: null,
        номерлс: null,
        стороннийномер: null,
        датнач: null,
        даткнц: null,
        видакта: null,
        контролер: null,
        операции: null,
        основание: null,
        датначобхода: null,
        даткнцобхода: null,
        месяцнач: null,
        месяцкон: null,
      } as any,
    };
  },
});
