
import Vue from 'vue';
import TwoTableExchange from './TwoTableExchange.vue';
import tableHeadersLeft from '@/tasks/fl/selections/accountsplit/ДелениеЛицевого.Платежи до';
import tableHeadersRight from '@/tasks/fl/selections/accountsplit/ДелениеЛицевого.Платежи после';

export default Vue.extend({
  components: { TwoTableExchange },
  props: {
    record: { type: Object, required: true },
    disabled: { type: Boolean, required: true },
    runCreation: { type: Boolean, required: true },
  },
  computed: {
    paramGeneral(): object {
      return { лицевой: this.record.лицевой, дата: this.record.дата };
    },
  },
  data() {
    return {
      isSave: false,
      headersLeft: tableHeadersLeft.fields as StackField[],
      headersRight: tableHeadersRight.fields as StackField[],
    };
  },
  methods: {
    apply() {
      this.isSave = true;
      // @ts-ignore
      const records = this.$refs.twoTables.getRightTable();
      this.$emit('save', { выбПлатежей: records });
    },
  },
});
