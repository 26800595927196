
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    title(): string {
      const stringTitle = 'Соответствие услуг';
      switch (this.record.тип) {
        case 1:
          return stringTitle + ' УСЗН для региона';
        case 2:
          return stringTitle + ' 1С';
        default:
          return stringTitle;
      }
    },
  },
  methods: {
    changeType() {
      this.record['счет-соответствие'] = null;
      this.record.регион = null;
      this.record.примечание = null;
    },
    changeAddress(item: StackTableRow[]) {
      if (item[0]) {
        this.record.примечание = (item[0].адреслс ? item[0].адреслс : '') as string;
      }
    },
  },
});
