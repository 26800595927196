
import Vue from 'vue';
import datnachDatend from './$datNachdatEnd.vue';

export default Vue.extend({
  components: { datnachDatend },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    label: { type: String, default: '' },
    type: { type: String, default: '' },
    suffix: { type: String, default: '' },
    hideDate: { type: Boolean, default: false },
    mask: { type: String, default: undefined },
    returnMaskedValue: { type: Boolean, default: false },
  },
});
