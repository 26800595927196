
import Vue from 'vue';

export default Vue.extend({
  name: 'FilterContract',
  props: {
    params: { type: Object, required: true },
  },
  data() {
    return {
      значенияпараметра: [] as any,
      dataParameters: new this.$HttpModel('Договоры.Фильтр.ЗначенияПараметра'),
    };
  },
  methods: {
    async fetchData(dataObject: DataModel, params: any) {
      const items = [];
      const data = (await dataObject.getRecords(params)) as StackTableEnumRow[];
      for (const key in data) {
        data[key].$номерЗаписи = data[key].ключ;
        items.push(data[key]);
      }
      return items;
    },
    parSelect(params: any) {
      let parName = '';
      for (const par in params) {
        parName = params[par].название;
      }
      this.params.ЗначениеПарДог = null;
      if (parName) {
        this.fetchData(this.dataParameters, { name: parName }).then((res: any) => {
          this.значенияпараметра = res;
        });
      }
    },
  },
});
