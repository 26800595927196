
import Vue from 'vue';
import datePickerFullYear from '@/tasks/common/views/references/constants/$dialogs/$datePickerFullYear.vue';

export default Vue.extend({
  components: { datePickerFullYear },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      schedule: null as number | null,
      fiveDay: 0,
      sixDay: 0,
      takeHolydays: 0,

      addScheduleMode: false, // режим добавления нового графика
      newScheduleName: null,

      year: this.$store.getters.getWorkMonth(),
      dates: [[], [], [], [], [], [], [], [], [], [], [], []] as string[][], // выделенные дни
      holydays: [] as string[], // выходные и праздничные дни в формате гггг-мм-дд
      workdays: [] as string[], // список рабочих дней в формате гггг-мм-дд
      selectedDays: [] as string[], // выбранные руками даты, чтобы не перезатереть при смене галок
    };
  },
  computed: {
    hasScheduleData(): boolean {
      for (const month of this.dates) {
        if (month.length) {
          return true;
        }
      }
      return false;
    },
    title(): string {
      return `Графики` + (this.schedule && !this.hasScheduleData ? ` (отсутствуют данные у графика за ${this.$stackDate.getYear(this.year)} год)` : ``);
    },
    disabled(): boolean {
      return this.addScheduleMode || !this.schedule;
    },
  },
  methods: {
    formatDate(value: string | Date): string {
      return this.$stackDate.format(value, 'YYYY-MM-DD');
    },
    async getScheduleData() {
      this.sixDay = 0;
      this.fiveDay = 0;
      this.takeHolydays = 0;
      this.selectedDays = [];

      if (this.schedule && this.year) {
        const res = await new this.$HttpModel('ГрафикКалендарь').executeMethod('получитьДанныеГрафика', {
          график: this.schedule,
          год: this.year,
        });

        if (res && res.length) {
          this.dates = [];
          for (let month = 0; month < 12; month++) {
            if (res[month]) {
              this.dates.push(
                res[month].map((item: string) => {
                  return this.formatDate(item);
                }),
              );
            } else {
              this.dates.push([]);
            }
          }
        } else {
          this.dates = [[], [], [], [], [], [], [], [], [], [], [], []];
        }
      }
    },
    // заполним выходные и праздничные дни в формате ГГГГ-ММ-ДД
    async getHolydays() {
      this.workdays = [];
      const workdays = await new this.$HttpModel('СписокРабочихДней').getRecords({ год: this.year });
      if (workdays) {
        this.workdays = workdays.map((record: StackTableRow) => {
          return this.formatDate(record['_дата'] as string);
        }) as string[];
      }

      this.holydays = [];
      const holydays = await new this.$HttpModel('СписокВыходныхДней').getRecords({ год: this.year });
      if (holydays) {
        this.holydays = holydays.map((record: StackTableRow) => {
          return this.formatDate(record['_дата'] as string);
        }) as string[];
      }
    },
    fillSelectedDays() {
      const fullYear = this.$stackDate.getYear(this.year);
      const daysOfYear = this.$stackDate.differenceInDays(new Date(fullYear, 11, 31), new Date(fullYear, 0, 1)) + 1;
      for (let month = 0; month < 12; month++) {
        let date = new Date(fullYear, month, 1);
        const daysofMonth = 33 - new Date(fullYear, month, 33).getDate();
        for (let day = 1; day <= daysofMonth; day++) {
          date = new Date(fullYear, month, day);
          const dateFormat = this.formatDate(date);
          // почистим дату из массива, если есть ( ручные оставим )
          if (this.dates[month].lastIndexOf(dateFormat) !== -1 && this.selectedDays.lastIndexOf(dateFormat) === -1) {
            this.dates[month].splice(this.dates[month].lastIndexOf(dateFormat), 1);
          }

          if (this.dates[month].lastIndexOf(dateFormat) === -1 && (!this.takeHolydays || this.workdays.lastIndexOf(dateFormat) === -1)) {
            if (this.fiveDay && (date.getDay() === 6 || date.getDay() === 0)) {
              // пятидневная неделя, пометим субботу и воскресенье ( исключаем будни выпавшие на выходные если нужно )
              this.dates[month].push(dateFormat);
            } else if (this.sixDay && date.getDay() === 0) {
              // шестидневная - только воскресенье  ( исключаем будни выпавшие на выходные если нужно )
              this.dates[month].push(dateFormat);
            } else if (this.takeHolydays && this.holydays.lastIndexOf(dateFormat) >= 0) {
              // если день помечен выходным и стоит флаг учитывать их
              this.dates[month].push(dateFormat);
            }
          }
        }
      }
      this.changeFunc();
    },
    changeFunc() {
      const str = JSON.stringify({ график: this.schedule, год: this.year, даты: this.dates });
      this.$set(this.record, 'значение', str);
    },
    onChangeYear() {
      this.getScheduleData();
      this.getHolydays();
    },
    async onAddSchedule() {
      const res = await new this.$HttpModel('ГрафикКалендарь').createRecord({ название: this.newScheduleName });
      this.addScheduleMode = false;
      this.newScheduleName = null;
      if (res) {
        this.schedule = res as number;
        this.getScheduleData();
      }
    },
    async onDeleteSchedule() {
      if (await this.$yesno('Вы уверены, что хотите удалить график?')) {
        await new this.$HttpModel('ГрафикКалендарь').deleteRecords([{ $номерЗаписи: this.schedule as number }]);
        if (this.$refs && this.$refs.cbSchedule) {
          // @ts-ignore
          this.$refs.cbSchedule.fetchData();
        }
        this.schedule = null;
        this.getScheduleData();
      }
    },
    async onChangeTypeMonth(value: number) {
      if (value === 5) {
        this.sixDay = 0;
      }
      if (value === 6) {
        this.fiveDay = 0;
      }

      this.fillSelectedDays();
    },
    onClickDatePicker(payload: string) {
      if (payload) {
        const index = this.selectedDays.lastIndexOf(payload);
        if (index === -1) {
          this.selectedDays.push(payload);
        } else {
          this.selectedDays.splice(index, 1);
        }
      }
      this.changeFunc();
    },
  },
  mounted() {
    this.getHolydays();
  },
});
