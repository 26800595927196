
import Vue from 'vue';
import { ReportsBuilder } from '@/stackEngine';

export default Vue.extend({
  props: {
    selectedItems: { type: Array, default: undefined },
    dataObject: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    async onBtnPrintClick(reportName: string) {
      await new this.$ReportsBuilder(reportName).executeReport2(reportName, {
        'Ведомости удержаний': this.selectedItems,
        имяВыборки: this.dataObject.model,
      });
    },
  },
});
