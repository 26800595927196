
import Vue from 'vue';
export default Vue.extend({
  computed: {
    getAPIUrls() {
      return { 'По умолчанию': undefined, ...this.$store.getters.getAPIUrls() };
    },
  },
  data() {
    return {
      url: this.$store.state.configStore.customAPIUrl,
    };
  },
  methods: {
    onSaveUrl() {
      this.$store.commit('SET_CUSTOM_BACKEND_URL', this.url);
      this.$store.commit('SET_URL_DIALOG_STATE', false);
    },
  },
});
