
import Vue from 'vue';
import basemethod from './basemethod.vue';
import ViolationsactsAddDialog from '../$lawsuits/violationsactsAddDialog.vue';

export default Vue.extend({
  components: {
    'ViolationsactsAdd-dialog': ViolationsactsAddDialog,
  },
  extends: basemethod,
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      actvisible: true,
      loading: false,
    };
  },
  computed: {
    aktotkl(): boolean {
      return !!(this && this.record && this.record.$блокинит && this.record['@операции'].indexOf('АКТНАРУШЕНИЯ') !== -1);
    },
  },
  methods: {
    async setact(data: any) {
      this.loading = true;
      await new this.$HttpModel('ОперацииЕдиничногоПерехода').executeMethod('ВыполнитьОперацию', {
        состояние: this.record.$номерЗаписи,
        длг: data,
        тип: 'АктНарушения',
      });
      this.loading = false;
      this.actvisible = false;
      this.$emit('update');
    },
  },
});
