
import Vue from 'vue';
import addressFilter from './addressFilter.vue';
import datePeriodFilter from './datePeriodFilter.vue';
import monthPeriodFilter from './monthPeriodFilter.vue';
import serviceFilter from './serviceFilter.vue';
import numberTypeFilter from './numberTypeFilter.vue';
import organizationsFilter from './organizationsFilter.vue';

export default Vue.extend({
  components: { addressFilter, datePeriodFilter, monthPeriodFilter, serviceFilter, numberTypeFilter, organizationsFilter },
  data() {
    return {
      item: {
        нп: null,
        улица: null,
        дом: null,
        квартира: null,
        номерлс: null,
        стороннийномер: null,
        фионанимателя: null,
        номер: null,
        вид: null,
        датнач: null,
        даткнц: null,
        месяцс: null,
        месяцпо: null,
        услуга: null,
        организация: null,
      } as any,
    };
  },
});
