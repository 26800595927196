
import Vue from 'vue';
import Recordable from '../mixins/recordable';
import InlineEditCustomItem from './InlineEditCustomItem.vue';

export default Vue.extend({
  name: 'StackTableInlineEdit',
  components: { InlineEditCustomItem },
  mixins: [Recordable],
  props: {
    record: { type: Object, required: true },
    fieldList: { type: [Object, Array], required: true }, // парсер лох
    allHeadersDef: { type: [Object], required: true }, // описание всех полей выборки
    /**
     * запрет удаления записей
     */
    noDelete: { type: Boolean, default: false },
    /**
     * Возможность выбора строчек в таблице (выводить чекбоксы для выбора или нет)
     */
    noSelect: { type: Boolean, default: false },
    /**
     * Функция возвращающая стиль ячейки
     */
    tdStyle: {
      type: Function,
      default: (column: StackTableRowDataItem) => {
        return column;
      },
    },
    /**
     * дополнительные параметры, которые "проксируются" в params
     */
    params: { type: Object, default: null },
    readonly: { type: Boolean, default: false },
    // коллбек на изменение поля в инлайн
    onInlineFieldChange: {
      type: Function,
      default: (item: StackTableRow, columnName: string): StackTableRow => {
        return item;
      },
    },
    /**
     * не шлём команду save модели в инлайне
     */
    noSendSave: { type: Boolean, default: false },
    /**
     * В инлане сразу сохранять записи
     */
    fastEdit: { type: Boolean, default: false },
    editOn: { type: Boolean, default: false },
    searchWithFolder: { type: Boolean, default: false },
    noOpenLink: { type: Boolean, default: false },
  },
  data() {
    return {
      editMode: this.editOn,
    };
  },
  methods: {
    async onFieldChange(record: StackTableRow, columnName: string) {
      // @ts-ignore
      await this.onInlineFieldChange(record, columnName);
      if (this.fastEdit) {
        // @ts-ignore
        if (this.isNewRecord) {
          this.onCreateRecord();
        } else {
          this.onSaveRecord();
        }
      }
    },
    headersDef(columnName: string) {
      if (this.record && this.record.$data && this.record.$data[columnName] && this.record.$data[columnName].$fields) {
        return { ...this.allHeadersDef[columnName], ...this.record.$data[columnName].$fields };
      }
      return this.allHeadersDef[columnName];
    },
    async onSaveRecord() {
      // @ts-ignore
      if (this.dataObject.prepareRow) {
        // TODO нужно для пересчета вычисляемого поля, т.к. в инлайнтаблице фетч не происходит
        // @ts-ignore
        // this.dataObject.prepareRow(this.record);
      }
      // @ts-ignore
      if (this.dataObject.checkRow) {
        // TODO нужно для переопределения или проверки данных ввода
        // @ts-ignore
        const res = this.dataObject.checkRow(this.record);
        if (res) {
          this.$toast(res);
          return false;
        }
      }
      if (this.noSendSave) {
        // @ts-ignore
        this.customEmit('change', this.record);
        this.editMode = false;
        return true;
      }
      // @ts-ignore
      if (await this.saveRecord()) {
        // TODO после сохранения записи перечитываем её, т.к. данные могут поменятся на беке
        // @ts-ignore
        await this.readRecord();
        // @ts-ignore
        this.customEmit('change', this.record);
        this.editMode = false;
        return true;
      }
    },
    async onCreateRecord() {
      // @ts-ignore
      if (this.dataObject.checkRow) {
        // TODO нужно для переопределения или проверки данных ввода
        // @ts-ignore
        const res = this.dataObject.checkRow(this.record);
        if (res) {
          this.$toast(res);
          return false;
        }
      }
      // @ts-ignore
      if (await this.createRecord()) {
        // TODO после внесения записи перечитываем её, т.к. данные могут поменятся на беке
        // @ts-ignore
        await this.readRecord();
        this.editMode = false;
      }
    },
    // цвет ячеек
    getBackgroundColor(selected: any) {
      return selected ? `secondary` : '';
    },
    // цвет фона ячеек которые можно редактировать
    // есть ф-я tdStyle которая может передаваться из родительского компонента (stackTable например)
    // но класс для строки в зависисмости от редактирования задается тут - поэтому и выбор цвета оставил тут
    getStyleBackgroundColor(columnName: string) {
      const readOnly = !!this.allHeadersDef[columnName].readonly;
      let chStyle = {} as any;
      if (!readOnly && !this.record.$selected) {
        chStyle = { 'background-color': 'var(--v-secondary-lighten1)' };
      }
      // @ts-ignore
      return { ...this.tdStyle(this.allHeadersDef[columnName]), ...chStyle };
    },

    // клик на удаление при инлайн редактировании
    onDeleteRow(row: StackTableRow) {
      this.$emit('delete', row);
    },
  },
  watch: {
    isNewRecord: {
      immediate: true,
      handler(to: boolean) {
        if (to) {
          this.editMode = to;
        }
      },
    },
  },
});
