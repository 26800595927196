
import Vue from 'vue';
/**
 * Кнопка в виде большой карточки (используется в подменю)
 */
export default Vue.extend({
  name: 'StackCardButton',
  props: {
    /**
     * роут, куда переходим по клику
     */
    to: { type: [String, Object], default: undefined },
    /**
     * роут, куда переходим по клику
     */
    href: { type: String, default: undefined },

    /**
     * заголовок
     */
    title: { type: String, default: undefined },

    /**
     * имя svg иконки
     */
    icon: { type: String, default: undefined },

    /**
     * url произвольного изображения
     */
    img: { type: String, default: undefined },

    /** Мелкий размер карточек */
    small: { type: Boolean, default: false },

    /** задизаблить */
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    color: { type: String, default: 'primary' },
  },
  computed: {
    imgSize(): string {
      return !this.small ? '64px' : '44px';
    },
    contSize(): string {
      return !this.small ? '70px' : '50px';
    },
    getImagePack(): ImagePack {
      return this.$store.getters.getImagePack();
    },
    getImg(): string | undefined {
      if (this.getImagePack[this.img]) {
        return this.getImagePack[this.img]?.default ? this.getImagePack[this.img].default.id : this.getImagePack[this.img].id;
      }
      return (this.img.includes('.') ? this.img : undefined);
    },
  },
});
