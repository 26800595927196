
import Vue from 'vue';

import AktFormFilter from './$components/AktFormFilter.vue';

export default Vue.extend({
  name: 'AktObhodForm',
  components: { AktFormFilter },
  data() {
    return {
      totalRows: 0,
    };
  },
  methods: {
    getTotals(data: number) {
      this.totalRows = data;
    },
    async openAccount(id: number) {
      const routeData = this.$router.resolve({ path: `/accounts/${id}` });
      open(routeData.href, '_blank');
    },
  },
});
