
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    items(): any {
      return this.record.моделькомбо.split('\n');
    },
  },
});
