
import Vue from 'vue';

export default Vue.extend({
  methods: {
    async LoadLogFiels(selRows: StackTableRow[]) {
      if (selRows.length === 0) {
        this.$toast('Отметите записи');
        return;
      }
      const obj = new this.$HttpModel('СписокЛоговПрограммы');
      try {
        for (const row of selRows) {
          const jobID = (await obj.executeMethod('ЗагрузитьФайл', { path: row.path }, { async: true })).asyncId;
          this.$store.commit('MSG_ADD', { title: 'Файл лога: ' + row.path, asyncId: jobID, silent: true, type: 'file' });
        }
      } catch (error: AnyException) {
        this.$toast('Ошибка загрузки файла', { color: 'error' });
      }
    },
  },
});
