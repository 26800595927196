
import Vue from 'vue';
import tabWithErrors from './$components/tabWithErrors.vue';

interface step {
  success: Boolean;
  error: Boolean;
  running: Boolean;
  readyToGoNextStep?: Boolean;
}
interface steps {
  [index: number]: step;
}
interface errorTab {
  show: Boolean;
  typeTable: Number;
}
export default Vue.extend({
  components: {
    tabWithErrors,
  },
  data() {
    return {
      item: {
        лс: null,
        fias: null,
      },
      errorTab: {
        show: false,
        typeTable: 0 as Number, // признак для таблицы модального диалога
      } as errorTab,
      queryParams: {},
      loading: false,
      steps: {
        1: { success: false, error: false, running: false },
        2: { success: false, error: false, running: false },
        3: { success: false, error: false, running: false, readyToGoNextStep: false, wasError: false },
        4: { success: false, error: false, running: false, readyToGoNextStep: false, wasError: false },
        5: { success: false, error: false, running: false },
      } as steps,
      selectedItemsGuids: [] as StackTableRow[],
    };
  },
  methods: {
    async fillBrowserFias() {
      try {
        this.steps[1].running = true;
        await new this.$HttpModel('Улицы.ЗагрузкаГеографии').executeMethod(
          'ЗаполнитьВсехПотомков',
          { родитель: this.item.fias },
          {
            async: true,
            jobName: 'Загрузка данных о потомках из ФИАС',
            onComplete: () => {
              this.steps[1].running = false;
              this.steps[1].success = true;
              this.steps[1].error = false;
            },
            onError: () => {
              this.steps[1].running = false;
              this.steps[1].error = true;
            },
          },
        );
      } catch (e: AnyException) {
        this.steps[1].running = false;
        this.steps[1].error = true;
      }
    },
    async downloadGUIDS() {
      try {
        this.steps[2].running = true;
        await new this.$HttpModel('Улицы.ЗагрузкаГеографии').executeMethod(
          'Загрузить',
          { записи: this.selectedItemsGuids.map(item => { return { гуид: item.гуид }; }) },
          {
            async: true,
            jobName: 'Загрузка данных ФИАС',
            onComplete: () => {
              this.steps[2].running = false;
              this.steps[2].success = true;
            },
            onError: () => {
              this.steps[2].running = false;
              this.steps[2].error = true;
            },
          },
        );
      } catch (e: AnyException) {
        this.steps[2].running = false;
        this.steps[2].error = true;
      }
    },
    async checkStreets() {
      try {
        this.steps[3].running = true;

        await new this.$HttpModel('ПроверкаЛсПоГуиду').executeMethod(
          'заполнитьУлицы',
          {
            ЛицевыеСчета: this.item.лс,
            родительФиас: this.item.fias,
          },
          {
            async: true,
            jobName: 'Поиск и сопоставление',
            onComplete: () => {
              this.steps[3].running = false;
              this.checkOnErrors(0);
            },
            onError: () => {
              this.steps[3].running = false;
              this.steps[3].error = true;
              this.checkOnErrors(0);
            },
          },
        );
        this.errorTab.typeTable = 0 as Number;
      } catch (e: AnyException) {
        this.steps[3].running = false;
        this.steps[3].error = true;
      }
    },
    async checkHouse() {
      try {
        this.steps[4].running = true;
        await new this.$HttpModel('ПроверкаЛсПоГуиду').executeMethod(
          'заполнитьДома',
          {
            ЛицевыеСчета: this.item.лс,
            родительФиас: this.item.fias,
          },
          {
            async: true,
            jobName: 'Поиск и сопоставление домов',
            onComplete: () => {
              this.steps[4].running = false;
              this.checkOnErrors(1);
            },
            onError: () => {
              this.steps[4].running = false;
              this.steps[4].error = true;
              this.checkOnErrors(1);
            },
          },
        );
        this.errorTab.typeTable = 1 as Number;
      } catch (e: AnyException) {
        this.steps[4].running = false;
        this.steps[4].error = true;
      }
    },
    async checkOnErrors(type: Number) {
      this.errorTab.show = false;
      const res = await new this.$HttpModel('ПроверкаЛсПоГуиду').executeMethod('получитьКоличествоОшибок', { тип: type });
      const curStep = type === 1 ? this.steps[4] : this.steps[3];
      curStep.error = res > 0;
      curStep.readyToGoNextStep = true;
    },
    showTabError(type: Number) {
      this.errorTab.typeTable = type;
      this.errorTab.show = true;
    },
  },
  watch: {
    queryParams: {
      deep: true,
      handler(to) {
        this.loading = true;
        new this.$HttpModel('Улицы.ЗагрузкаГеографии').executeMethod('ЗаполнитьПотомков', to, {
          jobName: 'Заполнение потомков',
          silent: true,
          external: true,
          async: true,
          onComplete: () => {
            this.loading = false;
            this.$sendMsg('stack-table', 'Улицы.ЗагрузкаГеографии', 'reload');
          },
          onError: () => {
            this.loading = false;
          },
        });
      },
    },
  },
});
