const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ПоказанияСчетчиковОбщеквартирные',
  description: 'Показания ОКПУ',
  recordRoute: 'reading',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'заводскойномер',
      text: 'Заводской\nномер',
      type: 'String',
      width: '20%',
    },
    {
      field: 'показание',
      text: 'Показание',
      type: 'NumberZero',
    },
    {
      field: 'расход',
      text: 'Расход',
      type: 'NumberZero',
    },
    {
      field: 'итоговый расход',
      text: 'Итоговый\nрасход',
      type: 'NumberZero',
    },
    {
      field: '@зона',
      text: 'Зона\nсуток',
      type: 'Enum',
      width: '50px',
      computed: true,
    },
    {
      field: 'типввода',
      text: 'Тип\nввода',
      type: 'Number',
    },
    {
      field: '@тип ввода',
      text: 'Тип\nввода',
      type: 'Enum',
      computed: true,
    },
    {
      field: 'объект-показания',
      text: 'Объект-Показания',
      type: 'Number',
    },
    {
      field: 'расчетный месяц',
      text: 'За',
      type: 'DateMonth',
    },
    {
      field: 'тариф',
      text: 'Зона суток',
      type: 'String',
    },
    {
      field: 'показания-документ',
      text: 'Показания-Документ',
      type: 'Number',
    },
  ],
};
export default accs;
