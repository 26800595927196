
import Vue from 'vue';
import paramTable from '@/tasks/common/views/$common/paramTable.vue';

export default Vue.extend({
  model: { prop: 'record' },
  components: { paramTable },
  name: 'ParamTab',
  props: {
    record: { type: Object, required: true },
    ownerID: { type: [String, Number], required: true },
  },
});
