const changingsumsogl: StackSelection = {
  objectName: 'СоглашенияОРассрочке.ИзменениеСумм',
  fields: [
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'Date',
      width: '150px',
      readonly: true,
    },
    {
      field: 'срокоплаты',
      text: 'Срок\nоплаты',
      type: 'Date',
      readonly: true,
    },
    {
      field: 'замесяц',
      text: 'За месяц',
      type: 'Date',
      readonly: true,
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
  ],
};

export default changingsumsogl;
