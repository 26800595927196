const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.История',
  recordRoute: '',
  description: '',
  fields: [
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'датнач',
      text: 'ДатНач',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'ДатКнц',
      type: 'Date',
    },
    {
      field: 'история-сотрудник',
      text: 'История-Сотрудник',
      type: 'Number',
    },
    {
      field: 'история-сотрудник>фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'история-тип',
      text: 'История-Тип',
      type: 'Number',
    },
    {
      field: 'история-тип>название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'история-услуга',
      text: 'История-Услуга',
      type: 'Number',
    },
    {
      field: 'история-услуга>название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'объем',
      text: 'Объем',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Number',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
  ],
};
export default accs;
