const service: StackSelection = {
  objectName: 'СоответствиеУслуг',
  description: 'Соответствие услуг',
  hier: true,
  fields: [
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'регион',
      text: 'Регион',
      type: 'Number',
    },
    {
      field: 'номеруслуги',
      text: 'НомерУслуги',
      type: 'Number',
    },
    {
      field: 'номерсторонний',
      text: 'Номер услуги',
      type: 'String',
    },
    {
      field: 'наименованиесторонний',
      text: 'Наименование услуги',
      type: 'String',
    },
    {
      field: '@примечание',
      text: 'Примечание',
      type: 'String',
      computed: true,
    },
    {
      field: 'датнач',
      text: 'С',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'По',
      type: 'Date',
    },
    {
      field: 'флаги',
      text: 'Флаги',
      type: 'Number',
    },
    {
      field: '@тип',
      text: 'Вид соответствия',
      type: 'String',
      computed: true,
    },
    {
      field: 'номер услуги',
      text: 'Номер услуги СТЕК',
      type: 'Number',
    },
    {
      field: 'наименование услуги',
      text: 'Наименование услуги СТЕК',
      type: 'String',
    },
    {
      field: 'услуга-соответствие',
      text: 'Номер услуги СТЕК',
      type: 'Link',
    },
    {
      field: 'счет-соответствие',
      text: 'Лицевые счета',
      type: 'Link',
    },
    {
      field: '@спецрегион',
      text: 'СпецРегион',
      type: 'Number',
      computed: true,
    },
  ],
};
export default service;
