
import Vue from 'vue';
import soglashenieTab from './$agreement/soglashenie_tab.vue';
import CommonTab from '@/tasks/dlg_fl/components/$lawsuits/CommonTab.vue';
import dolgdetalTab from './$agreement/dolgdetal_tab.vue';
import closeAgreementDialog from './$agreement/closeAgreementDialog.vue';

export default Vue.extend({
  components: {
    soglashenieTab,
    CommonTab,
    dolgdetalTab,
    closeAgreementDialog,
  },
  props: {
    agreementid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      httpModel: new this.$HttpModel('СоглашенияОРассрочке'),
      disable: false,
      modaldialog: false,
      tab: null,
      newrecord: false,
      loading: false,
      showCloseAgreementDialog: false,
      closeMonthLs: null,
      executionOfObligation: 0,
    };
  },
  async created() {
    try {
      if (this.agreementid === 'new') {
        this.newrecord = true;
      } else {
        this.loading = true;
        await this.httpModel.executeMethod('РасчитатьЛицевой', { соглашение: this.agreementid });
        this.fetchData();
      }
    } finally {
      this.loading = false;
    }
  },
  computed: {
    title(): string {
      return this && this.record && this.record.номер ? 'Соглашение о рассрочке № ' + this.record.номер.toString() : 'Соглашение о рассрочке';
    },
    isNewRecord(): boolean {
      return this.record.$номерЗаписи === -1;
    },
    inCloseMonth(): boolean {
      return !!this.record.дата && (this.record.дата as string) < this.$store.getters.getCloseMonth();
    },
    Status(): any {
      return this.record.статус !== 'В работе';
    },
    Dis(): any {
      const readonly = [] as any;
      // @ts-ignore
      if (this && this.record && this.record.запретитьэлементыокна) {
        const arrd = this.record.запретитьэлементыокна.toString().split(',');
        for (const row of arrd) {
          readonly[row] = true;
        }
      }
      // @ts-ignore
      if (this && this.record && this.record.разрешитьэлементыокна) {
        const arrr = this.record.разрешитьэлементыокна.toString().split(',');
        for (const row of arrr) {
          readonly[row] = false;
        }
      }
      return readonly;
    },
    paramsForClose(): Object {
      return {
        Лицевой: this.record.лицевой,
        Тип: this.record.тип,
        Признаки: this.record.признаки,
        Позиция: this.record.$номерЗаписи,
        ВыполненыОбязательства: this.executionOfObligation,
        ОткрытыйМесяцЛС: this.record['@открытыймесяцлс'],
        ДатНач: this.record.датнач,
      };
    },
  },
  methods: {
    fetchData() {
      this.$sendMsg('stack-table', 'Соглашение_детализация', 'reload');
    },
    async closeAgreement() {
      this.executionOfObligation = await this.httpModel.executeMethod('ПроверитьВыполнениеОбязательств', { соглашение: this.agreementid });
      let strForAnswer = 'Внимание! ';
      const correctPay = +this.executionOfObligation === 1;
      strForAnswer += correctPay ? 'Оплата производится в соответствии с графиком' : 'Произошло нарушение оплаты по графику.';
      strForAnswer += ' Вы хотите расторгнуть соглашение?';
      this.showCloseAgreementDialog = await this.$yesno(strForAnswer);
    },
    async FillChart(filter: boolean) {
      let rec = this.record;
      const result = await this.httpModel.executeMethod('ЗаполнитьГрафик', { рассрочка: this.record, фильтр: filter });
      rec = Object.assign(rec, result);
      this.fetchData();
    },
    reloadTables(to: any) {
      if (to === 'vziskanie') {
        this.$sendMsg('stack-table', 'Соглашение_взыскание', 'reload');
      }
    },
    onSaveCloseDialog() {
      this.showCloseAgreementDialog = false;
      this.$sendMsg('stack-dialog', 'СоглашенияОРассрочке', 'reload');
      this.fetchData();
    },
  },
});
