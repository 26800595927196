
import Vue from 'vue';

export default Vue.extend({
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      sum: 0,
      records: [] as StackTableRow[],
      flag: 0,
    };
  },
  computed: {
    isError(): boolean {
      return this.records.find((element: StackTableRow) => element.сумма && +element.сумма <= 0) !== undefined;
    },
  },
  methods: {
    async getRecords(data: StackTableRow[]) {
      if (data) {
        this.sum = data.reduce((a, b) => a + (b.сумма ? +b.сумма : 0), 0);
        if (this.record.сумма - this.sum !== 0) {
          data[data.length - 1].сумма = this.round(Number(data[data.length - 1].сумма) + this.record.сумма - this.sum, 2);
        }
        this.records = data;
      }
    },
    async fillUserChart() {
      this.getRecords(this.records);
      if (!this.isError) {
        let rec = this.record;
        const result = await new this.$HttpModel('СоглашенияОРассрочке.ИзменениеСумм').executeMethod('ЗаполнитьГрафикПользователя', {
          рассрочка: this.record,
          суммы: this.records,
        });
        rec = Object.assign(rec, result);
        this.$sendMsg('stack-table', 'Соглашение_детализация', 'reload');
        this.$emit('close');
      } else {
        this.$toast('Суммы оплаты по графику должны быть положительными', { color: 'error' });
      }
    },
    round(value: number, decimals: number) {
      const numConvert = Math.pow(10, decimals);
      return Number(Math.round(value * numConvert) / numConvert);
    },
    onInlineChange(item: StackTableRow, columnName: string) {
      if (columnName === 'сумма' && item.сумма) {
        item.сумма = !isNaN(+item.сумма) ? item.сумма : 0;
      }
      return item;
    },
  },
});
