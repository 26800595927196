
import Vue from 'vue';
import folder from './folder.vue';
import { TableHandlers } from '@/stackEngine';

export default Vue.extend({
  components: { folder },
  methods: {
    paramColorHandler(item: any) {
      if (item && item.даткнц && item.даткнц < this.$store.getters.getOpenMonth()) {
        TableHandlers.trColor(item, 'grey');
      }
      return item;
    },
  },
});
