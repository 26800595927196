const lg: StackSelection = {
  hier: true,
  objectName: 'РеестрКонтролеров',
  description: 'Реестр контролеров',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'фио',
      text: 'Исполнитель',
      type: 'String',
    },
    {
      field: 'название',
      text: 'Реестр',
      type: 'String',
    },
    {
      field: 'исполнитель',
      text: 'ИД Исполнителя',
      type: 'Number',
    },
    {
      field: 'реестр',
      text: 'ИД Реестра',
      type: 'Number',
    },
  ],
};

export default lg;
