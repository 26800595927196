
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      items: [
        { title: 'Реестр кампаний', img: 'ci_doc_center_info', to: { name: 'infocenter-campaigns' } },
        { title: 'Шаблоны центра информирования', img: 'ci_shablons', to: { name: 'infocenter-shablonci' } },
        { title: 'Медиафайлы центра информирования', img: 'ci_media_files', to: { name: 'infocenter-mediaci' } },
      ],
    };
  },
});
