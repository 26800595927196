const acts: StackSelection = {
  objectName: 'СторонниеУслуги',
  description: 'Сторонние услуги',
  hier: true,
  fields: [
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'счетчик',
      text: 'Счетчик',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'внешний код 1',
      text: 'Внешний код 1',
      type: 'Number',
      align: 'right',
    },
    {
      field: 'внешний код 2',
      text: 'Внешний код 2',
      type: 'Number',
      align: 'right',
    },
    {
      field: 'источник-сторонние услуги>название',
      text: 'Организация',
      type: 'String',
      align: 'left',
    },
    {
      field: 'источник-сторонние услуги',
      text: 'орг',
      type: 'Link',
    },
  ],
};

export default acts;
