import Vue, { PropType } from 'vue';
/**
 * Контроллер
 * отвечает за чтение и сохранение данных записи, используется в составе StackDialog и в StackModalDialog
 */

export default Vue.extend({
  name: 'StackDialogController',
  model: { prop: 'record', event: 'change' },
  props: {
    /**
     * не проводить начальное чтение данных (подразумевается, что данные передаются через record)
     */
    noInit: { type: Boolean, default: false },
    /**
     * Модель данных, откуда берем запись
     */
    dataModel: { type: [String, Object, Array], required: true },
    /**
     * запись, с которой работаем
     */
    record: { type: Object as PropType<StackTableRow>, required: true },
    /**
     * row_id записи, которую нужно получить с бэкэнда
     */
    id: { type: [Number, String], required: true },
    /**
     * номер записи владельца выборки
     */
    ownerID: { type: [Number, String], default: null },
    /**
     * номер записи папки
     */
    parentID: { type: [Number, String], default: null },
    /**
     * дополнительные параметры, которые "проксируются" в params запроса (пока только при создании записи)
     */
    params: { type: Object, default: null },
    /** блокировать ли запись при создании */
    recordLock: { type: [Boolean, String], default: false },
    /**
     * сделать диалог только для чтения
     */
    readonly: { type: Boolean, default: false },
    /**
     * Не проверять на изменения перед выходом
     */
    noCheckChanged: { type: Boolean, default: false },
    /**
     * Коллбек, вызываемый перед сохранением записи
     */
    beforeSave: {
      type: Function,
      default: async (item: StackTableRow): Promise<boolean> => {
        return true;
      },
    },
    /**
     * Коллбек, вызываемый перед закрытием диалога
     */
    beforeClose: {
      type: Function,
      default: async (item: StackTableRow): Promise<boolean> => {
        return true;
      },
    },
    /**
     * Коллбек, вызываемый перед созданием записи
     */
    beforeCreate: {
      type: Function,
      default: async (item: StackTableRow): Promise<boolean> => {
        return true;
      },
    },
  },
  data() {
    return {
      dataObject: {} as DataModel,
      state: {} as StackTableRow,
      lockID: null as number | null,
      isBusy: false,
      valid: false, // все поля диалога корректны
      isReady: false,
    };
  },
  computed: {
    isAuth(): boolean {
      return this.$store.getters.isAuth();
    },
    isLocked(): boolean {
      return this.state.$блокировка === 2;
    },
    isReadonly(): boolean {
      return this.readonly || (this.record && !!this.record.$толькоЧтение) || this.isLocked;
    },
    blockingUser(): string {
      return this.state.$блокирующийПользователь as string;
    },
    // это новая запись или нет ?
    isNewRecord(): boolean {
      return !(+this.id >= 0) || (this.record && !!this.record.$блок);
    },

    noStateFields(): string[] {
      const arr: string[] = [];
      this.dataObject.fields.forEach((item: StackField) => {
        if (item.noState) {
          arr.push(item.field);
        }
      });
      return arr;
    },
    multiField(): string | undefined {
      let res: string | undefined;
      this.dataObject.fields.forEach((item: StackField) => {
        if (item.type === 'Link') {
          const rec = this.record[item.field];
          if (typeof rec === 'string' && rec.indexOf(',') > 0) {
            res = item.field;
          }
        }
      });
      return res;
    },
    // перечень измененных полей
    changedFields(): string[] {
      const chFields = [] as any;
      for (const fieldName in this.record) {
        if (!fieldName.startsWith('$') || (this.isNewRecord && fieldName !== '$data')) {
          const stField = this.state[fieldName] || '';
          const recField = this.record[fieldName] || '';
          if (stField !== undefined && stField !== null && stField.toString() !== recField.toString()) {
            chFields.push(fieldName);
          }
        }
      }
      return chFields;
    },

    isChanged(): boolean {
      return this.changedFields.length > 0;
    },

    getQueryParams() {
      let params = {} as any;
      if (this.parentID && +this.parentID > 0) {
        params.папка = this.parentID;
      }
      if (this.ownerID && +this.ownerID > 0) {
        params.владелец = this.ownerID;
      }
      if (this.record && this.record.$этоПапка) {
        params.этоПапка = true;
      }
      // TODO костыль для копирования из немодальных берем параметры корневого элемента (передается через роут)
      if (this.$parent?.$attrs.params) {
        params = Object.assign(params, this.$parent.$attrs.params);
      }
      if (this.params) {
        params = Object.assign(params, this.params);
      }
      return params;
    },
    getChangedFields(): StackTableRow {
      const rec = {} as StackTableRow;
      this.changedFields.forEach((fieldName: string) => {
        rec[fieldName] = this.record[fieldName] !== undefined ? this.record[fieldName] : null;
      });
      this.noStateFields.forEach((fieldName: string) => {
        rec[fieldName] = this.record[fieldName] !== undefined ? this.record[fieldName] : null;
      });
      return rec;
    },
  },

  watch: {
    isAuth(to) {
      if (to && !this.isReady) {
        this.fetchData();
      }
    },
    dataModel: {
      immediate: true,
      handler() {
        if (typeof this.dataModel === 'string') {
          this.dataObject = new this.$HttpModel(this.dataModel);
        } else {
          this.dataObject = this.dataModel;
        }
        if (this.dataModel) {
          this.fetchData();
        }
      },
    },

    // сменился id записи - фетчим новые данные....
    id: {
      immediate: false,
      handler(to, from) {
        // TODO с бэка прилетает -1, а тут у нас новая запись - это new
        const convto = to === -1 ? 'new' : to;
        if (convto !== from && !this.record.$блокинит && !this.record.$блок) {
          this.isReady = false;
          this.fetchData();
        }
      },
    },

    isChanged: {
      immediate: true,
      handler(to) {
        this.record.$естьИзменения = to;
      },
    },
    // если форма готова, то валидируем поля
    isReady: {
      immediate: false,
      handler(to) {
        if (to) {
          this.$nextTick(() => {
            if (this.$refs.form) {
              // @ts-ignore
              this.$refs.form.validate();
              this.$emit('ready');
            }
          });
        }
      },
    },
  },
  methods: {
    mergeRecord(to: StackTableRow, from: StackTableRow) {
      for (const fieldName in from) {
        Vue.set(to, fieldName, from[fieldName]);
        // to[fieldName] = from[fieldName];
      }
    },
    async readRecord() {
      this.isBusy = true;
      try {
        const recs = await this.dataObject.getRecords({ ...this.getQueryParams, номерЗаписи: this.id });
        // this.rec = recs[0];
        if (recs[0]) {
          this.saveState(recs[0]);
          this.mergeRecord(this.record, recs[0]);
          this.customEmit('change', recs[0]);
        }
      } catch (err: AnyException) {
        // @ts-ignore
        if (this.onClose) {
          // @ts-ignore
          this.onClose({}, err?.code === 404);
        }
      } finally {
        this.isBusy = false;
      }
    },
    // Читаем из роута параметры
    // Если видим rec_, то мержим с текущей записью
    initRecordFromRoute(rec: StackTableRow) {
      const query = this.$route.query;
      let founOne = false;
      for (const queryItem in query) {
        if (queryItem.startsWith('rec_')) {
          const fieldName = queryItem.substr(4);
          rec[fieldName] = query[queryItem] as string;
          // @ts-ignore
          query[queryItem] = undefined;
          founOne = true;
        }
      }
      if (founOne) {
        this.$router.replace(query);
      }
    },
    async initRecord() {
      if (!this.dataObject.initRecord || !this.dataObject.createRecord) {
        return false;
      }
      try {
        this.isBusy = true;
        const rec = await this.dataObject.initRecord(this.getQueryParams.$копировать ? { блокировать: true, ...this.getQueryParams } : this.getQueryParams);
        this.initRecordFromRoute(rec);
        if (rec && rec.$номерЗаписи && rec.$номерЗаписи > 0) {
          if (this.getQueryParams.$копировать) {
            rec.$блок = true;
            rec.$копирование = true;
          } else {
            rec.$блокинит = true;
          }
        }
        // && !this.getQueryParams.$копировать
        if (this.recordLock) {
          // @ts-ignore
          const comment = typeof this.recordLock === 'string' && this.recordLock !== '' ? this.recordLock : this.title;
          const lockID = await this.dataObject.createRecord(rec, { блокировать: { url: window.location.href, комментарий: comment }, ...this.getQueryParams });
          if (lockID && +lockID > 0) {
            rec.$номерЗаписи = lockID;
            rec.$блок = true;
          } else {
            throw new Error('Не удалось заблокировать запись');
          }
        }
        if (rec) {
          this.saveState(rec);
          this.mergeRecord(this.record, rec);
          this.customEmit('change', rec);
        }
      } catch (err: AnyException) {
        this.isBusy = false;
        return false;
      } finally {
        this.isBusy = false;
      }
      return true;
    },

    async createRecord(): Promise<boolean> {
      // @ts-ignore
      const isValid = !this.$refs.form || this.$refs.form.validate();
      if (!isValid) {
        return false;
      }
      this.isBusy = true;
      // @ts-ignore
      if (!await this.beforeCreate(this.record)) {
        this.isBusy = false;
        return false;
      }

      let rec = Object.assign({}, this.record);
      if (this.isNewRecord) {
        if (!this.dataObject.createRecord || !this.dataObject.saveRecord) {
          this.isBusy = false;
          return false;
        }
        try {
          if (this.record.$блок || this.record.$блокинит) {
            if (await this.dataObject.saveRecord(rec, this.getQueryParams)) {
              if (this.record.$блокинит) {
                this.record.$блокинит = false;
              } else if (this.record.$блок && this.dataObject.releaseLock) {
                if (await this.dataObject.releaseLock(this.record)) {
                  this.record.$блок = false;
                }
              }
            } else {
              throw new Error('Создание записи не удалось');
            }
          } else {
            const newRecId = await this.dataObject.createRecord(rec, { мультиПоле: this.multiField, ...this.getQueryParams });
            if (newRecId && newRecId > 0) {
              this.record.$номерЗаписи = rec.$номерЗаписи = newRecId;
              if (this.dataObject.prepareRow) {
                rec = this.dataObject.prepareRow(rec);
              }
              this.mergeRecord(this.record, rec);
              this.customEmit('change', rec);
            } else {
              this.customEmit('create_cancel');
              this.isBusy = false;
              return false;
            }
          }
        } catch (error: AnyException) {
          this.isBusy = false;
          return false;
        }

        this.isBusy = false;
        return true;
      }
      this.isBusy = false;
      return false;
    },

    async fetchData() {
      if (!this.isAuth) {
        return;
      }
      if (this.dataObject.model) {
        if (!this.isNewRecord) {
          if (!this.noInit) {
            await this.readRecord();
          } else {
            this.saveState(this.record);
          }
        } else {
          if (this.id === 'new') {
            if (!(await this.initRecord())) {
              // TODO костыль. Разобраться с onClose в диалогах
              // @ts-ignore
              if (this.onClose) {
                // @ts-ignore
                this.onClose();
              }
            }
          }
        }
      }
      this.isReady = true;
    },

    saveState(rec: StackTableRow) {
      this.state = Object.assign({}, rec);
    },

    restoreFromState() {
      this.mergeRecord(this.record, this.state);
      this.customEmit('change', Object.assign({}, this.state));
    },

    async close(doNotAsk = false): Promise<boolean> {
      this.isBusy = true;
      // @ts-ignore
      if (!await this.beforeClose(this.record)) {
        this.isBusy = false;
        return false;
      }

      if (!this.noCheckChanged && !doNotAsk && this.isChanged && !(await this.$yesno('Данные не сохранены. Вы уверены ?'))) {
        this.isBusy = false;
        return false;
      }
      if (this.record.$блок && this.dataObject.releaseLock) {
        await this.dataObject.releaseLock(this.record, { сУдалением: true });
      }
      if (this.record.$блокинит && this.dataObject.deleteRecords) {
        await this.dataObject.deleteRecords([this.record]);
      }
      // if (this.isChanged) {
      //   this.customEmit('change', this.state);
      // }
      this.isBusy = false;
      return true;
    },

    customEmit(event: string, payload?: any) {
      if (this.$attrs.slotEmit) {
        // @ts-ignore
        this.$attrs.slotEmit(event, payload);
      } else {
        this.$emit(event, payload);
      }
    },

    async saveRecord(): Promise<boolean> {
      // @ts-ignore
      const isValid = !this.$refs.form || this.$refs.form.validate();
      // если метод не поддерживается текущей моделью....
      if (!this.dataObject.saveRecord || !isValid) {
        return false;
      }
      this.isBusy = true;
      // @ts-ignore
      if (!await this.beforeSave(this.record)) {
        this.isBusy = false;
        return false;
      }
      const rec = this.getChangedFields;
      rec.$номерЗаписи = this.id;
      rec.$блок = this.record && !!this.record.$блок;

      if (!this.isNewRecord) {
        try {
          const saved = await this.dataObject.saveRecord(rec, this.getQueryParams);
          if (saved) {
            if (this.record.$блокинит) {
              this.record.$блокинит = false;
            }
            if (this.record.$блок) {
              this.record.$блок = false;
            }
            await this.fetchData();
          } else {
            throw new Error('Сохранение не удалось');
          }
        } catch (error: AnyException) {
          this.isBusy = false;
          return false;
          // this.customEmit('change', this.state);
        }
        this.isBusy = false;
        return true;
      }
      return false;
    },
  },
});
