const tars: StackSelection = {
  objectName: 'Услуги.Тарифы.КалькуляцияТарифа',
  description: '',
  fields: [
    {
      field: 'ук-детализация',
      text: 'УК',
      type: 'Link',
      dataModel: 'Организации',
      headers: 'инн,название',
      labelField: 'название',
      hier: true,
      width: '600px',
    },
    {
      field: 'поставщик-детализация',
      text: 'Поставщик',
      type: 'Link',
      dataModel: 'Организации',
      headers: 'инн,название',
      labelField: 'название',
      hier: true,
      width: '600px',
    },
    {
      field: 'услуга-детализация',
      text: 'Номер услуги',
      type: 'Link',
      dataModel: 'Услуги',
      headers: 'наименование,номер услуги',
      labelField: record => {
        return '№' + record['номер услуги'] + ' ' + record.название;
      },
      hier: true,
      width: '300px',
    },
    {
      field: 'значение',
      text: 'Тариф',
      type: 'Money',
      align: 'left',
    },
  ],
};

export default tars;
