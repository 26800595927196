
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  props: {
    record: {
      type: Object,
      required: true,
    },
    name: { type: String, default: '' },
  },
  data() {
    return {
      headers: [
        {
          field: 'Дата',
          text: 'Дата начала действия константы',
          type: 'Date',
          width: '100px',
          readonly: true,
        },
        {
          field: 'НачСезона',
          text: 'Начало сезона',
          type: 'Date',
          width: '100px',
          required: true,
        },
        {
          field: 'КнцСезона',
          text: 'Конец сезона',
          type: 'Date',
          width: '100px',
          required: true,
        },
      ],
      isChange: false as Boolean,
      disableSaveBtn: true as Boolean,
      editedItemIndex: -1 as number,
    };
  },
  methods: {
    rowhandler(item: StackTableRow) {
      if (this.record.дата) {
        if (!item.Дата) {
          item.Дата = this.record.дата;
        }
      }

      if (item.Дата && !item.НачСезона && !item.КнцСезона) {
        item.НачСезона = item.Дата;
      }

      return item;
    },
    checkRows(items: StackTableRow[]) {
      const itemsDate: Array<number> = [];
      let hasWrongRec = false;
      items.forEach(item => {
        if (item.Дата) {
          itemsDate.push(Date.parse(item.Дата.toString()));
        }

        if (!item.КнцСезона) {
          hasWrongRec = true;
        }
      });
      const itemsDateSet = new Set(itemsDate);
      if (itemsDate.length !== itemsDateSet.size) {
        const item = items.splice(this.editedItemIndex >= 0 ? this.editedItemIndex : items.length - 1, 1)[0];
        if (item) {
          // @ts-ignore
          const date = item.Дата.split('T')[0].split('-');
          this.$toast('На дату ' + date[2] + '.' + date[1] + '.' + date[0] + ' уже заполнен ' + this.name, { color: 'error' });
        }
        this.editedItemIndex = -1;
        this.disableSaveBtn = true;
        return 1;
      }

      if (this.isChange) {
        this.isChange = false;

        if (items) {
          items.sort((rec1, rec2) => {
            const dateBeg = rec1 ? rec1.Дата : undefined;
            const dateEnd = rec2 ? rec2.Дата : undefined;
            if (dateBeg && dateEnd && (dateBeg < dateEnd)) {
              return 1;
            } else if (dateBeg && dateEnd && (dateBeg > dateEnd)) {
              return -1;
            }
            return 0;
          });
        }
      }

      this.disableSaveBtn = hasWrongRec;
      return 0;
    },
    onInlineFieldChange(item: StackTableRow, columnName: string) {
      // @ts-ignore
      this.editedItemIndex = item.$index;
      let msg = '';
      if (!item.НачСезона && columnName === 'НачСезона') {
        msg = 'Начало сезона';
      } else if (!item.КнцСезона && columnName === 'КнцСезона') {
        msg = 'Конец сезона';
      }
      if (msg) {
        this.$toast('Необходимо заполнить поле: ' + msg);
        this.disableSaveBtn = true;
        return 1;
      }

      if (item.Дата !== item.НачСезона) {
        item.Дата = item.НачСезона;
      }

      const date = item.Дата ? item.Дата.toString() : undefined;
      const dateBeg = item.НачСезона ? item.НачСезона.toString() : undefined;
      const dateEnd = item.КнцСезона ? item.КнцСезона.toString() : undefined;
      if (date && dateBeg && dateEnd) {
        if (Date.parse(date) !== Date.parse(dateBeg)) {
          this.$toast('Дата начала сезона должна быть равна дате начала действия константы', { color: 'error' });
          this.disableSaveBtn = true;
          return 1;
        }
        if (typeof this.$stackRules.dateIsGreaterThan(dateBeg, dateEnd) === 'string') {
          this.$toast('Дата конца сезона должна быть больше даты начала сезона', { color: 'error' });
          this.disableSaveBtn = true;
          return 1;
        }
      }

      this.isChange = true;

      return 0;
    },
  },
});
