const deductions: StackSelection = {
  recordRoute: 'documents-deductions-deductionsid',
  objectName: 'ВедомостиУдержаний',
  description: 'Ведомости удержаний',
  hier: true,
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'источник-платежи>название',
      text: 'Работодатель',
      type: 'String',
    },
    {
      field: 'источник-платежи',
      text: 'ИсточникID',
      type: 'Link',
    },
    {
      field: 'кол_во',
      text: 'Σ',
      type: 'Number',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'расчмесяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: '₽',
      type: 'Money',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'дата распределения',
      text: 'Дата распределения',
      type: 'Date',
    },
    {
      field: 'автор',
      text: 'Автор',
      type: 'String',
    },
    {
      field: '@блокирована',
      text: ' ',
      type: 'Enum',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Enum',
    },
    {
      field: 'платеж-выписка',
      text: 'ПлатежID',
      type: 'Link',
    },
  ],
};
export default deductions;
