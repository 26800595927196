
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: 'Имя',
          text: 'Имя',
          type: 'String',
          placeholder: 'TOM (латин)',
        },
        {
          field: 'Наименование',
          text: 'Наименование',
          type: 'String',
        },
        {
          field: '@Сред01',
          text: 'Янв',
          type: 'Number',
        },
        {
          field: '@Сред02',
          text: 'Фев',
          type: 'Number',
        },
        {
          field: '@Сред03',
          text: 'Март',
          type: 'Number',
        },
        {
          field: '@Сред04',
          text: 'Апр',
          type: 'Number',
        },
        {
          field: '@Сред05',
          text: 'Май',
          type: 'Number',
        },
        {
          field: '@Сред06',
          text: 'Июнь',
          type: 'Number',
        },
        {
          field: '@Сред07',
          text: 'Июль',
          type: 'Number',
        },
        {
          field: '@Сред08',
          text: 'Авг',
          type: 'Number',
        },
        {
          field: '@Сред09',
          text: 'Сент',
          type: 'Number',
        },
        {
          field: '@Сред10',
          text: 'Окт',
          type: 'Number',
        },
        {
          field: '@Сред11',
          text: 'Ноя',
          type: 'Number',
        },
        {
          field: '@Сред12',
          text: 'Дек',
          type: 'Number',
        },
      ],
    };
  },
});
