
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  methods: {
    async getParam() {
      if (this.record.типвыгрузкиосзн !== 3) {
        const response = await new this.$HttpModel('ОСЗН_ОпределитьРегион').getRecords(this.record);
        if (response && response[0] && response[0].значение !== undefined && response[0].значение !== null) {
          this.$set(this.record, 'типвыгрузкиосзн', +response[0].значение);
        }
      }
    },
  },
});
