
import Vue from 'vue';
import { TableHandlers } from '@/stackEngine';

export default Vue.extend({
  name: 'PaymentsTab',
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  methods: {
    toDialog(row: StackTableRow) {
      if (row['платеж-список']) {
        this.$router.push({
          name: 'payments-paylists-paylistsid',
          params: {
            paylistsid: row['платеж-список'].toString(),
          },
        });
      }
    },
  },
});
