
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    itemValue: { type: String, default: '$номерЗаписи' },
  },
  computed: {
    items(): any {
      return this.record.поля.split('\n');
    },
  },
});
