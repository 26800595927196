
import Vue from 'vue';
import sealsTable from '../../$common/seals/sealsTable.vue';

type HookReleaseFunction = () => void;

export default Vue.extend({
  components: { sealsTable },
  name: 'Quickaddcounters',
  data() {
    return {
      valid: true,
      routeHook: null as null | HookReleaseFunction, // хук на смену роута
      measuretype: {} as any,
      data: [] as StackTableRow[],
      isNewRecord: false, // новая запись или нет
      fund: null,
      fundError: false,
      addCounter: false,
      jobIDgenerate: null as any,
      params: [] as any,
      serviceItems: [] as StackTableRow[],
      rulesThis: Object.assign(this.$stackRules, {
        dateIsLessOrEqualThan: (date1: string, date2: string, mess: string): string | true => {
          return !date1 || !date2 || Date.parse(date1) >= Date.parse(date2) || mess;
        },
      }),
      dostup: [' ', 'Доступен', 'Не доступен'],
    };
  },
  created() {
    this.routeHook = this.$router.beforeEach(this.checkChangedOnLeave) as HookReleaseFunction;
  },
  beforeDestroy() {
    if (this.routeHook) {
      this.routeHook();
    }
  },
  computed: {
    checkHouse(): string | true {
      return this.fundError ? 'Выберите дом!' : true;
    },
    taskprogress(): boolean {
      const result = this.$store.getters.getMsgActionByID(this.jobIDgenerate);
      return result && !(result.complete || result.error);
    },
    iscompletewithouterror(): boolean {
      const result = this.$store.getters.getMsgActionByID(this.jobIDgenerate);
      return result && result.complete;
    },
  },
  methods: {
    checkFields() {
      let resCheck = true;
      this.data.forEach((value, index) => {
        // @ts-ignore
        this.data[index].success = this.$refs.form[index].validate();
        this.data[index].error = !this.data[index].success;
        if (!this.data[index].success) {
          resCheck = false;
        }
      });
      return resCheck;
    },
    addNewCounter() {
      if (this.checkFields()) {
        this.data.push({
          датнач: this.$store.getters.getCurrentDate(),
          датаповерки: this.$store.getters.getCurrentDate(),
          success: false,
          error: false,
        });
      }
    },
    onSelectFund(payload: any) {
      this.fundError = false;
      if (payload && payload[0] && payload[0].тип !== 3) {
        this.fundError = true;
      }
    },
    async addCountersToBase() {
      if (this.checkFields()) {
        const parameters = { metod: 'вставка', newcounters: this.data };
        this.jobIDgenerate = (
          await new this.$HttpModel('БыстрыйВводИПУ').executeMethod('Отправить', parameters, {
            async: true,
            jobName: 'Создание счетчиков на лицевых',
          })
        ).asyncId;
      }
    },
    async onBtnDelete(idx: number) {
      const answer = await this.$yesno('Внесенные данные будут удалены. Вы уверены ?');
      if (!answer) {
        return;
      }
      this.data.splice(idx, 1);
    },
    async onSelectMeasure(event: string, payload: any, row: StackTableRow) {
      if (payload && payload[0]) {
        this.measuretype = payload[0];
        Vue.set(row, 'тарифность', Math.max(this.measuretype.тарифность, 1));
        Vue.set(row, 'микрогенерация', this.measuretype['микрогенерация']);
        Vue.set(row, 'разрядность', this.measuretype['счетчика разрядность']);
        Vue.set(row, 'дробнаяразрядность', this.measuretype['счетчика дробная разрядность']);
        if (this.serviceItems.map(item => item.ключ).includes(this.measuretype['@услуга'])) {
          this.$set(row, 'объект-услуга', this.measuretype['@услуга']);
        }
        Vue.set(row, 'коэффициент трансформации', 1);

        await this.setNPP(row);
        this.changeNextPoverkaDate('onSelectMeasure' + event, row);
      }
    },
    async setNPP(row: StackTableRow) {
      const ls = row['объекты-счет'];
      const usl = row['объект-услуга'];
      let counter = -1;

      for (const item of this.data) {
        if (item['объекты-счет'] === ls && item['объект-услуга'] === usl) {
          counter++;
        }
      }

      const parameters = { нзлс: ls, нзусл: usl, дата: row['датнач'] };
      const result = await new this.$HttpModel('БыстрыйВводИПУ').executeMethod('ОпределитьНПП', parameters);
      // @ts-ignore
      Vue.set(row, 'нпп', +result['нпп'] + counter);

      this.setLabelsForPok(row);
    },
    onChangePoverkaDate(row: StackTableRow) {
      this.changeNextPoverkaDate('onChangePoverkaDate', row);
    },

    async onChangeInstallDate(row: StackTableRow) {
      await this.setNPP(row);
      this.changeNextPoverkaDate('onChangeInstallDate', row);
    },
    changeNextPoverkaDate(event: string, row: StackTableRow) {
      let changeDate = false;
      const poverkaDate = (row.датаповерки || row.датнач || '') as string;
      const nextpoverkaDate = (row.датаследующейповерки || '') as string;
      const poverkaInterval = (this.measuretype['@интервал'] || 0) as number;

      switch (event) {
        case 'onChangePoverkaDate':
          if (row.датаповерки === null) {
            row.датаповерки = poverkaDate;
          }
          changeDate = true;
          break;
        case 'onChangeInstallDate':
          row.датаповерки = row.датнач;
          this.onChangePoverkaDate(row);
          break;
        case 'onSelectMeasureInit':
          if (!this.isNewRecord && nextpoverkaDate === '') {
            changeDate = true;
          }
          break;
        case 'onSelectMeasureUpdate':
          // если выбрали прибор учета с нулевым интервалом, дата следующей поверки совпадает с текущей
          if (poverkaInterval === 0) {
            Vue.set(row, 'датаследующейповерки', this.$stackDate.format(new Date(poverkaDate), 'ISO'));
          } else {
            changeDate = true;
          }
          break;
      }

      if ((changeDate || nextpoverkaDate === '') && poverkaDate !== '' && poverkaInterval) {
        Vue.set(row, 'датаследующейповерки', this.$stackDate.format(this.$stackDate.addYears(new Date(poverkaDate), poverkaInterval), 'ISO'));
      }
    },
    async checkChangedOnLeave(to: any, from: any, next: any) {
      let answer = true;
      if (this.data.length !== 0) {
        answer = await this.$yesno('Несохраненные данные будут утеряны! Вы уверены, что хотите выйти?');
      }
      if (answer) {
        next();
      }
    },
    async setLabelsForPok(row: StackTableRow) {
      const masLabelsTar = await new this.$HttpModel('СписокМетокТарифов').executeMethod('ВернутьМассивМеток', {
        нзусл: row['объект-услуга'],
        тарифность: row['тарифность'],
        мг: row['микрогенерация'],
      });
      Vue.set(row, 'label1', masLabelsTar[1] || '');
      Vue.set(row, 'label2', masLabelsTar[2] || '');
      Vue.set(row, 'label3', masLabelsTar[3] || '');
      Vue.set(row, 'label4', masLabelsTar[4] || '');
      Vue.set(row, 'label5', masLabelsTar[5] || '');
      Vue.set(row, 'label6', masLabelsTar[6] || '');
    },
    cleanupAdr(id: number, type: number) {
      if (!id) {
        switch (type) {
          case 12:
            this.params.улица = null;
            this.params.дом = null;
            break;
          case 2:
            this.params.дом = null;
            break;
        }
      }
    },
  },
  watch: {
    iscompletewithouterror(to) {
      if (to) {
        this.data = [];
        this.fund = null;
      }
    },
  },
});
