
import Vue from 'vue';

export default Vue.extend({
  name: 'HistoryList',
  props: {
    cacheRecs: { type: Array, default: undefined },
  },
  data() {
    return {
      styleList: { 'max-height': 'calc(100vh - 170px)', 'overflow-y': 'auto' },
    };
  },
  methods: {
    onHisListClick(to: string) {
      if (to && this.$route.path !== to) {
        this.$router.push(to);
      }
    },
    toDateTime(date: string) {
      return this.$stackDate.format(date, 'DD.MM.YYYY HH:mm');
    },
  },
});
