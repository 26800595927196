
import Vue from 'vue';

export default Vue.extend({
  props: {
    record: { type: Object, required: true },
  },
  created() {
    // Vue.set(this.record, 'files', files);
  },
  methods: {
    upFiles(files: any) {
      Vue.set(this.record, 'files', files);
      // this.record.files = files;
    },
  },
});
