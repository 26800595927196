const par: StackSelection = {
  recordRoute: 'accounts-filterls-filterlsid',
  hier: true,
  objectName: 'ФильтрыЛС',
  description: 'Фильтры лицевых',
  openLink: 'accounts-filterls',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'фильтр-лицевой',
      text: 'Лицевой',
      type: 'Link',
    },
    {
      field: 'автор',
      text: 'Автор',
      type: 'String',
    },
    {
      field: 'времяактуализации',
      text: 'Время актуализации',
      type: 'DateTime',
    },
  ],
};

export default par;
