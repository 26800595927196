import { TableHandlers } from '@/stackEngine';
const an: StackSelection = {
  recordRoute: '',
  objectName: 'ПоискОрганизацииПоИнн',
  description: 'Найденные организации',
  prepareRow: row => {
    if (row.даталиквидации !== '') {
      TableHandlers.trColor(row, 'grey');
    }
    return row;
  },
  fields: [
    {
      field: 'инн',
      text: 'ИНН',
      type: 'String',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
    {
      field: 'огрн',
      text: 'ОГРН',
      type: 'String',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'окпо',
      text: 'ОКПО',
      type: 'String',
    },
    {
      field: 'кпп',
      text: 'КПП',
      type: 'String',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'String',
    },
    {
      field: 'оквэд',
      text: 'ОКВЭД',
      type: 'String',
    },
    {
      field: 'директор',
      text: 'Директор',
      type: 'String',
    },
    {
      field: 'директор_должность',
      text: 'Директор_Должность',
      type: 'String',
    },
    {
      field: 'датарегистрации',
      text: 'ДатаРегистрации',
      type: 'String',
    },
    {
      field: 'даталиквидации',
      text: 'ДатаЛиквидации',
      type: 'String',
    },
  ],
};

export default an;
