
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: {
      type: Object,
      default: (): any => {
        return { year: false };
      },
    },
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        ДатНач: this.$store.getters.getWorkMonth(),
        ДатКнц: this.$store.getters.getWorkMonth(),
        '@Услуга': null,
        '@КатегорияУслуг': null,
        '@Поставщик': null,
        '@КатегорияПоставщиков': null,
        ФПоставщик: 0,
        ФГруппыУсл: 0,
        ФМесяц: 0,
        ФГода: this.params.year ? 1 : 0,
      },
    };
  },
});
