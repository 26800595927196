const rasp: StackSelection = {
  objectName: 'ЛицевыеСчета.СписокРаспределителей',
  description: 'Список распределителей',
  recordRoute: 'accounts-accountsid-distributors-distributorsid',
  fields: [
    {
      field: 'n п/п',
      text: '№ п/п',
      type: 'String',
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
    {
      field: 'распределитель-номенклатура',
      text: 'Номенклатура',
      type: 'Number',
    },
    {
      field: 'заводскойномер',
      text: 'Заводской номер',
      type: 'String',
    },
    {
      field: 'доступ',
      text: 'Доступ',
      type: 'String',
    },
    {
      field: 'разрядность',
      text: 'Разрядность',
      type: 'Number',
    },
    {
      field: 'дробнаяразрядность',
      text: 'Дробная разрядность',
      type: 'Number',
    },
    {
      field: 'датаповерки',
      text: 'Дата поверки',
      type: 'Date',
    },
    {
      field: 'номерпломбы',
      text: 'Номер пломбы',
      type: 'String',
    },
    {
      field: 'место установки',
      text: 'Место установки',
      type: 'Enum',
    },
    {
      field: 'датнач',
      text: 'Установлен',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Снят',
      type: 'Date',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'причинаснятия',
      text: 'ПричинаСнятия',
      type: 'Number',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: 'радиаторный коэффициент',
      text: 'Радиаторный коэффициент',
      type: 'Number',
    },
    {
      field: 'коэффициент помещения',
      text: 'Коэффициент помещения',
      type: 'Number',
    },
  ],
};
export default rasp;
