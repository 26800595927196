
import Vue from 'vue';
import addressFilter from '@/tasks/fl/views/$common/filters/addressFilter.vue';

export default Vue.extend({
  components: { addressFilter },
  props: {
    params: { type: [Object], default: null },
    /**
     * объект с фильтром по-умолчанию
     */
    filter: { type: [Object], default: null },
  },
  created() {
    Object.assign(this.item, this.filter);
  },
  computed: {
    wasSetAddres(): string | number {
      return this.item && (this.item.квартира || this.item.дом || this.item.улица || this.item.нп);
    },
  },
  data() {
    return {
      item: {
        лицевой: null,
        нп: null,
        улица: null,
        дом: null,
        квартира: null,
        серия: null,
        номер: null,
        инн: null,
        снилс: null,
        фио: null,
        датарождения: null,
        фсобственники: null,
        фжильцы: null,
        фпредставители: null,
        фзаявители: null,
        фбезпривязок: null,
        допидчл: null,
        история: null,
      } as any,
    };
  },
  methods: {
    onChangeLs() {
      this.item.квартира = this.item.дом = this.item.улица = this.item.нп = null;
    },
  },
  watch: {
    wasSetAddres(to) {
      // Если выбран фонд - очищаем лс
      if (to) {
        this.item.лицевой = null;
      }
    },
  },
});
