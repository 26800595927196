
import Vue from 'vue';

import { TableHandlers } from '@/stackEngine';

export default Vue.extend({
  model: { prop: 'record', event: 'input' },
  props: {
    ownerID: { type: Number, required: true },
    params: { type: Object, required: true },
  },
  data() {
    return {
      развернуть: false,
      selectedRows: [] as StackTableRow[],
      selectedRowsCompens: [] as StackTableRow[],
      tabs: null,
    };
  },
  methods: {
    async onSave() {
      await new this.$HttpModel('ЛицевыеСчета.ПерерасчетыЗаСтарыйПериод').executeMethod('СохранитьПерерасчеты', { владелец: this.ownerID, перерасчет: this.selectedRows });
      await new this.$HttpModel('ЛицевыеСчета.ПерерасчетыКомпенсацийЗаСтарыйПериод').executeMethod('СохранитьПерерасчеты', {
        владелец: this.ownerID,
        перерасчет: this.selectedRowsCompens,
      });
      this.$emit('save');
    },
  },
});
