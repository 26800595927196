
import Vue from 'vue';
import addressFilter from '@/tasks/fl/views/$common/filters/addressFilter.vue';

export default Vue.extend({
  name: 'LsSearch',
  components: { addressFilter },
  computed: {
    headers(): StackField[] {
      const headers = [] as StackField[];
      headers.push({ field: 'номер', text: 'Номер', type: 'String' });
      headers.push({ field: 'адреслс', text: 'Адрес', type: 'String' });
      headers.push({ field: 'счет-наниматель>фио', text: 'ФИО', type: 'String' });
      headers.push({ field: 'примечание', text: 'Примечание', type: 'String' });
      return headers;
    },
  },
  data() {
    return {
      fundModel: [] as StackTableRow[],
      loading: false,
      item: {} as StackTableRow,
      tabs: 0,
    };
  },
  methods: {
    getRouteName() {
      return 'operations-windowoperator-accountsid';
    },
    timeout(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async onFilter() {
      // TODO Комбобокс не успевает дослать change, если жать сразу на Сохранить
      // Нужно разобраться ! Никуда Не копипастить !!!
      await this.timeout(200);
      // this.$store.commit('SAVE_CACHE_DATA', { id: this.stateIdHash, data: this.item });
      await this.onSearch(false);
    },
    async onSearch(jump: boolean) {
      const parameters = {} as any;
      let foundOne = false;
      for (const field in this.item) {
        if (this.item[field] && !jump) {
          parameters[field] = this.item[field];
          foundOne = true;
        } else if (this.item[field] && (field === 'нп' || field === 'пструктура' || field === 'улица' || field === 'дом' || field === 'квартира' || field === 'комната')) {
          parameters[field] = this.item[field];
          foundOne = true;
        }
      }
      if (!foundOne) {
        this.$toast('Поиск возможен только при указании адреса');
        return;
      }
      this.loading = true;
      const params = { фильтр: { ...parameters, развернутьПапки: 1, сКорня: 1, переход: jump } };
      this.fundModel = await new this.$HttpModel('ЛицевыеСчета').getRecords(params);
      if (this.fundModel.length === 0) {
        this.$toast('Лицевой не найден');
      }
      this.loading = false;
    },
  },
});
