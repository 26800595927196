const par: StackSelection = {
  objectName: 'Объекты.Параметры',
  description: 'Параметры прибора учета',
  fields: [
    {
      field: 'виды-параметры>название',
      text: 'Краткое название',
      type: 'String',
    },
    {
      field: 'виды-параметры>наименование',
      text: 'Параметр',
      type: 'String',
    },
    {
      field: 'виды-параметры',
      text: 'виды-параметры',
      type: 'Link',
    },
    {
      field: 'строка',
      text: 'Значение',
      type: 'String',
      align: 'right',
      computed: true,
    },
    {
      field: 'датнач',
      text: 'Дата начала',
      type: 'Date',
      align: 'right',
    },
    {
      field: 'даткнц',
      text: 'Дата окончания',
      type: 'Date',
      align: 'right',
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
      align: 'right',
    },
  ],
};
export default par;
