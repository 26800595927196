const actsqu: StackSelection = {
  objectName: 'ВедомостиРазовыхНачислений.Файлы',
  recordRoute: '',
  description: '',
  fields: [
    {
      field: 'имя файла',
      text: 'Имя файла',
      type: 'String',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'комментарий',
      text: 'Комментарий',
      type: 'String',
    },
    {
      field: 'расширение',
      text: 'Расширение',
      type: 'Enum',
    },
    {
      field: 'типы документов-файл',
      text: 'типы документов-файл',
      type: 'Link',
    },
    {
      field: 'датнач',
      text: 'Период действия с',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Период действия по',
      type: 'Date',
    },
  ],
};
export default actsqu;
