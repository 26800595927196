
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: 'полноеимя',
          text: 'Название',
          type: 'String',
        },
        {
          field: 'значение0',
          text: '1 прож',
          type: 'Number',
        },
        {
          field: 'значение1',
          text: '2 прож',
          type: 'Number',
        },
        {
          field: 'значение2',
          text: '3 прож',
          type: 'Number',
        },
        {
          field: 'значение3',
          text: '4 прож',
          type: 'Number',
        },
        {
          field: 'значение4',
          text: '5 и более',
          type: 'Number',
        },
      ],
    };
  },
  methods: {
    title(item: StackTableRow) {
      return item['полное имя'] + ' без электроплит';
    },
  },
});
