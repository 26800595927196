
import Vue from 'vue';

export default Vue.extend({
  name: 'LsSelectDialog',
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: '' },
    typeGC: { type: [Number, String], default: 0 },
    hideType: { type: Boolean, default: false }, // скрываем выбор главного счетчика
    singleLine: { type: Boolean, default: false },
  },
  data() {
    return {
      fundIsCorrectFlag: 0,
      rulesThis: {
        fundIsCorrect: (flag: number): string | true => {
          switch (flag) {
            case 1:
              return 'Фонд не выбран';
            case 2:
              return 'Прибор учета должен относится к уровню дома';
            default:
              return true;
          }
        },
      },
    };
  },
  methods: {
    onChangeFund(items: StackTableRow[]) {
      let tempFlag = 0;
      const tempArray: Array<{ $номерЗаписи: number; тип: number }> = [];
      if (!(items && items.length > 0)) {
        tempFlag = 1;
      } else {
        items.forEach((item: any) => {
          if (item && item.$номерЗаписи && item.$номерЗаписи > 0 && item.тип !== undefined) {
            if (item.тип > 10 || (item.тип <= 2 && item.тип >= 0)) {
              tempFlag = 2;
            }
            tempArray.push({ $номерЗаписи: item.$номерЗаписи, тип: item.тип });
          }
        });
      }
      this.fundIsCorrectFlag = tempFlag;
    },
  },
});
