export const helloToolbar: StackTourStep[] = [
  {
    route: '/help/tour/',
    target: '.intro',
    title: 'Давайте знакомиться!',
    content: `
        Для того, чтобы работа с ПК СТЕК была простой, быстрой и удобной начнем с основ.
        <div class="pt-2" />
        Первым делом ознакомимся с верхней частью программы.`,
    params: {
      placement: 'auto',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [5, -100],
          },
        },
      ],
    },
  },
];

export const helloDial: StackTourStep[] = [
  {
    route: '/help/tour/dialog',
    commit: '-1',
    target: '[data-cy="stack-test-dialog"]',
    title: 'Диалоги − это просто!',
    content: `Большая часть работы в ПК СТЕК проходит в диалоговых окнах: создание ЛС, документов; построение отчетов; работа со справочниками; операции.
        <div class="pt-2" />
        Научимся эффективно взаимодействовать с диалогами.`,
    params: {
      placement: 'auto',
    },
  },
];

export const helloTable: StackTourStep[] = [
  {
    route: '/help/tour/table',
    target: '[data-cy="stack-table"]',
    commit: '0',
    title: 'Подробно о таблицах',
    content: `Таблицы − важная составляющая ПК СТЕК. Они позволяют анализировать, сравнивать, фильтровать, сортировать и управлять данными.
        <div class="pt-2" />
        Научимся получать ценную информацию и совершать действия с таблицей.`,
    params: {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [5, 35],
          },
        },
      ],
    },
  },
];