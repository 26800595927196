
import Vue from 'vue';
import StackTourStep from './StackTourStep.vue';
import { DEFAULT_OPTIONS, KEYS } from './constants';

export default Vue.extend({
  name: 'VTour',
  components: { StackTourStep },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    name: {
      type: String,
    },
  },
  data() {
    return {
      currentStep: -1,
      callbacks: {
        // @ts-ignore
        onPreviousStep: this.stepCallback,
        // @ts-ignore
        onStart: this.stepCallback,
        // @ts-ignore
        onStop: this.onStopTour,
        // @ts-ignore
        onNextStep: this.stepCallback,
      },
      options: DEFAULT_OPTIONS,
      currentRoute: '/',
    };
  },
  mounted() {
    if (this.options.useKeyboardNavigation) {
      window.addEventListener('keyup', this.handleKeyup);
    }
    this.start();
  },
  beforeDestroy() {
    if (this.options.useKeyboardNavigation) {
      window.removeEventListener('keyup', this.handleKeyup);
    }
  },
  computed: {
    isRunning(): boolean {
      return this.currentStep > -1 && this.currentStep < this.numberOfSteps;
    },
    isFirst(): boolean {
      return this.currentStep === 0;
    },
    isLast(): boolean {
      return this.currentStep === this.steps.length - 1;
    },
    numberOfSteps(): number {
      return this.steps.length;
    },
    steps(): StackTourStep[] {
      return this.$store.getters.HelpSteps();
    },
  },
  methods: {
    onStopTour() {
      this.$store.commit('SET_HELP_TOUR_STATE', false);
      this.$router.push(this.currentRoute);
    },
    stepCallback(currentStep = 0) {
      const newRoute = this.steps[currentStep].route;
      if (newRoute && this.$route.path !== newRoute) {
        this.currentRoute = this.$route.path;
        this.$router.push(newRoute);
      } else if (this.$route.path === newRoute) {
        this.firstStep();
      }
    },
    firstStep() {
      this.currentStep = 0;
    },
    start(startStep?: number) {
      this.callbacks.onStart();
    },
    previousStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
        this.callbacks.onPreviousStep(this.currentStep);
      }
    },
    nextStep() {
      if (this.currentStep < this.numberOfSteps - 1 && this.currentStep !== -1) {
        this.currentStep++;
        this.callbacks.onNextStep(this.currentStep);
      }
    },
    stop() {
      this.callbacks.onStop();
      this.currentStep = -1;
    },

    handleKeyup(e: KeyboardEvent) {
      switch (e.keyCode) {
        case KEYS.ARROW_RIGHT:
          this.nextStep();
          break;
        case KEYS.ARROW_LEFT:
          this.previousStep();
          break;
        case KEYS.ESCAPE:
          this.stop();
          break;
      }
    },
  },
});
