
import Vue from 'vue';

export default Vue.extend({
  props: {
    typeTable: { type: Number, default: 0 }, // 0 - улицы, 1 - дома
  },

  data() {
    return {
      item: {
        состояние: 0,
        типОшибки: -1,
      } as any,
    };
  },
});
