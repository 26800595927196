const ls: StackSelection = {
  objectName: 'ДелениеЛицевого.Платежи до',
  description: 'Старый лицевой',
  fields: [
    {
      field: 'источник',
      text: 'Источник',
      type: 'String',
      readonly: true,
    },
    {
      field: 'row_id',
      text: 'ROW_ID',
      type: 'Number',
      readonly: true,
    },
    {
      field: 'дата',
      text: 'ДатНач',
      type: 'Date',
      width: '100px',
      readonly: true,
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
      width: '100px',
      readonly: true,
    },
  ],
};

export default ls;
