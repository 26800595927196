export interface TwainDeviceInfo {
  id: string;
  name: string;
}

export interface TwainEnumerateDeviceResult {
  devices: TwainDeviceInfo[];
  defaultDeviceId: string;
}

interface Client {
  send: (method: string, params?: object) => Promise<any>;
}

export class Twain {
  // tslint:disable-next-line: variable-name
  private _client: Client;

  constructor(client: Client) {
    this._client = client;
  }

  public enumerateDevices(): Promise<TwainEnumerateDeviceResult> {
    return this._client.send('Twain.enumerateDevices');
  }

  public scanToFile(deviceId: string, fileName: string) {
    return this._client.send('Twain.scanToFile', { deviceId, fileName });
  }
}
