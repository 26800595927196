
import Vue from 'vue';

import AgreementFilter from '@/tasks/fl/views/$common/filters/AgreementFilter.vue';
import AgreementFolder from './$folder/Agreementfolder.vue';
import AgreementToolbar from './$agreement/AgreementToolbar.vue';

export default Vue.extend({
  components: {
    AgreementToolbar,
    AgreementFilter,
    AgreementFolder,
  },
});
