const doguk: StackSelection = {
  objectName: 'ДоговорыУК.Объекты',
  description: '',
  fields: [
    {
      field: 'датнач',
      text: 'С',
      type: 'Date',
    },
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'даткнц',
      text: 'По',
      type: 'Date',
    },
    {
      field: 'счет-ук',
      text: 'Счет-УК',
      type: 'Link',
    },
    {
      field: 'услуга-укдоговор',
      text: 'Услуга-УКДоговор',
      type: 'Link',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
  ],
};
export default doguk;
