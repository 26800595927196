const grcount: StackSelection = {
  objectName: 'ГрупповыеСчетчики.ВводПоказаний',
  description: '',
  fields: [
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'заводскойномер',
      text: 'Заводской номер',
      type: 'String',
    },
    {
      field: 'расчетный месяц',
      text: 'За',
      type: 'DateMonth',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'показание',
      text: 'Показание',
      type: 'Number',
    },
    {
      field: 'расход',
      text: 'Расход',
      type: 'Number',
    },
    {
      field: 'дополнительный расход',
      text: 'Дополнительный Расход',
      type: 'Number',
    },
    {
      field: 'итоговый расход',
      text: 'Итоговый Расход',
      type: 'Number',
    },
    {
      field: 'групповой',
      text: 'Групповой',
      type: 'Number',
    },
    {
      field: 'тариф',
      text: 'Зона суток',
      type: 'String',
    },
    {
      field: 'типввода',
      text: 'Тип ввода',
      type: 'Number',
    },
    {
      field: 'объект-показания',
      text: 'объект',
      type: 'Number',
    },
    {
      field: 'показания-услуга',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'разрядность',
      text: 'Разрядность',
      type: 'Number',
    },
    {
      field: 'коэффициент трансформации',
      text: 'КТранс',
      type: 'Number',
    },
    {
      field: '@зона',
      text: 'Зона суток',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'модель',
      text: 'Модель',
      type: 'String',
    },
    {
      field: '@покпредсч',
      text: '@ПокПредСч',
      type: 'Number',
    },
    {
      field: '@датпредсч',
      text: '@ДатПредСч',
      type: 'Date',
    },
    {
      field: '@меспредсч',
      text: '@МесПредСч',
      type: 'Date',
    },
    {
      field: 'ограничениерасхода',
      text: 'ОграничениеРасхода',
      type: 'Number',
    },
    {
      field: '$ошибкаПоказания',
      text: '$ошибкаПоказания',
      type: 'Boolean',
    },
    {
      field: '$ошибкаРасхода',
      text: '$ошибкаРасхода',
      type: 'Boolean',
    },
    {
      field: '$ошибкаДаты',
      text: '$ошибкаДаты',
      type: 'Boolean',
    },
    {
      field: '$ошибкаТарифности',
      text: '$ошибкаТарифности',
      type: 'Boolean',
    },
    {
      field: 'тарифность',
      text: 'тарифность',
      type: 'Number',
    },
    {
      field: 'дробнаяразрядность',
      text: 'ДробнаяРазрядность',
      type: 'Number',
    },
    {
      field: 'типсчетчика',
      text: 'ТипСчетчика',
      type: 'Number',
    },
    {
      field: 'наименование',
      text: 'Услуга',
      type: 'String',
    },
  ],
};
export default grcount;
