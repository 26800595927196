const acts: StackSelection = {
  objectName: 'ПочтовыйКлиент.Ящики',
  description: 'Ящики',
  fields: [
    {
      field: 'откого',
      text: 'Ящик',
      type: 'String',
    },
    {
      field: 'кому',
      text: 'Кому',
      type: 'String',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'блокировать',
      text: 'Блокировать',
      type: 'String',
    },
    {
      field: 'вложение',
      text: 'Вложение',
      type: 'String',
    },
    {
      field: 'обработано',
      text: 'Обработано',
      type: 'String',
    },
    {
      field: 'отб_создано',
      text: 'Отб_создано',
      type: 'String',
    },
    {
      field: 'отб_получено',
      text: 'Отб_получено',
      type: 'String',
    },
    {
      field: 'тело',
      text: 'тело',
      type: 'String',
    },
    {
      field: '@тело',
      text: '@тело',
      type: 'String',
    },
    {
      field: 'исхподключение',
      text: 'исхподключение',
      type: 'String',
    },
    {
      field: 'вхподключение',
      text: 'вхподключение',
      type: 'String',
    },
    {
      field: 'сообщение-транспорт',
      text: 'транспорт id',
      type: 'Link',
    },
    {
      field: 'сообщение-транспорт>отфио',
      text: 'От кого',
      type: 'String',
    },
    {
      field: 'сообщение-транспорт>название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'сообщение-транспорт>исхсервер',
      text: 'исхсервер',
      type: 'String',
    },
    {
      field: 'сообщение-транспорт>исхлогин',
      text: 'исхлогин',
      type: 'String',
    },
    {
      field: 'сообщение-транспорт>исхпорт',
      text: 'исхпорт',
      type: 'String',
    },
    {
      field: 'сообщение-транспорт>вхсервер',
      text: 'Сервер',
      type: 'String',
    },
    {
      field: 'сообщение-транспорт>вхлогин',
      text: 'Логин',
      type: 'String',
    },
    {
      field: 'сообщение-транспорт>вхпорт',
      text: 'вхпорт',
      type: 'String',
    },
    {
      field: 'сообщение-транспорт>отэладрес',
      text: 'сообщение-транспорт>отэладрес',
      type: 'String',
    },
    {
      field: 'сообщение-транспорт>оторганизация',
      text: 'сообщение-транспорт>оторганизация',
      type: 'String',
    },
    {
      field: 'сообщение-транспорт>обрадрфио',
      text: 'сообщение-транспорт>обрадрфио',
      type: 'String',
    },
    {
      field: 'сообщение-транспорт>обрадрэладрес',
      text: 'сообщение-транспорт>обрадрэладрес',
      type: 'String',
    },
    {
      field: 'детализацияжурнала',
      text: 'детализацияжурнала',
      type: 'String',
    },
    {
      field: 'сообщение-транспорт>черныйсписок',
      text: 'сообщение-транспорт>черныйсписок',
      type: 'String',
    },
  ],
};
export default acts;
