const doguk: StackSelection = {
  objectName: 'ДоговорыСПоставщиками.Объекты',
  description: '',
  fields: [
    {
      field: 'датнач',
      text: 'С',
      type: 'Date',
    },
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'даткнц',
      text: 'По',
      type: 'Date',
    },
    {
      field: 'счет-список поставщиков',
      text: 'Счет-Список поставщиков',
      type: 'Link',
    },
    {
      field: 'услуги-список поставщиков',
      text: 'Услуги-Список поставщиков',
      type: 'Link',
    },
  ],
};
export default doguk;
