
import Vue from 'vue';

export default Vue.extend({
  name: 'NumberTypeFilter',
  model: { prop: 'params', event: 'input' },
  props: {
    params: { type: Object, required: true },
    dense: { type: Boolean, default: false },
    numberLabel: { type: String, default: 'Номер' },
    numberType: { type: String, default: 'number' },
    typeLabel: { type: String, default: 'Тип' },
    typedataModel: { type: String, default: '' },
    valueKey: { type: String, default: 'номер' },
  },
});
