
import Vue from 'vue';

export default Vue.extend({
  props: {
    record: { type: Object, required: true },
    disabled: { type: Boolean, required: true },
    runCreation: { type: Boolean, required: true },
  },
  data() {
    return {
      typeSplitDebt: 0,
      isSave: false,
    };
  },
  methods: {
    apply() {
      this.isSave = true;
      this.$emit('save', {
        делСальдо: this.typeSplitDebt,
      });
    },
  },
});
