import { store } from '../../store';

// Список возможных событий
type EventList = 'APP_STARTED' | 'APP_MOUNTED' | 'APP_READY' | 'APP_USER_SIGN_IN' | 'APP_USER_SIGN_OUT';

type ListenerFunc = (...args: any[]) => void | Promise<void>;
interface Listener {
  func: ListenerFunc;
  msg?: string;
}
interface EEvents {
  [event: string]: Listener[];
}

export class StackAppEmitter {
  private readonly events: EEvents = {};

  public on(event: EventList, func: ListenerFunc, msg?: string): () => void {
    if (typeof this.events[event] !== 'object') {
      this.events[event] = [];
    }
    const listenerObj: Listener = { func, msg };
    this.events[event].push(listenerObj);
    return () => this.removeListener(event, listenerObj.func);
  }

  public removeListener(event: EventList, listenerFunc: ListenerFunc): void {
    if (typeof this.events[event] !== 'object') {
      return;
    }

    const idx: number = this.events[event].findIndex(i => i.func === listenerFunc);
    if (idx > -1) {
      this.events[event].splice(idx, 1);
    }
  }

  public removeAllListeners(): void {
    Object.keys(this.events).forEach((event: string) =>
      this.events[event].splice(0, this.events[event].length),
    );
  }

  public async emitAsync(event: EventList, ...args: any[]): Promise<void> {
    // console.log('send emit', event);
    if (typeof this.events[event] !== 'object') {
      return;
    }
    const listeners = [...this.events[event]];
    for (const listener of listeners) {
      if (listener.msg) {
        // console.log('on event', event, listener.msg);
        store.commit('SET_BUSY_STATUS', listener.msg);
      }
      try {
        await listener.func.apply(this, args);
      } catch (e: AnyException) {
        console.log(`StackAppEvent error on ${event}`, e);
      }
    }
    store.commit('SET_BUSY_STATUS', '');
    // console.log('end event', event);
  }
}

export const stackAppEmitter = new StackAppEmitter();