
import Vue from 'vue';
import InputAccountDialog from '@/tasks/common/views/$common/accounts/$dialogs/InputAccountDialog.vue';

export default Vue.extend({
  name: 'WebTab',
  components: { InputAccountDialog },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
});
