
import Vue from 'vue';

export default Vue.extend({
  name: 'MassPlomb',
  props: {
    folder: { type: Number, default: -10 },
  },
  data() {
    return {
      начномер: 0,
      количество: 0,
      вид: 0,
      префикс: '',
      постфикс: '',
      колзнаков: 8,
      папка: this.folder,
      датаввода: this.$store.getters.getCurrentDate(),
      rulesThis: {
        isRequired: (value: number): string | true => {
          return (value && value % 1 === 0) || 'Введите целое число больше 0!';
        },
      },
    };
  },
  watch: {
    датаввода(to) {
      if (to > this.$store.getters.getCurrentDate()) {
        this.$toast('Указанная дата ввода больше текущей даты.\nПроверьте, верна ли дата.');
      }
    },
  },
});
