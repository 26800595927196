const accs: StackSelection = {
  objectName: 'ИзменяемыеТаблицы.Переименование',
  description: '',
  fields: [
    {
      field: 'имя',
      text: 'Имя',
      type: 'String',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'String',
    },
    {
      field: 'размер',
      text: 'Размер',
      type: 'String',
    },
    {
      field: 'точность',
      text: 'Точность',
      type: 'Number',
      width: '30',
    },
    {
      field: 'масштаб',
      text: 'Масштаб',
      type: 'Number',
      width: '30',
    },

    {
      field: 'новое имя',
      text: 'Новое имя',
      type: 'String',
    },
    {
      field: 'новый тип',
      text: 'Новый Тип',
      type: 'String',
    },
    {
      field: 'новый размер',
      text: 'Новый  размер',
      type: 'String',
    },
    {
      field: 'новая точность',
      text: 'Новая точность',
      type: 'Number',
      width: '30',
    },
    {
      field: 'новый масштаб',
      text: 'Новый масштаб',
      type: 'Number',
      width: '30',
    },
  ],
};
export default accs;
