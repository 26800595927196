
import Vue from 'vue';
import JSON5 from 'json5';

export default Vue.extend({
  props: {
    title: { type: String, required: true },
    message: { type: String, required: true },
    stacktrace: { type: Object, default: undefined },
  },
  computed: {
    sstacktrace(): string {
      return JSON5.stringify(this.stacktrace, null, '\t');
    },
  },
});
