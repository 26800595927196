import { stackDate } from './stackDate';
import { store } from '../../store';

interface ParamRecord {
  разрядность?: number;
  дробнаяразрядность?: number;
  показание?: number;
  дата?: string;
  '@датпредсч'?: string;
  месяц?: string;
  месяцПред?: string;
  типввода?: number;
  пропуститьправило?: boolean;
  типсчетчика?: string; // 'индивидуальный', 'групповой'
  режим?: string; // 'создание' или 'редактирование'
  ограничениерасхода?: number;
  расход?: number;
  групповой?: number; // если групповой, то параметр есть
  '@типпредсч'?: number;
}
// TODO ?
function convertDate(date: string) {
  return stackDate.format(new Date(date), 'YYYY-MM-DD').valueOf();
}

export const rules = {
  isRequired: (value: string): string | true => (value !== undefined && value !== null && value !== '') || 'Поле не может быть пустым',

  isLinked: (value: number): string | true => (value !== undefined && value !== null && value > 0) || 'Связь должна быть установлена',
  email: (value: string): string | true => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !value || pattern.test(value) || 'Некорректный E-mail';
  },
  isGUID: (value: string): string | true => {
    const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return !value || pattern.test(value) || 'Некорректный ГУИД';
  },
  maxLength: (value: string, len: number, fieldName = 'Поле'): string | true => {
    return !value || value.toString().length <= len || `${fieldName} не может быть длиннее ${len} символов`;
  },

  singleMonth: (value: number): string | true => {
    return value === 0 || `Можно указывать даты только относящиеся к одному месяцу!`;
  },
  dateIsLessThan: (date1: string, date2: string, message = 'Дата конца меньше даты начала'): string | true => {
    return Date.parse(date1) > Date.parse(date2) || !date1 || !date2 || message;
  },
  dateIsGreaterThan: (date1: string, date2: string, message = 'Дата начала больше даты конца'): string | true => {
    return Date.parse(date1) < Date.parse(date2) || !date1 || !date2 || message;
  },
  dateIsLessEqualThan: (date1: string, date2: string, message = 'Дата конца меньше даты начала'): string | true => {
    return Date.parse(date1) >= Date.parse(date2) || !date1 || !date2 || message;
  },
  dateIsGreaterEqualThan: (date1: string, date2: string, message = 'Дата начала больше даты конца'): string | true => {
    return Date.parse(date1) <= Date.parse(date2) || !date1 || !date2 || message;
  },
  mobilePhone: (value: string): string | true => {
    const pattern = /^((8|\+7)[- ]?)(\d{3}[- ]?)(\d{3}[- ]?)(\d{2}[- ]?)[\d]{2}$/;
    return !value || pattern.test(value) || 'Некорректный номер мобильного телефона';
  },
  phone: (value: string): string | true => {
    const pattern = /^((8|\+7)[- ]?)?(\(?\d{3,5}\)?[- ]?)?[\d\- ]{4,8}[\d]{1}$/;
    return !value || pattern.test(value) || 'Некорректный номер телефона';
  },

  isSNILS: (value: string): string | true => {
    if (!value) {
      return true;
    }
    value = value.replace(/\D+/g, '').toString();
    const checkSum = parseInt(value.slice(9), 10);
    const checkedValue = value.split('');

    let sum = +checkedValue[0] * 9;
    sum += +checkedValue[1] * 8;
    sum += +checkedValue[2] * 7;
    sum += +checkedValue[3] * 6;
    sum += +checkedValue[4] * 5;
    sum += +checkedValue[5] * 4;
    sum += +checkedValue[6] * 3;
    sum += +checkedValue[7] * 2;
    sum += +checkedValue[8] * 1;

    if (sum < 100 && sum === checkSum) {
      return true;
    } else if ((sum === 100 || sum === 101) && checkSum === 0) {
      return true;
    } else if (sum > 101 && (sum % 101 === checkSum || (sum % 101 === 100 && checkSum === 0))) {
      return true;
    } else {
      return 'Некорректный СНИЛС';
    }
  },
  isCadastralNum: (value: string): string | true => {
    const pattern = /^[0-9A-zА-я:/-]*$/;
    return !value || pattern.test(value) || 'Некорректный кадастровый номер';
  },
  isORGN: (value: string): string | true => {
    const pattern = /(^[0-9]{13}$)|(^[0-9]{15}$)/;
    return !value || pattern.test(value) || 'Некорректный ОГРН';
  },
  isKPP: (value: string): string | true => {
    const pattern = /^[0-9]{9}$/;
    return !value || pattern.test(value) || 'Некорректный КПП';
  },
  isINN: (value: string | number): string | true => {
    if (!value) {
      return true;
    }
    value = value
      .toString()
      .replace(/\D+/g, '')
      .toString();
    const pattern = /(^[0-9]{10}$)|(^[0-9]{12}$)/;
    if (pattern.test(value)) {
      const checkedValue = value.split('');
      let sum = 0;
      let checkNumber1 = 0;
      let checkNumber2 = 0;

      if (value.length === 12) {
        sum = +checkedValue[0] * 3;
        sum += +checkedValue[1] * 7;
        sum += +checkedValue[2] * 2;
        sum += +checkedValue[3] * 4;
        sum += +checkedValue[4] * 10;
        sum += +checkedValue[5] * 3;
        sum += +checkedValue[6] * 5;
        sum += +checkedValue[7] * 9;
        sum += +checkedValue[8] * 4;
        sum += +checkedValue[9] * 6;
        sum += +checkedValue[10] * 8;
        checkNumber1 = sum % 11 === 10 ? 0 : sum % 11;

        sum = 0;
        sum = +checkedValue[0] * 7;
        sum += +checkedValue[1] * 2;
        sum += +checkedValue[2] * 4;
        sum += +checkedValue[3] * 10;
        sum += +checkedValue[4] * 3;
        sum += +checkedValue[5] * 5;
        sum += +checkedValue[6] * 9;
        sum += +checkedValue[7] * 4;
        sum += +checkedValue[8] * 6;
        sum += +checkedValue[9] * 8;
        checkNumber2 = sum % 11 === 10 ? 0 : sum % 11;

        if (parseInt(checkedValue[10], 10) === checkNumber2 && parseInt(checkedValue[11], 10) === checkNumber1) {
          return true;
        }
      } else if (value.length === 10) {
        const checkedValue = value.split('');

        let sum = 0;
        sum = +checkedValue[0] * 2;
        sum += +checkedValue[1] * 4;
        sum += +checkedValue[2] * 10;
        sum += +checkedValue[3] * 3;
        sum += +checkedValue[4] * 5;
        sum += +checkedValue[5] * 9;
        sum += +checkedValue[6] * 4;
        sum += +checkedValue[7] * 6;
        sum += +checkedValue[8] * 8;

        checkNumber1 = sum % 11 === 10 ? 0 : sum % 11;

        if (parseInt(checkedValue[9], 10) === checkNumber1) {
          return true;
        }
      }
    }
    return 'Некорректный ИНН';
  },
  isClosedMonth: (value: string): string | true => {
    return Date.parse(value) >= Date.parse(store.getters.getOpenMonth()) || `Месяц закрыт`;
  },
  // только буквы, пробел и тире
  onlyLetters: (value: string): string | true => {
    const pattern = /^[A-Za-zА-Яа-яёЁ-\s]*$/;
    return !value || pattern.test(value) || 'Недопустимые символы в поле';
  },
  // только русские
  onlyRussianLetters: (value: string): string | true => {
    const pattern = /^[А-Яа-яёЁ]*$/;
    return !value || pattern.test(value) || 'Недопустимые символы в поле';
  },
  // Запрет спецсимволов
  noSpecialSymbols: (value: string): string | true => {
    const pattern = /[~`!*|&#$%;<>?^@/\\]/;
    return !value || !pattern.test(value) || 'Недопустимые символы в поле';
  },
  addressProxy: (value: string): string | true => {
    const pattern = /^([1-9]\d?|[1]\d?\d?|2[0-4]\d|25[0-5])\.(([1-9]?\d?|[1]\d?\d?|2[0-4]\d|25[0-5])\.){2}([1-9]?\d?|[1]\d?\d?|2[0-4]\d|25[0-5])(:)([1-9]\d{1,4}$)/;
    return !value || pattern.test(value) || 'Некорректный адрес';
  },

  rulesPok: (params: ParamRecord): string | true => {
    let integer, factorial;
    const int = params.разрядность ? params.разрядность : 1;
    const fact = params.дробнаяразрядность ? params.дробнаяразрядность : 0;
    const val = params.показание ? params.показание : 0;
    // const group = params.групповой ? params.групповой : 0;
    if (+val < 0) {
      return 'Показание не может быть отрицательным';
    }
    // TODO
    // eslint-disable-next-line prefer-const
    [integer, factorial] = val.toString().split('.');
    if (integer && int && integer.length > int) {
      return 'Вы превысили разрядность счетчика!';
    }
    if (factorial && fact && factorial.length > fact) {
      return 'Вы превысили дробную разрядность счетчика!';
    }
    return true;
  },
  rulesRash: (params: ParamRecord): string | true => {
    const val = params.расход ? params.расход : 0;
    const valMax = params.ограничениерасхода ? params.ограничениерасхода : 0;
    if (+valMax > 0 && (+val > +valMax)) {
      return 'Расход превышает предельное значение';
    }
    return true;
  },
  rulesDate: (params: ParamRecord): string | true => {
    const tekDate = params.дата ? params.дата : '';
    const predDate = params['@датпредсч'] ? params['@датпредсч'] : '';
    const mode = params.режим ? params.режим.toLowerCase() : '';
    // Если последнее показание с типом коррекции - то убираем анализ на пересечение дат
    const lastTypePok = params['@типпредсч'] ? params['@типпредсч'] : 0;

    // убрал, т.к.в десктопе эта операция доступна
    // if (convertDate(store.getters.getOpenMonth()) > convertDate(tekDate) && mode !== 'редактирование') {
    //  return 'Дата текущего показания не может быть меньше открытого месяца';
    // }
    if (predDate && convertDate(predDate) > convertDate(tekDate)) {
      return 'Дата текущего показания не может быть меньше даты предыдущего показания';
    }
    if (predDate && convertDate(predDate) === convertDate(tekDate) && lastTypePok !== 7) {
      return 'У нового показания дата должна быть больше предыдущего';
    }
    return true;
  },
  rulesMonth: (params: ParamRecord): string | true => {
    const tekMonth = params.месяц ? params.месяц : '';
    const predMonth = params.месяцПред ? params.месяцПред : '';
    const tekDate = params.дата ? params.дата : '';
    const mode = params.режим ? params.режим.toLowerCase() : '';
    const counterType = params.типсчетчика ? params.типсчетчика.toLowerCase() : '';

    if (mode === 'редактирование' && counterType === 'групповой') {
      const firstDay = new Date(new Date(tekMonth).getFullYear(), new Date(tekMonth).getMonth(), 1).toString();
      const lastDay = new Date(new Date(tekMonth).getFullYear(), new Date(tekMonth).getMonth() + 1, 0).toString();
      if (convertDate(tekDate) > convertDate(lastDay) || convertDate(tekDate) < convertDate(firstDay)) {
        return 'Месяц должен соответствовать дате показания';
      }
    }
    if (convertDate(store.getters.getOpenMonth()) > convertDate(tekMonth) && mode !== 'редактирование') {
      return 'Месяц текущего показания не может быть меньше открытого месяца';
    }
    if (predMonth && convertDate(predMonth) > convertDate(tekMonth)) {
      return 'Месяц текущего показания не может быть меньше месяца предыдущего показания';
    }
    return true;
  },
  rulesDateEnd: (params: ParamRecord): string | true => {
    const datePosl = params['@датпредсч'] ? params['@датпредсч'] : '';
    const date = params.дата ? params.дата : '';
    if (convertDate(datePosl) > convertDate(date)) {
      return 'Дата снятия не может быть меньше даты последнего показания';
    }
    return true;
  },
  rulesType: (params: ParamRecord): string | true => {
    const type = params.типввода ? params.типввода : 1;
    const skipRule = params.пропуститьправило ? params.пропуститьправило : false;
    if (skipRule) {
      return true;
    } else if (type === 4 || type === 5 || type === 7) {
      return 'На этот тип показания менять нельзя';
    }
    return true;
  },
  checkDatePoverki: (date1: string, date2: string): string | true => {
    if (Date.parse(date1) === Date.parse(date2)) {
      return 'Дата след. поверки равна дате тек. поверки ';
    } else if (Date.parse(date1) < Date.parse(date2)) {
      return 'Дата след. поверки меньше даты тек. поверки ';
    } else {
      return true;
    }
  },
  notNullSum: (num: number): string | true => {
    return (num && num > 0) || 'Значение должно быть больше нуля';
  },
  fromZeroToHundred: (num: number): string | true => {
    return (num >= 0 && num <= 100) || 'Значение должно быть больше или равно нулю и меньше или равно 100';
  },
};
