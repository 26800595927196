
import Vue from 'vue';

export default Vue.extend({
  name: 'LsInfo',
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      http: new this.$HttpModel('ЛицевыеСчета.ОбщаяИнформация'),
      item: {} as StackTableRow,
    };
  },
  computed: {
    items(): any {
      return [
        { title: 'Состояние', value: this.item.состояниестрока },
        { title: 'Общая площадь', value: this.item['общая площадь'] },
        { title: 'Жилая площадь', value: this.item['жилая площадь'] },
        { title: 'Кол-во комнат', value: this.item['колво комнат'] },
        { title: 'Документ на собственность', value: this.item['документ на собственность'] },
        { title: 'Тип строения', value: this.item['тип строения'] },
      ];
    },
    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
  },
  methods: {
    async fetchData() {
      const items = await this.http.getRecords({ владелец: this.record.$номерЗаписи });
      if (items.length > 0) {
        this.item = items[0];
        if (items[0].типстрой) {
          this.$emit('getType', items[0].типстрой);
        }
        if (items[0].категория) {
          this.$emit('getCategory', items[0].категория);
        }
      }
    },
  },
  watch: {
    record: {
      immediate: true,
      handler(to, old) {
        if (to?.$номерЗаписи > 0 && to.$номерЗаписи !== old?.$номерЗаписи) {
          this.fetchData();
        }
      },
    },
    workMonth() {
      this.fetchData();
    },
  },
});
