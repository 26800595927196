
import Vue from 'vue';

export default Vue.extend({
  name: 'Login',
  computed: {
    orgFullName(): string {
      return this.$store.getters.getOrgFullName();
    },
    visible(): boolean {
      // прячем логин на разрешенных страницах
      return !this.$route.name || this.whitelist.indexOf(this.$route.name) === -1;
    },
    // isStackPushPresent(): boolean {
    //   return this.$store.getters['wsStore/isConnected']();
    // },
    checkPass(): string | true {
      return this.formLogin.newPassword !== this.formLogin.passwordRetry ? 'Пароли не совпадают' : !!this.formLogin.newPassword || 'Пароль не может быть пустым';
    },
  },
  data() {
    return {
      whitelist: ['about', 'plugin'],
      valid: true,
      loading: false,
      error: '',
      formLogin: {
        user: this.$store.getters.getLogin(),
        password: '',
        newPassword: '',
        passwordRetry: '',
      },
      ruleLogin: {
        user: [{ required: true, message: 'Введите логин', trigger: 'blur' }],
      },
      isPassVisible: false,
      isPassVisibleNew: false,
      isPassVisibleRet: false,
      changePassError: false,
    };
  },
  methods: {
    async tokenLogin(token: string) {
      try {
        await this.$store.dispatch('SIGN_IN', { token });
      } catch (error: AnyException) {
        this.error = error.message;
      }
      return false;
    },
    async plainLogin(userID: string, secret: string, forceLogin = false, newPassword: string | undefined = undefined) {
      try {
        await this.$store.dispatch('SIGN_IN', { login: userID, password: secret, force: forceLogin, newPassword });
        return true;
      } catch (error: AnyException) {
        const errCode = error.response?.status || error.code;
        if (errCode === 409 && !forceLogin) {
          if (
            await this.$yesno(`Пользователь с таким именем уже вошел в систему.\n
              Если продолжить вход, то работа уже вошедшего пользователя будет прервана.\n
              Продолжить вход?`)
          ) {
            await this.plainLogin(userID, secret, true);
          }
        } else if (errCode === 401) {
          this.error = error.response?.status ? error.response?.data : error.message;
        } else if (errCode === 418) {
          this.error = error.message;
          this.changePassError = true;
        } else {
          // this.error = error.message;
          this.error = 'Подключиться не удалось';
        }
      }
      return false;
    },

    async login(userID: string, secret: string, forceLogin = false) {
      this.loading = true;
      this.error = '';
      // @ts-ignore
      if (this.$refs.form.validate()) {
        await this.plainLogin(userID, secret, forceLogin);
      }
      this.loading = false;
    },
    async changePassword() {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        await this.plainLogin(this.formLogin.user, this.formLogin.password, true, this.formLogin.newPassword);
      }
    },

  },
});
