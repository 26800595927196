import Vue from 'vue';
import Scrollbar from 'smooth-scrollbar';
/**
  Красивый скролл
 */
export default Vue.extend({
  data() {
    return {
      ps: undefined as any,
    };
  },
  mounted() {
    setTimeout(() => {
      // @ts-ignore
      const container: HTMLElement = this.$parent.$el.querySelector('.ps-container') as HTMLElement;
      if (container) {
        Scrollbar.init(container, { alwaysShowTracks: true });
      }
    }, 0);
  },
  beforeDestroy() {
    this.ps && this.ps.destroy();
  },
});
