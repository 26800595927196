
import Vue from 'vue';

export default Vue.extend({
  name: 'NewCampaign',
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    avtor(): string {
      return this.$store.getters.getUserName();
    },
    transport(): string {
      switch (this.record.транспорт) {
        case 0:
          return 'Автообзвон';
        case 1:
          return 'СМС';
        case 2:
          return 'Push';
        case 3:
          return 'E-mail';
        case 4:
          return 'ЛК';
        case 5:
          return 'ЛК ЮЛ';
      }
      return 'Автообзвон';
    },
  },
  methods: {
    changeCampaign(payload: any) {
      if (payload && payload[0]) {
        this.record.транспорт = payload[0].транспорт;
      }
    },
  },
});
