
import Vue from 'vue';
import folder from '@/tasks/common/views/$common/documentFolder.vue';
import GroupCountersToolbar from './$groupcounters/GroupCountersToolbar.vue';
import MonthSelectDialog from './$dialogs/$monthSelectDialog/MonthSelectDialog.vue';
import GroupCountFilter from '@/tasks/fl/views/$common/filters/GroupCountFilter.vue';

export default Vue.extend({
  components: { GroupCountFilter, GroupCountersToolbar, folder, MonthSelectDialog },
  data() {
    return {
      monthSelectDialogVis: false,
      httpModel: new this.$HttpModel('ГрупповыеСчетчики'),
      selectedRows: [] as StackTableRow[], // выделенные записи в таблице
      jobID: null as null | string,
    };
  },
  computed: {
    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
  },
  methods: {
    async calcODPU(month: any) {
      this.monthSelectDialogVis = false;

      const items = [] as number[];
      let jobNameMultiple = '';
      let jobNameSingle = '';
      let jobNameResult = '';

      if (month && this.selectedRows && this.selectedRows.length > 0) {
        this.selectedRows.forEach((item: StackTableRow) => {
          if (item.$номерЗаписи && item.$номерЗаписи > 0) {
            items.push(item.$номерЗаписи as number);

            if (items.length === 1) {
              jobNameSingle = ' №' + item.номер + (item.примечание ? ' ' + item.примечание : '');
            }
            jobNameMultiple += ', №' + item.номер;
          }
        });

        jobNameResult =
          'Деление' +
          (items.length === 1 ? ' группового счетчика' + jobNameSingle : ' групповых счетчиков' + jobNameMultiple.substr(1)) +
          ' за ' +
          this.$stackDate.format(month as string, 'MM-YYYY');

        const obj = this.httpModel;
        if (items && items.length > 0) {
          try {
            this.jobID = (
              await obj.executeMethod(
                'Рассчитать',
                {
                  массивГС: items,
                  месяцРасчета: month,
                },
                {
                  async: true,
                  jobName: jobNameResult,
                  type: 'func',
                },
              )
            ).asyncId;
          } catch (error: AnyException) {
            this.$toast('Ошибка деления', { color: 'error' });
          }
        }
      }
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.jobID = '';
        // @ts-ignore
        this.$refs.table.fetchData();
      }
    },
    isError(to) {
      if (to) {
        this.jobID = '';
      }
    },
  },
});
