
// TODO Не копипастить !

import Vue from 'vue';
import CardRegSubDialog from './StackCardRegLinkFieldDialog.vue';

export default Vue.extend({
  name: 'StackCardRegLinkField',
  components: { CardRegSubDialog },
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: [String, Number], default: undefined },
    labelField: {
      type: [Function, String],
      default: 'фио',
    },
    /**
     * объект с фильтром по-умолчанию
     */
    filter: { type: [Object], default: null },
    headers: { type: String, default: 'фио,дата прописки,дата выписки' },
    dataModel: { type: String, default: 'ЛицевыеСчета.КарточкиРегистрации' },
    label: { type: String, default: 'Жилец' },
    linkField: { type: String, default: 'фио' },
    ownerID: { type: [String, Number], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
    };
  },
  methods: {
    // событие на чекбокс
    onSelect(selectedRows: StackTableRow[]) {
      this.record = {};
      if (selectedRows && selectedRows.length > 0) {
        this.record = selectedRows[0];
      }
    },
    onClose(props: any) {
      props.onClose();
    },
    onSave(props: any) {
      if (this.record && this.record.$номерЗаписи && this.record.$номерЗаписи > 0) {
        props.onSave([this.record]);
      }
    },
  },
});
