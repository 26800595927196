const orgsak: StackSelection = {
  objectName: 'Организации.АккаунтыОрганизацииЛК',
  description: 'Аккаунты организации ЛК',
  fields: [
    {
      field: 'логин',
      text: 'Логин',
      type: 'String',
    },
    {
      field: 'e-mail',
      text: 'E-Mail',
      type: 'String',
    },
    {
      field: 'телефон',
      text: 'Телефон',
      type: 'String',
    },
    {
      field: 'датасоздания',
      text: 'Создан',
      type: 'Date',
    },
    {
      field: 'датаобновления',
      text: 'Обновлен',
      type: 'Date',
    },
    {
      field: 'состояние',
      text: 'Cостояние',
      type: 'Number',
    },
    {
      field: '@статус',
      text: 'Статус',
      type: 'String',
    },
    {
      field: 'доступ',
      text: 'Доступ',
      type: 'String',
    },
    {
      field: '@доступ',
      text: '@Доступ',
      type: 'String',
    },
    {
      field: 'пароль-организация',
      text: 'пароль-организация',
      type: 'Link',
    },
  ],
};

export default orgsak;
