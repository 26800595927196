const accs: StackSelection = {
  objectName: 'Счетчики.Показания',
  description: '',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчетный месяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'показание',
      text: 'Показание',
      type: 'NumberZero',
    },
    {
      field: 'расход',
      text: 'Расход',
      type: 'NumberZero',
    },
    {
      field: 'итоговый расход',
      text: 'Итоговый расход',
      type: 'NumberZero',
    },
    {
      field: 'дополнительный расход',
      text: 'Доп. расход',
      type: 'Number',
    },
    {
      field: 'тариф',
      text: 'Зона суток',
      type: 'String',
    },
    {
      field: 'типввода',
      text: 'Тип ввода',
      type: 'Number',
    },
    {
      field: '@тип ввода',
      text: 'Тип ввода',
      type: 'Number',
      computed: true,
    },
    {
      field: '@зона',
      text: 'Зона суток',
      type: 'String',
      width: '60px',
      computed: true,
    },
  ],
};
export default accs;
