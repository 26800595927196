
import Vue from 'vue';
import datnachDatend from './$datNachdatEnd.vue';

export default Vue.extend({
  components: { datnachDatend },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    hideDate: { type: Boolean, default: false },
  },
  data() {
    return {
      list: [] as any[],
    };
  },
  created() {
    // TODO в кампутед
    const s: string = this.record.примечание;
    let data = [] as any;
    if (s) {
      data = s.split('#');
    }

    let val = '';
    for (const i in data) {
      if (data[i]) {
        val = data[i];
      } else {
        val = '';
      }
      this.list.push(val);
    }
  },
  methods: {
    onChange() {
      this.record.примечание = '';

      let val = 0;
      for (let i = 0; i < 2; i++) {
        if (this.list[i]) {
          val = this.list[i];
        } else {
          val = 0;
        }
        this.record.примечание += val + '#';
      }
    },
  },
});
