
import Vue from 'vue';
import { TableHandlers } from '@/stackEngine';
import ProviderDialog from './_providerLsDialog.vue';

/**
 * Поставщик лицевого
 */
export default Vue.extend({
  components: { ProviderDialog },
  props: {
    dataModel: { type: String, required: true },
    ownerID: { type: Number, default: null },
    headers: { type: String, default: 'номер услуги,наименование,поставщики-список>название,датнач,даткнц' },
    onlyServiceProvider: { type: Boolean, default: false },
    history: { type: Boolean, default: true },
    hideDate: { type: Boolean, default: false },
  },
  computed: {
    showHistoryByDefault(): boolean {
      return this.$store.getters['flStore/getShowHistory']();
    },
  },
  data() {
    return {
      title: 'Поставщик',
      newParamDialogVisible: false,
      currentNewParam: {} as StackTableRow,
    };
  },
  methods: {
    providerHandler(item: any) {
      if (+item['счет-список поставщиков'] > 0 && +this.ownerID !== +item['счет-список поставщиков']) {
        item.$data.наименование.$иконка = '$vuetify.icons.hier';
        item.$data.наименование.$иконка_цвет = 'grey';
        TableHandlers.trColor(item, 'grey');
      }
      return item;
    },
  },
});
