const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.Аккаунты',
  description: '',
  fields: [
    {
      field: 'датасоздания',
      text: 'Дата создания',
      type: 'Date',
    },
    {
      field: 'датаобновления',
      text: 'Дата обновления',
      type: 'Date',
    },
    {
      field: 'логин',
      text: 'Аккаунт',
      type: 'String',
    },
    {
      field: 'e-mail',
      text: 'E-Mail',
      type: 'String',
    },
    {
      field: 'телефон',
      text: 'Телефон',
      type: 'String',
    },
    {
      field: 'телефон',
      text: 'Телефон',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: 'стрсостояние',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'привязка-пароль',
      text: 'Привязка-Пароль',
      type: 'Link',
    },
    {
      field: 'пароль',
      text: 'пароль',
      type: 'String',
    },
  ],
};
export default accs;
