
import Vue from 'vue';

export default Vue.extend({
  props: {
    rulesid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
    };
  },
  computed: {
    title(): string {
      return `Правило операции`;
    },
  },
});
