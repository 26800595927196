
import Vue from 'vue';
import AdrFilter from '@/tasks/fl/views/$common/filters/AdrFilter.vue';
import AddressObjectFilter from '@/tasks/commun/views/accounts/filters/AddressObjectsFilter.vue';
import AccountFilter from '@/tasks/ul/views/$common/filters/AccountFilter.vue';
import AdrSelectDialog from './AdrSelectDialog.vue';

export default Vue.extend({
  name: 'StackAdrLinkField',
  components: { AdrFilter, AccountFilter, AdrSelectDialog, AddressObjectFilter },
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: [String, Number], default: undefined },
    labelField: {
      type: [Function, String],
      default: 'адрес',
    },
    headers: { type: String, default: 'номер,счет-наниматель>фио,примечание' },
    dataModel: { type: [String, Object], default: 'ЛицевыеСчета' },
    label: { type: String, default: 'Адрес' },
    linkField: { type: String, default: 'номер' },
    multi: { type: Boolean, default: false },
    extendSelect: { type: Boolean, default: false },
    /**
     * Не выводить иконку открытия записи в соседней вкладке
     */
    noOpenLink: { type: Boolean, default: false },
  },
  data() {
    return {
      lsSelected: [] as StackTableRow[],
      regLsSelected: [] as StackTableRow[],
      mode: 0,
    };
  },
  inject: {
    selectionName: { default: undefined },
  },
  computed: {
    calcDataModel(): string {
      // @ts-ignore
      return this.selectionName || this.dataModel;
    },
    lsTextValue(): string {
      return this.lsSelected.map((item) => item.$номерЗаписи).join(',');
    },
    regLsTextValue(): string {
      return this.regLsSelected.map((item) => item.$номерЗаписи).join(',');
    },
  },
  methods: {
    async onSave(props: any) {
      const regLs = this.regLsTextValue && (await new this.$HttpModel('РеестрыЛицевых').executeMethod('ПолучитьЛицевые', { лицевые: this.regLsTextValue }));
      const result = regLs && this.lsTextValue ? regLs + ',' + this.lsTextValue : this.lsTextValue || regLs;
      this.$emit('change', result);
      props.onClose();
    },
    clear() {
      this.lsSelected = [];
      this.regLsSelected = [];
    },
    onSelect(model: string, items: StackTableRow[]) {
      switch (model) {
        case 'ЛицевыеСчета':
          this.lsSelected = items;
          break;
        case 'РеестрыЛицевых':
          this.regLsSelected = items;
          break;
      }
    },
    focus() {
      // @ts-ignore
      if (this.$refs.linkField && this.$refs.linkField.focus) {
        // @ts-ignore
        this.$refs.linkField.focus();
      }
    },
  },
});
