
import Vue from 'vue';

export default Vue.extend({
  props: {
    residentslgot: { type: [Number, String], default: undefined },
    accountsid: { type: [Number, String], default: undefined },
  },
  computed: {
    title(): string {
      return `Льготник` + this.titleAcc;
    },
    titleAcc(): string {
      return this.record.номерлс ? ' ( Лицевой счет № ' + this.record.номерлс + ' )' : '';
    },
    disableDial(): boolean {
      return !!this.record['недействующая льгота'];
    },
    btnTitle(): string {
      return this.record['недействующая льгота'] ? 'Восстановить льготу' : 'Снять льготу';
    },
  },
  data() {
    return {
      record: {} as StackTableRow,
    };
  },
  methods: {
    fetchResident(to: any) {
      if (to[0]) {
        this.record['человек-льгота>дата рождения'] = to[0]['дата рождения'] ? to[0]['дата рождения'] : this.record['человек-льгота>дата рождения'];
        this.record['человек-льгота>пенсионное свидетельство'] = to[0]['пенсионное свидетельство']
          ? to[0]['пенсионное свидетельство']
          : this.record['человек-льгота>пенсионное свидетельство'];
      }
    },
    async closePrivelege() {
      let answer = true;
      if (!this.record['недействующая льгота']) {
        answer = await this.$yesno('Сделать льготу недействующей?');
      }
      if (answer) {
        await new this.$HttpModel('ЛицевыеСчета.Льготники').executeMethod('СнятьЛьготу', { номерЗаписи: this.record.$номерЗаписи });
        // @ts-ignore
        this.$refs.dialog.fetchData();
      }
    },
  },
});
