
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      items: [
        { title: 'Адреса проживающих', img: 'accounts', to: '/accounts' },
        { title: 'Отчеты', img: 'reports', to: '/reports' },
        { title: 'Центр информирования', img: 'ci_doc_center_info', to: { name: 'infocenter' } },
        { title: 'Платежные ведомости', img: 'plat_vedom', to: '/payments/paylists' },
        { title: 'Окно оператора', img: 'window_operator', to: { name: 'operations-windowoperator' } },
      ],
      items2: [
        { title: 'Ввод показаний ИПУ', img: 'master_vvod_pok_ipu', to: { name: 'metersdata-readings-massinput' }, color: 'stack' },
        { title: 'Ввод показаний ОДПУ', img: 'master_vvod_pok_odpu', to: { name: 'metersdata-readings-massinputgroup' }, color: 'stack' },
      ],
    };
  },
  mounted() {
    this.$store.commit('SET_BROWSER_TITLE', 'Главная');
  },
});
