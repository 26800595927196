const ccc: StackSelection = {
  objectName: 'АктыОбходаСостав',
  description: 'Состав акта',
  fields: [
    {
      field: 'документ',
      text: 'Документ',
      type: 'Link',
    },
    {
      field: 'датнач',
      text: 'Дата начала',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Дата окончания',
      type: 'Date',
    },
    {
      field: 'операция',
      text: 'Операция',
      type: 'Enum',
    },
    {
      field: 'информация',
      text: 'Информация',
      type: 'String',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Enum',
    },
    {
      field: '@тип',
      text: 'Тип ',
      type: 'String',
    },
    {
      field: '@операция',
      text: 'Операция ',
      type: 'String',
    },
    {
      field: '@информация',
      text: 'Информация',
      type: 'String',
    },
    {
      field: 'актзамены',
      text: 'Акт замены',
      type: 'Link',
    },
    {
      field: '@типпу',
      text: 'Тип ПУ ',
      type: 'String',
    },
    {
      field: '@допданные',
      text: 'Доп данные',
      type: 'String',
    },
  ],
};
export default ccc;
