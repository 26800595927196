
import Vue from 'vue';
import stStep from './step.vue';

export default Vue.extend({
  components: { stStep },
  props: {
    isComplete: { type: Boolean, default: false },
    routeParams: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      filter: {
        НомерЗаписиУслуги: null,
        НомерЗаписиКатегории: null,
        НомерЗаписиПоставщика: null,
        НомерЗаписиКатегорииПост: null,
        НомерЗаписиУК: null,
        НомерЗаписиУКДоговор: null,
        ВариантСписания: 0,
        СуммаДолга: 5,
      },
    };
  },
  methods: {
    async onFilterRecords() {
      try {
        this.loading = true;
        await new this.$HttpModel('ЛицевыеСчета.БлокировкаПерерасчетаПени').executeMethod(
          'ОтобратьМассово',
          { Параметры: { ...this.filter, лс: this.routeParams.fund, месяц: this.routeParams.month } },
          {
            async: true,
            jobName: 'Отбор записей',
            onComplete: (stackMsg) => {
              const result = stackMsg.data.result;
              const countLs = result.Колво ? result.Колво : 0;
              const sumWriteOff = result.Сумма ? result.Сумма.toFixed(2) : 0;
              const docNumber = result.НомерВедомости ? result.НомерВедомости : 0;
              if (countLs > 0 && sumWriteOff > 0) {
                const query = { ...this.$route.query };
                query.countLs = countLs;
                query.sumWriteOff = sumWriteOff;
                query.optionWriteOff = this.filter.ВариантСписания.toString();
                query.docNumber = docNumber.toString();
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                this.$router.push({ query }).catch((e) => {});
                this.loading = false;
                this.$emit('next');
              } else {
                this.$toast('Не удалось отобрать записи', { color: 'error' });
              }
            },
            onError: () => {
              this.loading = false;
            },
          },
        );
      } catch (error: AnyException) {
        this.loading = false;
        this.$toast('Ошибка отбора', { color: 'error' });
      }
    },
  },
});
