
import Vue from 'vue';
import FilterContract from './AccountsFilterContract.vue';
import FilterPoint from './CommonFilterPoint.vue';
import FilterCounter from './AccountsFilterCounter.vue';
import FilterAnalit from './AccountsFilterAnalit.vue';

export default Vue.extend({
  components: {
    'filter-contract': FilterContract,
    'filter-point': FilterPoint,
    'filter-counter': FilterCounter,
    'filter-analit': FilterAnalit,
  },
  data() {
    return {
      params: {} as any,
    };
  },
});
