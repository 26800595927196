// Иконки Common
export default {
  reports: require(`@/assets/svg/reports.svg`), // Отчеты

  spr_posts: require(`@/assets/svg/spr_dolzhnosti.svg`), // Должности

  accounts: require(`@/assets/svg/contract_ctrl.svg`), // Адреса проживающих
  // операции
  action_exports: require(`@/assets/svg/data_export.svg`), // Выгрузки в файлы
  action_imports: require(`@/assets/svg/data_import.svg`), // Подкачки из файлов

  // Переключение программ
  task_fl: require(`@/assets/svg/tasks/fl.svg`), // Расчеты с абонентами - физическими лицами
  task_dlg_fl: require(`@/assets/svg/tasks/dlg_fl.svg`), // Работа с должниками - физическими лицами
  task_passport: require(`@/assets/svg/tasks/passport.svg`), // Паспортный стол
  task_ul: require(`@/assets/svg/tasks/ul.svg`), // Расчеты с абонентами - юридическими лицами
  task_dlg_ul: require(`@/assets/svg/tasks/dlg_ul.svg`), // Работа с должниками - юридическими лицами
  task_commun: require(`@/assets/svg/tasks/commun.svg`), // Коммуникации
  task_kassa: require(`@/assets/svg/tasks/kassa.svg`), // Касса
  task_integration: require(`@/assets/svg/tasks/integration.svg`), // Интеграция
  task_dwh: require(`@/assets/svg/tasks/dwh.svg`), // Хранилище
  task_rsp: require(`@/assets/svg/tasks/rsp.svg`), // Расчеты с поставщиками
  task_pdu: require(`@/assets/svg/tasks/pdu.svg`), // ПДУ
  task_avar: require(`@/assets/svg/tasks/avar.svg`), // Аварийно-диспетчерская служба
  task_post: require(`@/assets/svg/tasks/post.svg`), // АРМ поставщика
  task_post_ur: require(`@/assets/svg/tasks/post_ur.svg`), // АРМ юриста поставщика
  task_lead: require(`@/assets/svg/tasks/lead.svg`), // АРМ Руководителя
  task_contactcenter: require(`@/assets/svg/tasks/contactcenter.svg`), // Контакт-центр
  task_quality: require(`@/assets/svg/tasks/quality.svg`), // Оценка качества
  task_asd: require(`@/assets/svg/tasks/asd.svg`), // Автоматизированный сбор данных

  license_alert: require(`@/assets/svg/license_alert.svg`), // Иконка в окне регистрации
  stack_logo: require(`@/assets/svg/stack_logo.svg`), // логотип Стек
  stack_logo_full: require(`@/assets/svg/stack_logo_full.svg`), // логотип Стек c текстом
  stack_logo_loading: require(`@/assets/svg/stack_logo_loading.svg`), // логотип Стек в оверлеях загрузки (закруленный)

  // Служебные операции
  srv_connectinfo: require(`@/assets/svg/operatsii.svg`), // Данные подключения
  srv_execfunc: require(`@/assets/svg/operatsii.svg`), // Выполнить функцию
  srv_debugserver: require(`@/assets/svg/adm_konfiguratsiya_sistemy.svg`), // Отладчик
  srv_requesttest: require(`@/assets/svg/sluzhebnyye_operatsii.svg`), // Тестирование запросов
  srv_change_backend_url: require(`@/assets/svg/sluzhebnyye_operatsii.svg`), // Сменить URL Backend
  srv_blockrasch: require(`@/assets/svg/smena_blokirovki_rascheta.svg`), // Смена блокировки расчета
  srv_saldo_closed_month: require(`@/assets/svg/raschet_saldo_v_zakrytykh_mesyatsakh.svg`), // Расчет сальдо в закрытом месяце

  // социальные сети
  social_facebook: require(`@/assets/svg/social/social_facebook.svg`),
  social_email: require(`@/assets/svg/social/social_email.svg`),
  social_instagram: require(`@/assets/svg/social/social_instagram.svg`),
  social_skype: require(`@/assets/svg/social/social_skype.svg`),
  social_telegram: require(`@/assets/svg/social/social_telegram.svg`),
  social_vkontakte: require(`@/assets/svg/social/social_vkontakte.svg`),
  social_youtube: require(`@/assets/svg/social/social_youtube.svg`),
} as ImagePack;
