const uploads: StackSelection = {
  objectName: 'ОперацииСлужебные',
  description: '',
  fields: [
    {
      field: 'title',
      text: 'title',
      type: 'String',
    },
    {
      field: 'dialog',
      text: 'dialog',
      type: 'String',
    },
    {
      field: 'месяц',
      text: 'месяц',
      type: 'Date',
    },
    {
      field: 'лицевые',
      text: 'лицевые',
      type: 'String',
    },
    {
      field: 'дата',
      text: 'дата',
      type: 'Date',
    },
    {
      field: 'датнач',
      text: 'датнач',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'даткнц',
      type: 'Date',
    },
    {
      field: 'год',
      text: 'год',
      type: 'Date',
    },
    {
      field: 'значениечисло',
      text: 'значениечисло',
      type: 'Number',
    },
    {
      field: 'значениестрока',
      text: 'значениестрока',
      type: 'String',
    },
  ],
};
export default uploads;
