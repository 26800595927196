
import Vue from 'vue';
import StackReadingsHeader from './StackReadingsHeader.vue';
import StackReadingsRow from './StackReadingsRow.vue';
import dialogErrors from '@/stackEngine/components/StackReadingsTable/dialogErrors.vue';
import { ReportsBuilder } from '@/stackEngine';

interface FilterRecords {
  название?: string;
  флаг?: number;
  услуга?: number;
  вид?: number;
  дата?: string;
  владелец?: number | null;
  ведомость?: number | string;
  месяц?: string;
}
interface DataRecords {
  месяц?: string;
  дата?: string;
  типввода?: number;
}
interface StackTableRows {
  [index: number]: StackTableRow;
}
interface checkTarRow {
  колвоПоказаний: number;
  тарифность: number;
  массивЗаписей: number[];
}

interface checkTarRows {
  [index: number]: checkTarRow;
}
type HookReleaseFunction = () => void;
/**
 * Компонент для внесения показаний счетчиков
 */
export default Vue.extend({
  name: 'StackMetersData',
  components: { StackReadingsRow, dialogErrors, StackReadingsHeader },
  props: {
    /**
     * темная тема
     */
    dark: { type: Boolean, default: true },
    /**
     * выборка
     */
    dataModel: { type: [String, Object], default: null },
    /**
     * отображение кнопки сохранения показания
     */
    showButton: { type: [Boolean], default: false },
    /**
     * название таблицы
     */
    description: { type: String, default: 'Ввод показаний' },
    /**
     * свой метод сохранения показаний
     */
    method: { type: String, default: 'ВнестиПоказания' },
    /**
     * не использовать пагинацию
     */
    noPagination: { type: Boolean, default: false },
    /**
     * отображать фильтр
     */
    filter: { type: Boolean, default: false },
    /**
     * внешний фильтр
     */
    inputFilter: { type: Object, default: null },
    /**
     * предустановленные данные
     */
    inputData: { type: Object, default: null },
    /**
     * отображать тулбар
     */
    noToolbar: { type: Boolean, default: false },
    /**
     * отключить предварительную инициализацию
     */
    noInit: { type: Boolean, default: false },
    /**
     * отображать дату в таблице
     */
    noShowDate: { type: Boolean, default: false },
    /**
     * создавать ведомость поазаний
     */
    createList: { type: Boolean, default: true },
    /**
     * не перенабирать выборку после сохранения показаний (для платежей)
     */
    noFetchAfterSend: { type: Boolean, default: false },
    /**
     * флаг ввода с лицевого счета
     */
    isLS: { type: Boolean, default: false },
    /**
     * отображать колонку с услугой в таблице
     */
    showService: { type: Boolean, default: false },
    /**
     * отображать итоговый расход за месяц в таблице
     */
    showTotal: { type: Boolean, default: false },
    /**
     * отображать колонку с признаком субабонента
     */
    showSub: { type: Boolean, default: false },
    /**
     * отображать колонку с номером ТУ
     */
    showTu: { type: Boolean, default: false },
    /**
     * отображать колонку с состоянием
     */
    showState: { type: Boolean, default: false },
    /**
     * отображать колонку с датой следующей поверки
     */
    showDateCheck: { type: Boolean, default: false },
    /**
     * отображать дату установки счетчика
     */
    showDateInst: { type: Boolean, default: false },
    /**
     * не отображать колонки с доп.итогами
     */
    noShowDopRas: { type: Boolean, default: false },
    /**
     * не отображать адрес в названии счетчика
     */
    noShowAddress: { type: Boolean, default: false },
    /**
     * не отображать номер ЛС
     */
    noShowNumberLs: { type: Boolean, default: false },
    /**
     * не отображать колонку с зоной
     */
    noShowZone: { type: Boolean, default: false },
    /**
     * не отображать закрытие окна
     */
    noClose: { type: Boolean, default: false },
    /**
     * не отображать закрытие окна
     */
    history: { type: Boolean, default: false },
    /**
     * высота таблицы
     */
    height: { type: [String, Number], default: undefined },
    /**
     * создание ведомости без примечания массового ввода
     */
    notMass: { type: Boolean, default: false },
    /**
     * на таблице есть владелец
     */
    haveOwner: { type: Boolean, default: false },
    /**
     * после внесений показаний делать ли перерасчет лс
     */
    needRecalcLs: { type: Boolean, default: false },
    /**
     * необходимость фильтра на инициализации
     */
    noFocus: { type: Boolean, default: false },
    /**
     * Проверить показания на тарифность счетчика перед вставкой
     */
    checkReadingsOnTariff: { type: Boolean, default: false },
    /**
     * объект с кастомными параметрами в запрос
     */
    params: { type: [Object], default: null },
    beforeFetch: { type: Function, default: () => new Promise(resolve => resolve(true)) },
  },
  inject: ['isDialogChild'],
  data() {
    return {
      readings: [] as any, // текущие записи
      readingsChanges: [] as any, // записи для сохранения
      dataObject: {} as DataModel,
      loading: false, // загрузка таблицы
      page: 1, // текущая страница
      rowsPerPage: 0, // строк на странице
      filterData: {} as FilterRecords,
      inputDataRecords: {} as DataRecords,
      routeHook: null as null | HookReleaseFunction, // хук на смену роута
      ownerID: null as null | number | string, // владелец выборки, приходит в filterData
      panel: [true], // expansion panel
      unwatchHandler: undefined as any, // watch на queryParams
      objectErrors: {} as StackTableRows, // объект некорректных записей и полей
      vedID: null as null | number | string, // row_id ведомости показаний
      filterValid: true,
      isVisible: false,
      placeInst: [] as string[],
      dataError: [] as StackTableRow[], // массив ошибок показаний
      isDialogErrors: false,
      jobID: null as null | string | StackTableRow[],
      isComplete: false,
      isError: false,
      step: 50,
      width: 0,
      start: 0,
      rowHeight: 40,
      perPage: 50,
      btnhistory: false,
      jobStarting: false,
    };
  },
  computed: {
    readingsLimited(): StackTableRow[] {
      return this.readings.slice(0, this.perPage + this.start);
    },
    openMonth(): Date {
      return this.$store.getters.getOpenMonth();
    },
    // конструктор параметров, передаваемых на back-end
    queryParams(): StackHttpRequestTaskParam {
      let q: StackHttpRequestTaskParam = {};
      q.размерСтраницы = this.rowsPerPage;
      q.номерСтраницы = this.page ? this.page : 1;
      q.страницаЗаписи = 0;
      if (this.haveOwner) {
        q.владелец = this.ownerID ? +this.ownerID : undefined;
      } else {
        q.лицевые = this.ownerID ? this.ownerID : undefined;
      }
      q.фильтр = Object.assign({ последние: true, действующие: true, история: this.btnhistory }, this.filterData);
      if (this.params) {
        q = Object.assign({}, q, this.params);
      }
      return q;
    },
    // сообщение в пустой таблице
    emptyMsg(): string {
      return this.loading ? 'Загрузка' : 'Пусто';
    },
    disabledBtnSave(): boolean {
      if (this.valid && !this.inputprogress && !this.jobStarting) {
        return false;
      }
      return true;
    },
    valid(): boolean {
      return this.readingsChanges.length > 0 && Object.keys(this.objectErrors).length === 0 && this.filterValid;
    },
    inputprogress(): boolean {
      return this.jobID !== null || this.readings.some((row: StackTableRow) => row.isBusy);
    },
    // всего записей выборки
    // totalItems(): number {
    //   return this.dataObject.length;
    // },
    calcHeight(): string | number {
      if (this.height !== '100%') {
        return this.height;
      }
      if (this.isVisible) {
        const sel = this.$el.querySelector('.v-data-table__wrapper');
        if (sel) {
          sel.id = 'scroller';
          const rect = sel.getBoundingClientRect();
          const top = rect.top;
          return `calc(100vh - ${top}px)`;
        }
      }
      return this.height;
    },
  },
  async created() {
    // @ts-ignore не регистрируем роут хук если являемся потомком диалога
    if (!this.isDialogChild) {
      this.routeHook = this.$router.beforeEach(this.checkChangedOnLeave) as HookReleaseFunction;
    }
    this.unwatchHandler = this.noInit ? this.$watch('queryParams', this.fetchData) : this.$watch('queryParams', this.fetchData, { immediate: true });
    await this.preInstall();
  },
  mounted() {
    this.width = this.$el ? this.$el.clientWidth : 0;
  },
  beforeDestroy() {
    if (this.routeHook) {
      this.routeHook();
    }
    this.unwatchHandler();
  },
  methods: {
    // кнопка истории
    onBtnHistory() {
      this.btnhistory = !this.btnhistory;
    },
    onScroll(e: any) {
      const { scrollTop } = e.target;
      if (scrollTop + e.target.clientHeight + this.rowHeight >= e.target.scrollHeight) {
        if (this.start + this.perPage < this.readings.length) {
          this.start += this.step;
        }
      }
    },
    async preInstall() {
      const response = await new this.$HttpModel('Счетчики.МестаУстановки').getRecords();
      for (const item of response) {
        if (item.значение !== null && item.значение !== undefined) {
          this.placeInst.push(item.значение as string);
        }
      }
    },
    // onIntersect(entries: any) {
    //   this.isVisible = entries[0].isIntersecting || this.isVisible;
    // },
    async checkChangedOnLeave(to: any, from: any, next: any) {
      if (!this.disabledBtnSave && from.path !== to.path) {
        const answer = await this.$yesno('Данные не сохранены. Вы уверены ?');
        if (!answer) {
          next(false);
          return;
        }
      }
      next();
    },
    async fetchData() {
      if (this.dataObject.getRecords) {
        this.readings = [];
        this.loading = true;
        try {
          // @ts-ignore
          await this.beforeFetch(this.queryParams);
          this.readings = await this.dataObject.getRecords(this.queryParams);
          if (!this.noFocus) {
            this.$nextTick(() => {
              const input: HTMLElement | null = this.$el.querySelector('#показание input');
              // eslint-disable-next-line no-unused-expressions
              input?.focus();
            });
          }
        } finally {
          this.loading = false;
          this.start = 0;
        }
      }
      this.$emit('init', this.readings);
    },
    // меняем кол-во записей на странице
    onChangePerPage(perPage: number) {
      this.rowsPerPage = perPage;
    },
    // меняем страницу
    onChangePage(page: number) {
      if (this.page !== +page) {
        this.page = page;
      }
    },
    // отправляем на бэкенд
    async sendReadings(owner?: number, params?: any) {
      let result = true;
      if (this.checkReadingsOnTariff) {
        if (!this.checkReadingsOnValidTarriff()) {
          return false;
        }
      }
      this.dataError = [] as StackTableRow[];
      try {
        if (this.dataObject && this.dataObject.executeMethod && this.readingsChanges.length > 0) {
          this.jobStarting = true;
          const ownerID = owner || undefined;
          this.jobID = this.noToolbar ? '' : null;
          this.jobID = await this.dataObject.executeMethod(
            this.method,
            {
              показания: this.readingsChanges,
              владелец: ownerID,
              создаватьВедомость: this.createList,
              общиеданные: this.inputDataRecords,
              вводслицевого: this.isLS,
              неМассовыйВвод: this.notMass,
              надоПерерасчитатьЛс: this.needRecalcLs,
              ...params,
            },
            {
              async: !this.noToolbar,
              jobName: 'Внесение показаний',
              external: !this.noToolbar,
              silent: true,
              onComplete: () => (this.isComplete = true),
              onError: () => (this.isError = true),
              onChangeState: this.onChangeState,
            },
          );
          if (this.jobID && !this.noToolbar) {
            // @ts-ignore
            this.jobID = this.jobID.asyncId ? this.jobID.asyncId : null;
          }
        }
      } catch {
        result = false;
        this.jobID = null;
      } finally {
        this.jobStarting = false;
        if (this.noToolbar) {
          result = await this.errorProcessing(this.jobID !== null && typeof this.jobID === 'object' ? this.jobID : []);
        }
      }
      return result;
    },
    // применить внешний фильтр
    applyFilter(inputFilter: FilterRecords) {
      for (const ind in inputFilter) {
        // @ts-ignore
        if (!this.filterData[ind] || inputFilter[ind] !== this.filterData[ind]) {
          const month = this.inputDataRecords.месяц ? this.inputDataRecords.месяц : '';
          const date = this.inputDataRecords.дата || inputFilter.дата || '';
          this.filterData = Object.assign({}, inputFilter, { месяц: month, дата: date });
          this.ownerID = this.filterData.владелец ? this.filterData.владелец : null;
          this.vedID = this.filterData.ведомость ? this.filterData.ведомость : null;
          this.readingsChanges = [];
          this.objectErrors = {};
          this.dataError = [] as StackTableRow[];
          this.onChangePage(1);
          break;
        }
      }
    },
    // применить предустановленные внешние данные
    applyData(inputData: DataRecords) {
      this.inputDataRecords = Object.assign({}, inputData);
      if (this.inputDataRecords.месяц !== this.filterData.месяц) {
        this.filterData = Object.assign({}, this.filterData, { месяц: this.inputDataRecords.месяц });
      }
      if (this.inputDataRecords.дата !== this.filterData.дата && this.inputDataRecords.дата) {
        this.filterData = Object.assign({}, this.filterData, { дата: this.inputDataRecords.дата });
      }
    },
    // добавляем измененную запись в отдельный массив для отправки на сервер
    changeRecord(item: StackTableRow) {
      const itemID = item.$номерЗаписи ? +item.$номерЗаписи : -1;
      item.$ошибкаТарифности = false;
      if (item.$ошибкаПоказания || item.$ошибкаРасхода || item.$ошибкаДаты) {
        this.objectErrors = Object.assign({}, this.objectErrors, { [itemID]: true });
      } else {
        if (this.objectErrors[itemID]) {
          delete this.objectErrors[itemID];
          // Надо переопределить массив, иначе реактивность не работает
          this.objectErrors = Object.assign({}, this.objectErrors);
        }
        let deleteItem = false;
        // TODO lint ругается на null or undefined
        const dopr = (item['дополнительный расход'] ? item['дополнительный расход'] : 0) as number;
        if (
          (item.показание === null || item.показание === undefined || (item.показание !== undefined && item.показание.toString() === '')) &&
          (!item.расход || (item.расход && +item.расход === 0)) &&
          (!item['дополнительный расход'] || +dopr === 0)
        ) {
          deleteItem = true;
        }
        for (const ind in this.readingsChanges) {
          if (this.readingsChanges[ind].$номерЗаписи === itemID) {
            this.readingsChanges.splice(ind, 1);
            break;
          }
        }
        if (!deleteItem) {
          if (this.vedID) {
            item['показания-документ'] = this.vedID;
          }
          this.readingsChanges.push(item);
        }
      }
      this.$emit('update:pok', this.readingsChanges);
      this.$emit('valid', this.valid);
    },
    pressBtn(event: KeyboardEvent) {
      // 39 и 40 это кнопки вправо и вниз
      const nextCommand = event.keyCode === 39 || event.keyCode === 40 ? 'nextElementSibling' : 'previousElementSibling';
      // берем элемент input
      const input = (event.target || event.srcElement) as any;
      // берем элемент td, в котором находится наш input
      const parentElem = input && input instanceof HTMLInputElement ? input.closest('td') : null;
      // c td читаем аттрибут id
      const parentID = parentElem ? parentElem.getAttribute('id') : null;
      let needFocusElement: any = null;
      // 38 и 40 это кнопки вверх и вниз
      if ((event.keyCode === 38 || event.keyCode === 40) && parentElem) {
        // переходим на элемент tr
        needFocusElement = input.closest('tr');
        // шагаем вверх или вниз
        needFocusElement = needFocusElement && needFocusElement[nextCommand] ? needFocusElement[nextCommand] : null;
        // ищем input
        needFocusElement = needFocusElement ? needFocusElement.querySelector(`#${parentID} input`) : null;
      }
      if (needFocusElement) {
        needFocusElement.focus({ preventScroll: false });
      }
    },
    async errorProcessing(objectResult: any) {
      const result = true;
      const arrayError = [];
      if (Object.keys(objectResult).length) {
        for (const ind in objectResult) {
          if (objectResult[ind].Ошибки) {
            let address = '';
            let meter = '';
            const errorText = (objectResult[ind].Ошибки && objectResult[ind].Ошибки ? objectResult[ind].Ошибки : []) as string[];
            arrayError.push(objectResult[ind].Ошибки);
            for (const index of this.readingsChanges) {
              if (index.$номерЗаписи === objectResult[ind].номерЗаписи) {
                address = this.showTu ? index.номерту : index.адрес;
                meter = index.наименование + ' ' + index.заводскойномер;
                this.dataError.push({ адрес: address, номерлс: index.номерлс, счетчик: meter, ошибка: errorText.join('\n') });
              }
            }
          }
        }
      }
      const pastReadings = this.readings;
      this.readingsChanges = [];
      if (!this.noFetchAfterSend) {
        await this.fetchData();
      }
      if (this.dataError.length) {
        await new ReportsBuilder('Ошибки показаний').executeReport(
          'Ошибки показаний',
          { reportFormat: 'xls', ошибки: this.dataError },
          { title: 'Ошибки показаний', silent: true },
        );
        if (objectResult.НомерВедомости && objectResult.Дата) {
          this.$toast(
            `Показания частично внесены в ведомость показаний № ${objectResult.НомерВедомости}  от  ${this.$stackDate.format(
              objectResult.Дата,
              'DD.MM.YYYY',
            )}. Лог ошибочных показаний можно посмотреть в реестре событий.`,
            { color: 'error' },
          );
        } else if (this.noToolbar) {
          this.$toast(arrayError.join('\n'), { color: 'error' });
        } else {
          this.$toast('По показаниям есть ошибки. Лог ошибочных показаний можно посмотреть в реестре событий.', { color: 'error' });
        }
        // this.readings = this.readings.map((item: StackTableRow, i: number) => {
        //   if (Object.keys(objectResult).find((key: string) => objectResult[key].номерЗаписи === pastReadings[i]['$номерЗаписи'] && objectResult[key].Ошибки)) {
        //     return { ...item, ошибки: true };
        //   } else return item;
        // });
      }
      this.jobID = null;
      return result;
    },
    async onChangeState(job: StackStateMsg) {
      if (job.data && job.data.result !== undefined) {
        await this.errorProcessing(job.data.result ? job.data.result : []);
      }
    },
    // Проверяем показания на тариф
    checkReadingsOnValidTarriff() {
      let result = true;
      const readingsLargeTar = {} as checkTarRows;
      // Сначала перебем все показания, у счетчиков с тарифностью > 1 - добавим в массив и посчитаем количество показаний
      this.readingsChanges.forEach((row: StackTableRow) => {
        const curTar = row.тарифность ? +row.тарифность : 1;
        const mg = row.микрогенерация ? +row.микрогенерация : 0;
        if (curTar > 1 && mg === 0) {
          const curIndex = row['объект-показания'] ? +row['объект-показания'] : 0;
          if (readingsLargeTar[curIndex] && readingsLargeTar[curIndex].колвоПоказаний) {
            readingsLargeTar[curIndex].колвоПоказаний += 1;
          } else {
            readingsLargeTar[curIndex] = { тарифность: curTar, колвоПоказаний: 1, массивЗаписей: [] };
            if (row.$номерЗаписи) {
              readingsLargeTar[curIndex].массивЗаписей.push(+row.$номерЗаписи);
            }
          }
        }
      });
      let errorsRowsArray = [] as number[];
      for (const ind in readingsLargeTar) {
        const curItem = readingsLargeTar[ind];
        if (curItem.колвоПоказаний !== curItem.тарифность) {
          errorsRowsArray = errorsRowsArray.concat(curItem.массивЗаписей);
        }
      }
      const errorsRows = new Set(errorsRowsArray);
      if (errorsRows.size > 0) {
        this.readings.forEach((row: StackTableRow) => {
          row.$ошибкаТарифности = row.$номерЗаписи && errorsRows.has(+row.$номерЗаписи);
        });
        this.$toast('Показание не по всем тарифам/ Не принято показание по одному из тарифов', { color: 'error' });
        result = false;
      }
      return result;
    },
  },
  watch: {
    inputFilter: {
      immediate: true,
      deep: true,
      handler(to: FilterRecords, from: FilterRecords | undefined) {
        if (to && JSON.stringify(to) !== JSON.stringify(from)) {
          this.applyFilter(to);
        }
      },
    },
    inputData: {
      immediate: true,
      deep: true,
      handler(to: DataRecords, from: DataRecords | undefined) {
        if (to && JSON.stringify(to) !== JSON.stringify(from)) {
          this.applyData(to);
        }
      },
    },
    dataModel: {
      immediate: true,
      handler() {
        if (typeof this.dataModel === 'string') {
          this.dataObject = new this.$HttpModel(this.dataModel);
        } else {
          this.dataObject = new this.$StaticModel(this.dataModel);
        }
      },
    },
    inputprogress() {
      this.$emit('busy', this.inputprogress);
    },
  },
});
