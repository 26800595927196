
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        'Параметры.ДатНач': '',
        'Параметры.ДатКнц': '',
        'Параметры.МесКнц': this.$store.getters.getWorkMonth(),
        'Параметры.МесНач': this.$store.getters.getWorkMonth(),
        'Параметры.Источник': -1,
        'Параметры.Флаги': 0,
        'Параметры.Вид отчета': 0,
      },
    };
  },
});
