const lg: StackSelection = {
  objectName: 'ПрочитатьЭксплуатационныеПараметры',
  description: 'ПрочитатьЭксплуатационныеПараметры',
  fields: [
    {
      field: 'startdatamonth',
      text: 'StartDataMonth',
      type: 'Date',
    },
    {
      field: 'firstworkmonth',
      text: 'FirstWorkMonth',
      type: 'Date',
    },
    {
      field: 'debtmonth',
      text: 'DebtMonth',
      type: 'Number',
    },
    {
      field: 'servicetype',
      text: 'servicetype',
      type: 'String',
    },
    {
      field: 'onrecalc',
      text: 'OnRecalc',
      type: 'Number',
    },
    {
      field: 'showhistory',
      text: 'ShowHistory',
      type: 'Number',
    },
  ],
};

export default lg;
