
import Vue from 'vue';
import stepBtn from './stepBtn.vue';

export default Vue.extend({
  components: { stepBtn },
  props: {
    record: { type: Object, required: true },
    steps: { type: Object, required: true },
    parentID: { type: String, default: '' },
  },
  data() {
    return {
      категория: 0,
      типстрой: 0,
    };
  },
  methods: {
    async fetchData() {
      if (this.record && this.record.$номерЗаписи > 0) {
        const items = await new this.$HttpModel('ЛицевыеСчета.ОбщаяИнформация').getRecords({ владелец: this.record.$номерЗаписи });
        if (items.length > 0) {
          this.категория = items[0] && items[0].категория ? Number(items[0].категория) : this.категория;
          this.типстрой = items[0] && items[0].категория ? Number(items[0].типстрой) : this.типстрой;
        }
      }
    },
  },
  watch: {
    record: {
      immediate: true,
      handler(to) {
        this.fetchData();
      },
    },
  },
});
