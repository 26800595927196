const orgs: StackSelection = {
  objectName: 'Организации.Категории',
  description: 'Категории организаций',
  hier: true,
  openLink: 'references-organizationskat',
  fields: [
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'строка',
      text: 'Строка',
      type: 'String',
    },
    {
      field: 'допкод',
      text: 'ДопКод',
      type: 'String',
    },
    {
      field: 'категории-организация',
      text: 'Организация',
      type: 'Link',
    },
  ],
};

export default orgs;
