
import Vue from 'vue';

export default Vue.extend({
  name: 'LsParams',
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      items: {} as StackTableRow,
      http: new this.$HttpModel('ОкноОператораПараметрыЛС'),
      itemsLeft: [] as StackField[],
      itemsRight: [] as StackField[],
      itemsBottom: [] as StackField[],
    };
  },
  computed: {
    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
  },
  methods: {
    async fetchData() {
      if (this.record && this.record.$номерЗаписи > 0) {
        this.itemsLeft = [];
        this.itemsRight = [];
        this.itemsBottom = [];
        const items = await this.http.executeMethod('получитьПараметры', { владелец: this.record.$номерЗаписи });
        items[0].left?.forEach((item: any) => { this.itemsLeft.push(item); });
        items[0].right?.forEach((item: any) => { this.itemsRight.push(item); });
        items[0].bottom?.forEach((item: any) => { this.itemsBottom.push(item); });
      }
    },
  },
  watch: {
    record: {
      immediate: true,
      handler(to) {
        this.fetchData();
      },
    },
    workMonth() {
      this.fetchData();
    },
  },
});
