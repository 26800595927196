
import Vue from 'vue';

export default Vue.extend({
  props: {
    dataObject: { type: Object, default: undefined },
    active: { type: [Object, Array], default: undefined }, // Array парсер
    params: { type: Object, default: undefined },
    netType: { type: Number, required: true },
  },
  data() {
    return {
      record: this.active && this.active[0],
      month: this.$store.getters.getWorkMonth(),
      flags: 0,
    };
  },
  computed: {
    networkLeafs(): String {
      if (this.active) {
        let leafs = '';
        for (const itm of Array.from(this.active) as any[]) {
          leafs += ', ' + itm.название;
        }
        return leafs.substring(2);
      }
      return '';
    },
  },
  methods: {
    async onSaveDialog() {
      const parameters = Object.assign({}, this.params, { тип: this.netType, месяц: this.month, флаги: this.flags, элементы: this.active });
      this.dataObject.executeMethod('Рассчитать', parameters, { async: true, jobName: 'Расчет сети' });
      this.$emit('close');
    },
  },
});
