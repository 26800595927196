
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: { type: Object, required: true },
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true },
    selectedValues: { type: [String, Number], default: undefined },
    selectedItems: { type: Array, required: true },
  },
});
