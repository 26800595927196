const minp: StackSelection = {
  objectName: 'МассовыйВвод.Услуги',
  description: 'Услуги для простановки данных',
  fields: [
    {
      field: 'вид-услуги',
      text: 'Вид-Услуги',
      type: 'Number',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'датнач',
      text: 'ДатНач',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'ДатКнц',
      type: 'Date',
    },
    {
      field: 'состояниестрокой',
      text: 'Состояние',
      type: 'String',
    },
  ],
};

export default minp;
