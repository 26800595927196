const accs: StackSelection = {
  objectName: 'ПлатежныеВедомости.ПлатежиВедомости.Показания',
  description: '',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчетный месяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'показание',
      text: 'Показание',
      type: 'Number',
    },
    {
      field: 'расход',
      text: 'Расход',
      type: 'Number',
    },
    {
      field: 'итоговый расход',
      text: 'Итоговый расход',
      type: 'Number',
    },
    {
      field: 'дополнительный расход',
      text: 'Доп. расход',
      type: 'Number',
    },
    {
      field: 'тариф',
      text: 'Зона суток',
      type: 'String',
    },
    {
      field: 'типввода',
      text: 'Тип ввода',
      type: 'Number',
    },
    {
      field: '@тип ввода',
      text: 'Тип ввода',
      type: 'Number',
    },
    {
      field: '@зона',
      text: 'Зона суток',
      type: 'String',
      width: '60px',
    },
    {
      field: '@покпредсч',
      text: 'Показание предыдущее',
      type: 'Number',
    },
    {
      field: '@меспредсч',
      text: 'Месяц предыдущий',
      type: 'Date',
    },
    {
      field: '@типпредсч',
      text: 'типпредсч',
      type: 'Number',
    },
    {
      field: 'заводскойномер',
      text: 'Заводской номер',
      type: 'String',
    },
    {
      field: 'дробнаяразрядность',
      text: 'Дробная разрядность',
      type: 'Number',
    },
    {
      field: 'разрядность',
      text: 'Разрядность',
      type: 'Number',
    },
    {
      field: 'коэффициент трансформации',
      text: 'КТранс',
      type: 'Number',
    },
    {
      field: 'наименование',
      text: 'услуга',
      type: 'String',
    },
    {
      field: 'адрес',
      text: 'адрес',
      type: 'String',
    },
    {
      field: 'объект-показания',
      text: 'объект-показания',
      type: 'Number',
    },
    {
      field: 'услуга_id',
      text: 'услуга_id',
      type: 'Number',
    },
    {
      field: 'счмесяцев',
      text: 'СчМесяцев',
      type: 'Number',
    },
    {
      field: 'типсчетчика',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'объекты-счет',
      text: 'счет',
      type: 'Number',
    },
    {
      field: 'объекты-групповой',
      text: 'групповой',
      type: 'Number',
    },
    {
      field: 'изменилипениилипоказание',
      text: 'изменилипениилипоказание',
      type: 'Number',
    },
  ],
};
export default accs;
