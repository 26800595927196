const serv: StackSelection = {
  objectName: 'Организации.Контакты',
  description: '',
  fields: [
    {
      field: 'номер',
      text: 'Контакт',
      type: 'String',
    },
    {
      field: 'добавочный',
      text: 'Добавочный',
      type: 'String',
    },
    {
      field: 'подписка',
      text: 'подписка',
      type: 'Number',
    },
    {
      field: '@флаги',
      text: 'Участвует в рассылке',
      type: 'String',
      computed: true,
    },
    {
      field: 'датаактуализации',
      text: 'Дата изменений',
      type: 'Date',
    },
    {
      field: 'частные лица-телефон',
      text: 'Принадлежность',
      type: 'Link',
    },
    {
      field: 'частные лица-телефон>фио',
      text: 'Принадлежность',
      type: 'String',
    },
    {
      field: 'fioHandler',
      text: 'Принадлежность',
      type: 'String',
      computed: true,
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: '@тип',
      text: 'Тип',
      type: 'String',
      computed: true,
    },
    {
      field: 'нпп',
      text: '№ п/п',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'флаги',
      text: 'Участвует в рассылке',
      type: 'Number',
    },
  ],
};

export default serv;
