const sostispdoc: StackSelection = {
  recordRoute: '',
  objectName: 'ЛицевыеСчета.ИсполнительныеДокументы',
  description: 'Реестр исполнительных документов',
  prepareRow: row => {
    row.общаясумма = Number(row.сумма) + Number(row.сумма2) + Number(row.госпошлина) + Number(row.прочее);
    return row;
  },
  fields: [
    {
      field: 'номердела',
      text: 'Номер дела',
      type: 'String',
    },
    {
      field: 'фио',
      text: 'Ответчик',
      type: 'String',
    },
    {
      field: 'название',
      text: 'Текущее состояние ИЛ',
      type: 'String',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'дата',
      text: 'Дата ИД',
      type: 'Date',
    },
    {
      field: 'датаполучения',
      text: 'Дата\nполучения',
      type: 'Date',
    },
    {
      field: 'общаясумма',
      text: 'Общая сумма\nпо ИД',
      type: 'Money',
    },
    {
      field: 'сумма',
      text: 'Сумма долга',
      type: 'Money',
    },
    {
      field: 'сумма2',
      text: 'Пени',
      type: 'Money',
    },
    {
      field: 'госпошлина',
      text: 'Госпошлина',
      type: 'Money',
    },
    {
      field: 'прочее',
      text: 'прочее',
      type: 'Money',
    },
  ],
};
export default sostispdoc;
