
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    srok4(): any {
      const arr = this.record.значение.split(';');
      // Если пришло что-то непонятное ставим значения по умолчанию
      return arr.length === 3 ? arr : [10, 5, 5];
    },
  },
  methods: {
    changeFunc(value: number, id: number) {
      const arr = this.srok4;
      arr[id] = value;
      this.record.значение = arr.join(';');
    },
  },
});
