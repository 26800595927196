
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    parentRecord: { type: Object, required: true },
    ownerID: { type: [String, Number], required: true },
    title: { type: String, default: 'Адрес' },
  },
  data() {
    return {
      entrance: false,
      floor: false,
      rules: Object.assign(this.$stackRules, {
        inCorrectMonth: (date1: string, date2: string): string | true => {
          return this.$stackDate.firstDay(date1) === this.$stackDate.firstDay(date2) || 'Даты должны быть в одном месяце!';
        },
      }, {
        firstMonthRecalc: (date: string): string | true => {
          const firstMonthRecalc = this.$store.getters['flStore/getfirstWorkMonth']();
          return Date.parse(date) >= Date.parse(firstMonthRecalc) || `Нельзя делать перерасчет ранее ${this.$stackDate.format(firstMonthRecalc, 'MM.YYYY')}`;
        },
      }),
    };
  },
  computed: {
    hourFrom(): string {
      return this.record.часнач;
    },
    dayCount(): number {
      return this.$stackDate.differenceInDays(new Date(this.record.даткнц), new Date(this.record.датнач)) + 1;
    },
  },
  methods: {
    changeEntrance(value: boolean | number, type?: number) {
      if (typeof value === 'boolean') {
        this.record['@подъездс'] = 0;
        this.record['@подъездпо'] = 0;
      } else {
        if (type && type === 1) {
          this.record['@подъездпо'] = this.record['@подъездс'] > this.record['@подъездпо'] ? this.record['@подъездс'] : this.record['@подъездпо'];
        } else {
          this.record['@подъездс'] = this.record['@подъездс'] > this.record['@подъездпо'] ? this.record['@подъездпо'] : Math.max(this.record['@подъездс'], 1);
        }
      }
    },
    changeFloor(value: boolean | number, type?: number) {
      if (typeof value === 'boolean') {
        this.record['@этажс'] = 0;
        this.record['@этажпо'] = 0;
      } else {
        if (type && type === 1) {
          this.record['@этажпо'] = this.record['@этажс'] > this.record['@этажпо'] ? this.record['@этажс'] : this.record['@этажпо'];
        } else {
          this.record['@этажс'] = this.record['@этажс'] > this.record['@этажпо'] ? this.record['@этажпо'] : Math.max(this.record['@этажс'], 1);
        }
      }
    },
  },
  watch: {
    dayCount(to) {
      this.record.дни = to;
    },
    hourFrom(to, old) {
      if (old === '') {
        this.$set(this.record, 'часкнц', '23:59');
      }
    },
  },
});
