
import Vue from 'vue';

export default Vue.extend({
  props: {
    dataModel: { type: String, required: true },
    closeBtn: { type: Boolean, default: false },
    toolbarDark: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async onOpenNewTab(item: StackTableRow) {
      window.open(item.url as string, '_blank');
    },
    async onUnlock(items: StackTableRow[], remove: boolean) {
      const msg = items.length > 1 ? ` выделенных записей: ${items.length}` : 'текущую запись';
      if (remove && !(await this.$yesno(`Вы уверены, что хотите удалить\n${msg} ?`, 'Внимание', { width: '600px' }))) {
        return;
      }
      const dataModel = new this.$HttpModel(this.dataModel);
      this.loading = true;
      try {
        await Promise.all(items.map((item: StackTableRow) => dataModel.executeMethod('удалить', { ИмяТаблицы: item.имятаблицы, номерЗаписи: item.идзаписи, сУдалением: remove })));
      } finally {
        this.loading = false;
        this.$sendMsg('stack-table', this.dataModel, 'reload');
        this.$sendMsg('stack-table', this.dataModel, 'unselect-all');
      }
    },
  },
});
