const serv: StackSelection = {
  recordRoute: 'references-typesanalitics2-typesanalitics2id',
  objectName: 'ТипыАналитики2',
  description: 'Виды аналитики',
  fields: [
    {
      field: 'row_id',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
    {
      field: 'название',
      text: 'Краткое название',
      type: 'String',
    },
    {
      field: 'перерасчет',
      text: 'Перерасчет',
      type: 'String',
    },
  ],
};

export default serv;
