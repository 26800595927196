const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ПерерасчетыЗаСтарыйПериод',
  description: '',
  fields: [
    {
      field: 'услуга',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'за',
      text: 'За',
      type: 'DateMonth',
    },
    {
      field: 'поставщик',
      text: 'Поставщик',
      type: 'Number',
    },
    {
      field: 'договорук',
      text: 'договорук',
      type: 'Number',
    },
    {
      field: 'наименованиеуслуги',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'наименованиепоставщика',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'использовантариф',
      text: 'Тариф',
      type: 'Number',
    },
    {
      field: 'использовананорма',
      text: 'Норма',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: 'Сумма,₽',
      type: 'Money',
    },
    {
      field: 'объем',
      text: 'Объем,V',
      type: 'Number',
    },
    {
      field: 'аналитика1',
      text: 'Аналитика1',
      type: 'String',
    },
    {
      field: 'аналитика2',
      text: 'Аналитика2',
      type: 'String',
    },
  ],
};
export default accs;
