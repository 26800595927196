const params: StackSelection = {
  recordRoute: 'references-dialogDeviceMeteringParam',
  objectName: 'ТипыПриборовУчета.Параметры',
  description: 'Параметры',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
    {
      field: 'параметр-значения',
      text: 'Параметр-Значения',
      type: 'Link',
    },
  ],
};

export default params;
