
import Vue from 'vue';
import { TableHandlers } from '@/stackEngine';
import ServiceDialog from './_serviceLsDialog.vue';

/**
 * Услуги лицевого
 */
export default Vue.extend({
  components: { ServiceDialog },
  props: {
    dataModel: { type: String, required: true },
    ownerID: { type: Number, default: null },
    headers: { type: String, default: 'номер услуги,наименование,состояниекартинка,датнач,даткнц' },
    hideTarif: { type: Boolean, default: false },
    hideDate: { type: Boolean, default: false },
    hideState: { type: Boolean, default: false },
    history: { type: Boolean, default: true },
    noPagination: { type: Boolean, default: false },
    noSearch: { type: Boolean, default: false },
  },
  computed: {
    showHistoryByDefault(): boolean {
      return this.$store.getters['flStore/getShowHistory']();
    },
  },
  data() {
    return {
      title: 'Услуга',
    };
  },
  methods: {
    uslHandler(item: any) {
      if (+item['счет-услуги'] > 0 && +this.ownerID !== +item['счет-услуги']) {
        item.$data.наименование.$иконка = '$vuetify.icons.hier';
        item.$data.наименование.$иконка_цвет = 'grey';
        TableHandlers.trColor(item, 'grey');
      }
      return item;
    },
  },
});
