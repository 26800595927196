
import Vue from 'vue';
import CommonTab from '@/tasks/dlg_fl/components/$lawsuits/CommonTab.vue';

export default Vue.extend({
  components: { CommonTab },
  props: {
    lawsuitid: { type: [Number, String], default: undefined },
    model: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      tabs: null,
      record: {} as StackTableRow,
    };
  },
  computed: {
    otdel(): number {
      return this && this.record && this.record['документ-фаза>фаза-состояние>тип'] ? +this.record['документ-фаза>фаза-состояние>тип'] : 2;
    },
    title(): string {
      const doptitle = ` № ${this.record.номер}`;
      switch (this.otdel) {
        case 1:
          return `Досудебная работа ${doptitle}`;
        case 3:
          return `Дело об ограничении ${doptitle}`;
        default:
          return `Дело о взыскании задолженности: ${doptitle}`;
      }
    },
  },
});
