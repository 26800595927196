
import Vue from 'vue';

export default Vue.extend({
  name: 'DateBeginDialog',
  props: {
    ls: { type: [Number, String], required: true },
  },
  data() {
    return {
      month: this.$store.getters.getWorkMonth(),
      count: 12,
      selectedItems: [],
      loading: false,
    };
  },
  methods: {
    async onBtnOkClick() {
      this.loading = true;
      await new this.$HttpModel('ЛицевыеСчета.СозданиеРассрочки').executeMethod('ВставитьСоглашение', { лицевой: this.ls, записи: this.selectedItems });
      this.loading = false;
      this.$emit('save');
    },
  },
});
