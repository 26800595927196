
import Vue from 'vue';
import mDialog from './$dialogs/street.vue';
import FiasDialog from '@/tasks/common/views/operations/downloadfias/index.vue';

export default Vue.extend({
  components: { mDialog, FiasDialog },
  data() {
    return {
      dlgGetFias: false,
    };
  },
});
