
import Vue from 'vue';
export default Vue.extend({
  name: 'DatePickerFullYear',
  model: { prop: 'dates', event: 'change' },
  props: {
    // массив String[][] с датами по месяцам
    dates: { type: [Array], required: true },
    year: { type: [Date, String], default: new Date() },
    // колонки в cols для определения сколько месяцев в 1 ряд
    cols: { type: String, default: '4' },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  methods: {
    getPickerMonth(month: number) {
      return this.$stackDate.format(new Date(this.$stackDate.getYear(this.year), month, 1), 'ISO');
    },
    getPickerMaxValue(month: number) {
      return this.$stackDate.lastDay(this.getPickerMonth(month));
    },
  },
});
