const accs: StackSelection = {
  objectName: 'ИзменяемыеТаблицы.Умолчания',
  description: '',
  fields: [
    {
      field: 'имя',
      text: 'Имя',
      type: 'String',
    },
    {
      field: 'действие',
      text: 'Действие',
      type: 'String',
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
  ],
};
export default accs;
