import { TableHandlers } from '@/stackEngine';
const payments: StackSelection = {
  objectName: 'ПлатежныеВедомости.ПлатежиВедомости',
  description: '',
  recordRoute: 'payments-paylists-paylistsid-payments-paymentsid',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    // @ts-ignore
    if (row['@цвет'] !== '') {
      // @ts-ignore
      TableHandlers.stackStyle(row, row['@цвет']);
    }
    return row;
  },
  fields: [
    {
      field: 'n п/п',
      text: 'N п/п',
      type: 'Number',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'назначение платежа',
      text: 'Назначение платежа',
      type: 'String',
    },
    {
      field: 'платежная группа',
      text: 'Платежная группа',
      type: 'Number',
    },
    {
      field: 'комментарий',
      text: 'Комментарий',
      type: 'String',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчмесяц',
      text: 'РасчМесяц',
      type: 'DateMonth',
    },
    {
      field: 'платежза',
      text: 'ПлатежЗа',
      type: 'Date',
    },
    {
      field: 'платежпо',
      text: 'ПлатежПо',
      type: 'Date',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'счет-оплата>номер',
      text: 'Лицевой счет',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'пени',
      text: 'Пени',
      type: 'Number',
    },
    {
      field: 'счет-оплата',
      text: 'счет-оплата',
      type: 'Link',
    },
    {
      field: 'ук-оплата',
      text: 'УК-оплата',
      type: 'Link',
    },
    {
      field: 'пост-оплата',
      text: 'Пост-оплата',
      type: 'Link',
    },
    {
      field: 'значениеил',
      text: 'значениеИЛ',
      type: 'String',
    },
    {
      field: '@цвет',
      text: 'цвет',
      type: 'String',
    },
    {
      field: 'идентификатортранзакции',
      text: 'Идентификатор Транзакции',
      type: 'String',
    },
    {
      field: 'суммапени',
      text: 'В т.ч. пени',
      type: 'Money',
    },
    {
      field: 'платеж-список>номер',
      text: 'Документ',
      type: 'Number',
    },
  ],
};
export default payments;
