
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      val: this.record.примечание.replace(/(\d+).(\d+).(\d+)/, '$3-$2-$1') as string,
    };
  },
  methods: {
    onChange() {
      if (this.val === null) {
        this.record.примечание = '';
      } else {
        const date = new Date(this.val);

        let dd: string = date.getDate().toString();
        if (dd.length === 1) {
          dd = `0${dd}`;
        }

        let mm: string = (date.getMonth() + 1).toString();
        if (mm.length === 1) {
          mm = `0${mm}`;
        }

        const yyyy: string = date.getFullYear().toString();
        this.record.примечание = `${dd}.${mm}.${yyyy}`;
      }
    },
  },
});
