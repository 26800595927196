const accs: StackSelection = {
  objectName: 'ОбновлениеВерсии.ИзменяемыеИндексы',
  description: '',
  fields: [
    {
      field: 'индекс',
      text: 'Индекс',
      type: 'String',
    },
    {
      field: 'таблица',
      text: 'Таблица',
      type: 'String',
    },
    {
      field: 'база',
      text: 'База',
      type: 'String',
    },
    {
      field: 'операция',
      text: 'Операция',
      type: 'String',
    },
  ],
};
export default accs;
