// Реквизиты
export default {
  shortName: 'Стек',
  fullName: 'Комплекс программ «СТЕК»',
  company: 'Группа компаний «СТЕК»',
  site: 'stack-it.ru',
  siteUrl: 'http://stack-it.ru',
  telUrl: 'tel:+74852230003',
  tel: '+7 (4852) 23-00-03, 59-45-00',
  worktime: '09:00 – 18:00 (по МСК)',
  mailtoUrl: 'mailto:support@stack-it.ru?subject=Стек Web&body=Стек Web',
  email: 'support@stack-it.ru',
  skype: 'skype:stack-it?chat',
  vk: 'https://vk.com/companystack',
  // fb: 'https://www.facebook.com/companystack',
  // ig: 'https://www.instagram.com/companystack',
  youtube: 'https://www.youtube.com/channel/UCP4THlMlACwFDYd19gStBqw',
  pluginName: 'Стек Ассистент',
  description: 'Разработка, внедрение и сопровождение программного обеспечения. Автоматизация биллинга и обслуживания абонентов в ЖКХ и энергетике. Более 1050 успешных проектов в 66 регионах РФ! Работаем с 1993г.',
  keywords: 'учет в жкх, учет в управляющих компаниях, учет в тсж, расчет квартплаты, программа для жкх, тсж, ук, жск, биллинг жкх',
};
