import { store, TableHandlers } from '@/stackEngine';
const accs: StackSelection = {
  recordRoute: 'accounts-accountsid',
  objectName: 'ЛицевыеСчета',
  description: 'Адреса проживающих',
  linkField: 'номер',
  openLink: 'accounts',
  hier: true,
  // для тестирования дерева
  // prepareRow: row => {
  //   if (!row.$этоПапка) {
  //     row.$название = `${row.адрес}`;
  //   }
  //   return row;
  // },
  prepareRow: row => {
    const task = store.getters.getCurrentTask();
    if (task !== 'ul') {
      if (row.датазакрытия) {
        TableHandlers.trColor(row, 'grey');
      }
      return row;
    }

    if (!row.$data) {
      row.$data = {};
    }
    if (!row.$data.номер) {
      row.$data.номер = {} as StackTableRowDataItem;
    }

    if (!row.$этоПапка) {
      switch (store.getters['flStore/getComplexKey'](row.видпотребления)) {
        case 'ЖКХ':
          TableHandlers.tdIcon(row, 'номер', '$vuetify.icons.home', 'green');
          break;
        case 'РСО':
          TableHandlers.tdIcon(row, 'номер', '$vuetify.icons.sun', 'orange');
          break;
        case 'ЭНЕРГО':
          TableHandlers.tdIcon(row, 'номер', '$vuetify.icons.lightbulb', 'light-blue darken-4');
          break;
        case 'ТКО':
          TableHandlers.tdIcon(row, 'номер', '$vuetify.icons.delete', 'green');
          break;
      }
    }
    return row;
  },
  fields: [
    {
      field: 'номер',
      text: 'Номер лицевого',
      type: 'Number',
      align: 'left',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
      computed: true,
    },
    {
      field: 'адреслс',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'счет-наниматель>фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'счет-линейный участок',
      text: 'Участок',
      type: 'String',
    },
    {
      field: 'улица-лицевой счет',
      text: 'Улица',
      type: 'String',
    },
    {
      field: 'счет-наниматель',
      text: 'НанимательID',
      type: 'Link',
      computed: true,
    },
    {
      field: 'e-mail',
      text: 'E-Mail',
      type: 'String',
    },
    {
      field: 'адресдоставки',
      text: 'Адрес доставки',
      type: 'String',
    },
    {
      field: 'индексдоставки',
      text: 'индекс доставки',
      type: 'Number',
    },
    {
      field: 'областьдоставки',
      text: 'Область доставки',
      type: 'String',
    },
    {
      field: 'стороннийномер',
      text: 'Сторонний номер',
      type: 'String',
    },
    {
      field: 'гуид',
      text: 'ГУИД',
      type: 'String',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'типздания',
      text: 'Тип Здания',
      type: 'Number',
    },
    {
      field: 'счета',
      text: 'Счета',
      type: 'Link',
    },
    {
      field: 'договор',
      text: 'Договор',
      type: 'String',
      computed: true,
    },
    {
      field: 'счет-аналитика1',
      text: 'Группа',
      type: 'Link',
    },
    {
      field: 'счет-структура',
      text: 'СтруктураСети',
      type: 'Link',
    },
    {
      field: 'электросеть',
      text: 'Акт РБП',
      type: 'String',
    },
    {
      field: 'датаоткрытия',
      text: 'ДатаОткрытия',
      type: 'Date',
    },
    {
      field: 'начисления с',
      text: 'Начисления с',
      type: 'Date',
    },
    {
      field: 'датазакрытия',
      text: 'Дата закрытия',
      type: 'Date',
    },
    {
      field: 'гис_елс',
      text: 'ГИС_ЕЛС',
      type: 'String',
    },
    {
      field: 'счет-доставка',
      text: 'счет-доставка',
      type: 'Link',
    },
    {
      field: 'месблок',
      text: 'Блокировка',
      type: 'Enum',
      computed: true,
    },
    {
      field: '@кадастровый номер',
      text: 'Кадастровый номер',
      type: 'String',
      computed: true,
    },
    {
      field: '@количество подъезды',
      text: 'Количество подъездов',
      type: 'Number',
      computed: true,
    },
    {
      field: '@количество этажи',
      text: 'Количество этажей',
      type: 'Number',
      computed: true,
    },
    {
      field: '@этажей наименьшее',
      text: 'Этажей наименьшее',
      type: 'Number',
      computed: true,
    },
    {
      field: '@год постройки',
      text: 'Год постройки',
      type: 'Number',
      computed: true,
    },
    {
      field: '@площадь общая',
      text: 'Общая площадь здания',
      type: 'Number',
      computed: true,
    },
    {
      field: '@застроенная площадь',
      text: 'Застроенная площадь здания',
      type: 'Number',
      computed: true,
    },
    {
      field: 'видпотребления',
      text: 'ВидПотребления',
      type: 'Number',
    },
    {
      field: '@наниматели',
      text: 'Наниматели',
      type: 'String',
      computed: true,
    },
    {
      field: 'юлфл',
      text: ' ',
      type: 'String',
    },
    {
      field: 'сетьпуть',
      text: 'Сеть',
      type: 'String',
      computed: true,
    },
    {
      field: 'видблокировкиюл',
      text: 'видблокировкиюл',
      type: 'String',
      computed: true,
    },
    {
      field: '@вадмрежиме',
      text: 'В адм режиме',
      type: 'Number',
      computed: true,
    },
    {
      field: 'кадастровый номер',
      text: 'Кадастровый номер',
      type: 'String',
    },
  ],
};
export default accs;
