
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      items: [{}, {}, {}],
      dataModel: ['среднемесячное', 'среднесуточное'],
    };
  },
  computed: {},
  methods: {
    changeFunc() {
      this.$set(this.record, 'значение', JSON.stringify(this.items));
    },
  },
  created() {
    if (this.record && this.record.значение) {
      this.items = JSON.parse(this.record.значение);
    }
  },
});
