import { TableHandlers } from '@/stackEngine';

const dlgpir: StackSelection = {
  objectName: 'СостоянияДела',
  description: 'Движение дела',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    // @ts-ignore
    if (row['@цвет'] !== '') {
      // @ts-ignore
      TableHandlers.stackStyle(row, row['@цвет']);
    }

    return row;
  },
  fields: [
    {
      field: 'фаза-состояние>название',
      text: 'Фаза',
      type: 'String',
    },
    {
      field: 'фаза-состояние>код',
      text: 'код фазы',
      type: 'String',
    },
    {
      field: 'подфаза-состояние>имя',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'подфаза-состояние>код',
      text: 'код подфазы',
      type: 'String',
    },
    {
      field: 'датнач',
      text: 'Дата начала',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Дата окончания',
      type: 'Date',
    },
    {
      field: 'сумма',
      text: 'Долг',
      type: 'Money',
    },
    {
      field: 'сумма2',
      text: 'Пени',
      type: 'Money',
    },
    {
      field: 'суммапрочее',
      text: 'Прочее',
      type: 'Money',
    },
    {
      field: 'суммапениоплата',
      text: 'Пени на опл долги',
      type: 'Money',
    },
    {
      field: 'госпошлина',
      text: 'Госполнина',
      type: 'Money',
    },
    {
      field: 'датначдолга',
      text: 'Дата начала долга',
      type: 'Date',
    },
    {
      field: 'даткнцдолга',
      text: 'Дата конца долга',
      type: 'Date',
    },
    {
      field: 'фикспени',
      text: 'фикспени',
      type: 'Number',
    },
    {
      field: 'номеруведомления',
      text: 'Номер документа',
      type: 'String',
    },
    {
      field: 'n п/п',
      text: 'N п/п ',
      type: 'Number',
    },
    {
      field: 'дата уведомления',
      text: 'Дата документа',
      type: 'Date',
    },
    {
      field: 'даткнцпени',
      text: 'Дата кнц пени',
      type: 'Date',
    },
    {
      field: 'датначпени',
      text: 'Дата нач пени',
      type: 'Date',
    },
    {
      field: 'состояние-дополнение>суд-организация',
      text: 'Суд',
      type: 'Number',
    },
    {
      field: '@типдиалога',
      text: 'тип диалога',
      type: 'String',
    },
    {
      field: '@позицияперехода',
      text: 'позиция перехода',
      type: 'Number',
    },
    {
      field: 'документ-состояние>документ-фаза',
      text: 'документ-состояние>документ-фаза',
      type: 'Number',
    },
    {
      field: '@разрешитьэлемент',
      text: '',
      type: 'String',
    },
    {
      field: '@запретитьэлемент',
      text: '',
      type: 'String',
    },
    {
      field: '@срокобжалования',
      text: '',
      type: 'String',
    },
    {
      field: '@датавступления',
      text: '',
      type: 'String',
    },
    {
      field: '@решение',
      text: '',
      type: 'Number',
    },
    {
      field: 'доставка',
      text: 'доставка',
      type: 'Number',
    },
    {
      field: '@информация',
      text: '',
      type: 'String',
    },
    {
      field: 'номердокумента',
      text: '',
      type: 'String',
    },
    {
      field: '@цвет',
      text: 'Цвет записи',
      type: 'String',
    },
    {
      field: '@сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'дата частичного',
      text: 'Дата частичного',
      type: 'Date',
    },
    {
      field: 'дата отключения',
      text: 'Дата отключения',
      type: 'Date',
    },
    {
      field: '@операции',
      text: 'Операции',
      type: 'String',
    },
    {
      field: 'документ-состояние',
      text: 'Документ-Состояние',
      type: 'Number',
    },
    {
      field: 'документ-состояние>счет-взыскания',
      text: 'Счет-Взыскания',
      type: 'Number',
    },
    {
      field: 'документ-состояние>договор-взыскания',
      text: 'Договор-Взыскания',
      type: 'Number',
    },
    {
      field: 'фаза-состояние>тип',
      text: 'Фаза-Состояние>Тип',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'примечание',
      type: 'String',
    },
    {
      field: '@итоговыйакт',
      text: 'итоговыйакт',
      type: 'Number',
    },
    {
      field: '@номеракта',
      text: 'номеракта',
      type: 'String',
    },
    {
      field: '@датаакта',
      text: 'датаакта',
      type: 'Date',
    },
    {
      field: '@датапредъявления',
      text: 'датапредъявления',
      type: 'Date',
    },
    {
      field: '@номерил',
      text: 'номерил',
      type: 'String',
    },
    {
      field: '@датаил',
      text: 'датаил',
      type: 'Date',
    },
    {
      field: '@датаполученияил',
      text: 'датаполученияил',
      type: 'Date',
    },
    {
      field: 'суммааванса',
      text: 'авансы',
      type: 'Number',
    },
    {
      field: '@обжалование',
      text: 'обжалование',
      type: 'Number',
    },
    {
      field: 'причина',
      text: 'Причина',
      type: 'Number',
    },
    {
      field: 'состояние-организации',
      text: 'Состояние-Организации',
      type: 'Number',
    },
    {
      field: 'суммадоля',
      text: 'СуммаДоля',
      type: 'Money',
    },
    {
      field: 'сумма2доля',
      text: 'Сумма2Доля',
      type: 'Money',
    },
    {
      field: 'документ-состояние>доляпроцент',
      text: 'ДоляПроцент',
      type: 'Number',
    },
    {
      field: 'возвратпричина',
      text: 'возвратпричина',
      type: 'Number',
    },
    {
      field: '@наличиесф',
      text: 'НаличиеСФ',
      type: 'Number',
    },
    {
      field: '@поззаписи',
      text: 'ПозЗаписи',
      type: 'Number',
    },
    {
      field: '@суммаактабезндс',
      text: 'СуммаАктаБезНДС',
      type: 'Money',
    },
    {
      field: '@суммаакта',
      text: 'СуммаАкта',
      type: 'Money',
    },
    {
      field: '@сумма2',
      text: 'Пени',
      type: 'Money',
    },
  ],
};

export default dlgpir;
