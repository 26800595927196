const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.РучныеПерерасчеты',
  description: '',
  fields: [
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'название услуги',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'услуга',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'аналитика1',
      text: 'Аналитика1',
      type: 'String',
    },
    {
      field: 'использовантариф',
      text: 'Тариф',
      type: 'Number',
    },
    {
      field: 'название льготы',
      text: 'Название льготы',
      type: 'String',
    },
    {
      field: 'льгота',
      text: 'Льгота',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: 'Сумма,₽',
      type: 'Money',
    },
    {
      field: 'номер льготы',
      text: 'номер льгота',
      type: 'Number',
    },
    {
      field: 'льготник',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'перерасчет',
      text: 'За',
      type: 'DateMonth',
    },
    {
      field: 'договорук',
      text: 'ДоговорУК',
      type: 'Number',
    },
    {
      field: 'объем',
      text: 'Объем,V',
      type: 'Number',
    },
    {
      field: 'ук',
      text: 'УК',
      type: 'Link',
    },
    {
      field: 'название ук',
      text: 'Исполнитель',
      type: 'String',
    },
    {
      field: 'поставщик',
      text: 'Поставщик',
      type: 'Link',
    },
    {
      field: 'название поставщика',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'документ',
      text: 'Документ',
      type: 'Number',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'датнач',
      text: 'ДатНач',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'ДатКнц',
      type: 'Date',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'сотрудник',
      text: 'Сотрудник',
      type: 'Number',
    },
    {
      field: 'сотрудникфио',
      text: 'сотрудникфио',
      type: 'String',
    },
  ],
};
export default accs;
