const grcount: StackSelection = {
  objectName: 'ГрупповыеСчетчики.История',
  description: '',
  recordRoute: '',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    row.номер = row.номер?.toString();
    return row;
  },
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'адреслс',
      text: 'АдресЛС',
      type: 'String',
    },
    {
      field: 'расчетный месяц',
      text: 'Месяц деления',
      type: 'DateMonth',
    },
    {
      field: 'распррасход',
      text: 'Распределено',
      type: 'Number',
      computed: true,
    },
    {
      field: 'формула',
      text: 'Формула',
      type: 'String',
    },
    {
      field: 'история-услуга>название',
      text: 'По услуге',
      type: 'String',
    },
  ],
};
export default grcount;
