
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },
  computed: {
    title(): string {
      return this && this.record && this.record['фаза-состояние>название'] ? this.record['фаза-состояние>название'] : '';
    },
    isReadOnly(): string {
      return this.readonly || this.record.$толькоЧтение;
    },
    DisableField(): any {
      const Disable = [] as any;
      if (this && this.record && this.record['@разрешитьэлемент']) {
        const arrr = this.record['@разрешитьэлемент'].toString().split(',');
        for (const row of arrr) {
          Disable[row] = true;
        }
      }
      if (this && this.record && this.record['@запретитьэлемент']) {
        const arrd = this.record['@запретитьэлемент'].toString().split(',');
        for (const row of arrd) {
          Disable[row] = false;
        }
      }
      return Disable;
    },
  },
});
