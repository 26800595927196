
import Vue from 'vue';
import DeductionPrintBtn from './DeductionPrintBtn.vue';

export default Vue.extend({
  components: { DeductionPrintBtn },
  props: {
    params: { type: Object, required: true },
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    selectedItems: { type: [Object, Array], required: true }, // выбранные записи
  },
  data() {
    return {
      jobID: null,
      clearDialogVis: false,
    };
  },
  methods: {
    async onClick() {
      await new this.$HttpModel('ВедомостиУдержаний').executeMethod(
        'СоздатьПлатежныеВедомости',
        { записи: this.selectedItems },
        {
          async: true,
          jobName: 'Создание платежных ведомостей',
          onComplete: () => {
            this.tableAction({ action: 'reload' });
            this.tableAction({ action: 'unselect-all' });
          },
          onError: () => {
            this.tableAction({ action: 'reload' });
            this.tableAction({ action: 'unselect-all' });
          },
        },
      );
    },
  },
});
