
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      phazeID: null as number | null,
      item: {
        лицевой: null,
        ответственный: null,
        тип: null,
        номер: null,
        дата: null,
        датаначалас: null,
        датаначалапо: null,
        суммас: null,
        суммапо: null,
        статус: 0,
        готовы: null,
      } as any,
      rules: {
        checkSumm: (value1: number, value2: number): string | true => {
          return !value2 || value2 >= value1 || `Диапазон сумм задан неверно`;
        },
      },
    };
  },
});
