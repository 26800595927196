
import Vue from 'vue';
import addressForm from '@/tasks/common/views/$common/addressForm.vue';

export default Vue.extend({
  name: 'AddressTab',
  components: { addressForm },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    ownerID(): number {
      return this.record && this.record.$номерЗаписи;
    },
  },
  created() {
    this.splitAddress();
  },
  data() {
    return {
      address: { индекс: 0, область: null, район: null, город: null, наспункт: null, планструктура: null, улица: null, дом: null, квартира: null, комната: null, initPostcode: false },
      radio: this.record['счет-доставка'] > 0 ? 0 : 1,
      flagParent: false,
      flagChild: false,
      flagThis: false,
      isChangeLS: false,
      rules: {
        linkRules: (value: boolean): string | true => {
          return !value || 'Выбранный лицевой имеет заполненный адрес доставки!';
        },
        linkRulesThis: (value: boolean): string | true => {
          return !value || 'Выбран текущий ЛС!';
        },
        radioRules: (value: number, flag: boolean): string | true => {
          return value === 1 || (value === 0 && !flag) || 'На текущий адрес происходит доставка с других лицевых!';
        },
      },
    };
  },
  methods: {
    splitAddress() {
      this.address.initPostcode = false;
      const str = this.record.адресдоставки;
      const arr = str ? str.split(',') : [];

      if (arr) {
        this.address.район = (arr[0] && arr[0].trim()) || null;
        this.address.город = (arr[1] && arr[1].trim()) || null;
        this.address.наспункт = (arr[2] && arr[2].trim()) || null;
        this.address.планструктура = (arr[3] && arr[3].trim()) || null;
        this.address.улица = (arr[4] && arr[4].trim()) || null;
        this.address.дом = (arr[5] && arr[5].replace('д.', '').trim()) || null;
        this.address.квартира = (arr[6] && arr[6].replace('кв.', '').trim()) || null;
        this.address.комната = (arr[7] && arr[7].trim()) || null;
      }

      const postcode = this.record.индексдоставки;
      this.address.индекс = postcode || 0;

      const region = this.record.областьдоставки;
      this.address.область = region || null;
    },
    // на этот лицевой ссылаются другие лицевые
    isFlagParent(event: StackTableRow) {
      const eventLength = (event && event !== undefined ? event.length : 0) as number;
      this.flagParent = eventLength > 0;
    },
    initLS(event: number[]) {
      const objectEvent = (event && event[0] ? event[0] : {}) as StackTableRow;
      if (objectEvent && objectEvent.$номерЗаписи && this.isChangeLS) {
        const outLS = (objectEvent['счет-доставка'] ? objectEvent['счет-доставка'] : -1) as number;
        this.isFlagChild(+objectEvent.$номерЗаписи, +outLS);
        this.getAddress(+objectEvent.$номерЗаписи);
        this.isChangeLS = false;
      }
    },
    // выбранный лицевой ссылается на другие лицевые
    isFlagChild(id: number, outID: number) {
      this.flagThis = false;
      this.flagChild = false;
      if (id === this.record.$номерЗаписи) {
        this.flagThis = true;
      } else {
        this.flagChild = outID > 0;
      }
    },
    // при выборе лицевого запрашиваем его адрес
    async getAddress(id: number) {
      const data = await new this.$HttpModel('ЛицевыеСчета.ПолучитьАдресДоставки').getRecords({ лицевой: id });
      this.record.индексдоставки = data[1] && data[1].значение ? data[1].значение : 0;
      this.record.областьдоставки = '';
      this.record.адресдоставки = data[0] && data[0].значение ? data[0].значение : '';

      this.splitAddress();
    },
    changeRadio(event: number) {
      if (event === 1) {
        this.record['счет-доставка'] = -1;
        this.flagChild = false;
        this.flagThis = false;
      }
    },
  },
  watch: {
    address: {
      handler(val: any) {
        const district = val.район;
        const city = val.город;
        const locality = val.наспункт;
        const street = val.улица;
        const house = val.дом;
        const flat = val.квартира;
        const room = val.комната;
        const planStruct = val.планструктура;

        let str = '';
        if (district || city || locality || street || house || flat || room || planStruct) {
          const arr = [];
          arr.push(district ? district.trim() : '');
          arr.push(city ? city.trim() : '');
          arr.push(locality ? locality.trim() : '');
          arr.push(planStruct ? planStruct.trim() : '');
          arr.push(street ? street.trim() : '');
          arr.push(house ? 'д. ' + house.trim().padStart(5 + (house.trim().length - house.trim().search(/[^0-9]/))) : '');
          arr.push(flat ? 'кв. ' + flat.trim().padStart(5) : '');
          arr.push(room ? room.trim() : '');
          str = arr.join(', ');
        }

        this.record.адресдоставки = str;
        const region = this.address.область;
        this.record.областьдоставки = region || '';
        const postcode = val.индекс;
        this.record.индексдоставки = postcode || '';
      },
      deep: true,
    },
    ownerID() {
      this.splitAddress();
      this.radio = this.record['счет-доставка'] > 0 ? 0 : 1;
    },
  },
});
