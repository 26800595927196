const vzdelo: StackSelection = {
  objectName: 'Соглашение_взыскание',
  description: 'Взыскания',
  fields: [
    {
      field: 'дата документа',
      text: 'Дата документа',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'название',
      text: 'Фаза',
      type: 'String',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'Date',
    },
  ],
};
export default vzdelo;
