
import Vue from 'vue';

import { TableHandlers } from '@/stackEngine';

export default Vue.extend({
  name: 'DistributorTab',
  components: {},
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
    };
  },
  methods: {
    paramIsClosed(item: any) {
      TableHandlers.isClosed(item);
      return item;
    },
  },
});
