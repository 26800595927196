
import Vue from 'vue';
import AdrFilter from '@/tasks/fl/views/$common/filters/AdrFilter.vue';
import paramTable from '@/tasks/common/views/$common/paramTable.vue';
import serviceTable from '@/tasks/common/views/$common/serviceTable.vue';
import providerTable from '@/tasks/common/views/$common/providerTable.vue';

export default Vue.extend({
  components: { AdrFilter, paramTable, serviceTable, providerTable },
  computed: {
    textSwitch(): string {
      switch (this.type) {
        case 0:
          return 'С учетом значения';
        case 1:
          return 'С учетом состояния';
        default:
          return '';
      }
    },
    paramsWithoutDialog(): boolean {
      return this.action === 1 && !this.допУсловие;
    },
    headersParamTable(): string {
      return this.paramsWithoutDialog ? 'наименование' : 'наименование,строка';
    },
  },
  data() {
    return {
      countFund: 0,
      countData: 0,
      dialogFund: false,
      level: 3,
      type: -1,
      oldType: 0,
      action: 0,
      compare: 0,
      isLoad: false,
      loading: false,
      допУсловие: false,
      hideType: true as boolean,
      dateStart: this.$store.getters.getCurrentDate(),
      dateEnd: `2045-05-09T00:00:00`,
    };
  },
  async created() {
    await new this.$HttpModel(`МассовыйВвод`).executeMethod('Очистить');
    this.isLoad = true;
    this.hideType = true;
  },
  methods: {
    async addFund(adrItems: StackTableRow[]) {
      this.dialogFund = false;
      if (adrItems.length > 0) {
        try {
          await new this.$HttpModel('МассовыйВвод.Адреса').executeMethod('ДобавитьФонд', { фонд: adrItems, уровень: this.level });
          this.$sendMsg('stack-table', 'МассовыйВвод.Адреса', 'reload');
        } catch {
          // ошибка
        }
      }
    },
    async changeLevel(value: number) {
      if (this.countFund) {
        await new this.$HttpModel('МассовыйВвод.Адреса').executeMethod('ПеренабратьФонд', { уровень: this.level });
        this.$sendMsg('stack-table', 'МассовыйВвод.Адреса', 'reload');
      }
    },
    changeType(value: number) {
      let table = '';
      switch (this.oldType) {
        case 0:
          table = 'Параметры';
          break;
        case 1:
          table = 'Услуги';
          break;
        case 2:
          table = 'Поставщики';
          break;
      }
      new this.$HttpModel(`МассовыйВвод.${table}`).executeMethod('Очистить');
      this.oldType = value;
    },
    changeAdrTable(items: StackTableRow[]) {
      this.countFund = items ? items.length : 0;
    },
    changeTable(items: StackTableRow[]) {
      this.countData = items ? items.length : 0;
    },
    async inputAddr() {
      let answer = await this.$yesno(`Вы желаете внести ${this.countData} записей в ${this.countFund} выбранных объектов ?`);
      if (!answer) {
        return;
      }
      this.loading = true;
      try {
        await new this.$HttpModel('МассовыйВвод').executeMethod('Внести', {
          уровень: this.level,
          тип: this.type,
          действие: this.action,
          допусловие: this.допУсловие,
          равенство: this.compare,
          датнач: this.dateStart,
          даткнц: this.dateEnd,
        });
      } catch {
        this.loading = false;
        return;
      }
      this.loading = false;
      answer = await this.$yesno(`Продолжить работу с выбранным фондом ?`);
      if (!answer) {
        new this.$HttpModel(`МассовыйВвод`).executeMethod('Очистить');
        this.hideType = true;
        this.type = -1;
      }
      this.isLoad = false;
      await new this.$HttpModel(`МассовыйВвод.Параметры`).executeMethod('Очистить');
      await new this.$HttpModel(`МассовыйВвод.Услуги`).executeMethod('Очистить');
      await new this.$HttpModel(`МассовыйВвод.Поставщики`).executeMethod('Очистить');
      this.isLoad = true;
    },
    ShowType() {
      if (this.countFund) {
        this.hideType = false;
      }
    },
  },
});
