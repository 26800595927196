const par: StackSelection = {
  objectName: 'БлокировкиЗаписейПользователя',
  fields: [
    {
      field: 'имятаблицы',
      text: 'Имя таблицы',
      type: 'String',
    },
    {
      field: 'пользователь',
      text: 'Логин',
      type: 'String',
    },
    {
      field: 'фио',
      text: 'ФИО Пользователя',
      type: 'String',
    },
    {
      field: 'создана',
      text: 'Создана',
      type: 'DateTime',
    },
    {
      field: 'комментарий',
      text: 'Комментарий',
      type: 'String',
    },
    {
      field: 'url',
      text: 'Ссылка',
      type: 'String',
    },
  ],
};

export default par;
