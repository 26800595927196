const reports: StackSelection = {
  objectName: 'МакетыКвитанций.ДоговорыУК',
  description: '',
  fields: [
    {
      field: 'укмакет',
      text: 'укмакет',
      type: 'Link',
    },
    {
      field: 'укдоговор',
      text: 'укдоговор',
      type: 'Link',
    },
    {
      field: 'укдоговор>номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'укдоговор>тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'укдоговор>примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'укдоговор>датнач',
      text: 'Дата начала',
      type: 'Date',
    },
    {
      field: 'укдоговор>даткнц',
      text: 'Дата окончания',
      type: 'Date',
    },
  ],
};
export default reports;
