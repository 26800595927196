
import Vue from 'vue';
import InstDateForm from './counterid/InstDateForm.vue';
import PropsTable from './counterid/PropsTab.vue';
import SealsToolbar from './counterid/SealsToolbar.vue';
import PlombDialog from './counterid/$dialogs/PlombDialog.vue';
import FileTable from '@/tasks/common/views/$common/fileTable.vue';

export default Vue.extend({
  components: { InstDateForm, PropsTable, FileTable, SealsToolbar, PlombDialog },
  model: { prop: 'record' },
  props: {
    dataModel: { type: String, required: true },
    record: { type: Object, required: true },
    ownerID: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      measuretype: {} as any,
      rules: {
        isTrans: (value: number): string | true => {
          switch (this.record.идентификатор) {
            case 1:
              return value === 1 || 'Выберите трансформатор тока';
            case 2:
              return value === 2 || 'Выберите силовой трансформатор';
            case 3:
              return value === 3 || 'Выберите трансформатор напряжения';
            default:
              return 'Выберите трансформатор';
          }
        },
      },
    };
  },
  computed: {
    title(): string {
      switch (this.record.идентификатор) {
        case 1:
          return 'Трансформатор тока';
        case 2:
          return 'Трансформатор силовой';
        case 3:
          return 'Трансформатор напряжения';
        default:
          return '';
      }
    },
    isConsiderPlombs(): boolean {
      return this.$store.getters['flStore/getConsiderPlombs']();
    },
  },
  methods: {
    onSelectMeasure(payload: any) {
      if (payload && payload[0]) {
        this.measuretype = payload[0];
      }
    },
  },
});
