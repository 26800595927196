const minp: StackSelection = {
  objectName: 'МассовыйВвод.Параметры',
  description: 'Параметры для простановки данных',
  fields: [
    {
      field: 'виды-параметры',
      text: 'Виды-Параметры',
      type: 'Number',
    },
    {
      field: 'значение',
      text: 'Значение',
      type: 'Number',
    },
    {
      field: 'знач2',
      text: 'Знач2',
      type: 'Number',
    },
    {
      field: 'знач3',
      text: 'Знач3',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
    {
      field: 'строка',
      text: 'Значение ',
      type: 'String',
    },
    {
      field: 'датнач',
      text: 'Дата начала',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Дата окончания',
      type: 'Date',
    },
  ],
};

export default minp;
