const lg: StackSelection = {
  objectName: 'НастройкиАрмЮриста',
  description: 'НастройкиАрмЮриста',
  fields: [
    {
      field: 'пени-услуга',
      text: 'пени',
      type: 'Number',
    },
    {
      field: 'пошлина-услуга',
      text: 'пошлина',
      type: 'Number',
    },
    {
      field: 'прочие-услуга',
      text: 'прочие',
      type: 'Number',
    },
    {
      field: 'подключение-услуга',
      text: 'подключение',
      type: 'Number',
    },
    {
      field: 'отключение-услуга',
      text: 'пени',
      type: 'Number',
    },
    {
      field: 'списание-услуга',
      text: 'списание',
      type: 'Number',
    },
    {
      field: 'списание',
      text: 'Вид списания',
      type: 'Number',
    },
  ],
};

export default lg;
