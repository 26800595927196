import { TableHandlers } from '@/stackEngine';

const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.КарточкиРегистрации',
  recordRoute: 'accounts-accountsid-residents-residentsid',
  description: 'Жильцы',
  // openLink: { name: 'accounts-accountsid', hash: '#people' }, // если открывать с нанимателя при новой записи new то будет новая запись new

  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    // @ts-ignore
    row.$data.фио = { $иконка: '$vuetify.icons.person_outline' };

    if (row['дата выписки'] && Date.parse(row['дата выписки'] as string) <= Date.parse(new Date().toString())) {
      TableHandlers.trColor(row, 'warning', 'darken-2');
    } else {
      switch (row['@видрегистрации']) {
        case 'Временная РФ':
        case 'Временная ин.':
          TableHandlers.trColor(row, 'purple');
          break;
        case 'Без прописки':
          TableHandlers.trColor(row, 'error');
          break;
        default:
          if (!row['дата прописки']) {
            TableHandlers.trColor(row, 'error');
          }
      }
    }

    return row;
  },
  fields: [
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'дата рождения',
      text: 'Дата рождения',
      type: 'Date',
    },
    {
      field: 'пол',
      text: 'Пол',
      type: 'String',
    },
    {
      field: 'человек-родство',
      text: 'Человек-родство',
      type: 'String',
    },
    {
      field: 'человек-родство>название',
      text: 'Родство',
      type: 'String',
    },
    {
      field: 'человек-гражданство',
      text: 'Человек-гражданство',
      type: 'String',
    },
    {
      field: 'человек-гражданство>название',
      text: 'Гражданство',
      type: 'String',
    },
    {
      field: 'пенсионное свидетельство',
      text: 'Пенсионное свидетельство',
      type: 'String',
    },
    {
      field: 'инн',
      text: 'ИНН',
      type: 'String',
    },
    {
      field: 'кпп',
      text: 'КПП',
      type: 'String',
    },
    {
      field: 'огрн',
      text: 'ОГРН',
      type: 'String',
    },
    {
      field: 'вид регистрации',
      text: 'Вид регистрации',
      type: 'Enum',
    },
    {
      field: '@видрегистрации',
      text: 'Вид регистрации',
      type: 'Enum',
      computed: true,
    },
    {
      field: 'дата прописки',
      text: 'Дата прописки',
      type: 'Date',
    },
    {
      field: 'основание выписки',
      text: 'Основание выписки',
      type: 'Number',
    },
    {
      field: '@основаниевыписки',
      text: 'Основание выписки',
      type: 'String',
      computed: true,
    },
    {
      field: 'дата выписки',
      text: 'Дата выписки',
      type: 'Date',
    },
    {
      field: 'основание',
      text: 'Основание',
      type: 'Number',
    },
    {
      field: 'расчетныепризнаки',
      text: 'РасчетныеПризнаки',
      type: 'Number',
    },
    {
      field: 'дата смерти',
      text: 'Дата смерти',
      type: 'Date',
    },
    {
      field: 'направление выплаты',
      text: 'Направление выплаты',
      type: 'Number',
    },
    {
      field: 'банк-льгота',
      text: 'Банк-льгота',
      type: 'Number',
    },
    {
      field: 'льгота - номер счета',
      text: 'Льгота - номер счета',
      type: 'String',
    },
    {
      field: 'льгота - вид вклада',
      text: 'Льгота - вид вклада',
      type: 'String',
    },
    {
      field: 'код льготополучателя',
      text: 'Код льготополучателя',
      type: 'String',
    },
    {
      field: 'флаги',
      text: 'Флаги',
      type: 'Number',
    },
    {
      field: 'email',
      text: 'email',
      type: 'String',
    },
    {
      field: 'собственник',
      text: 'Собст',
      type: 'Number',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'String',
    },
    {
      field: 'типжильца',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: '@фамилия',
      text: 'Фамилия',
      type: 'String',
    },
    {
      field: '@имя',
      text: 'Имя',
      type: 'String',
    },
    {
      field: '@отчество',
      text: 'Отчество',
      type: 'String',
    },
    {
      field: 'номерлс',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: '@местоработы',
      text: 'Место работы',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
  ],
};
export default accs;
