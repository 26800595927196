
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'Представитель' },
  },
  data() {
    return {
      initPerson: {
        inn: null,
        birth: null,
      } as any,
      loading: false,
      dataModel: new this.$HttpModel('Организации.Представители'),
    };
  },
  methods: {
    changePerson(payload: any) {
      if (payload && payload[0]) {
        this.$set(this.initPerson, 'inn', payload[0].инн);
        this.$set(this.initPerson, 'birth', payload[0]['дата рождения']);
      }
    },
    async addLoginPass() {
      if (this.record && this.record.фио !== '') {
        this.loading = true;
        try {
          const res = await this.dataModel.executeMethod('СгенеритьПарольДляЧЛ', { номерЗаписи: this.record.$номерЗаписи, фио: this.record.фио });
          this.record['логин'] = res.Логин;
          this.record['пароль'] = res.Пароль;
          if (await this.$yesno(`Распечатать пароль?`, 'Внимание', { width: '600px' })) {
            await this.dataModel.executeMethod(
              'ПечатьПароля',
              { запись: this.record },
              {
                async: true,
                jobName: 'Печать пароля',
                type: 'report',
              },
            );
          }
        } catch (error: AnyException) {
          this.$toast(error.message);
        }
        this.loading = false;
      } else {
        this.$toast('Не заполнены Ф.И.О. для логина');
      }
    },
    async deleteLoginPass() {
      if (await this.$yesno(`Вы действительно хотите удалить логин и пароль?`, 'Внимание', { width: '600px' })) {
        this.record.логин = '';
        const res = await new this.$HttpModel('Организации.Представители').executeMethod('УдалитьПарольДляЧЛ', { номерЗаписи: this.record.$номерЗаписи });
      }
    },
    async showPartyDialog(id: number) {
      const routeData = this.$router.resolve({ path: `/references/people/${id}` });
      window.open(routeData.href, '_blank');
    },
  },
});
