const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.Перерасчеты.Детально',
  description: '',
  fields: [
    {
      field: 'услуга',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'номеруслугиродитель',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'названиеуслугиродитель',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'аналитика1',
      text: 'Аналитика1',
      type: 'Number',
    },
    {
      field: 'тариф',
      text: 'Тариф',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: 'Сумма,₽',
      type: 'Money',
    },
    {
      field: 'льгота',
      text: 'Льгота',
      type: 'Number',
    },
    {
      field: 'объем',
      text: 'Объем,V',
      type: 'Number',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'String',
    },
    {
      field: 'поставщик',
      text: 'Поставщик',
      type: 'Number',
    },
    {
      field: 'месяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'аналитика2',
      text: 'Аналитика',
      type: 'Number',
    },
    {
      field: 'аналитикастр',
      text: 'Аналитика',
      type: 'String',
    },
    {
      field: 'поставщикстр',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'номердог',
      text: 'Договор',
      type: 'String',
    },
    {
      field: 'ук',
      text: 'Исполнитель',
      type: 'String',
    },
  ],
};
export default accs;
