
import Vue from 'vue';
import numberTypeFilter from '@/tasks/fl/views/$common/filters/numberTypeFilter.vue';
import addressFilter from '@/tasks/fl/views/$common/filters/addressFilter.vue';

export default Vue.extend({
  components: { numberTypeFilter, addressFilter },
  data() {
    return {
      item: {
        номер: null,
        нп: null,
        улица: null,
        дом: null,
        квартира: null,
        датнач: null,
        даткнц: null,
        фаза: null,
        видработ: null,
        обслуживающая: null,
        исполнитель: null,
        датначвып: null,
        даткнцвып: null,
        закрытые: 0,
        просроченные: 0,
        карточка: null,
        номерлс: null,
        подразделение: null,
      } as any,
    };
  },
});
