
import Vue from 'vue';
import { ReportsBuilder } from '@/stackEngine';
import FileDialog from '@/tasks/common/views/$common/_fileDialog.vue';

export default Vue.extend({
  components: { FileDialog },
  data() {
    return {
      selectedRequest: [] as any,
      tabs: null,
    };
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    patchedBody() {
      return this.record['@телоhtml'].replace('%upload_host%', this.$store.getters.getApiHostUpload());
    },
    isContactcenter(): boolean {
      return this.$store.getters.getCurrentTask() === 'contactcenter';
    },
    isRequestCreated(): boolean {
      return this.record['@обращениякц'] !== '';
    },
    isAdmin(): boolean {
      return this.$store.getters.isUserAdmin();
    },
  },
  methods: {
    async onCreateRequest() {
      await new this.$HttpModel('РеестрОбращений').executeMethod(
        'СоздатьОбращенияПоПисьмам',
        {
          письма: [this.record['$номерЗаписи']],
        },
        {
          async: true,
          jobName: 'Создаем обращения',
          onComplete: () => (this.record['@обращениякц'] = '-'),
        },
      );
    },
    async onAddToRequest() {
      await new this.$HttpModel('РеестрОбращений').executeMethod(
        'ДобавитьПисьмоВОбращение',
        {
          письмо: this.record['$номерЗаписи'],
          обращение: this.selectedRequest[0].$номерЗаписи,
        },
        {
          async: true,
          jobName: 'Добавляем письмо в обращение',
          onComplete: () => (this.record['@обращениякц'] = this.selectedRequest[0].номер),
        },
      );
    },
    async onRemoveFromRequest() {
      await new this.$HttpModel('РеестрОбращений').executeMethod(
        'убратьПисьмоИзОбращения',
        {
          письмо: this.record['$номерЗаписи'],
        },
        {
          async: true,
          jobName: 'Открепляем обращение',
          onComplete: () => (this.record['@обращениякц'] = ''),
        },
      );
    },
    onPrintMessage() {
      new ReportsBuilder('Электронное письмо').executeReport2('Электронное письмо', { идПисьма: this.record.$номерЗаписи }, { title: `Электронное письмо` });
    },
    onOpenRequest(item: StackTableRow) {
      const routeData = this.$router.resolve({ path: `/requestlog/${item.$номерЗаписи}` });
      window.open(routeData.href, '_blank');
    },
  },
});
