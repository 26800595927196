const par: StackSelection = {
  objectName: 'Параметры объектов',
  description: 'Параметры объектов',
  hier: true,
  openLink: 'references-parametersobj',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'имя',
      text: 'Имя',
      type: 'String',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: '@тип',
      text: 'Тип',
      type: 'String',
      computed: true,
    },
  ],
};

export default par;
