const par: StackSelection = {
  objectName: 'ФильтрыЛС.Результат',
  fields: [
    {
      field: 'row_id',
      text: 'Идентификатор уникальный в пределах фильтра',
      type: 'Number',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'описаниелс',
      text: 'Лицевой счет',
      type: 'String',
    },
    {
      field: 'результат-фильтр',
      text: 'Ссылка на фильтр',
      type: 'Link',
    },
    {
      field: 'результат-лицевой',
      text: 'Параметр',
      type: 'Link',
    },
  ],
};

export default par;
