
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: 'Имя',
          text: 'Имя',
          type: 'String',
          placeholder: 'Т_ВН',
        },
        {
          field: 'Наименование',
          text: 'Наименование',
          type: 'String',
        },
        {
          field: '@Внут',
          text: 'Значение',
          type: 'Number',
        },
      ],
    };
  },
});
