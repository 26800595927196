import { TableHandlers } from '@/stackEngine';

const sch: StackSelection = {
  objectName: 'ЛицевыеСчета.Объекты',
  description: 'Приборы учёта',
  prepareRow: row => {
    TableHandlers.isClosed(row);
    return row;
  },

  fields: [
    {
      field: 'n п/п',
      text: '№ п/п',
      type: 'String',
    },
    {
      field: 'номенклатура-объекты>наименование',
      text: 'Тип счетчика',
      type: 'String',
    },
    {
      field: 'номенклатура-объекты',
      text: 'Номенклатура',
      type: 'Number',
    },
    {
      field: 'объект-услуга>номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'объект-услуга>наименование',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'объект-услуга>название',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'заводскойномер',
      text: 'Заводской номер',
      type: 'String',
    },
    {
      field: 'коэффициент трансформации',
      text: 'КТР',
      type: 'Number',
    },
    {
      field: 'тарифность',
      text: 'Тарифность',
      type: 'Number',
    },
    {
      field: 'доступ',
      text: 'Доступ',
      type: 'String',
    },
    {
      field: 'разрядность',
      text: 'Разрядность',
      type: 'Number',
    },
    {
      field: 'дробнаяразрядность',
      text: 'Дробная разрядность',
      type: 'Number',
    },
    {
      field: 'датаповерки',
      text: 'Дата поверки',
      type: 'Date',
    },
    {
      field: 'номерпломбы',
      text: 'Номер пломбы',
      type: 'String',
    },
    {
      field: 'годвыпуска',
      text: 'Год выпуска',
      type: 'Date',
    },
    {
      field: 'место установки',
      text: 'Место установки',
      type: 'Enum',
    },
    {
      field: 'датнач',
      text: 'Установлен',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Снят',
      type: 'Date',
    },
    {
      field: 'объект-услуга',
      text: 'объект-услуга',
      type: 'Link',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'причинаснятия',
      text: 'ПричинаСнятия',
      type: 'Enum',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: 'состояниестрока',
      text: 'Текущее состояние',
      type: 'Enum',
      computed: true,
    },
    {
      field: 'номерлс',
      text: 'номерлс',
      type: 'Number',
    },
    {
      field: 'идентификатор',
      text: ' ',
      type: 'Number',
    },
    {
      field: 'типпу',
      text: 'Тип',
      type: 'Enum',
      computed: true,
    },
    {
      field: 'датаследующейповерки',
      text: 'Дата Следующей Поверки',
      type: 'Date',
    },

  ],
};
export default sch;
