import Vue from 'vue';
/**
 * для разблокировки подэлементов у форм ввода
 * переопределяем методы v-form у Vuetify
 */
export default Vue.extend({
  provide: {
    form: {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      register: () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      unregister: () => {},
    },
  },
});
