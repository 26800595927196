
import Vue from 'vue';
import debugDialog from './$debugDialog.vue';
import connectionInfoDialog from './$connectionInfoDialog.vue';
import blockingCalcDialog from './$blockingCalcDialog.vue';
import calcCloseDialog from './$calcCloseDialog.vue';
import updateVerNsiDialog from './$updateVerNsiDialog.vue';
import { HttpModel, store, stackAppEmitter } from '@/stackEngine';

export default Vue.extend({
  components: { debugDialog, connectionInfoDialog, blockingCalcDialog, calcCloseDialog, updateVerNsiDialog },
  data() {
    return {
      debugDialogVis: false,
      connectionInfoDialogVis: false,
      changeBlockingCalcVis: false,
      calcClosedMonthsVis: false,
      updateVerNsiVis: false,
    };
  },
  computed: {
    items(): any[] {
      return [
        { title: 'Данные подключения', img: 'srv_connectinfo', id: 1 },
        { title: 'Выполнить функцию', img: 'srv_execfunc', to: { name: 'admsrv-execfunc' }, color: 'stack' },
        { title: 'Отладчик', img: 'srv_debugserver', color: 'stack', id: 2 },
        { title: 'Тестирование запросов', img: 'srv_requesttest', to: { name: 'admsrv-requesttest' }, color: 'stack' },
        { title: 'Сменить URL Backend`а', img: 'srv_change_backend_url', color: 'stack', id: 3 },
        { title: 'Смена блокировки расчета', img: 'srv_blockrasch', color: 'stack', id: 4 },
        { title: 'Расчет сальдо в закрытых периодах', img: 'srv_saldo_closed_month', color: 'stack', id: 5 },
        { title: 'Реестр многопроцессных операций', img: 'other_reestr_multi_operations', to: { name: 'admsrv-multiprocreesters' }, color: 'stack' },
        { title: 'Обновление версии НСИ', img: 'other_reestr_multi_operations', color: 'stack', id: 6 },
        { title: 'Юнит-тесты', img: 'srv_requesttest', to: { name: 'admsrv-unittest' }, color: 'stack' },
        { title: 'Список лог файлов программы', img: 'srv_requesttest', to: { name: 'admsrv-logs' }, color: 'stack' },
      ];
    },
  },
  methods: {
    async onClick(event: number) {
      if (this.items[event]?.id === 1) {
        this.connectionInfoDialogVis = true;
      }
      if (this.items[event]?.id === 2) {
        this.debugDialogVis = true;
      }
      if (this.items[event]?.id === 3) {
        this.$store.commit('SET_URL_DIALOG_STATE', true);
      }
      if (this.items[event]?.id === 4) {
        this.changeBlockingCalcVis = true;
      }
      if (this.items[event]?.id === 5) {
        this.calcClosedMonthsVis = true;
      }
      if (this.items[event]?.id === 6) {
        const isTrust = await new HttpModel('ОбновлениеВерсииНСИ').executeMethod('ПроверкаДоверительногоРежима');
        if (isTrust) {
          this.updateVerNsiVis = true;
        } else {
          await (new HttpModel('ОбновлениеВерсииНСИ')).executeMethod(
            'ОбновитьВерсию',
            {},
            { async: true, jobName: 'Обновление локальной версии' },
          );
        }
      }
    },
  },
});
