const ved: StackSelection = {
  recordRoute: 'metersdata-problemstatements-problemstatementsid',
  objectName: 'ВедомостиСтороннихПоказаний',
  description: 'Сторонние показания',
  hier: true,
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчмесяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'автор',
      text: 'Автор',
      type: 'String',
      computed: true,
    },
    {
      field: 'примечание',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'кол_во',
      text: 'Показаний',
      type: 'Number',
    },
    {
      field: 'сумма1',
      text: 'Ошибочных',
      type: 'Number',
    },
    {
      field: 'сумма2',
      text: 'Расчетных',
      type: 'Number',
    },
    {
      field: 'источник-платежи',
      text: 'Источник',
      type: 'Number',
    },
    {
      field: 'дата создания',
      text: 'Дата создания',
      type: 'DateTime',
    },
  ],
};
export default ved;
