const Citizenship: StackSelection = {
  objectName: 'Гражданство',
  description: 'Гражданство',
  openLink: 'references-citizenships',
  fields: [
    {
      field: 'название',
      text: 'Гражданство',
      type: 'String',
    },
  ],
};
export default Citizenship;
