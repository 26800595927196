
import Vue from 'vue';
import logsFilter from '@/tasks/common/views/$common/filters/logsFilter.vue';

export default Vue.extend({
  name: 'HistoryLsTab',
  components: { logsFilter },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
});
