
import Vue from 'vue';
import ModalParam from './$dialogs/dialogOrganizationParam.vue';
import ModalRs from './$dialogs/dialogOrganizationRS.vue';
import ModalPeople from './$dialogs/dialogOrganizationPeople.vue';
import ModalMessage from './$dialogs/dialogOrganizationMessage.vue';
import ModalPatterns from './$dialogs/dialogOrganizationPatterns.vue';
import ContactsTab from './$organizations/ContactsTab.vue';
import ModalDepartment from './$dialogs/dialogOrganizationDepartment.vue';
import ModalSbp from './$dialogs/dialogOrganizationSbp.vue';

export default Vue.extend({
  props: {
    organizationsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
    organizationModel: { type: String, default: 'Организации' },
  },
  components: {
    ModalPeople,
    ModalRs,
    ModalParam,
    ModalDepartment,
    ContactsTab,
    ModalMessage,
    ModalPatterns,
    ModalSbp,
  },
  data() {
    return {
      record: {} as StackTableRow,
      int_id_disable: true,
      departmentDialog: false,
      checkMessage: false,
      sbpDialogVis: false,
      innSearchDialog: false,
    };
  },
  computed: {
    title(): string {
      return `Организация ${this.record.название || ''}`;
    },
    openHour(): string {
      return (this.record['режим работы'] as string) || '';
    },
    isPatternsTabVisible(): boolean {
      return this.$store.getters.getCurrentTask() === 'rsp';
    },
    isNewRecord(): boolean {
      return this.record && this.record.$номерЗаписи as number < 0;
    },
  },
  methods: {
    async onCloseDepDialog() {
      this.departmentDialog = false;
    },
    async onSelect(rows: StackTableRow[]) {
      if (rows) {
        const result = await new this.$HttpModel('Организации.ПраваНаОтчеты').executeMethod('ВнестиОтметку', {
          отметки: rows,
          организация: this.organizationsid,
        });
      }
    },
    // TODO
    modifyTable(items: StackTableRow[]) {
      if (items) {
        for (const item of items) {
          if (item.позиция_праванаотчеты !== -1 && item.флаги === 1) {
            // @ts-ignore
            this.$refs.reportstable.selectItem(item);
          }
        }
      }
    },
    async onBtnChooseOrg(payload: StackTableRow[]) {
      this.innSearchDialog = false;
      if (payload && payload[0]) {
        if (!this.isNewRecord) {
          if (!(await this.$yesno(`Меняем данные на загруженные?`, 'Внимание', { width: '600px' }))) {
            return;
          }
        }
        this.$set(this.record, 'название', payload[0].название);
        this.$set(this.record, 'наименование', payload[0].наименование);
        this.$set(this.record, 'кпп', payload[0].кпп);
        this.$set(this.record, 'инн', payload[0].инн);
        this.$set(this.record, 'огрн', payload[0].огрн);
        this.$set(this.record, 'адрес', payload[0].адрес);
        this.$set(this.record, 'фактадрес', payload[0].фактадрес);
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.checkMessage) {
      this.$toast('Проверьте актуальность сообщений!');
    }
    next();
  },
  watch: {
    'record.адрес': {
      handler(to, from) {
        if (to !== from && from && to) {
          this.checkMessage = true;
        }
      },
    },
    'record.www': {
      handler(to, from) {
        if (to !== from && from && to) {
          this.checkMessage = true;
        }
      },
    },
    'record.email': {
      handler(to, from) {
        if (to !== from && from && to) {
          this.checkMessage = true;
        }
      },
    },
    openHour: {
      handler(to, from) {
        if (to !== from && from && to) {
          this.checkMessage = true;
        }
      },
    },
  },
});
