
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: '@01',
          text: 'Янв',
          type: 'Boolean',
        },
        {
          field: '@02',
          text: 'Фев',
          type: 'Boolean',
        },
        {
          field: '@03',
          text: 'Март',
          type: 'Boolean',
        },
        {
          field: '@04',
          text: 'Апр',
          type: 'Boolean',
        },
        {
          field: '@05',
          text: 'Май',
          type: 'Boolean',
        },
        {
          field: '@06',
          text: 'Июнь',
          type: 'Boolean',
        },
        {
          field: '@07',
          text: 'Июль',
          type: 'Boolean',
        },
        {
          field: '@08',
          text: 'Авг',
          type: 'Boolean',
        },
        {
          field: '@09',
          text: 'Сент',
          type: 'Boolean',
        },
        {
          field: '@10',
          text: 'Окт',
          type: 'Boolean',
        },
        {
          field: '@11',
          text: 'Ноя',
          type: 'Boolean',
        },
        {
          field: '@12',
          text: 'Дек',
          type: 'Boolean',
        },
      ],
    };
  },
});
