const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.Льготники',
  recordRoute: 'accounts-accountsid-residentslgot-residentslgot',
  description: 'Льготники',
  fields: [
    {
      field: 'флаги',
      text: 'Флаги',
      type: 'Number',
    },
    {
      field: 'датнач',
      text: 'Дата начала',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Дата окончания',
      type: 'Date',
    },
    {
      field: 'вид-льготы',
      text: 'Вид-Льготы',
      type: 'Link',
    },
    {
      field: 'вид-льготы>номер льготы',
      text: '№',
      type: 'Number',
    },
    {
      field: 'вид-льготы>название',
      text: 'Льгота',
      type: 'String',
    },
    {
      field: 'число льготников',
      text: 'КолП',
      type: 'Number',
    },
    {
      field: 'дата рождения',
      text: 'Дата рождения',
      type: 'Date',
    },
    {
      field: 'пенсионное свидетельство',
      text: 'Пенсионное свидетельство',
      type: 'String',
    },
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'дата выдачи 1',
      text: 'Дата выдачи 1',
      type: 'Date',
    },
    {
      field: 'вид документа 1',
      text: 'Вид документа 1',
      type: 'Number',
    },
    {
      field: 'вид документа 2',
      text: 'Вид документа 2',
      type: 'Number',
    },
    {
      field: 'дата выдачи 1',
      text: 'Дата выдачи 1',
      type: 'Date',
    },
    {
      field: 'дата выдачи 2',
      text: 'Дата выдачи 2',
      type: 'Date',
    },
    {
      field: 'n п/п',
      text: 'N п/п',
      type: 'Number',
    },
    {
      field: 'место выдачи 1',
      text: 'Место выдачи 1',
      type: 'Link',
    },
    {
      field: 'место выдачи 2',
      text: 'Место выдачи 2',
      type: 'Link',
    },
    {
      field: 'сместо выдачи 1',
      text: 'СМесто выдачи 1',
      type: 'String',
    },
    {
      field: 'сместо выдачи 2',
      text: 'СМесто выдачи 2',
      type: 'String',
    },
    {
      field: 'серия 1',
      text: 'Серия 1',
      type: 'String',
    },
    {
      field: 'серия 2',
      text: 'Серия 2',
      type: 'String',
    },
    {
      field: 'документ',
      text: 'Документ',
      type: 'String',
    },
    {
      field: 'почтовый индекс',
      text: 'Почтовый индекс',
      type: 'String',
    },
    {
      field: 'номерлс',
      text: 'Номер',
      type: 'Number',
    },
  ],
};
export default accs;
