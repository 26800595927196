
import Vue from 'vue';

export default Vue.extend({
  name: 'Phone',
  props: {
    params: { type: Object, default: null },
  },
  data() {
    return {
      record: {
        phone: '',
      },
    };
  },
});
