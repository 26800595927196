
import Vue from 'vue';
import MainMenu from './MainMenu/MainMenu.vue'; // главное меню
import loginDialog from './Login.vue'; // модалка с логином
import AppToolbar from './AppToolbar/AppToolbar.vue'; // верхний тулбар
import StackAppTour from './StackAppTour/StackAppTour.vue'; // Интерактивный хелп
import LicenseDialog from './RegisterLicenseDialog.vue';
import CheckBackendConnect from './CheckBackendConnect.vue';
import HelpNavigationDrawer from './HelpNavigationDrawer.vue'; // Помощь
import BlockedRecordsDialog from './BlockedRecordsDialog.vue';
import StackAppUrlDialog from './StackAppUrlDialog.vue';
import helpHotkeys from './helpHotkeys.vue';
import StackAppNews from './StackAppNews.vue';
import StackAppFooter from './StackAppFooter.vue';

export default Vue.extend({
  components: {
    MainMenu,
    AppToolbar,
    loginDialog,
    CheckBackendConnect,
    LicenseDialog,
    StackAppTour,
    HelpNavigationDrawer,
    BlockedRecordsDialog,
    StackAppUrlDialog,
    StackAppNews,
    StackAppFooter,
    helpHotkeys,
  },
  data() {
    return {
      helpDial: false,
    };
  },
  computed: {
    userName(): string {
      return this.$store.getters.getUserName();
    },
    urlDialogState(): number {
      return this.$store.state.configStore.urlDialogState;
    },
    getBlockedRecords(): number {
      return this.$store.getters.getBlockedRecords();
    },
    isHelpVisible(): boolean {
      return this.$store.getters.isHelpVisible();
    },
    getBusyStatusMsg(): boolean {
      return this.$store.getters.getBusyStatusMsg();
    },
    isTourEnable(): boolean {
      return this.$store.getters.isTourEnable();
    },
    isAppReady(): boolean {
      return this.$store.getters.isAppReady();
    },
    isCurrentTaskExternal(): boolean {
      return this.$store.getters.isCurrentTaskExternal();
    },
    isAuth(): boolean {
      return this.$store.getters.isAuth();
    },
    isBackendConnected(): boolean {
      return this.$store.getters.isBackendConnected();
    },
    hasLicense(): boolean {
      return this.$store.getters.hasLicense();
    },
  },
});
