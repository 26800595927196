import { StaticModel, HttpModel } from '@/stackEngine';

interface FilterRecalcPeni {
  filterEnable?: Boolean,
  размерСтраницы? : Number,
  номерСтраницы? : Number
}

export class ReCalcPeniModel extends StaticModel {
  private httpModel: DataModel = new HttpModel('ЛицевыеСчета.Пени_перерасчет');
  private isHold = false; // статус того, что данные загружены
  private state: String = JSON.stringify([]);// соберем пустой массив в json
  private isChangedChecked = false; // статус того, что данные были изменены, чтобы не сравнивать каждый раз массивы
  private filterLoaded = {} as FilterRecalcPeni; // Фильтр по которому сохранена выборка
  private openMonthLs = '';
  private lengthState = 0; // Количество сохраненных записей, надо чтобы отловить удаленные записи

  // данные протухли, нужно перезагрузить с бэка
  public reset() {
    this.isHold = false;
    this.state = this.prepareArrayToCheck(this.data);
  }

  public async initRecord(): Promise<StackTableRow> {
    let newRec = {} as StackTableRow;
    if (this.httpModel.initRecord) {
      const data = await this.httpModel.initRecord();
      if (data) {
        newRec = Object.assign({}, data);
      }
    }
    return newRec;
  }

  get isChanged() {
    if (this.isHold && !(this.filterLoaded.filterEnable)) {
      // Если определили, что выборка была изменена - больше не сравниваем массивы
      if (!this.isChangedChecked) {
        if (this.lengthState !== this.data.length) {
          this.isChangedChecked = true;
        } else {
          this.isChangedChecked = this.prepareArrayToCheck(this.data) !== this.state;
        }
      }
    } else {
      this.isChangedChecked = false;
    }
    // проверяем только если выборка сохранена
    return this.isChangedChecked;
  }

  // Зададим открытый месяц лс, с учетом адм режима, задается извне
  public setOpenMonthLs(value: string) {
    this.openMonthLs = value;
  }

  public async getRecords(params?: StackHttpRequestTaskParam) {
    // Если прилетает событие фильтра на выборке - снимаем блокировку выборки
    if (params && params?.фильтр?.filterEnable) {
      if (JSON.stringify(this.filterLoaded) !== JSON.stringify(params.фильтр)) {
        this.isHold = false;
      }
    } else {
      if (!params?.номерЗаписи && this.filterLoaded.filterEnable) {
        this.isHold = false;
      }
    }
    // грузим если не заблокирована выборка
    if (!this.isHold) {
      this.filterLoaded = { ...params?.фильтр };
      if (params && !params?.номерЗаписи) {
        // сбросим размер страниц - чтобы вытащить всю выборку
        params.размерСтраницы = 0;
        // Без строки иногда падает ошибка деления на 0
        params.страницаЗаписи = 0;
        // сбросим номер страницы, чтобы при изменении параметров оказаться на 1 странице
        if (params.номерСтраницы) {
          params.номерСтраницы = 1;
        }
      }
      this.data = await this.httpModel.getRecords(params);
      this.isHold = true;
      if (params && !params?.номерЗаписи && !params?.фильтр?.filterEnable) {
        this.state = this.prepareArrayToCheck(this.data);
        this.lengthState = this.data.length;
        // Если нет номера записи, и нет действующего фильтра - переопределим currentRowid
        this.data.forEach((row: StackTableRow) => {
          this.currentRowid = Math.max(this.currentRowid, Number(row.$номерЗаписи));
        });
      }
    }
    if (params && params?.номерЗаписи) {
      return this.data.filter((row: StackTableRow) => row.номерЗаписи === params.номерЗаписи);
    }
    this.sort(params);
    let pageSize = this.filterLoaded.размерСтраницы || 100;
    let curPage = this.filterLoaded.номерСтраницы || 1;
    if (params && params?.размерСтраницы) {
      pageSize = params.размерСтраницы;
    }
    if (params && params?.номерСтраницы) {
      curPage = params.номерСтраницы;
    }
    const minIndex = Math.round(+pageSize * (+curPage - 1));
    const maxIndex = Math.round(+pageSize * (+curPage));
    return this.data.slice(minIndex, maxIndex);
  }

  // Возвращаем все записи без пагинации
  public async getAllRecords() {
    // Возвращаем все без фильтра
    return this.data;
  }

  public async executeMethod(methodName: string, params?: object): Promise<any> {
    if (this.httpModel.executeMethod) {
      const data = await this.httpModel.executeMethod(methodName, params);
      return data;
    }
    return '';
  }

  // Подготавливает массив для сравнения, убирает все служебные поля,убираем строки, которые не доступны для редактивроания. Возвращает массив в JSON строке
  private prepareArrayToCheck(targetArray : StackTableRow[]) {
    const bufArray = [] as StackTableRow[];
    for (const ind in targetArray) {
      // Если поле перерасчет меньше или не заполнено - то запись доступна только на просмотр,не берем их в массив
      const tempRecalc = targetArray[ind].перерасчет?.toString() || '';
      if (Date.parse(tempRecalc) >= Date.parse(this.openMonthLs)) {
        // Надо сделать полную копию входного массива, иначе он будет удалять значение основного
        bufArray.push(JSON.parse(JSON.stringify(targetArray[ind])));
      }
    }
    // отсортируем выходной массив по номеру записи, чтобы он не изменялся при изменении сортировки
    bufArray.sort((a, b) => {
      let aS = a.$номерЗаписи as string | number;
      let bS = b.$номерЗаписи as string | number;
      if (typeof a.$номерЗаписи === 'string') {
        aS = aS.toString().toLowerCase();
        bS = bS.toString().toLowerCase();
      }
      return aS > bS ? 1 : -1;
    });
    for (const ind in bufArray) {
      for (const field in bufArray[ind]) {
        if (field.indexOf('$') >= 0) {
          delete bufArray[ind][field];
        }
      }
    }
    return JSON.stringify(bufArray);
  }
}
