
import Vue from 'vue';
export default Vue.extend({
  props: {
    message: { type: String, required: true },
    options: { type: Object, default: undefined },
  },
  computed: {
    icon(): string | null {
      return this.options && this.options.icon ? this.options.icon : null;
    },
  },
  data() {
    return {
      visible: true,
    };
  },
});
