
import Vue from 'vue';
import { getComponents, stringTranslit } from '@/stackEngine';

const componentsDefs = require.context('.', false, /\.vue$/);
const constDlg = getComponents(componentsDefs);

export default Vue.extend({
  components: Object.assign(constDlg),
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    comboModel(): any {
      return this.record.допзначение.split('\n');
    },
  },
  data() {
    return {
      itemsList: [{} as StackTableRow],
      isDialog: false,
      itemsListData: [{} as StackTableRow],
      comboValue: 0,
    };
  },
  methods: {
    getDialogName(row: StackTableRow): string {
      if (row && row.имя) {
        const name = stringTranslit(row.имя as string).toUpperCase();
        if (this.$options.components && name in this.$options.components) {
          return name as string;
        }
        return 'DEFAULT';
      }
      return '';
    },
    getList(item: StackTableRow) {
      this.itemsList = [];
      this.isDialog = false;
      const dataObject = this.record && this.record.значение ? JSON.parse(this.record.значение) : [];
      for (const objItem of dataObject) {
        if (objItem.Категория === item.значение) {
          this.itemsList = [].concat(objItem.Данные).map((value: StackTableRow) => {
            if (value.тип === 'число' && Number(value.значение) >= 0) {
              value.значение = Number(value.значение);
            }
            value.model = 'КонстантыНастроек';
            value.$номерЗаписи = this.record.$номерЗаписи;
            return value;
          });
        }
      }
      this.isDialog = true;
    },
  },
});
