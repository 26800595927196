
import Vue from 'vue';

export default Vue.extend({
  props: {
    dataModel: { type: Object, required: true },
    fund: { type: String, default: null },
    accounts: { type: String, default: null },
    month: { type: [String, Date], default: null },
    blockM: { type: [String, Date], default: null },
    monthLic: { type: [String, Date], default: null },
    disabled: { type: Boolean, required: true },
    enableAdmMode: { type: Boolean, default: false },
  },
  data() {
    return {
      jobID: null as null | number,
      operations: 0,
    };
  },
  computed: {
    dataOperations(): string[] {
      const array = [];
      if (this.accounts !== '-10' && Date.parse(this.blockM) > Date.parse(this.monthLic)) {
        array.push('Разблокировка месяца');
      }
      array.push('Открытие месяца');
      if (this.enableAdmMode) {
        array.push('Открытие месяца для административной группы');
      }
      return array;
    },
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
  },
  methods: {
    async selection() {
      const monthOperation = this.dataOperations[this.operations].indexOf('Открытие месяца', -1) >= 0 ? this.month : this.blockM;
      const params = { фонд: this.fund, месяц: monthOperation, лицевые: this.accounts, событие: this.dataOperations[this.operations] };
      this.jobID = (await this.dataModel.executeMethod('проверкаДоступностиОперации', params, { async: true })).asyncId;
      this.$store.commit('MSG_ADD', { title: 'Открытие', asyncId: this.jobID, external: true, silent: true });
      this.$emit('run');
    },
    finishJob(res: string) {
      this.$store.commit('MSG_DELETE', this.jobID);
      this.jobID = null;
      this.$emit('end', { res, operation: this.dataOperations[this.operations] });
    },
    cancel() {
      this.$emit('reset');
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.finishJob('success');
      }
    },
    isError(to) {
      if (to) {
        this.$toast(this.resultJob.status, { color: 'error' });
        this.finishJob('error');
      }
    },
  },
});
