
import Vue from 'vue';
import AdrPrintBtn from '@/tasks/fl/views/accounts/$accountsid/AdrPrintBtn.vue';

export default Vue.extend({
  components: { AdrPrintBtn },
  props: {
    params: { type: Object, required: true },
    dataObject: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбек к таблице
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async onUpdate() {
      const obj = this.dataObject as DataModel;
      if (obj.executeMethod) {
        try {
          this.isLoading = true;
          await obj.executeMethod(
            'Обновить',
            {},
            {
              async: true,
              jobName: 'Обновление реестра управляющих компаний',
              onComplete: () => {
                const currentQuery = this.$route.query;
                if ((currentQuery.parentID && currentQuery.parentID !== '-10') || (currentQuery.page && currentQuery.page !== '1') || currentQuery.expand !== undefined) {
                  // Если мы были внутри выборки - то сбросим до корня, так как внутри метода перенабирается таблица, на которой основана выборка
                  const q = Object.create(this.$route.query);
                  q.expand = undefined;
                  q.page = '1';
                  q.parentID = '-10';
                  this.$router.push({ query: q });
                } else {
                  this.$sendMsg('stack-table', 'РеестрыУК', 'reload');
                }
                this.isLoading = false;
              },
            },
          );
        } catch (error: AnyException) {
          this.$toast('Ошибка расчета', { color: 'error' });
          this.$sendMsg('stack-table', 'РеестрыУК', 'reload');
          this.isLoading = false;
        }
      }
    },
  },
});
