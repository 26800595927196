
import Vue from 'vue';
import lawsuitId from './ПОСТАНОВКАКОДФЛ.vue';

export default Vue.extend({
  components: { lawsuitId },
  props: {
    record: { type: Object, required: true, default: undefined },
  },
});
