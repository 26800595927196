import Vue from 'vue';

export default Vue.extend({
  watch: {
    isReady: {
      immediate: true,
      handler(to: boolean) {
        if (to) {
          this.$nextTick(() => {
            setTimeout(() => {
              // @ts-ignore
              const el = this.$refs?.form?.$el.querySelectorAll('input:not([data-cy="stack-combobox"]):not([type="hidden"]):not([disabled]),textarea:not([disabled]') as NodeListOf<HTMLElement>;
              el?.[0]?.focus();
            }, 0);
          });
        }
      },
    },
  },
});
