
import Vue from 'vue';

export default Vue.extend({
  props: {
    orgID: { type: [Number, String], required: true },
  },
  data() {
    return {
      record: {
        $номерЗаписи: -1,
        подразделение: -1,
      },
    };
  },
  methods: {
    async save() {
      this.record.подразделение = +this.orgID;
      await new this.$HttpModel('Организации').saveRecord(this.record, { проверкаполей: 0 });
      this.$sendMsg('stack-table', 'Организации.Подразделения', 'reload');
      this.$emit('close');
    },
  },
});
