
import Vue from 'vue';

export default Vue.extend({
  props: {
    reestr: { type: [Number, String], required: true },
  },
  computed: {
    ownerReestr(): number {
      const reestrArray = typeof this.reestr === 'number' ? [this.reestr] : this.reestr.split(',');
      return reestrArray[0] ? +reestrArray[0] : -1;
    },
  },
});
