
import Vue from 'vue';
import handcalcDialog from './$dialogs/handcalcDialog.vue';
import HandCalcToolbar from './$toolbars/HandCalcToolbar.vue';
import { HandCalcModel } from './$classes/HandCalcModel';
import handleHeaders from '@/tasks/fl/selections/accounts/ЛицевыеСчета.РучныеПерерасчеты';

export default Vue.extend({
  components: { handcalcDialog, HandCalcToolbar },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    isReadonly(): boolean {
      return this.$store.getters.isWorkMonthInClosedMonth();
    },
    closedMonthLs(): [Date, String] {
      return this.record['@вадмрежиме'] ? this.$stackDate.prevMonth(this.record.закрытыймесяц, 1) : this.record.закрытыймесяц;
    },
  },
  data() {
    return {
      dataModel: null as HandCalcModel | null,
      headers: handleHeaders.fields as StackField[],
    };
  },
  created() {
    // @ts-ignore TODO
    this.dataModel = new HandCalcModel([], { rowTemplate: this.headers, description: '' });
  },
});
