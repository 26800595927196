import Vue from 'vue';

export default Vue.extend({

  created() {
    this.$root.$on('send-global-event', this.checkGlobalEvent);
  },
  beforeDestroy() {
    this.$root.$off('send-global-event', this.checkGlobalEvent);
  },
  methods: {
    checkGlobalEvent({ who, name, action, payload }: StackMsgEventBusData) {
      // @ts-ignore
      if (name === this.dataObject.model || name === '*') {
        switch (who) {
          case 'stack-dialog':
          case 'stack-modal-dialog': {
            if (action === 'reload') {
              // @ts-ignore
              this.readRecord();
            }
            break;
          }
          case 'stack-table':
            if (action) {
              // @ts-ignore
              if (this.onToolBarAction) this.onToolBarAction({ action, payload });
            }
            break;
          default:
            break;
        }
      }
    },
  },
});
