
import Vue from 'vue';
import { rules } from '@/stackEngine';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    params: { type: Object, required: true },
    tableModel: { type: Object, default: null },
  },
  computed: {
    isService(): boolean {
      return this.tableModel.model === 'ДоговорыУК.УслугиДоговора.СхемыРаспределения';
    },
  },
  data() {
    return {
      rules,
    };
  },
  methods: {
    changeFlag(value: number) {
      this.record['схема-услуга'] = -1;
    },
    changeService(value: number) {
      this.record.признаки = +this.record.признаки % 8 >= 2 ? +this.record.признаки - 4 : this.record.признаки;
    },
  },
});
