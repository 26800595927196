
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: {
      type: Object,
      default: (): any => {
        return {};
      },
    },
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        'Параметры.Месяц': this.$store.getters.getOpenMonth(),
        'Параметры.МесДо': 0,
        'Параметры.Услуга': -1,
        'Параметры.КатегорияУслуг': -1,
        'Параметры.Поставщик': -1,
        'Параметры.КатегорияПоставщиков': -1,
        'Параметры.УК': -1,
        'Параметры.ДержателиДолга': -1,
        'Параметры.Сумма': 0,
        'Параметры.От': 0,
        'Параметры.До': 0,
        'Параметры.Флаги': 1,
        'Параметры.фУчетОплат': 0,
        'Параметры.ДатаВызова': this.$store.getters.getCurrentDate(),
        'Параметры.ВремяВызова': '00:00',
      },
      derjdolga: 1,
      dataModel: new this.$HttpModel('Задолженность_Отчет'),
    };
  },
  async created() {
    const res = await this.dataModel.executeMethod('НачальныеПараметры', {});
    if (res) {
      this.record['Параметры.МесДо'] = res.месДо;
      if (res.Режим !== 0 && !res.РаботаСРеестрамиЛицевых) {
        this.derjdolga = 0;
      }
    }
  },
  computed: {
    call(): boolean {
      return this.params && this.params.call ? this.params.call : false;
    },
  },
});
