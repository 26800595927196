import { TableHandlers } from '@/stackEngine';
const lg: StackSelection = {
  recordRoute: '',
  objectName: 'РезультатПроверкиИнтеграции',
  description: 'Проверки интеграции',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    switch (row.картинка) {
      case '0':
        // @ts-ignore
        row.$data.картинка = { $иконка: '$vuetify.icons.close_circle' };
        TableHandlers.trColor(row, 'error');
        break;
      case '1':
        // @ts-ignore
        row.$data.картинка = { $иконка: '$vuetify.icons.check' };
        break;
      case '2':
        // @ts-ignore
        row.$data.картинка = { $иконка: '$vuetify.icons.alert' };
        TableHandlers.trColor(row, 'warning');
        break;
      default:
        break;
    }
    row.картинка = '';
    return row;
  },
  fields: [
    {
      field: 'описание',
      text: 'Описание',
      type: 'String',
    },
    {
      field: 'решение',
      text: 'Решение',
      type: 'String',
    },
    {
      field: 'сообщение',
      text: 'Сообщение',
      type: 'String',
    },
    {
      field: 'текст',
      text: 'Текст',
      type: 'String',
    },
    {
      field: 'картинка',
      text: 'Картинка',
      type: 'String',
    },
    {
      field: 'идентификатор',
      text: 'Идентификатор',
      type: 'String',
    },
  ],
};

export default lg;
