const reports: StackSelection = {
  objectName: 'МассоваяПечатьКвитанций.ДополнительнаяПечать',
  description: '',
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
      readonly: true,
    },
    {
      field: 'шаблон',
      text: 'Шаблон',
      type: 'String',
      readonly: true,
    },
    {
      field: 'предельнаясумма',
      text: 'Предельная сумма',
      type: 'Number',
    },
  ],
};
export default reports;
