
import Vue from 'vue';
import PaylistFilter from '@/tasks/fl/views/$common/filters/PaylistFilter.vue';
import paylistfolder from '@/tasks/common/views/$common/documentFolder.vue';
import PaylistToolbar from './PaylistToolbar.vue';

export default Vue.extend({
  name: 'Paylist',
  components: { PaylistFilter, paylistfolder, PaylistToolbar },
  data() {
    return {
      dataObject: new this.$HttpModel('ПлатежныеВедомости'),
    };
  },
  methods: {
    // TODO убрать копипасту
    async onLockUnlock(row: StackTableRow) {
      // @ts-ignore
      let str = this.$refs.table.getSelectedRecords().map(function(rowSel: StackTableRow) {
        return rowSel['состояние'] === row['состояние'] ? rowSel['$номерЗаписи'] : '';
      }).join(',');
        // @ts-ignore
      this.$refs.table.unSelectAll();
      if (str === '') {
        str += row['$номерЗаписи'];
      }
      await this.dataObject.executeMethod('Блокировать', { ид: str });
      this.$sendMsg('stack-table', 'ПлатежныеВедомости', 'reload');
    },
    formStr(rows: StackTableRow[]): string {
      let str = '';
      for (const row of rows) {
        str += ',' + row['$номерЗаписи'];
      }
      return str;
    },
    async onClickSformVip(rows: StackTableRow[]) {
      await this.dataObject.executeMethod('СформироватьВыписку', { выделения: this.formStr(rows) });
      // @ts-ignore
      this.$refs.table.fetchData();
    },
    async onClickIsklIzVip(rows: StackTableRow[]) {
      await this.dataObject.executeMethod('ИсключитьИзВыписки', { выделения: this.formStr(rows) });
      // @ts-ignore
      this.$refs.table.fetchData();
    },
    async onClickDelRasp(rows: StackTableRow[]) {
      await this.dataObject.executeMethod('УдалитьРаспределение', { выделения: this.formStr(rows) });
      // @ts-ignore
      this.$refs.table.fetchData();
    },
    sendPic(value: string) {
      switch (value) {
        case '1':
          return '$vuetify.icons.check';
        case '2':
          return '$vuetify.icons.printer';
        default:
          return '';
      }
    },
  },
});
