
import Vue from 'vue';

export default Vue.extend({
  computed: {
    isUserAdmin(): boolean {
      return this.$store.getters.isUserAdmin();
    },
    menuItems(): any {
      // return this.isUserAdmin ? [...this.itemsadm, ...this.items] : this.items;
      return this.items;
    },
  },
  data() {
    return {
      // itemsadm: [
      //   ],
      items: [
        { title: 'Общие настройки ПО', img: 'settings_main', to: { name: 'settings-common' }, color: 'stack' },
        { title: 'Макеты квитанций', img: 'kvit_layouts', to: { name: 'settings-kvitmodels' }, color: 'stack' },
        { title: 'Настройки АРМ Юриста', img: 'settings_dlg', to: { name: 'settings-lawsettings' }, color: 'stack' },
      ],
    };
  },
});
