const lg: StackSelection = {
  recordRoute: '',
  objectName: 'ЖурналПодкаченныхРеестровПлатежей',
  description: 'Журнал платежей',
  hier: true,
  fields: [
    {
      field: 'статус',
      text: 'Статус',
      type: 'String',
    },
    {
      field: 'имяфайла',
      text: 'Имя файла',
      type: 'String',
    },
    {
      field: 'пользователь',
      text: 'Пользователь',
      type: 'String',
    },
    {
      field: 'датастатуса',
      text: 'Дата статуса',
      type: 'DateTime',
    },
  ],
};

export default lg;
