const doguk: StackSelection = {
  objectName: 'ДоговорыУК.ПараметрыРасчета',
  description: 'Параметры расчета',
  fields: [
    {
      field: 'датнач',
      text: 'С',
      type: 'Date',
    },
    {
      field: 'значение',
      text: 'Знач',
      type: 'Number',
    },
    {
      field: 'даткнц',
      text: 'По',
      type: 'Date',
    },
    {
      field: 'параметр-значения',
      text: 'Параметр-Значения',
      type: 'Link',
    },
    {
      field: 'лицевой-параметры',
      text: 'Лицевой-Параметры',
      type: 'Link',
    },
    {
      field: 'орг-параметры',
      text: 'Орг-Параметры',
      type: 'Link',
    },
    {
      field: 'услуги-параметры',
      text: 'Услуги-Параметры',
      type: 'Link',
    },
    {
      field: '@адрес',
      text: 'адрес',
      type: 'String',
    },
    {
      field: 'орг-параметры>название',
      text: 'Организация-поставщик',
      type: 'String',
    },
    {
      field: '@текстуслуга',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: 'параметр-значения>название',
      text: 'Параметр',
      type: 'String',
    },
    {
      field: '@значение_текст',
      text: 'Значение',
      type: 'String',
    },
  ],
};
export default doguk;
