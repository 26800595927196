
import Vue from 'vue';

export default Vue.extend({
  computed: {
    disableInputReadings(): boolean {
      if (!this.valid) {
        return true;
      } else {
        return this.disabledBtnFilter;
      }
    },
  },
  data() {
    return {
      filter: {
        владелец: this.$route.query.владелец || '',
        объект: -1,
        услуга: -1,
      } as any,
      data: {
        типввода: 1,
        дата: this.$store.getters.getCurrentDate(),
        месяц: this.$stackDate.firstDay(this.$store.getters.getCurrentDate()),
        контролер: -1,
      } as any,
      disabledBtnFilter: false,
      valid: true,
    };
  },
  methods: {
    applyFilter() {
      this.disabledBtnFilter = true;
      this.$emit('applyFilter', this.filter);
      this.$emit('applyData', this.data);
    },
    changeFilter() {
      if (this.filter.владелец <= 0) {
        this.$emit('applyFilter', this.filter);
      }
      this.disabledBtnFilter = false;
    },
  },
});
