const param: StackSelection = {
  objectName: 'Параметры расчета',
  description: 'Параметры расчета пени',
  fields: [
    {
      field: 'код',
      text: 'Код',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Описание',
      type: 'String',
    },
    {
      field: 'имя',
      text: 'Имя',
      type: 'String',
    },
    {
      field: 'описание',
      text: 'Описание',
      type: 'String',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
  ],
};
export default param;
