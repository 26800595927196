// описание полей перечисляемых
const enumFields =
  [
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
    {
      field: 'ключ',
      text: 'Ключ',
      type: 'Number',
    },
  ] as StackField[];

interface StackTableCacheItems {
  [index: string]: StackTableRow[];
}
interface StackTableCacheItemsParams {
  [index: string]: StackHttpRequestTaskParam;
}

export class StackSelectionCache {
  private static definations: StackSelections = {};
  private static cachedData: StackTableCacheItems = {};
  private static cachedDataParams: StackTableCacheItemsParams = {};

  public static addSelections(selections: any) {
    const sels = selections.keys().map(selections);
    sels.forEach((selection: any) => {
      const selDef: StackSelection | StackEnums = selection.default || selection;
      // если это описание выборки
      if (selDef && (selDef as StackSelection).objectName) {
        StackSelectionCache.definationAdd(selDef as StackSelection);
      }
      // если это файл с перечисляемыми
      if (selDef && (selDef as StackEnums).enums) {
        const enums = (selDef as StackEnums).enums;
        enums.forEach(enumName => {
          const sel: StackSelection = {
            objectName: enumName,
            fields: enumFields,
            cached: true,
          };
          StackSelectionCache.definationAdd(sel);
        });
      }
    });
  }

  // читаем описания выборок
  public static fillSelections(selections: any[]) {
    StackSelectionCache.clearSelectionsCache();
    selections.forEach(selection => {
      // @ts-ignore
      const selDef: StackSelection | StackEnums = selection.default || selection;
      // если это описание выборки
      if (selDef && (selDef as StackSelection).objectName) {
        StackSelectionCache.definationAdd(selDef as StackSelection);
      }
      // если это файл с перечисляемыми
      if (selDef && (selDef as StackEnums).enums) {
        const enums = (selDef as StackEnums).enums;
        enums.forEach(enumName => {
          const sel: StackSelection = {
            objectName: enumName,
            fields: enumFields,
            cached: true,
          };
          StackSelectionCache.definationAdd(sel);
        });
      }
    });
  }

  public static clearCacheByModel(model: string) {
    delete StackSelectionCache.cachedData[model];
    delete StackSelectionCache.cachedDataParams[model];
  }

  public static getCacheByModel(model: string, params?: StackHttpRequestTaskParam | undefined) {
    if (StackSelectionCache.cachedData[model]) {
      const copy: StackTableRow[] = [];
      if (Array.isArray(StackSelectionCache.cachedData[model])) {
        StackSelectionCache.cachedData[model].forEach(item => {
          let isok = true;
          // ищем по номеру записи
          if (params && params.номерЗаписи && params.номерЗаписи !== item.$номерЗаписи) {
            isok = false;
          }
          if (params && params.папка && params.папка !== item.$папка) {
            isok = false;
          }
          if (isok) {
            copy.push({ ...item });
          }
        });
        return copy;
      }
      return StackSelectionCache.cachedData[model];
    }
    return undefined;
  }

  public static getCacheParamsByModel(model: string) {
    return StackSelectionCache.cachedDataParams[model] || undefined;
  }

  public static getTriggeredParams(params: StackHttpRequestTaskParam): StackHttpRequestTaskParam {
    const triggerParams: StackHttpRequestTaskParam = {};
    for (const i in params) {
      if (i !== 'папка') {
        triggerParams[i] = params[i];
      }
    }
    return triggerParams;
  }

  public static setCacheByModel(model: string, data: StackTableRow[], params?: StackHttpRequestTaskParam | undefined) {
    if (data) {
      StackSelectionCache.cachedData[model] = data;
      if (params) {
        const triggerParams: StackHttpRequestTaskParam = StackSelectionCache.getTriggeredParams(params);
        StackSelectionCache.cachedDataParams[model] = JSON.parse(JSON.stringify(triggerParams));
      }
    }
  }

  public static getSelectionDefinition(selectionName: string): StackSelection | undefined {
    if (this.definations && this.definations[selectionName]) {
      return this.definations[selectionName];
    }
    return undefined;
  }

  public static getObjectNames(): string[] {
    return Object.keys(this.definations);
  }

  public static getCustomApi(selectionName: string): string | undefined {
    return this.definations && this.definations[selectionName] && this.definations[selectionName].customApi ? this.definations[selectionName].customApi : undefined;
  }

  public static getFields(selectionName: string) {
    const values = [] as any;
    const def = this.definations[selectionName];
    if (def) {
      def.fields.forEach((element: StackField) => {
        values.push(element.field);
      });
    }
    return values;
  }

  public static definationAdd(def: StackSelection) {
    this.definations[def.objectName] = def;
  }

  public static clearSelectionsCache() {
    this.definations = {};
  }

  public static getSelectionUrl(selectionName: string, isFolder: boolean): string {
    const def = this.definations[selectionName];
    let url = '';
    if (def) {
      const routeName = isFolder ? def.folderRoute : def.recordRoute;
      if (routeName) {
        url = routeName.split('-').shift() + '';
      }
    }
    return url;
  }
}
