
import Vue from 'vue';
import dolgdetalTab from './dolgdetal_tab.vue';
import srokOplat from './срокГрафика.vue';
import changingsumDialog from './changingsum_tab.vue';

export default Vue.extend({
  components: {
    dolgdetalTab,
    srokOplat,
    changingsumDialog,
  },
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      dataObjectSR: new this.$HttpModel('СоглашенияОРассрочке'),
      modaldialog: false,
      srokdialog: false,
      onfilter: false,
      modalchangingsumDialog: false,
      sumDolgLeft: 0,
      procDolgLeft: 0,
      isLoading: false,
    };
  },
  computed: {
    isNewRecord(): boolean {
      return this.record.$номерЗаписи === -1;
    },
    listf(): any {
      const temp = [] as any;
      if (this.record.списокф) {
        const arr = this.record.списокф.toString().split(';');
        for (const row of arr) {
          if (row.indexOf('/') !== -1) {
            temp.push({ title: row.replace('/', ''), separator: 1 });
          } else {
            temp.push({ title: row });
          }
        }
      }
      return temp;
    },
    Dis(): any {
      const readonly = [] as any;
      if (this.record.запретитьэлементыокна) {
        const arr = this.record.запретитьэлементыокна.toString().split(',');
        for (const row of arr) {
          readonly[row] = true;
        }
      }
      // если выполняется какое-то действие, то не разрешаем поля
      if (!this.isLoading) {
        if (this.record.разрешитьэлементыокна) {
          const arr2 = this.record.разрешитьэлементыокна.toString().split(',');
          for (const row of arr2) {
            readonly[row] = false;
          }
        }
      }
      return readonly;
    },
    Status(): boolean {
      return this.record.статус === 'В работе';
    },
    disableFillSchedule(): boolean {
      return this.Dis['заполнить график'] || this.record.тип !== 0 || +this.record['потребитель'] <= 0 || this.isNewRecord || +this.record.лицевой <= 0;
    },
    disableType(): boolean {
      return this.isLoading || this.Status || this.Dis['заполнить график'] || this.record['лицевой'] < 0 || this.record['лицевой'] == null;
    },
  },
  methods: {
    async changeField(gpload: any, field: any) {
      this.isLoading = true;
      let rec = this.record;
      const result = await this.dataObjectSR.executeMethod('ПроверкаПолей', { поле: field, рассрочка: rec });
      rec = Object.assign(rec, result);
      const msg = await this.dataObjectSR.getQuestions();
      if (msg && msg.проверкаполей) {
        this.$toast(msg.проверкаполей.текст, { color: 'error', timeout: 10000, dismissible: true });
      }
      this.isLoading = false;
    },
    fillChart(srok: number) {
      this.srokdialog = false;
      this.record.срокоплаты = srok;
      if (this.record.типпроцента !== 2) {
        this.$emit('fillChart', this.onfilter);
      } else {
        this.modalchangingsumDialog = true;
      }
    },
    onBuild() {
      this.modaldialog = false;
      if (this.record.срокоплаты === 0) {
        this.srokdialog = true;
      } else {
        this.$emit('fillChart', this.onfilter);
      }
      this.$emit('build');
    },
    getTableValues(data: any) {
      this.sumDolgLeft = 0;
      this.procDolgLeft = 0;
      if (data) {
        for (const ind in data) {
          this.sumDolgLeft += ((data[ind].сумма ? data[ind].сумма : 0) - (data[ind].оплатадолг ? data[ind].оплатадолг : 0)) as number;
          this.procDolgLeft += ((data[ind].суммапроцентов ? data[ind].суммапроцентов : 0) - (data[ind].оплатапроцент ? data[ind].оплатапроцент : 0)) as number;
        }
      }
    },
    onClickFillSchedule() {
      if (+this.record.сумма > 0) {
        this.srokdialog = true;
      } else {
        this.$toast('Сумма рассрочки должна быть больше 0!', { color: 'error' });
      }
    },
  },
});
