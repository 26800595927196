const conf: StackSelection = {
  objectName: 'ПочтаКонфигурация',
  description: 'Почта Конфигурация',
  fields: [
    {
      field: 'группа данных',
      text: 'Группа данных',
      type: 'String',
    },
  ],
};

export default conf;
