
import Vue from 'vue';
import undisable from '../mixins/undisable';

export default Vue.extend({
  model: { prop: 'page', event: 'change' },
  props: {
    disabled: { type: Boolean, default: false },
    page: { type: Number, default: 1 },
    rowsPerPage: { type: Number, required: true },
    total: { type: Number, required: true },
    itemsCount: { type: Number, required: true },
    selectedItems: { type: Array, required: true },
  },
  mixins: [undisable],
  data() {
    return {
      currentWidth: 0,
      perPage: [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: '500', value: 500 },
        { text: '1500', value: 1500 },
        // { text: 'Все', value: 50000 },
      ],
    };
  },
  mounted() {
    this.onResize();
  },
  computed: {
    // номпп первой показанной записи
    currentStart(): number {
      return (this.page - 1) * this.rowsPerPage + 1;
    },
    // номпп последней показанной записи
    currentEnd(): number {
      return Math.min(this.currentStart + this.itemsCount - 1, this.total);
    },
    // Показываем область: Показано записей n из n
    hideShowFromTo(): boolean {
      return this.currentWidth < 550;
    },
    // Показываем область: выбора пагинации и показано записей n из n
    hidePerPage(): boolean {
      return this.currentWidth < 440;
    },
  },
  methods: {
    onResize() {
      // @ts-ignore
      this.currentWidth = this.$refs.pag?.getBoundingClientRect()?.width;
    },
    onChangePage(page: number) {
      if (page > 0 && page <= Math.ceil(this.total / this.rowsPerPage)) {
        this.$emit('change', page);
      } else {
        this.$forceUpdate();
      }
    },
    onChangeRowsPerPage(payload: number) {
      this.$emit('update:rowsPerPage', payload);
    },
  },
  watch: {
    disabled(to: boolean) {
      if (!to) {
        this.onResize();
      }
    },
  },
});
