import { TableHandlers } from '@/stackEngine';
const types: StackSelection = {
  recordRoute: '',
  objectName: 'ТипыДокументов',
  description: 'Типы документов',
  hier: true,
  prepareRow: row => {
    if (row.служебный !== 0) {
      TableHandlers.trColor(row, 'brown');
    }
    return row;
  },
  fields: [
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'кназвание',
      text: 'Краткое название',
      type: 'String',
    },
    {
      field: 'папки',
      text: 'папки',
      type: 'Link',
    },
    {
      field: 'отчет',
      text: 'Печатная форма',
      type: 'String',
    },
    {
      field: 'котчет',
      text: 'КОтчет',
      type: 'String',
    },
    {
      field: 'иотчет',
      text: 'ИОтчет',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'служебный',
      text: 'Служебный',
      type: 'Number',
    },
    {
      field: 'скрытый',
      text: 'Скрытый',
      type: 'Number',
    },
    {
      field: 'уровеньдетализации',
      text: 'УровеньДетализации',
      type: 'Number',
    },
    {
      field: 'вариантдетализации',
      text: 'ВариантДетализации',
      type: 'Number',
    },
    {
      field: 'внешнийдокумент',
      text: 'ВнешнийДокумент',
      type: 'Number',
    },
    {
      field: 'признаки',
      text: 'Признаки',
      type: 'Number',
    },
    {
      field: '@инт_тип',
      text: 'Тип документа в ГИС ЖКХ',
      type: 'String',
      computed: true,
    },
  ],
};

export default types;
