
import Vue from 'vue';

export default Vue.extend({
  name: 'AddressFilter',
  model: { prop: 'params', event: 'input' },
  props: {
    params: { type: Object, required: true },
    noShowNumberLS: { type: Boolean, default: false },
    noShowFIO: { type: Boolean, default: false },
    showKm: { type: Boolean, default: false },
    noShowAdrSubheader: { type: Boolean, default: false },
    showCadastre: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
  },
  computed: {
    showPs(): boolean {
      const data = this.$store.getters['flStore/getlsHierTypes'] && this.$store.getters['flStore/getlsHierTypes']();
      return data?.some((row: StackTableRow) => row.type === 10);
    },
  },
  methods: {
    cleanupAdr(id: number, type: number) {
      if (!id) {
        switch (type) {
          case 12:
            this.params.пструктура = null;
            this.params.улица = null;
            this.params.дом = null;
            this.params.квартира = null;
            this.params.комната = null;
            break;
          case 2:
            this.params.дом = null;
            this.params.квартира = null;
            this.params.комната = null;
            break;
          case 3:
            this.params.квартира = null;
            this.params.комната = null;
            break;
          case 10:
            this.params.улица = null;
            this.params.дом = null;
            this.params.квартира = null;
            this.params.комната = null;
            break;
        }
      }
    },
  },
});
