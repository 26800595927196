
import Vue from 'vue';

export default Vue.extend({
  props: {
    questions: { type: [Object, Array], required: true },
    title: { type: String, default: '' },
  },
  data() {
    return {
      radioResult: null,
    };
  },
  created() {
    for (const question in this.questions) {
      if (this.questions[question].типСообщения === 'дата' && !this.questions[question].варианты) {
        this.questions[question].варианты = this.$store.getters.getCurrentDate();
      }
    }
  },
  methods: {
    onBtnClick(question: StackHttpTaskQuestion, value: string | number, param: any, idx: any) {
      if (!question.вариантПрервать || value !== question.вариантПрервать) {
        this.$emit('stack-wrapper:close', { [param]: idx });
      } else {
        this.$emit('stack-wrapper:error', { [param]: idx });
      }
    },
    onClose() {
      this.$emit('stack-wrapper:error', 'close');
    },
  },
});
