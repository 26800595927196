const par: StackSelection = {
  objectName: 'БлокировкиЗаписей',
  fields: [
    {
      field: 'имятаблицы',
      text: 'Имя таблицы',
      type: 'String',
    },
    {
      field: 'идзаписи',
      text: 'Номер\nзаписи',
      type: 'Number',
    },
    {
      field: 'пользователь',
      text: 'Пользователь',
      type: 'String',
    },
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'создана',
      text: 'Создана',
      type: 'String',
    },
    {
      field: 'комментарий',
      text: 'Комментарий',
      type: 'String',
    },
    {
      field: 'url',
      text: 'Ссылка',
      type: 'String',
    },
  ],
};

export default par;
