
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        ДатНач: this.$store.getters.getWorkMonth(),
        ДатКнц: this.$store.getters.getWorkMonth(),
        Сальдо: 3,
        Начисления: 15,
        Другое: 15,
      } as StackTableRow,
      nameButton: 'Снять все',
      flagButton: false,
    };
  },
  methods: {
    flagsOff() {
      this.flagButton = !this.flagButton;
      if (this.flagButton === true) {
        this.record.Сальдо = 0;
        this.record.Начисления = 0;
        this.record.Другое = 0;
        this.nameButton = 'Установить все';
      } else {
        this.record.Сальдо = 3;
        this.record.Начисления = 15;
        this.record.Другое = 15;
        this.nameButton = 'Снять все';
      }
    },
  },
});
