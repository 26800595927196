
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        'Параметры.ДатНач': this.$store.getters.getWorkMonth(),
        'Параметры.ДатКнц': this.$store.getters.getWorkMonth(),
        'Параметры.Услуга': -1,
        'Параметры.КатегорияУслуг': -1,
        'Параметры.Поставщик': -1,
        'Параметры.КатегорияПоставщиков': -1,
        'Параметры.флМесяцы': 0,
        'Параметры.переклУслуг': 0,
      },
    };
  },
});
