const constantsetting: StackSelection = {
  objectName: 'КонстантыНастроек',
  description: 'Константы',
  fields: [
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
      noState: true,
    },
    {
      field: 'имя',
      text: 'Имя',
      type: 'String',
      noState: true,
    },
    {
      field: 'полное имя',
      text: 'Полное имя',
      type: 'String',
      noState: true,
    },
    {
      field: 'константы',
      text: 'Константы',
      type: 'Number',
    },
    {
      field: 'номзап',
      text: 'Row_id',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
      noState: true,
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'String',
    },
    {
      field: 'поля',
      text: 'Поля',
      type: 'String',
      noState: true,
    },
    {
      field: 'допзначение',
      text: 'ДопЗначение',
      type: 'String',
    },
    {
      field: 'допзначение2',
      text: 'ДопЗначение',
      type: 'String',
    },
    {
      field: 'допзначение3',
      text: 'ДопЗначение',
      type: 'String',
    },
    {
      field: 'допзначение4',
      text: 'ДопЗначение',
      type: 'String',
    },
    {
      field: 'допзначение5',
      text: 'ДопЗначение',
      type: 'String',
    },
    {
      field: 'допзначение6',
      text: 'ДопЗначение',
      type: 'String',
    },
    {
      field: 'допзначение7',
      text: 'ДопЗначение',
      type: 'String',
    },
    {
      field: 'допзначение8',
      text: 'ДопЗначение',
      type: 'String',
    },
    {
      field: 'допзначение9',
      text: 'ДопЗначение',
      type: 'String',
    },
    {
      field: 'допзначение10',
      text: 'ДопЗначение',
      type: 'String',
    },
    {
      field: 'допзначение11',
      text: 'ДопЗначение',
      type: 'String',
    },
    {
      field: 'допзначение12',
      text: 'ДопЗначение',
      type: 'String',
    },
    {
      field: 'допзначение13',
      text: 'ДопЗначение',
      type: 'String',
    },
    {
      field: 'допимя',
      text: 'допимя',
      type: 'String',
      noState: true,
    },
    {
      field: 'дататип',
      text: 'ДатаТип',
      type: 'String',
    },
    {
      field: 'разделитель',
      text: 'Разделитель',
      type: 'String',
    },
  ],
};

export default constantsetting;
