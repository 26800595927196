const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ВременноеДвижение',
  recordRoute: 'accounts-accountsid-residentsmove-residentsmoveid',
  description: 'Временное движение',
  fields: [
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'дата подтверждения',
      text: 'Дата подтверждения',
      type: 'Date',
    },
    {
      field: 'датрег',
      text: 'Дата регистрации',
      type: 'Date',
    },
    {
      field: 'датнач',
      text: 'ДатНач',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'ДатКнц',
      type: 'Date',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'type',
      text: 'Тип',
      type: 'String',
      computed: true,
    },
    {
      field: 'оставлять',
      text: 'Оставлять',
      type: 'Number',
    },
    {
      field: 'причина',
      text: 'Причина',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'флаги',
      text: 'Флаги',
      type: 'Number',
    },
    {
      field: 'номерзаявления',
      text: 'НомерЗаявления',
      type: 'Number',
    },
    {
      field: 'документ',
      text: 'Документ',
      type: 'String',
    },
    {
      field: 'человек-движения',
      text: 'Человек-Движения',
      type: 'Link',
    },
    {
      field: 'счет-движения',
      text: 'Счет-Движения',
      type: 'Link',
    },
    {
      field: 'номерлс',
      text: 'Номер',
      type: 'Number',
    },
  ],
};
export default accs;
