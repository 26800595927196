
import Vue from 'vue';
import datnachdatend from './$datNachdatEnd.vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    hideDate: { type: Boolean, default: false },
  },
  components: { datnachdatend },
});
