
import Vue from 'vue';
import { splitFullName, pickFullName } from '@/stackEngine';
import docreg from '@/tasks/common/views/$common/residents/docReg.vue';

export default Vue.extend({
  components: { docreg },
  props: {
    residentsid: { type: [Number, String], default: undefined },
    accountsid: { type: [Number, String], default: undefined },
  },
  data() {
    return {
      record: {} as StackTableRow,
      tabs: null,
      initPerson: {
        inn: null,
        gender: null,
        birth: null,
        snils: null,
      } as any,
      typeMan: 0,
    };
  },
  computed: {
    personid(): number | null {
      return this.record['человек-карточка'] ? +this.record['человек-карточка'] : null;
    },
    title(): string {
      return `Жилец: ${this.record.фио ? this.record.фио : ''}` + this.titleAcc;
    },
    withoutRegistration(): boolean {
      const typeOfRegistration = this.record['вид регистрации'] as number;
      return typeOfRegistration === 3;
    },
    dischargeIsDied(): boolean {
      const foundation = this.record['основание выписки'] as number;
      return foundation === 7;
    },
    titleAcc(): string {
      return this.record.номерлс ? ' ( Лицевой счет № ' + this.record.номерлс + ' )' : '';
    },
  },
  methods: {
    changePerson(payload: any) {
      if (payload && payload[0]) {
        this.$set(this.initPerson, 'inn', payload[0].инн);
        this.$set(this.initPerson, 'gender', payload[0].пол);
        this.$set(this.initPerson, 'birth', payload[0]['дата рождения']);
        this.$set(this.initPerson, 'snils', payload[0]['пенсионное свидетельство']);

        this.$set(this.initPerson, 'inn', payload[0].инн);
        this.$set(this.initPerson, 'kpp', payload[0].кпп);
        this.$set(this.initPerson, 'ogrn', payload[0].огрн);

        this.typeMan = payload[0].типжильца;
      }
    },
    onChange() {
      this.record.типжильца = 0;
    },
    onChangeFIO(field: string, value: string) {
      // с заглавной буквы
      this.record[field] = value.charAt(0).toUpperCase() + value.slice(1);
    },
    onChangeRegistration() {
      const typeOfRegistration = this.record['вид регистрации'] as number;
      if (typeOfRegistration !== 3) {
        this.record.расчетныепризнаки = 0;
      }
    },
    onChangeFoundation() {
      const foundation = this.record['основание выписки'] as number;
      if (foundation !== 7) {
        this.record['дата смерти'] = '';
      }
    },
  },
});
