
import Vue from 'vue';
import inputable from '../mixins/inputable';
import { StackSelectionCache } from '../../utils/selections';

/**
 * Поле связи
 */
export default Vue.extend({
  name: 'StackLinkField',
  mixins: [inputable],
  model: { prop: 'value', event: 'change' },
  props: {
    filter: { type: Object, default: null },
    floatPlaceholder: { type: Boolean, default: false },
    /** текущее значение (ключ) поля связи */
    value: { type: [String, Number], default: undefined }, // инициализированные значения линка TODO
    /**
     * модель данных
     */
    dataModel: { type: [String, Object], required: true },
    /**
     * название поля, которое является ключём
     */
    keyField: { type: String, default: '$номерЗаписи' },
    /**
     * коллбек на визуальное представление выбранной записи, когда нужно вывести что то составное
     * например `${item.номер} от ${item.дата}` или "1231231231231 в Сбербанке"
     */
    labelField: {
      type: [Function, String],
      default: (): any => {
        return '';
      },
    },
    /**
     * перечень названий полей для таблицы ('номер,примечание,фио')
     */
    headers: { type: String, required: true },
    /**
     * Можно выбирать несколько записей
     */
    multi: { type: Boolean, default: false },
    /**
     * нельзя выбирать папки
     */
    noFolder: { type: Boolean, default: false },
    /**
     * не отображать иконку поиска в тулбаре
     */
    noSearch: { type: Boolean, default: false },
    /**
     * название поля для поиска записи при вводе (например при вводе номера лицевого в классическом Стеке - это поле номер)
     */
    linkField: { type: String, default: null },
    /**
     * пропс что-бы не срабатывал клик на иконке при задизейбленом поле
     */
    readonly: { type: Boolean, default: false },
    /**
     * пропс для мультивыбора, когда необходимо выбрать на разных страницах
     */
    noPagination: { type: Boolean, default: false },
    ownerID: { type: [String, Number], default: undefined },
    /**
     * объект с кастомными параметрами в запрос
     */
    params: { type: [Object], default: null },
    /**
     * Коллбек для переопределения данных таблицы
     * (например поменять шрифт или цвет, добавить картинку, кнопку и тд)
     */
    /**
     * пропс для поиска с учетом папок
     */
    searchWithFolder: { type: Boolean, default: false },
    rowhandler: {
      type: Function,
      default: (item: StackTableRow): StackTableRow => {
        return item;
      },
    },
    /**
     * переопределение заголовка тулбара
     */
    description: { type: String, default: null },
    /**
     * Связь должна быть установлена
     */
    required: { type: Boolean, default: false },
    /**
     * Быстрый поиск вместе с папками
     */
    searchWithFolders: { type: Boolean, default: false },
    /**
     * Не выводить иконку открытия записи в соседней вкладке
     */
    noOpenLink: { type: Boolean, default: false },
    toRecord: { type: Boolean, default: false },
    testId: { type: String, default: '' },
  },
  data() {
    return {
      menu: false,
      dataObject: {} as DataModel,
      selected: [] as StackTableRow[],
      // unwatchHandler: undefined as any,
      inputValue: '' as string,
      isUserInput: false,
      loading: false,
    };
  },
  computed: {
    hasLinkIcon(): boolean {
      return !!this.route?.openLink && !this.noOpenLink;
    },
    isLinked(): string | true {
      return (
        (this.value && this.value > 0) ||
        (this.multi && this.value && this.value.toString().split(',').length > 0 && !(+this.value < 0)) ||
        'Связь должна быть установлена'
      );
    },

    // Перегрузка миксина
    customRules(): any {
      const rulls = [];
      if (this.required) {
        rulls.push(this.isLinked);
      }
      // @ts-ignore
      if (this.rules) {
        // @ts-ignore
        rulls.push(...this.rules);
      }
      return rulls.length ? rulls : undefined;
    },
    textValue(): string | null {
      if (!this.value) {
        return null;
      }
      let values = '';
      this.selected.forEach((row: any) => {
        if (row) {
          if (typeof this.labelField === 'function') {
            const value = this.labelField(row);
            values += values ? ',' + value : value;
          } else {
            const value = row[this.labelField] ? row[this.labelField] : null;
            values += values ? ',' + value : value;
          }
        }
      });
      return values;
    },
    keyValue(): string {
      let keys = '';
      if (this.selected) {
        this.selected.forEach((row: any) => {
          const key = row[this.keyField];
          keys += keys ? ',' + key : key;
        });
      }
      return keys;
    },
    route() {
      // @ts-ignore
      return !this.multi ? StackSelectionCache.getSelectionDefinition(this.dataObject.model) : undefined;
    },
  },
  methods: {
    onClose() {
      this.menu = false;
    },
    onInput(value: string) {
      this.isUserInput = true;
      this.inputValue = value;
    },
    // Если не используется ручной ввод то сразу предлагаем выбрать
    onClick() {
      // @ts-ignore
      if (!this.linkField && !this.readonly && !this.isInputDisabled) {
        this.menu = true;
      }
    },
    openNewTab() {
      if (this.route && this.value && +this.value > 0) {
        const loc = typeof this.route.openLink === 'string' ? { name: this.route.openLink } : this.route.openLink;
        if (!loc) {
          return;
        }
        const routeData = this.$router.resolve(loc);
        if (this.toRecord) {
          window.open(routeData.href + '?row_id=' + this.value);
        } else if (this.route.recordRoute && !this.selected[0].$этоПапка) {
          window.open(routeData.href + '/' + this.value);
        } else {
          window.open(routeData.href + `${this.selected[0]['$папка'] ? `?parentID=${this.selected[0]['$папка']}&` : '?'}openModal=${this.selected[0]['$номерЗаписи']}`, '_blank');
        }
      }
    },
    focus() {
      // @ts-ignore
      if (this.$refs.textField && this.$refs.textField.focus) {
        // @ts-ignore
        this.$refs.textField.focus();
      }
    },

    // при вводе руками ищем запись
    async onBlur() {
      if (this.dataObject.findRecord && this.inputValue) {
        this.loading = true;
        const records = await this.dataObject.findRecord(this.inputValue, this.linkField);
        if (records[0] && records[0][this.keyField] && (!records[0].$этоПапка || !this.noFolder)) {
          this.selected = records;
          this.sendChangeEmit();
        } else {
          this.onClear();
        }
        this.loading = false;
      } else {
        if (this.value && !this.inputValue && this.isUserInput) {
          this.onClear();
        }
      }
      this.isUserInput = false;
    },

    sendChangeEmit() {
      this.$emit('update:selected', this.selected);
      this.$emit('change', this.keyValue);
      // TODO init вообще лишний в этом компоненте наверно
      this.$emit('init', this.selected);
    },

    // Выбор записей из диалога
    onSave(selectedRows: StackTableRow[]) {
      this.selected = selectedRows;
      this.inputValue = '';
      this.sendChangeEmit();
      this.menu = false;
      this.$nextTick(() => this.focus());
    },

    onClear() {
      this.selected = [];
      this.$emit('change', null);
      this.$emit('update:selected', this.selected);
      this.isUserInput = false;
    },

    // TODO инициализация нескольких записей
    async fetchData() {
      this.selected = [];
      if (this.dataObject.model && this.value) {
        this.loading = true;
        const valueArray = this.value.toString().split(',');
        for (const i in valueArray) {
          if (this.keyField === '$номерЗаписи') {
            const rowid = +valueArray[i];
            if (rowid >= 0) {
              const rec = await this.dataObject.getRecords({ номерЗаписи: rowid });
              if (rec[0]) {
                this.selected.push(rec[0]);
              }
            }
          } else {
            let parameters =
              this.ownerID !== undefined
                ? { владелец: this.ownerID, поискСтрока: String(valueArray[i]).trim(), поискПоля: this.keyField }
                : { поискСтрока: String(valueArray[i]).trim(), поискПоля: this.keyField };
            if (this.params) {
              parameters = Object.assign(parameters, this.params);
            }
            if (this.searchWithFolder) {
              parameters = Object.assign(parameters, { развернутьПапки: true, показыватьУзлы: true });
            }
            const rec = await this.dataObject.getRecords(parameters);
            // TODO понять, что за конструкция
            rec
              .filter((item) => {
                return String(item[this.keyField]) === String(valueArray[i]).trim();
              })
              .forEach((item) => {
                this.selected.push(item);
              });
            if (!this.selected.length) {
              this.onClear();
            }
          }
        }
        this.$emit('init', this.selected);
        this.loading = false;
      }
    },
  },
  watch: {
    // TODO если очистили извне, то очищаем ручной ввод
    value: {
      async handler(to: number | string) {
        if (to === null || to === -1) {
          this.inputValue = '';
        }
        // Если сменился текущий idшник записи, то нужно перенабрать контекст
        if ((to && this.selected.length === 0) || (this.selected[0] && this.selected[0][this.keyField] && this.selected[0][this.keyField] !== +to)) {
          await this.fetchData();
        }
      },
    },
    dataModel: {
      immediate: true,
      async handler(dataModel) {
        // if (this.unwatchHandler) {
        //   this.unwatchHandler();
        // }
        if (typeof dataModel === 'string') {
          this.dataObject = new this.$HttpModel(dataModel);
        } else {
          this.dataObject = dataModel;
        }
        await this.fetchData();
        // this.unwatchHandler = this.$watch('value', this.fetchData);
      },
    },
  },
});
