
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      item: {
        датнач: null,
        даткнц: null,
        номер: null,
        лицевой: null,
        услуга: null,
        исполнитель: null,
        контрагент: null,
        группа: null,
        испсотрудник: null,
        ответственный: null,
        сумма_с: null,
        сумма_по: null,
      } as any,
    };
  },
});
