const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.НаселенныеПункты',
  description: '',
  fields: [
    {
      field: 'счета',
      text: 'Улица',
      type: 'Link',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'полныйадрес',
      text: 'Адрес',
      type: 'String',
    },
  ],
};
export default accs;
