
import Vue from 'vue';
import AsyncActionMenuFilesDialog from './AsyncActionMenuFilesDialog.vue';
import AsyncActionMenuMultiDialog from './AsyncActionMenuMultiDialog.vue';
import AsyncActionMenuList from './AsyncActionMenuList.vue';
import AsyncActionMenuInfoDialog from './AsyncActionMenuInfoDialog.vue';

export default Vue.extend({
  components: { AsyncActionMenuList, AsyncActionMenuFilesDialog, AsyncActionMenuMultiDialog, AsyncActionMenuInfoDialog },
  computed: {
    msgCount(): number {
      return this.msgActions.length;
    },
    msgCountErr(): number {
      return this.msgActions.filter(msg => msg.error).length;
    },
    msgCountComplete(): number {
      return this.msgActions.filter(msg => msg.complete).length;
    },
    msgCountBusy(): number {
      return this.msgActions.filter(msg => !msg.complete && !msg.error).length;
    },
    msgActions(): StackStateMsg[] {
      return this.$store.getters.getMsgActions().sort((a: StackStateMsg, b: StackStateMsg) => {
        return Date.parse(a.created) < Date.parse(b.created) ? 1 : -1;
      });
    },
    getMultyThreads(): number {
      return this.$store.getters['flStore/getMultyThreads']();
    },
    getColor():string {
      return this.msgCountBusy ? 'warning' : this.msgCountErr ? 'error' : 'success';
    },
  },
  data() {
    return {
      multiDialogEnable: false,
      infoDialogEnable: false,
      filesDialogEnable: false,
      files: [] as StackResultAsFile[],
      multiID: null as null | string,
      message: '',
      title: '',
      stacktrace: undefined,
    };
  },
  methods: {
    openAttach(msg: StackStateMsg) {
      if (msg.files) {
        if (msg.files.length > 1) {
          this.files = msg.files;
          this.filesDialogEnable = true;
        } else {
          // Пакет файлов
          window.open(this.$store.getters.getApiHostShare() + msg.files[0].fileUrl);
        }
      }
    },
    onSubtitleClick(msg: StackStateMsg) {
      if (msg.status) {
        this.stacktrace = undefined;
        if (msg.error) {
          this.stacktrace = msg.stacktrace;
        }
        this.message = msg.status;
        this.title = msg.title;
        this.infoDialogEnable = true;
      }
    },

    openURL(msg: StackStateMsg) {
      if (msg.to) {
        window.open(this.$router.resolve({ path: msg.to }).href);
        return;
      }
      if (msg.type === 'multi') {
        this.multiID = msg.asyncId ? msg.asyncId : null;
        if (this.getMultyThreads > 1) this.multiDialogEnable = true;
      } else if (msg.files) {
        this.openAttach(msg);
      } else {
        this.onSubtitleClick(msg);
      }
    },
    async deleteMessage(msg: StackStateMsg) {
      if (!msg.complete && !msg.error) {
        if (await this.$yesno('Вы уверены, что хотите отменить задачу ?')) {
          this.$store.commit('MSG_DELETE', msg.asyncId);
          const api = new this.$StackApi();
          await api.cancelAsyncJob(msg.asyncId);
        }
      } else {
        this.$store.commit('MSG_DELETE', msg.asyncId);
      }
    },
    onClearAll() {
      this.$store.commit('MSG_CLEAR');
    },
  },
});
