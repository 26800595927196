
import Vue from 'vue';
import PrintKomplekt from '@/tasks/dlg_fl/components/$dialogs/PrintKomplekt.vue';
import ReadingInputDialog from '@/tasks/fl/views/$common/$readingdialog/ReadingInputDialog.vue';
import TransitionModal from './TransitionModal.vue';

export default Vue.extend({
  name: 'Trans',
  components: { 'Print-Komplekt': PrintKomplekt, 'ReadingInput-Dialog': ReadingInputDialog, TransitionModal },
  model: { prop: 'parametersdop', event: 'input' },
  props: {
    parametersdop: { type: [Object, Array], required: true },
  },

  data() {
    return {
      headers: 'номерлс,допфио,номердела,дата документа,дата контроля,текущаясумма,@состояние,датнач' as string,
      dopfields: '',
      dataObject: new this.$HttpModel('РеестрПерехода'),
      modelControl: null as any,
      filter: {
        долгот: 0,
        долгдо: 0,
        дсостояние: this.parametersdop.НаДату.НаДату,
        'дата уведомления': '',
        ТекущаяФаза: -1,
        ТекущееСостояние: -1,
        статус: -1,
        доставка: -1,
        организации: -1,
      } as any,
      record: {
        показаниясчетчиков: 0,
        номерЗаписи: 0,
        счет: 0,
      } as any,
      modaldialog: false,
      loading: false,
      items: [] as any[],
      prefix: '',
      SelectIndex: [] as any,
      matixMoving: [] as any,
      arrMoving: [] as any,
      arrDelivery: [] as any,
      readinginput: false,
    };
  },
  computed: {
    isk(): boolean {
      return this.dopfields.indexOf('дата уведомления') !== -1;
    },
    delivery(): boolean {
      return this.dopfields.indexOf('доставка') !== -1;
    },
    nomerUv(): boolean {
      return this.dopfields.indexOf('номеруведомления') !== -1;
    },
    pokSchet(): boolean {
      return this.dopfields.indexOf('показаниясчетчиков') !== -1;
    },
    executor(): boolean {
      return this.dopfields.indexOf('организации') !== -1;
    },
  },
  async created() {
    // Сформируем статичные массивы перехода и доставки
    const resultsMoving = await new this.$HttpModel('РеестрПерехода_Переходы').getRecords({ ТекущаяФаза: this.filter.ТекущаяФаза, ТекущееСостояние: this.filter.ТекущееСостояние });
    for (const key in resultsMoving) {
      this.arrMoving.push(resultsMoving[key].значение);
    }
    const resultsDelivery = await new this.$HttpModel('Состояние_документа_СпособыДоставки').getRecords();
    for (const key in resultsDelivery) {
      this.arrDelivery.push(resultsDelivery[key].значение);
    }
    // Подкидиваем массивы в поля модели
    for (const key_matrix in this.dataObject.fields) {
      if (this.dataObject.fields[key_matrix].field === 'состояние') {
        // @ts-ignore
        this.dataObject.fields[key_matrix].dataModel = this.arrMoving;
      }
      if (this.dataObject.fields[key_matrix].field === 'доставка') {
        // @ts-ignore
        this.dataObject.fields[key_matrix].dataModel = this.arrDelivery;
      }
    }
    this.initializationTableControl();
  },

  methods: {
    onEnterPok(payload: any) {
      this.record.показаниясчетчиков = payload.показаниясчетчиков;
      this.record.номерЗаписи = payload.ид;
      this.record.счет = payload['счет-взыскания'];
      this.readinginput = true;
    },
    async initializationTableControl() {
      const results = await this.dataObject.getRecords({ Параметры: this.filter });
      this.modelControl = new this.$StaticModel(results, { rowTemplate: this.dataObject.fields as StackField[], description: this.dataObject.description });
      if (this.modelControl.data.length !== 0) {
        this.filter.ТекущаяФаза = this.modelControl.data[0]['фаза-состояние'];
        this.filter.ТекущееСостояние = this.modelControl.data[0]['подфаза-состояние'];
        this.matixMoving = await this.dataObject.executeMethod('ОткрытыеПоля', { Параметры: this.filter });
        for (const key_matrix in this.matixMoving) {
          for (const key_headers in this.matixMoving[key_matrix]) {
            this.prefix = key_headers.toLowerCase() === 'доставка' || key_headers.toLowerCase() === 'организации' ? '@' : '';
            this.headers += this.headers.indexOf(key_headers.toLowerCase()) === -1 && key_headers.toLowerCase() !== 'показаниясчетчиков' ? ',' + this.prefix + key_headers.toLowerCase() : '';
            this.dopfields += this.headers.indexOf(key_headers.toLowerCase()) === -1 ? ',' + key_headers.toLowerCase() : '';
          }
        }
      } else {
        this.$stackMsg([
          {
            текст: `Не найдено дел для дальнейшей обработки`,
            варианты: ['Ок'],
          },
        ]);
      }
    },
    async ApplyReadings() {
      if (this.filter.статус > 0) {
        const parameters = { Параметры: this.filter, 'Обновление данных': false, Индексы: this.SelectIndex };
        await this.dataObject.executeMethod('ПрименитьДляВсех', parameters, { jobName: 'Массовый переход', type: 'func' });
        // @ts-ignore
        this.$refs.table.fetchData();
      } else {
        this.$stackMsg([
          {
            текст: `Выберите следующее состояние`,
            варианты: ['Ок'],
          },
        ]);
      }
    },
    async СheckMassKomplekt() {
      // Смотрим, есть ли комплекты для массового перехода
      const res = await new this.$HttpModel('РеестрПерехода_Печать').getRecords({
        текущаяФаза: this.filter.ТекущаяФаза,
        текущееСостояние: this.filter.ТекущееСостояние,
        тип: 1,
      });
      if (res && res.length !== 0) {
        this.modaldialog = true;
      } else {
        this.$emit('closeTransition');
      }
    },
    async sendControls() {
      this.loading = true;
      const parameters = { Параметры: this.filter, 'Подготовка данных': false };
      await this.dataObject.executeMethod(
        'МассовыйПереход',
        parameters, {
          async: true,
          jobName: 'Массовый переход',
          type: 'func',
          onComplete: () => {
            this.СheckMassKomplekt();
            this.loading = false;
          },
        },
      );
    },
    async Change_delivery() {
      this.items = await this.dataObject.executeMethod('ПроверкаДоставки', { Параметры: this.filter, отдел: this.parametersdop.otdel });
      if (this.items !== this.filter.доставка) {
        this.$toast('Выбранный способ доставки не может быть установлен на дело!');
        this.filter.доставка = this.items;
      }
    },
    async Change_sost() {
      const parameters = { Параметры: this.filter, 'Подготовка данных': true, отдел: this.parametersdop.otdel };
      this.headers = 'номерлс,допфио,номердела,дата документа,дата контроля,текущаясумма,@состояние,датнач';
      this.dopfields = '';
      this.matixMoving = null;
      this.matixMoving = await this.dataObject.executeMethod('ОбработчикПерехода', { Параметры: this.filter });
      this.items = await this.dataObject.executeMethod('ПроверкаПолей', parameters);
      for (const key in this.items) {
        this.prefix = this.items[key].columnName.toLowerCase() === 'доставка' || this.items[key].columnName.toLowerCase() === 'организации' ? '@' : '';
        this.headers +=
            this.headers.indexOf(this.items[key].columnName.toLowerCase()) === -1 && this.items[key].columnName.toLowerCase() !== 'показаниясчетчиков'
              ? ',' + this.prefix + this.items[key].columnName
              : '';
        this.dopfields += this.dopfields.indexOf(this.items[key].columnName.toLowerCase()) === -1 ? ',' + this.items[key].columnName.toLowerCase() : '';
        this.filter[this.items[key].columnName] = this.items[key].columnName === 'доставка' ? +this.items[key].record : this.items[key].record;
      }
    },
  },
});
