const orgs: StackSelection = {
  objectName: 'Объекты.Доступ',
  description: '',
  fields: [
    {
      field: 'значение',
      text: 'Значение',
      type: 'String',
    },
  ],
};
export default orgs;
