const files: StackSelection = {
  objectName: 'ЗаявкиАбонентов.Файлы',
  description: 'Внешние файлы',
  fields: [
    {
      field: 'имя файла',
      text: 'Имя файла',
      type: 'String',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'комментарий',
      text: 'Комментарий',
      type: 'String',
    },
    {
      field: 'расширение',
      text: 'Расширение',
      type: 'Enum',
    },
  ],
};
export default files;
