
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: {
      type: Object,
      default: (): any => {
        return {};
      },
    },
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        'Параметры.ДатНач': this.$store.getters.getWorkMonth(),
        'Параметры.ДатКнц': this.$store.getters.getWorkMonth(),
        'Параметры.НомерЗаписиУслуги': -1,
        'Параметры.НомерЗаписиКатегории': -1,
        'Параметры.НомерЗаписиПоставщика': -1,
        'Параметры.НомерЗаписиКатегорииПост': -1,
        'Параметры.НомерЗаписиУК': -1,
        'Параметры.НомерЗаписиУКДоговор': -1,
        'Параметры.showMonth': 0,
        'Параметры.Флаги': 2,
        'Параметры.флТариф': 0,
        'Параметры.ПоказыватьАн2': 0,
        'Параметры.Переключатель': 0,
        'Параметры.фДогУК': 0,
        'Параметры.флКат': 0,
        'Параметры.флУслКат': 0,
        'Параметры.фФильтрПоВидамДоговора': 0,
      } as any,
      showDogovorTypeFilter: false as boolean,
    };
  },
  methods: {
    onChangeParametersFlags(flags: number) {
      if (+flags % 2 === 0) {
        this.record['Параметры.фДогУК'] = 0;
        this.record['Параметры.фФильтрПоВидамДоговора'] = 0;
      }
    },
    onChangeParametersPerekl(perekl: number) {
      if (+perekl === 1 && (this.params.onSaldo === false || this.params.onSaldo === undefined)) {
        this.record['Параметры.ПоказыватьАн2'] = 1;
      } else {
        this.record['Параметры.ПоказыватьАн2'] = 0;
        this.record['Параметры.флТариф'] = 0;
      }
    },
    onChangeServ(id: number) {
      this.record['Параметры.НомерЗаписиКатегории'] = -1;
    },
    onChangeCategServ(id: number) {
      this.record['Параметры.НомерЗаписиУслуги'] = -1;
    },
    onChangePost(id: number) {
      this.record['Параметры.НомерЗаписиКатегорииПост'] = -1;
    },
    onChangeCategPost(id: number) {
      this.record['Параметры.НомерЗаписиПоставщика'] = -1;
    },
    onChangeParametersFDogUK(id: number) {
      if (+id % 2 === 0) {
        this.record['Параметры.фФильтрПоВидамДоговора'] = 0;
      }
    },
    onChangeDogUK(id: number) {
      if (+id > 0) {
        this.record['Параметры.фФильтрПоВидамДоговора'] = 0;
      }
    },
    onInitDogovorTypeFilter(item: any) {
      this.showDogovorTypeFilter = !!(item.length > 0 && item.length !== undefined);
    },
  },
});
