const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.РаспределенныйРасходОДПУ_ПоДокументу',
  description: 'Распределенный расход ОДПУ',
  fields: [
    {
      field: 'номерлс',
      text: 'Номер ЛС',
      type: 'Number',
    },
    {
      field: 'адреслс',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'объемлс',
      text: 'Объем',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Формула',
      type: 'String',
    },
  ],
};
export default accs;
