
import Vue from 'vue';
export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      values: [false, false, false],
    };
  },
  methods: {
    onChange(flags: number) {
      this.values[2] = (flags & 1) === 0;
      if (this.values[2]) {
        this.record.значение = (this.record.значение as number) & 3;
      }
    },
  },
});
