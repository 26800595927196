
import Vue from 'vue';
import DeductionFilter from './$components/DeductionsFilter.vue';
import deductionfolder from '@/tasks/common/views/$common/documentFolder.vue';
import DeductionToolbar from './$components/DeductionToolbar.vue';

export default Vue.extend({
  name: 'Deductions',
  components: { DeductionFilter, deductionfolder, DeductionToolbar },
  computed: {
    openMonth(): Date {
      return this.$store.getters.getOpenMonth();
    },
  },
  methods: {
    // TODO убрать копипасту
    async onLockUnlock(row: StackTableRow) {
      await new this.$HttpModel('ВедомостиУдержаний').executeMethod('Блокировать', { ид: row.$номерЗаписи });
      // @ts-ignore
      this.$refs.table.fetchData();
    },
  },
});
