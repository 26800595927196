const acts: StackSelection = {
  objectName: 'ПочтовыйКлиент',
  description: 'Почтовый клиент',
  hier: true,
  prepareRow: row => {
    if (row.$этоПапка && row.$папка !== -10) {
      row.$этоПапка = false;
    }
    return row;
  },
  fields: [
    {
      field: 'откого',
      text: 'От',
      type: 'String',
    },
    {
      field: 'кому',
      text: 'Кому',
      type: 'String',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'блокировать',
      text: 'Блокировать',
      type: 'String',
    },
    {
      field: 'вложение',
      text: 'Вложение',
      type: 'String',
    },
    {
      field: 'обработано',
      text: 'Обработано',
      type: 'String',
    },
    {
      field: 'отб_создано',
      text: 'Создано',
      type: 'String',
    },
    {
      field: 'отб_получено',
      text: 'Получено',
      type: 'String',
    },
  ],
};
export default acts;
