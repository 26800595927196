
import Vue from 'vue';
export default Vue.extend({
  props: {
    /** запись, в которой было вызвано меню */
    item: { type: Object, required: true },

    /** TODO переименовать бы Параметры таблицы  */
    params: { type: Object, required: true },
    /** Если noSelect, то нужно показывать удаление на строке */
    noSelect: { type: Boolean, default: false },
    /** список пунктов меню  */
    items: {
      type: Array,
      default: (): object[] => {
        return [
          { title: 'Редактировать', icon: '$vuetify.icons.edit', action: 'edit' },
        ];
      },
    },
    tableAction: { type: Function, required: true }, // коллбек к таблице
    /**
     * Нельзя удалять
     */
    noDelete: { type: Boolean, default: false },
    /**
     * Нельзя редактировать
     */
    noEdit: { type: Boolean, default: false },
    // Открыть запись в новой вкладке
    openLink: { type: Boolean, default: false },
  },
  computed: {
    actionItems(): any {
      const ai = [...this.items];
      if (this.openLink) {
        ai.push({ title: 'Открыть запись в новой вкладке', icon: '$vuetify.icons.open_in_new_tab', action: 'open-link' });
      }
      // TODO
      // @ts-ignore
      if (this.noSelect && !this.noDelete && !this.$options.propsData?.items) {
        ai.push({ title: 'Удалить', icon: '$vuetify.icons.delete', action: 'delete', color: 'error' });
      }
      return ai;
    },
  },
  methods: {
    onClick(action: string, item: StackTableRow) {
      this.tableAction({ action, payload: item });
    },
    onShiftClick(action: string, item: StackTableRow) {
      if (action === 'edit') {
        this.tableAction({ action: 'copy', payload: item });
      }
    },
  },
});
