
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: '@Месяц',
          text: 'Месяц',
          type: 'String',
          readonly: true,
        },
        {
          field: '@01',
          text: '01',
          type: 'Number',
        },
        {
          field: '@02',
          text: '02',
          type: 'Number',
        },
        {
          field: '@03',
          text: '03',
          type: 'Number',
        },
        {
          field: '@04',
          text: '04',
          type: 'Number',
        },
        {
          field: '@05',
          text: '05',
          type: 'Number',
        },
        {
          field: '@06',
          text: '06',
          type: 'Number',
        },
        {
          field: '@07',
          text: '07',
          type: 'Number',
        },
        {
          field: '@08',
          text: '08',
          type: 'Number',
        },
        {
          field: '@09',
          text: '09',
          type: 'Number',
        },
        {
          field: '@10',
          text: '10',
          type: 'Number',
        },
        {
          field: '@11',
          text: '11',
          type: 'Number',
        },
        {
          field: '@12',
          text: '12',
          type: 'Number',
        },
        {
          field: '@13',
          text: '13',
          type: 'Number',
        },
        {
          field: '@14',
          text: '14',
          type: 'Number',
        },
        {
          field: '@15',
          text: '15',
          type: 'Number',
        },
        {
          field: '@16',
          text: '16',
          type: 'Number',
        },
        {
          field: '@17',
          text: '17',
          type: 'Number',
        },
        {
          field: '@18',
          text: '18',
          type: 'Number',
        },
        {
          field: '@19',
          text: '19',
          type: 'Number',
        },
        {
          field: '@20',
          text: '20',
          type: 'Number',
        },
        {
          field: '@21',
          text: '21',
          type: 'Number',
        },
        {
          field: '@22',
          text: '22',
          type: 'Number',
        },
        {
          field: '@23',
          text: '23',
          type: 'Number',
        },
        {
          field: '@24',
          text: '24',
          type: 'Number',
        },
        {
          field: '@25',
          text: '25',
          type: 'Number',
        },
        {
          field: '@26',
          text: '26',
          type: 'Number',
        },
        {
          field: '@27',
          text: '27',
          type: 'Number',
        },
        {
          field: '@28',
          text: '28',
          type: 'Number',
        },
        {
          field: '@29',
          text: '29',
          type: 'Number',
        },
        {
          field: '@30',
          text: '30',
          type: 'Number',
        },
        {
          field: '@31',
          text: '31',
          type: 'Number',
        },
      ],
    };
  },
});
