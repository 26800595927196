
import Vue from 'vue';
import FileTable from '@/tasks/common/views/$common/fileTable.vue';

export default Vue.extend({
  components: { FileTable },
  model: { prop: 'record' },
  props: {
    record: { type: Object, required: true },
    ownerID: { type: [String, Number], required: true },
    title: { type: String, default: 'Документ владения' },
    showOwner: { type: Boolean, default: false },
  },
  data() {
    return {
      tabs: null,
      tabs2: null,
      initPerson: {
        inn: null,
        snils: null,
      } as any,
      isOver: false,
      error: 0,
      blockProxy: true,
    };
  },
  computed: {
    checkQuota(): string | true {
      if (this.record && (this.record.доля_числитель || this.record.доля_знаменатель)) {
        const one = Number(this.record.доля_числитель);
        const two = Number(this.record.доля_знаменатель);
        if (one > two || one === 0 || (two === 0 && one >= 1)) {
          return 'Некорректная доля';
        }
        if (this.isOver) {
          return 'Сумма долей на лс превышает 1';
        }
      }
      return true;
    },
    checkFoundation(): string | true {
      if (this.error === 1) {
        return 'На лицевом уже есть документ владения с признаком "Частная собственность"';
      }
      if (this.error === 2) {
        return 'На лицевом может быть только один документ владения без признака "Частная собственность"';
      }
      return true;
    },
  },
  methods: {
    changeTitle() {
      if (this.record && this.record.титульный) {
        new this.$HttpModel('ЧастныеЛица.ДокументыНаВладение').executeMethod('ПроверкаРебенка', { человек: this.record['человек-владение'] });
      }
    },
    async onChangeDoli() {
      if (+this.record.доля_числитель > 0 && +this.record.доля_числитель < 1) {
        this.record.доля_знаменатель = 1;
      }
      this.isOver = await this.checkSumDoli();
    },
    async checkSumDoli() {
      if (+this.record.доля_числитель > 0 && +this.record.доля_знаменатель > 0) {
        const result = await new this.$HttpModel('ЧастныеЛица.ДокументыНаВладение').executeMethod('ПроверкаДолей', {
          счет: this.record['счет-владение'],
          запись: this.record['$номерЗаписи'],
          датнач: this.record['датнач'],
          даткнц: this.record['даткнц'],
        });
        const sumDoli = +result.toFixed(7) + +(this.record.доля_числитель / this.record.доля_знаменатель).toFixed(7);
        return sumDoli > 1;
      }
      return false;
    },
    async changePerson(payload: any) {
      if (payload && payload[0]) {
        this.$set(this.initPerson, 'inn', payload[0].инн);
        this.$set(this.initPerson, 'snils', payload[0]['пенсионное свидетельство']);
      }
    },
    async changeFoundation(payload: StackTableRow[]) {
      const result = await new this.$HttpModel('ЧастныеЛица.ДокументыНаВладение').executeMethod('ПроверкаОснования', {
        счет: this.record['счет-владение'],
        основание: this.record['основание'],
        запись: this.record['$номерЗаписи'],
        датнач: this.record['датнач'],
        даткнц: this.record['даткнц'],
      });
      this.isOver = await this.checkSumDoli();
      this.error = +result;
      this.blockProxy = payload && payload[0] ? payload[0].частнаясобственность === 1 : false;
      this.record.довлицо = this.blockProxy ? false : this.record.довлицо;
    },
    async onBeforeCreate() {
      if (await this.onBeforeChange()) {
        this.$emit('addRecord', this.record);
        return true;
      }
      return false;
    },
    async onBeforeChange() {
      if (await this.checkSumDoli()) {
        this.$toast('Сумма долей на лс превышает 1', { color: 'error' });
        return false;
      }
      return true;
    },
  },
});
