
import Vue from 'vue';
import logsFilter from '@/tasks/common/views/$common/filters/logsFilter.vue';
import { TableHandlers } from '@/stackEngine';

export default Vue.extend({
  name: 'HistoryLsTab',
  components: { logsFilter },
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
    height: { type: String, default: '100%' },
  },
  methods: {
    logsDocTabHandler(item: any) {
      if (this.record.$номерЗаписи && item['докжурнал-счет'] && this.record.$номерЗаписи !== item['докжурнал-счет']) {
        TableHandlers.trColor(item, 'grey');
      }
      return item;
    },
  },
});
