
import Vue from 'vue';

import { TableHandlers } from '@/stackEngine';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'УК' },
  },
  computed: {
    disabledUkDebts(): boolean {
      return !(this.record.принимаетвседолги !== 0 &&
        (this.record.примечание === '' || this.record['@долгдатнач'] === '' || this.record['@долгдаткнц'] === '' || this.record.$номерЗаписи === -1));
    },
  },
  data() {
    return {
      flag: ['Принимает все долги'],
      flagvd: true,
    };
  },
  methods: {
    labelFieldUKHandler(item: StackTableRow) {
      return '№' + item.номер + ' ' + item.тема + ' ' + item['организация-укдоговор>название'] + ' от ' + this.$stackDate.format(item.датнач as string, 'DD.MM.YYYY');
    },
    // TODO: обработчик задублирован из справочников договоров УК; убрать дублирование
    dogUkHandler(item: any) {
      if (!item.$этоПапка && item.флагестьдома === 0) {
        TableHandlers.trColor(item, 'grey');
      }
      return item;
    },
    disableService(item: any) {
      this.flagvd = item && item[0] && item[0]['вид договора'] === 0;
      this.record['услуга-укдоговор'] = this.flagvd ? -1 : this.record['услуга-укдоговор'];
    },
  },
});
