const work: StackSelection = {
  objectName: 'Пользователи',
  description: 'Пользователи',
  customApi: 'admin',
  fields: [
    {
      field: 'номер',
      text: 'Табельный номер',
      type: 'String',
    },
    {
      field: 'имя',
      text: 'Логин',
      type: 'String',
    },
    {
      field: '@п1',
      text: 'п1',
      type: 'Number',
    },
    {
      field: '@п2',
      text: 'п2',
      type: 'Number',
    },
    {
      field: 'ф1',
      text: 'ф1',
      type: 'Number',
    },
    {
      field: 'ф2',
      text: 'ф2',
      type: 'Number',
    },
    {
      field: '@сотрудник',
      text: 'сотрудник',
      type: 'Link',
    },
    {
      field: 'код',
      text: 'Код',
      type: 'Number',
    },
    {
      field: '@пароль1',
      text: 'пароль1',
      type: 'String',
    },
    {
      field: 'политика',
      text: 'Политика',
      type: 'String',
    },
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
    },
  ],
};

export default work;
