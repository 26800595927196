
import Vue from 'vue';
import datnachDatend from './$datNachdatEnd.vue';

export default Vue.extend({
  components: { datnachDatend },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    hideDate: { type: Boolean, default: false },
  },
  data() {
    return {
      month: 0,
    };
  },
  watch: {
    month(to) {
      this.record['знач2'] = to;
    },
  },
});
