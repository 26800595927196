
import Vue from 'vue';
import paramTable from '@/tasks/common/views/$common/paramTable.vue';
import StateDialog from './counterid/$dialogs/StateDialog.vue';
import FileTable from '@/tasks/common/views/$common/fileTable.vue';
import AbnormalWorkTable from '@/tasks/common/views/$common/abnormalworkhours/abnormalTable.vue';
import InspectionsActDialog from '@/tasks/fl/views/documents/inspectionsacts/$resources/_inspectionsactid.vue';
import SealsToolbar from './counterid/SealsToolbar.vue';
import PlombDialog from './counterid/$dialogs/PlombDialog.vue';
import BlankDialog from './counterid/$dialogs/BlankDialog.vue';
import PlombToolbar from './counterid/PlombToolbar.vue';

export default Vue.extend({
  model: { prop: 'record' },
  components: { paramTable, StateDialog, FileTable, AbnormalWorkTable, SealsToolbar, PlombDialog, BlankDialog, PlombToolbar },
  name: 'CommonCounter',
  props: {
    dataModel: { type: String, default: undefined },
    ownerID: { type: [Number, String], default: undefined },
    record: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
    isServiceRuleEnable: { type: Boolean, default: false },
  },
  data() {
    return {
      tarifs: [] as string[],
      // record: {} as StackTableRow,
      measuretype: {} as any,
      dostup: [' ', 'Доступен', 'Не доступен'],

      closemode: false as boolean, // нажали кнопку снять
      restoremode: false as boolean, // нажали кнопку восстановить
      isClosedCounter: false as boolean, // признак закрытого пу
      isDoubled: false as boolean, // есть двойной недопуск
      repairmode: false as boolean, // нажали кнопку восстановить учет
      isPressedButton: false as boolean, // флаг нажатия любой кнопки
      items: [] as StackTableRow[],
      serviceItems: [] as StackTableRow[],
      loading: false,
      rules: Object.assign(this.$stackRules, {
        dateLessThanDateReadings: (date1: string, date2: string, strError: string): string | true => {
          return Date.parse(date1) > Date.parse(date2) || date2 === null || strError || 'Дата снятия меньше даты последних показаний';
        },
        dateLessThanMonthReadings: (date1: string, date2: string): string | true => {
          const firstDay = this.$stackDate.addDays(new Date(date1), -1);
          const firstDayM = this.$stackDate.firstDay(firstDay);
          return Date.parse(firstDayM) >= Date.parse(date2) || date2 === null || 'Есть показания за более поздний расчетный месяц!';
        },
        sameServiceRule: (val: number): string | true => {
          // @ts-ignore
          if (this.isServiceRuleEnable && !!this.items.filter((item) => item['объект-услуга'] !== val && item['объект-услуга'] !== -1).length && val !== -1) {
            return 'Можно использовать только один вид услуг';
          }
          return true;
        },
        checkReading: (val: number, int: number, fact: number): string | true => {
          // проверка разрядности, выделим целую и дробную часть
          let integer, factorial;
          if (val && val < 0) {
            return 'Нельзя вводить отрицательное показание!';
          }
          if (!val && val !== 0) {
            return 'Вы не ввели показание!';
          }
          // TODO
          // eslint-disable-next-line prefer-const
          [integer, factorial] = val.toString().split('.');
          if (integer && int && integer.length > int) {
            return 'Вы превысили разрядность счетчика!';
          }
          if (factorial && fact && factorial.length > fact) {
            return 'Вы превысили дробную разрядность счетчика!';
          }
          return true;
        },
        checkTarifCount: (val: number): string | true => {
          if (val < 0 || val > 3) {
            return 'Неверное количество тарифов';
          }
          return true;
        },
      }),

      readings: {
        дата: this.$store.getters.getCurrentDate(),
        0: { показание: null, расход: 0, допрасход: 0, итоговыйрасход: 0 },
        1: { показание: null, расход: 0, допрасход: 0, итоговыйрасход: 0 },
        2: { показание: null, расход: 0, допрасход: 0, итоговыйрасход: 0 },
        3: { показание: null, расход: 0, допрасход: 0, итоговыйрасход: 0 },
        4: { показание: null, расход: 0, допрасход: 0, итоговыйрасход: 0 },
        5: { показание: null, расход: 0, допрасход: 0, итоговыйрасход: 0 },
      } as any,
      lastReadings: [] as any,
      lastReadingsIsChange: [false, false, false, false, false, false],
      lastReadingsDate: null as null | string,
      lastReadingsMonth: null as null | string,

      dataObject: new this.$HttpModel(this.dataModel),
      seelsRecords: [] as StackTableRow[],
      menux: 0,
      menuy: 0,
      newPlombDialogVisible: false,
      askdata: {
        visible: false,
        answer: null,
        variants: ['Перекрутка счетчика', 'Отрицательный расход', 'Ошибка оператора'],
      } as any,
    };
  },
  async created() {
    if (this.isNewRecord) {
      this.tarifs = await this.tariffToZones(this.record.тарифность, this.record['объект-услуга'], this.record.микрогенерация);
    }
    this.$set(this.record, '@безпоказаний', 0);
    this.loading = true;
    // Определим закрыт ли счетчик по ДатКнц
    this.checkClosedCounter();
    this.checkDouble();
    if (this.isServiceRuleEnable) {
      this.items = await this.dataObject.getRecords({ владелец: this.ownerID });
    }
    this.loading = false;
  },
  computed: {
    isClosedObj(): boolean {
      return this.record.идентификатор !== 0 && this.record.даткнц !== null && new Date(this.record.даткнц).getFullYear() !== 2045;
    },
    objReadings(): any {
      return this.record.идентификатор === 0 ? this.readings : undefined;
    },
    zrules(): any {
      return this.record.идентификатор === 0 ? [this.rules.isRequired, this.rules.noSpecialSymbols] : undefined;
    },
    title(): string {
      return 'Информация об объекте ' + this.titleAcc;
    },
    titleAcc(): string {
      return this.record.номерлс ? '( Лицевой счет № ' + this.record.номерлс + ' )' : '';
    },
    isNewRecord(): boolean {
      return !(this.record.$номерЗаписи > 0) || this.record.$блок;
    },
    typeOperation(): string | undefined {
      if (this.isNewRecord) {
        return 'Установка';
      }
      if (this.closemode) {
        return 'Снятие';
      }
      if (this.repairmode) {
        return 'Восстановление';
      }
      return undefined;
    },
    isRsoCounter(): boolean {
      if (this.record && this.record.видпотребления) {
        return this.$store.getters['flStore/getComplexKey'](this.record.видпотребления) === 'РСО';
      }
      return false;
    },
    isOdpuCounter(): boolean {
      return this.dataModel === 'ГрупповыеСчетчики.Счетчики';
    },
    isNetCounter(): boolean {
      return this.record && this.record['объекты-сетьсоединение'] > 0;
    },
    withoutLastReadings(): boolean {
      return !this.isClosedCounter && this.record.даткнц !== null && new Date(this.record.даткнц).getFullYear() !== 2045;
    },
    isVisibleAbnormalWorkTable(): boolean {
      return this.$store.getters.getCurrentTask() === 'ul' && (this.isRsoCounter || this.isOdpuCounter);
    },
    isConsiderPlombs(): boolean {
      return this.$store.getters['flStore/getConsiderPlombs']();
    },
    isConsiderBlanks(): boolean {
      return this.$store.getters['flStore/getConsiderBlanks']();
    },
    showRestoreButton(): boolean {
      return this.isClosedCounter || this.isClosedObj;
    },
    disabledRestoreButton(): boolean {
      return this.restoremode || this.repairmode || this.readonly || this.isPressedButton;
    },
    disabledCloseCounterButton(): boolean {
      return this.isNewRecord || this.closemode || this.repairmode || this.readonly || this.isPressedButton;
    },
    showRepairButton(): boolean {
      return !this.isClosedObj && this.isDoubled && !this.isNewRecord;
    },
    disabledRepairButton(): boolean {
      return this.isNewRecord || this.closemode || this.isPressedButton;
    },
    showClosedPok(): boolean {
      return this.isClosedCounter || this.closemode;
    },
  },
  methods: {
    async beforeClose() {
      if (this.dataModel !== 'ЛицевыеСчета.Объекты' || this.readonly || this.record.$толькоЧтение) {
        return true;
      }
      if (this.record.$блок || !(this.record.$номерЗаписи > 0)) {
        return true;
      }
      if (this.closemode || this.restoremode) {
        return true;
      }
      // Проверяем только у Счетчика
      if (this.record.идентификатор === 0) {
        const result = await new this.$HttpModel('ЛицевыеСчета.Объекты').executeMethod('ПроверкаПломбы', { номерЗаписи: this.record.$номерЗаписи });
        if (result) {
          if (!(await this.$yesno('Не заполнена пломба счетчика. Уверены, что хотите выйти из диалога?'))) {
            return false;
          }
        }
      }
      return true;
    },
    async onBeforeSave() {
      if (this.seelsRecords) {
        const indexChangeSeels = this.seelsRecords.findIndex((row: StackTableRow) => {
          return row.$естьИзменения || (row.$номерЗаписи && row.$номерЗаписи <= 0);
        });
        if (indexChangeSeels >= 0) {
          if (!(await this.$yesno('Не сохранены пломбы счетчика. Уверены, что хотите выйти из диалога?'))) {
            return false;
          }
        }
        // Делаем проверку только, если счетчики с лс
        if (this.dataModel === 'ЛицевыеСчета.Объекты' && this.closemode) {
          const warningMsg = await new this.$HttpModel('ЛицевыеСчета.Объекты').executeMethod('ПроверитьСчетчик', {
            счет: this.record['объекты-счет'],
            услуга: this.record['объект-услуга'],
            даткнц: this.record.даткнц,
            владелец: this.record.$номерЗаписи,
          });
          if (warningMsg) {
            this.$stackMsg([
              {
                текст: warningMsg,
                варианты: ['ОК'],
              },
            ]);
          }
        }
      }
      return true;
    },
    // обновление полей начальных показаний при смене тарифности реализование через @input, так как при реализации
    // через computed properties бывают ситуации, когда при изменении поля "Тарифность" computed не обновляется;
    async onInputTariff(payload: number) {
      this.tarifs = await this.tariffToZones(payload, this.record['объект-услуга'], this.record.микрогенерация);
    },
    async tariffToZones(tarif: number, usl: number, microgen: number) {
      const masLabelsTar = await new this.$HttpModel('СписокМетокТарифов').executeMethod('ВернутьМассивМеток', {
        нзусл: usl,
        тарифность: tarif,
        мг: microgen,
      });
      return Object.values(masLabelsTar) as string[];
    },
    async onSelectMeasure(event: string, payload: any) {
      if (payload && payload[0]) {
        this.measuretype = payload[0];
        if (this.isNewRecord && !(this.record.$копировать && event === 'Init')) {
          this.$set(this.record, 'тарифность', Math.max(this.measuretype.тарифность, 1));
          this.$set(this.record, 'микрогенерация', this.measuretype['микрогенерация']);
          this.tarifs = await this.tariffToZones(this.record.тарифность, +this.measuretype['@услуга'], this.record.микрогенерация);

          this.$set(this.record, 'разрядность', this.measuretype['счетчика разрядность']);
          this.$set(this.record, 'дробнаяразрядность', this.measuretype['счетчика дробная разрядность']);
          if (this.serviceItems.map(item => item.ключ).includes(this.measuretype['@услуга'])) {
            this.$set(this.record, 'объект-услуга', Number(this.measuretype['@услуга']));
          }

          // сформируем номер по порядку
          if (event === 'Update' && this.dataObject && this.dataObject.executeMethod) {
            const tmpResult = await this.dataObject.executeMethod('ПолучитьНПП', {
              запись: this.record,
            });
            if (tmpResult !== 0) {
              this.record['n п/п'] = tmpResult;
            }
          }
        }
        this.$set(this.record, 'микрогенерация', this.measuretype['микрогенерация']);
        this.changeNextPoverkaDate('onSelectMeasure' + event);
      }
    },
    async onChangeServ() {
      this.tarifs = await this.tariffToZones(this.record.тарифность, this.record['объект-услуга'], this.record.микрогенерация);
      if (this.isNewRecord) {
        await this.setNPP();
      }
    },
    tariffToMg(tar: number, tarifnost: number) {
      let tarmg = tar;

      switch (tarifnost) {
        case 1:
          if (tar === 1) {
            tarmg = 10;
          }
          return tarmg;
        case 2:
        {
          switch (tar) {
            case 2:
            {
              tarmg = 10;
              return tarmg;
            }
            case 3:
            {
              tarmg = 11;
              return tarmg;
            }
            default:
              return tarmg;
          }
        }
        case 3:
        {
          switch (tar) {
            case 3:
            {
              tarmg = 10;
              return tarmg;
            }
            case 4:
            {
              tarmg = 11;
              return tarmg;
            }
            case 5:
            {
              tarmg = 12;
              return tarmg;
            }
            default:
              return tarmg;
          }
        }
        default:
          return tarmg;
      }
    },

    async onCloseCounter() {
      this.isPressedButton = true;
      this.tarifs = await this.tariffToZones(this.record.тарифность, this.record['объект-услуга'], this.record.микрогенерация);
      this.closemode = true;
      this.repairmode = false;
      this.record.даткнц = this.$store.getters.getCurrentDate() as string;
      // устанавливаем дату конца следующим днем после даты последнего показания
      if (this.lastReadingsDate && Date.parse(this.record.даткнц.toString()) <= Date.parse(this.lastReadingsDate)) {
        const date = this.$stackDate.addDays(this.lastReadingsDate, 1);
        this.record.даткнц = date;
      }

      let newtar;
      // устанавливаем конечные показания
      for (const tar in this.readings) {
        newtar = tar;
        if (this.record.микрогенерация) {
          newtar = this.tariffToMg(Number(tar), this.record.тарифность);
        }
        if (this.lastReadings[newtar]) {
          this.readings[tar].показание = this.lastReadings[newtar];
        }
      }
    },

    async onRestoreCounter() {
      let answer = true;
      this.repairmode = false;
      if (this.record.идентификатор === 0 && this.isClosedCounter && !this.withoutLastReadings) {
        answer = await this.$yesno('При восстановлении счетчика, все конечные показания будут удалены. Вы уверены ?');
      }
      if (answer) {
        try {
          this.restoremode = true;
          await this.dataObject.executeMethod('Восстановление', { номерЗаписи: this.record.$номерЗаписи });
          // сбросим флаг, что счетчик закрыт
          this.isClosedCounter = false;
        } finally {
          this.restoremode = false;
          this.$sendMsg('stack-table', 'Счетчики.Показания', 'reload');
          this.$sendMsg('stack-table', 'Объекты.Состояния', 'reload');
          // @ts-ignore
          if (this.$refs.dialog) {
            // @ts-ignore
            this.$refs.dialog.readRecord();
          }
        }
      }
    },

    // при изменении показаний пересчитываем расходы
    async onChangeReading(tar: number) {
      let newtar;
      newtar = tar;
      if (this.record.микрогенерация) {
        newtar = this.tariffToMg(Number(tar), this.record.тарифность);
      }
      if (this.readings[tar] && this.lastReadings[newtar] !== undefined) {
        const reading = this.readings[tar].показание;
        const lastreading = this.lastReadings[newtar] ? this.lastReadings[newtar] : 0;
        this.lastReadingsIsChange[tar] = true;
        let volume = this.repairmode ? 0 : +(reading - lastreading).toFixed(3);

        await this.fetchDopData();
        const msgRules = this.$stackRules.rulesRash({ расход: volume, ограничениерасхода: this.record.ограничениерасхода });
        if (typeof msgRules === 'string') {
          this.$toast(msgRules, { color: 'error' });
        }

        if (volume < 0 && +reading >= 0) {
          // @ts-ignore
          const pos = this.$refs.ref_pok[tar].$el.getBoundingClientRect();
          this.menux = pos.left + pos.width / 2;
          this.menuy = pos.top;
          // спросим пользователя что это он ввел
          this.askdata.visible = true;
          const timer = setInterval(() => {
            if (!this.askdata.visible) {
              clearInterval(timer);
              switch (this.askdata.answer) {
                case 0: {
                  const max = this.record.разрядность ? Math.pow(10, Number(this.record.разрядность)) : Math.pow(10, 9);
                  volume = max - lastreading + reading;
                  break;
                }
                case 2:
                  this.readings[tar].показание = lastreading;
                  volume = 0;
                  break;
                default: {
                  // Пока ждём, показание может измениться
                  const reading = this.readings[tar].показание;
                  volume = +(reading - lastreading).toFixed(3);
                  break;
                }
              }
              this.readings[tar].расход = volume;
              this.onChangeVolume(tar);
            }
          }, 500);
        } else {
          this.readings[tar].расход = volume;
          this.onChangeVolume(tar);
          this.askdata.visible = false;
        }
      }
    },

    onChangeVolume(tar: number) {
      if (this.readings[tar]) {
        let koef = 1;
        if (this.record['коэффициент трансформации']) {
          koef = Number(this.record['коэффициент трансформации']);
        }
        this.readings[tar].итоговыйрасход = this.readings[tar].расход * koef + this.readings[tar].допрасход;
        if (this.record['разрядность']) {
          const r = Math.pow(10, this.record['разрядность']);
          this.readings[tar].итоговыйрасход = Math.trunc(this.readings[tar].итоговыйрасход * r) / r;
        }
      }
    },

    onInitReadings(payload: any) {
      this.lastReadings = [];
      this.lastReadingsDate = null;
      this.lastReadingsMonth = null;
      // для многотарифного счетчика при переборе сразу может оказаться последний тариф
      // поэтому количество показаний посчитаем руками
      let lenLastReadings = 0;
      let tariffmg = Math.max(this.record.тарифность, 1);
      if (this.record.микрогенерация) {
        tariffmg = tariffmg * 2;
      }

      // как на бэкенде перебираем показания, сверху лежат самые поздние.
      if (payload) {
        for (const reading of payload) {
          if (this.record.тарифность && (reading.тариф < this.record.тарифность || this.record.микрогенерация)) {
            if (!this.lastReadings[reading.тариф]) {
              this.lastReadings[reading.тариф] = reading.показание;
              lenLastReadings++;
            }
            if (!this.lastReadingsDate) {
              this.lastReadingsDate = reading.дата;
              if (Date.parse(this.readings.дата.toString()) <= Date.parse(reading.дата)) {
                const dateBuf = this.$stackDate.addDays(reading.дата, 1);
                this.readings.дата = dateBuf;
              }
            }
            if (!this.lastReadingsMonth) {
              this.lastReadingsMonth = reading['расчетный месяц'];
            }
            // определяем по типу ввода или дате что счетчик уже закрыт
            if (reading.типввода === 5) {
              this.isClosedCounter = true;
            }
          }
          if ((lenLastReadings === this.record.тарифность && this.record.микрогенерация === 0) || (lenLastReadings === tariffmg && this.record.микрогенерация)) {
            break;
          }
        }
      }
    },

    // onChangeDialog() {
    //   this.tarifs = this.tariffToZones(this.record.тарифность);
    // },

    onChangePoverkaDate() {
      this.changeNextPoverkaDate('onChangePoverkaDate');
    },

    async onChangeInstallDate() {
      await this.setNPP();
      this.changeNextPoverkaDate('onChangeInstallDate');
    },

    changeNextPoverkaDate(event: string) {
      let changeDate = false;
      const poverkaDate = (this.record.датаповерки || this.record.датнач || '') as string;
      const nextpoverkaDate = (this.record.датаследующейповерки || '') as string;
      const poverkaInterval = (this.measuretype['@интервал'] || 0) as number;

      switch (event) {
        case 'onChangePoverkaDate':
          if (this.record.датаповерки === null) {
            this.record.датаповерки = poverkaDate;
          }
          changeDate = true;
          break;
        case 'onChangeInstallDate':
          if (this.isNewRecord && !this.record.$копировать) {
            this.record.датаповерки = this.record.датнач;
            this.onChangePoverkaDate();
          }
          break;
        case 'onSelectMeasureInit':
          if (!this.isNewRecord && !this.record.$копировать && nextpoverkaDate === '') {
            changeDate = true;
          }
          break;
        case 'onSelectMeasureUpdate':
          // если выбрали прибор учета с нулевым интервалом, дата следующей поверки совпадает с текущей
          if (poverkaInterval === 0) {
            this.record.датаследующейповерки = poverkaDate;
          } else {
            changeDate = true;
          }
          break;
      }

      if ((changeDate || nextpoverkaDate === '') && poverkaDate !== '') {
        this.record.датаследующейповерки = this.$stackDate.format(this.$stackDate.addYears(new Date(poverkaDate), +poverkaInterval), 'ISO');
      }
    },

    async setNPP() {
      if (this.dataObject && this.dataObject.executeMethod) {
        const result = await this.dataObject.executeMethod('ПолучитьНПП', { запись: this.record });
        // @ts-ignore
        Vue.set(this.record, 'n п/п', +result);
      }
    },
    async fetchDopData() {
      const res = await this.dataObject.executeMethod('ПолучитьДопДанные', this.record);
      if (res) {
        this.record.ограничениерасхода = +res;
      }
    },

    // async onSaveDialog(payload: any) {
    //   // @ts-ignore
    //   if (this.$refs.readingstab && this.$refs.readingstab.fetchData) {
    //     // @ts-ignore
    //     this.$refs.readingstab.fetchData();
    //   }
    //   this.closemode = false;
    //   this.readings = {
    //     0: { показание: 0, расход: 0, допрасход: 0, итоговыйрасход: 0 },
    //     1: { показание: 0, расход: 0, допрасход: 0, итоговыйрасход: 0 },
    //     2: { показание: 0, расход: 0, допрасход: 0, итоговыйрасход: 0 },
    //   };
    // },
    async onRepairCounter() {
      this.isPressedButton = true;
      this.tarifs = await this.tariffToZones(this.record.тарифность, this.record['объект-услуга'], this.record.микрогенерация);
      this.repairmode = true;
      this.closemode = false;

      // устанавливаем показания восстановления учета
      for (const tar in this.readings) {
        if (this.lastReadings[tar]) {
          this.readings[tar].показание = this.lastReadings[tar];
        }
      }
    },
    onClose() {
      if (this.closemode || this.repairmode) {
        this.$sendMsg('stack-table', 'ЛицевыеСчета.ПоказанияСчетчиковИндивидуальные', 'reload');
        this.$sendMsg('stack-table', 'ГрупповыеСчетчики.Показания', 'reload');
      }
      this.$emit('close');
    },
    // Изменилось состояние счетчика - надо проверить на двукратный недопуск
    async onUpdateStatusCounter() {
      this.checkDouble();
    },
    async checkDouble() {
      const result = await new this.$HttpModel('ЛицевыеСчета.Объекты').executeMethod('ПроверитьДвойнойНедопуск', { номерЗаписи: this.record.$номерЗаписи });
      this.isDoubled = +result === 1;
    },
    checkClosedCounter() {
      this.isClosedCounter = this.record.даткнц !== null && new Date(this.record.даткнц).getFullYear() !== 2045 && !this.isNewRecord;
    },
  },
});
