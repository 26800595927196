
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      selectedHouse: {} as any,
    };
  },
  computed: {
    error(): boolean {
      return this.selectedHouse == null;
    },
  },
  props: {
    houses: { type: Array, required: true },
  },
});
