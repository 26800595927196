
import Vue from 'vue';
import onlysumm from './$master/onlysumm.vue';
import saldoFilter from './$master/saldoFilter.vue';

export default Vue.extend({
  name: 'Corrsaldomaster',
  components: { onlysumm, saldoFilter },
  data() {
    return {
      dataModel: new this.$HttpModel('СозданиеКоррекцииСальдо'),
      statusGenerate: '',
      loading: false,
      readyToGo: false,
      jobID: null,
      lsError: false,
      состояния: [
        { $номерЗаписи: 0, значение: 'По всем' },
        { $номерЗаписи: 1, значение: 'С состоянием ИСПОЛЬЗУЕТСЯ' },
        { $номерЗаписи: 2, значение: 'С состоянием ВРЕМЕННО НЕ ИСПОЛЬЗУЕТСЯ' },
        { $номерЗаписи: 3, значение: 'С состоянием НЕ ИСПОЛЬЗУЕТСЯ' },
      ],
      newStatements: {
        номер: 0,
        дата: this.$store.getters.getCurrentDate(),
        месяц: this.$store.getters.getOpenMonth(),
        примечание: '',
        основание: '',
        причина: -1,
      },
      preSettings: {
        типкоррекции: 0,
        типсписания: 0,
        фонд: null,
        состояние: 0,
        за: this.$store.getters.getCloseMonth(),
        тип: 0,
        услуга: -1,
        укдоговор: -1,
        поставщик: -1,
        на_за: this.$store.getters.getWorkMonth(),
        на_услуга: -1,
        на_укдоговор: -1,
        на_поставщик: -1,
        лицевой: -1,
        задолженность: 1,
        переплата: 1,
      },
    };
  },
  computed: {
    monthFrom(): any {
      return this.$store.getters.getCloseMonth();
    },
    toDateLabel(): string {
      let label = 'На месяц';
      if (this.preSettings.типкоррекции === 2 && this.preSettings.типсписания) {
        label = 'По';
      }
      return label;
    },
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
    fundError(): boolean {
      const result = !this.preSettings.фонд || this.lsError;
      if (this.preSettings.типкоррекции === 2 && this.preSettings.типсписания === 0) {
        return result || (!this.preSettings.задолженность && !this.preSettings.переплата);
      }
      return result;
    },
    isRequired(): boolean {
      return this.preSettings.типкоррекции === 2 && this.preSettings.типсписания === 1;
    },
    disabled(): boolean {
      return false;
    },
  },
  methods: {
    async onChange(лицевые: string | null) {
      this.lsError = false;
      if (лицевые) {
        const result = await this.dataModel.executeMethod('ПолучитьЗаблокированныйМесяц', { лицевые });
        if (result && result >= this.newStatements.месяц) {
          this.$toast('Расчетный месяц для части ЛС уже закртыт. Работа мастера невозможна', { color: 'error' });
          this.lsError = true;
        }
      }
    },
    async setFreeNumber() {
      const newNumber = await this.dataModel.executeMethod('СвободныйНомер');
      this.$set(this.newStatements, 'номер', newNumber);
    },
    async insertCorrection() {
      const parameters = Object.assign({}, this.preSettings, this.newStatements);
      this.jobID = (
        await this.dataModel.executeMethod('Занести', parameters, {
          async: true,
          jobName: 'Создание коррекции сальдо. Ведомость ' + this.newStatements.номер,
          type: 'file',
        })
      ).asyncId;
    },
    async showCorrection() {
      this.readyToGo = false;
      const parameters = Object.assign({}, this.preSettings, this.newStatements);
      await this.dataModel.executeMethod('Заполнить', parameters);
      this.readyToGo = true;
    },
    labelFieldUKHandler(item: StackTableRow) {
      return '№' + item.номер + ' ' + item.тема + ' ' + item['организация-укдоговор>название'] + ' от ' + this.$stackDate.format(item.датнач as string, 'DD.MM.YYYY');
    },
  },
  mounted() {
    // @ts-ignore
    this.$refs.form.validate();
  },
  async created() {
    await this.setFreeNumber();
  },
  watch: {
    preSettings: {
      deep: true,
      async handler(to, from) {
        // форма отрисовывается не сразу, добавлено, чтобы проверка проходила.
        this.$nextTick(() => {
          // @ts-ignore
          this.$refs.form.validate();
        });
        this.readyToGo = false;
        this.jobID = null;
      },
    },
    'preSettings.типкоррекции': {
      async handler(to, from) {
        if (to === 3) {
          this.preSettings.за = this.$store.getters.getCloseMonth();
          this.preSettings.на_за = this.$store.getters.getCloseMonth();
        } else {
          this.preSettings.за = this.$store.getters.getCloseMonth();
          this.preSettings.на_за = this.$store.getters.getWorkMonth();
          if (to === 2) {
            this.preSettings.за = this.$stackDate.format(new Date(1980, 0, 1), 'ISO');
            this.preSettings.на_за = this.$store.getters.getCloseMonth();
          }
        }
        if (to !== from && this.preSettings.типкоррекции !== 0) {
          this.preSettings.услуга = -1;
          this.preSettings.укдоговор = -1;
          this.preSettings.поставщик = -1;
          this.preSettings.на_услуга = -1;
          this.preSettings.на_укдоговор = -1;
          this.preSettings.на_поставщик = -1;
        }
      },
    },
    async isComplete(to) {
      if (to) {
        this.jobID = null;
        this.statusGenerate = 'success';
        await this.setFreeNumber();
        this.readyToGo = false;
      }
    },
    isError(to) {
      if (to) {
        this.jobID = null;
        this.statusGenerate = 'error';
      }
    },
  },
});
