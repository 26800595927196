
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      item: {
        название: null,
        бик: null,
      } as any,
    };
  },
});
