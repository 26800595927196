
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      disItems: [false, false, false],
    };
  },
  methods: {
    checkDisabled(value: number) {
      this.disItems[2] = (value & 1) === 0;
      if ((value & 1) === 0) {
        this.record.значение = this.record.значение & 3;
      }
    },
  },
});
