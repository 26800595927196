import { stringTranslit } from './stringTranslit';

/** генерит "массив" компонентов из исходников полученных от webpack`овской require.context
 * Названия переводит в транслит
 * @tutorial
 * const foo = require.context('path\to\some\folder', false, /\.vue$/);
 * const someComponents = getComponents(foo);
 * export default Vue.extend({
 *    components: Object.assign(someComponents),
 * ....
 */

export function getComponents(componentsContext: any) {
  const comps = {} as any;
  componentsContext.keys().forEach(async (compID: string) => {
    const compDef = componentsContext(compID).default;
    const compName = compID.match(/[\wa-яё]+/gi);
    if (compName && compName[0] && compDef) {
      comps[stringTranslit(compName[0]).toUpperCase()] = compDef;
    }
  });
  return comps;
}
export function getDynamicModalName(row: StackTableRow): { file: string; title: string } {
  if (row && row.тип !== undefined && row.тип !== null) {
    switch (+row.тип) {
      case 0:
        return { file: 'RAYON', title: 'Район' };
      case 1:
        return { file: 'UCHASTOK', title: 'Участок' };
      case 10:
        return { file: 'PLSTRUKT', title: 'Планировочная структура' };
      case 11:
        return { file: 'GOROD', title: 'Город' };
      case 12:
        return { file: 'NASPUNKT', title: 'Населенный пункт' };
      case 13:
        return { file: 'MUNRAYON', title: 'Муниципальный район' };
      case 2:
        return { file: 'ULICA', title: 'Улица' };
      case 3:
        return { file: 'DOM', title: 'Дом' };
      case 4:
        return { file: 'KVARTIRA', title: 'Квартира' };
    }
  }
  return { file: 'UnknownAdrDialog', title: 'Неизвестный' };
}
