
import Vue from 'vue';

export default Vue.extend({
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    dataModel(): StackTableRow[] {
      return JSON.parse(this.record.значение);
    },
    arrayModel(): StackTableRow[] {
      return this.dataModel.map((value: any) => {
        return value.комбо.split('\n');
      });
    },
    items1(): StackTableRow {
      return this.arrayModel[0];
    },
    items2(): StackTableRow {
      return this.arrayModel[1];
    },
    названиеЛ(): string {
      return this.dataModel[0].название as string;
    },
    названиеУ(): string {
      return this.dataModel[1].название as string;
    },
  },
  data() {
    return {
      value1: 0,
      value2: 0,
    };
  },
  created() {
    const dataTemp = this.dataModel;
    this.value1 = dataTemp[0] && dataTemp[0].значение ? +dataTemp[0].значение : 0;
    this.value2 = dataTemp[1] && dataTemp[1].значение ? +dataTemp[1].значение : 0;
  },
  methods: {
    changeCombo(value: number, type: number) {
      const dataTemp = this.dataModel;
      dataTemp[type].значение = value;
      const str = JSON.stringify(dataTemp);
      this.$set(this.record, 'значение', str);
    },
  },
});
