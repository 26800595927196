
import Vue from 'vue';
import payments from '@/tasks/fl/views/payments/paylists/_paylistsid/payments/_paymentsid.vue';

export default Vue.extend({
  components: { payments },
  props: {
    paymentsid: { type: [Number, String], default: undefined },
    paylistsid: { type: [Number, String], default: undefined },
    paymentsreceiptsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
  },
});
