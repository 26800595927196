// по алфавиту
export default {
  enums: [
    'АктыДолжников.Тип',
    'АктыНарушенийАбонентов.Тип',
    'ВидыСниженияОплаты.Тип',
    'ВариантПоквартирнойКарточки',
    'ВедомостиРазовыхНачислений.ВидРасчета',
    'ВедомостиРазовыхНачислений.Флаги',
    'ВедомостиРазовыхНачислений.ФлагиАдресов',
    'ВедомостиРазовыхНачислений.ФлагиАдресов_Вид',
    'ВедомостиРазовыхНачислений.ФлагиАдресов_ИПУ',
    'ВедомостиСтороннихПоказаний.ФлагиФильтра',
    'ГрупповыеСчетчики.МесяцДеленияОтчет',
    'ГрупповыеСчетчики.Тип',
    'ДетализацияФильтра.Тип',
    'ДоговорыСПоставщиками.ВидДоговора',
    'Докрутка_ТипДокрутки',
    'ДокументНаСобственность',
    'ОснованияВладения.Название',
    'Документы.Флаги',
    'ДокументыВладения.ДокументыПодтверждения',
    'ДокументыВладения.Основания',
    'ДокументыВладения.Совместная',
    'ЕдиницыИзмеренияСОИ',
    'ЗаявкиАбонентов.Состояние',
    'КарточкиРегистрации.ВидыРегистрации',
    'КарточкиРегистрации.НаправлениеВыплаты',
    'КарточкиРегистрации.ОснованияСнятия',
    'КарточкиРегистрации.Пол',
    'КарточкиРегистрации.РасчетныеПризнаки',
    'КарточкиРегистрации.Флаги',
    'Льготы.ИсточникФинансирования',
    'Льготы.НаКого',
    'Льготы.СпособВозмещения',
    'Льготы.Условие',
    'Начисления_ПараметрыОтчета.Переключатель',
    'Параметры Расчета.Тип',
    'ПараметрыШаблонаЦентраИнформирования',
    'Пени_ПараметрыОтчета.Переключатель',
    'Пени_ПараметрыОтчета.Флаги',
    'ПериодичностьВнесенияПлатыЗаОтопление',
    'Платежи_ПараметрыОтчета.Переключатель',
    'Платежи_ПараметрыОтчета.Флаги',
    'Правила операций.Признак',
    'ПрочееБаланс_ПараметрыОтчета.Переключатель',
    'ПрочееДолжники_ПараметрыОтчета.Переключатель',
    'СальдоНачисления_ПараметрыОтчета.Переключатель',
    'СальдоНачисления_ПараметрыОтчета.Флаги',
    'СальдоНачисления_ПараметрыОтчета.фФильтрПоВидамДоговора',
    'СлужебныеОтчеты_Сравнение_ВидНачислений',
    'СлужебныеОтчеты_Сравнение_ТипНачислений',
    'СообщенияДляКвитанций.ТипАдресата',
    'СообщенияДляКвитанций.ТипАдресатаБезОрг',
    'СообщенияДляКвитанций.ТипАдресатаОрганизация',
    'СпособыОтображенияДокументаОтчет',
    'СхемаРасчетаОтопление',
    'УслугиПоСчетчику_Значения',
    'УслугиПоСчетчику_Ключи',
    'ЦентрИнформированияШаблоны.Транспорт',
    'РеестрМногопроцессныхОпераций.Состояние',
    'НастройкаРолиОрганизации.Роли',
    'НастройкаРолиОрганизации.Участок',
    'ВариантЭкспорта',
    'ВариантВыполненияПроверок',
    'ЭкспортИнтеграции',
    'ВедомостиПоказанийИПУ.Тип',
    'ВедомостиПоказанийИПУ.ТипВФильтр',
    'ФлагиВыгрузкиВИнтеграцию',
    'ОСЗН_ОпределитьРегион',
    'ТипыСоответствияУслуг',
    'ЖурналПодкаченныхРеестровПлатежей.Пользователи',
    'ТипыПоказанийСчетчика',
    'РаспределениеОплаты_ПараметрыОтчета_Флаги',
    'РаспределениеОплаты_ПараметрыОтчета_Переключатель',
    'ДелениеЛицевого.ДелитьСальдо',
    'ТипАктаЗамены',
    'ПричиныСнятияСчетчика',
    'ПочтовыйКлиент.Ящики.Шифрование',
    'ПочтовыйКлиент.Ящики.Отправка',
    'ПочтовыйКлиент.Ящики.ИсхАутентификация',
    'ПочтовыйКлиент.Ящики.ВхАутентификация',
    'ПочтовыйКлиент.Ящики.Типы',
    'МакросыШаблонаПочты',
    'ЦентрИнформирования.Пользователи',
    'ЦентрИнформирования.Канал',
    'ЦентрИнформирования.Состояние',
    'ВыгрузитьПлатежиВИнтеграцию',
    'КомпенсацияПотерьРСК',
    'ТипДоговораПотериСК',
    'ВыставлениеТолькоПотерь',
    'НеНачислятьПотериТСО',
    'РаспределениеПотерьПоСубабонентам',
    'ВыставлениеБезПотерь',
    'ДоговорСФСК',
    'РассчитанныйПрофиль',
    'НомерГрафикаВнешнегоОсвещения',
    'НомерГрафикаВнутреннегоОсвещения',
    'ВидКорректировки',
    'КолвоТарифныхЗонПоПрофилю',
    'МассовыйРасчетПрофиляПочасовика',
    'СистемаГВССтояк',
    'СистемаГВССети',
    'ТипРасчета',
    'ВидКотельной',
    'ВидКонтейнера',
    'СоглашенияОРассрочке.Тип',
    'СоглашенияОРассрочке.ТипПроцента',
    'СоглашенияОРассрочке.ВидПроцента',
    'ВедомостиРазовыхНачислений.ТипСуммы',
    'МассовыйВвод.УровниПростановки',
    'МассовыйВвод.ТипПростановки',
    'ЛицевыеСчета.СписокРаспределителей.Состояния.Список',
    'КомпенсациюНеНачислять',
  ],
};
