const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.Пени_перерасчет',
  description: 'Пени перерасчет',
  fields: [
    {
      field: 'номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'поставщикназвание',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'число дней',
      text: 'Число дней',
      type: 'Number',
    },
    {
      field: 'ставка',
      text: 'Ставка',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: 'Сумма,₽',
      type: 'Money',
    },
    {
      field: 'суммаплатежа',
      text: 'Сумма долга,₽',
      type: 'Money',
    },
    {
      field: 'перерасчет',
      text: 'Перерасчет',
      type: 'DateMonth',
    },
    {
      field: 'делитель',
      text: 'Делитель',
      type: 'String',
    },
    {
      field: 'договорук',
      text: 'ДоговорУК',
      type: 'Number',
    },
    {
      field: 'поставщик',
      text: 'Поставщик',
      type: 'Number',
    },
    {
      field: 'месдолга',
      text: 'За',
      type: 'DateMonth',
    },
    {
      field: 'датаплатежа',
      text: 'Дата платежа',
      type: 'Date',
    },
    {
      field: 'отсчет от',
      text: 'Отсчет от',
      type: 'Date',
    },
    {
      field: 'договорномер',
      text: 'Договор',
      type: 'String',
    },
    {
      field: 'укназвание',
      text: 'Исполнитель',
      type: 'String',
    },
    {
      field: 'услуга',
      text: 'Услуга',
      type: 'Number',
    },
  ],
};
export default accs;
