
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      tableGV: [] as StackTableRow[],
      tableOT: [] as StackTableRow[],
      comboValue: 0,
      dateGS: null as string | null,
      headersGV: [
        {
          field: '@ТА00',
          text: 'Трубопровод',
          type: 'String',
          readonly: true,
        },
        {
          field: '@ТА01',
          text: 'Отапливаемый период',
          type: 'Number',
        },
        {
          field: '@ТА02',
          text: 'Неотапливаемый период',
          type: 'Number',
        },
      ] as StackField[],
      headersOT: [
        {
          field: '@ТА00',
          text: 'Трубопровод',
          type: 'String',
          readonly: true,
        },
        {
          field: '@ТА01',
          text: 'Янв',
          type: 'Number',
        },
        {
          field: '@ТА02',
          text: 'Фев',
          type: 'Number',
        },
        {
          field: '@ТА03',
          text: 'Март',
          type: 'Number',
        },
        {
          field: '@ТА04',
          text: 'Апр',
          type: 'Number',
        },
        {
          field: '@ТА05',
          text: 'Май',
          type: 'Number',
        },
        {
          field: '@ТА06',
          text: 'Июнь',
          type: 'Number',
        },
        {
          field: '@ТА07',
          text: 'Июль',
          type: 'Number',
        },
        {
          field: '@ТА08',
          text: 'Авг',
          type: 'Number',
        },
        {
          field: '@ТА09',
          text: 'Сент',
          type: 'Number',
        },
        {
          field: '@ТА10',
          text: 'Окт',
          type: 'Number',
        },
        {
          field: '@ТА11',
          text: 'Ноя',
          type: 'Number',
        },
        {
          field: '@ТА12',
          text: 'Дек',
          type: 'Number',
        },
        {
          field: '@ТА13',
          text: 'Коэф',
          type: 'Number',
        },
      ] as StackField[],
    };
  },
  methods: {
    changeRecord(newValue: string | StackTableRow[], name: string) {
      const dataObject = this.record && this.record.значение ? JSON.parse(this.record.значение) : [];
      if (dataObject[this.comboValue] !== undefined) {
        dataObject[this.comboValue][name] = newValue;
        this.$set(this.record, 'значение', JSON.stringify(dataObject));
      }
    },
    changeTable(items: StackTableRow[], name: string) {
      // облегчаем объект (убираем лишние данные вроде $data и тп)
      const arrayTemp = [];
      for (const item of items) {
        let objTemp = {};
        for (const ind in item) {
          if (ind.indexOf('$') < 0) {
            objTemp = Object.assign({}, objTemp, { [ind]: item[ind] });
          }
        }
        arrayTemp.push(objTemp);
      }
      if (arrayTemp.length > 0) {
        this.changeRecord(arrayTemp, name);
      }
    },
    getData(item: StackTableRow) {
      this.tableGV = [];
      this.tableOT = [];
      this.dateGS = '';
      // наберем данные, в зависимости от значения combobox
      const dataObject = this.record && this.record.значение ? JSON.parse(this.record.значение) : [];
      for (const ind in dataObject) {
        if (+ind === item.$номерЗаписи) {
          this.dateGS = dataObject[ind].Дата;
          this.tableGV = dataObject[ind].ТАГВ ? [].concat(dataObject[ind].ТАГВ) : [];
          this.tableOT = dataObject[ind].ТАОТ ? [].concat(dataObject[ind].ТАОТ) : [];
          break;
        }
      }
    },
  },
});
