const kvit: StackSelection = {
  objectName: 'МакетыКвитанций',
  recordRoute: 'settings-kvitmodels-kvitmodelsid',
  description: 'МакетыКвитанций',
  hier: true,
  prepareRow: row => {
    if (row.признакмакета === 0) {
      if (!row.$класс) {
        row.$класс = '';
      }
      row.$класс += `grey--text`;
    }
    return row;
  },
  fields: [
    {
      field: 'код',
      text: 'Код',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'шаблон',
      text: 'Файл шаблона',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'макеты-получатель',
      text: 'макеты-получатель',
      type: 'Link',
    },
    {
      field: 'макеты-получатель>название',
      text: 'Получатель платежа',
      type: 'String',
    },
    {
      field: '@признакмакета',
      text: 'Действие',
      type: 'String',
    },
    {
      field: 'признакмакета',
      text: 'Признак число',
      type: 'Number',
    },
    {
      field: 'детализация',
      text: 'Детализация',
      type: 'Number',
    },
    {
      field: 'детализацийполучаетелей',
      text: 'Детализация получателей',
      type: 'Number',
    },
    {
      field: '@заменитьполучателя',
      text: 'Заменить получателя',
      type: 'String',
    },
    {
      field: 'невыгружатьвгис',
      text: 'НеВыгружатьВГИС',
      type: 'Number',
    },
  ],
};
export default kvit;
