
import Vue from 'vue';
import { FundModel } from './FundModel';

type HookReleaseFunction = () => void;

export default Vue.extend({
  props: {
    fund: { type: Number, default: null },
  },
  data() {
    return {
      routeHook: null as null | HookReleaseFunction, // хук на смену роута
      data: [] as StackTableRow[],
      rec: [] as object[],
      fundModel: {} as DataModel,
      jobID: null as null | string,
      lenTable: 0 as number,
      prepareItem: {
        open: this.$store.getters.getOpenMonth(),
        calc: this.$store.getters.getOpenMonth(),
      },
      header: [
        {
          field: 'подъезд',
          text: 'Подъезд',
          type: 'Number',
          align: 'center',
          dataModel: '',
          width: '50px',
          itemValue: '',
        },
        {
          field: 'этаж',
          text: 'Этаж',
          type: 'Number',
          align: 'center',
          width: '50px',
        },
        {
          field: 'номеркв',
          text: 'Номер\n кв.',
          type: 'Number',
          align: 'center',
          width: '50px',
        },
        {
          field: 'литеракв',
          text: 'Литера',
          type: 'String',
          align: 'center',
          width: '50px',
        },
        {
          field: 'номерлс',
          text: 'Номер\n лицевого',
          type: 'Number',
          align: 'center',
          width: '250px',
        },
        {
          field: 'фио',
          text: 'ФИО',
          type: 'String',
          align: 'center',
          width: '500px',
        },
        {
          field: 'ДокументВладение',
          text: 'Документ\n на собственность',
          type: 'Link',
          dataModel: 'ОснованияВладения',
          labelField: 'название',
          headers: 'название,@стрчс',
          hier: true,
        },
        {
          field: 'номердокумента',
          text: 'Номер\n документа',
          type: 'String',
          align: 'center',
          width: '50px',
        },
      ],
    };
  },
  computed: {
    headers(): StackField[] {
      const headers = this.header;
      this.rec.forEach((element: any) => {
        switch (element.parametr) {
          case 'документ':
            break;
          case 'номердокумента':
            break;
          case 'комнаты':
            headers.push({
              field: 'пар_комнаты',
              text: 'Комнат',
              type: 'Number',
              align: 'center',
              width: '50px',
            });
            break;
          case 'общплощадь':
            headers.push({
              field: 'пар_общплощадь',
              text: 'Общая\n площадь',
              type: 'Number',
              align: 'center',
              width: '50px',
            });
            break;
          case 'проп':
            headers.push({
              field: 'пар_проп',
              text: 'Прописано',
              type: 'Number',
              align: 'center',
              width: '50px',
            });
            break;
          case 'категория':
            headers.push({
              field: 'пар_категория',
              text: 'Категория квартиры',
              type: 'Enum',
              dataModel: 'КатегорияКвартиры',
              align: 'center',
              width: '300px',
              itemValue: '',
            });
            break;
          default:
            headers.push({
              field: 'пар_' + element.parametr,
              text: element.name,
              type: 'String',
              align: 'center',
              width: '50px',
            });
            break;
        }
      });
      return headers as StackField[];
    },
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
    isUnsavedRecords(): boolean {
      let flag = false;
      if (this.data.length === 0) {
        flag = true;
      } else {
        this.data.forEach((item: StackTableRow) => {
          if (item.$номерЗаписи && item.$номерЗаписи.toString() === 'new') {
            flag = true;
          }
        });
      }
      return flag;
    },
  },
  async created() {
    this.routeHook = this.$router.beforeEach(this.checkChangedOnLeave) as HookReleaseFunction;

    const data = await new this.$HttpModel('БыстрыйВводФонда').getRecords();
    data.forEach((value) => {
      if (value.значение) {
        let parametr = '';
        let parametrName = '';
        let parametrDefault = '';

        const tempRec = value.значение.toString().split(',');
        tempRec.forEach((tempValue) => {
          const tempRec = tempValue.toString().split('/');
          if (tempRec[0]) {
            parametr = tempRec[0];
            parametrName = tempRec[1] ? tempRec[1] : parametr;
            parametrDefault = tempRec[2] ? tempRec[2] : '';
            this.rec.push({ parametr, name: parametrName, default: parametrDefault });
          }
        });
      }
    });
    const checkParamDocument = await new this.$HttpModel('БыстрыйВводФонда').executeMethod('ПроверитьОбязательностьПоляДокумент');
    // @ts-ignore TODO
    this.fundModel = new FundModel([], {
      rowTemplate: this.headers,
      description: '',
      checkRow: (row) => {
        if (!row.номеркв) {
          return 'Указан некорректный номер квартиры!';
        }
        // Проверяем только при наличии параметрам, параметр передается в this.rec
        if (
          this.rec.some((item: any) => {
            return item.parametr && item.parametr === 'общплощадь';
          }) &&
          typeof row.пар_общплощадь === 'number' &&
          +row.пар_общплощадь <= 0
        ) {
          return 'Общая площадь должна быть больше 0!';
        }
        if (
          this.rec.some((item: any) => {
            return item.parametr && item.parametr === 'жиллощадь';
          }) &&
          typeof row.пар_жилплощадь === 'number' &&
          +row.пар_жилплощадь <= 0
        ) {
          return 'Жилая площадь должна быть больше 0!';
        }
        if (checkParamDocument && !row.ДокументВладение) {
          return 'Документ на собственность должен быть заполнен!';
        }
        return false;
      },
    });
  },
  beforeDestroy() {
    if (this.routeHook) {
      this.routeHook();
    }
  },
  methods: {
    async changeFund(payload: StackTableRow[]) {
      this.data = payload;
      if (this.lenTable > payload.length) {
        let maxFlat = 0;
        for (const key in payload) {
          maxFlat = Math.max(Number(payload[key].номеркв), maxFlat);
        }
        await new this.$HttpModel('БыстрыйВводФонда').executeMethod('снятьБлокировкиКвартир', { house: this.prepareItem, maxFlat, fund: this.fund });
      }
      this.lenTable = this.data.length;
    },
    async addFund() {
      const parameters = { metod: 'вставка', house: this.prepareItem, newls: this.data, fund: this.fund };
      this.jobID = (await new this.$HttpModel('БыстрыйВводФонда').executeMethod('Отправить', parameters, { async: true, jobName: 'Создание лицевых в доме' })).asyncId;
      this.$emit('run');
    },
    async checkChangedOnLeave(to: any, from: any, next: any) {
      let answer = true;
      if (!this.isUnsavedRecords) {
        answer = await this.$yesno('Несохраненные данные будут утеряны! Вы уверены, что хотите выйти?');
      }
      if (answer) {
        next();
      }
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.jobID = null;
        // @ts-ignore TODO
        this.fundModel = new FundModel([], { rowTemplate: this.headers, description: '' });
        this.data = [];
        this.$emit('end', 'success');
      }
    },
    isError(to) {
      if (to) {
        this.jobID = null;
        this.$emit('end', 'error');
      }
    },
    fund(to) {
      // если изменилась ссылка на фонд - обнуляем данные
      if (this.data.length > 0) {
        this.fundModel = new FundModel([], { rowTemplate: this.headers, description: '' });
        this.data = [];
      }
    },
  },
});
