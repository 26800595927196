
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    tableAction: { type: Function, required: true }, // коллбек к таблице
  },
  data() {
    return {
      items: [] as any,
    };
  },
  async created() {
    const result = await new this.$HttpModel('ТипыНоменклатурТрасформатора').getRecords();
    this.items = [];
    result.forEach((element: any) => {
      if (+element.ключ !== 0) {
        this.items.push({ title: element.значение, type: element.ключ });
      }
    });
  },

  methods: {
    onBtnClick(type: number) {
      const payload = { идентификатор: type };
      this.tableAction({ action: 'add', payload });
    },
  },
});
