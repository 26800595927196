const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ОбщаяИнформация',
  description: 'Общая информация',
  fields: [
    {
      field: 'состояниестрока',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'проп',
      text: 'Прописанных',
      type: 'Number',
    },
    {
      field: 'прож',
      text: 'Проживающих',
      type: 'Number',
    },
    {
      field: 'времрег',
      text: 'Временно зарегистрированных',
      type: 'Number',
    },
    {
      field: 'времотс',
      text: 'Временно отсутствующих',
      type: 'Number',
    },
    {
      field: 'общая площадь',
      text: 'Общая площадь',
      type: 'Number',
    },
    {
      field: 'жилая площадь',
      text: 'Жилая площадь',
      type: 'Number',
    },
    {
      field: 'тип строения',
      text: 'Тип строения',
      type: 'String',
    },
    {
      field: 'документ на собственность',
      text: 'Документ на собственность',
      type: 'String',
    },
    {
      field: 'колво комнат',
      text: 'Колво комнат',
      type: 'Number',
    },
    {
      field: 'ук',
      text: 'Упр. кампания',
      type: 'String',
    },
    {
      field: 'льгота',
      text: 'Льготы',
      type: 'String',
    },
    {
      field: 'документыюрист',
      text: 'Дела',
      type: 'String',
    },
    {
      field: 'категория',
      text: 'Категория',
      type: 'Number',
    },
  ],
};
export default accs;
