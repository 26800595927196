const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ЗаявкиПоЛС',
  description: '',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'время',
      text: 'Время',
      type: 'DateTime',
    },
    {
      field: 'срок',
      text: 'Срок',
      type: 'Date',
    },
    {
      field: 'датазавершения',
      text: 'ДатаЗавершения',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'фио',
      text: 'Исполнитель',
      type: 'String',
    },
    {
      field: 'заявитель',
      text: 'Заявитель',
      type: 'String',
    },
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: 'состояниетекст',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'автор',
      text: 'Автор',
      type: 'String',
    },
    {
      field: '_автор',
      text: 'Автор',
      type: 'Number',
    },
    {
      field: 'дописполнители',
      text: 'ДопИсполнители',
      type: 'String',
    },
    {
      field: 'описание',
      text: 'Описание',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'заявка-тип',
      text: 'Заявка-Тип',
      type: 'Link',
    },
    {
      field: 'заявка-сотрудник',
      text: 'Заявка-Сотрудник',
      type: 'Link',
    },
  ],
};
export default accs;
