
import Vue from 'vue';
import { ReportsBuilder } from '@/stackEngine';
import ItemDialog from './$modal/ItemDialog.vue';

interface InfoItem {
  title: string;
  value: any;
}

export default Vue.extend({
  components: { ItemDialog },
  props: {
    deductionsid: { type: [Number, String], default: undefined },
    parentID: { type: [Number, String], default: undefined },
    ownerID: { type: [Number, String], default: null },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      record: {
        кол_во: 0,
      } as StackTableRow,
      isChange: false as boolean,
    };
  },
  computed: {
    boxInfo(): InfoItem[] {
      if (this.$options.filters) {
        return [
          // TODO замапить фильтры
          { title: 'Всего удержаний', value: this.$options.filters.int(this.record.кол_во) },
          { title: 'Сумма ₽', value: this.$options.filters.money(this.record.сумма) },
          { title: 'Дата распределения', value: this.$options.filters.date(this.record['дата распределения']) },
        ];
      }
      return [];
    },
    title(): string {
      return `Ведомость удержаний № ${this.record.номер} от ${this.$stackDate.format(this.record.дата, 'DD.MM.YYYYг.')}`;
    },
    openMonth(): Date {
      return this.$store.getters.getOpenMonth();
    },
    notInReceipt(): boolean {
      return !this.ownerID || this.ownerID < 0;
    },
    deddata(): any {
      return {
        нзист: this.record['источник-платежи'],
        месяц: this.record['расчмесяц'],
        новая: this.isNewRecord,
        нзведомости: this.record['$номерЗаписи'],
        блокирована: this.record['@блокирована'],
      };
    },
    isNewRecord(): boolean {
      return this.deductionsid === 'new';
    },
    showFillBill(): boolean {
      return !this.readonly && +this.deddata.нзведомости > 0 && +this.deddata.блокирована !== 1;
    },
  },
  methods: {
    modifiedTable() {
      if (this.isChange) {
        this.$sendMsg('stack-dialog', 'ВедомостиУдержаний', 'reload');
        this.isChange = false;
      }
    },
    async fillBill() {
      let needClear = 0;
      if (this.record && this.record.кол_во && +this.record.кол_во > 0) {
        if (await this.$yesno(`В ведомости присутствуют записи.\n Удалить их?`)) {
          needClear = 1;
        }
      }
      await new this.$HttpModel('ВедомостиУдержаний').executeMethod(
        'заполнитьВедомость',
        {
          тип: this.record['тип'],
          очиститьведомость: needClear,
          нзведомости: this.record.$номерЗаписи,
          месяц: this.record.расчмесяц,
          источник: this.record['источник-платежи'],
        },
        {
          async: true,
          jobName: 'Заполнение ведомости ведомости № ' + this.record.номер,
          onComplete: () => {
            this.$sendMsg('stack-table', 'ВедомостиУдержаний.СписокУдержаний', 'reload');
            this.isChange = true;
          },
          onError: () => {
            this.$sendMsg('stack-table', 'ВедомостиУдержаний.СписокУдержаний', 'reload');
            this.isChange = true;
          },
        },
      );
    },
  },
});
