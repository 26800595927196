
import Vue from 'vue';
import writeOffStep1 from './$steps/step1.vue';
import writeOffStep2 from './$steps/step2.vue';
import writeOffStep3 from './$steps/step3.vue';
import writeOffStep4 from './$steps/step4.vue';
import writeOffStep5 from './$steps/step5.vue';

export default Vue.extend({
  components: { writeOffStep1, writeOffStep2, writeOffStep3, writeOffStep4, writeOffStep5 },
  computed: {
    // Параметры, которые летят во все запросы на бекэнд на многих шагах
    routeParams(): object {
      const fund = this.$route.query.fund || undefined;
      const month = this.$route.query.month ? `${this.$route.query.месяц}-01T00:00:00.000` : this.$store.getters.getOpenMonth();
      const optionWriteOff = this.$route.query.optionWriteOff || undefined;
      const countLs = this.$route.query.countLs || undefined;
      const sumWriteOff = this.$route.query.sumWriteOff || undefined;
      const docNumber = this.$route.query.docNumber || undefined;
      const acceptToCreate = this.$route.query.acceptToCreate ? JSON.parse(this.$route.query.acceptToCreate.toString()) : false;
      return { fund, month, optionWriteOff, countLs, sumWriteOff, docNumber, acceptToCreate };
    },
    // текущий шаг храним в роуте
    step: {
      get(): number {
        return this.$route.query.step ? +this.$route.query.step : 1;
      },
      set(newValue: number) {
        const query: any = { ...this.$route.query };
        query.step = newValue;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.$router.push({ query }).catch((e) => {});
      },
    },
  },
  methods: {
    onNext() {
      this.step++;
    },
  },
});
