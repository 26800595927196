const actsqu: StackSelection = {
  objectName: 'ВедомостиКоррекцииСальдо.Список',
  description: 'Коррекция сальдо',
  fields: [
    {
      field: 'за',
      text: 'За',
      type: 'DateMonth',
    },
    {
      field: 'тип',
      text: 'тип число',
      type: 'Number',
    },
    {
      field: 'апи_тип',
      text: 'Тип',
      type: 'String',
      computed: true,
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'услуга-коррекция',
      text: 'Услуга-Коррекция',
      type: 'Link',
    },
    {
      field: 'поставщик-коррекция',
      text: 'Поставщик-Коррекция',
      type: 'Link',
    },
    {
      field: 'укдоговор-коррекция',
      text: 'УКДоговор-Коррекция',
      type: 'Link',
    },
    {
      field: 'услуга-коррекция>название',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: 'услуга-коррекция>номер услуги',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: 'поставщик-коррекция>название',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'укдоговор-коррекция>организация-укдоговор>название',
      text: 'Договор',
      type: 'String',
    },
    {
      field: 'укдоговор-коррекция>номер',
      text: 'Договор',
      type: 'String',
    },
    {
      field: 'укдоговор-коррекция>датнач',
      text: 'Договор',
      type: 'Date',
    },
    {
      field: 'handlerServ',
      text: 'Услуга',
      type: 'String',
      computed: true,
    },
    {
      field: 'handlerPost',
      text: 'Поставщик',
      type: 'String',
      computed: true,
    },
    {
      field: 'handlerDog',
      text: 'Договор',
      type: 'String',
      computed: true,
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
  ],
};
export default actsqu;
