
import Vue, { PropType } from 'vue';

import Listenable from '../mixins/listenable';

import Pagination from './Pagination.vue'; // область пагинации
import InlineEdit from './InlineEdit.vue'; // инлайн редактирование записи
import StackTableRow from './StackTableRow.vue'; // строка таблицы
import MoreMenu from './MoreMenu.vue';
import StackTableHeaders from './StackTableHeaders.vue'; // заголовки таблицы
import { StackSelectionCache } from '../../utils/selections';
// eslint-disable-next-line no-unused-vars
import { Route, Location } from 'vue-router/types/router';

/**
 * Компонент-таблица
 */
export default Vue.extend({
  name: 'StackTable',
  mixins: [Listenable],
  components: {
    Pagination,
    InlineEdit,
    MoreMenu,
    StackTableRow,
    StackTableHeaders,
  },
  provide(): any {
    return {
      historyState: () => this.tableInfo?.filter?.история,
    };
  },
  inject: {
    isDialogReadonly: { default: () => () => false },
  },
  props: {
    /**
     * Коллбек для переопределения данных таблицы
     * (например поменять шрифт или цвет, добавить картинку, кнопку и тд)
     */
    rowhandler: {
      type: Function,
      default: (item: StackTableRow): StackTableRow => {
        return item;
      },
    },
    // коллбек на изменение поля в инлайн
    onInlineFieldChange: {
      type: Function,
      default: (item: StackTableRow, columnName: string): StackTableRow => {
        return item;
      },
    },
    /**
     * отрисовывать сетку таблицы или нет
     */
    grid: { type: Boolean, default: false },
    /**
     * узкая таблица
     */
    dense: { type: Boolean, default: false },
    /**
     * Скрывать тулбар
     */
    noToolbar: { type: Boolean, default: false },
    /**
     * не использовать пагинацию
     */
    noPagination: { type: Boolean, default: false },
    /**
     * не использовать меню таблицы
     */
    noMenu: { type: Boolean, default: false },
    /**
     * Cкрыть заголовки таблицы
     */
    noHeaders: { type: Boolean, default: false },
    /**
     * отображать папки, как записи
     */
    folderLikeField: { type: Boolean, default: false },
    /**
     * номер записи "владельца" выборки
     */
    ownerID: { type: [Number, String], default: null },
    /**
     * перечень названий полей через запятую, или массив объектов с полями
     */
    headers: { type: [String, Array], required: true },
    /**
     * высота таблицы
     */
    height: { type: [String, Number], default: undefined },
    /**
     * ширина таблицы
     */
    width: { type: [String, Number], default: undefined },
    /**
     * отмеченные записи в формате (1;2;3) которые будут сопоставляться с $номерзаписи (преимущественно нужно для полей связи)
     * @model
     */
    value: { type: [String, Number], default: null },
    /**
     * учавствует ли таблица в роутинге
     */
    route: { type: Boolean, default: false },
    /**
     * Возможность выбора строчек в таблице (выводить чекбоксы для выбора или нет)
     */
    noSelect: { type: Boolean, default: false },
    /**
     * Без действий на строке
     */
    noRowAction: { type: Boolean, default: false },
    /**
     * Запрещено добавление записей
     */
    noAdd: { type: Boolean, default: false },
    /**
     * Нельзя удалять
     */
    noDelete: { type: Boolean, default: false },
    /**
     * Нельзя редактировать
     */
    noEdit: { type: Boolean, default: false },
    /**
     * Нельзя копировать
     */
    noCopy: { type: Boolean, default: false },
    /**
     * Нельзя переносить
     */
    noMove: { type: Boolean, default: false },
    /**
     * Нельзя суммировать
     */
    noCalcSum: { type: Boolean, default: false },
    /**
     * Возможность объединять записи
     */
    canMerge: { type: Boolean, default: false },
    /**
     * разрешен ли мультивыбор чекбоксами
     */
    single: { type: Boolean, default: false },
    /**
     * нельзя выбирать папку
     */
    noFolder: { type: Boolean, default: false },
    /**
     * кастомный роут перехода для записей, если не указан, возьмет из описания выборки
     */
    to: { type: [String, Object], default: null },
    /**
     * кастомный роут перехода для папок, если не указан, возьмет из описания выборки
     */
    toFolder: { type: [String, Object], default: null },
    /**
     * количество записей на странице для пагинации
     */
    rowsCount: { type: Number, default: null },
    /**
     * показывать историю
     */
    history: { type: Boolean, default: false },
    /**
     * Состояние кнопки истории по умолчанию
     */
    historyDefault: { type: Boolean, default: undefined },
    /**
     * объект с данными или название выборки
     */
    dataModel: { type: [String, Object, Array], required: true },
    /**
     * Сделать тублар тёмным
     */
    toolbarDark: { type: Boolean, default: false },
    /**
     * Режим инлайн редактирования
     */
    inlineEdit: { type: Boolean, default: false },
    /**
     * Коллбек для переопределения данных записи после редактирования
     */
    // inlinehandler: {
    //   type: Function,
    //   default: (item: object, olditem: object) => {
    //     return item;
    //   },
    // },
    /**
     * объект с кастомными параметрами в запрос
     */
    params: { type: [Object], default: null },
    /**
     * объект с фильтром по-умолчанию
     */
    filter: { type: [Object], default: null },
    /**
     * переопределение роута в зависимости от открываемой записи
     */
    getRouteName: {
      type: Function,
      default: (row: StackTableRow, to: string, toFolder: string, payload: any): string | undefined => {
        // если текущая запись - папка, то берем роут с неё либо с глобального описания выборки, либо с пропсов
        return toFolder && row.$этоПапка ? toFolder : to;
      },
    },
    /**
     * переопределение заголовка тулбара
     */
    description: { type: String, default: null },

    readonly: { type: Boolean, default: false },
    /**
     * игнорируем ридонли записи при работе с таблицей
     */
    ignoreReadonly: { type: Boolean, default: false },
    /**
     * не отображать иконку поиска в тулбаре
     */
    noSearch: { type: Boolean, default: false },
    /**
     * Быстрый поиск вместе с папками
     */
    searchWithFolders: { type: Boolean, default: false },
    /**
     * Поиск в inline edit вместе с папками
     */
    inlineEditSearchWithFolder: { type: Boolean, default: false },
    /**
     * клик по строке - это отметка записи
     */
    selectOnRow: { type: Boolean, default: false },
    /**
     * Признак, что нужно перенабирать выборку при смене рабочего месяца
     */
    reloadOnWorkMonth: { type: Boolean, default: false },
    /**
     * После удаления записей принудительно перенабираем выборку
     */
    reloadOnDelete: { type: Boolean, default: false },
    /**
     * При reloadOnWorkMonth сбрасывать иерархию и пагинацию
     */
    dropOnReload: { type: Boolean, default: false },
    /**
     * название поля, которое является ключём.
     * Пока используется только для начального позиционирования
     * если отличен от дефолта не фокусируется на записи
     * нужно для линкфилдов, когда значением является не row_id
     */
    keyField: { type: String, default: '$номерЗаписи' },
    /**
     * поле, на котором выводить стрелку expand
     */
    expandField: { type: String, default: undefined },
    /**
     * поле являющиеся признаком наличия слота expand
     */
    expandFlag: { type: String, default: undefined },
    /**
     * Развернуть папки
     */
    expand: { type: Boolean, default: false },
    /**
     * Выводит статус загрузки таблицы, если нужно произвести какие то действия
     */
    loading: { type: Boolean, default: false },
    /**
     * Не сохранять выделение при любом новом фетче записей
     */
    noSaveSelected: { type: Boolean, default: false },
    /**
     * не нужно проверять ownerID
     */
    noOwner: { type: Boolean, default: false },
    /**
     * Переводит HTTPModel в StaticModel
     */
    static: { type: Boolean, default: false },
    /**
     * не шлём команду save модели в инлайне
     */
    noSendSave: { type: Boolean, default: false },
    /**
     * выделяем все записи при фетче
     */
    selectAll: { type: Boolean, default: false },
    /**
     * В инлане сразу сохранять записи
     */
    fastEdit: { type: Boolean, default: false },
    /**
     * Печать экрана данными с фронта, а не выборки с бека
     * Нужно, когда вычисляемые поля на фронте, а на беке их нет
     */
    printContext: { type: Boolean, default: false },
    /**
     * У каждой записи иконка "Открыть в новой вкладке" - URL
     */
    openLinkTo: { type: String, default: undefined },
    /**
     * У каждой записи иконка "Открыть в новой вкладке" - Поле связи
     */
    openLinkField: { type: String, default: '$номерЗаписи' },
    apiRights: { type: Boolean, default: false },
    editOn: { type: Boolean, default: false },
    /**
     * При рефетче не показываем статус загрузки
     */
    silent: { type: Boolean, default: false },
    noOpenLink: { type: Boolean, default: false },
  },
  data() {
    return {
      currentHeaders: this.headers,
      isVisible: false,
      tableTop: null as null | number,
      modalDialogVisible: false,
      modalFolderDialogVisible: false,
      dataObject: {} as DataModel,
      routeParamName: 'parentID', // имя параметра (?param=10) для роутинга
      items: [] as StackTableRow[], // данные таблицы
      selectedItems: [] as StackTableRow[], // данные таблицы
      totals: null as StackTableRow | null, // Итоги по таблице
      currentRow: undefined as undefined | StackTableRow, // текущая запись
      currentRowParams: {} as object, // проброс параметров в модалку
      isChanged: false, // показывать кнопку сохранить в тулбаре выборки
      tableInfo: {
        row_id: undefined as number | undefined,
        parentID: -10, // Текущий корень иерархии
        // dataObject: {} as DataModel,
        loading: false,
        icon: null, // TODO выпилить или передавать
        description: '', // Описание (Название) таблицы
        totalItems: 0, // всего записей выборки
        sortBy: '', // сортировать по
        descending: false, // обратная сортировка
        page: 1, // текущая страница
        rowPage: undefined as number | undefined, // страница записи
        rowsPerPage: this.rowsCount ? this.rowsCount : 100, // строк на странице
        expandA: this.expand, // развернуть папки
        filter: {
          история: this.historyDefault,
        } as any, // дополнительные, произвольные условия фильтрации в формате ключ: значение, которые будут форвардится на бэк
      },
      queryParamsWatcher: null as any,
      routeWatcher: null as any,
      navBarFilter: false, // открыт/закрыт навбар с фильтрами
      allHeadersDef: {} as any,
      searchEnable: false, // (не)быстрый поиск открыт/закрыт
      merging: false,
      moving: false,
      preActionItems: [] as StackTableRow[],
      isTableFocused: false,
      actionLoading: false,
      tableSelector: null as Element | null,
      isInitalize: false, // Признак, что компонент проинициализировался
      deletedRecords: [] as StackTableRow['$номерЗаписи'][],
      deleteDialogVis: false,
    };
  },
  computed: {
    // Определяем, а можно ли добавлять записи
    noAddAction(): boolean {
      // @ts-ignore
      return (this.isDialogReadonly() && !(this.ignoreReadonly || (this.apiRights && this.rights && this.rights.редактирование))) || this.noRowAction || this.noAdd || this.tableInfo.expandA;
    },
    // Определяем, а можно ли удалять записи
    noDeleteAction(): boolean {
      // @ts-ignore
      return (this.isDialogReadonly() && !(this.ignoreReadonly || (this.apiRights && this.rights && this.rights.удаление))) || this.noRowAction || this.noDelete;
    },
    rights(): any {
      return this.dataObject.rights;
    },
    isFilterEnable(): boolean {
      return !!this.tableInfo.filter.filterEnable;
    },
    rowSlots(): any {
      const rowSlots = {} as any;
      for (const sl in this.$scopedSlots) {
        if (sl.startsWith('item.')) {
          rowSlots[sl] = this.$scopedSlots[sl];
        }
      }
      return rowSlots;
    },
    drawerWidth(): boolean {
      return this.$store.getters.getNavDrawerState() ? this.$store.getters.getNavBarWidth() : this.$store.getters.getNavBarWidthMini();
    },

    tableStyle(): any {
      if (this.width) {
        if (this.width === '100%') {
          return { 'overflow-y': 'auto', width: `calc(100vw - ${this.drawerWidth}px)`, 'box-sizing': 'border-box' };
        } else {
          return { 'overflow-y': 'auto', width: `${this.width}`, 'box-sizing': 'border-box' };
        }
      }
      return undefined;
    },
    cacheId(): string {
      return `${this.dataObject.model}_stack_table_rowid`;
    },
    tableCache(): any {
      return this.$store.getters.getSyncCache(this.cacheId);
    },
    isLoading(): boolean {
      return this.loading || this.tableInfo.loading;
    },
    isAuth(): boolean {
      return this.$store.getters.isAuth();
    },
    calcHeight(): string | number {
      const height = this.height ? this.height.toString() : undefined;
      if (!height || height.indexOf('%') <= 0) {
        return this.height;
      }
      if (this.tableTop !== null) {
        const heightProcent = +height.replace('%', '');
        return `calc(${heightProcent}vh - ${heightProcent * this.tableTop * 0.01}px)`;
      }
      return this.height;
    },
    modal(): boolean {
      return !!this.$scopedSlots.modal;
    },
    modalFolder(): boolean {
      return !!this.$scopedSlots.modalfolder;
    },
    // флаг наличия навбара с фильтром в вёрстке
    isFilterPresent(): boolean {
      return !!this.$slots.filter || !!this.$scopedSlots.filter;
    },

    // признак того, что таблица иерархическая
    hier(): boolean {
      return this.dataObject.hier;
    },

    tdCount(): number {
      return this.headersList.length + Number(!this.noSelect) + Number(!!this.$scopedSlots.actions) + Number(!this.noRowAction);
    },
    // список полей в таблицу
    headersList(): StackField[] {
      const res = [] as any;
      for (const field of this.headersStringList) {
        if (this.allHeadersDef[field]) {
          res.push({ ...this.allHeadersDef[field] });
        } else {
          res.push({ field, text: field, type: 'Number' });
        }
      }
      return res;
    },
    // Перечень полей, по которым производится быстрый поиск
    searchFieldList(): string {
      const arr = [] as string[];
      this.headersList.forEach((header) => {
        if (!header.computed) {
          arr.push(header.field);
        }
      });
      return arr.toString();
    },

    // массив строковых названий полей которые показываем
    headersStringList(): string[] {
      const heads = [] as string[];
      for (const head in this.allHeadersDef) {
        if (this.allHeadersDef[head].visible) {
          heads.push(this.allHeadersDef[head].field);
        }
      }
      return heads;
    },

    parentSaved(): boolean {
      if (!this.noOwner && this.$options.propsData && 'ownerID' in this.$options.propsData && !(Number(this.ownerID) > 0)) {
        return false;
      }
      return true;
    },

    // массив IDшников отмеченных записей
    selectedIDs(): string[] {
      if (this.value) {
        return this.value.toString().split(',');
      }
      return [];
    },
    selectedIndexes(): number[] {
      const vals: number[] = [];
      this.items.forEach((value, index) => {
        if (value.$selected) {
          vals.push(index);
        }
      });
      return vals;
    },
    selectedValues(): string {
      const vals: string[] = [];
      return this.selectedItems.map((item) => item.$номерЗаписи).join(',');
    },

    // выбранные элементы
    selectedItemsOnPage(): StackTableRow[] {
      return this.items.filter((item) => item.$selected);
    },
    itemsCount(): number {
      return this.items.length;
    },
    allSelected(): boolean {
      return this.itemsCount > 0 && this.selectedItemsOnPage.length === this.itemsCount;
    },

    // конструктор параметров, передаваемых на back-end
    queryParams(): StackHttpRequestTaskParam {
      let q: StackHttpRequestTaskParam = {};
      // устанавливаем папку
      if (this.tableInfo.parentID > 0 && !this.tableInfo.filter.сКорня) {
        q.папка = this.tableInfo.parentID;
      }

      // устанавливаем владельца выборки
      if (this.ownerID && +this.ownerID > 0) {
        q.владелец = +this.ownerID;
      }
      // если есть пагинация, то устанавливаем номер и размер страницы (TODO и почему то сортировку)
      if (!this.noPagination) {
        q.размерСтраницы = this.tableInfo.rowsPerPage;
        if (this.tableInfo.rowPage) {
          q.страницаЗаписи = this.tableInfo.rowPage;
        } else {
          q.номерСтраницы = this.tableInfo.page ? this.tableInfo.page : 1;
        }
      }
      if (this.tableInfo.sortBy) {
        q.сортировка = this.tableInfo.sortBy + (this.tableInfo.descending ? ':DESC' : '');
      }

      if (this.tableInfo.expandA) {
        q.развернутьПапки = 1;
      }
      // всё, что прилетело извне (от тулбара например)
      q = Object.assign({}, q, { фильтр: this.tableInfo.filter });
      if (this.tableInfo.filter.сКорня !== undefined) {
        q.развернутьПапки = 1;
      }
      if (this.tableInfo.filter.развернутьПапки !== undefined) {
        q.развернутьПапки = this.tableInfo.filter.развернутьПапки;
      }
      if (this.tableInfo.filter.поискСтрока) {
        const поискСтрока = this.tableInfo.filter.поискСтрока;
        q.поискСтрока = this.tableInfo.filter.поискСтрока;
      }
      if (this.tableInfo.filter.поискПоля) {
        q.поискПоля = this.tableInfo.filter.поискПоля;
      }
      if (this.tableInfo.filter.история) {
        q.история = this.tableInfo.filter.история;
      }
      if (q.поискСтрока) {
        q.поискПоля = q.поискПоля || this.searchFieldList;
        if (this.searchWithFolders) {
          q.развернутьПапки = 1;
          q.показыватьУзлы = 1;
        }
      }

      if (this.params) {
        q = Object.assign({}, q, this.params);
      }
      return q;
    },

    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
  },

  async created() {
    if (this.routeWatcher) {
      this.routeWatcher();
    }
    if (this.queryParamsWatcher) {
      this.queryParamsWatcher();
    }

    await this.prepareDataModel();
    this.allHeadersDef = this.getAllHeadersDef();
    if (this.route) {
      await this.setFromRouteQuery(this.$route);
    }

    // TODO отрефакторить
    if (!this.route) {
      // если есть установленный value, то нужно один раз спозиционировать таблицу на нужную папку и страницу
      if (this.keyField === '$номерЗаписи' && this.selectedIDs.length > 0) {
        const firstID = +this.selectedIDs[0];
        await this.checkRowId(firstID);
      }
    }
    if (!this.loading) {
      if (this.$route.query.openModal && this.route) {
        this.tableInfo.rowPage = +this.$route.query.openModal;
        this.tableInfo.row_id = +this.$route.query.openModal;
      }
      await this.fetchData();
      if (this.$route.query.openModal && this.route) {
        const row = this.items.find((item) => item['$номерЗаписи'] === +this.$route.query.openModal);
        row && this.openDialog(row);
      }
      this.isInitalize = true;
    }

    this.$watch('dataModel', this.dataModelWatcher, { immediate: false });
    // отслеживаем изменения
    if (this.route) {
      this.routeWatcher = this.$watch('$route', this.setFromRouteQuery, { immediate: false });
    }
    this.queryParamsWatcher = this.$watch('queryParams', this.queryWatcher, { immediate: false, deep: true });

    // инициализируем слушатели событий
    this.$on('tableAction', (msg: any) => this.onToolBarAction(msg));
  },
  mounted() {
    if (this.$el) {
      this.tableSelector = this.$el.querySelector('.v-data-table');
    }
  },
  methods: {
    calcTop() {
      if (this.isVisible && this.tableSelector) {
        const rect = this.tableSelector.getBoundingClientRect();
        this.tableTop = rect.top;
      }
    },
    timeout(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async onIntersect(entries: IntersectionObserverEntry[]) {
      let isVisible = false;
      entries.forEach((entry) => {
        isVisible = isVisible || entry.isIntersecting;
      });
      this.isVisible = isVisible;
      if (this.isVisible) {
        this.calcTop();
        await this.timeout(50);
        this.$sendMsg('stack-table', '*', 'recalc-top');
      }
    },
    // все описания полей, ключем является имя поля
    getAllHeadersDef(): any {
      const allHeaders: StackField[] = Array.isArray(this.currentHeaders) ? (this.currentHeaders as StackField[]) : this.dataObject.fields;
      const defaultHeaders: StackField[] | string[] = Array.isArray(this.headers) ? (this.headers as StackField[]) : this.headers.split(',');
      const res = {} as any;

      const cacheHeadersVis: string[] = this.$store.getters.getSyncCache(`${this.dataObject.model}_headers_order`); // Сохраненные в кеше
      // сначала набираем те поля, которые видимы по умолчанию для того, чтобы они были первые в выборе полей
      defaultHeaders.forEach((i: StackField | string) => {
        const headName = typeof i === 'string' ? i : i.field;
        res[headName] = { field: headName, text: headName, visible: cacheHeadersVis ? cacheHeadersVis.indexOf(headName) !== -1 : true };
      });
      // в конец добавляем все остальные поля, которые существуют в выборке
      if (allHeaders) {
        for (const head of allHeaders) {
          res[head.field] = { ...head, visible: cacheHeadersVis ? cacheHeadersVis.indexOf(head.field) !== -1 : !!res[head.field] && !!res[head.field].visible };
        }
      }
      return res;
    },

    async dataModelWatcher() {
      await this.prepareDataModel();
      if (!this.loading) {
        await this.fetchData();
      }
    },
    onTableFocusOut(ev: any) {
      if (this.$el.contains(ev.relatedTarget)) {
        return;
      }
      this.isTableFocused = false;
    },
    onTableFocus() {
      this.isTableFocused = true;
      const id = this.currentRow && this.currentRow.$номерЗаписи ? this.currentRow.$номерЗаписи : this.items && this.items[0] ? this.items[0].$номерЗаписи : undefined;
      id && this.toRow(+id, { withoutScroll: true });
    },
    onRowFocus(e: any) {
      if (e && e.target && e.target.id) {
        const id = +e.target.id;
        this.currentRow = this.items.find((item: StackTableRow) => item.$номерЗаписи === id);
      }
    },
    toRow(id: number, options?: any) {
      this.$nextTick(() => {
        if (document.activeElement && document.activeElement.tagName === 'INPUT' && !(options && options.fromCache)) {
          return;
        }
        const tag = `tr[id="${id}"]`;
        const el = this.$el.querySelector(tag) as HTMLElement;
        if (el) {
          // @ts-ignore
          this.isTableFocused = true;
          el.focus({ preventScroll: this.isTableFocused });
          if (options && options.withoutScroll) return;
          el.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      });
    },
    async onKeyPressTable(e: KeyboardEvent) {
      if (this.isLoading || !this.isTableFocused) {
        return;
      }
      if (e.ctrlKey) {
        switch (e.code) {
          // ctrl+A развернуть/свернуть папки
          case 'KeyA':
            if (this.hier) {
              this.onChangeExpand(!this.tableInfo.expandA);
              e.preventDefault();
            }
            break;
          // ctrl+Q Открыть/закрыть фильтр
          case 'KeyQ':
            if (this.isFilterPresent) {
              this.navBarFilter = !this.navBarFilter;
              e.preventDefault();
            }
            break;
          // (не)быстрый поиск
          case 'KeyF':
            if (!this.noSearch) {
              this.searchEnable = !this.searchEnable;
              e.preventDefault();
            }
            break;
          case 'ArrowLeft':
            this.onChangePage(this.tableInfo.page - 1);
            e.preventDefault();
            break;
          case 'ArrowRight':
            this.onChangePage(this.tableInfo.page + 1);
            e.preventDefault();
        }
      }
      if (!e.ctrlKey && !e.altKey && !e.shiftKey && !e.metaKey) {
        const elAdd: HTMLElement | null = this.$el.querySelector('[data-cy="btn-add"]');
        const el: any = e.srcElement;
        let elnext: any;
        switch (e.key) {
          case 'Insert':
            // this.onAddRecord(undefined, false);
            // жмём на кнопку в туллбаре, если она есть
            if (!this.noAddAction) {
              elAdd && elAdd.click();
            }
            break;
          case 'Delete':
            if (!this.noAddAction) {
              e.stopPropagation();
              if (this.currentRow) {
                await this.onBtnDelete(this.currentRow);
              }
            }
            break;
          case 'Backspace':
          case 'Escape':
            if (await this.onUpFolder()) {
              e.stopPropagation();
            }
            break;
          case 'Enter':
            if (this.currentRow) {
              e.stopPropagation();
              this.onRowDblClick(this.currentRow, { newTab: e.altKey });
            }
            break;
          case 'F3':
            if (this.currentRow && this.currentRow.$номерЗаписи && this.currentRow.$номерЗаписи > 0) {
              e.stopPropagation();
              e.preventDefault();
              this.openDialog(this.currentRow);
            }
            break;
          case 'ArrowDown':
            e.stopPropagation();
            elnext = el.nextSibling;
            break;
          case 'ArrowUp':
            e.stopPropagation();
            elnext = el.previousSibling;
            break;
        }
        if (elnext && elnext.focus) {
          this.$nextTick(() => elnext.focus());
        }
      }
      if (e.ctrlKey && !e.altKey && !e.shiftKey && !e.metaKey) {
        const el: HTMLElement | null = this.$el.querySelector('[data-cy="btn-addfolder"]');
        switch (e.key) {
          case 'Insert':
            // this.onAddRecord(undefined, false);
            // жмём на кнопку добавления папки в туллбаре, если она есть
            el && el.click();
            break;
        }
      }
    },
    async checkRowId(rowid: number) {
      if (rowid > 0) {
        if (this.hier && this.dataObject.getFolderID) {
          const parentID = (await this.dataObject.getFolderID({ номерЗаписи: rowid, владелец: this.ownerID || undefined })) as number;
          this.tableInfo.parentID = parentID;
          if (this.route) {
            if (this.hier) {
              const q: any = { ...this.$route.query };
              if (q[this.routeParamName] !== (parentID ? parentID.toString() : undefined)) {
                q[this.routeParamName] = parentID ? parentID.toString() : undefined;
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                this.$router.push({ query: q }).catch(e => {});
              }
            }
          }
        }
        this.tableInfo.rowPage = rowid;
        this.tableInfo.row_id = rowid;
      }
    },

    async prepareDataModel() {
      if (this.filter) {
        this.tableInfo.filter = this.filter;
        this.tableInfo.filter.filterEnable = true;
      }
      if (typeof this.dataModel === 'string') {
        this.dataObject = new this.$HttpModel(this.dataModel);
        if (this.static) {
          const items = await this.dataObject.getRecords(this.params);
          this.dataObject = new this.$StaticModel(items, { rowTemplate: this.dataObject.fields, description: this.dataObject.description });
        }
      } else if (Array.isArray(this.dataModel)) {
        // @ts-ignore
        this.dataObject = new this.$StaticModel(this.dataModel, { rowTemplate: this.headers, description: this.description });
      } else {
        this.dataObject = this.dataModel;
      }
      // описание таблицы (заголовок в тулбаре)
      this.tableInfo.description = this.description || this.dataObject.description;

      // уберем сортировку, если в списке полей нет поля, по которому нужно ее выполнить
      if (this.tableInfo.sortBy !== '') {
        if (this.dataObject.fields.findIndex((item: StackField) => item.field === this.tableInfo.sortBy) === -1) {
          this.tableInfo.sortBy = '';
        }
      }
    },

    // изменились параметры ?  фетчим новые данные
    queryWatcher(to: StackHttpRequestTaskParam, old: StackHttpRequestTaskParam) {
      if (JSON.stringify(to) !== JSON.stringify(old)) {
        this.fetchData();
      }
    },
    // Выравнивание
    thJustify(column: StackField): string {
      if (this.inlineEdit) {
        return 'start';
      }
      if (column) {
        if (column.align) {
          switch (column.align) {
            case 'center':
              return 'center';
            case 'left':
              return 'start';
            case 'right':
              return 'end';
          }
        }
        switch (column.type) {
          case 'Date':
          case 'DateMonth':
          case 'DateTime':
          case 'Time':
          case 'Enum':
            return 'center';
          case 'Number':
          case 'NumberZero':
          case 'Quantity':
          case 'Money':
          case 'Link':
            return 'end';
          case 'String':
            return 'start';
        }
      }
      return 'center';
    },
    // class ячеек
    tdClass(column: StackTableRowDataItem): string[] | undefined {
      const tdClass = [] as any;
      if (!column) {
        return tdClass;
      }
      if (column && column.$класс) {
        tdClass.push(column.$класс);
      }
      if (column.align) {
        tdClass.push(`text-${column.align}`);
      }
      switch (column.type) {
        case 'Date':
        case 'DateMonth':
        case 'Enum':
        case 'Number':
        case 'Money':
        case 'Quantity':
          tdClass.push('text-no-wrap');
          break;
      }
      return tdClass || undefined;
    },

    // стиль ячееек
    tdStyle(column: StackTableRowDataItem): any {
      let tdStyle = {} as any;
      if (!column) {
        return tdStyle;
      }
      if (column.$стиль) {
        tdStyle = Object.assign(tdStyle, column.$стиль);
      }
      if (column.width || column.minwidth) {
        if (column.width) {
          tdStyle.width = column.width;
        }
        if (column.minwidth) {
          tdStyle['min-width'] = column.minwidth;
        }
      } else if (
        column.type === 'Date' ||
        column.type === 'DateMonth' ||
        column.type === 'Number' ||
        column.type === 'Money' ||
        column.type === 'NumberZero' ||
        column.type === 'Quantity'
      ) {
        tdStyle.width = '70px';
      }
      if (column.type === 'String' && column.maxwidth) {
        tdStyle['max-width'] = column.maxwidth;
        tdStyle.overflow = 'hidden';
        tdStyle['text-overflow'] = 'ellipsis';
        tdStyle['white-space'] = 'nowrap';
      }
      return tdStyle;
    },

    // читаем события извне (от тулбара)
    async onToolBarAction({ action, payload }: StackTableActionWithPayload) {
      if (this.isLoading) {
        return;
      }
      if (action === 'reload') {
        if (this.dropOnReload && !this.route) {
          const ping = this.tableInfo.parentID > 0 || (this.tableInfo.page && this.tableInfo.page > 1); // проверка, а сработает ли вотчер
          this.tableInfo.parentID = -10;
          this.tableInfo.page = 1;
          if (!ping) {
            await this.fetchData();
          }
        } else {
          await this.fetchData();
        }
        return;
      }

      if (this.readonly && (action === 'add' || action === 'edit' || action === 'add_folder' || action === 'delete')) {
        return;
      }
      switch (action) {
        // добавить запись
        case 'add':
          this.onAddRecord(payload, false);
          break;
        case 'copy':
          if (!this.noCopy) {
            this.onAddRecord({ номерЗаписи: payload.$номерЗаписи, $копировать: true, тип: payload.тип }, payload && payload.$этоПапка);
            this.unSelectItem(payload);
          }
          break;
        case 'move':
          this.onMoveRecords(payload);
          break;
        case 'add_folder':
          this.onAddRecord(payload, true);
          break;
        // редактировать запись
        case 'edit':
          this.openDialog(payload);
          break;
        // удалить запись
        case 'delete':
          this.onBtnDelete(payload);
          break;
        // событие фильтра
        case 'filter':
          this.onFilter(payload);
          break;
        case 'recalc-top':
          this.calcTop();
          break;
        // открываем навбар с фильтром
        case 'open_filter':
          this.navBarFilter = true;
          break;
        case 'clear_filter':
          this.clearFilter();
          break;
        // закрываем навбар с фильтром
        case 'close_filter':
          this.navBarFilter = false;
          break;
        // переход на уровень выше (для иерархических выборок)
        case 'up':
          await this.onUpFolder();
          break;
        case 'print':
          this.onBtnPrint(payload);
          break;
        case 'open-link':
          this.openNewTab(payload);
          break;
        case 'unselect-all':
          this.unSelectAll();
          break;
        case 'reset-headers':
          this.allHeadersDef = this.getAllHeadersDef();
          break;
        case 'start-action':
          this.startAction(payload);
          break;
        case 'finish-action':
          if (payload === 'moving') {
            this.onMoveRecords({ папка: this.tableInfo.parentID });
          } else {
            this.finishMerging();
          }
          break;
        case 'cancel-action':
          this.unSelectAll();
          this.merging = false;
          this.moving = false;
          break;
        case 'search':
          this.searchEnable = payload;
          break;
        default:
          // Всё еще неизвестное науке эмитим вверх
          this.$emit(action, payload);
      }
    },
    startAction(action: string) {
      this.preActionItems = this.selectedItems;
      this.unSelectAll();
      if (action === 'moving') {
        this.moving = true;
        // this.tableInfo.parentID = -10;
      } else {
        this.merging = true;
      }
      this.actionLoading = false;
    },
    async finishMerging() {
      const num = this.preActionItems.length;
      if (
        await this.$yesno(
          `Вы уверены, что хотите объединить ${num} ${
            ['запись', 'записи', 'записей'][num % 100 > 4 && num % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][num % 10 < 5 ? num % 10 : 5]]
          } с текущей?`,
        )
      ) {
        this.actionLoading = true;
        try {
          // @ts-ignore
          await this.dataObject.executeMethod('объединить', {
            номерЗаписи: this.selectedItems[0].$номерЗаписи,
            записиДляОбъединения: this.preActionItems.map((el) => el.$номерЗаписи),
          });
        } finally {
          await this.fetchData();
          this.actionLoading = false;
          this.merging = false;
          this.$toast('Объединение завершено');
          this.unSelectAll();
        }
      }
    },
    clearFilter() {
      const query = { ...this.$route.query };
      if (this.route && this.clearFilterFields(query)) {
        // @ts-ignore
        query.fltr_filterEnable = undefined;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.$router.replace({ query, hash: this.$route.hash }).catch(e => {});
      } else {
        const filter = {} as any;
        for (const field in this.tableInfo.filter) {
          filter[field] = undefined;
        }
        this.$set(this.tableInfo, 'filter', filter);
      }
      this.searchEnable = false;
    },
    clearFilterFields(query: any) {
      let foundOne = false;
      for (const field in query) {
        if (field.startsWith('fltr_')) {
          query[field] = undefined;
          foundOne = true;
        }
      }
      return foundOne;
    },
    onFilter(filterobj: any) {
      this.onChangePage(1);
      if (this.route) {
        const query = { ...this.$route.query };
        for (const field in filterobj) {
          const fieldName = field !== 'row_id' ? 'fltr_' + field : field;
          query[fieldName] = filterobj[field] !== null ? (filterobj[field] !== undefined ? filterobj[field] : query[fieldName]) : undefined;
        }
        let isfilterEnable = false;
        for (const qname in query) {
          if (qname.startsWith('fltr_') && qname !== 'fltr_filterEnable' && query[qname] !== undefined && query[qname] !== null) {
            isfilterEnable = true;
            break;
          }
        }
        // @ts-ignore
        query.fltr_filterEnable = isfilterEnable ? isfilterEnable.toString() : undefined;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.$router.replace({ query, hash: this.$route.hash }).catch(e => {});
      } else {
        if (filterobj.row_id) {
          this.checkRowId(filterobj.row_id);
        } else {
          // this.tableInfo.filter = {};
          const filter = {} as any;
          for (const field in filterobj) {
            filter[field] = filterobj[field] !== null ? (filterobj[field] !== undefined ? filterobj[field] : this.tableInfo.filter[field]) : undefined;
          }
          this.$set(this.tableInfo, 'filter', filter);
          this.tableInfo.filter.filterEnable = true;
        }
      }
    },
    async onUpFolder() {
      if (this.dataObject.getFolderID) {
        const folderID = !this.tableInfo.expandA && this.currentRow && this.currentRow.$папка ? this.currentRow.$папка : this.tableInfo.parentID;
        if (this.tableInfo.expandA) {
          this.tableInfo.expandA = false;
          this.onChangeExpand(false);
        } else if (folderID && folderID > 0) {
          this.$store.commit('POP_BREADCRUMB_ITEM');
          this.clearFilter();
          this.checkRowId(folderID);
        } else return false;
        return true;
      }
    },
    // смена сортировки на заголовке
    changeSort(column: StackField) {
      if (column.computed || this.inlineEdit) {
        return;
      }
      if (this.tableInfo.sortBy === column.field) {
        if (this.route) {
          const q = Object.create(this.$route.query);
          q.sortDesc = Number(!this.tableInfo.descending);
          this.$router.replace({ query: q, hash: this.$route.hash });
        }
        this.tableInfo.descending = !this.tableInfo.descending;
      } else {
        if (this.route) {
          const q = Object.create(this.$route.query);
          q.sortBy = column.field;
          q.sortDesc = 0;
          this.$router.replace({ query: q, hash: this.$route.hash });
        }
        this.tableInfo.sortBy = column.field;
        this.tableInfo.descending = false;
      }
    },
    selectItem(item: StackTableRow) {
      this.$set(item, '$selected', true);
      const foundOne = this.selectedItems.filter((sItem) => sItem.$номерЗаписи === item.$номерЗаписи).length > 0;
      if (!foundOne) {
        this.selectedItems.push({ ...item });
      }
    },
    unSelectItem(item: StackTableRow) {
      this.$set(item, '$selected', false);
      let delItem: StackTableRow | undefined;
      this.selectedItems = this.selectedItems.filter((sItem) => sItem.$номерЗаписи !== item.$номерЗаписи);
    },
    unSelectAll() {
      for (const item of this.items) {
        this.unSelectItem(item);
      }
      this.selectedItems = [];
    },
    // чекбокс "выделить всё"
    toggleAll() {
      if (this.selectedItemsOnPage.length === this.itemsCount) {
        this.selectedItems = [];
        for (const item of this.items) {
          if (item.$selected) {
            this.unSelectItem(item);
          }
        }
      } else {
        for (const item of this.items) {
          if (!this.noFolder || !item.$этоПапка) {
            this.selectItem(item);
          }
        }
      }
      this.$emit('select', this.selectedItems);
    },

    async modalEventBus(action: string, payload: any) {
      switch (action) {
        case 'initerror':
          this.modalDialogVisible = false;
          this.modalFolderDialogVisible = false;
          this.route && this.$route.query.openModal && this.$router.replace({ query: { ...this.$route.query, openModal: undefined }, hash: this.$route.hash });
          this.onTableFocus();
          break;
        case 'close':
          this.modalDialogVisible = false;
          this.modalFolderDialogVisible = false;
          this.route && this.$route.query.openModal && this.$router.replace({ query: { ...this.$route.query, openModal: undefined }, hash: this.$route.hash });
          this.onTableFocus();
          // TODO при создании позиционируемся на страницу, где создана запись
          if (payload && payload.$номерЗаписи) {
            if (!this.hier) {
              // TODO Распутать
              // Если rowPage одинаковый запускаем принудительный фетч таблицы
              if (this.tableInfo.rowPage !== payload.$номерЗаписи) {
                this.tableInfo.rowPage = payload.$номерЗаписи;
              } else if (!this.noPagination) {
                await this.fetchData();
              }
            }
            await this.checkRowId(payload.$номерЗаписи);
          }
          // TODO если пагинация отключена, рефрешим таблицу сами
          if (this.noPagination) {
            await this.fetchData();
          }
          break;
        case 'save':
          this.$emit('save');
          break;
        case 'create':
          this.$emit('create');
          break;
        case 'change':
          // если запись изменилась (из модального диалога)
          // this.currentRow = Object.assign({}, this.currentRow, payload);
          break;
        case 'create_cancel':
          this.$emit('create_cancel');
          break;
      }
    },
    openNewTab(item: StackTableRow) {
      if (this.dataObject.model) {
        if (this.openLinkTo) {
          const routeData = this.$router.resolve(this.openLinkTo);
          if (this.openLinkTo.slice(-1) === '/' && (!item.$этоПапка || this.folderLikeField)) {
            window.open(routeData.href + item[this.openLinkField]);
          } else {
            window.open(routeData.href + `${item['$папка'] ? `?parentID=${item['$папка']}&` : '?'}openModal=${item[this.openLinkField]}`, '_blank');
          }
        }
      }
    },
    // шлём событие выбора строки заинтересованным
    onSelect(rec: any, shiftKey = false) {
      if (this.merging && !!this.preActionItems.filter((el) => el.$номерЗаписи === rec.$номерЗаписи).length) {
        this.$nextTick(() => {
          this.unSelectItem(rec);
          this.$toast('Нельзя объединять запись саму с собой');
        });
      }
      const curFlag = rec.$selected;
      // если не разрешен мультивыбор то оставляем только одну выбранную запись
      if ((this.single || this.merging) && this.selectedItems && this.selectedItems.length !== 0) {
        for (const item of this.items) {
          this.unSelectItem(item);
        }
        this.selectedItems = [];
      }
      if (shiftKey && this.selectedItems.length > 0 && rec.$index) {
        const temp = [...this.selectedIndexes, rec.$index];
        temp.sort((a, b) => a - b);
        for (let i = temp[0]; i < temp[temp.length - 1]; i++) {
          this.selectItem(this.items[i]);
        }
      }
      if (curFlag) {
        this.unSelectItem(rec);
      } else {
        this.selectItem(rec);
      }
      this.$emit('select', this.selectedItems);
    },

    // Добавить запись в выборку
    async onAddRecord(payload: any, folder: boolean) {
      if (this.noAddAction) {
        return;
      }
      const item = {} as StackTableRow;
      if (this.inlineEdit) {
        this.headersStringList.forEach((fieldName) => {
          item[fieldName] = null;
        });
      }
      let row = {} as StackTableRow;
      // TODO тип ЛС при создании берем из записи, а передаётся параметром....?
      if (payload) {
        row = Object.assign(row, payload);
      }
      row = Object.assign(item, row, { $номерЗаписи: 'new', $этоПапка: folder, $папка: this.tableInfo.parentID });
      if (this.inlineEdit) {
        this.items.push(row);
        this.tableInfo.totalItems++;
        this.$emit('update:itemCount', this.tableInfo.totalItems);
        return;
      }
      this.openDialog(row, false, payload);
    },

    // печать таблицы
    async onBtnPrint(payload: { reportName: string; type: StackReportTypes }) {
      if (this.dataObject.executeReport) {
        if (payload.type === 'record') {
          for (const row in this.selectedItems) {
            await this.dataObject.executeReport(payload.reportName, { номерЗаписи: this.selectedItems[row].$номерЗаписи });
          }
        }
        if (payload.type === 'table') {
          await this.dataObject.executeReport(payload.reportName);
        }
        if (payload.type === 'screen') {
          await this.dataObject.executeReport(undefined, { формат: 'html' });
        }
      }
    },

    // перенос записей
    async onMoveRecords(payload: any) {
      if (!this.dataObject.moveRecord) {
        this.moving = false;
        return;
      }
      const selectedItems = this.moving ? this.preActionItems : this.selectedItemsOnPage;
      const notMovingItems = [];
      if (selectedItems.length === 0) {
        this.$toast('Записи не выбраны');
        return;
      }
      if (this.moving && this.items[0] && this.items[0].$папка === selectedItems[0].$папка && selectedItems[0].$этоПапка) {
        this.$toast('Нельзя переместить папку в папку того же уровня');
        return;
      }
      if (this.moving && !(await this.$yesno(`Вы уверены, что хотите переместить\n выделенных записей: ${this.preActionItems.length} ?`, 'Внимание', { width: '600px' }))) {
        return;
      }
      try {
        this.actionLoading = true;
        for (const record of selectedItems) {
          const res = await this.dataObject.moveRecord(record, payload);
          if (!res) {
            notMovingItems.push(record);
          }
        }
      } catch (error: AnyException) {
        return;
      } finally {
        await this.fetchData();
        if (notMovingItems.length !== 0) {
          this.moving = true;
          this.preActionItems = [];
          for (const record of notMovingItems) {
            this.preActionItems.push(record);
          }
        } else {
          this.unSelectAll();
          this.moving = false;
        }
        this.actionLoading = false;
      }
    },
    async rowsDelete(delItems: StackTableRow[]) {
      if (this.dataObject.deleteRecords) {
        this.deleteDialogVis = this.dataObject.model !== 'static';
        this.deletedRecords = [];
        for (const item of delItems) {
          const res = await this.dataObject.deleteRecords([item], this.params);
          if (res) this.deletedRecords.push(item.$номерЗаписи);
        }
        this.deleteDialogVis = false;
        this.selectedItems = this.selectedItems.filter(item => !this.deletedRecords.includes(item.$номерЗаписи));
        this.items = this.items.filter(item => {
          if (this.deletedRecords.includes(item.$номерЗаписи)) {
            this.tableInfo.totalItems--;
            return false;
          }
          return true;
        });
      }
    },
    // Удалить запись в выборке
    async onBtnDelete(item?: StackTableRow) {
      if (this.noDeleteAction) {
        return;
      }
      const countSelected = this.selectedItemsOnPage.length;
      if (!item && countSelected === 0) {
        this.$toast('Нечего удалять');
        return;
      }

      const delRows: StackTableRow[] = countSelected > 0 ? this.selectedItemsOnPage : item && item.$номерЗаписи !== undefined && item.$номерЗаписи >= 0 ? [item] : [];
      let error = false;
      delRows.forEach((item: StackTableRow) => {
        if (item.$толькоЧтение) {
          error = true;
        }
      });
      if (error) {
        this.$toast('Удаление запрещено', { color: 'error' });
        return;
      }
      if (delRows.length > 0) {
        const msg = countSelected > 0 ? ` выделенных записей: ${countSelected}` : item && item.$название ? item.$название : 'текущую запись';
        if (!(await this.$yesno(`Вы уверены, что хотите удалить\n${msg} ?`, 'Внимание', { width: '600px' }))) {
          this.onTableFocus();
          return;
        }

        try {
          this.tableInfo.loading = true;
          await this.rowsDelete(delRows);
          this.$emit('delete');
        } catch (error: AnyException) {
          return;
        } finally {
          // если записи остались, а на экране уже пусто (например при пагинации), то перенабираем выборку
          if (this.reloadOnDelete || (this.items.length === 0 && this.tableInfo.totalItems > 0)) {
            if (this.tableInfo.page > 1 && !this.reloadOnDelete) {
              this.onChangePage(this.tableInfo.page - 1);
            } else {
              await this.fetchData();
            }
            this.onTableFocus();
          }
          this.tableInfo.loading = false;
        }
      } else {
        // при инлайне и когда запись еще не создана
        if (item && this.inlineEdit) {
          await this.fetchData();
        }
      }
    },

    // двойной клик на строке таблицы
    onRowDblClick(item: StackTableRow, options?: { newTab?: boolean; isExpanded?: boolean; expand?: any }) {
      if (this.$scopedSlots.expand) {
        this.$emit('rowclick', item, !options?.isExpanded);
        if (options?.expand) {
          options.expand(!options?.isExpanded);
        }
        return;
      }
      // если заняты, игнорируем
      if (this.isLoading) {
        return;
      }
      // клик по строке это отметка записи
      if (this.selectOnRow && !item.$этоПапка) {
        this.onSelect(item);
        return;
      }

      // если это клик по папке, то проваливаемся
      if (item.$этоПапка && item.$папка) {
        if (item.$номерЗаписи && item.$номерЗаписи >= 0) {
          this.changeParentID(item);
        }
        // иначе "открываем" диалог
      } else {
        this.openDialog(item, !!options?.newTab);
      }
    },

    // "открыть диалог" текущей записи
    openDialog(row: StackTableRow, newTab?: boolean, payload?: any) {
      if (this.readonly || this.moving) {
        return;
      }
      if ((this.modal && !row.$этоПапка) || (this.modalFolder && row.$этоПапка)) {
        this.currentRow = Object.assign({}, row);
        // this.currentRow = Object.assign({}, this.currentRow, row);
        // при инициализации проксируем доп. инфу. Например сказать, что ннада
        this.currentRowParams = payload ? Object.assign({}, payload) : undefined;
        // Если запись не является папкой
        if (!row.$этоПапка) {
          this.modalDialogVisible = true;
        }
        // Если запись является папкой
        if (row.$этоПапка) {
          this.modalFolderDialogVisible = true;
        }
        if (this.route && !this.$route.query.openModal && (this.modalDialogVisible || this.modalFolderDialogVisible)) {
          const rowId = this.currentRow.$номерЗаписи;
          if (rowId) {
            this.$router.replace({ query: { ...this.$route.query, openModal: rowId.toString() }, hash: this.$route.hash });
          }
        }
        return;
      }
      // если текущая запись - папка, то берем роут с неё либо с глобального описания выборки, либо с пропсов
      // @ts-ignore
      let routeName = this.getRouteName(row, this.to, this.toFolder, payload);
      this.$store.commit('SAVE_CACHE_DATA', { id: this.cacheId, data: this.tableInfo.row_id ? this.tableInfo.row_id : row.$номерЗаписи });
      // если текущая запись - не узел
      if (!routeName) {
        if (this.dataObject.model) {
          const sel = StackSelectionCache.getSelectionDefinition(this.dataObject.model);
          if (sel) {
            routeName = row.$этоПапка ? sel.folderRoute : sel.recordRoute;
          }
        }
      }
      // определим имя роута для передачи row_id, как текущий + 'id'.
      if (this.$route.name && routeName) {
        const nameparts = routeName.split('-');
        const nameID = nameparts.pop() + '';

        // переходим куда просят
        if (row.$номерЗаписи) {
          let params = {
            [nameID]: row.$номерЗаписи.toString(),
          };
          if (this.tableInfo.parentID) {
            params[this.routeParamName] = this.tableInfo.parentID.toString();
          }
          if (payload) {
            params = Object.assign(params, { params: payload });
          }
          if (!newTab) {
            this.tableInfo.row_id = +row.$номерЗаписи;
            this.$router.push({
              name: this.$store.getters.getRouteName(routeName),
              params,
            });
          } else {
            const routeData = this.$router.resolve({ name: this.$store.getters.getRouteName(routeName), params });
            window.open(routeData.href, '_blank');
          }
        }
      } else {
        // если диалогов нет, то шлём событие наверх
        this.$emit('rowclick', row);
      }
    },

    onChangePerPage(perPage: number) {
      this.onChangePage(1);
      if (this.route) {
        const q = Object.create(this.$route.query);
        q.perPage = perPage;
        this.$router.replace({ query: q, hash: this.$route.hash });
      } else {
        this.tableInfo.rowsPerPage = perPage;
      }
    },

    onChangeExpand(expandA: boolean) {
      if (this.route) {
        const q = Object.create(this.$route.query);
        q.expand = expandA || undefined;
        q.page = '1';
        this.$router.push({ query: q });
      } else {
        this.tableInfo.expandA = expandA;
        this.tableInfo.page = 1;
      }
    },

    onChangePage(page: number, routeReplace = false, saveRowPage = false) {
      if (page <= 0 || page > Math.ceil(this.tableInfo.totalItems / this.tableInfo.rowsPerPage)) {
        return;
      }
      if (!saveRowPage) {
        this.tableInfo.rowPage = undefined;
      }

      this.tableInfo.rowPage = undefined;
      if (this.tableInfo.page !== +page) {
        if (this.route) {
          const q = Object.create(this.$route.query);
          q.page = page;
          // if (routeReplace) {
          this.$router.replace({ query: q, hash: this.$route.hash });
          // } else {
          //   this.$router.push({ query: q, hash: this.$route.hash });
          // }
        }
        this.tableInfo.page = page;
      }
    },

    // TODO меняем текущий корень в иерархич. таблице
    changeParentID(row: StackTableRow) {
      this.tableInfo.expandA = false;
      if (row.$номерЗаписи !== undefined && row.$номерЗаписи >= 0) {
        const parentID = +row.$номерЗаписи;
        this.tableInfo.row_id = undefined;
        if (this.route) {
          const q = Object.create(this.$route.query);
          q[this.routeParamName] = parentID ? parentID.toString() : undefined;
          q.page = 1;
          this.clearFilterFields(q);
          this.$router.push({ query: q }, () => {
            if (row && row.$название && this.$route.name) {
              this.addBreadCrumbItem(row.$название, { name: this.$route.name, query: q });
            }
          });
        } else {
          this.tableInfo.parentID = parentID;
          this.tableInfo.page = 1;
        }
      }
    },

    // добавляем хлебные крошки
    addBreadCrumbItem(title: string, to: string | Location) {
      this.$store.commit('ADD_BREADCRUMB_ITEM', { title, to });
    },

    // получение данных с бэкэнда
    async fetchData() {
      // Не просим ничего, когда аттрибут ownerID есть, но он пустой
      if (!this.noOwner && this.$options.propsData && 'ownerID' in this.$options.propsData && !(Number(this.ownerID) > 0)) {
        this.items = [];
        return;
      }
      // TODO еще заняты...
      if (this.loading) {
        return; // на всякий пожарный случай
      }

      try {
        this.tableInfo.loading = true;

        if (this.noSaveSelected) {
          this.selectedItems = [];
        }
        const queryParams = this.queryParams;
        this.$emit('update:query-params', queryParams);
        const items: StackTableRow[] = await this.dataObject.getRecords(queryParams);

        // стока итого
        if (this.dataObject.getTotals) {
          const totals = this.dataObject.getTotals();

          if (totals) {
            this.prepareRec(totals);
            this.totals = totals;
          }
        }
        let rowIndex = 0;
        for (const item of items) {
          item.$index = rowIndex++;
          this.prepareRec(item);
        }
        // this.tableInfo.totalItems = items.length; // Всего записей в выборке
        this.tableInfo.totalItems = Math.max(items.length, this.dataObject.length); // TODO Всего записей в выборке
        this.$emit('update:itemCount', this.tableInfo.totalItems);
        // TODO распутать костыли
        this.items = this.dataObject.model === 'static' ? [...items] : items;

        if (this.selectAll) {
          this.toggleAll();
        }
        // TODO позиционируемся на страницу записи (Для линкфилда)
        const currentTablePage = this.tableInfo.rowPage || 1;
        const rowPage = items && items[0] && items[0].$текущаяСтраница ? items[0].$текущаяСтраница : 1;
        if (rowPage !== currentTablePage) {
          this.onChangePage(rowPage, true, this.currentRow && this.currentRow.$папка !== this.tableInfo.rowPage);
        }
        this.$emit('update:tableItems', this.items);
        this.$nextTick(() => {
          if (this.tableInfo.row_id) {
            this.toRow(this.tableInfo.row_id);
          }
        });
        if (!this.currentRow && this.tableCache) {
          this.toRow(this.tableCache, { fromCache: true });
        }
        // Поищем текущую запись в фокусе в таблице
        if ((this.currentRow && this.currentRow.$номерЗаписи) || this.tableCache) {
          const id = this.currentRow ? this.currentRow.$номерЗаписи : this.tableCache;
          this.currentRow = this.items.find((item: StackTableRow) => item.$номерЗаписи === id);
        }
        // Если не установлена или не нашли, то устанавливаем на первую
        if (this.items && this.items[0] && !this.currentRow) {
          this.currentRow = this.items[0];
          const id = this.currentRow.$номерЗаписи;
          if (id) {
            this.toRow(+id);
          }
        }
      } finally {
        this.tableInfo.loading = false;
      }
    },

    prepareRec(item: StackTableRow) {
      if (!item.$selected) {
        this.$set(item, '$selected', false); // флаг выделения записи
      }
      if (item.$data === undefined) {
        item.$data = {};
      }
      for (const header in item) {
        if (!header.startsWith('$')) {
          if (item.$data[header] === undefined) {
            // @ts-ignore
            item.$data[header] = {};
          }
          item.$data[header] = Object.assign({}, item.$data[header], this.allHeadersDef[header]);
        }
      }
      // @ts-ignore
      item = this.rowhandler(item); // кастомный коллбэк на строку таблицы

      // Выделяем ранее выделенные записи
      if (item.$номерЗаписи) {
        for (const sItem of this.selectedItems) {
          if (sItem.$номерЗаписи === item.$номерЗаписи) {
            item.$selected = true;
          }
        }
        for (const sItem of this.selectedIDs) {
          if (Number(sItem) === item.$номерЗаписи) {
            this.selectItem(item);
          }
        }
      }
    },

    // refs возвращает записи выборки
    getRecords() {
      return this.items;
    },

    // refs возвращает выделенные записи выборки
    getSelectedRecords() {
      return this.selectedItems;
    },

    getParams() {
      let params: StackHttpRequestTaskParam = {};
      if (this.tableInfo.parentID && +this.tableInfo.parentID > 0) {
        params.папка = this.tableInfo.parentID;
      }
      if (this.ownerID && +this.ownerID > 0) {
        params.владелец = +this.ownerID;
      }
      if (this.params) {
        params = Object.assign(params, this.params);
      }
      return params;
    },

    async setFromRouteQuery(route: Route) {
      const parentID = route.query[this.routeParamName]; // корень выборки
      const row_id = route.query.row_id;
      const page = route.query.page; // страница
      const perPage = route.query.perPage; // записей на странице
      const expand = route.query.expand; // развернуть папки
      const sortBy = route.query.sortBy; // сортировка
      const sortDesc = Number(route.query.sortDesc); // сортировка asc-desc

      if (parentID !== undefined) {
        if (this.tableInfo.parentID !== +parentID) {
          this.tableInfo.parentID = +parentID;
        }
      } else {
        this.tableInfo.parentID = -10;
      }
      if (row_id !== undefined) {
        // @ts-ignore
        this.$route.query.row_id = undefined;
        await this.checkRowId(+row_id);
      }
      if (!isNaN(+page) && this.tableInfo.page !== +page) {
        this.tableInfo.page = +page; // Math.min(+page, Math.ceil(this.tableInfo.totalItems / this.tableInfo.rowsPerPage));
      }
      if (perPage !== undefined) {
        if (this.tableInfo.rowsPerPage !== +perPage) {
          this.tableInfo.rowsPerPage = +perPage;
        }
      } else {
        this.tableInfo.rowsPerPage = this.rowsCount ? this.rowsCount : 100;
      }

      this.tableInfo.expandA = !!expand;
      if (sortBy !== undefined) {
        this.tableInfo.sortBy = sortBy as string;
      }
      this.tableInfo.descending = !!sortDesc;

      const q = {} as any;
      for (const field in this.$route.query) {
        if (field.startsWith('fltr_')) {
          const filterName = field.substr(5);
          q[filterName] = this.$route.query[field];
          // Если в описании выборки тип поля Число, то по возможности преобразуем
          if (this.allHeadersDef[filterName]?.type === 'Number') {
            q[filterName] = !isNaN(Number(this.$route.query[field])) ? +this.$route.query[field] : this.$route.query[field];
          }
          const fieldValue = String(this.$route.query[field]).toLowerCase();
          if (fieldValue === 'true' || fieldValue === 'false') {
            q[filterName] = fieldValue === 'true';
          }
        }
      }
      this.tableInfo.filter = Object.assign({}, this.tableInfo.filter, q);
    },
  },

  watch: {
    headers: {
      immediate: false,
      handler() {
        this.currentHeaders = this.headers;
        this.allHeadersDef = this.getAllHeadersDef();
      },
    },
    isAuth(to) {
      if (to && !this.isInitalize) {
        this.fetchData();
      }
    },
    loading(to) {
      if (!to) {
        this.fetchData();
      }
    },
    workMonth() {
      if (this.reloadOnWorkMonth) {
        this.onToolBarAction({ action: 'reload' });
      }
    },
    route(to) {
      if (to && !this.routeWatcher) {
        this.routeWatcher = this.$watch('$route', this.setFromRouteQuery, { immediate: false });
      }
      if (!to && this.routeWatcher) {
        this.routeWatcher();
        this.routeWatcher = null;
      }
    },
  },
});
