
import Vue from 'vue';

export default Vue.extend({
  props: {
    params: {
      type: Object,
      default: (): any => {
        return {};
      },
    },
    value: { type: Object, default: undefined },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        ДатНач: this.params.setCurrentDate === 0 ? this.$store.getters.getWorkMonth() : this.$store.getters.getCurrentDate(),
        ДатКнц: this.params.setCurrentDate === 0 ? this.$store.getters.getWorkMonth() : this.$store.getters.getCurrentDate(),
        ГрЛС: '',
        Показания: false,
      },
    };
  },
  methods: {
    initAddress(items: StackTableRow[]) {
      this.record.ГрЛС = (items[0] && items[0].адрес ? items[0].адрес : '') as string;
    },
  },
});
