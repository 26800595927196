
import Vue from 'vue';
import PrintList from './PrintList.vue';

export default Vue.extend({
  components: { PrintList },
  model: { prop: 'records' },
  props: {
    /**
     * массив записей для печати
     */
    records: { type: [Array, Object], default: undefined },
    /**
     *  1 - массовый переход
     *  2 - С состояния
     *  0 - С документа
     */
    type: { type: Number, default: undefined },
    currentfaza: { type: Number, default: undefined },
    currentsost: { type: Number, default: undefined },
    doc: { type: Number, default: undefined },
    индексы: { type: [Array, Object], default: undefined },
  },
  data() {
    return {
      paramprint: {} as any,
      podpis: {} as StackTableRow, // Подписи
      visibleDialog: false,
      dataModel: 'РеестрПерехода_Печать',
      listOfDocs: [] as StackTableRow[], // список документов в таблицу для выбора,
      selectall: false,
    };
  },
  computed: {
    paramsPodpis(): any {
      return {
        руководитель: this.podpis && this.podpis.руководитель ? this.podpis.руководитель : '',
        должность: this.podpis && this.podpis.должность ? this.podpis.должность : '',
        основание: this.podpis && this.podpis.основание ? this.podpis.основание : '',
      };
    },
  },
  methods: {
    async selectPodpis(subscriber: StackTableRow) {
      if (subscriber) {
        this.podpis.должность = subscriber.должность;
        this.podpis.основание = subscriber.основание;
      }
    },
    async getTypeOfDocs() {
      this.listOfDocs = [];
      this.listOfDocs = await new this.$HttpModel(this.dataModel)
        .getRecords({
          позицияДокумента: this.doc,
          текущаяФаза: this.currentfaza,
          текущееСостояние: this.currentsost,
          тип: this.type,
          индексы: this.индексы,
        });
    },
    async onSave() {
      let docs = [] as StackTableRow[];
      docs = this.listOfDocs.filter(item => item.$selected);
      if (docs.length === 0) {
        this.$toast('Документы не выбраны!', { color: 'error' });
        return;
      }
      // облегчаем объект (убираем лишние данные, отправляем только нужное)
      let arrayTemp = {};
      if (this.type !== 99) {
        arrayTemp = this.records.map((item: StackTableRow) => {
          switch (this.type) {
            case 0: // с дела
              return { $номерЗаписи: item.$номерЗаписи };
            case 1: // массовый переход
              return { row_id: item.row_id, состояние: item.состояние };
            case 2: // с состояния
              return { фаза: item.фаза, row_id: item.row_id };
            default:
              return { $номерЗаписи: item.$номерЗаписи, фаза: item.фаза, row_id: item.row_id };
          }
        });
      }
      await new this.$HttpModel('ВзысканиеЗадолженностей').executeMethod(
        'ПечатьКомплектов',
        {
          reportFormat: this.paramprint.reportFormat,
          тип: this.type,
          параметрыдиалога: { ...this.paramsPodpis },
          документы: arrayTemp,
          отчеты: docs,
          текущаяФаза: this.currentfaza,
          текущееСостояние: this.currentsost,
          индексы: docs,
        },
        { async: true, jobName: `Печать комплекта документов `, type: 'report' },
      );
      this.$emit('save');
    },
    onSelected(sel: boolean) {
      this.listOfDocs.forEach((row: any) => { this.$set(row, '$selected', sel); });
    },
  },
  mounted() {
    this.getTypeOfDocs();
  },
});
