
import Vue from 'vue';
import Fetch from '../mixins/fetchable';
/**
 * Побитовые флаги
 */
export default Vue.extend({
  name: 'StackRadioBox',
  mixins: [Fetch],
  model: { prop: 'value', event: 'change' },
  props: {
    /**
     * текущее значение
     * @model
     */
    value: { type: [Number, String], default: null },
    /**
     * высота элемента, по-умолчанию сам растянется как надо
     */
    height: { type: [Number, String], default: null },
    /**
     * модель данных
     */
    dataModel: { type: [String, Object, Array], required: true },
    /**
     * отображение строкой или в столбец
     */
    row: { type: Boolean, default: false },
    /**
     * какое поле является ключем в записи. По-умолчанию это $номерЗаписи
     */
    itemValue: { type: String, default: '$номерЗаписи' },
    /**
     * какое поле является ключем в записи. По-умолчанию это $номерЗаписи
     */
    itemKey: { type: String, default: 'значение' },
    /**
     * label для элемента
     */
    label: { type: String, default: '' },
    /**
     * неактивный радиобокс
     */
    disabled: { type: Boolean, default: false },
    /**
     * только чтение
     */
    readonly: { type: Boolean, default: false },
    /**
     * Вернуть объект
     */
    returnObject: { type: Boolean, default: false },
  },
  data() {
    return {
      items: [] as any,
    };
  },
  methods: {
    // получение данных с бэкэнда
    async fetchData() {
      // @ts-ignore
      if (this.dataObject.getRecords) {
        // @ts-ignore
        this.items = await this.dataObject.getRecords();
      }
    },

    onChange(event: any, item: any) {
      this.$emit('change', this.returnObject ? item : event);
    },
  },
});
