const acts: StackSelection = {
  objectName: 'Объекты.Состояния',
  description: 'Состояние',
  fields: [
    {
      field: 'датнач',
      text: 'Начало действия',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Окончание действия',
      type: 'Date',
    },
    {
      field: 'строкасостояния',
      text: 'Состояние',
      type: 'String',
      computed: true,
    },
    {
      field: 'значение',
      text: 'Среднее',
      type: 'Number',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
  ],
};
export default acts;
