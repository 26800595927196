
import Vue from 'vue';
import FiasResult from '@/tasks/common/views/$common/FiasResult.vue';

interface paramsParentAddress {
  имя: string; // Глобальный флаг разрешен или запрещен доступ
  тип: string | number | null;
}

interface objectParamsAddress {
  тип?: string | number; //
  родитель?: paramsParentAddress[];
  needFill: boolean;// Флаг, указывающий бэку - что надо выполнять поиск
}

export default Vue.extend({
  components: { FiasResult },
  model: {
    prop: 'record',
    event: 'input',
  },
  props: {
    record: { type: Object, required: true },
    disabled: { type: Boolean, required: false },
    showCountry: { type: Boolean, default: false }, // выводить страну
    showPostal: { type: Boolean, default: true }, // выводить почтовый индекс
    showRegion: { type: Boolean, default: true }, // выводить область
    showDistrict: { type: Boolean, default: true }, // выводить район
    showLocality: { type: Boolean, default: true }, // выводить населенный пункт
    showLocalArea: { type: Boolean, default: false }, // выводить городской район
    showKorp: { type: Boolean, default: false }, // выводить корпус дома
    showRoom: { type: Boolean, default: true }, // выводить комнату
    showOffice: { type: Boolean, default: false }, // выводить номер офиса
    showDesc: { type: Boolean, default: false }, // выводить примечание
    showFias: { type: Boolean, default: false }, // поиск в ФИАС
  },
  data() {
    return {
      waitFias: false,
      findFias: false,
      houses: [] as StackTableRow[],
    };
  },
  computed: {
    region(): objectParamsAddress {
      const arrParent = [] as paramsParentAddress[];
      if (this.record.страна) { arrParent.push({ имя: this.record.страна, тип: 0 }); }
      return { needFill: true, тип: 1, родитель: arrParent };
    },
    district(): objectParamsAddress {
      return { needFill: true, тип: '2,3', родитель: [{ имя: this.record?.область, тип: 1 }] };
    },
    city(): objectParamsAddress {
      if (!(this.record.район || this.record.область) || !this.district.родитель) {
        return { needFill: false };
      }
      const arrParent = [...this.district.родитель] as paramsParentAddress[];
      if (this.record.район) { arrParent.push({ имя: this.record.район, тип: '2,3' }); }
      return { needFill: true, тип: 5, родитель: arrParent };
    },
    locality(): objectParamsAddress {
      if (!(this.record.город || this.record.район) || !this.city.родитель) {
        return { needFill: false };
      }
      const arrParent = [...this.city.родитель] as paramsParentAddress[];
      if (this.record.город) { arrParent.push({ имя: this.record.город, тип: 5 }); }
      return { needFill: true, тип: 6, родитель: arrParent };
    },
    planStruct(): objectParamsAddress {
      if (!(this.record.наспункт || this.record.город) || !this.locality.родитель) {
        return { needFill: false };
      }
      const arrParent = [...this.locality.родитель] as paramsParentAddress[];
      if (this.record.наспункт) { arrParent.push({ имя: this.record.наспункт, тип: 6 }); }
      return { needFill: true, тип: 7, родитель: arrParent };
    },
    street(): objectParamsAddress {
      if (!(this.record.планструктура || this.record.наспункт || this.record.город) || !this.planStruct.родитель) {
        return { needFill: false };
      }
      const arrParent = [...this.planStruct.родитель] as paramsParentAddress[];
      if (this.record.планструктура) { arrParent.push({ имя: this.record.планструктура, тип: 7 }); }
      return { needFill: true, тип: 8, родитель: arrParent };
    },
  },
  methods: {
    clear(field?: string) {
      switch (field) {
        case 'область':
          this.record.район = this.record.город = this.record.наспункт = this.record.грайон = this.record.улица = this.record.планструктура = null;
          break;

        case 'район':
          this.record.город = this.record.наспункт = this.record.грайон = this.record.улица = this.record.планструктура = null;
          break;

        case 'наспункт':
        case 'город':
          this.record.улица = this.record.грайон = this.record.планструктура = null;
          break;

        case 'планструктура':
          this.record.улица = this.record.грайон = null;
          break;

        case 'улица':
          this.record.индекс = this.record.initPostcode ? this.record.индекс : 0;
          break;

        default:
          this.record.индекс = 0;
          this.record.улица = this.record.планструктура = this.record.грайон = this.record.наспункт = this.record.город = this.record.район = this.record.область = null;
          break;
      }

      this.record.дом = this.record.примечание = this.record.корпус = this.record.квартира = this.record.комната = this.record.офис = null;
    },
    async onSyncFias() {
      this.waitFias = true;
      try {
        this.houses = await new this.$HttpModel('ЛицевыеСчета.ПоискДомаФиас').getRecords({
          номер: this.record.дом,
          литера: this.record.корпус,
          нзУлица: this.record.нзУлица,
        });
        if (this.houses.length > 0) {
          this.findFias = true;
        }
      } catch (e) {
        console.log('fetch error', e);
      }
      this.waitFias = false;
    },
    async onFiasSave(_selectedHouse: any) {
      if (_selectedHouse != null) {
        this.record.дом = _selectedHouse.номер || '';
        this.record.корпус = _selectedHouse.литера || '';
        this.record.индекс = _selectedHouse.индекс || '';
      }
      this.findFias = false;
    },
    async fetchIndex(event: StackTableRowData) {
      this.record.нзУлица = (event && event.ключ ? event.ключ : -1) as number;
      if (this.record.initPostcode) {
        if (this.record.нзУлица > 0) {
          const data = await new this.$HttpModel('Улицы').getRecords({ номерЗаписи: this.record.нзУлица });
          if (data[0] && data[0]['почтовый индекс']) {
            this.record.индекс = data[0]['почтовый индекс'] as number;
          }
        }
      } else {
        this.record.initPostcode = true;
      }
    },
  },
});
