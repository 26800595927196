
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    headers: { type: Array as PropType<StackField[]>, required: true },
    noSelect: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    single: { type: Boolean, default: false },
    allSelected: { type: Boolean, default: false },
    inlineEdit: { type: Boolean, default: false },
    descending: { type: Boolean, default: false },
    hasRowAction: { type: Boolean, default: false },
    sortBy: { type: String, default: '' },
    thJustify: { type: Function, required: true },
  },
  data() {
    return {
      thDragStart: false,
      thStartX: 0,
      thStartWidth: 0,
      thEl: null as HTMLTableHeaderCellElement | null,
    };
  },
  methods: {
    thMouseDown(event: MouseEvent) {
      this.thDragStart = true;
      this.thStartX = event.pageX;
      const el = event.target as HTMLInputElement;
      if (el) {
        this.thEl = el.closest('th');
      }
      if (el) {
        this.thStartWidth = el ? el.clientWidth : 0;
      }
    },
    thMouseUp() {
      this.thDragStart = false;
      this.thEl = null;
    },
    thMouseMove(event: MouseEvent) {
      if (this.thDragStart) {
        if (this.thEl) {
          const newWidth = this.thStartWidth + (event.pageX - this.thStartX) + 'px';
          this.thEl.style.width = newWidth;
        }
      }
    },
  },
});
