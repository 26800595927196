
import Vue from 'vue';
import datePeriodFilter from '@/tasks/fl/views/$common/filters/datePeriodFilter.vue';
import monthPeriodFilter from '@/tasks/fl/views/$common/filters/monthPeriodFilter.vue';
import organizationsFilter from '@/tasks/fl/views/$common/filters/organizationsFilter.vue';
import sumFilter from '@/tasks/fl/views/$common/filters/sumFilter.vue';
import addressFilter from '@/tasks/fl/views/$common/filters/addressFilter.vue';

export default Vue.extend({
  components: { datePeriodFilter, monthPeriodFilter, organizationsFilter, sumFilter, addressFilter },
  data() {
    return {
      item: {
        датнач: null,
        даткнц: null,
        месяцс: null,
        месяцпо: null,
        организация: null,
        сумма: null,
        суммаудерж: null,
        номер: null,
        нп: null,
        улица: null,
        дом: null,
        квартира: null,
        номерлс: null,
        стороннийномер: null,
        фионанимателя: null,
      } as any,
    };
  },
});
