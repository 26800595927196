
import Vue from 'vue';
import datePeriodFilter from './datePeriodFilter.vue';

export default Vue.extend({
  name: 'OrganizationsFilter',
  components: { datePeriodFilter },
  data() {
    return {
      item: {
        датнач: null,
        даткнц: null,
        канал: null,
        состояние: null,
        пользователь: null,
      } as any,
    };
  },
});
