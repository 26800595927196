const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.Объекты.Показания',
  description: 'Показания',
  recordRoute: 'reading',
  fields: [
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: 'заводскойномер',
      text: 'Заводской номер',
      type: 'String',
    },
    {
      field: 'расчетный месяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'показание',
      text: 'Показание',
      type: 'NumberZero',
      width: '100px',
    },
    {
      field: 'расход',
      text: 'Расход',
      type: 'NumberZero',
      width: '100px',
    },
    {
      field: 'итоговый расход',
      text: 'Итоговый расход',
      type: 'NumberZero',
      width: '100px',
    },
    {
      field: 'дополнительный расход',
      text: 'Дополнительный расход',
      type: 'Number',
      width: '100px',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'тариф',
      text: 'Зона суток',
      type: 'String',
    },
    {
      field: 'типввода',
      text: 'Тип ввода',
      type: 'Number',
    },
    {
      field: '@тип ввода',
      text: 'Тип ввода',
      type: 'Number',
    },
    {
      field: 'Объект-Показания>Разрядность',
      text: 'Разрядность',
      type: 'Number',
    },
    {
      field: 'Объект-Показания>ДробнаяРазрядность',
      text: 'ДробнаяРазрядность',
      type: 'Number',
    },
    {
      field: 'Объект-Показания>Коэффициент трансформации',
      text: 'КТранс',
      type: 'Number',
    },
    {
      field: '@зона',
      text: 'Зона суток',
      type: 'String',
      width: '60px',
    },
  ],
};
export default accs;
