
import Vue from 'vue';
import folder from './$dialogs/folder.vue';
import CheckListsToolbar from './$dialogs/CheckListsToolbar.vue';

export default Vue.extend({
  name: 'HistoryLsTab',
  components: { CheckListsToolbar, folder },
  methods: {
    updateTable() {
      // @ts-ignore
      this.$refs.table.fetchData();
    },
  },
});
