
import Vue from 'vue';
import reloadDialog from './ReloadResourсes.vue';
import passwordDialog from '../PasswordResetDialog.vue';
import credits from '../../../themes/credits_stack';

export default Vue.extend({
  components: {
    reloadDialog,
    passwordDialog,
  },
  computed: {
    getHelpUrl(): string | undefined {
      return this.$store.getters.getHelpUrl();
    },
    isDebugMode(): boolean {
      return this.$store.getters.isDebugMode();
    },
    userIconColor(): string {
      return this.isDebugMode ? 'error' : '';
    },
    userFunc(): string {
      return this.$store.getters.isUserAdmin() ? 'Администратор' : 'Пользователь';
    },

    pluginName(): string {
      return credits.pluginName;
    },
    menuItems(): any[] {
      const items: any = [];
      // items.push({ title: 'Сменить пароль', id: 6, icon: '$vuetify.icons.reset_password' });
      items.push({ title: 'Перезагрузить ресурсы', id: 1, icon: '$vuetify.icons.reload' });
      if (this.$store.getters.isUserAdmin()) {
        items.push({ title: 'Администрирование', id: 2, icon: '$vuetify.icons.settings', to: '/admin' });
      }
      items.push({ title: 'Помощь', id: 5, icon: '$vuetify.icons.help' });
      if (!this.isStackPushPresent || this.isPluginExpired) {
        items.push({
          title: this.isStackPushPresent && this.isPluginExpired ? `Обновить ${this.pluginName}` : `Установить ${this.pluginName}`,
          id: 4,
          icon: '$vuetify.icons.plugin',
          to: '/plugin',
        });
      }
      if (this.getBundles.length > 1) {
        items.push({ title: 'Переключение программ', id: 4, icon: '$vuetify.icons.task_switch', to: '/taskswitch' });
      }
      // items.push({ title: 'Выход', id: 3, icon: '$vuetify.icons.logout' });
      if (this.isDevelop) {
        items.push({ title: 'Перезагрузка бэкэнда', id: 100, icon: '$vuetify.icons.refresh' });
      }
      return items;
    },
    isDevelop(): boolean {
      return this.$store.getters.isDevelop();
    },
    getBundles(): string[] {
      // @ts-ignore
      return this.$store.getters.getBundles();
    },
    getInfo(): string {
      if (this.isStackPushPresent) {
        if (this.isPluginExpired) {
          return `Необходимо обновить ${this.pluginName}`;
        }
        return `${this.pluginName} активен`;
      }
      return `${this.pluginName} не найден`;
    },
    getIconColor(): string {
      if (this.isStackPushPresent) {
        if (this.isPluginExpired) {
          return 'error';
        }
        return 'primary';
      }
      return '';
    },
    userName(): string {
      return this.$store.getters.getUserName();
    },
    isStackPushPresent(): boolean {
      return this.$store.getters['wsStore/isConnected']();
    },
    isPluginExpired(): boolean {
      return this.$store.getters['wsStore/isExpired']();
    },
    isAuth(): boolean {
      return this.$store.getters.isAuth();
    },
  },
  data() {
    return {
      dialogVis: false,
      dialogPassVis: false,
      evListener: undefined as any,
    };
  },
  mounted() {
    this.evListener = (e: KeyboardEvent) => this.keyboardListener(e);
    document.addEventListener('keydown', this.evListener);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.evListener);
  },
  methods: {
    keyboardListener(event: KeyboardEvent) {
      // alt-F12
      if (event.altKey && event.code === 'F12') {
        this.onReload();
        event.stopPropagation();
      }
      // alt+shift+ctrl+B
      if (event.altKey && event.ctrlKey && event.shiftKey) {
        if (event.code === 'KeyU') {
          this.$store.commit('SET_URL_DIALOG_STATE', true);
        }
        if (event.code === 'KeyZ') {
          this.$store.commit('ENABLE_HIDDEN_MENU');
        }
      }
    },
    async onClick(menuItem: any) {
      switch (menuItem.id) {
        case 1:
          this.onReload();
          break;
        case 2:
          window.open('/admin', '_blank');
          break;
        case 3:
          await this.onExit();
          break;
        case 4:
          this.$router.push(menuItem.to);
          break;
        case 5:
          this.$store.commit('SET_HELP_STATE', true);
          break;
        // case 6:
        //   this.dialogPassVis = true;
        //   break;
        case 100:
          await new this.$StackApi().restart();
          this.$store.commit('SET_CONNECTED', false);
          break;
      }
    },
    async onExit() {
      this.$store.dispatch('SIGN_OUT');
    },
    onReload() {
      if (this.isAuth) {
        this.dialogVis = true;
      }
    },
    async onCheckPlugin() {
      await this.$store.dispatch('wsStore/connect');
    },
  },
});
