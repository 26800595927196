
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      items: ['Берем для выгрузки счет регоператора', 'Берем спецсчет'],
    };
  },
});
