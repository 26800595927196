
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      Месяц: this.$store.getters.getOpenMonth(),
      dataModel: new this.$HttpModel('ВыгрузитьПлатежиВИнтеграцию'),
      jobID: null as null | number,
      success: false,
      payments: null,
      selectPayments: '',
      errorMessage: '',
      error: false,
      valid: true,
    };
  },
  computed: {
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
  },
  methods: {
    rowhandler(item: StackTableRow) {
      if (item['@блокирована']) {
        // @ts-ignore
        item.$data['@блокирована'].$иконка = '$vuetify.icons.email_lock';
        // @ts-ignore
        item.$data['@блокирована'].$иконка_цвет = 'error';
        item['@блокирована'] = '';
      }
      return item;
    },
    onSelectPayments(payload: any) {
      let str = '';
      payload.forEach((element: any) => {
        str === '' ? (str += element.$номерЗаписи) : (str += ', ' + element.$номерЗаписи);
      });
      this.selectPayments = str;
    },
    async exportPayments() {
      // @ts-ignore
      if (!this.$refs.form.validate()) {
        return;
      }

      this.success = false;
      this.error = false;
      this.jobID = (await this.dataModel.executeMethod(
        'Выгрузить',
        { Месяц: this.Месяц, Платежи: this.selectPayments },
        {
          async: true,
          jobName: 'Выгрузка платежей для online-касс',
        },
      )).asyncId;
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.jobID = null;
        this.success = true;
        this.error = false;
      }
    },
    isError(to) {
      if (to) {
        this.errorMessage = this.resultJob.status;
        this.jobID = null;
        this.success = false;
        this.error = true;
      }
    },
  },
});
