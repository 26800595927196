
import Vue from 'vue';
import ReadingFilter from './ReadingFilter.vue';

export default Vue.extend({
  components: { ReadingFilter },
  props: {
    dataObject: { type: Object, default: undefined },
    active: { type: [Object, Array], default: undefined }, // Array парсер
    params: { type: Object, default: undefined },
  },
  data() {
    return {
      record: this.active && this.active[0],
      group: 0,
      flags: 0,
      parameters: {},
      visibleInputDialog: false,
    };
  },
  methods: {
    async onSaveDialog() {
      this.parameters = Object.assign({}, this.params, { флаги: this.flags, элемент: this.record, группа: this.group });
      this.visibleInputDialog = true;
    },
    applyFilter(payload: any) {
      const filter = Object.assign({}, payload, this.parameters);
      // @ts-ignore
      this.$refs.table.applyFilter(filter);
    },
    applyData(payload: any) {
      // @ts-ignore
      this.$refs.table.applyData(payload);
    },
  },
});
