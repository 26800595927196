
import Vue from 'vue';
import datePeriodFilter from './datePeriodFilter.vue';
import organizationsFilter from './organizationsFilter.vue';

export default Vue.extend({
  components: { datePeriodFilter, organizationsFilter },
  data() {
    return {
      item: {
        датнач: this.$stackDate.firstDay(this.$store.getters.getCloseMonth()),
        даткнц: null,
        месяцс: null,
        месяцпо: null,
        организация: null,
        суммавед_с: null,
        суммавед_по: null,
        номер: null,
        органюр: null,
        датсозданияс: null,
        датсозданияпо: null,
        примечание: null,
        причина: null,
      } as any,
    };
  },
  computed: {
    isDisabledContract(): boolean {
      return this.item.юрлиц;
    },
    isDisabledOrg(): boolean {
      return this.item.юрлиц || this.item.органюр;
    },
  },
});
