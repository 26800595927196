
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  computed: {
    paramDetail(): object {
      return { допОтчетСальдо: 1, тип: this.record['Параметры.фНазваниеОтчета'] };
    },
  },
  data() {
    return {
      record: {
        'Список лицевых': this.value && this.value['Список лицевых'] ? this.value['Список лицевых'] : -10,
        'Параметры.ДатНач': this.$store.getters.getWorkMonth(),
        'Параметры.ДатКнц': this.$store.getters.getWorkMonth(),
        'Параметры.Месяц': this.$store.getters.getWorkMonth(),
        'Параметры.@МинМес': 0,
        'Параметры.@МаксМес': 0,
        'Параметры.Услуга': -1,
        'Параметры.КатегорияУслуг': -1,
        'Параметры.Поставщик': -1,
        'Параметры.КатегорияПоставщиков': -1,
        'Параметры.УК': -1,
        'Параметры.сТипНач': -1,
        'Параметры.фВыводExcel': 0,
        'Параметры.Детализация': 0,
        'Параметры.фНазваниеОтчета': 0,
        'Параметры.пВариант': 0,
        'Параметры.Переключатель': 0,
        'Параметры.фДогУК': 0,
        'Параметры.Флаги': 2,
        'Параметры.флТариф': 0,
        'Параметры.ПоказыватьАн2': 0,
        'Параметры.флКат': 0,
        'Параметры.флУслКат': 0,
        'Параметры.фФильтрПоВидамДоговора': 0,
        'Параметры.showMonth': 0,
        'Параметры.фРазвернутьСальдо': 0,
      } as StackTableRow,
      menu: false,
      showDogovorTypeFilter: false as boolean,
    };
  },
  methods: {
    onChangeParametersFlags(flags: number) {
      if (+flags % 2 === 0) {
        this.record['Параметры.фДогУК'] = 0;
        this.record['Параметры.фФильтрПоВидамДоговора'] = 0;
      }
    },
    onChangeServ(id: number) {
      this.record['Параметры.КатегорияУслуг'] = -1;
      this.record['Параметры.флУслКат'] = 0;
    },
    onChangeCategServ(id: number) {
      this.record['Параметры.Услуга'] = -1;
      if (+id === 0) {
        this.record['Параметры.флКат'] = 0;
        this.record['Параметры.флУслКат'] = 0;
      } else {
        this.record['Параметры.флУслКат'] = 1;
      }
    },
    onChangePost(id: number) {
      this.record['Параметры.КатегорияПоставщиков'] = -1;
    },
    onChangeCategPost(id: number) {
      this.record['Параметры.Поставщик'] = -1;
    },
    onChangeParametersFDogUK(id: number) {
      if (+id % 2 === 0) {
        this.record['Параметры.фФильтрПоВидамДоговора'] = 0;
      }
    },
    onChangeDogUK(id: number) {
      if (+id > 0) {
        this.record['Параметры.фФильтрПоВидамДоговора'] = 0;
      }
    },
    onInitDogovorTypeFilter(item: any) {
      this.showDogovorTypeFilter = !!(item.length > 0 && item.length !== undefined);
    },
  },
});
