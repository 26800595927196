
import Vue from 'vue';
import recalcDialog from '../../../$resources/RecalcDialog.vue';
import CycleDialog from '../$dialogs/CycleDialog.vue';
import SumToolbar from '@/tasks/fl/components/SumToolbar.vue';
import { StackSelectionCache } from '@/stackEngine';

export default Vue.extend({
  components: { recalcDialog, CycleDialog, SumToolbar },
  props: {
    dataObject: { type: Object, required: true },
    params: { type: Object, required: true },
    ownerID: { type: Number, required: true },
    record: { type: Object, required: true },
    tableAction: { type: Function, required: true }, // коллбэк в stacktable
    selectedItems: { type: [Object, Array], required: true }, // array
  },
  data() {
    return {
      calculatedNow: false,
      recalcDialogVis: false,
      cycleDialogVis: false,
    };
  },
  methods: {
    async onCalc() {
      const obj = new this.$HttpModel('ЛицевыеСчета');
      if (obj.executeMethod) {
        try {
          this.calculatedNow = true;
          await obj.executeMethod('Рассчитать', { номерЗаписи: this.ownerID });
          StackSelectionCache.clearCacheByModel('ЛицевыеСчета.Расчет');
          this.tableAction({ action: 'reload' });
        } finally {
          this.calculatedNow = false;
        }
      }
    },
  },
});
