import ПлатежныеВедомости from './ПлатежныеВедомости.ПроблемныеПлатежи';
import { TableHandlers } from '@/stackEngine';
const problempays: StackSelection = {
  recordRoute: 'payments-paymentsreceipts-paymentsreceiptsid-paylists-paylistsid-problempays-problempaysid',
  objectName: 'ВыпискиИзБанка.ПлатежныеВедомости.ПроблемныеПлатежи',
  description: '',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.ошибкаплатежа && row.ошибкаплатежа !== '') {
      TableHandlers.trColor(row, 'warning', 'darken-4');
    }
    return row;
  },
  fields: ПлатежныеВедомости.fields,
};
export default problempays;
