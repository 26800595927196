
import Vue from 'vue';
import AbnormalDialog from './abnormalDialog.vue';
import AbnormalPeriodDialog from './abnormalPeriodDialog.vue';
import AbnormalToolbar from './abnormalToolbar.vue';

export default Vue.extend({
  components: { AbnormalDialog, AbnormalToolbar, AbnormalPeriodDialog },
  props: {
    ownerID: { type: [Number, String], required: true },
    height: { type: String, default: undefined },
  },
  data() {
    return {
      inputType: 0 as number,
      hours: 0 as number,
      visiblePeriodDialog: false as boolean,
    };
  },
  methods: {
    async onUpdateTable() {
      const res = await new this.$HttpModel('Счетчики.НештатнаяРабота').executeMethod('получитьКоличество', { владелец: this.ownerID });
      this.hours = res;
    },
  },
});
