
import Vue from 'vue';
import JSON from 'json5';
import ApiDialog from './ApiDialog.vue';

export default Vue.extend({
  components: { ApiDialog },
  props: {
    scheme: { type: [String, Object], required: true },
    params: { type: Object, default: undefined },
    record: { type: Object, default: undefined },
  },
  provide(): any {
    return {
      reportName: this.scheme.Имя,
      noFormat: this.noFormat,
      favorite: !!this.params?.favorite,
      selectionName: this.params?.selectionName,
    };
  },
  computed: {
    fields(): any {
      return this.formScheme.elements;
    },
    passParams(): any {
      const formParams = typeof this.formScheme.params === 'string' ? JSON.parse(this.formScheme.params) : this.formScheme.params;
      return this.formScheme.params ? { ...this.params, ...formParams } : this.params;
    },
    noFormat(): boolean {
      return this.passParams?.noFormat || false;
    },
  },
  data() {
    return {
      rec: {} as object,
      formScheme: {} as any,
      loading: true,
      forceRestorFields: [] as string[],
    };
  },

  async created() {
    let formScheme: any;
    if (typeof this.scheme === 'string') {
      formScheme = await new this.$ReportsBuilder(this.scheme).getDialogScheme();
    } else {
      formScheme = this.scheme;
    }
    if (formScheme) {
      if (formScheme.disabled) {
        this.$toast(formScheme.disabled);
        this.$emit('stack-wrapper:close', false);
        return;
      }
      if (formScheme.elements) {
        formScheme.elements.forEach((elem: any) => {
          // Добавляем все поля с бэка с начальным значением, чтобы работал StackRestorBtn
          if (elem.str_param !== undefined) {
            try {
              elem.params = JSON.parse(elem.str_param);
            } catch {
              console.error('Не корректное значение str_param в RX в элементе ' + elem.Имя);
            }
          }
          if (elem.value) this.forceRestorFields.push(elem.Имя);
          Vue.set(this.rec, elem.Имя, elem.value !== null && elem.value !== undefined ? elem.value : null);
        });
      }
      if (this.record) {
        this.rec = Object.assign({}, this.rec, this.record);
      }
      this.formScheme = formScheme;
    }
    this.loading = false;
  },
});
