const dog = {
  objectName: 'ФЛДоговоры.Контрагенты',
  description: 'Контрагенты',
  fields: [
    {
      field: 'флдоговор-человек',
      text: ' ',
      type: 'Link',
      dataModel: 'ЧастныеЛица',
      labelField: 'фио',
      headers: 'фио',
    },
    {
      field: 'флдоговор-человек>фио',
      text: 'Контрагент',
      type: 'String',
    },
  ],
};

export default dog;
