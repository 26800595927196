const orgs: StackSelection = {
  objectName: 'Организации.Логотип',
  description: '',
  fields: [
    {
      field: 'имя_файла',
      text: 'Имя_файла',
      type: 'String',
    },
  ],
};
export default orgs;
