
import Vue from 'vue';
import commonCounter from '@/tasks/common/views/$common/counters/_counterid.vue';
import AdrFolderDialog from '@/tasks/fl/components/AdrFolderDialog.vue';
import modalParameter from '@/tasks/common/views/references/parameters/$dialogs/parameter.vue';

export default Vue.extend({
  model: { prop: 'record' },
  components: { commonCounter, AdrFolderDialog, modalParameter },
  props: {
    record: { type: Object, required: true },
    dataModel: { type: String, required: true },
    httpModel: { type: Object, required: true },
    linkRecord: { type: Object, required: true },
  },
  data() {
    return {
      title: null as null | string,
      nameDialog: 'def' as string,
    };
  },
  methods: {
    getModalName(row: StackTableRow): string {
      if (!row.ид_объект || row.ид_объект <= 0) {
        this.nameDialog = '-1';
        return '';
      }
      if (row && row.связь) {
        switch (row.связь) {
          case 'Реестр проверок': // никогда не должно быть!!
            this.nameDialog = 'Диалог реестра проверок';
            break;
          case 'Счетчик':
          case 'ОДПУСчетчик':
          case 'ОКПУСчетчик':
            this.nameDialog = 'common-counter';
            break;
          case 'ИерархияЛС':
          case 'ДокументПУ':
          case 'Организации':
            this.nameDialog = '';
            if (this.linkRecord.$номерЗаписи && this.linkRecord.$номерЗаписи > 0) {
              const params = {} as any;
              let name = '';
              switch (row.связь) {
                case 'ИерархияЛС':
                  if (this.linkRecord.тип === 5) { // для лицевого счета немодальный диалог
                    name = 'accounts-accountsid';
                    params.accountsid = this.linkRecord.$номерЗаписи as string;
                  } else { // для всех остальных модальный
                    this.nameDialog = 'adr-folder-dialog';
                  }
                  break;
                case 'ДокументПУ':
                  name = 'metersdata-groupcounters-groupcountersid';
                  params.groupcountersid = this.linkRecord.$номерЗаписи as string;
                  break;
                case 'Организации':
                  name = 'references-organizations-organizationsid';
                  params.organizationsid = this.linkRecord.$номерЗаписи as string;
                  break;
              }
              if (name) this.$router.push({ name, params });
            }
            break;
          case 'ВидПараметр':
            this.nameDialog = 'modal-parameter';
            break;
          default:
            this.nameDialog = '';
        }
      }
      return this.nameDialog;
    },
  },
  watch: {
    nameDialog: {
      handler(to) {
        if (to === '') {
          this.$toast('Диалог не реализован');
        } else if (to === '-1') {
          this.$toast('Номер записи -1');
        }
      },
    },
  },
});
