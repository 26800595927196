
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    title(): string {
      return `Сообщение для квитанции`;
    },
    closed(): boolean {
      if (this.record && this.record.датнач && this.record.датнач < this.$store.getters.getOpenMonth()) {
        return true;
      }
      return false;
    },
    closedDateEnd(): boolean {
      if (this.record && this.record.даткнц && this.record.даткнц < this.$store.getters.getOpenMonth()) {
        return true;
      }
      return false;
    },
  },
  methods: {
    labelFieldLSHandler(record: StackTableRow) {
      return record.тип === 5 ? record.адреслс + ' ' + '№' + record.номер + ' (' + record['счет-наниматель>фио'] + ')' : record.$название;
    },
    labelFieldUKHandler(record: StackTableRow) {
      return '№' + record.номер + ' ' + record.тема + ' ' + record['организация-укдоговор>название'] + ' от ' + this.$stackDate.format(record.датнач as string, 'DD.MM.YYYY');
    },
  },
  watch: {
    record: {
      deep: true,
      handler(to) {
        if (this.record.типадресата !== 1) {
          this.record['лицевой-сообщение'] = -1;
        }
        if (this.record.типадресата !== 2) {
          this.record['ук договор-сообщение'] = -1;
        }
      },
    },
  },
});
