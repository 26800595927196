
import Vue from 'vue';

export default Vue.extend({
  name: 'LawsuitTab',
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
});
