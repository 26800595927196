
import Vue from 'vue';
import datePeriodFilter from '@/tasks/fl/views/$common/filters/datePeriodFilter.vue';
import addressFilter from '@/tasks/fl/views/$common/filters/addressFilter.vue';

export default Vue.extend({
  components: { datePeriodFilter, addressFilter },
  props: {
    accountID: { type: Number, default: null },
    link: { type: Boolean, default: false },
  },
  data() {
    return {
      params: { лицевой: this.accountID },
      item: {
        номер: null,
        группа: null,
        лицевой: null,
        услуга: null,
        ответственный: null,
        контрагент: null,
        датнач: null,
        дата: null,
        даткнц: null,
        здаткнц: null,
        здатнач: null,
        аналитика: -1,
        нп: null,
        улица: null,
        дом: null,
        квартира: null,
        обслуживающая: null,
        закрытые: true,
        подразделение: null,
      } as any,
    };
  },
});
