
import Vue from 'vue';
import IndrateDialog from './_individualRate.vue';
import { TableHandlers } from '@/stackEngine';

/**
 * Поставщик лицевого
 */
export default Vue.extend({
  components: { IndrateDialog },
  props: {
    dataModel: { type: String, required: true },
    headers: { type: String, default: 'вид-тарифы>номер услуги,вид-тарифы>название,значение,знач2,знач3,знач4,датнач,даткнц' },
    ownerID: { type: Number, default: null },
    params: { type: Object, required: true },
  },
  data() {
    return {
      title: 'Индивидуальные тарифы',
      withHier: true,
    };
  },
  computed: {
    btnHierTitle(): string {
      return this.withHier ? 'Без учета иерархии' : 'С учетом иерархии';
    },
  },
  methods: {
    onClickBtnHier() {
      this.withHier = !this.withHier;
    },
    rateHandler(item: any) {
      if (this.ownerID !== item['счет-тарифы']) {
        item.$data['вид-тарифы>номер услуги'].$иконка = '$vuetify.icons.hier';
        item.$data['вид-тарифы>номер услуги'].$иконка_цвет = 'blue-grey';
        TableHandlers.trColor(item, 'blue-grey');
      }
      return item;
    },
  },
});
