import { Module, GetterTree, MutationTree } from 'vuex';
import { store } from '.';

const MAX_CHIP_LENGTH = 5; // макс. кол-во чипсов

export interface ChipItem {
  title: string;
  to: string;
}

interface ChipsState {
  chips: ChipItem[]; // айтемы чипсов
}

const state: ChipsState = {
  chips: [],
};

// Геттеры
const getters: GetterTree<ChipsState, any> = {
  // список чипсов
  getChips: (state: ChipsState) => (): ChipItem[] | null => {
    return store.getters.isAuth() ? state.chips : null;
  },
};

const mutations: MutationTree<ChipsState> = {
  // добавляем chip
  ADD_CHIP(state: ChipsState, item: ChipItem) {
    // если уже много, то удаляем излишки по FIFO
    if (state.chips.length >= MAX_CHIP_LENGTH) {
      state.chips.splice(0, state.chips.length - MAX_CHIP_LENGTH);
    }
    // если chip с таким url уже есть, то просто обновляем по нему инфу
    let foundOne = false;
    const newItem = Object.assign({}, item);
    // Новые записи игнорируем
    if (newItem.to.lastIndexOf('/new') !== -1) {
      return;
    }

    // обрезаем ancors
    const idxUrl = newItem.to.lastIndexOf('/');
    const idxHash = newItem.to.lastIndexOf('#');
    if (idxHash > idxUrl) {
      newItem.to = newItem.to.substring(0, idxHash);
    }

    // ищем повторы
    state.chips.forEach((chip: ChipItem) => {
      if (chip.to === newItem.to) {
        chip.title = newItem.title;
        foundOne = true;
      }
    });
    // ненашли ? добавляем чипсу
    if (!foundOne) {
      state.chips.push(newItem);
    }
  },

  // удаляем chip по номпп
  REMOVE_CHIP(state: ChipsState, i: number) {
    if (state.chips && state.chips.length) {
      state.chips.splice(i, 1);
    }
  },
};

const chipsState: Module<ChipsState, any> = {
  state,
  getters,
  mutations,
};

export default chipsState;
