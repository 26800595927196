
import Vue from 'vue';
import DialogDate from '../$dialogs/НаДату.vue';
import basemethod from './basemethod.vue';

export default Vue.extend({
  extends: basemethod,
  model: {
    prop: 'record',
  },
  components: { DialogDate },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      dataObject: new this.$HttpModel('СостоянияДела'),
      isdialog: false,
      isload: false,
    };
  },
  methods: {
    async changeСourtField(gpload: any) {
      if (gpload >= 0) {
        const rec = this.record;
        await this.dataObject.executeMethod('ПроверкаПолей', { поле: 'Суд', сдела: rec });
      }
    },
    async ChangeDate(item: any) {
      this.isdialog = false;
      let rec = this.record;
      this.isload = true;
      const result = await this.dataObject.executeMethod('ВыполнитьОперациюНаФазе', { сдела: rec, dopparams: item, операция: 'ИзменитьДату' });
      rec = Object.assign(rec, result);
      this.isload = false;
      this.$emit('change', rec);
    },
    async ChangeDate_dialog() {
      const answer = await this.$yesno('Внимание! При изменении даты планируемой подачи иска будет обновлена сумма по основному долгу и пени.');
      if (answer) {
        this.isdialog = true;
      }
    },
  },
});
