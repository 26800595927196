const actsqu: StackSelection = {
  recordRoute: 'documents-correctionsaldostatements-correctionsaldostatementsid-correctionsaldostatementsheaderid',
  objectName: 'ВедомостиКоррекцииСальдо.Заголовок',
  description: 'Коррекция сальдо',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'счет-коррекция',
      text: 'Счет-Коррекция',
      type: 'Link',
    },
    {
      field: 'счет-коррекция>номер',
      text: 'Лицевой',
      type: 'Number',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'расчмесяц',
      text: 'Расчетный месяц',
      type: 'Date',
    },
    {
      field: 'название',
      text: 'название',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'коррекция',
      text: 'коррекция',
      type: 'Link',
    },
    {
      field: 'типведомости',
      text: 'Тип ведомости',
      type: 'Number',
    },
  ],
};
export default actsqu;
