
import Vue from 'vue';

export default Vue.extend({
  props: {
    dataModel: { type: Object, required: true },
    disabled: { type: Boolean, required: true },
    runCreation: { type: Boolean, required: true },
  },
  data() {
    return {
      лицевой: -1,
      дата: this.$store.getters.getCurrentDate(),
      номер: 0,
      oldNumber: 0,
      isSave: false,
    };
  },
  methods: {
    async changeLS(idLS: string) {
      await this.removeLock();
      if (idLS && +idLS > 0) {
        const response = await this.dataModel.executeMethod('инициализацияЛицевого', { id: idLS });
        this.номер = response > 0 ? response : 0;
      } else {
        this.номер = 0;
      }
      this.oldNumber = this.номер;
    },
    async changeNumber(numberLS: number) {
      try {
        const response = await this.dataModel.executeMethod('проверитьНомерЛицевого', { старыйНомер: this.oldNumber, номер: numberLS, id: this.лицевой });
        this.номер = response > 0 ? response : 0;
        this.oldNumber = this.номер;
      } catch {
        this.номер = this.oldNumber;
      }
    },
    async removeLock() {
      if (this.номер > 0) {
        await this.dataModel.executeMethod('снятьБлокировку', { номер: this.номер });
      }
    },
    apply() {
      this.isSave = true;
      this.$emit('save', { лицевой: this.лицевой, номер: this.номер, дата: this.дата });
    },
  },
});
