const positions: StackSelection = {
  objectName: 'Должности',
  description: 'Должности',
  hier: true,
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'разряд',
      text: 'Разряд',
      type: 'Number',
    },
    {
      field: 'ставка',
      text: 'Ставка',
      type: 'Number',
    },
    {
      field: 'признак',
      text: 'Признак',
      type: 'Number',
    },
  ],
};
export default positions;
