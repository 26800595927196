
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
    title: { type: String, default: 'Показание' },
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    if (this.record['@зона'] !== '') this.items = JSON.parse(this.record['@зона']);
  },
  methods: {
    openLS() {
      const lsID = this.record['показания-счет'];
      const routeData = this.$router.resolve({ path: `/accounts/${lsID}` });
      open(routeData.href, '_blank');
    },
  },
});
