const dog = {
  objectName: 'ЧастныеЛица.ДоговорыФЛ',
  description: 'Договоры частного лица',
  fields: [
    {
      field: 'номер',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'статус',
      text: 'Статус',
      type: 'Number',
    },
    {
      field: 'виддоговора',
      text: 'Вид договора',
      type: 'Number',
    },
    {
      field: 'видуслуги',
      text: 'Вид услуги',
      type: 'Number',
    },
    {
      field: 'вариантоплаты',
      text: 'Вариант Оплаты',
      type: 'Number',
    },
    {
      field: 'рассрочкасрок',
      text: 'Рассрочка Срок',
      type: 'Number',
    },
    {
      field: 'предоплатапроцент',
      text: 'Предоплата Процент',
      type: 'Number',
    },
    {
      field: 'датнач',
      text: 'Дата начала',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Дата окончания',
      type: 'Date',
    },
    {
      field: 'регистрация',
      text: 'Регистрация',
      type: 'Date',
    },
    {
      field: 'расторгнут',
      text: 'Расторгнут',
      type: 'Date',
    },
    {
      field: 'отказ',
      text: 'Отказ',
      type: 'Date',
    },
    {
      field: 'вдго-исполнитель',
      text: '',
      type: 'Link',
    },
    {
      field: 'исполнитель',
      text: 'Исполнитель',
      type: 'String',
    },
    {
      field: 'флдоговор-группа>название',
      text: 'Группа',
      type: 'String',
    },
  ],
};

export default dog;
