import { TableHandlers } from '@/stackEngine';
const Agreement: StackSelection = {
  recordRoute: 'documents-agreement-agreementid',
  objectName: 'СоглашенияОРассрочке',
  description: 'Соглашения о рассрочке',
  hier: true,
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    // @ts-ignore
    if (row.признаки % 4 >= 2) {
      // @ts-ignore
      if (row.признаки % 8 >= 4) {
        TableHandlers.stackStyle(row, '*80.170.100');
      }
      // @ts-ignore
      if (row.признаки % 8 < 4) {
        TableHandlers.stackStyle(row, '*200.30.30');
      }
    }
    // @ts-ignore

    return row;
  },
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'адреслс',
      text: 'Адрес',
      type: 'String',
      computed: true,
    },
    {
      field: 'фио',
      text: 'Наниматель',
      type: 'String',
    },
    {
      field: 'лицевой>номер',
      text: 'Лицевой счет',
      type: 'Number',
    },
    {
      field: 'сумма',
      text: 'Сумма',
      type: 'Money',
    },
    {
      field: 'статус',
      text: 'Статус',
      type: 'String',
      computed: true,
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'суммаостатка',
      text: 'Сумма Остатка',
      type: 'Number',
    },
    {
      field: 'cтатус',
      text: 'Статус',
      type: 'Number',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'Number',
    },
    {
      field: 'автор',
      text: 'Автор',
      type: 'Link',
    },
    {
      field: '@срок',
      text: 'Срок',
      type: 'Number',
    },
    {
      field: '@первыйплатеж',
      text: 'Первый платеж',
      type: 'Money',
    },
    {
      field: '@ежемесячныйплатеж',
      text: 'Ежемесячный платеж',
      type: 'Money',
    },
    {
      field: 'стпп',
      text: 'СтПП',
      type: 'Number',
    },
    {
      field: 'сттип',
      text: 'СтТип',
      type: 'Number',
    },
    {
      field: 'отложенныйграфик',
      text: 'ОтложенныйГрафик',
      type: 'Number',
    },
    {
      field: '@делаобработаны',
      text: '@делаОбработаны',
      type: 'Number',
    },
    {
      field: 'списокф',
      text: 'списокф',
      type: 'String',
    },
    {
      field: 'запретитьэлементыокна',
      text: 'ЗапретитьЭлементыОкна',
      type: 'String',
    },
    {
      field: 'разрешитьэлементыокна',
      text: 'РазрешитьЭлементыОкна',
      type: 'String',
    },
    {
      field: 'срокоплаты',
      text: 'Срок',
      type: 'Number',
    },
    {
      field: '@открытыймесяцлс',
      text: 'Открытый месяц лс',
      type: 'DateMonth',
      computed: true,
    },
  ],
};

export default Agreement;
