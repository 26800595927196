
import Vue from 'vue';
import serviceAccordDialog1C from './$dialogs/serviceAccordDialog1C.vue';
import serviceAccordDialogUSZN from './$dialogs/serviceAccordDialogUSZN.vue';
import serviceAccordDialogExchange from './$dialogs/serviceAccordDialogExchange.vue';
import serviceAccordFolder from './$dialogs/serviceAccordFolder.vue';

export default Vue.extend({
  components: { serviceAccordDialog1C, serviceAccordDialogUSZN, serviceAccordDialogExchange, serviceAccordFolder },
});
