const sch: StackSelection = {
  objectName: 'ЛицевыеСчета.Объекты',
  description: 'Приборы учёта',
  fields: [
    {
      field: 'n п/п',
      text: '№ п/п',
      type: 'String',
    },
    {
      field: 'номенклатура-объекты>наименование',
      text: 'Тип счетчика',
      type: 'String',
    },
    {
      field: 'номенклатура-объекты',
      text: 'Номенклатура',
      type: 'Number',
    },
    {
      field: 'объект-услуга>номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'объект-услуга>наименование',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'заводскойномер',
      text: 'Заводской номер',
      type: 'String',
    },
    {
      field: 'коэффициент трансформации',
      text: 'КТР',
      type: 'Number',
    },
    {
      field: 'тарифность',
      text: 'Тарифность',
      type: 'Number',
    },
    {
      field: 'доступ',
      text: 'Доступ',
      type: 'String',
    },
    {
      field: 'разрядность',
      text: 'Разрядность',
      type: 'Number',
    },
    {
      field: 'дробнаяразрядность',
      text: 'Дробная разрядность',
      type: 'Number',
    },
    {
      field: 'датаповерки',
      text: 'Дата поверки',
      type: 'Date',
    },
    {
      field: 'датаследующейповерки',
      text: 'Дата следующей поверки',
      type: 'Date',
    },
    {
      field: 'годвыпуска',
      text: 'Год выпуска',
      type: 'Number',
    },
    {
      field: 'место установки',
      text: 'Место установки',
      type: 'Enum',
    },
    {
      field: 'датнач',
      text: 'Установлен',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Снят',
      type: 'Date',
    },
    {
      field: 'объект-услуга',
      text: 'объект-услуга',
      type: 'Link',
    },
    {
      field: 'состояниестрока',
      text: 'Состояние',
      type: 'String',
    },
    {
      field: 'состояние',
      text: 'Состояние',
      type: 'Number',
    },
    {
      field: 'причинаснятия',
      text: 'Причина снятия',
      type: 'Number',
    },
    {
      field: 'идентификатор',
      text: 'Идентификатор',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'номинмощ',
      text: 'Номинальная мощность',
      type: 'Number',
    },
    {
      field: 'потерикз',
      text: 'Потери КЗ',
      type: 'Number',
    },
    {
      field: 'потерихх',
      text: 'Потери ХХ',
      type: 'Number',
    },
  ],
};
export default sch;
