
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
    title: { type: String, default: 'Норма' },
  },
  data() {
    return {
      items: ['Действует', 'Не действует'],
    };
  },
  computed: {},
  methods: {
    nameUsl(item: StackTableRow) {
      return `${item['номер услуги']} ${item.наименование}`;
    },
  },
});
