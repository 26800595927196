import { TableHandlers } from '@/stackEngine';

const sch: StackSelection = {
  objectName: 'ЛицевыеСчета.ОбъектыЛицевого',
  description: 'Приборы учёта',
  prepareRow: row => {
    TableHandlers.isClosed(row);
    return row;
  },

  fields: [
    {
      field: 'n п/п',
      text: '№ п/п',
      type: 'String',
    },
    {
      field: 'номенклатура>наименование',
      text: 'Тип счетчика',
      type: 'String',
    },
    {
      field: 'услуга>номер услуги',
      text: '№ услуги',
      type: 'Number',
    },
    {
      field: 'услуга>наименование',
      text: 'Название услуги',
      type: 'String',
    },
    {
      field: 'заводскойномер',
      text: 'Заводской номер',
      type: 'String',
    },
    {
      field: 'датаповерки',
      text: 'Дата поверки',
      type: 'Date',
    },
    {
      field: 'датнач',
      text: 'Установлен',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'Снят',
      type: 'Date',
    },
    {
      field: 'тип',
      text: 'Тип',
      type: 'String',
    },
    {
      field: 'датаследующейповерки',
      text: 'Дата поверки',
      type: 'Date',
    },
    {
      field: 'типпу',
      text: 'Тип ПУ',
      type: 'String',
    },
    {
      field: 'услуга',
      text: 'Услуга',
      type: 'Link',
    },
    {
      field: 'счет',
      text: 'Счет',
      type: 'Link',
    },
    {
      field: 'тарифность',
      text: 'Тарифность',
      type: 'Number',
    },
    {
      field: 'дробнаяразрядность',
      text: 'ДробнаяРазрядность',
      type: 'Number',
    },
    {
      field: 'микрогенерация',
      text: 'Микрогенерация',
      type: 'String',
    },
  ],
};
export default sch;
