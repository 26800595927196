
import Vue from 'vue';
import { TableHandlers } from '@/stackEngine';

export default Vue.extend({
  name: 'CallCentrTab',
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
});
