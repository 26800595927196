
import Vue from 'vue';
import FileTable from '@/tasks/common/views/$common/fileTable.vue';

export default Vue.extend({
  components: { FileTable },
  props: {
    record: { type: Object, required: true },
    steps: { type: Object, required: true },
  },
});
