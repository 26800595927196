
import Vue from 'vue';
import LsSelectDialog from './$lsSelectDialog/LsSelectDialog.vue';
import AdrFolderDialog from '@/tasks/fl/components/AdrFolderDialog.vue';

export default Vue.extend({
  components: {
    LsSelectDialog,
    'adr-folder-dialog': AdrFolderDialog,
  },
  props: {
    ownerID: { type: [Number, String], default: null },
    noOwner: { type: Boolean, default: false },
    title: { type: String, default: 'Лицевой счет группового счетчика' },
    typeGC: { type: [Number, String], default: 0 },
    heightTable: { type: String, default: '100%' },
    dataModel: { type: String, default: 'ГрупповыеСчетчики.ЛицевыеСчета' },
    hideType: { type: Boolean, default: false }, // скрывать ли выбор главного счетчика для окпу
  },
  data() {
    return {
      record: {} as StackTableRow[],
      dataObject: new this.$HttpModel('ЛицевыеСчета'),
      showDlg: false,
    };
  },
  methods: {
    async ShowLs(item: any) {
      this.record = await this.dataObject.getRecords({ номерЗаписи: item['показания-счет'] });
      const routeData = this.$router.resolve({ path: `/accounts/${item['показания-счет']}` });
      if (this.record[0].тип === 5) {
        window.open(routeData.href, '_blank');
      } else {
        this.showDlg = true;
      }
    },
    onCloseDlgls() {
      this.showDlg = false;
    },
  },
});
