
import Vue from 'vue';
export default Vue.extend({
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      flags: this.record.значение || 0 as number,
    };
  },
  methods: {
    onChangeDoc(payload: number) {
      const realFlag = 2 ** 1;
      const recalcFlag = 2 ** 6;
      const docs = this.flags;

      this.record.значение = payload;
      // взаимоисключающие флаги реализации и перерасчетов
      if ((payload & recalcFlag) && (payload & realFlag)) {
        if (!(docs & recalcFlag)) {
          this.record.значение = payload - realFlag;
        }
        if (!(docs & realFlag)) {
          this.record.значение = payload - recalcFlag;
        }
      }
      this.flags = this.record.значение;
    },
  },
});
