
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      isChange: false,
      dop: 0,
      osn: '',
      typeuslug: ['Исключить долги по услугам', 'Учитывать долги только по услугам'],
      header: [
        {
          field: 'номер услуги',
          text: 'Номер',
          type: 'Link',
          dataModel: 'Услуги',
          keyField: 'номер услуги',
          headers: 'наименование,номер услуги',
          labelField: (record: any) => {
            return '№' + record['номер услуги'] + ' ' + record.название;
          },
          hier: true,
        },
      ],
    };
  },
  created() {
    this.dop = Number(this.record.допзначение);
  },

  computed: {
    dataTable(): StackTableRow[] {
      return this.record && this.record.значение ? JSON.parse(this.record.значение) : [];
    },
  },
  methods: {
    changeFunc(items: StackTableRow[]) {
      // в первый раз просто загрузка записи
      if (this.isChange) {
        this.osn = '';
        for (const objItem of items) {
          if (objItem['номер услуги'] !== null && objItem['номер услуги'] !== undefined) {
            this.osn += ';' + objItem['номер услуги'];
          }
        }
        this.$set(this.record, 'оснзначение', this.dop + this.osn);
      }
      this.isChange = true;
    },
    changeFuncDop(items: StackTableRow[]) {
      this.$set(this.record, 'оснзначение', items + this.osn);
    },
  },
});
