
import Vue from 'vue';
import monthPeriodFilter from './monthPeriodFilter.vue';

export default Vue.extend({
  components: { monthPeriodFilter },
  props: {
    showRecalcMonth: { type: Boolean, default: false },
    tableAction: { type: Function, required: true }, // коллбек к таблице
  },
  data() {
    return {
      item: {
        месяцс: null,
        месяцпо: null,
        услуга: null,
        организация: null,
        номердог: null,
        ставка: null,
        делитель: null,
        перерасчет: null,
      } as any,
    };
  },
});
