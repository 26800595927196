
import Vue from 'vue';

export default Vue.extend({
  props: {
    record: { type: Object, required: true },
    disabled: { type: Boolean, required: true },
  },
  computed: {
    paramTypeOld(): object {
      switch (this.record.делСальдо) {
        case 1:
          return { месяц: this.month, тип: this.record.делСальдо, коэфПлощадь: this.record.коэфПлощадь, датаР: this.record.дата, старыйЛс: 1 };
        case 2:
          return { месяц: this.month, тип: this.record.делСальдо, коэфДоля: this.record.коэфДоля, датаР: this.record.дата, старыйЛс: 1 };
        default:
          return { месяц: this.month };
      }
    },
    paramTypeNew(): object {
      switch (this.record.делСальдо) {
        case 1:
          return { месяц: this.month, тип: this.record.делСальдо, коэфПлощадь: this.record.коэфПлощадь, датаР: this.record.дата, старыйЛс: 0 };
        case 2:
          return { месяц: this.month, тип: this.record.делСальдо, коэфДоля: this.record.коэфДоля, датаР: this.record.дата, старыйЛс: 0 };
        default:
          return { месяц: this.month };
      }
    },
    showSaldoOldLs(): boolean {
      return !this.disabled && this.record.делСальдо !== 3;
    },
    showSaldoNewLs(): boolean {
      return (
        !this.disabled &&
        this.record.делСальдо !== 0 &&
        (this.record.делСальдо !== 1 || this.record.коэфПлощадь !== 1) &&
        (this.record.делСальдо !== 2 || this.record.коэфДоля !== 1) &&
        this.record.делСальдо !== 3
      );
    },
  },
  data() {
    return {
      month: this.$store.getters.getOpenMonth(),
    };
  },
});
