const tars: StackSelection = {
  objectName: 'ЛицевыеСчета.ИндТарифы',
  description: '',
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row['@картинка']) {
      // @ts-ignore
      row.$data['вид-тарифы>номер услуги'] = { $иконка: '$vuetify.icons.calculator' };
    }
    return row;
  },
  fields: [
    {
      field: '@картинка',
      text: 'Калькуляция',
      type: 'String',
      width: '250px',
      computed: true,
    },
    {
      field: 'вид-тарифы',
      text: 'Услуга 2',
      type: 'Link',
      width: '250px',
    },
    {
      field: 'вид-тарифы>название',
      text: 'Услуга',
      type: 'String',
      width: '250px',
    },
    {
      field: 'вид-тарифы>номер услуги',
      text: 'Номер услуги',
      type: 'Number',
      // width: '250px',
    },
    {
      field: 'счет-тарифы',
      text: 'Фонд',
      type: 'Link',
      dataModel: 'ЛицевыеСчета',
      hier: true,
      labelField: 'адреслс',
      headers: 'адреслс,номер,счет-наниматель>фио,примечание',
      width: '250px',
    },
    {
      field: 'расчадрес',
      text: 'Адрес',
      type: 'Number',
      align: 'left',
    },
    {
      field: 'значение',
      text: 'Тариф',
      type: 'Number',
      align: 'left',
    },
    {
      field: 'знач2',
      text: 'Тариф2',
      type: 'Number',
      align: 'left',
    },
    {
      field: 'знач3',
      text: 'Тариф3',
      type: 'Number',
      align: 'left',
    },
    {
      field: 'знач4',
      text: 'Тариф4',
      type: 'Number',
      align: 'left',
    },
    {
      field: 'датнач',
      text: 'Начало действия',
      type: 'Date',
      align: 'center',
    },
    {
      field: 'даткнц',
      text: 'Окончание',
      type: 'Date',
      align: 'center',
    },
  ],
};

export default tars;
