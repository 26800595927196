const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.ЕдиницыПотребленияРаспределителей',
  description: 'Единицы потребления',
  fields: [
    {
      field: 'расчетный месяц',
      text: 'За месяц',
      type: 'DateMonth',
    },
    {
      field: 'коэффициент',
      text: 'Единицы потребления тепловой энергии',
      type: 'Number',
    },
  ],
};
export default accs;
