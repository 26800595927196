
import Vue from 'vue';
import { getComponents } from '@/stackEngine';

const componentsDefs = require.context('./$dialogs', false, /\.vue$/);
const constDlg = getComponents(componentsDefs);

export default Vue.extend({
  components: Object.assign(constDlg),
  props: {
    dialogName: { type: [String], default: 'default' },
  },
  computed: {
    workMonth(): Date {
      return this.$store.getters.getWorkMonth();
    },
  },
  data() {
    return {
      item: {} as StackTableRow,
      dataModel: new this.$HttpModel('ОперацииСлужебные'),
      isDialog: false,
      records: [] as StackTableRow[],
      otheropers: [
        { title: 'Проверка целостности данных', to: { name: 'operations-checkdata' } },
        { title: 'Списание дебетового сальдо по пени', to: { name: 'operations-writeoffpeni' } },
      ],
      itemsReport: [] as any,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const result = await this.dataModel.getRecords();
      const groupTitle = '';
      for (const res of result) {
        const item = { ...res };
        let children;
        if (res.тип === 'группа') {
          children = [];
          item.icon = '$folder';
          const childrenBuf = item.список && JSON.parse(item.список.toString());
          for (const ind_child in childrenBuf) {
            children.push({ title: childrenBuf[ind_child].TITLE, dialog: childrenBuf[ind_child].DIALOG, resource: item.title });
          }
        }
        // @ts-ignore
        this.records.push({ ...item, children });
      }
    },
    getDialogName(row: StackTableRow): string {
      if (row) {
        if (row.dialog) {
          const name = (row.dialog as string).toUpperCase();
          if (this.$options.components && name in this.$options.components) {
            return name as string;
          }
        }
        return 'DEFAULT';
      }
      return '';
    },
    async openList(item: StackTableRow) {
      if (item?.children) {
        this.itemsReport = item.children;
      } else {
        this.isDialog = false;
        const record = await this.dataModel.initRecord(item);
        const tempRecord = {} as StackTableRow;
        for (const ind in record) {
          if (record[ind] && !ind.startsWith('$')) {
            tempRecord[ind] = record[ind];
          }
        }
        this.item = Object.assign({}, item, tempRecord);
        this.isDialog = true;
      }
    },
    run(item: StackTableRow) {
      this.isDialog = false;
      const заголовок = (this.item.title ? this.item.title : '') as string;
      this.dataModel.executeMethod('Выполнить', Object.assign(this.item, item), {
        async: true,
        jobName: заголовок,
        type: 'func',
      });
    },
  },
});
