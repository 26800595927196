
import Vue from 'vue';

export default Vue.extend({
  props: {
    disabled: { type: Boolean, required: true },
    operation: { type: String, default: '' },
    dataModel: { type: Object, required: true },
    fund: { type: String, default: null },
    accounts: { type: String, default: null },
    month: { type: [String, Date], default: null },
  },
  computed: {
    tittle(): string {
      let text = 'Расчет произведен! Можно сверять отчеты';
      switch (this.operation) {
        case 'Блокировка месяца':
          text = 'Блокировка произведена! Можно сверять отчеты';
          break;
        case 'Закрытие месяца':
          text = 'Закрытие произведено! Можно сверять отчеты';
          break;
      }
      return text;
    },
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
  },
  data() {
    return {
      jobID: null as null | number,
    };
  },
  methods: {
    async end() {
      const params = { фонд: this.fund, месяц: this.month, лицевые: this.accounts, событие: this.operation };
      this.jobID = (await this.dataModel.executeMethod('снятьБлокировкуРесурса', params, { async: true })).asyncId;
      this.$store.commit('MSG_ADD', { title: 'снятьБлокировкуРесурса', asyncId: this.jobID, external: true, silent: true });
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.$store.commit('MSG_DELETE', this.jobID);
        this.jobID = null;
        this.$emit('end', 'success');
      }
    },
    isError(to) {
      if (to) {
        this.$store.commit('MSG_DELETE', this.jobID);
        this.jobID = null;
        this.$emit('end', 'error');
      }
    },
  },
});
