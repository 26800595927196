
import Vue from 'vue';
import basemethod from './basemethod.vue';
import ReadingInputDialog from '@/tasks/fl/views/$common/$readingdialog/ReadingInputDialog.vue';
import ServicesactsAddDialog from '../$lawsuits/servicesactsAddDialog.vue';

export default Vue.extend({
  extends: basemethod,
  components: { 'ReadingInput-Dialog': ReadingInputDialog, 'ServicesactsAdd-dialog': ServicesactsAddDialog },
  model: {
    prop: 'record',
  },
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      actvisible: true,
      readinginput: false,
      loading: false,
    };
  },
  computed: {
    aktotkl(): boolean {
      // return !!(this && this.record && this.record.$блокинит && this.record['@операции'].indexOf('АКТОТКЛЮЧЕНИЯ') !== -1);
      // Пока блокируем создание акта для этой фазы, настройка под клиента
      return false;
    },
  },
  methods: {
    async setact(data: any) {
      this.loading = true;
      await new this.$HttpModel('ОперацииЕдиничногоПерехода').executeMethod('ВыполнитьОперацию', {
        состояние: this.record.$номерЗаписи,
        длг: data,
        тип: 'АктОтключения',
      });
      this.loading = false;
      this.actvisible = false;
      this.$emit('update');
    },
  },
});
