
import Vue from 'vue';
export default Vue.extend({
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    title(): string {
      return `Неизвестный тип диалога: Тип=${this.record?.тип}`;
    },
  },
});
