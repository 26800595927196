
import Vue from 'vue';
import { TableHandlers } from '@/stackEngine';
import CountersToolbar from './CountersToolbar.vue';
import commonCounter from '@/tasks/common/views/$common/counters/_counterid.vue';
import transToolbar from './$components/TransToolbar.vue';
import commonTrans from '@/tasks/common/views/$common/counters/_transid.vue';

export default Vue.extend({
  components: { CountersToolbar, commonCounter, transToolbar, commonTrans },
  name: 'CountersTab',
  model: { prop: 'record', event: 'input' },
  props: {
    record: { type: Object, required: true },
  },
  computed: {
    showHistoryByDefault(): boolean {
      return this.$store.getters['flStore/getShowHistory']();
    },
    showTransformer(): boolean {
      return this.$store.getters['flStore/getLossesEl']();
    },
  },
  methods: {
    paramIsClosed(item: any) {
      TableHandlers.isClosed(item);
      return item;
    },
    fetchData() {
      // @ts-ignore
      this.$refs.table.fetchData();
    },
  },
});
