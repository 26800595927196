const serv: StackSelection = {
  recordRoute: '',
  objectName: 'Услуги.Нормы',
  description: 'Нормы',
  fields: [
    {
      field: 'датнач',
      text: 'Действие с',
      type: 'Date',
    },
    {
      field: 'значение',
      text: 'Основная',
      type: 'NumberZero',
    },
    {
      field: 'знач1',
      text: 'СОИ',
      type: 'NumberZero',
    },
    {
      field: 'знач2',
      text: 'Норма 2',
      type: 'NumberZero',
    },
    {
      field: 'знач3',
      text: 'Инд.повыш.норматив',
      type: 'NumberZero',
    },
    {
      field: 'ключевая фраза',
      text: 'Правило операции',
      type: 'String',
    },
    {
      field: 'правило-нормы',
      text: 'Правило-Нормы',
      type: 'Link',
    },
    {
      field: 'нормасч1',
      text: 'Норма',
      type: 'NumberZero',
    },
    {
      field: 'нормасч2',
      text: 'Норма',
      type: 'NumberZero',
    },
    {
      field: 'нормасч3',
      text: 'Норма',
      type: 'NumberZero',
    },
    {
      field: 'нормалг1',
      text: 'Норма',
      type: 'Number',
    },
    {
      field: 'нормалг2',
      text: 'Норма',
      type: 'Number',
    },
    {
      field: 'нормалг3',
      text: 'Норма',
      type: 'Number',
    },
    {
      field: 'нормасуб1',
      text: 'Норма',
      type: 'Number',
    },
    {
      field: 'нормасуб2',
      text: 'Норма',
      type: 'Number',
    },
    {
      field: 'нормасуб3',
      text: 'Норма',
      type: 'Number',
    },
  ],
};

export default serv;
