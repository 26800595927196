
import Vue from 'vue';

export default Vue.extend({
  props: {
    ownerID: { type: Number, required: true },
  },
  data() {
    return {
      data: [] as any[],
      data_copy: [] as any[],
      debtdetailModel: null as HttpModel | null,
      update: true,
      modelControl: null as any,
      headersDebt: [
        {
          field: 'месяц долга',
          text: 'Дата',
          type: 'Date',
          width: '75px',
          readonly: true,
        },
        {
          field: 'долг-документ',
          text: 'Долг-Документ',
          type: 'Number',
          readonly: true,
        },
        {
          field: 'наименование',
          text: 'Наименование',
          type: 'String',
          width: '225px',
          readonly: true,
        },
        {
          field: 'входящийдолг',
          text: 'Уточненная сумма',
          type: 'Money',
          width: '75px',
        },
        {
          field: 'оплата',
          text: 'Оплачено',
          type: 'Money',
          width: '75px',
          readonly: true,
        },
        {
          field: 'исходящийдолг',
          text: 'Неоплаченнная сумма',
          type: 'Money',
          width: '75px',
          readonly: true,
        },
        {
          field: 'отклонено',
          text: 'Отклонено',
          type: 'Money',
          width: '75px',
          readonly: true,
        },
        {
          field: 'row_id',
          text: 'row_id',
          type: 'Number',
          readonly: true,
        },
      ] as StackField[],
    };
  },
  async created() {
    this.debtdetailModel = await new this.$HttpModel('ВзысканиеЗадолженностиДетализацияЮЛ');
    const results = await this.debtdetailModel.getRecords({ владелец: this.ownerID, уточнение: 1 });
    this.modelControl = new this.$StaticModel(results, {
      rowTemplate: this.headersDebt,
      description: this.debtdetailModel.description,
      prepareRow: this.checkRow,
    });
  },
  methods: {
    getTotals(data: StackTableRow[]) {
      this.data = Object.assign({}, data);
      for (const row in data) {
        const index = (data[row].$номерЗаписи ? data[row].$номерЗаписи : 0) as number;
        this.data_copy[index] = data[row].входящийдолг;
      }
    },
    checkRow(data: StackTableRow) {
      const index = (data.$номерЗаписи ? data.$номерЗаписи : 0) as number;
      const входящийдолг = Number(data.входящийдолг) ? Number(data.входящийдолг) : 0;
      const исходящийдолг = Number(data.исходящийдолг) ? Number(data.исходящийдолг) : 0;
      const вхдолг = Number(this.data_copy[index]) ? Number(this.data_copy[index]) : входящийдолг;
      let отклонено = Number(data.отклонено) ? Number(data.отклонено) : 0;
      if (вхдолг !== входящийдолг) {
        отклонено += вхдолг - входящийдолг;
        if (отклонено < 0) {
          отклонено -= вхдолг - входящийдолг;
          data.входящийдолг = вхдолг.toFixed(2);
        }
        data.исходящийдолг = Math.max(исходящийдолг - отклонено, 0);
        data.отклонено = отклонено.toFixed(2);
      }
    },
  },
});
