const obj: StackSelection = {
  objectName: 'ЛицевыеСчета.Сети',
  description: 'Сети',
  fields: [
    {
      field: 'типсети',
      text: 'Тип сети',
      type: 'Number',
    },
    {
      field: 'выборка',
      text: 'Сеть',
      type: 'String',
      readonly: true,
      width: '200px',
    },
    {
      field: 'путь',
      text: 'Путь',
      type: 'String',
    },
    {
      field: 'соединение',
      text: 'Соединение',
      type: 'Number',
    },
    {
      field: 'элемент',
      text: 'Элемент',
      type: 'Number',
    },
    {
      field: 'связь',
      text: 'Связь',
      type: 'Number',
    },
  ],
};
export default obj;
