const minp: StackSelection = {
  objectName: 'МассовыйВвод.Поставщики',
  description: 'Поставщики для простановки данных',
  fields: [
    {
      field: 'поставщики-список',
      text: 'поставщики-список',
      type: 'Number',
    },
    {
      field: 'услуги-список поставщиков',
      text: 'услуги-список поставщиков',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Поставщик',
      type: 'String',
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
    {
      field: 'номер услуги',
      text: 'Номер услуги',
      type: 'Number',
    },
    {
      field: 'датнач',
      text: 'ДатНач',
      type: 'Date',
    },
    {
      field: 'даткнц',
      text: 'ДатКнц',
      type: 'Date',
    },
  ],
};

export default minp;
