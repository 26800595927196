
import Vue from 'vue';
import fund from './$components/fund.vue';
import checkBase from './$components/checkBase.vue';

export default Vue.extend({
  components: { fund, checkBase },
  data() {
    return {
      statusPrepare: '',
      месяц: this.$store.getters.getOpenMonth(),
    };
  },
  methods: {
    runPrepare() {
      this.statusPrepare = '';
    },
    endPrepare(to: string) {
      this.statusPrepare = to;
    },
  },
});
