
import Vue from 'vue';

export default Vue.extend({
  props: {
    record: { type: Object, required: true },
  },
  data() {
    return {
      items: [] as StackTableRow[],
      header: [
        {
          field: 'нпп',
          text: 'Номер п/п',
          type: 'Number',
          readonly: true,
        },
        {
          field: 'значение',
          text: 'Наименование',
          type: 'String',
        },
      ],
      maxIndexNumber: 0,
      resource: '',
      isChange: false,
    };
  },
  methods: {
    getRecords() {
      this.isChange = false;
      this.maxIndexNumber = 0;
      this.items = this.record?.значение ? JSON.parse(this.record.значение).filter((row: StackTableRow) => row.ресурс === this.resource || row.ресурс === undefined) : [];
    },
    rowhandler(item: StackTableRow) {
      // Если новая запись, то нпп не объявлен - и возьмём его из переменной, так как поле не доступно на редактирование
      item.нпп = item.нпп || +this.maxIndexNumber + 1;
      // значение - чтобы не летело как пустое
      item.значение = item.значение || '';
      item.ресурс = this.resource;
      return item;
    },
    changeTable(items: StackTableRow[]) {
      // в первый раз просто загрузка записи
      if (this.isChange) {
        // облегчаем объект (убираем лишние данные вроде $data и тп)
        const arrayTemp = [];
        for (const item of items) {
          if (item && item.$номерЗаписи !== 'new') {
            let objTemp = {};
            for (const ind in item) {
              if (ind.indexOf('$') < 0 || ind === '$толькоЧтение') {
                objTemp = Object.assign({}, objTemp, { [ind]: item[ind] });
              }
            }
            arrayTemp.push(objTemp);
          } else {
            this.rowhandler(item);
          }
        }
        const data = this.record?.значение ? JSON.parse(this.record.значение).filter((row: StackTableRow) => row.ресурс !== this.resource) : [];

        this.$set(this.record, 'значение', JSON.stringify([...data, ...arrayTemp]));
      }
      this.isChange = true;

      items.forEach((row: StackTableRow) => {
        const curIndexNumber = row.нпп ? +row.нпп : 0;
        this.maxIndexNumber = Math.max(+this.maxIndexNumber, curIndexNumber);
      });
    },
  },
  created() {
    this.getRecords();
  },
});
