
import Vue from 'vue';
import { ReportsBuilder } from '../../middleware';

export default Vue.extend({
  props: {
    dataModel: { type: [String], required: true },
    testId: { type: String, default: '' },
    favorite: { type: Boolean, default: false },
  },
  data() {
    return {
      itemsReport: [] as StackReportSelections[],
      recordsReport: [] as StackReportSelections[],
    };
  },
  computed: {
    task(): string {
      return this.$store.getters.getCurrentTask();
    },
    favoriteReportNode(): any {
      const node = JSON.parse(JSON.stringify(this.$store.getters.getFavoriteReportNode()));
      node.icon = '$vuetify.icons.report_more';
      node.isGroup = true;
      node.children = this.setFrontEntries(node.children);
      return node;
    },
    cRecordsReport(): any {
      if (this.favoriteReportNode && this.favorite) return [this.favoriteReportNode, ...this.recordsReport];
      return this.recordsReport;
    },
  },
  created() {
    this.reportsList();
  },

  methods: {
    searchDialog(item: any) {
      // Некоторые отчеты имеют после своего имени кучу пробелов, эти проверки не пройдут, если их не обрезать.
      const name = item.name.trim();
      this.buildReport(name);
    },
    // набор главного меню (левая часть)
    async reportsList() {
      this.recordsReport = await new ReportsBuilder(this.dataModel).getReportMenu();

      this.recordsReport = this.setFrontEntries(this.recordsReport);
    },

    setFrontEntries(reports: any) {
      for (const ind in reports) {
        reports[ind].title = reports[ind].title || reports[ind].name.replace('@', '');
        reports[ind].icon = reports[ind].children ? '$folder' : '$report';
        if (reports[ind].children) {
          reports[ind].children = this.setFrontEntries(reports[ind].children);
        }
      }
      return reports;
    },
    // кидаем список отчетов в массив для отображения справа
    async openList(item: StackReportSelections) {
      if (item?.children) {
        this.itemsReport = item.children;
      } else {
        this.searchDialog(item);
      }
    },

    // запуск построителя отчета
    buildReport(name: string) {
      new ReportsBuilder(name).executeReport2(name, {}, { title: name, params: { favorite: this.favorite } });
    },
  },
});
