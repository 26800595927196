
import Vue from 'vue';

export default Vue.extend({
  props: {
    selectBox: { type: Number, default: -1 },
    nameBox: { type: String, default: '' },
  },
  data() {
    return {
      item: {
        новые: 0,
        датнач: '',
        даткнц: '',
        времянач: '00:00',
        времякнц: '23:59',
        ид_папка: this.selectBox,
      } as any,
      filtermail: 1 as number,
      newEmails: [],
      dataModel: new this.$HttpModel('ПочтовыйКлиент'),
      mailDataModel: new this.$HttpModel('ПочтовыйКлиент.НоваяПочта'),
      disableFilter: false,
      jobID: null,
    };
  },
  created() {
    this.fillIn(this.filtermail);
  },
  computed: {
    isContactcenter(): boolean {
      return this.$store.getters.getCurrentTask() === 'contactcenter';
    },
    resultJob(): StackStateMsg {
      const result = this.$store.getters.getMsgActionByID(this.jobID);
      return result || {};
    },
    isComplete(): boolean {
      return this.resultJob.complete ? this.resultJob.complete : false;
    },
    isError(): boolean {
      return this.resultJob.error ? this.resultJob.error : false;
    },
  },
  methods: {
    async fillIn(onlyNew: number) {
      this.disableFilter = true;
      if (this.item.новые !== onlyNew) {
        this.item.новые = onlyNew;
        await this.mailDataModel.executeMethod('ОчиститьВыборку');
        this.jobID = (await this.mailDataModel.executeMethod('ЗаполнитьВыборку', Object.assign({ ид_папка: this.selectBox }, this.item), { async: true, jobName: 'Получение данных...' })).asyncId;
      } else {
        this.disableFilter = false;
      }
      // @ts-ignore
      this.$refs.table.fetchData();
      // this.item.новые = onlyNew;
    },
    async onUpdateMail(updated: any) {
      if (this.isContactcenter) {
        this.newEmails = updated;
      }
    },
    async onSelectEmails(selected: any) {
      if (!this.isContactcenter) {
        this.newEmails = selected;
      }
    },

    async onSave() {
      await this.dataModel.executeMethod('ПолучитьПисьма', { новые: this.newEmails, ид_папка: this.selectBox }, { async: true, jobName: 'Загружаем новую почту' });
      this.onClose();
    },
    onClose() {
      this.mailDataModel.executeMethod('ОчиститьВыборку');
      this.$emit('close');
    },
  },
  watch: {
    isComplete(to) {
      if (to) {
        this.disableFilter = false;
        this.jobID = null;
        // @ts-ignore
        this.$refs.table.fetchData();
      }
    },
    isError(to) {
      if (to) {
        this.disableFilter = false;
      }
    },
  },
});
