import { TableHandlers } from '@/stackEngine';

const doguk: StackSelection = {
  objectName: 'ДоговорыУКДляВыбора',
  description: 'Договоры',
  prepareRow: row => {
    if (!row.$этоПапка && row.флагестьдома === 0) {
      TableHandlers.trColor(row, 'grey');
    }
    return row;
  },
  fields: [
    {
      field: 'датнач',
      text: 'С',
      type: 'Date',
    },
    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'тема',
      text: 'Тема',
      type: 'String',
    },
    {
      field: 'полный номер',
      text: 'Полный номер',
      type: 'String',
    },
    {
      field: 'даткнц',
      text: 'По',
      type: 'Date',
    },
    {
      field: 'организация-укдоговор>название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'организация-укдоговор>наименование',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'организация-укдоговор',
      text: 'организация-укдоговор',
      type: 'Link',
    },
    {
      field: 'минпроцент',
      text: 'МинПроцент',
      type: 'Money',
    },
    {
      field: 'минсумма',
      text: 'МинСумма',
      type: 'Money',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
    {
      field: 'вид договора',
      text: 'Вид договора',
      type: 'Number',
    },
    {
      field: 'макссумма',
      text: 'МаксСумма',
      type: 'Money',
    },
    {
      field: 'срок',
      text: 'Срок',
      type: 'Number',
    },
    {
      field: 'распределениепереплаты',
      text: 'РаспределениеПереплаты',
      type: 'Number',
    },
    {
      field: 'объединениеперерасчетов',
      text: 'ОбъединениеПерерасчетов',
      type: 'Number',
    },
    {
      field: 'запретперерасчета',
      text: 'ЗапретПерерасчета',
      type: 'String',
    },
    {
      field: 'флагестьдома',
      text: 'флагЕстьДома',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'Дата заключения',
      type: 'Date',
    },
  ],
};
export default doguk;
