
import Vue from 'vue';
import documentFolder from '@/tasks/common/views/$common/documentFolder.vue';
import PaymentsReceiptsFilter from '@/tasks/fl/views/$common/filters/PaymentsReceiptsFilter.vue';

export default Vue.extend({
  name: 'Paymentsreceipt',
  components: { documentFolder, PaymentsReceiptsFilter },
  methods: {
    async clickBtn(row: StackTableRow, action: string) {
      await new this.$HttpModel('ВыпискиИзБанка').executeMethod('Блокировать', { ид: row.$номерЗаписи });
      // @ts-ignore
      this.$refs.table.fetchData();
    },
  },
});
