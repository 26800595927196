const acts: StackSelection = {
  objectName: 'РеестрМногопроцессныхОпераций',
  description: 'Реестр многопроцессных операций',
  hier: true,
  prepareRow: row => {
    if (!row.$data) {
      row.$data = {};
    }
    if (row.$этоПапка) {
      // @ts-ignore
      row.$data.название = { $иконка: '$vuetify.icons.folder' };
    }
    return row;
  },
  fields: [
    {
      field: 'название',
      text: 'Название',
      type: 'String',
    },
    {
      field: 'старт',
      text: 'Старт',
      type: 'String',
    },
    {
      field: 'продолжительность',
      text: 'Продолжительность',
      type: 'String',
    },
    {
      field: 'статус',
      text: 'Статус число',
      type: 'String',
    },
    {
      field: 'апи_статус',
      text: 'Статус',
      type: 'String',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
  ],
};
export default acts;
