export const DEFAULT_OPTIONS = {
  useKeyboardNavigation: true,
  startTimeout: 0,
  labels: {
    buttonSkip: 'Закрыть',
    buttonPrevious: 'Назад',
    buttonNext: 'Вперед',
    buttonStop: 'Конец',
  },
};

export const DEFAULT_STEP_OPTIONS = {
  placement: 'bottom',
  enableScrolling: true,
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [5, 5],
      },
    },
  ],
};

export const KEYS = {
  ARROW_RIGHT: 39,
  ARROW_LEFT: 37,
  ESCAPE: 27,
};
