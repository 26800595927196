
import Vue, { PropType } from 'vue';
import Moveable from '../mixins/moveable';
import Focusable from '../mixins/focusable';

/**
 * Модальный диалог для активностей, вызовов функций и обработки чего-то
 */
export default Vue.extend({
  name: 'StackActionDialog',
  mixins: [Moveable, Focusable],
  model: { prop: 'record', event: 'change' },
  provide(): any {
    return {
      // @ts-ignore
      isDialogReadonly: () => this.readonly,
    };
  },

  props: {
    /**
     * заголовок диалога
     */
    title: { type: String, default: '' },
    /**
     * подзаголовок диалога
     */
    subtitle: { type: String, default: null },
    /**
     * запись диалога
     */
    record: { type: Object as PropType<StackTableRow>, default: undefined },
    loading: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    isReady: { type: Boolean, default: true },
    error: { type: Boolean, default: false },
    saveBtnTitle: { type: String, default: 'Сохранить' },
    cancelBtnTitle: { type: String, default: 'Отмена' },
    maxWidth: { type: [String, Number], default: '1000px' },
    width: { type: [String, Number], default: '100%' },
    maxHeight: { type: [String, Number], default: undefined },
    height: { type: [String, Number], default: undefined },
    noButtons: { type: Boolean, default: false },
    noClose: { type: Boolean, default: false },
    noSave: { type: Boolean, default: false },
    noCancel: { type: Boolean, default: false },
    testId: { type: String, default: undefined },
    noToolbar: { type: Boolean, default: false },
  },
  data() {
    return {
      // oldRecord: {} as any,
      valid: false,
      diag: false,
    };
  },
  methods: {
    customEmit(event: string, payload?: any) {
      // TODO
      if (this.$attrs['slot-emit']) {
        // @ts-ignore
        this.$attrs['slot-emit'](event, payload);
      } else {
        this.$emit(event, payload);
      }
    },
    onClose() {
      if (this.loading) {
        return;
      }
      // this.customEmit('change', this.oldRecord);
      this.customEmit('close');
    },
    onCancel() {
      this.customEmit('cancel');
      this.customEmit('close');
    },
    onSave() {
      setTimeout(() => {
        // @ts-ignore
        const isValid = !this.$refs.form || this.$refs.form.validate();
        if (isValid && !this.error && !this.loading && !this.noSave && !this.readonly) {
          this.customEmit('save');
        }
      }, 0);
    },
  },
  mounted() {
    this.diag = true;
  },
});
