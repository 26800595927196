import { TableHandlers } from '@/stackEngine';
const cmonth: StackSelection = {
  objectName: 'ЗакрытиеМесяца.Реестр',
  description: 'Лицевые для операции',
  hier: true,
  prepareRow: item => {
    if (item && item.результат && item.результат.toString().indexOf('повторяющийся фонд') >= 0) {
      TableHandlers.trColor(item, 'error');
    }
    item.вадмрежиме = item && item.адрес && item.адрес.toString().indexOf('(в адм. режиме)') >= 0;
    if (item.вадмрежиме) {
      TableHandlers.trColor(item, 'warning');
    }
    return item;
  },
  fields: [
    {
      field: 'последнеедействие',
      text: 'ПоследнееДействие',
      type: 'String',
    },
    {
      field: 'заблокирован',
      text: 'Заблокирован',
      type: 'String',
    },
    {
      field: 'закрыт',
      text: 'Закрыт',
      type: 'String',
    },
    {
      field: 'результат',
      text: 'Результат',
      type: 'String',
    },
    {
      field: 'флагузла',
      text: 'ФлагУзла',
      type: 'Number',
    },
    {
      field: 'наименование',
      text: 'Наименование',
      type: 'String',
    },
    {
      field: 'комментарий',
      text: 'Комментарий',
      type: 'String',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'реестр-счет',
      text: 'Реестр-Счет',
      type: 'Link',
    },
    {
      field: 'вадмрежиме',
      text: 'В административном режиме',
      computed: true,
      type: 'Boolean',
    },
  ],
};

export default cmonth;
