const accs: StackSelection = {
  objectName: 'ЛицевыеСчета.СторонниеПоказания',
  description: 'Сторонние показания',
  fields: [
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'название',
      text: 'Название\nуслуги',
      type: 'String',
    },
    {
      field: '@тариф',
      text: 'Тариф',
      type: 'String',
      computed: true,
    },
    {
      field: 'номерведомости',
      text: 'Номер',
      type: 'String',
    },
    {
      field: 'датаведомости',
      text: 'Дата ведомости',
      type: 'Date',
    },
    {
      field: 'показание',
      text: 'Показание',
      type: 'NumberZero',
    },
    {
      field: 'адреслс файл',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'контроль-показание>расход',
      text: 'Расход',
      type: 'Number',
    },
    {
      field: 'контроль-документ',
      text: 'Ведомость',
      type: 'Link',
    },
  ],
};
export default accs;
