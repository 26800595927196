
import Vue from 'vue';
import credits from '../../../themes/credits_stack';

export default Vue.extend({
  data() {
    return {
      credits,
    };
  },
});
