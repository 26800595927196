
import Vue from 'vue';

export default Vue.extend({
  props: {
    totalRows: { type: Number, default: null },
  },
  data() {
    return {
      filter: {
        фонд: null,
        контролерфонд: 0,
        датаинвентаризации: this.$store.getters.getCurrentDate(),
        датапослпок: null,
        суммадолга: 0,
        датапослоплаты: null,
        флагсчетчики: 0,
      } as any,
      commonFilter: {
        контролер: null,
        услуга: null,
        дата: this.$store.getters.getCurrentDate(),
        контролерФИО: '',
        типпроверки: 0,
        месяц: this.$stackDate.firstDay(this.$store.getters.getCurrentDate()),
      } as any,
      loading: false,
      jobID: null,
    };
  },
  async created() {
    await this.clearFilter();
  },
  computed: {
    title(): string {
      return `Формирование актов обхода для контролера ${this.commonFilter.контролерФИО}`;
    },
    title2(): string {
      return `Общее число записей: ${this.totalRows.toString()}`;
    },
    disableCreateAct(): boolean {
      return +this.totalRows <= 0 || this.notValidMainField;
    },
    disableApplyAct(): boolean {
      return +this.filter.фонд <= 0 || !this.filter.датаинвентаризации || this.notValidMainField;
    },
    disableDatePok(): boolean {
      return this.filter.флагсчетчики !== 1;
    },
    // проверка обязательных полей
    notValidMainField(): boolean {
      return (
        +this.commonFilter.услуга <= 0 ||
        +this.commonFilter.дата <= 0 ||
        +this.commonFilter.контролер <= 0 ||
        !(typeof this.$stackRules.isClosedMonth(this.commonFilter.месяц) === 'boolean' && this.$stackRules.isClosedMonth(this.commonFilter.месяц))
      );
    },
  },
  methods: {
    async applyFilter() {
      this.loading = true;
      const jobID = (
        await new this.$HttpModel('АктыОбхода.Формирование').executeMethod(
          'Перенабрать',
          {
            фильтр: { ...this.filter, ...this.commonFilter },
          },
          {
            async: true,
            jobName: 'Подготовка данных для формирования актов обходов',
            onComplete: () => {
              this.reloadTable();
              this.loading = false;
            },
            onError: () => {
              this.reloadTable();
              this.loading = false;
            },
          },
        )
      ).asyncId;
    },
    async createAct() {
      if (await this.$yesno(` Для контролера ${this.commonFilter.контролерФИО}  будет сформировано ${this.totalRows.toString()} актов на месяц. Продолжить?`)) {
        this.loading = true;
        await new this.$HttpModel('АктыОбхода.Формирование').executeMethod(
          'Создать',
          { параметры: this.commonFilter },
          {
            async: true,
            jobName: 'Создание актов обходов',
            onComplete: () => {
              this.reloadTable();
              this.loading = false;
            },
            onError: () => {
              this.reloadTable();
              this.loading = false;
            },
          },
        );
      }
    },
    async clearFilter() {
      this.clearTable();
      this.clearLinks();
    },
    clearLinks() {
      this.filter.фонд = null;
      this.filter.контролерфонд = null;
      this.filter.датаповерки = null;
      this.filter.датапослпок = null;
      this.filter.суммадолга = null;
      this.filter.датапослоплаты = null;
      this.filter.флагсчетчики = 0;
      this.filter.датаинвентаризации = this.$store.getters.getCurrentDate();
    },
    changeFIO(payload: any) {
      this.commonFilter.контролерФИО = payload && payload[0] ? payload[0]['фио'] : '';
    },
    reloadTable() {
      this.$sendMsg('stack-table', 'АктыОбхода.Формирование', 'reload');
    },
    changeFlag() {
      if (this.filter.флагсчетчики !== 1 && this.filter.датапослпок) {
        this.filter.датапослпок = null;
      }
    },
    async clearTable() {
      this.loading = true;
      await new this.$HttpModel('АктыОбхода.Формирование').executeMethod('Очистить', {});
      this.reloadTable();
      this.loading = false;
    },
    async onChangeUsl() {
      this.clearTable();
    },
  },
});
