const acts: StackSelection = {
  objectName: 'АктыСменыПУ.Ведомость',
  description: 'Ведомость смены ПУ',
  hier: true,
  fields: [
    {
      field: 'объект-показания',
      text: 'объект',
      type: 'Link',
    },
    {
      field: 'показания-услуга',
      text: 'услуга-связь',
      type: 'Link',
    },
    {
      field: 'показания-счет',
      text: 'Счет',
      type: 'Link',
    },
    {
      field: 'показания-счет>номер',
      text: 'Л/с',
      type: 'Number',
    },
    {
      field: 'адрес',
      text: 'Адрес',
      type: 'String',
    },
    {
      field: 'показания-услуга>номер услуги',
      text: 'Услуга',
      type: 'Number',
    },
    {
      field: 'показания-услуга>название',
      text: 'Услуга',
      type: 'String',
    },
    {
      field: 'объект-показания>заводскойномер',
      text: 'Счетчик',
      type: 'String',
    },
    {
      field: '@зона',
      text: 'Зона\nсуток',
      type: 'String',
      computed: true,
    },
    {
      field: '@датпредсч',
      text: 'Дата пред.\nпоказания',
      type: 'Date',
      computed: true,
    },
    {
      field: '@типпредсч',
      text: 'показания',
      type: 'Number',
      computed: true,
    },
    {
      field: '@покпредсч',
      text: 'Пред.\nпоказания',
      type: 'String',
      computed: true,
    },
    {
      field: '@документ',
      text: 'документ',
      type: 'String',
    },
    {
      field: '@изменения',
      text: 'изменения',
      type: 'String',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчетный месяц',
      text: 'Расчетный месяц',
      type: 'DateMonth',
    },
    {
      field: 'показание',
      text: 'Показание',
      type: 'String',
    },
    {
      field: 'расход',
      text: 'Расход',
      type: 'String',
    },
    {
      field: 'итоговый расход',
      text: 'Итого',
      type: 'String',
    },
    {
      field: 'типвводастрокой',
      text: 'Тип ввода',
      type: 'String',
    },

    {
      field: 'номер',
      text: 'Номер',
      type: 'Number',
    },
    {
      field: 'дата',
      text: 'Дата',
      type: 'Date',
    },
    {
      field: 'расчмесяц',
      text: 'Месяц',
      type: 'DateMonth',
    },
    {
      field: 'кол_во',
      text: 'Показаний',
      type: 'Number',
    },
    {
      field: 'примечание',
      text: 'Примечание',
      type: 'String',
    },
  ],
};

export default acts;
