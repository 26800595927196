
import Vue from 'vue';
import tableConst from '@/tasks/common/views/references/constants/$dialogs/$tableConst.vue';

export default Vue.extend({
  components: { tableConst },
  data() {
    return {
      header: [
        {
          field: '@ТGR01',
          text: 'Янв',
          type: 'Number',
        },
        {
          field: '@ТGR02',
          text: 'Фев',
          type: 'Number',
        },
        {
          field: '@ТGR03',
          text: 'Март',
          type: 'Number',
        },
        {
          field: '@ТGR04',
          text: 'Апр',
          type: 'Number',
        },
        {
          field: '@ТGR05',
          text: 'Май',
          type: 'Number',
        },
        {
          field: '@ТGR06',
          text: 'Июнь',
          type: 'Number',
        },
        {
          field: '@ТGR07',
          text: 'Июль',
          type: 'Number',
        },
        {
          field: '@ТGR08',
          text: 'Авг',
          type: 'Number',
        },
        {
          field: '@ТGR09',
          text: 'Сент',
          type: 'Number',
        },
        {
          field: '@ТGR10',
          text: 'Окт',
          type: 'Number',
        },
        {
          field: '@ТGR11',
          text: 'Ноя',
          type: 'Number',
        },
        {
          field: '@ТGR12',
          text: 'Дек',
          type: 'Number',
        },
      ],
    };
  },
});
