const acts: StackSelection = {
  recordRoute: 'references-workers-workersid',
  folderRoute: 'fworkers',
  objectName: 'АктыНарушенийАбонентов.Исполнители',
  description: '',
  fields: [
    {
      field: 'фио',
      text: 'ФИО',
      type: 'String',
    },
    {
      field: 'должность',
      text: 'Должность',
      type: 'String',
    },
    {
      field: 'исполнители-сотрудник',
      text: 'исполнители-сотрудник',
      type: 'Link',
    },
  ],
};

export default acts;
