import Vue from 'vue';

export default Vue.extend({
  props: {
    label: { type: String, default: undefined },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    rules: {
      type: [Object, Array],
      default: (): any => {
        return undefined;
      },
    },
  },
  inject: {
    isDialogReadonly: { default: () => false },
  },
  computed: {
    isInputDisabled(): boolean {
      // @ts-ignore
      return this.disabled || (this.isDialogReadonly && this.isDialogReadonly());
    },
    customRules(): any {
      const rulls = [];
      if (this.required) {
        rulls.push(this.$stackRules.isRequired);
      }
      // @ts-ignore
      if (this.rules) {
        // @ts-ignore
        rulls.push(...this.rules);
      }
      return rulls.length ? rulls : undefined;
    },
    requiredPresent(): boolean {
      let isPresent = false;
      if (this.rules) {
        this.rules.forEach((rule: any) => {
          const ruleName: string = rule && rule.name ? rule.name : '';
          if (ruleName && (ruleName.startsWith('isRequired') || ruleName.startsWith('isLinked'))) {
            isPresent = true;
          }
        });
      }
      return isPresent;
    },
    labelRequired(): string | undefined {
      return (this.required || this.requiredPresent) && this.label ? `${this.label}*` : this.label;
    },
  },
});
