
import Vue from 'vue';
import Plomb from './$dialogs/plomb.vue';
import Folder from '@/tasks/common/views/$common/folderCommon.vue';
import MassPlomb from './$dialogs/massPlomb.vue';
import MassPlombUpd from './$dialogs/massPlombUpd.vue';
import SealToolbar from './$dialogs/SealToolbar.vue';
import plombFilter from '@/tasks/common/views/$common/filters/plombFilter.vue';
import massChangeFields from './$dialogs/massChangeFields.vue';

export default Vue.extend({
  data() {
    return {
      massPlombVis: false,
      massPlombSel: false,
      massChangeFieldVis: false,
      maxDatePlombSel: '1990-01-01T00:00:00' as string,
      selectedItems: [] as StackTableRow[],
      params: {} as any,
    };
  },
  components: { Folder, Plomb, MassPlomb, MassPlombUpd, SealToolbar, plombFilter, massChangeFields },
  methods: {
    updateTable() {
      this.$sendMsg('stack-table', 'РеестрПломб', 'reload');
    },
    async addMassPlomb(plombload: any) {
      this.massPlombVis = false;
      const resultPl = await new this.$HttpModel('Объекты.Пломбы').executeMethod('МассовыйВводПломб', {
        папка: plombload.папка ? plombload.папка : this.params.parentID,
        префикс: plombload.префикс,
        постфикс: plombload.постфикс,
        колзнаков: plombload.колзнаков,
        начномер: plombload.начномер,
        количество: plombload.количество,
        вид: plombload.вид,
        датаввода: plombload.датаввода,
      });
      if (resultPl !== '') {
        this.$toast(resultPl);
      }
      this.$sendMsg('stack-table', 'РеестрПломб', 'reload');
    },
    async updMassPlomb(plombload: any) {
      this.massPlombSel = false;
      const resultPl = await new this.$HttpModel('Объекты.Пломбы').executeMethod('МассоваяВыдачаПломб', {
        папка: this.params.parentID,
        выбрано: this.selectedItems,
        сотрудник: plombload.сотрудник,
        дата: plombload.дата,
        первыйНомер: plombload.первыйНомер,
        последнийНомер: plombload.последнийНомер,
      });
      if (resultPl !== '') {
        this.$toast(resultPl);
      }
      this.$sendMsg('stack-table', 'РеестрПломб', 'reload');
      this.$sendMsg('stack-table', 'РеестрПломб', 'unselect-all');
    },
    onSelect(items: StackTableRow[]) {
      this.selectedItems = items;
    },
    onBtnPlombSelect(selectedItems: StackTableRow[]) {
      this.massPlombSel = true;
      // Определим максимальную дату состояния из выделенных нераспределённых пломб
      this.maxDatePlombSel = '1990-01-01T00:00:00';
      let currDatePlombSel: any = '';
      for (const indS in selectedItems) {
        currDatePlombSel = selectedItems[indS]['дата состояния'];
        if (
          currDatePlombSel !== null &&
          currDatePlombSel !== undefined &&
          Date.parse(currDatePlombSel.toString()) > Date.parse(this.maxDatePlombSel) &&
          selectedItems[indS].состояние === 4
        ) {
          this.maxDatePlombSel = currDatePlombSel.toString();
        }
      }
    },
    async onChangeFields(fields: any) {
      this.massChangeFieldVis = false;
      const resultPl = await new this.$HttpModel('Объекты.Пломбы').executeMethod('МассоваяСменаПолей', {
        датасостояния: fields.датасостояния,
        сотрудник: fields.сотрудник,
        вид: fields.вид,
        состояние: fields.состояние,
        выбрано: this.selectedItems,
      });
      this.$sendMsg('stack-table', 'РеестрПломб', 'reload');
    },
  },
});
